import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {faBagsShopping} from '@fortawesome/pro-solid-svg-icons';
import Modal from '../../shared/modal/Modal';
import PrintJobTable from '../shared/PrintJobTable';
import EditPrintJob from '../shared/EditPrintJob';
import ViewPrintJob from '../shared/ViewPrintJob';

const PrintJobWidgetModal = ({
  disabled,
  fromDate,
  toDate,
  hierarchyId,
  identifier,
  onCancel,
  open,
  ...props
}) => {
  const {t} = useTranslation();
  const [editingRecord, setEditingRecord] = useState(null);
  const [viewingRecord, setViewingRecord] = useState(null);

  return (
    <Modal
      width="90vw"
      title={(
        <>
          <FontAwesomeIcon icon={faBagsShopping}/>
          {' '}
          {t('printJob.model.other')}
        </>
      )}
      footer={false}
      open={open}
      onCancel={() => {
        onCancel();
      }}
      {...props}
    >
      <PrintJobTable
        fromDate={fromDate}
        toDate={toDate}
        hierarchyId={hierarchyId}
        onEdit={setEditingRecord}
        onView={setViewingRecord}
      />
      {editingRecord && (
        <EditPrintJob
          job={editingRecord}
          onSuccess={() => setEditingRecord(null)}
          onCancel={() => setEditingRecord(null)}
        />
      )}
      {viewingRecord && (
        <ViewPrintJob
          job={viewingRecord}
          onSuccess={() => setViewingRecord(null)}
          onCancel={() => setViewingRecord(null)}
        />
      )}
    </Modal>
  );
};
export default observer(PrintJobWidgetModal);
