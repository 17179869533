import { NamePath } from 'antd/es/form/interface';
import { Rule } from 'antd/es/form';
import { ReactNode } from 'react';
import { DisplayablePropertyParams } from '../models/displayableProperty';

export enum FieldType {
  String = 'String',
  Boolean = 'Boolean',
  Number = 'Number',
  Select = 'Select',
  SelectMultiple = 'SelectMultiple',
  SelectTags = 'SelectTags',
  StorageUnitSelectorField = 'StorageUnitSelectorField',
  Properties = 'Properties',
  Color = 'Color',
  HierarchyTree = 'HierarchyTree',
  HierarchyTreeMultiple = 'HierarchyTreeMultiple',
  HierarchyTreeMultipleWorkplacesOnly = 'HierarchyTreeMultipleWorkplacesOnly',
  ModelPropertySelectField = 'ModelPropertySelectField',
  HierarchyTreeWorkplace = 'HierarchyTreeWorkplace',
  CustomTabEditor = 'CustomTabEditor',
  PropertiesSelectDisplayField = 'PropertiesSelectDisplayField',
  Translatable = 'Translatable',
  RadioField = 'RadioField'
}

export type FieldOption = {
  value: any;
  label: ReactNode;
  helpText?: string;
  group?: {
    key: string,
    label: string,
  }
};

export type FieldProperty = {
  key: string;
  title: string;
  enabled: boolean;
};

export type NestedModelOption = {
  key: string;
  label: string;
  properties: DisplayablePropertyParams[] | (() => DisplayablePropertyParams[]);
};

export type FieldTranslations = {
  [key: string]: string;
};

/*
 * FIXME: Harmonize fields in this type:
 *  - simplify id (at the moment, can be nested arrays (not represented)). Ideally should be 'string | number'
 *  - ensure, type is only used for configuring WidgetConfig Inputs, and not their Results
 *  - simplify modelOptions should only
 */
export type ConfigField = {
  id: string | number | (string | number)[];
  type: FieldType;
  modelOptions?: DisplayablePropertyParams[] | NestedModelOption[];
  disabled?: ((param: any) => boolean) | boolean;
  isDisabled?: ((param: any) => boolean);
  label?: string;
  helpText?: string;
  validations?: Rule[];
  defaultValue?: any;
  onBlur?: (event: FocusEvent) => void;
  dependencies?: NamePath[];
  options?: (() => FieldOption[]) | FieldOption[];
  allowClear?: boolean;
  showSearch?: boolean;
  unit?: string;
  onChange?: (change: any) => void;
  maxTabCount?: number;
  tabFields?: ConfigField[];
  disableGroupEditor?: boolean;
  name?: string | number | (string | number)[];
  properties?: FieldProperty[];
  texts?: FieldTranslations
};

/*
 * FIXME: Type of a return value of BaseWidgetConfig.getSettingValue. At the moment this is only an alias for any.
 *  Use it anyway to improve future refactoring
 */
export type SettingValue = any;

export type ListElement = {
  name: string;
  fieldKey: string;
};

export type TabDefinition = {
  key: string;
  label: string;
};

export type ConfigSetting = Omit<ConfigField, 'id'> & {
  id: string,
};

export interface WidgetConfigExtension {
  getTabs: () => TabDefinition[],
  getSettings: (tab: string) => ConfigSetting[]
}
