import { computed, observable, makeObservable } from 'mobx';
import dayjs from 'dayjs';
import { Observer } from 'mobx-react-lite';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import { InspectionTask, InspectionType } from './inspectionTask';
import { datetimeFormat, durationFormat } from '../config/dayjs';
import {
  sortAlphabetically, sortBoolean, sortChronologically, sortNumerically
} from '../components/shared/tables/sorters';
import UserFullName from '../components/shared/UserFullName';
import { RootStore } from '../stores/rootStore';

/**
 * t('inspectionTaskResult.valuation.notValuated')
 * t('inspectionTaskResult.valuation.rejected')
 * t('inspectionTaskResult.valuation.accepted')
 */
export enum InspectionTaskResultValuation {
  accepted = 'A',
  rejected = 'R',
  notValuated = 'N'
}

export class InspectionTaskResult extends BaseModel {
  id: number = 0;
  inspectionTaskId: number = 0;
  value: string = '';
  valuation: InspectionTaskResultValuation | null = null;
  remark: string | null = null;
  batchId: number | null = null;
  overruledUserId: number | null = null;
  overruledValue: string | null = null;
  overruledValuation: InspectionTaskResultValuation | null = null;
  overruledRemark: string | null = null;
  dueDate: string | null = null;
  isVerified: boolean = false;
  verificationMessage: string | null = null;
  recordedAt: string = '';
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      inspectionTaskId: observable,
      inspectionTask: computed,
      value: observable,
      valuation: observable,
      remark: observable,
      batchId: observable,
      overruledUserId: observable,
      overruledUser: computed,
      overruledValue: observable,
      overruledValuation: observable,
      overruledRemark: observable,
      dueDate: observable,
      createdBy: observable,
      createdByUser: computed,
      overdueDifference: computed,
      overdueDifferenceAbsolute: computed,
      differenceInMinutes: computed,
      isVerified: observable,
      verificationMessage: observable,
      recordedAt: observable,
    });
  }

  displayableProperties = [
    displayableProperty({
      key: 'recordedAt',
      title: i18n.t('inspectionTaskResult.model.attributes.recordedAt'),
      params: [
        displayablePropertyParam({
          path: 'recordedAt', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortChronologically(a.recordedAt, b.recordedAt),
      renderText: (text, record) => record?.recordedAt || '',
    }),
    displayableProperty({
      key: 'createdAt',
      title: i18n.t('inspectionTaskResult.model.attributes.createdAt'),
      params: [
        displayablePropertyParam({
          path: 'createdAt', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortChronologically(a.createdAt, b.createdAt),
      renderText: (text, record) => record?.createdAt || '',
    }),
    displayableProperty({
      key: 'createdBy',
      title: i18n.t('inspectionTaskResult.model.attributes.createdBy'),
      params: [displayablePropertyParam({
        path: 'createdByUser',
        transform: (user) => (
          <Observer>
            {() => <UserFullName user={user}/>}
          </Observer>
        ),
      })],
      raw: true,
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.createdByUser.fullName, b.createdByUser.fullName),
      renderText: (text, record) => record?.createdByUser?.fullName || '',
    }),
    displayableProperty({
      key: 'dueDate',
      title: i18n.t('inspectionTaskResult.model.attributes.dueDate'),
      params: [
        displayablePropertyParam({
          path: 'dueDate', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortChronologically(a.dueDate, b.dueDate),
      renderText: (text, record) => record?.dueDate || '',
    }),
    displayableProperty({
      key: 'overdueDifference',
      title: i18n.t('inspectionTaskResult.model.attributes.overdueDifference'),
      params: [
        displayablePropertyParam({
          path: 'overdueDifference', transform: transformation.durationOrDash({ format: durationFormat }),
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortNumerically(a.overdueDifference, b.overdueDifference),
    }),
    displayableProperty({
      key: 'overdueDifferenceAbsolute',
      title: i18n.t('inspectionTaskResult.model.attributes.overdueDifferenceAbsolute'),
      params: [
        displayablePropertyParam({
          path: 'overdueDifferenceAbsolute', transform: transformation.durationOrDash({ format: durationFormat }),
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortNumerically(a.overdueDifferenceAbsolute, b.overdueDifferenceAbsolute),
    }),
    displayableProperty({
      key: 'value',
      title: i18n.t('inspectionTaskResult.model.attributes.value'),
      params: [displayablePropertyParam({ path: 'value', transform: transformation.none })],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.value, b.value),
    }),
    displayableProperty({
      key: 'qualitativeText',
      title: i18n.t('inspectionTaskResult.model.attributes.qualitativeText'),
      params: [displayablePropertyParam({ path: 'qualitativeText', transform: transformation.fallback() })],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.qualitativeText, b.qualitativeText),
    }),
    displayableProperty({
      key: 'valueWithText',
      title: i18n.t('inspectionTaskResult.model.attributes.valueWithText'),
      params: [displayablePropertyParam({ path: 'valueWithText', transform: transformation.fallback() })],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.value, b.value),
    }),
    displayableProperty({
      key: 'valuation',
      title: i18n.t('inspectionTaskResult.model.attributes.valuation'),
      params: [displayablePropertyParam({ path: 'valuation', transform: transformation.none })],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.valuation, b.valuation),
    }),
    displayableProperty({
      key: 'remark',
      title: i18n.t('inspectionTaskResult.model.attributes.remark'),
      params: [displayablePropertyParam({ path: 'remark', transform: transformation.none })],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.remark, b.remark),
    }),
    displayableProperty({
      key: 'overruledValue',
      title: i18n.t('inspectionTaskResult.model.attributes.overruledValue'),
      params: [displayablePropertyParam({ path: 'overruledValue', transform: transformation.none })],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.overruledValue, b.overruledValue),
    }),
    displayableProperty({
      key: 'qualitativeTextOverrule',
      title: i18n.t('inspectionTaskResult.model.attributes.qualitativeTextOverrule'),
      params: [displayablePropertyParam({ path: 'qualitativeTextOverrule', transform: transformation.fallback() })],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.qualitativeTextOverrule, b.qualitativeTextOverrule),
    }),
    displayableProperty({
      key: 'valueWithTextOverrule',
      title: i18n.t('inspectionTaskResult.model.attributes.overruledValueWithText'),
      params: [displayablePropertyParam({ path: 'valueWithTextOverrule', transform: transformation.fallback() })],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.overruledValue, b.overruledValue),
    }),
    displayableProperty({
      key: 'overruledValuation',
      title: i18n.t('inspectionTaskResult.model.attributes.overruledValuation'),
      params: [displayablePropertyParam({ path: 'overruledValuation', transform: transformation.none })],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.overruledValuation, b.overruledValuation),
    }),
    displayableProperty({
      key: 'overruledBy',
      title: i18n.t('inspectionTaskResult.model.attributes.overruledBy'),
      params: [displayablePropertyParam({
        path: 'overruledUser',
        transform: (user) => (
          <Observer>
            {() => <UserFullName user={user}/>}
          </Observer>
        ),
      })],
      raw: true,
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.overruledUser?.fullName, b.overruledUser?.fullName),
      renderText: (text, record) => record?.overruledUser?.fullName || '',
    }),
    displayableProperty({
      key: 'overruledRemark',
      title: i18n.t('inspectionTaskResult.model.attributes.overruledRemark'),
      params: [displayablePropertyParam({ path: 'overruledRemark', transform: transformation.none })],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.overruledRemark, b.overruledRemark),
    }),
    displayableProperty({
      key: 'overruleType',
      title: i18n.t('inspectionTaskResult.model.attributes.overruleType'),
      params: [displayablePropertyParam({ path: 'inspectionTask.overruleType', transform: transformation.none })],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.overruleType, b.overruleType),
    }),
    displayableProperty({
      key: 'isVerified',
      title: i18n.t('inspectionTaskResult.model.attributes.isVerified'),
      params: [displayablePropertyParam({ path: 'isVerified', transform: transformation.booleanValue })],
      template: '{value}',
      raw: true,
      sorter: (a, b) => sortBoolean(a.isVerified, b.isVerified),
    }),
  ];

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);

    const inspectionTaskPrefix = `${titlePrefix}${i18n.t('inspectionTask.model.one')} > `;
    allDisplayableProperties.push(
      ...InspectionTask.allDisplayableProperties(rootStore, `${pathPrefix}inspectionTask.`, inspectionTaskPrefix)
    );

    return allDisplayableProperties;
  }

  get inspectionTask() {
    return this.rootStore.inspectionTaskStore.getById(this.inspectionTaskId);
  }

  get overruledUser() {
    return this.rootStore.userStore.getById(this.overruledUserId);
  }

  get createdByUser() {
    return this.rootStore.userStore.getById(this.createdBy);
  }

  get overdueDifference() {
    if (!this.dueDate) {
      return null;
    }

    return dayjs(this.recordedAt).diff(dayjs(this.dueDate), 'seconds');
  }

  get overdueDifferenceAbsolute() {
    return Math.abs(this.overdueDifference || 0);
  }

  get differenceInMinutes() {
    const diff = this.overdueDifference;
    return diff ? Math.round(diff / 60) : 0;
  }

  get qualitativeText(): string {
    if (this.inspectionTask && [InspectionType.FactoryCode, InspectionType.Qualitative, InspectionType.BestBeforeDate]
      .includes(this.inspectionTask?.inspectionType)) {
      const catalog = this.inspectionTask.qualitative?.catalogFeatures;
      const feature = catalog?.find((feat) => (
        feat.code === this.value && feat.valuation === this.valuation && !feat.isOverrule
      ));
      return feature?.text || '';
    }
    return '';
  }

  get valueWithText() {
    return `(${this.value}) ${this.qualitativeText}`;
  }

  get qualitativeTextOverrule(): string {
    if (this.overruledValue
      && this.inspectionTask
      && [InspectionType.FactoryCode, InspectionType.Qualitative, InspectionType.BestBeforeDate]
        .includes(this.inspectionTask?.inspectionType)) {
      const catalog = this.inspectionTask.qualitative?.catalogFeatures;
      const feature = catalog?.find((feat) => (
        feat.code === this.overruledValue && feat.valuation === this.overruledValuation && feat.isOverrule
      ));
      return feature?.text || '';
    }
    return '';
  }

  get valueWithTextOverrule() {
    return (this.qualitativeTextOverrule)
      ? `(${this.overruledValue}) ${this.qualitativeTextOverrule}`
      : `(${this.overruledValue})`;
  }
}
