import { uniqueId } from 'lodash';
import { ReactNode } from 'react';

export enum FlashMessageType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error'
}

export interface FlashMessageOptions {
  [key: string]: any;
}

export class FlashMessage {
  id: string;
  type: FlashMessageType;
  title: ReactNode;
  options?: FlashMessageOptions = {};
  duration?: number = 0;

  constructor(
    type: FlashMessageType,
    title: ReactNode,
    options: FlashMessageOptions = {},
    duration: number = 3,
    id?: string
  ) {
    this.type = type;
    this.title = title;
    this.options = options;
    this.duration = duration;
    this.id = id || uniqueId();
  }
}
