import {computed, observable, makeObservable} from 'mobx';
import i18n from '../../../../../i18n/i18n';
import {ValidationRules} from '../../../../../utils/validationRules';
import {CockpitWidgetConfig} from './cockpitWidgetConfig';
import {FieldType} from '../../../../../types/widgetConfig';

export class multipleTimeseriesCockpitWidgetConfig extends CockpitWidgetConfig {
  selectedHierarchyId = 0;

  constructor({rootStore, widgetTypeIdentifier, widgetId}) {
    super({rootStore, widgetTypeIdentifier, widgetId});

    makeObservable(this, {
      selectedHierarchyId: observable,
      timeseries: computed,
    });
  }

  static onLoadConfig = (rootStore) => {
    rootStore.sensorStore.loadAll();
  };

  static widgetType = {
    identifier: 'multiple-timeseries-chart',
    name: () => i18n.t('cockpitWidget.widgetTypeIdentifier.multipleTimeseriesChart'),
  };

  // eslint-disable-next-line class-methods-use-this
  getWidgetTypeName() {
    return i18n.t('cockpitWidget.widgetTypeIdentifier.multipleTimeseriesChart');
  }

  get tabs() {
    return [
      this.generalSettingsTab,
      this.workplaceTab,
      {
        key: 'timeseries',
        label: i18n.t('widget.WidgetSettingsModal.timeseriesConfigTabTitle'),
      },
    ];
  }

  workplace = [
    {
      id: 'hierarchyId',
      label: i18n.t('cockpitWidget.settings.workplace.label'),
      helpText: i18n.t('cockpitWidget.settings.workplace.help'),
      type: FieldType.HierarchyTreeWorkplace,
    },
  ];

  get timeseries() {
    return [
      {
        id: 'sensors',
        label: i18n.t('multipleTimeseriesCockpitWidgetConfig.settings.sensors.label'),
        type: FieldType.SelectMultiple,
        options: this.rootStore.sensorStore.sensors.map((sensor) => ({
          value: sensor.name,
          label: sensor.label || sensor.name,
        })),
        validations: [ValidationRules.required()],
        onFormChange: (form, changedFields) => {
          if (changedFields.hierarchyId !== undefined) {
            form.setFieldsValue({sensor: null});
          }
        },
      },
      {
        id: 'amountOfHours',
        label: i18n.t('multipleTimeseriesCockpitWidgetConfig.settings.amountOfHours.label'),
        type: FieldType.Number,
        unit: i18n.t('multipleTimeseriesCockpitWidgetConfig.settings.amountOfHours.unit'),
        validations: [
          ValidationRules.required(),
          ValidationRules.lessThanOrEqualTo(168),
          ValidationRules.greaterThan(0),
        ],
        defaultValue: 8,
      },
      {
        id: 'decimalPlaces',
        label: i18n.t('multipleTimeseriesCockpitWidgetConfig.settings.decimalPlaces.label'),
        type: FieldType.Number,
        validations: [ValidationRules.greaterThanOrEqualTo(0), ValidationRules.lessThanOrEqualTo(100)],
        defaultValue: 3,
      },
      {
        id: 'showTargets',
        label: i18n.t('multipleTimeseriesCockpitWidgetConfig.settings.showTargets.label'),
        type: FieldType.Boolean,
        defaultValue: false,
      }, {
        id: 'resolution',
        label: i18n.t('multipleTimeseriesCockpitWidgetConfig.settings.resolution.label'),
        helpText: i18n.t('multipleTimeseriesCockpitWidgetConfig.settings.resolution.help'),
        unit: i18n.t('multipleTimeseriesCockpitWidgetConfig.settings.resolution.unit'),
        type: FieldType.Number,
        validations: [ValidationRules.greaterThanOrEqualTo(1), ValidationRules.lessThanOrEqualTo(60)],
        defaultValue: '',
      },
    ];
  }

  getHierarchyId() {
    return this.getSettingValue('workplace', 'hierarchyId') || null;
  }

  getSensors() {
    return this.getSettingValue('timeseries', 'sensors') || [];
  }

  getAmountOfHours() {
    return this.getSettingValue('timeseries', 'amountOfHours') || 0;
  }

  getShowTargets() {
    return this.getSettingValue('timeseries', 'showTargets') || false;
  }

  getResolution() {
    return this.getSettingValue('timeseries', 'resolution') || undefined;
  }
}
