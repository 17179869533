import React, { useRef, useState } from 'react';
import { Space, Upload as AntdUpload, UploadFile, UploadProps as AntdUploadProps } from 'antd';
import { v4 as uuid } from 'uuid';
import { faCamera, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UploadFileStatus } from 'antd/lib/upload/interface';
import { RcFile } from 'antd/es/upload';
import { useTranslation } from 'react-i18next';
import Button from '../../buttons/Button';
import { readFileAsDataURL } from '../fileReader';
import { useStore } from '../../../../hooks/useStore';
import { FlashMessage, FlashMessageType } from '../../../../models/flashMessage';
import { Document } from '../../../../models/document';
import WebcamModal from './WebcamModal';
import { useMount } from '../../../../hooks/useMount';

export type MultiImageUploadProps = Omit<AntdUploadProps, 'picture' | 'action' | 'accept'> & {
  enableCameraIntegration?: boolean;
  onChange?: (value: number[]) => void;
};

const MultiImageUpload: React.FC<MultiImageUploadProps> = ({
  onChange,
  enableCameraIntegration = false,
  ...props
}) => {
  const store = useStore();
  const { t } = useTranslation();
  const [files, setFiles] = useState<{ [key: string]: number }>({});
  const [documentIds, setDocumentIds] = useState<number[]>([]);
  const [showWebcamModal, setShowWebcamModal] = useState(false);
  const [rerender, setRerender] = useState(false);
  const fileList = useRef<UploadFile[]>([]);

  useMount(() => {
    const listener = (e: Event) => {
      if (fileList.current.length) {
        e.preventDefault();
      }
    };
    window.addEventListener('beforeunload', listener);
    return () => { window.removeEventListener('beforeunload', listener); };
  }, [fileList.current.length]);

  const addFile = (uid: string, document: Document): void => {
    const newDocumentIds = [...documentIds, document.id];
    setDocumentIds(newDocumentIds);
    setFiles({ ...files, [uid]: document.id });
    if (onChange) {
      onChange(newDocumentIds);
    }
  };

  const addToFileList = (uid: string, filename: string) => {
    if (fileList.current.find((file) => file.uid === uid)) {
      return;
    }
    fileList.current = [...fileList.current, {
      uid,
      name: filename,
      status: 'uploading',
    }];
    setRerender(!rerender);
  };

  const updateFileList = (uid: string, change: { status?: UploadFileStatus, thumbUrl?: string }) => {
    const file = fileList.current.find((f) => f.uid === uid);
    if (!file) {
      return;
    }
    file.status = change.status || file.status;
    file.thumbUrl = change.thumbUrl || file.thumbUrl;
    setRerender(!rerender);
  };

  const handleUploadRequest = async (options: any) => {
    const dataUrl = await readFileAsDataURL(options.file);
    const { uid, name = `${uuid()}.jpeg` } = (options.file as RcFile);
    try {
      addToFileList(uid, name);
      const document = await store.documentStore.createImage({
        label: '',
        content: dataUrl?.split('base64,')[1] || '',
        filename: name,
      });
      updateFileList(uid, { status: 'done', thumbUrl: document.src });
      addFile((options.file as RcFile).uid, document);
      if (options.onSuccess) {
        options.onSuccess();
      }
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error('error while saving new image', e);
      updateFileList(uid, { status: 'error' });
      store.flashMessageStore.addFlashMessage(
        new FlashMessage(FlashMessageType.ERROR, t('errors.messages.unexpected'))
      );
      if (options.onError) {
        options.onError();
      }
    }
  };

  const handleCameraRequest = async (data: string) => {
    setShowWebcamModal(false);
    const uid = uuid();
    const name = `${uid}.jpeg`;
    try {
      addToFileList(uid, name);
      const document = await store.documentStore.createImage({
        label: '',
        content: data.split('base64,')[1],
        filename: name,
      });
      updateFileList(uid, { status: 'done', thumbUrl: document.src });
      addFile(uid, document);
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error('error while saving new image', e);
      updateFileList(uid, { status: 'error' });
      store.flashMessageStore.addFlashMessage(
        new FlashMessage(FlashMessageType.ERROR, t('errors.messages.unexpected'))
      );
    }
  };

  const handleDelete = (file: UploadFile) => {
    (async () => {
      const documentId = files[file.uid];
      if (!documentId) {
        return;
      }
      try {
        await store.documentStore.delete(documentId, { skipNotification: true });
      } catch (e: any) {
        // eslint-disable-next-line no-console
        console.error('error while deleting an image', e);
      } finally {
        const idIdx = documentIds.indexOf(documentId);
        const newDocumentIds = [...documentIds];
        newDocumentIds.splice(idIdx, 1);
        setDocumentIds(newDocumentIds);
        fileList.current = fileList.current.filter((fileListItem) => fileListItem.uid !== file.uid);
        if (onChange) {
          onChange(newDocumentIds);
        }
      }
    })();
  };

  const handleWebcamOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowWebcamModal(true);
  };

  // Required for AntdUpload to not throw a warning
  // @ts-ignore
  delete props.value;

  return (
    <>
      <AntdUpload
        listType={'picture'}
        accept={'image/jpeg'}
        customRequest={handleUploadRequest}
        onRemove={handleDelete}
        fileList={fileList.current}
        {...props}
      >
        <Space direction={'horizontal'}>
          <Button
            icon={<FontAwesomeIcon icon={faUpload}/>}
            disabled={documentIds.length >= 5}
          >
            {t('input.multiImageUpload.file')}
          </Button>
          {enableCameraIntegration && (
            <Button
              icon={<FontAwesomeIcon icon={faCamera}/>}
              onClick={handleWebcamOpen}
              disabled={documentIds.length >= 5}
            >
              {t('input.multiImageUpload.camera')}
            </Button>
          )}
        </Space>
      </AntdUpload>
      <WebcamModal
        open={showWebcamModal}
        onCancel={() => setShowWebcamModal(false)}
        onSave={handleCameraRequest}
      />
    </>
  );
};

export default MultiImageUpload;
