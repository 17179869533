import {createEntity, executeDelete, executeGet, executePatch, updateEntity} from '../requests';

const resourcesName = 'v1/users';

export const updatePasswordEndpoint = 'updatePassword';

export const UsersApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...values}) => updateEntity(values, `${resourcesName}/${id}`),
  [updatePasswordEndpoint]: ({id, ...entity}) => executePatch(`${resourcesName}/${id}`, entity),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
};
