import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { InspectionPlan } from '../models/inspectionPlan';
import { RootStore } from './rootStore';

export class InspectionPlanStore extends EntityStore<InspectionPlan> {
  inspectionPlans: InspectionPlan[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'inspectionPlans', Api.inspectionPlan, InspectionPlan);

    makeObservable(this, {
      inspectionPlans: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.inspectionTaskStore,
        modelId: 'inspectionTaskIds',
      },
      {
        store: this.rootStore.materialStore,
        modelId: 'materialIds',
      },
    ];
  }
}
