import { observable, runInAction, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { BatchQueueItem } from '../models/batchQueueItem';
import { RootStore } from './rootStore';

export class BatchQueueItemStore extends EntityStore<BatchQueueItem> {
  batchQueueItems: BatchQueueItem[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'batchQueueItems', Api.batchQueueItems, BatchQueueItem);

    makeObservable(this, {
      batchQueueItems: observable,
    });
  }

  cancelQueue(workplaceId: number, materialId: number, batchQueueItemIds: number[]) {
    const pendingAction = 'cancelQueue';
    this.addPendingAction(pendingAction);

    return Api.batchQueueItems.cancelQueue({ workplaceId, materialId, batchQueueItemIds }).then(({ data }) => {
      const entities = data.map(this.createModelInstance.bind(this));
      runInAction(() => {
        this.removePendingAction(pendingAction);
        this.addAll(entities, { replaceOptions: { preserveReduced: true } });
      });
      return entities;
    }).catch((e) => this.handleApiError(e, pendingAction));
  }

  getByWorkplaceIdAndMaterialId(workplaceId: number, materialId: number) {
    return this.batchQueueItems.filter((item) => item.workplaceId === workplaceId && item.materialId === materialId);
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.batchStore,
        modelId: 'batchId',
      },
    ];
  }
}
