import i18n from '../../../../../i18n/i18n';
import {CockpitWidgetConfig} from './cockpitWidgetConfig';
import {TimeframeOptions} from './timeframeAggregationOptions';
import {ValidationRules} from '../../../../../utils/validationRules';
import appConfig from '../../../../../utils/appConfig';
import {FieldType} from '../../../../../types/widgetConfig';

export class TagInterruptionAnalysisWidgetConfig extends CockpitWidgetConfig {
  static widgetType = {
    identifier: 'tag-interruption-analysis',
    name: () => i18n.t('cockpitWidget.widgetTypeIdentifier.tagInterruptionAnalysis'),
  };

  // eslint-disable-next-line class-methods-use-this
  getWidgetTypeName() {
    return i18n.t('cockpitWidget.widgetTypeIdentifier.tagInterruptionAnalysis');
  }

  get tabs() {
    return [
      this.generalSettingsTab,
      this.workplaceTab,
      {
        key: 'tagInterruptionAnalysis',
        label: i18n.t('widget.WidgetSettingsModal.tagInterruptionAnalysisConfigTabTitle'),
      },
    ];
  }

  workplace = [
    {
      id: 'hierarchyId',
      label: i18n.t('cockpitWidget.settings.workplace.label'),
      helpText: i18n.t('cockpitWidget.settings.workplace.help'),
      type: FieldType.HierarchyTreeWorkplace,
    },
  ];

  static interruptionSourceLevels = [
    'hierarchyLevel.0',
    'hierarchyLevel.1',
    'hierarchyLevel.2',
    'hierarchyLevel.3',
    'hierarchyLevel.4',
    'hierarchyLevel.5',
    'hierarchyLevel.6',
  ];

  static interruptionClass = 'interruptionClass';

  static interruptionReason = 'interruptionReason';

  // eslint-disable-next-line class-methods-use-this
  getInterruptionTags() {
    const options = [
      {
        value: TagInterruptionAnalysisWidgetConfig.interruptionClass,
        label: i18n.t('interruptionClass.model.one'),
      },
      {
        value: TagInterruptionAnalysisWidgetConfig.interruptionReason,
        label: i18n.t('interruptionReason.model.one'),
      },
    ];

    if (appConfig) {
      const config = appConfig;
      const hierarchyConfig = config.hierarchy;
      const customLabel0 = hierarchyConfig.level0Label ? hierarchyConfig.level0Label[config.language] : '';
      const customLabel1 = hierarchyConfig.level1Label ? hierarchyConfig.level1Label[config.language] : '';
      const customLabel2 = hierarchyConfig.level2Label ? hierarchyConfig.level2Label[config.language] : '';
      const customLabel3 = hierarchyConfig.level3Label ? hierarchyConfig.level3Label[config.language] : '';
      const customLabel4 = hierarchyConfig.level4Label ? hierarchyConfig.level4Label[config.language] : '';
      const customLabel5 = hierarchyConfig.level5Label ? hierarchyConfig.level5Label[config.language] : '';
      const customLabel6 = hierarchyConfig.level6Label ? hierarchyConfig.level6Label[config.language] : '';

      options.push(
        {
          value: 'hierarchyLevel.0',
          label: customLabel0 || i18n.t('hierarchy.model.level0'),
        },
        {
          value: 'hierarchyLevel.1',
          label: customLabel1 || i18n.t('hierarchy.model.level1'),
        },
        {
          value: 'hierarchyLevel.2',
          label: customLabel2 || i18n.t('hierarchy.model.level2'),
        },
        {
          value: 'hierarchyLevel.3',
          label: customLabel3 || i18n.t('hierarchy.model.level3'),
        },
        {
          value: 'hierarchyLevel.4',
          label: customLabel4 || i18n.t('hierarchy.model.level4'),
        },
        {
          value: 'hierarchyLevel.5',
          label: customLabel5 || i18n.t('hierarchy.model.level5'),
        },
        {
          value: 'hierarchyLevel.6',
          label: customLabel6 || i18n.t('hierarchy.model.level6'),
        }
      );
    }

    return options;
  }

  tagInterruptionAnalysis = [
    {
      id: 'timeframe',
      label: i18n.t('tagInterruptionAnalysisWidgetConfig.settings.tagInterruptionAnalysis.timeframe.label'),
      helpText: i18n.t('tagInterruptionAnalysisWidgetConfig.settings.tagInterruptionAnalysis.timeframe.helpText'),
      type: FieldType.Select,
      options: Object.values(TimeframeOptions).map((o) => ({
        value: o,
        label: i18n.t(`cockpitWidget.settings.timeframe.${o}`),
      })),
      validations: [ValidationRules.required()],
    },
    {
      id: 'daysBack',
      label: i18n.t('tagInterruptionAnalysisWidgetConfig.settings.tagInterruptionAnalysis.daysBack.label'),
      helpText: i18n.t('tagInterruptionAnalysisWidgetConfig.settings.tagInterruptionAnalysis.daysBack.helpText'),
      type: FieldType.Number,
      unit: i18n.t('tagInterruptionAnalysisWidgetConfig.settings.tagInterruptionAnalysis.daysBack.unit'),
      defaultValue: 3,
      validations: [
        ({getFieldValue}) => ValidationRules.requiredIf(getFieldValue('timeframe') === TimeframeOptions.DAYS_BACK),
        ValidationRules.minNumber(1),
        ValidationRules.maxNumber(7),
      ],
      disabled: ({getFieldValue}) => getFieldValue('timeframe') !== TimeframeOptions.DAYS_BACK,
      dependencies: ['timeframe'],
    },
    {
      id: 'tags',
      label: i18n.t('tagInterruptionAnalysisWidgetConfig.settings.tagInterruptionAnalysis.tags.label'),
      type: FieldType.SelectMultiple,
      defaultValue: [],
      options: this.getInterruptionTags(),
      validations: [ValidationRules.required()],
    },
  ];
}
