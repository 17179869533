import { computed, observable, makeObservable } from 'mobx';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { getTranslation } from '../utils/translations';
import { Translation } from './translations';
import { RootStore } from '../stores/rootStore';

export interface InterruptionClassTranslation extends Translation {
  label: string | null;
  description: string | null;
}

export class InterruptionClass extends BaseModel {
  id: number = 0;
  name: string = '';
  translations: InterruptionClassTranslation[] = [];
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      label: computed,
      description: computed,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
      translations: observable,
    });
  }

  static faIcon = faExclamationCircle;

  searchableProperties = ['label', 'description'];

  translatedProperties = ['label', 'description'];

  static prepareApiPayload(model: InterruptionClass) {
    return {
      id: model.id,
      name: model.name,
      translations: InterruptionClass.convertToSavableTranslations(model),
    };
  }

  get label() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.label;
  }

  get description() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.description;
  }
}
