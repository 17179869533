import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faCheck,
  faCircleNotch,
  faLock,
  faPause,
  faStop,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faClock, faPlayPause } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import React, { ReactNode } from 'react';
import i18n from '../../../i18n/i18n';
import appConfig from '../../../utils/appConfig';
import { ConfigSetting, FieldType } from '../../../types/widgetConfig';

const iconElements: { [key: string]: IconDefinition } = {
  planned: faCalendarAlt,
  ready: faStop,
  paused: faPause,
  active: faCircleNotch,
  finished: faCheck,
  aborted: faTimesCircle,
  disabled: faLock,
};

if (appConfig.modules.enableTransitionQueue) {
  iconElements.queued = faPlayPause;
  iconElements.scheduled = faClock;
}

export const iconNames = Object.keys(iconElements);

/**
 * context translations:
 * t('orderWidget.settings.icon.planned')
 * t('orderWidget.settings.icon.paused')
 * t('orderWidget.settings.icon.active')
 * t('orderWidget.settings.icon.finished')
 * t('orderWidget.settings.icon.aborted')
 * t('orderWidget.settings.icon.disabled')
 * t('orderWidget.settings.icon.queued')
 * t('orderWidget.settings.icon.scheduled')
 */
export const getIcon = (name: any, withLabel = true): ReactNode => (
  <>
    <FontAwesomeIcon icon={iconElements[name]}/>
    {withLabel && ` - ${i18n.t<string>(`orderWidget.settings.icon.${name}`)}`}
  </>
);

const iconSelectOptions = iconNames.map((value) => ({
  value,
  label: getIcon(value),
}));

/**
 * context translations:
 * t('orderWidget.settings.icon.select.label.planned')
 * t('orderWidget.settings.icon.select.label.ready')
 * t('orderWidget.settings.icon.select.label.active')
 * t('orderWidget.settings.icon.select.label.paused')
 * t('orderWidget.settings.icon.select.label.paused')
 * t('orderWidget.settings.icon.select.label.finished')
 * t('orderWidget.settings.icon.select.label.aborted')
 * t('orderWidget.settings.icon.select.label.disabled')
 * t('orderWidget.settings.icon.select.label.queued')
 * t('orderWidget.settings.icon.select.label.scheduled')
 */
export const iconSelects: ConfigSetting[] = iconNames.map((name, defaultValue) => ({
  id: `icon.${name}`,
  key: `icon.${name}`,
  label: i18n.t<string>(`orderWidget.settings.icon.select.label.${name}`),
  type: FieldType.Select,
  defaultValue: iconNames[defaultValue],
  options: iconSelectOptions,
}));
