import ImageCockpitWidget from '../components/cockpit/shared/widgets/ImageCockpitWidget';
import DataTableWidget from '../components/cockpit/shared/widgets/DataTableWidget';
import EventDurationCockpitWidget from '../components/cockpit/shared/widgets/EventDurationCockpitWidget';
import KpiChartWidget from '../components/cockpit/shared/widgets/KpiChartWidget';
import KpiPerOrderCockpitWidget from '../components/cockpit/shared/widgets/KpiPerOrderCockpitWidget';
import KpiSpeedometerWidget from '../components/cockpit/shared/widgets/KpiSpeedometerWidget';
import InterruptionAnalysisWidget from '../components/cockpit/shared/widgets/InterruptionAnalysisWidget';
import TagInterruptionAnalysisWidget from '../components/cockpit/shared/widgets/TagInterruptionAnalysisWidget';
import MultipleTimeseriesCockpitWidget from '../components/cockpit/shared/widgets/MultipleTimeseriesCockpitWidget';
import SensorStatusDisplayWidget from '../components/cockpit/shared/widgets/SensorStatusDisplayWidget';
import OrderProgressWidget from '../components/cockpit/shared/widgets/OrderProgressWidget';
import TimeseriesCockpitWidget from '../components/cockpit/shared/widgets/TimeseriesCockpitWidget';
import appConfig from '../utils/appConfig';

// register all available cockpit widgets here, so they can be found by the widget configuration.
// NOTE: widget components need to respond to 'identifier' and 'defaultLayout'

// TimeseriesCockpitWidget deprecated
const CockpitWidgetRegistry = [
  ImageCockpitWidget,
  DataTableWidget,
  TimeseriesCockpitWidget,
  MultipleTimeseriesCockpitWidget,
  EventDurationCockpitWidget,
  KpiChartWidget,
  KpiPerOrderCockpitWidget,
  InterruptionAnalysisWidget,
  TagInterruptionAnalysisWidget,
];

if (appConfig.modules.enableShopfloorDashboard) {
  CockpitWidgetRegistry.push(
    OrderProgressWidget,
    KpiSpeedometerWidget,
    SensorStatusDisplayWidget
  );
}

export {CockpitWidgetRegistry};
