import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import styles from '../InspectionTaskPendingWidget.module.scss';
import Form from '../../shared/form/Form';
import Button from '../../shared/buttons/Button';
import BatchSelector from '../../shared/inputs/autocomplete/BatchSelector';
import {useMount} from '../../../hooks/useMount';
import {useStore} from '../../../hooks/useStore';

const OrderRelatedBatchSelector = ({form, isSelectorActive}) => {
  const {t} = useTranslation();
  const store = useStore();
  const [showSelector, setShowSelector] = useState(false);
  const [batchFormFieldIsLoading, setBatchFormFieldIsLoading] = useState(false);
  const [refreshBatchFormField, setRefreshBatchFormField] = useState(false);

  const setBatchFormFieldValue = (batchId) => {
    // the refreshBatchFormField flag is used to force the form to refresh the batchId field
    setRefreshBatchFormField(true);
    form.setFieldValue('batchId', batchId);
    setRefreshBatchFormField(false);
  };

  useMount(() => {
    const activeOperation = store.operationStore.active;
    if (showSelector && activeOperation) {
      // load batchId from the latest yield booked on the active operation
      // if no yield is booked, load the batchId from the order of the active operation
      setBatchFormFieldIsLoading(true);
      const operationId = activeOperation.id;
      store.yieldStore.loadByOperation(operationId, {})
        .then((yields) => {
          let batchId;
          if (yields.length > 0) {
            const latestYield = yields.reduce((a, b) => (a.recordedAt > b.recordedAt ? a : b));
            batchId = latestYield.batchId;
          } else {
            batchId = store.orderStore.getById(activeOperation.orderId)?.batchId;
          }
          return batchId ? store.batchStore.load(batchId, {}) : null;
        })
        .then((batch) => {
          setBatchFormFieldValue(batch ? batch.id : null);
        })
        .catch(() => {
          store.flashMessageStore.addFlashMessage({
            type: 'error',
            message: t('inspectionTaskPendingModal.taskBox.batchSelectorError'),
          });
          setBatchFormFieldValue(null);
        })
        .finally(() => {
          setBatchFormFieldIsLoading(false);
        });
    } else {
      setBatchFormFieldValue(null);
    }
  }, [showSelector]);

  if (!isSelectorActive) {
    return null;
  }

  return (
    <>
      {!showSelector && (
        <Button
          type={'link'}
          className={styles.primaryText}
          onClick={() => setShowSelector(true)}
          style={{padding: 0}}
        >
          {t('inspectionTaskPendingModal.taskBox.addBatchRelation')}
        </Button>
      )}
      <div style={{display: showSelector ? 'inline' : 'none'}}>
        <Form.Item
          name={'batchId'}
          labelCol={{span: 24}}
          wrapperCol={{span: 24}}
          label={t('inspectionTaskPendingModal.taskBox.batchSelector')}
          hidden={!showSelector}
          style={{marginBottom: 0}}
        >
          {!refreshBatchFormField && (
            <BatchSelector
              form={form}
              name={'batchId'}
              loading={batchFormFieldIsLoading}
            />
          )}
        </Form.Item>
        {showSelector && (
          <Button
            type={'link'}
            className={styles.redText}
            onClick={() => setShowSelector(false)}
            style={{display: 'inline', padding: 0}}
          >
            {t('inspectionTaskPendingModal.taskBox.deleteBatchRelation')}
          </Button>
        )}
      </div>
    </>
  );
};

export default OrderRelatedBatchSelector;
