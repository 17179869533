import {Button} from 'antd';
import {CheckSquareFilled} from '@ant-design/icons';

export const StopButton = ({buttonText, ...props}) => (
  <Button
    type="primary"
    danger
    block
    {...props}
  >
    <CheckSquareFilled/>
    {buttonText}
  </Button>
);
