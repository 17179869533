import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { CustomAction } from '../models/customAction';
import { RootStore } from './rootStore';

export class CustomActionStore extends EntityStore<CustomAction> {
  customActions: CustomAction[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'customActions', Api.customActions, CustomAction);

    makeObservable(this, {
      customActions: observable,
    });
  }
}
