import { RootStore } from '../../stores/rootStore';
import { Component } from '../../models/component';
import { Colorizing } from './DosageSliderComponent';
import colors from '../../assets/stylesheets/colors.module.scss';
import cSliderColors from './dosage/DosageWidgetStyles.module.scss';
import { ParentInformation } from './dosage/DosageTabs';
import { STORAGE_UNIT_SETUP_PREFIX } from '../../stores/storageUnitStore';

export enum ColorizingType {
  BLUE = 'blue',
  GREEN = 'green',
  RED = 'red'
}

export const getActualPreparation = (
  preparationId: number,
  component: Component,
  store: RootStore,
  isGetAverage: boolean = false,
  parentStorageUnitId?: number
): number => {
  if (isGetAverage && !parentStorageUnitId) {
    return 0;
  }

  const preparation = store.preparationStore.getById(preparationId);

  const unit = store.storageUnitStore.getById(
    isGetAverage
      ? parentStorageUnitId || null
      : preparation?.storageUnitId || null
  );

  let amount = 0;
  let count = 0;
  if (unit) {
    const children = store.storageUnitStore.getDescendantsById(unit.id);
    children.forEach((child) => {
      if (child.materialId === component.materialId) {
        count += child.contents.length;
        amount += child.contents.reduce((acc, content) => acc + content.amount, 0);
      }
    });
  }

  if (!isGetAverage) {
    return amount;
  }

  if (count <= 0 || amount <= 0) {
    return 0;
  }

  return amount / count;
};

export const getCalculatedAverageOfPreparationByAmount = (
  preparationId: number,
  component: Component,
  parentStorageUnitId: number,
  store: RootStore
): number => getActualPreparation(preparationId, component, store, true, parentStorageUnitId);

export const getColorizingObject = (type: ColorizingType): Colorizing => {
  switch (type) {
    case ColorizingType.BLUE:
      return {
        trackColor: cSliderColors.cSliderTrackColor,
        markColor: cSliderColors.cSliderMarkColorBlue,
        genericMarkColor: cSliderColors.cSliderGenericMarkColorBlue,
        markTextColor: colors.primaryTextColor,
        trackCurrentValueColor: cSliderColors.cSliderTrackCurrentValueColorBlue,
        trackNewValueColor: cSliderColors.cSliderTrackNewValueColorBlue,
      };
    case ColorizingType.GREEN:
      return {
        trackColor: cSliderColors.cSliderTrackColor,
        markColor: cSliderColors.cSliderMarkColorGreen,
        genericMarkColor: cSliderColors.cSliderGenericMarkColorGreen,
        markTextColor: colors.primaryTextColor,
        trackCurrentValueColor: cSliderColors.cSliderTrackCurrentValueColorGreen,
        trackNewValueColor: cSliderColors.cSliderTrackNewValueColorGreen,
      };
    case ColorizingType.RED:
      return {
        trackColor: cSliderColors.cSliderTrackColor,
        markColor: cSliderColors.cSliderMarkColorRed,
        genericMarkColor: cSliderColors.cSliderGenericMarkColorRed,
        markTextColor: colors.primaryTextColor,
        trackCurrentValueColor: cSliderColors.cSliderTrackCurrentValueColorRed,
        trackNewValueColor: cSliderColors.cSliderTrackNewValueColorRed,
      };
    default:
      return {
        trackColor: cSliderColors.cSliderTrackColor,
        markColor: cSliderColors.cSliderMarkColorBlue,
        genericMarkColor: cSliderColors.cSliderGenericMarkColorBlue,
        markTextColor: colors.primaryTextColor,
        trackCurrentValueColor: cSliderColors.cSliderTrackCurrentValueColorBlue,
        trackNewValueColor: cSliderColors.cSliderTrackNewValueColorBlue,
      };
  }
};

export const getBatchesNo = (
  preparationId: number,
  component: Component,
  store: RootStore
): string[] => {
  const batches: string[] = [];

  const preparation = store.preparationStore.getById(preparationId);
  if (!preparation) {
    return batches;
  }

  const preparationStorageUnit = store.storageUnitStore.getById(preparation.storageUnitId || null);
  if (!preparationStorageUnit) {
    return batches;
  }

  const children = store.storageUnitStore.getDescendantsById(preparationStorageUnit.id);
  children.forEach((child) => {
    if (child.materialId === component.materialId) {
      const { contents } = child;
      contents.forEach((content) => {
        if (!content.batchId) {
          return;
        }
        const batch = store.batchStore.getById(content.batchId);
        if (!batch) {
          return;
        }
        batches.push(batch.no);
      });
    }
  });

  return batches;
};

export const getParentInformationByComponent = (
  component: Component,
  store: RootStore
): ParentInformation => {
  const parentInformation: ParentInformation = {
    isParent: false,
    childrenAmount: 0,
    parentStorageUnitId: 0,
  };

  if (!component) {
    return parentInformation;
  }

  const noToFind: string = `${STORAGE_UNIT_SETUP_PREFIX}${component.operationId}_${component.materialId}`;

  const setupStorageUnit = store.storageUnitStore.storageUnits
    .find((unit) => unit.no === noToFind);

  if (!setupStorageUnit) {
    return parentInformation;
  }

  const sourceChildren = store.storageUnitStore.getDescendantsById(setupStorageUnit.id);

  if (!sourceChildren || !sourceChildren.length) {
    return parentInformation;
  }

  const sortedSourceChildren = sourceChildren.sort((a, b) =>
    (a?.setupOrder ?? Number.POSITIVE_INFINITY) - (b?.setupOrder ?? Number.POSITIVE_INFINITY));
  const unitChildren = store.storageUnitStore.getDescendantsById(sortedSourceChildren[0].id)
    .filter((unit) => unit.contents.length > 0);

  if (!unitChildren || !unitChildren.length) {
    return parentInformation;
  }

  parentInformation.isParent = true;
  parentInformation.parentStorageUnitId = sortedSourceChildren[0].id;
  parentInformation.childrenAmount = unitChildren.length;

  return parentInformation;
};
