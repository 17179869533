import { action, computed, makeObservable, observable } from 'mobx';
import { faHammer } from '@fortawesome/free-solid-svg-icons';
import dayjs, { Dayjs } from 'dayjs';
import { transformation } from '../utils/transformations';
import { dateFormat, datetimeFormat, durationFormat, timeFormat } from '../config/dayjs';
import { CustomPropertyTypes } from './customPropertyDataTypes';
import i18n from '../i18n/i18n';
import { DisplayableProperty, displayableProperty } from './displayableProperty';
import { displayablePropertyParam } from './displayablePropertyParam';
import { Material } from './material';
import { Order, OrderState } from './order';
import { OperationState } from './operationState';
import { round } from '../utils/number';
import { QuantityConversionModel } from './quantityConversion';
import { getTranslation } from '../utils/translations';
import { Workplace } from './workplace';
import appConfig from '../utils/appConfig';
import { SpeedDenominator } from './speed';
import { EnDash } from '../components/shared/unicodeWrapper/EnDash';
import { RootStore } from '../stores/rootStore';
import { CustomPropertiesObject } from './customProperty';
import { Translation } from './translations';
import { DisplayablePropertiesOptions } from './base';

const MAX_DECIMAL_PLACES = 3;

// @ts-ignore
const speedDenominator = SpeedDenominator[appConfig.speedDenominator];

export const OperationDefaultTabs = {
  planned: 'planned',
  finished: 'finished',
};

export const OperationDisplayStates = {
  none: 'none',
  active: 'active',
  planned: 'planned',
  ready: 'ready',
  paused: 'paused',
  finished: 'finished',
  aborted: 'aborted',
  disabled: 'disabled',
  queued: 'queued',
  scheduled: 'scheduled',
};

interface OperationTranslation extends Translation {
  name: string | null;
  info: string | null;
}

export class Operation extends QuantityConversionModel {
  id: number = 0;
  actualEnd: string | null = null;
  actualQuantity: number | null = null;
  actualScrap: number | null = null;
  actualStart: string | null = null;
  createdAt: string = '';
  createdBy: number = 0;
  disabled: boolean = false;
  materialId: number | null = null;
  no: string = '';
  orderId: number = 0;
  plannedDurationSeconds: number | null = null;
  plannedEnd: string | null = null;
  plannedQuantity: number | null = null;
  plannedScrap: number | null = null;
  plannedStart: string | null = null;
  actualSpeed: number | null = null;
  plannedSpeed: number | null = null;
  properties?: CustomPropertiesObject = undefined;
  translations: OperationTranslation[] = [];
  sortOrder: number | null = null;
  stateId: number = 0;
  unitOfMeasureId: number | null = null;
  updatedAt: string = '';
  updatedBy: number = 0;
  workplaceId: number = 0;
  usedDurationSeconds: number = 0;
  speedUnitOfMeasureId: number | null = null;
  isPlanned: boolean = false;
  isActive: boolean = false;
  isFinished: boolean = false;
  tmpSortOrder: number | null = null;
  isControlOperation: boolean = false;
  workflowId?: number | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      actualEnd: observable,
      actualQuantity: observable,
      actualScrap: observable,
      actualStart: observable,
      components: computed,
      createdAt: observable,
      createdBy: observable,
      disabled: observable,
      id: observable,
      info: computed,
      materialId: observable,
      material: computed,
      name: computed,
      label: computed,
      no: observable,
      orderId: observable,
      order: computed,
      plannedDurationSeconds: observable,
      plannedEnd: observable,
      plannedQuantity: observable,
      plannedScrap: observable,
      plannedStart: observable,
      actualSpeed: observable,
      plannedSpeed: observable,
      properties: observable,
      translations: observable,
      sortOrder: observable,
      stateId: observable,
      unitOfMeasureId: observable,
      unitOfMeasure: computed,
      updatedAt: observable,
      updatedBy: observable,
      workplaceId: observable,
      workplace: computed,
      displayStatus: computed,
      usedDurationSeconds: observable,
      operationPhases: computed,
      speedUnitOfMeasureId: observable,
      speedUnitOfMeasure: computed,
      overtimeSeconds: computed,
      availableSeconds: computed,
      usedDurationPercentage: computed,
      availablePercentage: computed,
      overtimePercentage: computed,
      recalculateUsedDurationSeconds: action,
      remainingQuantity: computed,
      isPlanned: observable,
      isActive: observable,
      isFinished: observable,
      tmpSortOrder: observable,
      expectedEnd: computed,
      isControlOperation: observable,
      isPlannedOrReady: computed,
      transitionQueue: computed,
      setIsActive: action,
      setIsPlanned: action,
      setIsFinished: action,
      activeTimeOperationState: computed,
    });
  }

  static faIcon = faHammer;

  saveableProperties = [
    'no', 'materialId', 'workplaceId', 'unitOfMeasureId', 'sortOrder', 'plannedQuantity', 'actualQuantity', 'orderId',
    'plannedStart', 'plannedEnd', 'plannedDurationSeconds', 'stateId', 'workflowId', 'actualStart', 'actualEnd',
    'plannedScrap', 'actualScrap', 'plannedSpeed', 'actualSpeed', 'speedUnitOfMeasureId', 'disabled',
    'isControlOperation', 'operationPhases', 'components', 'translations', 'properties',
  ];

  translatedProperties = ['name', 'info'];

  searchableProperties = ['material.no', 'material.name', 'order.no'];

  customPropertyType = CustomPropertyTypes.Operation;

  i18nPrefix = 'operation';

  reducedProperties = ['isFinished', 'isPlanned', 'isActive'];

  setIsActive(value: boolean) {
    this.isActive = value;
  }

  setIsPlanned(value: boolean) {
    this.isPlanned = value;
  }

  setIsFinished(value: boolean) {
    this.isFinished = value;
  }

  displayableProperties = [
    displayableProperty({
      key: 'no',
      title: i18n.t('operation.model.attributes.no'),
      params: [displayablePropertyParam({ path: 'no', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'name',
      title: i18n.t('operation.model.attributes.name'),
      params: [
        displayablePropertyParam({ path: 'name', transform: transformation.none }),
      ],
      template: '{value}',
    }),
    displayableProperty({
      key: 'label',
      title: i18n.t('operation.model.attributes.label'),
      params: [
        displayablePropertyParam({ path: 'label', transform: transformation.none }),
      ],
      template: '{value}',
    }),
    displayableProperty({
      key: 'info',
      title: i18n.t('operation.model.attributes.info'),
      params: [
        displayablePropertyParam({ path: 'info', transform: transformation.none }),
      ],
      template: '{value}',
    }),
    displayableProperty({
      key: 'plannedStartDatetime',
      title: i18n.t('operation.model.attributes.plannedStartDatetime'),
      params: [
        displayablePropertyParam({
          path: 'plannedStart',
          transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      renderText: (text, record) => record?.plannedStart || '',
    }),
    displayableProperty({
      key: 'plannedStartDate',
      title: i18n.t('operation.model.attributes.plannedStartDate'),
      params: [
        displayablePropertyParam({ path: 'plannedStart', transform: transformation.datetime({ format: dateFormat }) }),
      ],
    }),
    displayableProperty({
      key: 'plannedStartTime',
      title: i18n.t('operation.model.attributes.plannedStartTime'),
      params: [
        displayablePropertyParam({ path: 'plannedStart', transform: transformation.datetime({ format: timeFormat }) }),
      ],
    }),
    displayableProperty({
      key: 'plannedEndDatetime',
      title: i18n.t('operation.model.attributes.plannedEndDatetime'),
      params: [
        displayablePropertyParam({
          path: 'plannedEnd',
          transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      renderText: (text, record) => record?.plannedEnd || '',
    }),
    displayableProperty({
      key: 'plannedEndDate',
      title: i18n.t('operation.model.attributes.plannedEndDate'),
      params: [
        displayablePropertyParam({ path: 'plannedEnd', transform: transformation.datetime({ format: dateFormat }) }),
      ],
    }),
    displayableProperty({
      key: 'plannedEndTime',
      title: i18n.t('operation.model.attributes.plannedEndTime'),
      params: [
        displayablePropertyParam({ path: 'plannedEnd', transform: transformation.datetime({ format: timeFormat }) }),
      ],
    }),
    displayableProperty({
      key: 'actualStartDatetime',
      title: i18n.t('operation.model.attributes.actualStartDatetime'),
      params: [
        displayablePropertyParam({
          path: 'actualStart',
          transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      renderText: (text, record) => record?.actualStart || '',
    }),
    displayableProperty({
      key: 'actualStartDate',
      title: i18n.t('operation.model.attributes.actualStartDate'),
      params: [
        displayablePropertyParam({ path: 'actualStart', transform: transformation.datetime({ format: dateFormat }) }),
      ],
      template: '{value}',
    }),
    displayableProperty({
      key: 'actualStartTime',
      title: i18n.t('operation.model.attributes.actualStartTime'),
      params: [
        displayablePropertyParam({ path: 'actualStart', transform: transformation.datetime({ format: timeFormat }) }),
      ],
    }),
    displayableProperty({
      key: 'actualEndDatetime',
      title: i18n.t('operation.model.attributes.actualEndDatetime'),
      params: [
        displayablePropertyParam({ path: 'actualEnd', transform: transformation.datetime({ format: datetimeFormat }) }),
      ],
      template: '{value}',
      renderText: (text, record) => record?.actualEnd || '',
    }),
    displayableProperty({
      key: 'actualEndDate',
      title: i18n.t('operation.model.attributes.actualEndDate'),
      params: [
        displayablePropertyParam({ path: 'actualEnd', transform: transformation.datetime({ format: dateFormat }) }),
      ],
      template: '{value}',
    }),
    displayableProperty({
      key: 'actualEndTime',
      title: i18n.t('operation.model.attributes.actualEndTime'),
      params: [
        displayablePropertyParam({ path: 'actualEnd', transform: transformation.datetime({ format: timeFormat }) }),
      ],
    }),
    displayableProperty({
      key: 'plannedDurationSeconds',
      title: i18n.t('operation.model.attributes.plannedDurationSeconds'),
      params: [
        displayablePropertyParam({ path: 'plannedDurationSeconds', transform: transformation.duration() }),
      ],
      template: '{value}',
    }),
    displayableProperty({
      key: 'plannedQuantity',
      title: i18n.t('operation.model.attributes.plannedQuantity'),
      params: [
        displayablePropertyParam({ path: 'plannedQuantity', transform: transformation.none, as: 'plannedQuantity' }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{plannedQuantity} {unitOfMeasure}',
    }),
    displayableProperty({
      key: 'actualQuantity',
      title: i18n.t('operation.model.attributes.actualQuantity'),
      params: [
        displayablePropertyParam({ path: 'actualQuantity', transform: transformation.none, as: 'actualQuantity' }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{actualQuantity} {unitOfMeasure}',
    }),
    displayableProperty({
      key: 'plannedScrap',
      title: i18n.t('operation.model.attributes.plannedScrap'),
      params: [
        displayablePropertyParam({ path: 'plannedScrap', transform: transformation.none, as: 'plannedScrap' }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{plannedScrap} {unitOfMeasure}',
    }),
    displayableProperty({
      key: 'actualScrap',
      title: i18n.t('operation.model.attributes.actualScrap'),
      params: [
        displayablePropertyParam({ path: 'actualScrap', transform: transformation.none, as: 'actualScrap' }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{actualScrap} {unitOfMeasure}',
    }),
    displayableProperty({
      key: 'plannedSpeed',
      title: i18n.t('operation.model.attributes.plannedSpeed'),
      params: [
        displayablePropertyParam({ path: 'plannedSpeed', transform: transformation.none }),
      ],
      template: '{value}',
    }),
    displayableProperty({
      key: 'quantityIsShould',
      title: i18n.t('operation.model.attributes.quantityIsShould'),
      params: [
        displayablePropertyParam({
          path: 'actualQuantity',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'actualQuantity',
        }),
        displayablePropertyParam({
          path: 'plannedQuantity',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'plannedQuantity',
        }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{actualQuantity} / {plannedQuantity} {unitOfMeasure}',
    }),
    displayableProperty({
      key: 'scrapIsShould',
      title: i18n.t('operation.model.attributes.scrapIsShould'),
      params: [
        displayablePropertyParam({
          path: 'actualScrap',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'actualScrap',
        }),
        displayablePropertyParam({
          path: 'plannedScrap',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'plannedScrap',
        }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{actualScrap} / {plannedScrap} {unitOfMeasure}',
    }),
    displayableProperty({
      key: 'status',
      title: i18n.t('operation.model.attributes.status'),
      params: [displayablePropertyParam({ path: 'displayStatus' })],
      raw: true,
    }),
    displayableProperty({
      key: 'durationIsShould',
      title: i18n.t('operation.model.attributes.durationIsShould'),
      params: [
        displayablePropertyParam({ path: 'plannedDurationSeconds',
          transform: transformation.duration(),
          as: 'should' }),
        displayablePropertyParam({
          path: 'usedDurationSeconds',
          transform: transformation.duration(),
          as: 'is',
        }),
      ],
      template: '{is} / {should}',
    }),
    displayableProperty({
      key: 'speedIsShould',
      title: i18n.t('operation.model.attributes.speedIsShould'),
      params: [
        displayablePropertyParam({
          path: 'plannedSpeed',
          transform: transformation.fallback(),
          as: 'should',
        }),
        displayablePropertyParam({
          path: 'actualSpeed',
          transform: transformation.fallback(),
          as: 'is',
        }),
      ],
      template: '{is} / {should}',
    }),
    displayableProperty({
      key: 'expectedDurationActualSpeed',
      title: i18n.t('operation.model.attributes.expectedDurationActualSpeed'),
      raw: true,
      params: [
        displayablePropertyParam({
          path: '.',
          transform: (o) => {
            const expectedDuration = Operation.getExpectedDurationActualSpeed(o);
            return expectedDuration !== 0
              ? Operation.transformDuration(expectedDuration)
              : EnDash();
          },
          as: 'expectedDurationActualSpeed',
        }),
      ],
    }),
    displayableProperty({
      key: 'expectedDurationPlannedSpeed',
      title: i18n.t('operation.model.attributes.expectedDurationPlannedSpeed'),
      raw: true,
      params: [
        displayablePropertyParam({
          path: '.',
          transform: (o) => {
            const expectedDuration = Operation.getExpectedDurationPlannedSpeed(o);
            return expectedDuration !== 0
              ? Operation.transformDuration(expectedDuration)
              : EnDash();
          },
          as: 'expectedDurationPlannedSpeed',
        }),
      ],
    }),
    displayableProperty({
      key: 'expectedEndPlannedSpeed',
      title: i18n.t('operation.model.attributes.expectedEndPlannedSpeed'),
      raw: true,
      params: [
        displayablePropertyParam({
          path: '.',
          transform: (o) => {
            const expectedDuration = Operation.getExpectedDurationPlannedSpeed(o);
            return expectedDuration !== 0
              ? transformation.datetime({ format: datetimeFormat })(Operation.getExpectedEndPlannedSpeed(o))
              : EnDash();
          },
          as: 'expectedEndPlannedSpeed',
        }),
      ],
    }),
    displayableProperty({
      key: 'expectedEndActualSpeed',
      title: i18n.t('operation.model.attributes.expectedEndActualSpeed'),
      raw: true,
      params: [
        displayablePropertyParam({
          path: '.',
          transform: (o) => {
            const expectedDuration = Operation.getExpectedDurationActualSpeed(o);
            return expectedDuration !== 0
              ? transformation.datetime({ format: datetimeFormat })(Operation.getExpectedEndActualSpeed(o))
              : EnDash();
          },
          as: 'expectedEndActualSpeed',
        }),
      ],
    }),
    displayableProperty({
      key: 'delayPlannedSpeed',
      title: i18n.t('operation.model.attributes.delayPlannedSpeed'),
      raw: true,
      params: [
        displayablePropertyParam({
          path: '.',
          transform: (o) => {
            const expectedDelay = Operation.getExpectedDelayPlannedSpeed(o);
            return expectedDelay !== null
              ? transformation.duration({ format: durationFormat })(expectedDelay)
              : EnDash();
          },
          as: 'delayPlannedSpeed',
        }),
      ],
    }),
    displayableProperty({
      key: 'delayActualSpeed',
      title: i18n.t('operation.model.attributes.delayActualSpeed'),
      raw: true,
      params: [
        displayablePropertyParam({
          path: '.',
          transform: (o) => {
            const expectedDelay = Operation.getExpectedDelayActualSpeed(o);
            return expectedDelay !== null
              ? transformation.duration({ format: durationFormat })(expectedDelay)
              : EnDash();
          },
          as: 'delayActualSpeed',
        }),
      ],
    }),
    displayableProperty({
      key: 'activeTimeOperationState',
      title: i18n.t('operation.model.attributes.activeTime'),
      params: [
        displayablePropertyParam({
          path: 'activeTimeOperationState',
          transform: transformation.duration({ format: durationFormat, unit: 'milliseconds' }),
        }),
      ],
      template: '{value}',
    }),
  ];

  static allDisplayableProperties(
    rootStore: RootStore,
    pathPrefix = '',
    titlePrefix = '',
    options: DisplayablePropertiesOptions = { includeConversions: false }
  ) {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix, options);
    const addAdditionalFields = (insertAfterKey: string, fields: DisplayableProperty[]) => {
      allDisplayableProperties.splice(
        allDisplayableProperties.findIndex((p) => p.key === insertAfterKey) + 1,
        0,
        ...fields
      );
    };

    allDisplayableProperties.push(
      ...this.displayableCustomProperties(rootStore, pathPrefix, titlePrefix)
    );

    allDisplayableProperties.push(
      ...Material.allDisplayableProperties(rootStore, `${pathPrefix}material.`, titlePrefix)
    );

    const orderPrefix = `${titlePrefix}${i18n.t('order.model.one')} > `;
    allDisplayableProperties.push(
      ...Order.allDisplayableProperties(rootStore, `${pathPrefix}order.`, orderPrefix, options)
    );

    const operationStatePrefix = `${titlePrefix}${i18n.t('operationState.model.one')} > `;
    allDisplayableProperties.push(
      ...OperationState.allDisplayableProperties(rootStore, `${pathPrefix}state.`, operationStatePrefix, options)
    );

    const workplacePrefix = `${titlePrefix}${i18n.t('workplace.model.one')} > `;
    allDisplayableProperties.push(
      ...Workplace.allDisplayableProperties(rootStore, `${pathPrefix}workplace.`, workplacePrefix)
    );

    if (options.includeConversions) {
      addAdditionalFields(
        `${pathPrefix}actualQuantity`,
        this.getSimpleQuantityFieldProperties('actualQuantity', 'orderId', rootStore, pathPrefix, titlePrefix)
      );
      addAdditionalFields(
        `${pathPrefix}plannedQuantity`,
        this.getSimpleQuantityFieldProperties('plannedQuantity', 'orderId', rootStore, pathPrefix, titlePrefix)
      );
      addAdditionalFields(
        `${pathPrefix}quantityIsShould`,
        this.getIsShouldQuantityFieldProperties('quantity', 'orderId', rootStore, pathPrefix, titlePrefix)
      );
      addAdditionalFields(
        `${pathPrefix}actualScrap`,
        this.getSimpleQuantityFieldProperties('actualScrap', 'orderId', rootStore, pathPrefix, titlePrefix)
      );
      addAdditionalFields(
        `${pathPrefix}plannedScrap`,
        this.getSimpleQuantityFieldProperties('plannedScrap', 'orderId', rootStore, pathPrefix, titlePrefix)
      );
      addAdditionalFields(
        `${pathPrefix}scrapIsShould`,
        this.getIsShouldQuantityFieldProperties('scrap', 'orderId', rootStore, pathPrefix, titlePrefix)
      );
    }

    return allDisplayableProperties;
  }

  static transformDuration = (duration: number) => {
    switch (speedDenominator) {
      case SpeedDenominator.DAYS:
        return transformation.duration({ format: durationFormat, unit: 'days' })(duration);
      case SpeedDenominator.HOURS:
        return transformation.duration({ format: durationFormat, unit: 'hours' })(duration);
      case SpeedDenominator.MINUTES:
        return transformation.duration({ format: durationFormat, unit: 'minutes' })(duration);
      case SpeedDenominator.SECONDS:
        return transformation.duration({ format: durationFormat, unit: 'seconds' })(duration);
      default:
        return transformation.duration({ format: durationFormat, unit: 'seconds' })(duration);
    }
  };

  static getExpectedDurationActualSpeed = (operation: Operation): number => {
    if (operation.actualSpeed === null || operation.actualSpeed === 0) {
      return 0;
    }
    const actualQuantity = operation.plannedQuantity !== null ? operation.actualQuantity
      : operation.order?.actualQuantity;
    const plannedQuantity = operation.plannedQuantity ? operation.plannedQuantity
      : operation.order?.plannedQuantity;
    return ((plannedQuantity || 0) - (actualQuantity || 0)) / operation.actualSpeed;
  };

  static getExpectedDurationPlannedSpeed = (operation: Operation): number => {
    if (operation.plannedSpeed === null || operation.plannedSpeed === 0) {
      return 0;
    }
    const actualQuantity = operation.plannedQuantity !== null ? operation.actualQuantity
      : operation.order?.actualQuantity;
    const plannedQuantity = operation.plannedQuantity ? operation.plannedQuantity
      : operation.order?.plannedQuantity;
    return ((plannedQuantity || 0) - (actualQuantity || 0)) / operation.plannedSpeed;
  };

  static calculateEnd = (start: Dayjs, duration: number): Dayjs => {
    switch (speedDenominator) {
      case SpeedDenominator.DAYS:
        return start.add(duration, 'days');
      case SpeedDenominator.HOURS:
        return start.add(duration, 'hours');
      case SpeedDenominator.MINUTES:
        return start.add(duration, 'minutes');
      default:
        return start.add(duration, 'seconds');
    }
  };

  static getExpectedEndActualSpeed = (operation: Operation): Dayjs | null => {
    const expectedDuration = this.getExpectedDurationActualSpeed(operation);
    if (expectedDuration === 0) {
      return null;
    }
    const start = dayjs(operation.actualStart) ? dayjs() : dayjs(operation.plannedStart);
    return this.calculateEnd(start, expectedDuration);
  };

  static getExpectedEndPlannedSpeed = (operation: Operation): Dayjs | null => {
    const expectedDuration = this.getExpectedDurationPlannedSpeed(operation);
    if (expectedDuration === 0) {
      return null;
    }
    const start = dayjs(operation.actualStart) ? dayjs() : dayjs(operation.plannedStart);
    return this.calculateEnd(start, expectedDuration);
  };

  static getExpectedDelayActualSpeed = (operation: Operation): number | null => {
    const expectedEnd = Operation.getExpectedEndActualSpeed(operation);
    if (expectedEnd === null || operation.plannedEnd === null) {
      return null;
    }
    return expectedEnd.diff(dayjs(operation.plannedEnd), 'seconds');
  };

  static getExpectedDelayPlannedSpeed = (operation: Operation): number | null => {
    const expectedEnd = Operation.getExpectedEndPlannedSpeed(operation);
    if (expectedEnd === null || operation.plannedEnd === null) {
      return null;
    }
    return expectedEnd.diff(dayjs(operation.plannedEnd), 'seconds');
  };

  populateAttributesFromStore(rootStore: RootStore) {
    super.populateAttributesFromStore(rootStore);

    this.tmpSortOrder = this.sortOrder;

    this.recalculateUsedDurationSeconds(rootStore);
  }

  recalculateUsedDurationSeconds(rootStore: RootStore) {
    const endedStateLogs = rootStore.operationStateLogStore.logs.filter(
      (log) => log.operationId === this.id && log.end
    );
    const notEndedStateLogsByOperationId = rootStore.operationStateLogStore.logs.find(
      (log) => log.operationId === this.id && !log.end
    );

    if (!notEndedStateLogsByOperationId) {
      this.usedDurationSeconds = 0;
      return;
    }

    const endedSeconds = endedStateLogs.reduce(
      (acc, current) => (current.durationSeconds ? acc + current.durationSeconds.asSeconds() : acc),
      0
    );
    const activeSeconds = (
      notEndedStateLogsByOperationId
        ? dayjs.duration(dayjs().diff(dayjs(notEndedStateLogsByOperationId.start), 'seconds'), 'seconds').asSeconds()
        : 0
    );

    this.usedDurationSeconds = endedSeconds + activeSeconds;
  }

  get displayStatus() {
    if ((!this.rootStore.operationStore.active && this.isActive)
      || this.id === this.rootStore.operationStore.active?.id) {
      return OperationDisplayStates.active;
    }
    if (this.disabled) {
      return OperationDisplayStates.disabled;
    }
    if (this.transitionQueue && this.transitionQueue.plannedExecution) {
      return OperationDisplayStates.scheduled;
    }
    if (this.transitionQueue) {
      return OperationDisplayStates.queued;
    }
    if (this.state?.isAbort) {
      return OperationDisplayStates.aborted;
    }
    if (this.state?.isFinal) {
      return OperationDisplayStates.finished;
    }
    if (this.state?.isInitial && this.actualStart !== null) {
      return OperationDisplayStates.paused;
    }
    if (!this.state?.isFinal && this.order?.state === OrderState.PLANNED) {
      return OperationDisplayStates.planned;
    }
    if (!this.state?.isFinal) {
      return OperationDisplayStates.ready;
    }
    return OperationDisplayStates.none;
  }

  get components() {
    return this.rootStore.componentStore.components
      .filter((component) => component.operationId === this.id);
  }

  get operationPhases() {
    return this.rootStore.operationPhaseStore.operationPhases
      .filter((phase) => phase.operationId === this.id);
  }

  get operationState() {
    return this.rootStore.operationStateStore.getById(this.stateId);
  }

  get order() {
    return this.rootStore.orderStore.getById(this.orderId);
  }

  get workplace() {
    return this.rootStore.workplaceStore.getById(this.workplaceId);
  }

  get unitOfMeasure() {
    return this.rootStore.unitOfMeasurementStore.getById(this.unitOfMeasureId);
  }

  get material() {
    return this.rootStore.materialStore.getById(this.materialId);
  }

  get speedUnitOfMeasure() {
    return this.rootStore.unitOfMeasurementStore.getById(this.speedUnitOfMeasureId);
  }

  get state() {
    return this.rootStore.operationStateStore.getById(this.stateId);
  }

  get overtimeSeconds() {
    return (this.usedDurationSeconds - (this.plannedDurationSeconds || 0)) < 0
      ? 0
      : (this.usedDurationSeconds - (this.plannedDurationSeconds || 0));
  }

  get availableSeconds() {
    return (this.plannedDurationSeconds || 0) - this.usedDurationSeconds;
  }

  get usedDurationPercentage() {
    return this.overtimeSeconds <= 0
      ? (1 / (this.plannedDurationSeconds || 0)) * this.usedDurationSeconds
      : (1 / ((this.plannedDurationSeconds || 0) + this.overtimeSeconds)) * (this.plannedDurationSeconds || 0);
  }

  get availablePercentage() {
    return this.overtimeSeconds <= 0
      ? 1 - this.usedDurationPercentage
      : 0;
  }

  get overtimePercentage() {
    return this.overtimeSeconds <= 0
      ? 0
      : 1 - this.usedDurationPercentage;
  }

  get remainingQuantity() {
    return round((this.plannedQuantity || 0) - (this.actualQuantity || 0), MAX_DECIMAL_PLACES);
  }

  get expectedEnd() {
    if (!this.actualStart || !this.plannedDurationSeconds) {
      return undefined;
    }

    return dayjs(this.actualStart).add(this.plannedDurationSeconds, 'seconds');
  }

  get name() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.name;
  }

  get info() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.info;
  }

  get label() {
    return `${this.no} - ${this.name}`;
  }

  get isPlannedOrReady() {
    return this.displayStatus === OperationDisplayStates.planned
      || this.displayStatus === OperationDisplayStates.ready;
  }

  get transitionQueue() {
    return this.rootStore.transitionQueueStore.getByOperationId(this.id);
  }

  get activeTimeOperationState() {
    const operationStateLog = this.rootStore.operationStateLogStore.getLatestOfWorkplace(this.workplaceId);
    return operationStateLog?.start ? dayjs().diff(dayjs(operationStateLog.start)) : 0;
  }
}
