import React from 'react';
import {observer} from 'mobx-react-lite';
import InventoryWidgetSlot from './InventoryWidgetSlot';
import styles from './InventoryWidgetGauge.module.scss';
import {useStore} from '../../../hooks/useStore';

const InventoryWidgetLayout = ({
  loading,
  layout,
  inventorySlots,
}) => {
  const store = useStore();
  const directions = {
    slot: {
      vertical: 'column',
      horizontal: 'row',
      square: 'column',
    },
    group: {
      vertical: 'column',
      horizontal: 'row',
      square: 'row',
    },
  };

  let rowHeight = 100;
  if (layout === 'vertical' || (layout === 'square' && inventorySlots?.[2])) {
    rowHeight = 80;
  }

  return (
    <div className={styles.inventoryWidget} style={{flexDirection: directions.slot[layout]}}>
      <div style={{display: 'flex', minHeight: `${rowHeight}%`, flexDirection: directions.group[layout]}}>
        { inventorySlots?.[0] && (
          <InventoryWidgetSlot
            storageUnit={store.storageUnitStore?.getStorageUnitBySlot(inventorySlots?.[0])}
            title={inventorySlots?.[0]?.title}
            loading={loading}
            layout={layout}
            slotData={inventorySlots?.[0]}
          />
        )}
        { inventorySlots?.[1] && (
          <InventoryWidgetSlot
            storageUnit={store.storageUnitStore?.getStorageUnitBySlot(inventorySlots?.[1])}
            title={inventorySlots?.[1]?.title}
            loading={loading}
            layout={layout}
            slotData={inventorySlots?.[1]}
          />
        )}
      </div>
      {(inventorySlots?.[2] || inventorySlots?.[3]) && (
        <div style={{display: 'flex', minHeight: `${rowHeight}%`, flexDirection: directions.group[layout]}}>
          { inventorySlots?.[2] && (
            <InventoryWidgetSlot
              storageUnit={store.storageUnitStore?.getStorageUnitBySlot(inventorySlots?.[2])}
              title={inventorySlots?.[2]?.title}
              loading={loading}
              layout={layout}
              slotData={inventorySlots?.[2]}
            />
          )}
          { inventorySlots?.[3] && (
            <InventoryWidgetSlot
              storageUnit={store.storageUnitStore?.getStorageUnitBySlot(inventorySlots?.[3])}
              title={inventorySlots?.[3]?.title}
              loading={loading}
              layout={layout}
              slotData={inventorySlots?.[3]}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default observer(InventoryWidgetLayout);
