import {executePost} from '../requests';

const resourcesName = 'v1/config';

export const ConfigTransferApi = {
  export: (selection = {}) => executePost(`${resourcesName}/export`, selection),
  importPreview: ({
    selection = {},
    content,
    delete: deleteFlag = false,
  }) => executePost(`${resourcesName}/import-preview`, {
    selection,
    content,
    delete: deleteFlag,
  }),
  dependencyCheck: ({ selection = {} }) => executePost(`${resourcesName}/dependency-check`, {
    selection,
  }),
  import: ({
    selection = {},
    content,
    delete: deleteFlag = false,
  }) => executePost(`${resourcesName}/import`, {
    selection,
    content,
    delete: deleteFlag,
  }),
};
