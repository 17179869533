import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import Alert from 'antd/es/alert';
import {useStore} from '../../../../hooks/useStore';
import {useMount} from '../../../../hooks/useMount';
import {KpiChartCockpitWidgetConfig} from './config/kpiChartCockpitWidgetConfig';
import CockpitWidget from './CockpitWidget';
import {WidgetLayout} from '../../../../models/widgetLayout';
import {WidgetLayouts} from '../../../shared/widgets/WidgetLayouts';
import KpiChart from './KpiChart';
import useWorkplaceRelatedWidgetTitle from '../../../../hooks/useWorkplaceRelatedWidgetTitle';
import {getFromToResolutionByOptions} from './config/timeframeAggregationOptions';

const KpiCockpitWidget = ({
  widgetId,
  title,
  ...props
}) => {
  const store = useStore();
  const [selectedWorkplaceId, setSelectedWorkplaceId] = useState(null);
  const [unitOfMeasurement, setUnitOfMeasurement] = useState();
  const [isMultipleUnitOfMeasureSelected, setIsMultipleUnitOfMeasureSelected] = useState(false);
  const {t} = useTranslation();
  const widgetConfig = useMemo(() => new KpiCockpitWidget.Config({
    rootStore: store,
    identifier: KpiCockpitWidget.identifier,
    widgetId,
  }), [store.keyPerformanceIndicatorStore.kpis.length]);
  const widgetTitle = useWorkplaceRelatedWidgetTitle(title, selectedWorkplaceId);

  const timeframe = widgetConfig.getSettingValue('kpi', 'timeframe');
  const aggregation = widgetConfig.getSettingValue('kpi', 'aggregation');
  const daysBack = widgetConfig.getSettingValue('kpi', 'daysBack');
  const kpiSelection = widgetConfig.getSettingValue('kpi', 'kpis');
  const yAxisMax = widgetConfig.getSettingValue('kpi', 'yAxisMax');
  const yAxisTitle = widgetConfig.getSettingValue('kpi', 'yAxisTitle');
  const showPercent = widgetConfig.getSettingValue('kpi', 'showPercent');
  const legendPosition = widgetConfig.getSettingValue('kpi', 'legendPosition');

  const kpiIds = kpiSelection.map((kpis) => kpis.split(':')[1]);
  const {from, to, resolution} = getFromToResolutionByOptions(timeframe, aggregation, daysBack);

  useMount(() => {
    setSelectedWorkplaceId(
      store.workplaceStore.getByHierarchyId(widgetConfig.getSettingValue('workplace', 'hierarchyId'))?.id
      || store.cockpitStore.currentWorkplaceId || undefined
    );
  }, [
    store.cockpitStore.currentWorkplaceId,
    widgetConfig.getSettingValue('workplace', 'hierarchyId'),
  ]);

  useMount(() => {
    (async () => {
      const selectedUnitOfMeasures = kpiSelection
        ?.map((kpis) => kpis.split(':')[1])
        .reduce((acc, curr) => {
          const uomId = store.keyPerformanceIndicatorStore.getById(curr)?.unitOfMeasureId || -1;
          if (!acc.includes(uomId)) {
            acc.push(uomId);
            return acc;
          }

          return acc;
        }, [])
        .filter((kpi) => kpi > -1);

      if (selectedUnitOfMeasures?.length) {
        const uomId = selectedUnitOfMeasures[0];
        const uom = store.unitOfMeasurementStore.getById(uomId);
        setUnitOfMeasurement(uom);
        setIsMultipleUnitOfMeasureSelected(selectedUnitOfMeasures.length > 1);
      }
    })();
  }, [kpiSelection]);

  return (
    <CockpitWidget
      widgetId={widgetId}
      title={widgetTitle}
      widgetConfig={widgetConfig}
      {...props}
    >
      {
        isMultipleUnitOfMeasureSelected
          ? <Alert type={'info'} message={t('kpiChartCockpitWidget.alert.multipleUnitOfMeasuresSelected')}/>
          : ''
      }
      {
        !selectedWorkplaceId
          ? <Alert type={'info'} message={t('kpiChartCockpitWidget.alert.noWorkplaceSelected')}/>
          : ''
      }
      {
        selectedWorkplaceId && !isMultipleUnitOfMeasureSelected
          ? (
            <KpiChart
              workplaceId={selectedWorkplaceId}
              kpiIds={kpiIds}
              resolution={resolution}
              aggregation={aggregation}
              fromDate={from}
              toDate={to}
              unitOfMeasurement={unitOfMeasurement}
              yAxisMax={yAxisMax}
              yAxisTitle={yAxisTitle}
              showPercent={showPercent}
              legendPosition={legendPosition}
            />
          )
          : ''
      }
    </CockpitWidget>
  );
};

KpiCockpitWidget.Config = KpiChartCockpitWidgetConfig;
KpiCockpitWidget.defaultLayout = new WidgetLayout({
  identifier: CockpitWidget.identifier,
  width: 4,
  height: 16,
  minHeight: 4,
  minWidth: WidgetLayouts.sixthWidth.minW,
});
export default observer(KpiCockpitWidget);
