import { observable, makeObservable, computed } from 'mobx';
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

export class LdapGroup extends BaseModel {
  id: number = 0;
  label: string = '';
  assignment: string = '';
  roleIds: number[] = [];
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      label: observable,
      assignment: observable,
      roleIds: observable,
      roles: computed,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faNetworkWired;

  searchableProperties = ['label', 'assignment'];

  get roles() {
    return this.rootStore.roleStore.getByIds(this.roleIds);
  }

  static prepareApiPayload(model: LdapGroup) {
    return {
      ...model,
      roleIds: model.roleIds || [],
    };
  }
}
