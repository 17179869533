import {createEntity, executeDelete, executeGet, executePatch, executePost, updateEntity} from '../requests';

const resourcesName = 'v1/inspection-tasks';
const activate = 'activate';
const deactivate = 'deactivate';
export const updateQuantitativeTargetValuesEndpoint = 'updateQuantitativeTargetValuesEndpoint';
const updateQuantitativeTargetValuesResource = 'quantitative';
export const updateQualitativeTargetValuesEndpoint = 'updateQualitativeTargetValuesEndpoint';
const updateQualitativeTargetValuesResource = 'qualitative';
export const searchEndpoint = 'v1/search-inspection-tasks';

export const InspectionTasksApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  activate: (id) => executePost(`${resourcesName}/${id}/${activate}`, {}),
  deactivate: (id, remark) => executePost(`${resourcesName}/${id}/${deactivate}`, {remark}),
  [updateQuantitativeTargetValuesEndpoint]: ({id, ...params}) => executePatch(
    `${resourcesName}/${id}/${updateQuantitativeTargetValuesResource}`,
    params
  ),
  [updateQualitativeTargetValuesEndpoint]: ({id, ...params}) => executePatch(
    `${resourcesName}/${id}/${updateQualitativeTargetValuesResource}`,
    params
  ),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...values}) => updateEntity(values, `${resourcesName}/${id}`),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
  search: (params) => executeGet(`${searchEndpoint}`, {params}),
};
