import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { StorageUnitLevel } from '../models/storageUnitLevel';
import { RootStore } from './rootStore';

export class StorageUnitLevelStore extends EntityStore<StorageUnitLevel> {
  storageUnitLevels: StorageUnitLevel[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'storageUnitLevels', Api.storageUnitLevels, StorageUnitLevel);

    makeObservable(this, {
      storageUnitLevels: observable,
    });
  }

  loadByStorageUnitId(storageUnitId: number) {
    return this.loadAll({ params: { storageUnitId } });
  }

  getByStorageUnitId(storageUnitId: number) {
    return this.storageUnitLevels.filter((level) => level.storageUnitId === storageUnitId);
  }
}
