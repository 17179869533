import { computed, observable, makeObservable } from 'mobx';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { Workflow } from '../models/workflow';
import { sortAlphabetically } from '../components/shared/tables/sorters';
import { RootStore } from './rootStore';

export class WorkflowStore extends EntityStore {
  workflows: Workflow[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'workflows', Api.workflows, Workflow);

    makeObservable(this, {
      workflows: observable,
      orderedAlphabeticallyByLabel: computed,
    });
  }

  get orderedAlphabeticallyByLabel() {
    return this.workflows.slice().sort((a, b) => sortAlphabetically(a.label, b.label));
  }
}
