import {createEntity, executeDelete, executeGet, executePost, updateEntity} from '../requests';

const resourcesName = 'v1/printers';

export const printEndpoint = 'print';

export const PrintersApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...values}) => updateEntity(values, `${resourcesName}/${id}`),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
  print: (id, data) => executePost(`${resourcesName}/${id}/${printEndpoint}`, data),
};
