import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../../hooks/useStore';
import Modal from '../../shared/modal/Modal';
import InspectionLotDeactivationForm from './InspectionLotDeactivationForm';

const InspectionLotDeactivationModal = ({
  inspectionLotId,
  open,
  onCancel,
  afterDeactivate = () => undefined,
}) => {
  const store = useStore();
  const {t} = useTranslation();

  return (
    <Modal
      title={t('inspectionLot.deactivateLot')}
      open={open}
      onCancel={onCancel}
      style={{minWidth: 600}}
      footer={false}
      fullscreen={store.clientStore.isMobile}
    >
      <InspectionLotDeactivationForm
        inspectionLotId={inspectionLotId}
        onCancel={onCancel}
        afterDeactivate={afterDeactivate}
      />
    </Modal>
  );
};

export default observer(InspectionLotDeactivationModal);
