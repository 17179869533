import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { OperationPhase } from '../models/operationPhase';
import { RootStore } from './rootStore';

export class OperationPhaseStore extends EntityStore<OperationPhase> {
  operationPhases: OperationPhase[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'operationPhases', Api.operationPhases, OperationPhase);

    makeObservable(this, {
      operationPhases: observable,
    });
  }

  loadByOperation(operationId: OperationPhase['operationId']) {
    return this.loadAll({ params: { operationId } });
  }

  loadByOperationIds(operationIds: number[]) {
    return this.loadAll({ params: { operationId: operationIds } });
  }

  getByOperationId(operationId: OperationPhase['operationId']) {
    return this.operationPhases.filter((ph) => ph.operationId === operationId);
  }

  getActiveByOperationIdAndPhaseId(operationId: OperationPhase['operationId'], phaseId: OperationPhase['id']) {
    return this.operationPhases.find((ph) => ph.operationId === operationId && ph.phaseId === phaseId);
  }
}
