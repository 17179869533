import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import Button from '../shared/buttons/Button';
import styles from './ShowDocumentNewTab.module.scss';

const ShowDocumentNewTab = ({document}) => {
  const {t} = useTranslation();
  return (
    <div className={styles.centeredContainer}>
      <Button
        type={'primary'}
        href={document.link}
        target={'_blank'}
        rel={'noopener noreferrer'}
      >
        {t('document.show.newTab')}
      </Button>
    </div>
  );
};

export default observer(ShowDocumentNewTab);
