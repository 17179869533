import Widget from '../../../shared/widgets/Widget';
import {getCockpitWidgetIcon} from './config/cockpitWidgetIconConfig';
import {useStore} from '../../../../hooks/useStore';
import OperatorWidget from '../../../operator/shared/OperatorWidget';
import CockpitViewerWidget from '../../../cockpitViewer/CockpitViewerWidget';

const CockpitWidget = ({
  widgetId,
  title,
  widgetConfig,
  children,
  isEditMode,
  onSettingsChange,
  isOperatorWidget,
  operatorWidgetConfig,
  ...props
}) => {
  const store = useStore();

  if (isOperatorWidget) {
    return (
      <OperatorWidget
        {...props}
        icon={getCockpitWidgetIcon(widgetConfig.getSettingValue('generalSettings', 'icon'), false)}
        title={title}
        widgetConfig={operatorWidgetConfig}
        isPinable
        identifier={CockpitViewerWidget.identifier}
        minimized={store.terminalLayoutStore.layout
          .find((widget) => widget.i === CockpitViewerWidget.identifier)?.minimized}
      >
        {children}
      </OperatorWidget>
    );
  }

  const createOrUpdateWidgetConfig = (newWidgetConfig) => {
    store.cockpitWidgetStore.createOrUpdateWidgetConfig(widgetId, newWidgetConfig).then(() => {
      if (onSettingsChange) {
        onSettingsChange(newWidgetConfig);
      }
    });
  };

  const onDeleteWidget = () => {
    store.cockpitWidgetStore.delete(widgetId);
  };

  return (
    <Widget
      title={title}
      actionsLeft={getCockpitWidgetIcon(widgetConfig.getSettingValue('generalSettings', 'icon'), false)}
      widgetConfig={widgetConfig}
      createOrUpdateSettings={createOrUpdateWidgetConfig}
      isEditMode={isEditMode}
      isDeletable
      onDeleteWidget={onDeleteWidget}
    >
      {children}
    </Widget>
  );
};

export default CockpitWidget;
