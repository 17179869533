import i18n from '../../i18n/i18n';
import {BaseWidgetConfig} from '../shared/widgets/baseWidgetConfig';
import {ValidationRules} from '../../utils/validationRules';
import {ShiftBook} from '../../models/shiftBook';
import {getAllI18nTranslations} from '../../utils/translations';
import {FieldType} from '../../types/widgetConfig';

export class ShiftBookWidgetConfig extends BaseWidgetConfig {
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'display',
        label: i18n.t('widget.WidgetSettingsModal.displayConfigTabTitle'),
      },
      {
        key: 'settings',
        label: i18n.t('widget.WidgetSettingsModal.settingConfigTabTitle'),
      },
      {
        key: 'data',
        label: i18n.t('widget.WidgetSettingsModal.dataConfigTabTitle'),
      },
    ];
  }

  display = [
    {
      id: 'title',
      label: i18n.t('widget.settings.title.label'),
      helpText: i18n.t('widget.settings.title.help'),
      type: FieldType.Translatable,
      defaultValue: getAllI18nTranslations('shiftBook.title'),
      validations: [ValidationRules.required()],
    },
    {
      id: 'queryTimeSpan',
      label: i18n.t('shiftBookWidget.settings.queryTimeSpan.label'),
      helpText: i18n.t('shiftBookWidget.settings.queryTimeSpan.help'),
      type: FieldType.Number,
      unit: i18n.t('shiftBookWidget.settings.queryTimeSpan.unit'),
      defaultValue: 24,
      validations: [ValidationRules.required(), ValidationRules.positiveNumber],
    },
    {
      id: 'pagination.entries',
      label: i18n.t('shiftBookWidget.settings.entries.label'),
      helpText: i18n.t('shiftBookWidget.settings.entries.help'),
      type: FieldType.Select,
      defaultValue: 10,
      options: [5, 10, 15, 20, 25].map((value) => ({
        value,
        label: i18n.t(`shiftBookWidget.settings.entries.options.${value}.label`),
        helpText: i18n.t(`shiftBookWidget.settings.entries.options.${value}.help`),
      })),
      validations: [ValidationRules.required(), ValidationRules.positiveNumber],
    },
    {
      id: 'pagination.showTotal',
      label: i18n.t('shiftBookWidget.settings.showTotal.label'),
      helpText: i18n.t('shiftBookWidget.settings.showTotal.help'),
      type: FieldType.Boolean,
      defaultValue: true,
      validations: [ValidationRules.required()],
    },
  ];

  settings = [
    {
      id: 'messageFieldRows',
      label: i18n.t('shiftBookWidget.settings.messageFieldRows.label'),
      helpText: i18n.t('shiftBookWidget.settings.messageFieldRows.help'),
      type: FieldType.String,
      defaultValue: 10,
      validations: [ValidationRules.required(), ValidationRules.positiveNumber],
    },
    {
      id: 'timeSpanFinished',
      label: i18n.t('shiftBookWidget.settings.timeSpanFinished.label'),
      helpText: i18n.t('shiftBookWidget.settings.timeSpanFinished.help'),
      type: FieldType.Number,
      unit: i18n.t('shiftBookWidget.settings.timeSpanFinished.unit'),
      defaultValue: 8,
      validations: [ValidationRules.required(), ValidationRules.positiveNumber],
    },
    {
      id: 'ordersDisplayedInSelect',
      label: i18n.t('shiftBookWidget.settings.ordersDisplayedInSelect.label'),
      helpText: i18n.t('shiftBookWidget.settings.ordersDisplayedInSelect.help'),
      type: FieldType.Number,
      unit: i18n.t('shiftBookWidget.settings.ordersDisplayedInSelect.unit'),
      defaultValue: 5,
      validations: [ValidationRules.required(), ValidationRules.positiveNumber],
    },
    {
      id: 'orderIsMandatory',
      label: i18n.t('shiftBookWidget.settings.orderIsMandatory.label'),
      helpText: i18n.t('shiftBookWidget.settings.orderIsMandatory.help'),
      type: FieldType.Boolean,
      defaultValue: false,
      validations: [ValidationRules.required()],
    },
  ];

  data = [
    {
      id: 'properties',
      type: FieldType.PropertiesSelectDisplayField,
      modelOptions: ShiftBook.allDisplayableProperties(this.rootStore),
      defaultValue: ['createdAt', 'message', 'order.no', 'createdBy'],
    },
  ];

  getTableColumns() {
    return this.getSelectedProperties('data', 'properties');
  }

  getWidgetTitle() {
    return this.getTranslatableValue('display', 'title');
  }
}
