import dayjs from 'dayjs';
import { sortChronologically } from '../shared/tables/sorters';
import { OperationStateLog } from '../../models/operationStateLog';
import { WorkplaceStateLog } from '../../models/workplaceStateLog';

type Log = WorkplaceStateLog | OperationStateLog;

export const getReducedByInterruptionsMinDuration = (
  logs: Log[],
  showInterruptionsMinDuration: number
) => logs
  .sort((a, b) => sortChronologically(a.start, b.start))
  .reduce((accumulator: Log[], current: Log, index, array) => {
    if (!current.state || !current.state.isInterruption || !current.end) {
      accumulator.push(current);
      return accumulator;
    }

    const durationInSeconds = dayjs(current.end).diff(dayjs(current.start), 'seconds');

    if (durationInSeconds >= showInterruptionsMinDuration) {
      accumulator.push(current);
    } else if (accumulator.length > 0) {
      accumulator[accumulator.length - 1].end = current.end;
    }

    if (index === array.length - 1 && durationInSeconds < showInterruptionsMinDuration && accumulator.length > 0) {
      accumulator[accumulator.length - 1].end = current.end;
    }

    return accumulator;
  }, []);
