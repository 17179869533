import { makeObservable, observable } from 'mobx';
import { LdapFieldMapping } from '../models/ldapFieldMapping';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { RootStore } from './rootStore';

export class LdapFieldMappingStore extends EntityStore<LdapFieldMapping> {
  ldapFieldMappings: LdapFieldMapping[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'ldapFieldMappings', Api.ldapFieldMappings, LdapFieldMapping);

    makeObservable(this, {
      ldapFieldMappings: observable,
    });
  }
}
