import {useMemo, useState} from 'react';
import {Space} from 'antd';
import {Observer, observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLayerGroup} from '@fortawesome/pro-solid-svg-icons';
import {WidgetLayouts} from '../shared/widgets/WidgetLayouts';
import {WidgetLayout} from '../../models/widgetLayout';
import OperatorWidget from '../operator/shared/OperatorWidget';
import {useStore} from '../../hooks/useStore';
import {ScopeContext} from '../../policies/scopeContext';
import {batch} from '../../models/scope';
import PreparationModal from './PreparationModal';
import {BatchHandlingWidgetConfig} from './BatchHandlingWidgetConfig';
import ScanStorageUnit from './shared/ScanStorageUnit';
import {useMount} from '../../hooks/useMount';
import Table from '../shared/tables/Table';
import {PreparationState} from '../../models/preparation';
import Spinner from '../shared/spinners/Spinner';
import Button from '../shared/buttons/Button';

const BatchHandlingWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const {t} = useTranslation();
  const widgetConfig = useMemo(() =>
    new BatchHandlingWidgetConfig(store, identifier), []);

  const [preparationModalVisible, setPreparationModalVisible] = useState(null);

  useMount(() => {
    if (store.operationStore.active?.id) {
      store.preparationStore.loadAll({
        params: {
          operationId: store.operationStore.active.id,
        },
      }).then((preparations) => {
        store.storageUnitStore.loadMany(preparations.map((prep) => prep.storageUnitId));
      });
    }
  }, [store.operationStore.active?.id]);

  const onSetStorageUnit = (storageUnit) => {
    if (storageUnit) {
      const [undonePreparation] = store.preparationStore.filterUndoneByStorageUnitIdAndOperationId(
        storageUnit.id,
        store.operationStore.active.id
      );

      if (undonePreparation) {
        setPreparationModalVisible(undonePreparation);
      } else {
        store.preparationStore.create({
          workplaceId: store.workplaceStore.selectedWorkplace.id,
          storageUnitId: storageUnit.id,
          state: PreparationState.RUNNING,
        }).then((preparation) => {
          if (preparation.id) {
            setPreparationModalVisible(store.preparationStore.getById(preparation.id));
          }
        });
      }
    }
  };

  return (
    <ScopeContext.Provider value={[batch]}>
      <OperatorWidget
        icon={<FontAwesomeIcon icon={faLayerGroup}/>}
        title={widgetConfig.getWidgetTitle()}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
      >
        <ScanStorageUnit
          onChangeStorageUnit={onSetStorageUnit}
          operationDisabled={!store.operationStore.active}
          widgetDisabled={disabled}
        />
        <Space size={'middle'} direction={'vertical'} style={{display: 'flex'}}>
          <Table
            header={null}
            rowKey={'id'}
            loading={store.preparationStore.isLoadingCollection}
            pagination={false}
            dataSource={store.preparationStore.runningPreparations}
            columns={[
              {
                title: t('batchHandling.runningPreparations'),
                render: (preparation) => (
                  <Observer>
                    {() => (
                      preparation.storageUnit !== undefined
                        ? `${t('batchHandling.batchNo')} ${preparation.storageUnit?.no}`
                        : <Spinner size={'small'}/>
                    )}
                  </Observer>
                ),
              },
              {
                render: (record) => (
                  <Button
                    type={'link'}
                    onClick={() => setPreparationModalVisible(record)}
                  >
                    {t('links.show')}
                  </Button>
                ),
              },
            ]}
          />
          <Table
            header={null}
            rowKey={'id'}
            loading={store.preparationStore.isLoadingCollection}
            pagination={false}
            dataSource={[{id: 1, done: store.preparationStore.donePreparations?.length || 0}]}
            columns={[
              {
                title: t('batchHandling.donePreparations'),
                dataIndex: 'done',
              },
            ]}
          />
        </Space>
      </OperatorWidget>
      {preparationModalVisible && (
        <PreparationModal
          fullscreen={store.clientStore.isMobile}
          preparation={preparationModalVisible}
          onClose={() => setPreparationModalVisible(null)}
        />
      )}
    </ScopeContext.Provider>
  );
};

BatchHandlingWidget.icon = faLayerGroup;

BatchHandlingWidget.identifier = 'batchHandlingWidget';
BatchHandlingWidget.defaultLayout = new WidgetLayout(
  {
    identifier: BatchHandlingWidget.identifier,
    x: 0,
    y: 18,
    height: 8,
    minHeight: 4,
    width: WidgetLayouts.sixthWidth.w,
    minWidth: WidgetLayouts.sixthWidth.minW,
  }
);

export default observer(BatchHandlingWidget);
