import React from 'react';
import { ColorPicker as AntdColorPicker, ColorPickerProps as AntdColorPickerProps } from 'antd';

export type ColorPickerProps = Omit<AntdColorPickerProps, 'onChange'> & {
  onChange?: (hex: string | null) => void;
};

const ColorPicker: React.FC<ColorPickerProps> = ({
  value,
  onChange,
  disabled,
  showText = true,
  allowClear = true,
  size = 'middle',
  ...props
}) => (
  <AntdColorPicker
    value={value || '#FFFFFF'}
    onChange={(c) => {
      if (onChange) {
        onChange(c?.toHexString().toUpperCase() || null);
      }
    }}
    showText={showText}
    allowClear={allowClear}
    disabled={disabled}
    size={size}
    {...props}
  />
);

export default ColorPicker;
