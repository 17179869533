import { observable, makeObservable, computed } from 'mobx';
import { faArchive } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import { StorageUnit } from './storageUnit';
import { Operation } from './operation';
import { User } from './user';
import { datetimeFormat } from '../config/dayjs';
import { sortAlphabetically, sortChronologically } from '../components/shared/tables/sorters';
import { EnDash } from '../components/shared/unicodeWrapper/EnDash';
import { RootStore } from '../stores/rootStore';
import { Product } from './product';

/**
 * preparation states translations:
 * t('preparation.model.attributes.states.RUNNING')
 * t('preparation.model.attributes.states.FINISHED')
 * t('preparation.model.attributes.states.DONE')
 */

export enum PreparationState {
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
  DONE = 'DONE'
}

export class Preparation extends BaseModel {
  id: number = 0;
  operationId: number = 0;
  storageUnitId: number = 0;
  state: PreparationState = PreparationState.RUNNING;
  productId: number | null = null;
  no: number | null = null;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      operationId: observable,
      operation: computed,
      storageUnitId: observable,
      storageUnit: computed,
      state: observable,
      productId: observable,
      product: computed,
      no: observable,
      consumptions: computed,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
      createdByUser: computed,
    });
  }

  static faIcon = faArchive;

  displayableProperties = [
    displayableProperty({
      title: i18n.t('preparation.model.attributes.createdAt'),
      params: [
        displayablePropertyParam({
          path: 'createdAt', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      key: 'createdAt',
      sorter: (a, b) => sortChronologically(a.createdAt, b.createdAt),
      renderText: (text, record) => record?.createdAt || '',
    }),
    displayableProperty({
      title: i18n.t('preparation.model.attributes.state'),
      params: [displayablePropertyParam({
        path: 'stateText',
      })],
      template: '{value}',
      key: 'state',
      sorter: (a, b) => sortAlphabetically(a.state, b.state),
    }),
    displayableProperty({
      key: 'dosageEnd',
      title: i18n.t('preparation.model.attributes.dosageEnd'),
      params: [
        displayablePropertyParam({
          path: 'consumptions',
          transform: transformation.youngest(datetimeFormat, 'dosedEnd'),
          as: 'newestConsumption',
        }),
      ],
      template: '{newestConsumption}',
    }),
    displayableProperty({
      key: 'dosageDuration',
      title: i18n.t('preparation.model.attributes.dosageDuration'),
      params: [
        displayablePropertyParam({
          path: 'consumptions',
          transform: transformation.timeDifference('dosedStart', 'dosedEnd'),
          as: 'dosageDuration',
        }),
      ],
      template: '{dosageDuration}',
    }),
    displayableProperty({
      key: 'sumOfConsumptions',
      title: i18n.t('preparation.model.attributes.sumOfConsumptions'),
      params: [
        displayablePropertyParam({
          path: '.',
          transform: (p) => Preparation.sumOfConsumptions(p),
          as: 'sumOfConsumptions',
        }),
        displayablePropertyParam({
          path: '.',
          transform: (p) => Preparation.relevantUnitOfMeasure(p),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{sumOfConsumptions} {unitOfMeasure}',
    }),
    displayableProperty({
      key: 'allConsumptionsInTolerance',
      title: i18n.t('preparation.model.attributes.allConsumptionsInTolerance'),
      raw: true,
      params: [
        displayablePropertyParam({
          path: '.',
          transform: (p) => transformation.booleanValue(Preparation.allConsumptionsInTolerance(p)),
          as: 'allConsumptionsInTolerance',
        }),
      ],
    }),
    displayableProperty({
      key: 'sumOfComponentPreparationQuantity',
      title: i18n.t('preparation.model.attributes.sumOfComponentPreparationQuantity'),
      params: [
        displayablePropertyParam({
          path: '.',
          transform: (p) => Preparation.sumOfComponentPreparationQuantity(p),
          as: 'sumOfComponentPreparationQuantity',
        }),
        displayablePropertyParam({
          path: '.',
          transform: (p) => Preparation.relevantUnitOfMeasure(p),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{sumOfComponentPreparationQuantity} {unitOfMeasure}',
    }),
    displayableProperty({
      key: 'quantityIsShould',
      title: i18n.t('preparation.model.attributes.quantityIsShould'),
      params: [
        displayablePropertyParam({
          path: '.',
          transform: (p) => Preparation.sumOfConsumptions(p),
          as: 'sumOfConsumptions',
        }),
        displayablePropertyParam({
          path: '.',
          transform: (p) => Preparation.sumOfComponentPreparationQuantity(p),
          as: 'sumOfComponentPreparationQuantity',
        }),
        displayablePropertyParam({
          path: '.',
          transform: (p) => Preparation.relevantUnitOfMeasure(p),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{sumOfConsumptions} / {sumOfComponentPreparationQuantity} {unitOfMeasure}',
    }),
  ];

  static relevantUnitOfMeasure = (prep: Preparation) => prep?.operation?.unitOfMeasure?.label
    || prep?.operation?.order?.unitOfMeasure?.label;

  static sumOfConsumptions = (prep: Preparation) => {
    if (!prep) {
      return EnDash();
    }
    let sum = 0;
    const relevantUnitOfMeasureId = prep.operation?.unitOfMeasureId || prep.operation?.order?.unitOfMeasureId;
    prep.consumptions?.forEach((consumption) => {
      if (relevantUnitOfMeasureId && (consumption.unitOfMeasureId === relevantUnitOfMeasureId)) {
        sum += consumption.amount;
      }
    });
    return sum || EnDash();
  };

  static allConsumptionsInTolerance = (prep: Preparation) => {
    let inTolerance = true;
    if (!prep) {
      return inTolerance;
    }
    prep.consumptions?.forEach((consumption) => {
      const relevantComponent = prep.operation?.components?.find(
        (component) => component.id === consumption.componentId
      );
      if (relevantComponent
        && ((relevantComponent.toleranceLow && consumption.amount < relevantComponent.toleranceLow)
          || (relevantComponent.toleranceHigh && consumption.amount > relevantComponent.toleranceHigh))) {
        inTolerance = false;
      }
    });
    return inTolerance;
  };

  static sumOfComponentPreparationQuantity = (prep: Preparation) => {
    if (!prep) {
      return EnDash();
    }
    let sum = 0;
    const relevantUnitOfMeasureId = prep.operation?.unitOfMeasureId || prep.operation?.order?.unitOfMeasureId;
    prep.operation?.components?.forEach((component) => {
      if (relevantUnitOfMeasureId && (component.unitOfMeasureId === relevantUnitOfMeasureId)) {
        sum += component.preparationQuantity || 0;
      }
    });
    // eslint-disable-next-line max-len
    return sum || EnDash();
  };

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);

    const storageUnitPrefix = `${titlePrefix}${i18n.t('storageUnit.model.one')} > `;
    allDisplayableProperties.push(
      ...StorageUnit.allDisplayableProperties(rootStore, `${pathPrefix}storageUnit.`, storageUnitPrefix)
    );
    const operationPrefix = `${titlePrefix}${i18n.t('operation.model.one')} > `;
    allDisplayableProperties.push(
      ...Operation.allDisplayableProperties(rootStore, `${pathPrefix}operation.`, operationPrefix)
    );

    const productPrefix = `${titlePrefix}${i18n.t('product.model.one')} > `;
    allDisplayableProperties.push(
      ...Product.allDisplayableProperties(rootStore, `${pathPrefix}product.`, productPrefix)
    );

    const createdByUserPrefix = `${titlePrefix}${i18n.t('preparation.model.attributes.createdBy')} > `;
    allDisplayableProperties.push(
      ...User.allDisplayableProperties(rootStore, `${pathPrefix}createdByUser.`, createdByUserPrefix)
    );

    return allDisplayableProperties;
  }

  get storageUnit() {
    return this.rootStore.storageUnitStore.getById(this.storageUnitId);
  }

  get operation() {
    return this.rootStore.operationStore.getById(this.operationId);
  }

  get product() {
    return this.rootStore.productStore.getById(this.productId);
  }

  get createdByUser() {
    return this.rootStore.userStore.getById(this.createdBy);
  }

  get consumptions() {
    return this.rootStore.consumptionLogStore.getByPreparationIds([this.id]);
  }

  get stateText() {
    const stateName = PreparationState[this.state];
    return i18n.t(`preparation.model.attributes.states.${stateName}`);
  }
}
