import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Alert, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Key } from 'antd/es/table/interface';
import { uniq } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { useStore } from '../../../hooks/useStore';
import { useMount } from '../../../hooks/useMount';
import Button from '../../shared/buttons/Button';
import InputNumber from '../../shared/inputs/InputNumber';
import Form from '../../shared/form/Form';
import DosageStorageUnitChildrenTable from './DosageStorageUnitChildrenTable';
import { DosageWidgetConfig } from './dosageWidgetConfig';
import DosageSensorSelect from './DosageSensorSelect';
import { ParentDosageMode } from '../../../models/dosage';
import {
  ColorizingType,
  getActualPreparation,
  getCalculatedAverageOfPreparationByAmount,
  getColorizingObject,
  getParentInformationByComponent,
} from '../dosageUtil';
import DosageSliderComponent, { Colorizing } from '../DosageSliderComponent';
import { ParentInformation, SourceStorageUnit } from './DosageTabs';
import { Component } from '../../../models/component';
import { PrepareResponse } from '../../../stores/dosageStore';
import Modal from '../../shared/modal/Modal';
import { StorageUnit } from '../../../models/storageUnit';
import styles from './DosageWidgetStyles.module.scss';

export type DosageQuantitySliderProps = {
  preparationId: number;
  sourceStorageUnitIds: SourceStorageUnit[];
  widgetConfig: DosageWidgetConfig;
  onSensorNameChange?: (value: string) => void;
};

export interface ChildrenSelection {
  componentId: number;
  childrenSelection: number[];
}

const DosageQuantitySlider: React.FC<DosageQuantitySliderProps> = ({
  preparationId,
  sourceStorageUnitIds,
  widgetConfig,
  onSensorNameChange,
}) => {
  const store = useStore();
  const { t } = useTranslation();
  const [unitOfMeasure, setUnitOfMeasure] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(0);
  const [sensorName, setSensorName] = useState<string>('');
  const [maxQuantity, setMaxQuantity] = useState<number>(0);
  const [childrenSelection, setChildrenSelection] = useState<ChildrenSelection[]>([]);
  const [isQuantityEditing, setIsQuantityEditing] = useState(false);
  const [openPreparations, setOpenPreparations] = useState<number>(0);
  const parentDosageMode = widgetConfig.getSettingValue('settings', 'parentDosage_selectionQuantity');
  const [isPreparationModalOpen, setIsPreparationModalOpen] = useState(false);
  const [preparationResults, setPreparationResults] = useState<PrepareResponse[]>([]);
  const getIsMultiselectedComponents = useCallback(() =>
    store.dosageStore.selectedComponents.length > 1, [store.dosageStore.selectedComponents]);

  useMount(() => {
    store.preparationStore.loadWithDependencies(preparationId, {});
  }, [preparationId]);

  useMount(() => {
    const { selectedComponents } = store.dosageStore;
    if (!selectedComponents || !selectedComponents.length || !preparationId) {
      return;
    }

    if (getIsMultiselectedComponents()) {
      const uom = store.unitOfMeasurementStore.getById(selectedComponents[0].unitOfMeasureId);
      setUnitOfMeasure(uom?.label || '');
      return;
    }

    setChildrenSelection([]);

    const {
      toleranceHigh,
      preparationQuantity,
    } = selectedComponents[0];

    const actualPreparation = getActualPreparation(preparationId, selectedComponents[0], store);
    const calculatedOpenPreparations = preparationQuantity ? preparationQuantity - actualPreparation : 0;

    setOpenPreparations(calculatedOpenPreparations);

    const uom = store.unitOfMeasurementStore.getById(selectedComponents[0].unitOfMeasureId);
    setUnitOfMeasure(uom?.label || '');

    const maxQty = toleranceHigh || actualPreparation;

    setMaxQuantity(maxQty);
  }, [store.dosageStore.selectedComponents, preparationId]);

  useMount(() => {
    setQuantity(0);
  }, [store.dosageStore.selectedComponents, store.dosageStore.selectedComponentUnitIds.length]);

  const handleQuantityChange = (value: number) => {
    const selectedComponent = getIsMultiselectedComponents() ? null : store.dosageStore.selectedComponents[0];
    if (selectedComponent) {
      const { toleranceHigh } = selectedComponent;

      if (!toleranceHigh) {
        setMaxQuantity(value);
      }

      if (selectedComponent.preparationQuantity) {
        const actualPreparationQty = getActualPreparation(preparationId, selectedComponent, store);
        setOpenPreparations(selectedComponent.preparationQuantity - (actualPreparationQty + value));
      }
    }
    setQuantity(value);
  };
  const handleSensorSelect = (value: string) => {
    if (!onSensorNameChange) {
      return;
    }
    onSensorNameChange(value);
    setSensorName(value);
  };

  const handleDose = async (
    component: Component,
    sourceStorageUnitId: number,
    parentInformation: ParentInformation,
    isSelection: boolean = false,
    selectedChildren: number[] = []
  ): Promise<PrepareResponse> => {
    if (!component?.id) {
      return { success: false, message: 'Invalid component ID', componentId: component.id };
    }

    const {
      isParent,
      parentStorageUnitId,
      childrenAmount,
    } = parentInformation;

    let finalQuantity: number;
    if (!isParent) {
      finalQuantity = quantity;
    } else if (isSelection) {
      finalQuantity = quantity;
    } else {
      finalQuantity = quantity > childrenAmount ? childrenAmount : quantity;
    }

    try {
      return await store.dosageStore.prepare(
        preparationId,
        finalQuantity,
        component.id,
        isParent ? parentStorageUnitId : sourceStorageUnitId || null,
        isParent ? { ...parentInformation, childrenSelection: selectedChildren, isSelection } : null
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error prepare dosage', e);
      return { success: false, message: 'Error during preparation', componentId: component.id };
    }
  };

  const handleDoseBefore = async (isSelection: boolean = false) => {
    const { selectedComponents } = store.dosageStore;
    if (!selectedComponents || !selectedComponents.length) {
      return;
    }
    store.dosageStore.setIsInPreparation(true);

    const dosePromises = selectedComponents
      .filter((component) => {
        let selectedChildren: number[] = [];
        if (isSelection) {
          const componentChildrenSelection = childrenSelection.find((cs) => cs.componentId === component.id);
          if (componentChildrenSelection) {
            selectedChildren = componentChildrenSelection.childrenSelection;
          }
        } else {
          return true;
        }

        return isSelection && selectedChildren.length;
      })
      .map(async (component) => {
        const parentInformation = getParentInformationByComponent(component, store);
        const sourceStorageUnitId: number = sourceStorageUnitIds
          .find((su) => su.operationId === component.operationId && su.materialId === component.materialId)?.id || 0;

        let selectedChildren: number[] = [];
        if (isSelection) {
          const componentChildrenSelection = childrenSelection.find((cs) => cs.componentId === component.id);
          if (componentChildrenSelection) {
            selectedChildren = componentChildrenSelection.childrenSelection;
          }
        }

        return handleDose(component, sourceStorageUnitId, parentInformation, isSelection, selectedChildren);
      });

    const results = await Promise.all(dosePromises);
    setPreparationResults(results);

    store.dosageStore.setIsInPreparation(false);

    if (results.some((result) => !result.success)) {
      setIsPreparationModalOpen(true);
    }

    if (widgetConfig.getWidgetSetting('property_autoTareOnWeighing')) {
      const operation = store.operationStore.getById(
        store.preparationStore.getById(Number(preparationId))?.operationId || null
      );
      const workplace = store.workplaceStore.getById(operation?.workplaceId || null);
      if (workplace && sensorName) {
        await store.dosageStore.tare(
          workplace.id,
          sensorName,
          operation?.id
        );
      }
    }
  };

  const getMaterialLabelByComponentId = (componentId: number | undefined) => {
    if (!componentId) {
      return '';
    }
    const component = store.componentStore.getById(componentId);
    if (!component) {
      return '';
    }
    const material = store.materialStore.getById(component.materialId);
    return material?.label || '';
  };

  const closePreparationModal = () => {
    setIsPreparationModalOpen(false);
    setPreparationResults([]);
  };

  const handleChildrenSelection = (selectedRowKeys: Key[], selectedRows: StorageUnit[]) => {
    if (!selectedRowKeys.length || !selectedRows.length) {
      setChildrenSelection([]);
      return;
    }

    const activeOperation = store.operationStore.active;
    if (!activeOperation) {
      setChildrenSelection([]);
      return;
    }

    const childrenSelectionNew: ChildrenSelection[] = [];
    selectedRows.forEach((row) => {
      const component = store.dosageStore.selectedComponents
        .find((c) => c.materialId === row.materialId && c.operationId === activeOperation.id);

      if (!component) {
        setChildrenSelection([]);
        return;
      }

      const existingComponentChildSelection = childrenSelectionNew.find((cs) => cs.componentId === component.id);
      if (existingComponentChildSelection) {
        existingComponentChildSelection.childrenSelection.push(row.id);
      } else {
        childrenSelectionNew.push({ componentId: component.id, childrenSelection: [row.id] });
      }
    });

    setChildrenSelection(childrenSelectionNew);
  };

  const { selectedComponents } = store.dosageStore;
  const isMultiselectedComponents = getIsMultiselectedComponents();
  const selectedComponent = isMultiselectedComponents ? null : selectedComponents[0];
  const parentInformations: { componentId: number, parentInformation: ParentInformation }[] = [];
  let minChildAmount = 0;
  const parentStorageUnitIds: number[] = [];

  selectedComponents.forEach((component) => {
    const parentInformation = getParentInformationByComponent(component, store);
    minChildAmount = minChildAmount > parentInformation.childrenAmount
      ? parentInformation.childrenAmount
      : minChildAmount;
    parentStorageUnitIds.push(parentInformation.parentStorageUnitId);
    parentInformations.push({ componentId: component.id, parentInformation });
  });

  const { isParent } = parentInformations[0].parentInformation;

  const step = isParent ? 1 : 0.1;
  let max = maxQuantity;
  if (isMultiselectedComponents) {
    if (isParent) {
      max = minChildAmount;
    }
  }

  const actualMarks = [];
  let currentValue: number = 0;
  let averageValue: number = 0;
  let colorizing: Colorizing = getColorizingObject(ColorizingType.BLUE);
  let plannedPreparationsUom: string = '';
  let openPreparationsUom: string = '';
  let newValue: number = 0;

  if (!isMultiselectedComponents || parentDosageMode === ParentDosageMode.Selection) {
    const parentInformation = parentInformations.find((info) => info.componentId === selectedComponent?.id);
    const {
      childrenAmount,
      parentStorageUnitId,
    } = parentInformation?.parentInformation || {};

    if (selectedComponent) {
      const { toleranceHigh, toleranceLow } = selectedComponent;
      currentValue = getActualPreparation(preparationId, selectedComponent, store);

      if (parentStorageUnitId) {
        averageValue = getCalculatedAverageOfPreparationByAmount(
          preparationId,
          selectedComponent,
          parentStorageUnitId,
          store
        );
      }

      const valueToCheck = isParent ? currentValue + (quantity * averageValue) : currentValue + quantity;

      if (toleranceLow && toleranceHigh) {
        if (valueToCheck >= toleranceLow && valueToCheck <= toleranceHigh) {
          colorizing = getColorizingObject(ColorizingType.GREEN);
        } else {
          colorizing = getColorizingObject(ColorizingType.RED);
        }
      }
    }

    if (isParent && childrenAmount) {
      const childAmount: number = childrenAmount;
      if (quantity > 0 && quantity < childAmount) {
        if (currentValue > 0) {
          actualMarks.push((quantity * averageValue) + currentValue);
        } else {
          actualMarks.push(quantity * averageValue);
        }
      }
    } else if (!isParent && quantity > 0 && quantity < max) {
      if (currentValue > 0) {
        actualMarks.push(quantity + currentValue);
      } else {
        actualMarks.push(quantity);
      }
    }

    if (currentValue > 0) {
      actualMarks.push(currentValue);
    }

    plannedPreparationsUom = selectedComponent
      ? `${selectedComponent.preparationQuantity} ${unitOfMeasure}`
      : '-';

    openPreparationsUom = `${Math.round(openPreparations * 100_000) / 100_000} ${unitOfMeasure}`;
    newValue = !isParent ? quantity : quantity * averageValue;
  }

  return (
    <>
      {
        (isPreparationModalOpen && preparationResults.length > 0) && (
          <Modal
            title={(
              <span className={styles.errorTitle}>
                <FontAwesomeIcon icon={faCircleXmark}/>
                {' '}
                {t('dosageWidget.preparation.modal.title')}
              </span>
            )}
            open
            width={'50vw'}
            centered
            onCancel={closePreparationModal}
            footer={[
              <Button
                key="close"
                type="primary"
                onClick={closePreparationModal}
              >
                {t<string>('dosageWidget.preparation.modal.close')}
              </Button>,

            ]}
          >
            {
              preparationResults.some((result) => !result.success) && (
                <Alert
                  type={'error'}
                  message={t('dosageWidget.preparation.modal.dosingResults.unsuccessful')}
                  description={(
                    <ul>
                      {preparationResults
                        .filter((result) => !result.success)
                        .map((result) => (
                          <li key={result.componentId}>
                            {`${getMaterialLabelByComponentId(result.componentId)}`}
                          </li>
                        ))}
                    </ul>
                  )}
                />
              )
            }
            {
              preparationResults.some((result) => result.success) && (
                <Alert
                  type={'success'}
                  message={t('dosageWidget.preparation.modal.dosingResults.successful')}
                  description={(
                    <ul>
                      {preparationResults
                        .filter((result) => result.success)
                        .map((result) => (
                          <li key={result.componentId}>
                            {`${getMaterialLabelByComponentId(result.componentId)}`}
                          </li>
                        ))}
                    </ul>
                  )}
                />
              )
            }
          </Modal>
        )
      }

      <div
        style={{
          paddingInline: '20px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {
          (
            isParent
            && parentStorageUnitIds
            && parentStorageUnitIds.length > 0
            && parentDosageMode === ParentDosageMode.Selection
          ) && (
            <DosageStorageUnitChildrenTable
              widgetConfig={widgetConfig}
              parentStorageUnitIds={parentStorageUnitIds}
              getSelection={(selectedRowKeys: Key[], selectedRows: StorageUnit[]) => {
                handleChildrenSelection(selectedRowKeys, selectedRows);
              }}
            />
          )
        }
        {
          (!isParent || parentDosageMode === ParentDosageMode.Quantity) && (
            <>
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '40px',
                }}
              >
                <Form.Item label={t('dosageWidget.quantity.label')}>
                  <InputNumber
                    data-cy={'DosageQuantitySlider-InputNumber'}
                    value={quantity}
                    addonAfter={!isParent ? unitOfMeasure : t<string>('dosageWidget.quantity.addonAfter.isParent')}
                    onChange={(value) => handleQuantityChange(value as number)}
                    step={step}
                    disabled={store.dosageStore.isInPreparation}
                    min={0}
                    max={max || undefined}
                    style={{
                      width: '150px',
                    }}
                    onFocus={() => setIsQuantityEditing(true)}
                    onBlur={() => setIsQuantityEditing(false)}
                  />
                </Form.Item>

                {
                  (selectedComponent && selectedComponent.preparationQuantity) && (
                    <>
                      <Form.Item label={`${t('dosageWidget.planned.label')}:`}>
                        <span>
                          {plannedPreparationsUom}
                        </span>
                      </Form.Item>

                      <Form.Item label={`${t('dosageWidget.openQuantity.label')}:`}>
                        <span>
                          {openPreparationsUom}
                        </span>
                      </Form.Item>
                    </>
                  )
                }
                {
                  widgetConfig.getReadScales() && (
                    <DosageSensorSelect
                      onSelect={(value) => handleSensorSelect(value)}
                      onValueChange={(value) => handleQuantityChange(value)}
                      suspended={isQuantityEditing}
                      allowedStorageUnitId={selectedComponents[0]?.unitOfMeasureId}
                    />
                  )
                }
              </Row>
              {
                !isMultiselectedComponents && (
                  <div data-cy={'DosageQuantitySlider-Slider'}>
                    <DosageSliderComponent
                      min={0}
                      max={max}
                      marks={uniq(actualMarks)}
                      currentValue={currentValue}
                      newValue={newValue}
                      colorizing={colorizing}
                      genericSteps={5}
                      unitOfMeasureLabel={unitOfMeasure}
                      isParent={isParent}
                    />
                  </div>
                )
              }
            </>
          )
        }
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            marginBlock: '30px',
            justifyContent: 'flex-end',
          }}
        >
          {
            (isParent && parentDosageMode === ParentDosageMode.Selection) && (
              <Button
                data-cy={'DosageQuantitySlider-SelectionDoseButton'}
                type="primary"
                onClick={() => handleDoseBefore(true)}
                disabled={store.dosageStore.isInPreparation || childrenSelection.length <= 0}
              >
                {t('dosageWidget.dosage.doseSelection')}
              </Button>
            )
          }

          {
            (!isParent || parentDosageMode === ParentDosageMode.Quantity) && (
              <Button
                data-cy={'DosageQuantitySlider-DoseButton'}
                type="primary"
                onClick={() => handleDoseBefore()}
                disabled={store.dosageStore.isInPreparation || quantity <= 0}
              >
                {isParent
                  ? t('dosageWidget.dosage.doseQuantity')
                  : t('dosageWidget.dosage.dose')}
              </Button>
            )
          }
        </div>
      </div>
    </>
  );
};

export default observer(DosageQuantitySlider);
