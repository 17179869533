import { action, observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { SensorDataLimit } from '../models/sensorDataLimit';
import { RootStore } from './rootStore';

export class SensorDataLimitStore extends EntityStore<SensorDataLimit> {
  sensorDataLimits: SensorDataLimit[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'sensorDataLimits', Api.sensorDataLimit, SensorDataLimit);

    makeObservable(this, {
      sensorDataLimits: observable,
      loadBySensorsAndHierarchy: action,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.sensorStore,
        modelId: 'sensorName',
      },
      {
        store: this.rootStore.hierarchyStore,
        modelId: 'hierarchyId',
      },
    ];
  }

  loadBySensorsAndHierarchy(sensorNames: string[], hierarchyId: number) {
    return this.loadAll({
      params: {
        sensorName: sensorNames,
        hierarchyId,
        includeParents: true,
      },
    });
  }

  getBySensorAndHierarchy(sensorName: string, hierarchyId: number) {
    const hierarchyPath = this.rootStore.hierarchyStore.getPathIds(hierarchyId);
    // eslint-disable-next-line no-restricted-syntax
    for (const pathId of hierarchyPath) {
      const sensorLimit = this.sensorDataLimits.find((limit) => limit.sensorName === sensorName
        && limit.hierarchyId === pathId);
      if (sensorLimit) {
        return sensorLimit;
      }
    }
    return undefined;
  }
}
