import { action, observable, makeObservable, computed } from 'mobx';
import dayjs, { Dayjs } from 'dayjs';
import { AxiosResponse } from 'axios';
import { Actions, EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { interruptionsEndpoint } from '../middleware/endpoints/stateHistories';
import { RootStore } from './rootStore';
import { BaseModel } from '../models/base';
import { Workplace } from '../models/workplace';
import { OperationStateLog } from '../models/operationStateLog';
import { WorkplaceStateLog } from '../models/workplaceStateLog';
import { PersonnelLog } from '../models/personnelLog';
import { StateLog } from '../models/stateLog';

interface StateHistoryResponse {
  workplace: Workplace | null;
  operationStateLogs: OperationStateLog[];
  workplaceStateLogs: WorkplaceStateLog[];
  personnelLogs: PersonnelLog[];
}

interface LoadStateHistoriesByWorkplaceProps {
  workplaceId: number;
  from: string;
  to: string;
}

interface LoadStateHistoriesByHierarchyProps {
  hierarchyId: number;
  from: string;
  to: string;
}

interface LoadInterruptionHistoryProps {
  workplaceId?: number;
  hierarchyIds?: number[];
  fromDate?: string;
  toDate?: string;
  materialId?: number;
  orderId?: number;
  isQuickstop?: boolean;
  ignoreOperation?: boolean;
  ignoreWorkplace?: boolean;
}

export class StateHistoryStore extends EntityStore {
  stateHistories = []; // not used
  stateHistoryOfWorkplace: StateHistoryResponse = {
    workplace: null,
    workplaceStateLogs: [],
    operationStateLogs: [],
    personnelLogs: [],
  };
  stateHistoryOfHierarchyWorkplaces: StateHistoryResponse[] = [{
    workplace: null,
    workplaceStateLogs: [],
    operationStateLogs: [],
    personnelLogs: [],
  }];
  interruptionStateHistoryByWorkplaceId: Map<number | null, StateLog[]> = new Map();

  constructor(rootStore: RootStore) {
    super(rootStore, 'stateHistories', Api.stateHistories, BaseModel);

    makeObservable(this, {
      loadStateHistoriesByWorkplace: action,
      loadStateHistoriesByHierarchy: action,
      loadInterruptionHistory: action,
      stateHistoryOfWorkplace: observable,
      stateHistoryOfHierarchyWorkplaces: observable,
      interruptionStateHistory: computed,
      interruptionStateHistoryByWorkplaceId: observable,
    });
  }

  // returns all interruption state history entries
  get interruptionStateHistory() {
    return Array.from(this.interruptionStateHistoryByWorkplaceId.values())
      .reduce((prev, curr) => [...prev, ...curr], []);
  }

  loadStateHistoriesByWorkplace(
    { workplaceId, from, to }: LoadStateHistoriesByWorkplaceProps,
    saveInStores: boolean = false
  ) {
    this.stateHistoryOfWorkplace = {
      workplace: null,
      workplaceStateLogs: [],
      operationStateLogs: [],
      personnelLogs: [],
    };
    return this.api[Actions.loadAll]({ from, to, workplaceId }).then(async (response: AxiosResponse) => {
      const stateHistory: StateHistoryResponse = response.data
        .find((history: StateHistoryResponse) => history.workplace?.id === workplaceId);

      if (saveInStores && stateHistory) {
        await this.rootStore.operationStore
          .loadManyWithDependencies(stateHistory.operationStateLogs.map((log) => log.operationId));
        const phaseTimeoutReasonIds = new Set();
        stateHistory.operationStateLogs.forEach((log) => {
          if (log.interruptionReasonId) {
            this.rootStore.interruptionReasonStore
              .loadWithDependencies(log.interruptionReasonId, { onlyIfEmpty: true });
          }
          if (log.phaseTimeoutReasonId) {
            phaseTimeoutReasonIds.add(log.phaseTimeoutReasonId);
          }
        });
        const operationEntities = stateHistory.operationStateLogs
          .map((log) => this.rootStore.operationStateLogStore.createModelInstance(log));
        this.rootStore.operationStateLogStore.addAll(operationEntities);

        stateHistory.workplaceStateLogs.forEach((log) => {
          if (log.interruptionReasonId) {
            this.rootStore.interruptionReasonStore
              .loadWithDependencies(log.interruptionReasonId, { onlyIfEmpty: true });
          }
        });
        const workplaceEntities = stateHistory.workplaceStateLogs
          .map((log) => this.rootStore.workplaceStateLogStore.createModelInstance(log));
        this.rootStore.workplaceStateLogStore.addAll(workplaceEntities);

        stateHistory.personnelLogs.forEach((log) => {
          this.rootStore.personnelLogStore.add(this.rootStore.personnelLogStore.createModelInstance(log));
        });
        if (phaseTimeoutReasonIds.size) {
          await this.rootStore.phaseTimeoutReasonStore.loadAll({ params: { id: Array.from(phaseTimeoutReasonIds) } });
        }
      }
      this.stateHistoryOfWorkplace = stateHistory;
      return this.stateHistoryOfWorkplace;
    });
  }

  loadStateHistoriesByHierarchy({ hierarchyId, from, to }: LoadStateHistoriesByHierarchyProps) {
    return this.api[Actions.loadAll]({ from, to, hierarchyId }).then((response: AxiosResponse) => {
      const hierarchyWorkplaces: StateHistoryResponse[] = response.data;
      hierarchyWorkplaces?.forEach((stateHistoryOfWorkplace) => {
        this.rootStore.operationStore.loadManyWithDependencies(
          stateHistoryOfWorkplace.operationStateLogs.map((log) => log.operationId)
        );
      });
      this.stateHistoryOfHierarchyWorkplaces = hierarchyWorkplaces || [];
    });
  }

  loadInterruptionHistory(
    {
      workplaceId,
      hierarchyIds,
      fromDate,
      toDate,
      materialId,
      orderId,
      isQuickstop,
      ignoreOperation,
      ignoreWorkplace,
    }: LoadInterruptionHistoryProps,
    fullReload: boolean = true
  ) {
    const relatedWorkplaceId = workplaceId || null;
    return this.api[interruptionsEndpoint]({
      params: {
        workplaceId,
        hierarchyIds,
        fromDate,
        toDate,
        materialId,
        orderId,
        isQuickstop,
        ignoreOperation,
        ignoreWorkplace,
      },
    }).then((response: AxiosResponse) => {
      const data = response.data?.map((d: any) => StateLog.fromPlainObject<StateLog>(d, this.rootStore));
      if (fullReload) {
        this.interruptionStateHistoryByWorkplaceId.clear();
      }
      this.interruptionStateHistoryByWorkplaceId.set(relatedWorkplaceId, data);
    }).catch((e: Error) => this.handleApiError(e, interruptionsEndpoint));
  }

  getWorkplacePersonnelAfter(date: Dayjs) {
    return this.stateHistoryOfWorkplace.personnelLogs.reduce((prev: PersonnelLog | null, curr) => {
      const currDate = dayjs(curr.createdAt);
      if (currDate.isAfter(date) && (!prev || currDate.isBefore(prev.createdAt))) {
        return curr;
      }
      return prev;
    }, null);
  }
  getInterruptionStateHistoryByWorkplaceId(workplaceId: number | null) {
    return this.interruptionStateHistoryByWorkplaceId.get(workplaceId) || [];
  }
}
