import { computed, observable, makeObservable } from 'mobx';
import { faBrowser } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

export class Terminal extends BaseModel {
  id: number = 0;
  name: string = '';
  isBroken: boolean = false;
  brokenReason: string | null = null;
  terminalLayoutId: number | null = null;
  workplaceId: number | null = null;
  workplaceGroupId: number | null = null;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      isBroken: observable,
      brokenReason: observable,
      terminalLayoutId: observable,
      workplaceId: observable,
      workplaceGroupId: observable,
      hasTerminalWorkplaces: computed,
      ...Terminal.modelCreateDecoration,
      ...Terminal.modelUpdateDecoration,
    });
  }

  static faIcon = faBrowser;

  searchableProperties = ['name'];

  static prepareApiPayload(model: Terminal) {
    return {
      ...model,
      brokenReason: model.isBroken ? model.brokenReason : null,
    };
  }

  get hasTerminalWorkplaces() {
    return this.workplaceId || this.workplaceGroupId;
  }
}
