import {computed, makeObservable, observable} from 'mobx';
import i18n from '../../../../../i18n/i18n';
import {ValidationRules} from '../../../../../utils/validationRules';
import {CockpitWidgetConfig} from './cockpitWidgetConfig';
import {FieldType} from '../../../../../types/widgetConfig';

export class TimeseriesCockpitWidgetConfig extends CockpitWidgetConfig {
  selectedHierarchyId = 0;

  constructor({rootStore, widgetTypeIdentifier, widgetId}) {
    super({rootStore, widgetTypeIdentifier, widgetId});

    makeObservable(this, {
      selectedHierarchyId: observable,
      sensorStatus: computed,
    });
  }

  static onLoadConfig = (rootStore) => {
    rootStore.sensorStore.loadAll();
  };

  static widgetType = {
    identifier: 'sensor-status-display',
    name: () => i18n.t('cockpitWidget.widgetTypeIdentifier.sensorStatus'),
  };

  // eslint-disable-next-line class-methods-use-this
  getWidgetTypeName() {
    return i18n.t('cockpitWidget.widgetTypeIdentifier.sensorStatus');
  }

  get tabs() {
    return [
      this.generalSettingsTab,
      {
        key: 'sensorStatus',
        label: i18n.t('widget.WidgetSettingsModal.sensorStatusConfigTabTitle'),
      },
    ];
  }

  get sensorStatus() {
    return [
      {
        id: 'hierarchyId',
        label: i18n.t('cockpitWidget.settings.workplace.label'),
        helpText: i18n.t('cockpitWidget.settings.workplace.help'),
        type: FieldType.HierarchyTreeWorkplace,
        // validations: [ValidationRules.required()],
      }, {
        id: 'sensor',
        label: i18n.t('sensor.model.one'),
        type: FieldType.Select,
        options: this.rootStore.sensorStore.sensors.map((sensor) => ({
          value: sensor.name,
          label: sensor.label || sensor.name,
        })),
        validations: [ValidationRules.required()],
        onFormChange: (form, changedFields) => {
          if (changedFields.hierarchyId !== undefined) {
            form.setFieldsValue({sensor: null});
          }
        },
      },
    ];
  }
}
