import {AppConfigApi} from './endpoints/appConfig';
import {AuditLogsApi} from './endpoints/auditLogs';
import {AuthApi} from './endpoints/auth';
import {BatchGeneratorFunctionApi} from './endpoints/batchGeneratorFunctions';
import {BatchQueueItemsApi} from './endpoints/batchQueueItems';
import {BatchesApi} from './endpoints/batches';
import {BatchReportApi} from './endpoints/batchReport';
import {CockpitDashboardsApi} from './endpoints/cockpitDashboard';
import {CockpitWidgetsApi} from './endpoints/cockpitWidgets';
import {CockpitsApi} from './endpoints/cockpits';
import {ComponentsApi} from './endpoints/components';
import {ConsumptionsApi} from './endpoints/consumptions';
import {CustomActionsApi} from './endpoints/customActions';
import {CustomFunctionsApi} from './endpoints/customFunctions';
import {CustomPropertiesApi} from './endpoints/customProperty';
import {DataExportSchedulerApi} from './endpoints/dataExportScheduler';
import {DocumentTypesApi} from './endpoints/documentTypes';
import {DocumentsApi} from './endpoints/documents';
import {DosageApi} from './endpoints/dosage';
import {EventLogsApi} from './endpoints/eventLogs';
import {ExternalResourceApi} from './endpoints/externalResource';
import {ExternalResourceLogsApi} from './endpoints/externalResourceLogs';
import {FaultsApi} from './endpoints/faults';
import {FaultCollectionsApi} from './endpoints/faultCollections';
import {GatewaysApi} from './endpoints/gateways';
import {HierarchyApi} from './endpoints/hierarchy';
import {IngredientsApi} from './endpoints/ingredients';
import {InspectionEquipmentApi} from './endpoints/inspectionEquipment';
import {InspectionLotsApi} from './endpoints/inspectionLot';
import {InspectionPlansApi} from './endpoints/inspectionPlan';
import {InspectionTaskGroupsApi} from './endpoints/insepctionTaskGroup';
import {InspectionTaskResultsApi} from './endpoints/insepctionTaskResult';
import {InspectionTasksApi} from './endpoints/inspectionTask';
import {InspectionTasksPendingApi} from './endpoints/inspectionTasksPending';
import {InspectionVerificationsApi} from './endpoints/inspectionVerification';
import {InterruptionClassesApi} from './endpoints/interruptionClasses';
import {InterruptionReasonsApi} from './endpoints/interruptionReasons';
import {KeyPerformanceIndicatorApi} from './endpoints/keyPerformanceIndicator';
import {DataExportApi} from './endpoints/dataExport';
import {DataExportTemplateApi} from './endpoints/dataExportTemplate';
import {KeyPerformanceIndicatorLimitApi} from './endpoints/keyPerformanceIndicatorLimit';
import {KeyPerformanceIndicatorTargetApi} from './endpoints/keyPerformanceIndicatorTarget';
import {LdapConnectionApi} from './endpoints/ldapConnection';
import {LdapFieldMappingsApi} from './endpoints/ldapFieldMappings';
import {LdapGroupsApi} from './endpoints/ldapGroups';
import {MaterialSensorApi} from './endpoints/materialSensors';
import {MaterialsApi} from './endpoints/materials';
import {MessageDocumentsApi} from './endpoints/messageDocuments';
import {MessageReadsApi} from './endpoints/messageReads';
import {MessagesApi} from './endpoints/messages';
import {ObjectLocksApi} from './endpoints/objectLocks';
import {OperationPhaseLogsApi} from './endpoints/operationPhaseLogs';
import {OperationPhasesApi} from './endpoints/operationPhases';
import {OperationStateLogsApi} from './endpoints/operationStateLogs';
import {OperationStatesApi} from './endpoints/operationStates';
import {OperationsApi} from './endpoints/operations';
import {OrdersApi} from './endpoints/orders';
import {OrderTemplatesApi} from './endpoints/orderTemplates';
import {PermissionsApi} from './endpoints/permissions';
import {PersonnelLogsApi} from './endpoints/personnel';
import {PhaseTimeoutReasonsApi} from './endpoints/phaseTimeoutReasons';
import {PhaseTimeoutsApi} from './endpoints/phaseTimeouts';
import {PhasesApi} from './endpoints/phases';
import {PreparationsApi} from './endpoints/preparations';
import {ProcessDataConnectionApi} from './endpoints/processDataConnection';
import {ProcessDataPointApi} from './endpoints/processDataPoint';
import {ProcessDataSourcesApi} from './endpoints/processDataSource';
import {ProfileApi} from './endpoints/profile';
import {PrintAllocationsApi} from './endpoints/printAllocations';
import {PrintLabelsApi} from './endpoints/printLabels';
import {PrintersApi} from './endpoints/printers';
import {PrintJobsApi} from './endpoints/printJobs';
import {PrintJobLogsApi} from './endpoints/printJobLogs';
import {RelevantMessagesApi} from './endpoints/relevantMessages';
import {ReportLogsApi} from './endpoints/reportLogs';
import {ReportTypesApi} from './endpoints/reportTypes';
import {RolesApi} from './endpoints/roles';
import {SensorDataApi} from './endpoints/sensorData';
import {SensorDataLimitApi} from './endpoints/sensorDataLimit';
import {SensorDataTargetApi} from './endpoints/sensorDataTarget';
import {SensorsApi} from './endpoints/sensors';
import {SettingsApi} from './endpoints/settings';
import {ShiftBooksApi} from './endpoints/shiftBooks';
import {ShiftsApi} from './endpoints/shifts';
import {StateHistoriesApi} from './endpoints/stateHistories';
import {StorageAreasApi} from './endpoints/storageAreas';
import {StorageUnitsApi} from './endpoints/storageUnits';
import {StorageUnitLevelsApi} from './endpoints/storageUnitLevels';
import {StorageUnitLogsApi} from './endpoints/storageUnitLogs';
import {TerminalLayoutsApi} from './endpoints/terminalLayouts';
import {TerminalsApi} from './endpoints/terminals';
import {TransitionsApi} from './endpoints/transitions';
import {TransitionQueuesApi} from './endpoints/transitionQueues';
import {UnitOfMeasurementConversionsApi} from './endpoints/unitOfMeasurementConversion';
import {UnitsOfMeasurementApi} from './endpoints/unitsOfMeasurement';
import {UserRolesApi} from './endpoints/userRoles';
import {UsersApi} from './endpoints/users';
import {UserNotificationsApi} from './endpoints/userNotifications';
import {WorkflowCorrectionsApi} from './endpoints/workflowCorrections';
import {WorkflowsApi} from './endpoints/workflows';
import {WorkplaceGroupsApi} from './endpoints/workplaceGroups';
import {WorkplaceStateLogsApi} from './endpoints/workplaceStateLogs';
import {WorkplaceStatesApi} from './endpoints/workplaceStates';
import {WorkplaceStorageRelationsApi} from './endpoints/workplaceStorageRelations';
import {WorkplacesApi} from './endpoints/workplaces';
import {YieldApi} from './endpoints/yields';
import {YieldTypesApi} from './endpoints/yieldTypes';
import {ConfigTransferLogsApi} from './endpoints/configTransferLogs';
import {ConfigTransferFilesApi} from './endpoints/configTransferFiles';
import {ConfigTransferApi} from './endpoints/configTransfers';
import {QualityReportsApi} from './endpoints/qualityReports';
import {UserNotificationConfigApi} from './endpoints/userNotificationConfig';
import {ProductsApi} from './endpoints/products';
import {WidgetActionsApi} from './endpoints/widgetActions';

export const Api = {
  DataExportTemplate: DataExportTemplateApi,
  appConfig: AppConfigApi,
  auditLogs: AuditLogsApi,
  auth: AuthApi,
  batchGeneratorFunctions: BatchGeneratorFunctionApi,
  batchQueueItems: BatchQueueItemsApi,
  batchReport: BatchReportApi,
  batches: BatchesApi,
  cockpitDashboards: CockpitDashboardsApi,
  cockpitWidgets: CockpitWidgetsApi,
  cockpits: CockpitsApi,
  components: ComponentsApi,
  configTransferFiles: ConfigTransferFilesApi,
  configTransferLogs: ConfigTransferLogsApi,
  configTransfers: ConfigTransferApi,
  consumptions: ConsumptionsApi,
  customActions: CustomActionsApi,
  customFunctions: CustomFunctionsApi,
  dataExportApi: DataExportApi,
  dataExportSchedulers: DataExportSchedulerApi,
  documentTypes: DocumentTypesApi,
  documents: DocumentsApi,
  dosage: DosageApi,
  eventLogs: EventLogsApi,
  externalResourceLogs: ExternalResourceLogsApi,
  externalResources: ExternalResourceApi,
  faultCollections: FaultCollectionsApi,
  faults: FaultsApi,
  gateways: GatewaysApi,
  hierarchy: HierarchyApi,
  ingredients: IngredientsApi,
  inspectionEquipment: InspectionEquipmentApi,
  inspectionLot: InspectionLotsApi,
  inspectionPlan: InspectionPlansApi,
  inspectionTask: InspectionTasksApi,
  inspectionTaskGroup: InspectionTaskGroupsApi,
  inspectionTaskResult: InspectionTaskResultsApi,
  inspectionTasksPending: InspectionTasksPendingApi,
  inspectionVerification: InspectionVerificationsApi,
  interruptionClasses: InterruptionClassesApi,
  interruptionReasons: InterruptionReasonsApi,
  keyPerformanceIndicator: KeyPerformanceIndicatorApi,
  keyPerformanceIndicatorLimit: KeyPerformanceIndicatorLimitApi,
  keyPerformanceIndicatorTarget: KeyPerformanceIndicatorTargetApi,
  ldapConnection: LdapConnectionApi,
  ldapFieldMappings: LdapFieldMappingsApi,
  ldapGroups: LdapGroupsApi,
  materialSensors: MaterialSensorApi,
  materials: MaterialsApi,
  messageDocuments: MessageDocumentsApi,
  messageReads: MessageReadsApi,
  messages: MessagesApi,
  objectLocks: ObjectLocksApi,
  operationPhaseLog: OperationPhaseLogsApi,
  operationPhases: OperationPhasesApi,
  operationStateLogs: OperationStateLogsApi,
  operationStates: OperationStatesApi,
  operations: OperationsApi,
  orderTemplates: OrderTemplatesApi,
  orders: OrdersApi,
  permissions: PermissionsApi,
  personnelLogs: PersonnelLogsApi,
  phaseTimeoutReasons: PhaseTimeoutReasonsApi,
  phaseTimeouts: PhaseTimeoutsApi,
  phases: PhasesApi,
  preparations: PreparationsApi,
  printAllocations: PrintAllocationsApi,
  printJobLogs: PrintJobLogsApi,
  printJobs: PrintJobsApi,
  printLabels: PrintLabelsApi,
  printers: PrintersApi,
  processDataConnections: ProcessDataConnectionApi,
  processDataPoints: ProcessDataPointApi,
  processDataSources: ProcessDataSourcesApi,
  products: ProductsApi,
  profiles: ProfileApi,
  properties: CustomPropertiesApi,
  qualityReport: QualityReportsApi,
  relevantMessages: RelevantMessagesApi,
  reportLogs: ReportLogsApi,
  reportTypes: ReportTypesApi,
  roles: RolesApi,
  sensorData: SensorDataApi,
  sensorDataLimit: SensorDataLimitApi,
  sensorDataTarget: SensorDataTargetApi,
  sensors: SensorsApi,
  settings: SettingsApi,
  shiftBooks: ShiftBooksApi,
  shifts: ShiftsApi,
  stateHistories: StateHistoriesApi,
  storageAreas: StorageAreasApi,
  storageUnitLevels: StorageUnitLevelsApi,
  storageUnitLogs: StorageUnitLogsApi,
  storageUnits: StorageUnitsApi,
  terminalLayouts: TerminalLayoutsApi,
  terminals: TerminalsApi,
  transitionQueues: TransitionQueuesApi,
  transitions: TransitionsApi,
  unitOfMeasurementConversions: UnitOfMeasurementConversionsApi,
  unitsOfMeasurement: UnitsOfMeasurementApi,
  userNotificationConfig: UserNotificationConfigApi,
  userNotifications: UserNotificationsApi,
  userRoles: UserRolesApi,
  users: UsersApi,
  widgetActions: WidgetActionsApi,
  workflowCorrections: WorkflowCorrectionsApi,
  workflows: WorkflowsApi,
  workplaceGroups: WorkplaceGroupsApi,
  workplaceStateLogs: WorkplaceStateLogsApi,
  workplaceStates: WorkplaceStatesApi,
  workplaceStorageRelations: WorkplaceStorageRelationsApi,
  workplaces: WorkplacesApi,
  yieldTypes: YieldTypesApi,
  yields: YieldApi,
};
