import { observable, makeObservable } from 'mobx';
import { Dayjs } from 'dayjs';
import { AxiosResponse } from 'axios';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { SensorDataTarget } from '../models/sensorDataTarget';
import { byTimeEndpoint } from '../middleware/endpoints/sensorDataTarget';
import { RootStore } from './rootStore';

export class SensorDataTargetStore extends EntityStore<SensorDataTarget> {
  sensorDataTargets: SensorDataTarget[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'sensorDataTargets', Api.sensorDataTarget, SensorDataTarget);

    makeObservable(this, {
      sensorDataTargets: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.sensorStore,
        modelId: 'sensorName',
      },
      {
        store: this.rootStore.workplaceStore,
        modelId: 'workplaceId',
      },
      {
        store: this.rootStore.materialStore,
        modelId: 'materialId',
      },
    ];
  }

  loadByTime(sensorName: string, workplaceId: number, fromDate: Dayjs, toDate: Dayjs) {
    return this.api[byTimeEndpoint]({
      params: { sensorName, workplaceId, fromDate: fromDate.toISOString(), toDate: toDate.toISOString() },
    })
      .then((response: AxiosResponse) => response.data || [])
      .catch((e: Error) => this.handleApiError(e, byTimeEndpoint));
  }
}
