import React, { CSSProperties } from 'react';
import { get } from 'lodash';
import { observer } from 'mobx-react-lite';
import Descriptions, { DescriptionsProps } from './Descriptions';
import Spinner from '../spinners/Spinner';
import Label from '../dataDisplay/Label';
import Empty from '../empty/Empty';
import { EnDash } from '../unicodeWrapper/EnDash';
import Barcode, { BarcodeOptions, BarcodeType } from '../barcode/Barcode';
import { DisplayableProperty } from '../../../models/displayableProperty';
import { BaseModel } from '../../../models/base';

export type ModelPropertiesProps = DescriptionsProps & {
  column?: number;
  loading?: boolean;
  properties: DisplayableProperty[];
  barcodeProperties?: string[];
  barcodeOptions?: BarcodeOptions;
  qrCodeProperties?: string[];
  qrCodeOptions?: BarcodeOptions;
  dataMatrixProperties?: string[];
  dataMatrixOptions?: BarcodeOptions;
  model: BaseModel;
  title?: string;
  showPropertyLabels?: boolean;
  hideEmpty?: boolean;
  itemLabelStyle?: CSSProperties;
};

const ModelProperties: React.FC<ModelPropertiesProps> = ({
  column = 1,
  loading = false,
  properties,
  barcodeProperties = [],
  barcodeOptions,
  qrCodeProperties = [],
  qrCodeOptions,
  dataMatrixProperties = [],
  dataMatrixOptions,
  model,
  title = undefined,
  showPropertyLabels = true,
  hideEmpty = false,
  itemLabelStyle = undefined,
  ...props
}) => {
  if (loading) {
    return <Spinner fullWidth/>;
  }

  if (!properties || !properties.length || !model) {
    return <Empty/>;
  }

  if (hideEmpty) {
    properties = properties.filter((property) => !!property.render(model, model, 0).children);
  }

  /**
   * Helper function to get the barcode type from the property key
   */
  function getBarcodeType(key: string): BarcodeType | undefined {
    let barcodeType;
    if (barcodeProperties.includes(key)) {
      barcodeType = BarcodeType.Barcode;
    } else if (qrCodeProperties.includes(key)) {
      barcodeType = BarcodeType.QrCode;
    } else if (dataMatrixProperties.includes(key)) {
      barcodeType = BarcodeType.DataMatrix;
    }
    return barcodeType;
  }

  /**
   * Helper function to get the barcode options from the barcode type
   */
  function getBarcodeOptionsByType(type: BarcodeType): BarcodeOptions | undefined {
    let options;
    if (type === BarcodeType.Barcode) {
      options = barcodeOptions;
    } else if (type === BarcodeType.QrCode) {
      options = qrCodeOptions;
    } else if (type === BarcodeType.DataMatrix) {
      options = dataMatrixOptions;
    }
    return options;
  }

  properties = properties.map((property) => {
    const newProperty = { ...property };

    const barcodeType = getBarcodeType(property.key);
    if (barcodeType) {
      newProperty.render = (text, propModel) => {
        if (property.customProperty) {
          property.render = (t, pM) => {
            const param = property.params && property.params[0];
            if (!param) {
              return { children: null };
            }
            return {
              children: get(pM, param.path.replace(/\.+$/, ''), {}),
            };
          };
        }
        const value = property.render(text, propModel, 0);
        if (value.children === EnDash() || value.children === '') {
          return { children: EnDash() };
        }
        const options = getBarcodeOptionsByType(barcodeType);
        return {
          children: (
            <div style={{ width: '100%', textAlign: 'center', marginTop: '-2px', marginBottom: '-8px' }}>
              <Barcode
                type={barcodeType}
                value={value.children}
                width={options?.width}
                height={options?.height}
                displayValue={options?.displayValue}
              />
            </div>
          ),
        };
      };
    }

    return newProperty;
  });

  return (
    <Descriptions column={column} title={title} {...props} bordered={!!properties.length}>
      {properties.map((property) => (
        <Descriptions.Item
          key={property.key}
          label={showPropertyLabels ? <Label style={itemLabelStyle}>{property.title}</Label> : undefined}
        >
          {property.render(model, model, 0).children}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
};

export default observer(ModelProperties);
