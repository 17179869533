import { observable, makeObservable, computed } from 'mobx';
import { faBoxArchive } from '@fortawesome/pro-solid-svg-icons';
import { Observer } from 'mobx-react-lite';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { sortAlphabetically, sortChronologically, sortNumerically } from '../components/shared/tables/sorters';
import { Material } from './material';
import { Batch } from './batch';
import { transformation } from '../utils/transformations';
import { StorageArea } from './storageArea';
import { StorageUnit } from './storageUnit';
import { datetimeFormat } from '../config/dayjs';
import UserFullName from '../components/shared/UserFullName';
import { RootStore } from '../stores/rootStore';

/**
 * storage unit log operations translations:
 * t('storageUnitLog.operations.PUT')
 * t('storageUnitLog.operations.REMOVE')
 * t('storageUnitLog.operations.MOVE')
 */
export enum StorageUnitOperation {
  Put = 'PUT',
  Remove = 'REMOVE',
  Move = 'MOVE'
}

export class StorageUnitLog extends BaseModel {
  id: number = 0;
  operation: StorageUnitOperation = StorageUnitOperation.Put;
  storageAreaId: number | null = null;
  storageUnitId: number | null = null;
  parentStorageUnitId: number | null = null;
  amount: number | null = null;
  amountDiff: number | null = null;
  materialId: number | null = null;
  batchId: number | null = null;
  isCorrection: boolean = false;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      operation: observable,
      storageAreaId: observable,
      storageArea: computed,
      storageUnitId: observable,
      storageUnit: computed,
      parentStorageUnitId: observable,
      parentStorageUnit: computed,
      amount: observable,
      amountDiff: observable,
      materialId: observable,
      material: computed,
      batchId: observable,
      batch: computed,
      isCorrection: observable,
    });
  }

  static faIcon = faBoxArchive;

  searchableProperties = ['storageUnit.no', 'material.name'];

  displayableProperties = [
    displayableProperty({
      key: 'createdAt',
      title: i18n.t('storageUnitLog.model.attributes.createdAt'),
      params: [displayablePropertyParam({
        path: 'createdAt', transform: transformation.datetime({ format: datetimeFormat }),
      })],
      sorter: (a, b) => sortChronologically(a.createdAt, b.createdAt),
    }),
    displayableProperty({
      title: i18n.t('storageUnitLog.model.attributes.createdBy'),
      params: [displayablePropertyParam({
        path: 'user',
        transform: (user) => (
          <Observer>
            {() => <UserFullName user={user}/>}
          </Observer>
        ),
      })],
      raw: true,
      template: '{value}',
      key: 'createdBy',
      sorter: (a, b) => sortAlphabetically(a.createdBy.fullName, b.createdBy.fullName),
    }),
    displayableProperty({
      key: 'operation',
      title: i18n.t('storageUnitLog.model.attributes.operation'),
      params: [
        displayablePropertyParam({
          path: 'operation', transform: (operation) => i18n.t(`storageUnitLog.operations.${operation}`),
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.operation, b.operation),
    }),
    displayableProperty({
      key: 'amount',
      title: i18n.t('storageUnitLog.model.attributes.amount'),
      params: [
        displayablePropertyParam({
          path: 'amount', transform: transformation.none,
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortNumerically(a.amount, b.amount),
    }),
    displayableProperty({
      key: 'amountDiff',
      title: i18n.t('storageUnitLog.model.attributes.amountDiff'),
      params: [
        displayablePropertyParam({
          path: 'amountDiff', transform: transformation.none,
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortNumerically(a.amountDiff, b.amountDiff),
    }),
  ];

  get storageArea() {
    return this.rootStore.storageAreaStore.getById(this.storageAreaId);
  }

  get storageUnit() {
    return this.rootStore.storageUnitStore.getById(this.storageUnitId);
  }

  get parentStorageUnit() {
    return this.rootStore.storageUnitStore.getById(this.parentStorageUnitId);
  }

  get material() {
    return this.rootStore.materialStore.getById(this.materialId);
  }

  get batch() {
    return this.rootStore.batchStore.getById(this.batchId);
  }

  get user() {
    return this.rootStore.userStore.getById(this.createdBy);
  }

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);

    const storageAreaPrefix = `${titlePrefix}${i18n.t('storageArea.model.one')} > `;
    allDisplayableProperties.push(
      ...StorageArea.allDisplayableProperties(rootStore, `${pathPrefix}storageArea.`, storageAreaPrefix)
    );

    const storageUnitPrefix = `${titlePrefix}${i18n.t('storageUnit.model.one')} > `;
    allDisplayableProperties.push(
      ...StorageUnit.allDisplayableProperties(rootStore, `${pathPrefix}storageUnit.`, storageUnitPrefix)
    );

    const parentStorageUnitPrefix = `${titlePrefix}${i18n.t('storageUnit.model.attributes.parentStorageUnit')} > `;
    allDisplayableProperties.push(
      ...StorageUnit.allDisplayableProperties(rootStore, `${pathPrefix}parentStorageUnit.`, parentStorageUnitPrefix)
    );

    const materialPrefix = `${titlePrefix}${i18n.t('material.model.one')} > `;
    allDisplayableProperties.push(
      ...Material.allDisplayableProperties(rootStore, `${pathPrefix}material.`, materialPrefix)
    );

    const batchPrefix = `${titlePrefix}${i18n.t('batch.model.one')} > `;
    allDisplayableProperties.push(
      ...Batch.allDisplayableProperties(rootStore, `${pathPrefix}batch.`, batchPrefix)
    );

    return allDisplayableProperties;
  }
}
