import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Empty, Layout, Space} from 'antd';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import MessageListItem from './MessageListItem';
import MessageItemContent from './MessageItemContent';
import Button from '../shared/buttons/Button';
import Spinner from '../shared/spinners/Spinner';
import {useStore} from '../../hooks/useStore';
import {useModulePolicy} from '../../hooks/useModulePolicy';
import {useMount} from '../../hooks/useMount';

const {Footer, Content} = Layout;

const MessageViewer = ({messages = [], isLoading = false, allowMarkAsRead = false}) => {
  const store = useStore();
  const {t} = useTranslation();
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const policy = useModulePolicy(store, ['message-read']);

  useMount(() => {
    if (store.workplaceStore.selectedWorkplace?.id) {
      // show first (unread) message when opening, if no message has been selected before
      // and marking as read is allowed
      if (allowMarkAsRead
        && policy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})
        && messages.length > 0
        && !selectedMessageId
      ) {
        const unreadMessages = messages.filter((message) => message.isUnread);
        if (unreadMessages.length > 0) {
          setSelectedMessageId(unreadMessages[0].id);
        } else {
          setSelectedMessageId(messages[0].id);
        }
      } else {
        setSelectedMessageId(null);
      }
    }
  }, [store.workplaceStore.selectedWorkplace?.id, messages.length]);

  const onMessageSelect = ({id}) => {
    setSelectedMessageId(id);
  };

  const markMessageAsRead = () => {
    if (!store.messageReadStore.messageReads.find(
      (mr) => mr.messageId === selectedMessageId && mr.userId === store.authStore.user?.id
    )
    ) {
      store.messageReadStore.create({
        messageId: selectedMessageId,
        timestamp: dayjs(),
      }, {skipNotification: true}).then(() => {
        store.messageStore.addMessage({type: 'success', title: t('flashMessages.messageReadSuccess')}, {});
      });
    }
  };

  if (isLoading) {
    return <Spinner fullWidth/>;
  }

  const selectedMessage = messages?.find((message) => message.id === selectedMessageId);

  return (
    <Space size={'middle'} direction={'vertical'} style={{display: 'flex'}}>
      {messages?.length > 0
        ? (
          <Layout style={{height: '100%'}}>
            <Layout.Sider
              width={400}
              style={{
                backgroundColor: '#ffffff',
                borderRight: '1px solid #f0f0f0',
                overflowY: 'auto',
              }}
            >
              {messages.map((message) => (
                <MessageListItem
                  key={message.id}
                  message={message}
                  isSelected={message.id === selectedMessageId}
                  onSelect={onMessageSelect}
                />
              ))}
            </Layout.Sider>
            <Layout>
              <Content
                style={{
                  padding: 24,
                  paddingRight: 56,
                  backgroundColor: '#ffffff',
                  borderBottom: '1px solid #f0f0f0',
                  overflowY: 'auto',
                }}
              >
                {selectedMessage
                  ? (
                    <MessageItemContent
                      message={selectedMessage}
                      sender={selectedMessage.author}
                    />
                  )
                  : <Empty description={t('message.noSelection')}/>}
              </Content>
              {allowMarkAsRead && policy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})
                ? (
                  <Footer style={{padding: '24px 56px 24px 24px', backgroundColor: '#ffffff'}}>
                    {selectedMessageId
                      ? (
                        <Button
                          onClick={markMessageAsRead}
                          type="primary"
                          style={{float: 'right'}}
                          disabled={!selectedMessage?.isUnread}
                        >
                          {t('message.markAsRead')}
                        </Button>
                      )
                      : ''}
                  </Footer>
                )
                : ''}
            </Layout>
          </Layout>
        )
        : (
          <Empty description={t('message.empty')}/>
        )}
    </Space>
  );
};

export default observer(MessageViewer);
