import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { ReportType, ReportTypeEnum } from '../models/reportType';
import { RootStore } from './rootStore';

export class ReportTypeStore extends EntityStore<ReportType> {
  reportTypes: ReportType[] = [];

  // TODO: replace with 'type'
  identityField = 'id';

  constructor(rootStore: RootStore) {
    super(rootStore, 'reportTypes', Api.reportTypes, ReportType);

    makeObservable(this, {
      reportTypes: observable,
    });
  }

  getByType(type: ReportTypeEnum) {
    return this.reportTypes.find((c) => c.type === type);
  }
}
