import {useCallback, useState} from 'react';
import {debounce} from 'lodash';
import {Form} from 'antd';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {useStore} from '../../../hooks/useStore';
import ScanInput from '../../shared/inputs/ScanInput';
import {FormItemLayouts} from '../../shared/form/formItemLayouts';
import i18n from '../../../i18n/i18n';

const ScanStorageUnit = ({storageUnit, onChangeStorageUnit, operationDisabled, widgetDisabled}) => {
  const [form] = Form.useForm();
  const store = useStore();
  const {t} = useTranslation();
  const [isValid, setIsValid] = useState(false);

  const queryByNo = (queryNo) => {
    store.storageUnitStore.getByNo(queryNo).then((unit) => {
      if (queryNo === form.getFieldValue('storageUnitNo')) {
        setIsValid(!!unit);
      }
    });
  };
  const debouncedQuery = useCallback(debounce((q) => queryByNo(q), 500), []);
  const handleValuesChange = (changedValues) => {
    setIsValid(false);
    if (changedValues?.storageUnitNo) {
      debouncedQuery(changedValues?.storageUnitNo);
    }
  };

  const setStorageUnit = (storageUnitNo) => {
    debouncedQuery.cancel();
    store.storageUnitStore.getOrCreateByNo(storageUnitNo).then((storageUnitToAdd) => {
      setIsValid(false);
      if (storageUnitToAdd) {
        onChangeStorageUnit(storageUnitToAdd);
        form.setFieldsValue({storageUnitNo: null});
      } else {
        store.flashMessageStore.addFlashMessage({
          type: 'warning',
          title: i18n.t('batchHandling.invalidStorageUnit'),
        });
      }
    });
  };

  return (
    <Form
      form={form}
      onValuesChange={handleValuesChange}
      labelAlign={'left'}
      {...FormItemLayouts.fullWidth}
    >
      <Form.Item
        name={'storageUnitNo'}
      >
        <ScanInput
          disabled={storageUnit || operationDisabled}
          isValid={isValid}
          onAccept={setStorageUnit}
          placeholder={t('batchHandling.enterBarcode')}
          autoFocus={!widgetDisabled}
        />
      </Form.Item>
    </Form>
  );
};

export default observer(ScanStorageUnit);
