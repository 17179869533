import React, {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import Alert from 'antd/es/alert';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {useStore} from '../../../../hooks/useStore';
import {KpiPerOrderCockpitWidgetConfig} from './config/kpiPerOrderCockpitWidgetConfig';
import CockpitWidget from './CockpitWidget';
import {WidgetLayout} from '../../../../models/widgetLayout';
import {WidgetLayouts} from '../../../shared/widgets/WidgetLayouts';
import KpiPerOrderChart from './KpiPerOrderChart';
import {useMount} from '../../../../hooks/useMount';
import useWorkplaceRelatedWidgetTitle from '../../../../hooks/useWorkplaceRelatedWidgetTitle';

const KpiPerOrderCockpitWidget = ({
  widgetId,
  title,
  ...props
}) => {
  const store = useStore();
  const {t} = useTranslation();
  const widgetConfig = useMemo(() => new KpiPerOrderCockpitWidget.Config({
    rootStore: store,
    identifier: KpiPerOrderCockpitWidget.identifier,
    widgetId,
  }), []);
  const [selectedWorkplaceId, setSelectedWorkplaceId] = useState(null);
  const widgetTitle = useWorkplaceRelatedWidgetTitle(title, selectedWorkplaceId);

  const daysBack = widgetConfig.getSettingValue('kpiPerOrder', 'daysBack');
  const yAxisTitle = widgetConfig.getSettingValue('kpiPerOrder', 'yAxisTitle');
  const barKpiId = widgetConfig.getSettingValue('kpiPerOrder', 'barKpiId');
  const lineKpiIds = widgetConfig.getSettingValue('kpiPerOrder', 'lineKpiIds');

  useMount(() => {
    setSelectedWorkplaceId(
      store.workplaceStore.getByHierarchyId(widgetConfig.getSettingValue('workplace', 'hierarchyId'))?.id
      || store.cockpitStore.currentWorkplaceId || undefined
    );
  }, [
    store.cockpitStore.currentWorkplaceId,
    widgetConfig.getSettingValue('workplace', 'hierarchyId'),
  ]);

  return (
    <CockpitWidget
      widgetId={widgetId}
      title={widgetTitle}
      widgetConfig={widgetConfig}
      {...props}
    >
      {
        !selectedWorkplaceId
          ? <Alert type={'info'} message={t('kpiPerOrderCockpitWidget.alert.noWorkplaceSelected')}/>
          : ''
      }
      {
        selectedWorkplaceId
          ? (
            <KpiPerOrderChart
              yAxisTitle={yAxisTitle}
              daysBack={daysBack}
              fromDate={dayjs().startOf('day').subtract(daysBack, 'days').toISOString()}
              toDate={dayjs().startOf('day').toISOString()}
              barKpiId={barKpiId}
              lineKpiIds={lineKpiIds}
              workplaceId={selectedWorkplaceId}
            />
          )
          : ''
      }
    </CockpitWidget>
  );
};

KpiPerOrderCockpitWidget.Config = KpiPerOrderCockpitWidgetConfig;
KpiPerOrderCockpitWidget.defaultLayout = new WidgetLayout({
  identifier: CockpitWidget.identifier,
  width: 4,
  height: 16,
  minHeight: 4,
  minWidth: WidgetLayouts.sixthWidth.minW,
});
export default observer(KpiPerOrderCockpitWidget);
