import React, {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import Alert from 'antd/es/alert';
import {useTranslation} from 'react-i18next';
import {faLayerPlus} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {WidgetLayout} from '../../models/widgetLayout';
import {WidgetLayouts} from '../shared/widgets/WidgetLayouts';
import OperatorWidget from '../operator/shared/OperatorWidget';
import {useStore} from '../../hooks/useStore';
import {ScopeContext} from '../../policies/scopeContext';
import {unrestricted} from '../../models/scope';
import {BatchQueueWidgetConfig} from './batchQueueWidgetConfig';
import WidgetSettingsModal from '../shared/widgets/WidgetSettingsModal';
import {useMount} from '../../hooks/useMount';
import BatchQueueTable from './BatchQueueTable';
import BatchQueueSetupModal from './BatchQueueSetupModal';
import {BatchQueueItemState} from '../../models/batchQueueItem';
import {useMQTTBatchQueueItemSubscription} from '../../hooks/useMQTT';
import {SensorType} from '../../models/sensor';
import {handleError} from '../../middleware/utils';
import Collapse from '../shared/collapse/Collapse';
import List from '../shared/lists/List';
import Switch from '../shared/inputs/Switch';
import widgetStyles from '../operator/shared/OperatorWidget.module.scss';
import BatchQueueResetModal from './BatchQueueResetModal';

const BatchQueueWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const {t} = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);
  const [setupModalVisible, setSetupModalVisible] = useState(false);
  const [setupData, setSetupData] = useState(undefined);
  const [resetModalVisible, setResetModalVisible] = useState(false);
  const [resetData, setResetData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const widgetConfig = useMemo(() =>
    new BatchQueueWidgetConfig(store, identifier), []);
  const [noActiveOperation, setNoActiveOperation] = useState(false);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);

  const loadMaterialSensors = async () => store.materialSensorStore.loadAll({
    params: {
      sensorName: store.sensorStore.getByHierarchyIdAndSensorType(
        store.workplaceStore.selectedWorkplace.hierarchyId,
        SensorType.COMPONENT_PREPARE
      ).map((s) => s.name),
    },
  });

  useMount(() => {
    if (store.operationStore.active?.id) {
      setIsLoading(true);
      setNoActiveOperation(false);

      store.componentStore
        .loadAllWithDependencies({
          dependencies: [
            {
              store: store.materialStore,
              modelId: 'materialId',
            },
          ],
          params: {operationId: store.operationStore.active?.id},
        })
        .then(() => store.batchQueueItemStore
          .loadAllWithDependencies({
            dependencies: [
              {
                store: store.batchStore,
                modelId: 'batchId',
              },
            ],
            params: {
              operationId: store.operationStore.active.id,
              state: [
                BatchQueueItemState.ACTIVE,
                BatchQueueItemState.PLANNED,
              ],
            },
          }))
        .then(() => store.sensorStore.loadAll({
          params: {
            hierarchyId: store.workplaceStore.selectedWorkplace.hierarchyId,
            type: SensorType.COMPONENT_PREPARE,
            includeHierarchyChildren: true,
          },
        }))
        .then(() => loadMaterialSensors())
        .then(() => setIsLoading(false))
        .catch((err) => handleError(err));
    } else {
      setNoActiveOperation(true);
    }
  }, [store.operationStore.active?.id]);

  useMQTTBatchQueueItemSubscription(store, disabled);

  const onSubmitSettings = (settingValues) => {
    store.settingStore.createOrUpdate(
      store.terminalLayoutStore.currentTerminalLayout.id,
      store.workplaceStore.selectedWorkplace.id,
      widgetConfig.settingsIdentifier,
      settingValues
    );
    setModalVisible(false);
  };

  const setMaterialSensor = (component) => (sensorName) => {
    if (!sensorName) {
      return;
    }
    const entity = {materialId: component.materialId, sensorName};
    if (store.materialSensorStore.getById(sensorName)) {
      store.materialSensorStore.update(entity).then(() => loadMaterialSensors());
    } else {
      store.materialSensorStore.create(entity).then(() => loadMaterialSensors());
    }
  };

  const clearMaterialSensor = (sensorName) => {
    store.materialSensorStore.delete(sensorName).then(() => loadMaterialSensors());
  };

  const onSubmitNewBatch = (formValues) => {
    store.batchQueueItemStore.create({
      workplaceId: store.workplaceStore.selectedWorkplace.id,
      materialId: setupData.materialId,
      batchId: formValues.batchId,
      quantity: formValues.quantity,
      quantityUsed: 0,
      plannedAt: dayjs().toISOString(),
    }).then(() => store.batchStore.load(formValues.batchId)).then(() => {
      setSetupData(undefined);
      setSetupModalVisible(false);
    });
  };

  const onSubmitResetQueue = (formValues) => {
    if (!formValues.batchQueueItemsToDelete?.length) {
      return;
    }
    store.batchQueueItemStore.cancelQueue(
      store.workplaceStore.selectedWorkplace.id,
      resetData.component.material?.id,
      formValues.batchQueueItemsToDelete
    ).then(() => {
      setResetModalVisible(false);
      setResetData(undefined);
    });
  };

  const onCancelSetupQueueItem = () => {
    setSetupModalVisible(false);
    setSetupData(undefined);
  };

  const onCancelResetQueueItem = () => {
    setResetModalVisible(false);
    setResetData(undefined);
  };

  const loading = isLoading
    || store.batchQueueItemStore.isLoadingCollection
    || store.materialSensorStore.hasPendingRequests;

  const toggleSensorState = (sensor) => {
    setIsUpdateInProgress(true);
    store.sensorStore.update({
      ...sensor,
      enabled: !sensor.enabled,
    }).then(() => {
      setIsUpdateInProgress(false);
    });
  };

  const yieldReportingDisabled = store.operationStore.active?.order?.isYieldReportingDisabled;
  const prepareSensors = store.sensorStore.getByHierarchyIdAndSensorType(
    store.workplaceStore.selectedWorkplace.hierarchyId,
    SensorType.COMPONENT_PREPARE
  );

  return (
    <ScopeContext.Provider value={[unrestricted]}>
      <OperatorWidget
        title={widgetConfig.getWidgetTitle()}
        icon={<FontAwesomeIcon icon={faLayerPlus}/>}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        widgetClassNames={yieldReportingDisabled ? [widgetStyles.disabled] : []}
      >
        {
          yieldReportingDisabled && (
            <Alert type={'info'} message={t('batchQueueWidget.alert.reportingDisabledOrder')} showIcon/>
          )
        }
        {!loading && noActiveOperation
          ? (
            <Alert
              style={{marginBottom: '1em'}}
              message={t('batchQueueWidget.alert.noActiveOperation')}
              type="info"
              showIcon
            />
          )
          : ''}
        {!yieldReportingDisabled
          && !noActiveOperation && widgetConfig.showSensorStateManagementList() && prepareSensors.length > 0 && (
          <Collapse defaultActiveKey={[]} style={{marginBottom: '1em'}}>
            <Collapse.Panel key="prepareSensors" header={t('batchQueueWidget.prepareSensors.title')}>
              <List
                dataSource={prepareSensors}
                renderItem={(sensor) => (
                  <List.Item key={sensor.name} onClick={() => toggleSensorState(sensor)} style={{cursor: 'pointer'}}>
                    <List.Item.Meta
                      title={sensor.label}
                      description={sensor.name}
                    />
                    <Switch loading={isUpdateInProgress} disabled={isUpdateInProgress} checked={sensor.enabled}/>
                  </List.Item>
                )}
              />
            </Collapse.Panel>
          </Collapse>
        )}
        {!yieldReportingDisabled && !noActiveOperation && (
          <BatchQueueTable
            loading={loading}
            widgetConfig={widgetConfig}
            onCancelSetupQueueItem={onCancelSetupQueueItem}
            setSetupModalVisible={setSetupModalVisible}
            setSetupData={setSetupData}
            setResetModalVisible={setResetModalVisible}
            setResetData={setResetData}
            onSetMaterialSensor={setMaterialSensor}
            onClearMaterialSensor={clearMaterialSensor}
          />
        )}
        {!yieldReportingDisabled && modalVisible && (
          <WidgetSettingsModal
            widgetConfig={widgetConfig}
            onCancel={() => setModalVisible(false)}
            onOk={onSubmitSettings}
          />
        )}
        {!yieldReportingDisabled && setupModalVisible && (
          <BatchQueueSetupModal
            onCancel={onCancelSetupQueueItem}
            onOk={onSubmitNewBatch}
            setupData={setupData}
          />
        )}
        {!yieldReportingDisabled && resetModalVisible && (
          <BatchQueueResetModal
            onCancel={onCancelResetQueueItem}
            onOk={onSubmitResetQueue}
            resetData={resetData}
          />
        )}
      </OperatorWidget>
    </ScopeContext.Provider>
  );
};

BatchQueueWidget.icon = faLayerPlus;

BatchQueueWidget.identifier = 'BatchQueueWidget';
BatchQueueWidget.defaultLayout = new WidgetLayout(
  {
    identifier: BatchQueueWidget.identifier,
    x: 0,
    y: 10,
    height: 6,
    minHeight: 4,
    width: WidgetLayouts.halfWidth.w,
    minWidth: WidgetLayouts.halfWidth.minW,
  }
);

export default observer(BatchQueueWidget);
