import { observable, makeObservable } from 'mobx';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import {
  ExternalResourceLog,
  ExternalResourceLogReference,
} from '../models/externalResourceLog';
import i18n from '../i18n/i18n';
import { RootStore } from './rootStore';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';

export class ExternalResourceLogStore extends EntityStore<ExternalResourceLog> {
  externalResourceLogs: ExternalResourceLog[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'externalResourceLogs', Api.externalResourceLogs, ExternalResourceLog);

    makeObservable(this, {
      externalResourceLogs: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.externalResourceStore,
        modelId: 'externalResourceId',
      },
    ];
  }

  async loadAll(paramOptions = {}) {
    const res = await super.loadAll(paramOptions);
    const orderIds: number[] = [];
    const materialIds: number[] = [];
    const inspectionLotIds: number[] = [];
    this.externalResourceLogs.forEach((log) => {
      switch (log.reference) {
        case ExternalResourceLogReference.Order:
          orderIds.push(log.referenceId);
          break;
        case ExternalResourceLogReference.Material:
          materialIds.push(log.referenceId);
          break;
        case ExternalResourceLogReference.InspectionLot:
          inspectionLotIds.push(log.referenceId);
          break;
        default:
      }
    });
    if (orderIds.length > 0) {
      await this.rootStore.orderStore.loadMany(orderIds);
    }
    if (materialIds.length > 0) {
      await this.rootStore.materialStore.loadMany(materialIds);
    }
    if (inspectionLotIds.length > 0) {
      await this.rootStore.inspectionLotStore.loadMany(inspectionLotIds);
    }
    return res;
  }

  async bulkUpdate(externalResourceLogs: ExternalResourceLog[]) {
    await this.api.bulkUpdate(externalResourceLogs);
    this.addMessage(
      new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.updateSuccess')),
      { skipNotification: false }
    );
    this.clear();
  }
}
