import { action, computed, makeObservable, observable } from 'mobx';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { MessageRead } from '../models/messageRead';
import { RootStore } from './rootStore';

export class MessageReadStore extends EntityStore<MessageRead> {
  messageReads: MessageRead[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'messageReads', Api.messageReads, MessageRead);

    makeObservable(this, {
      messageReads: observable,
      numberOfUnreadMessagesForCurrentUser: computed,
      loadByMessageId: action,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.userStore,
        modelId: 'userId',
      },
    ];
  }

  getByMessageId(messageId: number) {
    return this.messageReads.filter((mr) => mr.messageId === messageId);
  }

  getByMessageIdAndUserId(messageId: number, userId?: number) {
    return this.messageReads.find((mr) => mr.messageId === messageId && mr.userId === userId);
  }

  loadByMessageId(messageId: number) {
    this.messageReads = this.messageReads.filter((mr) => mr.messageId !== messageId);
    return this.loadAll({ params: { messageId } });
  }

  get numberOfUnreadMessagesForCurrentUser() {
    const currentUserId = this.rootStore.authStore.user?.id;
    if (!currentUserId) {
      return 0;
    }

    const nRelevantMessages = this.rootStore.relevantMessagesStore.relevantMessages.length;
    const readCount = this.rootStore.relevantMessagesStore.relevantMessages
      .filter((message) => this.getByMessageIdAndUserId(message.id, currentUserId)).length;

    const result = nRelevantMessages - readCount;

    if (result < 0) {
      return 0;
    }

    return result;
  }
}
