import * as React from 'react';
import {useMemo, useState} from 'react';
import {useMeasure} from 'react-use';
import {Col, Row, Space} from 'antd';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import {faIndustry, faServer} from '@fortawesome/free-solid-svg-icons';
import {faSignalStream} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useMount} from '../../hooks/useMount';
import {WidgetLayouts} from '../shared/widgets/WidgetLayouts';
import StateHistoryDetailModal from './StateHistoryDetailModal';
import {WidgetLayout} from '../../models/widgetLayout';
import OperatorWidget from '../operator/shared/OperatorWidget';
import {StatusLine} from './StatusLine';
import {StateHistoryWidgetConfig} from './stateHistoryWidgetConfig';
import appConfig from '../../utils/appConfig';
import {useStore} from '../../hooks/useStore';
import {ScopeContext} from '../../policies/scopeContext';
import {workflow} from '../../models/scope';
import TimelineChart from '../shared/charts/TimelineChart';
import styles from '../shared/charts/timelineChart.module.scss';
import useTimedRerender from '../../hooks/useTimedRerender';
import {getReducedByInterruptionsMinDuration} from './stateHistoryUtils';

const defaultColor = appConfig.corporateIdentity.aspectColor;

const StateHistoryWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const [modalVisible, setModalVisible] = useState(false);
  const {t} = useTranslation();
  const workplaceId = store.workplaceStore.selectedWorkplace?.id;
  const widgetConfig = useMemo(() =>
    new StateHistoryWidgetConfig(store, StateHistoryWidget.identifier), []);
  const [ref, bounds] = useMeasure();

  const enableTimeline = widgetConfig.getSettingValue('settings', 'enableTimeline');
  const enableOperationState = widgetConfig.getSettingValue('settings', 'enableOperationState');
  const enableWorkplaceState = widgetConfig.getSettingValue('settings', 'enableWorkplaceState');
  const timelineLength = widgetConfig.getSettingValue('settings', 'timelineLength');
  const showInterruptionsMinDuration = widgetConfig.getSettingValue('settings', 'showInterruptionsMinDuration');

  useTimedRerender({seconds: 60});

  useMount(() => {
    store.workplaceStateStore.loadAll();
    store.operationStateStore.loadAll();
  });

  const fromDate = dayjs().subtract(timelineLength, 'hours');
  const toDate = dayjs();

  useMount(() => {
    if (workplaceId) {
      store.stateHistoryStore.loadStateHistoriesByWorkplace({
        workplaceId,
        from: fromDate.toISOString(),
        to: toDate.toISOString(),
      }, true);
    }
  }, [workplaceId, store.transitionStore.performedTransition, timelineLength]);

  const toItem = (group) => (log) => {
    const state = log.operationId
      ? store.operationStateStore.getById(log?.stateId)
      : store.workplaceStateStore.getById(log?.stateId);
    const backgroundColor = state?.color || defaultColor;
    return ({
      x: dayjs(log.start).valueOf(),
      x2: log.end ? dayjs(log.end).valueOf() : dayjs().valueOf(),
      y: group,
      color: backgroundColor,
    });
  };

  let operationStateLogs = store.operationStateLogStore.getByWorkplaceId(workplaceId);
  let workplaceStateLogs = store.workplaceStateLogStore.getByWorkplaceId(workplaceId);

  if (showInterruptionsMinDuration) {
    operationStateLogs = getReducedByInterruptionsMinDuration(operationStateLogs, showInterruptionsMinDuration);
    workplaceStateLogs = getReducedByInterruptionsMinDuration(workplaceStateLogs, showInterruptionsMinDuration);
  }

  const Label = ({label, icon}) => (
    <div style={{marginBottom: '13px'}}>
      <Space style={{margin: '0 0.4em', marginTop: '9px'}}>
        {icon && <FontAwesomeIcon icon={icon}/>}
        <span style={{position: 'relative', zIndex: 4}}>
          {t(`state.historyWidget.${label}`)}
        </span>
      </Space>
    </div>
  );

  const options = {
    series: {
      borderColor: null,
      showInLegend: false,
      label: {
        enabled: false,
      },
      turboThreshold: 0,
      data: [],
    },
    yAxis: {
      categories: [],
    },
  };

  if (enableOperationState) {
    options.yAxis.categories.push('');
    options.series.data.push(...(operationStateLogs?.map(toItem(0))) || []);
  }

  if (enableWorkplaceState) {
    options.yAxis.categories.push('');
    options.series.data.push(...(workplaceStateLogs?.map(toItem(Number(enableOperationState)))) || []);
  }

  return (
    <ScopeContext.Provider value={[workflow]}>
      <OperatorWidget
        icon={<FontAwesomeIcon icon={faSignalStream}/>}
        onHeadClick={() => setModalVisible(true)}
        title={widgetConfig.getWidgetTitle()}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        manualPath={'/workflow/state-history'}
      >
        <Row gutter={16} style={{marginTop: '-8px', flexWrap: 'wrap'}}>
          <Col flex={'120px'}>
            <div style={{marginTop: '25px'}}>
              {enableOperationState && (
                <Label label={'operation'} icon={faServer}/>
              )}
              {enableWorkplaceState && (
                <Label label={'workplace'} icon={faIndustry}/>
              )}
            </div>
          </Col>
          {enableTimeline ? (
            <Col flex={'auto'} ref={ref}>
              <TimelineChart
                key={`timeline-${bounds?.width || 0}`}
                options={options}
                start={fromDate.valueOf()}
                end={toDate.valueOf()}
                isLoading={store.stateHistoryStore.isLoadingCollection}
                className={styles.compact}
                style={{width: '100%'}}
              />
            </Col>
          ) : null}
          <Col flex={'180px'}>
            <Space direction={'vertical'} style={{width: '100%'}}>
              <div
                style={{
                  color: '#8e8e8e',
                  textTransform: 'uppercase',
                  fontWeight: 500,
                  fontSize: '12px',
                  marginBottom: '2px',
                }}
              >
                {t('state.historyWidget.currentState')}
              </div>
              {enableOperationState && (
                <StatusLine
                  style={{height: '33px'}}
                  logs={operationStateLogs}
                  operationStates={store.operationStateStore.operationStates}
                  workplaceStates={store.workplaceStateStore.workplaceStates}
                />
              )}
              {enableWorkplaceState && (
                <StatusLine
                  style={{height: '33px'}}
                  logs={workplaceStateLogs}
                  operationStates={store.operationStateStore.operationStates}
                  workplaceStates={store.workplaceStateStore.workplaceStates}
                />
              )}
            </Space>
          </Col>
        </Row>
      </OperatorWidget>
      {modalVisible && (
        <StateHistoryDetailModal
          initialFromDate={fromDate}
          showInterruptionsMinDuration={showInterruptionsMinDuration}
          onCancel={() => setModalVisible(false)}
        />
      )}
    </ScopeContext.Provider>
  );
};

StateHistoryWidget.icon = faSignalStream;

StateHistoryWidget.identifier = 'StateHistoryWidget';
StateHistoryWidget.defaultLayout = new WidgetLayout(
  {
    identifier: StateHistoryWidget.identifier,
    x: 6,
    y: 6,
    height: 3,
    minHeight: 3,
    width: WidgetLayouts.halfWidth.w,
    minWidth: WidgetLayouts.halfWidth.minW,
  }
);
export default observer(StateHistoryWidget);
