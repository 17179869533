import { observable, makeObservable, computed } from 'mobx';
import { BaseModel } from './base';
import i18n from '../i18n/i18n';
import { displayableProperty } from './displayableProperty';
import { displayablePropertyParam } from './displayablePropertyParam';
import { RootStore } from '../stores/rootStore';

export const AutoReloadOptions = [5, 10, 15, 30, 60];

export class CockpitDashboard extends BaseModel {
  id: number = 0;
  name: string = '';
  cockpitId: number = 0;
  sortOrder: number = 0;
  isLocked: boolean = false;
  autoReload: number = 0;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      cockpitId: observable,
      cockpit: computed,
      sortOrder: observable,
      isLocked: observable,
      autoReload: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  searchableProperties = ['name'];

  displayableProperties = [
    displayableProperty({
      key: 'name',
      title: i18n.t('cockpit.model.attributes.name'),
      params: [displayablePropertyParam({ path: 'name' })],
    }),
  ];

  static prepareApiPayload(model: Partial<CockpitDashboard>): Partial<CockpitDashboard> {
    return {
      id: model.id,
      name: model.name,
      cockpitId: model.cockpitId,
      sortOrder: model.sortOrder,
      isLocked: model.isLocked,
      autoReload: model.autoReload,
    };
  }

  get cockpit() {
    return this.rootStore.cockpitStore.getById(this.cockpitId);
  }
}
