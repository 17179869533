import {useState} from 'react';
import dayjs from 'dayjs';
import {sortBy, uniqBy} from 'lodash';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUsers} from '@fortawesome/free-solid-svg-icons';
import Modal from '../shared/modal/Modal';
import {useMount} from '../../hooks/useMount';
import {transformation} from '../../utils/transformations';
import {FilterFormItem} from '../shared/form/FilterFormItem';
import DateRangePicker from '../shared/inputs/DateRangePicker';
import {interpolate} from '../shared/charts/highchartHelpers/data';
import {PersonnelPlotConfig} from './CurrentPersonnelDetailModalPlotConfig';
import {useStore} from '../../hooks/useStore';
import AreaChart from '../shared/charts/AreaChart';

const CurrentPersonnelDetailModal = (props) => {
  const {t} = useTranslation();
  const store = useStore();
  const [dates, setDates] = useState({
    fromDate: dayjs().subtract(1, 'day'),
    toDate: dayjs(),
  });
  const [options, setOptions] = useState(PersonnelPlotConfig(setDates));

  const createActualPersonnelTimeSeries = (personnel) => {
    const extendedPersonnel = [...personnel];
    extendedPersonnel.push({
      createdAt: dayjs(dates.toDate).add(1),
      personnel: store.workplaceStore.selectedWorkplace?.actualPersonnel,
      remark: undefined,
    });
    return extendedPersonnel.reduce((acc, curr) => ([
      ...acc,
      {
        x: transformation.milliseconds(curr.createdAt),
        y: curr.personnel || 0,
        dataLabels: {
          enabled: !!curr.remark,
          format: curr.remark,
          y: -10,
        },
      },
    ]), []);
  };

  const createPlannedPersonnelTimeSeries = (operationStateLogs) => operationStateLogs.reduce((series, curr, index) => {
    const next = operationStateLogs[index + 1];
    const hasGap = next && curr.end !== next.start;
    const newSeries = [...series];
    const operationPhase = curr.operation.operationPhases.find((op) => op.phaseId === curr.state.phase?.id);
    newSeries.push(
      {
        x: transformation.milliseconds(curr.start),
        y: operationPhase?.plannedPersonnel || 0,
      }
    );
    if (hasGap) {
      newSeries.push({x: transformation.milliseconds(curr.end) + 1, y: operationPhase?.plannedPersonnel});
      newSeries.push({x: transformation.milliseconds(curr.end) + 1, y: null});
    }
    return newSeries;
  }, []);

  const splitActualBelowOver = (actualPersonnelData, plannedPersonnelData) => {
    const actualAtPlannedX = plannedPersonnelData.map((planned) => {
      const hasPointAtX = actualPersonnelData.find((actual) => planned.x === actual.x);
      if (!hasPointAtX && (planned.y || planned.y === 0)) {
        const actual = interpolate(actualPersonnelData, transformation.milliseconds(planned.x), 'step');
        return {x: planned.x, y: actual.y, marker: {enabled: false, states: {hover: {enabled: false}}}, tooltip: false};
      }
      return null;
    }).filter((p) => !!p);

    const actualExtended = sortBy([...actualPersonnelData, ...actualAtPlannedX], 'x');

    const [belowActual, overActual] = actualExtended.reduce(([below, over, wasHigher], actual) => {
      let isHigher;
      const planned = interpolate(plannedPersonnelData, transformation.milliseconds(actual.x), 'step');
      if (actual.y <= planned.y) {
        below.push(actual);
        if (wasHigher === true) {
          over.push(actual);
          over.push({...actual, y: null});
        }
        isHigher = false;
      } else {
        over.push(actual);
        if (wasHigher === false) {
          below.push(actual);
          below.push({...actual, y: null});
        }
        isHigher = true;
      }
      return [below, over, isHigher];
    }, [[], [], null]);
    return [belowActual, overActual];
  };

  const updateSeries = () => {
    const actualPersonnelData = sortBy(createActualPersonnelTimeSeries(
      store.workplaceStore.selectedWorkplace?.actualPersonnelLogs
    ), 'x');
    const plannedPersonnelData = createPlannedPersonnelTimeSeries(
      sortBy(store.operationStateLogStore.getByWorkplaceId(store.workplaceStore.selectedWorkplace.id), 'start')
    );
    const [below, over] = splitActualBelowOver(actualPersonnelData, plannedPersonnelData);

    setOptions({
      ...options,
      xAxis: {
        ...options.xAxis,
        min: transformation.milliseconds(dates.fromDate),
        max: transformation.milliseconds(dates.toDate),
      },
      series: [{
        name: t('personnel.planned'),
        type: 'line',
        data: plannedPersonnelData,
      }, {
        name: t('personnel.actual'),
        data: below,
        marker: {
          symbol: 'diamond',
        },
      }, {
        data: over,
        linkedTo: ':previous',
        color: '#ff0000',
        marker: {
          symbol: 'diamond',
        },
      }],
    });
  };

  useMount(() => {
    if (store.workplaceStore.selectedWorkplace) {
      const personnelLogQuery = store.personnelLogStore.loadAll({
        params: {
          workplaceId: store.workplaceStore.selectedWorkplace?.id,
          fromDate: dates.fromDate?.toISOString(),
          toDate: dates.toDate?.toISOString(),
        },
      });

      const operationStateLogQuery = store.operationStateLogStore
        .loadManyByWorkplace(store.workplaceStore.selectedWorkplace?.id, {
          fromDate: dates.fromDate,
          toDate: dates.toDate,
        })
        .then(async (logs) => {
          const operationIds = uniqBy(logs, 'operationId').map((o) => o.operationId);
          await Promise.all(operationIds.map((id) => store.operationPhaseStore.loadByOperation(id)));
        });

      Promise.all([personnelLogQuery, operationStateLogQuery]).then(() => {
        updateSeries();
      });
    }
  }, [store.workplaceStore.selectedWorkplace, dates]);

  return (
    <Modal
      fullscreen={store.clientStore.isMobile}
      title={(
        <>
          <FontAwesomeIcon icon={faUsers}/>
          {' '}
          {t('personnel.currentPersonnelDetailModal.title')}
        </>
      )}
      open
      footer={false}
      width={'80%'}
      {...props}
    >
      <FilterFormItem label={t('personnel.datepicker.label')}>
        <DateRangePicker
          allowClear={false}
          value={[dates.fromDate, dates.toDate]}
          onOk={([fromDate, toDate]) => setDates({fromDate, toDate})}
          onChange={(dateChange) => (dateChange ? setDates({
            fromDate: dateChange[0], toDate: dateChange[1],
          }) : null)}
          showDefaultTimeRanges
        />
      </FilterFormItem>
      <AreaChart
        options={options}
        isTimeseries
        dataLabelsEnabled
      />
    </Modal>
  );
};
export default observer(CurrentPersonnelDetailModal);
