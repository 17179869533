import {useMemo, useState} from 'react';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {faCalendarAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClock} from '@fortawesome/pro-solid-svg-icons';
import {observer} from 'mobx-react-lite';
import {formatDuration} from '../../../../utils/formatting';
import {useMount} from '../../../../hooks/useMount';
import {durationFormat, durationFormatWithDays} from '../../../../config/dayjs';
import {EventDurationCockpitWidgetConfig, stateGroups} from './config/eventDurationCockpitWidgetConfig';
import CockpitWidget from './CockpitWidget';
import {WidgetLayout} from '../../../../models/widgetLayout';
import {WidgetLayouts} from '../../../shared/widgets/WidgetLayouts';
import Spinner from '../../../shared/spinners/Spinner';
import {useStore} from '../../../../hooks/useStore';
import useWorkplaceRelatedWidgetTitle from '../../../../hooks/useWorkplaceRelatedWidgetTitle';

const EventDurationCockpitWidget = ({
  widgetId,
  title,
  ...props
}) => {
  const store = useStore();
  const {t} = useTranslation();
  const widgetConfig = useMemo(() => new EventDurationCockpitWidget.Config({
    rootStore: store,
    identifier: EventDurationCockpitWidget.identifier,
    widgetId,
  }), []);
  const [eventLog, setEventLog] = useState(null);
  const widgetTitle = useWorkplaceRelatedWidgetTitle(title, widgetConfig.getRelevantWorkplaceId());

  const loadEventLog = async (currentSettings) => {
    if (store.workplaceStore.workplaces && store.workplaceStore.workplaces.length) {
      const workplaceId = widgetConfig.getRelevantWorkplaceId();
      if (workplaceId) {
        const [selectedGroup, selectedValue] = currentSettings.state?.split(':') || [];
        const operationStateId = selectedGroup === stateGroups.operationStates ? selectedValue : null;
        const workplaceStateId = selectedGroup === stateGroups.workplaceStates ? selectedValue : null;
        store.workplaceStore.loadLatestLogByState(workplaceId, operationStateId, workplaceStateId);
      } else {
        setEventLog(undefined);
      }
    }
  };

  useMount(() => {
    loadEventLog(widgetConfig.getValues());
  }, [
    store.workplaceStore.workplaces.length,
    store.cockpitStore.currentWorkplaceId,
    widgetConfig.getSettingValue('workplace', 'hierarchyId'),
  ]);

  useMount(() => {
    const [selectedGroup, selectedValue] = widgetConfig.getValues().state?.split(':') || [];
    const stateId = parseInt(selectedValue, 10);
    const workplaceId = widgetConfig.getRelevantWorkplaceId();
    if (selectedGroup === stateGroups.operationStates) {
      const result = store.operationStateLogStore.getLatestByStateAndWorkplace(stateId, workplaceId);
      setEventLog(result);
    } else if (selectedGroup === stateGroups.workplaceStates) {
      const result = store.workplaceStateLogStore.getLatestByStateAndWorkplace(stateId, workplaceId);
      setEventLog(result);
    } else {
      setEventLog(null);
    }
  }, [
    store.operationStateLogStore.logs.length,
    store.workplaceStateLogStore.logs.length,
    store.operationStore.operations.length,
    store.cockpitStore.currentWorkplaceId,
    widgetConfig.getSettingValue('workplace', 'hierarchyId'),
  ]);

  const EventLogInfo = ({log}) => {
    let eventInfo = (<Spinner size={'small'}/>);
    const styleDate = {
      fontWeight: 'bold',
      fontFamily: 'Roboto Mono',
      fontSize: '20px',
    };
    const styleDuration = {
      fontWeight: 'bold',
      fontFamily: 'Roboto Mono',
      marginTop: '4px',
    };
    if (log === undefined) {
      eventInfo = (
        <div style={styleDuration}>
          {t('eventDurationCockpitWidget.noWorkplaceSelected')}
        </div>
      );
    } else if (log === null) {
      eventInfo = (
        <div style={styleDuration}>
          {t('eventDurationCockpitWidget.notFound')}
        </div>
      );
    } else if (log && log.end === null) {
      const start = dayjs(log.start);
      const startedSince = dayjs.duration(dayjs().diff(start));
      const format = startedSince.days() ? durationFormatWithDays : durationFormat;
      eventInfo = (
        <>
          <div style={styleDate}>
            <FontAwesomeIcon icon={faCalendarAlt} style={{marginRight: '1em'}}/>
            {start.format('lll')}
          </div>
          <div style={styleDuration}>
            <FontAwesomeIcon icon={faClock} style={{marginRight: '1em'}}/>
            {`${t('eventDurationCockpitWidget.ongoing')}: ${formatDuration(startedSince, format)}`}
          </div>
        </>
      );
    } else if (log && log.end && dayjs(log.end).isValid()) {
      const start = dayjs(log.start);
      const end = dayjs(log.end);
      const isSameDay = start.isSame(end, 'day');
      const endedSince = dayjs.duration(dayjs().diff(end));
      const format = endedSince.days() ? durationFormatWithDays : durationFormat;
      eventInfo = (
        <>
          <div style={styleDate}>
            <FontAwesomeIcon icon={faCalendarAlt} style={{marginRight: '1em'}}/>
            {start.format('lll')}
            &nbsp;&ndash;&nbsp;
            {end.format(isSameDay ? 'HH:mm' : 'lll')}
          </div>
          <div style={styleDuration}>
            <FontAwesomeIcon icon={faClock} style={{marginRight: '1em'}}/>
            {`${t('eventDurationCockpitWidget.ended')}: ${formatDuration(endedSince, format)}`}
          </div>
        </>
      );
    }
    return eventInfo;
  };

  return (
    <CockpitWidget
      widgetId={widgetId}
      title={widgetTitle}
      widgetConfig={widgetConfig}
      onSettingsChange={loadEventLog}
      {...props}
    >
      <EventLogInfo log={eventLog}/>
    </CockpitWidget>
  );
};

EventDurationCockpitWidget.Config = EventDurationCockpitWidgetConfig;
EventDurationCockpitWidget.defaultLayout = new WidgetLayout({
  identifier: CockpitWidget.identifier,
  width: 2,
  height: 4,
  minHeight: 2,
  minWidth: WidgetLayouts.sixthWidth.minW,
});
export default observer(EventDurationCockpitWidget);
