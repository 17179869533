import { action, observable, makeObservable } from 'mobx';
import { get } from 'lodash';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { Setting } from '../models/setting';
import { RootStore } from './rootStore';

export class SettingStore extends EntityStore<Setting> {
  settings: Setting[] = [];
  operatorSettingsReady: boolean = true;
  officeSettingsReady: boolean = true;

  constructor(rootStore: RootStore) {
    super(rootStore, 'settings', Api.settings, Setting);

    makeObservable(this, {
      settings: observable,
      operatorSettingsReady: observable,
      setOperatorSettingsReady: action,
      officeSettingsReady: observable,
      setOfficeSettingsReady: action,
    });
  }

  getByIdentifierAndWorkplace(identifier: string) {
    return this.settings.find(
      (setting) => setting.identifier === identifier
    );
  }

  getByIdentifier(identifier: string) {
    return this.settings.find(
      (setting) => setting.identifier === identifier
    );
  }

  getByConfigIds({ ids = [], path, identifier }: { ids: number[], path: string, identifier: string }): object[] | null {
    // TODO: workplaceId is currently not handled
    const found = this.getByIdentifierAndWorkplace(identifier);
    if (!found) {
      return null;
    }

    const result: object[] = [];
    ids.forEach((id) => {
      const setting = get(found, [path, id]);
      if (setting) {
        result[id] = setting;
      }
    });

    return result;
  }

  createOrUpdate(terminalLayoutId: number | null, workplaceId: number | null, identifier: string, value: object) {
    // TODO: workplaceId is currently not handled
    const currentSetting = this.getByIdentifierAndWorkplace(identifier);
    if (currentSetting
      && currentSetting.id
      && currentSetting.terminalLayoutId === terminalLayoutId) {
      this.update({ id: currentSetting.id, value });
    } else {
      this.create({ terminalLayoutId, workplaceId: null, identifier, value });
    }
  }

  setOperatorSettingsReady(value: boolean) {
    this.operatorSettingsReady = value;
  }

  setOfficeSettingsReady(value: boolean) {
    this.officeSettingsReady = value;
  }
}
