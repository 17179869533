import { computed, makeObservable, observable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { Document, ImageRequest } from '../models/document';
import i18n from '../i18n/i18n';
import { RootStore } from './rootStore';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';

export class DocumentStore extends EntityStore<Document> {
  documents: Document[] = [];

  get manualDocuments() {
    return this.documents.filter((d) => d.isManual);
  }

  constructor(rootStore: RootStore) {
    super(rootStore, 'documents', Api.documents, Document);

    makeObservable(this, {
      documents: observable,
      manualDocuments: computed,
    });
  }

  async createImage(request: ImageRequest): Promise<Document> {
    const res = await this.api.createImage(request);
    return res.data;
  }

  delete(id: number, options = {}) {
    return super.delete(id, options).catch((e: any) => {
      if (e.name === 'E_FOREIGN_KEY_VIOLATION') {
        this.rootStore.flashMessageStore.addFlashMessage(new FlashMessage(
          FlashMessageType.ERROR,
          i18n.t('document.errors.hasAssociations')
        ));
      }
      throw e;
    });
  }
}
