import {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {groupBy, sortBy} from 'lodash';
import {Col, Collapse, Empty, Row, Space} from 'antd';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {faLock, faUnlockAlt} from '@fortawesome/free-solid-svg-icons';
import {InfoCircleOutlined} from '@ant-design/icons';
import {useStore} from '../../../hooks/useStore';
import InspectionTaskDetails from '../details/InspectionTaskDetails';
import Button from '../../shared/buttons/Button';
import InspectionTaskDrilldownModal from '../details/InspectionTaskDrilldownModal';
import {useModulePolicy} from '../../../hooks/useModulePolicy';
import {specialTaskManagement} from '../../../models/scope';
import {sortNumerically} from '../../shared/tables/sorters';

const TaskLinkButton = ({text, disabled, icon, onClick}) => (
  <Button type="link" disabled={disabled} onClick={onClick} style={{padding: 0}}>
    {icon && <FontAwesomeIcon style={{marginRight: 8}} icon={icon}/>}
    {text}
  </Button>
);

const TaskLink = ({taskGroup, tasks, selectPendingTask, phaseId}) => {
  const {t} = useTranslation();
  const store = useStore();
  const policy = useModulePolicy(store, [specialTaskManagement]);
  const roleIds = store.authStore.user?.roleIds;

  if (!selectPendingTask) {
    return null;
  }
  if (store.contextStore.isOfficeContext) {
    return null;
  }
  if (taskGroup.showPending || policy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})) {
    const taskIds = tasks.map((task) => task.id);
    const pending = store.inspectionTaskPendingStore.getByInspectionTaskIds(taskIds);
    if (pending.length || policy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})) {
      const requiredRoleId = (pending.length) ? pending[0].inspectionTask.requiredRoleId : tasks[0].requiredRoleId;

      return (
        <TaskLinkButton
          text={(
            <>
              {t('inspectionTask.detailModal.task.show')}
              {(requiredRoleId && !roleIds.includes(requiredRoleId))
              && (
                <Space style={{paddingLeft: 8}}>
                  <FontAwesomeIcon icon={faLock} size="1x"/>
                </Space>
              )}
            </>
          )}
          onClick={(e) => {
            e.stopPropagation();
            return selectPendingTask((pending.length) ? pending[0] : null, (pending.length) ? null : tasks[0], phaseId);
          }}
        />
      );
    }
    return <TaskLinkButton disabled text={t('inspectionTask.detailModal.task.noTasks')}/>;
  }
  return <TaskLinkButton disabled icon={faUnlockAlt} text={t('inspectionTask.detailModal.task.notAvailable')}/>;
};

const DrilldownButton = ({onClick}) => (
  <Button
    type="link"
    icon={<InfoCircleOutlined/>}
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
  />
);

const PanelHeader = ({title, children, strikethrough = false}) => {
  const style = {};
  if (strikethrough) {
    style.textDecoration = 'line-through';
  }
  return (
    <Row justify={'space-around'} align={'middle'}>
      <Col xxl={20} xl={19} lg={18} md={16} sm={24} xs={24} style={style}>
        {title}
      </Col>
      <Col xxl={4} xl={5} lg={6} md={8} sm={24} xs={24}>
        {children}
      </Col>
    </Row>
  );
};

const InspectionTaskOverview = ({
  selectPendingTask = null,
  inspectionTasks,
  showWorkplace = false,
}) => {
  const {t} = useTranslation();
  const store = useStore();
  const [drilldownTask, setDrilldownTask] = useState(null);

  if (inspectionTasks.length === 0) {
    return <Empty description={t('inspectionTask.noTasks')}/>;
  }

  const activeOperation = store.operationStore.active;
  const operationState = store.operationStateStore.getById(activeOperation?.stateId);

  const taskTitle = (task) => (showWorkplace ? `${task.name} - ${task.workplace?.name}` : task.name);

  const workplacePhaseId = store.workplaceStateLogStore
    .getLatestOfWorkplace(store.workplaceStore.selectedWorkplace?.id)?.state?.inspectionTaskPhaseId;

  const groupByPhase = (tasks) => {
    const result = {};
    tasks.forEach((task) => {
      // Handle tasks without phase relation
      if (task.phaseIds.length === 0) {
        if (result.null) {
          result.null.push(task);
        } else {
          result.null = [task];
        }
      } else {
        task.phaseIds.forEach((phaseId) => {
          if (result[phaseId]) {
            result[phaseId].push(task);
          } else {
            result[phaseId] = [task];
          }
        });
      }
    });
    return result;
  };

  const byPhase = sortBy(Object.entries(groupByPhase(inspectionTasks)).map(([sPhaseId, groupedByPhase]) => {
    const phaseId = Number(sPhaseId);
    const showPending = phaseId === operationState?.inspectionTaskPhase?.id || phaseId === workplacePhaseId || !phaseId;

    const {
      null: byTaskGroupOrphans,
      ...byTaskGroupRest
    } = groupBy(groupedByPhase, (task) => task.inspectionTaskGroupId ?? null);
    const byTaskGroup = sortBy([
      ...(byTaskGroupOrphans ?? []).map((task) => ({
        type: 'single',
        phaseIds: task.phaseIds,
        operationNo: task.operationNo,
        characteristicNo: (task.phaseIds.length) ? task.characteristicNo : -1,
        showPending,
        task,
      })),
      ...(Object.entries(byTaskGroupRest).map(([key, group]) => {
        const task = group[0];
        return {
          type: 'group',
          title: store.inspectionTaskGroupStore.getById(task.inspectionTaskGroupId)?.name,
          phaseIds: task.phaseIds,
          inspectionTaskGroupId: task.inspectionTaskGroupId,
          operationNo: task.operationNo,
          characteristicNo: (task.phaseIds.length) ? task.characteristicNo : -1,
          key,
          showPending,
          tasks: group,
        };
      })),
    ], ['characteristicNo', 'operationNo']);

    return {
      phaseId,
      phaseSortOrder: store.phaseStore.getById(phaseId)?.sortOrder,
      characteristicNo: byTaskGroup[0].characteristicNo,
      operationNo: byTaskGroup[0].operationNo,
      title: (phaseId)
        ? `${t('phase.model.one')}: ${store.phaseStore.getById(phaseId)?.name}`
        : `${t('inspectionTask.detailModal.phase.undefined')}`,
      byTaskGroup,
    };
  }), ['characteristicNo', 'operationNo']);

  byPhase.sort((a, b) => sortNumerically(a.phaseSortOrder, b.phaseSortOrder));

  const renderSingle = (taskGroup, phaseId) => {
    const {task} = taskGroup;
    return (
      <Collapse.Panel
        header={(
          <PanelHeader title={taskTitle(task)} strikethrough={task.deactivatedAt || task.inspectionLot?.deactivatedAt}>
            <DrilldownButton onClick={() => setDrilldownTask(task)}/>
            <TaskLink
              taskGroup={taskGroup}
              tasks={[task]}
              selectPendingTask={selectPendingTask}
              phaseId={phaseId}
            />
          </PanelHeader>
        )}
        key={task.id}
      >
        <InspectionTaskDetails task={task}/>
      </Collapse.Panel>
    );
  };

  const renderGroup = (taskGroup, phaseId) => (
    <Collapse.Panel
      header={(
        <PanelHeader
          title={taskGroup.title}
          strikethrough={taskGroup.tasks.every((task) => task.deactivatedAt || task.inspectionLot?.deactivatedAt)}
        >
          <TaskLink
            taskGroup={taskGroup}
            tasks={taskGroup.tasks}
            selectPendingTask={selectPendingTask}
            phaseId={phaseId}
          />
        </PanelHeader>
      )}
      key={taskGroup.key}
    >
      <Collapse ghost>
        {taskGroup.tasks.map((task) => (
          <Collapse.Panel
            style={{marginRight: '-5px'}}
            key={task.id}
            header={(
              <PanelHeader
                title={taskTitle(task)}
                strikethrough={task.deactivatedAt || task.inspectionLot?.deactivatedAt}
              >
                <DrilldownButton onClick={() => setDrilldownTask(task)}/>
              </PanelHeader>
            )}
          >
            <InspectionTaskDetails task={task}/>
          </Collapse.Panel>
        ))}
      </Collapse>
    </Collapse.Panel>
  );

  return (
    <>
      <Collapse>
        {byPhase.map(({phaseId, byTaskGroup, title}) => (
          <Collapse.Panel key={phaseId} header={title}>
            <Collapse ghost>
              {byTaskGroup.map((taskGroup) => (
                taskGroup.type === 'single'
                  ? renderSingle(taskGroup, phaseId)
                  : renderGroup(taskGroup, phaseId)))}
            </Collapse>
          </Collapse.Panel>
        ))}
      </Collapse>
      {drilldownTask && <InspectionTaskDrilldownModal task={drilldownTask} onClose={() => setDrilldownTask(null)}/>}
    </>
  );
};

export default observer(InspectionTaskOverview);
