import {createRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import {flatten, uniq} from 'lodash';
import {Form, Input, Switch, TreeSelect} from 'antd';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import {datetimeFormat} from '../../config/dayjs';
import {transformation} from '../../utils/transformations';
import {ValidationRules} from '../../utils/validationRules';
import {FormItemLayouts} from '../shared/form/formItemLayouts';
import CancelSaveButtonGroup from '../shared/buttons/CancelSaveButtonGroup';
import {sortAlphabetically} from '../shared/tables/sorters';
import {useStore} from '../../hooks/useStore';
import {useMount} from '../../hooks/useMount';
import AlertWarning from '../shared/alert/AlertWarning';
import InterruptionClassSelect from '../interruption/InterruptionClassSelect';
import InterruptionReasonSelect from '../interruption/InterruptionReasonSelect';
import {Hierarchy} from '../../models/hierarchy';

const UnclassifiedInterruptionsForm = ({
  record,
  fields,
  onChange,
  saving = false,
  onSubmit,
  onCancel,
  saveButtonDisabled,
  fieldsDisabled,
  longestInterruptionDuration,
  scopeSources,
  selectSourceLeavesOnly,
}) => {
  const store = useStore();
  const [form] = Form.useForm();
  const formRef = createRef();
  const {t} = useTranslation();

  const [selectedInterruptionSourceHierarchyId, setSelectedInterruptionSourceHierarchyId] = useState(
    record?.interruptionSourceHierarchyId
  );
  const [selectedInterruptionClassId, setSelectedInterruptionClassId] = useState(record?.interruptionClassId);
  const [selectedInterruptionReasonId, setSelectedInterruptionReasonId] = useState(record?.interruptionReasonId);
  const [selectedInterruptionReason, setSelectedInterruptionReason] = useState();
  const [availableInterruptionReasons, setAvailableInterruptionReasons] = useState([]);
  const [availableInterruptionClasses, setAvailableInterruptionClasses] = useState(null);
  const [treeData, setTreeData] = useState();
  const [treeDefaultExpandedKeys, setTreeDefaultExpandedKeys] = useState([]);

  const [messageRequired, setMessageRequired] = useState(false);

  useMount(() => {
    const interruptionReasons = store.interruptionReasonStore
      .getReasonsByHierarchyId(selectedInterruptionSourceHierarchyId)
      .filter((r) => !r.isQuickstop);

    const interruptionClasses = store.interruptionClassStore.interruptionClasses
      .filter((interruptionClass) => uniq(flatten(interruptionReasons.map((r) => r.interruptionClassIds)))
        .includes(interruptionClass.id))
      .sort((a, b) => sortAlphabetically(a.label, b.label));

    if (selectedInterruptionClassId) {
      const filteredInterruptionReasonsByClass = interruptionReasons.filter(
        (reason) => reason.interruptionClassIds.includes(selectedInterruptionClassId)
      );
      setAvailableInterruptionReasons(filteredInterruptionReasonsByClass);
    }

    if (selectedInterruptionReasonId) {
      form.setFieldValue('interruptionReasonId', selectedInterruptionReasonId);
    }

    setAvailableInterruptionClasses(selectedInterruptionSourceHierarchyId ? interruptionClasses : null);
  }, [selectedInterruptionSourceHierarchyId]);

  useMount(() => {
    if (selectedInterruptionClassId) {
      const interruptionReasons = store.interruptionReasonStore
        .getReasonsByHierarchyId(selectedInterruptionSourceHierarchyId)
        .filter((r) => !r.isQuickstop);

      const filteredInterruptionReasonsByClass = interruptionReasons.filter(
        (reason) => reason.interruptionClassIds.includes(selectedInterruptionClassId)
      );

      setAvailableInterruptionReasons(filteredInterruptionReasonsByClass);
    }
  }, [selectedInterruptionClassId]);

  useMount(() => {
    let hierarchyTree;
    if (store.workplaceStore.selectedWorkplace?.hierarchy) {
      const rootId = store.workplaceStore.getRootIdFromWorkplace(store.workplaceStore.selectedWorkplace, scopeSources);
      hierarchyTree = store.hierarchyStore.getFullSubTree(rootId);
    } else {
      hierarchyTree = store.hierarchyStore.hierarchyTree;
    }
    setTreeData(Hierarchy.mapToSelectTreeData(
      hierarchyTree,
      null,
      (entity) => selectSourceLeavesOnly && entity.children
    ));
    if (!selectSourceLeavesOnly && store.workplaceStore.selectedWorkplace?.hierarchy?.id) {
      setSelectedInterruptionSourceHierarchyId(store.workplaceStore.selectedWorkplace?.hierarchy?.id);
      form.setFieldValue('interruptionSourceHierarchyId', store.workplaceStore.selectedWorkplace?.hierarchy?.id);
    }
  }, [
    store.workplaceStore.selectedWorkplace?.hierarchy?.id,
    store.hierarchyStore.hierarchies.length,
    selectSourceLeavesOnly,
  ]);

  useMount(() => {
    if (store.workplaceStore.selectedWorkplace?.hierarchy) {
      const rootId = store.workplaceStore.getRootIdFromWorkplace(store.workplaceStore.selectedWorkplace, scopeSources);
      setTreeDefaultExpandedKeys([rootId]);
    } else {
      setTreeDefaultExpandedKeys([store.hierarchyStore.hierarchyTree?.id]);
    }
  });

  const handleValuesChange = (changedValues) => {
    if (changedValues.interruptionSourceHierarchyId) {
      setSelectedInterruptionSourceHierarchyId(changedValues.interruptionSourceHierarchyId);
      setSelectedInterruptionClassId(undefined);
      setSelectedInterruptionReasonId(undefined);
      form.setFieldValue('interruptionClassId', undefined);
      form.setFieldValue('interruptionReasonId', undefined);
    } else if (changedValues.interruptionClassId) {
      setSelectedInterruptionClassId(changedValues.interruptionClassId);
      setSelectedInterruptionReasonId(undefined);
      form.setFieldValue('interruptionReasonId', undefined);
    } else if (changedValues.interruptionReasonId) {
      setSelectedInterruptionReasonId(changedValues.interruptionReasonId);
    }
  };

  useMount(() => {
    if (!record && selectedInterruptionReason && selectedInterruptionReason?.isMessageRequired) {
      setMessageRequired(
        longestInterruptionDuration > selectedInterruptionReason.messageRequiredAfterMinutes * 60 * 1000
      );
    }
  }, [longestInterruptionDuration]);

  useMount(() => {
    setSelectedInterruptionReason(store.interruptionReasonStore.getById(selectedInterruptionReasonId));
  }, [selectedInterruptionReasonId]);

  useMount(() => {
    if (!selectedInterruptionReason?.isMessageRequired) {
      setMessageRequired(false);
      return;
    }
    if (selectedInterruptionReason.messageRequiredAfterMinutes === 0) {
      setMessageRequired(true);
      return;
    }
    if (!record) {
      setMessageRequired(
        longestInterruptionDuration > selectedInterruptionReason.messageRequiredAfterMinutes * 60 * 1000
      );
      return;
    }
    const duration = dayjs(record.end).valueOf() - dayjs(record.start).valueOf();
    setMessageRequired(duration > selectedInterruptionReason.messageRequiredAfterMinutes * 60 * 1000);
  }, [selectedInterruptionReason]);

  return (
    <Form
      form={form}
      ref={formRef}
      {...FormItemLayouts.default}
      labelAlign={'left'}
      onFinish={onSubmit}
      fields={fields}
      onFieldsChange={(changedFields, allFields) => onChange && onChange(allFields)}
      initialValues={
        record
          ? {
            start: dayjs(record.start).format(datetimeFormat),
            durationSeconds: transformation.detailedDuration(record.durationSeconds),
            message: record.message,
            interruptionSourceHierarchyId: record.interruptionSourceHierarchyId
              || selectedInterruptionSourceHierarchyId,
            interruptionClassId: record.interruptionClassId,
            interruptionReasonId: record.interruptionReasonId,
          }
          : undefined
      }
      onValuesChange={handleValuesChange}
    >
      {
        record ? (
          <>
            <Form.Item name={'start'} label={t('stateLog.model.attributes.start')}>
              <Input disabled/>
            </Form.Item>
            <Form.Item name={'durationSeconds'} label={t('stateLog.model.attributes.durationSeconds')}>
              <Input disabled/>
            </Form.Item>
            <Form.Item name={'active'} label={t('stateLog.model.attributes.active')}>
              <Switch
                disabled
                checked={record.active}
                checkedChildren={<CheckOutlined/>}
                unCheckedChildren={<CloseOutlined/>}
              />
            </Form.Item>
          </>
        ) : ''
      }
      <Form.Item
        name={'interruptionSourceHierarchyId'}
        rules={[ValidationRules.required]}
        label={t('interruption.modal.form.interruptionSourceHierarchy.label')}
      >
        <TreeSelect
          disabled={fieldsDisabled}
          showSearch
          style={{width: '100%'}}
          dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
          allowClear
          treeDefaultExpandAll={false}
          treeDefaultExpandedKeys={treeDefaultExpandedKeys}
          treeData={treeData}
          treeNodeFilterProp={'title'}
          placeholder={t('hierarchyTree.selectHierarchy')}
        />
      </Form.Item>
      {form.getFieldValue('interruptionSourceHierarchyId')
        && availableInterruptionClasses?.length === 0
        && !store.interruptionClassStore.isLoadingCollection
        && (
          <AlertWarning
            message={t('interruption.modal.form.interruptionClass.noClassesConfigured')}
            closable
          />
        )}
      <Form.Item
        name={'interruptionClassId'}
        label={t('interruptionClass.model.one')}
        rules={[ValidationRules.required()]}
      >
        <InterruptionClassSelect
          disabled={fieldsDisabled}
          availableInterruptionClasses={availableInterruptionClasses || []}
        />
      </Form.Item>
      <Form.Item
        name={'interruptionReasonId'}
        label={t('interruptionReason.model.one')}
        rules={[ValidationRules.required()]}
      >
        <InterruptionReasonSelect
          disabled={fieldsDisabled}
          availableInterruptionReasons={availableInterruptionReasons}
        />
      </Form.Item>
      <Form.Item
        name={'message'}
        label={t('stateLog.model.attributes.message')}
        rules={[ValidationRules.requiredIf(messageRequired)]}
      >
        <Input.TextArea disabled={fieldsDisabled}/>
      </Form.Item>
      <Form.Item {...FormItemLayouts.fullWidth} style={{textAlign: 'right'}}>
        <CancelSaveButtonGroup
          onCancel={onCancel}
          onSave={saving}
          saveButtonDisabled={fieldsDisabled || saveButtonDisabled}
        />
      </Form.Item>
    </Form>
  );
};

export default UnclassifiedInterruptionsForm;
