import React, {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {Alert, Divider} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBalanceScaleLeft} from '@fortawesome/pro-solid-svg-icons';
import {useMount} from '../../hooks/useMount';
import {WidgetLayout} from '../../models/widgetLayout';
import OperatorWidget from '../operator/shared/OperatorWidget';
import {WidgetLayouts} from '../shared/widgets/WidgetLayouts';
import {useStore} from '../../hooks/useStore';
import {ScopeContext} from '../../policies/scopeContext';
import {materialManagement, processdataCollection} from '../../models/scope';
import {useModulePolicy} from '../../hooks/useModulePolicy';
import {SensorData as Model} from '../../models/sensorData';
import {ManualSenensorDataWidgetConfig} from './manualSenensorDataWidgetConfig';
import {ManualSensorDataWidgetModal} from './ManualSensorDataWidgetModal';
import Button from '../shared/buttons/Button';
import Table from '../shared/tables/Table';
import {sortAlphabetically} from '../shared/tables/sorters';
import {EnDash} from '../shared/unicodeWrapper/EnDash';
import ManualSensorDataWidgetHistoryModal from './ManualSensorDataWidgetHistoryModal';
import {useMQTTManualSensorDataSubscription} from '../../hooks/useMQTT';

const LatestValuesTable = ({columns, dataSource}) => {
  const {t} = useTranslation();
  return (
    <>
      <Divider>
        {t('manualSensorDataWidget.latest.title')}
      </Divider>
      <Table
        columns={columns}
        dataSource={dataSource}
        rowKey={'sensorName'}
        pagination={false}
      />
    </>
  );
};

const ManualSensorDataWidget = ({disabled, minimized = false, identifier}) => {
  const {t} = useTranslation();
  const store = useStore();
  const [newModalVisible, setNewModalVisible] = useState(false);
  const [historyModalVisible, setHistoryModalVisible] = useState(false);
  const widgetConfig = useMemo(() => (
    new ManualSenensorDataWidgetConfig(store, ManualSensorDataWidget.identifier)
  ), [store.sensorStore.sensors.length]);
  const policy = useModulePolicy(store, [materialManagement]);

  useMount(() => {
    store.sensorStore.loadAll({
      params: {
        isManual: true,
      },
    });
  });

  useMount(() => {
    if (widgetConfig.getSensorNames() && store.workplaceStore.selectedWorkplace.id && widgetConfig.getShowLatest()) {
      widgetConfig.getSensorNames().forEach((sensorName) => {
        store.sensorDataStore.loadLatestByWorkplace(sensorName, store.workplaceStore.selectedWorkplace.id);
      });
    }
  }, [widgetConfig.getSensorNames(), store.workplaceStore.selectedWorkplace.id, widgetConfig.getShowLatest()]);

  useMQTTManualSensorDataSubscription(store, disabled);

  const onHeadClick = () => {
    setHistoryModalVisible(true);
  };

  const ConfigAlert = ({message}) => <Alert type={'warning'} message={message}/>;

  const sensors = store.sensorStore.getByIds(widgetConfig.getSensorNames() || []);
  const missingConfig = sensors.length === 0;

  const columns = [{
    title: t('manualSensorDataWidget.sensor'),
    dataIndex: 'sensorName',
  }, {
    title: t('manualSensorDataWidget.latest.header'),
    dataIndex: 'value',
  }];

  const latestValues = sensors.map((sensor) => {
    const data = store.sensorDataStore.getLatestByWorkplace(sensor.name, store.workplaceStore.selectedWorkplace.id);
    return {
      sensorName: sensor.label,
      value: `${sensor.roundedValue(data?.value) || EnDash()} ${sensor.unitOfMeasure?.label || ''}`,
    };
  }).sort((a, b) => sortAlphabetically(a.sensorName, b.sensorName));

  return (
    <ScopeContext.Provider value={[processdataCollection]}>
      <OperatorWidget
        disabled={disabled}
        onHeadClick={missingConfig ? undefined : onHeadClick}
        icon={<FontAwesomeIcon icon={Model.faIcon}/>}
        title={widgetConfig.getWidgetTitle()}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
      >
        {missingConfig && (
          <ConfigAlert message={t('manualSensorDataWidget.missingConfig')}/>
        )}

        {!store.operationStore.active && (
          <ConfigAlert message={t('manualSensorDataWidget.noActiveOperation')}/>
        )}

        {!missingConfig && store.operationStore.active && (
          <>
            <Button
              type={'primary'}
              onClick={() => setNewModalVisible(true)}
              size={'large'}
              block
              disabled={!policy.canCreate({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})}
            >
              {t('manualSensorDataWidget.create')}
            </Button>
            {widgetConfig.getShowLatest() && (
              <LatestValuesTable
                columns={columns}
                dataSource={latestValues}
              />
            )}
          </>
        )}
      </OperatorWidget>

      {newModalVisible && (
        <ManualSensorDataWidgetModal
          widgetConfig={widgetConfig}
          onCancel={() => {
            setNewModalVisible(false);
          }}
          onSuccess={() => {
            setNewModalVisible(false);
          }}
          sensors={sensors}
        />
      )}

      {historyModalVisible && (
        <ManualSensorDataWidgetHistoryModal
          onCancel={() => setHistoryModalVisible(false)}
          onNew={() => setNewModalVisible(true)}
          sensors={sensors}
        />
      )}
    </ScopeContext.Provider>
  );
};

ManualSensorDataWidget.icon = faBalanceScaleLeft;

ManualSensorDataWidget.identifier = 'ManualSensorDataWidget';
ManualSensorDataWidget.defaultLayout = new WidgetLayout(
  {
    identifier: ManualSensorDataWidget.identifier,
    x: 0,
    y: 16,
    height: 3,
    minHeight: 3,
    width: WidgetLayouts.sixthWidth.w,
    minWidth: WidgetLayouts.sixthWidth.minW,
    disabled: true,
  }
);
export default observer(ManualSensorDataWidget);
