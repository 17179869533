import { action, makeObservable, observable, runInAction } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { RootStore } from './rootStore';
import { BaseModel } from '../models/base';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';
import i18n from '../i18n/i18n';
import { Component } from '../models/component';
import { StorageUnit } from '../models/storageUnit';
import { Preparation } from '../models/preparation';

export interface ComponentStorageIds {
  componentId: number;
  storageUnitIds: number[];
}

export interface PrepareResponse {
  success: boolean;
  message: string;
  componentId?: number;
}

export const DosageStoreActions = {
  setup: 'dosageSetup',
  start: 'dosageStart',
  prepare: 'dosagePrepare',
  finalize: 'dosageFinalize',
  tare: 'dosageTare',
};

export class DosageStore extends EntityStore {
  selectedComponents: Component[] = [];
  selectedComponentUnitIds: ComponentStorageIds[] = [];
  isInPreparation: boolean = false;
  constructor(rootStore: RootStore) {
    super(rootStore, 'dosage', Api.dosage, BaseModel);

    makeObservable(this, {
      selectedComponents: observable,
      addSelectedComponent: action,
      removeSelectedComponent: action,
      clearSelectedComponents: action,
      selectedComponentUnitIds: observable,
      addSelectedComponentUnitIds: action,
      removeSelectedComponentUnitIds: action,
      clearSelectedComponentUnitIds: action,
      isInPreparation: observable,
      setIsInPreparation: action,
      setup: action,
      start: action,
      prepare: action,
      finalize: action,
      tare: action,
    });
  }

  async setup(workplaceId: number, componentId: number, storageUnitIds: number[]) {
    const pendingAction = DosageStoreActions.setup;
    this.addPendingAction(pendingAction);

    return Api.dosage.setup({ workplaceId, componentId, storageUnitIds }).then(({ data }) => {
      if (data) {
        const model = this.rootStore.storageUnitStore.createModelInstance(data);
        this.rootStore.storageUnitStore.add(model);
      }
      this.removePendingAction(pendingAction);
      this.addMessage(
        new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.createSuccess')),
        { skipNotification: false }
      );
      return data;
    }).catch((e) => {
      runInAction(() => this.removePendingAction(pendingAction));
      this.rootStore.flashMessageStore.addFlashMessage(new FlashMessage(
        FlashMessageType.ERROR,
        i18n.t('dosageSetupWidget.error.setupFailed')
      ));
      throw e;
    });
  }

  async start(productId: number, workplaceId: number, targetStorageUnitId?: number) {
    const pendingAction = DosageStoreActions.start;
    this.addPendingAction(pendingAction);

    return Api.dosage.start({ productId, workplaceId, targetStorageUnitId }).then(({ data }) => {
      this.rootStore.preparationStore.add(Preparation.fromPlainObject(data, this.rootStore));
      this.removePendingAction(pendingAction);
      this.addMessage(
        new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.createSuccess')),
        { skipNotification: false }
      );
      return data;
    }).catch((e) => this.handleApiError(e, pendingAction));
  }

  async prepare(
    preparationId: number,
    amount: number,
    componentId: number,
    sourceStorageUnitId: number | null = null,
    parentInformation: {
      isParent: boolean,
      parentStorageUnitId: number | null,
      childrenAmount: number,
      childrenSelection: number[],
      isSelection: boolean,
    } | null = null
  ): Promise<PrepareResponse> {
    const pendingAction = DosageStoreActions.prepare;
    this.addPendingAction(pendingAction);

    try {
      const { data } = await Api.dosage.prepare({
        preparationId,
        amount,
        componentId,
        sourceStorageUnitId,
        parentInformation,
      });

      this.rootStore.storageUnitStore.addAll(data.map((su: any) => StorageUnit.fromPlainObject(su, this.rootStore)));
      if (sourceStorageUnitId) {
        this.rootStore.storageUnitStore.getDescendantsById(sourceStorageUnitId).forEach(
          (su) => this.rootStore.storageUnitStore.removeWithChildren(su.id)
        );
        try {
          await this.rootStore.storageUnitStore.loadDescendantsById(sourceStorageUnitId);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }

      this.removePendingAction(pendingAction);
      this.addMessage(
        new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.createSuccess')),
        { skipNotification: false }
      );
      return { success: true, message: 'Preparation successful', componentId };
    } catch (e) {
      this.handleApiError(e, pendingAction);
      return { success: false, message: 'Preparation failed', componentId };
    }
  }

  async finalize(operationId: number, workplaceId: number) {
    const pendingAction = DosageStoreActions.finalize;
    this.addPendingAction(pendingAction);

    return Api.dosage.finalize({ operationId, workplaceId }).then(() => {
      this.removePendingAction(pendingAction);
      this.addMessage(
        new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.createSuccess')),
        { skipNotification: false }
      );
    }).catch((e) => this.handleApiError(e, pendingAction));
  }

  async tare(workplaceId: number, sensorName: string, operationId?: number) {
    const pendingAction = DosageStoreActions.tare;
    this.addPendingAction(pendingAction);

    return Api.dosage.tare({ workplaceId, sensorName, operationId }).then(() => {
      this.removePendingAction(pendingAction);
      this.addMessage(
        new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.createSuccess')),
        { skipNotification: false }
      );
    }).catch((e) => this.handleApiError(e, pendingAction));
  }

  addSelectedComponent(component: Component) {
    this.selectedComponents = [...this.selectedComponents, component];
  }

  removeSelectedComponent(componentId: number) {
    this.selectedComponents = this.selectedComponents.filter((c) => c.id !== componentId);
  }

  clearSelectedComponents() {
    this.selectedComponents = [];
  }

  addSelectedComponentUnitIds(componentId: number, storageUnitIds: number[]) {
    const index = this.selectedComponentUnitIds.findIndex((c) => c.componentId === componentId);
    if (index >= 0) {
      this.selectedComponentUnitIds[index].storageUnitIds = storageUnitIds;
    } else {
      this.selectedComponentUnitIds = [...this.selectedComponentUnitIds, { componentId, storageUnitIds }];
    }
  }

  removeSelectedComponentUnitIds(componentId: number) {
    this.selectedComponentUnitIds = this.selectedComponentUnitIds.filter((c) => c.componentId !== componentId);
  }

  clearSelectedComponentUnitIds() {
    this.selectedComponentUnitIds = [];
  }

  setIsInPreparation(isInPreparation: boolean) {
    this.isInPreparation = isInPreparation;
  }
}
