import React from 'react';
import { Tag as AntdTag, TagProps as AntdTagProps } from 'antd';

export type TagProps = AntdTagProps;

const Tag: React.FC<TagProps> = ({ children, ...props }) => (
  <AntdTag
    {...props}
  >
    {children}
  </AntdTag>
);

export default Tag;
