import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {groupBy} from 'lodash';
import {useMount} from '../../../hooks/useMount';
import {useStore} from '../../../hooks/useStore';
import Table from '../../shared/tables/Table';
import OperationComponentTable from './OperationComponentTable';
import {AggregatedOperation} from '../../../models/aggregatedOperation';
import {sortAlphabetically} from '../../shared/tables/sorters';

const OrderOperationTable = ({orderId, operationColumns, componentColumns}) => {
  const store = useStore();
  const [operations, setOperations] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  useMount(() => {
    setLoading(true);
    store.operationStore.loadAllWithDependencies({ params: { orderId } }).then(() => {
      setLoading(false);
    });
  }, [orderId]);

  useMount(() => {
    const loadedOperations = store.operationStore
      .getByOrderId(orderId)
      .filter((o) => !o.isControlOperation)
      .sort((a, b) => sortAlphabetically(a.no, b.no));
    const grouped = groupBy(loadedOperations, (op) => `${op.orderId}-${op.workplaceId}`);
    const newOperations = Object.values(grouped).map((ops) => {
      if (ops.length > 1) {
        const aggregatedOperation = new AggregatedOperation(store, ops, null, orderId);
        if (ops[0].workplaceId === store.workplaceStore.selectedWorkplace?.id) {
          setExpandedKeys([aggregatedOperation.id]);
        }
        return aggregatedOperation;
      }
      return ops[0];
    });
    setOperations(newOperations);
  }, [store.operationStore.operations.length]);

  const expandedRowRender = (operation) => {
    if (operation instanceof AggregatedOperation) {
      return (
        <Table
          rowKey={'id'}
          columns={operationColumns}
          showHeader={false}
          pagination={false}
          dataSource={operation.children}
          expandable={{expandedRowRender}}
          tableLayout={'fixed'}
        />
      );
    }
    return (
      <OperationComponentTable
        columns={componentColumns}
        operationId={operation.id}
        showActions={false}
      />
    );
  };

  return (
    <Table
      rowKey={'id'}
      columns={operationColumns}
      pagination={false}
      dataSource={operations}
      expandable={componentColumns && {
        expandedRowRender,
        expandedRowKeys: expandedKeys,
        onExpandedRowsChange: (keys) => setExpandedKeys(keys),
      }}
      loading={loading}
      tableLayout={'fixed'}
      childrenColumnName={'not-children'}
    />
  );
};

export default observer(OrderOperationTable);
