import React, { useCallback, useState } from 'react';
import { FormInstance, Select as AntdSelect, SelectProps as AntdSelectProps } from 'antd';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { LabeledValue } from 'antd/es/select';
import { useMount } from '../../../../hooks/useMount';
import { SearchParams } from '../../../../types/autocomplete';

export type AutoCompleteImplProps = {
  reverseSearchFunction: (searchValue: any) => any;
  searchFunction: (params: SearchParams) => Promise<any[]>;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  populateStore?: (value: any) => void;
  renderOption: (value: any) => { label: string, value: string | number };
};

export type AutoCompleteProps = Omit<AntdSelectProps, 'showArrow'> & {
  form?: FormInstance;
  name?: string;
  propertyName: string;
  allowClear?: boolean;
  onClear?: () => void;
  onChange?: (value: any) => void;
  onPressEnter?: (value: any) => void;
  value?: any;
  placeholder?: string;
  multiple?: boolean;
  disableExactMatch?: boolean;
};

const AutoComplete: React.FC<AutoCompleteProps & AutoCompleteImplProps> = ({
  form,
  name,
  propertyName,
  searchFunction,
  reverseSearchFunction,
  isLoading,
  loading,
  setIsLoading,
  allowClear = true,
  onClear,
  onChange,
  onPressEnter,
  value,
  placeholder,
  populateStore,
  renderOption,
  multiple,
  disableExactMatch,
  ...props
}) => {
  const [options, setOptions] = useState<{ label: string, value: string | number }[]>([]);
  const [lastResults, setLastResults] = useState<any>([]);
  const [initialValueIsSet, setInitialValueIsSet] = useState(false);
  const [labeledValue, setLabeledValue] = useState<LabeledValue[]>([]);
  const [rawSearchValue, setRawSearchValue] = useState('');
  const [valueOnEnter, setValueOnEnter] = useState('');
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const { t } = useTranslation();
  const setFormValue = (newValue: any) => {
    if (form && name) {
      form.setFieldsValue({ [name]: newValue });
    }
    if (onChange) {
      onChange(newValue);
    }
    if (populateStore && newValue) {
      if (multiple) {
        newValue.forEach((v: any) => populateStore(v));
      } else {
        populateStore(newValue);
      }
    }
  };

  const setValue = (option: any) => {
    if (multiple) {
      const formValue = (form && name) ? form.getFieldValue(name) : value;
      if (formValue) {
        if (!formValue.includes(option.value)) {
          setFormValue([...formValue, option.value]);
          setLabeledValue([...labeledValue, option]);
        }
      } else {
        setFormValue([option.value]);
        setLabeledValue([...labeledValue, option]);
      }
    } else {
      setFormValue(option.value);
      setLabeledValue(option);
      setDropDownVisible(false);
    }
  };

  const sendQuery = (query: any) => {
    setIsLoading(true);
    searchFunction({ params: { q: query } }).then((result) => {
      if (result.length > 0) {
        const renderedOptions = result.map(renderOption);
        if (!query) {
          // @ts-ignore
          renderedOptions.splice(0, 0, { value: -1, label: `${t('autocomplete.prompt')}...`, disabled: true });
        }
        setOptions(renderedOptions);
        setLastResults(result);
        const exactMatch = renderedOptions.find((o) => o.label?.match(`\\b${query}\\b`));
        if (renderedOptions.length === 1 && renderedOptions[0].value !== -1 && !disableExactMatch && exactMatch) {
          setValue(exactMatch);
          // Reset user input and trigger search
          setRawSearchValue('');
          sendQuery('');
        }
        setNoResultsFound(false);
        setDropDownVisible(true);
      } else {
        setNoResultsFound(true);
        setOptions([]);
        setDropDownVisible(true);
      }
    }).catch(() => {
      setNoResultsFound(true);
      setOptions([]);
      setDropDownVisible(false);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  useMount(() => {
    if (reverseSearchFunction && !initialValueIsSet && value) {
      setInitialValueIsSet(true);
      if (multiple) {
        let results = [];
        value.forEach((val: any) => {
          const matchingResult = lastResults.find((r: any) => String(r[propertyName]) === String(val));
          if (matchingResult) {
            results.push(matchingResult);
          }
        });
        if (value.length > results.length) {
          results = reverseSearchFunction(value);
        }
        setLabeledValue(results.map(renderOption));
      } else {
        let result = lastResults.find((r: any) => String(r[propertyName]) === String(value));
        if (!result) {
          result = reverseSearchFunction(value);
        }
        // @ts-ignore
        setLabeledValue(result ? renderOption(result) : []);
      }
    }
    if (value === undefined) {
      setOptions([]);
      setLabeledValue([]);
    }
  }, [value]);

  const debouncedQuery = useCallback(
    debounce((q) => sendQuery(q), 200),
    [[labeledValue, value, multiple, name]]
  );

  useMount(() => {
    if (!isLoading && options.length && valueOnEnter && onPressEnter) {
      const directMatch = options.find((o) => o.label === valueOnEnter);
      if (directMatch) {
        setValue(directMatch);
        setValueOnEnter('');
        setOptions([]);
        setLabeledValue([]);
        setLastResults([]);
        setDropDownVisible(false);
      }
    } else if (!isLoading && noResultsFound && valueOnEnter && onPressEnter) {
      onPressEnter(valueOnEnter);
      setValueOnEnter('');
      setNoResultsFound(false);
      setOptions([]);
      setLabeledValue([]);
      setLastResults([]);
      setDropDownVisible(false);
    }
  }, [valueOnEnter, isLoading, options]);

  const onSearch = (input: string) => {
    if (!options.find((option) => option.value === input)) {
      debouncedQuery(input);
    }
    setRawSearchValue(input);
    setDropDownVisible(true);
  };

  const onDeselect = (v: LabeledValue) => {
    if (multiple) {
      // @ts-ignore
      setFormValue(value.filter((el: LabeledValue) => el !== v.value));
      setLabeledValue(labeledValue.filter((el) => el.value !== v.value));
      setDropDownVisible(false);
    } else {
      setFormValue(null);
      setLabeledValue([]);
      setDropDownVisible(false);
    }
  };

  const onClearHandler = () => {
    if (multiple) {
      setFormValue([]);
      setLabeledValue([]);
    } else {
      setFormValue(null);
      setLabeledValue([]);
    }
    setOptions([]);
    setDropDownVisible(true);
    if (onClear) {
      onClear();
    }
  };

  const onKeyDownHandler = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && rawSearchValue && onPressEnter) {
      e.stopPropagation();
      setValueOnEnter(rawSearchValue);
    }
  };

  return (
    <AntdSelect
      {...props}
      loading={isLoading || loading}
      value={labeledValue}
      open={dropDownVisible}
      options={options}
      onSearch={onSearch}
      onSelect={(v, option) => setValue(option)}
      onDeselect={onDeselect}
      onClear={onClearHandler}
      onFocus={() => onSearch('')}
      onBlur={() => setDropDownVisible(false)}
      onInputKeyDown={onKeyDownHandler}
      showArrow={false}
      filterOption={false}
      mode={multiple ? 'multiple' : undefined}
      showSearch
      allowClear={allowClear}
      labelInValue
      placeholder={placeholder}
      searchValue={rawSearchValue}
    />
  );
};
export default AutoComplete;
