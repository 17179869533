import Highcharts from 'highcharts';
import Chart from './Chart';

const LineChart = ({options, isTimeseries, dataLabelsEnabled, isLoading, showIsEmpty}) => (
  <Chart
    highcharts={Highcharts}
    options={{
      ...options,
      chart: {
        type: 'line',
        zoomType: 'xy',
        ...options.chart,
      },
      plotOptions: {
        line: {
          step: 'left',
          ...options?.plotOptions?.line,
        },
        ...options?.plotOptions,
      },
    }}
    isTimeseries={isTimeseries}
    showIsEmpty={showIsEmpty}
    isLoading={isLoading}
    dataLabelsEnabled={dataLabelsEnabled}
  />
);

/**
 * Subtract from a widget's height to ensure Chart stays completely inside, if used maximized in a widget.
 * e.g. MultipleTimeseriesCockpitWidget
 * @type {number}
 */
LineChart.HEIGHT_MARGIN = 62;
/**
 * Subtract from a widget's width to ensure Chart stays completely inside, if used maximized in a widget.
 * e.g. MultipleTimeseriesCockpitWidget
 * @type {number}
 */
LineChart.WIDTH_MARGIN = 24;

export default LineChart;
