import React from 'react';
import { observer } from 'mobx-react-lite';
import { AnyObject } from 'antd/es/_util/type';
import Table from './Table';

export type MonoTableProps<RecordType extends AnyObject = AnyObject> = {
  rows: RecordType[];
};

// eslint-disable-next-line max-len
const MonoTable: <RecordType extends AnyObject = AnyObject, >(props: MonoTableProps<RecordType>) => React.ReactNode = <RecordType extends AnyObject = AnyObject, >(
  { rows }: MonoTableProps<RecordType>
) => (
  <Table<RecordType>
    striped="odd"
    size="small"
    components={{
      header: {
        wrapper: () => null,
      },
    }}
    rowKey={(row) => row[0]}
    pagination={false}
    columns={[
      {
        key: 'value',
        render: (x) => x,
      },
    ]}
    dataSource={rows}
  />
);

export default observer(MonoTable);
