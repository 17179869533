import {Button} from 'antd';
import {CheckSquareOutlined, CloseSquareOutlined, FileSyncOutlined, UploadOutlined} from '@ant-design/icons';
import {observer} from 'mobx-react-lite';
import {OperationDefaultTabs} from '../../../models/operation';
import {ReportState} from '../../../models/reportLog';

const ManualOrderReportingButton = ({
  record,
  reportType,
  selectedTab,
  store,
  setCurrentReportLog = () => undefined,
}) => {
  const createReport = async () => {
    const newReportLog = await store.reportLogStore.create({
      orderId: record.orderId,
      typeName: reportType.type,
    }, {skipNotification: true});

    if (newReportLog) {
      setCurrentReportLog(newReportLog);

      await store.orderStore.update({
        id: newReportLog.orderId,
        state: 6,
      }, {skipNotification: true});
    }
  };

  if (selectedTab?.id === OperationDefaultTabs.finished && !record.children) {
    const existingReport = store.reportLogStore.getOneByReportTypeIdAndOrderId(reportType.id, record.orderId);
    let icon = <CloseSquareOutlined/>;
    if (!existingReport) {
      icon = <UploadOutlined/>;
    } else if (existingReport?.state === ReportState.Success || existingReport?.state === ReportState.Archived) {
      icon = <CheckSquareOutlined/>;
    } else if (existingReport?.state === ReportState.Pending) {
      icon = <FileSyncOutlined/>;
    }
    return (
      <Button
        type={'link'}
        icon={icon}
        onClick={() => createReport(record)}
        loading={store.reportLogStore.isLoadingCollection}
        disabled={record.order.state !== 3}
      />
    );
  }
  return null;
};

export default observer(ManualOrderReportingButton);
