import { observable, makeObservable, computed } from 'mobx';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import { RootStore } from '../stores/rootStore';

export class PersonnelLog extends BaseModel {
  id: number = 0;
  workplaceId: number = 0;
  personnel: number = 0;
  remark: string | null = null;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      workplaceId: observable,
      personnel: observable,
      remark: observable,
      createdAt: observable,
      createdBy: observable,
      createdByUser: computed,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  displayableProperties = [
    displayableProperty({
      key: 'personnel',
      title: i18n.t('personnelLog.model.attributes.personnel'),
      params: [displayablePropertyParam({ path: 'personnel', transform: transformation.none })],
      template: '{value}',
    }),
  ];

  get createdByUser() {
    return this.rootStore.userStore.getById(this.createdBy);
  }
}
