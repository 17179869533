import { observable, makeObservable } from 'mobx';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { RootStore } from '../stores/rootStore';

export class InspectionTaskGroup extends BaseModel {
  id: number = 0;
  name: string = '';
  sortOrder: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      sortOrder: observable,
    });
  }

  static faIcon = faLayerGroup;

  searchableProperties = ['id', 'name'];

  displayableProperties = [
    displayableProperty({
      key: 'name',
      params: [displayablePropertyParam({ path: 'name' })],
      template: '{value}',
      title: i18n.t('inspectionTaskGroup.model.attributes.name'),
      raw: false,
    }),
  ];
}
