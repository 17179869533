import { observable, makeObservable } from 'mobx';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { LdapGroup } from '../models/ldapGroup';
import { RootStore } from './rootStore';

export class LdapGroupStore extends EntityStore<LdapGroup> {
  ldapGroups: LdapGroup[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'ldapGroups', Api.ldapGroups, LdapGroup);

    makeObservable(this, {
      ldapGroups: observable,
    });
  }
}
