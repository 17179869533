import { computed, observable, makeObservable } from 'mobx';
import { faThermometer } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { getTranslation } from '../utils/translations';
import { Translation } from './translations';
import { RootStore } from '../stores/rootStore';

/**
 * sensor type translations:
 * t('sensor.types.DEFAULT')
 * t('sensor.types.SPEED')
 * t('sensor.types.INTERRUPTION')
 * t('sensor.types.GOODS_RECEIPT')
 * t('sensor.types.PRODUCE')
 * t('sensor.types.CONSUME')
 * t('sensor.types.COMPONENT_PREPARE')
 * t('sensor.types.WASTE')
 */
export enum SensorType {
  DEFAULT = 'DEFAULT',
  // Edit state log
  INTERRUPTION = 'INTERRUPTION',
  // Edit operation/order
  SPEED = 'SPEED',
  GOODS_RECEIPT = 'GOODS_RECEIPT',
  WASTE = 'WASTE',
  // Create yield
  YIELD_GR = 'YIELD_GR',
  YIELD_WASTE = 'YIELD_WASTE',
  // Batch queue
  COMPONENT_PREPARE = 'COMPONENT_PREPARE',
  PRODUCE = 'PRODUCE',
  CONSUME = 'CONSUME'
}

export enum SensorState {
  GOOD = 'GOOD',
  WARNING = 'WARNING',
  BAD = 'BAD'
}

/**
 * status type translations:
 * t('sensor.statusTypes.boolean')
 * t('sensor.statusTypes.value')
 * t('sensor.statusTypes.speedometer')
 */
export enum SensorStatusType {
  Boolean = 'boolean',
  Value = 'value',
  Speedometer = 'speedometer'
}

interface SensorTranslation extends Translation {
  label: string | null
}

export class Sensor extends BaseModel {
  id: number = 0;
  name: string = '';
  opcPath: string | null = null;
  sensorType: SensorType = SensorType.DEFAULT;
  statusType: SensorStatusType | null = null;
  hierarchyId: number | null = null;
  online: boolean = false;
  isAsync: boolean = false;
  isManual: boolean = false;
  enabled: boolean = true;
  publishAsync: boolean = false;
  unitOfMeasureId: number | null = null;
  decimalPlaces: number | null = null;
  translations: SensorTranslation[] = [];
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      label: computed,
      opcPath: observable,
      sensorType: observable,
      statusType: observable,
      hierarchyId: observable,
      hierarchy: computed,
      online: observable,
      isAsync: observable,
      isManual: observable,
      enabled: observable,
      publishAsync: observable,
      unitOfMeasureId: observable,
      unitOfMeasure: computed,
      decimalPlaces: observable,
      translations: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faThermometer;

  searchableProperties = ['name', 'label', 'hierarchy.name'];

  translatedProperties = ['label'];

  saveableProperties = [
    'name',
    'opcPath',
    'sensorType',
    'statusType',
    'hierarchyId',
    'online',
    'isAsync',
    'isManual',
    'enabled',
    'publishAsync',
    'unitOfMeasureId',
    'decimalPlaces',
  ];

  get hierarchy() {
    return this.rootStore.hierarchyStore.getById(this.hierarchyId);
  }

  get unitOfMeasure() {
    return this.rootStore.unitOfMeasurementStore.getById(this.unitOfMeasureId);
  }

  static prepareApiPayload(model: Sensor) {
    return {
      name: model.name,
      opcPath: model.opcPath,
      sensorType: model.sensorType,
      online: model.online || false,
      enabled: model.enabled || false,
      decimalPlaces: model.decimalPlaces,
      hierarchyId: model.hierarchyId || null,
      unitOfMeasureId: model.unitOfMeasureId || null,
      isAsync: model.isAsync || false,
      isManual: model.isManual || false,
      publishAsync: model.publishAsync || false,
      statusType: model.statusType || null,
      translations: Sensor.convertToSavableTranslations(model),
    };
  }

  get label() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.label;
  }

  roundedValue(value: number) {
    if (value && this.decimalPlaces) {
      return Math.round(value * (10 ** this.decimalPlaces)) / (10 ** this.decimalPlaces);
    }
    return value;
  }
}
