import { action, makeObservable, observable } from 'mobx';
import dayjs from 'dayjs';
import { Api } from '../middleware/api';
import { EntityStore, LoadStrategies } from './entityStore';
import { InspectionTaskResult, InspectionTaskResultValuation } from '../models/inspectionTaskResult';
import { RootStore } from './rootStore';

interface InspectionTaskResultFilter {
  fromDate?: string,
  toDate?: string,
  inspectionTaskId?: number,
  inspectionTaskName?: string,
  orderId?: number[],
  workplaceIds: number[],
  batchId?: number[],
  inspectionLotId?: number,
  valuation?: InspectionTaskResultValuation[],
  isVerified?: boolean,
  taskPropertyKey?: string[],
  taskPropertyValue?: string[],
  recordedFromDate?: string,
  recordedToDate?: string
}

export class InspectionTaskResultStore extends EntityStore<InspectionTaskResult> {
  inspectionTaskResults: InspectionTaskResult[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'inspectionTaskResults', Api.inspectionTaskResult, InspectionTaskResult);

    makeObservable(this, {
      inspectionTaskResults: observable,
      loadByInspectionTaskId: action,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.inspectionTaskStore,
        modelId: 'inspectionTaskId',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'createdBy',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'updatedBy',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'overruledUserId',
      },
    ];
  }

  getByInspectionTaskId(inspectionTaskId: number) {
    return this.inspectionTaskResults.filter((r) => r.inspectionTaskId === inspectionTaskId);
  }

  loadByInspectionTaskId(inspectionTaskId: number) {
    return this.loadAllWithDependencies({ params: { inspectionTaskId } });
  }

  loadFilteredWithDependencies(filter: InspectionTaskResultFilter) {
    return this.loadAllWithDependencies({
      params: {
        ...filter,
      },
      strategy: LoadStrategies.replace,
    });
  }

  getFiltered(filter: InspectionTaskResultFilter) {
    return this.inspectionTaskResults.filter((result) => {
      if (filter?.fromDate && dayjs(filter.fromDate).isAfter(dayjs(result.createdAt))) {
        return false;
      }
      if (filter?.toDate && dayjs(filter.toDate).isBefore(dayjs(result.createdAt))) {
        return false;
      }
      if (filter?.isVerified !== undefined && filter.isVerified !== result.isVerified) {
        return false;
      }
      if (filter?.batchId?.length
        && !filter.batchId.find((id) => id === result.batchId)) {
        return false;
      }
      if (filter?.orderId?.length
        && !filter.orderId.find((val) => val === result.inspectionTask?.inspectionLot?.orderId)) {
        return false;
      }
      if (filter?.workplaceIds?.length
        && !filter.workplaceIds.find((val) => val === result.inspectionTask?.workplaceId)) {
        return false;
      }
      if (filter?.valuation?.length && !filter.valuation.find((val) => val === result.valuation)) {
        return false;
      }
      if (filter.taskPropertyKey) {
        const doesNotMatch = filter.taskPropertyKey.some(
          (key, index) => result.inspectionTask?.properties?.[key] !== filter.taskPropertyValue?.[index]
        );
        if (doesNotMatch) {
          return false;
        }
      }
      return true;
    });
  }
}
