import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export type TabTitleProps = {
  faIcon: IconDefinition;
  title: React.ReactNode;
};

export const TabTitle: React.FC<TabTitleProps> = ({ faIcon, title }) => (
  <>
    <FontAwesomeIcon icon={faIcon} style={{ marginRight: 10 }}/>
    {title}
  </>
);
