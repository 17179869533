import {compact} from 'lodash';
import i18n from '../../../i18n/i18n';
import {BaseWidgetConfig} from '../../shared/widgets/baseWidgetConfig';
import {ValidationRules} from '../../../utils/validationRules';
import {Operation, OperationDefaultTabs} from '../../../models/operation';
import {defaultOperationStateTabs} from '../../../stores/operationStateStore';
import {iconSelects} from '../orderDetails/orderWidgetConfigIcon';
import {getAllI18nTranslations} from '../../../utils/translations';
import appConfig from '../../../utils/appConfig';
import {FieldType} from '../../../types/widgetConfig';

export class OrderOperatorWidgetConfig extends BaseWidgetConfig {
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'settings',
        label: i18n.t('widget.WidgetSettingsModal.settingConfigTabTitle'),
      },
      {
        key: 'advancedSettings',
        label: i18n.t('advancedOrderOfficeWidget.settings.modalTabAdvancedSettings'),
      },
      {
        key: 'appearance',
        label: i18n.t('widget.WidgetSettingsModal.appearanceConfigTabTitle'),
      },
      {
        key: 'data',
        label: i18n.t('widget.WidgetSettingsModal.dataConfigTabTitle'),
      },
    ];
  }

  settings = compact([
    {
      id: 'title',
      label: i18n.t('widget.settings.title.label'),
      helpText: i18n.t('widget.settings.title.help'),
      type: FieldType.Translatable,
      defaultValue: getAllI18nTranslations('order.model.other'),
      validations: [ValidationRules.required()],
    },
    {
      id: 'loadDaysPast',
      label: i18n.t('orderWidget.settings.loadDays.past'),
      type: FieldType.Number,
      unit: i18n.t('orderWidget.settings.loadDays.unit'),
      defaultValue: 15,
      validations: [ValidationRules.required()],
    },
    {
      id: 'loadDaysFuture',
      label: i18n.t('orderWidget.settings.loadDays.future'),
      type: FieldType.Number,
      unit: i18n.t('orderWidget.settings.loadDays.unit'),
      defaultValue: 15,
      validations: [ValidationRules.required()],
    },
    {
      id: 'showDatePicker',
      label: i18n.t('orderWidget.settings.showDatePicker'),
      type: FieldType.Boolean,
      defaultValue: false,
      validations: [ValidationRules.required()],
    },
    {
      id: 'filterOrders',
      label: i18n.t('orderWidget.settings.filterOrders.label'),
      helpText: i18n.t('orderWidget.settings.filterOrders.help'),
      defaultValue: false,
      type: FieldType.Boolean,
    },
    {
      id: 'warningPreviousOperationNotStartedDisabled',
      label: i18n.t('orderWidget.settings.warningPreviousOperationNotStartedDisabled.label'),
      helpText: i18n.t('orderWidget.settings.warningPreviousOperationNotStartedDisabled.help'),
      defaultValue: false,
      type: FieldType.Boolean,
    },
    appConfig.modules.enableTransitionQueue ? {
      id: 'enableTransitionQueue',
      label: i18n.t('orderWidget.settings.enableTransitionQueue.label'),
      helpText: i18n.t('orderWidget.settings.enableTransitionQueue.help'),
      defaultValue: false,
      type: FieldType.Boolean,
    } : undefined,
    appConfig.modules.enableTransitionQueue ? {
      id: 'startAllOperations',
      label: i18n.t('orderWidget.settings.startAllOperations.label'),
      helpText: i18n.t('orderWidget.settings.startAllOperations.help'),
      defaultValue: false,
      type: FieldType.Boolean,
    } : undefined,
  ]);

  appearance = compact([
    {
      id: 'color.planned',
      label: i18n.t('orderWidget.settings.color.planned'),
      type: FieldType.Color,
      defaultValue: '#FEF7EA',
    },
    {
      id: 'color.ready',
      label: i18n.t('orderWidget.settings.color.ready'),
      type: FieldType.Color,
      defaultValue: '#FEF7EA',
    },
    {
      id: 'color.paused',
      label: i18n.t('orderWidget.settings.color.paused'),
      type: FieldType.Color,
      defaultValue: '#F3F3F3',
    },
    {
      id: 'color.active',
      label: i18n.t('orderWidget.settings.color.active'),
      type: FieldType.Color,
      defaultValue: '#E8F4FF',
    },
    {
      id: 'color.finished',
      label: i18n.t('orderWidget.settings.color.finished'),
      type: FieldType.Color,
      defaultValue: '#EDF9E8',
    },
    {
      id: 'color.aborted',
      label: i18n.t('orderWidget.settings.color.aborted'),
      type: FieldType.Color,
      defaultValue: '#F9E3E6',
    },
    {
      id: 'color.disabled',
      label: i18n.t('orderWidget.settings.color.disabled'),
      type: FieldType.Color,
      defaultValue: '#CCCCCC',
    },
    appConfig.modules.enableTransitionQueue ? {
      id: 'color.queued',
      label: i18n.t('orderWidget.settings.color.queued'),
      type: FieldType.Color,
      defaultValue: '#F0E5FD',
    } : undefined,
    appConfig.modules.enableTransitionQueue ? {
      id: 'color.scheduled',
      label: i18n.t('orderWidget.settings.color.scheduled'),
      type: FieldType.Color,
      defaultValue: '#FFDAD5',
    } : undefined,
    ...iconSelects,
  ]);

  data = [
    {
      id: 'properties',
      label: i18n.t('orderWidget.settings.properties'),
      type: FieldType.PropertiesSelectDisplayField,
      modelOptions: Operation.allDisplayableProperties(this.rootStore),
      defaultValue: [
        'status',
        'plannedStartDate',
        'plannedStartTime',
        'plannedEndTime',
        'order.no',
        'order.material.no',
        'order.material.name',
        'order.plannedQuantity',
      ],
    },
    {
      id: 'propertiesFinished',
      label: i18n.t('orderWidget.settings.propertiesFinished'),
      type: FieldType.PropertiesSelectDisplayField,
      modelOptions: Operation.allDisplayableProperties(this.rootStore),
      defaultValue: [
        'status',
        'actualStartDate',
        'actualStartTime',
        'actualEndTime',
        'order.no',
        'order.material.no',
        'order.material.name',
        'order.actualQuantity',
      ],
    },
    {
      id: 'sortOrderForFinishedOrders',
      label: i18n.t('orderWidget.settings.sortOrderForFinishedOrders'),
      type: FieldType.Select,
      validations: [ValidationRules.required()],
      defaultValue: 'desc',
      options: [
        {
          value: 'asc',
          label: i18n.t('orderWidget.settings.sortOrder.asc.label'),
          helpText: i18n.t('orderWidget.settings.sortOrder.asc.help'),
        },
        {
          value: 'desc',
          label: i18n.t('orderWidget.settings.sortOrder.desc.label'),
          helpText: i18n.t('orderWidget.settings.sortOrder.desc.help'),
        },
      ],
    },
  ];

  advancedSettings = [
    {
      id: 'operationStates',
      label: i18n.t('advancedOrderOfficeWidget.settings.operationStates'),
      type: FieldType.SelectMultiple,
      defaultValue: [],
      options: this.rootStore.operationStateStore.operationStates.map((state) => ({
        value: state.id,
        label: state.label,
      })),
      validations: [],
    },
    {
      id: 'displayOperationStateColor',
      label: i18n.t('advancedOrderOfficeWidget.settings.displayOperationStateColor.label'),
      helpText: i18n.t('advancedOrderOfficeWidget.settings.displayOperationStateColor.help'),
      type: FieldType.Boolean,
      defaultValue: false,
      validations: [],
    },
    {
      id: 'operationStateColorOpacity',
      label: i18n.t('advancedOrderOfficeWidget.settings.operationStateColorOpacity.label'),
      helpText: i18n.t('advancedOrderOfficeWidget.settings.operationStateColorOpacity.help'),
      type: FieldType.Number,
      defaultValue: 20,
      validations: [ValidationRules.minNumber(0), ValidationRules.maxNumber(100)],
    },
    {
      id: 'defaultFilter',
      label: i18n.t('advancedOrderOfficeWidget.settings.operationStateFilter.label'),
      helpText: i18n.t('advancedOrderOfficeWidget.settings.operationStateFilter.help'),
      type: FieldType.Select,
      defaultValue: OperationDefaultTabs.planned,
      options: [
        ...this.rootStore.operationStateStore.operationStates.map((state) => ({
          value: state.id,
          label: state.label,
        })),
        ...defaultOperationStateTabs().map((state) => ({
          value: state.id,
          label: state.label,
        })),
      ],
      validations: [ValidationRules.required()],
    },
  ];

  getSelectedOperationStateIds() {
    return this.getSettingValue('advancedSettings', 'operationStates');
  }

  getActiveOperationFilter() {
    return this.getSettingValue('advancedSettings', 'defaultFilter');
  }
  getOrderTableColumnsPlanned() {
    return this.getSelectedProperties('data', 'properties');
  }

  getOrderTableColumnsFinished() {
    return this.getSelectedProperties('data', 'propertiesFinished');
  }

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'title');
  }
}
