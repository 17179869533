import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {v4 as uuid} from 'uuid';
import {useTranslation} from 'react-i18next';
import StorageUnitForm from './StorageUnitForm';
import FormModal from '../../shared/modal/FormModal';
import {makeFormFields} from '../../shared/form/formFields';
import Form from '../../shared/form/Form';
import {useStore} from '../../../hooks/useStore';
import i18n from '../../../i18n/i18n';
import {PropertiesObject} from '../../../models/propertiesTransforms';

const NewStorageUnit = ({onCancel, onSuccess, unitOfMeasurements, storageAreas, widgetConfig, customProperties}) => {
  const {t} = useTranslation();
  const store = useStore();
  const [fields, setFields] = useState(
    widgetConfig?.getDefaultStorageArea()
      ? makeFormFields({
        no: uuid(),
        storageAreaId: widgetConfig.getDefaultStorageArea(),
      })
      : []
  );
  const [form] = Form.useForm();

  const handleSubmit = async ({levels, ...values}) => {
    try {
      if (levels?.length) {
        if (!levels.some((el) => Number(el.value) === Number(values.capacity))) {
          store.storageUnitStore.addMessage({
            type: 'error',
            title: i18n.t('storageUnitLevel.errors.messages.capacityNotReached'),
          }, {});
          return;
        }
        if (levels.some((el) => Number(el.value) > Number(values.capacity))) {
          store.storageUnitStore.addMessage({
            type: 'error',
            title: i18n.t('storageUnitLevel.errors.messages.capacityOverflow'),
          }, {});
          return;
        }
      }

      const properties = PropertiesObject({customProperties, formValues: values});

      const storageUnit = await store.storageUnitStore.create({...values, properties}, {skipNotification: true});

      if (levels?.length) {
        const levelPromises = [];
        levels.forEach((level) => {
          levelPromises.push(store.storageUnitLevelStore.create(
            {storageUnitId: storageUnit.id, ...level},
            {skipNotification: true}
          ));
        });
        await Promise.all(levelPromises);
      }

      store.storageUnitStore.addMessage({type: 'success', title: i18n.t('flashMessages.createSuccess')}, {});

      if (onSuccess) {
        onSuccess(storageUnit);
      }
    } catch (errors) {
      setFields(makeFormFields(values, errors.fields));
    }
  };

  return (
    <FormModal title={t('storageUnit.new.title')} open onCancel={onCancel}>
      <StorageUnitForm
        form={form}
        fields={fields}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        isSaving={store.storageUnitStore.isMutationInProgress}
        unitOfMeasurements={unitOfMeasurements}
        storageAreas={storageAreas}
        widgetConfig={widgetConfig}
        customProperties={customProperties}
      />
    </FormModal>
  );
};

export default observer(NewStorageUnit);
