import {createEntity, executeDelete, executeGet, executePatch} from '../requests';

const resourcesName = 'v1/messages';

export const MessagesApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, orderId, materialId, hierarchyIds, ...entity}) => executePatch(`${resourcesName}/${id}`, entity),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
};
