import { computed, makeObservable, observable } from 'mobx';
import { Observer } from 'mobx-react-lite';
import i18n from '../i18n/i18n';
import { displayableProperty } from './displayableProperty';
import { displayablePropertyParam } from './displayablePropertyParam';
import { sortAlphabetically, sortBoolean, sortChronologically } from '../components/shared/tables/sorters';
import HelpTooltip from '../components/shared/tooltips/HelpTooltip';
import { RootStore } from '../stores/rootStore';
import { StateLog } from './stateLog';
import { transformation } from '../utils/transformations';
import { datetimeFormat } from '../config/dayjs';
import UserFullName from '../components/shared/UserFullName';
import { CustomPropertyTypes } from './customPropertyDataTypes';

export class OperationStateLog extends StateLog {
  operationId: number = 0;
  timeoutMessage: string | null = null;
  phaseTimeoutReasonId: number | null = null;
  isForceStarted: boolean | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      state: computed,
      operationId: observable,
      operation: computed,
      timeoutMessage: observable,
      phaseTimeoutReasonId: observable,
      phaseTimeoutReason: computed,
      isForceStarted: observable,
    });
  }

  get state() {
    return this.rootStore.operationStateStore.getById(this.stateId);
  }

  get operation() {
    return this.rootStore.operationStore.getById(this.operationId);
  }

  get phaseTimeoutReason() {
    return this.rootStore.phaseTimeoutReasonStore.getById(this.phaseTimeoutReasonId);
  }

  customPropertyType? = CustomPropertyTypes.OperationStateLog;

  displayableProperties = [
    displayableProperty({
      title: i18n.t('stateLog.model.attributes.start'),
      params: [
        displayablePropertyParam({
          path: 'start', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      key: 'start',
      sorter: (a, b) => sortChronologically(a.start, b.start),
      raw: false,
    }),
    displayableProperty({
      title: i18n.t('stateLog.model.attributes.end'),
      params: [
        displayablePropertyParam({
          path: 'end', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      key: 'end',
      sorter: (a, b) => sortChronologically(a.end, b.end),
      raw: false,
    }),
    displayableProperty({
      title: i18n.t('stateLog.model.attributes.durationSeconds'),
      params: [
        displayablePropertyParam({
          path: 'durationSeconds', transform: transformation.detailedDuration,
        }),
      ],
      template: '{value}',
      key: 'durationSeconds',
      sorter: (a, b) => sortChronologically(a.durationSeconds, b.durationSeconds),
      raw: false,
    }),
    displayableProperty({
      title: i18n.t('stateLog.model.attributes.active'),
      params: [
        displayablePropertyParam({
          path: 'active', transform: transformation.none,
        }),
      ],
      template: '{value}',
      key: 'active',
      sorter: (a, b) => sortBoolean(a.active, b.active),
      raw: false,
    }),
    displayableProperty({
      title: i18n.t('stateLog.model.attributes.interruptionSourceHierarchy'),
      params: [
        displayablePropertyParam({
          path: 'interruptionSourceHierarchy.title', transform: transformation.none,
        }),
      ],
      template: '{value}',
      key: 'interruptionSourceHierarchy',
      sorter: (a, b) => sortAlphabetically(
        a.interruptionSourceHierarchy?.title,
        b.interruptionSourceHierarchy?.title
      ),
      raw: false,
    }),
    displayableProperty({
      title: i18n.t('interruptionClass.model.one'),
      params: [
        displayablePropertyParam({
          path: 'interruptionClass.label', transform: transformation.none,
        }),
      ],
      template: '{value}',
      key: 'interruptionClass',
      sorter: (a, b) => sortAlphabetically(
        a.interruptionClass?.label,
        b.interruptionClass?.label
      ),
      raw: false,
    }),
    displayableProperty({
      title: i18n.t('interruptionReason.model.one'),
      params: [
        displayablePropertyParam({
          path: 'interruptionReason.label', transform: transformation.none,
        }),
      ],
      template: '{value}',
      key: 'interruptionReason',
      sorter: (a, b) => sortAlphabetically(a.interruptionReason?.label, b.interruptionReason?.label),
      raw: false,
    }),
    displayableProperty({
      title: i18n.t('stateLog.model.attributes.message'),
      params: [
        displayablePropertyParam({
          path: 'message', transform: transformation.none,
        }),
      ],
      template: '{value}',
      key: 'message',
      sorter: (a, b) => sortAlphabetically(a.message, b.message),
      raw: false,
    }),
    displayableProperty({
      title: i18n.t('stateLog.model.attributes.createdBy'),
      params: [
        displayablePropertyParam({
          path: 'createdByUser',
          transform: (user) => (
            <Observer>
              {() => <UserFullName user={user}/>}
            </Observer>
          ),
        }),
      ],
      template: '{value}',
      key: 'createdByUser',
      sorter: (a, b) => sortAlphabetically(a.createdByUser.fullName, b.createdByUser.fullName),
      raw: true,
    }),
    displayableProperty({
      title: i18n.t('stateLog.model.attributes.updatedBy'),
      params: [
        displayablePropertyParam({
          path: 'updatedByUser',
          transform: (user) => (
            <Observer>
              {() => <UserFullName user={user}/>}
            </Observer>
          ),
        }),
      ],
      template: '{value}',
      key: 'updatedByUser',
      sorter: (a, b) => sortAlphabetically(a.updatedByUser.fullName, b.updatedByUser.fullName),
      raw: true,
    }),
    displayableProperty({
      title: i18n.t('stateLog.model.attributes.interruptionSourceHierarchyCompletePath'),
      params: [
        displayablePropertyParam({
          path: '.',
          transform: (l) => OperationStateLog.getInterruptionSourceHierarchyPath(l),
        }),
      ],
      template: '{value}',
      key: 'interruptionSourceHierarchyCompletePath',
      sorter: (a, b) => sortAlphabetically(
        a.interruptionSourceHierarchy?.title,
        b.interruptionSourceHierarchy?.title
      ),
      raw: true,
    }),
    displayableProperty({
      title: i18n.t('stateLog.model.attributes.interruptionSourceHierarchyRelativePath'),
      params: [
        displayablePropertyParam({
          path: '.',
          transform: (l) => OperationStateLog.getInterruptionSourceHierarchyPath(l, true),
        }),
      ],
      template: '{value}',
      key: 'interruptionSourceHierarchyRelativePath',
      sorter: (a, b) => sortAlphabetically(
        a.interruptionSourceHierarchy?.title,
        b.interruptionSourceHierarchy?.title
      ),
      raw: true,
    }),
  ];

  static getInterruptionSourceHierarchyPath(operationStateLog: OperationStateLog, relative = false) {
    const { interruptionSourceHierarchy } = operationStateLog;
    if (!interruptionSourceHierarchy?.parents) {
      return '';
    }
    let parents = interruptionSourceHierarchy.parents.reverse();
    const fullPath = parents.map((h) => h.title).reduce((acc, cur, i) => {
      acc += `${'  '.repeat(i)} - **${cur}** \n`;
      return acc;
    }, '');
    let relativePath = '';
    const hierarchy = operationStateLog.operation?.workplace?.hierarchy;
    if (relative && hierarchy) {
      parents = parents.filter((h) => h.level >= hierarchy.level);
      relativePath = parents.map((h) => h.title).reduce((acc, cur) => {
        acc += `${cur} > `;
        return acc;
      }, '');
      relativePath = relativePath.substring(0, relativePath.length - 3);
    }
    return (
      <span>
        <span>{relative && relativePath ? relativePath : operationStateLog.interruptionSourceHierarchy?.title}</span>
        <HelpTooltip
          title={fullPath}
          markdown
        />
      </span>
    );
  }

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);
    allDisplayableProperties.push(
      ...this.displayableCustomProperties(rootStore, pathPrefix, titlePrefix)
    );

    return allDisplayableProperties;
  }

  static getInterruptionSourceHierarchyRelativePath(operationStateLog: OperationStateLog) {
    const { interruptionSourceHierarchy } = operationStateLog;
    if (!interruptionSourceHierarchy?.parents) {
      return '';
    }
    const fullPath = interruptionSourceHierarchy.parents.map((h) => h.title).reverse().reduce((acc, cur, i) => {
      acc += `${'  '.repeat(i)} - **${cur}** \n`;
      return acc;
    }, '');

    return (
      <span>
        <span>{operationStateLog.interruptionSourceHierarchy?.title}</span>
        <HelpTooltip
          title={fullPath}
          markdown
          overlayInnerStyle={{ width: 'fit-content', blockSize: 'fit-content' }}
          overlayStyle={{ width: 'fit-content', blockSize: 'fit-content' }}
        />
      </span>
    );
  }
}
