import { computed, observable, makeObservable } from 'mobx';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';
import { CustomActionAdditionalHeaders } from './customActionAdditionalHeaders';

export enum CustomActionHttpMethod {
  GET = 'GET',
  POST = 'POST'
}

export enum CustomActionType {
  PreTransition = 'PRE',
  PostTransition = 'POST'
}

export interface CustomActionParams {
  [key: string]: any;
}

export class CustomAction extends BaseModel {
  id: number = 0;
  name: string = '';
  execOrder: number = 0;
  params: CustomActionParams = {};
  url: string = '';
  additionalHeaders: CustomActionAdditionalHeaders = {};
  method: CustomActionHttpMethod = CustomActionHttpMethod.GET;
  type: CustomActionType = CustomActionType.PostTransition;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      execOrder: observable,
      params: observable,
      paramsFormatted: computed,
      url: observable,
      additionalHeaders: observable,
      additionalHeadersFormatted: computed,
      method: observable,
      type: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faSparkles;

  searchableProperties = ['name', 'method', 'type'];

  saveableProperties = ['name', 'execOrder', 'params', 'url', 'method', 'type', 'params', 'additionalHeaders'];

  get paramsFormatted() {
    return this.params ? JSON.stringify(this.params) : null;
  }

  get additionalHeadersFormatted() {
    return this.additionalHeaders ? JSON.stringify(this.additionalHeaders) : null;
  }
}

/**
 * t('customAction.description.POST_PERSONNEL_RESET_TO_PREVIOUS')
 * t('customAction.description.POST_PERSONNEL_SET_TO_PLANNED')
 * t('customAction.description.POST_PERSONNEL_SET_TO_ZERO')
 * t('customAction.description.POST_Q_ACTIVATE_ORIGIN_PMOPERATION_VLS_ALL')
 * t('customAction.description.POST_Q_ACTIVATE_ORIGIN_PMOPERATION_VLS_NEXT')
 * t('customAction.description.POST_Q_ACTIVATE_ORIGIN_PMWORKPLACE_VLS_ALL')
 * t('customAction.description.POST_Q_ACTIVATE_ORIGIN_PMWORKPLACE_VLS_NEXT')
 * t('customAction.description.POST_Q_ACTIVATE_ORIGIN_QM_VLS_ALL')
 * t('customAction.description.POST_Q_ACTIVATE_ORIGIN_QM_VLS_NEXT')
 * t('customAction.description.POST_Q_CALCULATE_ORIGIN_PMOPERATION_VLS_NEXT')
 * t('customAction.description.POST_Q_CALCULATE_ORIGIN_PMWORKPLACE_VLS_NEXT')
 * t('customAction.description.POST_Q_CALCULATE_ORIGIN_QM_VLS_NEXT')
 * t('customAction.description.POST_Q_DEACTIVATE_ORIGIN_PMOPERATION_VLS_ALL')
 * t('customAction.description.POST_Q_DEACTIVATE_ORIGIN_PMOPERATION_VLS_BEFORE')
 * t('customAction.description.POST_Q_DEACTIVATE_ORIGIN_PMWORKPLACE_VLS_ALL')
 * t('customAction.description.POST_Q_DEACTIVATE_ORIGIN_PMWORKPLACE_VLS_BEFORE')
 * t('customAction.description.POST_Q_DEACTIVATE_ORIGIN_QM_VLS_ALL')
 * t('customAction.description.POST_Q_DEACTIVATE_ORIGIN_QM_VLS_BEFORE')
 * t('customAction.description.POST_Q_DELETE_ORIGIN_PMOPERATION_VLS_ALL')
 * t('customAction.description.POST_Q_DELETE_ORIGIN_PMOPERATION_VLS_BEFORE')
 * t('customAction.description.POST_Q_DELETE_ORIGIN_PMWORKPLACE_VLS_ALL')
 * t('customAction.description.POST_Q_DELETE_ORIGIN_PMWORKPLACE_VLS_BEFORE')
 * t('customAction.description.POST_Q_DELETE_ORIGIN_QM_VLS_ALL')
 * t('customAction.description.POST_Q_DELETE_ORIGIN_QM_VLS_BEFORE')
 * t('customAction.description.POST_Q_GENERATE_ORIGIN_QM_VLS_ALL')
 * t('customAction.description.PRE_NEXT_WORKPLACE_AVAILABLE')
 * t('customAction.description.PRE_Q_CHECK_ORIGIN_PMOPERATION_VLS_ALL')
 * t('customAction.description.PRE_Q_CHECK_ORIGIN_PMOPERATION_VLS_BEFORE')
 * t('customAction.description.PRE_Q_CHECK_ORIGIN_PMOPERATION_VLS_NEXT')
 * t('customAction.description.PRE_Q_CHECK_ORIGIN_PMWORKPLACE_VLS_ALL')
 * t('customAction.description.PRE_Q_CHECK_ORIGIN_PMWORKPLACE_VLS_BEFORE')
 * t('customAction.description.PRE_Q_CHECK_ORIGIN_PMWORKPLACE_VLS_NEXT')
 * t('customAction.description.PRE_Q_CHECK_ORIGIN_QM_VLS_ALL')
 * t('customAction.description.PRE_Q_CHECK_ORIGIN_QM_VLS_BEFORE')
 * t('customAction.description.PRE_Q_CHECK_ORIGIN_QM_VLS_NEXT')
 * t('customAction.description.PRE_CHECK_UNCLASSIFIED_INTERRUPTIONS')
 * t('customAction.description.PRE_RUNNING_PREPARATIONS')
 * t('customAction.description.POST_Q_DELETE_ORIGIN_PMWORKPLACE_VLS_EXCEPTION')
 * t('customAction.description.POST_Q_DELETE_ORIGIN_PMOPERATION_VLS_EXCEPTION')
 * t('customAction.description.POST_Q_DELETE_ORIGIN_QM_VLS_EXCEPTION')
 * t('customAction.description.SEND_TO_EXTERNAL_RESOURCE')
 * t('customAction.description.PRE_CHECK_INVENTORY')
 * t('customAction.description.PRE_COMPONENT_STORAGE_CHECK')
 */
export const IntegratedCustomActions = Object.freeze({
  POST_PERSONNEL_RESET_TO_PREVIOUS: 'POST_PERSONNEL_RESET_TO_PREVIOUS',
  POST_PERSONNEL_SET_TO_PLANNED: 'POST_PERSONNEL_SET_TO_PLANNED',
  POST_PERSONNEL_SET_TO_ZERO: 'POST_PERSONNEL_SET_TO_ZERO',
  POST_Q_ACTIVATE_ORIGIN_PMOPERATION_VLS_ALL: 'POST_Q_ACTIVATE_ORIGIN_PMOPERATION_VLS_ALL',
  POST_Q_ACTIVATE_ORIGIN_PMOPERATION_VLS_NEXT: 'POST_Q_ACTIVATE_ORIGIN_PMOPERATION_VLS_NEXT',
  POST_Q_ACTIVATE_ORIGIN_PMWORKPLACE_VLS_ALL: 'POST_Q_ACTIVATE_ORIGIN_PMWORKPLACE_VLS_ALL',
  POST_Q_ACTIVATE_ORIGIN_PMWORKPLACE_VLS_NEXT: 'POST_Q_ACTIVATE_ORIGIN_PMWORKPLACE_VLS_NEXT',
  POST_Q_ACTIVATE_ORIGIN_QM_VLS_ALL: 'POST_Q_ACTIVATE_ORIGIN_QM_VLS_ALL',
  POST_Q_ACTIVATE_ORIGIN_QM_VLS_NEXT: 'POST_Q_ACTIVATE_ORIGIN_QM_VLS_NEXT',
  POST_Q_CALCULATE_ORIGIN_PMOPERATION_VLS_NEXT: 'POST_Q_CALCULATE_ORIGIN_PMOPERATION_VLS_NEXT',
  POST_Q_CALCULATE_ORIGIN_PMWORKPLACE_VLS_NEXT: 'POST_Q_CALCULATE_ORIGIN_PMWORKPLACE_VLS_NEXT',
  POST_Q_CALCULATE_ORIGIN_QM_VLS_NEXT: 'POST_Q_CALCULATE_ORIGIN_QM_VLS_NEXT',
  POST_Q_DEACTIVATE_ORIGIN_PMOPERATION_VLS_ALL: 'POST_Q_DEACTIVATE_ORIGIN_PMOPERATION_VLS_ALL',
  POST_Q_DEACTIVATE_ORIGIN_PMOPERATION_VLS_BEFORE: 'POST_Q_DEACTIVATE_ORIGIN_PMOPERATION_VLS_BEFORE',
  POST_Q_DEACTIVATE_ORIGIN_PMWORKPLACE_VLS_ALL: 'POST_Q_DEACTIVATE_ORIGIN_PMWORKPLACE_VLS_ALL',
  POST_Q_DEACTIVATE_ORIGIN_PMWORKPLACE_VLS_BEFORE: 'POST_Q_DEACTIVATE_ORIGIN_PMWORKPLACE_VLS_BEFORE',
  POST_Q_DEACTIVATE_ORIGIN_QM_VLS_ALL: 'POST_Q_DEACTIVATE_ORIGIN_QM_VLS_ALL',
  POST_Q_DEACTIVATE_ORIGIN_QM_VLS_BEFORE: 'POST_Q_DEACTIVATE_ORIGIN_QM_VLS_BEFORE',
  POST_Q_DELETE_ORIGIN_PMOPERATION_VLS_ALL: 'POST_Q_DELETE_ORIGIN_PMOPERATION_VLS_ALL',
  POST_Q_DELETE_ORIGIN_PMOPERATION_VLS_BEFORE: 'POST_Q_DELETE_ORIGIN_PMOPERATION_VLS_BEFORE',
  POST_Q_DELETE_ORIGIN_PMWORKPLACE_VLS_ALL: 'POST_Q_DELETE_ORIGIN_PMWORKPLACE_VLS_ALL',
  POST_Q_DELETE_ORIGIN_PMWORKPLACE_VLS_BEFORE: 'POST_Q_DELETE_ORIGIN_PMWORKPLACE_VLS_BEFORE',
  POST_Q_DELETE_ORIGIN_QM_VLS_ALL: 'POST_Q_DELETE_ORIGIN_QM_VLS_ALL',
  POST_Q_DELETE_ORIGIN_QM_VLS_BEFORE: 'POST_Q_DELETE_ORIGIN_QM_VLS_BEFORE',
  POST_Q_GENERATE_ORIGIN_QM_VLS_ALL: 'POST_Q_GENERATE_ORIGIN_QM_VLS_ALL',
  PRE_NEXT_WORKPLACE_AVAILABLE: 'PRE_NEXT_WORKPLACE_AVAILABLE',
  PRE_CHECK_INVENTORY: 'PRE_CHECK_INVENTORY',
  PRE_Q_CHECK_ORIGIN_PMOPERATION_VLS_ALL: 'PRE_Q_CHECK_ORIGIN_PMOPERATION_VLS_ALL',
  PRE_Q_CHECK_ORIGIN_PMOPERATION_VLS_BEFORE: 'PRE_Q_CHECK_ORIGIN_PMOPERATION_VLS_BEFORE',
  PRE_Q_CHECK_ORIGIN_PMOPERATION_VLS_NEXT: 'PRE_Q_CHECK_ORIGIN_PMOPERATION_VLS_NEXT',
  PRE_Q_CHECK_ORIGIN_PMWORKPLACE_VLS_ALL: 'PRE_Q_CHECK_ORIGIN_PMWORKPLACE_VLS_ALL',
  PRE_Q_CHECK_ORIGIN_PMWORKPLACE_VLS_BEFORE: 'PRE_Q_CHECK_ORIGIN_PMWORKPLACE_VLS_BEFORE',
  PRE_Q_CHECK_ORIGIN_PMWORKPLACE_VLS_NEXT: 'PRE_Q_CHECK_ORIGIN_PMWORKPLACE_VLS_NEXT',
  PRE_Q_CHECK_ORIGIN_QM_VLS_ALL: 'PRE_Q_CHECK_ORIGIN_QM_VLS_ALL',
  PRE_Q_CHECK_ORIGIN_QM_VLS_BEFORE: 'PRE_Q_CHECK_ORIGIN_QM_VLS_BEFORE',
  PRE_Q_CHECK_ORIGIN_QM_VLS_NEXT: 'PRE_Q_CHECK_ORIGIN_QM_VLS_NEXT',
  PRE_CHECK_UNCLASSIFIED_INTERRUPTIONS: 'PRE_CHECK_UNCLASSIFIED_INTERRUPTIONS',
  PRE_RUNNING_PREPARATIONS: 'PRE_RUNNING_PREPARATIONS',
  POST_Q_DELETE_ORIGIN_PMWORKPLACE_VLS_EXCEPTION: 'POST_Q_DELETE_ORIGIN_PMWORKPLACE_VLS_EXCEPTION',
  POST_Q_DELETE_ORIGIN_PMOPERATION_VLS_EXCEPTION: 'POST_Q_DELETE_ORIGIN_PMOPERATION_VLS_EXCEPTION',
  POST_Q_DELETE_ORIGIN_QM_VLS_EXCEPTION: 'POST_Q_DELETE_ORIGIN_QM_VLS_EXCEPTION',
  SEND_TO_EXTERNAL_RESOURCE: 'SEND_TO_EXTERNAL_RESOURCE',
  PRE_COMPONENT_STORAGE_CHECK: 'PRE_COMPONENT_STORAGE_CHECK',
});
