import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {groupBy, sumBy} from 'lodash';
import {faListAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useMount} from '../../../hooks/useMount';
import ComponentTable from './ComponentTable';
import {useStore} from '../../../hooks/useStore';
import appConfig from '../../../utils/appConfig';
import Button from '../../shared/buttons/Button';
import ConsumptionLogModal from '../../batchHandling/shared/ConsumptionLogModal';

const OperationComponentTable = ({columns = [], operationId, groupComponents, showActions = true}) => {
  const store = useStore();
  const [consumptionLogComponents, setConsumptionLogComponents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [components, setComponents] = useState([]);

  useMount(() => {
    setLoading(true);
    store.componentStore.loadByOperation(operationId).then(() => {
      setLoading(false);
    });
  }, [operationId]);

  const aggregateComponents = () => {
    const rawComponents = store.componentStore.getByOperationId(operationId);

    const grouped = groupBy(rawComponents, (c) => `${c.materialId}-${c.unitOfMeasureId}`);
    if (!groupComponents) {
      setComponents(rawComponents);
    } else {
      const groupedData = Object.entries(grouped).map(([materialId, groupedComponents]) => ({
        ...groupedComponents[0],
        id: `${operationId}_${materialId}`,
        operationId,
        plannedQuantity: sumBy(groupedComponents, 'plannedQuantity'),
        actualQuantity: sumBy(groupedComponents, 'actualQuantity'),
        preparationQuantity: sumBy(groupedComponents, 'preparationQuantity'),
        material: groupedComponents[0].material,
        operation: groupedComponents[0].operation,
        unitOfMeasure: groupedComponents[0].unitOfMeasure,
        info: groupedComponents[0].info,
        groupedComponents,
        batchNos: groupedComponents.map((comp) => comp.batchNos).join(', '),
      }));
      setComponents(groupedData);
    }
  };

  useMount(() => {
    aggregateComponents();
  }, [store.componentStore.components.length, loading]);

  const onConsumptionLogModalClose = () => {
    setConsumptionLogComponents([]);
  };

  const tableColumns = [...columns];
  if ((appConfig.modules.enableBatchHandling || appConfig.modules.enableBatchQueue) && showActions) {
    tableColumns.push({
      title: '',
      key: 'actions',
      fixed: 'right',
      width: 50,
      render: (value, component) => {
        const onClick = () => {
          setConsumptionLogComponents(groupComponents ? component.groupedComponents : [component]);
        };
        return (
          <Button type="default" icon={<FontAwesomeIcon icon={faListAlt}/>} onClick={onClick}/>
        );
      },
    });
  }

  tableColumns.map((item) => {
    item.title = item.label;
    return item;
  });

  return (
    <>
      <ComponentTable
        columns={tableColumns}
        components={components}
        loading={loading}
      />
      <ConsumptionLogModal components={consumptionLogComponents} onClose={onConsumptionLogModalClose}/>
    </>
  );
};

export default observer(OperationComponentTable);
