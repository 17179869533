/**
 lang.en
 lang.en-CH
 lang.de
 lang.de-CH
 lang.fr
 lang.fr-FR
 lang.it
 lang.it-IT
 lang.pl
 lang.pl-PL
 */
export enum Language {
  EN_CH = 'en-CH',
  DE_CH = 'de-CH',
  FR_FR = 'fr-FR',
  IT_IT = 'it-IT',
  PL_PL = 'pl-PL'
}

export interface Translation {
  language: Language;
}
