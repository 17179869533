import {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlayCircle} from '@fortawesome/pro-solid-svg-icons';
import {observer} from 'mobx-react-lite';
import {sortBy} from 'lodash';
import dayjs from 'dayjs';
import OrderTable from './OrderTable';
import {useMount} from '../../../hooks/useMount';
import {StateTransitionWidgetDetailModal} from '../../stateTransition/StateTransitionWidgetDetailModal';
import {useStore} from '../../../hooks/useStore';
import {useModulePolicy} from '../../../hooks/useModulePolicy';
import {transition} from '../../../models/scope';
import {OperationDisplayStates} from '../../../models/operation';
import Button from '../../shared/buttons/Button';
import OrderWidgetPopconfirm from './OrderWidgetPopconfirm';
import appConfig from '../../../utils/appConfig';
import {LoadStrategies} from '../../../stores/entityStore';
import {AggregatedOperation} from '../../../models/aggregatedOperation';

const OrderTableStateChange = ({
  identifier,
  settings,
  columnsPlanned,
  columnsFinished,
  activeFilter,
  operationStateIds,
  workplaceId,
  useSelectedOperation = [undefined, () => undefined],
  useIsReordering = [undefined, () => false],
  setShowOperationDetails = () => {},
  setDetailsModalVisible = () => {},
  fromDate,
  toDate,
  isModalTable,
  showTypeSelect,
  widgetConfig,
  displayStateChanged = 0,
}) => {
  const store = useStore();
  const [validTransitionsLoading, setValidTransitionsLoading] = useState(false);
  const [transitionConfirmVisible, setTransitionConfirmVisible] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentStartTransitionId, setCurrentStartTransitionId] = useState();
  const [currentOperationId, setCurrentOperationId] = useState();
  const [selectedAggregatedOperation, setSelectedAggregatedOperation] = useState(undefined);
  const [lastStateChange, setLastStateChange] = useState(null);
  const [forcedStart, setForcedStart] = useState(false);
  const policy = useModulePolicy(store, [transition]);
  const startAnyPolicy = useModulePolicy(store, ['start-any-operation']);

  useMount(() => {
    let latestChange = store.operationStateLogStore.getLatestOfWorkplace(workplaceId);
    const latestWpStateChange = store.workplaceStateLogStore.getLatestOfWorkplace(workplaceId);

    if ((!latestChange && latestWpStateChange)
      || (latestChange && latestWpStateChange && latestChange.start < latestWpStateChange.start)) {
      latestChange = latestWpStateChange;
    }
    setLastStateChange(latestChange);
  }, [
    store.workplaceStore.selectedWorkplace,
    store.operationStateLogStore.logs.length,
    store.workplaceStateLogStore.logs.length,
  ]);

  const openModal = (startTransitionId, operationId) => {
    setCurrentStartTransitionId(startTransitionId);
    setCurrentOperationId(operationId);
    setValidTransitionsLoading(false);
    setTransitionConfirmVisible(null);
    setModalVisible(true);
  };

  const closeModal = () => {
    setCurrentStartTransitionId(undefined);
    setCurrentOperationId(undefined);
    setSelectedAggregatedOperation(undefined);
    setModalVisible(false);
  };

  const handleTransitionQueueStarts = async (operationId, transitionId) => {
    if (selectedAggregatedOperation) {
      const promises = [];
      selectedAggregatedOperation.children.forEach((op) => {
        if (op.isPlanned && op.id !== operationId) {
          promises.push(store.transitionQueueStore.create({
            operationId: op.id,
            workplaceId: op.workplaceId,
            transitionId,
            plannedExecution: null,
          }));
        }
      });
      await Promise.all(promises);
    }
  };

  const afterExecuteTransition = (response, request) => {
    if (request?.startingOperationId && selectedAggregatedOperation) {
      handleTransitionQueueStarts(request.startingOperationId, currentStartTransitionId);
    }
    store.relevantMessagesStore.loadRelevantMessages();
  };

  const handleHasPreviousOperation = (previousOperation) => {
    const previousOperationIsStarted = Boolean(previousOperation?.actualStart);

    if (!previousOperationIsStarted) {
      return Promise.resolve(false);
    }
    return Promise.resolve(true);
  };

  const handleHasNoPreviousOperation = async (operation) => {
    const hasParentOrder = Boolean(operation.order?.parentOrderId);

    if (hasParentOrder) {
      const parentOperations = await store.operationStore.loadAllWithDependencies({
        params: {orderId: operation.order.parentOrderId},
      });

      const sortedParentOperations = sortBy(parentOperations, ['no'], ['asc']);

      if (sortedParentOperations.length) {
        return handleHasPreviousOperation(
          sortedParentOperations[sortedParentOperations.length - 1]
        );
      }
      return Promise.resolve(true);
    }
    return Promise.resolve(true);
  };

  const validateTransition = (operation) => {
    if (appConfig.modules.enableTransitionQueue && operation.isActive) {
      return Promise.resolve(true);
    }
    if (widgetConfig.getSettingValue('settings', 'warningPreviousOperationNotStartedDisabled')) {
      return Promise.resolve(true);
    }
    const sortedOperationsOfSelectedOrder = sortBy(
      store.operationStore.operations.filter((o) => o.orderId === operation.orderId),
      ['no'],
      ['asc']
    );

    const workplace = store.workplaceStore.getById(operation.workplaceId);
    const parent = workplace.hierarchyId ? store.hierarchyStore.getById(workplace.hierarchyId).parent : undefined;

    let posOfSelectedOperation = sortedOperationsOfSelectedOrder.findIndex((o) => o.id === operation.id);

    if (parent) {
      const parentWorkplace = store.workplaceStore.getByHierarchyId(parent.id);
      if (parentWorkplace?.isVirtualRoot) {
        posOfSelectedOperation = sortedOperationsOfSelectedOrder.findIndex((o) => o.workplaceId === parentWorkplace.id);
      } else if (parentWorkplace) {
        posOfSelectedOperation = undefined;
      }
    }

    const posOfPreviousOperation = posOfSelectedOperation ? (posOfSelectedOperation - 1) : -1;
    const hasPreviousOperation = posOfPreviousOperation >= 0;

    if (hasPreviousOperation) {
      return handleHasPreviousOperation(sortedOperationsOfSelectedOrder[posOfPreviousOperation]);
    }
    return handleHasNoPreviousOperation(operation);
  };

  const isInterruption = () => {
    const latestWorkplaceLog = store.workplaceStateLogStore
      .getLatestOfWorkplace(store.workplaceStore.selectedWorkplace.id);
    if (latestWorkplaceLog) {
      const latestOperationLog = store.operationStateLogStore
        .getActiveOfWorkplace(store.workplaceStore.selectedWorkplace.id);

      const currentWorkplaceState = latestWorkplaceLog
        ? store.workplaceStateStore.getById(latestWorkplaceLog.stateId)
        : undefined;
      const activeOperationState = latestOperationLog
        ? store.operationStateStore.getById(latestOperationLog.stateId)
        : undefined;

      if (currentWorkplaceState?.isInterruption || activeOperationState?.isInterruption) {
        return true;
      }
    }
    return false;
  };

  const canStartTransition = (operation) => {
    let canStart = policy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId});
    if (canStart && operation.displayStatus === OperationDisplayStates.active) {
      canStart = false;
    }
    if (canStart && isInterruption()) {
      canStart = false;
    }
    if (canStart && !startAnyPolicy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})) {
      // Paused operations can always be restarted
      if (operation.displayStatus === OperationDisplayStates.paused) {
        canStart = true;
      } else {
        // filter by type and date (only use operations that are displayed).
        const listStart = fromDate || dayjs().subtract(settings.loadDaysPast, 'days').startOf('day');
        const listEnd = toDate || dayjs().add(settings.loadDaysFuture, 'days').startOf('day');
        const plannedOperations = store.operationStore.aggregatedOperations
          .filter((op) => op.isPlannedOrReady
            && listStart.isBefore(op.plannedEnd) && listEnd.isAfter(op.plannedStart));
        if (plannedOperations.length > 0) {
          if (plannedOperations[0].children?.length > 0) {
            canStart = plannedOperations[0].children.some((child) => child.id === operation.id);
          } else {
            canStart = plannedOperations[0].id === operation.id;
          }
        } else {
          canStart = false;
        }
      }
    }
    return canStart;
  };

  const confirmForceStart = (operation) => {
    setForcedStart(true);
    setTransitionConfirmVisible(null);
    openModal(store.validStartTransitionsStore.transitions[0].id, operation.id);
  };

  const handleTransitionApiError = () => {
    store.operationStore.loadPlannedOperationsByWorkplace(
      store.workplaceStore.selectedWorkplace.id,
      {
        params: {
          fromDate: fromDate.toISOString(),
          toDate: toDate.toISOString(),
        },
      }
    );
  };

  const handleTransitionStart = (operation) => {
    validateTransition(operation).then((previousOperationValid) => {
      if (!previousOperationValid) {
        setTransitionConfirmVisible(operation.id);
      } else {
        openModal(store.validStartTransitionsStore.transitions[0]?.id, operation.id);
      }
    });
  };

  const handleRequestStart = (record) => {
    let operation = record;
    if (record instanceof AggregatedOperation && record.orderId) {
      operation = record.children.find((op) => op.isPlanned);
      setSelectedAggregatedOperation(record);
    }

    setTransitionConfirmVisible(null);
    setValidTransitionsLoading(true);
    store.validStartTransitionsStore.loadValidStartTransitions(operation.id)
      .then(() => store.transitionQueueStore.loadAll(
        {params: {workplaceId: store.workplaceStore.selectedWorkplace.id}, strategy: LoadStrategies.replace}
      ))
      .then(() => store.transitionStore.loadQueueTransitions())
      .then(() => handleTransitionStart(operation));
  };

  const stateChangeColumn = {
    render: (record) => {
      const doRender = !record.children
        || (record instanceof AggregatedOperation && record.orderId && settings?.startAllOperations);
      return {
        key: 'state-change-col',
        props: {
          className: doRender ? 'full-size-content' : '',
        },
        children: doRender && (
          <OrderWidgetPopconfirm
            showTransitionConfirm={transitionConfirmVisible === record.id}
            validateTransition={(startTransition) => validateTransition(startTransition, record)}
            onConfirmForce={() => confirmForceStart(record)}
            onCancel={() => {
              setTransitionConfirmVisible(null);
              setValidTransitionsLoading(false);
            }}
          >
            <Button
              icon={<FontAwesomeIcon icon={faPlayCircle}/>}
              type={'link'}
              onClick={(e) => {
                handleRequestStart(record);
                e.stopPropagation();
              }}
              disabled={!canStartTransition(record)}
              loading={validTransitionsLoading}
              style={{width: '54px', height: '100%', padding: 0}}
            />
          </OrderWidgetPopconfirm>
        ),
      };
    },
  };

  const columnsPlannedStart = [...columnsPlanned];
  const columnsFinishedStart = [...columnsFinished];

  columnsPlannedStart.splice(0, 0, stateChangeColumn);
  columnsFinishedStart.splice(0, 0, stateChangeColumn);

  return (
    <>
      <OrderTable
        fromDate={fromDate}
        toDate={toDate}
        showTypeSelect={showTypeSelect}
        workplaceId={workplaceId}
        identifier={identifier}
        settings={settings}
        activeFilter={activeFilter}
        operationStateIds={operationStateIds}
        columnsPlanned={columnsPlannedStart}
        columnsFinished={columnsFinishedStart}
        isModalTable={isModalTable}
        useSelectedOperation={useSelectedOperation}
        useIsReordering={useIsReordering}
        setShowOperationDetails={setShowOperationDetails}
        setDetailsModalVisible={setDetailsModalVisible}
        displayStateChanged={displayStateChanged}
      />
      {modalVisible && (
        <StateTransitionWidgetDetailModal
          fullscreen={store.clientStore.isMobile}
          onCancel={closeModal}
          onApiError={handleTransitionApiError}
          defaultTransitionId={currentStartTransitionId}
          afterExecuteTransition={afterExecuteTransition}
          startingOperationId={currentOperationId}
          isForceStarted={forcedStart}
          lastStateChange={lastStateChange}
          enableTransitionQueue={settings?.enableTransitionQueue && appConfig.modules.enableTransitionQueue}
        />
      )}
    </>
  );
};

export default observer(OrderTableStateChange);
