import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import SubTitleWithIcon from '../../shared/typography/SubTitleWithIcon';
import styles from './Message.module.scss';
import MarkdownItem from '../../shared/MarkdownItem';

const Message = ({message}) => {
  const {t} = useTranslation();

  return (
    <div
      className={styles.message}
      style={{
        paddingRight: message.hasDocuments ? '48px' : '16px',
        display: message.hasDocuments ? 'inline-block' : '',
      }}
    >
      <SubTitleWithIcon
        icon={<FontAwesomeIcon icon={faEnvelope} style={{marginRight: 8}}/>}
        text={t('message.model.attributes.message')}
      />
      <div className={styles.messageText}>
        <MarkdownItem>
          {message.message}
        </MarkdownItem>
      </div>
    </div>
  );
};

export default observer(Message);
