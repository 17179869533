import dayjs from 'dayjs';
import {dateFormat} from 'highcharts';

/**
 * formats given date (as Milliseconds)
 *
 * if timestamp is set the date format is dynamically adjusted to give preciser information as needed
 * example: if the from and to of the timespan is less than 5 minutes apart then seconds are shown as well.
 *
 * @param dateAsMs date as milliseconds
 * @param timespan object in the format {from: dateInMs, to: dateInMs}
 * @param breakLine if set to true line breaks before time
 * @returns {string}
 */
export const fullDateTimeFormatter = (dateAsMs, timespan = {from: null, to: null}, breakLine = false) => {
  let date = '%a %e. %b %Y';
  let time = '%H:%M';
  if (timespan && timespan.from && timespan.to) {
    const duration = dayjs.duration(timespan.to - timespan.from);
    if (dayjs(timespan.from).year() === dayjs(timespan.to).year()) {
      date = '%a %e. %b';
    }
    if (duration.asMinutes() < 5) {
      time = '%H:%M:%S';
    }
    if (duration.asSeconds() < 5) {
      time = '%H:%M:%S.%l';
    }
  }

  return `${dateFormat(date, dateAsMs)}${breakLine ? '<br/>' : ' '}${dateFormat(time, dateAsMs)}`;
};

/**
 * formats given date (as Milliseconds)
 *
 * if timestamp is set the date format is simplified to return a HH:mm format
 *
 * @param dateAsMs date as milliseconds
 * @returns {string}
 */
export const timeFormatter = (dateAsMs) => {
  const time = '%H:%M';
  return `${dateFormat(time, dateAsMs)}`;
};
