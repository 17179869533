import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore, ModelDependency } from './entityStore';
import { ProcessDataConnection } from '../models/processDataConnection';
import { RootStore } from './rootStore';

export class ProcessDataConnectionStore extends EntityStore<ProcessDataConnection> {
  processDataConnections: [] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'processDataConnections', Api.processDataConnections, ProcessDataConnection);

    makeObservable(this, {
      processDataConnections: observable,
    });
  }

  getDependencies(): ModelDependency[] {
    return [
      {
        store: this.rootStore.gatewayStore,
        modelId: 'gatewayId',
      },
    ];
  }
}
