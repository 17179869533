import { observable, makeObservable } from 'mobx';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { EventLog } from '../models/eventLog';
import { RootStore } from './rootStore';

export class EventLogStore extends EntityStore<EventLog> {
  eventLogs: EventLog[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'eventLogs', Api.eventLogs, EventLog);

    makeObservable(this, {
      eventLogs: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.materialStore,
        modelId: 'materialId',
      },
      {
        store: this.rootStore.orderStore,
        modelId: 'orderId',
      },
      {
        store: this.rootStore.inspectionLotStore,
        modelId: 'inspectionLotId',
      },
      {
        store: this.rootStore.externalResourceStore,
        modelId: 'externalResourceId',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'userId',
      },
    ];
  }

  getByOrderId(orderId: number) {
    return this.eventLogs.filter((log) => log.orderId === orderId);
  }
}
