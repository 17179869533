import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { RootStore } from './rootStore';
import { UserNotificationConfig } from '../models/userNotificationConfig';

export class UserNotificationConfigStore extends EntityStore<UserNotificationConfig> {
  userNotificationConfigs: UserNotificationConfig[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'userNotificationConfigs', Api.userNotificationConfig, UserNotificationConfig, true);

    makeObservable(this, {
      userNotificationConfigs: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.hierarchyStore,
        modelId: 'hierarchyId',
      },
    ];
  }
}
