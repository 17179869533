import React from 'react';
import { Col, Row } from 'antd';
import { chunk } from 'lodash';
import KeyValueTable, { KeyValue } from '../tables/KeyValueTable';

export type InformationRowProps = {
  items: KeyValue[];
  cols: number;
};

const InformationRow: React.FC<InformationRowProps> = ({ items, cols }) => (
  items?.length ? (
    <Row gutter={16}>
      {
        chunk(items, Math.ceil(items.length / cols)).map((group, index) => (
          <Col className="gutter-row" span={Math.floor(24 / cols)} key={group[0][0]}>
            {/* eslint-disable-next-line react/no-array-index-key */}
            <KeyValueTable key={index} rows={group}/>
          </Col>
        ))
      }
    </Row>
  ) : null
);

export default InformationRow;
