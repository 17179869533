/**
 * custom property data types translations:
 * t('customProperty.dataTypes.NUMBER')
 * t('customProperty.dataTypes.BOOLEAN')
 * t('customProperty.dataTypes.STRING')
 * t('customProperty.dataTypes.OBJECT')
 * t('customProperty.dataTypes.ENUM')
 * t('customProperty.dataTypes.COLOR')
 */
export enum CustomPropertyDataTypes {
  Number = 'NUMBER',
  Boolean = 'BOOLEAN',
  String = 'STRING',
  Object = 'OBJECT',
  Enum = 'ENUM',
  Color = 'COLOR'
}

/**
 * custom property translations:
 * t('customProperty.types.COMPONENT')
 * t('customProperty.types.EXTERNAL_RESOURCE')
 * t('customProperty.types.INSPECTION_LOT')
 * t('customProperty.types.INSPECTION_TASK')
 * t('customProperty.types.MATERIAL')
 * t('customProperty.types.ORDER')
 * t('customProperty.types.OPERATION')
 * t('customProperty.types.OPERATION_STATE')
 * t('customProperty.types.OPERATION_STATE_LOG')
 * t('customProperty.types.USER')
 * t('customProperty.types.WORKFLOW')
 * t('customProperty.types.WORKPLACE')
 * t('customProperty.types.WORKPLACE_STATE')
 * t('customProperty.types.WORKPLACE_STATE_LOG')
 * t('customProperty.types.BATCH')
 * t('customProperty.types.STORAGE_UNIT')
 * t('customProperty.types.GATEWAY')
 */
export enum CustomPropertyTypes {
  Component = 'COMPONENT',
  ExternalResource = 'EXTERNAL_RESOURCE',
  InspectionLot = 'INSPECTION_LOT',
  InspectionTask = 'INSPECTION_TASK',
  Material = 'MATERIAL',
  Operation = 'OPERATION',
  OperationState = 'OPERATION_STATE',
  OperationStateLog = 'OPERATION_STATE_LOG',
  Order = 'ORDER',
  User = 'USER',
  Workflow = 'WORKFLOW',
  Workplace = 'WORKPLACE',
  WorkplaceState = 'WORKPLACE_STATE',
  WorkplaceStateLog = 'WORKPLACE_STATE_LOG',
  Batch = 'BATCH',
  StorageUnit = 'STORAGE_UNIT',
  Gateway = 'GATEWAY'
}
