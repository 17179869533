import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Modal from '../shared/modal/Modal';
import DateRangePicker from '../shared/inputs/DateRangePicker';
import {FilterFormItem} from '../shared/form/FilterFormItem';
import LineChart from '../shared/charts/LineChart';
import {useMount} from '../../hooks/useMount';
import {useStore} from '../../hooks/useStore';
import {EnDash} from '../shared/unicodeWrapper/EnDash';
import {useModulePolicy} from '../../hooks/useModulePolicy';
import {useScopes} from '../../hooks/useScopes';
import Button from '../shared/buttons/Button';
import {SensorData as Model} from '../../models/sensorData';

const MAX_DURATION_DAYS = 7;

const ManualSensorDataWidgetHistoryModal = ({sensors, onCancel, onNew}) => {
  const {t} = useTranslation();
  const store = useStore();
  const scopes = useScopes();
  const policy = useModulePolicy(store, scopes);
  const [dates, setDates] = useState({fromDate: dayjs().subtract(1, 'day'), toDate: dayjs()});
  const [isLoading, setIsLoading] = useState(false);
  const [valid, setValid] = useState({state: 'success', help: null});
  const [sensorData, setSensorData] = useState([]);
  const [options, setOptions] = useState({
    series: [],
    title: {text: ''},
    yAxis: {title: ''},
    xAxis: {
      min: dates.fromDate.valueOf(),
      max: dates.toDate.valueOf(),
    },
  });

  const updateOptions = (newOptions) => {
    setOptions({
      ...options,
      ...newOptions,
    });
  };

  useMount(() => {
    if (sensors?.length > 0 && valid.state === 'success') {
      setIsLoading(true);
      const requests = [];

      sensors.forEach((sensor) => {
        requests.push(store.sensorDataStore.loadAll({
          params: {
            sensorName: sensor.name,
            workplaceId: store.workplaceStore.selectedWorkplace.id,
            fromDate: dates.fromDate.toISOString(),
            toDate: dates.toDate.toISOString(),
            amountOfLeadingItems: 1,
          },
          raw: true,
        }));
      });

      Promise.all(requests).then((results) => {
        setIsLoading(false);
        setSensorData(results);
      });
    }
  }, [sensors.length, dates, valid]);

  useMount(() => {
    if (isLoading) {
      return;
    }
    const axis = new Map();
    const yAxis = [{
      title: {text: `[${EnDash()}]`},
      showEmpty: false,
    }];
    sensors.forEach((sensor) => {
      const uom = sensor?.unitOfMeasure;
      if (uom && !axis.has(uom?.id)) {
        const index = yAxis.push({
          title: {text: `${uom?.name} [${uom?.label}]`},
          showEmpty: false,
        });
        axis.set(uom?.id, index - 1);
      }
    });
    const seriesData = sensorData.map((result, i) => {
      const uom = sensors[i].unitOfMeasure;
      const series = {
        name: `${sensors[i].label} [${uom?.label || EnDash()}]`,
        data: result.data.map((point) => ({
          x: dayjs(point.timestamp).valueOf(),
          y: sensors[i].roundedValue(point.value),
        })),
        yAxis: uom ? axis.get(uom?.id) : 0,
        step: 'left',
      };
      if (series.data.length > 0) {
        series.data.push({
          x: dayjs().valueOf(),
          y: series.data[series.data.length - 1].y,
        });
      }
      return series;
    });
    updateOptions({
      series: seriesData,
      yAxis,
      sensors,
      xAxis: {
        min: dates.fromDate.valueOf(),
        max: dates.toDate.valueOf(),
      },
    });
  }, [sensorData]);

  const handleNew = () => {
    if (onCancel) {
      onCancel();
    }
    if (onNew) {
      onNew();
    }
  };

  const handleDates = (dateChange) => {
    if (dateChange) {
      const duration = dayjs.duration(dayjs(dateChange[1]).diff(dayjs(dateChange[0]))).asDays();
      if (duration > MAX_DURATION_DAYS) {
        setValid({
          state: 'error',
          help: t('manualSensorDataWidget.errors.dateRangeTooLong', {days: MAX_DURATION_DAYS}),
        });
      } else {
        setValid({
          state: 'success',
          help: null,
        });
      }
      setDates({
        fromDate: dateChange[0], toDate: dateChange[1],
      });
    }
  };

  return (
    <Modal
      title={(
        <>
          <FontAwesomeIcon icon={Model.faIcon}/>
          {' '}
          {t('manualSensorDataWidget.history.title')}
        </>
      )}
      open
      onCancel={onCancel}
      footer={(
        <Button
          type={'primary'}
          onClick={() => handleNew()}
          disabled={!policy.canCreate()}
        >
          {t('manualSensorDataWidget.create')}
        </Button>
      )}
      width={700}
    >
      <FilterFormItem
        label={t('manualSensorDataWidget.history.time')}
        validateStatus={valid.state}
        help={valid.help}
      >
        <DateRangePicker
          value={[dates.fromDate, dates.toDate]}
          onChange={handleDates}
          allowClear={false}
          showDefaultTimeRanges
          disabledDate={(current) => current && current > dayjs()}
        />
      </FilterFormItem>
      <LineChart
        options={options}
        isTimeseries
        isLoading={isLoading}
      />
    </Modal>
  );
};

export default observer(ManualSensorDataWidgetHistoryModal);
