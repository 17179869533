import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Radio, Row, Space } from 'antd';
import React, { useState } from 'react';
import { Key } from 'antd/es/table/interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagCheckered, faPlay, faPrint } from '@fortawesome/free-solid-svg-icons';
import { Dayjs } from 'dayjs';
import Table from '../../shared/tables/Table';
import { TableColumns } from '../../shared/tables/columns';
import { sortAlphabetically } from '../../shared/tables/sorters';
import { defaultPaginationPreset } from '../../shared/tables/paginationPresets';
import { PrintJob, PrintJobState } from '../../../models/printJob';
import { EnDash } from '../../shared/unicodeWrapper/EnDash';
import ShowButton from '../../shared/buttons/ShowButton';
import EditButton from '../../shared/buttons/EditButton';
import { useScopes } from '../../../hooks/useScopes';
import { useModulePolicy } from '../../../hooks/useModulePolicy';
import { useStore } from '../../../hooks/useStore';
import PrintJobActions from './PrintJobActions';
import { FlashMessage, FlashMessageType } from '../../../models/flashMessage';
import { useMount } from '../../../hooks/useMount';
import { calculateEndpoint } from '../../../middleware/endpoints/printJobs';
import PrintJobFilter from '../office/PrintJobFilter';
import { Operation } from '../../../models/operation';

export type PrintJobTableProps = {
  fromDate?: Dayjs | null;
  toDate?: Dayjs | null;
  hierarchyId?: number | null;
  onEdit: (printJob: PrintJob) => void;
  onView: (printJob: PrintJob) => void;
  dateAndHierarchyFilterEnabled?: boolean;
};

const PrintJobTable: React.FC<PrintJobTableProps> = ({
  fromDate,
  toDate,
  hierarchyId,
  onEdit,
  onView,
  dateAndHierarchyFilterEnabled = false,
}) => {
  const { t } = useTranslation();
  const store = useStore();
  const scopes = useScopes();
  const policy = useModulePolicy(store, scopes);
  const [printState, setPrintState] = useState<PrintJobState>(PrintJobState.INIT);
  const [searchQuery, setSearchQuery] = useState<string>();
  const [selectedJobs, setSelectedJobs] = useState<number[]>([]);
  const [update, setUpdate] = useState<boolean>(false);

  useMount(() => {
    store.printerStore.loadAll();
    setUpdate(!update);
  }, [
    store.printLabelStore.printLabels.length,
    store.orderStore.orders.length,
    store.printerStore.printers.length,
  ]);

  const loadData = () => {
    if (!fromDate || !toDate || !hierarchyId) {
      return;
    }
    store.printJobStore.loadByDatesAndHierarchyIdAndState(
      fromDate,
      toDate,
      hierarchyId,
      printState
    );
  };

  useMount(() => {
    loadData();
  }, [hierarchyId, fromDate, toDate, printState]);
  const getResultLine = (title: string, ids: number[]) => {
    if (!ids.length) {
      return null;
    }
    return (
      <div>
        {title}
        {': [ '}
        {ids.join(', ')}
        {' ]'}
      </div>
    );
  };

  const handleResult = (data: any) => {
    const message = (
      <div>
        {getResultLine(t('printJob.success'), data.success)}
        {getResultLine(t('printJob.skipped'), data.skipped)}
        {getResultLine(t('printJob.failure'), data.failure)}
      </div>
    );
    const type = data.failure.length ? FlashMessageType.ERROR : FlashMessageType.SUCCESS;
    store.flashMessageStore.addFlashMessage(new FlashMessage(type, message, {}, 10));
  };

  const handleRecalculate = async () => {
    const operationIdsSet: Set<number> = new Set();
    const jobs = store.printJobStore.getByIds(selectedJobs);
    jobs.forEach((job) => operationIdsSet.add(job.operationId));
    const operationIds = Array.from(operationIdsSet);
    // eslint-disable-next-line no-restricted-syntax
    for (const operationId of operationIds) {
      // eslint-disable-next-line no-await-in-loop
      await store.printJobStore.calculate(operationId);
    }
    setSelectedJobs([]);
    loadData();
  };

  const handleStart = async () => {
    try {
      const result = await store.printJobStore.start(selectedJobs);
      handleResult(result.data);
    } catch (e) {
      store.flashMessageStore.addFlashMessage(new FlashMessage(FlashMessageType.ERROR, t('printJob.error'), {}));
    }
    setSelectedJobs([]);
    loadData();
  };

  const handleAbort = async () => {
    try {
      const result = await store.printJobStore.abort(selectedJobs);
      handleResult(result.data);
    } catch (e) {
      store.flashMessageStore.addFlashMessage(new FlashMessage(FlashMessageType.ERROR, t('printJob.error'), {}));
    }
    setSelectedJobs([]);
    loadData();
  };

  let dataSource = store.printJobStore.printJobs.slice();
  if (searchQuery && searchQuery.length > 0) {
    dataSource = PrintJob.search(searchQuery, dataSource);
  }

  const isLoading = store.printJobStore.isLoadingCollection
    || store.printJobStore.isActionInProgress(calculateEndpoint);

  const printStateOptions = [
    {
      label: (
        <Space>
          <FontAwesomeIcon icon={faPlay}/>
          {t('printJob.model.states.INIT')}
        </Space>
      ),
      value: PrintJobState.INIT,
    },
    {
      label: (
        <Space>
          <FontAwesomeIcon icon={faPrint}/>
          {t('printJob.model.states.PRINTING')}
        </Space>
      ),
      value: PrintJobState.PRINTING,
    },
    {
      label: (
        <Space>
          <FontAwesomeIcon icon={faFlagCheckered}/>
          {t('printJob.model.states.FINISHED')}
        </Space>
      ),
      value: PrintJobState.FINISHED,
    },
  ];

  return (
    <Space size={'middle'} direction={'vertical'} style={{ display: 'flex' }}>
      <Row>
        <Radio.Group
          onChange={(e) => setPrintState(e.target.value)}
          value={printState}
          optionType={'button'}
          options={printStateOptions}
          disabled={isLoading}
        />
      </Row>
      <Row>
        <PrintJobFilter setSearchQuery={setSearchQuery} dateAndHierarchyFilterEnabled={dateAndHierarchyFilterEnabled}/>
      </Row>
      <Table<PrintJob>
        loading={isLoading}
        size={'middle'}
        footer={() => (
          <PrintJobActions
            selectedJobs={selectedJobs}
            onRecalculate={handleRecalculate}
            onStart={handleStart}
            onAbort={handleAbort}
            loading={isLoading}
          />
        )}
        rowSelection={{
          hideSelectAll: true,
          preserveSelectedRowKeys: false,
          selectedRowKeys: selectedJobs,
          onChange: (selectedKeys: Key[]) => setSelectedJobs(selectedKeys as number[]),
        }}
        columns={[
          TableColumns.id(),
          {
            title: t('printJob.model.attributes.state'),
            dataIndex: 'state',
            sorter: (a: PrintJob, b: PrintJob) => sortAlphabetically(a.state, b.state),
            render: (r: PrintJobState) => t(`printJob.model.states.${r}`),
          },
          {
            title: t('order.model.attributes.no'),
            dataIndex: 'operation',
            key: 'orderId',
            sorter: (a: PrintJob, b: PrintJob) => sortAlphabetically(a?.operation?.order?.no, b?.operation?.order?.no),
            render: (r: Operation) => r?.order?.no || EnDash(),
          },
          {
            title: t('material.model.one'),
            dataIndex: 'operation',
            key: 'materialId',
            sorter: (a: PrintJob, b: PrintJob) => sortAlphabetically(
              a?.operation?.material?.no,
              b?.operation?.material?.no
            ),
            render: (r: Operation) => r?.material?.label || EnDash(),
          },
          {
            title: t('printer.model.one'),
            dataIndex: 'printer',
            // @ts-ignore
            sorter: (a: PrintJob, b: PrintJob) => sortAlphabetically(a.printer?.label, b.printer?.label),
            // @ts-ignore
            render: (r) => r?.label || EnDash(),
          },
          {
            title: t('printLabel.model.one'),
            dataIndex: 'printLabel',
            // @ts-ignore
            sorter: (a: PrintJob, b: PrintJob) => sortAlphabetically(a.printLabel?.name, b.printLabel?.name),
            // @ts-ignore
            render: (r) => r?.name || EnDash(),
          },
          {
            title: t('printJob.model.attributes.actualAmount.label'),
            dataIndex: 'actualAmount',
            sorter: (a: PrintJob, b: PrintJob) => a.actualAmount - b.actualAmount,
          },
          {
            dataIndex: '',
            key: 'actions',
            align: 'right',
            render: (record: PrintJob) => (
              <Space>
                <ShowButton
                  onClick={() => onView(record)}
                  tooltip={t<string>('printJob.show.tooltip')}
                />
                {policy.canEdit() && (
                  <EditButton
                    onClick={() => onEdit(record)}
                    disabled={record.state !== PrintJobState.INIT}
                  />
                )}
              </Space>
            ),
          },
        ]}
        dataSource={dataSource}
        rowKey={'id'}
        pagination={defaultPaginationPreset}
        wrapperStyle={undefined}
      />
    </Space>
  );
};

export default observer(PrintJobTable);
