import React from 'react';
import { isFinite } from 'lodash';
import BooleanValue from './BooleanValue';
import { EnDash } from '../unicodeWrapper/EnDash';
import ColorValue from './ColorValue';
import { CustomPropertyDataTypes } from '../../../models/customPropertyDataTypes';
import { RecordOfString } from '../../../types/util';
import { CustomProperty } from '../../../models/customProperty';

function toNumber(value: string): number | string {
  const parsedValue = parseFloat(value);
  return isFinite(parsedValue) ? parsedValue : value;
}

function toEnum(value: string, displayableProperty: CustomProperty) {
  const { options } = displayableProperty;
  if (!options || !(options.length > 0)) {
    return value;
  }

  return displayableProperty.getOptionsLabel(value) || EnDash();
}

function toObject(value: string): React.ReactNode {
  try {
    const parsedValue = JSON.stringify(JSON.parse(value), null, 2);
    // return (<div style={{fontFamily: '"Roboto Mono", monospace'}}><pre>{parsedValue}</pre></div>);
    return (<pre style={{ fontFamily: 'inherit' }}>{parsedValue}</pre>);
  } catch (e) {
    return value;
  }
}

type CustomPropertyValueProps = {
  properties: RecordOfString;
  availableProperty: CustomProperty;
};

const CustomPropertyValue: React.FC<CustomPropertyValueProps> = ({ properties, availableProperty }) => {
  const { dataType, key } = availableProperty;

  // extract key if the key contains the whole path
  let propertyKey = key;
  if (key.includes('.')) {
    const path = key.split('.');
    propertyKey = path[path.length - 1];
  }
  if (!properties[propertyKey]) {
    return EnDash();
  }

  const value = properties[propertyKey];

  switch (dataType) {
    case CustomPropertyDataTypes.Boolean:
      return (<BooleanValue value={/false|0/i.test(value) ? false : !!value}/>);
    case CustomPropertyDataTypes.Number:
      return toNumber(value);
    case CustomPropertyDataTypes.Enum:
      return toEnum(value, availableProperty);
    case CustomPropertyDataTypes.Object:
      return toObject(value);
    case CustomPropertyDataTypes.Color:
      return (<ColorValue value={value}/>);
    case CustomPropertyDataTypes.String: // fall through
    default:
      return value;
  }
};

export default CustomPropertyValue;
