import {BaseWidgetConfig} from '../shared/widgets/baseWidgetConfig';
import i18n from '../../i18n/i18n';
import {ValidationRules} from '../../utils/validationRules';
import {getAllI18nTranslations} from '../../utils/translations';
import {getProdConfigPath} from '../../utils/manualPaths';
import {FieldType} from '../../types/widgetConfig';

export class StateHistoryWidgetConfig extends BaseWidgetConfig {
  manualPath = getProdConfigPath('/workflow/state-history');

  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'settings',
        label: i18n.t('widget.WidgetSettingsModal.settingConfigTabTitle'),
      },
    ];
  }

  settings = [
    {
      id: 'title',
      label: i18n.t('widget.settings.title.label'),
      helpText: i18n.t('widget.settings.title.help'),
      type: FieldType.Translatable,
      defaultValue: getAllI18nTranslations('state.historyWidget.title'),
      validations: [ValidationRules.required()],
    },
    {
      id: 'timelineLength',
      label: i18n.t('state.historyWidget.settings.timelineLength.label'),
      type: FieldType.Number,
      defaultValue: 24,
      unit: i18n.t('state.historyWidget.settings.timelineLength.unit'),
      validations: [
        ValidationRules.required(),
        ValidationRules.greaterThanOrEqualTo(1),
        ValidationRules.lessThanOrEqualTo(24),
      ],
    },
    {
      id: 'enableTimeline',
      label: i18n.t('state.historyWidget.settings.enableTimeline'),
      type: FieldType.Boolean,
      defaultValue: true,
      validations: [ValidationRules.required()],
    },
    {
      id: 'enableOperationState',
      label: i18n.t('state.historyWidget.settings.enableOperationState'),
      type: FieldType.Boolean,
      defaultValue: true,
      validations: [ValidationRules.required()],
    },
    {
      id: 'enableWorkplaceState',
      label: i18n.t('state.historyWidget.settings.enableWorkplaceState'),
      type: FieldType.Boolean,
      defaultValue: true,
      validations: [ValidationRules.required()],
    },
    {
      id: 'showInterruptionsMinDuration',
      label: i18n.t('state.historyWidget.settings.showInterruptionsMinDuration.label'),
      type: FieldType.Number,
      unit: i18n.t('state.historyWidget.settings.showInterruptionsMinDuration.unit'),
      defaultValue: 0,
      validations: [ValidationRules.required(), ValidationRules.greaterThanOrEqualTo(0), ValidationRules.number],
    },
  ];

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'title');
  }
}
