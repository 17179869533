import { observable, makeObservable } from 'mobx';
import { Role } from '../models/role';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { RootStore } from './rootStore';

export class RoleStore extends EntityStore<Role> {
  roles: Role[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'roles', Api.roles, Role);

    makeObservable(this, {
      roles: observable,
    });
  }
}
