import React from 'react';
import { Button, Form } from 'antd';
import { faUndo, faSave } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { FormItemLayouts } from './formItemLayouts';
import { useFormCancelCallback } from './useFormCancelCallback';

export type DiscardFormActionsProps = {
  onDiscard: () => void;
  saving?: boolean;
  submitText: string;
  discardText: string;
  submitDisabled: boolean;
};

const DiscardFormActions: React.FC<DiscardFormActionsProps> = ({
  onDiscard,
  saving,
  submitText,
  discardText,
  submitDisabled = false,
}) => {
  const handleDiscard = useFormCancelCallback(onDiscard);
  const { t } = useTranslation();

  return (
    <Form.Item {...FormItemLayouts.fullWidth} style={{ textAlign: 'right' }}>
      <Button style={{ marginRight: '10px' }} onClick={handleDiscard}>
        <FontAwesomeIcon style={{ marginRight: '1em' }} icon={faUndo}/>
        {discardText || t('confirm.discard.ok')}
      </Button>
      <Button type="primary" htmlType="submit" loading={saving} disabled={submitDisabled}>
        <FontAwesomeIcon style={{ marginRight: '1em' }} icon={faSave}/>
        {submitText || t('confirm.save')}
      </Button>
    </Form.Item>
  );
};

export default DiscardFormActions;
