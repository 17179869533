import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '../../shared/inputs/Select';
import { useStore } from '../../../hooks/useStore';
import Form from '../../shared/form/Form';
import { useMount } from '../../../hooks/useMount';
import { mqtt, TOPIC_BASE } from '../../../middleware/mqtt';
import { SensorData } from '../../../models/sensorData';
import Button from '../../shared/buttons/Button';

export type DosageSensorSelectProps = {
  onValueChange?: (value: number) => void;
  onSelect?: (value: string) => void;
  suspended?: boolean;
  allowedStorageUnitId?: number | null;
};

const DosageSensorSelect: React.FC<DosageSensorSelectProps> = ({
  onValueChange,
  onSelect,
  suspended = false,
  allowedStorageUnitId,
}) => {
  const store = useStore();
  const { t } = useTranslation();
  const [selectedSensor, setSelectedSensor] = useState<null | string>(null);
  const suspendedRef = useRef(suspended);
  const getIsInPreparation = useCallback(() =>
    store.dosageStore.isInPreparation, [store.dosageStore.isInPreparation]);

  const handleValue = (_?: string, value?: SensorData) => {
    if (suspendedRef.current || !onValueChange || value?.value === undefined) {
      return;
    }
    const sensor = store.sensorStore.getById(selectedSensor);
    let roundedValue = value.value;
    if (sensor) {
      roundedValue = sensor.roundedValue(roundedValue);
    }
    onValueChange(roundedValue);
  };

  useMount(() => {
    suspendedRef.current = suspended;
  }, [suspended]);

  useMount(() => {
    if (getIsInPreparation()) {
      return () => {};
    }
    if (!selectedSensor) {
      return () => {};
    }
    if (!onSelect) {
      return () => {};
    }
    onSelect(selectedSensor);
    const topic = `${TOPIC_BASE}/sensor-data/${selectedSensor}/insert`;
    const id = mqtt.subscribe(topic, handleValue);
    return () => {
      mqtt.unsubscribe(topic, id);
    };
  }, [selectedSensor]);

  const handleTare = async () => {
    const workplaceId = store.workplaceStore.selectedWorkplace?.id;
    const sensorName = selectedSensor;
    const operationId = store.operationStore.active?.id;

    if (!workplaceId || !sensorName) {
      return;
    }

    try {
      await store.dosageStore.tare(
        workplaceId,
        sensorName,
        operationId
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error tare', e);
    }
  };

  const sensors = store.sensorStore.getLocalAsync(store.workplaceStore.selectedWorkplace?.hierarchyId)
    .filter((sensor) => allowedStorageUnitId && sensor.unitOfMeasureId === allowedStorageUnitId);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
      }}
    >
      <Form.Item label={t('dosageWidget.scales.label')}>
        <Select<string>
          options={sensors.map((sensor) => ({
            label: sensor.label || sensor.name,
            value: sensor.name,
          }))}
          style={{ minWidth: '240px' }}
          value={selectedSensor}
          onChange={setSelectedSensor}
          disabled={getIsInPreparation()}
          allowClear
        />
      </Form.Item>
      <Form.Item>
        <Button
          data-cy={'DosageQuantitySlider-TaraButton'}
          type="primary"
          onClick={handleTare}
          style={{ minWidth: '100px' }}
          disabled={getIsInPreparation() || !selectedSensor}
        >
          {t('dosageWidget.dosage.tare')}
        </Button>
      </Form.Item>
    </div>
  );
};

export default DosageSensorSelect;
