import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import {faTachometerAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useStore} from '../../hooks/useStore';
import FormModal from '../shared/modal/FormModal';
import {FilterFormItem} from '../shared/form/FilterFormItem';
import DateRangePicker from '../shared/inputs/DateRangePicker';
import AreaChart from '../shared/charts/AreaChart';
import {useMount} from '../../hooks/useMount';
import Empty from '../shared/empty/Empty';
import {fullDateTimeFormatter} from '../shared/charts/highchartHelpers/formatter';
import {getSymbolByNameAsHtml} from '../shared/charts/highchartHelpers/label';

const SpeedChangeDetailModal = ({
  sensor,
  onCancel,
  decimals,
}) => {
  const store = useStore();
  const {t} = useTranslation();
  const MAX_DURATION_DAYS = 14;
  const [dates, setDates] = useState({
    fromDate: dayjs().subtract(1, 'day'),
    toDate: dayjs(),
  });
  const [valid, setValid] = useState({
    state: 'success',
    help: null,
  });
  const [options, setOptions] = useState({
    title: false,
    yAxis: {
      title: false,
    },
    tooltip: {
      useHtml: true,
      formatter() {
        const {x, y, series, point} = this;
        const timespan = {from: series.xAxis.min, to: series.xAxis.max};
        let data;

        try {
          data = JSON.parse(point.remark);
        } catch (e) {
          // NOOP
        }

        const formattedY = decimals !== null ? y.toFixed(decimals) : y;

        return `${fullDateTimeFormatter(x, timespan)}<br/>`
          + `${getSymbolByNameAsHtml(series.symbol, point.color)} `
          + `${t('speedChangeWidget.actualSpeed')}: ${formattedY}<br/>`
          + `${t('speedChangeWidget.detailModal.reason')}: ${data?.reason || '-'}<br/>`
          + `${t('speedChangeWidget.detailModal.username')}: ${data?.username || t('speedChangeWidget.system')}`;
      },
    },
  });
  const [loading, setLoading] = useState(true);
  const [showIsEmpty, setShowIsEmpty] = useState(false);

  useMount(() => {
    const durationDays = dates.toDate.diff(dates.fromDate, 'days');
    if (durationDays > MAX_DURATION_DAYS) {
      setValid({
        state: 'error',
        help: t('speedChangeWidget.detailModal.dateRangeTooLarge'),
      });
    } else if (sensor && store.workplaceStore.selectedWorkplace) {
      setValid({
        state: 'success',
        help: null,
      });
      setLoading(true);
      store.sensorDataStore.loadByWorkplaceAndTimeRange(
        sensor,
        store.workplaceStore.selectedWorkplace.id,
        dates.fromDate,
        dates.toDate
      ).then((dataPoints) => {
        const data = dataPoints.map((d) => ({x: dayjs(d.timestamp).valueOf(), y: d.value, remark: d.remark}));
        if (data.length > 0) {
          data.push({
            x: dayjs(dates.toDate).add(1, 'hour'),
            y: data[data.length - 1].y,
            remark: '',
          });
        }
        const sensorModel = store.sensorStore.getById(sensor);
        const seriesData = {
          name: sensorModel?.label || sensor,
          data,
          turboThreshold: 0,
        };
        setOptions({
          ...options,
          xAxis: {
            min: dates.fromDate ? dates.fromDate.valueOf() : undefined,
            max: dates.toDate ? dates.toDate.valueOf() : undefined,
          },
          series: [seriesData],
        });
        setLoading(false);
        setShowIsEmpty(dataPoints.length === 0);
      });
    }
  }, [sensor, dates]);

  return (
    <FormModal
      title={(
        <>
          <FontAwesomeIcon icon={faTachometerAlt}/>
          {' '}
          {t('speedChangeWidget.detailModal.title')}
        </>
      )}
      open
      onCancel={onCancel}
    >
      <FilterFormItem
        label={t('personnel.datepicker.label')}
        validateStatus={valid.state}
        help={valid.help}
      >
        <DateRangePicker
          value={[dates.fromDate, dates.toDate]}
          onOk={([fromDate, toDate]) => setDates({fromDate, toDate})}
          onChange={(dateChange) => (dateChange ? setDates({
            fromDate: dateChange[0], toDate: dateChange[1],
          }) : null)}
          allowClear={false}
          showDefaultTimeRanges
        />
      </FilterFormItem>
      {sensor
        ? <AreaChart options={options} isTimeseries showIsEmpty={showIsEmpty} isLoading={loading}/>
        : <Empty description={t('speedChangeWidget.noSensorConfigured')}/>}
    </FormModal>
  );
};

export default observer(SpeedChangeDetailModal);
