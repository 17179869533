import { observable, makeObservable, computed } from 'mobx';
import { faCompressAlt } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

export class TransitionQueue extends BaseModel {
  id: number = 0;
  workplaceId: number = 0;
  operationId: number = 0;
  transitionId: number = 0;
  plannedExecution: string | null = null;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      workplaceId: observable,
      workplace: computed,
      operationId: observable,
      operation: computed,
      transitionId: observable,
      transition: computed,
      plannedExecution: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faCompressAlt;

  searchableProperties = [];

  translatedProperties = [];

  displayableProperties = [];

  static prepareApiPayload(model: TransitionQueue) {
    return {
      id: model.id || undefined,
      workplaceId: model.workplaceId,
      operationId: model.operationId,
      transitionId: model.transitionId,
      plannedExecution: model.plannedExecution,
    };
  }

  get workplace() {
    return this.rootStore.workplaceStore.getById(this.workplaceId);
  }

  get operation() {
    return this.rootStore.operationStore.getById(this.operationId);
  }

  get transition() {
    return this.rootStore.transitionStore.getById(this.transitionId);
  }
}
