import { computed, makeObservable, observable } from 'mobx';
import { faPoll } from '@fortawesome/free-solid-svg-icons';
import { BaseModel, DisplayablePropertiesOptions } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import { sortAlphabetically, sortChronologically } from '../components/shared/tables/sorters';
import { datetimeFormat } from '../config/dayjs';
import { InspectionLot } from './inspectionLot';
import { EnDash } from '../components/shared/unicodeWrapper/EnDash';
import { Order } from './order';
import { Operation } from './operation';
import { ReportTypeEnum } from './reportType';
import { RootStore } from '../stores/rootStore';

/**
 * event context translations:
 * t('reportLog.states.DRAFT')
 * t('reportLog.states.PENDING')
 * t('reportLog.states.SUCCESS')
 * t('reportLog.states.FAILURE')
 * t('reportLog.states.ON_HOLD')
 * t('reportLog.states.ARCHIVED')
 */
export enum ReportState {
  Draft = 'DRAFT',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  OnHold = 'ON_HOLD',
  Archived = 'ARCHIVED'
}

export interface AbstractReport<VALUES, METADATA> {
  values: VALUES,
  metadata: METADATA,
}

export class ReportLog extends BaseModel {
  id: number = 0;
  /**
   * @deprecated use typeName
   */
  typeId = null;
  typeName: ReportTypeEnum | null = null;
  state: ReportState = ReportState.Draft;
  orderId: number | null = null;
  operationId: number | null = null;
  workplaceId: number | null = null;
  inspectionLotId: number | null = null;
  report: AbstractReport<any, any> = { metadata: {}, values: {} };
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;
  deletedAt: string | null = null;
  deletedBy: number | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      typeId: observable,
      type: computed,
      state: observable,
      orderId: observable,
      order: computed,
      operationId: observable,
      operation: computed,
      workplaceId: observable,
      workplace: computed,
      inspectionLotId: observable,
      inspectionLot: computed,
      report: observable,
      typeTranslated: computed,
      stateTranslated: computed,
      orderObject: computed,
      ...ReportLog.modelChangeDecorations,
    });
  }

  static faIcon = faPoll;

  searchableProperties = [
    'workplace.no',
    'workplace.name',
    'order.no',
    'operation.no',
    'operation.name',
    'inspectionLot.lotNo',
  ];

  nestedModels = ['report'];

  displayableProperties = [
    displayableProperty({
      key: 'createdAt',
      title: i18n.t('reportLog.model.attributes.createdAt'),
      sorter: (a, b) => sortChronologically(a.createdAt, b.createdAt),
      defaultSortOrder: 'descend',
      params: [
        displayablePropertyParam({
          path: 'createdAt', transform: transformation.datetime({ format: datetimeFormat }),
        })],
    }),
    displayableProperty({
      key: 'type',
      title: i18n.t('reportLog.model.attributes.type'),
      sorter: (a, b) => sortAlphabetically(a.typeTranslated, b.typeTranslated),
      params: [displayablePropertyParam({ path: 'typeTranslated', transform: transformation.none })],
    }),
    displayableProperty({
      key: 'state',
      title: i18n.t('reportLog.model.attributes.state'),
      sorter: (a, b) => sortAlphabetically(a.stateTranslated, b.stateTranslated),
      params: [displayablePropertyParam({ path: 'stateTranslated', transform: transformation.none })],
    }),
    displayableProperty({
      key: 'workplace',
      title: i18n.t('reportLog.model.attributes.workplace'),
      sorter: (a, b) => sortAlphabetically(a.workplace?.no, b.workplace?.no),
      params: [displayablePropertyParam({ path: 'workplace', transform: transformation.objectProperty('label') })],
    }),
  ];

  static allDisplayableProperties(
    rootStore: RootStore,
    pathPrefix = '',
    titlePrefix = '',
    options: DisplayablePropertiesOptions = {}
  ) {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);

    const inspectionLotPrefix = `${titlePrefix}${i18n.t('inspectionLot.model.one')} > `;
    allDisplayableProperties.push(
      ...InspectionLot.allDisplayableProperties(rootStore, `${pathPrefix}inspectionLot.`, inspectionLotPrefix, options)
    );

    const orderPrefix = `${titlePrefix}${i18n.t('order.model.one')} > `;
    allDisplayableProperties.push(
      ...Order.ownDisplayableProperties(rootStore, `${pathPrefix}orderObject.`, orderPrefix, options)
    );

    const operationPrefix = `${titlePrefix}${i18n.t('operation.model.one')} > `;
    allDisplayableProperties.push(
      ...Operation.ownDisplayableProperties(rootStore, `${pathPrefix}operation.`, operationPrefix, options)
    );

    return allDisplayableProperties;
  }

  static prepareApiPayload(model: ReportLog) {
    return {
      id: model.id,
      typeName: model.typeName,
      state: model.state,
      operationId: model.operationId,
      orderId: model.orderId,
      workplaceId: model.workplaceId,
      report: model.report,
    };
  }

  public get type() {
    if (this.typeName) {
      return this.rootStore.reportTypeStore.getByType(this.typeName);
    }
    // TODO: replace this.typeId with this.typeName
    return this.rootStore.reportTypeStore.getById(this.typeId);
  }

  public get order() {
    return this.rootStore.orderStore.getById(this.orderId);
  }

  public get operation() {
    return this.rootStore.operationStore.getById(this.operationId);
  }

  public get workplace() {
    return this.rootStore.workplaceStore.getById(this.workplaceId);
  }

  public get inspectionLot() {
    return this.rootStore.inspectionLotStore.getById(this.inspectionLotId);
  }

  get typeTranslated() {
    return this.type?.type ? i18n.t(`reportType.types.${this.type?.type}`) : EnDash();
  }

  get stateTranslated() {
    return i18n.t(`reportLog.states.${this.state}`);
  }

  get orderObject() {
    return this.operation ? this.operation.order : this.order;
  }
}

/*
 * Type definition of report types
 * FIXME: These types make heavy use of the 'any' type. This is because there are no types available that
 *  are not model instances, and the objects in reports are not models. This may be fixed once such types are available.
 */

export interface FinalPerformanceReportValues {
  phase?: any;
  operationPhase?: any;
  personnel?: any;
  machine?: any;
  ignoredPersonnel?: any;
  ignoredMachine?: any;
  actualQuantity?: any;
}
export interface FullOrderReportMetadata {
  order: any;
  operations: any[];
  materials: any[];
  unitsOfMeasurement: any[];
  batches: any[];
  unitOfMeasureConversions: any[];
  workplaces: any[];
}
export interface FullOrderReportValueItem {
  id: number;
  no: string;
  components: any[];
  yields: any[];
  reports: FinalPerformanceReportValues[];
}
export interface FullOrderReport extends AbstractReport<FullOrderReportValueItem[], FullOrderReportMetadata> {}
