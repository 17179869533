import {createEntity, executeDelete, executeGet, updateEntity} from '../requests';

const resourcesName = 'v1/sensors';

export const SensorsApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({name, ...values}) => updateEntity(values, `${resourcesName}/${name}`),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
};
