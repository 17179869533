import {useState} from 'react';
import {Divider} from 'antd';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import Modal from '../shared/modal/Modal';
import {useStore} from '../../hooks/useStore';
import ScanTerminal from './shared/ScanTerminal';
import {useMount} from '../../hooks/useMount';
import TouchTag from '../shared/tags/TouchTag';
import IsBrokenModal from './shared/IsBrokenModal';

const LogoffModal = ({
  onSuccess,
  onCancel,
  onRepair,
  ...props
}) => {
  const store = useStore();
  const {t} = useTranslation();
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isBrokenModalVisible, setIsBrokenModalVisible] = useState(false);

  useMount(() => {
    if (selectedTerminal) {
      if (!selectedTerminal.workplaceGroupId) {
        store.flashMessageStore.addFlashMessage({
          type: 'warning',
          title: t('terminalManagement.modal.noAssignedWorkplaceGroup'),
        });
        setSelectedTerminal(null);
      } else if (selectedTerminal.isBroken) {
        setIsBrokenModalVisible(true);
      } else {
        store.workplaceGroupStore.load(selectedTerminal.workplaceGroupId).then((group) => {
          setSelectedGroup(group);
        });
      }
    } else {
      setSelectedGroup(null);
    }
  }, [selectedTerminal]);

  const handleSubmit = () => {
    if (selectedGroup) {
      store.workplaceGroupStore.update({
        id: selectedGroup.id,
        name: selectedGroup.name,
        workplaceIds: [],
        defaultWorkplaceId: null,
      }).then(() => onSuccess());
    }
  };

  const handleCancel = () => {
    setIsBrokenModalVisible(false);
    setSelectedTerminal(null);
  };

  return (
    <Modal
      fullscreen={store.clientStore.isMobile}
      open
      okText={t('terminalManagement.buttons.logoff')}
      cancelText={t('confirm.cancel')}
      okButtonProps={{disabled: !selectedTerminal}}
      maskClosable={false}
      onCancel={onCancel}
      onOk={handleSubmit}
      {...props}
    >
      <ScanTerminal terminal={selectedTerminal} onChangeTerminal={setSelectedTerminal}/>
      <Divider/>
      <div style={{marginBottom: '1em'}}>{`${t('terminalManagement.modal.logoff.selectedWorkplaces')}: `}</div>
      <div>
        {!selectedGroup?.workplaces.length
          ? <i style={{opacity: 0.6}}>{t('terminalManagement.modal.form.noWorkplace')}</i>
          : null}
      </div>
      {selectedGroup?.workplaces.map((workplace) => (
        <TouchTag
          style={{width: '100%'}}
          key={workplace.no}
          size={'large'}
        >
          {`${workplace.no} (${workplace.name})`}
        </TouchTag>
      ))}
      {
        isBrokenModalVisible
          ? (
            <IsBrokenModal
              onCancel={handleCancel}
              onSuccess={() => onRepair(selectedTerminal)}
              onRepair={onRepair}
              terminal={selectedTerminal}
            />
          )
          : null
      }
    </Modal>
  );
};

export default observer(LogoffModal);
