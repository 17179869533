import { action, observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore, LoadStrategies } from './entityStore';
import { Transition } from '../models/transition';
import { validStartTransitionsEndpoint } from '../middleware/endpoints/operations';
import { ValidStartTransition } from '../models/validStartTransition';
import { RootStore } from './rootStore';

export class ValidStartTransitionsStore extends EntityStore<ValidStartTransition> {
  transitions: ValidStartTransition[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'transitions', Api.operations, Transition);

    makeObservable(this, {
      transitions: observable,
      loadValidStartTransitions: action,
    });
  }

  loadValidStartTransitions(operationId: number, params = {}) {
    return this.loadAll({
      strategy: LoadStrategies.replace,
      apiEndpoint: validStartTransitionsEndpoint,
      params: {
        id: operationId,
        params,
      },
      mapper: (validStartTransition: any) => ValidStartTransition.fromPlainObject(validStartTransition, this.rootStore),
    });
  }
}
