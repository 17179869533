import {observer} from 'mobx-react-lite';
import {arrayMove} from '../../../utils/helpers';
import SortableTable from '../../shared/tables/SortableTable';

const SortableOrderTable = ({
  columns,
  rowClassNames,
  // eslint-disable-next-line no-unused-vars
  useSortableOrders = [[], (value) => undefined],
  ...props
}) => {
  const [sortableOrders, setSortableOrders] = useSortableOrders;

  const onReordering = ({oldIndex, newIndex}) => {
    if (oldIndex !== newIndex) {
      const data = arrayMove(sortableOrders, oldIndex, newIndex);

      if (data[newIndex - 1] && data[newIndex + 1]) {
        data[newIndex].tmpSortOrder = (data[newIndex - 1].tmpSortOrder + data[newIndex + 1].tmpSortOrder) / 2;
      } else if (data[newIndex + 1]) {
        data[newIndex].tmpSortOrder = data[newIndex + 1].tmpSortOrder - 0.5;
      } else {
        data[newIndex].tmpSortOrder = data[newIndex - 1].tmpSortOrder + 0.5;
      }

      setSortableOrders(data);
    }
  };

  return (
    <SortableTable
      className={'order-table'}
      rowKey={'id'}
      columns={columns}
      pagination={false}
      rowClassNames={rowClassNames}
      dataSource={sortableOrders}
      showDragHandle={(record) => record !== undefined && record.isPlannedOrReady}
      dragHandlePosition={'last'}
      onOrderUpdated={onReordering}
      {...props}
    />
  );
};

export default observer(SortableOrderTable);
