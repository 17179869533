import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { UnitOfMeasurementConversion } from '../models/unitOfMeasurementConversion';
import { RootStore } from './rootStore';

export class UnitOfMeasurementConversionStore extends EntityStore<UnitOfMeasurementConversion> {
  unitOfMeasurementConversions: UnitOfMeasurementConversion[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'unitOfMeasurementConversions', Api.unitOfMeasurementConversions, UnitOfMeasurementConversion);

    makeObservable(this, {
      unitOfMeasurementConversions: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.unitOfMeasurementStore,
        modelId: 'sourceUnitOfMeasureId',
      },
      {
        store: this.rootStore.unitOfMeasurementStore,
        modelId: 'destinationUnitOfMeasureId',
      },
    ];
  }

  getByOrderId(orderId: number) {
    return this.unitOfMeasurementConversions.filter((c) => c.orderId === orderId);
  }

  loadByOrderId(orderId: number) {
    return this.loadAllWithDependencies({
      params: { orderId },
    });
  }

  convert(value: number, orderId: number, sourceUnitOfMeasureId: number, destinationUnitOfMeasureId: number) {
    const conversion = this.unitOfMeasurementConversions.find((c) =>
      c.orderId === orderId
      && c.sourceUnitOfMeasureId === sourceUnitOfMeasureId
      && c.destinationUnitOfMeasureId === destinationUnitOfMeasureId);
    if ((value || value === 0) && conversion) {
      return value * (conversion.numerator / conversion.denominator);
    }
    return null;
  }
}
