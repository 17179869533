import React, {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import {useMount} from '../../../../hooks/useMount';
import {useStore} from '../../../../hooks/useStore';
import Spinner from '../../../shared/spinners/Spinner';
import {OrderProgressWidgetConfig} from './config/orderProgressWidgetConfig';
import {WidgetLayout} from '../../../../models/widgetLayout';
import CockpitWidget from './CockpitWidget';
import useWorkplaceRelatedWidgetTitle from '../../../../hooks/useWorkplaceRelatedWidgetTitle';
import {OrderProgressChart} from '../../../shared/charts/OrderProgressChart';

const OrderProgressWidget = ({
  workplaceId,
  title,
  widgetId,
  ...props
}) => {
  const store = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWorkplaceId, setSelectedWorkplaceId] = useState();
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [stateLog, setStateLog] = useState(null);
  const widgetConfig = useMemo(() => new OrderProgressWidget.Config({
    rootStore: store,
    identifier: OrderProgressWidget.Config.widgetType.identifier,
    widgetId,
  }), []);
  const widgetTitle = useWorkplaceRelatedWidgetTitle(title, workplaceId);

  useMount(() => {
    setSelectedWorkplaceId(
      store.workplaceStore.getByHierarchyId(widgetConfig.getSettingValue('workplace', 'hierarchyId'))?.id
      || store.cockpitStore.currentWorkplaceId || undefined
    );
  }, [
    store.cockpitStore.currentWorkplaceId,
    widgetConfig.getSettingValue('workplace', 'hierarchyId'),
  ]);

  useMount(() => {
    if (selectedWorkplaceId) {
      setIsLoading(true);
      store.operationStore.loadActiveOperationByWorkplace(selectedWorkplaceId)
        .then(() => {
          const [operation] = store.operationStore.getUnfinishedByWorkplace(selectedWorkplaceId);
          if (operation) {
            store.operationStateLogStore.loadByOperation(operation.id);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [selectedWorkplaceId]);

  const handleUpdate = () => {
  };

  useMount(() => {
    const operation = store.operationStore.getActiveByWorkplaceId(selectedWorkplaceId);
    setSelectedOperation(operation || null);
    if (operation) {
      setStateLog(store.operationStateLogStore.getFirstByOperationId(operation.id) || null);
    }
  }, [store.operationStore.operations.length, store.operationStateLogStore.logs.length, selectedWorkplaceId]);

  return (
    <div style={{height: '100%'}}>
      {
        isLoading
          ? <Spinner fullWidth fullHeight/>
          : (
            <CockpitWidget
              widgetConfig={widgetConfig}
              title={widgetTitle}
              widgetId={widgetId}
              onSettingsChange={handleUpdate}
              {...props}
            >
              <OrderProgressChart
                actualQuantity={selectedOperation?.plannedQuantity === null
                  ? selectedOperation.order?.actualQuantity : selectedOperation?.actualQuantity}
                plannedQuantity={selectedOperation?.plannedQuantity === null
                  ? selectedOperation.order?.plannedQuantity : selectedOperation?.plannedQuantity}
                speed={
                  widgetConfig.getSettingValue('settings', 'speedForCalculation') === 'plannedSpeed'
                    ? selectedOperation?.plannedSpeed
                    : selectedOperation?.actualSpeed
                }
                actualStart={dayjs(stateLog?.start)}
                plannedDurationMs={(selectedOperation?.plannedDurationSeconds || 0) * 1000}
                unit={selectedOperation?.plannedQuantity === null
                  ? selectedOperation.order?.unitOfMeasure : selectedOperation?.unitOfMeasure}
                showDurationLabel
              />
            </CockpitWidget>
          )
      }
    </div>
  );
};
OrderProgressWidget.Config = OrderProgressWidgetConfig;
OrderProgressWidget.defaultLayout = new WidgetLayout({
  identifier: CockpitWidget.identifier,
  width: 4,
  height: 8,
  minHeight: 8,
  minWidth: 2,
});
export default observer(OrderProgressWidget);
