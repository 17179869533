import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Button, { ButtonProps } from './Button';

export type SearchButtonProps = ButtonProps;

const SearchButton: React.FC<SearchButtonProps> = (props) => {
  const { t } = useTranslation();

  const buttonProps: ButtonProps = {
    type: 'primary',
    htmlType: 'submit',
    icon: <SearchOutlined/>,
    children: t('button.search'),
    ...props,
  };

  return <Button {...buttonProps}/>;
};

export default SearchButton;
