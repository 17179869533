import { computed, makeObservable, observable } from 'mobx';
import { faSealQuestion } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { getTranslation } from '../utils/translations';
import { RootStore } from '../stores/rootStore';
import { Translation } from './translations';

interface PhaseTimeoutReasonTranslation extends Translation {
  label: string | null;
}

export class PhaseTimeoutReason extends BaseModel {
  id: number = 0;
  reason: string = '';
  translations: PhaseTimeoutReasonTranslation[] = [];
  hierarchyIds: number[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      reason: observable,
      translations: observable,
      hierarchyIds: observable,
      hierarchies: computed,
      label: computed,
    });
  }

  static faIcon = faSealQuestion;

  searchableProperties = ['reason', 'label'];

  translatedProperties = ['label'];

  get hierarchies() {
    return this.rootStore.hierarchyStore.getByIds(this.hierarchyIds);
  }

  static prepareApiPayload(model: PhaseTimeoutReason) {
    return {
      id: model.id || undefined,
      reason: model.reason,
      translations: PhaseTimeoutReason.convertToSavableTranslations(model),
      hierarchyIds: model.hierarchyIds || [],
    };
  }

  get label() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.label;
  }
}
