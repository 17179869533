import {useStore} from './useStore';

const useProfile = () => {
  const store = useStore();
  return {
    profile: store.profileStore.currentProfile,
  };
};

export default useProfile;
