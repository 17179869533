import React from 'react';
import {useTranslation} from 'react-i18next';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';
import {DatePicker} from 'antd';
import {ValidationRules} from '../../../utils/validationRules';
import Form from '../../shared/form/Form';
import Input from '../../shared/inputs/Input';
import Select from '../../shared/inputs/Select';
import Switch from '../../shared/inputs/Switch';
import MaterialSelector from '../../shared/inputs/autocomplete/MaterialSelector';
import OrderSelector from '../../shared/inputs/autocomplete/OrderSelector';
import {EnDash} from '../../shared/unicodeWrapper/EnDash';
import StorageUnitSelector from '../../shared/inputs/autocomplete/StorageUnitSelector';
import HelpTooltipFormLabel from '../../shared/tooltips/HelpTooltipFormLabel';
import CustomPropertyFormItem from '../../shared/form/CustomPropertyFormItem';
import {datetimeFormat} from '../../../config/dayjs';

const StorageUnitFormPartial = ({
  form,
  selectedUnitOfMeasureId,
  selectedStorageAreaId,
  selectedParentStorageUnitId,
  unitOfMeasurements,
  storageAreas,
  widgetConfig,
  customProperties = [],
}) => {
  const {t} = useTranslation();

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i += 1) {
      result.push(i);
    }
    return result;
  };

  const disabledStartDate = (startValue) => {
    const timerEnd = form.getFieldValue('timerEnd');
    if (!startValue || !timerEnd) {
      return false;
    }
    return startValue.valueOf() > timerEnd.valueOf();
  };

  const disabledEndDate = (endValue) => {
    const timerStart = form.getFieldValue('timerStart');
    if (!endValue || !timerStart) {
      return false;
    }
    return endValue.valueOf() <= timerStart.valueOf();
  };

  const disabledStartTime = (startValue) => {
    const timerEnd = form.getFieldValue('timerEnd');
    if (!startValue || !timerEnd || startValue.format('YYYY-MM-DD') !== timerEnd.format('YYYY-MM-DD')) {
      return {};
    }
    return {
      disabledHours: () => range(timerEnd.hours() + 1, 24),
      disabledMinutes: (selectedHour) => {
        if (selectedHour === timerEnd.hours() && startValue.format('YYYY-MM-DD') === timerEnd.format('YYYY-MM-DD')) {
          return range(timerEnd.minutes(), 60);
        }
        return [];
      },
    };
  };

  const disabledEndTime = (endValue) => {
    const timerStart = form.getFieldValue('timerStart');
    if (!endValue || !timerStart || endValue.format('YYYY-MM-DD') !== timerStart.format('YYYY-MM-DD')) {
      return {};
    }
    return {
      disabledHours: () => range(0, timerStart.hours()),
      disabledMinutes: (selectedHour) => {
        if (selectedHour === timerStart.hours()) {
          return range(0, timerStart.minutes() + 1);
        }
        return [];
      },
    };
  };

  return (
    <>
      <Form.Item
        name={'no'}
        label={t('storageUnit.model.attributes.no')}
        rules={[ValidationRules.required(), ValidationRules.stringMaxLength(50)]}
      >
        <Input/>
      </Form.Item>
      <Form.Item
        name={'storageAreaId'}
        label={t('storageUnit.model.attributes.storageArea')}
        rules={[ValidationRules.requiredIf(!selectedParentStorageUnitId)]}
      >
        <Select
          allowClear
          options={storageAreas.map((area) => ({
            label: area.no,
            value: area.id,
          }))}
          disabled={!!selectedParentStorageUnitId}
        />
      </Form.Item>
      <Form.Item
        name={'parentId'}
        label={t('storageUnit.model.attributes.parentStorageUnit')}
        rules={[ValidationRules.requiredIf(!selectedStorageAreaId)]}
      >
        <StorageUnitSelector
          disabled={!!selectedStorageAreaId}
        />
      </Form.Item>
      <Form.Item
        name={'materialId'}
        label={t('storageUnit.model.attributes.material')}
      >
        <MaterialSelector
          form={form}
          name={'materialId'}
          disabled={form.getFieldValue('contents')?.length > 0}
        />
      </Form.Item>
      <Form.Item
        name={'unitOfMeasureId'}
        label={t('storageUnit.model.attributes.unitOfMeasure')}
      >
        <Select
          options={unitOfMeasurements.map((uom) => ({
            label: uom.label,
            value: uom.id,
          }))}
          disabled={form.getFieldValue('contents')?.length > 0}
        />
      </Form.Item>
      <Form.Item
        name={'capacity'}
        label={t('storageUnit.model.attributes.capacity')}
      >
        <Input
          type={'number'}
          addonAfter={selectedUnitOfMeasureId ? unitOfMeasurements.find(
            (uom) => uom.id === selectedUnitOfMeasureId
          )?.label : EnDash()}
        />
      </Form.Item>
      <Form.Item
        name={'reservedOrderId'}
        label={t('storageUnit.model.attributes.reservedOrderId')}
        rules={[ValidationRules.requiredIf(widgetConfig?.getReservedOrderMandatory())]}
      >
        <OrderSelector
          form={form}
          name={'reservedOrderId'}
        />
      </Form.Item>

      <Form.Item
        name={'timerStart'}
        label={t('storageUnit.model.attributes.timerStart')}
        rules={[ValidationRules.isBeforeField('timerEnd', t('storageUnit.model.attributes.timerEnd.label'))]}
        dependencies={['timerEnd', 'timerDuration']}
      >
        <DatePicker
          showToday
          showTime
          disabledDate={disabledStartDate}
          disabledTime={disabledStartTime}
          format={datetimeFormat}
          allowClear
        />
      </Form.Item>

      <Form.Item
        name={'timerEnd'}
        label={t('storageUnit.model.attributes.timerEnd')}
        rules={[ValidationRules.isAfterField('timerStart', t('storageUnit.model.attributes.timerStart.label'))]}
        dependencies={['timerStart', 'timerDuration']}
      >
        <DatePicker
          showToday
          showTime
          disabledDate={disabledEndDate}
          disabledTime={disabledEndTime}
          format={datetimeFormat}
          allowClear
        />
      </Form.Item>

      <Form.Item
        name={'timerDuration'}
        label={t('storageUnit.model.attributes.duration.label')}
        rules={[ValidationRules.greaterThanOrEqualTo(1)]}
        dependencies={['timerStart', 'timerEnd']}
      >
        <Input
          type={'number'}
          addonAfter={t('storageUnit.model.attributes.duration.unit')}
          allowClear
        />
      </Form.Item>
      <Form.Item
        name={'isSingleUse'}
        label={t('storageUnit.model.attributes.isSingleUse')}
        initialValue={false}
        valuePropName={'checked'}
      >
        <Switch
          checkedChildren={<CheckOutlined/>}
          unCheckedChildren={<CloseOutlined/>}
        />
      </Form.Item>
      <Form.Item
        name={'isStationary'}
        label={(
          <HelpTooltipFormLabel
            label={t('storageUnit.model.attributes.isStationary.label')}
            help={t('storageUnit.model.attributes.isStationary.help')}
          />
        )}
        valuePropName={'checked'}
      >
        <Switch
          checkedChildren={<CheckOutlined/>}
          unCheckedChildren={<CloseOutlined/>}
        />
      </Form.Item>
      <Form.Item
        name={'isMixingBatches'}
        label={t('storageUnit.model.attributes.isMixingBatches')}
        valuePropName={'checked'}
      >
        <Switch
          checkedChildren={<CheckOutlined/>}
          unCheckedChildren={<CloseOutlined/>}
        />
      </Form.Item>
      {customProperties.map((property) => (
        <CustomPropertyFormItem
          key={property.key}
          property={property}
          propertyName={property.key}
        />
      ))}
    </>
  );
};

export default StorageUnitFormPartial;
