import { observable, makeObservable, computed } from 'mobx';
import dayjs from 'dayjs';
import { AxiosResponse } from 'axios';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { StorageUnit } from '../models/storageUnit';
import {
  descendantsEndpoint,
  getOrCreateStorageUnitEndpoint,
  inputStorageUnitEndpoint,
  outputStorageUnitEndpoint,
  siblingsEndpoint,
  StorageUnitsApi,
  transferStorageUnitEndpoint,
} from '../middleware/endpoints/storageUnits';
import i18n from '../i18n/i18n';
import { RootStore } from './rootStore';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';
import { SearchParams } from '../types/autocomplete';

export interface StorageUnitTransferOperation {
  sourceStorageUnitId: number;
  targetStorageUnitId: number;
  amount: number;
  materialId: number;
  unitOfMeasureId: number;
  batchId?: number;
  isCorrection: boolean;
}

export interface StorageUnitInputOperation extends Omit<StorageUnitTransferOperation, 'sourceStorageUnitId'> {
}

export interface StorageUnitOutputOperation extends Omit<StorageUnitTransferOperation, 'targetStorageUnitId'> {
}

export const STORAGE_UNIT_SETUP_PREFIX = 'SetupStorageUnit_';

interface StorageUnitSearchResponse {
  id: number,
  no: string,
}

interface GetFilteredParams {
  updatedAtFrom: string;
  updatedAtTo: string;
  materialIds: number[];
  storageAreaIds: number[];
}

export class StorageUnitStore extends EntityStore<StorageUnit> {
  storageUnits: StorageUnit[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'storageUnits', Api.storageUnits, StorageUnit, true);

    makeObservable(this, {
      storageUnits: observable,
      storageAreaRef: computed,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.materialStore,
        modelId: 'materialId',
      },
      {
        store: this.rootStore.storageAreaStore,
        modelId: 'storageAreaId',
      },
      {
        store: this.rootStore.orderStore,
        modelId: 'reservedOrderId',
      },
      {
        store: this.rootStore.storageUnitStore,
        modelId: 'parentId',
      },
    ];
  }

  get storageAreaRef(): string {
    return this.storageUnits.reduce((str, unit) => `${str},${unit.storageAreaId}`, '');
  }

  async searchByNo({ params }: SearchParams): Promise<StorageUnitSearchResponse[]> {
    return this.api.search({ ...params }).then((response: AxiosResponse) => response.data);
  }

  async getByNo(storageUnitNo: string) {
    let storageUnit = this.storageUnits.find((unit) => unit.no === storageUnitNo);
    if (!storageUnit) {
      await this.loadAll({ params: { no: storageUnitNo } });
      storageUnit = this.storageUnits.find((unit) => unit.no === storageUnitNo);
    }
    return storageUnit;
  }

  getSetupStorageUnitByOperationAndMaterialId(operationId: number, materialId: number) {
    return this.storageUnits.find((unit) =>
      unit.no === `${STORAGE_UNIT_SETUP_PREFIX}${operationId}_${materialId}` && unit.deletedAt === null);
  }

  getAllSetupStorageUnits(): StorageUnit[] {
    return this.storageUnits.filter((unit) =>
      unit.no?.includes(STORAGE_UNIT_SETUP_PREFIX) && unit.deletedAt === null) || [];
  }

  getFiltered({ updatedAtFrom, updatedAtTo, materialIds, storageAreaIds }: GetFilteredParams) {
    return this.storageUnits.filter((storageUnit) => {
      const updatedAt = dayjs(storageUnit.updatedAt);
      const isAfter = updatedAtFrom ? updatedAt.isAfter(updatedAtFrom) : true;
      const isBefore = updatedAtTo ? updatedAt.isBefore(updatedAtTo) : true;
      const hasMaterial = materialIds.length && storageUnit.materialId
        ? materialIds.includes(storageUnit.materialId)
        : true;
      const hasStorageArea = storageAreaIds.length && storageUnit.storageAreaId
        ? storageAreaIds.includes(storageUnit.storageAreaId)
        : true;
      return isAfter && isBefore && hasMaterial && hasStorageArea;
    });
  }

  getStorageUnitBySlot(slot?: { storageUnit: number; storageArea: number }) {
    if (!slot) {
      return null;
    }
    if (slot.storageUnit) {
      return this.rootStore.storageUnitStore.getById(slot.storageUnit);
    }
    if (slot.storageArea) {
      return this.rootStore.storageUnitStore.getByStorageAreaId(slot.storageArea)[0] || null;
    }
    return null;
  }

  getByStorageAreaId(storageAreaId: number) {
    return this.storageUnits.filter((su) => su.storageAreaId === storageAreaId);
  }

  async getOrCreateByNo(storageUnitNo: string) {
    let storageUnit;
    try {
      await this.create(
        { no: storageUnitNo },
        {
          apiEndpoint: getOrCreateStorageUnitEndpoint,
          skipNotification: true,
        }
      );
      storageUnit = this.storageUnits.find((unit) => unit.no === storageUnitNo);
    } catch (e) {
      this.handleApiError(e);
    }
    return storageUnit;
  }

  loadDescendantsById(storageUnitId: number) {
    return this.loadAllWithDependencies(
      {
        params: { id: storageUnitId },
        api: StorageUnitsApi,
        apiEndpoint: descendantsEndpoint,
      }
    );
  }

  getDescendantsById(storageUnitId: number) {
    return this.storageUnits.filter((unit) => unit.parentId === storageUnitId);
  }

  getDescendantsByIds(storageUnitIds: number[]) {
    return this.storageUnits.filter((unit) => storageUnitIds.includes(unit.parentId || 0));
  }

  getDescendantsByIdAndMaterial(id: number, materialId: number): StorageUnit[] {
    return this.storageUnits.filter((unit) => unit.parentId === id && unit.materialId === materialId);
  }

  loadByStorageAreaId(storageAreaId: number) {
    return this.loadAllWithDependencies(
      {
        params: { storageAreaId },
      }
    );
  }

  loadAllByStorageAreaIds(storageAreaIds: number[]) {
    return this.loadAllWithDependencies(
      {
        params: { storageAreaIds },
      }
    );
  }

  getStorageUnitsByStorageAreaId(storageAreaId: number) {
    return this.storageUnits.filter((unit) => unit.storageAreaId === storageAreaId);
  }

  loadSiblingsById(storageUnitId: number) {
    return this.loadAllWithDependencies(
      {
        params: { id: storageUnitId },
        api: StorageUnitsApi,
        apiEndpoint: siblingsEndpoint,
      }
    );
  }

  getSiblingsById(storageUnitId: number) {
    const storageUnit = this.storageUnits.find((unit) => unit.id === storageUnitId);
    if (storageUnit && storageUnit.parentId) {
      return this.storageUnits.filter((unit) => unit.parentId === storageUnit.parentId);
    }
    return this.storageUnits.filter((unit) => unit.id === storageUnitId);
  }

  removeWithChildren(id: number): void {
    const children = this.getDescendantsById(id);
    for (let i = 0; i < children.length; i++) {
      this.removeWithChildren(children[i].id);
    }
    this.remove(id);
  }

  input(operation: StorageUnitInputOperation): Promise<StorageUnit> {
    return this.api[inputStorageUnitEndpoint]({ operation })
      .then(({ data }: AxiosResponse) => {
        this.add(this.createModelInstance(data));
        this.addMessage(
          new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.createSuccess')),
          { skipNotification: false }
        );
      })
      .catch((e: Error) => this.handleApiError(e, 'create'));
  }

  output(operation: StorageUnitOutputOperation): Promise<StorageUnit> {
    return this.api[outputStorageUnitEndpoint]({ operation })
      .then(({ data }: AxiosResponse) => {
        this.add(this.createModelInstance(data));
        this.addMessage(
          new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.createSuccess')),
          { skipNotification: false }
        );
      })
      .catch((e: Error) => this.handleApiError(e, 'create'));
  }

  transfer(operation: StorageUnitTransferOperation): Promise<StorageUnit> {
    return this.api[transferStorageUnitEndpoint]({ operation })
      .then(({ data }: AxiosResponse) => {
        data.forEach((su: any) => {
          this.add(this.createModelInstance(su));
        });
        this.addMessage(
          new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.createSuccess')),
          { skipNotification: false }
        );
      })
      .catch((e: Error) => this.handleApiError(e, 'create'));
  }
}
