import {executeGet, updateEntity} from '../requests';

const resourcesName = 'v1/ldap-connection';

export const ldapConnectionEndpoint = 'ldap-connection';

export const LdapConnectionApi = {
  [ldapConnectionEndpoint]: ({...params}) => executeGet(resourcesName, {params}),
  update: ({id, ...values}) => updateEntity(values, `${resourcesName}`),
};
