import { computed, observable, makeObservable } from 'mobx';
import { faCompressAlt } from '@fortawesome/free-solid-svg-icons';
import { isNil } from 'lodash';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { sortAlphabetically, sortBoolean, sortNumerically } from '../components/shared/tables/sorters';
import { transformation } from '../utils/transformations';
import { getTranslation } from '../utils/translations';
import { RootStore } from '../stores/rootStore';
import { Translation } from './translations';
import { CustomAction } from './customAction';
import { OperationState } from './operationState';
import { WorkplaceState } from './workplaceState';

export interface TransitionTranslation extends Translation {
  label: string | null;
  description: string | null;
}

export class Transition extends BaseModel {
  id: number = 0;
  name: string = '';
  customActionIds: number[] = [];
  activeOperationFromStateIds: number[] = [];
  activeOperationToStateId: number | null = null;
  activeOperationWorkflowId: number | null = null;
  isInternal: boolean = false;
  isWorkplaceExclusive = null;
  startingOperationFromStateIds: number[] = [];
  startingOperationToStateId: number | null = null;
  startingOperationWorkflowId: number | null = null;
  workplaceFromStateIds: number[] = [];
  workplaceToStateId: number | null = null;
  translations: TransitionTranslation[] = [];
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      label: computed,
      description: computed,
      customActionIds: observable,
      customActions: computed,
      activeOperationFromStateIds: observable,
      activeOperationFromStates: computed,
      activeOperationToStateId: observable,
      activeOperationToState: computed,
      activeOperationWorkflowId: observable,
      activeOperationWorkflow: computed,
      isInternal: observable,
      isWorkplaceExclusive: observable,
      startingOperationFromStateIds: observable,
      startingOperationFromStates: computed,
      startingOperationToStateId: observable,
      startingOperationToState: computed,
      startingOperationWorkflowId: observable,
      startingOperationWorkflow: computed,
      workplaceFromStateIds: observable,
      workplaceFromStates: computed,
      workplaceToStateId: observable,
      workplaceToState: computed,
      createdByUser: computed,
      updatedByUser: computed,
      isStartTransition: computed,
      isActiveTransition: computed,
      isWorkplaceTransition: computed,
      customActionCount: computed,
      translations: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faCompressAlt;

  searchableProperties = ['name', 'description'];

  translatedProperties = ['description', 'label'];

  displayableProperties = [
    displayableProperty({
      key: 'name',
      title: i18n.t('transition.model.attributes.name.label'),
      sorter: (a, b) => sortAlphabetically(a.name, b.name),
      params: [displayablePropertyParam({ path: 'name' })],
    }),
    displayableProperty({
      key: 'label',
      title: i18n.t('transition.model.attributes.label.label'),
      sorter: (a, b) => sortAlphabetically(a.label, b.label),
      params: [displayablePropertyParam({ path: 'label' })],
    }),
    displayableProperty({
      key: 'description',
      title: i18n.t('transition.model.attributes.description'),
      params: [displayablePropertyParam({ path: 'description' })],
    }),
    displayableProperty({
      key: 'isStartTransition',
      title: i18n.t('transition.model.attributes.isStartTransition'),
      sorter: (a, b) => sortBoolean(a.isStartTransition, b.isStartTransition),
      raw: true,
      params: [displayablePropertyParam({ path: 'isStartTransition', transform: transformation.booleanValue })],
    }),
    displayableProperty({
      key: 'isActiveTransition',
      title: i18n.t('transition.model.attributes.isActiveTransition'),
      sorter: (a, b) => sortBoolean(a.isActiveTransition, b.isActiveTransition),
      raw: true,
      params: [displayablePropertyParam({ path: 'isActiveTransition', transform: transformation.booleanValue })],
    }),
    displayableProperty({
      key: 'isWorkplaceTransition',
      title: i18n.t('transition.model.attributes.isWorkplaceTransition'),
      sorter: (a, b) => sortBoolean(a.isWorkplaceTransition, b.isWorkplaceTransition),
      raw: true,
      params: [displayablePropertyParam({ path: 'isWorkplaceTransition', transform: transformation.booleanValue })],
    }),
    displayableProperty({
      key: 'customActionCount',
      title: i18n.t('transition.model.attributes.customActionCount'),
      sorter: (a, b) => sortNumerically(a.customActionCount, b.customActionCount),
      params: [displayablePropertyParam({ path: 'customActionCount', transform: transformation.fallback() })],
    }),
    displayableProperty({
      key: 'customActions',
      title: i18n.t('transition.model.attributes.customActions'),
      raw: true,
      params: [displayablePropertyParam({
        path: 'customActions',
        transform: (customActions: CustomAction[]) => customActions?.map(
          (customAction) => <div key={customAction.id}>{customAction.name}</div>
        ),
      })],
    }),
    displayableProperty({
      key: 'startingWorkflow',
      title: i18n.t('transition.model.attributes.startingWorkflow'),
      sorter: (a, b) => sortAlphabetically(a.startingOperationWorkflow?.name, b.startingOperationWorkflow?.name),
      params: [displayablePropertyParam({ path: 'startingOperationWorkflow.name' })],
    }),
    displayableProperty({
      key: 'activeWorkflow',
      title: i18n.t('transition.model.attributes.activeWorkflow'),
      sorter: (a, b) => sortAlphabetically(a.activeOperationWorkflow?.name, b.activeOperationWorkflow?.name),
      params: [displayablePropertyParam({ path: 'activeOperationWorkflow.name' })],
    }),
    displayableProperty({
      key: 'startingOperationFromStates',
      title: i18n.t('transition.model.attributes.startingOperationFromStates'),
      raw: true,
      params: [displayablePropertyParam({
        path: 'startingOperationFromStates',
        transform: (states: OperationState[]) => states?.map((state) => <div key={state.id}>{state.label}</div>),
      })],
    }),
    displayableProperty({
      key: 'activeOperationFromStates',
      title: i18n.t('transition.model.attributes.activeOperationFromStates'),
      raw: true,
      params: [displayablePropertyParam({
        path: 'activeOperationFromStates',
        transform: (states: OperationState[]) => states?.map((state) => <div key={state.id}>{state.label}</div>),
      })],
    }),
    displayableProperty({
      key: 'workplaceFromStates',
      title: i18n.t('transition.model.attributes.workplaceFromStates'),
      raw: true,
      params: [displayablePropertyParam({
        path: 'workplaceFromStates',
        transform: (states: WorkplaceState[]) => states?.map((state) => <div key={state.id}>{state.label}</div>),
      })],
    }),
    displayableProperty({
      key: 'startingOperationToState',
      title: i18n.t('transition.model.attributes.startingOperationToState'),
      raw: true,
      params: [displayablePropertyParam({
        path: 'startingOperationToState',
        transform: (state: OperationState) => !isNil(state) && <div key={state.id}>{state.label}</div>,
      })],
    }),
    displayableProperty({
      key: 'activeOperationToState',
      title: i18n.t('transition.model.attributes.activeOperationToState'),
      raw: true,
      params: [displayablePropertyParam({
        path: 'activeOperationToState',
        transform: (state: OperationState) => !isNil(state) && <div key={state.id}>{state.label}</div>,
      })],
    }),
    displayableProperty({
      key: 'workplaceToState',
      title: i18n.t('transition.model.attributes.workplaceToState'),
      raw: true,
      params: [displayablePropertyParam({
        path: 'workplaceToState',
        transform: (state: WorkplaceState) => !isNil(state) && <div key={state.id}>{state.label}</div>,
      })],
    }),
  ];

  static prepareApiPayload(model: Transition) {
    return {
      id: model.id || undefined,
      name: model.name,
      workplaceToStateId: model.workplaceToStateId,
      activeOperationWorkflowId: model.activeOperationWorkflowId,
      activeOperationToStateId: model.activeOperationToStateId,
      startingOperationWorkflowId: model.startingOperationWorkflowId,
      startingOperationToStateId: model.startingOperationToStateId,
      isWorkplaceExclusive: model.isWorkplaceExclusive,
      isInternal: model.isInternal,
      workplaceFromStateIds: model.workplaceFromStateIds,
      activeOperationFromStateIds: model.activeOperationFromStateIds,
      startingOperationFromStateIds: model.startingOperationFromStateIds,
      customActionIds: model.customActionIds,
      translations: Transition.convertToSavableTranslations(model),
    };
  }

  get activeOperationFromStates() {
    return this.rootStore.operationStateStore.getByIds(this.activeOperationFromStateIds);
  }

  get activeOperationToState() {
    return this.rootStore.operationStateStore.getById(this.activeOperationToStateId);
  }

  get startingOperationFromStates() {
    return this.rootStore.operationStateStore.getByIds(this.startingOperationFromStateIds);
  }

  get startingOperationToState() {
    return this.rootStore.operationStateStore.getById(this.startingOperationToStateId);
  }

  get workplaceFromStates() {
    return this.rootStore.workplaceStateStore.getByIds(this.workplaceFromStateIds);
  }

  get workplaceToState() {
    return this.rootStore.workplaceStateStore.getById(this.workplaceToStateId);
  }

  get activeOperationWorkflow() {
    return this.rootStore.workflowStore.getById(this.activeOperationWorkflowId);
  }

  get startingOperationWorkflow() {
    return this.rootStore.workflowStore.getById(this.startingOperationWorkflowId);
  }

  get customActions() {
    return this.rootStore.customActionStore.getByIds(this.customActionIds);
  }

  get createdByUser() {
    return this.rootStore.userStore.getById(this.createdBy);
  }

  get updatedByUser() {
    return this.rootStore.userStore.getById(this.updatedBy);
  }

  get isStartTransition() {
    return this.startingOperationFromStateIds.length > 0;
  }

  get isActiveTransition() {
    return this.activeOperationFromStateIds.length > 0;
  }

  get isWorkplaceTransition() {
    return this.workplaceFromStateIds.length > 0;
  }

  get customActionCount() {
    return this.customActionIds.length;
  }

  get description() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.description;
  }

  get label() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.label;
  }
}
