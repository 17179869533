import { Progress as AntdProgress, ProgressProps as AntdProgressProps } from 'antd';
import React from 'react';
import { observer } from 'mobx-react-lite';
import dayjs, { Dayjs } from 'dayjs';
import { useMount } from '../../../hooks/useMount';
import { formatDuration } from '../../../utils/formatting';
import { durationFormat } from '../../../config/dayjs';

export enum ProgressBarValueType {
  DATE = 'date',
  NUMBER = 'number'
}

export type ProgressBarProps = AntdProgressProps & {
  isPercentage?: boolean;
  isWithTimer?: boolean;
  timerIntervalSeconds?: number;
  customText?: string;
  progress?: number;
  startValue?: any;
  endValue?: any;
  valueType?: ProgressBarValueType;
  calculateRemainingTime?: boolean
};

const ProgressBar: React.FC<Omit<ProgressBarProps, 'percent'>> = ({
  isPercentage = true,
  isWithTimer = false,
  timerIntervalSeconds,
  customText = '',
  progress = 0,
  startValue,
  endValue,
  valueType,
  calculateRemainingTime,
  ...props
}) => {
  const [currentProgress, setCurrentProgress] = React.useState<number>(progress || 0);
  const [intervalId, setIntervalId] = React.useState<any>(null);
  const [remainingTime, setRemainingTime] = React.useState<string>('');

  const calculateProgress = () => {
    if (valueType) {
      if (valueType === ProgressBarValueType.DATE) {
        const start = dayjs(startValue) as Dayjs;
        const end = dayjs(endValue) as Dayjs;
        const now = dayjs();
        const total = end.diff(start, 'minutes');
        const current = now.diff(start, 'minutes');
        setCurrentProgress(Math.round((current / total) * 100));
        if (calculateRemainingTime) {
          const remainingTimeMinutes = end.diff(now, 'minutes');

          if (remainingTimeMinutes <= 0) {
            clearInterval(intervalId);
            setIntervalId(null);
            setRemainingTime('00:00');
          } else {
            setRemainingTime(formatDuration(dayjs.duration(remainingTimeMinutes, 'minutes'), durationFormat));
          }
        }
      } else if (valueType === ProgressBarValueType.NUMBER) {
        const start = startValue as number;
        const end = endValue as number;
        const total = end - start;
        const current = currentProgress - start;
        setCurrentProgress(Math.round((current / total) * 100));
      }
    } else {
      setCurrentProgress(progress || 0);
    }
  };

  useMount(() => {
    calculateProgress();

    if (isWithTimer && timerIntervalSeconds) {
      if (!intervalId) {
        setIntervalId(setInterval(calculateProgress, timerIntervalSeconds * 1000));
      }
    } else {
      clearInterval(intervalId);
      setIntervalId(null);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isWithTimer]);

  return (
    <>
      <AntdProgress
        percent={currentProgress}
        showInfo={isPercentage}
        size={[200, 20]}
        {...props}
      />
      {
        !isPercentage && (
          <span>
            { remainingTime || customText }
          </span>
        )
      }
    </>
  );
};

export default observer(ProgressBar);
