import { observable, makeObservable, computed } from 'mobx';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { displayableProperty } from './displayableProperty';
import { CustomPropertyTypes } from './customPropertyDataTypes';
import { transformation } from '../utils/transformations';
import { datetimeFormat } from '../config/dayjs';
import { CustomPropertiesObject } from './customProperty';
import { RootStore } from '../stores/rootStore';

export enum InspectionLotOrigin {
  order = 'ERP-QM',
  notOrder = 'ERP-PM'
}

export class InspectionLot extends BaseModel {
  id: number = 0;
  orderId: number | null = null;
  lotNo: string = '';
  validStart: string | null = null;
  validEnd: string | null = null;
  inspectionPlanId: number | null = null;
  deactivatedBy: number | null = null;
  deactivatedAt: string | null = null;
  deactivatedReason: string | null = null;
  properties?: CustomPropertiesObject = undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      orderId: observable,
      order: computed,
      inspectionPlanId: observable,
      lotNo: observable,
      validStart: observable,
      validEnd: observable,
      deactivatedBy: observable,
      deactivatedByUser: computed,
      deactivatedAt: observable,
      deactivatedReason: observable,
    });
  }

  static faIcon = faTag;

  displayableProperties = [
    displayableProperty({
      key: 'lotNo',
      title: i18n.t('inspectionLot.model.attributes.lotNo'),
      params: [displayablePropertyParam({ path: 'lotNo' })],
    }),
    displayableProperty({
      key: 'order',
      title: i18n.t('order.model.attributes.no'),
      params: [displayablePropertyParam({ path: 'order.no' })],
    }),
    displayableProperty({
      key: 'validStart',
      title: i18n.t('inspectionLot.model.attributes.validStart'),
      params: [{ path: 'validStart', transform: transformation.datetime({ format: datetimeFormat }) }],
      renderText: (text, record) => record?.validStart || '',
    }),
    displayableProperty({
      key: 'validEnd',
      title: i18n.t('inspectionLot.model.attributes.validEnd'),
      params: [{ path: 'validEnd', transform: transformation.datetime({ format: datetimeFormat }) }],
      renderText: (text, record) => record?.validEnd || '',
    }),
    displayableProperty({
      key: 'deactivatedAt',
      title: i18n.t('inspectionLot.model.attributes.deactivatedAt'),
      params: [{ path: 'deactivatedAt', transform: transformation.datetime({ format: datetimeFormat }) }],
      raw: false,
      renderText: (text, record) => record?.deactivatedAt || '',
    }),
    displayableProperty({
      key: 'deactivatedBy',
      title: i18n.t('inspectionLot.model.attributes.deactivatedBy'),
      params: [displayablePropertyParam({ path: 'deactivatedByUser.fullName' })],
      renderText: (text, record) => record?.deactivatedByUser?.fullName || '',
    }),
    displayableProperty({
      key: 'deactivatedReason',
      title: i18n.t('inspectionLot.model.attributes.deactivatedReason'),
      params: [displayablePropertyParam({ path: 'deactivatedReason' })],
      raw: false,
    }),
  ];

  customPropertyType = CustomPropertyTypes.InspectionLot;

  get order() {
    return this.rootStore.orderStore.getById(this.orderId);
  }

  get deactivatedByUser() {
    return this.rootStore.userStore.getById(this.deactivatedBy);
  }
}
