import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useStore } from '../../../hooks/useStore';
import { useMount } from '../../../hooks/useMount';
import Tabs from '../../shared/tabs/Tabs';
import Button from '../../shared/buttons/Button';
import { Component } from '../../../models/component';
import { Preparation } from '../../../models/preparation';
import DosageProductTable from './DosageProductTable';
import Modal from '../../shared/modal/Modal';
import DosageTable from './DosageTable';
import { DosageWidgetConfig } from './dosageWidgetConfig';
import { STORAGE_UNIT_SETUP_PREFIX } from '../../../stores/storageUnitStore';
import DosageQuantitySlider from './DosageQuantitySlider';
import DosageSelectTargetModal from './DosageSelectTargetModal';
import { DosageStoreActions } from '../../../stores/dosageStore';

export type DosageTabsProps = {
  widgetConfig: DosageWidgetConfig;
  selectedComponents: Component[];
};

export interface SourceStorageUnit {
  id: number;
  operationId: number;
  materialId: number;
}

export interface ParentInformation {
  isParent: boolean;
  parentStorageUnitId: number;
  childrenAmount: number;
}

export type Item = {
  label: string;
  children: JSX.Element | string;
  key: string;
  closable: boolean;
};

export type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

const DosageTabs: React.FC<DosageTabsProps> = ({
  widgetConfig,
  selectedComponents,
}) => {
  const store = useStore();
  const { t } = useTranslation();
  const [activeKey, setActiveKey] = useState<string | null>(null);
  const [items, setItems] = useState<Item[] | []>([]);
  const [openFinalizeModal, setOpenFinalizeModal] = useState<boolean>(false);
  const [sourceStorageUnitIds, setSourceStorageUnitIds] = React.useState<SourceStorageUnit[]>([]);
  const [selectTargetModalVisible, setSelectTargetModalVisible] = useState(false);
  const [sensorName, setSensorName] = React.useState<string>('');

  const handleFinalize = () => {
    setOpenFinalizeModal(true);
  };

  const closeFinalizeModal = () => {
    setOpenFinalizeModal(false);
  };

  const initActiveKey = (preparations: Preparation[]) => {
    if (!activeKey || preparations.every((prep) => prep.id.toString() !== activeKey)) {
      setActiveKey(preparations[0].id.toString());
    }
  };

  const handleSensorNameChange = (value: string) => {
    setSensorName(value);
  };

  useMount(() => {
    if (store.operationStore.active) {
      store.preparationStore.loadAllWithDependencies({
        params: {
          operationId: store.operationStore.active.id,
        },
      });
    }
  }, [store.operationStore.active?.id]);

  useMount(() => {
    if (!store.operationStore.active || !selectedComponents.length) {
      return;
    }
    const preparations = store.preparationStore.getRunningByOperations([store.operationStore.active?.id]);
    const filteredByProductId = preparations
      .filter((preparation) => preparation.productId === selectedComponents[0].productId);

    if (!filteredByProductId.length) {
      setItems([]);
      setActiveKey(null);
      return;
    }

    const createdItems: Item[] = filteredByProductId.map((preparation) => ({
      label: `${t('dosageWidget.tabs.newTab')} ${preparation.no}`,
      children: '',
      key: preparation.id.toString(),
      closable: false,
    }));
    setItems([
      ...createdItems,
    ]);
    initActiveKey(filteredByProductId);
  }, [
    store.operationStore.active?.id,
    selectedComponents,
    sourceStorageUnitIds,
    store.preparationStore.preparations.length,
  ]);

  useMount(() => {
    if (!selectedComponents || !selectedComponents.length) {
      return;
    }
    const promises = selectedComponents.map((component) => store.storageUnitStore.loadAllWithDependencies({
      params: {
        no: `${STORAGE_UNIT_SETUP_PREFIX}${component.operationId}_${component.materialId}`,
      },
    }));

    Promise.all(promises).then((data) => {
      const finalData = data.filter((unit) => unit.length > 0).flat();
      if (finalData.length > 0) {
        const sourceStorageUnit: SourceStorageUnit[] = [];
        finalData.forEach((unit) => {
          const operationId = unit.no?.substring(unit.no.indexOf('_') + 1, unit.no?.lastIndexOf('_')) || 0;
          const materialId = unit.no?.substring(unit.no.lastIndexOf('_') + 1) || 0;

          sourceStorageUnit.push({
            id: unit.id,
            operationId: Number(operationId),
            materialId: Number(materialId),
          });
        });
        setSourceStorageUnitIds(sourceStorageUnit);
      }
    });
  }, [
    selectedComponents,
    store.dosageStore.selectedComponentUnitIds.length,
    store.storageUnitStore.storageUnits.length,
  ]);

  const add = async (targetStorageUnitId?: number) => {
    if (!store.operationStore.active || !selectedComponents[0].productId) {
      return;
    }
    let preparation: Preparation | null = null;

    try {
      const data = await store.dosageStore.start(
        selectedComponents[0].productId,
        store.operationStore.active.workplaceId,
        targetStorageUnitId
      );
      preparation = data || null;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error starting dosage', e);
    }

    if (preparation === null) {
      return;
    }

    const newActiveKey = preparation.id.toString();
    setItems([
      ...items,
      {
        label: `${t('dosageWidget.tabs.newTab')} ${preparation.no}`,
        key: newActiveKey,
        children: '',
        closable: false,
      },
    ]);
    setActiveKey(newActiveKey);
  };

  const onEdit = (_targetKey: TargetKey, action: 'add' | 'remove') => {
    if (action === 'add') {
      add();
    }
  };
  const tare = async () => {
    if (!selectedComponents || !selectedComponents.length) {
      return;
    }
    const activePreparation = store.preparationStore.getById(Number(activeKey));
    if (activePreparation) {
      const operation = store.operationStore.getById(selectedComponents[0].operationId);
      const workplace = store.workplaceStore.getById(operation?.workplaceId || null);
      if (workplace && sensorName) {
        try {
          await store.dosageStore.tare(
            workplace.id,
            sensorName,
            selectedComponents[0].operationId
          );
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Error tare dosage', e);
        }
      }
    }
  };

  useMount(() => {
    if (!selectedComponents || !selectedComponents.length) {
      return;
    }
    if (widgetConfig.getWidgetSetting('property_autoTareOnComponentChange')) {
      tare();
    }
  }, [selectedComponents]);

  const onChange = (key: string) => {
    setActiveKey(key);
    if (widgetConfig.getWidgetSetting('property_autoTareOnDosageChange')) {
      tare();
    }
  };

  const finalize = async () => {
    if (!store.operationStore.active) {
      return;
    }
    const { id: operationId, workplaceId } = store.operationStore.active;
    try {
      await store.dosageStore.finalize(
        operationId,
        workplaceId
      );

      setItems([]);
      setActiveKey(null);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error finalize dosage', e);
    }
    closeFinalizeModal();
  };

  let activePreparation: Preparation | undefined;
  if (activeKey) {
    activePreparation = store.preparationStore.getById(Number(activeKey));
  }

  return (
    <div>
      {
        openFinalizeModal
          ? (
            <Modal
              title={t('dosageWidget.tabs.modal.finalize.title')}
              open
              width={'90vw'}
              centered
              onCancel={closeFinalizeModal}
              footer={[
                <Button
                  key="submit"
                  type="primary"
                  onClick={finalize}
                  loading={store.dosageStore.isResourceBusy(DosageStoreActions.finalize)}
                >
                  {t('dosageWidget.tabs.button.finalize')}
                </Button>,
              ]}
            >
              <DosageTable
                widgetConfig={widgetConfig}
                operationId={store.operationStore.active?.id}
                showAverages
              />
            </Modal>
          ) : null
      }
      {
        selectTargetModalVisible
          ? (
            <DosageSelectTargetModal
              data-cy={'DosageTabs-DosageSelectTargetModal'}
              open={selectTargetModalVisible}
              onCancel={() => {
                setSelectTargetModalVisible(false);
              }}
              onSuccess={(targetUnitId) => {
                add(targetUnitId);
                setSelectTargetModalVisible(false);
              }}
            />
          ) : null
      }
      {
        activeKey ? (
          <>
            <Tabs
              data-cy={'DosageTabs-Tabs'}
              hideAdd
              onChange={onChange}
              activeKey={activeKey}
              type="editable-card"
              onEdit={onEdit}
              items={items}
              tabBarExtraContent={{
                right: (
                  <Button
                    type={'link'}
                    disabled={store.dosageStore.isInPreparation}
                    onClick={() => {
                      widgetConfig.getSelectTargetStorageUnit()
                        ? setSelectTargetModalVisible(true)
                        : add();
                    }}
                    icon={<FontAwesomeIcon icon={faPlus}/>}
                  >
                    {t('dosageWidget.tabs.button.addDosage')}
                  </Button>
                ),
              }}
            />
            {activePreparation ? (
              <>
                <DosageTable
                  widgetConfig={widgetConfig}
                  operationId={store.operationStore.active?.id}
                  showAverages={false}
                  productId={selectedComponents[0].productId || undefined}
                  showPagination={false}
                />
                <DosageQuantitySlider
                  widgetConfig={widgetConfig}
                  preparationId={activePreparation.id}
                  sourceStorageUnitIds={sourceStorageUnitIds}
                  onSensorNameChange={handleSensorNameChange}
                />
                <DosageProductTable
                  widgetConfig={widgetConfig}
                  productId={selectedComponents[0].productId}
                  preparationId={activePreparation.id}
                  isMarkSetupSelectionInTable
                />
              </>
            ) : null}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '20px',
                paddingBottom: '10px',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                data-cy={'DosageTabs-FinalizeButton'}
                disabled={!store.operationStore.active}
                type={'primary'}
                onClick={handleFinalize}
                style={{
                  minWidth: '150px',
                  paddingInline: '16px',
                }}
              >
                {t('dosageWidget.tabs.button.finalize')}
              </Button>
            </div>
          </>
        ) : (
          <Button
            data-cy={'DosageTabs-AddTabButton'}
            type={'link'}
            disabled={store.dosageStore.isInPreparation}
            onClick={() => {
              widgetConfig.getSelectTargetStorageUnit()
                ? setSelectTargetModalVisible(true)
                : add();
            }}
            icon={(
              <FontAwesomeIcon
                icon={faPlus}
              />
            )}
          >
            { t('dosageWidget.tabs.button.addDosage') }
          </Button>
        )
      }
    </div>
  );
};

export default observer(DosageTabs);
