import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FormModal from '../shared/modal/FormModal';
import {useStore} from '../../hooks/useStore';
import {Yield as Model} from '../../models/yield';
import {useMount} from '../../hooks/useMount';
import YieldForm from './YieldForm';
import {makeFormFields} from '../shared/form/formFields';

export const YieldCreateModal = ({onCancel, onSuccess}) => {
  const {t} = useTranslation();
  const store = useStore();
  const [fields, setFields] = useState([]);

  const handleSubmit = (values) => {
    const yieldType = store.yieldTypeStore.getById(values.yieldTypeId);
    if (yieldType.isProduct) {
      values.unitOfMeasureId = store.operationStore.active.unitOfMeasureId
        || store.operationStore.active.order.unitOfMeasureId;
    } else {
      values.unitOfMeasureId = yieldType.unitOfMeasureId;
    }
    store.yieldStore.create({...values, operationId: store.operationStore.active.id})
      .then((record) => onSuccess && onSuccess(record))
      .catch((errors) => setFields(makeFormFields(values, errors.fields)));
  };

  useMount(() => {
    store.yieldTypeStore.loadAll();
  });

  return (
    <FormModal
      fullscreen={store.clientStore.isMobile}
      title={(
        <>
          <FontAwesomeIcon icon={Model.faIcon}/>
          {' '}
          {t('yieldWidget.createModal.title')}
        </>
        )}
      open
      width={'600px'}
      onCancel={onCancel}
    >
      <YieldForm
        fields={fields}
        onChange={setFields}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        saving={store.yieldStore.isMutationInProgress}
      />
    </FormModal>
  );
};

export default observer(YieldCreateModal);
