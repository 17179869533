import React from 'react';
import { observer } from 'mobx-react-lite';
import Button from '../../buttons/Button';
import { WidgetAction } from '../../../../models/widgetAction';
import { useScopes } from '../../../../hooks/useScopes';
import { useModulePolicy } from '../../../../hooks/useModulePolicy';
import { useStore } from '../../../../hooks/useStore';
import { execResourceName } from '../../../../middleware/endpoints/widgetActions';

export type WidgetActionInputProps = {
  widgetAction: WidgetAction;
  onExec: (id: number, value: string, cb?: () => void) => void;
};

const WidgetActionButton: React.FC<WidgetActionInputProps> = ({
  widgetAction,
  onExec,
}) => {
  const store = useStore();
  const scopes = useScopes();
  const policy = useModulePolicy(store, scopes);

  const handleExec = () => {
    onExec(widgetAction.id, '');
  };

  const isExecuting = store.widgetActionStore.isResourceBusy(execResourceName);
  const disabled = !policy.canExecute() || isExecuting;

  return (
    <Button
      loading={isExecuting}
      disabled={disabled}
      type={'primary'}
      onClick={handleExec}
      data-cy={'WidgetActionButton-Button'}
    >
      {widgetAction.label}
    </Button>
  );
};

export default observer(WidgetActionButton);
