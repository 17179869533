import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { RootStore } from './rootStore';
import { Product } from '../models/product';

export class ProductStore extends EntityStore<Product> {
  products: Product[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'products', Api.products, Product, true);

    makeObservable(this, {
      products: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.materialStore,
        modelId: 'materialId',
      },
      {
        store: this.rootStore.operationStore,
        modelId: 'operationId',
      },
      {
        store: this.rootStore.unitOfMeasurementStore,
        modelId: 'unitOfMeasureId',
      },
    ];
  }

  getByOrderId(orderId: number | null | undefined) {
    if (orderId === null || orderId === undefined) {
      return [];
    }
    return this.products.filter((product) => product.operation?.orderId === orderId);
  }

  getByOperationId(operationId: number | null | undefined): Product[] {
    if (operationId === null || operationId === undefined) {
      return [];
    }
    return this.products.filter((product) => product.operationId === operationId);
  }
}
