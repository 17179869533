import {executeGet} from '../requests';

const resourcesName = 'v1/state-history';
export const interruptionsEndpoint = 'interruptionHistory';
export const interruptionsResource = 'interruptions';

export const StateHistoriesApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  [interruptionsEndpoint]: (params) => executeGet(`${resourcesName}/${interruptionsResource}`, params),
};
