import { computed, observable, makeObservable } from 'mobx';
import { faBoxesStacked } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';
import { BaseModel, DisplayablePropertiesOptions } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import { Material } from './material';
import { Order } from './order';
import { UnitOfMeasurement } from './unitOfMeasurement';
import { sortAlphabetically, sortNumerically } from '../components/shared/tables/sorters';
import { StorageArea } from './storageArea';
import { CustomPropertyTypes } from './customPropertyDataTypes';
import { RootStore } from '../stores/rootStore';
import { CustomPropertiesObject } from './customProperty';
import { datetimeFormat } from '../config/dayjs';
import ProgressBar, { ProgressBarValueType } from '../components/shared/dataDisplay/ProgressBar';
import { EnDash } from '../components/shared/unicodeWrapper/EnDash';

export const MAX_UNITS = 1000;

/**
 * storage unit operations translations:
 * t('storageUnit.operations.INPUT')
 * t('storageUnit.operations.OUTPUT')
 * t('storageUnit.operations.TRANSFER')
 */
export const StorageUnitOperations = Object.freeze({
  Input: 'INPUT',
  Output: 'OUTPUT',
  Transfer: 'TRANSFER',
});

export interface StorageUnitContent {
  id: number;
  batchId?: number | null;
  amount: number;
  storageUnitId: number;
  createdAt: string;
}

export interface BatchAmount {
  storageUnitId: number;
  batchId: number | null;
  amount: number;
  label: string;
  createdAt: string;
}

export class StorageUnit extends BaseModel {
  id: number = 0;
  no: string | null = null;
  materialId: number | null = null;
  unitOfMeasureId: number | null = null;
  storageAreaId: number | null = null;
  reservedOrderId: number | null = null;
  capacity: number | null = null;
  parentId: number | null = null;
  isSingleUse: boolean = false;
  isStationary: boolean = false;
  isMixingBatches: boolean = false;
  timerStart: Date | null = null;
  timerEnd: Date | null = null;
  setupOrder: number | null = null;
  contents: StorageUnitContent[] = [];
  properties?: CustomPropertiesObject = undefined;
  createdAt = null;
  createdBy = null;
  updatedAt = null;
  updatedBy = null;
  deletedAt = null;
  deletedBy = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      no: observable,
      materialId: observable,
      material: computed,
      unitOfMeasureId: observable,
      unitOfMeasure: computed,
      storageAreaId: observable,
      storageArea: computed,
      reservedOrderId: observable,
      reservedOrder: computed,
      capacity: observable,
      parentId: observable,
      parent: computed,
      isSingleUse: observable,
      isStationary: observable,
      isMixingBatches: observable,
      timerStart: observable,
      timerEnd: observable,
      timerDurationMinutes: computed,
      setupOrder: observable,
      contents: observable,
      properties: observable,
      totalAmount: computed,
      batchAmount: computed,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
      deletedAt: observable,
      deletedBy: observable,
    });
  }

  static faIcon = faBoxesStacked;

  searchableProperties = ['no', 'material.name', 'material.no'];

  saveableProperties = [
    'id',
    'no',
    'unitOfMeasureId',
    'materialId',
    'reservedOrderId',
    'isSingleUse',
    'parentId',
    'capacity',
    'storageAreaId',
    'isStationary',
    'isMixingBatches',
    'timerStart',
    'timerEnd',
  ];

  customPropertyType = CustomPropertyTypes.StorageUnit;

  displayableProperties = [
    displayableProperty({
      key: 'no',
      title: i18n.t('storageUnit.model.attributes.no'),
      params: [
        displayablePropertyParam({
          path: 'no',
          transform: transformation.none,
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.no, b.no),
    }),
    displayableProperty({
      key: 'parentNo',
      title: i18n.t('storageUnit.model.attributes.parentStorageUnit'),
      params: [displayablePropertyParam({ path: 'parent.no' })],
      sorter: (a, b) => sortAlphabetically(a.parent?.no, b.parent?.no),
      template: '{value}',
    }),
    displayableProperty({
      key: 'capacity',
      title: i18n.t('storageUnit.model.attributes.capacity'),
      params: [
        displayablePropertyParam({ path: 'capacity', as: 'capacity' }),
        displayablePropertyParam({ path: 'unitOfMeasure.label', as: 'uom' }),
      ],
      sorter: (a, b) => sortNumerically(a.capacity, b.capacity),
      template: '{capacity} {uom}',
    }),
    displayableProperty({
      key: 'amount',
      title: i18n.t('storageUnit.model.attributes.amount'),
      params: [
        displayablePropertyParam({
          path: 'totalAmount',
          transform: transformation.none,
          as: 'amount',
        }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{amount} {unitOfMeasure}',
    }),
    displayableProperty({
      key: 'timerStart',
      title: i18n.t('storageUnit.model.attributes.timerStart'),
      params: [
        displayablePropertyParam({
          path: 'timerStart',
          transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.timerStart, b.timerStart),
    }),
    displayableProperty({
      key: 'timerEnd',
      title: i18n.t('storageUnit.model.attributes.timerEnd'),
      params: [
        displayablePropertyParam({
          path: '.',
          transform: (su) => {
            const ProgressData = StorageUnit.getStartEnd(su);
            if (!ProgressData.start || !ProgressData.end) {
              return <EnDash/>;
            }
            return (
              <ProgressBar
                isPercentage={false}
                isWithTimer
                timerIntervalSeconds={60}
                customText={dayjs(su.timerEnd).format(datetimeFormat)}
                startValue={ProgressData.start}
                endValue={ProgressData.end}
                valueType={ProgressBarValueType.DATE}
              />
            );
          },
          as: 'timerEnd',
        }),
      ],
      raw: true,
    }),
    displayableProperty({
      key: 'timerDurationMinutes',
      title: i18n.t('storageUnit.model.attributes.timerDurationMinutes'),
      params: [
        displayablePropertyParam({
          path: '.',
          transform: (su) => {
            const ProgressData = StorageUnit.getStartEnd(su);
            if (!ProgressData.start || !ProgressData.end) {
              return <EnDash/>;
            }
            return (
              <ProgressBar
                isPercentage={false}
                isWithTimer
                timerIntervalSeconds={60}
                startValue={ProgressData.start}
                endValue={ProgressData.end}
                valueType={ProgressBarValueType.DATE}
                calculateRemainingTime
              />
            );
          },
          as: 'timerDurationMinutes',
        }),
      ],
      raw: true,
    }),
  ];

  static allDisplayableProperties(
    rootStore: RootStore,
    pathPrefix = '',
    titlePrefix = '',
    options: DisplayablePropertiesOptions = { includeConversions: false }
  ) {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix, options);

    allDisplayableProperties.push(
      ...this.displayableCustomProperties(rootStore, pathPrefix, titlePrefix)
    );

    allDisplayableProperties.push(
      ...Material.allDisplayableProperties(rootStore, `${pathPrefix}material.`, titlePrefix)
    );

    const storageAreaPrefix = `${titlePrefix}${i18n.t('storageArea.model.one')} > `;
    allDisplayableProperties.push(
      ...StorageArea.allDisplayableProperties(rootStore, `${pathPrefix}storageArea.`, storageAreaPrefix)
    );
    const orderPrefix = `${titlePrefix}${i18n.t('order.model.one')} > `;
    allDisplayableProperties.push(
      ...Order.allDisplayableProperties(rootStore, `${pathPrefix}reservedOrder.`, orderPrefix)
    );
    const unitOfMeasurePrefix = `${titlePrefix}${i18n.t('unitOfMeasurement.model.one')} > `;
    allDisplayableProperties.push(
      ...UnitOfMeasurement.allDisplayableProperties(rootStore, `${pathPrefix}unitOfMeasure.`, unitOfMeasurePrefix)
    );

    allDisplayableProperties.push(
      ...this.displayableCustomProperties(rootStore, pathPrefix, titlePrefix)
    );

    return allDisplayableProperties;
  }

  get material() {
    return this.rootStore.materialStore.getById(this.materialId);
  }

  get storageArea() {
    return this.rootStore.storageAreaStore.getById(this.storageAreaId);
  }

  get parent() {
    return this.rootStore.storageUnitStore.getById(this.parentId);
  }

  get reservedOrder() {
    return this.rootStore.orderStore.getById(this.reservedOrderId);
  }

  get unitOfMeasure() {
    return this.rootStore.unitOfMeasurementStore.getById(this.unitOfMeasureId);
  }

  get timerDurationMinutes() {
    if (this.timerStart && this.timerEnd) {
      return Math.round((this.timerEnd.getTime() - this.timerStart.getTime()) / 60000);
    }
    return null;
  }

  static prepareApiPayload(model: StorageUnit) {
    return {
      id: model.id || undefined,
      no: model.no,
      storageAreaId: model.storageAreaId || null,
      parentId: model.parentId || null,
      materialId: model.materialId || null,
      unitOfMeasureId: model.unitOfMeasureId || null,
      capacity: model.capacity || null,
      reservedOrderId: model.reservedOrderId || null,
      isSingleUse: model.isSingleUse || false,
      isStationary: model.isStationary || false,
      isMixingBatches: model.isMixingBatches || false,
      properties: model.properties || undefined,
      timerStart: model.timerStart || null,
      timerEnd: model.timerEnd || null,
    };
  }

  get totalAmount() {
    return this.contents?.reduce((sum, next) => sum + (next.amount || 0), 0);
  }

  get batchAmount() {
    if (!this.contents || !this.contents.length) {
      return [];
    }

    const batchAmounts: BatchAmount[] = [];

    const sortedContents = sortBy(this.contents, (content) => new Date(content.createdAt));

    sortedContents
      .forEach((content) => {
        const batch = content.batchId ? this.rootStore.batchStore.getById(content.batchId) : null;
        batchAmounts.push({
          storageUnitId: this.id,
          batchId: content?.batchId || null,
          amount: content.amount,
          createdAt: content.createdAt,
          label: batch
            ? `${batch.no}, ${content.amount}${this.unitOfMeasure?.label}`
            : `${content.amount}${this.unitOfMeasure?.label}`,
        });
      });

    return batchAmounts;
  }

  // eslint-disable-next-line max-len
  static getStartEnd = (storageUnit: StorageUnit): any => ({ start: storageUnit.timerStart, end: storageUnit.timerEnd });
}
