import {useState} from 'react';
import {Form} from 'antd';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import Modal from '../shared/modal/Modal';
import {useStore} from '../../hooks/useStore';
import {useMount} from '../../hooks/useMount';
import Input from '../shared/inputs/Input';
import {ValidationRules} from '../../utils/validationRules';
import {FormItemLayouts} from '../shared/form/formItemLayouts';
import CancelSaveButtonGroup from '../shared/buttons/CancelSaveButtonGroup';

const {TextArea} = Input;

const InterruptionRemarkModal = ({
  currentLog,
  closeModal,
  ...props
}) => {
  const store = useStore();
  const {t} = useTranslation();
  const [form] = Form.useForm();

  const [isCommentRequired, setIsCommentRequired] = useState();

  useMount(() => {
    const reason = store.interruptionReasonStore.getById(currentLog.interruptionReasonId);
    setIsCommentRequired(reason?.isMessageRequired);
  });

  return (
    <Modal open footer={false} maskClosable={false} onCancel={closeModal} {...props}>
      <Form
        form={form}
        onCancel={() => closeModal()}
        onFinish={async (values) => {
          const res = await store.operationStateLogStore.update({
            id: currentLog.id,
            message: values.message,
          });
          if (res) {
            closeModal();
          }
        }}
        initialValues={{message: currentLog?.message}}
        layout={'vertical'}
      >
        <Form.Item
          name="message"
          label={t('interruption.modal.form.comment.label')}
          rules={[ValidationRules.requiredIf(isCommentRequired)]}
        >
          <TextArea
            placeholder={t('interruption.modal.form.comment.placeholder')}
          />
        </Form.Item>
        <Form.Item {...FormItemLayouts.fullWidth} style={{textAlign: 'right'}}>
          <CancelSaveButtonGroup onCancel={() => closeModal()} onSave={false}/>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(InterruptionRemarkModal);
