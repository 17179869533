import {useState} from 'react';
import {Col, Row} from 'antd';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHistory} from '@fortawesome/free-solid-svg-icons';
import Modal from '../../shared/modal/Modal';
import Form from '../../shared/form/Form';
import Input from '../../shared/inputs/Input';
import DefaultFormActions from '../../shared/form/DefaultFormActions';
import {useMount} from '../../../hooks/useMount';
import {useStore} from '../../../hooks/useStore';
import {ValidationRules} from '../../../utils/validationRules';
import {EnDash} from '../../shared/unicodeWrapper/EnDash';

const InspectionTaskQualitativeTargetValuesModal = ({task, onClose}) => {
  const store = useStore();
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState();

  const onSubmit = (values) => {
    setIsSaving(true);
    store.inspectionTaskStore.updateQualitativeTargetValues({
      id: task.id,
      ...values,
    }).then(() => {
      onClose();
    }).finally(() => setIsSaving(false));
  };

  useMount(() => {
    form.setFieldsValue({...task?.qualitative, editedRemark: null});
  }, [task?.qualitative]);

  const InputWithOldValue = ({oldValue, ...props}) => (
    <Row type={'flex'}>
      <Col flex={'auto'}>
        <Input {...props}/>
      </Col>
      <Col
        offset={1}
        style={{opacity: 0.5, lineHeight: '31px'}}
        title={t('inspectionTask.targetValuesModal.oldValue')}
      >
        <FontAwesomeIcon icon={faHistory} style={{marginRight: '0.2em'}}/>
        {oldValue !== undefined ? oldValue : EnDash()}
      </Col>
    </Row>
  );

  return (
    <Modal
      title={t('inspectionTask.targetValuesModal.title')}
      open
      onCancel={onClose}
      footer={false}
    >
      <Row style={{marginBottom: '2em'}}>
        <Col span={8}>
          {t('inspectionTask.model.one')}
        </Col>
        <Col span={16}>
          {task?.name || '-'}
        </Col>
      </Row>
      <Form
        form={form}
        onFinish={onSubmit}
      >
        <Form.Item
          name={'targetValue'}
          label={t('inspectionTaskQualitative.model.attributes.desiredValue')}
          rules={[ValidationRules.required()]}
        >
          <InputWithOldValue type={'text'} oldValue={task?.qualitative?.targetValueOld}/>
        </Form.Item>
        <Form.Item
          name={'editedRemark'}
          label={t('inspectionTaskQualitative.model.attributes.editedRemark')}
          rules={[ValidationRules.required()]}
        >
          <Input.TextArea maxLength={255} placeholder={task?.qualitative?.editedRemark || ''}/>
        </Form.Item>
        <DefaultFormActions
          saving={isSaving}
          onCancel={onClose}
        />
      </Form>
    </Modal>
  );
};

export default observer(InspectionTaskQualitativeTargetValuesModal);
