import i18n from '../../i18n/i18n';
import {ValidationRules} from '../../utils/validationRules';
import {BaseWidgetConfig} from '../shared/widgets/baseWidgetConfig';
import {InspectionTaskPending} from '../../models/inspectionTaskPending';
import {getAllI18nTranslations} from '../../utils/translations';
import {FieldType} from '../../types/widgetConfig';

export class InspectionTaskPendingWidgetConfig extends BaseWidgetConfig {
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'settings',
        label: i18n.t('inspectionTaskPendingWidget.settings.tabTitle'),
      },
      {
        key: 'modalSettings',
        label: i18n.t('inspectionTaskPendingWidget.modalSettings.tabTitle'),
      },
      {
        key: 'modalInspectionLots',
        label: i18n.t('inspectionTaskPendingWidget.modalInspectionLots.tabTitle'),
      },
    ];
  }

  settings = [
    {
      id: 'title',
      label: i18n.t('widget.settings.title.label'),
      helpText: i18n.t('widget.settings.title.help'),
      type: FieldType.Translatable,
      defaultValue: getAllI18nTranslations('inspectionTaskPendingWidget.title'),
      validations: [ValidationRules.required()],
    },
    {
      id: 'showInspectionTaskGroupIcon',
      label: i18n.t('widget.settings.showInspectionTaskGroupIcon.label'),
      helpText: i18n.t('widget.settings.showInspectionTaskGroupIcon.help'),
      type: FieldType.Boolean,
      defaultValue: false,
    },
    {
      id: 'openFirstDocument',
      label: i18n.t('widget.settings.openFirstDocument.label'),
      helpText: i18n.t('widget.settings.openFirstDocument.help'),
      type: FieldType.Boolean,
      defaultValue: false,
    },
    {
      id: 'uniformQualityIndicator',
      label: i18n.t('widget.settings.uniformQualityIndicator.label'),
      helpText: i18n.t('widget.settings.uniformQualityIndicator.help'),
      type: FieldType.Boolean,
      defaultValue: false,
    },
    {
      id: 'showGroupRemark',
      label: i18n.t('widget.settings.showGroupRemark.label'),
      helpText: i18n.t('widget.settings.showGroupRemark.help'),
      type: FieldType.Boolean,
      defaultValue: false,
    },
    {
      id: 'showBatchSelector',
      label: i18n.t('widget.settings.showBatchSelector.label'),
      helpText: i18n.t('widget.settings.showBatchSelector.help'),
      type: FieldType.Boolean,
      defaultValue: false,
    },
    {
      id: 'showDateAdjuster',
      label: i18n.t('widget.settings.showDateAdjuster.label'),
      helpText: i18n.t('widget.settings.showDateAdjuster.help'),
      type: FieldType.Boolean,
      defaultValue: false,
    },
    {
      id: 'minRemarkLength',
      label: i18n.t('widget.settings.minRemarkLength.label'),
      helpText: i18n.t('widget.settings.minRemarkLength.help'),
      type: FieldType.Number,
      validations: [ValidationRules.required(), ValidationRules.minNumber(0)],
      defaultValue: 0,
    },
    {
      id: 'showSilentInspections',
      label: i18n.t('widget.settings.showSilentInspections.label'),
      help: i18n.t('widget.settings.showSilentInspections.help'),
      type: FieldType.Boolean,
      defaultValue: false,
    },
    {
      id: 'widgetProperties',
      type: FieldType.PropertiesSelectDisplayField,
      modelOptions: InspectionTaskPending.allDisplayableProperties(this.rootStore),
      defaultValue: [
        'dueDate',
        'inspectionTask.name',
      ],
    },
  ];

  modalSettings = [
    {
      id: 'modalProperties',
      type: FieldType.PropertiesSelectDisplayField,
      modelOptions: InspectionTaskPending.allDisplayableProperties(this.rootStore),
      defaultValue: [
        'dueDate',
        'inspectionTask.name',
      ],
    },
  ];

  modalInspectionLots = [
    {
      id: 'inspectionLotsLoadDaysPast',
      label: i18n.t('inspectionTaskPendingWidget.settings.loadDays.past'),
      type: FieldType.Number,
      unit: i18n.t('inspectionTaskPendingWidget.settings.loadDays.unit'),
      defaultValue: 7,
      validations: [ValidationRules.required(), ValidationRules.maxNumber(30)],
    },
    {
      id: 'inspectionLotsLoadDaysFuture',
      label: i18n.t('inspectionTaskPendingWidget.settings.loadDays.future'),
      type: FieldType.Number,
      unit: i18n.t('inspectionTaskPendingWidget.settings.loadDays.unit'),
      defaultValue: 7,
      validations: [ValidationRules.required(), ValidationRules.maxNumber(30)],
    },
  ];

  getWidgetSetting(identifier) {
    return this.getSettingValue('settings', identifier);
  }

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'title');
  }

  getWidgetTableColumns() {
    return this.getSelectedProperties('settings', 'widgetProperties');
  }

  getOpenFirstDocument() {
    return this.getSettingValue('settings', 'openFirstDocument');
  }

  getModalTableColumns() {
    return this.getSelectedProperties('modalSettings', 'modalProperties');
  }

  getInspectionLotsLoadDaysPast() {
    return this.getSettingValue('modalInspectionLots', 'inspectionLotsLoadDaysPast');
  }

  getInspectionLotsLoadDaysFuture() {
    return this.getSettingValue('modalInspectionLots', 'inspectionLotsLoadDaysFuture');
  }
}
