import config from '../config';
import axios from './axios';
import {handleError} from './utils';

const resourcePath = (resource) => `${config.API_PATH}/${resource}`;

const uploadFile = (method) => (entity, file, path) => {
  const formData = new FormData();
  formData.append(file.paramName, file.instance);
  Object.entries(entity).forEach(([key, value]) => {
    if (value !== undefined) {
      if (value && Array.isArray(value)) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    }
  });
  return axios({
    method,
    url: resourcePath(path),
    data: formData,
    headers: {'Content-Type': 'multipart/form-data'},
  })
    .then((response) => response)
    .catch(handleError);
};

export const executeGet = (path, params = {}) => axios.get(resourcePath(path), params)
  .then((response) => response)
  .catch(handleError);

export const executePatch = (path, params = {}) => axios.patch(resourcePath(path), params)
  .then((response) => response)
  .catch(handleError);

export const executePut = (path, params = {}) => axios.put(resourcePath(path), params)
  .then((response) => response)
  .catch(handleError);

export const executePost = (path, data = {}) => axios.post(resourcePath(path), data)
  .then((response) => response)
  .catch(handleError);

export const executePostMultipart = (path, data = {}) => axios.post(resourcePath(path), data, {
  headers: {'Content-Type': 'multipart/form-data'},
})
  .then((response) => response)
  .catch(handleError);

export const createEntityWithFile = uploadFile('post');

export const createEntity = (entity, path) => axios.post(resourcePath(path), entity)
  .then((response) => response)
  .catch(handleError);

export const updateEntity = (entity, path) => axios.put(resourcePath(path), entity)
  .then((response) => response)
  .catch(handleError);

export const updateEntityWithFile = uploadFile('put');

export const executeDelete = (path, params = {}) => axios.delete(resourcePath(path), params)
  .then((response) => response)
  .catch(handleError);
