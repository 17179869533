import { action, makeObservable, observable } from 'mobx';
import dayjs from 'dayjs';
import { AxiosResponse } from 'axios';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { InspectionTask, InspectionTaskQualitative, InspectionTaskQuantitativeBase } from '../models/inspectionTask';
import {
  updateQualitativeTargetValuesEndpoint,
  updateQuantitativeTargetValuesEndpoint,
} from '../middleware/endpoints/inspectionTask';
import { sortAlphabetically } from '../components/shared/tables/sorters';
import { InspectionTaskPending } from '../models/inspectionTaskPending';
import i18n from '../i18n/i18n';
import { RootStore } from './rootStore';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';

interface QuantitativePatchRequest extends Omit<InspectionTaskQuantitativeBase,
  'inspectionTaskId' | 'inspectionTask' | 'unit' | 'unitText' | 'decimalPlaces'> {
  id: number;
  editedRemark: string;
  decimalPlaces?: number | null;
}

export interface QualitativePatchRequest extends
  Omit<InspectionTaskQualitative, 'catalogFeatures' | 'type' | 'plant' | 'name' | 'targetValue' | 'targetValueOld'> {
  id: number;
  editedRemark: string;
  targetValue: string;
}

export type InspectionTaskSearchProps = {
  params: {
    q: string, isTemplateTask?: boolean, size?: number
  }
};

export class InspectionTaskStore extends EntityStore<InspectionTask> {
  inspectionTasks: InspectionTask[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'inspectionTasks', Api.inspectionTask, InspectionTask, true);

    makeObservable(this, {
      inspectionTasks: observable,
      updateQuantitativeTargetValues: action,
      updateQualitativeTargetValues: action,
      updateTargetValues: action,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.inspectionLotStore,
        modelId: 'inspectionLotId',
      },
      {
        store: this.rootStore.inspectionTaskGroupStore,
        modelId: 'inspectionTaskGroupId',
      },
      {
        store: this.rootStore.phaseStore,
        modelId: 'phaseIds',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'createdBy',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'updatedBy',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'deactivatedBy',
      },
      {
        store: this.rootStore.customFunctionStore,
        modelId: 'quantitative.calculatedCustomFunctionId',
      },
    ];
  }

  async deactivate(id: number, remark: string) {
    await this.api.deactivate(id, remark)
      .then(() => {
        this.load(id);
        this.rootStore.inspectionTaskResultStore.loadAll({ params: { inspectionTaskId: id } });
        this.rootStore.inspectionTaskPendingStore.pendingInspectionTasks = this.rootStore
          .inspectionTaskPendingStore.pendingInspectionTasks.filter((pit) => pit.inspectionTaskId !== id);
        this.addMessage(
          new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.deactivateSuccess')),
          { skipNotification: false }
        );
      }).catch((e: Error) => this.handleApiError(e, 'deactivate'));
  }

  async activate(id: number) {
    await this.api.activate(id)
      .then(() => {
        this.load(id);
        this.rootStore.inspectionTaskPendingStore.loadAll({ params: { inspectionTaskId: id } });
        this.addMessage(
          new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.reactivateSuccess')),
          { skipNotification: false }
        );
      }).catch((e: Error) => this.handleApiError(e, 'activate'));
  }

  getSameGroup(
    inspectionTask: InspectionTask,
    inspectionTaskPending: InspectionTaskPending,
    phaseId: number | null = null
  ) {
    if (inspectionTask.inspectionTaskGroupId) {
      const isSameGroup = (task: InspectionTask) => {
        const sameGroup = task.inspectionTaskGroupId === inspectionTask.inspectionTaskGroupId;
        const sameWorkplace = task.workplaceId === inspectionTask.workplaceId;
        const samePhase = (!phaseId && task.phaseIds.length === 0) || (phaseId && task.phaseIds.includes(phaseId));
        return sameGroup && sameWorkplace && (inspectionTaskPending || samePhase);
      };

      if (inspectionTaskPending) {
        const pendingTasks = this.rootStore.inspectionTaskPendingStore.pendingInspectionTasks.filter(
          (pendingTask) => pendingTask.inspectionTask && isSameGroup(pendingTask.inspectionTask)
            && InspectionTaskPending.isSameCalculation(pendingTask, inspectionTaskPending)
        );

        return pendingTasks.sort((a, b) => {
          if (a.inspectionTask?.characteristicNo !== b.inspectionTask?.characteristicNo) {
            return sortAlphabetically(a.inspectionTask?.characteristicNo, b.inspectionTask?.characteristicNo);
          }
          return a.inspectionTaskId - b.inspectionTaskId;
        }).map((pendingTask) => ({
          key: `task_${pendingTask.inspectionTaskId}_${pendingTask.id}`,
          inspectionTask: pendingTask.inspectionTask,
          inspectionTaskPending: pendingTask,
        }));
      }

      return this.inspectionTasks.filter((current) => isSameGroup(current)).sort((a, b) => {
        if (a.characteristicNo !== b.characteristicNo) {
          return sortAlphabetically(a.characteristicNo, b.characteristicNo);
        }
        return a.id - b.id;
      }).map((task) => ({
        key: `task_${task.id}_0`,
        inspectionTask: task,
        inspectionTaskPending: null,
      }));
    }

    return [{
      key: `task_${inspectionTask.id}_${inspectionTaskPending?.id || '0'}`,
      inspectionTask,
      inspectionTaskPending,
    }];
  }

  getByInspectionLotId(inspectionLotId: number) {
    return this.inspectionTasks.filter((task) => task.inspectionLotId === inspectionLotId);
  }

  getByOrder(orderId: number) {
    return this.inspectionTasks.filter((task) => task.inspectionLot?.orderId === orderId);
  }

  getTemplates() {
    return this.inspectionTasks.filter((task) => task.isTemplateTask);
  }

  getValidByWorkplaceIdAndOrderId(workplaceId: number, orderId: number) {
    return this.inspectionTasks.filter((task) => {
      if (task.workplaceId !== workplaceId) {
        if (!task.showOnSiblingVirtualWorkplaces) {
          return false;
        }
        const siblingVirtualWorkplaces = this.rootStore.workplaceStore.getVirtualSiblingWorkplaces(workplaceId);

        if (task.workplaceId && !siblingVirtualWorkplaces.map((w) => w.id).includes(task.workplaceId)) {
          return false;
        }
      }
      if (orderId !== null) {
        return task.inspectionLot?.orderId === orderId;
      }
      if (task.inspectionLot?.orderId) {
        return false;
      }
      return task.inspectionLot ? dayjs().isBetween(
        dayjs(task.inspectionLot.validStart),
        dayjs(task.inspectionLot.validEnd),
        null,
        '[]'
      ) : false;
    });
  }

  async updateTargetValues({
    id,
    fn,
    target,
  }: {
    id: number;
    fn: Promise<AxiosResponse>;
    target: 'quantitative' | 'qualitative',
  }) {
    const entity = this.getById(id);

    return fn.then(({ data }: AxiosResponse) => {
      if (entity) {
        entity[target] = {
          ...entity[target],
          ...data,
        };
      }
    });
  }

  updateQuantitativeTargetValues(params: QuantitativePatchRequest) {
    return this.updateTargetValues({
      id: params.id,
      fn: Api.inspectionTask[updateQuantitativeTargetValuesEndpoint](params),
      target: 'quantitative',
    });
  }

  updateQualitativeTargetValues(params: QualitativePatchRequest) {
    return this.updateTargetValues({
      id: params.id,
      fn: Api.inspectionTask[updateQualitativeTargetValuesEndpoint](params),
      target: 'qualitative',
    });
  }

  async search({ params }: InspectionTaskSearchProps) {
    return this.api.search(params).then((response: AxiosResponse) => response.data);
  }
}
