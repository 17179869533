import { action, observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { CockpitDashboard } from '../models/cockpitDashboard';
import { arrayMove } from '../utils/helpers';
import i18n from '../i18n/i18n';
import { RootStore } from './rootStore';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';

export class CockpitDashboardStore extends EntityStore<CockpitDashboard> {
  cockpitDashboards: CockpitDashboard[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'cockpitDashboards', Api.cockpitDashboards, CockpitDashboard, true);

    makeObservable(this, {
      cockpitDashboards: observable,
      loadByCockpitId: action,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.cockpitStore,
        modelId: 'cockpitId',
      },
    ];
  }

  loadByCockpitId(cockpitId: number) {
    return this.loadAll({ params: { cockpitId } });
  }

  moveCockpitDashboard(fromIndex: number, toIndex: number, cockpitId: number) {
    if (fromIndex !== toIndex) {
      const boards = this.getByCockpitId(cockpitId);
      const dashboards = arrayMove(
        [...boards],
        fromIndex,
        toIndex
      );

      for (let i = Math.min(fromIndex, toIndex); i <= Math.max(fromIndex, toIndex); i += 1) {
        this.update({
          ...dashboards[i],
          sortOrder: i,
        }, { skipNotification: true });
      }
    }
  }

  getByCockpitId(id: number) {
    return this.cockpitDashboards.filter((dashboard) => dashboard.cockpitId === id)
      .sort((a, b) => a.sortOrder - b.sortOrder);
  }

  async clone(dashboardId: number, params: { targetCockpitId: number }) {
    const response = await this.api.clone(dashboardId, params);
    this.cockpitDashboards.push(CockpitDashboard.fromPlainObject(response.data, this.rootStore));
    await this.rootStore.cockpitWidgetStore.loadAll();
    this.rootStore.flashMessageStore.addFlashMessage(new FlashMessage(
      FlashMessageType.SUCCESS,
      i18n.t('flashMessages.createSuccess')
    ));
  }
}
