import { createEntity, executeDelete, executeGet, updateEntity } from '../requests';
import { InspectionEquipment } from '../../models/inspectionEquipment';

const resourcesName = 'v1/inspection-equipment';

export const InspectionEquipmentApi = {
  all: ({ ...params }) => executeGet(resourcesName, { params }),
  byId: (id: number) => executeGet(`${resourcesName}/${id}`),
  create: (entity: Partial<InspectionEquipment>) => createEntity(entity, resourcesName),
  update: ({ id, ...entity }: any) => updateEntity(entity, `${resourcesName}/${id}`),
  destroy: (id: number) => executeDelete(`${resourcesName}/${id}`),
};
