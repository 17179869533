import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {Col, Row, Space} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileCsv, faFilePdf} from '@fortawesome/free-solid-svg-icons';
import {faDownload} from '@fortawesome/pro-solid-svg-icons';
import Button from '../shared/buttons/Button';
import Dropdown from '../shared/buttons/Dropdown';

const exportMethod = Object.freeze({
  exportToCsv: 'exportToCsv',
  exportToCsvWithConsumptions: 'exportToCsvWithConsumptions',
  exportAsBatchReport: 'exportAsBatchReport',
});

const BatchLogTableAction = ({disabled, onExport}) => {
  const {t} = useTranslation();

  const iconStyle = { marginRight: '5px' };

  const items = [
    {
      label: t('batchLog.actions.exportToCsv'),
      key: exportMethod.exportToCsv,
      icon: <FontAwesomeIcon icon={faFileCsv} style={iconStyle}/>,
    }, {
      label: t('batchLog.actions.exportToCsvWithConsumptions'),
      key: exportMethod.exportToCsvWithConsumptions,
      icon: <FontAwesomeIcon icon={faFileCsv} style={iconStyle}/>,
    }, {
      label: t('batchLog.actions.exportAsBatchReport'),
      key: exportMethod.exportAsBatchReport,
      icon: <FontAwesomeIcon icon={faFilePdf} style={iconStyle}/>,
    },
  ];

  return (
    <Row>
      <Col style={{width: '100%', textAlign: 'right'}}>
        <Dropdown
          menu={{items, onClick: onExport}}
          placement="bottomRight"
          trigger={['click']}
        >
          <Button
            type={'link'}
            onClick={(e) => e.preventDefault()}
            disabled={disabled}
          >
            <Space>
              <FontAwesomeIcon icon={faDownload}/>
              {t('batchLog.actions.export')}
            </Space>
          </Button>
        </Dropdown>
      </Col>
    </Row>
  );
};

export default observer(BatchLogTableAction);
export {exportMethod};
