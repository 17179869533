import {useTranslation} from 'react-i18next';
import {Space} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {useState} from 'react';
import Form from '../../shared/form/Form';
import Table from '../../shared/tables/Table';
import DeleteButton from '../../shared/buttons/DeleteButton';
import {ValidationRules} from '../../../utils/validationRules';
import Button from '../../shared/buttons/Button';
import Input from '../../shared/inputs/Input';

const StorageUnitLevelsFormPartial = ({
  form,
  removedLevels,
  setRemovedLevels,
  selectedUnitOfMeasureId,
  unitOfMeasurements,
}) => {
  const {t} = useTranslation();

  const [newValue, setNewValue] = useState({stop: '', value: null});

  const onNewValueChange = (e) => {
    setNewValue({...newValue, [e.target.name]: e.target.value});
  };

  const isStopUnique = (name, key) => {
    const existingLevels = form.getFieldValue(name);
    const hits = existingLevels.filter(
      (level) => Number(level.stop) === Number(key)
    );
    return hits.length <= 1;
  };

  const unitOfMeasurementLabel = `${unitOfMeasurements.find((uom) => uom.id === selectedUnitOfMeasureId)?.label || ''}`;

  return (
    <Form.List name={'levels'}>
      {(formFields, {add, remove}) => (
        <Form.Item wrapperCol={{sm: 24}}>
          <Table
            id={'headerTable'}
            size={'small'}
            pagination={false}
            dataSource={formFields}
            rowKey={'key'}
            columns={[
              {
                title: t('storageUnitLevel.model.attributes.stop'),
                render: (row, field) => (
                  <Form.Item
                    {...field}
                    wrapperCol={{sm: 24}}
                    name={[field.name, 'stop']}
                    validateTrigger={'onBlur'}
                    rules={[
                      ValidationRules.required,
                      ValidationRules.number,
                      ValidationRules.greaterThanOrEqualTo(0),
                      ValidationRules.lessThanOrEqualTo(100),
                      {
                        validator: (rule, value) => {
                          if (!isStopUnique('levels', value)) {
                            return Promise.reject(new Error(t('storageUnitLevel.errors.messages.uniqueStops')));
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                    style={{marginBottom: 0}}
                  >
                    <Input type={'number'}/>
                  </Form.Item>
                ),
                width: '45%',
              },
              {
                title: `${t('storageUnitLevel.model.attributes.value')}${unitOfMeasurementLabel
                  ? ` [${unitOfMeasurementLabel}]` : ''}`,
                render: (field) => (
                  <Form.Item
                    {...field}
                    wrapperCol={{sm: 24}}
                    name={[field.name, 'value']}
                    validateTrigger={'onBlur'}
                    rules={[ValidationRules.required, ValidationRules.number]}
                    style={{marginBottom: 0}}
                  >
                    <Input type={'number'}/>
                  </Form.Item>
                ),
                width: '45%',
              },
              {
                render: (field) => (
                  <DeleteButton
                    onConfirm={() => {
                      const existingLevels = form.getFieldValue('levels');
                      remove(field.name);
                      const [removedLevel] = existingLevels.filter(
                        (level) => !form.getFieldValue('levels').includes(level)
                      );
                      if (removedLevel.id) {
                        setRemovedLevels([...removedLevels, removedLevel]);
                      }
                    }}
                  />
                ),
              },
            ]}
            footer={() => (
              <Space size={'middle'}>
                <Input
                  name="stop"
                  placeholder={t('storageUnitLevel.model.attributes.stop')}
                  value={newValue.stop}
                  type={'number'}
                  onChange={onNewValueChange}
                  style={{minWidth: 196}}
                />
                <Input
                  name="value"
                  placeholder={`${t('storageUnitLevel.model.attributes.value')}${unitOfMeasurementLabel
                    ? ` [${unitOfMeasurementLabel}]` : ''}`}
                  value={newValue.value}
                  type={'number'}
                  onChange={onNewValueChange}
                  style={{minWidth: 190}}
                />
                <Button
                  disabled={!newValue.stop || !newValue.value}
                  onClick={() => {
                    newValue.stop = newValue.stop?.trim();
                    newValue.value = newValue.value?.trim();
                    add(newValue);
                    setNewValue({stop: '', value: null});
                  }}
                >
                  <FontAwesomeIcon icon={faPlus}/>
                </Button>
              </Space>
            )}
          />
        </Form.Item>
      )}
    </Form.List>
  );
};

export default StorageUnitLevelsFormPartial;
