import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import {useState} from 'react';
import {isNil} from 'lodash';
import {fullDateTimeFormatter, timeFormatter} from './highchartHelpers/formatter';
import {getSymbolByNameAsHtml} from './highchartHelpers/label';
import Spinner from '../spinners/Spinner';
import Empty from '../empty/Empty';
import {useMount} from '../../../hooks/useMount';

HighchartsMore(Highcharts);

const LegendPositionOptions = Object.freeze({
  bottom: {},
  right: {
    layout: 'vertical',
    align: 'right',
  },
  none: {
    enabled: false,
  },
});

const Chart = ({
  options,
  isTimeseries,
  is24HourSeries,
  dataLabelsEnabled,
  isLoading,
  showIsEmpty,
  legendPosition,
  ...props
}) => {
  const [repaint, setRepaint] = useState(0);

  useMount(() => {
    setRepaint(repaint + 1);
  }, [isLoading]);

  let timeseriesXLabels = {};
  if (isTimeseries) {
    timeseriesXLabels = {
      tickPixelInterval: 150,
      labels: {
        useHtml: true,
        formatter() {
          const {chart, value} = this;
          const timespan = {from: chart.xAxis[0].min, to: chart.xAxis[0].max};
          return fullDateTimeFormatter(value, timespan, true);
        },
      },
    };
  }

  if (is24HourSeries) {
    timeseriesXLabels = {
      tickPixelInterval: 60,
      labels: {
        useHtml: true,
        formatter() {
          const {value} = this;
          return timeFormatter(value);
        },
      },
    };
  }

  const timeseriesTooltip = isTimeseries ? {
    useHtml: true,
    formatter() {
      const {x, y, series, point} = this;
      if (point.tooltip === false) {
        return false;
      }
      const seriesName = series.linkedParent ? series.linkedParent.name : series.name;
      const seriesSymbol = series.linkedParent ? series.linkedParent.symbol : series.symbol;
      const timespan = {from: series.xAxis.min, to: series.xAxis.max};
      return `${fullDateTimeFormatter(x, timespan)}<br/>`
        + `${getSymbolByNameAsHtml(seriesSymbol, point.color)} ${seriesName}: `
        + `<span style="font-family:'Roboto Mono', monospace">
            ${(!isNil(options.decimalPlaces)) ? y.toFixed(options.decimalPlaces) : y}
          </span>`;
    },
  } : {};

  const markers = dataLabelsEnabled ? {
    dataLabels: {
      shape: 'callout',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      style: {
        color: '#FFFFFF',
        textOutline: 'none',
      },
    },
  } : {};

  const labelsFormatter = {
    labels: {
      formatter: (tick) => (!isNil(options.decimalPlaces)
        ? tick.value.toFixed(options.decimalPlaces) : tick.value),
    },
  };

  const yAxis = Array.isArray(options.yAxis)
    ? options.yAxis.map((axis) => ({
      ...labelsFormatter,
      ...axis,
    })) : {
      ...labelsFormatter,
      gridLineColor: '#e2e2e2',
      ...options.yAxis,
    };

  const xAxis = Array.isArray(options.xAxis)
    ? options.xAxis
    : {
      ...timeseriesXLabels,
      ...options.xAxis,
    };

  const chartOptions = {
    ...options,
    chart: {
      style: {
        fontFamily: 'Roboto, sans-serif',
      },
      type: options.chart?.type || 'line',
      plotBackgroundColor: '#f5f5f5',
      resetZoomButton: {
        position: {
          align: 'right',
          verticalAlign: 'top',
          x: -20,
          y: 20,
        },
        relativeTo: 'chart',
      },
      spacing: [3, 3, 5, 3],
      ...options.chart,
    },
    plotOptions: {
      ...options.plotOptions,
      series: {
        ...markers,
        ...options.plotOptions?.series,
      },
    },
    legend: {
      itemStyle: {
        textOverflow: 'wrap',
        width: 100,
      },
      ...LegendPositionOptions[legendPosition || 'bottom'],
      ...options.legend,
    },
    tooltip: {
      ...timeseriesTooltip,
      ...options.tooltip,
    },
    yAxis,
    xAxis,
    credits: {
      enabled: false,
      ...options.credits,
    },
  };

  return (
    <>
      {(isLoading || showIsEmpty) && (
        <div
          style={{
            display: 'flex',
            height: options.chart?.height || 400,

            alignItems: 'end',
            justifyContent: 'end',
          }}
        >
          {isLoading && <Spinner fullWidth/>}
          {!isLoading && showIsEmpty && <Empty style={{margin: 'auto'}}/>}
        </div>
      )}
      {!isLoading && !showIsEmpty && (
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          {...props}
        />
      )}
    </>
  );
};

export default Chart;
