import {useState} from 'react';
import {Space, Tabs} from 'antd';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import {faPauseCircle, faTasks} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {get} from 'lodash';
import Modal from '../shared/modal/Modal';
import {useStore} from '../../hooks/useStore';
import InspectionTaskPendingTable from './InspectionTaskPendingTable';
import InspectionTaskOverviewByWorkplace from './overview/InspectionTaskOverviewByWorkplace';
import InspectionLotOverview from './overview/InspectionLotOverview';
import Spinner from '../shared/spinners/Spinner';
import {getIcons} from './getIcons';
import styles from './InspectionTaskPendingWidget.module.scss';
import {formatDuration} from '../../utils/formatting';
import {detailedDurationFormat} from '../../config/dayjs';
import {InspectionTaskPending} from '../../models/inspectionTaskPending';
import {sortAlphabetically} from '../shared/tables/sorters';
import {EnDash} from '../shared/unicodeWrapper/EnDash';

const InspectionTaskPendingWidgetModal = ({selectPendingTask, workplaceId, widgetConfig, ...props}) => {
  const {t} = useTranslation();
  const store = useStore();
  const roleIds = store.authStore.user?.roleIds;
  const [closable, setClosable] = useState(true);

  const configColumns = widgetConfig.getModalTableColumns().map((column) => {
    if (column.key === 'dueDate') {
      return {
        ...column,
        defaultSortOrder: 'ascend',
        sorter: {
          compare: InspectionTaskPending.sort,
          multiple: 100,
        },
        render: (value, pendingTask) => {
          if (pendingTask.pausedSince) {
            return (
              <Space>
                <FontAwesomeIcon icon={faPauseCircle}/>
                {t('inspectionTaskPendingWidget.paused')}
              </Space>
            );
          }
          if (!pendingTask.dueDate) {
            return EnDash();
          }
          const duration = dayjs.duration(dayjs(pendingTask.dueDate).diff(dayjs()));
          const dueDateIsBeforeNow = dayjs(pendingTask.dueDate).isBefore(dayjs());
          return (
            <span className={dueDateIsBeforeNow ? styles.redText : null}>
              {formatDuration(duration, detailedDurationFormat)}
            </span>
          );
        },
      };
    }
    return {
      ...column,
      sorter: {
        compare: (a, b) => sortAlphabetically(get(a, column.key), get(b, column.key)),
        multiple: 1,
      },
    };
  });

  const columns = [
    {
      title: '',
      key: 'icons',
      render: (value, pendingTask) => {
        let taskIcon;
        if (pendingTask.inspectionLot === undefined) {
          taskIcon = <Spinner size={'small'}/>;
        } else {
          taskIcon = getIcons(pendingTask, roleIds)
            .map(({icon, style}) => <FontAwesomeIcon key={icon.iconName} icon={icon} size="1x" style={style}/>);
        }
        return (
          <Space>
            {taskIcon}
          </Space>
        );
      },
    },
    ...configColumns,
  ];

  return (
    <Modal
      fullscreen={store.clientStore.isMobile}
      width="90%"
      bodyStyle={{minHeight: '70vh'}}
      title={(
        <>
          <FontAwesomeIcon icon={faTasks}/>
          {' '}
          {t('inspectionTask.detailModal.title')}
        </>
      )}
      open
      footer={false}
      maskClosable={closable}
      {...props}
    >
      <Tabs
        items={[
          {
            key: 'pending',
            label: t('inspectionTask.detailModal.tab.pendingTasks'),
            children: (
              <InspectionTaskPendingTable
                onRowClick={selectPendingTask}
                filterSilent
                loading={store.inspectionTaskPendingStore.isLoadingCollection}
                columns={columns}
                dataSource={store.inspectionTaskPendingStore.getGroupedByWorkplaceId(workplaceId)}
              />
            ),
          },
          {
            key: 'overview',
            label: t('inspectionTask.detailModal.tab.inspectionOverview'),
            children: (
              <InspectionTaskOverviewByWorkplace workplaceId={workplaceId} selectPendingTask={selectPendingTask}/>
            ),
          },
          {
            key: 'inspectionLotOverview',
            label: t('inspectionTask.detailModal.tab.inspectionLotOverview'),
            children: (
              <InspectionLotOverview
                start={dayjs().subtract(widgetConfig.getInspectionLotsLoadDaysPast(), 'd')}
                end={dayjs().add(widgetConfig.getInspectionLotsLoadDaysFuture(), 'd')}
                setModalClosable={setClosable}
              />
            ),
          },
        ]}
      />
    </Modal>
  );
};

export default observer(InspectionTaskPendingWidgetModal);
