import { observable, makeObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { Printer } from '../models/printer';
import { RootStore } from './rootStore';
import { printEndpoint } from '../middleware/endpoints/printers';
import i18n from '../i18n/i18n';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';
import { PrintJobLog } from '../models/printJobLog';

export class PrinterStore extends EntityStore<Printer> {
  printers: Printer[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'printers', Api.printers, Printer, true);

    makeObservable(this, {
      printers: observable,
    });
  }
  getDependencies() {
    return [
      {
        store: this.rootStore.customFunctionStore,
        modelId: 'customFunctionId',
      },
    ];
  }

  print(printerId: number, printData: any) {
    this.addPendingAction(printEndpoint);
    return this.api.print(printerId, printData).then(({ data }: AxiosResponse) => {
      this.removePendingAction(printEndpoint);
      if (data?.error?.length > 0) {
        this.addMessage(new FlashMessage(FlashMessageType.ERROR, i18n.t('printJobLog.error')), {});
        return undefined;
      }
      if (data.state === 'FAILURE') {
        this.addMessage(new FlashMessage(FlashMessageType.WARNING, i18n.t('printJobLog.warning')), {});
      } else {
        this.addMessage(new FlashMessage(FlashMessageType.SUCCESS, i18n.t('printJobLog.success')), {});
      }
      return this.rootStore.printJobLogStore.add(PrintJobLog.fromPlainObject(this.rootStore, data));
    });
  }
}
