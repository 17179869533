import { action, computed, makeObservable, observable } from 'mobx';
import { Api } from '../middleware/api';
import { TerminalLayout } from '../models/terminalLayout';
import { Actions, EntityStore, LoadOptions } from './entityStore';
import { WidgetLayout } from '../models/widgetLayout';
import { BadgedWidgets, WidgetRegistry } from '../config/widgetRegistry';
import i18n from '../i18n/i18n';
import { RootStore } from './rootStore';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';

export class TerminalLayoutStore extends EntityStore<TerminalLayout> {
  terminalLayouts: TerminalLayout[] = [];
  isEditMode = false;
  currentTerminalLayout?: TerminalLayout = undefined;
  maxMinimizedOrder = 0;
  layout: TerminalLayout['widgetLayouts'] = [];
  badgeCounters = BadgedWidgets.reduce((counters, w) => {
    counters.set(w.identifier, 0);
    return counters;
  }, new Map<string, number>());

  constructor(rootStore: RootStore) {
    super(rootStore, 'terminalLayouts', Api.terminalLayouts, TerminalLayout);

    makeObservable(this, {
      terminalLayouts: observable,
      isEditMode: observable,
      currentTerminalLayout: observable,
      layout: observable,
      badgeCounters: observable,
      toggleEditMode: action,
      toggleDisabled: action,
      toggleMinimized: action,
      saveLayout: action,
      setBadgeCounter: action,
      defaultTerminalLayout: computed,
      setTerminalLayout: action,
      setLayout: action,
      setMaxMinimizedOrder: action,
    });
  }

  loadAll(paramOptions = {}) {
    return super.loadAll(paramOptions);
  }

  create(entity: TerminalLayout, options: LoadOptions = {}) {
    return super.create(entity, options).then((created) => {
      if (entity.isDefault) {
        this.loadAll();
      }
      return created;
    }).catch((e: Error) => this.handleApiError(e, options?.action || Actions.create));
  }

  update(entity: Partial<TerminalLayout>, options: LoadOptions = {}) {
    return super.update(entity, options).then(() => {
      if (entity.isDefault) {
        this.loadAll();
      }
    }).catch((e: Error) => this.handleApiError(e, options?.action || Actions.update));
  }

  async cloneTerminalLayout(params: Pick<TerminalLayout, 'id' | 'name' | 'scale'>) {
    const response = await this.api.clone(params);
    const entity = this.createModelInstance(response.data);
    this.terminalLayouts.push(entity);
    this.rootStore.flashMessageStore.addFlashMessage(new FlashMessage(
      FlashMessageType.SUCCESS,
      i18n.t<string>('flashMessages.createSuccess')
    ));
  }

  createModelInstance(json: any) {
    json.widgetLayouts = json.widgetLayouts
      ? JSON.parse(json.widgetLayouts)
        .map((widgetLayout: any) => new WidgetLayout(widgetLayout))
      : [];
    return super.createModelInstance(json);
  }

  setTerminalLayout(terminalLayout: TerminalLayout) {
    this.currentTerminalLayout = terminalLayout;

    const layout = WidgetRegistry.map((registryWidget) => {
      const widget = terminalLayout.widgetLayouts.find(
        (layoutWidget: any) => layoutWidget.identifier === registryWidget.identifier
      );

      return {
        i: registryWidget.identifier,
        x: widget === undefined ? registryWidget.defaultLayout.x : widget.x,
        y: widget === undefined ? registryWidget.defaultLayout.y : widget.y,
        h: widget === undefined ? registryWidget.defaultLayout.height : widget.height,
        w: widget === undefined ? registryWidget.defaultLayout.width : widget.width,
        minH: registryWidget.defaultLayout.minHeight,
        minW: registryWidget.defaultLayout.minWidth,
        disabled: widget === undefined ? true : (widget.disabled || false),
        minimized: widget === undefined ? false : (widget.minimized || false),
        minimizedOrder: widget === undefined ? 0 : (widget.minimizedOrder || 0),
      };
    });

    this.setLayout(layout);

    const minimizedWidgets = this.layout.filter((l) => l.minimized);
    this.setMaxMinimizedOrder(Math.max(...minimizedWidgets.map((l) => l.minimizedOrder)));
  }

  setLayout(layout: any[]) {
    this.layout = layout;
  }

  setMaxMinimizedOrder(max: number) {
    this.maxMinimizedOrder = max;
  }

  toggleEditMode() {
    this.isEditMode = !this.isEditMode;
  }

  toggleDisabled(disabled: boolean, identifier: string) {
    const widgetLayoutIndex = this.layout.findIndex((widget) => widget.i === identifier);
    this.layout.splice(widgetLayoutIndex, 1, {
      ...this.layout[widgetLayoutIndex],
      disabled,
    });
  }

  toggleMinimized(minimized: boolean, identifier: string) {
    const widgetLayoutIndex = this.layout.findIndex((widget) => widget.i === identifier);
    this.layout.splice(widgetLayoutIndex, 1, {
      ...this.layout[widgetLayoutIndex],
      minimized,
      /* eslint-disable-next-line no-plusplus */
      minimizedOrder: minimized ? ++this.maxMinimizedOrder : 0,
    });
  }

  saveLayout() {
    if (!this.currentTerminalLayout) {
      return;
    }
    const terminalLayout = this.currentTerminalLayout.getPlainSaveableObject();

    terminalLayout.widgetLayouts = this.layout
      .map((widget) => ({
        identifier: widget.i,
        x: widget.x,
        y: widget.y,
        height: widget.h,
        width: widget.w,
        disabled: widget.disabled,
        minimized: widget.minimized,
        minimizedOrder: widget.minimizedOrder,
      }));

    if (terminalLayout) {
      this.update(terminalLayout)
        .then(() => {
          this.setTerminalLayout(TerminalLayout.fromPlainObject(terminalLayout, this.rootStore));
          this.isEditMode = false;
        });
    } else {
      this.isEditMode = false;
    }
  }

  setBadgeCounter(key: string, value: number) {
    if (this.badgeCounters.get(key) !== value) {
      this.badgeCounters.set(key, value);
    }
  }

  get defaultTerminalLayout() {
    return this.terminalLayouts.find((terminalLayout) => terminalLayout.isDefault);
  }
}
