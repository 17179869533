import { observable, makeObservable } from 'mobx';
import { EntityStore, LoadOptions, LoadStrategies } from './entityStore';
import { Api } from '../middleware/api';
import { DataExportScheduler } from '../models/dataExportScheduler';
import { RootStore } from './rootStore';

export class DataExportSchedulerStore extends EntityStore<DataExportScheduler> {
  dataExportSchedulers = [];

  loadAll(paramOptions: LoadOptions = {}) {
    const defaultParams = { strategy: LoadStrategies.add };
    const mergeParamOptions = Object.assign(defaultParams, paramOptions);
    return super.loadAll(mergeParamOptions);
  }

  constructor(rootStore: RootStore) {
    super(rootStore, 'dataExportSchedulers', Api.dataExportSchedulers, DataExportScheduler);

    makeObservable(this, {
      dataExportSchedulers: observable,
    });
  }
}
