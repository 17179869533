import React from 'react';
import { Switch as AntdSwitch, SwitchProps as AntdSwitchProps } from 'antd';

export type SwitchProps = AntdSwitchProps & {
  initialValue?: boolean;
};

const Switch: React.FC<SwitchProps> = ({
  initialValue,
  ...props
}) => <AntdSwitch checked={initialValue} {...props}/>;

export default Switch;
