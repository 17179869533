/** 2D barcode symbol creation by javascript
 * @author alois zingl
 * @version V2.2 June 2021
 * @license MIT copyright: open-source software
 * @link https://zingl.github.io/
 * @description the indention of this library is a short and easy implementation to create the 2D barcodes
 *	of Data Matrix, QR, Aztec or PDF417 symbols so it could be easily adapted for individual requirements.
 *	ALL return the smallest possible barcode fitting the data as array matrix
 *	which could be converted to SVG path, html/css, canvas or GIF image.
 * functions:
 *	code128(text)                       create Code 128 barcode
 *	toPath(mat)                         convert array matrix to SVG path
 *	toGif(mat,scale,trans,pad,rgb)      convert array matrix to GIF image
 *	toHtml(mat,size,blocks)             convert array matrix to html/css
 * The functions have no dependency between, just copy the ones you need.
 *	'Small is beautiful' - Leopold Kohr.
 */
"use strict";

/** Code 128 symbol creation according ISO/IEC 15417:2007
 * @param text to encode
 * @return array of code128 barcode
 */
function code128(text) {
  var t = 3, enc = [], i, j, c, mat = [];
  for (i = 0; i < text.length; i++) {
    c = text.charCodeAt(i);
    if (t != 2) { // alpha mode
      for (j = 0; j+i < text.length; j++) // count digits
        if (text.charCodeAt(i+j)-48>>>0 > 9) break; // digit ?
      if ((j > 1 && i == 0) || (j > 3 && (i+j < text.length || (j&1) == 0))) {
        enc.push(i == 0 ? 105 : 99); // Start / Code C
        t = 2; // to digit
      }
    }
    if (t == 2) // digit mode
      if (c-48>>>0 < 10 && i+1 < text.length && text.charCodeAt(i+1)-48>>>0 < 10)
        enc.push(+text.substr(i++,2)); // 2 digits
      else t = 3; // exit digit
    if (t != 2) { // alpha mode
      if (t > 2 || ((c&127) < 32 && t) || ((c&127) > 95 && !t)) { // change ?
        for (j = t > 2 ? i : i+1; j < text.length; j++) // A or B needed?
          if ((text.charCodeAt(j)-32)&64) break; // < 32 or > 95
        j = j == text.length || (text.charCodeAt(j)&96) ? 1 : 0;
        enc.push(i == 0 ? 103+j : j != t ? 101-j : 98); // start:code:shift
        t = j; // change mode
      }
      if (c > 127) enc.push(101-t); // FNC4: code chars > 127
      enc.push(((c&127)+64)%96);
    }
  }
  if (i == 0) enc.push(103); // empty message
  j = enc[0]; // check digit
  for (i = 1; i < enc.length; i++) j += i*enc[i];
  enc.push(j%103); enc.push(106); // stop

  c = [358,310,307,76,70,38,100,98,50,292,290,274,206,110,103,230,118,115,313,302,295,370,314,439,422,406,403,
    434,410,409,364,355,283,140,44,35,196,52,49,324,276,273,220,199,55,236,227,59,443,327,279,372,369,375,
    428,419,395,436,433,397,445,289,453,152,134,88,67,22,19,200,194,104,97,26,25,265,296,477,266,61,158,94,
    79,242,122,121,466,458,457,367,379,475,188,143,47,244,241,468,465,239,247,431,471,322,328,334,285];
  for (t = i = 0; i < enc.length; i++, t++) { // code to pattern
    mat[t++] = 1;
    for (j = 256; j > 0; j >>= 1, t++)
      if (c[enc[i]]&j) mat[t] = 1;
  }
  mat[t++] = mat[t] = 1;
  return mat;
}

/** convert a black&white image matrix to SVG/canvas path
 * @param mat: 0/1 image matrix array, will be destroyed
 * @return string: d attribut of svg path
 */
function toPath(mat) {
  var  path = "", x, y;
  mat.forEach(function (y) { y.unshift(0); }); // add padding around matrix
  mat.push([]); mat.unshift([]);
  for (;;) {		// draw polygons
    for (y = 0; y+2 < mat.length; y++) // look for set pixel
      if ((x = mat[y+1].indexOf(1)-1) >= 0 || (x = mat[y+1].indexOf(5)-1) >= 0) break;
    if (y+2 == mat.length || path.length > 1e7) return path;
    var c = mat[y+1][x+1]>>2, p = ""; // from start
    for (var x0 = x, y0 = y, d = 1; p.length < 1e6;) { // encircle pixel area
      do x += 2*d-1; // move left/right
      while ((mat[y][x+d]^mat[y+1][x+d])&mat[y+d][x+d]&1); // follow horizontal edge
      d ^= mat[y+d][x+d]&1; // turn up/down
      do mat[d ? ++y : y--][x+1] ^= 2; // move and mark edge
      while ((mat[y+d][x]^mat[y+d][x+1])&mat[y+d][x+1-d]&1); // follow vertical edge
      if (x == x0 && y == y0) break; // returned to start
      d ^= 1^mat[y+d][x+1-d]&1; // turn left/right
      if (c) p = "V"+y+"H"+x+p; // add points counterclockwise
      else p += "H"+x+"V"+y; // add clockwise
    }
    path += "M"+x+" "+y+p+(c ? "V"+y : "H"+x)+"Z"; // close path
    for (d = 0, y = 1; y < mat.length-1; y++) // clear pixel between marked edges
      for (x = 1; x < mat[y].length; x++) {
        d ^= (mat[y][x]>>1)&1; // invert pixels inside, clr marking
        mat[y][x] = 5*d^mat[y][x]&5;
      }
  }
}

/** convert an image matrix to GIF base64 string <img src=".." />
 * @param mat image matrix array of index colors
 * @param scale optional (1): single bar or [width,height]
 * @param trans optional: transparent color index (undefined)
 * @param pad optional: padding arround image in cells (0)
 * @param rgb optional: table of rgb color map (black&white)
 * @param max optional: maximum dictionary bits (2-12 - but large dictionaries are slow in js)
 * @return string "data:image/gif;base64,imagedata"
 */
function toGif(mat, scale, trans, pad, rgb, max) {
  var eb = 0, ec = 0, ev = 0, i, c, dic = [], xl = 0; // encoding, pixel dictionary
  rgb = rgb||[[255,255,255],[0,0,0]]; // default colors black&white only
  if (!(scale instanceof Array)) scale = [scale||1,scale||1]; // default 1x
  for (i = mat.length; i--; xl = Math.max(xl,mat[i].length)); // get max width of matrix
  pad = pad||0; // padding around barcode
  function put(val, bits) { // raster data stream
    ev |= (val<<eb)&255;
    val >>= 8-eb;
    for (eb += bits; eb > 7; eb -= 8) { // output data stream
      enc += String.fromCharCode(ev);
      if (ec++ == 254) { enc += "\xff"; ec = 0; } // new data block
      ev = val&255;
      val >>= 8;
    }
  }
  for (var colorBits = 0, colors = 2; colors < rgb.length; colors += colors, colorBits++);
  var sx, sy, x = scale[0]*(xl+2*pad), y = scale[1]*(mat.length+2*pad);
  var enc = "GIF89a"+String.fromCharCode(x&255,x>>8, y&255,y>>8, 17*colorBits+128,0,0); // global descriptor
  for (i = 0; i < colors; i++) { // global color map
    c = rgb[i]||[i,i,i]; // default color
    enc += String.fromCharCode(c[0],c[1],c[2]);
    dic[i] = String.fromCharCode(i); // init dictionary
  }
  if (trans !== undefined)
    enc += String.fromCharCode(33,249,4,1,0,0,trans,0); // extension block transparent color
  if (colorBits++ == 0) { colorBits++; colors = 4; dic.push(""); dic.push(""); } // black&white image
  enc += ",\0\0\0\0"+enc.substr(6,4)+String.fromCharCode(0,colorBits,255); // local descriptor, raster data block
  dic.push(""); dic.push(""); // add clear, reset code
  var bits = colorBits+1, code, seq = "", p;
  put(colors,bits); // clear code
  for (y = -pad; y < pad+mat.length; y++) // LZW compression
    for (sy = 0; sy < scale[1]; sy++)
      for (x = -pad; x < pad+xl; x++)
        for (sx = 0; sx < scale[0]; sx++) {
          c = x < 0 || y < 0 || x >= xl || y >= mat.length ? 0 : mat[y][x]&(colors-1); // get pixel
          seq += p = String.fromCharCode(c);
          i = dic.indexOf(seq); // sequenze in dictionary?
          if (i < 0) { // no
            put(code,bits); // output code
            dic.push(seq); // add to dictionary
            seq = p; code = c; // new sequence
            if (dic.length-1 == 1<<bits) bits++; // increase bits
            if (bits == (max||5) && dic.length == 1<<bits) { // max 12 bits
              put(colors,bits); // clear code
              bits = colorBits+1; // reset
              dic.splice(colors+2,dic.length-colors-2); // reset dictionary
            }
          } else code = i; // append code
        }
  put(code,bits); // output remaining
  put(colors+1,bits+((24-eb-bits)&7)); // end of image
  enc = enc.substr(0,enc.length-ec-1)+String.fromCharCode(ec)+enc.substr(enc.length-ec); // length final raster data block
  return 'data:image/gif;base64,'+btoa(enc+"\0;"); // <img src=".." /> DOM gif image
}

/** convert a black&white image matrix to html/css
 * @param mat 0/1 image matrix array
 * @param size optional (3): single bar in pixel, or as [width,height]
 * @param blocks optional (7): # of bar/space classes
 * @return html/css of 2D barcode
 */
function toHtml(mat, size, blocks) {
  if (!Array.isArray(size)) size = [size||3,size||3];
  var s = "barcode"+size[0]+size[1], b; // style class
  var html = "<style> ."+s+" div {float:left; margin:0; height:"+size[1]+"px}";
  blocks = blocks||7;
  for (var i = 0; i < blocks; i++) // define bars/spaces
    for (var j = 0; j < blocks; j++)
      html += "."+s+" .bar"+i+j+" {border-left:"+i*size[0]+"px solid; margin-right:"+j*size[0]+"px}";
  html += "</style><div class="+s+" style='line-height:"+size[1]+"px; display:inline-block'>";

  for (i = 0; i < mat.length; i++) // convert matrix
    for (j = 0; j < mat[i].length; ) {
      if (i && !j) html += "<br style='clear:both' />";
      for (b = 0; j < mat[i].length; b++, j++) // bars
        if (!mat[i][j] || b+1 == blocks) break;
      for (s = 0; j < mat[i].length; s++, j++) // spaces
        if (mat[i][j] || s+1 == blocks) break;
      html += "<div class=bar"+b+s+"></div>";
    }
  return html+"</div>"; // html/css of 2D barcode
}

export {code128, toPath, toGif, toHtml};
