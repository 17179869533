import React from 'react';
import { observer } from 'mobx-react-lite';

const lightColor = '#8E8E8E';

export type SubTitleWithIconProps = {
  icon: React.ReactNode;
  text: React.ReactNode;
};

const SubTitleWithIcon: React.FC<SubTitleWithIconProps> = ({ icon, text }) => (
  <p style={{
    fontFamily: 'Roboto',
    fontWeight: '500',
    letterSpacing: 0.5,
    textTransform: 'uppercase',
    fontSize: '12px',
    marginBottom: '8px',
    color: lightColor,
  }}
  >
    {icon}
    {text}
  </p>
);

export default observer(SubTitleWithIcon);
