import {useState} from 'react';
import {Typography} from 'antd';
import dayjs from 'dayjs';

import styles from './Timer.module.css';
import {formatDuration} from '../../utils/formatting';
import {detailedDurationFormat} from '../../config/dayjs';
import {useMount} from '../../hooks/useMount';

const {Title} = Typography;

export const Timer = ({time}) => {
  const [calculatedTime, setCalculatedTime] = useState(dayjs.duration(dayjs().diff(dayjs(time))).asSeconds());

  /* eslint-disable consistent-return */
  useMount(() => {
    if (time) {
      const interval = setInterval(
        () => setCalculatedTime(dayjs.duration(dayjs().diff(dayjs(time))).asSeconds()),
        1000
      );
      return () => {
        clearInterval(interval);
      };
    }
  }, [time]);

  return (
    <Title type="danger" level={3} className={styles.timer}>
      {formatDuration(dayjs.duration(calculatedTime, 'seconds'), detailedDurationFormat)}
    </Title>
  );
};
