import {observer} from 'mobx-react-lite';
import {lazy, Suspense} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import ContextSwitch from './components/welcomePage/WelcomePage';
import ErrorBoundary from './components/shared/errors/ErrorBoundary';
import ScrollToTop from './components/shared/ScrollToTop';
import Spinner from './components/shared/spinners/Spinner';
import {Viewport} from './components/shared/Viewport';
import {Locale} from './components/shared/Locale';
import {mqtt} from './middleware/mqtt';
import './config/dayjs';
import './config/highcharts';
import 'react-grid-layout/css/styles.css';

// noinspection JSCheckFunctionSignatures
const ProductionTerminalRoutes = lazy(() => import('./components/operator/Router'));
// noinspection JSCheckFunctionSignatures
const OfficeRoutes = lazy(() => import('./components/office/Router'));
mqtt.connect();

const App = () => (
  <Router>
    <ErrorBoundary>
      <ScrollToTop/>
      <Viewport/>
      <Suspense fallback={<Spinner fullWidth/>}>
        <Locale>
          <Routes>
            <Route
              path="/"
              element={<ContextSwitch/>}
            />
            <Route
              path="/production/*"
              element={<ProductionTerminalRoutes/>}
            />
            <Route
              path="/operator/*"
              element={<ProductionTerminalRoutes/>}
            />
            <Route
              path="/office/*"
              element={<OfficeRoutes/>}
            />
          </Routes>
        </Locale>
      </Suspense>
    </ErrorBoundary>
  </Router>
);

export default observer(App);
