import {Form} from 'antd';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {ValidationRules} from '../../utils/validationRules';
import Input from '../shared/inputs/Input';
import HelpTooltipFormLabel from '../shared/tooltips/HelpTooltipFormLabel';
import {useStore} from '../../hooks/useStore';
import {PhaseTimeoutInputType} from '../../models/phaseTimeout';
import Select from '../shared/inputs/Select';

const PhaseTimeoutPartialForm = () => {
  const {t} = useTranslation();
  const store = useStore();
  const latestLog = store.operationStateLogStore.getLatestOfWorkplace(store.workplaceStore.selectedWorkplace?.id);
  const phaseTimeout = store.phaseTimeoutStore.getByPhaseIdAndWorkflowId(
    latestLog.state.phaseId,
    latestLog.operation.workflowId || latestLog.operation.order.workflowId
  );

  const reasons = store.phaseTimeoutReasonStore.getByHierarchyId(store.workplaceStore.selectedWorkplace?.hierarchyId);

  if (phaseTimeout?.inputType === PhaseTimeoutInputType.SELECT && reasons.length) {
    return (
      <Form.Item
        name={'phaseTimeoutReasonId'}
        rules={[ValidationRules.required()]}
        label={(
          <HelpTooltipFormLabel
            label={t('stateTransitionWidget.modal.form.timeoutMessage.label')}
            help={t('stateTransitionWidget.modal.form.timeoutMessage.help')}
          />
        )}
      >
        <Select>
          {reasons.map((reason) => (
            <Select.Option key={reason.id} value={reason.id}>
              {reason.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  }

  return (
    <Form.Item
      name={'timeoutMessage'}
      rules={[ValidationRules.required()]}
      label={(
        <HelpTooltipFormLabel
          label={t('stateTransitionWidget.modal.form.timeoutMessage.label')}
          help={t('stateTransitionWidget.modal.form.timeoutMessage.help')}
        />
      )}
    >
      <Input.TextArea/>
    </Form.Item>
  );
};

export default observer(PhaseTimeoutPartialForm);
