const setupSeriesAxisLabel = (series) => {
  const axisLabel = {};
  const {chart} = series;
  const uniqueName = `axisLabel-${series.name}`;
  axisLabel.hide = () => {
    if (chart && chart[uniqueName]) {
      chart[uniqueName].destroy();
      chart[uniqueName] = null;
    }
  };
  axisLabel.show = ({x, y, text, color, ...labelAttr}) => {
    if (!chart[uniqueName]) {
      chart[uniqueName] = chart.renderer
        .label('', 0, 0, 'callout', 0, 0, true, true)
        .attr({
          padding: 8,
          r: 5,
          zIndex: 6,
          fill: series.color,
        })
        .add();
    }
    if (text && series.visible) {
      chart[uniqueName]
        .css({
          color,
        })
        .attr({
          x,
          y,
          text,
          ...labelAttr,
        });
    } else {
      axisLabel.hide();
    }
  };

  axisLabel.series = series;

  return axisLabel;
};
export default setupSeriesAxisLabel;
