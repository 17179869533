import {createEntity, executeDelete, executeGet, executePost, updateEntity} from '../requests';

const resourcesName = 'v1/storage-units';

export const getOrCreateStorageUnitEndpoint = 'getOrCreate';
const getOrCreateStorageUnitResource = 'get-or-create';
export const searchEndpoint = 'v1/search-storage-units';

export const inputStorageUnitEndpoint = 'input';
const inputStorageUnitResource = 'input';

export const outputStorageUnitEndpoint = 'output';
const outputStorageUnitResource = 'output';

export const transferStorageUnitEndpoint = 'transfer';
const transferStorageUnitResource = 'transfer';

export const ancestorsEndpoint = 'relatives/ancestors';
export const descendantsEndpoint = 'relatives/descendants';
export const siblingsEndpoint = 'relatives/siblings';

export const StorageUnitsApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...entity}) => updateEntity(entity, `${resourcesName}/${id}`),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
  search: (params) => executeGet(`${searchEndpoint}`, {params}),
  [getOrCreateStorageUnitEndpoint]: ({no}) =>
    executePost(`${resourcesName}/${getOrCreateStorageUnitResource}/${encodeURIComponent(no)}`),
  [inputStorageUnitEndpoint]: (({operation}) =>
    executePost(`${resourcesName}/${inputStorageUnitResource}`, operation)),
  [outputStorageUnitEndpoint]: (({operation}) =>
    executePost(`${resourcesName}/${outputStorageUnitResource}`, operation)),
  [transferStorageUnitEndpoint]: (({operation}) =>
    executePost(`${resourcesName}/${transferStorageUnitResource}`, operation)),
  [ancestorsEndpoint]: ({...params}) => executeGet(`${resourcesName}/${ancestorsEndpoint}`, {params}),
  [descendantsEndpoint]: ({...params}) => executeGet(`${resourcesName}/${descendantsEndpoint}`, {params}),
  [siblingsEndpoint]: ({...params}) => executeGet(`${resourcesName}/${siblingsEndpoint}`, {params}),
};
