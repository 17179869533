import { observable, makeObservable } from 'mobx';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { AuditLog } from '../models/auditLog';
import { RootStore } from './rootStore';

export class AuditLogStore extends EntityStore<AuditLog> {
  auditLogs: AuditLog[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'auditLogs', Api.auditLogs, AuditLog);

    makeObservable(this, {
      auditLogs: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.userStore,
        modelId: 'createdBy',
      },
    ];
  }
}
