const BASE_PATH = '/manual';

const OFFICE_PATH = '/office';

const PROD_PATH = '/prod';

const PROD_CONFIG_PATH = '/prod-config';

const OFFICE_CONFIG_PATH = '/office-config';

const cleanInput = (manualPath: string) => {
  let cleaned = manualPath;
  if (!cleaned.startsWith('/')) {
    cleaned = `/${cleaned}`;
  }
  if (cleaned.endsWith('/')) {
    cleaned.substring(0, cleaned.length - 1);
  }
  return cleaned;
};

/**
 * This utility prepends and appends manual paths for production widgets. "/workflow/workflow-correction" becomes
 * "/manual/workflow/workflow-correction/prod"
 * @param {string} manualPath The inner part of the manual's path e.g. "/workflow/workflow-correction".
 * @returns {string}
 */
export const getProductionWidgetPath = (manualPath: string) => {
  if (!manualPath) {
    return manualPath;
  }
  return `${BASE_PATH}${cleanInput(manualPath)}${PROD_PATH}`;
};

/**
 * This utility prepends and appends manual paths for office widgets. "/workflow/workflow-correction" becomes
 * "/manual/workflow/workflow-correction/office"
 * @param {string} manualPath The inner part of the manual's path e.g. "/workflow/workflow-correction".
 * @returns {string}
 */
export const getOfficeWidgetPath = (manualPath: string) => {
  if (!manualPath) {
    return manualPath;
  }
  return `${BASE_PATH}${cleanInput(manualPath)}${OFFICE_PATH}`;
};

/**
 * This utility prepends and appends manual paths for widgets for prod and office. "/user-configuration/" becomes
 * "/manual/user-configuration/"
 * @param {string} manualPath The inner part of the manual's path e.g. "/user-configuration/".
 * @returns {string}
 */
export const getBaseWidgetPath = (manualPath: string) => {
  if (!manualPath) {
    return manualPath;
  }
  return `${BASE_PATH}${cleanInput(manualPath)}`;
};

/**
 * This utility prepends and appends manual paths for widget configs. "/workflow/workflow-correction" becomes
 * "/manual/workflow/workflow-correction/prod-config"
 * @param {string} manualPath The inner part of the manual's path e.g. "/workflow/workflow-correction".
 * @returns {string}
 */
export const getProdConfigPath = (manualPath: string) => {
  if (!manualPath) {
    return manualPath;
  }
  return `${BASE_PATH}${cleanInput(manualPath)}${PROD_CONFIG_PATH}`;
};

/**
 * This utility prepends and appends manual paths for widget configs. "/workflow/workflow-correction" becomes
 * "/manual/workflow/workflow-correction/office-config"
 * @param {string} manualPath The inner part of the manual's path e.g. "/workflow/workflow-correction".
 * @returns {string}
 */
export const getOfficeConfigPath = (manualPath: string) => {
  if (!manualPath) {
    return manualPath;
  }
  return `${BASE_PATH}${cleanInput(manualPath)}${OFFICE_CONFIG_PATH}`;
};
