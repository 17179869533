import { action, makeObservable, observable } from 'mobx';
import { Dayjs } from 'dayjs';
import { Api } from '../middleware/api';
import { EntityStore, LoadStrategies } from './entityStore';
import { SensorData } from '../models/sensorData';
import { sensorDataAnalysisEndpoint } from '../middleware/endpoints/sensorData';
import { sortChronologically } from '../components/shared/tables/sorters';
import { RootStore } from './rootStore';

interface LoadSensorDataForAnalysisParams {
  sensorName: string;
  workplaceId: number;
  fromDate: Dayjs;
  toDate: Dayjs;
  orderId: number;
  materialId: number;
  resolution: number;
}

export class SensorDataStore extends EntityStore<SensorData> {
  sensorData: SensorData[] = [];
  identityField = 'sensorName';

  constructor(rootStore: RootStore) {
    super(rootStore, 'sensorData', Api.sensorData, SensorData);

    makeObservable(this, {
      sensorData: observable,
      loadSensorDataForAnalysis: action,
    });
  }

  loadSensorDataForAnalysis(
    { sensorName, workplaceId, fromDate, toDate, orderId, materialId, resolution }: LoadSensorDataForAnalysisParams
  ) {
    return this.loadAll({
      apiEndpoint: sensorDataAnalysisEndpoint,
      params: {
        sensorName,
        workplaceId,
        fromDate: fromDate?.toISOString(),
        toDate: toDate?.toISOString(),
        orderId,
        materialId,
        resolution: resolution || undefined,
      },
      raw: true,
    });
  }

  loadLatestByWorkplace(sensorName: string, workplaceId: number) {
    return this.loadAll({
      params: {
        sensorName,
        workplaceId,
        fromDate: new Date(),
        toDate: new Date(),
        amountOfLeadingItems: 2,
      },
    });
  }

  loadByWorkplaceAndTimeRange(sensorName: string, workplaceId: number, fromDate: Dayjs, toDate: Dayjs) {
    return this.loadAll({
      params: {
        sensorName,
        workplaceId,
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
        amountOfLeadingItems: 1,
      },
      strategy: LoadStrategies.replace,
    });
  }

  getLatestByWorkplace(sensorName: string, workplaceId: number) {
    const data = this.sensorData
      .filter((sd) => sd.sensorName === sensorName && sd.workplaceId === workplaceId)
      .sort((a, b) => sortChronologically(b.timestamp, a.timestamp));
    return data.length > 0 ? data[0] : null;
  }
}
