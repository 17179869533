import { faGears } from '@fortawesome/free-solid-svg-icons';
import { computed, makeObservable, observable } from 'mobx';
import { BaseModel } from './base';
import { Translation } from './translations';
import { RootStore } from '../stores/rootStore';
import { CustomActionAdditionalHeaders } from './customActionAdditionalHeaders';
import { Gateway } from './gateway';
import { getTranslation } from '../utils/translations';
import { EnDash } from '../components/shared/unicodeWrapper/EnDash';
import { getKeyValueObject, KeyValueObject } from './keyValueTransforms';

/*
 * t('widgetAction.model.attributes.types.BUTTON')
 * t('widgetAction.model.attributes.types.SCAN')
 * t('widgetAction.model.attributes.types.TEXT')
 */
export enum WidgetActionType {
  Button = 'BUTTON',
  Scan = 'SCAN',
  Text = 'TEXT'
}

export interface WidgetActionTranslation extends Translation {
  label: string | null;
}

export class WidgetAction extends BaseModel {
  id: number = 0;
  name: string = '';
  type: WidgetActionType = WidgetActionType.Text;
  url: string = '';
  additionalHeaders: CustomActionAdditionalHeaders | null = null;
  gatewayId: number = 0;

  translations: WidgetActionTranslation[] = [];

  translatedProperties = ['label'];
  saveableProperties = ['name', 'type', 'url', 'additionalHeaders', 'gatewayId', 'translations'];
  searchableProperties = ['name', 'label'];

  static faIcon = faGears;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      type: observable,
      url: observable,
      additionalHeaders: observable,
      gatewayId: observable,
      translations: observable,
      gateway: computed,
      label: computed,
    });
  }

  static prepareApiPayload(
    model: Partial<Omit<WidgetAction, 'additionalHeaders'>> & { additionalHeaders?: KeyValueObject[] }
  ) {
    return {
      id: model.id ? model.id : undefined,
      name: model.name,
      type: model.type,
      url: model.url,
      additionalHeaders: !model.additionalHeaders || !Object.getOwnPropertyNames(model.additionalHeaders).length
        ? null
        : getKeyValueObject(model.additionalHeaders),
      gatewayId: model.gatewayId,
      translations: WidgetAction.convertToSavableTranslations(model),
    };
  }

  get gateway(): Gateway | undefined {
    return this.rootStore.gatewayStore.getById(this.gatewayId);
  }

  get label(): string {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.label || EnDash();
  }
}

export type WidgetActionExecRequest = {
  id: number,
  data: string,
  workplaceId: number,
  operationId?: number,
};
