import i18n from '../../i18n/i18n';
import { BaseWidgetConfig } from '../shared/widgets/baseWidgetConfig';
import { ValidationRules } from '../../utils/validationRules';
import { QualityReport } from '../../models/qualityReport';
import { getAllI18nTranslations } from '../../utils/translations';
import { FieldType, TabDefinition } from '../../types/widgetConfig';
import { RootStore } from '../../stores/rootStore';

export class QualityReportWidgetConfig extends BaseWidgetConfig {
  constructor(rootStore: RootStore, identifier: string) {
    super(rootStore, identifier);
    this.tabConfigs.set('display', [
      {
        id: 'title',
        label: i18n.t<string>('widget.settings.title.label'),
        helpText: i18n.t<string>('widget.settings.title.help'),
        type: FieldType.Translatable,
        defaultValue: getAllI18nTranslations('qualityReport.model.other'),
        validations: [ValidationRules.required()],
      },
      {
        id: 'queryTimeSpan',
        label: i18n.t<string>('qualityReport.settings.queryTimeSpan.label'),
        helpText: i18n.t<string>('qualityReport.settings.queryTimeSpan.help'),
        type: FieldType.Number,
        unit: i18n.t<string>('qualityReport.settings.queryTimeSpan.unit'),
        defaultValue: 24,
        validations: [ValidationRules.required(), ValidationRules.minNumber(1), ValidationRules.maxNumber(72)],
      },
      {
        id: 'selectableOrdersTimeSpan',
        label: i18n.t<string>('qualityReport.settings.selectableOrdersTimeSpan.label'),
        helpText: i18n.t<string>('qualityReport.settings.selectableOrdersTimeSpan.help'),
        type: FieldType.Number,
        unit: i18n.t<string>('qualityReport.settings.selectableOrdersTimeSpan.unit'),
        defaultValue: 8,
        validations: [ValidationRules.required(), ValidationRules.positiveNumber],
      },
      {
        id: 'activeOrderOnly',
        label: i18n.t<string>('qualityReport.settings.activeOrderOnly.label'),
        helpText: i18n.t<string>('qualityReport.settings.activeOrderOnly.help'),
        type: FieldType.Boolean,
        defaultValue: false,
        validations: [ValidationRules.required()],
      },
    ]);

    this.tabConfigs.set('data', [
      {
        id: 'properties',
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: QualityReport.allDisplayableProperties(this.rootStore),
        defaultValue: ['createdAt', 'title', 'message', 'coordinatorUser', 'type', 'priority'],
      },
    ]);
  }
  // eslint-disable-next-line class-methods-use-this
  get tabs(): TabDefinition[] {
    return [
      {
        key: 'display',
        label: i18n.t('widget.WidgetSettingsModal.displayConfigTabTitle'),
      },
      {
        key: 'data',
        label: i18n.t('widget.WidgetSettingsModal.dataConfigTabTitle'),
      },
    ];
  }

  getTableColumns() {
    return this.getSelectedProperties('data', 'properties');
  }

  getWidgetTitle() {
    return this.getTranslatableValue('display', 'title');
  }

  getQueryTimeSpan() {
    return this.getSettingValue('display', 'queryTimeSpan');
  }

  getDefaultReportType() {
    return this.getSettingValue('display', 'defaultReportType');
  }

  getSelectableOrdersTimeSpan() {
    return this.getSettingValue('display', 'selectableOrdersTimeSpan');
  }

  getActiveOrderOnly() {
    return this.getSettingValue('display', 'activeOrderOnly');
  }
}
