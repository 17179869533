import { observable, makeObservable, action } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore, LoadOptions } from './entityStore';
import { WorkplaceState } from '../models/workplaceState';
import { RootStore } from './rootStore';

export class WorkplaceStateStore extends EntityStore<WorkplaceState> {
  workplaceStates: WorkplaceState[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'workplaceStates', Api.workplaceStates, WorkplaceState);

    makeObservable(this, {
      workplaceStates: observable,
      resetIsDefault: action,
    });
  }

  update(entity: WorkplaceState, options: LoadOptions = {}) {
    return super.update(entity, options).then((res: WorkplaceState) => {
      if (res.isDefault) {
        this.resetIsDefault(res.id);
      }
    });
  }

  create(entity: WorkplaceState, options: LoadOptions = {}) {
    const createAction = super.create(entity, options);
    createAction.then((res) => {
      if (res.isDefault) {
        this.resetIsDefault(res.id);
      }
      return res;
    });
    return createAction;
  }

  resetIsDefault(exceptId: number) {
    this.workplaceStates = this.workplaceStates.map((state) => {
      if (state.isDefault && state.id !== exceptId) {
        state.isDefault = false;
      }
      return state;
    });
  }
}
