import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import DocumentsNavigator from '../../shared/dataDisplay/DocumentsNavigator';
import {useStore} from '../../../hooks/useStore';
import {useMount} from '../../../hooks/useMount';
import Spinner from '../../shared/spinners/Spinner';

const OrderDetailsDocuments = ({operations}) => {
  const store = useStore();

  const [documentsTree, setDocumentsTree] = useState(store.operationStore.relevantDocumentsTree);

  useMount(() => {
    store.operationStore.loadRelevantDocumentsForOperations(operations);
  }, [operations.reduce((res, op) => `${res}-${op.id}`, '')]);

  useMount(() => {
    setDocumentsTree(store.operationStore.relevantDocumentsTree);
  }, [store.operationStore.relevantDocuments.materialDocuments?.length]);

  if (store.operationStore.isLoadingRelevantDocuments) {
    return <Spinner fullWidth/>;
  }

  return (
    <DocumentsNavigator documentTree={documentsTree} style={{height: '70vh'}} isMobile={store.clientStore.isMobile}/>
  );
};

export default observer(OrderDetailsDocuments);
