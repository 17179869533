import * as ZXing from '@zxing/library';
import {useMount} from '../../hooks/useMount';

const QRCodeReader = ({onFound, id = 'video', ...props}) => {
  useMount(() => {
    const codeReader = new ZXing.BrowserQRCodeReader();
    codeReader.decodeFromInputVideoDeviceContinuously(undefined, id, (result) => {
      if (result) {
        onFound(result);
      }
    });
    return () => {
      codeReader.reset();
    };
  }, []);
  // eslint-disable-next-line jsx-a11y/media-has-caption
  return <video id={id} {...props}/>;
};

export default QRCodeReader;
