import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faListAlt} from '@fortawesome/free-solid-svg-icons';
import {observer} from 'mobx-react-lite';
import {Col, Row} from 'antd';
import Modal from '../../shared/modal/Modal';
import {useStore} from '../../../hooks/useStore';
import InspectionTaskDeactivationModal from './InspectionTaskDeactivationModal';
import {useMount} from '../../../hooks/useMount';
import {CustomPropertyTypes} from '../../../models/customPropertyDataTypes';
import GeneralSection from './sections/GeneralSection';
import DFSection from './sections/DFSection';
import TimesAndIntervalsSection from './sections/TimesAndIntervalsSection';
import ControlSection from './sections/ControlSection';
import TaskPropertiesSection from './sections/TaskPropertiesSection';
import LotPropertiesSection from './sections/LotPropertiesSection';
import BlameSection from './sections/BlameSection';
import ResultsSection from './sections/ResultsSection';
import QuantitativeSettingSection from './sections/QuantitativeSettingSection';
import QualitativeSection from './sections/QualitativeSection';
import DetailsSection from './sections/DetailsSection';
import InspectionTaskQuantitativeTargetValuesModal from './InspectionTaskQuantitativeTargetValuesModal';
import InspectionTaskQualitativeTargetValuesModal from './InspectionTaskQualitativeTargetValuesModal';
import {useModulePolicy} from '../../../hooks/useModulePolicy';
import {taskManagement} from '../../../models/scope';
import {transformation} from '../../../utils/transformations';

const InspectionTaskDrilldownModal = ({task, onClose, ...props}) => {
  const {t} = useTranslation();
  const store = useStore();
  const [deactivateModalVisible, setDeactivateModalVisible] = useState(false);
  const [quantitativeTargetValueModalVisible, setQuantitativeTargetValueModalVisible] = useState(false);
  const [qualitativeTargetValueModalVisible, setQualitativeTargetValueModalVisible] = useState(false);
  const policy = useModulePolicy(store, [taskManagement]);
  const inspectionLot = store.inspectionLotStore.getById(task.inspectionLotId);

  const colProps = {lg: 8, md: 12, sm: 24};
  const fallback = transformation.fallback();
  const availableTaskProperties = store.propertyStore.getByType({type: CustomPropertyTypes.InspectionTask});
  const availableLotProperties = store.propertyStore.getByType({type: CustomPropertyTypes.InspectionLot});

  useMount(() => {
    store.documentStore.loadMany(task.documentIds);
  });

  return (
    <>
      <Modal
        width={'90%'}
        fullscreen={store.clientStore.isMobile}
        onCancel={onClose}
        bodyStyle={{minHeight: '420px'}}
        title={(
          <>
            <FontAwesomeIcon icon={faListAlt}/>
            {' '}
            {t('inspectionTask.drilldownModal.title')}
          </>
        )}
        open
        footer={false}
        {...props}
      >
        <Row gutter={16}>
          <Col {...colProps}>
            <GeneralSection fallback={fallback} task={task}/>
          </Col>
          <Col {...colProps}>
            <DFSection fallback={fallback} task={task}/>
          </Col>
          <Col {...colProps}>
            <TimesAndIntervalsSection fallback={fallback} task={task}/>
          </Col>

          <Col {...colProps}>
            <ControlSection fallback={fallback} task={task}/>
          </Col>

          <Col {...colProps}>
            <DetailsSection fallback={fallback} task={task}/>
          </Col>

          {availableTaskProperties.length > 0 ? (
            <Col {...colProps}>
              <TaskPropertiesSection task={task} availableProperties={availableTaskProperties}/>
            </Col>
          ) : null}

          {task.quantitative && (
            <Col {...colProps}>
              <QuantitativeSettingSection
                canEdit={policy.canEdit({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})}
                task={task}
                fallback={fallback}
                setQuantitativeTargetValueModalVisible={setQuantitativeTargetValueModalVisible}
              />
            </Col>
          )}

          {task.qualitative?.catalogFeatures && (
            <Col {...colProps}>
              <QualitativeSection
                canEdit={policy.canEdit({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})}
                task={task}
                setQualitativeTargetValueModalVisible={setQualitativeTargetValueModalVisible}
              />
            </Col>
          )}

          <Col {...colProps}>
            <BlameSection fallback={fallback} task={task} setDeactivateModalVisible={setDeactivateModalVisible}/>
          </Col>

          {availableLotProperties.length > 0 ? (
            <Col {...colProps}>
              <LotPropertiesSection inspectionLot={inspectionLot} availableProperties={availableLotProperties}/>
            </Col>
          ) : null}

          <Col span={24}>
            <ResultsSection task={task}/>
          </Col>
        </Row>
      </Modal>
      {deactivateModalVisible
      && <InspectionTaskDeactivationModal task={task} onClose={() => setDeactivateModalVisible(false)}/>}
      {quantitativeTargetValueModalVisible
      && (
        <InspectionTaskQuantitativeTargetValuesModal
          task={task}
          onClose={() => setQuantitativeTargetValueModalVisible(false)}
        />
      )}
      {qualitativeTargetValueModalVisible
      && (
        <InspectionTaskQualitativeTargetValuesModal
          task={task}
          onClose={() => setQualitativeTargetValueModalVisible(false)}
        />
      )}
    </>
  );
};

export default observer(InspectionTaskDrilldownModal);
