import { action, makeObservable, observable } from 'mobx';
import { AxiosResponse } from 'axios';
import { Api } from '../middleware/api';
import { AdvancedSearchParams, Workplace } from '../models/workplace';
import { EntityStore } from './entityStore';
import { RelevantDocuments } from '../models/relevantDocuments';
import { Document } from '../models/document';
import { loadLatestLogEndpoint } from '../middleware/endpoints/workplaces';
import i18n from '../i18n/i18n';
import { RootStore } from './rootStore';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';

interface PerformQuickStopParams {
  workplaceId: number;
  body: {
    transitionId: number;
    interruptionReasonId: number;
  },
}

export class WorkplaceStore extends EntityStore<Workplace> {
  workplaces: Workplace[] = [];
  selectedWorkplace?: Workplace = undefined;
  relevantDocuments: RelevantDocuments = {
    hierarchyDocuments: [],
    materialDocuments: [],
    changeDocuments: [],
  };
  monitoredWorkplaces: Workplace[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'workplaces', Api.workplaces, Workplace);

    makeObservable(this, {
      workplaces: observable,
      selectedWorkplace: observable,
      relevantDocuments: observable,
      addMonitoredWorkplaces: action,
      resetMonitoredWorkplaces: action,
      loadRelevantDocumentsToSelectedWorkplace: action,
      loadLatestLogByState: action,
      performQuickstop: action,
      setRelevantHierarchyDocuments: action,
      setRelevantChangeDocuments: action,
      setRelevantMaterialDocuments: action,
      setSelectedWorkplace: action,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.workplaceGroupStore,
        modelId: 'groupId',
      },
      {
        store: this.rootStore.terminalLayoutStore,
        modelId: 'terminalLayoutId',
      },
      {
        store: this.rootStore.hierarchyStore,
        modelId: 'hierarchyId',
      },
      {
        store: this.rootStore.personnelLogStore,
        modelId: 'workplaceId',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'createdBy',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'updatedBy',
      },
    ];
  }

  loadRelevantDocumentsToSelectedWorkplace() {
    if (!this.selectedWorkplace) {
      return;
    }
    Api.workplaces.loadRelevantDocuments(this.selectedWorkplace.id).then((documents) => {
      this.setRelevantHierarchyDocuments(documents.data.hierarchyDocuments);
      this.setRelevantMaterialDocuments(documents.data.materialDocuments);
      this.setRelevantChangeDocuments(documents.data.changeDocuments);
    });
  }

  loadLatestLogByState(workplaceId: number, operationStateId: number, workplaceStateId: number) {
    this.api[loadLatestLogEndpoint]({ workplaceId, operationStateId, workplaceStateId })
      .then(({ data }: AxiosResponse) => {
        if (data.operationStateLog) {
          this.rootStore.operationStore.load(data.operationStateLog.operationId);
          this.rootStore.operationStateLogStore
            .add(this.rootStore.operationStateLogStore.createModelInstance(data.operationStateLog));
        }
        if (data.workplaceStateLog) {
          this.rootStore.workplaceStateLogStore
            .add(this.rootStore.workplaceStateLogStore.createModelInstance(data.workplaceStateLog));
        }
      });
  }

  setSelectedWorkplace(workplace?: Workplace) {
    this.selectedWorkplace = workplace;
  }

  setRelevantHierarchyDocuments(documents: Document[]) {
    this.relevantDocuments.hierarchyDocuments = documents
      .map((document) => Document.fromPlainObject<Document>(document, this.rootStore));
    return this.relevantDocuments.hierarchyDocuments;
  }

  setRelevantMaterialDocuments(documents: Document[]) {
    this.relevantDocuments.materialDocuments = documents
      .map((document) => Document.fromPlainObject<Document>(document, this.rootStore));
    return this.relevantDocuments.materialDocuments;
  }

  setRelevantChangeDocuments(documents: Document[]) {
    this.relevantDocuments.changeDocuments = documents
      .map((document) => Document.fromPlainObject<Document>(document, this.rootStore));
    return this.relevantDocuments.changeDocuments;
  }

  getRelevantDocumentsWidgetTree(categoryEntries: number) {
    return RelevantDocuments
      .getRelevantDocumentsTree(this.relevantDocuments, categoryEntries)
      .filter((node) => node.children.length > 0);
  }

  getByHierarchyId(hierarchyId?: number) {
    if (!hierarchyId) {
      return undefined;
    }
    return this.workplaces.find((w) => w.hierarchyId === hierarchyId);
  }

  getByHierarchyIds(hierarchyIds: number[]) {
    return this.workplaces.filter((w) => w.hierarchyId && hierarchyIds.includes(w.hierarchyId));
  }

  get relevantDocumentsTree() {
    return RelevantDocuments
      .getRelevantDocumentsTree(this.relevantDocuments)
      .filter((node) => node.children.length > 0);
  }

  getByNo(workplaceNo: Workplace['no']) {
    return this.workplaces.find((workplace) => workplace.no === workplaceNo);
  }

  async advancedSearch(params: AdvancedSearchParams) {
    return this.api.search(params).then((response: AxiosResponse) => response.data);
  }

  getRootIdFromWorkplace = (workplace: Workplace, levelsUp = 99) => {
    let { hierarchy } = workplace;
    if (!hierarchy) {
      return null;
    }
    let rootId = hierarchy.id;
    let i = 0;
    const getParent = (id?: number) => this.rootStore.hierarchyStore.hierarchies.find((h) => h.id === id);
    while (i < levelsUp && hierarchy) {
      if (!hierarchy?.parentId) {
        break;
      }
      const parent = getParent(hierarchy.parentId);
      if (parent) {
        rootId = hierarchy.parentId;
        hierarchy = parent;
      }
      i++; // eslint-disable-line no-plusplus
    }
    return rootId;
  };

  performQuickstop({ workplaceId, body }: PerformQuickStopParams) {
    this.api
      .performQuickstop({ id: workplaceId, ...body })
      .then((res: AxiosResponse) => {
        (res.data)
          ? this.addMessage(new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.quickstopSuccess')), {})
          : this.addMessage(new FlashMessage(FlashMessageType.ERROR, i18n.t('flashMessages.quickstopError')), {});
      })
      .catch((e: Error) => {
        this.addMessage(
          new FlashMessage(FlashMessageType.ERROR, `${i18n.t('flashMessages.quickstopError')}: ${e.message}`),
          {}
        );
        this.handleApiError(e);
      });
  }

  getVirtualSiblingWorkplaces(workplaceId: number) {
    const virtualSiblingWorkplaces: Workplace[] = [];
    const workplace = this.rootStore.workplaceStore.getById(workplaceId);
    if (workplace?.hierarchyId) {
      const hierarchy = this.rootStore.hierarchyStore.getById(workplace.hierarchyId);
      const parentWorkplace = this.rootStore.workplaceStore.getByHierarchyId(hierarchy?.parent?.id);
      if (parentWorkplace?.isVirtualRoot) {
        this.rootStore.hierarchyStore.getChildren(hierarchy?.parent?.id).forEach((child) => {
          const childWorkplace = this.rootStore.workplaceStore.getByHierarchyId(child.id);
          if (childWorkplace) {
            if (childWorkplace.id !== workplaceId) {
              virtualSiblingWorkplaces.push(childWorkplace);
            }
          }
        });
      }
    }

    return virtualSiblingWorkplaces;
  }

  addMonitoredWorkplaces(workplaces: Workplace[]) {
    workplaces.forEach((wp) => {
      if (!this.monitoredWorkplaces.find((mwp) => mwp.id === wp.id)) {
        this.monitoredWorkplaces.push(wp);
      }
    });
  }

  resetMonitoredWorkplaces() {
    this.monitoredWorkplaces = [];
  }
}
