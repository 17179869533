import { observer } from 'mobx-react-lite';
import { FormInstance } from 'antd';
import React, { useState } from 'react';
import { useStore } from '../../../hooks/useStore';
import { Setting } from '../../../models/setting';
import Form from '../../shared/form/Form';
import { makeFormFields } from '../../shared/form/formFields';
import { useMount } from '../../../hooks/useMount';
import ModuleConfigurationQualityReportForm from './ModuleConfigurationQualityReportForm';
import Empty from '../../shared/empty/Empty';

export const MODULE_NAME_PREFIX: string = 'Module.';

/**
 * Translations:
 * t('moduleConfiguration.tabs.QualityReport')
 * t('moduleConfiguration.tabs.Empty')
 */
export enum ModuleNames {
  QUALITY_REPORT = 'QualityReport',
  EMPTY = 'Empty'
}

export type ModuleConfigurationFormHandlerProps = {
  identifier: string,
  formModule: ModuleNames
};

const ModuleConfigurationFormHandler: React.FC<ModuleConfigurationFormHandlerProps> = (
  {
    identifier,
    formModule,
  }
) => {
  const store = useStore();
  const [form]: Array<FormInstance<Setting>> = Form.useForm();
  const [currentRecord, setCurrentRecord] = useState<Setting | null>(null);
  const [fields, setFields] = useState<any[]>([
    ...makeFormFields(Setting),
  ]);

  useMount(() => {
    const record = store.settingStore.getByIdentifier(identifier);

    setCurrentRecord(record || null);
    if (record) {
      form.setFieldsValue({
        ...record.value,
      });
    }
  }, [store.settingStore.officeSettingsReady, identifier]);

  const onSave = (values: any) => {
    const settings = store.settingStore.getByIdentifier(identifier);

    if (settings) {
      store.settingStore.update({
        id: settings.id,
        value: {
          ...values,
        },
      })
        .catch((errors: any) => {
          setFields(makeFormFields(Setting, errors.fields));
        });
    } else {
      store.settingStore.create({
        identifier,
        value: {
          ...values,
        },
      })
        .catch((errors: any) => {
          setFields(makeFormFields(Setting, errors.fields));
        });
    }
  };

  const onDiscard = () => {
    currentRecord ? form.setFieldsValue({ ...currentRecord.value }) : form.resetFields();
  };

  return (
  // TODO delete eslint disable after more than one form is implemented
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        formModule === ModuleNames.QUALITY_REPORT && (
          <ModuleConfigurationQualityReportForm
            form={form}
            fields={fields}
            setFields={setFields}
            onSave={onSave}
            onDiscard={onDiscard}
          />
        )
      }
      {
        formModule === ModuleNames.EMPTY && (
          <Empty/>
        )
      }
    </>
  );
};

export default observer(ModuleConfigurationFormHandler);
