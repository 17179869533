import {observer} from 'mobx-react-lite';
import {useMount} from '../../../hooks/useMount';
import MessageViewer from '../../message/MessageViewer';
import {useStore} from '../../../hooks/useStore';

const OrderMessageTabPane = ({operations = []}) => {
  const store = useStore();

  useMount(() => {
    store.operationMessageStore.clear();
    operations.forEach((operation) => {
      store.operationMessageStore.loadRelevantMessages(
        operation.workplaceId,
        {operationId: operation.id},
        true
      );
    });
  }, [operations.reduce((res, op) => `${res}-${op.id}`, '')]);

  return (
    <MessageViewer
      isLoading={store.operationMessageStore.isLoadingRelevantMessages}
      messages={store.operationMessageStore.operationMessages}
      allowMarkAsRead={false}
    />
  );
};

export default observer(OrderMessageTabPane);
