import { action, observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore, LoadOptions } from './entityStore';
import { Yield } from '../models/yield';
import { RootStore } from './rootStore';

export class YieldStore extends EntityStore<Yield> {
  yields: Yield[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'yields', Api.yields, Yield);

    makeObservable(this, {
      yields: observable,
      loadByOperation: action,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.operationStore,
        modelId: 'operationId',
      },
      {
        store: this.rootStore.yieldTypeStore,
        modelId: 'yieldTypeId',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'createdBy',
      },
      {
        store: this.rootStore.batchStore,
        modelId: 'batchId',
      },
      {
        store: this.rootStore.preparationStore,
        modelId: 'preparationId',
      },
    ];
  }

  loadByOperation(operationId: Yield['operationId'], paramOptions: LoadOptions = {}) {
    return this.loadAllWithDependencies({ params: { operationId }, ...paramOptions });
  }

  getByOperation(operationId: Yield['operationId']) {
    return this.yields.filter((y) => y.operationId === operationId);
  }

  getByOperations(operationIds: Yield['operationId'][]) {
    return this.yields.filter((y) => operationIds.find((operationId) => y.operationId === operationId));
  }

  getFilteredByExistingBatch() {
    return this.yields.filter((y) => y.batchId);
  }

  getByIds(ids: Yield['id'][]) {
    return super.getByIds(ids);
  }
}
