import {executeGet, executePatch, executePost, updateEntity} from '../requests';

const resourcesName = 'v1/operations';

export const relevantDocumentsEndpoint = 'relevantDocuments';
export const relevantDocumentsResource = 'relevant-documents';
export const validStartTransitionsEndpoint = 'validStartTransitions';
export const validStartTransitionsResource = 'valid-start-transitions';
export const personnelDurationsEndpoint = 'personnelDurations';
export const personnelDurationsResource = 'personnel-durations';
export const produceEndpoint = 'produce';
export const produceAction = 'produce';

export const OperationsApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  [relevantDocumentsEndpoint]: (id) => executeGet(`${resourcesName}/${id}/${relevantDocumentsResource}`),
  [validStartTransitionsEndpoint]: ({id, ...params}) => executeGet(
    `${resourcesName}/${id}/${validStartTransitionsResource}`,
    params
  ),
  update: ({id, ...entity}) => updateEntity(entity, `${resourcesName}/${id}`),
  activeOperation: (workplaceId) => executeGet(`v1/workplaces/${workplaceId}/active-operation`),
  abort: (id) => executePatch(`${resourcesName}/${id}/abort`),
  reorder: ({id, ...entity}) => executePatch(`${resourcesName}/${id}/reorder`, {...entity}),
  [personnelDurationsEndpoint]: ({id, ...params}) => executeGet(
    `${resourcesName}/${id}/${personnelDurationsResource}`,
    {params}
  ),
  [produceEndpoint]: (id, params) => executePost(`${resourcesName}/${id}/${produceAction}`, params),
};
