import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import styles from './Subject.module.scss';
import SubTitleWithIcon from '../../shared/typography/SubTitleWithIcon';
import {getProductionWidgetPath} from '../../../utils/manualPaths';
import WidgetManualButton from '../../manual/WidgetManualButton';

const Subject = ({message}) => {
  const {t} = useTranslation();

  return (
    <div className={styles.subject}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <SubTitleWithIcon
          icon={<FontAwesomeIcon icon={faInfoCircle} style={{marginRight: 8}}/>}
          text={t('message.model.attributes.subject')}
        />
        <div style={{marginTop: '-6px'}}>
          <WidgetManualButton manualPath={getProductionWidgetPath('/information/message')}/>
        </div>
      </div>
      <p className={styles.subjectText}>{message.subject}</p>
    </div>
  );
};

export default observer(Subject);
