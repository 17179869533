import i18n from '../../i18n/i18n';
import {ValidationRules} from '../../utils/validationRules';
import {BaseWidgetConfig} from '../shared/widgets/baseWidgetConfig';
import {getAllI18nTranslations} from '../../utils/translations';
import {FieldType} from '../../types/widgetConfig';

export class DocumentWidgetConfig extends BaseWidgetConfig {
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'settings',
        label: i18n.t('widget.WidgetSettingsModal.settingConfigTabTitle'),
      },
    ];
  }

  settings = [
    {
      id: 'document.title',
      label: i18n.t('document.documentWidget.fields.title.label'),
      helpText: i18n.t('document.documentWidget.fields.title.help'),
      type: FieldType.Translatable,
      defaultValue: getAllI18nTranslations('document.documentWidget.fields.title.defaultValue'),
      validations: [ValidationRules.required()],
    },
    {
      id: 'document.category.entries',
      label: i18n.t('document.documentWidget.fields.entries.label'),
      helpText: i18n.t('document.documentWidget.fields.entries.help'),
      type: FieldType.Number,
      defaultValue: 5,
      validations: [ValidationRules.required()],
    },
  ];

  getWidgetSetting(identifier) {
    return this.getSettingValue('settings', identifier);
  }

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'document.title');
  }
}
