import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {InspectionLotOrigin} from '../../../../models/inspectionLot';
import Descriptions from '../../../shared/descriptions/Descriptions';
import {datetimeFormat} from '../../../../config/dayjs';
import {transformation} from '../../../../utils/transformations';
import ConfirmationValue from './ConfirmationValue';

const GeneralSection = ({fallback, task}) => {
  const {t} = useTranslation();

  return (
    <Descriptions
      title={t('inspectionTask.drilldownModal.section.general')}
      bordered
      size={'small'}
      column={1}
      style={{marginBottom: 16}}
    >
      <Descriptions.Item label={t('inspectionTask.model.attributes.name.label')}>
        {fallback(task.name)}
      </Descriptions.Item>
      <Descriptions.Item label={t('inspectionTask.model.attributes.description.label')}>
        {fallback(task.description)}
      </Descriptions.Item>
      <Descriptions.Item
        label={t('inspectionLot.model.one')}
      >
        {fallback(task.inspectionLot?.lotNo)}
      </Descriptions.Item>
      <Descriptions.Item label={t('inspectionLot.model.attributes.validStart')}>
        {fallback(task.inspectionLot?.validStart, transformation.datetime({format: datetimeFormat}))}
      </Descriptions.Item>
      <Descriptions.Item label={t('inspectionLot.model.attributes.validEnd')}>
        {fallback(task.inspectionLot?.validEnd, transformation.datetime({format: datetimeFormat}))}
      </Descriptions.Item>
      <Descriptions.Item label={t('inspectionLot.model.attributes.type')}>
        {task.inspectionLot?.orderId ? InspectionLotOrigin.order : InspectionLotOrigin.notOrder}
      </Descriptions.Item>
      <Descriptions.Item label={t('inspectionLot.model.attributes.active')}>
        <ConfirmationValue bool={!task.inspectionLot.deactivatedAt}/>
      </Descriptions.Item>
      <Descriptions.Item label={t('inspectionTask.model.attributes.priority.label')}>
        {t(`inspectionTask.model.attributes.priorityType.${task.priority}`)}
      </Descriptions.Item>
      <Descriptions.Item label={t('inspectionTask.model.attributes.reportingNo')}>
        {fallback(task.reportingNo)}
      </Descriptions.Item>
      <Descriptions.Item label={t('inspectionTask.model.attributes.characteristicNo.label')}>
        {fallback(task.characteristicNo)}
      </Descriptions.Item>
      <Descriptions.Item label={t('inspectionTask.model.attributes.operationNo.label')}>
        {fallback(task.operationNo)}
      </Descriptions.Item>
      <Descriptions.Item
        label={t('inspectionTask.model.attributes.graph.label')}
      >
        {t(`inspectionTask.model.attributes.graphType.${task.graph}`)}
      </Descriptions.Item>
      <Descriptions.Item
        label={t('inspectionTask.model.attributes.showQualityIndicator')}
      >
        <ConfirmationValue bool={!task.hideQualityIndicator}/>
      </Descriptions.Item>
    </Descriptions>
  );
};

export default observer(GeneralSection);
