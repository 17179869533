import { computed, observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { YieldType } from '../models/yieldType';
import { RootStore } from './rootStore';
import { Operation } from '../models/operation';

export class YieldTypeStore extends EntityStore<YieldType> {
  yieldTypes: YieldType[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'yieldTypes', Api.yieldTypes, YieldType);

    makeObservable(this, {
      yieldTypes: observable,
      generalYieldTypes: computed,
    });
  }

  getUnit(yieldType: YieldType, operation?: Operation) {
    let unitId;
    if (!yieldType) {
      return null;
    }
    if (yieldType.isProduct && operation) {
      unitId = operation.materialId
        ? operation.unitOfMeasureId
        : operation.order?.unitOfMeasureId;
    } else {
      unitId = yieldType.unitOfMeasureId;
    }
    if (!unitId) {
      return null;
    }
    return this.rootStore.unitOfMeasurementStore.getById(unitId)?.label;
  }

  get generalYieldTypes() {
    return this.yieldTypes.filter((y) => !y.isInternal);
  }
}
