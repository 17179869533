import { computed, makeObservable, observable } from 'mobx';
import { faTimer } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

/**
 * t('phaseTimeout.inputTypes.INPUT')
 * t('phaseTimeout.inputTypes.SELECT')
 */
export enum PhaseTimeoutInputType {
  INPUT = 'INPUT',
  SELECT = 'SELECT'
}

/**
 * t('phaseTimeout.durationTypes.MINUTES')
 * t('phaseTimeout.durationTypes.PERCENT')
 */
export enum PhaseTimeoutDurationType {
  MINUTES = 'MINUTES',
  PERCENT = 'PERCENT'
}

export class PhaseTimeout extends BaseModel {
  id: number = 0;
  workflowId: number = 0;
  phaseId: number = 0;
  inputType: PhaseTimeoutInputType = PhaseTimeoutInputType.INPUT;
  duration: number = 0;
  durationType: PhaseTimeoutDurationType = PhaseTimeoutDurationType.MINUTES;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      workflowId: observable,
      workflow: computed,
      phaseId: observable,
      phase: computed,
      inputType: observable,
      duration: observable,
      durationType: observable,
    });
  }

  static faIcon = faTimer;

  searchableProperties = ['phase.name', 'workflow.label'];

  get phase() {
    return this.rootStore.phaseStore.getById(this.phaseId);
  }

  get workflow() {
    return this.rootStore.workflowStore.getById(this.workflowId);
  }

  static prepareApiPayload(model: Partial<PhaseTimeout>): Partial<PhaseTimeout> {
    return {
      id: model.id || undefined,
      workflowId: model.workflowId,
      phaseId: model.phaseId,
      inputType: model.inputType,
      duration: model.duration,
      durationType: model.durationType,
    };
  }
}
