import {observer} from 'mobx-react-lite';
import Table from '../shared/tables/Table';
import {defaultPaginationPreset} from '../shared/tables/paginationPresets';

const InspectionTaskResultTable = ({
  displayableColumns,
  inspectionTaskResults = [],
  loading,
}) => (
  <Table
    loading={loading}
    size={'middle'}
    columns={displayableColumns}
    dataSource={inspectionTaskResults}
    rowKey={'id'}
    pagination={defaultPaginationPreset}
  />
);

export default observer(InspectionTaskResultTable);
