import React from 'react';
import {Observer, observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import Table from '../shared/tables/Table';
import {EnDash} from '../shared/unicodeWrapper/EnDash';

const ConsumedComponents = ({consumptionLog}) => {
  const {t} = useTranslation();

  const columns = [
    {
      key: 'component',
      title: t('producedMaterialWidget.consumedComponentsTable.column.component'),
      render: (dataIndex, logs) => (
        <Observer>
          {
            () => (
              <>
                <div className={'font-weight-bold'}>{logs[0]?.component?.material?.no}</div>
                <div className={'small'}>{logs[0]?.component?.material?.name}</div>
              </>
            )
          }
        </Observer>
      ),
    },
    {
      key: 'batchIds',
      title: t('producedMaterialWidget.consumedComponentsTable.column.batches'),
      render: (dataIndex, logs) => (
        <Observer>
          {
            () => (
              <>
                {logs.map((log) => <div key={log.id}>{log.batch?.no || <EnDash/>}</div>)}
              </>
            )
          }
        </Observer>
      ),
    },
    {
      key: 'amounts',
      title: t('producedMaterialWidget.consumedComponentsTable.column.consumptions'),
      render: (dataIndex, logs) => (
        <Observer>
          {
            () => (
              <>
                {
                  logs.map((log) => (
                    <div key={log.id}>
                      {log.amount}
                      &nbsp;
                      {log?.component?.unitOfMeasure?.label}
                    </div>
                  ))
                }
              </>
            )
          }
        </Observer>
      ),
    },
  ];

  return (
    <Table rowKey={(record) => record[0].id} dataSource={consumptionLog} columns={columns} pagination={false}/>
  );
};

export default observer(ConsumedComponents);
