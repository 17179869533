import { capitalize, get } from 'lodash';
import { BaseModel } from './base';
import { UnitOfMeasurementType } from './unitOfMeasurement';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import { RootStore } from '../stores/rootStore';

const MAX_DECIMAL_PLACES = 3;

const conversionTransformer = (
  rootStore: RootStore,
  fieldName: string,
  type: UnitOfMeasurementType,
  orderIdPath: string
) => (entity: any) => {
  const orderId = get(entity, orderIdPath, 0);
  const conversion = rootStore.unitOfMeasurementConversionStore.unitOfMeasurementConversions
    ?.find((c) => c.orderId === orderId && c.destinationUnitOfMeasure?.type === type);
  let value = entity?.[fieldName];
  if (conversion) {
    value = (value * conversion.numerator) / conversion.denominator;
  }
  return transformation.round(MAX_DECIMAL_PLACES)(value);
};

export class QuantityConversionModel extends BaseModel {
  static getSimpleQuantityFieldProperties(
    fieldName: string,
    orderIdPath: string,
    rootStore: RootStore,
    pathPrefix = '',
    titlePrefix = ''
  ) {
    return Object.values(UnitOfMeasurementType).map((type) => displayableProperty({
      key: `${pathPrefix}${fieldName}.${type}`,
      title: `${titlePrefix}${i18n.t(`order.model.attributes.${fieldName}`)}`
        + ` ${i18n.t('unitOfMeasurement.alternates')} > ${i18n.t(`unitOfMeasurement.types.${type}`)}`,
      params: [displayablePropertyParam({
        path: pathPrefix || '.',
        as: 'value',
        transform: conversionTransformer(rootStore, fieldName, type, orderIdPath),
      }),
      displayablePropertyParam({
        path: pathPrefix || '.',
        as: 'unitOfMeasure',
        transform: () => {
          const unit = rootStore.unitOfMeasurementStore.unitsOfMeasurement.find((u) => u.type === type);
          return transformation.objectProperty('label')(unit);
        },
      })],
      template: '{value} {unitOfMeasure}',
    }));
  }

  static getIsShouldQuantityFieldProperties(
    fieldNameWithoutPrefix: string,
    orderIdPath: string,
    rootStore: RootStore,
    pathPrefix = '',
    titlePrefix = ''
  ) {
    return Object.values(UnitOfMeasurementType).map((type) => displayableProperty({
      key: `${pathPrefix}${fieldNameWithoutPrefix}IsShould.${type}`,
      title: `${titlePrefix}${i18n.t(`order.model.attributes.${fieldNameWithoutPrefix}IsShould`)}`
        + ` ${i18n.t('unitOfMeasurement.alternates')} > ${i18n.t(`unitOfMeasurement.types.${type}`)}`,
      params: [displayablePropertyParam({
        path: pathPrefix || '.',
        as: 'is',
        transform: conversionTransformer(rootStore, `actual${capitalize(fieldNameWithoutPrefix)}`, type, orderIdPath),
      }),
      displayablePropertyParam({
        path: pathPrefix || '.',
        as: 'should',
        transform: conversionTransformer(rootStore, `planned${capitalize(fieldNameWithoutPrefix)}`, type, orderIdPath),
      }),
      displayablePropertyParam({
        path: pathPrefix || '.',
        as: 'unitOfMeasure',
        transform: () => {
          const unit = rootStore.unitOfMeasurementStore.unitsOfMeasurement.find((u) => u.type === type);
          return transformation.objectProperty('label')(unit);
        },
      })],
      template: '{is} / {should} {unitOfMeasure}',
    }));
  }
}
