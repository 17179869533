import React from 'react';
import { observer } from 'mobx-react-lite';
import Modal, { ModalProps } from './Modal';

const FormModal: React.FC<ModalProps> = (props) => (
  <Modal
    destroyOnClose
    maskClosable={false}
    footer={null}
    width={700}
    {...props}
  />
);

export default observer(FormModal);
