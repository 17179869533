import {useTranslation} from 'react-i18next';
import Input from '../../shared/inputs/Input';
import Form from '../../shared/form/Form';
import DefaultFormActions from '../../shared/form/DefaultFormActions';
import {ValidationRules} from '../../../utils/validationRules';
import {useStore} from '../../../hooks/useStore';

const InspectionLotDeactivationForm = ({
  inspectionLotId,
  saving = false,
  onCancel,
  afterDeactivate = () => undefined,
}) => {
  const store = useStore();
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const initialValues = {reason: ''};

  const onSubmit = (values) => {
    store.inspectionLotStore
      .deactivate(inspectionLotId, values.reason)
      .finally(() => {
        afterDeactivate(inspectionLotId);
        form.resetFields();
        onCancel();
      });
  };

  return (
    <Form
      initialValues={initialValues}
      form={form}
      onFinish={onSubmit}
    >
      <Form.Item
        name={'reason'}
        label={t('inspectionLot.model.attributes.reason')}
        rules={[ValidationRules.required()]}
      >
        <Input.TextArea maxLength={255}/>
      </Form.Item>
      <DefaultFormActions
        saving={saving}
        onCancel={onCancel}
        submitText={t('inspectionLot.deactivate')}
      />
    </Form>
  );
};
export default InspectionLotDeactivationForm;
