import {createEntity, executeDelete, executeGet, updateEntity} from '../requests';

const resourcesName = 'v1/batch-generators';

export const BatchGeneratorFunctionApi = {
  all: () => executeGet(`${resourcesName}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...values}) => updateEntity(values, `${resourcesName}/${id}`),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
};
