import { observer } from 'mobx-react-lite';
import { get } from 'lodash';
import { AnyObject } from 'antd/es/_util/type';
import { ColumnType } from 'antd/es/table';
import React from 'react';
import Table, { TableProps } from '../tables/Table';

type ColumnTypeWithLabel<RecordType> = ColumnType<RecordType> & {
  label: string;
};

export type WidgetTableProps<RecordType extends AnyObject = AnyObject> = TableProps<RecordType> & {
  columns: ColumnTypeWithLabel<RecordType>[];
  models: TableProps<RecordType>['dataSource'];
};

// eslint-disable-next-line max-len
const WidgetTable: (<RecordType extends AnyObject = AnyObject>(props: WidgetTableProps<RecordType>) => React.ReactNode) = <RecordType extends AnyObject = AnyObject>({
  columns,
  models,
  ...props
}: WidgetTableProps<RecordType>) => {
  /**
   * Wraps the sorter function for sorters defined in models that are relative to their model. This is required, as
   * the antd table component will always pass the entire object to the sorter, and a sorter of a nested model can't
   * handle that.
   * @param column
   * @returns {function(RecordType, RecordType): number}
   */
  const sortNestedDecorator = (column: ColumnType<RecordType>) => (_a: RecordType, _b: RecordType): number => {
    let a = _a;
    let b = _b;
    if (typeof column.key === 'string' && column.key.indexOf('.') !== -1) {
      const parts = column.key.split('.');
      const childIdent = parts.slice(0, parts.length - 1).join('.');
      a = get(a, childIdent);
      b = get(b, childIdent);
    }
    return typeof column.sorter === 'function' ? column.sorter(a, b) : 0;
  };

  const extendedColumns = columns?.map((column: ColumnTypeWithLabel<RecordType>) => ({
    ...column,
    title: column.title ? column.title : column.label,
    dataIndex: column.dataIndex ? column.dataIndex : column.key,
    // FIXME: A sorter can also be an object of {compare , multiple}. This is not handled at the moment.
    sorter: typeof column.sorter === 'function' ? sortNestedDecorator(column) : column.sorter,
  }));

  return (
    <Table dataSource={models} columns={extendedColumns} rowKey={'id'} {...props}/>
  );
};

export default observer(WidgetTable);
