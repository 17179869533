import { observable, makeObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { Batch } from '../models/batch';
import { RootStore } from './rootStore';

import { SearchParams } from '../types/autocomplete';

export class BatchStore extends EntityStore<Batch> {
  batches: Batch[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'batches', Api.batches, Batch, true);

    makeObservable(this, {
      batches: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.materialStore,
        modelId: 'materialId',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'createdBy',
      },
    ];
  }

  async searchByNo({ params }: SearchParams) {
    return this.api.search({ ...params }).then((response: AxiosResponse) => response.data);
  }

  getByMaterialId(materialId: number): Batch[] {
    return this.batches.filter((batch) => batch.materialId === materialId);
  }
}
