const setupPointerPositionEvents = (chart, mouseMoveCallback, mouseOutCallback, plotAreaOnly = true) => {
  const isInPlotArea = (xAxis, yAxis, position) => (
    !plotAreaOnly
    || (position.x >= xAxis.min
      && position.x <= xAxis.max
      && position.y >= yAxis.min
      && position.y <= yAxis.max)
  );
  let active = false;

  chart.container.addEventListener('mousemove', (e) => {
    if (chart) {
      e = chart.pointer.normalize(e);
      const pointerPosition = {
        x: e.chartX,
        y: e.chartY,
      };
      const plotPosition = {
        x: chart.xAxis[0].toValue(e.chartX),
        y: chart.yAxis[0].toValue(e.chartY),
      };
      if (isInPlotArea(chart.xAxis[0], chart.yAxis[0], plotPosition)) {
        if (mouseMoveCallback) {
          mouseMoveCallback(chart, pointerPosition, plotPosition);
        }
        active = true;
      } else if (mouseOutCallback && active) {
        mouseOutCallback(chart);
        active = false;
      }
    }
  });

  chart.container.addEventListener('mouseout', () => {
    if (active) {
      mouseOutCallback(chart);
      active = false;
    }
  });
};

export default setupPointerPositionEvents;
