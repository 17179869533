import React from 'react';
import { Form } from 'antd';
import { ValidationRules } from '../../../utils/validationRules';
import Input from '../inputs/Input';
import Select from '../inputs/Select';
import NumericFormItem from './NumericFormItem';
import Switch from '../inputs/Switch';
import ColorPicker from '../inputs/ColorPicker';
import { CustomPropertyDataTypes } from '../../../models/customPropertyDataTypes';
import { CustomProperty } from '../../../models/customProperty';
import { customePropertyPrefix } from '../../../models/propertiesTransforms';

export type CustomPropertyFormItemProps = {
  property: CustomProperty;
  propertyName: string | string[];
};

const CustomPropertyFormItem: React.FC<CustomPropertyFormItemProps> = ({
  property,
  // to set the name with property.key does not work
  propertyName,
  ...props
}) => {
  let flatName = [customePropertyPrefix];
  if (Array.isArray(propertyName)) {
    flatName = flatName.concat(propertyName);
  } else {
    flatName.push(propertyName);
  }

  const label = property.label || property.key;
  switch (property.dataType) {
    case CustomPropertyDataTypes.Number:
      return (
        <NumericFormItem
          name={flatName}
          label={label}
          rules={property.required ? [ValidationRules.required()] : []}
        >
          <Input type={'number'} {...props}/>
        </NumericFormItem>
      );
    case CustomPropertyDataTypes.Boolean:
      return (
        <Form.Item
          label={label}
          name={flatName}
          valuePropName={'checked'}
          getValueFromEvent={(checked) => checked.toString()}
          getValueProps={(value) => ({ checked: /false|0/i.test(value) ? false : !!value })}
        >
          <Switch
            {...props}
          />
        </Form.Item>
      );
    case CustomPropertyDataTypes.Object:
      return (
        <Form.Item
          name={flatName}
          label={label}
          rules={property.required ? [ValidationRules.required()] : []}
        >
          <Input.TextArea {...props}/>
        </Form.Item>
      );
    case CustomPropertyDataTypes.Enum:
      return (
        <Form.Item
          name={flatName}
          label={label}
          rules={property.required ? [ValidationRules.required()] : []}
        >
          <Select allowClear={!property.required} {...props}>
            {property.options?.map((option) => (
              <Select.Option key={option.key} value={option.key}>
                {property.translatedOption(option)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    case CustomPropertyDataTypes.Color:
      return (
        <Form.Item
          name={flatName}
          label={label}
          rules={property.required ? [ValidationRules.required()] : []}
        >
          <ColorPicker/>
        </Form.Item>
      );
    case CustomPropertyDataTypes.String:
    default:
      return (
        <Form.Item
          name={flatName}
          label={label}
          rules={property.required ? [ValidationRules.required()] : []}
        >
          <Input {...props}/>
        </Form.Item>
      );
  }
};

export default CustomPropertyFormItem;
