import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { StorageUnitLog } from '../models/storageUnitLog';
import { RootStore } from './rootStore';

export class StorageUnitLogStore extends EntityStore<StorageUnitLog> {
  storageUnitLogs: StorageUnitLog[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'storageUnitLogs', Api.storageUnitLogs, StorageUnitLog);

    makeObservable(this, {
      storageUnitLogs: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.storageAreaStore,
        modelId: 'storageAreaId',
      },
      {
        store: this.rootStore.storageUnitStore,
        modelId: 'storageUnitId',
      },
      {
        store: this.rootStore.storageUnitStore,
        modelId: 'parentStorageUnitId',
      },
      {
        store: this.rootStore.materialStore,
        modelId: 'materialId',
      },
      {
        store: this.rootStore.batchStore,
        modelId: 'batchId',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'createdBy',
      },
    ];
  }
}
