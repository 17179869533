import React from 'react';
import { computed, makeObservable, observable } from 'mobx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFileAlt, faFileImage, faFilePdf, faLink } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';
import { Hierarchy } from './hierarchy';

export const DocumentTypes = Object.freeze({
  file: 'file',
  link: 'link',
});

/**
 * Value is in MB.
 */
export const DOCUMENT_MAX_FILE_SIZE = 20;

export const DocumentAssociations = Object.freeze({
  hierarchyIds: 'hierarchyIds',
  materials: 'materials',
  inspectionTasksIds: 'inspectionTasksIds',
  codes: 'codes',
  changeDocuments: 'changeDocuments',
});

interface ChangeDocument {
  documentId: number;
  fromMaterialId: number;
  toMaterialId: number;
}

interface MaterialDocument {
  documentId: number;
  materialId: number;
  hierarchyId: number | null;
}

export type DocumentTreeNode = {
  key: number;
  id: number;
  document: Document;
  title: React.ReactNode;
  children: DocumentTreeNode[];
};

export class Document extends BaseModel {
  id: number = 0;
  filename?: string = undefined;
  label: string = '';
  link?: string = undefined;
  localFilename?: string = undefined;
  src?: string = undefined;
  hierarchyIds: number[] = [];
  inspectionTasksIds: number[] = [];
  changeDocuments: ChangeDocument[] = [];
  materials: MaterialDocument[] = [];
  externalId?: string = undefined;
  version: number = 0;
  openInTab?: boolean = undefined;
  documentTypeId: number = 0;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;
  isManual: boolean = false;
  mimeType: string | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      filename: observable,
      label: observable,
      link: observable,
      localFilename: observable,
      src: observable,
      hierarchies: computed,
      hierarchyIds: observable,
      inspectionTasks: computed,
      inspectionTasksIds: observable,
      changeDocuments: observable,
      materials: observable,
      externalId: observable,
      version: observable,
      openInTab: observable,
      documentTypeId: observable,
      documentType: computed,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
      icon: computed,
      isManual: observable,
      mimeType: observable,
    });
  }

  static faIcon = faFileAlt;

  searchableProperties = ['filename', 'label'];

  nestedModels = ['hierarchies', 'changeDocuments', 'materials', 'inspectionTasks'];

  static prepareApiPayload(model: Document) {
    return {
      ...model,
      documentTypeId: model.documentTypeId || null,
    };
  }

  get hierarchies(): Hierarchy[] {
    return this.hierarchyIds
      ?.map((hierarchyId) => this.rootStore.hierarchyStore.getById(hierarchyId))
      ?.filter((hierarchy): hierarchy is Hierarchy => !!hierarchy) as Hierarchy[]
    || [];
  }
  get documentType() {
    return this.rootStore.documentTypeStore.getById(this.documentTypeId);
  }

  get inspectionTasks() {
    return this.inspectionTasksIds
      ?.map((inspectionTaskId) => this.rootStore.inspectionTaskStore.getById(inspectionTaskId))
      ?.filter((inspectionTask) => inspectionTask)
    || [];
  }

  get isPDF() {
    return this.mimeType && this.mimeType === 'application/pdf';
  }

  get isImage() {
    return this.mimeType && this.mimeType.includes('image/');
  }

  get icon() {
    if (this.isPDF) {
      return <FontAwesomeIcon icon={faFilePdf}/>;
    }
    if (this.isImage) {
      return <FontAwesomeIcon icon={faFileImage}/>;
    }
    if (this.link) {
      return <FontAwesomeIcon icon={faLink}/>;
    }

    return <FontAwesomeIcon icon={faFile}/>;
  }
}

export interface ImageRequest extends Pick<Document, 'filename' | 'label'> {
  content: string;
}
