import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { TransitionQueue } from '../models/transitionQueue';
import { RootStore } from './rootStore';

export class TransitionQueueStore extends EntityStore<TransitionQueue> {
  transitionQueues: TransitionQueue[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'transitionQueues', Api.transitionQueues, TransitionQueue);

    makeObservable(this, {
      transitionQueues: observable,
    });
  }

  loadByWorkplaceId(workplaceId: number) {
    return this.loadAll({
      params: {
        workplaceId,
      },
    });
  }

  getByOperationId(operationId: number) {
    return this.transitionQueues.find((queue) => queue.operationId === operationId);
  }

  getScheduledByWorkplaceId(workplaceId: number) {
    return this.transitionQueues.find((queue) => queue.workplaceId === workplaceId && queue.plannedExecution);
  }
}
