import {useState} from 'react';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../hooks/useStore';
import KeyValueTable from '../shared/tables/KeyValueTable';
import {detailedDatetimeFormat, detailedDurationFormat} from '../../config/dayjs';
import {formatDuration} from '../../utils/formatting';
import {useMount} from '../../hooks/useMount';
import {EnDash} from '../shared/unicodeWrapper/EnDash';
import UserFullName from '../shared/UserFullName';
import {OperationStateLog} from '../../models/operationStateLog';

const LogDetails = ({log, nextLog}) => {
  const {t} = useTranslation();
  const store = useStore();

  const [dayjsStart, setDayjsStart] = useState(dayjs(log.start || log.createdAt));
  const [dayjsEnd, setDayjsEnd] = useState(dayjs(log.end || nextLog?.createdAt));
  const [duration, setDuration] = useState(dayjs.duration(dayjsEnd.diff(dayjsStart)));
  const [operation, setOperation] = useState(null);
  const [state, setState] = useState(null);
  const [createdByUser, setCreatedByUser] = useState(null);
  const [updatedByUser, setUpdatedByUser] = useState(null);

  useMount(() => {
    setDayjsStart(dayjs(log.start || log.createdAt));
  }, [log.start, log.createdAt]);

  useMount(() => {
    setDayjsEnd(dayjs(log.end || nextLog?.createdAt));
  }, [log.end, nextLog?.createdAt]);

  useMount(() => {
    setDuration(dayjs.duration(dayjsEnd.diff(dayjsStart)));
  }, [dayjsStart, dayjsEnd]);

  useMount(() => {
    if (log.operationId) {
      setOperation(store.operationStore.getById(log.operationId));
      setState(store.operationStateStore.getById(log.stateId));
    } else {
      setOperation(null);
      setState(store.workplaceStateStore.getById(log.stateId));
    }
  }, [log.stateId, log.operationId]);

  useMount(() => {
    setCreatedByUser(store.userStore.getById(log.createdBy));
  }, [log.createdBy]);

  useMount(() => {
    setUpdatedByUser(store.userStore.getById(log.updatedBy));
  }, [log.updatedBy]);

  const rows = [[t('state.historyModal.details.element'), state?.label || t('state.historyModal.personnel')]];
  if (state?.isInterruption) {
    const interruptionSourceHierarchy = store.hierarchyStore.getById(log.interruptionSourceHierarchyId);
    const interruptionClass = store.interruptionClassStore.getById(log.interruptionClassId);
    const interruptionReason = store.interruptionReasonStore.getById(log.interruptionReasonId);
    rows.push(
      [t('state.historyModal.details.interruptionSourceHierarchy'),
        interruptionSourceHierarchy?.title ? OperationStateLog.getInterruptionSourceHierarchyPath({...log,
          operation,
          interruptionSourceHierarchy}, true) : EnDash()],
      [t('state.historyModal.details.interruptionClass'), interruptionClass?.label || EnDash()],
      [t('state.historyModal.details.interruptionReason'), interruptionReason?.label || EnDash()],
      [t('state.historyModal.details.interruptionMessage'), log?.message || EnDash()]
    );
  }
  if (operation) {
    rows.push(
      [t('state.historyModal.details.orderNo'), operation?.order?.no],
      [t('state.historyModal.details.materialNo'), operation?.order?.material?.no],
      [t('state.historyModal.details.materialname'), operation?.order?.material?.name]
    );
  }
  rows.push(
    [t('state.historyModal.details.creator'), <UserFullName user={createdByUser}/>],
    [t('state.historyModal.details.editor'), log.end || nextLog?.createdAt
      ? <UserFullName user={updatedByUser}/> : EnDash()],
    [t('state.historyModal.details.start'), dayjsStart.format(detailedDatetimeFormat)],
    [
      t('state.historyModal.details.end'),
      log.end || nextLog?.createdAt ? dayjsEnd.format(detailedDatetimeFormat) : EnDash(),
    ],
    [t('state.historyModal.details.duration'), formatDuration(duration, detailedDurationFormat)]
  );
  if (log.personnel !== undefined && log.personnel !== null) {
    rows.push(
      [t('state.historyModal.details.actual'), log.personnel]
    );
  }
  if (log.phaseTimeoutReason) {
    rows.push(
      [t('state.historyModal.details.timeout'), log.phaseTimeoutReason.label]
    );
  } else if (log.timeoutMessage !== null) {
    rows.push(
      [t('state.historyModal.details.timeout'), log.timeoutMessage]
    );
  }
  return (
    <KeyValueTable rows={rows}/>
  );
};

export default observer(LogDetails);
