import React from 'react';
import {observer} from 'mobx-react-lite';

const Section = ({title, children}) => (
  <div style={{marginBottom: 16}}>
    <div className="ant-descriptions-header">
      <div className="ant-descriptions-title">{title}</div>
    </div>
    {children}
  </div>
);

export default observer(Section);
