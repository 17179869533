import axios from 'axios';
import {rootStoreInstance} from '../stores/rootStore';
import {refreshUrl} from './endpoints/auth';

const instance = axios.create({});

// intercept all requests to add the auth headers
instance.interceptors.request.use((config) => {
  // eslint-disable-next-line no-param-reassign
  config.headers = {
    ...config.headers,
    ...rootStoreInstance.authStore.getRequestHeaders(),
  };

  return config;
}, (error) => Promise.reject(error));

// intercept all api responses
instance.interceptors.response.use((response) => response, (error) => {
  if (error.response && error.response.status === 401 && !error.config.url.endsWith(refreshUrl)) {
    rootStoreInstance.authStore.validateStorageToken();
  }
  return Promise.reject(error);
});

export default instance;
