import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import styles from './Sender.module.scss';
import TextParagraph from '../../shared/typography/TextParagraph';
import SubTitleWithIcon from '../../shared/typography/SubTitleWithIcon';
import UserFullName from '../../shared/UserFullName';

const Sender = ({sender}) => {
  const {t} = useTranslation();

  return (
    <div className={styles.sender}>
      <SubTitleWithIcon
        icon={<FontAwesomeIcon icon={faPaperPlane} style={{marginRight: 8}}/>}
        text={t('message.model.attributes.sender')}
      />
      <TextParagraph text={<UserFullName user={sender}/>} fontWeight={500}/>
    </div>
  );
};

export default observer(Sender);
