import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {get, groupBy, isEmpty} from 'lodash';
import dayjs from 'dayjs';
import List from '../../shared/lists/List';
import Collapse from '../../shared/collapse/Collapse';
import IngredientStockTable from './IngredientStockTable';
import StorageLocationDetailsModal from './StorageLocationDetailsModal';
import {aggregatedStorageLocationsTableColumns, storageLocationDetailsColumns} from './IngredientColumns';
import {dateFormat} from '../../../config/dayjs';

const {Panel} = Collapse;

const IngredientDetails = ({ingredient, collapsed}) => {
  const {t} = useTranslation();
  const [selectedStockItem, setSelectedStockItem] = useState(null);

  const getItem = (key, path, format) => ({
    label: t(`barcodeInfo.attribute.${key}.label`),
    path,
    format,
  });

  const items = [
    getItem('description', ['material', 'name']),
    getItem('no', ['no']),
    getItem('batchNo', ['batch', 'no']),
    getItem('expiryDate', ['expiryDate'], (value) => (value
      ? dayjs(value).format(dateFormat)
      : '-')),
    getItem('release', ['locked'], (value) => (value
      ? t('barcodeInfo.attribute.release.locked')
      : t('barcodeInfo.attribute.release.free'))),
    getItem('amount', ['amount']),
  ];

  const getAggregatedStock = (stock) => {
    const stockCpy = stock.map((c) => ({...c}));
    const stockGroupedByStorageLocationAndBatchNo = Object
      .values(groupBy(stockCpy, (c) => `${c.storageLocation}-${c.batch?.no}`));
    return stockGroupedByStorageLocationAndBatchNo.map((stockGroup) =>
      stockGroup.reduce((acc, currentStock) => {
        if (isEmpty(acc)) {
          acc = currentStock;
        } else {
          acc.amount += currentStock.amount;
        }
        return acc;
      }, {}));
  };

  const getStockOfSelectedStorageLocation = () => ingredient.stock
    .filter((stockEntry) => stockEntry.storageLocation === selectedStockItem?.storageLocation
      && stockEntry.batch?.no === selectedStockItem?.batch?.no);

  const onShowDetailsClick = (record) => {
    setSelectedStockItem(record);
  };

  return (
    <Collapse
      defaultActiveKey={collapsed ? [] : ['1']}
      style={{marginBottom: '1em'}}
    >
      <Panel header={ingredient.material.name} key={1}>
        <List
          size="small"
          itemLayout="horizontal"
          dataSource={items}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={item.label}
                description={item.format
                  ? item.format(get(ingredient, item.path))
                  : get(ingredient, item.path)}
              />
            </List.Item>
          )}
        />
        <IngredientStockTable
          ingredientStock={getAggregatedStock(ingredient.stock)}
          columns={aggregatedStorageLocationsTableColumns(onShowDetailsClick)}
        />
        <StorageLocationDetailsModal
          open={selectedStockItem}
          storageLocation={selectedStockItem?.storageLocation}
          ingredientStock={getStockOfSelectedStorageLocation()}
          columns={storageLocationDetailsColumns}
          onCancel={() => setSelectedStockItem(null)}
        />
      </Panel>
    </Collapse>
  );
};

export default IngredientDetails;
