import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBarcode} from '@fortawesome/free-solid-svg-icons';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import QRCodeReader from '../../shared/QRCodeReader';
import Button from '../../shared/buttons/Button';
import AlertWarning from '../../shared/alert/AlertWarning';

const LocationCodeChecker = ({locationCode, onMatch}) => {
  const {t} = useTranslation();
  const [scanning, setScanning] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  if (!locationCode) {
    return null;
  }

  return (
    <>
      {scanning
        ? (
          <QRCodeReader
            id={`video-${locationCode}`}
            style={{width: '100%'}}
            onFound={(result) => {
              setScanning(false);
              if (result.text === locationCode) {
                onMatch();
              } else {
                setShowWarning(true);
              }
            }}
          />
        )
        : (
          <Button
            size={'large'}
            onClick={() => { setScanning(true); }}
          >
            <FontAwesomeIcon icon={faBarcode} style={{marginRight: '0.5em'}}/>
            {t('inspectionTaskPendingModal.taskBox.scan')}
          </Button>
        )}
      {showWarning
      && (
        <AlertWarning
          showIcon
          type={'error'}
          message={t('inspectionTaskPendingModal.taskBox.scanErrorMessage')}
        />
      )}
    </>
  );
};

export default LocationCodeChecker;
