import { computed, makeObservable, observable } from 'mobx';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { RootStore } from '../stores/rootStore';

export class InspectionPlan extends BaseModel {
  id: number = 0;
  name: string = '';
  description: string | null = null;
  enabled: boolean = false;
  cronPattern: string | null = null;
  inspectionTaskIds: number[] = [];
  hierarchyIds: number[] = [];
  materialIds: number[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      description: observable,
      enabled: observable,
      cronPattern: observable,
      inspectionTaskIds: observable,
      inspectionTasks: computed,
      hierarchyIds: observable,
      hierarchies: computed,
      materialIds: observable,
      materials: computed,
    });
  }

  static faIcon = faClipboardList;

  searchableProperties = ['id', 'name', 'description'];

  displayableProperties = [
    displayableProperty({
      key: 'name',
      params: [displayablePropertyParam({ path: 'name' })],
      template: '{value}',
      title: i18n.t('inspectionPlan.model.attributes.name'),
      raw: false,
    }),
  ];

  get hierarchies() {
    return this.rootStore.hierarchyStore.getByIds(this.hierarchyIds);
  }

  get inspectionTasks() {
    return this.rootStore.inspectionTaskStore.getByIds(this.inspectionTaskIds);
  }

  get materials() {
    return this.rootStore.materialStore.getByIds(this.materialIds);
  }

  static prepareApiPayload(model: Partial<InspectionPlan>): Partial<InspectionPlan> {
    return {
      ...model,
      description: model.description || null,
      cronPattern: model.cronPattern || null,
    };
  }
}
