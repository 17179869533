import dayjs from 'dayjs';
import {
  daysDurationFormat,
  detailedDurationFormat,
  durationFormat,
  durationFormatWithDays,
  minutesDurationFormat,
  secondsDurationFormat,
} from '../config/dayjs';

/**
 * Formats a dayjs duration
 * @param {plugin.Duration} duration
 * @param {string} format
 * @return {string}
 */
export const formatDuration = (duration, format) => {
  let preparedDuration;
  const absoluteDuration = dayjs.duration(Math.abs(duration.asMilliseconds()), 'ms');
  const isNegative = duration.asMilliseconds() < 0;
  switch (format) {
    case daysDurationFormat: {
      preparedDuration = dayjs.duration({
        seconds: absoluteDuration.seconds(),
        minutes: absoluteDuration.minutes(),
        hours: absoluteDuration.hours(),
        days: Math.floor(absoluteDuration.asDays()),
        months: 0,
        years: 0,
      });
      break;
    }
    case durationFormat: {
      preparedDuration = dayjs.duration({
        seconds: absoluteDuration.seconds(),
        minutes: absoluteDuration.minutes(),
        hours: Math.floor(absoluteDuration.asHours()),
        days: 0,
        months: 0,
        years: 0,
      });
      break;
    }
    case minutesDurationFormat: {
      preparedDuration = dayjs.duration({
        seconds: absoluteDuration.seconds(),
        minutes: Math.floor(absoluteDuration.asMinutes()),
        hours: 0,
        days: 0,
        months: 0,
        years: 0,
      });
      break;
    }
    case secondsDurationFormat: {
      preparedDuration = dayjs.duration({
        milliseconds: absoluteDuration.milliseconds(),
        seconds: Math.floor(absoluteDuration.asSeconds()),
        minutes: 0,
        hours: 0,
        days: 0,
        months: 0,
        years: 0,
      });
      break;
    }
    case durationFormatWithDays: {
      preparedDuration = dayjs.duration({
        seconds: absoluteDuration.seconds(),
        minutes: absoluteDuration.minutes(),
        hours: absoluteDuration.hours(),
        days: Math.floor(absoluteDuration.asDays()),
        months: 0,
        years: 0,
      });
      break;
    }
    case detailedDurationFormat: {
      preparedDuration = dayjs.duration({
        seconds: absoluteDuration.seconds(),
        minutes: absoluteDuration.minutes(),
        hours: Math.floor(absoluteDuration.asHours()),
        days: 0,
        months: 0,
        years: 0,
      });
      break;
    }
    default: {
      preparedDuration = absoluteDuration;
    }
  }
  return `${isNegative ? '-' : ''}${preparedDuration.format(format)}`;
};

/**
 * Formats a dayjs duration, with reduced unit count, based on overall length of the duration
 * @param {plugin.Duration} duration
 * @return {string}
 */
export const formatDurationShort = (duration) => {
  if (duration.days() > 0) {
    return formatDuration(duration, daysDurationFormat);
  }
  if (duration.hours() > 0) {
    return formatDuration(duration, durationFormat);
  }
  if (duration.minutes() > 0) {
    return formatDuration(duration, minutesDurationFormat);
  }
  return formatDuration(duration, secondsDurationFormat);
};
