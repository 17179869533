import { observable, makeObservable, computed } from 'mobx';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

/**
 * sensor data limit style translations:
 * t('sensorDataLimit.styles.LINE')
 * t('sensorDataLimit.styles.AREA')
 */
export enum SensorDataLimitStyle {
  LINE = 'LINE',
  AREA = 'AREA'
}

export class SensorDataLimit extends BaseModel {
  id: number = 0;
  sensorName: string = '';
  hierarchyId: number = 0;
  plausibleLow: number | null = null;
  toleranceLow: number | null = null;
  warningLow: number | null = null;
  plausibleHigh: number | null = null;
  toleranceHigh: number | null = null;
  warningHigh: number | null = null;
  toleranceStyle: SensorDataLimitStyle | null = null;
  warningStyle: SensorDataLimitStyle | null = null;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      sensorName: observable,
      sensor: computed,
      hierarchyId: observable,
      hierarchy: computed,
      plausibleLow: observable,
      toleranceLow: observable,
      warningLow: observable,
      plausibleHigh: observable,
      toleranceHigh: observable,
      warningHigh: observable,
      toleranceStyle: observable,
      warningStyle: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faChartLine;

  searchableProperties = ['sensorName', 'hierarchy.name'];

  static prepareApiPayload(model: SensorDataLimit) {
    return {
      id: model.id,
      sensorName: model.sensorName,
      hierarchyId: model.hierarchyId,
      plausibleLow: model.plausibleLow !== undefined ? model.plausibleLow : null,
      toleranceLow: model.toleranceLow !== undefined ? model.toleranceLow : null,
      warningLow: model.warningLow !== undefined ? model.warningLow : null,
      plausibleHigh: model.plausibleHigh !== undefined ? model.plausibleHigh : null,
      toleranceHigh: model.toleranceHigh !== undefined ? model.toleranceHigh : null,
      warningHigh: model.warningHigh !== undefined ? model.warningHigh : null,
      toleranceStyle: model.toleranceStyle || null,
      warningStyle: model.warningStyle || null,
    };
  }

  get sensor() {
    return this.rootStore.sensorStore.getById(this.sensorName);
  }

  get hierarchy() {
    return this.rootStore.hierarchyStore.getById(this.hierarchyId);
  }
}
