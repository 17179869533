import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { OrderTemplate } from '../models/orderTemplate';
import { RootStore } from './rootStore';

export class OrderTemplateStore extends EntityStore<OrderTemplate> {
  orderTemplates: OrderTemplate[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'orderTemplates', Api.orderTemplates, OrderTemplate, true);

    makeObservable(this, {
      orderTemplates: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.materialStore,
        modelId: 'materialId',
      },
      {
        store: this.rootStore.unitOfMeasurementStore,
        modelId: 'unitOfMeasureId',
      },
      {
        store: this.rootStore.workflowStore,
        modelId: 'workflowId',
      },
      {
        store: this.rootStore.operationStateStore,
        modelId: 'operationStateId',
      },
      {
        store: this.rootStore.workplaceStore,
        modelId: 'workplaceId',
      },
    ];
  }
}
