/**
 * t('kpiChartCockpitWidgetConfig.settings.kpi.legendPosition.bottom')
 * t('kpiChartCockpitWidgetConfig.settings.kpi.legendPosition.right')
 * t('kpiChartCockpitWidgetConfig.settings.kpi.legendPosition.none')
 */
export enum LegendPositionOptionTypes {
  bottom = 'bottom',
  right = 'right',
  none = 'none'
}
