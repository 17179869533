import {DatePicker as DatePickerDesktop} from 'antd';
import dayjs from 'dayjs';
import {useStore} from '../../hooks/useStore';
import {detailedDatetimeFormat} from '../../config/dayjs';
import Input from './inputs/Input';

export const DatePicker = ({format, ...props}) => {
  const store = useStore();

  if (store.clientStore.isMobile) {
    const {value, onChange, showTime, showToday, disabledDate, ...rest} = props;
    const date = value ? value.format('YYYY-MM-DDTHH:mm') : '';
    const wrapDate = (e) => {
      onChange(e.target.value ? dayjs(e.target.value) : null);
    };

    return (
      <Input type={showTime ? 'datetime-local' : 'date'} value={date} onChange={wrapDate} {...rest}/>
    );
  }

  return (
    <DatePickerDesktop format={format || detailedDatetimeFormat} {...props}/>
  );
};
