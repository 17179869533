import { computed, observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { ShiftBook } from '../models/shiftBook';
import { RootStore } from './rootStore';

export class ShiftBookStore extends EntityStore<ShiftBook> {
  shiftBooks: ShiftBook[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'shiftBooks', Api.shiftBooks, ShiftBook);

    makeObservable(this, {
      shiftBooks: observable,
      bySelectedWorkplace: computed,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.orderStore,
        modelId: 'orderId',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'createdBy',
      },
    ];
  }

  get bySelectedWorkplace() {
    const selectedWorkplaceId = this.rootStore.workplaceStore.selectedWorkplace?.id;
    if (selectedWorkplaceId) {
      return this.shiftBooks.filter((item) => item.workplaceId === selectedWorkplaceId);
    }
    return [];
  }
}
