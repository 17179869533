import * as React from 'react';
import {useContext, useState} from 'react';
import {observer} from 'mobx-react-lite';
import InspectionTaskBox from './InspectionTaskBox';
import {InspectionTaskContext} from './InspectionTaskExecutionModal';

const InspectionTaskExecutions = ({
  disabled,
  useUniformQualityIndicator,
  minRemarkLength,
  resultErrors,
  onBoxOpen,
}) => {
  const {rootForm, tasks} = useContext(InspectionTaskContext).contextValue;
  const [activeTaskIndex, setActiveTaskIndex] = useState(0);

  const openNext = () => {
    if (activeTaskIndex === -1 || activeTaskIndex === tasks.length - 1) {
      setActiveTaskIndex(undefined);
    } else {
      const resultKeys = Object.keys(rootForm.getFieldValue('results'));
      // eslint-disable-next-line no-restricted-syntax
      for (const [index, task] of tasks.entries()) {
        const hasResult = resultKeys.includes(task.key);
        if (!hasResult) {
          setActiveTaskIndex(index);
          onBoxOpen(task.inspectionTask.id);
          break;
        }
      }
    }
  };

  const handleOpen = (taskId) => {
    onBoxOpen(taskId);
  };

  return (
    <>
      {tasks.map((task, index) => (
        <InspectionTaskBox
          key={task.key}
          task={task}
          open={activeTaskIndex === index}
          openNext={openNext}
          disabled={disabled}
          useUniformQualityIndicator={useUniformQualityIndicator}
          minRemarkLength={minRemarkLength}
          resultError={resultErrors.get(task.key)}
          onOpen={() => handleOpen(task.inspectionTask.id)}
        />
      ))}
    </>
  );
};
export default observer(InspectionTaskExecutions);
