import dayjs from 'dayjs';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHourglassHalf} from '@fortawesome/free-solid-svg-icons';
import appConfig from '../../utils/appConfig';
import styles from './statusLine.module.scss';
import {formatDuration} from '../../utils/formatting';
import {durationFormat} from '../../config/dayjs';

const defaultColor = appConfig.corporateIdentity.aspectColor;

export const StatusLine = ({logs, style, operationStates, workplaceStates}) => {
  const currentLog = logs.find((log) => log.end === null);

  if (!currentLog) {
    return <div style={style}/>;
  }
  const duration = dayjs.duration(dayjs()
    .diff(dayjs(currentLog.start))) || 0;

  const state = currentLog.operationId
    ? operationStates.find((operationState) => operationState.id === currentLog?.stateId)
    : workplaceStates.find((workplaceState) => workplaceState.id === currentLog?.stateId);

  return (
    <div style={{...style}} className={styles.container}>
      <span style={{color: state?.color || defaultColor}} className={styles.circle}/>
      <div style={{whiteSpace: 'nowrap', flex: '3', overflow: 'hidden', textOverflow: 'ellipsis'}}>
        <span style={{fontSize: '1.4em', color: '#404040', fontWeight: 500, width: '100%'}} title={state?.label}>
          {state?.label}
        </span>
      </div>
      <span style={{
        flex: '2',
        whiteSpace: 'nowrap',
        fontFamily: '"Roboto Mono", monospace',
        fontWeight: 500,
        color: '#8E8E8E',
        marginLeft: '12px',
      }}
      >
        <FontAwesomeIcon icon={faHourglassHalf}/>
        {' '}
        {formatDuration(duration, durationFormat)}
      </span>
    </div>
  );
};
