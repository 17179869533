import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {EnDash} from '../../../shared/unicodeWrapper/EnDash';
import InspectionTaskDescription from './InspectionTaskDescription';
import ConfirmationValue from './ConfirmationValue';
import {transformation} from '../../../../utils/transformations';

const ControlSection = ({fallback, task}) => {
  const {t} = useTranslation();

  return (
    <InspectionTaskDescription title={t('inspectionTask.drilldownModal.section.control')}>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.mustBeAccepted.label')}
      >
        <ConfirmationValue bool={task.mustBeAccepted}/>
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.isOverruleAllowed.label')}
      >
        <ConfirmationValue bool={task.isOverruleAllowed}/>
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.isFourEyes.label')}
      >
        <ConfirmationValue bool={task.isFourEyes}/>
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.fourEyesDelay.label')}
      >
        {task.fourEyesDelay !== null ? transformation.duration({unit: 'minutes'})(task.fourEyesDelay) : EnDash}
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.fourEyesRoleId.label')}
      >
        {fallback(task.fourEyesRole?.name)}
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.isObligatory.label')}
      >
        <ConfirmationValue bool={task.isObligatory}/>
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.showUntilAccepted.label')}
      >
        <ConfirmationValue bool={task.showUntilAccepted}/>
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.showUntilConducted.label')}
      >
        <ConfirmationValue bool={task.showUntilConducted}/>
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.onceForAllVirtualWorkplaces.label')}
      >
        <ConfirmationValue bool={task.onceForAllVirtualWorkplaces}/>
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.isAutoValuate.label')}
      >
        <ConfirmationValue bool={task.isAutoValuate}/>
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.showOnSiblingVirtualWorkplaces.label')}
      >
        <ConfirmationValue bool={task.showOnSiblingVirtualWorkplaces}/>
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.isRemarkRequired.label')}
      >
        <ConfirmationValue bool={task.isRemarkRequired}/>
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item label={t('inspectionTask.model.attributes.isSilent.label')}>
        <ConfirmationValue bool={task.isSilent}/>
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item label={t('inspectionTask.model.attributes.isSilentNotAccepted.label')}>
        <ConfirmationValue bool={task.isSilentNotAccepted}/>
      </InspectionTaskDescription.Item>
    </InspectionTaskDescription>
  );
};

export default observer(ControlSection);
