import React from 'react';
import { Form, FormItemProps } from 'antd';
import styles from './FilterFormItem.module.scss';

export type FilterFormItemProps = FormItemProps;

export const FilterFormItem: React.FC<FilterFormItemProps> = ({ labelAlign = 'left', colon = false, ...props }) => {
  const { children } = props;

  return (
    <Form.Item className={styles.formItem} labelAlign={labelAlign} colon={colon} labelCol={{ span: 24 }} {...props}>
      {children}
    </Form.Item>
  );
};
