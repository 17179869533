import React, { useCallback } from 'react';
import { throttle } from 'lodash';
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select';
import Select, { SelectProps } from './Select';

export type MultiSelectProps<ValueType, OptType extends BaseOptionType | DefaultOptionType = DefaultOptionType> = {
  onSelectionChange: (value: ValueType[]) => void;
  throttleTime?: number;
} & SelectProps<ValueType, OptType>;

const MultiSelect: (<ValueType, OptType extends BaseOptionType | DefaultOptionType = DefaultOptionType>(
  props: MultiSelectProps<ValueType, OptType>,
) => React.ReactNode) = <ValueType, OptType extends BaseOptionType = BaseOptionType>({
  onSelectionChange,
  throttleTime = 100,
  ...props
}: MultiSelectProps<ValueType, OptType>) => {
  const onChangeHandler = useCallback(
    throttle((selectedValues) => onSelectionChange && onSelectionChange(selectedValues), throttleTime),
    [onSelectionChange, throttleTime]
  );

  return <Select<ValueType, OptType> mode="multiple" style={{ width: '100%' }} onChange={onChangeHandler} {...props}/>;
};

export default MultiSelect;
