import {useState} from 'react';
import {Col, Row} from 'antd';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHistory} from '@fortawesome/free-solid-svg-icons';
import Modal from '../../shared/modal/Modal';
import Form from '../../shared/form/Form';
import {ValidationRules} from '../../../utils/validationRules';
import Input from '../../shared/inputs/Input';
import DefaultFormActions from '../../shared/form/DefaultFormActions';
import {useMount} from '../../../hooks/useMount';
import {useStore} from '../../../hooks/useStore';
import {EnDash} from '../../shared/unicodeWrapper/EnDash';

const InspectionTaskQuantitativeTargetValuesModal = ({task, onClose}) => {
  const store = useStore();
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState();

  const onSubmit = (values) => {
    setIsSaving(true);
    store.inspectionTaskStore.updateQuantitativeTargetValues({
      id: task.id,
      ...values,
    }).then(() => {
      onClose();
    }).finally(() => setIsSaving(false));
  };

  useMount(() => {
    const quantitative = task?.quantitative;
    if (quantitative) {
      form.setFieldsValue({...quantitative, editedRemark: null});
    }
  }, [task]);

  const InputWithOldValue = ({oldValue, ...props}) => (
    <Row type={'flex'}>
      <Col flex={'auto'}>
        <Input type={'number'} {...props}/>
      </Col>
      <Col offset={1} style={{opacity: 0.5, lineHeight: '31px'}} title={t('inspectionTask.targetValuesModal.oldValue')}>
        <FontAwesomeIcon icon={faHistory} style={{marginRight: '0.2em'}}/>
        {oldValue !== undefined ? oldValue : EnDash()}
      </Col>
    </Row>
  );

  return (
    <Modal
      title={t('inspectionTask.targetValuesModal.title')}
      open
      onCancel={onClose}
      footer={false}
    >
      <Row style={{marginBottom: '2em'}}>
        <Col span={8}>
          {t('inspectionTask.model.one')}
        </Col>
        <Col span={16}>
          {task?.name || '-'}
        </Col>
      </Row>
      <Form
        form={form}
        onFinish={onSubmit}
      >
        <Form.Item
          name={'desiredValue'}
          label={t('inspectionTaskQuantitative.model.attributes.desiredValue.label')}
        >
          <InputWithOldValue oldValue={task?.quantitative?.desiredValueOld}/>
        </Form.Item>
        <Form.Item
          name={'decimalPlaces'}
          label={t('inspectionTaskQuantitative.model.attributes.decimalPlaces.label')}
          rules={[ValidationRules.required()]}
        >
          <InputWithOldValue oldValue={task?.quantitative?.decimalPlacesOld}/>
        </Form.Item>
        <Form.Item
          name={'plausibleLow'}
          label={t('inspectionTaskQuantitative.model.attributes.plausibleLow.label')}
        >
          <InputWithOldValue oldValue={task?.quantitative?.plausibleLowOld}/>
        </Form.Item>
        <Form.Item
          name={'toleranceLow'}
          label={t('inspectionTaskQuantitative.model.attributes.toleranceLow.label')}
        >
          <InputWithOldValue oldValue={task?.quantitative?.toleranceLowOld}/>
        </Form.Item>
        <Form.Item
          name={'warningLow'}
          label={t('inspectionTaskQuantitative.model.attributes.warningLow.label')}
        >
          <InputWithOldValue oldValue={task?.quantitative?.warningLowOld}/>
        </Form.Item>
        <Form.Item
          name={'warningHigh'}
          label={t('inspectionTaskQuantitative.model.attributes.warningHigh.label')}
        >
          <InputWithOldValue oldValue={task?.quantitative?.warningHighOld}/>
        </Form.Item>
        <Form.Item
          name={'toleranceHigh'}
          label={t('inspectionTaskQuantitative.model.attributes.toleranceHigh.label')}
        >
          <InputWithOldValue oldValue={task?.quantitative?.toleranceHighOld}/>
        </Form.Item>
        <Form.Item
          name={'plausibleHigh'}
          label={t('inspectionTaskQuantitative.model.attributes.plausibleHigh.label')}
        >
          <InputWithOldValue oldValue={task?.quantitative?.plausibleHighOld}/>
        </Form.Item>
        <Form.Item
          name={'editedRemark'}
          label={t('inspectionTaskQuantitative.model.attributes.editedRemark')}
          rules={[ValidationRules.required()]}
        >
          <Input.TextArea maxLength={255} placeholder={task?.quantitative?.editedRemark || ''}/>
        </Form.Item>
        <DefaultFormActions
          saving={isSaving}
          onCancel={onClose}
        />
      </Form>
    </Modal>
  );
};

export default observer(InspectionTaskQuantitativeTargetValuesModal);
