import Highcharts from 'highcharts';
import highchartsXrange from 'highcharts/modules/xrange';
import i18n from '../i18n/i18n';

export const chartTranslations = () => ({
  decimalPoint: i18n.t('chart.lang.decimalPoint'),
  thousandsSep: i18n.t('chart.lang.thousandsSep'),
  loading: i18n.t('chart.lang.loading'),
  months: i18n.t('chart.lang.months').split(','),
  weekdays: i18n.t('chart.lang.weekdays').split(','),
  shortMonths: i18n.t('chart.lang.shortMonths').split(','),
  shortWeekdays: i18n.t('chart.lang.shortWeekdays').split(','),
  resetZoom: i18n.t('chart.lang.resetZoom'),
  resetZoomTitle: i18n.t('chart.lang.resetZoomTitle'),
});

highchartsXrange(Highcharts);

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';

Highcharts.setOptions({
  lang: chartTranslations(),
  time: {
    timezone: tz,
    useUTC: false,
  },
});
