import { computed, makeObservable, observable } from 'mobx';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { RootStore } from '../stores/rootStore';

/**
 * t('printJob.model.states.INIT')
 * t('printJob.model.states.PRINTING')
 * t('printJob.model.states.FINISHED')
 */
export enum PrintJobState {
  INIT = 'INIT',
  PRINTING = 'PRINTING',
  FINISHED = 'FINISHED'
}

export const PrintAllocationSettingsIdentifier = 'Office.PrintAllocationWidget.settings';

export class PrintJob extends BaseModel {
  id: number = 0;
  printerId: number = 0;
  operationId: number = 0;
  printLabelId: number = 0;
  plannedAmount: number = 0;
  actualAmount: number = 0;
  state: PrintJobState = PrintJobState.INIT;
  documentId: number | null = null;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      printerId: observable,
      printer: computed,
      operationId: observable,
      operation: computed,
      printLabelId: observable,
      printLabel: computed,
      plannedAmount: observable,
      actualAmount: observable,
      state: observable,
      documentId: observable,
      document: computed,
    });
  }

  static faIcon = faPrint;

  searchableProperties = ['operation.order.no', 'printLabel.name', 'operation.material.label', 'printer.label'];

  saveableProperties = ['printerId', 'operationId', 'printLabelId', 'plannedAmount', 'actualAmount', 'state',
    'documentId'];

  displayableProperties = [
    displayableProperty({
      key: 'actualAmount',
      title: i18n.t('printJob.model.attributes.actualAmount.label'),
      params: [displayablePropertyParam({ path: 'actualAmount' })],
      template: '{value}',
    }),
  ];

  get printer() {
    return this.rootStore.printerStore.getById(this.printerId);
  }

  get operation() {
    return this.rootStore.operationStore.getById(this.operationId);
  }

  get printLabel() {
    return this.rootStore.printLabelStore.getById(this.printLabelId);
  }

  get document() {
    return this.rootStore.documentStore.getById(this.documentId);
  }
}
