import {executeDelete, executeGet, executePost, executePut} from '../requests';

const resourcesName = 'v1/app-config';

const updateImageEndpoint = `${resourcesName}/image`;
export const updateImage = 'updateImage';
export const deleteImage = 'deleteImage';

const updateVideo1Endpoint = `${resourcesName}/video1`;
export const updateVideo1 = 'updateVideo1';
export const deleteVideo1 = 'deleteVideo1';

const updateVideo2Endpoint = `${resourcesName}/video2`;
export const updateVideo2 = 'updateVideo2';
export const deleteVideo2 = 'deleteVideo2';

const updateVideo3Endpoint = `${resourcesName}/video3`;
export const updateVideo3 = 'updateVideo3';
export const deleteVideo3 = 'deleteVideo3';

const updateVideo4Endpoint = `${resourcesName}/video4`;
export const updateVideo4 = 'updateVideo4';
export const deleteVideo4 = 'deleteVideo4';

const updateCustomCssEndpoint = `${resourcesName}/custom-css`;
export const updateCustomCss = 'updateCustomCss';
export const deleteCustomCss = 'deleteCustomCss';

export const AppConfigApi = {
  load: () => executeGet(resourcesName),
  update: (entity) => executePut(`${resourcesName}`, entity),
  [updateImage]: (body) => executePost(updateImageEndpoint, body),
  [deleteImage]: (body) => executeDelete(updateImageEndpoint, {data: body}),
  [updateVideo1]: (body) => executePost(updateVideo1Endpoint, body),
  [deleteVideo1]: (body) => executeDelete(updateVideo1Endpoint, {data: body}),
  [updateVideo2]: (body) => executePost(updateVideo2Endpoint, body),
  [deleteVideo2]: (body) => executeDelete(updateVideo2Endpoint, {data: body}),
  [updateVideo3]: (body) => executePost(updateVideo3Endpoint, body),
  [deleteVideo3]: (body) => executeDelete(updateVideo3Endpoint, {data: body}),
  [updateVideo4]: (body) => executePost(updateVideo4Endpoint, body),
  [deleteVideo4]: (body) => executeDelete(updateVideo4Endpoint, {data: body}),
  [updateCustomCss]: (body) => executePost(updateCustomCssEndpoint, body),
  [deleteCustomCss]: (body) => executeDelete(updateCustomCssEndpoint, {data: body}),
};
