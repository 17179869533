import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { InspectionTaskGroup } from '../models/inspectionTaskGroup';
import { RootStore } from './rootStore';

export class InspectionTaskGroupStore extends EntityStore<InspectionTaskGroup> {
  inspectionTaskGroups: InspectionTaskGroup[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'inspectionTaskGroups', Api.inspectionTaskGroup, InspectionTaskGroup, true);

    makeObservable(this, {
      inspectionTaskGroups: observable,
    });
  }
}
