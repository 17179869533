import {useCallback, useState} from 'react';
import {debounce} from 'lodash';
import {Form} from 'antd';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {useStore} from '../../../hooks/useStore';
import ScanInput from '../../shared/inputs/ScanInput';
import {FormItemLayouts} from '../../shared/form/formItemLayouts';

const ScanIngredient = ({
  ingredient,
  onChangeIngredient,
  autoFocus,
  disabled,
}) => {
  const [form] = Form.useForm();
  const store = useStore();
  const {t} = useTranslation();
  const [isValid, setIsValid] = useState(false);

  const queryByNo = async (queryNo) => {
    const operationId = await store.operationStore.active?.id;
    store.ingredientStore.loadByNo(queryNo, operationId, true).then((ing) => {
      setIsValid(false);
      if (queryNo === form.getFieldValue('ingredientNo')) {
        setIsValid(!!ing);
      }
      if (ing === null) {
        onChangeIngredient(null, null);
      }
    });
  };
  const debouncedQuery = useCallback(debounce((q) => queryByNo(q), 500), []);
  const handleValuesChange = (changedValues) => {
    setIsValid(false);
    if (changedValues?.ingredientNo) {
      debouncedQuery(changedValues?.ingredientNo);
    }
  };

  const addIngredient = async (ingredientNo, isScanned) => {
    debouncedQuery.cancel();
    const operationId = await store.operationStore.active?.id;
    store.ingredientStore.loadByNo(ingredientNo, operationId, true).then((ingredientToAdd) => {
      setIsValid(false);
      if (ingredientToAdd) {
        onChangeIngredient(ingredientToAdd, isScanned);
        form.setFieldsValue({ingredientNo: null});
      }
    });
  };

  return (
    <Form
      form={form}
      onValuesChange={handleValuesChange}
      labelAlign={'left'}
      {...FormItemLayouts.fullWidth}
    >
      <Form.Item
        name={'ingredientNo'}
        style={{marginBottom: '1em'}}
        initialValue={ingredient?.no}
      >
        <ScanInput
          autoFocus={autoFocus}
          isValid={isValid}
          onAccept={addIngredient}
          placeholder={t('batchHandling.enterBarcode')}
          disabled={disabled}
        />
      </Form.Item>
    </Form>
  );
};

export default observer(ScanIngredient);
