import React, {useState} from 'react';
import Highcharts from 'highcharts';
import {observer} from 'mobx-react-lite';
import {difference} from 'lodash';
import {useStore} from '../../../../hooks/useStore';
import Chart from '../../../shared/charts/Chart';
import {useMount} from '../../../../hooks/useMount';

const KpiPerOrderChart = ({workplaceId, yAxisTitle, daysBack, fromDate, toDate, barKpiId, lineKpiIds}) => {
  const store = useStore();
  const [barChartData, setBarChartData] = useState();
  const [lineChartsData, setLineChartsData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useMount(() => {
    (async () => {
      setIsLoading(true);
      await store.keyPerformanceIndicatorStore.loadAll();
      const orders = await store.orderStore.loadAll({
        params: {
          activeFrom: fromDate,
          activeTo: toDate,
          activeWorkplaceId: workplaceId,
        },
      });

      const data = [];
      const kpiIds = [barKpiId, ...lineKpiIds];
      kpiIds.forEach((kpiId) => {
        data[kpiId] = [];
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const order of orders) {
        // eslint-disable-next-line no-await-in-loop
        const result = await store.keyPerformanceIndicatorStore.loadKpiByOrder({
          workplaceId,
          orderId: order.id,
          resolution: daysBack,
          kpiId: kpiIds,
        });

        const resultKpiIds = result.map((lineKpi) => lineKpi.kpiId);
        const missingKpiIds = difference(kpiIds, resultKpiIds);
        missingKpiIds.forEach((kpiId) => {
          data[kpiId].push({
            kpi: store.keyPerformanceIndicatorStore.getById(kpiId),
            order,
            y: 0,
          });
        });

        result.forEach((kpi) => {
          /** @type {KeyPerformanceIndicator} */
          const realKpi = store.keyPerformanceIndicatorStore.getById(kpi.kpiId);
          data[kpi.kpiId].push({
            kpi: realKpi,
            order,
            y: kpi.data.reduce((acc, curr) => acc + realKpi.calculateToPercentageValue(curr.y), 0),
          });
        });
      }

      const barData = data.filter((e, idx) => idx === barKpiId).filter((e) => !!e)[0];
      const lineData = data.filter((e, idx) => idx !== barKpiId).filter((e) => !!e);

      setBarChartData(barData);
      setLineChartsData(lineData);
      setIsLoading(false);
    })();
  }, [daysBack, barKpiId, lineKpiIds?.join(''), workplaceId]);

  let dataMax = null;
  barChartData?.forEach((d) => {
    if ((dataMax === null || d.y > dataMax) && d.y !== null) {
      dataMax = d.y;
    }
  });

  const plotBands = [];
  const plotLines = [];

  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    series: [
      {
        type: 'column',
        name: barChartData?.length ? barChartData[0].kpi.name : null,
        data: barChartData?.length
          ? barChartData.map((entry) => Number(entry.y.toFixed(2)))
          : [],
      },
    ],
    xAxis: {
      categories: barChartData?.length
        ? barChartData.map((entry) => entry.order.no || '')
        : [],
    },
    yAxis: {
      plotBands,
      plotLines,
      title: {
        text: yAxisTitle || null,
      },
      showEmpty: false,
    },
    legend: {
      layout: 'vertical',
      align: 'right',
    },
    tooltip: {
      formatter() {
        const {x, y, series: {name}} = this;
        const {kpi} = barChartData[0];
        const displayableValue = kpi.getDisplayableValue(y);
        return `<b>${name}</b><br/>${x}: ${displayableValue}`;
      },
    },
  };

  if (lineChartsData) {
    options.series = [
      ...options.series,
      ...lineChartsData.map((line) => ({
        name: line?.length ? line[0].kpi.name : null,
        type: 'line',
        tooltip: {
          formatter() {
            const {x, y, series: {name}} = this;
            const {kpi} = line[0];
            const displayableValue = kpi.getDisplayableValue(y);
            return `<b>${name}</b><br/>${x}: ${displayableValue}`;
          },
        },
        data: line.length
          ? line.map((entry) => Number(entry.y.toFixed(2)))
          : [],
      })),
    ];
  }

  return (
    <Chart options={options} highcharts={Highcharts} isLoading={isLoading}/>
  );
};

export default observer(KpiPerOrderChart);
