import {observer} from 'mobx-react-lite';
import {Col, Row, Space} from 'antd';
import {useTranslation} from 'react-i18next';
import appConfig from '../../../utils/appConfig';
import Table from '../../shared/tables/Table';
import {TableColumns} from '../../shared/tables/columns';
import AddButton from '../../shared/buttons/AddButton';
import {useStore} from '../../../hooks/useStore';
import {useScopes} from '../../../hooks/useScopes';
import {useModulePolicy} from '../../../hooks/useModulePolicy';
import StorageUnitSelector from '../../shared/inputs/autocomplete/StorageUnitSelector';
import {EnDash} from '../../shared/unicodeWrapper/EnDash';
import EditButton from '../../shared/buttons/EditButton';

const StorageUnitStackTable = ({
  storageUnits,
  rootStorageUnitId,
  setRootStorageUnitId,
  loading,
  highlightedRecordId,
  setHighlightedRecordId,
  widgetConfig,
  onAdd,
  onEdit,
  onAppend,
  enableAutofocus = true,
  onAppendUnknown,
}) => {
  const store = useStore();
  const scopes = useScopes();
  const policy = useModulePolicy(store, scopes);
  const {t} = useTranslation();

  const handleChange = (unitNo) => {
    setRootStorageUnitId(unitNo);
    setHighlightedRecordId(unitNo);
  };

  const handleClear = () => {
    setRootStorageUnitId(null);
    setHighlightedRecordId(null);
  };

  const handleUnknownRootStorageUnit = (unitNo) => {
    store.flashMessageStore.addFlashMessage({
      type: 'warning',
      title: `${t('StorageUnitStackWidget.rootUnknown')}: ${unitNo}`,
    });
    setRootStorageUnitId(null);
    setHighlightedRecordId(null);
  };

  return (
    <Space size={'middle'} direction={'vertical'} style={{display: 'flex'}}>
      <Row type={'flex'} justify={'space-between'}>
        <Col xl={16} md={16} sm={16} xs={24}>
          <Space direction={'horizontal'}>
            <StorageUnitSelector
              style={{minWidth: 300, display: 'flex'}}
              onChange={handleChange}
              onClear={handleClear}
              onPressEnter={handleUnknownRootStorageUnit}
              autoFocus={enableAutofocus && !rootStorageUnitId}
              disableExactMatch
            />
            <div>
              {/* eslint-disable-next-line max-len */}
              {`${t('StorageUnitStackWidget.selectedRoot')} ${store.storageUnitStore.getById(rootStorageUnitId) ? store.storageUnitStore.getById(rootStorageUnitId)?.no : EnDash()}` }
            </div>
          </Space>
        </Col>
        <Col>
          <Space>
            {policy.canEdit()
              ? (
                <EditButton
                  onClick={() => onEdit(store.storageUnitStore.getById(rootStorageUnitId))}
                  message={t('links.edit')}
                  type={'primary'}
                  disabled={!rootStorageUnitId}
                />
              ) : null}
            {policy.canCreate()
              ? <AddButton onClick={onAdd} type={'primary'}/> : null}
          </Space>
        </Col>
      </Row>
      <Table
        className={'storage-unit-stack-table'}
        loading={loading}
        size={'middle'}
        columns={[
          TableColumns.id(),
          ...widgetConfig.getTableColumns(),
          {
            dataIndex: '',
            key: 'actions',
            align: 'right',
            render: (record) => (
              <Space>
                {policy.canEdit() && <EditButton onClick={() => onEdit(record)}/>}
              </Space>
            ),
          },
        ]}
        dataSource={storageUnits}
        rowKey={'id'}
        onRow={(record) => ({
          className: record.id === highlightedRecordId ? 'no-hover' : null,
          style: {
            backgroundColor: record.id === highlightedRecordId ? appConfig.corporateIdentity.aspectColor : 'unset',
          },
        })}
        pagination={false}
      />
      <Row type={'flex'} justify={'left'}>
        <Space direction={'horizontal'}>
          <Col>
            <div>
              {`${t('StorageUnitStackWidget.addToStack')}` }
            </div>
          </Col>
          <Col>
            <StorageUnitSelector
              style={{width: 300, display: 'flex'}}
              onChange={onAppend}
              onPressEnter={onAppendUnknown}
              disabled={!rootStorageUnitId}
              autoFocus={enableAutofocus && rootStorageUnitId}
              disableExactMatch
            />
          </Col>
        </Space>
      </Row>
    </Space>
  );
};

export default observer(StorageUnitStackTable);
