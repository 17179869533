import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import Form from '../shared/form/Form';
import Input from '../shared/inputs/Input';
import {ValidationRules} from '../../utils/validationRules';
import {EnDash} from '../shared/unicodeWrapper/EnDash';
import DefaultFormActions from '../shared/form/DefaultFormActions';
import Select from '../shared/inputs/Select';
import {useStore} from '../../hooks/useStore';
import {sortAlphabetically} from '../shared/tables/sorters';
import {useMount} from '../../hooks/useMount';

const ManualSensorDataWidgetForm = ({sensors, onSubmit, onCancel, saving}) => {
  const [form] = Form.useForm();
  const {t} = useTranslation();
  const store = useStore();
  const [rerender, setRerender] = useState(false);

  useMount(() => {
    if (sensors.length === 1) {
      form.setFieldsValue({sensorName: sensors[0].name});
    }
  }, [sensors]);

  const getUnit = () => {
    const sensor = store.sensorStore.getById(form.getFieldValue('sensorName'));
    if (!sensor) {
      return null;
    }
    return sensor.unitOfMeasure?.label;
  };

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      onFieldsChange={() => setRerender(!rerender)}
    >
      <Form.Item
        name={'sensorName'}
        rules={[ValidationRules.required()]}
        label={t('manualSensorDataWidget.sensor')}
      >
        <Select
          size={'large'}
        >
          {sensors.sort((a, b) => sortAlphabetically(a.label, b.label)).map((sensor) => (
            <Select.Option value={sensor.name} key={sensor.name}>
              {sensor.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={'value'}
        rules={[ValidationRules.required()]}
        label={t('manualSensorDataWidget.value')}
      >
        <Input
          type={'number'}
          addonAfter={getUnit() || EnDash()}
          size={'large'}
        />
      </Form.Item>
      <DefaultFormActions
        onCancel={onCancel}
        saving={saving}
      />
    </Form>
  );
};

export default observer(ManualSensorDataWidgetForm);
