import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { faLayerPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { WidgetLayout } from '../../../models/widgetLayout';
import { WidgetLayouts } from '../../shared/widgets/WidgetLayouts';
import OperatorWidget from '../../operator/shared/OperatorWidget';
import { useStore } from '../../../hooks/useStore';
import { ScopeContext } from '../../../policies/scopeContext';
import { useMount } from '../../../hooks/useMount';
import { Operation } from '../../../models/operation';
import DosageTabs from './DosageTabs';
import { DosageWidgetConfig } from './dosageWidgetConfig';
import { DosageSetupWidgetConfig } from '../setup/dosageSetupWidgetConfig';
import AlertWarning from '../../shared/alert/AlertWarning';
import { WidgetImplProps } from '../../shared/widgets/Widget';
import { Scopes } from '../../../models/scopes';
import { WidgetTypes } from '../../../types/widget';

export type DosageWidgetProps = {
  disabled?: boolean;
  minimized?: boolean;
  identifier: string;
};

const DosageWidget: React.FC<DosageWidgetProps> & WidgetImplProps = ({
  disabled,
  minimized = false,
  identifier,
}) => {
  const store = useStore();
  const { t } = useTranslation();
  const widgetConfig = useMemo<DosageWidgetConfig>(() =>
    new DosageWidgetConfig(store, identifier), [store.settingStore.operatorSettingsReady]);
  const setupWidgetConfig = useMemo<DosageSetupWidgetConfig>(() =>
    new DosageSetupWidgetConfig(store, identifier), [store.settingStore.operatorSettingsReady]);
  const [activeOperation, setActiveOperation] = useState<Operation | undefined>(store.operationStore.active);

  useMount(() => {
    if (!store.workplaceStore.selectedWorkplace?.hierarchyId || widgetConfig.getReadScales()) {
      return;
    }
    store.sensorStore.loadLocalAsync(store.workplaceStore.selectedWorkplace.hierarchyId);
  }, [store.workplaceStore.selectedWorkplace?.id, widgetConfig.getReadScales()]);

  useMount(() => {
    const operation = store.operationStore.active;
    if (!operation) {
      return;
    }

    setActiveOperation(operation);
  }, [
    store.operationStore.active,
    store.operationStore.isLoadingActiveOperation,
  ]);

  return (
    <ScopeContext.Provider value={[Scopes.dosage]}>
      <OperatorWidget
        title={widgetConfig.getWidgetTitle()}
        icon={<FontAwesomeIcon icon={faLayerPlus}/>}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        onHeadClick={undefined}
        manualPath={'/dosage'}
      >
        {
          activeOperation && (
            store.dosageStore.selectedComponents.length ? (
              <>
                {store.dosageStore.selectedComponents.length > 1 && (
                  <AlertWarning
                    message={t('dosageWidget.message.multipleComponents')}
                    type="info"
                    showIcon
                  />
                )}
                <DosageTabs
                  widgetConfig={widgetConfig}
                  selectedComponents={store.dosageStore.selectedComponents}
                />
              </>
            ) : (
              <AlertWarning
                message={t('dosageWidget.message.selectComponent', { title: setupWidgetConfig.getWidgetTitle() })}
                type="warning"
                showIcon
              />
            )
          )
        }
      </OperatorWidget>
    </ScopeContext.Provider>
  );
};

DosageWidget.icon = faLayerPlus;
DosageWidget.identifier = WidgetTypes.DosageWidget;
DosageWidget.defaultLayout = new WidgetLayout(
  {
    identifier: DosageWidget.identifier,
    x: 0,
    y: 10,
    height: 6,
    minHeight: 4,
    width: WidgetLayouts.fullWidth.w,
    minWidth: WidgetLayouts.halfWidth.minW,
  }
);

export default observer(DosageWidget);
