import {computed, observable, makeObservable} from 'mobx';
import i18n from '../../../../../i18n/i18n';
import {ValidationRules} from '../../../../../utils/validationRules';
import {CockpitWidgetConfig} from './cockpitWidgetConfig';
import {FieldType} from '../../../../../types/widgetConfig';

export class timeseriesCockpitWidgetConfig extends CockpitWidgetConfig {
  selectedHierarchyId = 0;

  constructor({rootStore, widgetTypeIdentifier, widgetId}) {
    super({rootStore, widgetTypeIdentifier, widgetId});

    makeObservable(this, {
      selectedHierarchyId: observable,
      timeseries: computed,
    });
  }

  static onLoadConfig = (rootStore) => {
    rootStore.sensorStore.loadAll();
  };

  static widgetType = {
    identifier: 'timeseries-chart',
    name: () => i18n.t('cockpitWidget.widgetTypeIdentifier.timeseries'),
  };

  // eslint-disable-next-line class-methods-use-this
  getWidgetTypeName() {
    return i18n.t('cockpitWidget.widgetTypeIdentifier.timeseries');
  }

  get tabs() {
    return [
      this.generalSettingsTab,
      this.workplaceTab,
      {
        key: 'timeseries',
        label: i18n.t('widget.WidgetSettingsModal.timeseriesConfigTabTitle'),
      },
    ];
  }

  workplace = [
    {
      id: 'hierarchyId',
      label: i18n.t('cockpitWidget.settings.workplace.label'),
      helpText: i18n.t('cockpitWidget.settings.workplace.help'),
      type: FieldType.HierarchyTreeWorkplace,
    },
  ];

  get timeseries() {
    return [
      {
        id: 'showLegend',
        label: i18n.t('timeseriesCockpitWidgetConfig.settings.showLegend.label'),
        type: FieldType.Boolean,
        defaultValue: false,
      },
      {
        id: 'sensor',
        label: i18n.t('timeseriesCockpitWidgetConfig.settings.sensor.label'),
        type: FieldType.Select,
        options: this.rootStore.sensorStore.sensors.map((sensor) => ({
          value: sensor.name,
          label: sensor.label || sensor.name,
        })),
        validations: [ValidationRules.required()],
        onFormChange: (form, changedFields) => {
          if (changedFields.hierarchyId !== undefined) {
            form.setFieldsValue({sensor: null});
          }
        },
      },
      {
        id: 'yAxisUnit',
        label: i18n.t('timeseriesCockpitWidgetConfig.settings.yAxisUnit.label'),
        type: FieldType.String,
        validations: [ValidationRules.required()],
      },
      {
        id: 'yAxisMin',
        label: i18n.t('timeseriesCockpitWidgetConfig.settings.yAxisMin.label'),
        helpText: i18n.t('timeseriesCockpitWidgetConfig.settings.yAxisMin.help'),
        type: FieldType.Number,
      },
      {
        id: 'yAxisMax',
        label: i18n.t('timeseriesCockpitWidgetConfig.settings.yAxisMax.label'),
        helpText: i18n.t('timeseriesCockpitWidgetConfig.settings.yAxisMax.help'),
        type: FieldType.Number,
      },
      {
        id: 'xAxisHours',
        label: i18n.t('timeseriesCockpitWidgetConfig.settings.xAxisHours.label'),
        type: FieldType.Number,
        unit: i18n.t('timeseriesCockpitWidgetConfig.settings.xAxisHours.unit'),
        validations: [
          ValidationRules.required(),
          ValidationRules.lessThanOrEqualTo(168),
          ValidationRules.greaterThan(0),
        ],
        defaultValue: 8,
      },
      {
        id: 'decimalPlaces',
        label: i18n.t('timeseriesCockpitWidgetConfig.settings.decimalPlaces.label'),
        type: FieldType.Number,
        validations: [ValidationRules.greaterThanOrEqualTo(0), ValidationRules.lessThanOrEqualTo(100)],
        defaultValue: 3,
      },
      {
        id: 'resolution',
        label: i18n.t('timeseriesCockpitWidgetConfig.settings.resolution.label'),
        helpText: i18n.t('timeseriesCockpitWidgetConfig.settings.resolution.help'),
        unit: i18n.t('timeseriesCockpitWidgetConfig.settings.resolution.unit'),
        type: FieldType.Number,
        validations: [ValidationRules.greaterThanOrEqualTo(1), ValidationRules.lessThanOrEqualTo(60)],
        defaultValue: '',
      },
    ];
  }
}
