import { executePost } from '../requests';

const resourcesName = 'v1/dosage';

export const DosageApi = {
  setup: ({ ...params }) => executePost(`${resourcesName}/setup`, { ...params }),
  start: ({ ...params }) => executePost(`${resourcesName}/start`, { ...params }),
  prepare: ({ ...params }) => executePost(`${resourcesName}/prepare`, { ...params }),
  finalize: ({ ...params }) => executePost(`${resourcesName}/finalize`, { ...params }),
  tare: ({ ...params }) => executePost(`${resourcesName}/tare`, { ...params }),
};
