export enum Scopes {
  auditLog = 'audit-log',
  batch = 'batch',
  batchHandling = 'batch-handling',
  cockpit = 'cockpit',
  collaboration = 'collaboration',
  configTransfer = 'config-transfer',
  customActions = 'custom-actions',
  dataExportScheduler = 'data-export-scheduler',
  documentManagement = 'document-management',
  dosage = 'dosage',
  eventLog = 'event-log',
  externalResourceLog = 'external-resource-log',
  fullWorkplaceSelector = 'full-workplace-selector',
  gatewayManagement = 'gateway-management',
  hierarchyConfigurator = 'hierarchy-configurator',
  inspectionOverrule = 'inspection-overrule',
  inspectionVerificationConfig = 'inspection-verification-config',
  inspectionVerification = 'inspection-verification',
  interruptionClassification = 'interruption-classification',
  inventoryManagement = 'inventory-management',
  keyPerformanceIndicatorLimit = 'key-performance-indicator-limit',
  materialManagement = 'material-management',
  moduleConfiguration = 'module-configuration',
  objectLocks = 'object-locks',
  office = 'office',
  officeAdvancedOrderManagement = 'office-advanced-order-management',
  officeBatchHandling = 'office-batch-handling',
  officeCockpit = 'office-cockpit',
  officeCollaboration = 'office-collaboration',
  officeDocumentManagement = 'office-document-management',
  officeEventLog = 'office-event-log',
  officeMasterData = 'office-master-data',
  officeOrderManagement = 'office-order-management',
  officePerformanceAnalysis = 'office-performance-analysis',
  officePlantCockpit = 'office-plant-cockpit',
  officePrintManagement = 'office-print-management',
  officeReportLog = 'office-report-log',
  officeTaskManagement = 'office-task-management',
  officeWorkflowCorrection = 'office-workflow-corrections',
  operationAbort = 'operation-abort',
  orderManagement = 'order-management',
  performanceAnalysis = 'performance-analysis',
  personnelManagement = 'personnel-management',
  platformConfigurator = 'platform-configurator',
  printManagement = 'print-management',
  processdataCollection = 'processdata-collection',
  qualityReport = 'quality-report',
  reportLog = 'report-log',
  sensorDataLimit = 'sensor-data-limit',
  shiftManagement = 'shift-management',
  specialTaskManagement = 'special-task-management',
  superUser = 'super-user',
  taskActivation = 'task-activation',
  taskManagement = 'task-management',
  terminal = 'terminal',
  transition = 'transition',
  unrestricted = 'unrestricted',
  userManagement = 'user-management',
  widgetAction = 'widget-action',
  workflow = 'workflow',
  workflowCorrection = 'workflow-corrections'
}
