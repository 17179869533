import { computed, observable, makeObservable } from 'mobx';
import { faFrown } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel as Hierarchy, BaseModel } from './base';
import { getTranslation } from '../utils/translations';
import { Translation } from './translations';
import { RootStore } from '../stores/rootStore';

export interface InterruptionReasonTranslation extends Translation {
  label: string | null;
}

export class InterruptionReason extends BaseModel {
  id: number = 0;
  name: string = '';
  isMessageRequired: boolean = false;
  messageRequiredAfterMinutes: number | null = null;
  isQuickstop: boolean = false;
  quickstopDurationMinutes: number | null = null;
  sortOrder: number | null = null;
  ignoreInReport: boolean = false;
  hierarchyIds: number[] = [];
  interruptionClassIds: number[] = [];
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;
  archivedAt: string | null = null;
  archivedBy: number | null = null;
  translations: InterruptionReasonTranslation[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      label: computed,
      isMessageRequired: observable,
      messageRequiredAfterMinutes: observable,
      isQuickstop: observable,
      quickstopDurationMinutes: observable,
      sortOrder: observable,
      ignoreInReport: observable,
      hierarchyIds: observable,
      hierarchies: computed,
      interruptionClassIds: observable,
      interruptionClasses: computed,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
      archivedAt: observable,
      archivedBy: observable,
      translations: observable,
    });
  }

  static faIcon = faFrown;

  searchableProperties = ['label'];

  translatedProperties = ['label'];

  static prepareApiPayload(model: InterruptionReason) {
    return {
      id: model.id,
      name: model.name,
      isMessageRequired: Boolean(model.isMessageRequired),
      messageRequiredAfterMinutes: model.isMessageRequired ? Number(model.messageRequiredAfterMinutes) : null,
      isQuickstop: Boolean(model.isQuickstop),
      quickstopDurationMinutes: model.isQuickstop ? Number(model.quickstopDurationMinutes) : null,
      sortOrder: this.hasSortOrder(model) ? model.sortOrder : null,
      ignoreInReport: Boolean(model.ignoreInReport),
      interruptionClassIds: model.interruptionClassIds || [],
      hierarchyIds: model.hierarchyIds || [],
      translations: InterruptionReason.convertToSavableTranslations(model),
    };
  }

  get interruptionClasses() {
    return this.rootStore.interruptionClassStore.getByIds(this.interruptionClassIds);
  }
  get hierarchies() {
    return this.hierarchyIds.map(
      (hierarchyId) => Hierarchy.findInTree(this.rootStore.hierarchyStore.hierarchies, hierarchyId)
    );
  }

  static hasSortOrder(model: InterruptionReason) {
    return model.sortOrder !== null && model.sortOrder > 0;
  }

  get label() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.label;
  }
}
