import { observable, makeObservable, computed } from 'mobx';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

export class BatchGeneratorFunction extends BaseModel {
  id: number = 0;
  customFunctionId: number = 0;
  materialIds?: number[] = undefined;
  workplaceIds?: number[] = undefined;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      customFunctionId: observable,
      customFunction: computed,
      materialIds: observable,
      materials: computed,
      workplaceIds: observable,
      workplaces: computed,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  get customFunction(): any {
    return this.customFunctionId ? this.rootStore.customFunctionStore.getById(this.customFunctionId) : undefined;
  }

  get materials(): any {
    return this.materialIds ? this.rootStore.materialStore.getByIds(this.materialIds) : [];
  }

  get workplaces(): any[] {
    return this.workplaceIds ? this.rootStore.workplaceStore.getByIds(this.workplaceIds) : [];
  }

  static faIcon = faList;

  searchableProperties = ['id', 'material.no', 'material.name', 'customFunction.label'];
}
