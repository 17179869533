import React, {useState} from 'react';
import {Form} from 'antd';
import {useTranslation} from 'react-i18next';
import Modal from '../shared/modal/Modal';
import SelectTable from '../shared/inputs/SelectTable';

const BatchQueueResetModal = ({onOk, resetData, ...props}) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const handleSubmit = () => (form.submit());

  const columns = [
    {
      title: t('batchQueueWidget.resetModal.batch'),
      dataIndex: ['batch', 'no'],
    },
    {
      title: t('batchQueueWidget.resetModal.quantity'),
      dataIndex: 'quantity',
    },
  ];

  return (
    <Modal
      title={t('batchQueueWidget.resetModal.title')}
      onOk={handleSubmit}
      open
      okButtonProps={{danger: true, disabled}}
      okText={t('links.delete')}
      {...props}
    >
      <Form
        wrapperCol={{span: 24}}
        form={form}
        onFinish={(values) => onOk && onOk(values)}
        onFieldsChange={(changedFields, allFields) => {
          allFields[0]?.value?.length ? setDisabled(false) : setDisabled(true);
        }}
      >
        <Form.Item
          name={'batchQueueItemsToDelete'}
        >
          <SelectTable dataSource={resetData.batchQueueItems} columns={columns} rowKey={'id'}/>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BatchQueueResetModal;
