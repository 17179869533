import React, { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import Modal from '../shared/modal/Modal';
import { IntegratedCustomActions } from '../../models/customAction';
import { useMount } from '../../hooks/useMount';
import { InspectionTaskPendingStore } from '../../stores/inspectionTaskPendingStore';
import { MaterialFlowWidgetConfig } from '../inventoryManagement/materialFlow/MaterialFlowWidgetConfig';
import { useStore } from '../../hooks/useStore';
import PendingTasksList from '../order/operationList/PendingTaskList';
import MaterialFlowView from '../inventoryManagement/materialFlow/MaterialFlowView';
import { InspectionTaskPending } from '../../models/inspectionTaskPending';
import InspectionTaskExecutionModal from '../inspectionTask/execution/InspectionTaskExecutionModal';
import useInspectionTaskExecutionModal from '../../hooks/useInspectionTaskExecutionModal';
import DosageSetupTable from '../dosage/setup/DosageSetupTable';
import { DosageSetupWidgetConfig } from '../dosage/setup/dosageSetupWidgetConfig';
import DosageSetupWidget from '../dosage/setup/DosageSetupWidget';

enum ContentType {
  PENDING_INSPECTIONS,
  UNCLASSIFIED_INTERRUPTIONS,
  NEXT_WORKPLACE_AVAILABLE,
  MATERIAL_FLOW_CHECK,
  COMPONENT_STORAGE_CHECK
}

type PendingInspectionTasksProps = {
  relevantPendingIds: number[];
  selectPendingTask: (task: InspectionTaskPending) => void,
};

const PendingInspectionTasksContent: React.FC<PendingInspectionTasksProps> = ({
  relevantPendingIds,
  selectPendingTask,
}) => (
  <PendingTasksList
    relevantPendingIds={relevantPendingIds}
    selectPendingTask={selectPendingTask}
  />
);

const MaterialFlowCheck: React.FC = () => {
  const store = useStore();
  const materialFlowWidgetConfig = useMemo(() =>
    new MaterialFlowWidgetConfig(store, 'MaterialFlow'), []);
  return (
    <div>
      <MaterialFlowView
        /* eslint-disable-next-line max-len */
        showInternalStorageUnit={materialFlowWidgetConfig.getSettingValue('modalSettings', 'modalShowInternalStorageUnit')}
        showSourceStorageUnit={materialFlowWidgetConfig.getSettingValue('modalSettings', 'modalShowSourceStorageUnit')}
        showTargetStorageUnit={materialFlowWidgetConfig.getSettingValue('modalSettings', 'modalShowTargetStorageUnit')}
        columns={materialFlowWidgetConfig.getModalTableColumns()}
        showGraph={materialFlowWidgetConfig.getSettingValue('modalSettings', 'modalShowGraph')}
        showTable={materialFlowWidgetConfig.getSettingValue('modalSettings', 'modalShowTable')}
        graphLocation={materialFlowWidgetConfig.getSettingValue('modalSettings', 'modalGraphLocation')}
        amountOfColumns={materialFlowWidgetConfig.getSettingValue('modalSettings', 'modalAmountOfColumns')}
        wpStorageRelation={
          store.workplaceStorageRelationStore.workplaceStorageRelations.length > 0
            ? store.workplaceStorageRelationStore?.getByWorkplaceId(
              store.workplaceStore.selectedWorkplace?.id
            ) : []
        }
        sortBy={materialFlowWidgetConfig.getSettingValue('modalData', 'sortBy')}
        sortOrder={materialFlowWidgetConfig.getSettingValue('modalData', 'sortOrder')}
        filterByOrderMaterial={materialFlowWidgetConfig.getSettingValue('modalData', 'filterByOrderMaterial')}
      />
    </div>
  );
};

const ComponentStorageCheck: React.FC = () => {
  const store = useStore();
  // @ts-ignore
  const widgetConfig = useMemo(() => new DosageSetupWidgetConfig(store, DosageSetupWidget.identifier), []);

  return (
    <DosageSetupTable widgetConfig={widgetConfig} isSecondary/>
  );
};

export type StateTransitionErrorModalProps = {
  errors: any[],
  values: any[],
  inventoryCheckTransition: (values: any[]) => void,
  onCancel: () => void,
  onFinish: () => void,
};

const StateTransitionErrorModal: React.FC<StateTransitionErrorModalProps> = ({
  errors,
  values,
  inventoryCheckTransition,
  onCancel,
  onFinish,
}) => {
  const [title, setTitle] = useState('');
  const [width, setWidth] = useState<string | undefined>();
  const [relevantPendingIds, setRelevantPendingIds] = useState<number[] | null>(null);
  const [contentType, setContentType] = useState<ContentType | null>(null);
  const [errorsToProcess, setErrorsToProcess] = useState<any[]>(errors);
  const { t } = useTranslation();
  const { open: selectPendingTask, inspectionTaskExecutionModalProps } = useInspectionTaskExecutionModal();
  const store = useStore();

  const loadPendingInspectionTaskContent = async (inspectionTaskPendingIds: number[]) => {
    if (store.workplaceStore.selectedWorkplace?.id) {
      await store.inspectionTaskPendingStore.loadByWorkplace(store.workplaceStore.selectedWorkplace?.id);
    }
    setRelevantPendingIds(inspectionTaskPendingIds);
    const pendingTasks = InspectionTaskPendingStore.groupTasks(
      store.inspectionTaskPendingStore.getByIds(inspectionTaskPendingIds)
    );
    if (pendingTasks.length === 1) {
      selectPendingTask(pendingTasks[0]);
    }
  };

  const loadMaterialFlowCheck = async () => {
    if (store.workplaceStore.selectedWorkplace?.id) {
      await store.workplaceStorageRelationStore.loadByWorkplaceId(store.workplaceStore.selectedWorkplace?.id);
    }
  };

  useMount(() => {
    (async () => {
      let newContentType: ContentType | null = null;
      if (errorsToProcess.length > 0) {
        const [error] = errorsToProcess;

        let newTitle = t(`transition.customActionError.${error.type}`);

        if (!error.isTransitionAllowed) {
          newTitle = t('transition.customActionError.stateTransitionNotAllowed');
        }

        if (error.customActionName) {
          if (error.customActionName === IntegratedCustomActions.PRE_CHECK_UNCLASSIFIED_INTERRUPTIONS) {
            newContentType = ContentType.UNCLASSIFIED_INTERRUPTIONS;
          }
          if (error.customActionName === IntegratedCustomActions.PRE_NEXT_WORKPLACE_AVAILABLE) {
            newContentType = ContentType.NEXT_WORKPLACE_AVAILABLE;
          }
        }

        let newWidth;
        if (error.additionalPayload?.inspectionTaskPendingIds) {
          await loadPendingInspectionTaskContent(error.additionalPayload.inspectionTaskPendingIds);
          newContentType = ContentType.PENDING_INSPECTIONS;
          newWidth = '70vw';
        }
        if (
          error.isTransitionAllowed === false
          && error.customActionName === IntegratedCustomActions.PRE_CHECK_INVENTORY
        ) {
          await loadMaterialFlowCheck();
          newContentType = ContentType.MATERIAL_FLOW_CHECK;
          newWidth = '90vw';
          newTitle = t('transition.customActionError.inventoryCheck');
        }
        if (error.isTransitionAllowed === false
          && error.customActionName === IntegratedCustomActions.PRE_COMPONENT_STORAGE_CHECK) {
          newContentType = ContentType.COMPONENT_STORAGE_CHECK;
          newWidth = '90vw';
          newTitle = t('transition.customActionError.componentStorageCheck');
        }
        setTitle(newTitle);
        setWidth(newWidth);
        setContentType(newContentType);
      } else {
        onCancel();
      }
    })();
  }, [errorsToProcess.length]);

  useMount(() => {
    if (relevantPendingIds?.length === 0) {
      setErrorsToProcess(errorsToProcess.slice(1));
    }
  }, [relevantPendingIds]);

  const handleExecuted = (pendingTasksCleared: InspectionTaskPending[]) => {
    if (relevantPendingIds === null) {
      return;
    }
    const idToRemove: number[] = pendingTasksCleared.map((oldPending) => oldPending.id);

    const newRelevantPendingIds = relevantPendingIds.filter((id) => !idToRemove.includes(id));
    setRelevantPendingIds(newRelevantPendingIds);
  };

  let content: ReactNode;
  let okHandler: (() => void) | undefined = () => setErrorsToProcess(errorsToProcess.slice(1));
  let cancelHandler: (() => void) | undefined;
  switch (contentType) {
    case ContentType.PENDING_INSPECTIONS:
      content = (
        <PendingInspectionTasksContent
          relevantPendingIds={relevantPendingIds || []}
          selectPendingTask={selectPendingTask}
        />
      );
      break;
    case ContentType.UNCLASSIFIED_INTERRUPTIONS:
      content = t('transition.customActionError.unclassifiedInterruptions');
      break;
    case ContentType.NEXT_WORKPLACE_AVAILABLE:
      content = t('transition.customActionError.nextWorkplaceNotAvailable');
      break;
    case ContentType.MATERIAL_FLOW_CHECK:
      content = <MaterialFlowCheck/>;
      cancelHandler = () => onFinish();
      okHandler = () => {
        inventoryCheckTransition(values);
        onCancel();
      };
      break;
    case ContentType.COMPONENT_STORAGE_CHECK:
      content = <ComponentStorageCheck/>;
      break;
    default:
      content = errors[0]?.message || null;
  }

  return (
    <>
      <Modal
        open
        closeIcon={false}
        width={width}
        title={(
          <div>
            <FontAwesomeIcon icon={faCircleXmark} color={'#F5222D'}/>
            {' '}
            {title}
          </div>
        )}
        onOk={okHandler}
        onCancel={cancelHandler}
        okButtonProps={{ style: { display: !okHandler ? 'none' : 'inline-block' } }}
        cancelButtonProps={{ style: { display: !cancelHandler ? 'none' : 'inline-block' } }}
      >
        {content}
      </Modal>
      {/* @ts-ignore */}
      <InspectionTaskExecutionModal
        {...inspectionTaskExecutionModalProps}
        onExecuted={handleExecuted}
      />
    </>
  );
};

export default StateTransitionErrorModal;
