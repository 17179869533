import { BaseWidgetConfig } from '../../shared/widgets/baseWidgetConfig';
import i18n from '../../../i18n/i18n';
import { ValidationRules } from '../../../utils/validationRules';
import { Material } from '../../../models/material';
import { Component } from '../../../models/component';
import { getAllI18nTranslations } from '../../../utils/translations';
import { FieldType, TabDefinition } from '../../../types/widgetConfig';
import { Product } from '../../../models/product';
import { RootStore } from '../../../stores/rootStore';
import { WidgetActionExtension } from '../../shared/widgets/WidgetActionExtension';
import { StorageUnit } from '../../../models/storageUnit';
import { ParentDosageMode } from '../../../models/dosage';

export class DosageWidgetConfig extends BaseWidgetConfig {
  constructor(rootStore: RootStore, identifier: string) {
    super(rootStore, identifier, undefined, [new WidgetActionExtension(rootStore)]);
    this.tabConfigs.set('settings', [
      {
        id: 'title',
        label: i18n.t<string>('widget.settings.title.label'),
        helpText: i18n.t<string>('widget.settings.title.help'),
        type: FieldType.Translatable,
        // t('dosageWidget.config.settings.title.defaultValue')
        defaultValue: getAllI18nTranslations('dosageWidget.config.settings.title.defaultValue'),
        validations: [ValidationRules.required()],
      },
      {
        id: 'readScales',
        label: i18n.t<string>('dosageWidget.config.settings.readScales.label'),
        helpText: i18n.t<string>('dosageWidget.config.settings.readScales.help'),
        type: FieldType.Boolean,
        defaultValue: false,
      },
      {
        id: 'selectTargetStorageUnit',
        label: i18n.t<string>('dosageWidget.config.settings.selectTargetStorageUnit.label'),
        helpText: i18n.t<string>('dosageWidget.config.settings.selectTargetStorageUnit.help'),
        type: FieldType.Boolean,
        defaultValue: false,
      },
      {
        id: 'property_autoTareOnWeighing',
        label: i18n.t<string>('dosageWidget.config.settings.autoTareOnWeighing.label'),
        type: FieldType.Boolean,
        defaultValue: false,
      },
      {
        id: 'property_autoTareOnDosageChange',
        label: i18n.t<string>('dosageWidget.config.settings.autoTareOnDosageChange.label'),
        type: FieldType.Boolean,
        defaultValue: false,
      },
      {
        id: 'property_autoTareOnComponentChange',
        label: i18n.t<string>('dosageWidget.config.settings.autoTareOnComponentChange.label'),
        type: FieldType.Boolean,
        defaultValue: false,
      },
      {
        id: 'parentDosage_selectionQuantity',
        label: i18n.t<string>('dosageWidget.config.settings.parentDosageSelectionQuantity.label'),
        type: FieldType.Select,
        defaultValue: ParentDosageMode.Quantity,
        options: [
          {
            value: ParentDosageMode.Selection,
            label: i18n.t('dosageWidget.config.settings.parentDosageSelectionQuantity.selection'),
          },
          {
            value: ParentDosageMode.Quantity,
            label: i18n.t('dosageWidget.config.settings.parentDosageSelectionQuantity.quantity'),
          },
        ],
      },
      {
        id: 'property_single_product',
        label: i18n.t<string>('dosageWidget.config.settings.propertySingleProduct.label'),
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: Product.allDisplayableProperties(this.rootStore),
        defaultValue: [
          'no',
          'plannedQuantity',
          'actualQuantity',
          'plannedPreparations',
          'actualPreparations',
        ],
      },
      {
        id: 'property_childStorageUnitColumns',
        label: i18n.t<string>('dosageWidget.config.settings.propertyChildStorageUnit.label'),
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: StorageUnit.allDisplayableProperties(this.rootStore),
        defaultValue: [
          'no',
          'amount',
        ],
      },
      {
        id: 'property_components',
        label: i18n.t<string>('dosageWidget.config.settings.propertyComponents.label'),
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: [
          ...Material.allDisplayableProperties(this.rootStore, 'material.'),
          ...Component.allDisplayableProperties(this.rootStore),
        ],
        defaultValue: ['material.name', 'preparationQuantity', 'toleranceLow', 'toleranceHigh'],
      },
      {
        id: 'property_modal.productColumns',
        label: i18n.t<string>('dosageWidget.config.settings.propertyModalProducts.label'),
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: Product.allDisplayableProperties(this.rootStore),
        defaultValue: [
          'no',
          'plannedQuantity',
          'actualQuantity',
        ],
      },
      {
        id: 'property_modal.componentColumns',
        label: i18n.t<string>('dosageWidget.config.settings.propertyModalComponents.label'),
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: Component.allDisplayableProperties(this.rootStore),
        defaultValue: [
          'material.name',
          'plannedQuantity',
          'preparationQuantity',
        ],
      },
    ]);
  }
  // eslint-disable-next-line class-methods-use-this
  get tabs(): TabDefinition[] {
    return [
      {
        key: 'settings',
        label: i18n.t('widget.WidgetSettingsModal.settingConfigTabTitle'),
      },
      ...super.tabs,
    ];
  }

  getWidgetSetting(identifier: string) {
    return this.getSettingValue('settings', identifier);
  }

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'title');
  }

  getDosageProductTableColumns() {
    return this.getSelectedProperties('settings', 'property_modal.productColumns');
  }

  getDosageComponentTableColumns() {
    return this.getSelectedProperties('settings', 'property_modal.componentColumns');
  }

  getDosageSingleProductTableColumns() {
    return this.getSelectedProperties('settings', 'property_single_product');
  }

  getReadScales() {
    return this.getSettingValue('settings', 'readScales');
  }

  getSelectTargetStorageUnit() {
    return this.getSettingValue('settings', 'selectTargetStorageUnit');
  }
}
