import {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {useStore} from '../../../hooks/useStore';
import InspectionLotDetailsModal from './InspectionLotDetailsModal';
import {VisTimeline} from '../../shared/dataDisplay/VisTimeline';
import style from './InspectionLotOverview.module.scss';
import OverlaySpinner from '../../shared/spinners/OverlaySpinner';

const InspectionLotOverview = ({
  start = dayjs().subtract(7, 'd'),
  end = dayjs().add(7, 'd'),
  setModalClosable,
}) => {
  const store = useStore();
  const {t} = useTranslation();

  const [selectedInspectionLotId, setSelectedInspectionLotId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [inspectionLots, setInspectionLots] = useState([]);
  const [activationStateChanged, setActivationStateChange] = useState(0);

  useEffect(() => {
    setLoading(true);
    store.inspectionLotStore.loadValidByWorkplace(store.workplaceStore.selectedWorkplace.id, start, end)
      .then((lots) => {
        const items = lots.map((inspectionLot) => ({
          id: inspectionLot.id,
          group: inspectionLot.orderId === null ? 'pm' : 'qm',
          content: inspectionLot.lotNo,
          start: dayjs(inspectionLot.validStart).toDate(),
          end: dayjs(inspectionLot.validEnd).toDate(),
          style: inspectionLot.deactivatedBy ? 'background-color: #fff' : '',
        })).sort((a, b) => a.start - b.start);
        setInspectionLots(items);
      })
      .finally(() => setLoading(false));
  }, [activationStateChanged]);

  const groups = [
    {
      id: 'pm',
      content: t('inspectionLot.model.pm'),
    },
    {
      id: 'qm',
      content: t('inspectionLot.model.qm'),
    },
  ];

  const handleClick = (event) => {
    if (event.what === 'item') {
      setSelectedInspectionLotId(event.item);
    }
  };

  return (
    <div className={style.inspectionLotOverview} style={{position: 'relative'}}>
      {loading && (
        <OverlaySpinner/>
      )}
      <VisTimeline
        groups={groups}
        items={inspectionLots}
        clickHandler={handleClick}
        rangechangeHandler={() => { setModalClosable(false); }}
        rangechangedHandler={() => { setModalClosable(true); }}
        options={{
          start: start.toDate(),
          end: end.toDate(),
          min: start.toDate(),
          max: end.toDate(),
          stack: true,
          moveable: true,
        }}
      />
      <InspectionLotDetailsModal
        inspectionLotId={selectedInspectionLotId}
        open={selectedInspectionLotId !== null}
        onCancel={() => {
          setSelectedInspectionLotId(null);
          setModalClosable(true);
        }}
        afterDeactivate={() => setActivationStateChange(activationStateChanged + 1)}
        afterActivate={() => setActivationStateChange(activationStateChanged + 1)}
      />
    </div>
  );
};

export default observer(InspectionLotOverview);
