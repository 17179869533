import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { QualityReport } from '../../models/qualityReport';
import Table from '../shared/tables/Table';
import { useMount } from '../../hooks/useMount';
import Button from '../shared/buttons/Button';
import QualityReportAttachmentModal from './QualityReportAttachmentModal';

export type QualityReportTableProps = {
  dataSource: QualityReport[];
  columns: any[];
  loading: boolean;
};

const QualityReportTable: React.FC<QualityReportTableProps> = ({
  dataSource,
  columns,
  loading,
}) => {
  const [renderedColumns, setRenderedColumns] = useState([...columns]);
  const [openAttachmentModal, setOpenAttachmentModal] = useState<number | null>(null);

  useMount(() => {
    setRenderedColumns([
      ...columns,
      {
        title: '',
        render: (record: QualityReport) => (
          <Button
            onClick={() => setOpenAttachmentModal(record.id)}
            type={'link'}
            icon={<FontAwesomeIcon icon={faImage}/>}
            disabled={record.documentIds.length === 0}
          />
        ),
      },
    ]);
  }, [columns]);

  return (
    <>
      <Table
        columns={renderedColumns}
        dataSource={dataSource}
        loading={loading}
        wrapperStyle={undefined}
        rowKey={'id'}
      />
      <QualityReportAttachmentModal
        open={!!openAttachmentModal}
        onCancel={() => setOpenAttachmentModal(null)}
        qualityReportId={openAttachmentModal}
        onOk={() => setOpenAttachmentModal(null)}
      />
    </>
  );
};

export default QualityReportTable;
