import Modal from '../../shared/modal/Modal';
import IngredientStockTable from './IngredientStockTable';

const StorageLocationDetailsModal = ({
  storageLocation,
  ingredientStock,
  columns,
  open,
  ...props
}) => (
  <Modal
    title={storageLocation}
    footer={false}
    open={open}
    {...props}
  >
    <IngredientStockTable ingredientStock={ingredientStock} columns={columns}/>
  </Modal>
);

export default StorageLocationDetailsModal;
