import dayjs from 'dayjs';
import React, {useState} from 'react';
import {Col, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import {SpeedDenominatorFactor} from '../../../models/speed';
import appConfig from '../../../utils/appConfig';
import {useMount} from '../../../hooks/useMount';
import styles from './OrderProgressChart.module.scss';
import {transformation as formatter} from '../../../utils/transformations';
import {EnDash} from '../unicodeWrapper/EnDash';
import {durationFormat, timeFormat} from '../../../config/dayjs';
import {formatDuration} from '../../../utils/formatting';

export const OrderProgressChart = ({
  actualQuantity,
  plannedQuantity = 1,
  speed = 1,
  plannedDurationMs,
  actualStart,
  unit,
  showDurationLabel,
}) => {
  const {t} = useTranslation();
  const [timeStops, setTimeStops] = useState({
    predictedEnd: 0,
    plannedEnd: 0,
    now: 0,
    plannedEndDate: dayjs(),
    predictedEndDate: dayjs(),
  });
  const [quantityStops, setQuantityStops] = useState({actualQuantity: 0, plannedQuantity: 0});
  const [now, setNow] = useState(dayjs());
  const [duration, setDuration] = useState();

  const fallback = formatter.fallback(EnDash());

  const triggerUpdate = () => {
    setNow(dayjs());
  };

  useMount(() => {
    const interval = setInterval(triggerUpdate, 15000);
    return () => clearInterval(interval);
  });

  useMount(() => {
    const completionQuantity = Math.min(actualQuantity / plannedQuantity, 1);
    const predictedDuration = (plannedQuantity * (1 - completionQuantity)) / speed;
    const predictedDurationMs = predictedDuration * SpeedDenominatorFactor[appConfig.speedDenominator];
    const predictedEndDate = dayjs(now).add(predictedDurationMs, 'ms');
    const plannedEndDate = dayjs(actualStart).add(plannedDurationMs, 'ms');
    const predictedEndMs = predictedEndDate.diff(actualStart);
    const plannedEndMs = plannedEndDate.diff(actualStart);
    setDuration(Number.isNaN(predictedDurationMs) ? null : dayjs.duration(predictedDurationMs));

    const newTimeStops = {
      predictedEnd: 0,
      plannedEnd: 0,
      now: 0,
      plannedEndDate,
      predictedEndDate,
    };

    if (plannedEndMs > predictedEndMs) {
      newTimeStops.predictedEnd = (predictedEndMs / plannedEndMs) * 100;
      newTimeStops.plannedEnd = 100;
      newTimeStops.now = (now.diff(actualStart) / plannedEndMs) * 100;
    } else {
      newTimeStops.predictedEnd = 100;
      newTimeStops.plannedEnd = (plannedEndMs / predictedEndMs) * 100;
      newTimeStops.now = (now.diff(actualStart) / predictedEndMs) * 100;
    }

    const newQuantityStops = {
      actualQuantity: Math.min(completionQuantity * 100, 100),
      plannedQuantity: 100,
    };
    setTimeStops(newTimeStops);
    setQuantityStops(newQuantityStops);
  }, [actualQuantity, plannedQuantity, speed, plannedDurationMs, actualStart, now]);

  const getBar = (width, className) => (
    <span
      style={{width}}
      className={`${styles.opChartElement}  ${className}`}
      key={`${width}-${className}`}
    />
  );

  const getNowLabelSide = () => {
    let side = 'left';
    let value = timeStops.now;
    if (value > 50) {
      side = 'right';
      value = 100 - value;
    }
    return {[side]: `calc(${value}% + 4px)`, position: 'absolute'};
  };

  const getTimeSize = (value, otherValue) => {
    let result = value;
    if (value > otherValue) {
      result -= otherValue;
    }
    return result;
  };

  if (!actualStart) {
    return null;
  }

  const timeBars = [];
  const quantityBars = [];
  if (timeStops.predictedEnd < timeStops.plannedEnd) {
    if (timeStops.now <= timeStops.predictedEnd) {
      timeBars.push(getBar(`${timeStops.now}%`, styles.opChartProgress));
      timeBars.push(getBar(`${timeStops.predictedEnd - timeStops.now}%`, styles.opChartUnused));
      timeBars.push(getBar(`${timeStops.plannedEnd - timeStops.predictedEnd}%`, styles.opChartRemains));
    } else { // if (timeStops <= timeStops.plannedEnd)
      timeBars.push(getBar(`${timeStops.predictedEnd}%`, styles.opChartProgress));
      timeBars.push(getBar(`${timeStops.now - timeStops.predictedEnd}%`, styles.opChartOverdue));
      timeBars.push(getBar(`${timeStops.plannedEnd - timeStops.now}%`, styles.opChartRemains));
    }
  } else if (timeStops.now <= timeStops.plannedEnd) {
    timeBars.push(getBar(`${timeStops.now}%`, styles.opChartProgress));
    timeBars.push(getBar(`${timeStops.plannedEnd - timeStops.now}%`, styles.opChartRemains));
    timeBars.push(getBar(`${timeStops.predictedEnd - timeStops.plannedEnd}%`, styles.opChartEstimatedOverdue));
  } else {
    timeBars.push(getBar(`${timeStops.plannedEnd}%`, styles.opChartProgress));
    timeBars.push(getBar(`${timeStops.now - timeStops.plannedEnd}%`, styles.opChartOverdue));
    timeBars.push(getBar(`${timeStops.predictedEnd - timeStops.now}%`, styles.opChartEstimatedOverdue));
  }

  quantityBars.push(getBar(`${quantityStops.actualQuantity}%`, styles.opChartProgress));
  quantityBars.push(getBar(`${quantityStops.plannedQuantity - quantityStops.actualQuantity}%`, styles.opChartRemains));

  const predictionLabelSize = getTimeSize(timeStops.predictedEnd, timeStops.plannedEnd);
  const plannedLabelSize = getTimeSize(timeStops.plannedEnd, timeStops.predictedEnd);

  return (
    <Row style={{height: '90%'}}>
      <Col flex={'200px'} style={{height: '100%', marginTop: '6px'}}>
        <div
          className={styles.opChartCategory}
        >
          <span>
            {t('orderProgressWidget.graph.prediction')}
          </span>
        </div>
        <div
          className={styles.opChartCategory}
        >
          <span>
            {`${t('orderProgressWidget.graph.quantity')} [${fallback(unit?.label)}]`}
          </span>
        </div>
      </Col>
      <Col flex={'auto'} style={{height: '100%'}}>
        <div className={styles.opChartContainer}>
          <span className={styles.opChartNowMarker} style={{left: `${timeStops.now}%`}}/>
          <div style={{height: '1.5em', position: 'relative' }}>
            <span style={getNowLabelSide()}>{t('orderProgressWidget.graph.now')}</span>
          </div>
          <div
            className={styles.opChartBarContainer}
          >
            {timeBars}
          </div>
          <div className={styles.opChartLabelContainer}>
            <span
              className={styles.opChartLabel}
              style={{flex: '0 1 0%', order: 0}}
              key={'label-start'}
            >
              {actualStart.format(timeFormat)}
            </span>
            <span
              className={styles.opChartLabel}
              style={{
                flex: `${plannedLabelSize} 1 ${plannedLabelSize}%`,
                order: timeStops.plannedEnd < timeStops.predictedEnd ? 1 : 2,
              }}
              /* style={{right: `${100 - timeStops.plannedEnd}%`}} */
              title={t('orderProgressWidget.graph.planned')}
              key={'label-planned'}
            >
              {timeStops.plannedEndDate.format(timeFormat)}
            </span>
            <span
              className={styles.opChartLabel}
              style={{
                flex: `${predictionLabelSize} 1 ${predictionLabelSize}%`,
                order: timeStops.predictedEnd < timeStops.plannedEnd ? 1 : 2,
              }}
              title={t('orderProgressWidget.graph.prediction')}
              key={'label-prediction'}
            >
              {timeStops.predictedEndDate.format(timeFormat)}
            </span>
          </div>
          <div
            style={{width: `${timeStops.plannedEnd}% `}}
            className={styles.opChartBarContainer}
          >
            {quantityBars}
          </div>
          <div className={styles.opChartLabelContainer} style={{width: `${timeStops.plannedEnd}% `}}>
            <span
              style={{flex: '0 1 0%'}}
              className={styles.opChartLabel}
              key={'label-start'}
            >
              {'0'}
            </span>
            <span
              className={styles.opChartLabel}
              style={{flex: `${quantityStops.actualQuantity} 1 ${quantityStops.actualQuantity}%`}}
              title={t('orderProgressWidget.graph.actual')}
              key={'label-actual'}
            >
              {`${Math.round(actualQuantity)}`}
            </span>
            <span
              className={styles.opChartLabel}
              style={{flex: `${100 - quantityStops.actualQuantity} 1 ${100 - quantityStops.actualQuantity}%`}}
              title={t('orderProgressWidget.graph.planned')}
              key={'label-planned'}
            >
              {`${Math.round(plannedQuantity)}`}
            </span>
          </div>
        </div>
        {showDurationLabel && duration ? (
          <span>{`${t('orderProgressWidget.orderEnd')}: ${formatDuration(duration, durationFormat)}`}</span>
        ) : null}
      </Col>
    </Row>
  );
};
