import {useState} from 'react';
import {useMount} from './useMount';

export const useOrderOperationsUpdated = (store) => {
  const [orderOperationsUpdated, setOrderOperationsUpdated] = useState(store.operationStore.orderOperationsUpdated);

  useMount(() => {
    if (store.operationStore.orderOperationsUpdated !== orderOperationsUpdated) {
      setOrderOperationsUpdated(store.operationStore.orderOperationsUpdated);
    }
  }, [store.operationStore.orderOperationsUpdated]);

  return orderOperationsUpdated;
};
