export const readFileAsDataURL = (file: Blob): Promise<string | null> => {
  const fileReader = new FileReader();

  return new Promise<string | null>((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    fileReader.onload = () => {
      resolve(fileReader.result as string);
    };

    fileReader.readAsDataURL(file);
  });
};
