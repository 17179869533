import i18n from '../../i18n/i18n';
import {BaseWidgetConfig} from '../shared/widgets/baseWidgetConfig';
import {ValidationRules} from '../../utils/validationRules';
import {getAllI18nTranslations} from '../../utils/translations';
import {FieldType} from '../../types/widgetConfig';

export class InterruptionWidgetConfig extends BaseWidgetConfig {
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'settings',
        label: i18n.t('widget.WidgetSettingsModal.settingConfigTabTitle'),
      },
    ];
  }

  settings = [
    {
      id: 'title',
      label: i18n.t('widget.settings.title.label'),
      helpText: i18n.t('widget.settings.title.help'),
      type: FieldType.Translatable,
      defaultValue: getAllI18nTranslations('interruption.widget.title'),
      validations: [ValidationRules.required()],
    },
    {
      id: 'maxAmountQuickstopsToDisplay',
      label: i18n.t('interruption.settings.maxAmountQuickstopsToDisplay.label'),
      helpText: i18n.t('interruption.settings.maxAmountQuickstopsToDisplay.help'),
      type: FieldType.String,
      defaultValue: 3,
      validations: [ValidationRules.required(), ValidationRules.positiveNumber],
    },
    {
      id: 'scopeSources',
      label: i18n.t('interruption.settings.scopeSources.label'),
      helpText: i18n.t('interruption.settings.scopeSources.help'),
      type: FieldType.String,
      defaultValue: 0,
      validations: [
        ValidationRules.required(),
        ValidationRules.number,
        ValidationRules.minNumber(0),
        ValidationRules.maxNumber(64),
      ],
    },
    {
      id: 'selectSourceLeavesOnly',
      label: i18n.t('interruption.settings.selectSourceLeavesOnly.label'),
      helpText: i18n.t('interruption.settings.selectSourceLeavesOnly.help'),
      type: FieldType.Boolean,
      defaultValue: false,
      validations: [ValidationRules.required()],
    },
    {
      id: 'activateOneClickInterruption',
      label: i18n.t('interruption.settings.activateOneClickInterruption.label'),
      helpText: i18n.t('interruption.settings.activateOneClickInterruption.help'),
      type: FieldType.Boolean,
      defaultValue: false,
      validations: [ValidationRules.required()],
    },
    {
      id: 'oneClickRecordButtonText',
      label: i18n.t('interruption.settings.oneClickRecordButtonText.label'),
      helpText: i18n.t('interruption.settings.oneClickRecordButtonText.help'),
      type: FieldType.Translatable,
      defaultValue: i18n.t('interruption.settings.oneClickRecordButtonText.default'),
      validations: [ValidationRules.required()],
    },
    {
      id: 'oneClickStopButtonText',
      label: i18n.t('interruption.settings.oneClickStopButtonText.label'),
      helpText: i18n.t('interruption.settings.oneClickStopButtonText.help'),
      type: FieldType.Translatable,
      defaultValue: i18n.t('interruption.settings.oneClickStopButtonText.default'),
      validations: [ValidationRules.required()],
    },
  ];

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'title');
  }

  getOneClickRecordButtonText() {
    return this.getTranslatableValue('settings', 'oneClickRecordButtonText');
  }

  getOneClickStopButtonText() {
    return this.getTranslatableValue('settings', 'oneClickStopButtonText');
  }
}
