import {createEntity, executeGet, executePost, updateEntity} from '../requests';

const resourcesName = 'v1/batch-queue-items';

export const BatchQueueItemsApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({name, ...values}) => updateEntity(values, `${resourcesName}/${name}`),
  cancelQueue: ({workplaceId, materialId, batchQueueItemIds}) => executePost(`${resourcesName}/cancel-queue`, {
    workplaceId,
    materialId,
    batchQueueItemIds,
  }),
};
