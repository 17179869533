import React, {useMemo, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import {useMeasure} from 'react-use';
import {useTranslation} from 'react-i18next';
import CockpitWidget from './CockpitWidget';
import {WidgetLayout} from '../../../../models/widgetLayout';
import {WidgetLayouts} from '../../../shared/widgets/WidgetLayouts';
import {timeseriesCockpitWidgetConfig} from './config/timeseriesCockpitWidgetConfig';
import {TimeseriesPlotOptions} from './TimeseriesPlotOptions';
import {useMount} from '../../../../hooks/useMount';
import {useStore} from '../../../../hooks/useStore';
import Spinner from '../../../shared/spinners/Spinner';
import useWorkplaceRelatedWidgetTitle from '../../../../hooks/useWorkplaceRelatedWidgetTitle';
import {getLimitsSeries} from '../../../shared/charts/highchartHelpers/data';
import LineChart from '../../../shared/charts/LineChart';
import {useModulePolicy} from '../../../../hooks/useModulePolicy';
import {sensorDataLimit} from '../../../../models/scope';

const TimeseriesCockpitWidget = ({
  widgetId,
  title,
  ...props
}) => {
  const [ref, bounds] = useMeasure();
  const store = useStore();
  const {t} = useTranslation();
  const widgetConfig = useMemo(() => new TimeseriesCockpitWidget.Config({
    rootStore: store,
    identifier: TimeseriesCockpitWidget.Config.widgetType.identifier,
    widgetId,
  }), []);
  const options = useRef({});
  const [chartOptions, setChartOptions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWorkplaceId, setSelectedWorkplaceId] = useState(null);
  const widgetTitle = useWorkplaceRelatedWidgetTitle(title, selectedWorkplaceId);
  const sensorDataLimitPolicy = useModulePolicy(store, [sensorDataLimit]);

  useMount(() => {
    if (store.contextStore.isOperatorContext) {
      store.sensorStore.loadAll();
    }
  });

  useMount(() => {
    setSelectedWorkplaceId(
      store.workplaceStore.getByHierarchyId(widgetConfig.getSettingValue('workplace', 'hierarchyId'))?.id
      || store.cockpitStore.currentWorkplaceId || undefined
    );
  }, [
    store.cockpitStore.currentWorkplaceId,
    widgetConfig.getSettingValue('workplace', 'hierarchyId'),
  ]);

  const updateOptions = (opt) => {
    options.current = {
      ...options.current,
      ...opt,
    };
    setChartOptions(TimeseriesPlotOptions(options.current, t));
  };

  const loadSensorData = (sensorName, from, to, resolution) => {
    if (options.current.seriesKey !== sensorName
      || !from.isSame(options.current.xAxisMin, 'minutes')
      || !to.isSame(options.current.xAxisMax, 'minutes')
      || options.current.workplaceId !== selectedWorkplaceId
    ) {
      setIsLoading(true);
      const sensorDataProm = store.sensorDataStore.loadAll({
        params: {
          sensorName,
          workplaceId: selectedWorkplaceId,
          fromDate: from.toISOString(),
          toDate: to.toISOString(),
          amountOfLeadingItems: resolution ? 0 : 1,
          resolution,
        },
        raw: true,
      });
      let sensorLimitProm;
      if (sensorDataLimitPolicy.canView()
        && store.workplaceStore.getById(selectedWorkplaceId)?.hierarchyId && sensorName) {
        sensorLimitProm = store.sensorDataLimitStore.loadBySensorsAndHierarchy(
          [sensorName],
          store.workplaceStore.getById(selectedWorkplaceId)?.hierarchyId
        );
      } else {
        sensorLimitProm = Promise.resolve([]);
      }

      const yAxisMinConfig = widgetConfig.getSettingValue('timeseries', 'yAxisMin');
      const yAxisMaxConfig = widgetConfig.getSettingValue('timeseries', 'yAxisMax');

      Promise.all([sensorDataProm, sensorLimitProm])
        .then(([sensorData, limits]) => {
          // Prepare series data and limits
          const seriesData = [];
          let min = null;
          let max = null;
          sensorData.data.forEach((d) => {
            seriesData.push([dayjs(d.timestamp).valueOf(), d.value]);
            if ((min === null || d.value < min) && d.value !== null) {
              min = d.value;
            }
            if ((max === null || d.value > max) && d.value !== null) {
              max = d.value;
            }
          });
          seriesData.sort((a, b) => a[0] - b[0]);
          seriesData.push([dayjs().add(1, 'year').valueOf(), 0]);

          const {
            rangeSeries,
            chartMax,
            chartMin,
          } = getLimitsSeries(limits[0], from.valueOf(), to.valueOf(), min, max, 'sensorData');

          updateOptions({
            seriesData,
            seriesId: 'sensorData',
            limitSeries: rangeSeries,
            workplaceId: selectedWorkplaceId,
            connectNulls: !!resolution,
            yAxisMin: yAxisMinConfig !== null ? yAxisMinConfig : chartMin,
            yAxisMax: yAxisMaxConfig !== null ? yAxisMaxConfig : chartMax,
            limit: yAxisMinConfig !== null || yAxisMaxConfig !== null ? undefined : limits[0],
          });
          return sensorData.data;
        })
        .finally(() => setIsLoading(false));
    }
  };

  const formatSensorLabel = (label, resolution) => {
    if (resolution) {
      return `${label} (${t('timeseriesCockpitWidget.legend.resolution', {resolution})})`;
    }
    return label;
  };

  const loadSensorLabel = (sensorName, resolution) => {
    const sensor = store.sensorStore.getById(sensorName);
    if (!sensor) {
      store.sensorStore.load(sensorName).then(() => {
        const s = store.sensorStore.getById(sensorName);
        updateOptions({
          ...options,
          seriesLabel: formatSensorLabel(s?.label || s?.name, resolution),
        });
      });
    } else {
      updateOptions({
        ...options,
        seriesLabel: formatSensorLabel(sensor?.label || sensor?.name, resolution),
      });
    }
  };

  const updateSeries = (settings) => {
    if (settings && selectedWorkplaceId !== null) {
      const sensorName = settings.sensor;
      const from = dayjs().subtract(settings.xAxisHours, 'hours');
      const to = dayjs();
      loadSensorData(sensorName, from, to, settings.resolution || undefined);
      loadSensorLabel(sensorName, settings.resolution);
      updateOptions({
        ...settings,
        seriesKey: sensorName,
        width: bounds.width - LineChart.WIDTH_MARGIN,
        height: bounds.height - LineChart.HEIGHT_MARGIN,
        xAxisMin: from,
        xAxisMax: to,
      });
    }
  };

  useMount(() => {
    updateOptions({
      width: bounds.width - LineChart.WIDTH_MARGIN,
      height: bounds.height - LineChart.HEIGHT_MARGIN,
    });
  }, [bounds]);

  useMount(() => {
    updateSeries(widgetConfig.getValues());
  }, [selectedWorkplaceId]);

  return (
    <div ref={ref} style={{height: '100%'}}>
      <CockpitWidget
        widgetId={widgetId}
        title={widgetTitle}
        widgetConfig={widgetConfig}
        onSettingsChange={updateSeries}
        {...props}
      >
        {
          isLoading
            ? <Spinner fullWidth fullHeight/>
            : (
              <LineChart
                options={{...chartOptions, decimalPlaces: widgetConfig.getValues().decimalPlaces}}
                isTimeseries
              />
            )
        }
      </CockpitWidget>
    </div>
  );
};

TimeseriesCockpitWidget.Config = timeseriesCockpitWidgetConfig;
TimeseriesCockpitWidget.defaultLayout = new WidgetLayout({
  identifier: CockpitWidget.identifier,
  width: 4,
  height: 8,
  minHeight: 4,
  minWidth: WidgetLayouts.sixthWidth.minW,
});

/**
 * @deprecated
 */
export default observer(TimeseriesCockpitWidget);
