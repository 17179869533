import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Progress, ProgressProps, Space } from 'antd';
import { noop } from 'lodash';
import { observer } from 'mobx-react-lite';
import Button from './Button';
import appConfig from '../../../utils/appConfig';
import styles from './TimeoutCommit.module.scss';
import { useMount } from '../../../hooks/useMount';

type TimeoutProgressProps = ProgressProps & {
  timeoutSeconds: number;
  startDate: Date;
};

const TimeoutProgress: React.FC<TimeoutProgressProps> = ({ timeoutSeconds, startDate, ...props }) => {
  const [progress, setProgress] = useState(0);
  useMount(() => {
    const interval = setInterval(() => {
      const newProgress = ((((new Date().valueOf()) - startDate.valueOf()) / 1000) / timeoutSeconds) * 100;
      setProgress(Math.min(newProgress, 100));
    }, 100);
    return () => clearInterval(interval);
  });
  return <Progress {...props} percent={progress} showInfo={false}/>;
};

export type TimeoutCommitProps = {
  startDate: Date;
  timeoutSeconds: number;
  commitText: string;
  onCommit: () => void;
  onCancel: () => void;
  hideCancelButton?: boolean;
  disabled?: boolean;
};

const TimeoutCommit: React.FC<TimeoutCommitProps> = ({
  startDate,
  timeoutSeconds,
  commitText,
  onCommit,
  onCancel,
  hideCancelButton,
  disabled,
}) => {
  const { t } = useTranslation();
  const trailColor = 'white';
  const progressColor = appConfig.corporateIdentity.aspectColor;

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
      {startDate
        ? (
          <Space className={styles.timeoutCommit}>
            {hideCancelButton ? null : (<Button danger onClick={onCancel}>{t('button.cancel')}</Button>)}
            <Button type="primary" onClick={onCommit} style={{ overflow: 'hidden' }}>
              {commitText}
              <div>
                <TimeoutProgress
                  className={styles.timeoutProgress}
                  size={'small'}
                  strokeLinecap={'square'}
                  strokeWidth={4}
                  strokeColor={progressColor}
                  trailColor={trailColor}
                  timeoutSeconds={timeoutSeconds}
                  startDate={startDate}
                />
              </div>
            </Button>
          </Space>
        )
        : <Button type="primary" onClick={onCommit} disabled={disabled}>{commitText}</Button>}
    </div>
  );
};

/**
 * A button with a delayed action that can also be controlled from outside the component, using the functions returned
 * from the hook.
 *
 * Adapted from https://dev.to/droopytersen/new-react-hooks-pattern-return-a-component-31bh
 */

export const useTimeoutCommit = ({
  timeoutSeconds = 10,
  onCommit = noop,
  onCancel = noop,
  commitText,
  ...props
}: TimeoutCommitProps) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const timeoutHandle = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { t } = useTranslation();

  const handleCancel = () => {
    setStartDate(null);
    if (timeoutHandle.current) {
      clearTimeout(timeoutHandle.current);
    }
    onCancel();
  };

  const handleCommit = () => {
    setStartDate(null);
    if (timeoutHandle.current) {
      clearTimeout(timeoutHandle.current);
    }
    onCommit();
  };

  const handleStart = () => {
    setStartDate(new Date());
    timeoutHandle.current = setTimeout(handleCommit, timeoutSeconds * 1000);
  };

  return ({
    commit: handleCommit,
    cancel: handleCancel,
    start: handleStart,
    timeoutCommitProps: {
      ...props,
      commitText: commitText || t('button.save'),
      onStart: handleStart,
      onCancel: handleCancel,
      onCommit: handleCommit,
      startDate,
      timeoutSeconds,
    },
  });
};

export default observer(TimeoutCommit);
