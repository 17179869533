import React from 'react';
import { useTranslation } from 'react-i18next';
import { faRepeat } from '@fortawesome/pro-solid-svg-icons';
import { useStore } from '../../../hooks/useStore';
import Form from '../../shared/form/Form';
import FormModal from '../../shared/modal/FormModal';
import { FormItemLayouts as formItemLayouts } from '../../shared/form/formItemLayouts';
import Select from '../../shared/inputs/Select';
import DefaultFormActions from '../../shared/form/DefaultFormActions';

export type ReprintConfirmModalProps = {
  printJobLog: any;
  onCancel: () => void;
  onConfirm: (formData: any) => void;
};

const ReprintConfirmModal: React.FC<ReprintConfirmModalProps> = ({
  printJobLog,
  onCancel,
  onConfirm,
}) => {
  const store = useStore();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    <FormModal
      title={t('printJobLog.reprint.button')}
      open
      onCancel={onCancel}
    >
      <Form
        form={form}
        onFinish={onConfirm}
        {...formItemLayouts.fullWidth}
      >
        <Form.Item
          name={'printerId'}
          label={t('printJobLog.reprint.selection')}
          initialValue={printJobLog.printerId}
        >
          <Select
            options={
              store.printerStore.printers.map((printer) => ({
                label: printer.name,
                value: printer.id,
              }))
            }
          />
        </Form.Item>
        <DefaultFormActions
          onCancel={onCancel}
          submitText={t<string>('printJobLog.reprint.button')}
          submitIcon={faRepeat}
        />
      </Form>
    </FormModal>
  );
};

export default ReprintConfirmModal;
