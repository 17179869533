import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Form, Modal as AntModal, Row, Space} from 'antd';
import {ClockCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {faClock, faPlay, faPlayPause} from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import Modal from '../shared/modal/Modal';
import styles from './StateTransitionWidgetDetailModal.module.css';
import {DatePicker} from '../shared/DatePicker';
import {datetimeFormat} from '../../config/dayjs';
import {useStore} from '../../hooks/useStore';
import {ApiError} from '../../models/apiError';
import {validPhaseTimeoutEndpoint} from '../../middleware/endpoints/workplaces';
import appConfig from '../../utils/appConfig';
import {useMount} from '../../hooks/useMount';
import PhaseTimeoutPartialForm from './PhaseTimeoutPartialForm';
import Radio from '../shared/inputs/Radio';
import {ValidationRules} from '../../utils/validationRules';
import Button from '../shared/buttons/Button';
import AlertWarning from '../shared/alert/AlertWarning';
import StateTransitionErrorModal from './StateTransitionErrorModal';
import {StateTransitionWidgetConfig} from './StateTransitionWidgetConfig';
import StateTransitionWidget from './StateTransitionWidget';
import ModelProperties from '../shared/descriptions/ModelProperties';

const {Item, useForm} = Form;

/**
 * t('stateTransitionWidget.modal.form.mode.NOW')
 * t('stateTransitionWidget.modal.form.mode.SCHEDULED')
 * t('stateTransitionWidget.modal.form.mode.QUEUED')
 */
const TransitionModes = Object.freeze({
  Now: 'NOW',
  Scheduled: 'SCHEDULED',
  Queued: 'QUEUED',
});

const TransitionModeIcons = Object.freeze({
  NOW: <FontAwesomeIcon icon={faPlay}/>,
  SCHEDULED: <FontAwesomeIcon icon={faClock}/>,
  QUEUED: <FontAwesomeIcon icon={faPlayPause}/>,
});

const DatePickerItem = ({
  disabledDate,
  isTransitionRunning,
  setDateTime,
  lastStateChange,
  hidden,
  showLastTransition,
}) => {
  const {t} = useTranslation();
  return (
    <div hidden={hidden}>
      <p>{t('stateTransitionWidget.modal.form.datetime.label')}</p>
      <Row gutter={16}>
        <Col>
          <Item name="datetime" initialValue={dayjs()}>
            <DatePicker
              showToday
              showTime
              format={datetimeFormat}
              disabledDate={disabledDate}
              disabled={isTransitionRunning}
              allowClear={false}
            />
          </Item>
        </Col>
        <Col hidden={!showLastTransition}>
          <Item>
            <Button onClick={setDateTime} disabled={!lastStateChange || isTransitionRunning}>
              <ClockCircleOutlined/>
              {t('stateTransitionWidget.modal.form.button.lastStateChange')}
            </Button>
          </Item>
        </Col>
      </Row>
    </div>
  );
};

const ShowDatePicker = ({onClick}) => {
  const {t} = useTranslation();
  return (
    <Row>
      <Col xs={26}>
        <Form.Item name="addTime">
          <Button
            key="interruptionModalAddTime"
            htmlType="button"
            size="large"
            icon={<PlusOutlined/>}
            onClick={onClick}
          >
            {t('stateTransitionWidget.modal.form.button.setTime')}
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};

const RadioButton = ({mode, disabled = false}) => {
  const {t} = useTranslation();
  return (
    <Radio.Button value={mode} disabled={disabled}>
      {TransitionModeIcons[mode]}
      {' '}
      {t(`stateTransitionWidget.modal.form.mode.${mode}`)}
    </Radio.Button>
  );
};

export const StateTransitionWidgetDetailModal = ({
  defaultTransitionId,
  lastStateChange,
  startingOperationId = undefined,
  isForceStarted = undefined,
  afterExecuteTransition = undefined,
  onApiError = undefined,
  onCancel,
  enableTransitionQueue = false,
  ...rest
}) => {
  const {t} = useTranslation();
  const store = useStore();

  const [form] = useForm();
  const [fields] = useState([{name: ['datetime'], value: dayjs()}]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showGetDatePicker, setShowGetDatePicker] = useState(false);
  const [showLastTransition, setShowLastTransition] = useState(false);
  const [isTransitionRunning, setIsTransitionRunning] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [validPhaseTimeout, setValidPhaseTimeout] = useState(null);
  const [transitionOptions, setTransitionOptions] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [disableScheduled, setDisableScheduled] = useState(false);
  const [missingTransition, setMissingTransition] = useState(false);
  const [errors, setErrors] = useState([]);
  const [performTransitionValues, setPerformTransitionValues] = useState();
  const isEditQueue = useMemo(
    () => !!store.operationStore.getById(startingOperationId)?.transitionQueue,
    [startingOperationId]
  );
  const widgetConfig = useMemo(() =>
    new StateTransitionWidgetConfig(store, StateTransitionWidget.identifier), []);
  const relevantOperation = startingOperationId
    ? store.operationStore.getById(startingOperationId)
    : store.operationStore.active;
  const displayableProperties = widgetConfig.getOperationProperties();

  const handleTransitionSelect = (transitionId, mode) => {
    let transitionLabel;
    if (!startingOperationId) {
      transitionLabel = store.validTransitionsStore.getById(transitionId)?.label;
    } else if (mode === TransitionModes.Now) {
      transitionLabel = store.validStartTransitionsStore.getById(transitionId)?.label;
    } else {
      transitionLabel = store.transitionStore.getById(transitionId)?.label;
    }

    const newTitle = `${t('stateTransitionWidget.modal.title')} - `
      + `${transitionLabel || '...'}`;
    setModalTitle(newTitle);
  };

  const setTransitionId = (transitionId, mode) => {
    form.setFieldValue('transitionId', transitionId);
    handleTransitionSelect(transitionId, mode);
  };

  const setScheduledDatetime = () => {
    const operation = store.operationStore.getById(startingOperationId);
    let datetime = dayjs();
    if (isEditQueue && operation.transitionQueue?.plannedExecution) {
      datetime = dayjs(operation.transitionQueue.plannedExecution);
    }
    form.setFieldValue('datetime', datetime);
  };

  const handleModeChange = (mode) => {
    if (mode === TransitionModes.Now) {
      setShowDatePicker(false);
      setShowGetDatePicker(true);
      setShowLastTransition(false);
      form.setFieldValue('datetime', undefined);
      if (startingOperationId) {
        setTransitionOptions(store.validStartTransitionsStore.transitions);
      } else {
        setTransitionOptions([]);
      }
      setTransitionId(defaultTransitionId, mode);
      setMissingTransition(false);
    } else if (mode === TransitionModes.Scheduled) {
      setShowDatePicker(true);
      setShowGetDatePicker(false);
      setShowLastTransition(false);
      setScheduledDatetime();
    } else if (mode === TransitionModes.Queued) {
      setShowDatePicker(false);
      setShowGetDatePicker(false);
      setShowLastTransition(false);
      form.setFieldValue('datetime', undefined);
    }
    if (mode === TransitionModes.Scheduled || mode === TransitionModes.Queued) {
      const operation = store.operationStore.getById(startingOperationId);
      const transitions = store.transitionStore
        .getValidQueueTransitions(operation.workflowId || operation.order.workflowId);
      setTransitionOptions(transitions);
      if (transitions.length === 1) {
        setTransitionId(transitions[0].id, mode);
        setMissingTransition(false);
      } else {
        setTransitionId(undefined, mode);
        if (transitions.length === 0) {
          setMissingTransition(true);
        } else {
          setMissingTransition(false);
        }
      }
    }
  };

  const performTransition = async (request, values) => {
    const response = await store.transitionStore.performTransition({
      workplaceId: store.workplaceStore.selectedWorkplace?.id,
      body: request,
    }).catch((error) => {
      AntModal.error({
        title: t('transition.transitionExecute.errorOnExecute'),
        content: error.message,
      });
      return error;
    });
    if (response instanceof ApiError) {
      onApiError(response);
    } else {
      if (startingOperationId) {
        store.operationStore.load(startingOperationId);
      }

      if (store.operationStore.active?.id) {
        store.operationStore.load(store.operationStore.active?.id);
      }

      if (afterExecuteTransition) {
        afterExecuteTransition(response, request);
      }
    }

    if (!response?.errors?.length) {
      return true;
    }
    // eslint-disable-next-line no-use-before-define
    setErrors(response.errors);
    setPerformTransitionValues(values);

    return !(response.customActionType && response.customActionType === 'PRE');
  };

  const resetForm = () => {
    form.resetFields();
    onCancel();
  };

  const inventoryCheckTransition = async (values) => {
    if (values.phaseTimeoutReasonId) {
      values.timeoutMessage = store.phaseTimeoutReasonStore.getById(values.phaseTimeoutReasonId)?.reason;
    }
    const request = {
      transitionId: values.transitionId,
      timestamp: values.datetime ? values.datetime.seconds(0).milliseconds(0).toISOString() : undefined,
      activeOperationId: store.operationStore.active?.id,
      startingOperationId,
      isForceStarted,
      timeoutMessage: values.timeoutMessage,
      phaseTimeoutReasonId: values.phaseTimeoutReasonId,
      additionalPayload: {
        inventoryChecked: true,
      },
    };
    setIsTransitionRunning(true);
    const result = await performTransition(request, values);
    setIsTransitionRunning(false);
    if (result) {
      resetForm();
    }
  };

  const standardTransition = async (values) => {
    if (values.phaseTimeoutReasonId) {
      values.timeoutMessage = store.phaseTimeoutReasonStore.getById(values.phaseTimeoutReasonId)?.reason;
    }
    const request = {
      transitionId: values.transitionId,
      timestamp: values.datetime ? values.datetime.seconds(0).milliseconds(0).toISOString() : undefined,
      activeOperationId: store.operationStore.active?.id,
      startingOperationId,
      isForceStarted,
      timeoutMessage: values.timeoutMessage,
      phaseTimeoutReasonId: values.phaseTimeoutReasonId,
    };

    return performTransition(request, values);
  };

  const isValidPhaseTimeout = (timestamp) => {
    setIsValidating(true);
    return store.workplaceStore.api[validPhaseTimeoutEndpoint](
      store.workplaceStore.selectedWorkplace.id,
      {timestamp: timestamp ? timestamp.toISOString() : undefined}
    ).then((res) => {
      setIsValidating(false);
      setValidPhaseTimeout(res.data === true);
      return res.data;
    }).catch(() => {
      setIsValidating(false);
      return true;
    });
  };

  const sendTransition = async (values) => {
    setIsTransitionRunning(true);
    if (appConfig.modules.enablePhaseTimeout && validPhaseTimeout) {
      const isValid = await isValidPhaseTimeout(values.datetime);
      if (isValid !== true) {
        setIsTransitionRunning(false);
        return;
      }
    }
    const result = await standardTransition(values);
    setIsTransitionRunning(false);
    if (result) {
      resetForm();
    }
  };

  const sendTransitionQueue = async (values) => {
    setIsTransitionRunning(true);
    const operation = store.operationStore.getById(startingOperationId);
    const payload = {
      transitionId: values.transitionId,
      operationId: startingOperationId,
      workplaceId: operation.workplaceId,
      plannedExecution: values.mode === TransitionModes.Scheduled ? values.datetime.toISOString() : null,
    };
    if (isEditQueue) {
      await store.transitionQueueStore.update({...payload, id: operation.transitionQueue.id});
    } else {
      await store.transitionQueueStore.create(payload);
    }
    setIsTransitionRunning(false);
    resetForm();
  };

  const sendForm = async (values) => {
    if (values.mode === TransitionModes.Now) {
      await sendTransition(values);
    } else {
      await sendTransitionQueue(values);
    }
  };

  const setDateTime = () => {
    form.setFieldsValue({
      datetime: dayjs(lastStateChange.start).add(1, 'minute'),
    });
  };

  const handleValuesChange = (changedValues, allValues) => {
    if (changedValues.datetime) {
      isValidPhaseTimeout(changedValues.datetime);
    }
    if (changedValues.transitionId) {
      handleTransitionSelect(changedValues.transitionId, allValues.mode);
    }
    if (changedValues.mode) {
      handleModeChange(changedValues.mode);
    }
  };

  const handleDequeue = async () => {
    const operation = store.operationStore.getById(startingOperationId);
    if (operation.transitionQueue) {
      setIsTransitionRunning(true);
      await store.transitionQueueStore.delete(operation.transitionQueue.id);
      setIsTransitionRunning(false);
      onCancel();
    }
  };

  const disabledDate = (current) => {
    const mode = form.getFieldValue('mode');
    if (TransitionModes.Now === mode) {
      if (!lastStateChange) {
        return false;
      }
      const maxStart = dayjs(lastStateChange.start).add(1, 'minute');
      const maxEnd = dayjs();
      return !(maxStart.isSameOrBefore(current) && maxEnd.isSameOrAfter(current));
    }
    if (TransitionModes.Scheduled === mode) {
      return dayjs().startOf('day').isSameOrAfter(current);
    }
    return false;
  };

  useMount(() => {
    let defaultMode = TransitionModes.Now;
    if (startingOperationId && enableTransitionQueue) {
      const operation = store.operationStore.getById(startingOperationId);
      if (!defaultTransitionId || operation.transitionQueue?.plannedExecution) {
        defaultMode = TransitionModes.Scheduled;
      } else if (operation.transitionQueue && operation.transitionQueue.plannedExecution === null) {
        defaultMode = TransitionModes.Queued;
      }
    }
    handleModeChange(defaultMode);
    form.setFieldValue('mode', defaultMode);
    if (!appConfig.modules.enablePhaseTimeout) {
      setValidPhaseTimeout(true);
      return;
    }
    isValidPhaseTimeout();
  });

  useMount(() => {
    const transitionQueue = store.transitionQueueStore
      .getScheduledByWorkplaceId(store.workplaceStore.selectedWorkplace.id);
    setDisableScheduled(transitionQueue && transitionQueue.operationId !== startingOperationId);
  }, [store.transitionQueueStore.transitionQueues.length]);

  return (
    <>
      <Modal open footer={false} maskClosable={false} title={modalTitle} onCancel={onCancel} {...rest}>
        <Form
          form={form}
          fields={fields}
          onFinish={sendForm}
          layout="vertical"
          onValuesChange={handleValuesChange}
          preserve
        >
          <Item
            name={'mode'}
            hidden={!enableTransitionQueue || !startingOperationId}
          >
            <Radio.Group buttonStyle="solid">
              <RadioButton
                mode={TransitionModes.Now}
                disabled={startingOperationId && store.validStartTransitionsStore.transitions.length === 0}
              />
              <RadioButton mode={TransitionModes.Scheduled} disabled={disableScheduled}/>
              <RadioButton mode={TransitionModes.Queued}/>
            </Radio.Group>
          </Item>
          <DatePickerItem
            disabledDate={disabledDate}
            isTransitionRunning={isTransitionRunning}
            setDateTime={setDateTime}
            lastStateChange={lastStateChange}
            hidden={!showDatePicker}
            showLastTransition={showLastTransition}
          />
          {showGetDatePicker && (
            <ShowDatePicker
              onClick={() => {
                setShowDatePicker(true);
                setShowLastTransition(true);
                setShowGetDatePicker(false);
                form.setFieldValue('datetime', dayjs());
              }}
              form={form}
            />
          )}
          <Item
            name={'transitionId'}
            label={t('transition.model.one')}
            rules={[ValidationRules.required()]}
            hidden={transitionOptions.length <= 1}
          >
            <Radio.Group>
              <Space direction="vertical">
                {transitionOptions.map((transition) => (
                  <Radio value={transition.id} key={transition.id}>{transition.label}</Radio>
                ))}
              </Space>
            </Radio.Group>
          </Item>
          {missingTransition && (
            <AlertWarning message={t('stateTransitionWidget.modal.noTransition')}/>
          )}
          {isEditQueue && (
            <Row>
              <Col xs={24}>
                <Button
                  onClick={handleDequeue}
                  icon={<FontAwesomeIcon icon={faTrash}/>}
                  type="danger"
                >
                  {t('stateTransitionWidget.modal.form.button.dequeue')}
                </Button>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={24}>
              {validPhaseTimeout === false && (
                <PhaseTimeoutPartialForm/>
              )}
            </Col>
          </Row>
          <Item className={styles.buttonRow}>
            <Button
              className={styles.buttonDiscard}
              htmlType="button"
              size="large"
              onClick={resetForm}
              danger
              disabled={isTransitionRunning}
            >
              {t('stateTransitionWidget.modal.form.button.discard')}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={isTransitionRunning || isValidating}
            >
              {t('stateTransitionWidget.modal.form.button.submit')}
            </Button>
          </Item>
        </Form>
        {displayableProperties.length > 0 && !!relevantOperation && (
          <ModelProperties
            loading={store.operationStore.isLoadingCollection}
            properties={displayableProperties}
            model={relevantOperation}
          />
        )}
      </Modal>
      {errors.length && (
        <StateTransitionErrorModal
          errors={errors}
          values={performTransitionValues}
          inventoryCheckTransition={inventoryCheckTransition}
          onCancel={() => setErrors([])}
          onFinish={onCancel}
        />
      )}
    </>
  );
};
