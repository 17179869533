import { observable, makeObservable, computed } from 'mobx';
import { faBoxes } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { sortAlphabetically } from '../components/shared/tables/sorters';
import { RootStore } from '../stores/rootStore';

/**
 * sub-type translations:
 * t('yieldType.subTypes.PRODUCT_YIELD')
 * t('yieldType.subTypes.PRODUCT_LOSS')
 * t('yieldType.subTypes.REWORK')
 */
export enum YieldTypeSubType {
  PRODUCT_YIELD = 'PRODUCT_YIELD',
  PRODUCT_LOSS = 'PRODUCT_LOSS',
  REWORK = 'REWORK'
}

export class YieldType extends BaseModel {
  id: number = 0;
  name: string = '';
  label: string | null = null;
  unitOfMeasureId: number | null = null;
  subType: YieldTypeSubType | null = null;
  sortOrder: number | null = null;
  isProduct: boolean = false;
  isSensorRelevant: boolean = false;
  isInternal: boolean = false;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      label: observable,
      unitOfMeasureId: observable,
      unitOfMeasure: computed,
      subType: observable,
      sortOrder: observable,
      isProduct: observable,
      isSensorRelevant: observable,
      isInternal: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faBoxes;

  searchableProperties = ['name', 'label'];

  displayableProperties = [
    displayableProperty({
      key: 'name',
      title: i18n.t('yieldType.model.attributes.name'),
      params: [displayablePropertyParam({ path: 'name' })],
    }),
    displayableProperty({
      key: 'label',
      title: i18n.t('yieldType.model.attributes.label'),
      params: [displayablePropertyParam({ path: 'label' })],
      sorter: (a, b) => sortAlphabetically(a.label, b.label),
    }),
    displayableProperty({
      key: 'sortOrder',
      title: i18n.t('yieldType.model.attributes.sortOrder'),
      params: [displayablePropertyParam({ path: 'sortOrder' })],
    }),
  ];

  get unitOfMeasure() {
    return this.rootStore.unitOfMeasurementStore.getById(this.unitOfMeasureId);
  }

  static prepareApiPayload(model: YieldType) {
    return {
      ...model,
      isProduct: Boolean(model.isProduct),
      isSensorRelevant: Boolean(model.isSensorRelevant),
      isInternal: Boolean(model.isInternal),
    };
  }
}
