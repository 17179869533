import dayjs from 'dayjs';
import { isValidCron } from 'cron-validator';
import { isNil, isNaN, intersection } from 'lodash';
import { Rule } from 'antd/es/form';
import { FormInstance } from 'antd';
import i18n from '../i18n/i18n';
import { datetimeFormat } from '../config/dayjs';
import { localeNumber } from './number';

export type InputContent = string | number | undefined | null;

// FIXME: fix type should not be necessary, but there is some type mismatch between the expected FormInstance of
//  a Rule and how it is defined in the imported type. As a work around, we use a simplified consumer interface. This
//  also is a better as it allows interface segregation principle to be applied.
export type SimpleFormInstance = {
  getFieldValue: FormInstance['getFieldValue'];
};

const requiredIf = (required: boolean, { message } = { message: '' }) => ({
  required,
  message: message || i18n.t('errors.messages.blank'),
});

const email: Rule = { type: 'email', message: i18n.t<string>('errors.messages.email') };

const url: Rule = { type: 'url', message: i18n.t<string>('errors.messages.url') };

/**
 * Instantiates a regular expression validating for a correct url. By default, only http|s is allowed as a protocol.
 * This can be changed by providing a different test. The supplied string becomes part of the pattern. A colon is
 * implied and does not need to be provided by the caller.
 * @param protocol
 */
const httpPattern = (protocol = 'https?') => new RegExp(`^(${protocol}:\\/\\/)?`
  + '(([a-z\\d]([a-z\\d-]*[a-z\\d])*)(\\.[a-z\\d]([a-z\\d-]*[a-z\\d])*)*)' // validate domain name
  + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // validate port and path
  + '(\\?[;&a-z\\d%_.~+=-]*)?' // validate query string
  + '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator

const httpUrl: Rule = {
  type: 'url',
  validator: (rule: Rule, value: string) => {
    if (!value) {
      return Promise.resolve();
    }
    const result = httpPattern().test(value);
    if (result) {
      return Promise.resolve();
    }
    return Promise.reject(i18n.t<string>('errors.messages.url'));
  },
};

const toObject = (val: any) => {
  if (typeof val !== 'object') {
    try {
      val = JSON.parse(val);
    } catch (e) {
      return val;
    }
  }
  return val;
};

const isEmpty = (val: InputContent) => isNil(val) || val === '' || isNaN(val);

const requiredTranslatable = () => ({
  validator: (rule: Rule, value: any) => {
    if (isEmpty(value)) {
      return Promise.reject(new Error(i18n.t<string>('errors.messages.blank')));
    }
    if (!Object.values(value).some((translation) => translation)) {
      return Promise.reject(new Error(i18n.t<string>('errors.messages.blank')));
    }
    return Promise.resolve();
  },
});

const toNumber = (val: any): number => {
  if (typeof val === 'number' || isEmpty(val)) {
    return val;
  }
  return parseFloat(val);
};

const object: Rule = { type: 'object', message: i18n.t<string>('errors.messages.object'), transform: toObject };

const positiveNumber: Rule = {
  type: 'number',
  min: 1,
  transform: (val: any) => parseFloat(val),
  message: i18n.t<string>('errors.messages.mustBeAPositiveNumber'),
};

const greaterThanOrEqualTo = (min: number): Rule => ({
  type: 'number',
  validator: (rule: Rule, value: number) => {
    if (value >= min || isEmpty(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(i18n.t<string>('errors.messages.mustBeMinOrGreater', { min })));
  },
  transform: toNumber,
});

const greaterThan = (min: number): Rule => ({
  type: 'number',
  validator: (rule: Rule, value: number) => {
    if (value > min || isEmpty(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(i18n.t<string>('errors.messages.mustBeGreaterThanMin', { min })));
  },
  transform: toNumber,
});

const lessThanOrEqualTo = (max: number): Rule => ({
  type: 'number',
  validator: (rule: Rule, value: number) => {
    if (value <= max || isEmpty(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(i18n.t<string>('errors.messages.mustBeMaxOrLess', { max })));
  },
  transform: toNumber,
});

const lessThan = (max: number): Rule => ({
  type: 'number',
  validator: (rule: Rule, value: number) => {
    if (value < max || isEmpty(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(i18n.t<string>('errors.messages.mustBeLessThanMax', { max })));
  },
  transform: toNumber,
});

const maxDecimalPlaces = (decimals: number): Rule => ({
  type: 'number',
  validator: (rule: Rule, value: number) => {
    if (isEmpty(value)) {
      return Promise.resolve();
    }
    const parts = new Intl.NumberFormat().formatToParts(value);
    const decimalLocation = parts.findIndex((part) => part.type === 'decimal');
    if (decimalLocation === -1 || decimalLocation === parts.length - 1
      || parts[decimalLocation + 1].value.length <= decimals) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(i18n.t<string>('errors.messages.maxDecimalPlaces', { decimalPlaces: decimals })));
  },
});

const minDecimalPlaces = (decimals: number): Rule => ({
  type: 'number',
  validator: (rule: Rule, value: number) => {
    if (isEmpty(value)) {
      return Promise.resolve();
    }
    const parts = new Intl.NumberFormat().formatToParts(value);
    const decimalLocation = parts.findIndex((part) => part.type === 'decimal');
    if (decimalLocation === -1 || decimalLocation === parts.length - 1
      || parts[decimalLocation + 1].value.length < decimals) {
      return Promise.reject(new Error(i18n.t<string>('errors.messages.minDecimalPlaces', { decimalPlaces: decimals })));
    }
    return Promise.resolve();
  },
});

const integer: Rule = {
  type: 'number',
  validator: (rule: Rule, value: number | string) => {
    if (isNil(value) || value === '') {
      return Promise.resolve();
    }
    if (Number(value) % 1 === 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(i18n.t<string>('errors.messages.mustBeAnInteger')));
  },
  transform: toNumber,
};

// eslint-disable-next-line no-unused-vars
const dateRangeValidatorIf = ({ checkFromValid = true, checkToValid = true, condition = false } = {}) => () => ({
  validator(rule: Rule, value: any) {
    if (condition) {
      if (value) {
        if (checkFromValid && checkToValid) {
          if (dayjs(value[0]).isValid() && dayjs(value[1]).isValid()) {
            return Promise.resolve();
          }
        } else if (checkFromValid && !checkToValid) {
          if (dayjs(value[0]).isValid()) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(i18n.t<string>('errors.messages.fromDateRequired')));
        } else if (!checkFromValid && checkToValid) {
          if (dayjs(value[1]).isValid()) {
            return Promise.resolve();
          }
          return Promise.reject(new Error(i18n.t<string>('errors.messages.toDateRequired')));
        }
      }

      return Promise.reject(new Error(i18n.t<string>('errors.messages.dateRangeRequired')));
    }

    return Promise.resolve();
  },
});

/**
 * @param maxSize Define max file size in mb
 */
const maxFileSize = (maxSize: number) => ({
  type: 'object',
  validator: (rule: Rule, file: File) =>
    (file && file.size / 1024 / 1024 > maxSize
      ? Promise.reject(new Error(i18n.t<string>('errors.messages.maxFileSize', { maxFileSize: maxSize })))
      : Promise.resolve()),
});

const uniqueKeys = () => ({
  validator: (rule: Rule, value: any) => {
    if (value) {
      const trimmedValue = value.replace(/\s(?=(?:[^'"`]*(['"`])[^'"`]*\1)*[^'"`]*$)/g, '');
      const valueObject = toObject(value);
      if (typeof valueObject === 'object') {
        if (trimmedValue.length > JSON.stringify(valueObject).length) {
          return Promise.reject(new Error(i18n.t<string>('errors.messages.uniqueKeys')));
        }
      }
    }
    return Promise.resolve();
  },
});

const stringMinLength = (length: number) => ({
  min: length,
  message: i18n.t('errors.messages.minLength', { length }),
});

const stringMinLengthAlphanumeric = (length: number) => ({
  validator: (rule: Rule, value: string) => {
    if (length === 0 || (value ? value.replace(/[^a-zA-Z0-9]/gi, '').length : 0) >= length) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(i18n.t<string>('errors.messages.minLengthAlphanumeric', { length })));
  },
});

const stringMaxLength = (length: number) => ({
  max: length,
  message: i18n.t<string>('errors.messages.maxLength', { length }),
});

const maxSelectCount = (maxLength: number): Rule => ({
  type: 'array',
  validator: (rule: Rule, value: any[]) => {
    if (!value || value.length <= maxLength) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(i18n.t<string>('errors.messages.maxLengthOrLessMustBeSelected', { maxLength })));
  },
});

const isSameOrAfter = (minDate: dayjs.Dayjs) => {
  if (!minDate) {
    return Promise.resolve();
  }
  return ({
    validator: (rule: Rule, value: dayjs.Dayjs) => {
      if (value && !value.isSameOrAfter(minDate)) {
        return Promise.reject(
          new Error(i18n.t<string>('errors.messages.mustBeSameOrAfter', { minDate: minDate.format(datetimeFormat) }))
        );
      }
      return Promise.resolve();
    },
  });
};

const isAfter = (minDate: dayjs.Dayjs) => ({
  validator: (rule: Rule, value: dayjs.Dayjs) => {
    if (!minDate) {
      return Promise.resolve();
    }
    if (value && dayjs(value).isValid() && !value.isAfter(minDate)) {
      return Promise.reject(
        new Error(i18n.t<string>('errors.messages.mustBeAfter', { minDate: minDate.format(datetimeFormat) }))
      );
    }
    return Promise.resolve();
  },
});

const isAfterField = (otherField: string, otherFieldTranslation: string) => ({ getFieldValue }: FormInstance) => ({
  validator: (rule: Rule, value: dayjs.Dayjs) => {
    const minDate = getFieldValue(otherField);
    if ((!minDate && !value) || (minDate && value && value.isAfter(minDate))) {
      return Promise.resolve();
    }
    if (!minDate) {
      return Promise.reject(
        new Error(i18n.t<string>('errors.messages.valueInFieldMissing', { otherField: otherFieldTranslation }))
      );
    }
    return Promise.reject(
      new Error(i18n.t<string>('errors.messages.mustBeAfter', { minDate: minDate.format(datetimeFormat) }))
    );
  },
});

const isSameOrBefore = (maxDate: dayjs.Dayjs) => {
  if (!maxDate) {
    return Promise.resolve();
  }
  return ({
    validator: (rule: Rule, value: dayjs.Dayjs) => {
      if (value && !value.isSameOrBefore(maxDate)) {
        return Promise.reject(
          new Error(i18n.t<string>('errors.messages.mustBeSameOrBefore', { maxDate: maxDate.format(datetimeFormat) }))
        );
      }
      return Promise.resolve();
    },
  });
};

const isBefore = (maxDate: dayjs.Dayjs) => {
  if (!maxDate) {
    return Promise.resolve();
  }
  return ({
    validator: (rule: Rule, value: dayjs.Dayjs) => {
      if (value && dayjs(value).isValid() && !value.isBefore(maxDate)) {
        return Promise.reject(
          new Error(i18n.t<string>('errors.messages.mustBeBefore', { maxDate: maxDate.format(datetimeFormat) }))
        );
      }
      return Promise.resolve();
    },
  });
};

const isBeforeField = (otherField: string, otherFieldTranslation: 'string') => ({ getFieldValue }: FormInstance) => ({
  validator: (rule: Rule, value: dayjs.Dayjs | undefined) => {
    const maxDate = getFieldValue(otherField);
    if ((!maxDate && !value) || (maxDate && value && value.isBefore(maxDate))) {
      return Promise.resolve();
    }
    if (!maxDate) {
      return Promise.reject(
        new Error(i18n.t<string>('errors.messages.valueInFieldMissing', { otherField: otherFieldTranslation }))
      );
    }
    return Promise.reject(
      new Error(i18n.t<string>('errors.messages.mustBeBefore', { maxDate: maxDate.format(datetimeFormat) }))
    );
  },
});

const isTrue = () => ({
  validator: (rule: Rule, value: any) => {
    if (value === true) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error(i18n.t<string>('errors.messages.mustBeTrue'))
    );
  },
});

const validCron = {
  validator: (rule: Rule, value: string | undefined) => {
    if (!value || isValidCron(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(i18n.t<string>('errors.messages.isNotValidCron')));
  },
};

const number = {
  validator: (rule: Rule, v: string) => {
    const vl = localeNumber(navigator.language, v || '');
    // eslint-disable-next-line no-restricted-globals
    if (!isEmpty(vl) && (isNaN(vl) || !isFinite(Number(vl)) || /\s/g.test(vl))) {
      return Promise.reject(new Error(i18n.t<string>('errors.messages.notNumber')));
    }
    return Promise.resolve();
  },
};

const safeNumber = {
  validator: (rule: Rule, v: any) => {
    if (v === '' || v === undefined || v === null || typeof v === 'number') {
      return Promise.resolve();
    }
    const parts = new Intl.NumberFormat(undefined, { maximumFractionDigits: 20 }).formatToParts(v);
    const fractionLength = parts.find((part) => part.type === 'fraction')?.value.length || 0;
    // Check if javascript has truncated the number and therefore imprecision will be introduced
    // For this the string will be transformed to number and back to string again
    // which will then be compared to the original string
    // This solution needs to be verified and is therefore only used for the yield widget
    const num = Number(v);
    const numAsStr = num.toFixed(fractionLength);
    if (v !== numAsStr) {
      return Promise.reject(new Error(i18n.t<string>('errors.messages.notSafeNumber')));
    }
    return Promise.resolve();
  },
};

/**
 * Checks if the value is not part of another fields value. If the values are numbers or strings, they are compared for
 * equality. If one or both are arrays they are checked for intersections of their values. Intersecting values will fail
 * the validation.
 *
 * For reactive validation, add the other field as a dependency of the one where this rule is added.
 *
 * If the values are neither a number, string or array of one of those, this validation may break.
 * @param {string|function(Rule)} otherFieldName The name of the field to compare against
 * @param {string} otherFieldI18n The resolved label translation of the other field
 */
const notInField = (
  otherFieldName: string | string[] | ((r: Rule) => string | string[]),
  otherFieldI18n: string
): Rule => ({ getFieldValue }: SimpleFormInstance) => ({
  validator: (rule: Rule, v: any) => {
    let resolvedName = otherFieldName;
    if (typeof resolvedName === 'function') {
      resolvedName = resolvedName(rule);
    }
    let otherValue = getFieldValue(resolvedName);
    let thisValue = v;
    if (!Array.isArray(thisValue) && Array.isArray(otherValue)) {
      thisValue = [thisValue];
    }
    if (!Array.isArray(otherValue) && Array.isArray(thisValue)) {
      otherValue = [otherValue];
    }
    if ((!Array.isArray(thisValue) && thisValue === otherValue) || (intersection(thisValue, otherValue).length > 0)) {
      return Promise.reject(new Error(i18n.t<string>('errors.messages.notInField', { otherField: otherFieldI18n })));
    }
    return Promise.resolve();
  },
});

/**
 * Checks if the value valid compared to another fields value. Can be configured with validationRules which
 * use one parameter only. These will then be configured with the value of the other field and compared against.
 *
 * For reactive validation, add the other field as a dependency of the one where this rule is added.
 *
 * @param otherFieldName The name of the field to compare against
 * @param comparator The function to use for comparison
 */
const compareToField = (otherFieldName: string, comparator: (r: Rule) => any) => ({ getFieldValue }: FormInstance) => ({
  validator: (rule: Rule, value: any) => {
    if (!getFieldValue(otherFieldName)) {
      return Promise.resolve();
    }
    return comparator(getFieldValue(otherFieldName)).validator(rule, value);
  },
});

/**
 * Checks if one of the two fields has a value. If both are empty, the validation fails.
 *
 * For reactive validation, add the other field as a dependency of the one where this rule is added.
 *
 * @param otherFieldName The name of the second field
 * @param message The error message
 */
const oneFieldRequired = (otherFieldName: string, message = '') => ({ getFieldValue }: FormInstance) => ({
  validator(rule: Rule, value: any) {
    if (!value && !getFieldValue(otherFieldName)) {
      return Promise.reject(new Error(message || i18n.t<string>('errors.messages.oneFieldRequired')));
    }
    return Promise.resolve();
  },
});
/**
 * Checks if one of the two fields has a value. If both are empty and the condition returns true, the validation fails.
 *
 * For reactive validation, add the other field as a dependency of the one where this rule is added.
 *
 * @param condition A function that checks if the rule should apply
 * @param otherFieldName The name of the second field
 * @param message The error message
 */
const oneFieldRequiredIf = (
  condition: () => boolean,
  otherFieldName: string,
  message = ''
) => ({ getFieldValue }: FormInstance) => ({
  validator(rule: Rule, value: any) {
    if (!condition()) {
      return Promise.resolve();
    }
    if (!value && !getFieldValue(otherFieldName)) {
      return Promise.reject(new Error(message || i18n.t<string>('errors.messages.oneFieldRequired')));
    }
    return Promise.resolve();
  },
});

const mustNotContain = (forbidden: string) => {
  if (!forbidden) {
    return Promise.resolve();
  }
  return {
    validator: (rule: Rule, value: string | undefined) => {
      if (!value || !value.includes(forbidden)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(i18n.t<string>('errors.messages.forbiddenString', { forbidden })));
    },
    type: 'string',
  };
};

const safeCharacters = () => ({
  validator: (rule: Rule, value: string | undefined) => {
    // eslint-disable-next-line no-useless-escape
    if (value && !value.match(/^[0-9a-zA-Z_\-.]+$/)) {
      return Promise.reject(new Error(i18n.t<string>('errors.messages.hasUnsafeCharacters')));
    }
    return Promise.resolve();
  },
});

export const ValidationRules = {
  required: () => requiredIf(true),
  requiredTranslatable,
  requiredIf,
  email,
  positiveNumber,
  maxNumber: lessThanOrEqualTo,
  lessThanOrEqualTo,
  lessThan,
  minNumber: greaterThanOrEqualTo,
  greaterThanOrEqualTo,
  greaterThan,
  maxDecimalPlaces,
  minDecimalPlaces,
  integer,
  url,
  urlPattern: httpPattern,
  httpUrl,
  object,
  dateRangeValidatorIf,
  maxFileSize,
  uniqueKeys,
  stringMinLength,
  stringMinLengthAlphanumeric,
  stringMaxLength,
  maxSelectCount,
  isSameOrAfter,
  isAfter,
  isAfterField,
  isSameOrBefore,
  isBefore,
  isBeforeField,
  isTrue,
  validCron,
  number,
  safeNumber,
  notInField,
  compareToField,
  oneFieldRequired,
  oneFieldRequiredIf,
  mustNotContain,
  safeCharacters,
};
