import { observable, makeObservable, computed } from 'mobx';
import { faBook } from '@fortawesome/pro-solid-svg-icons';
import { Observer } from 'mobx-react-lite';
import { BaseModel } from './base';
import i18n from '../i18n/i18n';
import { transformation } from '../utils/transformations';
import { datetimeFormat } from '../config/dayjs';
import { displayableProperty } from './displayableProperty';
import { displayablePropertyParam } from './displayablePropertyParam';
import { sortAlphabetically, sortChronologically } from '../components/shared/tables/sorters';
import { Order } from './order';
import UserFullName from '../components/shared/UserFullName';
import { RootStore } from '../stores/rootStore';

export class ShiftBook extends BaseModel {
  id: number = 0;
  workplaceId: number = 0;
  message: string = '';
  orderId: number | null = null;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      workplaceId: observable,
      workplace: computed,
      message: observable,
      orderId: observable,
      order: computed,
      createdAt: observable,
      createdBy: observable,
      createdByUser: computed,
      user: computed,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faBook;

  searchableProperties = ['message', 'order.no'];

  displayableProperties = [
    displayableProperty({
      title: i18n.t('shiftBook.model.attributes.createdAt'),
      params: [
        displayablePropertyParam({
          path: 'createdAt', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      key: 'createdAt',
      sorter: (a, b) => sortChronologically(a?.createdAt, b?.createdAt),
    }),
    displayableProperty({
      title: i18n.t('shiftBook.model.attributes.message'),
      params: [displayablePropertyParam({ path: 'message', transform: transformation.none })],
      template: '{value}',
      key: 'message',
      sorter: (a, b) => sortAlphabetically(a?.message, b?.message),
    }),
    displayableProperty({
      title: i18n.t('shiftBook.model.attributes.createdBy'),
      params: [displayablePropertyParam({
        path: 'createdByUser',
        transform: (createdByUser) => (
          <Observer>
            {() => <UserFullName user={createdByUser}/>}
          </Observer>
        ),
      })],
      raw: true,
      template: '{value}',
      key: 'createdBy',
      sorter: (a, b) => sortAlphabetically(a?.createdByUser?.fullName, b?.createdByUser?.fullName),
    }),
  ];

  /**
   * @deprecated use createdByUser
   */
  get user() {
    return this.rootStore.userStore.getById(this.createdBy);
  }

  get createdByUser() {
    return this.rootStore.userStore.getById(this.createdBy);
  }

  get order() {
    return this.rootStore.orderStore.getById(this.orderId);
  }

  get workplace() {
    return this.rootStore.workplaceStore.getById(this.workplaceId);
  }

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);
    const orderPrefix = `${titlePrefix}${i18n.t('order.model.one')} > `;
    allDisplayableProperties.push(
      ...Order.allDisplayableProperties(rootStore, `${pathPrefix}order.`, orderPrefix)
    );

    return allDisplayableProperties;
  }
}
