import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { RootStore } from './rootStore';
import { Gateway } from '../models/gateway';

export class GatewayStore extends EntityStore<Gateway> {
  gateways: Gateway[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'gateways', Api.gateways, Gateway, true);

    makeObservable(this, {
      gateways: observable,
    });
  }
}
