import i18n from '../../../../../i18n/i18n';
import {ValidationRules} from '../../../../../utils/validationRules';
import {CockpitWidgetConfig} from './cockpitWidgetConfig';
import {FieldType} from '../../../../../types/widgetConfig';

export class ImageCockpitWidgetConfig extends CockpitWidgetConfig {
  static onLoadConfig = (rootStore) => {
    rootStore.documentStore.loadAll();
  };

  static widgetType = {
    identifier: 'image',
    name: () => i18n.t('cockpitWidget.widgetTypeIdentifier.image'),
  };

  // eslint-disable-next-line class-methods-use-this
  getWidgetTypeName() {
    return i18n.t('cockpitWidget.widgetTypeIdentifier.image');
  }

  get tabs() {
    return [
      this.generalSettingsTab,
      {
        key: 'image',
        label: i18n.t('widget.WidgetSettingsModal.imageConfigTabTitle'),
      },
    ];
  }

  image = [
    {
      id: 'documentId',
      label: i18n.t('imageCockpitWidgetConfig.settings.image.label'),
      helpText: i18n.t('imageCockpitWidgetConfig.settings.image.help'),
      type: FieldType.Select,
      defaultValue: i18n.t('imageCockpitWidgetConfig.defaultText'),
      options: this.getImagesFromDocuments().map((document) => ({
        value: document.id,
        label: document.label,
      })),
      validations: [ValidationRules.required()],
    },
  ];

  getImagesFromDocuments() {
    const {documents} = this.rootStore.documentStore;
    return documents.filter((document) => document?.isImage);
  }
}
