import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Button, { ButtonProps } from './Button';

export type AddButtonProps = ButtonProps & {
  iconOnly?: boolean;
};

const AddButton: React.FC<AddButtonProps> = ({ iconOnly = false, ...props }) => {
  const { t } = useTranslation();

  const buttonProps = {
    icon: <PlusOutlined/>,
    children: iconOnly ? null : t('links.add'),
    ...props,
  };

  return <Button {...buttonProps}/>;
};

export default AddButton;
