import { createEntity, executeDelete, executeGet, updateEntity } from '../requests';
import { Product } from '../../models/product';

const resourcesName = 'v1/products';

export const ProductsApi = {
  all: ({ ...params }) => executeGet(resourcesName, { params }),
  byId: (id: number) => executeGet(`${resourcesName}/${id}`),
  create: (entity: Product) => createEntity(entity, resourcesName),
  update: ({ id, ...entity }: any) => updateEntity(entity, `${resourcesName}/${id}`),
  destroy: (id: number) => executeDelete(`${resourcesName}/${id}`),
};
