import {observer} from 'mobx-react-lite';
import {useMemo, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBarcode} from '@fortawesome/pro-solid-svg-icons';
import {useStore} from '../../hooks/useStore';
import {ScopeContext} from '../../policies/scopeContext';
import {batch} from '../../models/scope';
import OperatorWidget from '../operator/shared/OperatorWidget';
import {WidgetLayout} from '../../models/widgetLayout';
import {WidgetLayouts} from '../shared/widgets/WidgetLayouts';
import {BarcodeInfoWidgetConfig} from './BarcodeInfoWidgetConfig';
import ScanIngredient from './shared/ScanIngredient';
import BarcodeInfoModal from './BarcodeInfoModal';

const BarcodeInfoWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const widgetConfig = useMemo(() => new BarcodeInfoWidgetConfig(store, identifier), []);

  const [ingredient, setIngredient] = useState(null);

  return (
    <ScopeContext.Provider value={[batch]}>
      <OperatorWidget
        onHeadClick={() => setIngredient('')}
        icon={<FontAwesomeIcon icon={faBarcode}/>}
        title={widgetConfig.getWidgetTitle()}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
      >
        <ScanIngredient
          disabled={!store.operationStore.active}
          ingredient={ingredient}
          onChangeIngredient={setIngredient}
        />
      </OperatorWidget>
      {ingredient || ingredient === '' ? (
        <BarcodeInfoModal
          ingredient={ingredient}
          onClose={() => setIngredient(null)}
          title={widgetConfig.getWidgetTitle()}
        />
      ) : null}
    </ScopeContext.Provider>
  );
};

BarcodeInfoWidget.icon = faBarcode;

BarcodeInfoWidget.identifier = 'barcodeInfoWidget';
BarcodeInfoWidget.defaultLayout = new WidgetLayout(
  {
    identifier: BarcodeInfoWidget.identifier,
    x: 1,
    y: 18,
    height: 2,
    minHeight: 2,
    width: WidgetLayouts.sixthWidth.w,
    minWidth: WidgetLayouts.sixthWidth.minW,
  }
);

export default observer(BarcodeInfoWidget);
