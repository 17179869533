import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {Modal, Space, Tabs} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTasks} from '@fortawesome/free-solid-svg-icons';
import BatchLogFormActions from './BatchLogFormActions';
import BatchDescriptions from './BatchDescriptions';
import ConsumedComponents from '../producedMaterial/ConsumedComponents';
import BatchProperties from '../producedMaterial/BatchProperties';
import InspectionTaskResultTable from '../inspectionReport/InspectionTaskResultTable';
import Form from '../shared/form/Form';
import {useStore} from '../../hooks/useStore';
import {useMount} from '../../hooks/useMount';

const ViewBatchLog = ({
  viewRecord,
  consumptionLog,
  onCancel,
  onExport,
  displayableColumns,
}) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const store = useStore();
  const [manualShowRemark, setManualShowRemark] = useState(false);

  const updateRemark = () => {
    store.batchStore.update({
      id: viewRecord.batch.id,
      remark: form.getFieldValue('remark') || '',
    })
      .then(() => onCancel());
  };

  useMount(() => {
    if (viewRecord?.batch?.id) {
      store.inspectionTaskResultStore.loadFilteredWithDependencies({batchId: [viewRecord.batch.id]});
    }
  }, [viewRecord?.batch?.id]);

  return (
    <Modal
      fullscreen={store.clientStore.isMobile}
      width="90%"
      bodyStyle={{minHeight: '70vh'}}
      title={(
        <>
          <FontAwesomeIcon icon={faTasks}/>
          {' '}
          {t('batchLog.view.title')}
        </>
      )}
      open
      onCancel={onCancel}
      footer={false}
    >
      <Tabs
        items={[
          {
            key: 'general',
            label: t('batchLog.view.tab.general'),
            children: (
              <Form
                form={form}
                initialValues={{
                  remark: viewRecord.batch.remark,
                }}
              >
                <Space direction={'vertical'} style={{width: '100%'}}>
                  <BatchDescriptions
                    batch={viewRecord.batch}
                    amount={viewRecord.quantity}
                    unit={viewRecord.unitOfMeasure.label}
                    form={form}
                    manualShowRemark={manualShowRemark}
                    setManualShowRemark={setManualShowRemark}
                  />
                  {consumptionLog.length ? <ConsumedComponents consumptionLog={consumptionLog}/> : ''}
                  {Object.keys(viewRecord.batch?.properties).length
                    ? <BatchProperties properties={viewRecord.batch.properties}/> : ''}
                  <BatchLogFormActions
                    onCancel={onCancel}
                    onExport={onExport}
                    onOk={updateRemark}
                    onOkDisabled={!manualShowRemark && (form.getFieldValue('remark') === undefined)}
                  />
                </Space>
              </Form>
            ),
          },
          {
            key: 'taskResult',
            label: t('batchLog.view.tab.taskResults'),
            children: (
              <InspectionTaskResultTable
                loading={store.inspectionTaskResultStore.areCollectionOrDependenciesLoading}
                displayableColumns={displayableColumns}
                inspectionTaskResults={store.inspectionTaskResultStore.getFiltered({batchId: [viewRecord?.batch?.id]})}
              />
            ),
          },
        ]}
      />
    </Modal>
  );
};

export default observer(ViewBatchLog);
