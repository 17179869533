import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { BatchGeneratorFunction } from '../models/batchGeneratorFunction';
import { RootStore } from './rootStore';

export class BatchGeneratorFunctionStore extends EntityStore<BatchGeneratorFunction> {
  batchGeneratorFunctions: BatchGeneratorFunction[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'batchGeneratorFunctions', Api.batchGeneratorFunctions, BatchGeneratorFunction, true);

    makeObservable(this, {
      batchGeneratorFunctions: observable,
    });
  }
  getDependencies() {
    return [
      {
        store: this.rootStore.customFunctionStore,
        modelId: 'customFunctionId',
      },
      {
        store: this.rootStore.materialStore,
        modelId: 'materialIds',
      },
      {
        store: this.rootStore.workplaceStore,
        modelId: 'workplaceIds',
      },
    ];
  }
}
