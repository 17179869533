export const getChartEventsForLimitHandling = (limit, t) => {
  if (!limit) {
    return undefined;
  }

  return ({
    load() {
      const chart = this;
      chart.yAxis[0].setExtremes(limit?.plausibleLow, limit?.plausibleHigh);
    },
    render() {
      const chart = this;
      const {userMin, userMax, dataMin, dataMax} = chart.yAxis[0].getExtremes();

      // Delete groups
      if (chart.customButtonGroup) {
        chart.customButtonGroup.destroy();
      }

      // Create groups
      chart.customButtonGroup = chart.renderer.g('customButtonGroup').add();
      chart.customButtonGroup.attr('zIndex', 3);

      const addButton = (label, action) => {
        chart.renderer.button(
          label,
          chart.plotLeft + chart.plotSizeX - 180,
          chart.plotTop + 10,
          action,
          {
            width: 150,
            'text-align': 'center',
          }
        ).add(chart.customButtonGroup);
      };

      if (userMin !== limit?.plausibleLow || userMax !== limit?.plausibleHigh) {
        addButton(t('chart.lang.resetZoom'), () => {
          chart.xAxis[0].setExtremes();
          chart.yAxis[0].setExtremes(limit?.plausibleLow, limit?.plausibleHigh);
        });
      } else if (dataMin < userMin || dataMax > userMax) {
        addButton(t('chart.lang.showAllData'), () => {
          chart.zoomOut();
        });
      }

      if (chart.resetZoomButton) {
        chart.resetZoomButton.destroy();
        delete chart.resetZoomButton;
      }
    },
  });
};
