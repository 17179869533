import i18n from '../../i18n/i18n';
import {BaseWidgetConfig} from '../shared/widgets/baseWidgetConfig';
import {sortAlphabetically} from '../shared/tables/sorters';
import {FieldType} from '../../types/widgetConfig';

export class CockpitViewerWidgetConfig extends BaseWidgetConfig {
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'settings',
        label: i18n.t('widget.WidgetSettingsModal.settingConfigTabTitle'),
      },
    ];
  }

  settings = [
    {
      id: 'cockpitWidget',
      label: `${i18n.t('cockpitViewerWidget.settings.cockpit.label')}`,
      helpText: i18n.t('cockpitViewerWidget.settings.cockpit.help'),
      options: this.rootStore.cockpitWidgetStore.getWorkplaceCockpitWidgetsWithoutHierarchyLink()
        .map((cockpitWidget) => ({
          value: cockpitWidget.id,
          label: `${cockpitWidget.dashboard.name} - ${cockpitWidget.name}`,
        }))
        .sort((a, b) => sortAlphabetically(a.label, b.label)),
      type: FieldType.Select,
      allowClear: true,
    },
  ];
}
