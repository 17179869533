import { Button as AntdButton, ButtonProps as AntdButtonProps } from 'antd';
import React, { forwardRef } from 'react';
import ButtonLink from './ButtonLink';
import styles from './Button.module.scss';

export type ButtonProps = AntdButtonProps & {
  to?: string;
  hideOverflow?: boolean;
  ref?: React.ForwardedRef<any>;
  dataCyPrefix?: string;
};

const Button: React.FC<ButtonProps> = forwardRef((
  {
    to,
    children,
    hideOverflow = false,
    dataCyPrefix,
    ...props
  },
  ref?: React.ForwardedRef<any>
) => {
  if (to) {
    return <ButtonLink {...props} to={to} ref={ref}/>;
  }

  let wrappedChildren = children;
  let spanClassName = '';
  let btnClassName = styles.atxBtn;

  if (hideOverflow) {
    if (children && typeof children === 'string') {
      wrappedChildren = (
        <span>{children}</span>
      );
    }

    if (children && Array.isArray(children)) {
      wrappedChildren = children?.map((ele, idx) => {
        if (typeof ele === 'string') {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <span key={idx}>
              {ele}
            </span>
          );
        }
        return ele;
      });
    }
    spanClassName = styles.atxButtonIcn;
    btnClassName = styles.atxBtnFix;
    if (props.icon) {
      spanClassName = `${spanClassName} ${styles.atxButtonIcn}`;
    }
  }

  if (props.className) {
    btnClassName = `${props.className} ${btnClassName}`;
  }

  return (
    <AntdButton
      data-cy={`${dataCyPrefix ? `${dataCyPrefix}-` : ''}Button`}
      {...props}
      ref={ref}
      className={btnClassName}
    >
      {wrappedChildren !== undefined && (
        <span className={spanClassName}>
          {wrappedChildren}
        </span>
      )}
    </AntdButton>
  );
});

export default Button;
