import { observable, makeObservable } from 'mobx';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

export class Shift extends BaseModel {
  id: number = 0;
  name: string = '';
  startTime: string = '';
  endTime: string = '';
  description: string | null = null;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      startTime: observable,
      endTime: observable,
      description: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faClock;

  searchableProperties = ['name', 'description'];
}
