import React from 'react';
import { Empty as AntdEmpty, EmptyProps as AntdEmptyProps } from 'antd';
import classnames from 'classnames';
import styles from './Empty.module.scss';

export type EmptyProps = AntdEmptyProps;

const Empty: React.FC<EmptyProps> = ({
  image = AntdEmpty.PRESENTED_IMAGE_SIMPLE,
  style = { marginTop: 40 },
  ...props
}) => (
  <div>
    <AntdEmpty
      image={image}
      style={style}
      {...props}
      className={classnames({
        [styles.empty]: true,
      })}
    />
  </div>
);

export default Empty;
