import dayjs from 'dayjs';
import i18n from '../../../../../i18n/i18n';
import {ValidationRules} from '../../../../../utils/validationRules';
import {CockpitWidgetConfig} from './cockpitWidgetConfig';
import {Operation} from '../../../../../models/operation';
import {Order} from '../../../../../models/order';
import {WorkplaceState} from '../../../../../models/workplaceState';
import {PersonnelLog} from '../../../../../models/personnelLog';
import appConfig from '../../../../../utils/appConfig';
import {TimeframeOptions} from './timeframeAggregationOptions';
import {displayablePropertyParam} from '../../../../../models/displayablePropertyParam';
import {displayableProperty} from '../../../../../models/displayableProperty';
import {transformation} from '../../../../../utils/transformations';
import {durationFormat} from '../../../../../config/dayjs';
import {FieldType} from '../../../../../types/widgetConfig';

export class DataTableWidgetConfig extends CockpitWidgetConfig {
  static SOURCE_HIERARCHY = 'hierarchy';
  static SOURCE_SELECTION = 'selection';
  static SOURCE_GROUP = 'group';

  static LINK_WORKPLACE_NOWHERE = 'none';
  static LINK_WORKPLACE_TO_COCKPIT = 'cockpit';
  static LINK_WORKPLACE_TO_PRODUCTION = 'production';

  static widgetType = {
    identifier: 'data-table',
    name: () => i18n.t('cockpitWidget.widgetTypeIdentifier.dataTable'),
  };

  // eslint-disable-next-line class-methods-use-this
  getWidgetTypeName() {
    return i18n.t('cockpitWidget.widgetTypeIdentifier.dataTable');
  }

  static onLoadConfig = (rootStore) => {
    rootStore.sensorStore.loadAll();
  };

  getIsWorkplaceCockpit() {
    return this.rootStore.cockpitStore.currentWorkplaceId;
  }

  loadStateHistoriesByWorkplace(workplaceId) {
    return this.rootStore.stateHistoryStore.loadStateHistoriesByWorkplace({
      workplaceId,
      from: (new Date()).toISOString(),
      to: (new Date()).toISOString(),
    }, true);
  }

  loadActiveOperationByWorkplace(workplaceId) {
    return this.rootStore.operationStore.loadActiveOperationByWorkplace(workplaceId).then((operation) => {
      if (operation && operation.orderId) {
        this.rootStore.orderStore.load(operation.orderId).then((order) => {
          this.rootStore.unitOfMeasurementConversionStore.loadByOrderId(order.id);
          if (order && order.parentOrderId) {
            this.rootStore.orderStore.load(order.parentOrderId);
          }
        });
      }
    });
  }

  loadInspectionTasks(workplaceId) {
    return this.rootStore.inspectionTaskPendingStore.loadByWorkplace(workplaceId);
  }

  loadPersonnelLogs(workplaceId) {
    return this.rootStore.personnelLogStore.loadAll({params: {
      workplaceId,
      fromDate: new Date(),
      toDate: new Date(),
    }});
  }

  loadUnclassifiedInterruptions(workplaceId) {
    // Assumed 1 month of logs to consider, as there is no possibility to parametrize this value in this place atm.
    const fromDate = dayjs().subtract(this.getSettingValue('settings', 'duration'), 'days').toISOString();
    const toDate = dayjs().toISOString();
    const loadOperationLogs = this.rootStore.operationStateLogStore.loadAllWithDependencies({
      dependencies: [{
        store: this.rootStore.operationStore,
        modelId: 'operationId',
      }],
      params: {
        workplaceId,
        interruptionReasonId: 'null',
        interruptionClassId: 'null',
        isInterruption: true,
        fromDate,
        toDate,
      },

    });
    const loadWorkplaceLogs = this.rootStore.workplaceStateLogStore.loadAll({
      params: {
        workplaceId,
        interruptionReasonId: 'null',
        interruptionClassId: 'null',
        isInterruption: true,
        fromDate,
        toDate,
      },
    });
    return Promise.all([loadOperationLogs, loadWorkplaceLogs]);
  }

  modelOptions = [
    {
      key: 'operation',
      label: i18n.t('operation.model.one'),
      properties: Operation.allDisplayableProperties(this.rootStore),
      getRecord: ({workplaceId}) => this.rootStore.operationStore.getActiveByWorkplaceId(workplaceId),
      loadAction: 'loadStateHistoriesByWorkplace',
    },
    {
      key: 'keyPerformanceIndicator',
      label: i18n.t('kpi.model.one'),
      properties: () => this.rootStore.keyPerformanceIndicatorStore.kpis
        .map((kpi) => ({
          key: kpi.id,
          title: kpi.label || kpi.name,
          render: () => {}, // rendering of kpi data is done as a special case in the widget itself.
        })),
      getRecord: () => ({
        displayableProperties: this.rootStore.keyPerformanceIndicatorStore.kpis.map((kpi) => ({
          key: kpi.name,
        })),
      }),
    },
    {
      key: 'order',
      label: i18n.t('order.model.one'),
      properties: Order.allDisplayableProperties(this.rootStore, '', '', {includeConversions: true}),
      getRecord: ({workplaceId}) => this.rootStore.operationStore.getActiveByWorkplaceId(workplaceId)?.order,
      loadAction: 'loadActiveOperationByWorkplace',
    },
    {
      key: 'workplaceState',
      label: i18n.t('workplaceState.model.one'),
      properties: [
        ...new WorkplaceState().displayableProperties,
        displayableProperty({
          key: 'activeTimeWorkplaceState',
          title: i18n.t('operationState.model.attributes.activeTime'),
          params: [
            displayablePropertyParam({
              path: 'activeTimeWorkplaceState',
              transform: transformation.duration({format: durationFormat, unit: 'milliseconds'}),
            }),
          ],
          template: '{value}',
        }),
      ],
      getRecord: ({workplaceId}) => ({
        ...this.rootStore.workplaceStateLogStore.getLatestOfWorkplace(workplaceId)?.state,
        activeTimeWorkplaceState: this.rootStore.workplaceStateLogStore.getLatestOfWorkplace(
          workplaceId
        )?.state.getActiveTimeWorkplaceState(workplaceId),
      }),
      loadAction: 'loadStateHistoriesByWorkplace',
    },
    {
      key: 'inspectionTaskPending',
      label: i18n.t('inspectionTaskPending.model.one'),
      properties: [
        {
          key: 'amount',
          title: i18n.t('inspectionTaskPending.amount'),
          render: (text, record) => ({
            props: {},
            children: record.amount,
          }),
        },
        {
          key: 'overdueAmount',
          title: i18n.t('inspectionTaskPending.overdueAmount'),
          render: (text, record) => ({
            props: {},
            children: record.overdueAmount,
          }),
        },
      ],
      getRecord: ({workplaceId}) => {
        const pendingInspectionTasks = this.rootStore.inspectionTaskPendingStore.getByWorkplaceId(workplaceId);
        return ({
          amount: pendingInspectionTasks.length,
          overdueAmount: pendingInspectionTasks.filter((task) => dayjs(task.dueDate).diff(dayjs()) < 0).length,
        });
      },
      loadAction: 'loadInspectionTasks',
    },
    {
      key: 'activePersonnel',
      label: i18n.t('personnelLog.model.one'),
      properties: new PersonnelLog().displayableProperties,
      getRecord: ({workplaceId}) => this.rootStore.personnelLogStore.getLatestByWorkplaceId(workplaceId),
      loadAction: 'loadPersonnelLogs',
    },
    {
      key: 'sensor',
      label: i18n.t('sensor.model.one'),
      properties: () => this.rootStore.sensorStore.sensors
        .map((sensor) => ({
          key: sensor.name,
          title: sensor.label || sensor.name,
          render: () => {}, // rendering of sensor data is done as a special case in the widget itself.
        })),
      getRecord: () => ({
        displayableProperties: this.rootStore.sensorStore.sensors.map((sensor) => ({
          key: sensor.name,
        })),
      }),
    },
    {
      key: 'sensorState',
      label: i18n.t('sensor.model.attributes.state'),
      properties: () => this.rootStore.sensorStore.sensors
        .map((sensor) => ({
          key: sensor.name,
          title: sensor.label || sensor.name,
          render: () => {}, // rendering of sensor data is done as a special case in the widget itself.
        })),
      getRecord: () => ({
        displayableProperties: this.rootStore.sensorStore.sensors.map((sensor) => ({
          key: sensor.name,
        })),
      }),
    },
    {
      key: 'interruption',
      label: i18n.t('interruption.model.one'),
      properties: [
        {
          key: 'unclassified',
          title: i18n.t('unclassifiedInterruptions.title'),
          render: (text, record) => ({
            props: {},
            children: record.unclassified,
          }),
        },
      ],
      getRecord: ({workplaceId}) => {
        const operation = this.rootStore.operationStateLogStore.getUnclassifiedByWorkplaceId(workplaceId);
        const workplace = this.rootStore.workplaceStateLogStore.getUnclassifiedByWorkplaceId(workplaceId);
        return {
          unclassified: operation.length + workplace.length,
        };
      },
      loadAction: 'loadUnclassifiedInterruptions',
    },
  ];

  get tabs() {
    return [
      this.generalSettingsTab,
      {
        key: 'settings',
        label: i18n.t('dataTableWidgetConfig.settings.title'),
      },
      {
        key: 'dataSource',
        label: i18n.t('dataTableWidgetConfig.dataSource.title'),
      },
    ];
  }

  getAdditionalSettings() {
    if (this.getIsWorkplaceCockpit()) {
      return [
        {
          id: 'workplacesSource',
          label: i18n.t('dataTableWidgetConfig.settings.workplacesSource.label'),
          helpText: i18n.t('dataTableWidgetConfig.settings.workplacesSource.help'),
          type: FieldType.Select,
          validations: [ValidationRules.required()],
          defaultValue: DataTableWidgetConfig.SOURCE_SELECTION,
          options: [
            {
              value: DataTableWidgetConfig.SOURCE_HIERARCHY,
              label: i18n.t('dataTableWidgetConfig.settings.workplacesSource.hierarchy'),
            },
            {
              value: DataTableWidgetConfig.SOURCE_GROUP,
              label: i18n.t('dataTableWidgetConfig.settings.workplacesSource.group'),
            },
            {
              value: DataTableWidgetConfig.SOURCE_SELECTION,
              label: i18n.t('dataTableWidgetConfig.settings.workplacesSource.selection'),
            },
          ],
        },
      ];
    }
    return [];
  }

  settings = [
    ...this.getAdditionalSettings(),
    {
      id: 'workplaces',
      label: i18n.t('dataTableWidgetConfig.settings.workplaces.label'),
      helpText: i18n.t('dataTableWidgetConfig.settings.workplaces.help'),
      type: FieldType.HierarchyTreeMultipleWorkplacesOnly,
      validations: [ValidationRules.requiredIf(!this.getIsWorkplaceCockpit())],
    },
    {
      id: 'orientation',
      label: i18n.t('dataTableWidgetConfig.settings.orientation.label'),
      helpText: i18n.t('dataTableWidgetConfig.settings.orientation.help'),
      type: FieldType.Select,
      defaultValue: 'vertical',
      validations: [ValidationRules.required()],
      options: [
        {value: 'vertical', label: i18n.t('dataTableWidgetConfig.settings.orientation.vertical')},
        {value: 'horizontal', label: i18n.t('dataTableWidgetConfig.settings.orientation.horizontal')},
      ],
    },
    {
      id: 'showWorkplace',
      label: i18n.t('dataTableWidgetConfig.settings.showWorkplace.label'),
      helpText: i18n.t('dataTableWidgetConfig.settings.showWorkplace.help'),
      type: FieldType.Boolean,
      defaultValue: true,
    },
    {
      id: 'linkWorkplaceTo',
      label: i18n.t('dataTableWidgetConfig.settings.linkWorkplaceTo.label'),
      helpText: i18n.t('dataTableWidgetConfig.settings.linkWorkplaceTo.help'),
      type: FieldType.Select,
      defaultValue: 'cockpit',
      options: [
        {
          // eslint-disable-next-line no-use-before-define
          value: DataTableWidgetConfig.LINK_WORKPLACE_NOWHERE,
          label: i18n.t('dataTableWidgetConfig.settings.linkWorkplaceTo.none'),
        },
        {
          // eslint-disable-next-line no-use-before-define
          value: DataTableWidgetConfig.LINK_WORKPLACE_TO_COCKPIT,
          label: i18n.t('dataTableWidgetConfig.settings.linkWorkplaceTo.cockpit'),
        },
        ...(appConfig.modules.enableProductionTerminal
          ? [{
            // eslint-disable-next-line no-use-before-define
            value: DataTableWidgetConfig.LINK_WORKPLACE_TO_PRODUCTION,
            label: i18n.t('dataTableWidgetConfig.settings.linkWorkplaceTo.production'),
          }]
          : []
        ),
      ],
    },
    {
      id: 'linkWorkplaceInNewTab',
      label: i18n.t('dataTableWidgetConfig.settings.linkWorkplaceInNewTab.label'),
      helpText: i18n.t('dataTableWidgetConfig.settings.linkWorkplaceInNewTab.help'),
      type: FieldType.Boolean,
      defaultValue: false,
    },
    {
      id: 'duration',
      label: i18n.t('dataTableWidgetConfig.settings.duration.label'),
      helpText: i18n.t('dataTableWidgetConfig.settings.duration.help'),
      unit: i18n.t('dataTableWidgetConfig.settings.duration.unit'),
      type: FieldType.Number,
      validations: [ValidationRules.required(), ValidationRules.minNumber(1), ValidationRules.maxNumber(30)],
      defaultValue: 5,
    },
    {
      id: 'timeframe',
      label: i18n.t('dataTableWidgetConfig.settings.kpi.timeframe.label'),
      helpText: i18n.t('dataTableWidgetConfig.settings.kpi.timeframe.helpText'),
      type: FieldType.Select,
      options: Object.values(TimeframeOptions).map((o) => ({
        value: o,
        label: i18n.t(`cockpitWidget.settings.timeframe.${o}`),
      })),
      validations: [ValidationRules.required()],
    },
    {
      id: 'amountOfDays',
      label: i18n.t('dataTableWidgetConfig.settings.amountOfDays.label'),
      helpText: i18n.t('dataTableWidgetConfig.settings.amountOfDays.help'),
      type: FieldType.Number,
      unit: i18n.t('dataTableWidgetConfig.settings.kpi.amountOfDays.unit'),
      defaultValue: 3,
      validations: [ValidationRules.required(), ValidationRules.minNumber(1), ValidationRules.maxNumber(7)],
    },
  ];

  dataSource = [
    {
      id: 'fields',
      type: FieldType.ModelPropertySelectField,
      modelOptions: this.modelOptions,
    },
  ];

  getWorkplacesSource() {
    return this.getSettingValue('settings', 'workplacesSource') || DataTableWidgetConfig.SOURCE_SELECTION;
  }

  getShowWorkplace() {
    return this.getSettingValue('settings', 'showWorkplace');
  }

  setSelectedWorkplaces() {
    return this.getSettingValue('settings', 'workplaces') || [];
  }
}
