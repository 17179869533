import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd';
import Form from '../../shared/form/Form';
import Select from '../../shared/inputs/Select';
import DiscardFormActions from '../../shared/form/DiscardFormActions';
import { Actions } from '../../../stores/entityStore';
import { useStore } from '../../../hooks/useStore';
import { Setting } from '../../../models/setting';
import Input from '../../shared/inputs/Input';
import { ScopeContext } from '../../../policies/scopeContext';
import { useModulePolicy } from '../../../hooks/useModulePolicy';

export type ModuleConfigurationQualityReportFormProps = {
  form: FormInstance<Setting>,
  fields: any[],
  setFields: (fields: any[]) => void,
  onSave: (values: any) => void,
  onDiscard: () => void,
};

const ModuleConfigurationQualityReportForm: React.FC<ModuleConfigurationQualityReportFormProps> = (
  {
    form,
    fields,
    setFields,
    onSave,
    onDiscard,
  }
) => {
  const store = useStore();
  const { t } = useTranslation();
  const scopes = useContext(ScopeContext);
  const policy = useModulePolicy(store, scopes);

  return (
    <Form
      form={form}
      fields={fields}
      onFinish={onSave}
      onFieldsChange={(changedFields: any, allFields: any) => setFields(allFields)}
    >
      <Form.Item
        name="coordinatorRole"
        label={t('moduleConfiguration.tab.qualityReport.coordinatorRole.label')}
      >
        <Select
          options={store.roleStore.roles.map((role) => ({
            label: role.name,
            value: role.id,
          }))}
        />
      </Form.Item>

      <Form.Item
        name="supplier"
        label={t('moduleConfiguration.tab.qualityReport.supplier.label')}
      >
        <Input/>
      </Form.Item>

      <DiscardFormActions
        saving={store.settingStore.isActionInProgress(Actions.update)}
        onDiscard={onDiscard}
        submitText={t('confirm.save')}
        discardText={t('confirm.discard.ok')}
        submitDisabled={!policy.canEditAll()}
      />
    </Form>
  );
};

export default observer(ModuleConfigurationQualityReportForm);
