import React from 'react';
import {observer} from 'mobx-react-lite';
import Descriptions from '../../../shared/descriptions/Descriptions';

const InspectionTaskDescription = (props) => (
  <Descriptions
    bordered
    size={'small'}
    column={1}
    style={{marginBottom: 16}}
    {...props}
  />
);

InspectionTaskDescription.Item = Descriptions.Item;

export default observer(InspectionTaskDescription);
