import i18n from '../../../i18n/i18n';
import {BaseWidgetConfig} from '../../shared/widgets/baseWidgetConfig';
import {ValidationRules} from '../../../utils/validationRules';
import {getAllI18nTranslations} from '../../../utils/translations';
import {FieldType} from '../../../types/widgetConfig';

export class PrintJobWidgetConfig extends BaseWidgetConfig {
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'settings',
        label: i18n.t('widget.WidgetSettingsModal.settingConfigTabTitle'),
        closable: false,
      },
    ];
  }

  settings = [
    {
      id: 'title',
      label: i18n.t('widget.settings.title.label'),
      helpText: i18n.t('widget.settings.title.help'),
      type: FieldType.Translatable,
      // t('printJobWidget.title')
      defaultValue: getAllI18nTranslations('printJobWidget.title'),
      validations: [ValidationRules.required()],
    },
    {
      id: 'loadDaysPast',
      label: i18n.t('printJobWidget.settings.loadDays.past'),
      type: FieldType.Number,
      defaultValue: 5,
      validations: [ValidationRules.required()],
    },
    {
      id: 'loadDaysFuture',
      label: i18n.t('printJobWidget.settings.loadDays.future'),
      type: FieldType.Number,
      defaultValue: 5,
      validations: [ValidationRules.required()],
    },
  ];

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'title');
  }

  getLoadDaysPast() {
    return this.getSettingValue('settings', 'loadDaysPast');
  }

  getLoadDaysFuture() {
    return this.getSelectedProperties('widget', 'loadDaysFuture');
  }
}
