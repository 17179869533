import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../hooks/useStore';
import { useMount } from '../../../hooks/useMount';
import Table from '../../shared/tables/Table';
import { Component } from '../../../models/component';
import { datetimeFormat } from '../../../config/dayjs';
import styles from './DosageWidgetStyles.module.scss';
import { getActualPreparation } from '../dosageUtil';
import { DosageStoreActions } from '../../../stores/dosageStore';
import { DosageWidgetConfig } from './dosageWidgetConfig';

export type DosageProductTableProps = {
  widgetConfig: DosageWidgetConfig;
  productId: number | null;
  preparationId: number;
  isMarkSetupSelectionInTable?: boolean | undefined;
};

export interface DosageTableData {
  createdAt: string;
  amount: string;
  batch: string | null;
}

const DosageProductTable: React.FC<DosageProductTableProps> = ({
  widgetConfig,
  productId,
  preparationId,
  isMarkSetupSelectionInTable,
}) => {
  const store = useStore();
  const { t } = useTranslation();

  useMount(() => {
    if (!store.operationStore.active?.id) {
      return;
    }

    const workplaceStorageRelation = store.workplaceStorageRelationStore
      .getByWorkplaceId(store.operationStore.active?.workplaceId);

    if (!workplaceStorageRelation) {
      return;
    }

    store.preparationStore.loadWithDependencies(preparationId, {});

    store.storageUnitStore.loadAllByStorageAreaIds(workplaceStorageRelation.internalStorageAreaIds).then((units) => {
      const batchIds = units.flatMap((unit) => unit.contents.map((content) => content.batchId));
      const uniqueBatchIds = uniq(batchIds);
      if (!uniqueBatchIds.length) {
        return;
      }
      store.batchStore.loadMany(uniqueBatchIds as number[]);
    });
  }, [store.operationStore.active?.id]);

  let componentColumns = widgetConfig.getSelectedProperties('settings', 'property_components');

  componentColumns = [
    ...componentColumns,
    {
      title: t('dosageWidget.productTable.title.actualDoseQuantity'),
      key: 'actualDoseQuantity',
      render: (_: any, component: Component) => {
        const amount = getActualPreparation(preparationId, component, store);

        return (
          <span>
            {`${amount} ${component.unitOfMeasure?.label}`}
          </span>
        );
      },
    },
  ];

  const dosageColumns = [
    {
      title: t('dosageWidget.productTable.title.contentsCreatedAt'),
      key: 'createdAt',
      dataIndex: 'createdAt',
    },
    {
      title: t('dosageWidget.productTable.title.contentsAmount'),
      key: 'amount',
      dataIndex: 'amount',
    },
    {
      title: t('dosageWidget.productTable.title.contentsBatch'),
      key: 'batch',
      dataIndex: 'batch',
    },
  ];

  const hasDosages = (component: Component): boolean => {
    const preparation = store.preparationStore.getById(preparationId);

    if (!preparation) {
      return false;
    }

    const storageUnits = store.storageUnitStore.getDescendantsById(preparation.storageUnitId);

    const unitsWithCorrectMaterial = storageUnits.filter((unit) => unit.materialId === component.materialId);

    if (!unitsWithCorrectMaterial.length) {
      return false;
    }

    return unitsWithCorrectMaterial.every((unit) => unit.contents.length > 0);
  };

  const expandedRowRender = (component: Component) => {
    const data: DosageTableData[] = [];
    const preparation = store.preparationStore.getById(preparationId);

    if (!preparation) {
      return false;
    }

    const storageUnits = store.storageUnitStore
      .getDescendantsByIdAndMaterial(preparation.storageUnitId, component.materialId);

    if (!storageUnits.length) {
      return false;
    }

    const sortedStorageUnits = storageUnits
      .slice()
      .sort((a, b) => (a.setupOrder ?? Number.POSITIVE_INFINITY) - (b.setupOrder ?? Number.POSITIVE_INFINITY));

    sortedStorageUnits.forEach((unit) => {
      const sortedContents = unit.contents.slice().sort((a, b) =>
        dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf());

      sortedContents.forEach((content) => {
        data.push({
          createdAt: dayjs(content.createdAt).format(datetimeFormat),
          amount: `${content.amount} ${component.unitOfMeasure?.label}`,
          batch: store.batchStore.getById(content.batchId || null)?.no || null,
        });
      });
    });

    return (
      <Table<DosageTableData>
        data-cy={'DosageProductTable-DosageTable'}
        columns={dosageColumns}
        rowKey={'batch'}
        dataSource={data}
        pagination={false}
      />
    );
  };

  const getRowClassName = useCallback((record: Component) => {
    let className = '';
    if (!isMarkSetupSelectionInTable) {
      return '';
    }

    if (store.dosageStore.selectedComponents.map((sc) => sc.id).includes(record.id)) {
      className = 'row-active';
    }

    return className;
  }, [store.dosageStore.selectedComponents.length]);

  return (
    <>
      <div
        style={{
          fontSize: '1.1em',
          color: 'black',
          fontWeight: 'bold',
        }}
      >
        {store.productStore.getById(productId)?.no}
      </div>
      <Table<Component>
        data-cy={'DosageProductTable-ComponentTable'}
        className={styles.ProductTable}
        rowKey={'id'}
        rowClassName={getRowClassName}
        columns={componentColumns}
        dataSource={store.componentStore.components.filter((component) => component.productId === productId)}
        expandable={{
          expandedRowRender: (record: Component) => expandedRowRender(record),
          rowExpandable: (record: Component) => hasDosages(record),
        }}
        pagination={false}
        loading={
          store.componentStore.isLoadingCollection
          || store.dosageStore.isOneOfActionsInProgress([DosageStoreActions.prepare])
        }
      />
    </>
  );
};

export default observer(DosageProductTable);
