import appConfig from './appConfig';
import i18n from '../i18n/i18n';

export const AvailableLocales = ['en-CH', 'de-CH', 'fr-FR', 'it-IT', 'pl-PL'];

export const getTranslation = (storeLanguage, translations) => {
  if (!translations || !translations.length > 0) {
    return null;
  }

  let translated = translations.find(
    // TODO: As an additional fallback on every level, try to find a translation for only the language, without the
    //  region.
    // Find preferred language for logged in user
    (translation) => translation.language === storeLanguage
  );
  if (!translated) {
    // Fallback to appConfig language
    translated = translations.find(
      (translation) => translation.language === appConfig.language
    );
  }
  if (!translated) {
    // Fallback to any available language
    [translated] = translations;
  }
  return translated;
};

export const getAllI18nTranslations = (identifier) => {
  const result = {};
  AvailableLocales.forEach((language) => {
    result[language] = i18n.getResource(language.split('-')[0], 'translation', identifier) || '';
  });
  return result;
};
