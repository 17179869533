import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import Table from '../../shared/tables/Table';
import {sortAlphabetically, sortChronologically} from '../../shared/tables/sorters';
import {datetimeFormat} from '../../../config/dayjs';
import {useMount} from '../../../hooks/useMount';
import {useStore} from '../../../hooks/useStore';
import UserFullName from '../../shared/UserFullName';

const OrderDetailsShiftBook = ({operation}) => {
  const {t} = useTranslation();
  const store = useStore();

  useMount(() => {
    store.shiftBookStore.loadAll({
      params: {
        orderId: operation.orderId,
      },
    });
  }, [operation?.id]);

  return (
    <Table
      columns={[
        {
          title: t('shiftBook.model.attributes.createdAt'),
          dataIndex: 'createdAt',
          render: (date) => dayjs(date).format(datetimeFormat),
          sorter: (a, b) => sortChronologically(a.createdAt, b.createdAt),
        },
        {
          title: t('shiftBook.model.attributes.message'),
          dataIndex: 'message',
          sorter: (a, b) => sortAlphabetically(a.message, b.message),
        },
        {
          title: t('shiftBook.model.attributes.createdBy'),
          dataIndex: 'user',
          render: (user) => <UserFullName user={user}/>,
          sorter: (a, b) => sortAlphabetically(a.user?.fullName, b.user?.fullName),
        },
      ]}
      dataSource={store.shiftBookStore.shiftBooks.filter((sb) => sb.orderId === operation.orderId) || []}
      rowKey={'id'}
    />
  );
};

export default observer(OrderDetailsShiftBook);
