import { Divider, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Operation } from '../../models/operation';
import { ValidationRules } from '../../utils/validationRules';
import {
  QualityReport,
  QualityReportPriorities,
  QualityReportPriorityDefault,
  QualityReportType,
} from '../../models/qualityReport';
import Select from '../shared/inputs/Select';
import Input from '../shared/inputs/Input';
import { FormItemLayouts } from '../shared/form/formItemLayouts';
import CancelSaveButtonGroup from '../shared/buttons/CancelSaveButtonGroup';
import Radio from '../shared/inputs/Radio';
import { useStore } from '../../hooks/useStore';
import AlertWarning from '../shared/alert/AlertWarning';
import Switch from '../shared/inputs/Switch';
import HelpTooltipFormLabel from '../shared/tooltips/HelpTooltipFormLabel';
import { OrderSelect } from './formComponents/OrderSelect';
import { MaterialSelect } from './formComponents/MaterialSelect';
import { BatchSelect } from './formComponents/BatchSelect';
import { Order } from '../../models/order';
import { Material } from '../../models/material';
import { Component } from '../../models/component';
import { Batch } from '../../models/batch';
import { useMount } from '../../hooks/useMount';
import { User } from '../../models/user';
import MultiImageUpload from '../shared/inputs/multiImageUpload/MultiImageUpload';
import Form from '../shared/form/Form';
import { sortAlphabetically } from '../shared/tables/sorters';

export type QualityReportFormProps = {
  fields: any[];
  onChange: (fields: any[]) => void;
  saving?: boolean;
  onSubmit: (values: QualityReport) => void;
  onCancel: () => void;
  coordinatorRole?: number;
  selectableOrderTimeSpan?: number;
  fixedWorkplaceId?: number;
};

const QualityReportForm: React.FC<QualityReportFormProps> = ({
  fields,
  onChange,
  onSubmit,
  onCancel,
  coordinatorRole,
  selectableOrderTimeSpan,
  fixedWorkplaceId,
}) => {
  const [form]: Array<FormInstance> = Form.useForm();
  const { t } = useTranslation();
  const store = useStore();
  const [restrictToOrder, setRestrictToOrder] = useState(true);
  const [relevantOrders, setRelevantOrders] = useState<Order[]>([]);
  const [relevantMaterials, setRelevantMaterials] = useState<Material[]>([]);
  const [relevantBatches, setRelevantBatches] = useState<Batch[]>([]);
  const [activeOrderId, setActiveOrderId] = useState<number | undefined>(undefined);

  const activeOperation = store.operationStore.active as Operation | undefined || null;

  const getQualityReportTypeOptions = () => ([
    {
      value: QualityReportType.SUPPLIER_COMPLAINT,
      label: t('qualityReport.model.attributes.type.SUPPLIER_COMPLAINT'),
    },
    {
      value: QualityReportType.INTERNAL,
      label: t('qualityReport.model.attributes.type.INTERNAL'),
    },
  ]);

  const loadOrders = () => {
    (async () => {
      const currentWorkplaceId = form.getFieldValue('workplaceId');
      if (!restrictToOrder || !currentWorkplaceId) {
        return;
      }

      const minEndDate = dayjs().subtract(selectableOrderTimeSpan || 0, 'hours');
      const maxEndDate = dayjs().add(selectableOrderTimeSpan || 0, 'hours');
      const minStartDate = dayjs().subtract(selectableOrderTimeSpan || 0, 'hours');
      const maxStartDate = dayjs().add(selectableOrderTimeSpan || 0, 'hours');

      let relevantActiveOperation = store.operationStore.active;

      if (!fixedWorkplaceId) {
        const activePromise = store.operationStore.loadActiveOperationByWorkplace(currentWorkplaceId);
        const finishedPromise = store.operationStore.loadFinishedOperationsByWorkplace(currentWorkplaceId, {
          params: {
            fromDate: minEndDate.toISOString(),
            toDate: maxEndDate.toISOString(),
          },
        });
        const plannedPromise = store.operationStore.loadPlannedOperationsByWorkplace(currentWorkplaceId, {
          params: {
            fromDate: minStartDate.toISOString(),
            toDate: maxStartDate.toISOString(),
          },
        });
        [relevantActiveOperation] = await Promise.all([activePromise, finishedPromise, plannedPromise]);
      }
      const finishedOperations = store.operationStore
        .filterFinishedOnly(currentWorkplaceId, minEndDate.toDate(), maxEndDate.toDate());
      const plannedOperations = store.operationStore
        .filterPlannedOnly(currentWorkplaceId, minStartDate.toDate(), maxStartDate.toDate());
      const orders: (Order | undefined)[] = (relevantActiveOperation ? [relevantActiveOperation.order] : [])
        .concat(finishedOperations.map((op) => op.order))
        .concat(plannedOperations.map((op) => op.order));
      setRelevantOrders(orders.filter((o): o is Order => !!o));
    })();
  };

  const loadMaterials = (orderId?: number | null) => {
    (async () => {
      if (!orderId) {
        setRelevantMaterials([]);
        return;
      }
      const order = store.orderStore.getById(orderId) as Order | undefined;
      const workplaceId = form.getFieldValue('workplaceId');
      if (!workplaceId) {
        return;
      }
      const operations = store.operationStore.getByOrderIdAndWorkplaceId(orderId, workplaceId) as Operation[];
      if (!order || !operations.length) {
        return;
      }
      const operationIds = operations.map((op) => op.id);
      await store.componentStore.loadAllWithDependencies({
        params: {
          operationId: operationIds,
        },
      });
      const components = store.componentStore.getByOperationIds(operationIds) as Component[];
      const materials = new Set<number>();
      if (order.materialId) {
        materials.add(order.materialId);
      }
      operations.forEach((op) => op.materialId && materials.add(op.materialId));
      components.forEach((comp) => comp.materialId && materials.add(comp.materialId));
      setRelevantMaterials(store.materialStore.getByIds(Array.from(materials)));
    })();
  };

  const loadBatches = (materialId?: number | null) => {
    (async () => {
      if (!materialId) {
        setRelevantBatches([]);
        return;
      }
      await store.batchStore.loadAll({
        params: {
          materialId,
        },
      });
      setRelevantBatches(store.batchStore.getByMaterialId(materialId));
    })();
  };

  useMount(() => {
    if (restrictToOrder && activeOperation?.orderId && fixedWorkplaceId) {
      setActiveOrderId(activeOperation.orderId);
      loadOrders();
    }
  });

  useMount(() => {
    if (activeOrderId) {
      loadMaterials(activeOrderId);
    }
  }, [activeOrderId]);

  const handleModeChange = (): void => {
    setRestrictToOrder(!restrictToOrder);
    const newFormValues: { orderId?: number, materialId?: number, batchId?: number } = {
      orderId: undefined, materialId: undefined, batchId: undefined,
    };
    if (!restrictToOrder && activeOperation?.orderId && fixedWorkplaceId) {
      newFormValues.orderId = activeOperation.orderId;
      loadMaterials(activeOperation.orderId);
    }
    form.setFieldsValue(newFormValues);
  };

  const handleValuesChange = (changedValues: Partial<QualityReport>): void => {
    if (!restrictToOrder) {
      return;
    }
    if (Object.hasOwn(changedValues, 'workplaceId')) {
      form.setFieldsValue({ materialId: undefined, batchId: undefined, orderId: undefined });
      loadOrders();
    }
    if (Object.hasOwn(changedValues, 'orderId')) {
      form.setFieldsValue({ materialId: undefined, batchId: undefined });
      loadMaterials(changedValues.orderId);
    }
    if (Object.hasOwn(changedValues, 'materialId')) {
      form.setFieldsValue({ batchId: undefined });
      loadBatches(changedValues.materialId);
    }
  };

  const priorityOptions = QualityReportPriorities.map((priority) => ({
    label: t(`qualityReport.model.attributes.priority.${priority}`),
    value: priority,
  }));

  const userOptions = (coordinatorRole ? store.userStore.getByRoleId(coordinatorRole) : [])
    .sort((a: User, b: User) => sortAlphabetically(a.lastName || '', b.lastName || ''))
    .map((user: User) => ({
      label: user.completeName,
      value: user.id,
    }));

  const configIncomplete = !coordinatorRole || !selectableOrderTimeSpan;

  return (
    <Form
      form={form}
      {...FormItemLayouts.default}
      labelAlign={'left'}
      onFinish={onSubmit}
      fields={fields}
      initialValues={{
        orderId: fixedWorkplaceId ? activeOperation?.orderId : undefined,
        priority: QualityReportPriorityDefault,
        workplaceId: fixedWorkplaceId,
      }}
      onValuesChange={handleValuesChange}
      onFieldsChange={(_: any, allFields: any[]) => onChange && onChange(allFields)}
    >
      {configIncomplete && (
        <AlertWarning
          type={'error'}
          message={t('qualityReport.form.errors.missingConfig')}
          style={{ marginBottom: '16px' }}
        />
      )}
      <Form.Item
        name={'type'}
        label={t('qualityReport.model.attributes.reportType')}
        rules={[ValidationRules.required()]}
      >
        <Radio.Group
          options={getQualityReportTypeOptions()}
          optionType={'button'}
        />
      </Form.Item>
      <Divider orientation={'left'}>{t('qualityReport.form.section.general.reference')}</Divider>
      <Form.Item
        label={(
          <HelpTooltipFormLabel
            help={t('qualityReport.form.restrictToOrder.help')}
            label={t('qualityReport.form.restrictToOrder.label')}
          />
        )}
      >
        <Switch
          checked={restrictToOrder}
          onChange={handleModeChange}
        />
      </Form.Item>
      <Form.Item
        name={'workplaceId'}
        label={(
          <HelpTooltipFormLabel
            help={t('qualityReport.form.workplace.help')}
            label={t('qualityReport.form.workplace.label')}
          />
        )}
        rules={[ValidationRules.required()]}
        style={{ display: fixedWorkplaceId ? 'none' : undefined }}
      >
        <Select
          allowClear={false}
          options={store.workplaceStore.workplaces.map((workplace) => ({
            value: workplace.id,
            label: workplace.label,
          }))}
          showSearch
          filterOption={(input: string, option?: { label: string, value: number }) =>
            (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
        />
      </Form.Item>
      <OrderSelect orders={relevantOrders} restrictToOrder={restrictToOrder}/>
      <MaterialSelect
        materials={relevantMaterials}
        restrictToOrder={restrictToOrder}
        loading={store.componentStore.areCollectionOrDependenciesLoading}
      />
      <BatchSelect
        batches={relevantBatches}
        restrictToOrder={restrictToOrder}
        loading={store.batchStore.areCollectionOrDependenciesLoading}
      />
      <Divider orientation={'left'}>{t('qualityReport.form.section.general.issue')}</Divider>
      <Form.Item name={'title'} label={t('qualityReport.model.attributes.title')} rules={[ValidationRules.required()]}>
        <Input maxLength={255}/>
      </Form.Item>
      <Form.Item
        name={'message'}
        label={t('qualityReport.model.attributes.message')}
        rules={[ValidationRules.required()]}
      >
        <Input.TextArea/>
      </Form.Item>
      <Form.Item
        name={'priority'}
        label={t('qualityReport.model.attributes.priority.label')}
        rules={[ValidationRules.required()]}
      >
        <Select<number> options={priorityOptions}/>
      </Form.Item>
      <Form.Item
        name={'coordinatorUserId'}
        label={t('qualityReport.model.attributes.coordinator')}
        rules={[ValidationRules.required()]}
      >
        <Select
          options={userOptions}
          showSearch
          filterOption={(input: string, option?: { label: string, value: number }) =>
            (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
        />
      </Form.Item>
      <Form.Item
        name={'documentIds'}
        label={(
          <HelpTooltipFormLabel
            label={t('qualityReport.model.attributes.attachments.label')}
            help={t('qualityReport.model.attributes.attachments.help')}
          />
        )}
      >
        <MultiImageUpload
          maxCount={5}
          enableCameraIntegration
        />
      </Form.Item>
      <Form.Item {...FormItemLayouts.fullWidth} style={{ textAlign: 'right' }}>
        <CancelSaveButtonGroup onCancel={onCancel} saveButtonDisabled={configIncomplete}/>
      </Form.Item>
    </Form>
  );
};

export default QualityReportForm;
