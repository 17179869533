import { observable, makeObservable, computed } from 'mobx';
import { faBalanceScaleLeft } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { SensorState } from './sensor';
import { RootStore } from '../stores/rootStore';

export class SensorData extends BaseModel {
  id: number = 0;
  sensorName: string = '';
  workplaceId: number | null = null;
  orderId: number | null = null;
  timestamp: string = '';
  value: number = 0;
  state: SensorState | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      sensorName: observable,
      workplaceId: observable,
      workplace: computed,
      orderId: observable,
      order: computed,
      timestamp: observable,
      value: observable,
      state: observable,
    });
  }

  static faIcon = faBalanceScaleLeft;

  get workplace() {
    return this.rootStore.workplaceStore.getById(this.workplaceId);
  }

  get order() {
    return this.rootStore.orderStore.getById(this.orderId);
  }
}
