import Chart from './Chart';
import appConfig from '../../../utils/appConfig';
import {findNotNil} from '../../../utils/helpers';

const Speedometer = ({
  width = 300,
  height = 300,
  value = 0,
  minValue = 0,
  maxValue = 100,
  toleranceHigh,
  toleranceLow,
  warningHigh,
  warningLow,
  needleColor = '#000000',
  labelsEnabled = true,
  dataLabelEnabled = true,
  bandThickness = 35,
  unit = '',
}) => {
  const plotBandProps = (color) => ({
    color,
    borderColor: color,
    borderWidth: 0,
    thickness: bandThickness,
  });

  const plotBands = [];
  if (toleranceLow || toleranceLow === 0) {
    plotBands.push({
      from: minValue,
      to: toleranceLow,
      ...plotBandProps(appConfig.quantitativeStateColors.bad),
    });
  }
  if (warningLow || warningLow === 0) {
    plotBands.push({
      from: findNotNil(toleranceLow, minValue),
      to: warningLow,
      ...plotBandProps(appConfig.quantitativeStateColors.warning),
    });
  }
  plotBands.push({
    from: findNotNil(warningLow, toleranceLow, minValue),
    to: findNotNil(warningHigh, toleranceHigh, maxValue),
    ...plotBandProps(appConfig.quantitativeStateColors.good),
  });
  if (warningHigh || warningHigh === 0) {
    plotBands.push({
      from: warningHigh,
      to: findNotNil(toleranceHigh, maxValue),
      ...plotBandProps(appConfig.quantitativeStateColors.warning),
    });
  }
  if (toleranceHigh || toleranceHigh === 0) {
    plotBands.push({
      from: toleranceHigh,
      to: maxValue,
      ...plotBandProps(appConfig.quantitativeStateColors.bad),
    });
  }

  let displayValue = value;
  if (displayValue < minValue) {
    displayValue = minValue;
  } else if (displayValue > maxValue) {
    displayValue = maxValue;
  }

  const options = {
    chart: {
      type: 'gauge',
      plotBackgroundColor: 'rgba(0,0,0,0)',
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      spacing: labelsEnabled ? [10, 10, 5, 10] : [2, 0, 0, 0],
      height,
      width,
    },
    title: {
      text: '',
    },
    pane: {
      startAngle: -90,
      endAngle: 89.9,
      background: null,
      center: ['50%', height - 40],
      size: '130%',
    },

    // the value axis
    yAxis: {
      min: minValue,
      max: maxValue,
      minorTickInterval: null,
      tickLength: 0,
      tickWidth: 0,
      labels: {
        enabled: labelsEnabled,
        distance: 10,
        style: {
          fontSize: '14px',
        },
      },
      plotBands,
    },

    tooltip: {
      enabled: false,
    },

    series: [{
      data: [Number(displayValue)],
      dataLabels: {
        enabled: dataLabelEnabled,
        format: `${value} ${unit}`,
        borderWidth: 0,
        style: {
          fontSize: '15.4px',
          fontWeight: 'bold',
          color: '#000000AA',
        },
      },
      dial: {
        radius: '95%',
        backgroundColor: needleColor,
        baseWidth: 8,
        baseLength: '0%',
        rearLength: '0%',
      },
      pivot: {
        backgroundColor: needleColor,
        radius: 4,
      },
    }],
  };

  return (
    <Chart
      dataLabelsEnabled={false}
      showIsEmpty={false}
      options={options}
    />
  );
};

export default Speedometer;
