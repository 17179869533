import {observer} from 'mobx-react-lite';
import {Empty} from 'antd';
import {useTranslation} from 'react-i18next';
import ShowDocumentLink from './ShowDocumentLink';
import ShowDocumentPdf from './ShowDocumentPdf';
import ShowDocumentImage from './ShowDocumentImage';
import ShowDocumentNewTab from './ShowDocumentNewTab';

const DocumentByType = ({document}) => {
  const {t} = useTranslation();

  if (document.link) {
    if (document.openInTab) {
      return (<ShowDocumentNewTab document={document}/>);
    }
    return (<ShowDocumentLink document={document}/>);
  }
  if (document.isPDF) {
    return (<ShowDocumentPdf document={document}/>);
  }
  if (document.isImage) {
    return (<ShowDocumentImage document={document}/>);
  }
  return (
    <Empty
      style={{marginTop: 0}}
      description={(
        <span>
          {' '}
          {t('document.show.unknownMimeType', {mimeType: document.mimeType})}
          {' '}
        </span>
      )}
    />
  );
};
export default observer(DocumentByType);
