import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { RootStore } from './rootStore';
import { InspectionEquipment } from '../models/inspectionEquipment';

export class InspectionEquipmentStore extends EntityStore<InspectionEquipment> {
  inspectionEquipment: InspectionEquipment[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'inspectionEquipment', Api.inspectionEquipment, InspectionEquipment, true);

    makeObservable(this, {
      inspectionEquipment: observable,
    });
  }
}
