import { computed, observable, makeObservable } from 'mobx';
import {
  faArchive, faBuilding, faDesktop, faFlask, faFolder, faIndustry, faLayerGroup, faPallet, faSitemap, faTable,
  faWarehouse,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import appConfig from '../utils/appConfig';
import { getTranslation } from '../utils/translations';
import { RootStore } from '../stores/rootStore';
import { SensorState } from './sensor';

/**
 * hierarchy icons translations:
 * t('hierarchy.icon.archive')
 * t('hierarchy.icon.building')
 * t('hierarchy.icon.desktop')
 * t('hierarchy.icon.flask')
 * t('hierarchy.icon.folder')
 * t('hierarchy.icon.industry')
 * t('hierarchy.icon.layer-group')
 * t('hierarchy.icon.pallet')
 * t('hierarchy.icon.table-list')
 * t('hierarchy.icon.warehouse')
 */
export enum HierarchyIcon {
  Archive = 'archive',
  Building = 'building',
  Desktop = 'desktop',
  Flask = 'flask',
  Folder = 'folder',
  Industry = 'industry',
  LayerGroup = 'layer-group',
  Pallet = 'pallet',
  TableList = 'table-list',
  Warehouse = 'warehouse'
}

export const HierarchyIconDefinition = Object.freeze({
  [HierarchyIcon.Archive]: faArchive,
  [HierarchyIcon.Building]: faBuilding,
  [HierarchyIcon.Desktop]: faDesktop,
  [HierarchyIcon.Flask]: faFlask,
  [HierarchyIcon.Folder]: faFolder,
  [HierarchyIcon.Industry]: faIndustry,
  [HierarchyIcon.LayerGroup]: faLayerGroup,
  [HierarchyIcon.Pallet]: faPallet,
  [HierarchyIcon.TableList]: faTable,
  [HierarchyIcon.Warehouse]: faWarehouse,
});

export class Hierarchy extends BaseModel {
  id: number = 0;
  sortOrder: number = 0;
  icon: HierarchyIcon | null = null;
  parentId: number | null = null;
  state: SensorState | null = null;
  stateChangedAt: string | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: computed,
      sortOrder: observable,
      icon: observable,
      parent: computed,
      parentId: observable,
      state: observable,
      stateChangedAt: observable,
      nameWithIcon: computed,
      level: computed,
    });
  }

  static faIcon = faSitemap;

  searchableProperties = ['name'];

  translatedProperties = ['name'];

  displayableProperties = [
    displayableProperty({
      key: 'id',
      title: 'Id',
      params: [displayablePropertyParam({ path: 'id', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'name',
      title: i18n.t('hierarchy.model.attributes.name'),
      params: [displayablePropertyParam({ path: 'name', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'icon',
      title: i18n.t('hierarchy.model.attributes.icon'),
      params: [displayablePropertyParam({ path: 'icon', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'sortOrder',
      title: i18n.t('hierarchy.model.attributes.sortOrder'),
      params: [displayablePropertyParam({ path: 'sortOrder', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'nameWithIcon',
      title: i18n.t('hierarchy.model.attributes.nameWithIcon'),
      params: [
        displayablePropertyParam({ path: 'nameWithIcon', transform: transformation.none }),
      ],
      template: '{value}',
      raw: true,
    }),
    displayableProperty({
      disabled: !appConfig.modules.enableFullOrderReporting,
      key: 'enableOrderReporting',
      title: i18n.t('hierarchy.model.attributes.enableOrderReporting'),
      params: [
        displayablePropertyParam({ path: 'enableOrderReporting', transform: transformation.none }),
      ],
      align: 'center',
      template: '{value}',
    }),
  ];

  get parent() {
    return this.rootStore.hierarchyStore.getById(this.parentId);
  }

  static prepareApiPayload(model: Hierarchy) {
    return {
      ...this.discardPropsWhereNameIncludes(model, 'translated_'),
      parentId: model.parentId || null,
      translations: Hierarchy.convertToSavableTranslations(model),
    };
  }

  // required attribute for tree select
  get value() {
    return this.id;
  }

  get name() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.name;
  }

  // required attribute for tree select
  get title() {
    return this.name;
  }

  get nameWithIcon() {
    if (this.icon) {
      return (
        <>
          <FontAwesomeIcon icon={HierarchyIconDefinition[this.icon]} style={{ marginRight: '10px' }}/>
          {this.name}
        </>
      );
    }

    return this.name;
  }

  countParents(element: Hierarchy, count = 0): number {
    if (element && !element.parentId) {
      return count;
    }

    return this.countParents(this.rootStore.hierarchyStore.getById(element.parentId) as Hierarchy, count + 1);
  }

  get level() {
    if (!this.parentId) {
      return 0;
    }

    return this.countParents(this);
  }

  parentsList(element: Hierarchy, parents: Hierarchy[] = []): Hierarchy[] {
    if (element && !element.parentId) {
      return [...parents, element];
    }

    return this.parentsList(
      this.rootStore.hierarchyStore.getById(element.parentId) as Hierarchy,
      [...parents, element]
    );
  }

  get parents() {
    return this.parentsList(this);
  }
}
