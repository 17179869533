import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonProps } from './Button';

export type CancelButtonProps = ButtonProps;

const CancelButton: React.FC<CancelButtonProps> = ({ children, ...props }) => {
  const { t } = useTranslation();

  return <Button {...props}>{t('button.cancel')}</Button>;
};

export default CancelButton;
