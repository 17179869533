import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import { gs1datamatrix, qrcode, drawingSVG } from 'bwip-js';
import { code128, toPath } from './1DBarcode';
import styles from './Barcode.module.scss';
import { useMount } from '../../../hooks/useMount';

export enum BarcodeType {
  Barcode = 'barcode',
  DataMatrix = 'dataMatrix',
  QrCode = 'qrCode'
}

const DefaultWidths = {
  barcode: 2,
  dataMatrix: 8,
  qrCode: 7,
};

const DefaultHeights = {
  barcode: 100,
  dataMatrix: 8,
  qrCode: 7,
};

export type BarcodeOptions = {
  width: number;
  height: number;
  displayValue?: boolean;
};

export type BarcodeProps = {
  type: BarcodeType;
  value: string;
  width?: number;
  height?: number;
  displayValue?: boolean;
};

const Barcode: React.FC<BarcodeProps> = ({ type, value, width, height, displayValue = true }) => {
  const { t } = useTranslation();
  const [id] = useState(uuid());
  const [bboxWidth, setBboxWidth] = useState(0);
  const [bboxHeight, setBboxHeight] = useState(0);
  const [barcodeType, setBarcodeType] = useState(type);

  useMount(() => {
    const bbox = (document.getElementById(id) as SVGPathElement | null)?.getBBox();
    setBboxWidth(bbox?.width || 0);
    setBboxHeight(bbox?.height || 0);
  }, [barcodeType, document.getElementById(id)]);

  let calcWidth = width;
  let calcHeight = height;
  if (!calcWidth) {
    calcWidth = DefaultWidths[type];
  }
  if (!calcHeight) {
    calcHeight = DefaultHeights[type];
  }

  if (type !== barcodeType) {
    setBarcodeType(type);
  }

  const extractDValue = (svg: string): string | undefined => (svg.match(/d="([^"]*)"/)?.[1]);
  let barcode;
  if (value.length > 0) {
    if (type === BarcodeType.Barcode) {
      barcode = toPath([code128(value)]);
    }
    if (type === BarcodeType.DataMatrix) {
      let svgData = null;
      try {
        svgData = gs1datamatrix({ text: value, bcid: 'gs1datamatrix' }, drawingSVG());
      } catch (error) {
        barcode = '';
        value = t('shared.error.gs1Standard');
      }
      if (svgData) {
        barcode = extractDValue(svgData);
      }
    }
    if (type === BarcodeType.QrCode) {
      let svgData = null;
      try {
        svgData = qrcode({ text: value, bcid: 'qrcode' }, drawingSVG());
      } catch (error) {
        barcode = '';
        value = t('shared.error.qrStandard');
      }
      if (svgData) {
        barcode = extractDValue(svgData);
      }
    }
  }

  calcWidth *= bboxWidth;
  calcHeight *= bboxHeight;

  return (
    <div className={styles.container}>
      <svg fill="black" width={calcWidth} height={calcHeight}>
        <path
          id={id}
          fillRule="evenodd"
          transform={`scale(${width || DefaultWidths[type]} ${height || DefaultHeights[type]})`}
          d={barcode}
        />
      </svg>
      {displayValue && value.length > 0 && <code>{value || ''}</code>}
    </div>
  );
};

export default Barcode;
