import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Tooltip, { TooltipProps } from './Tooltip';
import styles from './HelpTooltip.module.scss';
import MarkdownItem from '../MarkdownItem';

export type HelpTooltipProps = Omit<TooltipProps, 'title'> & {
  markdown?: boolean;
  // Keep this as string, as it is possible that this has to be rendered as markdown and therefore must be a string
  title?: string;
};
const HelpTooltip: React.FC<HelpTooltipProps> = ({ title, markdown, ...props }) => (
  <span className={styles.helpText}>
    {title ? (
      <Tooltip
        title={markdown ? <MarkdownItem>{title}</MarkdownItem> : title}
        overlayStyle={{ maxWidth: 'none' }}
        {...props}
      >
        <FontAwesomeIcon
          icon={faInfoCircle}
          className={'fa-1x'}
        />
      </Tooltip>
    ) : null}
  </span>
);

export default HelpTooltip;
