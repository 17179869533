import React from 'react';
import { Select as AntdSelect, SelectProps as AntdSelectProps } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/lib/select';

export type SelectProps<ValueType, OptType extends BaseOptionType | DefaultOptionType = DefaultOptionType> =
  AntdSelectProps<ValueType, OptType>;

const Select: (<ValueType, OptType extends BaseOptionType | DefaultOptionType = DefaultOptionType>(
  props: SelectProps<ValueType, OptType> & { children?: React.ReactNode }
) => React.ReactNode) & {
  Option: typeof AntdSelect.Option,
  OptGroup: typeof AntdSelect.OptGroup
} = <ValueType, OptType extends BaseOptionType = BaseOptionType>(props: SelectProps<ValueType, OptType>) => (
  <AntdSelect<ValueType, OptType> {...props}/>
);
export default Select;

Select.Option = AntdSelect.Option;
Select.OptGroup = AntdSelect.OptGroup;
