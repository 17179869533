import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { KeyPerformanceIndicatorTarget } from '../models/keyPerformanceIndicatorTarget';
import { calculateEndpoint } from '../middleware/endpoints/keyPerformanceIndicatorTarget';
import { RootStore } from './rootStore';

interface CalculateParams {
  fromDate: string;
  toDate: string;
  workplaceId: number;
  resolution: number;
  kpiIds: number[];
}

export class KeyPerformanceIndicatorTargetStore extends EntityStore<KeyPerformanceIndicatorTarget> {
  kpiTargets: KeyPerformanceIndicatorTarget[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'kpiTargets', Api.keyPerformanceIndicatorTarget, KeyPerformanceIndicatorTarget);

    makeObservable(this, {
      kpiTargets: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.keyPerformanceIndicatorStore,
        modelId: 'keyPerformanceIndicatorId',
      },
      {
        store: this.rootStore.materialStore,
        modelId: 'materialId',
      },
    ];
  }

  // eslint-disable-next-line class-methods-use-this
  async calculate(paramOptions: CalculateParams) {
    const { data } = await Api.keyPerformanceIndicatorTarget[calculateEndpoint](paramOptions);
    return data;
  }
}
