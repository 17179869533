import { Observer } from 'mobx-react-lite';
import { computed, makeObservable, observable } from 'mobx';
import { faMessageCheck } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { Document } from './document';
import { RootStore } from '../stores/rootStore';
import { Workplace } from './workplace';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import { datetimeFormat } from '../config/dayjs';
import { sortAlphabetically, sortChronologically, sortNumerically } from '../components/shared/tables/sorters';
import { User } from './user';
import { Order } from './order';
import { Material } from './material';
import { Batch } from './batch';

export enum QualityReportType {
  SUPPLIER_COMPLAINT = 'SUPPLIER_COMPLAINT',
  INTERNAL = 'INTERNAL'
}

/**
 * Translations:
 * t('qualityReport.model.attributes.state.CREATED')
 * t('qualityReport.model.attributes.state.SENT')
 * t('qualityReport.model.attributes.state.ARCHIVED')
 */
export enum QualityReportState {
  CREATED = 'CREATED',
  SENT = 'SENT',
  ARCHIVED = 'ARCHIVED'
}

/**
 * Translations:
 * t('qualityReport.model.attributes.priority.4')
 * t('qualityReport.model.attributes.priority.3')
 * t('qualityReport.model.attributes.priority.2')
 * t('qualityReport.model.attributes.priority.1')
 */
export enum QualityReportPriority {
  LOW = 4,
  MEDIUM = 3,
  HIGH = 2,
  VERY_HIGH = 1
}
export const QualityReportPriorities: QualityReportPriority[] = [
  QualityReportPriority.LOW,
  QualityReportPriority.MEDIUM,
  QualityReportPriority.HIGH,
  QualityReportPriority.VERY_HIGH,
];
export const QualityReportPriorityDefault = QualityReportPriority.MEDIUM;

/**
 * Translations:
 * t('qualityReport.model.other')
 * t('qualityReport.model.one')
 */
export class QualityReport extends BaseModel {
  id: number = 0;

  title: string = '';
  message: string = '';
  type: QualityReportType = QualityReportType.SUPPLIER_COMPLAINT;
  priority: QualityReportPriority = QualityReportPriorityDefault;
  state: QualityReportState = QualityReportState.CREATED;
  reportNo: string | null = null;
  supplier: string | null = null;

  workplaceId: number = 0;
  orderId: number | null = null;
  materialId: number | null = null;
  batchId: number | null = null;
  coordinatorUserId: number = 0;

  documentIds: number[] = [];

  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      title: observable,
      message: observable,
      type: observable,
      priority: observable,
      state: observable,
      reportNo: observable,
      supplier: observable,
      workplaceId: observable,
      workplace: computed,
      orderId: observable,
      order: computed,
      materialId: observable,
      material: computed,
      batchId: observable,
      batch: computed,
      coordinatorUserId: observable,
      coordinatorUser: computed,
      documentIds: observable,
      documents: computed,
      createdByUser: computed,
      ...QualityReport.modelCreateDecoration,
      ...QualityReport.modelUpdateDecoration,
    });
  }

  static faIcon = faMessageCheck;

  displayableProperties = [
    displayableProperty({
      title: i18n.t('qualityReport.model.attributes.createdAt'),
      params: [
        displayablePropertyParam({
          path: 'createdAt', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      key: 'createdAt',
      sorter: (a, b) => sortChronologically(a?.createdAt, b?.createdAt),
    }),
    displayableProperty({
      title: i18n.t('qualityReport.model.attributes.title'),
      params: [displayablePropertyParam({ path: 'title', transform: transformation.none })],
      template: '{value}',
      key: 'title',
      sorter: (a, b) => sortAlphabetically(a?.title, b?.title),
    }),
    displayableProperty({
      title: i18n.t('qualityReport.model.attributes.message'),
      params: [displayablePropertyParam({ path: 'message', transform: transformation.none })],
      template: '{value}',
      key: 'message',
      sorter: (a, b) => sortAlphabetically(a?.message, b?.message),
    }),
    displayableProperty({
      title: i18n.t('qualityReport.model.attributes.type.label'),
      template: '{value}',
      key: 'type',
      params: [displayablePropertyParam({
        path: 'type',
        transform: (type) => i18n.t(`qualityReport.model.attributes.type.${type}`),
      })],
      sorter: (a, b) => sortAlphabetically(a?.type, b?.type),
      raw: true,
    }),
    displayableProperty({
      title: i18n.t('qualityReport.model.attributes.priority.label'),
      template: '{value}',
      key: 'priority',
      params: [displayablePropertyParam({
        path: 'priority',
        transform: (priority) => i18n.t(`qualityReport.model.attributes.priority.${priority}`),
      })],
      sorter: (a, b) => sortNumerically(a?.priority, b?.priority),
      raw: true,
    }),
    displayableProperty({
      title: i18n.t('qualityReport.model.attributes.state.label'),
      template: '{value}',
      key: 'state',
      params: [displayablePropertyParam({
        path: 'state',
        transform: (state) => i18n.t(`qualityReport.model.attributes.state.${state}`),
      })],
      sorter: (a, b) => sortAlphabetically(a?.state, b?.state),
      raw: true,
    }),
    displayableProperty({
      title: i18n.t('qualityReport.model.attributes.createdBy'),
      params: [displayablePropertyParam({
        path: 'createdByUser',
        transform: (user) => (
          <Observer>
            {() => user.username}
          </Observer>
        ),
      })],
      raw: true,
      template: '{value}',
      key: 'createdByUser',
      sorter: (a, b) => sortAlphabetically(a?.createdByUser?.username, b?.createdByUser?.username),
    }),
    displayableProperty({
      title: i18n.t('qualityReport.model.attributes.coordinator'),
      params: [displayablePropertyParam({
        path: 'coordinatorUser',
        transform: (user) => (
          <Observer>
            {() => user.username}
          </Observer>
        ),
      })],
      raw: true,
      template: '{value}',
      key: 'coordinatorUser',
      sorter: (a, b) => sortAlphabetically(a?.coordinatorUser?.username, b?.createdByUser?.username),
    }),
    displayableProperty({
      title: i18n.t('qualityReport.model.attributes.reportNo'),
      params: [displayablePropertyParam({ path: 'reportNo', transform: transformation.none })],
      template: '{value}',
      key: 'reportNo',
      sorter: (a, b) => sortAlphabetically(a?.reportNo, b?.reportNo),
    }),
    displayableProperty({
      title: i18n.t('qualityReport.model.attributes.supplier'),
      params: [displayablePropertyParam({ path: 'supplier', transform: transformation.none })],
      template: '{value}',
      key: 'supplier',
      sorter: (a, b) => sortAlphabetically(a?.supplier, b?.supplier),
    }),
  ];

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);

    const workplacePrefix = `${titlePrefix}${i18n.t('workplace.model.one')} > `;
    allDisplayableProperties.push(
      ...Workplace.allDisplayableProperties(rootStore, `${pathPrefix}workplace.`, workplacePrefix)
    );

    const orderPrefix = `${titlePrefix}${i18n.t('order.model.one')} > `;
    allDisplayableProperties.push(
      ...Order.allDisplayableProperties(rootStore, `${pathPrefix}order.`, orderPrefix)
    );

    const materialPrefix = `${titlePrefix}${i18n.t('material.model.one')} > `;
    allDisplayableProperties.push(
      ...Material.allDisplayableProperties(rootStore, `${pathPrefix}material.`, materialPrefix)
    );

    const batchPrefix = `${titlePrefix}${i18n.t('batch.model.one')} > `;
    allDisplayableProperties.push(
      ...Batch.allDisplayableProperties(rootStore, `${pathPrefix}batch.`, batchPrefix)
    );

    return allDisplayableProperties;
  }

  get workplace(): Workplace {
    return this.rootStore.workplaceStore.getById(this.workplaceId) as unknown as Workplace;
  }

  get order() {
    return this.orderId ? this.rootStore.orderStore.getById(this.orderId) : null;
  }

  get material() {
    return this.materialId ? this.rootStore.materialStore.getById(this.materialId) : null;
  }

  get batch() {
    return this.batchId ? this.rootStore.batchStore.getById(this.batchId) : null;
  }

  get coordinatorUser(): User | undefined {
    return this.rootStore.userStore.getById(this.coordinatorUserId) as User | undefined;
  }

  get documents(): Document[] {
    return this.documentIds
      ? this.documentIds
        .map((id) => this.rootStore.documentStore.getById(id))
        .filter((document): document is Document => !!document)
      : [];
  }

  get createdByUser(): User | undefined {
    return this.rootStore.userStore.getById(this.createdBy) as User | undefined;
  }
}
