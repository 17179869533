import { observable, action, computed, makeObservable } from 'mobx';
import { RootStore } from './rootStore';

export class ClientStore {
  rootStore: RootStore;
  breakpoint = 'xs';

  isMinimalView = false;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      breakpoint: observable,
      isMobile: computed,
      isMinimalView: observable,
      setBreakpoint: action,
      setIsMinimalView: action,
    });

    this.rootStore = rootStore;
  }

  get isMobile() {
    return ['xs', 'sm'].includes(this.breakpoint);
  }

  setBreakpoint(breakpoint: string) {
    this.breakpoint = breakpoint;
  }

  setIsMinimalView(value: boolean) {
    this.isMinimalView = value;
  }
}
