import React from 'react';
import { Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { faArrowLeft, faArrowRight, faClock, faSearchMinus, faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../buttons/Button';

export type TimespanNavigatorProps = {
  fromDate: Dayjs;
  toDate: Dayjs;
  setFromDate: (date: Dayjs) => void;
  setToDate: (date: Dayjs) => void;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  minMinutes?: number;
};

const TimespanNavigator: React.FC<TimespanNavigatorProps> = ({
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  minDate,
  maxDate,
  minMinutes = 5,
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ flex: '0 1 auto' }}>
        <Button onClick={() => {
          const newFromDate = fromDate.clone().subtract(1, 'day');
          if (!minDate || newFromDate.isSameOrAfter(minDate)) {
            setFromDate(newFromDate);
            setToDate(toDate.clone().subtract(1, 'day'));
          }
        }}
        >
          <Space>
            <FontAwesomeIcon icon={faArrowLeft}/>
            {t('input.timespanNavigator.back1d')}
          </Space>
        </Button>
      </div>
      <div style={{ flex: '0 1 auto' }}>
        <Space>
          <Button onClick={() => {
            const newFromDate = fromDate.clone().subtract(toDate.diff(fromDate) / 2, 'ms');
            setFromDate(newFromDate);
          }}
          >
            <FontAwesomeIcon icon={faSearchMinus}/>
          </Button>
          <Button onClick={() => {
            setToDate(dayjs());
            setFromDate(dayjs().subtract(24, 'h'));
          }}
          >
            <Space>
              <FontAwesomeIcon icon={faClock}/>
              {t('input.timespanNavigator.last24h')}
            </Space>
          </Button>
          <Button
            onClick={() => {
              let newFromDate = fromDate.clone().add(toDate.diff(fromDate) / 2, 'ms');
              if (newFromDate.isSameOrAfter(toDate.clone().subtract(minMinutes, 'minutes'), 'minutes')) {
                newFromDate = toDate.clone().subtract(minMinutes, 'minutes');
              }
              setFromDate(newFromDate);
            }}
            disabled={fromDate.isSame(dayjs(toDate).subtract(minMinutes, 'minutes'), 'minutes')}
          >
            <FontAwesomeIcon icon={faSearchPlus}/>
          </Button>
        </Space>
      </div>
      <div style={{ flex: '0 1 auto' }}>
        <Button
          onClick={() => {
            const newToDate = toDate.clone().add(24, 'h');
            if (!maxDate || newToDate.isSameOrBefore(maxDate, 'day')) {
              setFromDate(fromDate.clone().add(24, 'h'));
              setToDate(newToDate);
            }
          }}
          disabled={toDate.isSame(dayjs(), 'day')}
        >
          <Space>
            <FontAwesomeIcon icon={faArrowRight}/>
            {t('input.timespanNavigator.forward1d')}
          </Space>
        </Button>
      </div>
    </div>
  );
};

export default TimespanNavigator;
