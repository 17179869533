import { computed, makeObservable, observable } from 'mobx';
import { Dayjs } from 'dayjs';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { WorkplaceStateLog } from '../models/workplaceStateLog';
import { RootStore } from './rootStore';

interface LoadManyByWorkplaceParams {
  fromDate: Dayjs;
  toDate: Dayjs;
  isInterruption?: boolean;
  interruptionReasonId?: number | string;
}

export class WorkplaceStateLogStore extends EntityStore<WorkplaceStateLog> {
  logs: WorkplaceStateLog[] = [];
  selected = null;

  constructor(rootStore: RootStore) {
    super(rootStore, 'logs', Api.workplaceStateLogs, WorkplaceStateLog);

    makeObservable(this, {
      selected: observable,
      logs: observable,
      latestBySelectedWorkplace: computed,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.workplaceStateStore,
        modelId: 'stateId',
      },
    ];
  }

  loadManyByWorkplace(
    workplaceId: number,
    { fromDate, toDate, isInterruption, interruptionReasonId }: LoadManyByWorkplaceParams
  ) {
    return this.loadAllWithDependencies({
      params: {
        workplaceId,
        fromDate: fromDate.toDate(),
        toDate: toDate.toDate(),
        isInterruption,
        interruptionReasonId,
      },
    });
  }

  getByWorkplaceId(workplaceId: number) {
    return this.logs.filter((log) => log.workplaceId === workplaceId);
  }

  getLatestOfWorkplace(workplaceId: number) {
    const wpLogs = this.getByWorkplaceId(workplaceId);
    return wpLogs.reduce((newest: WorkplaceStateLog | null, next) => {
      if (!newest || newest.start < next.start) {
        return next;
      }
      return newest;
    }, null);
  }

  getUnclassifiedByWorkplaceId(workplaceId: number) {
    return this.logs.filter((log) => log.workplaceId === workplaceId
      && log.state?.isInterruption && !log.interruptionReasonId);
  }

  getByStateIdAndWorkplaceId(stateId: number, workplaceId: number) {
    return this.logs.filter((log) => log.stateId === stateId && log.workplaceId === workplaceId);
  }

  getLatestByStateAndWorkplace(stateId: number, workplaceId: number) {
    const wpLogs = this.getByStateIdAndWorkplaceId(stateId, workplaceId);
    return wpLogs.reduce((newest: WorkplaceStateLog | null, next) => {
      if (!newest || newest.start < next.start) {
        return next;
      }
      return newest;
    }, null);
  }

  get latestBySelectedWorkplace() {
    if (this.rootStore.workplaceStore.selectedWorkplace) {
      const wpLogs = this.getByWorkplaceId(this.rootStore.workplaceStore.selectedWorkplace.id);
      return wpLogs.reduce((newest: WorkplaceStateLog | null, next) => {
        if (!newest || newest.start < next.start) {
          return next;
        }
        return newest;
      }, null);
    }
    return null;
  }
}
