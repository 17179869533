/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { useStore } from '../../../../hooks/useStore';
import AutoComplete, { AutoCompleteProps } from './Autocomplete';

export type BatchSelectorProps = Omit<AutoCompleteProps, 'propertyName'> & {
  propertyName?: string;
};

const BatchSelector: React.FC<BatchSelectorProps> = ({
  form,
  name,
  propertyName = 'id',
  value,
  multiple,
  ...props
}) => {
  const store = useStore();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <AutoComplete
      value={value}
      form={form}
      name={name}
      propertyName={propertyName}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      multiple={multiple}
      searchFunction={store.batchStore.searchByNo.bind(store.batchStore)}
      reverseSearchFunction={
        multiple
          ? store.batchStore.getByIds.bind(store.batchStore)
          : store.batchStore.getById.bind(store.batchStore)
      }
      populateStore={store.batchStore.load.bind(store.batchStore)}
      renderOption={(batch) => (
        {
          value: batch.id,
          label: batch.no,
        }
      )}
      {...props}
    />
  );
};

export default BatchSelector;
