import Highcharts from 'highcharts';
import Chart from './Chart';

const TimelineChart = ({options, isLoading, start, end, className, ...props}) => {
  const height = (42 * (options.yAxis?.categories?.length || 1)) + 26;
  return (
    <Chart
      highcharts={Highcharts}
      options={{
        ...options,
        chart: {
          type: 'xrange',
          animation: false,
          plotBackgroundColor: '#ffffff',
          height,
          margin: [26, 0, 0, 0],
          className,
          ...options.chart,
        },
        series: {
          pointWidth: 10,
          ...options.series,
        },
        title: {
          style: {
            display: 'none',
          },
          ...options.title,
        },
        subtitle: {
          style: {
            display: 'none',
          },
        },
        legend: {
          enabled: false,
          floating: true,
        },
        plotOptions: {
          series: {
            animation: false,
            minPointLength: 1,
            ...options.plotOptions?.series,
          },
          ...options.plotOptions,
        },
        tooltip: {
          enabled: false,
          ...options.tooltip,
        },
        xAxis: {
          type: 'datetime',
          min: start,
          max: end,
          offset: -(height + 4),
          tickLength: 0,
          ...options.xAxis,
        },
        pane: {
          size: '100%',
        },
        yAxis: {
          visible: false,
          reversed: true,
          ...options.yAxis,
        },
      }}
      isLoading={isLoading}
      is24HourSeries
      {...props}
    />
  );
};

export default TimelineChart;
