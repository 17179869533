import {createEntity, executeGet, executePost, updateEntity} from '../requests';

const resourcesName = 'v1/orders';

export const searchEndpoint = 'v1/search-orders';
export const reloadEndpoint = 'reload';
export const generateInspectionLotEndpoint = 'generateInspectionLot';

export const OrdersApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...values}) => updateEntity(values, `${resourcesName}/${id}`),
  search: (params) => executeGet(`${searchEndpoint}`, {params}),
  reload: (id) => executePost(`${resourcesName}/${id}/reload`),
  generateInspectionLot: (id) => executePost(`${resourcesName}/${id}/generate-inspection-lots`),
};
