import {executeGet} from '../requests';

const resourcesName = 'v1/data-export';

export const DataExportApi = {
  calculate: (params) => executeGet(
    `${resourcesName}`,
    { params }
  ),
};
