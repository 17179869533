import { observable, makeObservable, computed } from 'mobx';
import { faBoxes } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import { Material } from './material';
import { Operation } from './operation';
import { YieldType } from './yieldType';
import { Batch } from './batch';
import { UnitOfMeasurement } from './unitOfMeasurement';
import { datetimeFormat } from '../config/dayjs';
import { sortChronologically } from '../components/shared/tables/sorters';
import { Preparation } from './preparation';
import { RootStore } from '../stores/rootStore';

export class Yield extends BaseModel {
  id: number = 0;
  operationId: number = 0;
  yieldTypeId: number = 0;
  batchId: number | null = null;
  preparationId: number | null = null;
  consumptionLogIds: number[] = [];
  quantity: number = 0;
  unitOfMeasureId: number | null = null;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;
  recordedAt: string = '';

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      operationId: observable,
      operation: computed,
      yieldTypeId: observable,
      yieldType: computed,
      batchId: observable,
      batch: computed,
      preparationId: observable,
      preparation: computed,
      consumptionLogIds: observable,
      consumptionLogs: computed,
      quantity: observable,
      material: computed,
      unitOfMeasure: computed,
      recordedAt: observable,
      ...Yield.modelCreateDecoration,
      ...Yield.modelUpdateDecoration,
    });
  }

  get operation() {
    return this.operationId ? this.rootStore.operationStore.getById(this.operationId) : undefined;
  }

  get yieldType() {
    return this.yieldTypeId ? this.rootStore.yieldTypeStore.getById(this.yieldTypeId) : undefined;
  }

  get batch() {
    return this.batchId ? this.rootStore.batchStore.getById(this.batchId) : undefined;
  }

  get preparation() {
    return this.preparationId ? this.rootStore.preparationStore.getById(this.preparationId) : undefined;
  }

  get consumptionLogs() {
    return this.consumptionLogIds ? this.rootStore.consumptionLogStore.getByIds(this.consumptionLogIds) : undefined;
  }

  get material() {
    return this.operation?.material;
  }

  get unitOfMeasure(): UnitOfMeasurement | undefined {
    if (this.yieldType?.isProduct) {
      return this.rootStore.unitOfMeasurementStore.getById(
        this.operation?.materialId
          ? this.operation?.unitOfMeasureId || null
          : this.operation?.order?.unitOfMeasureId || null
      );
    }
    return this.rootStore.unitOfMeasurementStore.getById(this.yieldType?.unitOfMeasureId || null);
  }

  static faIcon = faBoxes;

  searchableProperties = [
    'operation.order.no',
    'material.name',
    'material.no',
  ];

  displayableProperties = [
    displayableProperty({
      title: i18n.t('yield.model.attributes.recordedAt'),
      params: [
        displayablePropertyParam({
          path: 'recordedAt', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      key: 'recordedAt',
      sorter: (a, b) => sortChronologically(a.recordedAt, b.recordedAt),
      renderText: (text, record) => record?.recordedAt || '',
    }),
    displayableProperty({
      title: i18n.t('yield.model.attributes.createdAt'),
      params: [
        displayablePropertyParam({
          path: 'createdAt', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      key: 'createdAt',
      sorter: (a, b) => sortChronologically(a.createdAt, b.createdAt),
      renderText: (text, record) => record?.createdAt || '',
    }),
    displayableProperty({
      key: 'quantity',
      title: i18n.t('yield.model.attributes.quantity'),
      sorter: (a, b) => a.quantity - b.quantity,
      params: [displayablePropertyParam({ path: 'quantity' })],
    }),
  ];

  static prepareApiPayload(model: Partial<Yield>) {
    return {
      ...model,
      batchId: model.batchId || null,
      unitOfMeasureId: model.unitOfMeasureId || null,
    };
  }

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);
    const operationPrefix = `${titlePrefix}${i18n.t('operation.model.one')} > `;
    allDisplayableProperties.push(
      ...Operation.allDisplayableProperties(rootStore, `${pathPrefix}operation.`, operationPrefix)
    );
    const yieldTypePrefix = `${titlePrefix}${i18n.t('yieldType.model.one')} > `;
    allDisplayableProperties.push(
      ...YieldType.allDisplayableProperties(rootStore, `${pathPrefix}yieldType.`, yieldTypePrefix)
    );
    const preparationPrefix = `${titlePrefix}${i18n.t('preparation.model.one')} > `;
    allDisplayableProperties.push(
      ...Preparation.allDisplayableProperties(rootStore, `${pathPrefix}preparation.`, preparationPrefix)
    );
    const materialPrefix = `${titlePrefix}${i18n.t('material.model.one')} > `;
    allDisplayableProperties.push(
      ...Material.allDisplayableProperties(rootStore, `${pathPrefix}material.`, materialPrefix)
    );
    const unitOfMeasurementPrefix = `${titlePrefix}${i18n.t('unitOfMeasurement.model.one')} > `;
    allDisplayableProperties.push(
      ...UnitOfMeasurement.allDisplayableProperties(
        rootStore,
        `${pathPrefix}unitOfMeasure.`,
        unitOfMeasurementPrefix
      )
    );
    const batchPrefix = `${titlePrefix}${i18n.t('batch.model.one')} > `;

    allDisplayableProperties.push(
      ...Batch.allDisplayableProperties(rootStore, `${pathPrefix}batch.`, batchPrefix)
    );

    return allDisplayableProperties;
  }
}
