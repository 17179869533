import {Space} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckDouble, faCircle} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import {Observer, observer} from 'mobx-react-lite';
import {transformation} from '../../../utils/transformations';
import {datetimeFormat, durationFormat} from '../../../config/dayjs';
import Table from '../../shared/tables/Table';
import {useStore} from '../../../hooks/useStore';
import {InspectionTaskResultValuation} from '../../../models/inspectionTaskResult';
import appConfig from '../../../utils/appConfig';
import UserFullName from '../../shared/UserFullName';

const QualitativeDetails = ({task, results}) => {
  const {t} = useTranslation();
  const store = useStore();

  const valuationStatusColor = (valuation) => {
    if (valuation === InspectionTaskResultValuation.accepted) {
      return appConfig.qualitativeStateColors.good;
    }
    if (valuation === InspectionTaskResultValuation.rejected) {
      return appConfig.qualitativeStateColors.bad;
    }
    return appConfig.qualitativeStateColors.warning;
  };

  const columns = [
    {
      key: 'date',
      title: t('inspectionTaskResult.model.attributes.recordedAt'),
      dataIndex: 'recordedAt',
      render: transformation.datetime({format: datetimeFormat}),
    },
    {
      key: 'value',
      title: t('inspectionTaskResult.model.attributes.value'),
      render: (record) => {
        const value = record.valuation === InspectionTaskResultValuation.notValuated
          ? t('inspectionTaskResult.valuation.notValuated') : record.valueWithText;
        if (record.overruledUserId) {
          return (
            <>
              <Space>
                <FontAwesomeIcon icon={faCircle} style={{color: valuationStatusColor(record.valuation)}}/>
                {task.isFourEyes ? value : <s>{value}</s>}
              </Space>
              <br/>
              <Space>
                {task.isFourEyes && <FontAwesomeIcon icon={faCheckDouble}/>}
                <FontAwesomeIcon icon={faCircle} style={{color: valuationStatusColor(record.overruledValuation)}}/>
                {record.overruledValue}
              </Space>
            </>
          );
        }
        return (
          <Space>
            <FontAwesomeIcon icon={faCircle} style={{color: valuationStatusColor(record.valuation)}}/>
            {value}
          </Space>
        );
      },
    },
    {
      key: 'user',
      title: t('inspectionTaskResult.model.attributes.createdBy'),
      dataIndex: 'createdBy',
      render: (userId, record) => (
        <Observer>
          {() => {
            const user = store.userStore.getById(userId);
            if (record.overruledUserId) {
              const overruledUser = store.userStore.getById(record.overruledUserId);
              return (
                <>
                  <UserFullName user={user} strikeout={!task.isFourEyes}/>
                  <br/>
                  <UserFullName user={overruledUser}/>
                </>
              );
            }
            return <UserFullName user={user}/>;
          }}
        </Observer>
      ),
    },
    {
      key: 'remark',
      title: t('inspectionTaskResult.model.attributes.remark'),
      dataIndex: 'remark',
      render: (value, record) => {
        if (record.overruledUserId) {
          return (
            <>
              {task.isFourEyes ? value : <s>{value}</s>}
              <br/>
              <span>{record.overruledRemark}</span>
            </>
          );
        }
        return value;
      },
    },
    {
      key: 'overdueDifference',
      title: t('inspectionTaskResult.model.attributes.overdueDifference'),
      dataIndex: 'overdueDifference',
      render: transformation.duration({format: durationFormat}),
    },
  ];
  return (
    <Table striped pagination={false} columns={columns} dataSource={results} rowKey={'id'} style={{width: '100%'}}/>
  );
};

export default observer(QualitativeDetails);
