import { computed, observable, makeObservable } from 'mobx';
import dayjs from 'dayjs';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

export class OperationPhase extends BaseModel {
  id: number = 0;
  no: string = '';
  name: string = '';
  operationId: number | null = null;
  phaseId: number = 0;
  plannedPersonnel: number | null = null;
  plannedStart: string | null = null;
  plannedEnd: string | null = null;
  plannedDurationSeconds: number | null = null;
  sortOrder: number | null = null;
  info: string | null = null;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      no: observable,
      name: observable,
      operationId: observable,
      phaseId: observable,
      phase: computed,
      plannedPersonnel: observable,
      plannedStart: observable,
      plannedEnd: observable,
      plannedDurationSeconds: observable,
      sortOrder: observable,
      info: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
      overtimeSeconds: computed,
      availableSeconds: computed,
      usedDurationPercentage: computed,
      availablePercentage: computed,
      overtimePercentage: computed,
      usedDurationSeconds: computed,
      sortOrderExtended: computed,
    });
  }

  get sortOrderExtended() {
    return this.sortOrder || this.phase?.sortOrder || Infinity;
  }

  get phase() {
    return this.rootStore.phaseStore.getById(this.phaseId);
  }

  get usedDurationSeconds() {
    const endedStateLogs = this.rootStore.operationStateLogStore.logs.filter(
      (log) => log.operationId === this.operationId && log.end
    );
    const notEndedStateLogsByOperationId = this.rootStore.operationStateLogStore.logs.find(
      (log) => log.operationId === this.operationId && !log.end
    );

    if (!notEndedStateLogsByOperationId) {
      return undefined;
    }

    const endedSeconds = endedStateLogs.reduce(
      (acc, current) => (
        current.durationSeconds
        && current.state?.phaseId
        && current.state.phaseId === this.rootStore.operationStore.active?.state?.phaseId
          ? acc + current.durationSeconds.asSeconds()
          : acc
      ),
      0
    );
    const activeSeconds = (
      notEndedStateLogsByOperationId
        ? dayjs.duration(dayjs().diff(dayjs(notEndedStateLogsByOperationId.start), 'seconds'), 'seconds').asSeconds()
        : 0
    );

    return endedSeconds + activeSeconds;
  }

  get overtimeSeconds() {
    return ((this.usedDurationSeconds || 0) - (this.plannedDurationSeconds || 0)) < 0
      ? 0
      : ((this.usedDurationSeconds || 0) - (this.plannedDurationSeconds || 0));
  }

  get availableSeconds() {
    return (this.plannedDurationSeconds || 0) - (this.usedDurationSeconds || 0);
  }

  get usedDurationPercentage() {
    return this.overtimeSeconds <= 0
      ? (1 / (this.plannedDurationSeconds || 0)) * (this.usedDurationSeconds || 0)
      : (1 / ((this.plannedDurationSeconds || 0) + this.overtimeSeconds)) * (this.plannedDurationSeconds || 0);
  }

  get availablePercentage() {
    return this.overtimeSeconds <= 0
      ? 1 - this.usedDurationPercentage
      : 0;
  }

  get overtimePercentage() {
    return this.overtimeSeconds <= 0
      ? 0
      : 1 - this.usedDurationPercentage;
  }
}
