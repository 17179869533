import {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {createRoot} from 'react-dom/client';
import {isEmpty} from 'lodash';
import dayjs from 'dayjs';
import {observer} from 'mobx-react-lite';
import {useMount} from '../../../hooks/useMount';
import {VisTimeline} from '../../shared/dataDisplay/VisTimeline';
import styles from './StateHistory.module.scss';
import {visItemStyle} from '../../shared/dataDisplay/visItemStyle';
import {visGroupStyle} from '../../shared/dataDisplay/visGroupStyle';

export const logTypes = {
  operationStateLog: 'OSL',
  workplaceStateLog: 'WSL',
  personnelLog: 'PL',
};

const toWindows = (arr) => arr.map((item, index) => [arr[index - 1] || null, item, arr[index + 1] || null]);

const toStateLogItem = (stateGroup, selected) => stateGroup.logs.map((log) => {
  const isEdited = !!log.isEdited;
  const states = log.operationId ? stateGroup.operationStates : stateGroup.workplaceStates;
  const logType = log.operationId ? logTypes.operationStateLog : logTypes.workplaceStateLog;
  const itemId = `${stateGroup.id}:${logType}:${log.id}`;
  const state = states?.find((s) => s.id === log?.stateId);
  const display = stateGroup.considerIsShownInHistory && !state?.isShownInHistory ? 'none' : 'inherit';
  const color = state?.color || '#ffffff';
  const isSelected = itemId === selected.itemId;
  const isError = log.error;
  // eslint-disable-next-line no-nested-ternary
  const zIndex = state.isInterruption ? (log.operationId ? 4 : 3) : (log.operationId ? 2 : 1);

  return ({
    id: itemId,
    logType,
    log,
    start: log.start,
    end: log.end || new Date(),
    zIndex,
    content: `${isEdited ? '<i>*' : ''} ${isError ? '!' : ''}`
      + `${state ? state.label : '&nbsp;'}${isEdited ? '</i>' : ''}`,
    style: visItemStyle({color, isSelected, isError, display, zIndex}),
    group: stateGroup.id,
    linkedGroups: stateGroup.linkedGroups,
  });
});

const toPersonnelLogItems = (personnelGroup, selected) => toWindows(personnelGroup.logs).map(([, log, next]) => {
  const isEdited = !!log.isEdited;
  const logType = 'PL';
  const itemId = `${personnelGroup.id}:${logType}:${log.id}`;
  const color = '#4f4d4d';
  const isSelected = itemId === selected.itemId;

  return ({
    id: itemId,
    logType,
    log,
    start: log.createdAt,
    end: dayjs(next?.createdAt).toISOString(),
    content: `${isEdited ? '<i>*' : ''}${log.personnel}${isEdited ? '</i>' : ''}`,
    style: visItemStyle({color, isSelected}),
    group: personnelGroup.id,
    linkedGroups: personnelGroup.linkedGroups,
  });
});

const toGroup = (groups, selected, showNested) => groups.map((group) => {
  if (group.id) {
    const isSelected = selected.groupId === group.id;
    return {
      id: group.id,
      content: group.name,
      style: visGroupStyle({isSelected}),
      title: group.name,
      treeLevel: group.treeLevel,
      nestedGroups: group.nestedGroups,
      showNested: group.showNested || showNested[group.id] || false,
    };
  }
  return null;
});

const StateHistory = ({
  stateLogGroups,
  personnelLogGroup,
  fromDate,
  toDate,
  readonly,
  onChange,
  options,
  refreshSelected,
  ...props
}) => {
  const {t} = useTranslation();
  const [items, _setItems] = useState([]);
  const itemsRef = useRef(items);
  const [groups, setGroups] = useState([]);
  const [selected, _setSelected] = useState({time: null});
  const selectedRef = useRef(selected);
  const [visibleRange, setVisibleRange] = useState({fromDate, toDate});
  const datesRef = useRef({fromDate, toDate});
  const showNested = useRef({});

  const setItems = (itemsArray) => {
    itemsRef.current = itemsArray; // keep updated
    _setItems(itemsArray);
  };

  const getRelevantLogs = (selection, filteredItemsByType) => {
    let selectedItem = null;
    const prevItems = {};
    const currentItems = {};
    const nextItems = {};
    Object.entries(filteredItemsByType).forEach(([logType, rawItems]) => {
      toWindows(rawItems).forEach(([prev, current, next]) => {
        if (dayjs(selection.time).isBetween(dayjs(current?.start), dayjs(current?.end), null, '[]')) {
          currentItems[logType] = current || null;

          if (current.group === selection?.groupId) {
            selectedItem = !selectedItem || current?.zIndex > selectedItem?.zIndex ? current : selectedItem;
          }
        }

        if (dayjs(selection.time).isBetween(
          dayjs(prev?.end || current?.start),
          dayjs(next?.start || current?.end),
          null,
          '[]'
        )) {
          prevItems[logType] = prev || null;
          nextItems[logType] = next || null;

          prevItems.closestItem = prevItems.closestItem?.end
          && (!prev || dayjs(prevItems.closestItem?.end)?.isAfter(dayjs(prev?.end)))
            ? prevItems.closestItem
            : prev;

          nextItems.closestItem = nextItems.closestItem?.start
          && dayjs(nextItems.closestItem?.start)?.isBefore(dayjs(next?.start))
            ? nextItems.closestItem
            : next;

          if (logType !== logTypes.personnelLog) {
            prevItems.closestStateItem = prevItems.closestStateItem?.end
            && (!prev || dayjs(prevItems.closestStateItem?.end)?.isAfter(dayjs(prev?.end)))
              ? prevItems.closestStateItem
              : prev;

            nextItems.closestStateItem = nextItems.closestStateItem?.start
            && dayjs(nextItems.closestStateItem?.start)?.isBefore(dayjs(next?.start))
              ? nextItems.closestStateItem
              : next;
          }
        }
      });
    });
    return [selectedItem, prevItems, currentItems, nextItems];
  };

  const setSelected = (selection) => {
    const filteredItemsByType = {};
    itemsRef.current.forEach((item) => {
      if ((item.group === selection?.groupId || item.linkedGroups?.includes(selection?.groupId))) {
        if (!filteredItemsByType[item.logType]) {
          filteredItemsByType[item.logType] = [];
        }
        filteredItemsByType[item.logType].push(item);
      }
    });

    const [selectedItem, prevItems, currentItems, nextItems] = getRelevantLogs(selection, filteredItemsByType);

    const selectedWithItem = {
      ...selection,
      itemId: selectedItem?.id || null,
      item: selectedItem || null,
      logType: selectedItem?.logType || null,
      prevItems,
      currentItems,
      nextItems,
    };

    selectedRef.current = selectedWithItem;
    _setSelected(selectedWithItem);
    onChange(selectedWithItem);
  };

  useMount(() => {
    setVisibleRange({fromDate, toDate});
  }, [fromDate, toDate]);

  const TimeIndicator = () => (
    <b className={styles.timeIndicator}>
      {selectedRef.current?.time ? dayjs(selectedRef.current?.time).format('LT') : null}
    </b>
  );

  useMount(() => {
    const allGroups = [];
    const allItems = [];

    if (stateLogGroups && stateLogGroups.length) {
      allGroups.push(...toGroup(stateLogGroups, selected, showNested.current));
      const stateLogGroupsItems = stateLogGroups.reduce(
        (acc, curr) => ([...acc, ...toStateLogItem(curr, selected)]),
        []
      );
      allItems.push(...stateLogGroupsItems);
    }

    if (personnelLogGroup && !isEmpty(personnelLogGroup)) {
      allGroups.push(...toGroup([personnelLogGroup], selected, showNested.current));
      const personnelLogGroupItems = toPersonnelLogItems(personnelLogGroup, selected);
      allItems.push(...personnelLogGroupItems);
    }

    setGroups(allGroups);
    setItems(allItems);
  }, [stateLogGroups, personnelLogGroup, selected]);

  useMount(() => {
    _setSelected({time: null});
    selectedRef.current = null;
  }, [refreshSelected]);

  const handleClick = (event) => {
    const allowedOn = ['background', 'item'];
    if (allowedOn.includes(event.what)) {
      const sel = {
        ...selectedRef.current,
        x: event.pageX,
        y: event.pageY,
        what: event.what,
        time: event.time,
        groupId: event.group,
      };
      setSelected(sel);
    }

    if (event.what === 'group-label') {
      showNested.current = {
        ...showNested.current,
        [event.group]: !showNested.current[event.group],
      };
    }
  };

  const handleTimeChange = (event) => {
    if (dayjs(event.time).isBetween(datesRef.current.fromDate, datesRef.current.toDate)) {
      const sel = {
        ...selectedRef.current,
        x: event.event.center.x,
        what: event.id,
        time: event.time,
      };
      setSelected(sel);
    }
  };

  const handleRangeChanged = (event) => {
    if (event.byUser) {
      setVisibleRange({fromDate: dayjs(event.start), toDate: dayjs(event.end)});
    }
  };

  const handleChanged = () => {
    if (document.querySelector('.vis-custom-time.time div')) {
      const root = createRoot(document.querySelector('.vis-custom-time.time div'));
      root.render(TimeIndicator());
    }
  };

  return (
    <div className={readonly ? styles.readonlyTimeline : styles.timeline}>
      {groups.length ? (
        <VisTimeline
          items={items}
          groups={groups.length ? groups : []}
          clickHandler={handleClick}
          timechangeHandler={handleTimeChange}
          rangechangedHandler={handleRangeChanged}
          changedHandler={handleChanged}
          options={{
            start: visibleRange.fromDate.toDate(),
            end: visibleRange.toDate.toDate(),
            min: fromDate.toDate(),
            max: toDate.toDate(),
            snap: (date) => {
              const minute = 1000 * 60;
              return Math.round(date / minute) * minute;
            },
            showCurrentTime: false,
            moveable: true,
            ...options,
          }}
          customTimes={readonly || !selected.time ? null : {
            time: selected.time,
          }}
          {...props}
        />
      ) : (<i>{t('workflowCorrection.noDataFound')}</i>)}
    </div>
  );
};

export default observer(StateHistory);
