import { observable, makeObservable } from 'mobx';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFileExclamation } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import i18n from '../i18n/i18n';
import { DisplayablePropertyParams, displayableProperty } from './displayableProperty';
import { displayablePropertyParam } from './displayablePropertyParam';
import { sortAlphabetically } from '../components/shared/tables/sorters';
import { RootStore } from '../stores/rootStore';

export class FaultCollection extends BaseModel {
  id: number = 0;
  name: string = '';
  description: string | null = null;
  uuid: string = '';
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      description: observable,
      uuid: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  searchableProperties = ['name'];

  static faIcon: IconDefinition = faFileExclamation;

  displayableProperties: DisplayablePropertyParams[] = [
    displayableProperty({
      key: 'name',
      title: i18n.t('faultCollection.model.attributes.name'),
      params: [displayablePropertyParam({ path: 'name' })],
      sorter: (a, b) => sortAlphabetically(a.name, b.name),
    }),
    displayableProperty({
      key: 'description',
      title: i18n.t('faultCollection.model.attributes.description'),
      params: [displayablePropertyParam({ path: 'description' })],
      sorter: (a, b) => sortAlphabetically(a.description, b.description),
    }),
  ];

  static prepareApiPayload(model: Partial<FaultCollection>) {
    return {
      id: model.id,
      name: model.name,
      description: model.description,
    };
  }
}
