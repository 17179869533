import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {uniq} from 'lodash';
import {Col, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import Modal from '../shared/modal/Modal';
import {useStore} from '../../hooks/useStore';
import ConsumeIngredient from './ConsumeIngredient';
import ComponentTable from './ComponentTable';
import Select from '../shared/inputs/Select';
import {useMount} from '../../hooks/useMount';
import styles from './preparationModal.module.scss';
import PreparationWarningModal from './PreparationWarningModal';
import Button from '../shared/buttons/Button';
import {PreparationState} from '../../models/preparation';

const PreparationModal = ({
  preparation,
  onClose,
  ...props
}) => {
  const store = useStore();
  const {t} = useTranslation();
  const storageUnit = store.storageUnitStore.getById(preparation.storageUnitId);
  const [positions, setPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState();
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [outOfToleranceComponents, setOutOfToleranceComponents] = useState([]);
  const [newState, setNewState] = useState(PreparationState.FINISHED);

  const setToUnfinishedPosition = (allPositions) => {
    const firstUnfinishedPosition = allPositions
      .find((pos) => !store.componentStore.positionFinished(preparation, pos));
    setSelectedPosition(firstUnfinishedPosition === undefined ? allPositions[0] : firstUnfinishedPosition);
  };

  const PositionOption = ({position}) => {
    const positionFinished = store.componentStore.positionFinished(preparation, position);
    return (
      <span className={positionFinished ? styles.positionFinished : null}>
        {store.componentStore.positionName(position)}
        {positionFinished ? (<FontAwesomeIcon icon={faCheckCircle} style={{marginLeft: '.5em'}}/>) : null}
      </span>
    );
  };

  const getTitle = () => (
    storageUnit
      ? `${t('batchHandling.preparation.title')} - ${t('batchHandling.batchNo')} ${storageUnit?.no}`
      : '-'
  );

  const getConsumptionState = (record) => {
    const actualQuantity = store.consumptionLogStore.actualConsumed(preparation.id, record.id);

    if ((actualQuantity >= (record.toleranceLow ?? record.preparationQuantity))
      && (actualQuantity <= (record.toleranceHigh ?? record.preparationQuantity))
    ) {
      return 1;
    }

    if (record.toleranceHigh && actualQuantity > record.toleranceHigh) {
      return -1;
    }

    return 0;
  };

  const updateState = (state) => {
    store.preparationStore
      .update({
        id: preparation.id,
        state,
      })
      .then(() => {
        onClose(true);
      });
  };

  const checkPreparation = (state) => {
    const components = store.componentStore.filterBy({operationId: preparation.operationId});
    const outOfTolerance = components.filter((component) => getConsumptionState(component) !== 1);

    setNewState(state);

    if (outOfTolerance.length > 0) {
      setOutOfToleranceComponents(outOfTolerance);
      setWarningModalVisible(true);
    } else {
      updateState(state);
    }
  };

  useMount(() => {
    store.consumptionLogStore.reloadConsumptions({preparationId: preparation.id});
  });

  useMount(() => {
    const compPositions = uniq(store.componentStore.components.map((c) => c.position)).sort();
    setPositions(compPositions);
    if (selectedPosition === undefined) {
      setToUnfinishedPosition(compPositions);
    }
  }, [
    store.componentStore.components.length,
  ]);

  useMount(() => {
    if (preparation
      && positions
      && selectedPosition
      && store.componentStore.positionFinished(preparation, selectedPosition)) {
      setToUnfinishedPosition(positions);
      store.flashMessageStore.addFlashMessage({
        type: 'success',
        title: t(
          'batchHandling.preparation.positionFinished',
          {positionName: store.componentStore.positionName(selectedPosition)}
        ),
      });
    }
  }, [store.consumptionLogStore.consumptions.length]);

  const getFooter = () => {
    if (preparation.state === PreparationState.FINISHED) {
      return (
        <Button
          type={'primary'}
          onClick={() => {
            updateState(PreparationState.DONE);
          }}
        >
          {t('batchHandling.preparation.empty')}
        </Button>
      );
    }
    return (
      <Row justify="space-between">
        <Col>
          <Button
            type={'primary'}
            onClick={() => {
              checkPreparation(PreparationState.FINISHED);
            }}
          >
            {t('batchHandling.preparation.finish')}
          </Button>
        </Col>
        <Col>
          <Button
            type={'primary'}
            onClick={() => {
              checkPreparation(PreparationState.DONE);
            }}
          >
            {t('batchHandling.preparation.finishAndEmpty')}
          </Button>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Modal
        title={getTitle()}
        fullscreen={store.clientStore.isMobile}
        open
        footer={getFooter()}
        maskClosable={false}
        onCancel={onClose}
        {...props}
      >
        <Row>
          <Col span={24}>
            <Select
              style={{width: '100%', marginBottom: '2em'}}
              value={selectedPosition}
              onChange={setSelectedPosition}
            >
              {positions.map((pos) => (
                <Select.Option key={pos} value={pos}>
                  <PositionOption position={pos}/>
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        {preparation.state === PreparationState.RUNNING && (
          <Row>
            <Col span={24}>
              <ConsumeIngredient
                preparation={preparation}
                position={selectedPosition}
              />
            </Col>
          </Row>
        )}
        <Row style={{marginTop: '1em'}}>
          <Col span={24}>
            <ComponentTable
              position={selectedPosition}
              preparation={preparation}
            />
          </Col>
        </Row>
      </Modal>
      {warningModalVisible && (
        <PreparationWarningModal
          preparation={preparation}
          outOfToleranceComponents={outOfToleranceComponents}
          title={getTitle()}
          onClose={() => {
            setWarningModalVisible(false);
          }}
          onSuccess={() => {
            setWarningModalVisible(false);
            updateState(newState);
          }}
        />
      )}
    </>
  );
};

export default observer(PreparationModal);
