import { observable, makeObservable } from 'mobx';
import { BaseModel } from './base';
import i18n from '../i18n/i18n';
import { displayableProperty } from './displayableProperty';
import { displayablePropertyParam } from './displayablePropertyParam';
import { RootStore } from '../stores/rootStore';

export class Cockpit extends BaseModel {
  id: number = 0;
  name: string = '';
  description: string | null = null;
  isWorkplaceCockpit: boolean = false;
  isExclusiveView: boolean = false;
  sortOrder: number = 0;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      description: observable,
      isWorkplaceCockpit: observable,
      isExclusiveView: observable,
      sortOrder: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  searchableProperties = ['name', 'description'];

  displayableProperties = [
    displayableProperty({
      key: 'name',
      title: i18n.t('cockpit.model.attributes.name'),
      params: [displayablePropertyParam({ path: 'name' })],
    }),
    displayableProperty({
      key: 'description',
      title: i18n.t('cockpit.model.attributes.description'),
      params: [displayablePropertyParam({
        path: 'description',
      })],
    }),
  ];

  static prepareApiPayload(model: Cockpit) {
    return {
      id: model?.id,
      name: model.name,
      description: model.description,
      isWorkplaceCockpit: model.isWorkplaceCockpit,
      isExclusiveView: model.isExclusiveView || false,
      sortOrder: model.sortOrder,
    };
  }
}
