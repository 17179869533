import React, {useState} from 'react';
import Highcharts from 'highcharts';
import {observer} from 'mobx-react-lite';
import {groupBy} from 'lodash';
import dayjs from 'dayjs';
import {useMount} from '../../../../hooks/useMount';
import {useStore} from '../../../../hooks/useStore';
import Chart from '../../../shared/charts/Chart';
import {formatDuration} from '../../../../utils/formatting';
import {durationFormat, minutesDurationFormat} from '../../../../config/dayjs';
import Spinner from '../../../shared/spinners/Spinner';
import {EnDash} from '../../../shared/unicodeWrapper/EnDash';

const InterruptionAnalysisChart = ({interruptionClassIds, workplaceId, fromDate, toDate}) => {
  const store = useStore();
  const [pieChartData, setPieChartData] = useState([]);
  const [columnChartData, setColumnChartData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const filterByInterruptionClasses = (interruptionStateHistory) =>
    interruptionStateHistory.filter(
      (ish) => interruptionClassIds.includes(ish.interruptionClassId)
    );

  const groupByInterruptionClass = (interruptionStateHistory) =>
    groupBy(interruptionStateHistory, (ish) => ish.interruptionClassId);

  const groupByInterruptionReason = (interruptionReasons) =>
    groupBy(interruptionReasons, (ir) => ir.interruptionReason?.id || null);

  const aggregateInterruptionReasonClassesDuration = (groupedInterruptionStateHistory) =>
    Object.entries(groupedInterruptionStateHistory).map(([key, stateHistoryEntries]) => {
      const aggregatedDuration = stateHistoryEntries
        .reduce((acc, curr) => acc + (curr.durationSeconds?.asMilliseconds() || 0), 0);
      return {
        interruptionClass: store.interruptionClassStore.getById(key),
        aggregatedDuration: dayjs.duration(aggregatedDuration, 'milliseconds'),
        interruptionReasons: stateHistoryEntries,
      };
    });

  const aggregateInterruptionReasonsDuration = (groupedInterruptionReasons) =>
    Object.entries(groupedInterruptionReasons).map(([key, interruptionReasons]) => {
      const aggregatedDuration = interruptionReasons
        .reduce((acc, curr) => acc + (curr.durationSeconds?.asMilliseconds() || 0), 0);

      return {
        interruptionReason: store.interruptionReasonStore.getById(key),
        aggregatedDuration: dayjs.duration(aggregatedDuration, 'milliseconds'),
      };
    });

  useMount(() => {
    setIsLoading(true);
    const resolve = [];

    const interruptionClassPromise = store.interruptionClassStore.loadMany(interruptionClassIds);
    resolve.push(interruptionClassPromise);

    interruptionClassIds.forEach((interruptionClassId) => {
      const interruptionReasonPromise = store.interruptionReasonStore.loadAll({
        params: {
          interruptionClassId,
        },
      });
      resolve.push(interruptionReasonPromise);
    });

    const loadInterruptionHistoryPromise = store.stateHistoryStore.loadInterruptionHistory({
      workplaceId,
      fromDate,
      toDate,
    }, false);
    resolve.push(loadInterruptionHistoryPromise);

    Promise.all(resolve)
      .then(() => {
        const historyByWorkplaceId = store.stateHistoryStore.getInterruptionStateHistoryByWorkplaceId(workplaceId);
        const filtered = filterByInterruptionClasses(historyByWorkplaceId);
        const grouped = groupByInterruptionClass(filtered);
        const aggregated = aggregateInterruptionReasonClassesDuration(grouped);
        if (aggregated.length > 0) {
          const maxAggregated = aggregated.find(
            (entry) => entry.aggregatedDuration.asMilliseconds() === Math.max(
              ...aggregated.map((o) => o.aggregatedDuration.asMilliseconds())
            )
          );
          const colGrouped = groupByInterruptionReason(maxAggregated.interruptionReasons);
          const colAggregated = aggregateInterruptionReasonsDuration(colGrouped);
          setColumnChartData({
            aggregatedData: colAggregated,
            aggregatedDuration: maxAggregated.aggregatedDuration,
            interruptionClass: maxAggregated.interruptionClass,
          });
        } else {
          setColumnChartData({
            aggregatedData: [],
          });
        }
        setPieChartData(aggregated);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [interruptionClassIds?.join(','), workplaceId, fromDate, toDate]);

  /* eslint-disable object-shorthand */
  /* eslint-disable react/no-this-in-sfc */
  /* eslint-disable func-names */
  const options = {
    chart: {
      type: 'pie',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    title: {
      text: '',
    },
    series: [{
      data: pieChartData.map((entry) => ({
        name: entry.interruptionClass.label,
        y: entry.aggregatedDuration.asSeconds(),
        aggregatedData: entry,
      })).sort((a, b) => b.y - a.y),
    }],
    plotOptions: {
      pie: {
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          formatter: function () {
            const duration = dayjs.duration(this.point.y, 'seconds');
            const formattedDuration = formatDuration(
              duration,
              duration.asMinutes() < 60 ? minutesDurationFormat : durationFormat
            );
            return `${this.point.name}: ${formattedDuration}`;
          },
        },
        events: {
          click: function (e) {
            const grouped = groupByInterruptionReason(e.point.aggregatedData.interruptionReasons);
            const aggregated = aggregateInterruptionReasonsDuration(grouped);
            setColumnChartData({
              aggregatedData: aggregated,
              aggregatedDuration: e.point.aggregatedData.aggregatedDuration,
              interruptionClass: e.point.aggregatedData.interruptionClass,
              color: e.point.color,
            });
          },
        },
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  const color = columnChartData?.color || '#7cb5ec';
  const colors = [];
  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);

  for (let i = 0.9; i > 0; i -= 0.1) {
    colors.push(`rgba(${r}, ${g}, ${b}, ${i.toFixed(1)})`);
  }

  const optionsDetailChart = {
    colors,
    chart: {
      type: 'column',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    title: {
      text: columnChartData?.interruptionClass?.label || '',
      floating: true,
      style: {
        color: 'black',
      },
    },
    series: columnChartData
      ? columnChartData.aggregatedData.map((interruptionReason) => ({
        name: interruptionReason.interruptionReason?.label || EnDash(),
        data: [{ y: interruptionReason.aggregatedDuration.asSeconds() }],
      }))
      : [],
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter: function () {
            const duration = dayjs.duration(this.point.y, 'seconds');
            return formatDuration(
              duration,
              duration.asMinutes() < 60 ? minutesDurationFormat : durationFormat
            );
          },
        },
      },
    },
    legend: {
      layout: 'vertical',
      align: 'right',
    },
    yAxis: {
      visible: false,
    },
    xAxis: {
      visible: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <div style={{height: '100%'}}>
      {
        isLoading
          ? <Spinner fullWidth fullHeight/>
          : (
            <div>
              <div style={{width: '50%', display: 'inline-block'}}>
                <Chart options={options} highcharts={Highcharts} showIsEmpty={!pieChartData.length}/>
              </div>
              {
                columnChartData?.aggregatedData.length
                  ? (
                    <div style={{width: '50%', display: 'inline-block'}}>
                      <Chart
                        options={optionsDetailChart}
                        highcharts={Highcharts}
                        showIsEmpty={!columnChartData?.aggregatedData.length}
                      />
                    </div>
                  )
                  : ''
              }
            </div>
          )
      }
    </div>
  );
};

export default observer(InterruptionAnalysisChart);
