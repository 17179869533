import {createEntity, executeDelete, executeGet, updateEntity} from '../requests';

const resourcesName = 'v1/documents';

export const DocumentsApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  createImage: (entity) => createEntity(entity, `${resourcesName}/image`),
  update: ({id, ...entity}) => updateEntity(entity, `${resourcesName}/${id}`),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
};
