import { createEntity, executeDelete, executeGet, updateEntity } from '../requests';
import { Gateway } from '../../models/gateway';

const resourcesName = 'v1/gateways';

export const GatewaysApi = {
  all: ({ ...params }) => executeGet(resourcesName, { params }),
  byId: (id: number) => executeGet(`${resourcesName}/${id}`),
  create: (entity: Gateway) => createEntity(entity, resourcesName),
  update: ({ id, ...entity }: any) => updateEntity(entity, `${resourcesName}/${id}`),
  destroy: (id: number) => executeDelete(`${resourcesName}/${id}`),
};
