import React from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Button, { ButtonProps } from './Button';
import Tooltip from '../tooltips/Tooltip';

export type ShowButtonProps = ButtonProps & {
  message?: string;
  tooltip?: string;
  icon?: IconDefinition;
};

const ShowButton: React.FC<ShowButtonProps> = ({
  tooltip,
  message,
  ...props
}) => {
  const buttonProps = {
    icon: <EyeOutlined/>,
    type: 'link',
    ...props,
  } as ButtonProps;

  if (tooltip) {
    return <Tooltip title={tooltip}><Button {...buttonProps}/></Tooltip>;
  }
  if (message) {
    return <Button {...buttonProps}>{message}</Button>;
  }
  return <Button {...buttonProps}/>;
};

export default ShowButton;
