import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { Ingredient } from '../models/ingredient';
import i18n from '../i18n/i18n';
import { RootStore } from './rootStore';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';

export class IngredientStore extends EntityStore<Ingredient> {
  ingredients = []; // used only to assure entity store functionality
  ingredient: Ingredient | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore, 'ingredients', Api.ingredients, Ingredient);

    makeObservable(this, {
      ingredient: observable,
    });
  }

  async loadByNo(ingredientNo: string, operationId: number, displayFlashMessages: boolean) {
    if (this.ingredient?.no !== ingredientNo) {
      try {
        const { data, ...rest } = await Api.ingredients.all({ no: ingredientNo, operationId });

        let ingredient;
        if (rest.status === 200) {
          if (data && data.length) {
            [ingredient] = data;
          } else {
            ingredient = data;
          }
        }

        if (rest.status === 204) {
          this.ingredient = null;

          if (displayFlashMessages) {
            this.rootStore.flashMessageStore.addFlashMessage(new FlashMessage(
              FlashMessageType.WARNING,
              i18n.t('batchHandling.consumption.errors.E_INGREDIENT_NOT_FOUND')
            ));
          }
          return null;
        }

        if (ingredient?.batchId) {
          await this.rootStore.batchStore.load(ingredient.batchId);
        }

        if (ingredient?.materialId && !ingredient.material) {
          await this.rootStore.materialStore.load(ingredient.materialId);
        }

        this.ingredient = this.createModelInstance(ingredient);

        return this.ingredient;
      } catch (err: any) {
        if (displayFlashMessages) {
          if (err.name === 'E_INVALID_BARCODE') {
            this.rootStore.flashMessageStore.addFlashMessage(new FlashMessage(
              FlashMessageType.ERROR,
              i18n.t('batchHandling.consumption.errors.E_INVALID_BARCODE')
            ));
          } else if (err.name === 'E_MISSING_VALID_STOCK') {
            this.rootStore.flashMessageStore.addFlashMessage(new FlashMessage(
              FlashMessageType.ERROR,
              i18n.t('batchHandling.consumption.errors.E_MISSING_VALID_STOCK')
            ));
          } else {
            this.rootStore.flashMessageStore.addFlashMessage(new FlashMessage(
              FlashMessageType.ERROR,
              i18n.t('batchHandling.consumption.errors.E_INTERNAL_SERVER_ERROR')
            ));
          }
        }
        this.ingredient = null;
      }
    }
    return this.ingredient;
  }
}
