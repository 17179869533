import { observable, makeObservable } from 'mobx';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { Permission } from '../models/permission';
import { RootStore } from './rootStore';

export class PermissionStore extends EntityStore<Permission, string> {
  identityField = 'identifier';
  permissions: Permission[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'permissions', Api.permissions, Permission);

    makeObservable(this, {
      permissions: observable,
    });
  }
}
