import React, { KeyboardEventHandler, useRef } from 'react';
import { Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import Input from '../../inputs/Input';
import { WidgetActionInputProps } from './WidgetActionButton';
import styles from './WidgetAction.module.scss';
import { useStore } from '../../../../hooks/useStore';
import { useScopes } from '../../../../hooks/useScopes';
import { useModulePolicy } from '../../../../hooks/useModulePolicy';
import Button from '../../buttons/Button';
import { execResourceName } from '../../../../middleware/endpoints/widgetActions';

const WidgetActionScan: React.FC<WidgetActionInputProps> = ({ widgetAction, onExec }) => {
  const store = useStore();
  const scopes = useScopes();
  const policy = useModulePolicy(store, scopes);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = React.useState('');

  const done = () => {
    setValue('');
    inputRef.current?.focus();
  };

  const handleExec = () => {
    if (!value) {
      return;
    }
    onExec(widgetAction.id, value, done);
  };

  const handleKey: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      handleExec();
    }
  };

  const isExecuting = store.widgetActionStore.isResourceBusy(execResourceName);
  const disabled = !policy.canExecute() || isExecuting;

  return (
    <Space.Compact style={{ width: '100%' }}>
      <Input
        onChange={(e) => setValue(e.target.value)}
        value={value}
        onKeyDown={handleKey}
        placeholder={widgetAction.label}
        className={styles.scan}
        disabled={disabled}
        ref={inputRef}
        data-cy={'WidgetActionScan-Input'}
      />
      <Button onClick={handleExec} disabled={disabled} type={'primary'}>
        <FontAwesomeIcon icon={faBarcode}/>
      </Button>
    </Space.Compact>
  );
};

export default observer(WidgetActionScan);
