import React, {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import Alert from 'antd/es/alert';
import {useTranslation} from 'react-i18next';
import CockpitWidget from './CockpitWidget';
import {WidgetLayout} from '../../../../models/widgetLayout';
import {WidgetLayouts} from '../../../shared/widgets/WidgetLayouts';
import {useMount} from '../../../../hooks/useMount';
import {useStore} from '../../../../hooks/useStore';
import useWorkplaceRelatedWidgetTitle from '../../../../hooks/useWorkplaceRelatedWidgetTitle';
import KpiSpeedometer from './KpiSpeedometer';
import {KpiSpeedometerCockpitWidgetConfig} from './config/kpiSpeedometerCockpitWidgetConfig';

const KpiSpeedometerWidget = ({
  widgetId,
  title,
  ...props
}) => {
  const store = useStore();
  const {t} = useTranslation();
  const [selectedWorkplaceId, setSelectedWorkplaceId] = useState(null);
  const widgetConfig = useMemo(() => new KpiSpeedometerWidget.Config({
    rootStore: store,
    identifier: KpiSpeedometerWidget.identifier,
    widgetId,
  }), []);
  const widgetTitle = useWorkplaceRelatedWidgetTitle(title, selectedWorkplaceId);

  const kpiId = widgetConfig.getSettingValue('kpi', 'kpi');
  const minutesBack = widgetConfig.getSettingValue('kpi', 'minutesBack');

  useMount(() => {
    setSelectedWorkplaceId(
      store.workplaceStore.getByHierarchyId(widgetConfig.getSettingValue('workplace', 'hierarchyId'))?.id
      || store.cockpitStore.currentWorkplaceId || undefined
    );
  }, [
    store.cockpitStore.currentWorkplaceId,
    widgetConfig.getSettingValue('workplace', 'hierarchyId'),
  ]);

  return (
    <CockpitWidget
      widgetId={widgetId}
      title={widgetTitle}
      widgetConfig={widgetConfig}
      {...props}
    >
      {
        !selectedWorkplaceId
          ? <Alert type={'info'} message={t('kpiSpeedometerCockpitWidget.alert.noWorkplaceSelected')}/>
          : ''
      }
      {
        selectedWorkplaceId
          ? (
            <KpiSpeedometer
              workplaceId={selectedWorkplaceId}
              kpiId={kpiId}
              minutesBack={minutesBack}
            />
          )
          : ''
      }
    </CockpitWidget>
  );
};

KpiSpeedometerWidget.Config = KpiSpeedometerCockpitWidgetConfig;
KpiSpeedometerWidget.defaultLayout = new WidgetLayout({
  identifier: CockpitWidget.identifier,
  width: 4,
  height: 8,
  minHeight: 4,
  minWidth: WidgetLayouts.sixthWidth.minW,
});
export default observer(KpiSpeedometerWidget);
