import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { UnitOfMeasurement } from '../models/unitOfMeasurement';
import { RootStore } from './rootStore';

export class UnitOfMeasurementStore extends EntityStore<UnitOfMeasurement> {
  unitsOfMeasurement: UnitOfMeasurement[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'unitsOfMeasurement', Api.unitsOfMeasurement, UnitOfMeasurement);

    makeObservable(this, {
      unitsOfMeasurement: observable,
    });
  }
}
