import { observable, makeObservable, computed } from 'mobx';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import { sortAlphabetically } from '../components/shared/tables/sorters';
import { CustomPropertyTypes } from './customPropertyDataTypes';
import { CustomActionAdditionalHeaders } from './customActionAdditionalHeaders';
import { CustomPropertiesObject } from './customProperty';
import { RootStore } from '../stores/rootStore';

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST'
}

export enum ExternalResourceProtocol {
  Http = 'HTTP',
  Https = 'HTTPS',
  File = 'FILE',
  Mqtt = 'MQTT'
}

export enum ExternalResources {
  InspectionReload = 'INSPECTION_RELOAD',
  BatchPropertiesLoad = 'BATCH_PROPERTIES_LOAD',
  OrderReload = 'ORDER_RELOAD',
  InspectionLotGenerated = 'INSPECTION_LOT_GENERATED'
}

export enum ExternalResourceType {
  System = 'SYSTEM',
  DataExport = 'DATA_EXPORT',
  WorkplaceMapping = 'WORKPLACE_MAPPING'
}

export const ExternalResourceIds = Object.freeze({
  InspectionReload: 1,
});

export class ExternalResource extends BaseModel {
  id: number = 0;
  name?: string = '';
  description?: string | null = null;
  protocol: ExternalResourceProtocol = ExternalResourceProtocol.Http;
  method: HttpMethod | null = null;
  url: string = '';
  additionalHeaders: CustomActionAdditionalHeaders = {};
  type?: ExternalResourceType = ExternalResourceType.System;
  workplaceIds: number[] = [];
  properties?: CustomPropertiesObject = undefined;
  isLogged: boolean = false;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      description: observable,
      protocol: observable,
      method: observable,
      url: observable,
      additionalHeaders: observable,
      type: observable,
      workplaceIds: observable,
      workplaces: computed,
      properties: observable,
      isLogged: observable,
    });
  }

  static faIcon = faExternalLinkAlt;

  searchableProperties = ['name', 'description', 'protocol', 'method', 'url', 'additionalHeaders'];

  customPropertyType = CustomPropertyTypes.ExternalResource;

  displayableProperties = [
    displayableProperty({
      key: 'name',
      title: i18n.t('externalResource.model.attributes.name'),
      params: [displayablePropertyParam({ path: 'name', transform: transformation.none })],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.name, b.name),
    }),
  ];

  get workplaces() {
    return this.rootStore.workplaceStore.getByIds(this.workplaceIds);
  }

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);
    allDisplayableProperties.push(
      ...this.displayableCustomProperties(rootStore, pathPrefix, titlePrefix)
    );

    return allDisplayableProperties;
  }

  get additionalHeadersFormatted() {
    return this.additionalHeaders ? JSON.stringify(this.additionalHeaders, null, 1) : null;
  }

  static prepareApiPayload(model: ExternalResource) {
    return {
      id: model.id,
      name: model.name,
      description: model.description,
      url: model.url || '',
      protocol: model.protocol,
      method: model.method || null,
      type: model.type,
      isLogged: model.isLogged,
      properties: model.properties || null,
      workplaceIds: model.workplaceIds || [],
      additionalHeaders: model.additionalHeaders || null,
    };
  }
}
