import {useCallback, useRef, useState} from 'react';
import {debounce} from 'lodash';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {Col, Row, Space} from 'antd';
import ScanIngredient from './shared/ScanIngredient';
import Button from '../shared/buttons/Button';
import AlertWarning from '../shared/alert/AlertWarning';
import Input from '../shared/inputs/Input';
import Form from '../shared/form/Form';
import {ValidationRules} from '../../utils/validationRules';
import {FormItemLayouts} from '../shared/form/formItemLayouts';
import TimeoutCommit, {useTimeoutCommit} from '../shared/buttons/TimeoutCommit';
import {useStore} from '../../hooks/useStore';
import {useMount} from '../../hooks/useMount';
import {IngredientType} from '../../models/ingredient';
import IngredientDetails from './shared/IngredientDetails';

const ConsumeIngredient = ({preparation, position}) => {
  const {t} = useTranslation();
  const store = useStore();
  const [form] = Form.useForm();
  const [ingredient, setIngredient] = useState(null);
  const currentIngredient = useRef(null);
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState(null);
  const [autoFocus, setAutoFocus] = useState(1);
  const amountInput = useRef(null);

  const consumeIngredient = async () => {
    const ingredientToConsume = currentIngredient.current;
    if (ingredientToConsume) {
      await store.consumptionLogStore.consumeIngredient({
        preparationId: preparation.id,
        ingredientNo: ingredientToConsume.no,
        amount: form.getFieldValue('amount'),
        position,
      });
      form.setFieldsValue({amount: null});
      currentIngredient.current = null;
      setIngredient(null);
      setError(null);
      setWarning(null);
      setAutoFocus(autoFocus + 1);
    }
  };

  const {timeoutCommitProps, cancel: handleCancel, start: handleStart} = useTimeoutCommit({
    onCommit: consumeIngredient,
    commitText: t('links.confirm'),
    hideCancelButton: true,
  });

  const validateIngredient = async (ingredientToValidate, amount, pos) => {
    if (ingredientToValidate) {
      try {
        setError(null);
        setWarning(null);
        await store.consumptionLogStore.validate({
          preparationId: preparation.id,
          ingredientNo: ingredientToValidate.no,
          amount,
          position: pos,
        });
        currentIngredient.current = ingredientToValidate;
      } catch (err) {
        handleCancel();
        currentIngredient.current = null;
        if (err.name === 'E_BAD_REQUEST') {
          setError(err.error?.messages[0]);
          setWarning(err.warning);
        } else {
          setError('E_COULD_NOT_VALIDATE');
        }
      }
    }
  };

  const debouncedValidationQuery = useCallback(debounce((i, amt, pos) => validateIngredient(i, amt, pos), 200), []);

  const handleAdjustInput = () => {
    amountInput.current.focus({cursor: 'all'});
    handleCancel();
  };

  const handleOnFocus = () => {
    handleCancel();
  };

  const handleChangeIngredient = async (newIngredient, isScanned) => {
    handleCancel();
    if (isScanned && newIngredient && currentIngredient.current && timeoutCommitProps.startDate) {
      // if another barcode is scanned then consume uncommitted
      await validateIngredient(currentIngredient.current, form.getFieldValue('amount'), position);
      await consumeIngredient();
    }
    const newAmount = newIngredient?.amount || null;
    if (newIngredient && newAmount) {
      handleStart();
      await validateIngredient(newIngredient, newAmount, position);
    }
    form.setFieldsValue({amount: newAmount});
    currentIngredient.current = newIngredient;
    setIngredient(newIngredient);
    if (!newIngredient) {
      setError(null);
      setWarning(null);
    }
  };

  useMount(() => {
    async function validate() {
      await validateIngredient(ingredient, form.getFieldValue('amount'), position);
    }

    if (ingredient) {
      handleCancel();
      validate();
    }
  }, [position]);

  const isInsoftIngredient = () => (
    ingredient?.type === IngredientType.INSOFT_MULTI_USE || ingredient?.type === IngredientType.INSOFT_SINGLE_USE
  );

  return (
    <>
      <ScanIngredient
        ingredient={ingredient}
        onChangeIngredient={handleChangeIngredient}
        autoFocus={autoFocus}
      />
      {ingredient ? <IngredientDetails ingredient={ingredient} collapsed/> : null}
      <Form
        form={form}
        labelAlign={'left'}
        {...FormItemLayouts.fullWidth}
        onValuesChange={() => debouncedValidationQuery(ingredient, form.getFieldValue('amount'), position)}
      >
        <Form.Item
          style={{marginBottom: '1em'}}
          name={'amount'}
          labelAlign={'left'}
          {...FormItemLayouts.fullWidth}
          rules={[ValidationRules.greaterThanOrEqualTo(0.001)]}
        >
          <Input
            ref={amountInput}
            type={'text'}
            inputMode={'numeric'}
            onFocus={handleOnFocus}
            autoComplete={'off'}
            addonAfter={ingredient?.unitOfMeasure?.label}
            disabled={!ingredient || isInsoftIngredient()}
            placeholder={t('batchHandling.preparation.amount')}
          />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <>
              <Row style={{marginBottom: '0.5em'}}>
                <Col span={24}>
                  {error ? (
                    <AlertWarning
                      size={'small'}
                      message={t(`batchHandling.consumption.errors.${error}`)}
                      type={error?.startsWith('W') ? 'warning' : 'error'}
                    />
                  ) : null}
                  {warning
                    ? <AlertWarning message={t(`batchHandling.consumption.warnings.${warning}`)} type={'warning'}/>
                    : null}
                </Col>
              </Row>
              <Row type={'flex'} justify="end">
                <Col>
                  <Space style={{float: 'right'}}>
                    <Button
                      onClick={isInsoftIngredient() ? handleCancel : handleAdjustInput}
                      disabled={!ingredient || (isInsoftIngredient() && !timeoutCommitProps.startDate)}
                    >
                      {isInsoftIngredient() ? t('button.cancel') : t('batchHandling.preparation.adjustInput')}
                    </Button>

                    <TimeoutCommit
                      {...timeoutCommitProps}
                      type={'primary'}
                      disabled={error || !ingredient || !!form.getFieldsError()
                        .filter(({errors}) => errors.length).length}
                    />
                  </Space>
                </Col>
              </Row>
            </>
          )}
        </Form.Item>

      </Form>
    </>
  );
};

export default observer(ConsumeIngredient);
