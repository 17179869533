import {observer} from 'mobx-react-lite';
import Widget from '../../shared/widgets/Widget';
import styles from './OperatorWidget.module.scss';
import Switch from '../../shared/inputs/Switch';
import {useStore} from '../../../hooks/useStore';
import {getProductionWidgetPath} from '../../../utils/manualPaths';

const OperatorWidget = ({
  icon,
  title,
  onHeadClick,
  children,
  identifier,
  widgetConfig,
  disabled,
  minimized,
  widgetClassNames = [],
  manualPath,
}) => {
  const store = useStore();

  const handleSwitchToggle = (enabled, e) => {
    e.stopPropagation();
    store.terminalLayoutStore.toggleDisabled(!enabled, identifier);
  };

  const handleMinimizedPin = (isMinimized, e) => {
    e.stopPropagation();
    store.terminalLayoutStore.toggleMinimized(!isMinimized, identifier);
  };

  const createOrUpdateSettings = (settingValues) => {
    store.settingStore.createOrUpdate(
      store.terminalLayoutStore.currentTerminalLayout.id,
      store.workplaceStore.selectedWorkplace.id,
      widgetConfig.settingsIdentifier,
      settingValues
    );
  };

  return (
    <Widget
      title={title}
      actionsLeft={
          store.terminalLayoutStore.isEditMode
            ? (
              <Switch
                size="small"
                defaultChecked
                checked={!disabled}
                onChange={handleSwitchToggle}
              />
            ) : icon
        }
      onHeadClick={store.terminalLayoutStore.isEditMode ? null : onHeadClick}
      widgetClassNames={disabled ? [styles.disabled] : widgetClassNames}
      headerClassNames={store.terminalLayoutStore.isEditMode ? [styles.withDragAction] : []}
      createOrUpdateSettings={createOrUpdateSettings}
      widgetConfig={widgetConfig}
      isEditMode={store.terminalLayoutStore.isEditMode}
      configureEnabled={!disabled}
      showPin
      isPinable
      onPinWidget={handleMinimizedPin}
      minimized={minimized}
      manualPath={getProductionWidgetPath(manualPath)}
    >
      {children}
    </Widget>

  );
};
export default observer(OperatorWidget);
