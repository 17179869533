import {useTranslation} from 'react-i18next';
import Input from '../shared/inputs/Input';
import Select from '../shared/inputs/Select';
import Form from '../shared/form/Form';
import DefaultFormActions from '../shared/form/DefaultFormActions';
import {useStore} from '../../hooks/useStore';
import {ValidationRules} from '../../utils/validationRules';
import {EnDash} from '../shared/unicodeWrapper/EnDash';
import NumericFormItem from '../shared/form/NumericFormItem';

const YieldForm = ({
  fields,
  onChange,
  saving = false,
  onSubmit,
  onCancel,
}) => {
  const store = useStore();
  const [form] = Form.useForm();
  const {t} = useTranslation();

  const getUnit = () => {
    const yieldType = store.yieldTypeStore.getById(form.getFieldValue('yieldTypeId'));
    if (!yieldType) {
      return null;
    }
    return store.yieldTypeStore.getUnit(yieldType, store.operationStore.active);
  };

  return (
    <Form
      form={form}
      labelCol={{span: 10}}
      onFinish={onSubmit}
      fields={fields}
      onFieldsChange={(changedFields, allFields) => onChange && onChange(allFields)}
    >
      <Form.Item
        name={'yieldTypeId'}
        label={t('yield.model.attributes.yieldType')}
        rules={[ValidationRules.required()]}
      >
        <Select
          size={'large'}
          style={{width: '100%'}}
          allowClear
          loading={store.yieldTypeStore.isLoadingCollection}
        >
          {store.yieldTypeStore.generalYieldTypes?.sort((a, b) => a.sortOrder - b.sortOrder)
            .map((yieldType) => (
              <Select.Option key={yieldType.id} value={yieldType.id}>
                {yieldType.label}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <NumericFormItem
        name={'quantity'}
        label={t('yield.model.attributes.quantity')}
        rules={[ValidationRules.required()]}
      >
        <Input
          size={'large'}
          type={'number'}
          autoComplete={'off'}
          addonAfter={getUnit() || EnDash()}
        />
      </NumericFormItem>
      <DefaultFormActions saving={saving} onCancel={onCancel}/>
    </Form>
  );
};

export default YieldForm;
