import React from 'react';
import { Breadcrumb } from 'antd';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';

export type HierarchyTreeBreadcrumbProps = {
  path: string[];
};

const HierarchyTreeBreadcrumb: React.FC<HierarchyTreeBreadcrumbProps> = ({ path }) => (
  <Breadcrumb
    separator={<FontAwesomeIcon icon={faCaretRight}/>}
    items={path.map((part) => ({ title: part }))}
  />
);

export default observer(HierarchyTreeBreadcrumb);
export const renderBreadcrumb = (path: string[]) => <HierarchyTreeBreadcrumb path={path}/>;
