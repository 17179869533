import {createEntity, executeDelete, executeGet, updateEntity} from '../requests';

const resourcesName = 'v1/sensor-data-targets';
export const byTimeEndpoint = 'byTime';
export const byTimeResource = 'by-time';

export const SensorDataTargetApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...entity}) => updateEntity(entity, `${resourcesName}/${id}`),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
  [byTimeEndpoint]: (params) => executeGet(`${resourcesName}/${byTimeResource}`, params),
};
