import {createEntity, executeDelete, executeGet, executePost, updateEntity} from '../requests';

const resourcesName = 'v1/print-labels';
export const reloadLabelsEndpoint = 'reload';

export const PrintLabelsApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...values}) => updateEntity(values, `${resourcesName}/${id}`),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
  reload: () => executePost(`${resourcesName}/${reloadLabelsEndpoint}`),
};
