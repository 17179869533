import { observable, makeObservable, computed } from 'mobx';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

/**
 * t('printJobLog.model.states.PENDING')
 * t('printJobLog.model.states.SUCCESS')
 * t('printJobLog.model.states.FAILURE')
 */
export enum PrintJobLogState {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}

export interface PrintJobData {
  [key: string]: any;
}

export class PrintJobLog extends BaseModel {
  id: number = 0;
  printJobId: number = 0;
  printJobData: PrintJobData = {};
  state: PrintJobLogState = PrintJobLogState.PENDING;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      printJobId: observable,
      printJob: computed,
      printJobData: observable,
      state: observable,
    });
  }

  static faIcon = faPrint;

  get printJob() {
    return this.rootStore.printJobStore.getById(this.printJobId);
  }
}
