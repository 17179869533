import { observable, action, makeObservable } from 'mobx';
import { Dayjs } from 'dayjs';
import { Api } from '../middleware/api';
import { EntityStore, LoadStrategies } from './entityStore';
import { PrintJob, PrintJobState } from '../models/printJob';
import { PrinterType } from '../models/printer';
import { abortEndpoint, calculateEndpoint, printDataEndpoint, startEndpoint } from '../middleware/endpoints/printJobs';
import { RootStore } from './rootStore';

export class PrintJobStore extends EntityStore<PrintJob> {
  printJobs: PrintJob[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'printJobs', Api.printJobs, PrintJob, true);

    makeObservable(this, {
      printJobs: observable,
      start: action,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.operationStore,
        modelId: 'operationId',
      },
      {
        store: this.rootStore.printLabelStore,
        modelId: 'printLabelId',
      },
      {
        store: this.rootStore.documentStore,
        modelId: 'documentId',
      },
    ];
  }

  loadByDatesAndHierarchyIdAndState(fromDate: Dayjs, toDate: Dayjs, hierarchyId: number, state: PrintJobState) {
    this.loadAllWithDependencies({
      strategy: LoadStrategies.replace,
      params: {
        fromDate: fromDate.toISOString(),
        toDate: toDate.toISOString(),
        hierarchyId,
        state,
      },
    });
  }

  jobsHaveRequestedState(ids: number[], requestedState: PrintJobState) {
    if (ids.length === 0) {
      return true;
    }
    return this.printJobs
      .filter((job) => ids.includes(job.id))
      .every((job) => job.state === requestedState);
  }

  anyJobRunningOnPrinter(printerId: number) {
    return this.printJobs.some((job) => job.printerId === printerId && job.state === PrintJobState.PRINTING);
  }

  jobsHaveUniquePrintersAndAreNotPrinting(ids: number[]) {
    if (ids.length === 0) {
      return true;
    }
    const printers = new Set();
    return this.printJobs
      .filter((job) => ids.includes(job.id))
      .every((job) => {
        if (this.rootStore.printerStore.getById(job.printerId)?.type === PrinterType.ONE_OFF) {
          return true;
        }
        if (printers.has(job.printerId) || this.anyJobRunningOnPrinter(job.printerId)) {
          return false;
        }
        printers.add(job.printerId);
        return true;
      });
  }

  // eslint-disable-next-line class-methods-use-this
  calculate(operationId: number) {
    return Api.printJobs[calculateEndpoint](operationId);
  }

  // eslint-disable-next-line class-methods-use-this
  start(printJobIds: number[]) {
    return Api.printJobs[startEndpoint](printJobIds);
  }

  // eslint-disable-next-line class-methods-use-this
  abort(printJobIds: number[]) {
    return Api.printJobs[abortEndpoint](printJobIds);
  }

  // eslint-disable-next-line class-methods-use-this
  getPrintData(printJobId: number) {
    return Api.printJobs[printDataEndpoint](printJobId);
  }
}
