import React from 'react';
import { DatePicker as AntdDatePicker } from 'antd';
import { RangePickerProps as AntdDateRangePickerProps } from 'antd/lib/date-picker';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { datetimeFormat } from '../../../config/dayjs';
import appConfig from '../../../utils/appConfig';
import i18n from '../../../i18n/i18n';

const DefaultRanges = {
  OneH: {
    label: '1h',
    value: [dayjs().subtract(1, 'hour'), dayjs()],
  },
  ThreeH: {
    label: '3h',
    value: [dayjs().subtract(3, 'hour'), dayjs()],
  },
  FiveH: {
    label: '5h',
    value: [dayjs().subtract(5, 'hour'), dayjs()],
  },
  EightH: {
    label: '8h',
    value: [dayjs().subtract(8, 'hour'), dayjs()],
  },
  OneD: {
    label: '24h',
    value: [dayjs().subtract(1, 'day'), dayjs()],
  },
  TwoD: {
    label: '48h',
    value: [dayjs().subtract(2, 'day'), dayjs()],
  },
};

export const DateRangePickerRanges: any = {
  ...DefaultRanges,
  Yesterday: {
    label: i18n.t('datepicker.yesterday'),
    value: [
      dayjs().subtract(1, 'day').startOf('day'),
      dayjs().subtract(1, 'day').endOf('day'),
    ],
  },
  LastWeek: {
    label: i18n.t('datepicker.lastWeek'),
    value: [
      dayjs().subtract(1, 'week').startOf('week').day(appConfig.locale.startOfWeek === 0 ? 0 : 1),
      dayjs().subtract(1, 'week').endOf('week').day(appConfig.locale.startOfWeek === 0 ? -1 : 0),
    ],
  },
};

/**
 * FIXME: replace all string types with dayjs
 */
export type DateRangePickerProps = Omit<AntdDateRangePickerProps, 'showTime' | 'format' | 'presets'> & {
  dateTimeFrom?: string;
  dateTimeFromFormat?: string;
  dateTimeTo?: string;
  dateTimeToFormat?: string;
  defaultDateFormat?: string;
  showTime?: boolean;
  showDefaultTimeRanges?: boolean;
};

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  dateTimeFrom,
  dateTimeFromFormat = datetimeFormat,
  dateTimeTo,
  dateTimeToFormat = datetimeFormat,
  defaultDateFormat = datetimeFormat,
  showTime = true,
  showDefaultTimeRanges = false,
  ...props
}) => {
  const { RangePicker } = AntdDatePicker;

  return (
    <RangePicker
      defaultValue={[
        dateTimeFrom ? dayjs(dateTimeFrom, dateTimeFromFormat) : null,
        dateTimeTo ? dayjs(dateTimeTo, dateTimeToFormat) : null,
      ]}
      format={defaultDateFormat}
      showTime={showTime}
      presets={showDefaultTimeRanges ? Object.values(DateRangePickerRanges) : undefined}
      {...props}
    />
  );
};

export default observer(DateRangePicker);
