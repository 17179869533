import React from 'react';
import { Input } from 'antd';
import { get, throttle } from 'lodash';
import { SearchProps } from 'antd/es/input';

export type SearchFieldProps = SearchProps & {
  throttleTime?: number;
};

const SearchField: React.FC<SearchFieldProps> = ({ onSearch, throttleTime = 100, ...props }) => {
  const defaultSearchProps = { enterButton: true, allowClear: true };

  const handleSearchChanged = throttle((e) => {
    const query = get(e, 'target.value', '');
    return onSearch && onSearch(query);
  }, throttleTime, { trailing: false });

  return <Input.Search onChange={handleSearchChanged} {...defaultSearchProps} {...props}/>;
};

export default SearchField;
