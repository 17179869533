import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import Table from '../shared/tables/Table';

const BatchProperties = ({properties}) => {
  const {t} = useTranslation();

  const dataSource = Object.entries(properties).map((prop) => ({ key: prop[0], value: prop[1] }));
  const columns = [
    {
      key: 'key',
      dataIndex: 'key',
      title: t('producedMaterialWidget.batchProperties.column.key'),
    },
    {
      key: 'value',
      dataIndex: 'value',
      title: t('producedMaterialWidget.batchProperties.column.value'),
    },
  ];

  return (
    <Table rowKey={(record) => record.value} dataSource={dataSource} columns={columns} pagination={false}/>
  );
};

export default observer(BatchProperties);
