import React, { forwardRef } from 'react';
import { Button as AntdButton, ButtonProps as AntdButtonProps } from 'antd';
import { Link } from 'react-router-dom';

export type ButtonLinkProps = AntdButtonProps & {
  to: string;
  ref?: React.ForwardedRef<any>;
};

const ButtonLink: React.FC<ButtonLinkProps> = forwardRef(({ to, children, ...rest }, ref) => (
  <Link to={to}>
    <AntdButton {...rest} ref={ref}>{children}</AntdButton>
  </Link>
));

export default ButtonLink;
