import React from 'react';
import { Col, Divider, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { BaseWidgetConfig } from '../baseWidgetConfig';
import { useStore } from '../../../../hooks/useStore';
import { WidgetAction, WidgetActionType } from '../../../../models/widgetAction';
import WidgetActionButton from './WidgetActionButton';
import WidgetActionScan from './WidgetActionScan';
import { ScopeContext } from '../../../../policies/scopeContext';
import { Scopes } from '../../../../models/scopes';
import { FlashMessage, FlashMessageType } from '../../../../models/flashMessage';
import WidgetActionText from './WidgetActionText';
import styles from './WidgetAction.module.scss';

export type WidgetActionsProps = {
  widgetConfig: BaseWidgetConfig;
};

const WidgetActionContainer: React.FC<WidgetActionsProps> = ({ widgetConfig }) => {
  const store = useStore();
  const { t } = useTranslation();

  const handleExec = (id: number, data: string, cb?: () => void) => {
    if (!store.workplaceStore.selectedWorkplace?.id) {
      if (cb) {
        cb();
      }
      return;
    }
    store.widgetActionStore.exec({
      id,
      data,
      workplaceId: store.workplaceStore.selectedWorkplace?.id,
      operationId: store.operationStore.active?.id,
    }).then(() => {
      store.flashMessageStore.addFlashMessage(new FlashMessage(
        FlashMessageType.SUCCESS,
        t('widgetAction.execute.success', { data })
      ));
    }).catch((e: Error) => {
      // eslint-disable-next-line no-console
      console.error(e);
      store.flashMessageStore.addFlashMessage(new FlashMessage(
        FlashMessageType.ERROR,
        t('widgetAction.execute.error', { data })
      ));
    }).finally(() => {
      if (cb) {
        cb();
      }
    });
  };

  const getInput = (action: WidgetAction): React.ReactNode => {
    switch (action.type) {
      case WidgetActionType.Button:
        return <WidgetActionButton widgetAction={action} onExec={handleExec}/>;
      case WidgetActionType.Scan:
        return <WidgetActionScan widgetAction={action} onExec={handleExec}/>;
      case WidgetActionType.Text:
        return <WidgetActionText widgetAction={action} onExec={handleExec}/>;
      default:
        return null;
    }
  };

  const actionIds: number[] = widgetConfig.getSettingValue('extWidgetActionConfig', 'widgetActions');
  if (!actionIds?.length) {
    return null;
  }

  // Actions are mapped individually by id to preserve the configured order.
  const actions = actionIds
    .map((id) => store.widgetActionStore.getById(id))
    .filter((action): action is WidgetAction => !!action);

  if (!actions.length) {
    return null;
  }

  return (
    <ScopeContext.Provider value={[Scopes.widgetAction]}>
      <Row justify={'start'} data-cy={'WidgetActionContainer-Row'} className={styles.row}>
        {actions.map((action) => (
          <Col key={action.id} className={styles.col}>
            {getInput(action)}
          </Col>
        ))}
        <Divider
          className={styles.divider}
          type={'horizontal'}
        />
      </Row>
    </ScopeContext.Provider>
  );
};

export default observer(WidgetActionContainer);
