import { observable, makeObservable, computed } from 'mobx';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import { Observer } from 'mobx-react-lite';
import { Dayjs } from 'dayjs';
import { BaseModel } from './base';
import i18n from '../i18n/i18n';
import { transformation } from '../utils/transformations';
import { dateFormat } from '../config/dayjs';
import { DisplayablePropertyParams, displayableProperty } from './displayableProperty';
import { displayablePropertyParam } from './displayablePropertyParam';
import { Material } from './material';
import { sortAlphabetically, sortChronologically } from '../components/shared/tables/sorters';
import { CustomPropertiesObject } from './customProperty';
import { RootStore } from '../stores/rootStore';
import UserFullName from '../components/shared/UserFullName';
import { CustomPropertyTypes } from './customPropertyDataTypes';

/*
 * Translations:
 * t('batch.model.status.FREE')
 * t('batch.model.status.LOCKED')
 */
export enum BatchStatus {
  FREE = 'FREE',
  LOCKED = 'LOCKED'
}

export class Batch extends BaseModel {
  id: number = 0;
  no: string = '';
  quantity?: number | null = undefined;
  status?: BatchStatus = undefined;
  materialId?: number | null = undefined;
  unitOfMeasureId?: number | null = undefined;
  remark?: string | null = undefined;
  expireDate?: string | null | Dayjs = undefined;
  properties?: CustomPropertiesObject = undefined;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      no: observable,
      quantity: observable,
      status: observable,
      materialId: observable,
      material: computed,
      unitOfMeasureId: observable,
      unitOfMeasure: computed,
      remark: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
      expireDate: observable,
      properties: observable,
      user: computed,
    });
  }

  get material(): any {
    return this.materialId ? this.rootStore.materialStore.getById(this.materialId) : undefined;
  }

  get unitOfMeasure(): any {
    return this.unitOfMeasureId ? this.rootStore.unitOfMeasurementStore.getById(this.unitOfMeasureId) : undefined;
  }

  get user(): any {
    return this.createdBy ? this.rootStore.userStore.getById(this.createdBy) : undefined;
  }

  static faIcon = faLayerGroup;

  searchableProperties = ['no', 'material.no', 'material.name', 'remark'];

  readonly saveableProperties = [
    'no',
    'materialId',
    'expireDate',
    'quantity',
    'unitOfMeasureId',
    'status',
    'remark',
    'properties',
  ];

  customPropertyType? = CustomPropertyTypes.Batch;

  displayableProperties: DisplayablePropertyParams[] = [
    displayableProperty({
      key: 'no',
      title: i18n.t('batch.model.attributes.no'),
      params: [displayablePropertyParam({ path: 'no' })],
      sorter: (a, b) => sortAlphabetically(a.no, b.no),
    }),
    displayableProperty({
      key: 'expireDate',
      title: i18n.t('batch.model.attributes.expireDate'),
      params: [displayablePropertyParam({
        path: 'expireDate', transform: transformation.datetime({ format: dateFormat }),
      })],
      sorter: (a, b) => sortChronologically(a.expireDate, b.expireDate),
      renderText: (text, record) => record?.expireDate || '',
    }),
    displayableProperty({
      title: i18n.t('batch.model.attributes.createdBy'),
      params: [displayablePropertyParam({
        path: 'user',
        transform: (user) => (
          <Observer>
            {() => <UserFullName user={user}/>}
          </Observer>
        ),
      })],
      raw: true,
      template: '{value}',
      key: 'createdBy',
      sorter: (a: Batch, b: Batch) => sortAlphabetically(a.no, b.no),
      renderText: (text, record: Batch) => record.user?.fullName || '',
    }),
    displayableProperty({
      title: i18n.t('batch.model.attributes.remark'),
      params: [displayablePropertyParam({ path: 'remark' })],
      key: 'remark',
      sorter: (a, b) => sortAlphabetically(a.remark, b.remark),
    }),
    displayableProperty({
      title: i18n.t('batch.model.attributes.status'),
      params: [displayablePropertyParam({
        path: 'status',
        transform: (status) => i18n.t(`batch.model.status.${status}`),
      })],
      raw: true,
      template: '{value}',
      key: 'status',
      sorter: (a: Batch, b: Batch) => sortAlphabetically(a.status, b.status),
      renderText: (text, record: Batch) => i18n.t(`batch.model.status.${record.status}`),
    }),
  ];

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);

    allDisplayableProperties.push(
      ...Material.allDisplayableProperties(rootStore, `${pathPrefix}material.`, titlePrefix),
      ...this.displayableCustomProperties(rootStore, pathPrefix, titlePrefix)
    );

    return allDisplayableProperties;
  }

  static prepareApiPayload(model: Partial<Batch>): Partial<Batch> {
    return {
      id: model.id,
      no: model.no,
      materialId: model.materialId,
      expireDate: model.expireDate,
      quantity: model.quantity,
      unitOfMeasureId: model.unitOfMeasureId,
      status: model.status,
      remark: model.remark,
      properties: model.properties,
    };
  }
}
