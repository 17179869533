import React, { MouseEvent } from 'react';
import { Descriptions as AntdDescriptions, DescriptionsProps as AntdDescriptionsProps } from 'antd';
import { DescriptionsItemProps as AntdDescriptionsItemProps } from 'antd/es/descriptions/Item';
import styles from './Descriptions.module.scss';

export type DescriptionsProps = AntdDescriptionsProps & {
  id?: string;
  onClick?: (e: MouseEvent) => void;
};

export type DescriptionsItemProps = AntdDescriptionsItemProps;

const DescriptionsItem: (props: DescriptionsItemProps) => React.ReactNode = ({ children, ...props }) => (
  <AntdDescriptions.Item {...props}>
    {children}
  </AntdDescriptions.Item>
);

const Descriptions: ((props: DescriptionsProps) => React.ReactNode) & {
  Item: typeof DescriptionsItem,
} = ({ children, className = '', ...props }) => (
  <AntdDescriptions
    {...props}
    className={`${styles.descriptions} ${className}`}
  >
    {children}
  </AntdDescriptions>
);

Descriptions.Item = DescriptionsItem;

export default Descriptions;
