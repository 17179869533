import { Spin, SpinProps } from 'antd';
import React from 'react';

export type SpinnerProps = SpinProps & {
  fullWidth?: boolean;
  fullHeight?: boolean;
  backdrop?: boolean;
};

const Spinner: React.FC<SpinnerProps> = ({
  fullWidth = false,
  fullHeight = false,
  style = {},
  backdrop = false,
  ...props
}) => {
  style = {
    ...style,
    display: 'flex',
  };

  if (fullWidth) {
    style = {
      ...style,
      width: '100%',
      justifyContent: 'center',
    };
  }

  if (fullHeight) {
    style = {
      ...style,
      height: '100%',
      alignItems: 'center',
    };
  }

  if (backdrop) {
    style = {
      ...style,
      backgroundColor: 'rgba(255, 255, 255, 0.85)',
      zIndex: 1000,
    };
  }

  return (
    <div style={style}>
      <Spin {...props}/>
    </div>
  );
};

export default Spinner;
