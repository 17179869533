import React, { useState } from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { PrintJob } from '../../../models/printJob';
import { useStore } from '../../../hooks/useStore';
import { useMount } from '../../../hooks/useMount';
import styles from './LabelPreview.module.scss';
import Descriptions from '../../shared/descriptions/Descriptions';
import Label from '../../shared/dataDisplay/Label';

export type PrintJobPreviewTabProps = {
  job: PrintJob;
  currentTab: string;
};

const PrintJobPreviewTab: React.FC<PrintJobPreviewTabProps> = ({
  job,
  currentTab,
}) => {
  const store = useStore();
  const { t } = useTranslation();
  const [printData, setPrintData] = useState();
  const { document } = job;

  useMount(() => {
    store.printJobStore.getPrintData(job.id)
      .then((res) => {
        setPrintData(res.data);
      });
  }, [currentTab]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={styles.labelPreview}>
        {
          // @ts-ignore
          document?.src
            ? (
              <img
                style={{ objectFit: 'scale-down', marginBottom: '10px' }}
                // @ts-ignore
                src={document.src}
                // @ts-ignore
                alt={document.label}
              />
            )
            : <Empty/>
        }
        <div>
          <Descriptions title={t('printJob.view.tabs.printData')} column={0} bordered style={{ whiteSpace: 'pre' }}>
            {printData ? Object.keys(printData).map((key) => (
              <Descriptions.Item
                key={'key'}
                label={<Label>{key}</Label>}
              >
                {printData[key]}
              </Descriptions.Item>
            )) : []}
          </Descriptions>
        </div>
      </div>
    </div>
  );
};

export default PrintJobPreviewTab;
