import { observable, makeObservable } from 'mobx';
import { faScroll } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { superUserPermission } from './permission';
import { RootStore } from '../stores/rootStore';

export class Role extends BaseModel {
  id: number = 0;
  name: string = '';
  defaultExpireDays: number | null = null;
  defaultAlertBeforeExpireDays: number | null = null;
  permissionIdentifiers: string[] = [];
  hierarchyIds: number[] = [];
  isInternal: boolean = false;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      defaultExpireDays: observable,
      defaultAlertBeforeExpireDays: observable,
      permissionIdentifiers: observable,
      hierarchyIds: observable,
      isInternal: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faScroll;

  searchableProperties = ['name'];

  get isSuperUserRole() {
    return this.permissionIdentifiers?.includes(superUserPermission);
  }
}
