import { observable, makeObservable } from 'mobx';
import dayjs from 'dayjs';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { PersonnelLog } from '../models/personnelLog';
import { sortChronologically } from '../components/shared/tables/sorters';
import { RootStore } from './rootStore';

export class PersonnelLogStore extends EntityStore<PersonnelLog> {
  personnelLogs: PersonnelLog[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'personnelLogs', Api.personnelLogs, PersonnelLog);

    makeObservable(this, {
      personnelLogs: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.userStore,
        modelId: 'createdBy',
      },
    ];
  }

  getByWorkplaceId(workplaceId: number) {
    return this.personnelLogs.filter((log) => log.workplaceId === workplaceId);
  }

  getLatestByWorkplaceId(workplaceId: number) {
    const logs = this.getByWorkplaceId(workplaceId)
      .sort((a, b) => sortChronologically(a.createdAt, b.createdAt));
    return logs.length > 0 ? logs[logs.length - 1] : null;
  }

  getNextByTimestamp(timestamp: string) {
    return this.personnelLogs
      .sort((a, b) => sortChronologically(dayjs(a.createdAt).toDate(), dayjs(b.createdAt).toDate()))
      .find((log) => dayjs(log.createdAt).valueOf() > dayjs(timestamp).valueOf());
  }
}
