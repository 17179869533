import i18n from '../../i18n/i18n';
import {BaseWidgetConfig} from '../shared/widgets/baseWidgetConfig';
import {ValidationRules} from '../../utils/validationRules';
import {OperationStateLog} from '../../models/operationStateLog';
import {getAllI18nTranslations} from '../../utils/translations';
import {FieldType} from '../../types/widgetConfig';

export class UnclassifiedInterruptionsWidgetConfig extends BaseWidgetConfig {
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'settings',
        label: i18n.t('widget.WidgetSettingsModal.settingConfigTabTitle'),
      },
      {
        key: 'data',
        label: i18n.t('widget.WidgetSettingsModal.dataConfigTabTitle'),
      },
    ];
  }

  settings = [
    {
      id: 'title',
      label: i18n.t('widget.settings.title.label'),
      helpText: i18n.t('widget.settings.title.help'),
      type: FieldType.Translatable,
      defaultValue: getAllI18nTranslations('unclassifiedInterruptions.title'),
      validations: [ValidationRules.required()],
    },
    {
      id: 'loadMaxDays',
      label: i18n.t('unclassifiedInterruptionsWidget.settings.loadMaxDays.label'),
      helpText: i18n.t('unclassifiedInterruptionsWidget.settings.loadMaxDays.help'),
      type: FieldType.Number,
      unit: i18n.t('unclassifiedInterruptionsWidget.settings.loadMaxDays.unit'),
      defaultValue: 30,
      validations: [ValidationRules.required(), ValidationRules.positiveNumber],
    },
    {
      id: 'loadMinDuration',
      label: i18n.t('unclassifiedInterruptionsWidget.settings.loadMinDuration.label'),
      helpText: i18n.t('unclassifiedInterruptionsWidget.settings.loadMinDuration.help'),
      type: FieldType.Number,
      unit: i18n.t('unclassifiedInterruptionsWidget.settings.loadMinDuration.unit'),
      defaultValue: 0,
      validations: [ValidationRules.required(), ValidationRules.greaterThanOrEqualTo(0), ValidationRules.number],
    },
    {
      id: 'scopeSources',
      label: i18n.t('unclassifiedInterruptionsWidget.settings.scopeSources.label'),
      helpText: i18n.t('unclassifiedInterruptionsWidget.settings.scopeSources.help'),
      type: FieldType.String,
      defaultValue: 0,
      validations: [
        ValidationRules.required(),
        ValidationRules.number,
        ValidationRules.minNumber(0),
        ValidationRules.maxNumber(64),
      ],
    },
    {
      id: 'selectSourceLeavesOnly',
      label: i18n.t('unclassifiedInterruptionsWidget.settings.selectSourceLeavesOnly.label'),
      helpText: i18n.t('unclassifiedInterruptionsWidget.settings.selectSourceLeavesOnly.help'),
      type: FieldType.Boolean,
      defaultValue: false,
      validations: [ValidationRules.required()],
    },
    {
      id: 'sortOrder',
      label: i18n.t('unclassifiedInterruptionsWidget.settings.sortOrder.label'),
      helpText: i18n.t('unclassifiedInterruptionsWidget.settings.sortOrder.help'),
      type: FieldType.Select,
      options: ['asc', 'desc'].map((value) => ({
        value,
        label: i18n.t(`unclassifiedInterruptionsWidget.settings.sortOrder.options.${value}.label`),
        helpText: i18n.t(`unclassifiedInterruptionsWidget.settings.sortOrder.options.${value}.help`),
      })),
      defaultValue: 'asc',
      validations: [ValidationRules.required()],
    },
    {
      id: 'pagination.entries',
      label: i18n.t('unclassifiedInterruptionsWidget.settings.entries.label'),
      helpText: i18n.t('unclassifiedInterruptionsWidget.settings.entries.help'),
      type: FieldType.Select,
      defaultValue: 10,
      options: [5, 10, 15, 20, 25].map((value) => ({
        value,
        label: i18n.t(`unclassifiedInterruptionsWidget.settings.entries.options.${value}.label`),
        helpText: i18n.t(`unclassifiedInterruptionsWidget.settings.entries.options.${value}.help`),
      })),
      validations: [ValidationRules.required(), ValidationRules.positiveNumber],
    },
    {
      id: 'pagination.showTotal',
      label: i18n.t('unclassifiedInterruptionsWidget.settings.showTotal.label'),
      helpText: i18n.t('unclassifiedInterruptionsWidget.settings.showTotal.help'),
      type: FieldType.Boolean,
      defaultValue: true,
      validations: [ValidationRules.required()],
    },
    {
      id: 'allowMultipleMutation',
      label: i18n.t('unclassifiedInterruptionsWidget.settings.allowMultipleMutation.label'),
      helpText: i18n.t('unclassifiedInterruptionsWidget.settings.allowMultipleMutation.help'),
      type: FieldType.Boolean,
      defaultValue: true,
      validations: [ValidationRules.required()],
    },
  ];

  data = [
    {
      id: 'properties',
      type: FieldType.PropertiesSelectDisplayField,
      modelOptions: OperationStateLog.allDisplayableProperties(this.rootStore),
      defaultValue: [
        'start', 'durationSeconds', 'active',
      ],
    },
  ];

  getTableColumns() {
    return this.getSelectedProperties('data', 'properties');
  }

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'title');
  }
}
