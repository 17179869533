import { makeObservable, observable } from 'mobx';
import { faTools } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { makeFormFields } from '../components/shared/form/formFields';
import { RootStore } from '../stores/rootStore';

export class AppConfig extends BaseModel {
  id = 1;
  modules = null;
  language = null;
  languageOptions = null;
  locale = null;
  logoutAfterInactivity = null;
  corporateIdentity = null;
  enableExperimental = null;
  mqtt = null;
  stateColors = null;
  qualitativeStateColors = null;
  quantitativeStateColors = null;
  speedDenominator = null;
  hierarchy = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      modules: observable,
      language: observable,
      languageOptions: observable,
      locale: observable,
      logoutAfterInactivity: observable,
      corporateIdentity: observable,
      enableExperimental: observable,
      mqtt: observable,
      stateColors: observable,
      qualitativeStateColors: observable,
      quantitativeStateColors: observable,
      speedDenominator: observable,
      hierarchy: observable,
    });
  }

  static faIcon = faTools;

  nestedModels = [
    'modules',
    'locale',
    'corporateIdentity',
    'mqtt',
    'stateColors',
    'qualitativeStateColors',
    'quantitativeStateColors',
    'hierarchy',
  ];

  static prepareApiPayload(model: AppConfig): Partial<AppConfig> {
    return {
      modules: model.modules,
      language: model.language,
      languageOptions: model.languageOptions,
      locale: model.locale,
      logoutAfterInactivity: model.logoutAfterInactivity,
      enableExperimental: model.enableExperimental,
      corporateIdentity: model.corporateIdentity,
      mqtt: model.mqtt,
      stateColors: model.stateColors,
      qualitativeStateColors: model.qualitativeStateColors,
      quantitativeStateColors: model.quantitativeStateColors,
      speedDenominator: model.speedDenominator,
      hierarchy: model.hierarchy,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  transformHierarchyLevel(
    obj: any,
    key: any,
    subKey: any,
    formFields: any
  ) {
    formFields.push({
      name: [key, subKey],
      value: obj[subKey],
    });
  }
  getFormFields() {
    return [
      ...makeFormFields(this, {}, {
        nestedValueTransformers: {
          // @ts-ignore
          hierarchy: this.transformHierarchyLevel,
        },
      }),
    ];
  }
}

/**
 * start of week day translation
 * t('appConfig.model.locale.startOfWeekDay.0')
 * t('appConfig.model.locale.startOfWeekDay.1')
 */
export const StartOfWeekDay = Object.freeze({ Sunday: 0, Monday: 1 });
