import { action, observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { CockpitWidget } from '../models/cockpitWidget';
import { CockpitDashboard } from '../models/cockpitDashboard';
import i18n from '../i18n/i18n';
import { RootStore } from './rootStore';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';

export class CockpitWidgetStore extends EntityStore<CockpitWidget> {
  cockpitWidgets: CockpitWidget[] = [];
  currentlyEditedConfig = null;

  constructor(rootStore: RootStore) {
    super(rootStore, 'cockpitWidgets', Api.cockpitWidgets, CockpitWidget, true);

    makeObservable(this, {
      cockpitWidgets: observable,
      currentlyEditedConfig: observable,
      loadByDashboardId: action,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.cockpitDashboardStore,
        modelId: 'dashboardId',
      },
    ];
  }

  loadByDashboardId(dashboardId: number) {
    return this.loadAll({ params: { dashboardId } });
  }

  getByDashboardId(id: number) {
    return this.cockpitWidgets.filter((widget) => widget.dashboardId === id);
  }

  createOrUpdateWidgetConfig(widgetId: number, widgetConfig: any) {
    const { name, ...config } = widgetConfig;

    if (name) {
      return this.update({
        id: widgetId,
        name,
        config: JSON.stringify(config),
      });
    }

    return this.update({
      id: widgetId,
      config: JSON.stringify(config),
    });
  }

  getWorkplaceCockpitWidgetsWithoutHierarchyLink() {
    return this.cockpitWidgets.filter((cockpitWidget) => {
      if (!cockpitWidget.dashboard?.cockpit?.isWorkplaceCockpit) {
        return false;
      }
      const config = JSON.parse(cockpitWidget.config);
      if (config.hierarchyId === undefined) {
        return true;
      }
      return config.hierarchyId === null;
    });
  }

  async clone(widgetId: number, params: { targetDashboardId: number }) {
    const response = await this.api.clone(widgetId, params);
    this.cockpitWidgets.push(CockpitDashboard.fromPlainObject(response.data, this.rootStore));
    this.rootStore.flashMessageStore.addFlashMessage(new FlashMessage(
      FlashMessageType.SUCCESS,
      i18n.t('flashMessages.createSuccess')
    ));
  }
}
