import { chunk, cloneDeep, compact, get, isMatch, uniq } from 'lodash';
import { action, autorun, computed, makeObservable, observable, runInAction } from 'mobx';
import { AxiosResponse } from 'axios';
import { ApiError } from '../models/apiError';
import i18n from '../i18n/i18n';
import { RootStore } from './rootStore';
import { BaseModel, Blamable, ReplaceOptions } from '../models/base';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';

const CHUNK_SIZE = 256;

export enum LoadStrategies {
  replace = 'replace',
  add = 'add'
}

export enum Actions {
  load = 'load',
  /** Calls the method "all" of the given object. As example {@link UsersApi.all} */
  loadAll = 'all',
  delete = 'delete',
  archive = 'archive',
  restore = 'restore',
  create = 'create',
  clone = 'clone',
  update = 'update',
  byId = 'byId'
}

export interface Params {
  [key: string]: any;
}

export interface AddMessageOptions {
  skipNotification?: boolean;
  successMessage?: FlashMessage;
}

export interface LoadOptions extends AddMessageOptions {
  params?: Params,
  onlyIfEmpty?: boolean;
  action?: Actions | string;
  raw?: boolean;
  api?: any;
  apiEndpoint?: any,
  skipCache?: boolean,

  [key: string]: any;
}

export interface PendingAction {
  action: Actions | string;
  apiEndpoint: any;
  params: Params;
  [key: string]: any;
}

type ReplaceEntity = { idx: number, entity: BaseModel };

export type ModelDependency = { store: EntityStore<any, any>, modelId: string };

const CACHE_LIFE = 3500; // Time in ms until a model can be reloaded

export class EntityStore<T extends BaseModel = BaseModel, IdentifierType = number> {
  pendingActions: PendingAction[] = [];
  identityField: string = 'id';
  supportsMultiIdLoad: boolean = false;

  cache: Map<IdentifierType, number> = new Map<IdentifierType, number>();

  rootStore: RootStore;
  listName: string;
  api: any; // TODO: fix api type
  modelClass: typeof BaseModel;

  multiComparator: (ids: any[]) => (e: any) => boolean;
  comparator: (id: any) => (e: any) => boolean;

  constructor(
    rootStore: RootStore,
    listName: string,
    api: any,
    modelClass: typeof BaseModel,
    supportsMultiIdLoad = false
  ) {
    makeObservable(this, {
      add: action,
      addAll: action,
      create: action,
      clone: action,
      load: action,
      loadAll: action,
      loadMany: action,
      remove: action,
      delete: action,
      archive: action,
      restore: action,
      replace: action,
      _replaceAll: action,
      clear: action,
      update: action,
      pendingActions: observable,
      hasPendingRequests: computed,
      areCollectionOrDependenciesLoading: computed,
      isEmpty: computed,
      isLoadingAndEmpty: computed,
      isLoadingCollection: computed,
      isMutationInProgress: computed,
      getDifferenceByIds: observable,
      loadDependencies: action,
      loadWithDependencies: action,
      loadAllWithDependencies: action,
      loadManyWithDependencies: action,
      addPendingAction: action,
      removePendingAction: action,
      createModelInstance: action,
      addMessage: action,
      loadManyBySingleLoad: action,
      loadChunk: action,
    });

    this.rootStore = rootStore;
    this.listName = listName;
    this.api = api;
    this.modelClass = modelClass;
    this.supportsMultiIdLoad = supportsMultiIdLoad;
    this.multiComparator = (ids) => (e) => ids
      .map((id) => id.toString())
      .indexOf(e[this.identityField].toString()) >= 0;
    this.comparator = (id) => this.multiComparator([id]);

    autorun(() => {
      // FIXME This is a workaround to force observer-observable relationship over model dependencies.
      //  The current implementation only looks at defined dependencies and does not rely on actual relations defined
      //  in populateAttributesFromStore.
      this.getDependencies().map((dep) => dep.store?.listName && (dep.store as any)[dep.store?.listName]?.length);
      // automatically set model relations from other stores,
      // runs whenever related/dependent store lists change
      // @ts-ignore
      if (!this[this.listName]) {
        return;
      }
      // @ts-ignore
      this[this.listName].forEach((e) => e.populateAttributesFromStore(this.rootStore));
    });
  }

  prepareApiPayload(model: Partial<Omit<T, Blamable>>): Partial<Omit<T, Blamable>> {
    if (typeof this.modelClass.prepareApiPayload === 'function') {
      return this.modelClass.prepareApiPayload(model) as Partial<Omit<T, Blamable>>;
    }
    return model;
  }

  load(id: IdentifierType | null, paramOptions: LoadOptions = {}) {
    const defaultOptions: LoadOptions = {
      params: {},
      onlyIfEmpty: false,
      action: Actions.load,
      raw: false,
      api: this.api,
      apiEndpoint: Actions.byId,
      skipCache: false,
    };
    const options = Object.assign(defaultOptions, paramOptions, { [this.identityField]: id });

    const cacheHit = options.skipCache || options.raw || paramOptions.api || paramOptions.apiEndpoint
      ? undefined
      : this.getCache(id);
    if (cacheHit) {
      return Promise.resolve(cacheHit);
    }

    if ((id !== null && this.isLoading(id)) || (options.onlyIfEmpty && id !== null && this.getById(id))) {
      return Promise.resolve(this.getById(id));
    }
    this.addPendingAction(options.action || Actions.load, options);

    return options.api[options.apiEndpoint](id, options).then(({ data, ...rest }: any) => {
      runInAction(() => this.removePendingAction(options.action || Actions.load, options));
      if (options.raw) {
        return { data, ...rest };
      }

      if (rest.status === 204) {
        return undefined;
      }
      const entity = this.createModelInstance(data);
      const [added] = this.addAll([entity], { ...options, replaceOptions: { preserveReduced: true } });
      if (id !== null) {
        this.cache.set(id, Date.now());
      }
      return added;
    }).catch((e: Error) => this.handleApiError(e, options.action || Actions.load, options));
  }

  loadManyBySingleLoad(ids: IdentifierType[], paramOptions = {}) {
    const defaultOptions = { onlyIfEmpty: false };
    const options = Object.assign(defaultOptions, paramOptions);

    if (Array.isArray(ids)) {
      // eslint-disable-next-line no-param-reassign
      ids = compact(uniq(ids));
    }
    return Promise.all((ids || []).map((id: IdentifierType) => this.load(id, options)));
  }

  async loadChunk(idsToLoad: IdentifierType[], paramOptions: LoadOptions, options: LoadOptions) {
    const actionOptions = cloneDeep(options);
    const cached: T[] = [];
    idsToLoad.forEach((id) => {
      const cacheHit = options.skipCache || options.raw || paramOptions.api || paramOptions.apiEndpoint
        ? undefined
        : this.getCache(id);
      if (cacheHit) {
        cached.push(cacheHit);
      } else {
        this.addPendingAction(Actions.load, {
          ...actionOptions,
          onlyIfEmpty: false,
          [this.identityField]: id,
        });
      }
    });
    const params = Object.assign(paramOptions.params || {}, { id: idsToLoad });
    const result = await this.loadAll({ params });
    idsToLoad.forEach((id: IdentifierType) => {
      this.removePendingAction(Actions.load, {
        ...actionOptions,
        onlyIfEmpty: false,
        [this.identityField]: id,
      });
      this.cache.set(id, Date.now());
    });
    return cached.concat(result);
  }

  async loadMany(requestedIds: IdentifierType[], paramOptions = {}): Promise<T[]> {
    if (!this.supportsMultiIdLoad) {
      return this.loadManyBySingleLoad(requestedIds, paramOptions);
    }

    let ids = requestedIds;
    const defaultOptions: LoadOptions = {
      params: {},
      onlyIfEmpty: false,
      action: Actions.load,
      raw: false,
      api: this.api,
      apiEndpoint: Actions.byId,
      skipCache: false,
    };
    const options = Object.assign(defaultOptions, paramOptions);

    if (Array.isArray(ids)) {
      ids = compact(uniq(ids));
    }

    const idsToLoad = ids.filter((id: IdentifierType) => !this.isLoading(id));

    if (idsToLoad.length === 1) {
      return [await this.load(idsToLoad[0])];
    }

    const result = [];

    if (idsToLoad.length > 0) {
      const chunks = chunk(idsToLoad, CHUNK_SIZE);
      // eslint-disable-next-line no-restricted-syntax
      for (const c of chunks) {
        // eslint-disable-next-line no-await-in-loop
        result.push(...(await this.loadChunk(c, paramOptions, options)));
      }
    }

    return Promise.resolve(result);
  }

  loadAll(paramOptions = {}): Promise<T[]> {
    const defaultOptions: LoadOptions = {
      strategy: LoadStrategies.add,
      params: {},
      onlyIfEmpty: false,
      api: this.api,
      raw: false,
      apiEndpoint: Actions.loadAll,
      mapper: this.createModelInstance.bind(this),
    };
    const options = Object.assign(defaultOptions, paramOptions);
    const pendingAction = options.apiEndpoint;

    if ((this.isActionInProgress(pendingAction, options) && !options.raw)
      || (options.onlyIfEmpty && !this.isEmpty)
    ) {
      // eslint-disable-next-line max-len,no-console
      console.warn(`Warning: This request (${pendingAction}-${this.listName}) is already running. Another request was not sent, and no result will be returned. Change your loading-order / effect-dependencies to prevent this. Params: `, JSON.stringify(options.params));
      return Promise.resolve([]);
    }

    this.addPendingAction(pendingAction, options);

    return options.api[options.apiEndpoint](options.params).then(({ data, ...rest }: any) => {
      let entities = options.raw
        ? { data, ...rest }
        : data.map(options.mapper);
      runInAction(() => {
        if (options.raw) {
          this.removePendingAction(pendingAction, options);
          return;
        }
        if (options.strategy === LoadStrategies.replace) {
          // @ts-ignore
          this[this.listName].replace(entities);
        } else if (options.strategy === LoadStrategies.add) {
          entities = this.addAll(entities, { ...options, replaceOptions: { preserveReduced: true } });
        }
        this.removePendingAction(pendingAction, options);
        const now = Date.now();
        this.entities.forEach((entity: T) => {
          // @ts-ignore
          this.cache.set((entity[this.identityField] as IdentifierType), now);
        });
      });
      return entities;
    }).catch((e: Error) => this.handleApiError(e, pendingAction, options));
  }

  loadAllRaw(paramOptions = {}): Promise<AxiosResponse> {
    const defaultOptions: LoadOptions = {
      strategy: LoadStrategies.add,
      params: {},
      onlyIfEmpty: false,
      api: this.api,
      apiEndpoint: Actions.loadAll,
      mapper: this.createModelInstance.bind(this),
    };
    const options = Object.assign(defaultOptions, paramOptions);
    const pendingAction = options.apiEndpoint;

    this.addPendingAction(pendingAction, options);

    return options.api[options.apiEndpoint](options.params).then((response: AxiosResponse) => {
      this.removePendingAction(pendingAction, options);
      return response;
    }).catch((e: Error) => this.handleApiError(e, pendingAction, options));
  }

  delete(id: IdentifierType, options: LoadOptions = {}) {
    this.addPendingAction(Actions.delete);

    return this.api.destroy(id, options.params).then((response: any) => {
      this.addMessage(
        new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.deleteSuccess')),
        options
      );
      runInAction(() => this.removePendingAction(Actions.delete));
      this.remove(id);
      return response;
    }).catch((e: Error) => this.handleApiError(e, Actions.delete));
  }

  archive(id: IdentifierType, options: LoadOptions = {}) {
    this.addPendingAction(Actions.archive);
    return this.api.archive(id, options.params).then(({ data }: any) => {
      // t('flashMessages.archiveSuccess')
      this.addMessage(
        new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.archiveSuccess')),
        options
      );
      runInAction(() => this.removePendingAction(Actions.archive));
      const model = this.createModelInstance(data);
      this.add(model);
      return model;
    }).catch((e: Error) => this.handleApiError(e, Actions.archive));
  }

  restore(id: IdentifierType, options: LoadOptions = {}) {
    this.addPendingAction(Actions.restore);
    return this.api.restore(id, options.params).then(({ data }: any) => {
      this.addMessage(
        new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.restoreSuccess')),
        options
      );
      runInAction(() => this.removePendingAction(Actions.restore));
      const model = this.createModelInstance(data);
      this.add(model);
      return model;
    }).catch((e: Error) => this.handleApiError(e, Actions.restore));
  }

  create(entity: Partial<Omit<T, Blamable>>, options: LoadOptions = {}): Promise<T> {
    const pendingAction = options.apiEndpoint || Actions.create;
    this.addPendingAction(pendingAction);
    return this.api[pendingAction](this.prepareApiPayload(entity), options?.params).then(({ data, ...rest }: any) => {
      // t('flashMessages.createSuccess')
      this.addMessage(
        new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.createSuccess')),
        options
      );
      runInAction(() => this.removePendingAction(pendingAction));
      if (options?.raw) {
        return { data, ...rest };
      }
      return this.add(this.createModelInstance(data));
    }).catch((e: Error) => this.handleApiError(e, pendingAction));
  }

  createMany(entities: T[], options: LoadOptions = {}) {
    if (options?.raw) {
      throw new Error('raw option is not allowed');
    }
    const pendingAction = options.apiEndpoint || Actions.create;
    this.addPendingAction(pendingAction);
    return this.api[pendingAction](entities.map((e) => this.prepareApiPayload(e)), options?.params)
      .then(({ data }: { data: Partial<T>[] }) => {
        if (options.skipNotification !== true) {
          this.addMessage(new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.createSuccess')), {});
        }
        runInAction(() => this.removePendingAction(pendingAction));
        return data.map((entity) => this.add(this.createModelInstance(entity)));
      }).catch((e: Error) => this.handleApiError(e, pendingAction));
  }

  clone(id: IdentifierType, options: LoadOptions = {}) {
    this.addPendingAction(Actions.clone);
    return this.api.clone(id, options.params).then(({ data, ...rest }: any) => {
      // t('flashMessages.createSuccess')
      this.addMessage(
        new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.cloneSuccess')),
        options
      );
      runInAction(() => this.removePendingAction(Actions.clone));
      if (options.raw) {
        return { data, ...rest };
      }
      return this.add(this.createModelInstance(data));
    }).catch((e: Error) => this.handleApiError(e, Actions.clone));
  }

  update(entity: Partial<Omit<T, Blamable>>, options: LoadOptions = {}) {
    const pendingAction = options.apiEndpoint || Actions.update;
    this.addPendingAction(pendingAction, options);
    return this.api[pendingAction](this.prepareApiPayload(entity), options.params).then(({ data, ...rest }: any) => {
      this.addMessage(
        new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.updateSuccess')),
        options
      );
      runInAction(() => this.removePendingAction(pendingAction, options));
      if (options.raw) {
        return { data, ...rest };
      }
      return this.replace(this.createModelInstance(data), { preserveReduced: true });
    }).catch((e: Error) => this.handleApiError(e, pendingAction));
  }

  add(entity: T, { replace = true, replaceOptions = {} } = {}): T {
    // @ts-ignore
    if (!this.getById(entity[this.identityField])) {
      // @ts-ignore
      this[this.listName].push(entity);
      return entity;
    }
    return replace && this.replace(entity, replaceOptions);
  }

  addAll(entities: T[], { replace = true, replaceOptions = {} } = {}) {
    const toAdd: T[] = [];
    const toReplace: ReplaceEntity[] = [];
    entities.forEach((entity) => {
      // @ts-ignore
      const idx = this[this.listName].findIndex(this.comparator(entity[this.identityField]));
      if (idx >= 0 && replace) {
        toReplace.push({ idx, entity });
      } else if (idx === -1) {
        toAdd.push(entity);
      }
    });
    let replaced: any[] = [];
    if (replace) {
      // eslint-disable-next-line no-underscore-dangle
      replaced = this._replaceAll(toReplace, replaceOptions);
    }
    // @ts-ignore
    this[this.listName].push(...toAdd);
    return toAdd.concat(...replaced);
  }

  getById(id: IdentifierType | null): T | undefined {
    if (id == null) {
      return undefined;
    }
    // @ts-ignore
    return this[this.listName].find(this.comparator(id));
  }

  getByIds(ids: IdentifierType[]): T[] {
    if (!ids) {
      return [];
    }
    // @ts-ignore
    return this[this.listName].filter(this.multiComparator(ids));
  }

  replace(entity: T, options: ReplaceOptions = {}): any {
    // @ts-ignore
    const oldEntity = this.getById(entity[this.identityField]);
    if (!oldEntity) {
      return this.add(entity);
    }
    return oldEntity.copyAttributesFrom(entity, options);
  }

  // eslint-disable-next-line no-underscore-dangle
  _replaceAll(entities: ReplaceEntity[], options: ReplaceOptions = {}) {
    const result: T[] = [];
    entities.forEach(({ idx, entity }) => {
      // @ts-ignore
      this[this.listName][idx].copyAttributesFrom(entity, options);
      // @ts-ignore
      result.push(this[this.listName][idx]);
    });
    return result;
  }

  remove(id: IdentifierType) {
    if (!id) {
      return;
    }
    // @ts-ignore
    const index = this[this.listName].findIndex(this.comparator(id));
    if (index >= 0) {
      // @ts-ignore
      this[this.listName].splice(index, 1);
    }
  }

  clear() {
    // @ts-ignore
    if (!this[this.listName] || typeof this[this.listName]?.clear !== 'function') {
      return;
    }
    // @ts-ignore
    this[this.listName].clear();
  }

  get hasPendingRequests() {
    return this.pendingActions.length > 0;
  }

  get isEmpty() {
    // @ts-ignore
    return this[this.listName].length === 0;
  }

  get isLoadingCollection(): boolean {
    return this.isActionInProgress(Actions.loadAll, { apiEndpoint: Actions.loadAll });
  }

  get isLoadingAndEmpty() {
    return this.isLoadingCollection && this.isEmpty;
  }

  get isMutationInProgress() {
    return this.isOneOfActionsInProgress([Actions.create, Actions.update]);
  }

  get areCollectionOrDependenciesLoading() {
    return this.recAreCollectionOrDependenciesLoading([]);
  }

  /**
   * @private
   * @param {Array} processed
   * @returns {boolean}
   */
  recAreCollectionOrDependenciesLoading(processed: any) {
    if (processed.includes(this.modelClass)) {
      return false;
    }
    const dependencies = this.getDependencies();
    const areLoading = dependencies.some((dependency) => (
      // @ts-ignore
      dependency.store.recAreCollectionOrDependenciesLoading([...processed, this.modelClass])
    ));
    return this.hasPendingRequests || areLoading;
  }

  get entities() {
    // @ts-ignore
    return this[this.listName];
  }

  isLoading(id: IdentifierType | null = null): boolean {
    return !!this.pendingActions.find(
      (pendingAction) => pendingAction.action === Actions.load
        // @ts-ignore
        && (!id || id === pendingAction[this.identityField])
    );
  }

  isOneOfActionsInProgress(actions: (Actions | string)[], params: LoadOptions = {}): boolean {
    return actions.some((a) => this.isActionInProgress(a, params));
  }

  isActionInProgress(paramAction: Actions | string, options: LoadOptions = {}): boolean {
    const index = this.getPendingActionIndex(paramAction, options);
    return index >= 0;
  }

  isResourceBusy(paramAction: Actions | string): boolean {
    return this.pendingActions.some((pendingAction) => pendingAction.action === paramAction);
  }

  handleApiError(e: any, paramAction?: Actions | string, options: LoadOptions = {}) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.warn(e, { action: paramAction });
    }

    if (e.error && e.error.messages) {
      const [errorMessage] = e.error.messages;
      // @ts-ignore
      const knownErrors = Object.keys(i18n.t('flashMessages'));
      if (knownErrors.indexOf(errorMessage) >= 0) {
        const message = new FlashMessage(FlashMessageType.ERROR, i18n.t(`flashMessages.${errorMessage}`));
        this.rootStore.flashMessageStore.addFlashMessage(message);
      }
    } else if (e.name && e.name === 'E_BAD_REQUEST') {
      this.rootStore.flashMessageStore
        .addFlashMessage(new FlashMessage(FlashMessageType.ERROR, e.message));
    }

    if (paramAction) {
      runInAction(() => this.removePendingAction(paramAction, options));
    }

    return Promise.reject(ApiError.fromPlainObject(e));
  }

  addMessage(message: FlashMessage, { skipNotification, successMessage }: AddMessageOptions) {
    if (!skipNotification) {
      this.rootStore.flashMessageStore.addFlashMessage(successMessage || message);
    }
  }

  createModelInstance(json: any): T {
    return this.modelClass.fromPlainObject<T>(json, this.rootStore);
  }

  getPendingActionReference(paramAction: Actions | string, options: LoadOptions = {}) {
    return {
      action: paramAction,
      apiEndpoint: options?.apiEndpoint,
      // @ts-ignore
      [this.identityField]: options?.[this.identityField],
      params: options?.params || {},
    };
  }

  addPendingAction(paramAction: Actions | string, options: LoadOptions = {}) {
    this.removePendingAction(paramAction, options);
    this.pendingActions.push(this.getPendingActionReference(paramAction, options));
  }

  removePendingAction(paramAction: Actions | string, options: LoadOptions = {}) {
    const index = this.getPendingActionIndex(paramAction, options);
    if (index >= 0) {
      this.pendingActions.splice(index, 1);
    }
  }

  getPendingActionIndex(paramAction: Actions | string, options: LoadOptions = {}) {
    const reference = this.getPendingActionReference(paramAction, options);
    return this.pendingActions.findIndex((pendingAction) => isMatch(reference, pendingAction));
  }

  getDifferenceByIds(ids = []) {
    // @ts-ignore
    const sameId = (entity: T) => (id: IdentifierType) => id === entity[this.identityField];
    const notInIds = (entity: T) => ids.findIndex(sameId(entity)) === -1;
    // @ts-ignore
    return this[this.listName].filter(notInIds);
  }

  // eslint-disable-next-line class-methods-use-this
  getDependencies(): ModelDependency[] {
    return [];
  }

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  loadDependencies(dependencies: any[], promise: any): Promise<T[]> {
    return promise.then(async (results: any) => {
      if (!Array.isArray(results)) {
        results = [results];
      }
      if (results) {
        const dependencyPromises: any[] = [];
        dependencies.forEach((dependency) => {
          const ids = results
            .filter((op: any) => op !== null && op !== undefined && op !== '')
            .map((op: any) => get(op, dependency.modelId))
            .flat(1);
          if (ids.length) {
            dependencyPromises.push(dependency.store.loadManyWithDependencies(
              ids,
              dependency.store.getDependencies(),
              { params: dependency.params || {} }
            ));
          }
        });
        await Promise.all(dependencyPromises);

        results.forEach((result: any) => {
          if (result?.populateAttributesFromStore) {
            result.populateAttributesFromStore(this.rootStore);
          }
        });
      }

      return results;
    });
  }

  loadWithDependencies(
    id: IdentifierType,
    paramOptions: LoadOptions,
    dependencies = this.getDependencies()
  ): Promise<any> {
    return this.loadDependencies(dependencies, this.load(id, paramOptions)).then(([res]: any) => res);
  }

  loadAllWithDependencies({
    dependencies = this.getDependencies(),
    ...paramOptions
  }): Promise<T[]> {
    return this.loadDependencies(dependencies, this.loadAll(paramOptions));
  }

  loadManyWithDependencies(
    ids: IdentifierType[],
    dependencies = this.getDependencies(),
    paramOptions = {}
  ) {
    return this.loadDependencies(dependencies, this.loadMany(ids, paramOptions));
  }

  private getCache(id: IdentifierType | null): T | undefined {
    const cacheHit = id !== null ? this.cache.get(id) : undefined;
    if (cacheHit && cacheHit + CACHE_LIFE > Date.now()) {
      const storeResult = this.getById(id);
      if (storeResult) {
        return storeResult;
      }
    }
    return undefined;
  }
}
