import dayjs from 'dayjs';
import i18n from '../../i18n/i18n';
import setupSeriesAxisLabel from '../shared/charts/SeriesAxisLabel';
import setupPointerPositionEvents from '../shared/charts/PointerPositionEvents';
import {interpolate} from '../shared/charts/highchartHelpers/data';

export const PersonnelPlotConfig = (setDates) => {
  const axisLabels = [];

  const hideAxisLabels = () => {
    axisLabels.forEach((label) => {
      label.hide();
    });
  };

  const showAxisLabels = (chart, pointerPosition, plotPosition) => {
    chart.crosshairLabels = chart.crosshairLabels ? chart.crosshairLabels : {};
    axisLabels.forEach((label) => {
      const {prev, next, ...current} = interpolate(label.series.data, plotPosition.x, 'step');
      label.show({
        x: 0,
        y: chart.yAxis[0].toPixels(current.y),
        text: current.y || current.y === 0 ? `${current.y}` : null,
        color: '#FFFFFF',
        fill: prev?.color || label.series.color,
        anchorX: 100,
      });
    });
  };

  return {
    title: false,
    chart: {
      zoomType: 'x',
      events: {
        selection(event) {
          setDates({
            fromDate: dayjs(event.xAxis[0].min),
            toDate: dayjs(event.xAxis[0].max),
          });
          return false;
        },
        load() {
          const chart = this;
          chart.series.forEach((s) => {
            axisLabels.push(setupSeriesAxisLabel(s));
          });
          setupPointerPositionEvents(chart, showAxisLabels, hideAxisLabels);
        },
      },
    },
    plotOptions: {
      series: {
        stickyTracking: false,
        step: true,
      },
    },
    xAxis: {
      crosshair: {
        snap: false,
      },
      title: {
        text: i18n.t('personnel.chart.xAxis'),
      },
    },
    yAxis: {
      title: {
        text: i18n.t('personnel.chart.yAxis'),
      },
      min: 0,
    },
  };
};
