import React, {useMemo, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import {useMeasure} from 'react-use';
import {isEqual} from 'lodash';
import {useTranslation} from 'react-i18next';
import CockpitWidget from './CockpitWidget';
import {WidgetLayout} from '../../../../models/widgetLayout';
import {WidgetLayouts} from '../../../shared/widgets/WidgetLayouts';
import {multipleTimeseriesCockpitWidgetConfig} from './config/multipleTimeseriesCockpitWidgetConfig';
import {useMount} from '../../../../hooks/useMount';
import {useStore} from '../../../../hooks/useStore';
import {EnDash} from '../../../shared/unicodeWrapper/EnDash';
import {datetimeFormat} from '../../../../config/dayjs';
import Spinner from '../../../shared/spinners/Spinner';
import useWorkplaceRelatedWidgetTitle from '../../../../hooks/useWorkplaceRelatedWidgetTitle';
import LineChart from '../../../shared/charts/LineChart';

const MultipleTimeseriesCockpitWidget = ({
  widgetId,
  title,
  ...props
}) => {
  const {t} = useTranslation();
  const [ref, bounds] = useMeasure();
  const store = useStore();
  const widgetConfig = useMemo(() => new MultipleTimeseriesCockpitWidget.Config({
    rootStore: store,
    identifier: MultipleTimeseriesCockpitWidget.Config.widgetType.identifier,
    widgetId,
  }), []);
  const options = useRef({});
  const [sensorData, setSensorData] = useState([]);
  const [sensorDataTargets, setSensorDataTargets] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWorkplaceId, setSelectedWorkplaceId] = useState(null);
  const widgetTitle = useWorkplaceRelatedWidgetTitle(title, selectedWorkplaceId);

  useMount(() => {
    if (store.contextStore.isOperatorContext) {
      store.sensorStore.loadAll();
    }
  });

  useMount(() => {
    setSelectedWorkplaceId(
      store.workplaceStore.getByHierarchyId(widgetConfig.getSettingValue('workplace', 'hierarchyId'))?.id
      || store.cockpitStore.currentWorkplaceId || undefined
    );
  }, [
    store.cockpitStore.currentWorkplaceId,
    widgetConfig.getSettingValue('workplace', 'hierarchyId'),
  ]);

  const formatSensorLabel = (sensor, resolution) => {
    if (resolution) {
      return `${sensor.label || sensor.name} (${t('multipleTimeseriesCockpitWidgetConfig.resolution', {resolution})})`;
    }
    return sensor.label || sensor.name;
  };

  const setOptions = (opt) => {
    options.current = {
      ...options.current,
      ...opt,
    };
    setChartOptions(options.current);
  };

  useMount(() => {
    const sensors = widgetConfig.getSensors();
    const showTarget = widgetConfig.getShowTargets();
    const res = widgetConfig.getResolution();
    const series = [];
    let showDefaultAxis = false;
    const axis = new Map();
    const yAxis = [{
      title: {text: `[${EnDash()}]`},
      showEmpty: false,
    }];

    sensors.forEach((sensorName) => {
      const uom = store.sensorStore.getById(sensorName)?.unitOfMeasure;
      if (uom && !axis.has(uom?.id)) {
        const index = yAxis.push({
          title: {text: uom ? `${uom.label || uom.name}` : `${EnDash()}`},
          showEmpty: false,
        });
        axis.set(uom?.id, index - 1);
      }
    });
    sensorData.forEach((result, index) => {
      const sensor = store.sensorStore.getById(sensors[index]);
      const uom = sensor?.unitOfMeasure;
      const seriesData = result
        .map((d) => ([dayjs(d.timestamp).valueOf(), d.value]))
        .sort((a, b) => a[0] - b[0]);

      if (!uom) {
        showDefaultAxis = true;
      }
      seriesData.push([dayjs().add(1, 'year').valueOf(), 0]);

      series.push(
        {
          name: showTarget
            ? `${formatSensorLabel(sensor, res)} - ${t('multipleTimeseriesCockpitWidgetConfig.isSuffix')}`
            : formatSensorLabel(sensor, res),
          data: seriesData,
          yAxis: uom ? axis.get(uom?.id) : 0,
        }
      );

      if (showTarget) {
        const targetResult = sensorDataTargets[index];
        if (targetResult?.length) {
          const targetSeriesData = targetResult.map((d) => ([dayjs(d.timestamp).valueOf(), d.value]));
          targetSeriesData.push([dayjs().add(1, 'year').valueOf(), 0]);
          series.push(
            {
              name: `${formatSensorLabel(sensor, res)} - ${t('multipleTimeseriesCockpitWidgetConfig.shouldSuffix')}`,
              data: targetSeriesData,
              yAxis: uom ? axis.get(uom?.id) : 0,
            }
          );
        }
      }
    });
    yAxis[0].visible = showDefaultAxis;

    setOptions({
      yAxis,
      series,
      plotOptions: {
        series: {
          connectNulls: !!widgetConfig.getResolution(),
        },
      },
    });
  }, [
    store.sensorStore.sensors.length,
    store.unitOfMeasurementStore.unitsOfMeasurement.length,
    sensorData,
    sensorDataTargets,
  ]);

  const loadSensorData = async (sensors, from, to, showTarget, res, clampToNow) => {
    if (!isEqual(options.current?.series?.map((sensor) => sensor.name), sensors)
      || !from.isSame(options.current.xAxisMin, 'minutes')
      || !to.isSame(options.current.xAxisMax, 'minutes')
      || options.current.workplaceId !== selectedWorkplaceId
    ) {
      setIsLoading(true);
      const sensorDataPromises = [];
      const sensorDataTargetPromises = [];
      sensors.forEach((sensorName) => {
        sensorDataPromises.push(store.sensorDataStore.loadAll({
          raw: true,
          params: {
            sensorName,
            workplaceId: selectedWorkplaceId,
            fromDate: from.toISOString(),
            toDate: to.toISOString(),
            amountOfLeadingItems: res ? 0 : 1,
            resolution: res,
          },
        }));
        if (showTarget) {
          sensorDataTargetPromises.push(store.sensorDataTargetStore.loadByTime(
            sensorName,
            selectedWorkplaceId,
            from,
            to,
            clampToNow
          ));
        }
      });

      const sensorDataResults = await Promise.all(sensorDataPromises);
      const targetResults = showTarget ? await Promise.all(sensorDataTargetPromises) : [];

      setSensorData(sensorDataResults.map((r) => r.data));
      setSensorDataTargets(targetResults);
      setIsLoading(false);
    }
  };

  const updateSeries = () => {
    if (widgetConfig && selectedWorkplaceId !== null) {
      const from = dayjs().subtract(widgetConfig.getAmountOfHours(), 'hours');
      const to = dayjs();

      loadSensorData(widgetConfig.getSensors(), from, to, widgetConfig.getShowTargets(), widgetConfig.getResolution());

      setOptions({
        title: {
          text: null,
        },
        xAxis: {
          tickPixelInterval: 150,
          min: dayjs(from, datetimeFormat).valueOf(),
          max: dayjs(to, datetimeFormat).valueOf(),
        },
        chart: {
          width: bounds.width - LineChart.WIDTH_MARGIN,
          height: bounds.height - LineChart.HEIGHT_MARGIN,
        },
      });
    }
  };

  useMount(() => {
    setOptions({
      chart: {
        width: bounds.width - LineChart.WIDTH_MARGIN,
        height: bounds.height - LineChart.HEIGHT_MARGIN,
      },
    });
  }, [bounds]);

  useMount(() => {
    updateSeries();
  }, [selectedWorkplaceId]);

  return (
    <div ref={ref} style={{height: '100%'}}>
      <CockpitWidget
        widgetId={widgetId}
        title={widgetTitle}
        widgetConfig={widgetConfig}
        onSettingsChange={updateSeries}
        {...props}
      >
        {
          isLoading
            ? <Spinner fullWidth fullHeight/>
            : (
              <LineChart
                options={{...chartOptions, decimalPlaces: widgetConfig.getValues().decimalPlaces}}
                isTimeseries
              />
            )
        }
      </CockpitWidget>
    </div>
  );
};

MultipleTimeseriesCockpitWidget.Config = multipleTimeseriesCockpitWidgetConfig;
MultipleTimeseriesCockpitWidget.defaultLayout = new WidgetLayout({
  identifier: CockpitWidget.identifier,
  width: 4,
  height: 8,
  minHeight: 4,
  minWidth: WidgetLayouts.sixthWidth.minW,
});
export default observer(MultipleTimeseriesCockpitWidget);
