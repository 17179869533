import {observer} from 'mobx-react-lite';
import {Progress} from 'antd';
import {useTranslation} from 'react-i18next';
import {useStore} from '../../../hooks/useStore';
import ModelProperties from '../../shared/descriptions/ModelProperties';
import appConfig from '../../../utils/appConfig';
import styles from './InventoryWidgetGauge.module.scss';
import Spinner from '../../shared/spinners/Spinner';
import AlertWarning from '../../shared/alert/AlertWarning';

const InventoryWidgetSlot = ({
  loading,
  layout,
  storageUnit,
  slotData = {},
}) => {
  const store = useStore();
  const {t} = useTranslation();

  const level = storageUnit?.capacity ? (storageUnit?.totalAmount || 0) / storageUnit.capacity : 1;

  let stateColor = appConfig.quantitativeStateColors.good;
  if (level >= 0.9) {
    stateColor = appConfig.quantitativeStateColors.bad;
  } else if (level >= 0.8) {
    stateColor = appConfig.quantitativeStateColors.warning;
  }

  const height = layout === 'vertical' ? '50%' : '100%';

  if (loading) {
    return (
      <div className={styles.inventoryCard} style={{height}}>
        <Spinner style={{justifyContent: 'center'}}/>
      </div>
    );
  }

  if (!storageUnit) {
    return (
      <div className={styles.inventoryCard} style={{height}}>
        <AlertWarning
          message={t('inventoryWidget.warning.noData')}
          type={'info'}
        />
      </div>
    );
  }

  const barcodeOptions = {
    barcode: {
      displayValue: true,
      width: slotData?.barcodeWidth,
      height: slotData?.barcodeHeight,
    },
    qrCode: {
      displayValue: true,
      width: slotData?.qrCodeSize,
      height: slotData?.qrCodeSize,
    },
    dataMatrix: {
      displayValue: true,
      width: slotData?.dataMatrixSize,
      height: slotData?.dataMatrixSize,
    },
  };

  return (
    <div className={styles.inventoryCard} style={{height}}>
      <div style={{textAlign: 'center'}}>
        {slotData.title}
      </div>
      <div className={styles.inventoryContainer}>
        {slotData.displayGauge && (
          <div className={styles.inventoryGauge}>
            <Progress
              gapDegree={90}
              strokeColor={stateColor}
              type="dashboard"
              percent={level * 100}
              format={() => `${storageUnit?.totalAmount || 0} ${storageUnit?.unitOfMeasure?.label || ''}`}
              status={'normal'}
            />
          </div>
        )}
        {slotData.displayTable && (
          <div style={{width: '100%', flexGrow: 0}}>
            <ModelProperties
              loading={store.storageUnitStore.areCollectionOrDependenciesLoading}
              barcodeProperties={slotData.barcode}
              barcodeOptions={barcodeOptions.barcode}
              qrCodeProperties={slotData.qrCode}
              qrCodeOptions={barcodeOptions.qrCode}
              dataMatrixProperties={slotData.dataMatrix}
              dataMatrixOptions={barcodeOptions.dataMatrix}
              properties={slotData.property}
              model={storageUnit}
              className={'active-operation-properties'}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(InventoryWidgetSlot);
