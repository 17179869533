import {SensorDataLimitStyle} from '../../../../models/sensorDataLimit';
import appConfig from '../../../../utils/appConfig';
import {findNotNil} from '../../../../utils/helpers';

/**
 * interpolates a data series at given x respecting interpolation
 * returns interpolated y of x, as well as previous and next datapoint
 */
export const interpolate = (data, x, interpolation) => {
  if (!data) {
    return null;
  }
  let yValue;
  const [prev, next] = data.reduce((acc, curr) => ([
    curr.x <= x && (!acc[0] || curr.x >= acc[0].x) ? curr : acc[0],
    curr.x >= x && (!acc[1] || curr.x <= acc[1].x) ? curr : acc[1],
  ]), [null, null]);
  if (prev && next) {
    if (interpolation === 'linear') {
      const slope = (next.y - prev.y) / (next.x - prev.x);
      yValue = prev.y + slope * (x - prev.x);
    }
    if (interpolation === 'step') {
      yValue = prev.y;
    }
  } else if (prev) {
    yValue = prev.y;
  }
  return {
    x,
    y: yValue,
    prev,
    next,
  };
};

const removeNullAndUndefined = (v) => v !== null && v !== undefined;

/**
 * Creates a highcharts series for displaying limits based on the sensors limit configurations
 * @param {Object} limit
 * @param {number} fromX
 * @param {number} toX
 * @param {number} min
 * @param {number} max
 * @param {string|undefined} linkedTo
 * @returns {{rangeSeries: Array<Object>, chartMin: number, chartMax: number}}
 */
export const getLimitsSeries = (limit, fromX, toX, min, max, linkedTo = undefined) => {
  const rangeSeries = [];

  const minMaxDiff = Math.abs(max - min);
  const adjustedMin = min - minMaxDiff * 0.05;
  const adjustedMax = max + minMaxDiff * 0.05;

  const minOptions = [limit?.plausibleLow, limit?.toleranceLow, limit?.warningLow];
  const maxOptions = [limit?.plausibleHigh, limit?.toleranceHigh, limit?.warningHigh];
  if (!limit?.plausibleLow && limit?.plausibleLow !== 0) {
    minOptions.push(adjustedMin);
  }
  if (!limit?.plausibleHigh && limit?.plausibleHigh !== 0) {
    maxOptions.push(adjustedMax);
  }
  const chartMin = Math.min(...minOptions.filter(removeNullAndUndefined), min);
  const chartMax = Math.max(...maxOptions.filter(removeNullAndUndefined), max);

  if (!limit) {
    return {rangeSeries, chartMin, chartMax};
  }

  const createRangeSeries = (color, style, from, to) => ({
    data: [
      [fromX - 1, from, to],
      [toX + 1, from, to],
    ],
    type: style === SensorDataLimitStyle.AREA ? 'arearange' : undefined,
    lineWidth: style === SensorDataLimitStyle.AREA ? 0 : 1,
    linkedTo,
    fillColor: color,
    zIndex: -1,
    marker: {
      enabled: false,
    },
    configStyle: style,
  });

  if (limit.toleranceHigh || limit.toleranceHigh === 0) {
    rangeSeries.push(createRangeSeries(
      appConfig.quantitativeStateColors.bad,
      limit.toleranceStyle,
      limit.toleranceHigh,
      chartMax
    ));
  }

  if (limit.warningHigh || limit.warningHigh === 0) {
    rangeSeries.push(createRangeSeries(
      appConfig.quantitativeStateColors.warning,
      limit.warningStyle,
      limit.warningHigh,
      findNotNil(limit.toleranceHigh, limit.plausibleHigh, adjustedMax)
    ));
  }

  if (limit.warningLow || limit.warningLow === 0) {
    rangeSeries.push(createRangeSeries(
      appConfig.quantitativeStateColors.warning,
      limit.warningStyle,
      limit.warningLow,
      findNotNil(limit.toleranceLow, limit.plausibleLow, adjustedMin)
    ));
  }

  if (limit.toleranceLow || limit.toleranceLow === 0) {
    rangeSeries.push(createRangeSeries(
      appConfig.quantitativeStateColors.bad,
      limit.toleranceStyle,
      limit.toleranceLow,
      chartMin
    ));
  }

  return {rangeSeries, chartMin, chartMax};
};
