import {observer} from 'mobx-react-lite';
import {useState} from 'react';
import {Button} from 'antd';
import {useTranslation} from 'react-i18next';
import Modal from '../shared/modal/Modal';
import {useStore} from '../../hooks/useStore';

const AbortOperationButton = ({operation}) => {
  const store = useStore();
  const {t} = useTranslation();

  const [modalVisible, setModalVisible] = useState(false);

  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);
  const onOk = async () => {
    await store.operationStore.abortOperation(operation?.id);
    store.transitionStore.hasPerformedTransition();
    hideModal();
  };

  return (
    <>
      <Button
        style={{marginTop: '2em', width: '100%'}}
        block
        type="primary"
        onClick={showModal}
        loading={store.operationStore.isAbortingOperation}
        disabled={store.operationStore.isAbortingOperation}
        size="large"
      >
        {t('operation.abortOperation')}
      </Button>
      <Modal
        title={t('operation.abortOperation')}
        open={modalVisible}
        onOk={onOk}
        okButtonProps={{
          loading: store.operationStore.isAbortingOperation,
          disabled: store.operationStore.isAbortingOperation,
          size: 'large',
        }}
        onCancel={hideModal}
        cancelButtonProps={{
          disabled: store.operationStore.isAbortingOperation,
          size: 'large',
        }}
        width="50%"
      >
        {t('operation.abortOperationInfoText')}
      </Modal>
    </>
  );
};

export default observer(AbortOperationButton);
