import React, { CSSProperties } from 'react';
import { Skeleton, Table as AntdTable, TableProps as AntdTableProps } from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import styles from './Table.module.scss';

export type TableProps<RecordType extends AnyObject = AnyObject> = AntdTableProps<RecordType> & {
  striped?: boolean | 'odd';
  loading?: boolean;
  hideWhileLoading?: boolean;
  wrapperStyle?: CSSProperties;
};

type TableType = <RecordType extends AnyObject = AnyObject>(props: TableProps<RecordType>) => React.ReactNode;

const Table: TableType & {
  Summary: typeof AntdTable.Summary & {
    Cell: typeof AntdTable.Summary.Cell;
    Row: typeof AntdTable.Summary.Row;
  };
} = <RecordType extends AnyObject = AnyObject, >({
  striped = false,
  columns = [],
  // this prevents rendering the table while the data is loading, which helps with `defaultExpandAllRows` not working.
  // the only helpful comment i've found regarding this is https://stackoverflow.com/q/61247937/1049699
  hideWhileLoading = false,
  loading,
  wrapperStyle,
  ...props
}: TableProps<RecordType>) => (
  <div style={wrapperStyle !== undefined ? wrapperStyle : { overflowX: 'auto' }}>
    {hideWhileLoading && loading ? <Skeleton/>
      : (
        <AntdTable<RecordType>
          columns={columns.map((col) => ({
            ...col,
            render: col.render ? (...args) => col.render?.call(col, ...args) : ((att) => att),
          }))}
          className={classnames({
            [styles.table]: true,
            [styles.tableStriped]: striped,
            [styles.tableStripedOdd]: striped === 'odd',
          })}
          loading={loading}
          {...props}
        />
      )}
  </div>
);

Table.Summary = AntdTable.Summary;
Table.Summary.Cell = AntdTable.Summary.Cell;
Table.Summary.Row = AntdTable.Summary.Row;

export default observer(Table);
