import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import {
  observable,
  makeObservable,
  computed
} from 'mobx';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import { Hierarchy } from './hierarchy';
import { sortAlphabetically } from '../components/shared/tables/sorters';
import { UnitOfMeasurement } from './unitOfMeasurement';

export class KeyPerformanceIndicator extends BaseModel {
  id: number = 0;
  name: string = '';
  formula: string = '';
  unitOfMeasureId: number | null = null;
  hierarchyIds: number[] = [];
  sortOrder?: number | null = null;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      formula: observable,
      unitOfMeasureId: observable,
      unitOfMeasure: computed,
      hierarchyIds: observable,
      hierarchies: computed,
      hierarchyNames: computed,
      sortOrder: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faCalculator;

  searchableProperties = ['name', 'formula', 'unitOfMeasure.label'];

  get unitOfMeasure(): UnitOfMeasurement | undefined {
    return this.unitOfMeasureId ? this.rootStore.unitOfMeasurementStore.getById(this.unitOfMeasureId) : undefined;
  }

  get hierarchies(): Hierarchy[] {
    return this.rootStore.hierarchyStore.getByIds(this.hierarchyIds);
  }

  get hierarchyNames(): string {
    return this.hierarchies
      .map((hierarchy) => hierarchy.name)
      .sort((a: string, b: string) => sortAlphabetically(a, b))
      .join(', ');
  }

  static prepareApiPayload(model: Partial<KeyPerformanceIndicator>): Partial<KeyPerformanceIndicator> {
    return {
      id: model.id,
      name: model.name,
      formula: model.formula,
      unitOfMeasureId: model.unitOfMeasureId,
      sortOrder: model.sortOrder,
      hierarchyIds: model.hierarchyIds,
    };
  }

  displayableProperties = [
    displayableProperty({
      key: 'name',
      title: i18n.t('performanceAnalysis.kpi.model.attributes.name'),
      sorter: (a: KeyPerformanceIndicator, b: KeyPerformanceIndicator) => (
        sortAlphabetically(a.name, b.name)
      ),
      params: [displayablePropertyParam({ path: 'name', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'formula',
      title: i18n.t('performanceAnalysis.kpi.model.attributes.formula'),
      sorter: (a: KeyPerformanceIndicator, b: KeyPerformanceIndicator) => (
        sortAlphabetically(a.formula, b.formula)
      ),
      params: [displayablePropertyParam({ path: 'formula', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'unitOfMeasure',
      title: i18n.t('performanceAnalysis.kpi.model.attributes.unitOfMeasure'),
      sorter: (a: KeyPerformanceIndicator, b: KeyPerformanceIndicator) => (
        sortAlphabetically(a.unitOfMeasure?.label, b.unitOfMeasure?.label)
      ),
      params: [displayablePropertyParam({ path: 'unitOfMeasure', transform: transformation.objectProperty('label') })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'hierarchies',
      title: i18n.t('performanceAnalysis.kpi.model.attributes.hierarchy'),
      sorter: (a: KeyPerformanceIndicator, b: KeyPerformanceIndicator) => (
        sortAlphabetically(a.hierarchyNames, b.hierarchyNames)
      ),
      params: [displayablePropertyParam({ path: 'hierarchyNames', transform: transformation.none })],
      template: '{value}',
    }),
  ];

  getDisplayableValue(value: number): string {
    const { unitOfMeasure } = this;
    return unitOfMeasure?.getDisplayableValue(value) || String(value);
  }

  calculateToPercentageValue(value: number): number {
    const { unitOfMeasure } = this;
    return unitOfMeasure?.calculateToPercentageValue(value) || value;
  }
}
