import React from 'react';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import ColorPicker from '../inputs/ColorPicker';

export type ColorValueProps = {
  value: string;
  size?: SizeType;
};

const ColorValue: React.FC<ColorValueProps> = ({ value, size }) => (
  <ColorPicker
    disabled
    showText={false}
    value={value}
    size={size}
  />
);

export default ColorValue;
