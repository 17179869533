import { createEntity, executeDelete, executeGet, updateEntity } from '../requests';
import { FaultCollection } from '../../models/faultCollection';

const resourcesName = 'v1/fault-collections';

export const FaultCollectionsApi = {
  all: ({ ...params }) => executeGet(resourcesName, { params }),
  byId: (id: number) => executeGet(`${resourcesName}/${id}`),
  create: (entity: FaultCollection) => createEntity(entity, resourcesName),
  update: ({ id, ...entity }: any) => updateEntity(entity, `${resourcesName}/${id}`),
  destroy: (id: number) => executeDelete(`${resourcesName}/${id}`),
};
