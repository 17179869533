import React, { ChangeEvent, useRef, useState } from 'react';
import { BarcodeOutlined, CheckOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { useStore } from '../../../hooks/useStore';
import Input, { InputProps } from './Input';
import Button, { ButtonProps } from '../buttons/Button';
import styles from './scanInput.module.scss';
import ManualBarcodeInputModal from './ManualBarcodeInputModal';
import { useMount } from '../../../hooks/useMount';

export type ScanInputProps = Omit<InputProps, 'onChange' | 'value'> & {
  onChange?: (value: string) => void
  buttonProps?: ButtonProps;
  onAccept: (value?: string, isScanned?: boolean) => void;
  isValid: boolean;
  value?: string;
};

const ScanInput: React.FC<ScanInputProps> = ({
  buttonProps,
  value,
  onChange,
  onAccept,
  isValid,
  autoFocus,
  disabled,
  ...props
}) => {
  const HEX_IDENT = '\\x';
  const store = useStore();
  const [manualInput, setManualInput] = useState(false);
  const [barcodeValue, setBarcodeValue] = useState<string | undefined>(value);
  const barcodeInput = useRef<HTMLInputElement>(null);

  const hexToText = (hex: string) => {
    let text = '';
    for (let i = 0; i < hex.length; i += 2) {
      text += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    }
    return text;
  };

  const parseForHex = (val: string) => {
    let outputValue = val;
    if (outputValue.endsWith(HEX_IDENT)) {
      outputValue = hexToText(val.substring(0, val.length - 2));
      setBarcodeValue(outputValue);
    }
    return outputValue;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const outputValue = parseForHex(e.target.value);
    setBarcodeValue(outputValue);
    if (onChange) {
      onChange(outputValue);
    }
  };

  const accept = (val: string | undefined, isScanned: boolean) => {
    if (val && onAccept) {
      const outputValue = parseForHex(val);
      onAccept(outputValue, isScanned);
    }
  };

  const onManualSubmit = (barcode: string) => {
    setManualInput(false);
    accept(barcode, true);
  };

  useMount(() => {
    if (autoFocus && !disabled) {
      barcodeInput.current?.focus();
      barcodeInput.current?.setSelectionRange(0, 0);
    }
  }, [autoFocus, disabled]);

  useMount(() => {
    setBarcodeValue(value);
  }, [value]);

  return (
    <Input.Group>
      <Row>
        <Col flex="auto">
          <Input
            ref={barcodeInput}
            inputMode={'none'}
            autoComplete={'off'}
            onPressEnter={() => accept(barcodeValue, true)}
            value={barcodeValue}
            allowClear
            onChange={handleChange}
            disabled={disabled}
            {...props}
          />
        </Col>
        <Col>
          {isValid ? (
            <Button
              className={styles.scanInputButton}
              onClick={() => accept(barcodeValue, false)}
              icon={<CheckOutlined/>}
              {...buttonProps}
              disabled={disabled}
            />
          ) : null}
          <Button
            className={styles.scanInputButton}
            onClick={() => setManualInput(true)}
            icon={<BarcodeOutlined/>}
            {...buttonProps}
            disabled={disabled}
          />
        </Col>
      </Row>
      <ManualBarcodeInputModal
        open={manualInput}
        fullscreen={store.clientStore.isMobile}
        onSubmit={onManualSubmit}
        onCancel={() => setManualInput(false)}
      />
    </Input.Group>
  );
};
export default ScanInput;
