import { TablePaginationConfig } from 'antd';
import i18n from '../../../i18n/i18n';

export const defaultPaginationPreset: TablePaginationConfig = {
  defaultPageSize: 50,
  showSizeChanger: true,
  pageSizeOptions: ['10', '25', '50', '100'],
  showTotal: (total, range) => i18n.t('pagination.range', { from: range[0], to: range[1], total }),
};

export const smallPaginationPreset: TablePaginationConfig = {
  ...defaultPaginationPreset,
  defaultPageSize: 10,
};

export const mediumPaginationPreset: TablePaginationConfig = {
  ...defaultPaginationPreset,
  defaultPageSize: 25,
};

export const largePaginationPreset: TablePaginationConfig = {
  ...defaultPaginationPreset,
  defaultPageSize: 100,
};
