import React from 'react';
import { Document } from '../../models/document';

export type ShowDocumentImageProps = {
  document: Document;
};

const ShowDocumentImage: React.FC<ShowDocumentImageProps> = ({ document }) => (
  <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={document.src} alt={document.label}/>
);
export default ShowDocumentImage;
