import React from 'react';
import {Descriptions} from 'antd';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import Table from '../../../shared/tables/Table';
import {sortAlphabetically} from '../../../shared/tables/sorters';
import Section from './Section';
import QualitativeSettingDescription from './QualitativeSettingDescription';

const QualitativeSection = ({canEdit, task, setQualitativeTargetValueModalVisible}) => {
  const {t} = useTranslation();

  return (
    <>
      <Descriptions title={t('inspectionTask.drilldownModal.section.qualitative')}/>
      <Section>
        {task.qualitative?.targetValue && (
          <QualitativeSettingDescription
            canEdit={canEdit}
            task={task}
            setQualitativeTargetValueModalVisible={setQualitativeTargetValueModalVisible}
          />
        )}
        <Table
          pagination={false}
          rowKey="id"
          columns={[
            {
              title: t('inspectionTaskQualitativeCatalogFeature.model.attributes.code.label'),
              dataIndex: 'code',
              sorter: (a, b) => sortAlphabetically(a.code, b.code),
              defaultSortOrder: 'ascend',
              sortDirections: ['ascend'],
            },
            {
              title: t('inspectionTaskQualitativeCatalogFeature.model.attributes.text.label'),
              dataIndex: 'text',
            },
            {
              title: t('inspectionTaskQualitativeCatalogFeature.model.attributes.valuation.label'),
              dataIndex: 'valuation',
            },
          ]}
          dataSource={task.qualitative.catalogFeatures}
        />
      </Section>
    </>
  );
};

export default observer(QualitativeSection);
