import {createEntity, executeDelete, executeGet, updateEntity} from '../requests';

const resourcesName = 'v1/preparations';

export const PreparationsApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...entity}) => updateEntity(entity, `${resourcesName}/${id}`),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
};
