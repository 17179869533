import React from 'react';
import { Button, Form, FormItemProps } from 'antd';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';
import { FormItemLayouts } from './formItemLayouts';
import { useFormCancelCallback } from './useFormCancelCallback';

export type DefaultFormActionsProps = {
  onCancel: () => void;
  saving?: boolean;
  submitText?: string;
  submitIcon?: IconDefinition;
  cancelText?: string;
  cancelIcon?: IconDefinition;
  submitDisabled?: boolean;
  style?: FormItemProps['style'];
  formItemProps?: FormItemProps;
  dataCyPrefix?: string;
};

const DefaultFormActions: React.FC<DefaultFormActionsProps> = ({
  onCancel,
  saving,
  submitText,
  cancelText,
  submitIcon = faSave,
  cancelIcon = faTimes,
  submitDisabled = false,
  style = {},
  formItemProps = {},
  dataCyPrefix,
}) => {
  const handleCancel = useFormCancelCallback(onCancel);
  const { t } = useTranslation();

  return (
    <Form.Item
      {...FormItemLayouts.fullWidth}
      {...formItemProps}
      style={{ textAlign: 'right', ...formItemProps.style || {}, ...style }}
    >
      <Button
        style={{ marginRight: '10px' }}
        onClick={handleCancel}
        data-cy={`${dataCyPrefix ? `${dataCyPrefix}-` : ''}DefaultFormActions-CancelButton`}
      >
        <FontAwesomeIcon style={{ marginRight: '1em' }} icon={cancelIcon}/>
        {cancelText || t('confirm.cancel')}
      </Button>
      <Button
        type="primary"
        htmlType="submit"
        loading={saving}
        disabled={submitDisabled}
        data-cy={`${dataCyPrefix ? `${dataCyPrefix}-` : ''}DefaultFormActions-SubmitButton`}
      >
        <FontAwesomeIcon style={{ marginRight: '1em' }} icon={submitIcon}/>
        {submitText || t('confirm.save')}
      </Button>
    </Form.Item>
  );
};

export default DefaultFormActions;
