import { computed, makeObservable, observable } from 'mobx';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

export class UserRole extends BaseModel {
  id: number = 0;
  userId: number = 0;
  roleId: number = 0;
  expireDate: Date | null = null;
  alertDate: Date | null = null;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      userId: observable,
      roleId: observable,
      role: computed,
      expireDate: observable,
      alertDate: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  get role() {
    return this.rootStore.roleStore.getById(this.roleId);
  }
}
