import { observable, makeObservable, computed } from 'mobx';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

export class UserNotification extends BaseModel {
  id: number = 0;
  userId: number = 0;
  eventLogId: number = 0;
  readAt: Date | null = null;
  createdAt: Date = new Date();

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      userId: observable,
      user: computed,
      eventLogId: observable,
      eventLog: computed,
      readAt: observable,
      createdAt: observable,
    });
  }

  get eventLog(): any {
    return this.eventLogId ? this.rootStore.eventLogStore.getById(this.eventLogId) : undefined;
  }

  get user(): any {
    return this.userId ? this.rootStore.userStore.getById(this.userId) : undefined;
  }

  readonly saveableProperties = [
    'readAt',
  ];

  static prepareApiPayload(model: Partial<UserNotification>): Partial<UserNotification> {
    return {
      id: model.id,
      readAt: model.readAt,
    };
  }
}
