import { action, observable, runInAction, makeObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { Api } from '../middleware/api';
import { EntityStore, LoadOptions } from './entityStore';
import { Transition } from '../models/transition';
import { RootStore } from './rootStore';

export const TransitionStoreActions = {
  performTransition: 'performTransition',
};

interface PerformTransitionRequest {
  transitionId: number;
  activeOperationId?: number;
  startingOperationId?: number;
  interruptionReasonId?: number;
  interruptionClassId?: number;
  interruptionSourceHierarchyId?: number;
  message?: string;
  timeoutMessage?: string;
  phaseTimeoutReasonId?: number | null;
  timestamp?: Date;
  isForceStarted?: boolean;
  additionalPayload?: any;
}

interface PerformTransitionParams {
  workplaceId: number;
  body: PerformTransitionRequest;
}

export class TransitionStore extends EntityStore<Transition> {
  transitions: Transition[] = [];
  performedTransition = 0;

  constructor(rootStore: RootStore) {
    super(rootStore, 'transitions', Api.transitions, Transition);

    makeObservable(this, {
      transitions: observable,
      performTransition: action,
      performedTransition: observable,
      hasPerformedTransition: action,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.userStore,
        modelId: 'createdBy',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'updatedBy',
      },
    ];
  }

  loadQueueTransitions() {
    return this.loadAll({ params: { isStart: true, isActive: false, isInternal: false } });
  }

  performTransition({ workplaceId, body }: PerformTransitionParams, paramOptions: LoadOptions = {}) {
    const options = {
      action: TransitionStoreActions.performTransition,
      ...paramOptions,
    };

    this.addPendingAction(options.action);

    return this.api
      .performTransition({ workplaceId, ...body })
      .then(({ data }: AxiosResponse) => {
        runInAction(() => this.removePendingAction(options.action));
        if (!data?.errors?.length) {
          this.hasPerformedTransition();
        }
        return data;
      })
      .catch((e: Error) => this.handleApiError(e, options.action));
  }

  getValidTransitionsFromCurrentStates(operationStateId: number, workplaceStateId: number) {
    return this.transitions.filter(
      (transition) => this.transitionValid(transition, operationStateId, workplaceStateId)
    );
  }

  getValidQueueTransitions(workflowId: number) {
    return this.transitions.filter((transition) => transition.startingOperationWorkflowId === workflowId
      && transition.startingOperationToStateId !== null && transition.activeOperationToStateId === null
      && !transition.isInternal);
  }

  // eslint-disable-next-line class-methods-use-this
  transitionValid(transition: Transition, operationStateId: number, workplaceStateId: number) {
    if (transition.workplaceToState && !transition.workplaceFromStateIds.includes(workplaceStateId)) {
      return false;
    }

    if (transition.isWorkplaceExclusive && operationStateId) {
      return false;
    }

    if (operationStateId && !transition.activeOperationFromStateIds.includes(operationStateId)) {
      return false;
    }

    return operationStateId || transition.startingOperationToStateId;
  }

  getStartTransitionsByWorkflow(workflowId: number) {
    return this.transitions.filter((transition) => transition.startingOperationWorkflowId === workflowId
      && transition.startingOperationToStateId !== null && transition.activeOperationToStateId === null);
  }

  hasPerformedTransition() {
    this.performedTransition += 1;
  }
}
