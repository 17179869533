import { observable, makeObservable, computed } from 'mobx';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

export class MaterialSensor extends BaseModel {
  sensorName: string = '';
  materialId: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      sensorName: observable,
      sensor: computed,
      materialId: observable,
      material: computed,
    });
  }

  get sensor() {
    return this.rootStore.sensorStore.getById(this.sensorName);
  }

  get material() {
    return this.rootStore.materialStore.getById(this.materialId);
  }
}
