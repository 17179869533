import {observer} from 'mobx-react-lite';
import {Col, Divider, Row} from 'antd';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDatabase} from '@fortawesome/pro-solid-svg-icons';
import MaterialFlowSlot from './MaterialFlowSlot';
import {useMount} from '../../../hooks/useMount';
import {useStore} from '../../../hooks/useStore';
import styles from './MaterialFlowWidget.module.scss';
import ModelProperties from '../../shared/descriptions/ModelProperties';
import {sortAlphabetically} from '../../shared/tables/sorters';

const MaterialFlowView = ({
  showSourceStorageUnit,
  showTargetStorageUnit,
  showInternalStorageUnit,
  showGraph,
  showTable,
  graphLocation,
  amountOfColumns,
  wpStorageRelation,
  columns,
  sortBy,
  sortOrder,
  filterByOrderMaterial,
}) => {
  const store = useStore();
  const [colSpan, setColSpan] = useState(8);
  const {t} = useTranslation();
  let columnWidth = 24;
  if (amountOfColumns && amountOfColumns >= 1 && amountOfColumns <= 4) {
    columnWidth = 24 / amountOfColumns;
  }
  const [sourceUnitIds, setSourceUnitIds] = useState([]);
  const [destinationUnitIds, setDestinationUnitIds] = useState([]);
  const [internalUnitIds, setInternalUnitIds] = useState([]);
  const [sourceUnitsByArea, setSourceUnitsByArea] = useState([]);
  const [destinationUnitsByArea, setDestinationUnitsByArea] = useState([]);
  const [internalUnitsByArea, setInternalUnitsByArea] = useState([]);

  useMount(() => {
    const getStorageUnitIds = async () => {
      const ids = new Set();
      const sourceIds = [];
      const destinationIds = [];
      const internalIds = [];
      const {
        sourceStorageUnitIds,
        destinationStorageUnitIds,
        internalStorageUnitIds,
        sourceStorageAreaIds,
        destinationStorageAreaIds,
        internalStorageAreaIds,
      } = wpStorageRelation;

      if (sourceStorageUnitIds?.length > 0) {
        sourceStorageUnitIds.forEach((id) => {
          ids.add(id);
          sourceIds.push(id);
        });
      }

      if (destinationStorageUnitIds?.length > 0) {
        destinationStorageUnitIds.forEach((id) => {
          ids.add(id);
          destinationIds.push(id);
        });
      }

      if (internalStorageUnitIds?.length > 0) {
        internalStorageUnitIds.forEach((id) => {
          ids.add(id);
          internalIds.push(id);
        });
      }

      if (sourceStorageAreaIds?.length > 0) {
        sourceStorageAreaIds.forEach((id) => {
          const storageUnits = store.storageUnitStore.getByStorageAreaId(id);
          const storageUnitByAreIds = [];
          storageUnits.forEach((storageUnit) => {
            ids.add(storageUnit.id);
            sourceIds.push(storageUnit.id);
            storageUnitByAreIds.push(storageUnit.id);
          });
          setSourceUnitsByArea(storageUnitByAreIds);
        });
      }

      if (internalStorageAreaIds?.length > 0) {
        internalStorageAreaIds.forEach((id) => {
          const storageUnits = store.storageUnitStore.getByStorageAreaId(id);
          const storageUnitByAreIds = [];
          storageUnits.forEach((storageUnit) => {
            ids.add(storageUnit.id);
            internalIds.push(storageUnit.id);
            storageUnitByAreIds.push(storageUnit.id);
          });
          setInternalUnitsByArea(storageUnitByAreIds);
        });
      }

      if (destinationStorageAreaIds?.length > 0) {
        destinationStorageAreaIds.forEach((id) => {
          const storageUnits = store.storageUnitStore.getByStorageAreaId(id);
          const storageUnitByAreIds = [];
          storageUnits.forEach((storageUnit) => {
            ids.add(storageUnit.id);
            destinationIds.push(storageUnit.id);
            storageUnitByAreIds.push(storageUnit.id);
          });
          setDestinationUnitsByArea(storageUnitByAreIds);
        });
      }

      setSourceUnitIds(sourceIds);
      setDestinationUnitIds(destinationIds);
      setInternalUnitIds(internalIds);
    };

    if (wpStorageRelation) {
      getStorageUnitIds();
    }
  }, [
    wpStorageRelation,
    store.storageUnitStore.storageAreaRef,
  ]);

  useMount(() => {
    let count = 0;
    if (showSourceStorageUnit) { count += 1; }
    if (showTargetStorageUnit) { count += 1; }
    if (showInternalStorageUnit) { count += 1; }
    if (count === 0) { count = 1; }
    setColSpan(24 / count);
  }, [showSourceStorageUnit, showTargetStorageUnit, showInternalStorageUnit]);

  let wpSourceStorageUnits = store.storageUnitStore.getByIds(sourceUnitIds) || [];

  let wpDestinationStorageUnits = store.storageUnitStore.getByIds(destinationUnitIds) || [];

  let wpInternalStorageUnits = store.storageUnitStore.getByIds(internalUnitIds) || [];

  if (sortBy) {
    wpSourceStorageUnits.sort((a, b) => sortAlphabetically(
      a[sortBy]?.toString(),
      b[sortBy]?.toString(),
      {ignoreCase: false, order: sortOrder === 'ascend' ? 'asc' : 'desc'}
    ));
    wpDestinationStorageUnits.sort((a, b) => sortAlphabetically(
      a[sortBy]?.toString(),
      b[sortBy]?.toString(),
      {ignoreCase: false, order: sortOrder === 'ascend' ? 'asc' : 'desc'}
    ));
    wpInternalStorageUnits.sort((a, b) => sortAlphabetically(
      a[sortBy]?.toString(),
      b[sortBy]?.toString(),
      {ignoreCase: false, order: sortOrder === 'ascend' ? 'asc' : 'desc'}
    ));
  }

  if (filterByOrderMaterial) {
    const workplaceId = wpStorageRelation?.workplaceId;
    if (workplaceId) {
      const activeOperation = store.operationStore.getActiveByWorkplaceId(workplaceId);

      if (activeOperation) {
        const operationMaterialId = activeOperation.materialId;
        const operationOrderMaterialId = activeOperation.order?.materialId;
        const operationComponentsMaterialIds = activeOperation.components?.map((component) => component.materialId);

        const uniqueMaterialIds = new Set();
        if (operationMaterialId) {
          uniqueMaterialIds.add(operationMaterialId);
        }
        if (operationOrderMaterialId) {
          uniqueMaterialIds.add(operationOrderMaterialId);
        }
        if (operationComponentsMaterialIds?.length > 0) {
          operationComponentsMaterialIds.forEach((id) => uniqueMaterialIds.add(id));
        }

        if (uniqueMaterialIds.size > 0) {
          wpSourceStorageUnits = wpSourceStorageUnits.filter((storageUnit) =>
            uniqueMaterialIds.has(storageUnit.materialId));
          wpDestinationStorageUnits = wpDestinationStorageUnits.filter((storageUnit) =>
            uniqueMaterialIds.has(storageUnit.materialId));
          wpInternalStorageUnits = wpInternalStorageUnits.filter((storageUnit) =>
            uniqueMaterialIds.has(storageUnit.materialId));
        }
      }
    }
  }

  const dataPerLine = amountOfColumns * ([
    showSourceStorageUnit,
    showTargetStorageUnit,
    showInternalStorageUnit,
  ].filter((show) => show).length);

  return (
    <Row style={{overflowY: 'hidden', height: '100%'}} gutter={8}>
      {showSourceStorageUnit
        ? (
          <Col span={colSpan} className={styles.scrollableCol}>
            <Divider orientation={'left'}>{t('materialFlowWidget.sourceStorageUnit')}</Divider>
            <div className={styles.materialSlotWrapper}>
              {wpSourceStorageUnits.map((sourceStorageUnit) => (
                <Col span={columnWidth} key={sourceStorageUnit.id} gutter={8} style={{ marginBottom: '16px' }}>
                  {
                    (!sourceUnitsByArea.length || !sourceUnitsByArea.includes(sourceStorageUnit.id))
                    && <FontAwesomeIcon icon={faDatabase} className={styles.topRightCorner}/>
                  }
                  <div style={{ textAlign: 'center'}}>{sourceStorageUnit.no}</div>
                  <div className={graphLocation === 'top' ? styles.materialGraphTop : styles.materialGraphLeft}>
                    {showGraph
                      ? (
                        <MaterialFlowSlot
                          storageUnit={sourceStorageUnit}
                          layout={'vertical'}
                          loading={false}
                        />
                      ) : null}
                    {showTable
                      ? (
                        <ModelProperties
                          properties={columns}
                          model={sourceStorageUnit}
                          layout={graphLocation === 'top' && dataPerLine <= 4 ? 'horizontal' : 'vertical'}
                          size={graphLocation === 'top' && dataPerLine <= 4 ? '' : 'small'}
                          className={graphLocation === 'top' && dataPerLine <= 4 ? '' : styles.materialTableSmall}
                        />
                      ) : null}
                  </div>
                </Col>
              ))}
            </div>
          </Col>
        )
        : null}
      {showInternalStorageUnit
        ? (
          <Col span={colSpan} className={styles.scrollableCol}>
            <Divider orientation={'left'}>{t('materialFlowWidget.internalStorageUnit')}</Divider>
            <div className={styles.materialSlotWrapper}>
              {wpInternalStorageUnits.map((internalStorageUnit) => (
                <Col span={columnWidth} key={internalStorageUnit.id} gutter={8}>
                  {
                    (!internalUnitsByArea.length || !internalUnitsByArea.includes(internalStorageUnit.id))
                    && <FontAwesomeIcon icon={faDatabase} className={styles.topRightCorner}/>
                  }
                  <div style={{ textAlign: 'center'}}>{internalStorageUnit.no}</div>
                  <div className={graphLocation === 'top' ? styles.materialGraphTop : styles.materialGraphLeft}>
                    {showGraph
                      ? (
                        <MaterialFlowSlot
                          storageUnit={internalStorageUnit}
                          layout={'vertical'}
                          loading={false}
                        />
                      ) : null}
                    {showTable
                      ? (
                        <ModelProperties
                          properties={columns}
                          model={internalStorageUnit}
                          layout={graphLocation === 'top' && dataPerLine <= 4 ? 'horizontal' : 'vertical'}
                          size={graphLocation === 'top' && dataPerLine <= 4 ? '' : 'small'}
                          className={graphLocation === 'top' && dataPerLine <= 4 ? '' : styles.materialTableSmall}
                        />
                      ) : null}
                  </div>
                </Col>
              ))}
            </div>
          </Col>
        )
        : null}
      {showTargetStorageUnit
        ? (
          <Col span={colSpan} className={styles.scrollableCol}>
            <Divider orientation={'left'}>{t('materialFlowWidget.destinationStorageUnit')}</Divider>
            <div className={styles.materialSlotWrapper}>
              {wpDestinationStorageUnits.map((destinationStorageUnit) => (
                <Col span={columnWidth} key={destinationStorageUnit.id} gutter={8}>
                  {
                    (!destinationUnitsByArea.length || !destinationUnitsByArea.includes(destinationStorageUnit.id))
                    && <FontAwesomeIcon icon={faDatabase} className={styles.topRightCorner}/>
                  }
                  <div style={{ textAlign: 'center'}}>{destinationStorageUnit.no}</div>
                  <div className={graphLocation === 'top' ? styles.materialGraphTop : styles.materialGraphLeft}>
                    {showGraph
                      ? (
                        <MaterialFlowSlot
                          storageUnit={destinationStorageUnit}
                          layout={'50%'}
                          loading={false}
                        />
                      ) : null}
                    {showTable
                      ? (
                        <ModelProperties
                          properties={columns}
                          model={destinationStorageUnit}
                          layout={graphLocation === 'top' && dataPerLine <= 4 ? 'horizontal' : 'vertical'}
                          size={graphLocation === 'top' && dataPerLine <= 4 ? '' : 'small'}
                          className={graphLocation === 'top' && dataPerLine <= 4 ? '' : styles.materialTableSmall}
                        />
                      ) : null}
                  </div>
                </Col>
              ))}
            </div>
          </Col>
        )
        : null}
    </Row>
  );
};
export default observer(MaterialFlowView);
