import { computed, observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { OperationState } from '../models/operationState';
import { OperationDefaultTabs } from '../models/operation';
import i18n from '../i18n/i18n';
import { RootStore } from './rootStore';

export const defaultOperationStateTabs = () => [
  { id: OperationDefaultTabs.planned, label: i18n.t('orderWidget.loadType.planned') },
  { id: OperationDefaultTabs.finished, label: i18n.t('orderWidget.loadType.finished') },
];

export class OperationStateStore extends EntityStore<OperationState> {
  operationStates: OperationState[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'operationStates', Api.operationStates, OperationState);

    makeObservable(this, {
      operationStates: observable,
      initialStates: computed,
    });
  }

  get initialStates() {
    return this.operationStates.filter((state) => state.isInitial || state.isFinal);
  }
}
