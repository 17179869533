import { ConfigProvider } from 'antd';
import enUs from 'antd/locale/en_US';
import deDe from 'antd/locale/de_DE';
import frFr from 'antd/locale/fr_FR';
import itIt from 'antd/locale/it_IT';
import plPl from 'antd/locale/pl_PL';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import 'cronstrue/locales/de';
import 'cronstrue/locales/fr';
import 'cronstrue/locales/it';
import 'cronstrue/locales/pl';
import appConfig from '../../utils/appConfig';
import { useStore } from '../../hooks/useStore';
import { useBreakpoint } from './Viewport';
import { useMount } from '../../hooks/useMount';
import i18n from '../../i18n/i18n';
import useProfile from '../../hooks/useProfile';
import { Language } from '../../models/translations';
import colors from '../../assets/stylesheets/colors.module.scss';

const antdLocales = {
  'en-CH': enUs,
  'de-CH': deDe,
  'fr-FR': frFr,
  'it-IT': itIt,
  'pl-PL': plPl,
};

export const Locale: React.FC<{ children: React.ReactNode }> = observer(({ children }) => {
  const { clientStore, languageStore } = useStore();
  const breakpoint = useBreakpoint();
  clientStore.setBreakpoint(breakpoint);
  const { profile } = useProfile();
  const [antdLocale, setLocale] = useState(antdLocales[appConfig.language as Language]);

  const setLanguage = async (lang: Language) => {
    const { startOfWeek } = appConfig.locale;
    const locale = dayjs.locale(lang.split('-')[0]);
    dayjs.Ls[locale].weekStart = startOfWeek;
    setLocale(antdLocales[lang]);
    if (i18n.language !== lang) {
      await i18n.changeLanguage(lang);
    }
  };

  useMount(() => {
    setLanguage(appConfig.language as Language);
  });

  useMount(() => {
    if (appConfig.modules.enableUserLanguage && profile?.language) {
      languageStore.setLang(profile?.language);
    } else if (appConfig.modules.enableUserLanguage) {
      languageStore.setLang(appConfig.language);
    }
  }, [profile?.language]);

  useMount(() => {
    if (languageStore.langRequest === languageStore.lang) {
      return;
    }
    const fn = async () => {
      await setLanguage(languageStore.langRequest as Language);
      languageStore.langLoaded(languageStore.langRequest as Language);
    };
    // eslint-disable-next-line no-console
    fn().catch((err) => console.error(`An error occurred while setting up the language: ${err}`));
  }, [languageStore.lang, languageStore.langRequest]);

  document.documentElement.lang = languageStore.lang;

  return (
    <ConfigProvider
      locale={antdLocale}
      theme={{
        components: {
          Slider: {
            dotActiveBorderColor: colors.primaryColor,
            handleActiveColor: colors.primaryColor,
            handleColor: colors.primaryColor,
            dotSize: 15,
            handleLineWidth: 4,
            handleLineWidthHover: 6,
            handleSize: 8,
            handleSizeHover: 10,
            railSize: 6,
            trackBg: colors.primaryColor,
            trackHoverBg: colors.primaryColor,
          },
        },
        token: {
          fontFamily: '\'Roboto\', sans-serif',
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
});
