import { computed, makeObservable, observable } from 'mobx';
import { faCodeCompare } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import i18n from '../i18n/i18n';
import { RootStore } from '../stores/rootStore';

export class PrintAllocation extends BaseModel {
  id: number = 0;
  materialId: number | null = null;
  printLabelId: number = 0;
  printerIds: number[] = [];
  enabled: boolean = true;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      materialId: observable,
      material: computed,
      printLabelId: observable,
      printLabel: computed,
      printerIds: observable,
      printers: computed,
      enabled: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faCodeCompare;

  searchableProperties = ['material.label', 'printLabel.name'];

  static additionalDataProperties = [{
    key: 'string',
    title: i18n.t('printAllocation.model.attributes.printData.additionalData.type.string'),
  }, {
    key: 'number',
    title: i18n.t('printAllocation.model.attributes.printData.additionalData.type.number'),
  }, {
    key: 'boolean',
    title: i18n.t('printAllocation.model.attributes.printData.additionalData.type.boolean'),
  }];

  get material() {
    return this.rootStore.materialStore.getById(this.materialId);
  }

  get printLabel() {
    return this.rootStore.printLabelStore.getById(this.printLabelId);
  }

  get printers() {
    return this.rootStore.printerStore.getByIds(this.printerIds);
  }
}
