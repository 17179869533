import React, {useState} from 'react';
import {Tabs} from 'antd';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBoxesStacked, faRulerVertical} from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import Form from '../../shared/form/Form';
import DefaultFormActions from '../../shared/form/DefaultFormActions';
import StorageUnitFormPartial from './StorageUnitFormPartial';
import StorageUnitLevelsFormPartial from './StorageUnitLevelsFormPartial';
import {useMount} from '../../../hooks/useMount';

const StorageUnitForm = ({
  customProperties = [],
  fields,
  onChange,
  isSaving = false,
  onSubmit,
  onCancel,
  removedLevels,
  setRemovedLevels,
  unitOfMeasurements,
  storageAreas,
  widgetConfig,
}) => {
  const [form] = Form.useForm();
  const {t} = useTranslation();
  const [selectedUnitOfMeasureId, setSelectedUnitOfMeasureId] = useState(null);
  const [selectedStorageAreaId, setSelectedStorageAreaId] = useState(null);
  const [selectedParentStorageUnitId, setSelectedParentStorageUnitId] = useState(null);
  const [capacity, setCapacity] = useState(null);

  useMount(() => {
    form.setFieldsValue({
      timerStart: form.getFieldValue('timerStart') ? dayjs(form.getFieldValue('timerStart')) : null,
      timerEnd: form.getFieldValue('timerEnd') ? dayjs(form.getFieldValue('timerEnd')) : null,
    });
  });
  const updateTimerDuration = (timerStart, timerEnd) => {
    const calcTimerDuration = dayjs(timerEnd).diff(dayjs(timerStart), 'minute');
    form.setFieldValue('timerDuration', calcTimerDuration);
  };

  const updateTimerStart = (timerEnd, timerDuration) => {
    const calcTimerStart = dayjs(timerEnd).subtract(timerDuration, 'minute');
    form.setFieldValue('timerStart', calcTimerStart);
  };

  const updateTimerEnd = (timerStart, timerDuration) => {
    const calcTimerEnd = dayjs(timerStart).add(timerDuration, 'minute');
    form.setFieldValue('timerEnd', calcTimerEnd);
  };

  const handleChange = (changedFields, allFields) => {
    const storageAreaId = changedFields.find((field) => field.name[0] === 'storageAreaId');
    if (storageAreaId) {
      setSelectedStorageAreaId(storageAreaId.value);
    }
    const parentStorageUnitId = changedFields.find((field) => field.name[0] === 'parentId');
    if (parentStorageUnitId) {
      setSelectedParentStorageUnitId(parentStorageUnitId.value);
    }
    const unitOfMeasureId = changedFields.find((field) => field.name[0] === 'unitOfMeasureId');
    if (unitOfMeasureId) {
      setSelectedUnitOfMeasureId(unitOfMeasureId.value);
    }
    const cap = changedFields.find((field) => field.name[0] === 'capacity');
    if (cap) {
      setCapacity(cap.value);
    }

    if (onChange) {
      onChange(allFields);
    }
  };

  const handleValuesChange = (changed, values) => {
    const { timerStart } = changed;
    if (timerStart) {
      const timerEndAll = values.timerEnd;
      const timerDurationAll = values.timerDuration;
      if (timerEndAll) {
        updateTimerDuration(timerStart, timerEndAll);
      } else if (timerDurationAll) {
        updateTimerEnd(timerStart, timerDurationAll);
      }
    }
    const { timerEnd } = changed;
    if (timerEnd) {
      const timerStartAll = values.timerStart;
      const timerDurationAll = values.timerDuration;
      if (timerStartAll) {
        updateTimerDuration(timerStartAll, timerEnd);
      } else if (timerDurationAll) {
        updateTimerStart(timerEnd, timerDurationAll);
      }
    }
    const { timerDuration } = changed;
    if (timerDuration) {
      const timerStartAll = values.timerStart;
      const timerEndAll = values.timerEnd;
      if (timerStartAll) {
        updateTimerEnd(timerStartAll, timerDuration);
      } else if (timerEndAll) {
        updateTimerStart(timerEndAll, timerDuration);
      }
    }
    form.validateFields();
  };

  useMount(() => {
    setSelectedUnitOfMeasureId(form.getFieldValue('unitOfMeasureId'));
    setCapacity(form.getFieldValue('capacity'));
    setSelectedStorageAreaId(form.getFieldValue('storageAreaId'));
    setSelectedParentStorageUnitId(form.getFieldValue('parentId'));
    const startField = fields.find((field) => field.name === 'timerStart' || field.name[0] === 'timerStart');
    const endField = fields.find((field) => field.name === 'timerEnd' || field.name[0] === 'timerEnd');
    if (startField?.value && endField?.value) {
      updateTimerDuration(startField.value, endField.value);
    }
  });

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      fields={fields}
      onFieldsChange={handleChange}
      onValuesChange={handleValuesChange}
    >
      <Tabs
        items={[
          {
            key: 1,
            label: (
              <span>
                <FontAwesomeIcon style={{marginRight: '1em'}} icon={faBoxesStacked}/>
                <span>{t('storageUnit.attributeTab')}</span>
              </span>
            ),
            children: (
              <StorageUnitFormPartial
                form={form}
                selectedUnitOfMeasureId={selectedUnitOfMeasureId}
                selectedStorageAreaId={selectedStorageAreaId}
                selectedParentStorageUnitId={selectedParentStorageUnitId}
                unitOfMeasurements={unitOfMeasurements}
                storageAreas={storageAreas}
                widgetConfig={widgetConfig}
                customProperties={customProperties}
              />
            ),
          },
          {
            key: 2,
            label: (
              <span>
                <FontAwesomeIcon style={{marginRight: '1em'}} icon={faRulerVertical}/>
                <span>{t('storageUnit.levelsTab')}</span>
              </span>
            ),
            children: (
              <StorageUnitLevelsFormPartial
                form={form}
                removedLevels={removedLevels}
                setRemovedLevels={setRemovedLevels}
                selectedUnitOfMeasureId={selectedUnitOfMeasureId}
                unitOfMeasurements={unitOfMeasurements}
                capacity={capacity}
              />
            ),
          },
        ]}
      />
      <DefaultFormActions saving={isSaving} onCancel={onCancel}/>
    </Form>
  );
};

export default StorageUnitForm;
