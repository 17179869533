import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import FormModal from '../shared/modal/FormModal';
import QualityReportForm from './QualityReportForm';
import { useStore } from '../../hooks/useStore';
import { QualityReportWidgetConfig } from './qualityReportWidgetConfig';
import { QualityReport } from '../../models/qualityReport';
import { makeFormFields } from '../shared/form/formFields';
import { FieldData } from '../shared/form/Form';
import { MODULE_NAME_PREFIX, ModuleNames } from '../moduleConfiguration/forms/ModuleConfigurationFormHandler';

export type NewQualityReportModalProps = {
  widgetConfig: QualityReportWidgetConfig;
  onCancel: () => void;
  onSuccess: (record: QualityReport) => void;
  open?: boolean;
  coordinatorRole?: number | undefined;
};

const NewQualityReportModal: React.FC<NewQualityReportModalProps> = ({
  widgetConfig,
  onCancel,
  onSuccess,
  open = true,
  coordinatorRole,
}) => {
  const { t } = useTranslation();
  const store = useStore();
  const [fields, setFields] = useState<FieldData[]>([]);

  const handleCancel = () => {
    (async () => {
      const documentIds = fields.find((field) => (Array.isArray(field.name) && field.name[0] === 'documentIds')
        || field.name === 'documentIds')?.value;
      const deletePromises: Promise<any>[] = [];
      if (Array.isArray(documentIds) && documentIds.length) {
        documentIds.forEach((documentId) => {
          deletePromises.push(store.documentStore.delete(documentId, { skipNotification: true }));
        });
      }
      await Promise.all(deletePromises);
      if (onCancel) {
        onCancel();
      }
    })();
  };

  const handleSubmit = (values: QualityReport) => {
    // Add supplier from Settings to values
    const setting = store.settingStore.getByIdentifier(`${MODULE_NAME_PREFIX}${ModuleNames.QUALITY_REPORT}`);
    if (setting) {
      const { supplier } = (setting.value as { coordinatorRole: number, supplier: string });
      values.supplier = supplier || '';
    }

    store.qualityReportStore.create(values)
      .then((record: any) => {
        onSuccess(record);
        if (record?.documentIds?.length) {
          return store.documentStore.loadMany(record.documentIds);
        }
        return Promise.resolve([]);
      })
      .then(() => {})
      .catch((errors: any) => setFields && setFields(makeFormFields(values, errors.fields)));
  };

  return (
    <FormModal
      title={(
        <>
          <FontAwesomeIcon icon={QualityReport.faIcon}/>
          {' '}
          {t('qualityReport.widget.newReportModal.title')}
        </>
      )}
      open={open}
      onCancel={handleCancel}
    >
      <QualityReportForm
        fields={fields}
        onChange={setFields}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        coordinatorRole={coordinatorRole}
        selectableOrderTimeSpan={widgetConfig.getSelectableOrdersTimeSpan()}
        fixedWorkplaceId={store.workplaceStore.selectedWorkplace?.id}
      />
    </FormModal>
  );
};

export default NewQualityReportModal;
