import { observable, makeObservable } from 'mobx';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { MaterialSensor } from '../models/materialSensor';
import { RootStore } from './rootStore';
import { SensorType } from '../models/sensor';

export class MaterialSensorStore extends EntityStore<MaterialSensor> {
  materialSensors: MaterialSensor[] = [];
  identityField = 'sensorName';

  constructor(rootStore: RootStore) {
    super(rootStore, 'materialSensors', Api.materialSensors, MaterialSensor, true);

    makeObservable(this, {
      materialSensors: observable,
    });
  }

  getRelevantByHierarchyId(hierarchyId: number) {
    const sensors = this.rootStore.sensorStore
      .getByHierarchyIdAndSensorType(hierarchyId, SensorType.COMPONENT_PREPARE);
    return this.materialSensors.filter((ms) => sensors.find((s) => s.name === ms.sensorName));
  }
}
