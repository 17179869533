import React from 'react';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {Col, Divider, Row} from 'antd';
import {observer} from 'mobx-react-lite';
import {datetimeFormat, durationFormat} from '../../../config/dayjs';
import {OrderStateNames} from '../../../models/order';
import {round} from '../../../utils/number';
import {useStore} from '../../../hooks/useStore';
import {formatDuration} from '../../../utils/formatting';
import MonoTable from '../../shared/tables/MonoTable';
import SpeedDenominator from '../../shared/dataDisplay/SpeedDenominator';
import {EnDash} from '../../shared/unicodeWrapper/EnDash';
import {useMount} from '../../../hooks/useMount';
import InformationRow from '../../shared/descriptions/InformationRow';

const OrderDetailsGeneral = ({
  operations,
  uomTypes = [],
  decimalsConversion,
  showOrderFirst,
  showPhaseMachineHours,
  cols = 4,
  orderProperties = [],
  operationProperties = [],
}) => {
  const {t} = useTranslation();
  const store = useStore();
  const item = (label, value) => [label, value];
  const {isMobile} = store.clientStore;
  if (isMobile) {
    cols = 1;
  }

  const isOverall = operations.length > 1;

  useMount(() => {
    const operationIds = operations.map((operation) => operation.id);
    if (showPhaseMachineHours) {
      store.operationStateLogStore.loadAll({params: {operationId: operationIds}});
    }
    store.unitOfMeasurementConversionStore.loadByOrderId(operations[0]?.orderId);
    store.operationStore.loadPersonnelDurations(operationIds);
    store.phaseStore.loadAll();
    store.operationPhaseStore.loadByOperation(operationIds);
  }, [operations.reduce((res, op) => `${res}-${op.id}`, '')]);

  const formatSeconds = (seconds) => formatDuration(dayjs.duration(seconds, 'seconds'), durationFormat);

  const quantities = () => {
    const destinations = store.unitOfMeasurementStore.unitsOfMeasurement.filter((uom) => uomTypes.includes(uom.type));
    const uomItems = [];
    const roundUOM = (value) => {
      // !decimalsConversion returns true for value 0 as well
      if (decimalsConversion === null || decimalsConversion === '') {
        return value;
      }
      const factorOfTen = 10 ** decimalsConversion;
      return Math.round(value * factorOfTen) / factorOfTen;
    };
    destinations.forEach((destinationUOM) => {
      const actual = store.unitOfMeasurementConversionStore.convert(
        operations[0]?.order?.actualQuantity,
        operations[0]?.orderId,
        operations[0]?.order.unitOfMeasureId,
        destinationUOM.id
      );
      const planned = store.unitOfMeasurementConversionStore.convert(
        operations[0]?.order?.plannedQuantity,
        operations[0]?.orderId,
        operations[0]?.order.unitOfMeasureId,
        destinationUOM.id
      );
      const remaining = store.unitOfMeasurementConversionStore.convert(
        operations[0]?.order?.remainingQuantity,
        operations[0]?.orderId,
        operations[0]?.order.unitOfMeasureId,
        destinationUOM.id
      );
      if ((actual || actual === 0)
        && (remaining || remaining === 0)
        && (planned || planned === 0)) {
        uomItems.push(
          item(
            `${t('operation.detail.orderQuantityRemaining')} (${destinationUOM.type}) `,
            `${roundUOM(remaining)} ${destinationUOM.label}`
          )
        );
        uomItems.push(
          item(
            `${t('operation.detail.orderQuantity')} (${destinationUOM.type}) `,
            `${roundUOM(actual)} / ${roundUOM(planned)} ${destinationUOM.label}`
          )
        );
      }
    });

    return [
      item(
        t('operation.detail.orderQuantityRemaining'),
        `${operations[0]?.order?.remainingQuantity} ${operations[0]?.order?.unitOfMeasure?.label}`
      ),
      item(
        t('operation.detail.orderQuantity'),
        `${operations[0]?.order?.actualQuantity ?? EnDash()} / ${operations[0]?.order?.plannedQuantity ?? EnDash()} `
        + `${operations[0]?.order?.unitOfMeasure?.label}`
      ),
      ...uomItems,
    ];
  };

  const phaseMachineHours = (operationPhase) => {
    if (!showPhaseMachineHours) {
      return [];
    }

    const operationLogs = store.operationStateLogStore.logs.filter(
      (log) => (operations.find((op) => op.id === log.operationId)) && (log.state?.phaseId === operationPhase.phaseId)
    );

    let machineSeconds = 0;
    if (operationLogs.length === 1) {
      const start = new Date(operationLogs[0].start);
      const end = (operationLogs[0].end) ? new Date(operationLogs[0].end) : new Date();
      machineSeconds = (end - start) / 1000;
    } else if (operationLogs.length > 1) {
      machineSeconds = operationLogs.reduce((agg, v) => {
        const start = new Date(v.start);
        const end = (v.end) ? new Date(v.end) : new Date();
        return ((end - start) / 1000) + agg;
      }, 0);
    }

    return [
      item(
        `${t('operation.detail.machineHoursRemaining')}`,
        formatSeconds(operationPhase.plannedDurationSeconds - machineSeconds)
      ),
      item(
        `${t('operation.detail.machineHours')}`,
        `${formatSeconds(machineSeconds)} / ${formatSeconds(operationPhase.plannedDurationSeconds)}`
      )];
  };

  const groupOperationPhases = (ops) => ops.reduce((opPhases, operation) => {
    operation.operationPhases?.forEach((opPhase) => {
      let currentOpPhase = opPhases.find((ph) => ph.phaseId === opPhase.phaseId);
      if (currentOpPhase) {
        currentOpPhase.plannedDurationSeconds += opPhase.plannedDurationSeconds;
        currentOpPhase.plannedPersonnel += opPhase.plannedPersonnel;
        currentOpPhase.plannedPersonnelDuration += opPhase.plannedPersonnel * opPhase.plannedDurationSeconds;
        currentOpPhase.personnelDurationSeconds += store.operationStore
          .getPersonnelDurationByOperationPhase(opPhase.id)?.personnelDurationSeconds || 0;
      } else {
        currentOpPhase = {...opPhase};
        currentOpPhase.sortOrder = opPhase.sortOrderExtended;
        currentOpPhase.personnelDurationSeconds = store.operationStore
          .getPersonnelDurationByOperationPhase(opPhase.id)?.personnelDurationSeconds || 0;
        currentOpPhase.plannedPersonnelDuration = currentOpPhase.plannedPersonnel
          * currentOpPhase.plannedDurationSeconds;
        opPhases.push(currentOpPhase);
      }
    });
    return opPhases;
  }, []);

  const operationData = () =>
    (
      <>
        <Col className="gutter-row" span={24}>
          <Divider orientation="left">
            {t('operation.detail.operationData')}
            {' - '}
            {isOverall ? EnDash() : operations[0].name}
          </Divider>
          <InformationRow
            cols={cols}
            items={[
              item(t('operation.detail.plannedStart'), !isOverall && operations[0].plannedStart
                ? dayjs(operations[0].plannedStart).format(datetimeFormat) : EnDash()),
              item(t('operation.detail.actualStart'), !isOverall && operations[0].actualStart
                ? dayjs(operations[0].actualStart).format(datetimeFormat) : EnDash()),
              item(t('operation.detail.plannedEnd'), !isOverall && operations[0].plannedEnd
                ? dayjs(operations[0].plannedEnd).format(datetimeFormat) : EnDash()),
              item(t('operation.detail.actualEnd'), !isOverall && operations[0].actualEnd
                ? dayjs(operations[0].actualEnd).format(datetimeFormat) : EnDash()),
              item(t('operation.detail.expectedEnd'), !isOverall && operations[0].expectedEnd
                ? dayjs(operations[0].expectedEnd).format(datetimeFormat) : EnDash()),
              item(t('operation.detail.currentState'), !isOverall && operations[0].state?.label
                ? operations[0].state.label : EnDash()),
              item(
                t('operation.detail.operationQuantityRemaining'),
                !isOverall ? `${operations[0].remainingQuantity} ${operations[0].unitOfMeasure?.label}` : EnDash()
              ),
              item(
                t('operation.detail.operationQuantity'),
                !isOverall ? `${round(operations[0].actualQuantity, 3)} / ${round(operations[0].plannedQuantity, 3)}`
                  + ` ${operations[0].unitOfMeasure?.label}` : EnDash()
              ),
              item(
                t('operation.detail.operationSpeed'),
                <span>
                  {!isOverall
                    ? <SpeedDenominator value={operations[0].actualSpeed} unit={operations[0].speedUnitOfMeasure}/>
                    : EnDash()}
                  {' / '}
                  {!isOverall
                    ? <SpeedDenominator value={operations[0].plannedSpeed} unit={operations[0].speedUnitOfMeasure}/>
                    : EnDash()}
                </span>
              ),
            ]}
          />
        </Col>
        {!isOverall && operationProperties.length > 0 ? (
          <Col className="gutter-row" span={24} style={{marginTop: '1em'}}>
            <InformationRow
              cols={cols}
              items={operationProperties.map((property) => (
                item(property.title, property.render(operations[0], operations[0]).children || EnDash())
              ))}
            />
          </Col>
        ) : null}
      </>
    );

  const orderData = () =>
    (
      <>
        <Col className="gutter-row" span={24}>
          <Divider orientation="left">{t('operation.detail.orderData')}</Divider>
          <InformationRow
            cols={cols}
            items={[
              item(t('operation.detail.orderNo'), operations[0].order?.no),
              item(
                t('operation.detail.orderStatus'),
                t(`order.model.attributes.state.${OrderStateNames[operations[0].order?.state]}`)
              ),
              ...quantities(),
              item(t('operation.detail.batchNo'), operations[0].order?.batch?.no),
              item(t('operation.detail.materialNo'), operations[0].order?.material?.no),
              item(t('operation.detail.materialName'), operations[0].order?.material?.name),
            ]}
          />
        </Col>
        {orderProperties.length > 0 ? (
          <Col className="gutter-row" span={24} style={{marginTop: '1em'}}>
            <InformationRow
              cols={cols}
              items={orderProperties.map((property) => (
                item(property.title, property.render(operations[0].order, operations[0].order).children || EnDash())
              ))}
            />
          </Col>
        ) : null}
      </>
    );

  const phaseData = (phase) => (
    <Col className="gutter-row" span={isMobile ? 24 : 6} key={phase.id}>
      <h3>
        {store.phaseStore.getById(phase.phaseId)?.description
          || store.phaseStore.getById(phase.phaseId)?.name}
      </h3>
      <InformationRow
        cols={1}
        items={[
          item(
            t('operationPhase.model.attributes.plannedStart'),
            phase.plannedStart ? dayjs(phase.plannedStart).format(datetimeFormat) : EnDash()
          ),
          item(
            t('operation.detail.remainingDurationPersonnel'),
            formatSeconds(phase.plannedPersonnelDuration - phase.personnelDurationSeconds)
          ),
          item(
            t('operation.detail.durationPersonnel'),
            `${formatSeconds(phase.personnelDurationSeconds)} / ${
              formatSeconds(phase.plannedPersonnelDuration)
            }`
          ),
          ...phaseMachineHours(phase),
        ]}
      />
    </Col>
  );

  const operationPhases = groupOperationPhases(operations);

  return (
    <>
      <Row gutter={16}>
        {(showOrderFirst) ? orderData() : operationData()}
      </Row>
      <Row gutter={16} style={{marginTop: '3em'}}>
        {(showOrderFirst) ? operationData() : orderData()}
      </Row>
      <Row gutter={16} style={{marginTop: '3em'}}>
        <Divider orientation="left">{t('operation.detail.phases')}</Divider>
        {operationPhases?.sort((a, b) => a.sortOrder - b.sortOrder).map(phaseData)}
      </Row>
      <Row gutter={16} style={{marginTop: '3em'}}>
        <Col className="gutter-row" span={24}>
          <Divider orientation="left">{t('order.model.attributes.info')}</Divider>
          <MonoTable rows={[operations[0].order?.info || t('operation.detail.noInfoAvailable')]}/>
        </Col>
      </Row>
    </>
  );
};

export default observer(OrderDetailsGeneral);
