import { observable, makeObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { Material } from '../models/material';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { RootStore } from './rootStore';

interface MaterialSearchResponse {
  id: number,
  no: string,
  name: string,
}

export class MaterialStore extends EntityStore<Material> {
  materials: Material[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'materials', Api.materials, Material, true);

    makeObservable(this, {
      materials: observable,
    });
  }

  async searchByNoAndName({ params }: { params: { q: string, size?: number } }): Promise<MaterialSearchResponse[]> {
    return this.api
      .search({ lang: this.rootStore.languageStore.lang, ...params })
      .then((response: AxiosResponse) => response.data);
  }
}
