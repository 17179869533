import { observable, action, makeObservable } from 'mobx';
import appConfig from '../utils/appConfig';
import { RootStore } from './rootStore';
import { Language } from '../models/translations';

export class LanguageStore {
  lang: Language = appConfig.language as Language;
  langRequest = appConfig.language;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      lang: observable,
      langRequest: observable,
      setLang: action,
      langLoaded: action,
    });

    this.rootStore = rootStore;
  }

  setLang(lang: string) {
    this.langRequest = lang;
  }

  langLoaded(lang: Language) {
    this.lang = lang;
  }
}
