import {createEntity, executeDelete, executeGet, executePut, updateEntity} from '../requests';

const resourcesName = 'v1/report-logs';

export const bulkUpdate = 'bulkUpdate';

export const ReportLogsApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...entity}) => updateEntity(entity, `${resourcesName}/${id}`),
  [bulkUpdate]: (reportLogs) => executePut(resourcesName, reportLogs),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
};
