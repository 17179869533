import { observable, makeObservable } from 'mobx';
import dayjs from 'dayjs';
import { intersection } from 'lodash';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { InspectionVerification } from '../models/inspectionVerification';
import { RootStore } from './rootStore';
import { InspectionTaskResultValuation } from '../models/inspectionTaskResult';

export interface InspectionVerificationFilter {
  fromDate?: string;
  toDate?: string;
  isVerified?: boolean;
  orderIds?: number[];
  valuation?: InspectionTaskResultValuation;
  workplaceIds?: number[];
  taskCustomProperties?: {
    [key: string]: string[];
  }
}

export class InspectionVerificationStore extends EntityStore<InspectionVerification> {
  inspectionVerifications: InspectionVerification[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'inspectionVerifications', Api.inspectionVerification, InspectionVerification);

    makeObservable(this, {
      inspectionVerifications: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.inspectionTaskResultStore,
        modelId: 'inspectionTaskResultIds',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'createdBy',
      },
    ];
  }

  getFiltered(filter: InspectionVerificationFilter) {
    return this.inspectionVerifications.filter((verification) => {
      if (filter?.fromDate) {
        const fromDate = dayjs(filter.fromDate).startOf('day');
        const createdAt = dayjs(verification.createdAt).endOf('day');
        if (fromDate.isAfter(createdAt)) {
          return false;
        }
      }
      if (filter?.toDate) {
        const toDate = dayjs(filter.toDate).endOf('day');
        const createdAt = dayjs(verification.createdAt).startOf('day');
        if (toDate.isBefore(createdAt)) {
          return false;
        }
      }
      if (filter?.workplaceIds?.length && (
        !verification.appliedFilters?.workplaceIds?.length
        || !intersection(filter.workplaceIds, verification.appliedFilters.workplaceIds).length)) {
        return false;
      }
      if (filter.taskCustomProperties && Object.keys(filter.taskCustomProperties).some((key) => (
        filter.taskCustomProperties
        && filter.taskCustomProperties[key].length > 0
        && (
          intersection(
            filter.taskCustomProperties[key],
            verification.appliedFilters?.taskCustomProperties?.[key] || []
          ).length === 0
        )
      ))) {
        return false;
      }
      return true;
    });
  }
}
