import { action, observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { Terminal } from '../models/terminal';
import { RootStore } from './rootStore';

export class TerminalStore extends EntityStore<Terminal> {
  terminals: Terminal[] = [];
  activeTerminal: Terminal | null = null;
  newUrlTerminal: string | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore, 'terminals', Api.terminals, Terminal);

    makeObservable(this, {
      activeTerminal: observable,
      setActiveTerminal: action,
      getByName: action,
      terminals: observable,
      newUrlTerminal: observable,
      setNewUrlTerminal: action,
    });
  }

  getByName(terminalName: string) {
    return this.terminals.find((terminal) => terminal.name?.toLowerCase() === terminalName?.toLowerCase());
  }

  setActiveTerminal(activeTerminal?: Terminal | null) {
    this.activeTerminal = activeTerminal || null;
  }

  /**
   * Set to true to trigger the WorkplaceSelector to switch the active workplace to the default workplace of the current
   * terminal/workplace group.
   */
  setNewUrlTerminal(newTerminal: string | null) {
    this.newUrlTerminal = newTerminal;
  }
}
