import {Button, Col, Radio, Row} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {observer, Observer} from 'mobx-react-lite';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import {useMount} from '../../../hooks/useMount';
import Table from '../../shared/tables/Table';
import {OperationDefaultTabs, OperationDisplayStates} from '../../../models/operation';
import {ReportTypeEnum} from '../../../models/reportType';
import {defaultOperationStateTabs as defaultTabs} from '../../../stores/operationStateStore';
import {useStateTransitionChanged} from '../../../hooks/useStateTransitionChanged';
import {useStore} from '../../../hooks/useStore';
import {getIcon, iconNames} from '../orderDetails/orderWidgetConfigIcon';
import {useModulePolicy} from '../../../hooks/useModulePolicy';
import {sortChronologically} from '../../shared/tables/sorters';
import {useOrderOperationsUpdated} from '../../../hooks/useOrderOperationsUpdated';
import appConfig from '../../../utils/appConfig';
import {reloadEndpoint} from '../../../middleware/endpoints/orders';
import ConfirmButton from '../../shared/buttons/ConfirmButton';
import {OrderState} from '../../../models/order';
import ManualFinalPerformanceReportingButton from './ManualFinalPerformanceReportingButton';
import ManualOrderReportingButton from './ManualOrderReportingButton';
import ReorderingButtons from './ReorderingButtons';
import SortableOrderTable from './SortableOrderTable';
import {hexToRGBArray} from '../../../utils/color';
import SearchField from '../../shared/inputs/SearchField';

const BACKGROUND_COLOR_CLASS_PREFIX = 'ord-lst-color-';

const OrderTable = ({
  workplaceId,
  showTypeSelect = true,
  settings = {
    loadDaysPast: undefined,
    loadDaysFuture: undefined,
    sortOrderForFinishedOrders: 'desc',
    reloadOrder: false,
    filterOrders: false,
  },
  activeFilter = OperationDefaultTabs.planned,
  columnsPlanned,
  columnsFinished,
  operationStateIds = [],
  fromDate: overrideFromDate,
  toDate: overrideToDate,
  setDetailsModalVisible = () => undefined,
  // eslint-disable-next-line no-unused-vars
  useSelectedOperation = [undefined, () => undefined],
  // eslint-disable-next-line no-unused-vars
  useIsReordering = [false, () => false],
  isModalTable = false,
  setShowOperationDetails = () => undefined,
  setCurrentReportLog = () => undefined,
  setReportLogModalVisible = () => undefined,
  onTypeChange = () => undefined,
  displayStateChanged = 0,
  ...props
}) => {
  const store = useStore();
  const {t} = useTranslation();
  const [tabSelection, setTabSelection] = useState(defaultTabs());
  const [activeTab, setActiveTab] = useState(defaultTabs()[0]);
  const [dates, setDates] = useState([undefined, undefined]);
  const [selectedOperation, setSelectedOperation] = useSelectedOperation;
  const stateTransitionChanged = useStateTransitionChanged(store);
  const orderOperationsUpdated = useOrderOperationsUpdated(store);
  const [dataSource, setDataSource] = useState([]);
  const reorderPolicy = useModulePolicy(store, ['reorder-operations']);
  const [isReordering, setIsReordering] = useIsReordering;
  const [sortableOrders, setSortableOrders] = useState(undefined);
  const [filterQuery, setFilterQuery] = useState(null);

  const phasePerformanceReportType = store.reportTypeStore.getByType(ReportTypeEnum.PhasePerformance);
  const fullOrderReportType = store.reportTypeStore.getByType(ReportTypeEnum.FullOrder);

  useMount(() => {
    if (showTypeSelect && !operationStateIds.includes(activeFilter)
      && !defaultTabs().some((dt) => dt.id === activeFilter)) {
      setActiveTab(defaultTabs()[0]);
      return;
    }
    let filter = store.operationStateStore.getById(activeFilter);
    if (filter) {
      setActiveTab(filter);
      return;
    }
    filter = defaultTabs().find((tab) => tab.id === activeFilter);
    setActiveTab(filter);
  }, [activeFilter, operationStateIds, showTypeSelect]);

  useMount(() => {
    const filteredByDisplayState = [];
    if (!showTypeSelect && activeTab?.id === activeFilter) {
      const active = store.operationStore.getActiveByWorkplaceId(store.workplaceStore.selectedWorkplace.id);
      if (active) {
        filteredByDisplayState.push(active);
      }
    }
    if (activeTab?.id === OperationDefaultTabs.finished) {
      filteredByDisplayState.push(...store.operationStore.aggregatedFinishedOperations
        .sort(
          (a, b) => {
            if (settings.sortOrderForFinishedOrders === 'desc') {
              return sortChronologically(b.actualStart, a.actualStart);
            }

            return sortChronologically(a.actualStart, b.actualStart);
          }
        ));
    } else if (activeTab?.id === OperationDefaultTabs.planned) {
      filteredByDisplayState.push(...store.operationStore.aggregatedPlannedOperations);
    } else if (activeTab?.id && isModalTable) {
      filteredByDisplayState.push(...store.operationStore.getAggregatedOperationsByOperationState(activeTab?.id));
    } else {
      filteredByDisplayState.push(
        ...store.operationStore.getAggregatedAndActiveOperationsByOperationState(activeTab?.id)
      );
    }

    setDataSource([...new Set(filteredByDisplayState
      .filter((op) => {
        const fromOk = op.isActive || !dates[0] || !op.plannedEnd || dates[0].isBefore(op.plannedEnd);
        const toOk = op.isActive || !dates[1] || dates[1].isAfter(op.plannedStart);
        return fromOk && toOk;
      }))]);
  }, [store.operationStore.operationTableLoading, store.operationPhaseStore.operationPhases.length, dates]);

  const loadOperationsByTabSelection = async (tab) => {
    if (store.operationStore.operationTableLoading || !store.settingStore.operatorSettingsReady) {
      return;
    }
    const loadActive = () => (store.operationStore.loadActiveOperationByWorkplace(workplaceId));

    const loadPlanned = () => store.operationStore.loadPlannedOperationsByWorkplace(workplaceId, {
      params: {
        fromDate: dates[0].toISOString(),
        toDate: dates[1].toISOString(),
      },
    });

    const loadFinished = () => store.operationStore.loadFinishedOperationsByWorkplace(workplaceId, {
      params: {
        fromDate: dates[0].toISOString(),
        toDate: dates[1].toISOString(),
      },
    }).then((operations) => {
      if (store.contextStore.isOfficeContext) {
        operations
          .forEach((operation) => {
            if (phasePerformanceReportType && appConfig.modules.enableManualFinalPerformanceReporting) {
              store.reportLogStore.loadAll({
                params: {
                  operationId: operation.id,
                  typeId: [phasePerformanceReportType.id],
                },
              });
            }
            if (fullOrderReportType && appConfig.modules.enableFullOrderReporting) {
              store.reportLogStore.loadAll({
                params: {
                  orderId: operation.orderId,
                  typeId: [fullOrderReportType.id],
                },
              });
            }
          });
      }
    });

    const loadByState = (state) => store.operationStore.loadByWorkplaceAndState(workplaceId, state, dates[0], dates[1]);

    if (workplaceId) {
      if (tab?.id === OperationDefaultTabs.planned) {
        store.operationStore.setOperationTableLoading(true);
        await loadPlanned();
        await loadActive();
      } else if (tab?.id === OperationDefaultTabs.finished) {
        store.operationStore.setOperationTableLoading(true);
        await loadFinished();
      } else if (tab?.id) {
        store.operationStore.setOperationTableLoading(true);
        await loadByState(tab?.id);
      }
      if (!showTypeSelect) {
        store.operationStore.setOperationTableLoading(true);
        await loadPlanned();
        await loadActive();
      }
      store.operationStore.setOperationTableLoading(false);
    }
  };

  useMount(() => {
    if (!store.operationStateStore.isLoadingCollection) {
      store.operationStateStore.loadAll();
    }

    if (!store.reportTypeStore.isLoadingCollection) {
      store.reportTypeStore.loadAll();
    }
  });

  useMount(() => {
    let newFromDate = null;
    let newToDate = null;
    if (overrideFromDate) {
      newFromDate = overrideFromDate;
      // ignore settings if they have not finished loading, to prevent loading of unwanted defaults
    } else if (store.settingStore.operatorSettingsReady && settings.loadDaysPast !== undefined) {
      newFromDate = dayjs().subtract(settings.loadDaysPast, 'day').startOf('day');
    }
    if (overrideToDate) {
      newToDate = overrideToDate;
      // ignore settings if they have not finished loading, to prevent loading of unwanted defaults
    } else if (store.settingStore.operatorSettingsReady && settings.loadDaysFuture !== undefined) {
      newToDate = dayjs().add(settings.loadDaysFuture, 'day').startOf('day');
    }
    if (newFromDate || newToDate) {
      setDates([newFromDate, newToDate]);
    }
  }, [
    settings.loadDaysPast,
    settings.loadDaysFuture,
    overrideFromDate,
    overrideToDate,
    store.settingStore.operatorSettingsReady,
  ]);

  useMount(() => {
    if (!dates[0] || !dates[1] || isReordering) {
      return;
    }
    loadOperationsByTabSelection(activeTab);
  }, [
    workplaceId,
    activeTab,
    operationStateIds,
    dates,
    stateTransitionChanged,
    orderOperationsUpdated,
    isReordering,
    displayStateChanged,
    settings,
  ]);

  useMount(() => {
    const tabs = defaultTabs();
    tabs.push(...operationStateIds.map((id) => store.operationStateStore.getById(id)));
    setTabSelection(tabs);
  }, [operationStateIds]);

  useMount(() => {
    store.transitionQueueStore.loadByWorkplaceId(workplaceId);
  }, [workplaceId, stateTransitionChanged]);

  useMount(() => {
    Object.values(OperationDisplayStates).forEach((state) => {
      const color = settings[`color.${state}`];
      if (color) {
        document.documentElement.style.setProperty(`--${BACKGROUND_COLOR_CLASS_PREFIX}${state}`, color);
      }
    });
  }, [settings]);

  const onClick = (record, isActionClick) => {
    if (!isReordering && record.order && !record.children) {
      if (isModalTable && record.disabled) {
        // do nothing
      } else if (isModalTable && isActionClick) {
        setShowOperationDetails(true);
        setSelectedOperation(record);
      } else if (store.contextStore.isOfficeContext && isActionClick) {
        setSelectedOperation(record);
        setDetailsModalVisible(true);
      } else if (store.contextStore.isOfficeContext && !isActionClick) {
        // do nothing
      } else {
        setSelectedOperation(record);
        setDetailsModalVisible(true);
      }
    }
  };

  const handleTabSelect = (e) => {
    const newActiveTab = tabSelection.find((tab) => tab.id === e.target.value);
    setActiveTab(newActiveTab);
    setSelectedOperation(undefined);
    onTypeChange(e.target.value);
  };

  const reloadOrder = (id) => {
    store.orderStore.reloadOrder(id);
  };

  const renderReloadOrderButton = (record) => {
    if (!record.children) {
      return (
        <ConfirmButton
          type={'link'}
          title={t('orderWidget.reloadOrder.confirmation')}
          icon={<DownloadOutlined/>}
          onConfirm={() => reloadOrder(record.id)}
          buttonProps={{
            loading: store.orderStore.isActionInProgress(reloadEndpoint, {apiEndpoint: reloadEndpoint, id: record.id}),
          }}
          disabled={[OrderState.RUNNING, OrderState.TERMINATED].includes(record.order.state)}
          disabledText={t('orderWidget.reloadOrder.disabled')}
        />
      );
    }
    return (
      <Button disabled icon={null}/>
    );
  };

  const stateButtonStyle = (operationState) => {
    if (settings && settings.displayOperationStateColor) {
      const opacity = settings.operationStateColorOpacity / 100;
      const {color} = operationState;
      const rgba = color ? `rgba(${hexToRGBArray(color)}, ${opacity})` : color;
      return rgba ? {backgroundColor: rgba} : {};
    }
    return {};
  };

  const rowClassNames = (record) => {
    const classNames = [];

    if (record.disabled && isModalTable) {
      classNames.push('disabled');
    } else if (store.contextStore.isOperatorContext) {
      classNames.push('pointable');
    }

    if (record.id === selectedOperation?.id && isModalTable) {
      classNames.push('selected');
    }

    const colorClass = settings && `${BACKGROUND_COLOR_CLASS_PREFIX}${record.displayStatus}`;
    classNames.push(colorClass);
    return classNames.join(' ');
  };

  let tableColumns = activeTab?.id === OperationDefaultTabs.finished ? [...columnsFinished] : [...columnsPlanned];

  if (isModalTable || store.contextStore.isOfficeContext) {
    if (!isReordering) {
      tableColumns.push({
        key: 'actions',
        dataIndex: 'actions',
        render: (text, record) => ({
          props: {},
          children: (
            <>
              {store.contextStore.isOfficeContext && appConfig.modules.enableManualFinalPerformanceReporting && (
                <ManualFinalPerformanceReportingButton
                  store={store}
                  record={record}
                  reportType={phasePerformanceReportType}
                  selectedTab={activeTab}
                  setCurrentReportLog={setCurrentReportLog}
                  setReportLogModalVisible={setReportLogModalVisible}
                />
              )}
              {store.contextStore.isOfficeContext && appConfig.modules.enableFullOrderReporting && (
                <ManualOrderReportingButton
                  store={store}
                  record={record}
                  reportType={fullOrderReportType}
                  selectedTab={activeTab}
                  setCurrentReportLog={setCurrentReportLog}
                />
              )}
              {store.contextStore.isOfficeContext && settings.reloadOrder === true && (
                renderReloadOrderButton(record)
              )}
              {!record.children && (
                <Button type={'link'} onClick={() => onClick(record, true)} size={'small'}>
                  {t('orderWidget.table.action.showDetails')}
                </Button>
              )}
            </>
          ),
        }),
      });
    }
  }

  tableColumns = tableColumns.map((column) => ({
    title: column.label,
    ...column,
    render: (text, record, index) => (
      <Observer>
        {() => {
          const result = column.render(text, record, index);

          if (settings && typeof result.children === 'string' && iconNames.includes(result.children)) {
            result.children = (
              <div style={{width: '100%', textAlign: 'center'}}>
                {getIcon(settings[`icon.${result.children}`], false)}
              </div>
            );
          }

          return (
            <div className={'order-table-data'} {...result.props}>
              {result.children}
            </div>
          );
        }}
      </Observer>
    ),
  }));

  let displayDataSource = dataSource;
  if (filterQuery) {
    displayDataSource = displayDataSource.filter((ds) => ds.fitsQuery(filterQuery.toLowerCase()));
  }

  return (
    <>
      {showTypeSelect && (
        <Col style={{paddingBottom: '1em'}}>
          <Row>
            <Radio.Group
              options={tabSelection.map((tab) => ({
                label: tab.label,
                value: tab.id,
                style: stateButtonStyle(tab),
              }))}
              onChange={handleTabSelect}
              value={activeTab?.id}
              optionType="button"
              disabled={isReordering}
            />
            {reorderPolicy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})
              && (activeTab?.id === OperationDefaultTabs.planned || activeTab?.isInitial)
              && (
                <div style={{position: 'absolute', right: 0}}>
                  <ReorderingButtons
                    store={store}
                    orders={displayDataSource}
                    useIsReordering={[isReordering, setIsReordering]}
                    useSortableOrders={[sortableOrders, setSortableOrders]}
                  />
                </div>
              )}
          </Row>
          {settings.filterOrders === true
            && (
              <Row gutter={16}>
                <Col style={{paddingTop: '1em'}} flex={'350px'}>
                  <SearchField placeholder={t('orderWidget.filter.placeholder')} onSearch={setFilterQuery}/>
                </Col>
              </Row>
            )}
        </Col>
      )}
      {!isReordering ? (
        <Table
          className={'order-table'}
          rowKey="id"
          columns={tableColumns}
          expandable={{
            defaultExpandAllRows: true,
            expandRowByClick: true,
          }}
          pagination={false}
          rowClassName={rowClassNames}
          onRow={(record) => ({onClick: () => onClick(record)})}
          dataSource={displayDataSource}
          loading={store.operationStore.operationTableLoading}
          {...props}
        />
      ) : (
        <SortableOrderTable
          columns={tableColumns}
          rowClassName={rowClassNames}
          useSortableOrders={[sortableOrders, setSortableOrders]}
          {...props}
        />
      )}
    </>
  );
};

export default observer(OrderTable);
