import React, {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons';
import {Form, Modal as AntModal} from 'antd';
import {take} from 'lodash';
import {WidgetLayouts} from '../shared/widgets/WidgetLayouts';
import {WidgetLayout} from '../../models/widgetLayout';
import OperatorWidget from '../operator/shared/OperatorWidget';
import {useStore} from '../../hooks/useStore';
import {InterruptionWidgetConfig} from './interruptionWidgetConfig';
import {Timer} from './Timer';
import Spinner from '../shared/spinners/Spinner';
import {RecordButton} from './RecordButton';
import {useMount} from '../../hooks/useMount';
import InterruptionModal from './InterruptionModal';
import {StopButton} from './StopButton';
import {ScopeContext} from '../../policies/scopeContext';
import {transition, workflow} from '../../models/scope';
import QuickStopButtons from './partials/QuickStopButtons';
import {useModulePolicy} from '../../hooks/useModulePolicy';
import useInspectionTaskExecutionModal from '../../hooks/useInspectionTaskExecutionModal';
import {InspectionTaskPendingStore} from '../../stores/inspectionTaskPendingStore';
import InspectionTaskExecutionModal from '../inspectionTask/execution/InspectionTaskExecutionModal';
import PendingTasksList from '../order/operationList/PendingTaskList';

const InterruptionWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const {t} = useTranslation();
  const widgetConfig = useMemo(() => new InterruptionWidgetConfig(store, identifier), []);
  const [form] = Form.useForm();
  const scopes = [transition];
  const policy = useModulePolicy(store, scopes);

  const [modalVisible, setModalVisible] = useState(false);
  const [currentInterruptionLog, setCurrentInterruptionLog] = useState(null);
  const [validTransitions, setValidTransitions] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [relevantTaskIds, setRelevantTaskIds] = useState([]);
  const {open: selectPendingTask, inspectionTaskExecutionModalProps} = useInspectionTaskExecutionModal();
  const errorModal = AntModal;

  const quickstops = take(
    store.interruptionReasonStore.quickstopInterruptionReasons,
    widgetConfig.getValues()?.maxAmountQuickstopsToDisplay || 0
  );

  const activateOneClickInterruption = widgetConfig.getSettingValue('settings', 'activateOneClickInterruption');

  useMount(() => {
    const latestWorkplaceLog = store.workplaceStateLogStore.latestBySelectedWorkplace;
    if (latestWorkplaceLog) {
      const latestOperationLog = store.operationStateLogStore.activeBySelectedWorkplace;

      const currentWorkplaceState = latestWorkplaceLog
        ? store.workplaceStateStore.getById(latestWorkplaceLog.stateId)
        : undefined;
      const activeOperationState = latestOperationLog
        ? store.operationStateStore.getById(latestOperationLog.stateId)
        : undefined;
      let latestLog = latestWorkplaceLog;

      if (latestOperationLog && latestOperationLog.start >= latestWorkplaceLog.start) {
        latestLog = latestOperationLog;
      }

      setValidTransitions(store.validTransitionsStore.transitions.filter(
        (item) => (
          (currentWorkplaceState?.isInterruption || activeOperationState?.isInterruption)
            ? !item.activeOperationToState?.isInterruption && !item.workplaceToState?.isInterruption
            : item.activeOperationToState?.isInterruption || item.workplaceToState?.isInterruption
        )
      ));

      if (validTransitions) {
        if (validTransitions.find((vt) => vt.activeOperationToStateId)) {
          store.operationStateStore.loadMany(validTransitions.map((vt) => vt.activeOperationToStateId));
        }
        if (validTransitions.find((vt) => vt.workplaceToStateId)) {
          store.workplaceStateStore.loadMany(validTransitions.map((vt) => vt.workplaceToStateId));
        }
      }

      if (currentWorkplaceState?.isInterruption || activeOperationState?.isInterruption) {
        setCurrentInterruptionLog(latestLog);
      } else {
        setCurrentInterruptionLog(null);
      }
    }
  }, [
    store.workplaceStore.selectedWorkplace?.id,
    JSON.stringify(store.validTransitionsStore.transitions.map((trans) => trans.id)),
    store.operationStateLogStore.activeBySelectedWorkplace,
    store.operationStateLogStore.activeBySelectedWorkplace?.interruptionReasonId,
    store.operationStateLogStore.activeBySelectedWorkplace?.interruptionClassId,
    store.workplaceStateLogStore.latestBySelectedWorkplace,
    store.workplaceStateLogStore.latestBySelectedWorkplace?.interruptionReasonId,
    store.workplaceStateLogStore.latestBySelectedWorkplace?.interruptionClassId,
  ]);

  useMount(() => {
    if (!store.validTransitionsStore.isLoadingCollection && store.workplaceStore.selectedWorkplace) {
      store.validTransitionsStore.loadValidTransitionsByWorkplace(
        store.workplaceStore.selectedWorkplace.id
      );
    }
  }, [store.workplaceStore.selectedWorkplace?.id]);

  const getPendingInspectionTasksContent = async (payload) => {
    await store.inspectionTaskPendingStore.loadByWorkplace(store.workplaceStore.selectedWorkplace.id);
    setRelevantTaskIds(payload.inspectionTaskPendingIds);
    return <PendingTasksList relevantTaskIds={payload.inspectionTaskPendingIds} selectPendingTask={selectPendingTask}/>;
  };

  const handleCustomActionErrors = async (errors) => {
    if (errors.length > 0) {
      const [error, ...errorRest] = errors;

      let title = t(`transition.customActionError.${error.type}`);

      if (!error.isTransitionAllowed) {
        title = t('transition.customActionError.stateTransitionNotAllowed');
      }

      let content = error.message;
      if (error.additionalPayload?.name) {
        if (error.additionalPayload.name === 'stateTransitionNotAllowed.unclassifiedInterruptions') {
          content = t('transition.customActionError.unclassifiedInterruptions');
        }
      }

      let width;
      if (error.additionalPayload?.inspectionTaskPendingIds) {
        content = await getPendingInspectionTasksContent(error.additionalPayload);
        width = '70vw';
      }

      errorModal[error.type]({
        title,
        content,
        width,
        onOk: () => {
          handleCustomActionErrors(errorRest);
        },
      });
    }
  };

  const handleSubmit = async ({...values}) => {
    setIsSubmitting(true);
    const response = await store.transitionStore.performTransition({
      workplaceId: store.workplaceStore.selectedWorkplace?.id,
      body: {
        transitionId: validTransitions.length > 0 ? validTransitions[0].id : undefined,
        activeOperationId: store.operationStore.active?.id,
        timestamp: values.datetime ? values.datetime.milliseconds(0).toISOString() : undefined,
        interruptionReasonId: values.interruptionReasonId || undefined,
        interruptionClassId: values.interruptionClassId || undefined,
        interruptionSourceHierarchyId: values.interruptionSourceHierarchyId || undefined,
        message: values.comment || undefined,
      },
    }).catch((error) => {
      AntModal.error({
        title: t('transition.transitionExecute.errorOnExecute'),
        content: error.message,
      });
      return error;
    });

    if (response?.errors?.length) {
      await handleCustomActionErrors(response.errors);
      setIsSubmitting(false);
      return true;
    }

    setModalVisible(false);
    setIsSubmitting(false);
    form.resetFields();
    store.transitionStore.hasPerformedTransition();

    return !(response.customActionType && response.customActionType === 'PRE');
  };

  const isLoading = () => store.operationStore.isLoadingCollection
    || store.operationStateLogStore.isLoadingCollection
    || store.workplaceStateLogStore.isLoadingCollection;

  const onInterruptionModalCancel = () => {
    form.resetFields();
    setModalVisible(false);
  };

  const executeInterruption = () => {
    if (activateOneClickInterruption) {
      if (!isSubmitting && validTransitions.length > 0) {
        if (validTransitions.length > 1) {
          setModalVisible(true);
        } else {
          handleSubmit({values: []});
        }
      }
    } else {
      setModalVisible(true);
    }
  };

  return (
    <ScopeContext.Provider value={[workflow]}>
      <OperatorWidget
        onHeadClick={(validTransitions?.length > 0) ? () => setModalVisible(true) : null}
        icon={<FontAwesomeIcon icon={faExclamationTriangle}/>}
        title={widgetConfig.getWidgetTitle()}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        manualPath={'/workflow/interruption'}
      >
        {isLoading() && <Spinner fullWidth fullHeight/>}
        {!isLoading() && currentInterruptionLog && (
          <>
            <StopButton
              buttonText={activateOneClickInterruption
                ? widgetConfig.getOneClickStopButtonText()
                : t('interruption.widget.button.stop')}
              disabled={
                !policy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})
                || validTransitions.length === 0
              }
              onClick={() => executeInterruption()}
              size="large"
            />
            <Timer time={currentInterruptionLog.start}/>
          </>
        )}
        {!isLoading() && !currentInterruptionLog && store.validTransitionsStore.hasValidInterruptionTransitions && (
          <>
            <RecordButton
              buttonText={activateOneClickInterruption
                ? widgetConfig.getOneClickRecordButtonText()
                : t('interruption.widget.button.record')}
              disabled={!policy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})}
              onClick={() => executeInterruption()}
              size="large"
            />
            <QuickStopButtons
              quickstops={quickstops}
              disabled={!policy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})}
            />
          </>
        )}
      </OperatorWidget>
      {modalVisible && (
        <InterruptionModal
          title={(
            <>
              <FontAwesomeIcon icon={faExclamationTriangle}/>
              {' '}
              {currentInterruptionLog ? t('interruption.modal.title.active') : t('interruption.modal.title.inactive')}
            </>
          )}
          onCancel={onInterruptionModalCancel}
          onSubmit={handleSubmit}
          form={form}
          isInterruption={currentInterruptionLog}
          currentLog={currentInterruptionLog}
          validTransitions={validTransitions}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          scopeSources={parseInt(widgetConfig.getValues()?.scopeSources || 0, 10)}
          selectSourceLeavesOnly={widgetConfig.getValues()?.selectSourceLeavesOnly}
        />
      )}
      <InspectionTaskExecutionModal
        {...inspectionTaskExecutionModalProps}
        onExecuted={() => {
          const stillPending = InspectionTaskPendingStore.groupTasks(
            store.inspectionTaskPendingStore.getByIds(relevantTaskIds)
          );
          if (stillPending.length === 0) {
            errorModal.destroyAll();
          }
        }}
      />
    </ScopeContext.Provider>
  );
};

InterruptionWidget.icon = faExclamationTriangle;

InterruptionWidget.identifier = 'InterruptionWidget';
InterruptionWidget.defaultLayout = new WidgetLayout(
  {
    identifier: InterruptionWidget.identifier,
    x: 2,
    y: 8,
    height: 3,
    minHeight: 3,
    width: WidgetLayouts.sixthWidth.w,
    minWidth: WidgetLayouts.sixthWidth.minW,
  }
);

export default observer(InterruptionWidget);
