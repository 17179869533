import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Col, Row} from 'antd';
import FormModal from '../shared/modal/FormModal';
import AddButton from '../shared/buttons/AddButton';
import WidgetTable from '../shared/widgets/WidgetTable';
import {useStore} from '../../hooks/useStore';
import {useModulePolicy} from '../../hooks/useModulePolicy';
import {Yield as Model} from '../../models/yield';
import {materialManagement} from '../../models/scope';
import {useMount} from '../../hooks/useMount';
import {smallPaginationPreset} from '../shared/tables/paginationPresets';

export const YieldWidgetModal = ({widgetConfig, onCancel, onAdd}) => {
  const {t} = useTranslation();
  const store = useStore();
  const policy = useModulePolicy(store, [materialManagement]);

  useMount(() => {
    store.yieldStore.loadByOperation(store.operationStore.active?.id);
  });

  return (
    <FormModal
      fullscreen={store.clientStore.isMobile}
      title={(
        <>
          <FontAwesomeIcon icon={Model.faIcon}/>
          {' '}
          {t('yieldWidget.modal.title')}
        </>
        )}
      open
      width={'80%'}
      onCancel={onCancel}
    >
      <Row type={'flex'} justify={'space-between'} style={{marginBottom: '12px'}}>
        <Col xl={8} md={10} sm={16} xs={24}/>
        <Col>
          <AddButton
            onClick={onAdd}
            type={'primary'}
            disabled={!store.operationStore.active
                || !policy.canEdit({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})}
          />
        </Col>
      </Row>
      <WidgetTable
        models={store.yieldStore.getByOperation(store.operationStore.active?.id)
          .filter((y) => !y.yieldType.isInternal)}
        loading={store.yieldStore.isLoadingCollection}
        columns={widgetConfig.getTableColumns()}
        pagination={smallPaginationPreset}
      />
    </FormModal>
  );
};

export default observer(YieldWidgetModal);
