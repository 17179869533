import {executeGet, updateEntity, createEntity, executeDelete} from '../requests';

const path = 'v1/external-resources';

export const ExternalResourceApi = {
  all: ({...params}) => executeGet(path, {params}),
  byId: (id) => executeGet(`${path}/${id}`),
  update: ({id, ...entity}) => updateEntity(entity, `${path}/${id}`),
  create: (entity) => createEntity(entity, path),
  destroy: (id) => executeDelete(`${path}/${id}`),
};
