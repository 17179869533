import { computed, makeObservable, observable } from 'mobx';
import { Dayjs } from 'dayjs';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import i18n from '../i18n/i18n';
import { RootStore } from '../stores/rootStore';
import { Hierarchy } from './hierarchy';

export class Message extends BaseModel {
  id: number = 0;
  orderId: number | null = null;
  materialId: number | null = null;
  documentIds: number[] = [];
  hierarchyIds: number[] = [];
  message: string = '';
  validDateRange: [Dayjs | null, Dayjs | null] = [null, null];
  validFrom: string = '';
  validTo: string = '';
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;
  deletedAt: string | null = null;
  deletedBy: number | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      orderId: observable,
      order: computed,
      materialId: observable,
      material: computed,
      message: observable,
      validFrom: observable,
      validTo: observable,
      documentIds: observable,
      hierarchyIds: observable,
      author: computed,
      subject: computed,
      hierarchyWithLowestId: computed,
      type: computed,
      hasDocuments: computed,
      isUnread: computed,
      reads: computed,
      ...Message.modelChangeDecorations,
    });
  }

  static faIcon = faEnvelope;

  searchableProperties = [
    'subject',
  ];

  static types = {
    order: 'order',
    material: 'material',
    hierarchy: 'hierarchy',
    materialHierarchy: 'materialHierarchy',
  };

  static prepareApiPayload(model: Message) {
    return {
      id: model.id,
      orderId: model.orderId,
      materialId: model.materialId,
      message: model.message || '',
      validFrom: model.validDateRange?.length > 1 && model.validDateRange[0] !== null
        ? model.validDateRange[0].toISOString()
        : undefined,
      validTo: model.validDateRange?.length > 1 && model.validDateRange[1] !== null
        ? model.validDateRange[1].toISOString()
        : undefined,
      documentIds: model.documentIds,
      hierarchyIds: model.hierarchyIds?.length > 0 ? model.hierarchyIds : undefined,
    };
  }

  get author() {
    return this.rootStore.userStore.getById(this.updatedBy);
  }

  get order() {
    return this.rootStore.orderStore.getById(this.orderId);
  }

  get material() {
    return this.rootStore.materialStore.getById(this.materialId);
  }

  get hierarchyWithLowestId() {
    return BaseModel.findInTree<Hierarchy>(this.rootStore.hierarchyStore.hierarchies, Math.min(...this.hierarchyIds));
  }

  get type() {
    if (this.orderId) {
      return Message.types.order;
    }
    if (this.materialId && this.hierarchyIds && this.hierarchyIds.length > 0) {
      return Message.types.materialHierarchy;
    }
    if (this.materialId) {
      return Message.types.material;
    }
    if (this.hierarchyIds && this.hierarchyIds.length > 0) {
      return Message.types.hierarchy;
    }
    return undefined;
  }

  get isUnread() {
    return !this.rootStore.messageReadStore.getByMessageIdAndUserId(this.id, this.rootStore.authStore.user?.id);
  }

  get reads() {
    return this.rootStore.messageReadStore.getByMessageId(this.id);
  }

  get subject() {
    if (this.orderId) {
      return this.order
        ? `${i18n.t('message.model.attributes.order')} ${this.order.no}`
        : '';
    }
    if (this.materialId) {
      return this.material
        ? `${i18n.t('message.model.attributes.material')} ${this.material.no} ${this.material.name}`
        : '';
    }
    if (this.hierarchyIds.length > 0) {
      return this.hierarchyWithLowestId
        ? `${i18n.t('message.model.attributes.hierarchy')} ${this.hierarchyWithLowestId.name}`
        : '';
    }

    return '';
  }

  get hasDocuments() {
    return this.documentIds?.length > 0;
  }
}
