import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { DocumentType } from '../models/documentType';
import { RootStore } from './rootStore';

export class DocumentTypeStore extends EntityStore<DocumentType> {
  documentTypes: DocumentType[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'documentTypes', Api.documentTypes, DocumentType);

    makeObservable(this, {
      documentTypes: observable,
    });
  }
}
