import React from 'react';
import Spinner, { SpinnerProps } from './Spinner';

export type OverlaySpinnerProps = Omit<SpinnerProps, 'fullHeight' | 'fullWidth' | 'style'>;

const OverlaySpinner: React.FC<OverlaySpinnerProps> = ({ ...props }) => (
  <Spinner
    fullWidth
    fullHeight
    style={{
      position: 'absolute',
      zIndex: 4,
      backgroundColor: '#ffffff7f',
    }}
    {...props}
  />
);

export default OverlaySpinner;
