import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {Descriptions} from 'antd';
import Section from './Section';
import InspectionTaskDetails from '../InspectionTaskDetails';

const ResultsSection = ({task}) => {
  const {t} = useTranslation();

  return (
    <>
      <Descriptions title={t('inspectionTask.drilldownModal.section.results')} style={{marginTop: '18px'}}/>
      <Section>
        <InspectionTaskDetails task={task}/>
      </Section>
    </>
  );
};

export default observer(ResultsSection);
