import { Rule } from 'antd/es/form';
import i18n from '../../i18n/i18n';
import { ValidationRules } from '../../utils/validationRules';
import { Operation } from '../../models/operation';
import { OperationWidgetConfig } from '../order/orderDetails/operationWidgetConfig';
import { getAllI18nTranslations } from '../../utils/translations';
import { FieldType } from '../../types/widgetConfig';
import { RootStore } from '../../stores/rootStore';

export const PhaseProgressPlanSource = {
  Operation: 'OPERATION',
  OperationPhase: 'OPERATION_PHASE',
};

export class ActiveOperationWidgetConfig extends OperationWidgetConfig {
  constructor(rootStore: RootStore, identifier: string) {
    super(rootStore, identifier);
    this.tabConfigs.set('widget', [
      {
        id: 'title',
        label: i18n.t<string>('widget.settings.title.label'),
        helpText: i18n.t<string>('widget.settings.title.help'),
        type: FieldType.Translatable,
        defaultValue: getAllI18nTranslations('activeOperationWidget.title'),
        validations: [ValidationRules.required()],
      },
      {
        id: 'properties',
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: Operation.allDisplayableProperties(this.rootStore, '', '', {
          includeConversions: true,
        }),
        defaultValue: [
          'order.no',
          'name',
          'actualStartDatetime',
          'quantityIsShould',
          'material.no',
          'material.name',
        ],
      },
      {
        id: 'barcode',
        label: i18n.t<string>('activeOperationWidget.settings.barcode.label'),
        helpText: i18n.t<string>('activeOperationWidget.settings.barcode.help'),
        type: FieldType.SelectMultiple,
        defaultValue: [],
        validations: [
          ValidationRules.notInField('dataMatrix', i18n.t<string>('activeOperationWidget.settings.dataMatrix.label')),
          ValidationRules.notInField('qrCode', i18n.t<string>('activeOperationWidget.settings.qrCode.label')),
        ] as Rule[],
        options: Operation.allDisplayableProperties(this.rootStore).map((item) => ({
          value: item.key,
          label: item.title,
        })),
      },
      {
        id: 'dataMatrix',
        label: i18n.t<string>('activeOperationWidget.settings.dataMatrix.label'),
        helpText: i18n.t<string>('activeOperationWidget.settings.dataMatrix.help'),
        type: FieldType.SelectMultiple,
        defaultValue: [],
        validations: [
          ValidationRules.notInField('barcode', i18n.t<string>('activeOperationWidget.settings.barcode.label')),
          ValidationRules.notInField('qrCode', i18n.t<string>('activeOperationWidget.settings.qrCode.label')),
        ] as Rule[],
        options: Operation.allDisplayableProperties(this.rootStore).map((item) => ({
          value: item.key,
          label: item.title,
        })),
      },
      {
        id: 'qrCode',
        label: i18n.t<string>('activeOperationWidget.settings.qrCode.label'),
        helpText: i18n.t<string>('activeOperationWidget.settings.qrCode.help'),
        type: FieldType.SelectMultiple,
        defaultValue: [],
        validations: [
          ValidationRules.notInField('barcode', i18n.t<string>('activeOperationWidget.settings.barcode.label')),
          ValidationRules.notInField('dataMatrix', i18n.t<string>('activeOperationWidget.settings.dataMatrix.label')),
        ] as Rule[],
        options: Operation.allDisplayableProperties(this.rootStore).map((item) => ({
          value: item.key,
          label: item.title,
        })),
      },
      {
        id: 'phaseProgress.showPhaseProgress',
        label: i18n.t<string>('activeOperationWidget.settings.modal.phaseProgress.showPhaseProgress.label'),
        type: FieldType.Boolean,
        defaultValue: true,
      },
      {
        id: 'phaseProgress.dataSource',
        label: i18n.t<string>('activeOperationWidget.settings.modal.phaseProgress.dataSource.label'),
        helpText: i18n.t<string>('activeOperationWidget.settings.modal.phaseProgress.dataSource.help'),
        type: FieldType.Select,
        options: [
          {
            value: PhaseProgressPlanSource.Operation,
            label: i18n.t('operation.model.one'),
          },
          {
            value: PhaseProgressPlanSource.OperationPhase,
            label: i18n.t('operationPhase.model.one'),
          },
        ],
        defaultValue: PhaseProgressPlanSource.Operation,
      },
      {
        id: 'phaseProgress.availableColor',
        label: i18n.t<string>('activeOperationWidget.settings.modal.phaseProgress.availableColor.label'),
        type: FieldType.Color,
        defaultValue: '#52C41A',
      },
      {
        id: 'phaseProgress.usedColor',
        label: i18n.t<string>('activeOperationWidget.settings.modal.phaseProgress.usedColor.label'),
        type: FieldType.Color,
        defaultValue: '#1890FF',
      },
      {
        id: 'phaseProgress.overtimeColor',
        label: i18n.t<string>('activeOperationWidget.settings.modal.phaseProgress.overtimeColor.label'),
        type: FieldType.Color,
        defaultValue: '#F5222D',
      },
    ]);
  }
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'widget',
        label: i18n.t('activeOperationWidget.settings.widget'),
      },
      ...super.tabs,
    ];
  }

  getWidgetSetting(identifier: string) {
    return this.getSettingValue('widget', identifier);
  }

  getWidgetTitle() {
    return this.getTranslatableValue('widget', 'title');
  }

  getWidgetPropertyKeys() {
    return this.getSettingValue('widget', 'properties');
  }

  getWidgetProperties() {
    return this.getSelectedProperties('widget', 'properties');
  }

  getBarcodePropertyKeys() {
    return this.getSettingValue('widget', 'barcode');
  }
  getDataMatrixPropertyKeys() {
    return this.getSettingValue('widget', 'dataMatrix');
  }
  getQrCodePropertyKeys() {
    return this.getSettingValue('widget', 'qrCode');
  }
}
