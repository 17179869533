import {WarningFilled} from '@ant-design/icons';
import Button from '../shared/buttons/Button';

export const RecordButton = ({buttonText, ...props}) => (
  <Button
    type="primary"
    danger
    block
    hideOverflow
    {...props}
  >
    <WarningFilled/>
    {buttonText}
  </Button>
);
