import {BaseWidgetConfig} from '../shared/widgets/baseWidgetConfig';
import i18n from '../../i18n/i18n';
import {ValidationRules} from '../../utils/validationRules';
import {Material} from '../../models/material';
import {Component} from '../../models/component';
import {getAllI18nTranslations} from '../../utils/translations';
import {FieldType} from '../../types/widgetConfig';

export class BatchQueueWidgetConfig extends BaseWidgetConfig {
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'settings',
        label: i18n.t('widget.WidgetSettingsModal.settingConfigTabTitle'),
      },
    ];
  }

  settings = [
    {
      id: 'title',
      label: i18n.t('widget.settings.title.label'),
      helpText: i18n.t('widget.settings.title.help'),
      type: FieldType.Translatable,
      defaultValue: getAllI18nTranslations('batchQueueWidget.config.settings.title.defaultValue'),
      validations: [ValidationRules.required()],
    },
    {
      id: 'properties',
      type: FieldType.PropertiesSelectDisplayField,
      modelOptions: [
        ...Material.allDisplayableProperties(this.rootStore, 'material.')
          .filter((m) => m.key === 'material.no' || m.key === 'material.name'),
        ...Component.allDisplayableProperties(this.rootStore).filter((c) => c.key === 'unitOfMeasure'),
      ],
      defaultValue: ['material.name', 'unitOfMeasure'],
    },
    {
      id: 'showSensorStateManagementList',
      label: i18n.t('batchQueueWidget.settings.showSensorStateManagementList.label'),
      helpText: i18n.t('batchQueueWidget.settings.showSensorStateManagementList.help'),
      type: FieldType.Boolean,
      defaultValue: true,
    },
  ];

  getWidgetSetting(identifier) {
    return this.getSettingValue('settings', identifier);
  }

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'title');
  }

  showSensorStateManagementList() {
    return this.getSettingValue('settings', 'showSensorStateManagementList');
  }
}
