import {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import CockpitWidget from './CockpitWidget';
import {WidgetLayout} from '../../../../models/widgetLayout';
import {WidgetLayouts} from '../../../shared/widgets/WidgetLayouts';
import {ImageCockpitWidgetConfig} from './config/imageCockpitWidgetConfig';
import ShowDocumentImage from '../../../document/ShowDocumentImage';
import {useMount} from '../../../../hooks/useMount';
import {useStore} from '../../../../hooks/useStore';

const ImageCockpitWidget = ({
  widgetId,
  title,
  workplaceId,
  ...props
}) => {
  const store = useStore();
  const widgetConfig = useMemo(() => new ImageCockpitWidget.Config({
    rootStore: store,
    identifier: ImageCockpitWidget.Config.widgetType.identifier,
    widgetId,
  }), []);

  const documentToShow = store.documentStore.getById(widgetConfig.getSettingValue('image', 'documentId'));

  useMount(() => {
    store.documentStore.loadAll();
  });

  return (
    <CockpitWidget widgetId={widgetId} title={title} widgetConfig={widgetConfig} {...props}>
      {documentToShow ? <ShowDocumentImage document={documentToShow}/> : null}
    </CockpitWidget>
  );
};

ImageCockpitWidget.Config = ImageCockpitWidgetConfig;
ImageCockpitWidget.defaultLayout = new WidgetLayout({
  height: 5,
  width: 2,
  identifier: CockpitWidget.identifier,
  minHeight: 1,
  minWidth: WidgetLayouts.sixthWidth.minW,
});
export default observer(ImageCockpitWidget);
