import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import InspectionTaskDescription from './InspectionTaskDescription';
import {transformation} from '../../../../utils/transformations';
import ConfirmationValue from './ConfirmationValue';
import {useStore} from '../../../../hooks/useStore';

const TimesAndIntervalsSection = ({fallback, task}) => {
  const {t} = useTranslation();
  const store = useStore();

  const durationMs = dayjs(task.inspectionLot.validEnd) - dayjs(task.inspectionLot.validStart);
  const formatPhases = (phaseIds) => store.phaseStore.getByIds(phaseIds)
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map((phase) => phase?.name)
    .join(', ');

  return (
    <InspectionTaskDescription title={t('inspectionTask.drilldownModal.section.timesAndIntervals')}>
      <InspectionTaskDescription.Item label={t('inspectionTask.model.attributes.interval.label')}>
        {transformation.durationOrDash({unit: 'minutes'})(task.interval)}
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item label={t('inspectionTask.model.attributes.isAbsoluteInterval.label')}>
        <ConfirmationValue bool={task.isAbsoluteInterval}/>
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.intervalNotAccepted.label')}
      >
        {transformation.durationOrDash({unit: 'minutes'})(task.intervalNotAccepted)}
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.maxOverdueAllowed.label')}
      >
        {fallback(task.maxOverdueAllowed, (transformation.duration({unit: 'minutes'})))}
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.checkAtStart.label')}
      >
        <ConfirmationValue bool={task.checkAtStart}/>
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.checkAtStartOffset.label')}
      >
        {fallback(task.checkAtStartOffset, (transformation.duration({unit: 'minutes'})))}
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item label={t('inspectionTask.model.attributes.checkAtEnd.label')}>
        <ConfirmationValue bool={task.checkAtEnd}/>
        {task.checkAtEnd && (
          ` (${t('inspectionTask.drilldownModal.mes.minValid', {
            value: fallback(task.intervalEnd, transformation.duration({unit: 'minutes'})),
          })})`
        )}
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.dueDateFromInspectionLot')}
      >
        {fallback(task.dueDateFromInspectionLot, transformation.percent)}
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.dueDateFromInspectionLotMin')}
      >
        {fallback(
          (task.dueDateFromInspectionLot / 100) * durationMs,
          transformation.duration({unit: 'ms'})
        )}
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.checkAtException.label')}
      >
        <ConfirmationValue bool={task.checkAtException}/>
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.exceptionSourcePhaseIds.label')}
      >
        {fallback(formatPhases(task.exceptionSourcePhaseIds))}
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.checkAtExceptionLimit.label')}
      >
        {fallback(task.checkAtExceptionLimit, (transformation.duration({unit: 'minutes'})))}
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.checkAtExceptionOffset.label')}
      >
        {fallback(task.checkAtExceptionOffset, (transformation.duration({unit: 'minutes'})))}
      </InspectionTaskDescription.Item>
    </InspectionTaskDescription>
  );
};

export default observer(TimesAndIntervalsSection);
