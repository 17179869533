import { computed, makeObservable, observable } from 'mobx';
import { Dayjs } from 'dayjs';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { OperationStateLog } from '../models/operationStateLog';
import { sortChronologically } from '../components/shared/tables/sorters';
import { RootStore } from './rootStore';

interface LoadManyByWorkplaceProps {
  fromDate: Dayjs;
  toDate: Dayjs;
  isInterruption?: boolean;
  interruptionReasonId?: number | string;
}

export class OperationStateLogStore extends EntityStore<OperationStateLog> {
  logs: OperationStateLog[] = [];
  selected = null;

  constructor(rootStore: RootStore) {
    super(rootStore, 'logs', Api.operationStateLogs, OperationStateLog);

    makeObservable(this, {
      selected: observable,
      logs: observable,
      activeBySelectedWorkplace: computed,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.userStore,
        modelId: 'createdBy',
      },
    ];
  }

  loadManyByWorkplace(
    workplaceId: number,
    { fromDate, toDate, isInterruption, interruptionReasonId }: LoadManyByWorkplaceProps
  ) {
    return this.loadAll({
      params: {
        workplaceId,
        fromDate: fromDate.toDate(),
        toDate: toDate.toDate(),
        isInterruption,
        interruptionReasonId,
      },
    }).then((results) => {
      this.rootStore.operationStore.loadMany(results.map((log) => log.operationId));

      return results;
    });
  }

  loadByOperation(operationId: number) {
    return this.loadAllWithDependencies({ params: { operationId } });
  }

  getByWorkplaceId(workplaceId: number) {
    return this.logs.filter((log) => log.operation?.workplaceId === workplaceId);
  }

  getLatestOfWorkplace(workplaceId: number) {
    const wpLogs = this.getByWorkplaceId(workplaceId);
    return wpLogs.reduce((newest: OperationStateLog | null, next) => {
      if (!newest || newest.start < next.start) {
        return next;
      }
      return newest;
    }, null);
  }

  getActiveOfWorkplace(workplaceId: number) {
    const log = this.getLatestOfWorkplace(workplaceId);
    if (log && !log.end) {
      return log;
    }
    return null;
  }

  getByStateIdAndWorkplaceId(stateId: number, workplaceId: number) {
    return this.logs.filter((log) => log.stateId === stateId && log.operation?.workplaceId === workplaceId);
  }

  getLatestByStateAndWorkplace(stateId: number, workplaceId: number) {
    const wpLogs = this.getByStateIdAndWorkplaceId(stateId, workplaceId);
    return wpLogs.reduce((newest: OperationStateLog | null, next) => {
      if (!newest || newest.start < next.start) {
        return next;
      }
      return newest;
    }, null);
  }

  getUnclassifiedByWorkplaceId(workplaceId: number) {
    return this.logs.filter(
      (log) => log.operation?.workplaceId === workplaceId && log.state?.isInterruption && !log.interruptionReasonId
    );
  }

  getInterruptionsByOperationIds(operationIds: number[]) {
    return this.logs.filter((log) =>
      operationIds.find((operationId) => log.operationId === operationId) && log.state?.isInterruption);
  }

  get activeBySelectedWorkplace() {
    if (this.rootStore.workplaceStore.selectedWorkplace) {
      const opLogs = this.getByWorkplaceId(this.rootStore.workplaceStore.selectedWorkplace.id);
      const log = opLogs.reduce((newest: OperationStateLog | null, next) => {
        if (!newest || newest.start < next.start) {
          return next;
        }
        return newest;
      }, null);
      if (log && !log.end) {
        return log;
      }
    }
    return null;
  }

  getFirstByOperationId(operationId: number) {
    return this.logs
      .filter((log) => log.operationId === operationId)
      .sort((a, b) => sortChronologically(a.start, b.start))[0];
  }
}
