import dayjs from 'dayjs';
import {useState} from 'react';
import {observer, Observer} from 'mobx-react-lite';
import {faPauseCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Space} from 'antd';
import {useTranslation} from 'react-i18next';
import styles from './InspectionTaskPendingWidget.module.scss';
import WidgetTable from '../shared/widgets/WidgetTable';
import {useStore} from '../../hooks/useStore';
import {EnDash} from '../shared/unicodeWrapper/EnDash';
import {formatDuration} from '../../utils/formatting';
import {detailedDurationFormat} from '../../config/dayjs';
import Spinner from '../shared/spinners/Spinner';
import {getIcons} from './getIcons';
import useTimedRerender from '../../hooks/useTimedRerender';
import {useMount} from '../../hooks/useMount';

const InspectionTaskPendingTable = ({loading, filterSilent, onRowClick, widgetConfig, ...props}) => {
  const store = useStore();
  const [configColumns, setConfigColumns] = useState([]);
  const [rerender, setRerender] = useState(false);

  const {t} = useTranslation();

  useTimedRerender();
  const showSilentInspections = !!widgetConfig?.getWidgetSetting('showSilentInspections');

  let dataSource = store.inspectionTaskPendingStore.getGroupedByWorkplaceId(
    store.workplaceStore.selectedWorkplace?.id
  );
  if (filterSilent) {
    if (!showSilentInspections) {
      dataSource = dataSource.filter((task) => !task.isSilent);
      if (props.dataSource) {
        props.dataSource = props.dataSource.filter((task) => !task.isSilent);
      }
    }
  }
  const showInspectionTaskGroupIcon = !!widgetConfig?.getWidgetSetting('showInspectionTaskGroupIcon');

  const isTooEarly = (pending) => {
    if (pending.inspectionTask.isAbsoluteInterval) {
      return (dayjs().valueOf()
        < dayjs(pending.dueDate).subtract(pending.inspectionTask.interval, 'minutes').valueOf());
    }
    return false;
  };

  useMount(() => {
    setRerender(!rerender);
  }, [
    store.inspectionTaskPendingStore.areCollectionOrDependenciesLoading,
  ]);

  useMount(() => {
    const cols = widgetConfig?.getWidgetTableColumns().map((column) => {
      if (column.key === 'dueDate') {
        return {
          title: column.label,
          ...column,
          render: (value, pendingTask) => {
            if (pendingTask.pausedSince) {
              return (
                <Space>
                  <FontAwesomeIcon icon={faPauseCircle}/>
                  {t('inspectionTaskPendingWidget.paused')}
                </Space>
              );
            }
            if (!pendingTask.dueDate) {
              return EnDash();
            }
            const duration = dayjs.duration(dayjs(pendingTask.dueDate).diff(dayjs()));
            const dueDateIsBeforeNow = dayjs(pendingTask.dueDate).isBefore(dayjs());
            return (
              <span className={dueDateIsBeforeNow ? styles.redText : null}>
                {formatDuration(duration, detailedDurationFormat)}
              </span>
            );
          },
        };
      }
      if (column.key === 'inspectionTask.name') {
        return {
          ...column,
          render: (value, pendingTask) => (
            <Observer>
              {() => {
                const group = store.inspectionTaskGroupStore.getById(pendingTask.inspectionTask.inspectionTaskGroupId);
                return group?.name || pendingTask.inspectionTask?.name || EnDash();
              }}
            </Observer>
          ),
        };
      }
      return column;
    }) || [];

    setConfigColumns(cols);
  }, [widgetConfig]);

  const columns = [
    {
      title: '',
      key: 'icons',
      render: (value, pendingTask) => {
        let taskIcon;
        if (pendingTask.inspectionLot === undefined) {
          taskIcon = <Spinner size={'small'}/>;
        } else {
          taskIcon = getIcons(pendingTask, showInspectionTaskGroupIcon, store.authStore.user?.roleIds, true, store)
            .map(({icon, style}) => <FontAwesomeIcon key={icon.iconName} icon={icon} size="1x" style={style}/>);
        }
        return (
          <Space>
            {taskIcon}
          </Space>
        );
      },
    },
    ...configColumns,
  ];

  const rowClassNames = (record) => {
    const classNames = ['pointable'];

    if (record.pausedSince || isTooEarly(record)) {
      classNames.push(styles.paused);
      return classNames.join(' ');
    }

    if (dayjs(record.dueDate).isBefore(dayjs())) {
      classNames.push(styles.alert);
      return classNames.join(' ');
    }

    return classNames.join(' ');
  };

  return (
    <div className={styles.inspectionTaskPendingTableWrapper}>
      <WidgetTable
        loading={loading}
        dataSource={dataSource}
        rowKey={'id'}
        onRow={(record) => ({onClick: () => onRowClick(record)})}
        rowClassName={rowClassNames}
        columns={columns}
        {...props}
      />
    </div>
  );
};

export default observer(InspectionTaskPendingTable);
