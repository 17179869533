import ReorderButton from '../../shared/buttons/ReorderButton';
import CancelButton from '../../shared/buttons/CancelButton';
import SaveButton from '../../shared/buttons/SaveButton';

const ReorderingButtons = ({
  store,
  orders,
  // eslint-disable-next-line no-unused-vars
  useIsReordering = [undefined, (value) => false],
  // eslint-disable-next-line no-unused-vars
  useSortableOrders = [[], (value) => undefined],
  reorderDisabled = false,
}) => {
  const [isReordering, setIsReordering] = useIsReordering;
  const [sortableOrders, setSortableOrders] = useSortableOrders;

  const calculateTmpSortOrder = (data) => {
    // set sortOrder as tmpSortOrder
    data.forEach((item) => {
      item.tmpSortOrder = item.sortOrder;
    });

    // when sortOrder of first record is the same or after the sortOrder of second record
    if (data.length > 1 && data[0].tmpSortOrder >= data[1].tmpSortOrder) {
      // set a new sortOrder of first record
      data[0].tmpSortOrder = data[1].tmpSortOrder - 0.5;
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < data.length; i++) {
      if (data[i].tmpSortOrder < data[i - 1].tmpSortOrder) {
        // if sortOrder of current record is before sortOrder of previous record
        // switch sortOrder of both records
        const currentItem = data[i].tmpSortOrder;
        const itemBefore = data[i - 1].tmpSortOrder;
        data[i].tmpSortOrder = itemBefore;
        data[i - 1].tmpSortOrder = (currentItem + itemBefore) / 2;
      } else if (data[i].tmpSortOrder === data[i - 1].tmpSortOrder) {
        // if sortOrder of current record is equal to the sortOrder of previous record ...
        if (data[i + 1] && data[i + 1].tmpSortOrder > data[i - 1].tmpSortOrder) {
          // ... and the current record is between two other records in correct order, use average as sortOrder
          data[i].tmpSortOrder = (data[i + 1].tmpSortOrder + data[i - 1].tmpSortOrder) / 2;
        } else {
          // ... change sortOrder of previous record (use average of the current record and the pre-previous record)
          const currentItem = data[i].tmpSortOrder;
          const itemBeforeBefore = data[i - 2].tmpSortOrder;
          data[i - 1].tmpSortOrder = (currentItem + itemBeforeBefore) / 2;
        }
      }
    }

    return data;
  };

  const startReordering = () => {
    setSortableOrders(calculateTmpSortOrder(orders));
    setIsReordering(true);
  };

  const abortReordering = () => {
    setIsReordering(false);
    setSortableOrders([]);
  };

  const saveReordering = async () => {
    let skipNotification = false;
    const updates = [];
    // Create a copy of the tmpSortOrders, otherwise, after saving the first change, 'populateAttributesFromStore'
    // can overwrite pending changes with the original 'sortOrder'.
    const newSortOrders = sortableOrders.map((o) => ({id: o.id, tmpSortOrder: o.tmpSortOrder}));
    orders.forEach((item) => {
      const sortedItem = newSortOrders.find((i) => i.id === item.id);
      if (item.sortOrder !== sortedItem.tmpSortOrder) {
        item.sortOrder = sortedItem.tmpSortOrder;
        if (item.children) {
          item.children.forEach((child, index) => {
            item.children[index].sortOrder = sortedItem.tmpSortOrder + (index * 0.001);
            updates.push(store.operationStore.reorder({
              id: item.children[index].id,
              sortOrder: item.children[index].sortOrder,
            }, {skipNotification}));
          });
        } else {
          updates.push(store.operationStore.reorder({
            id: item.id,
            sortOrder: item.sortOrder,
          }, {skipNotification}));
        }
        skipNotification = true;
      }
    });
    await Promise.all(updates);
    setIsReordering(false);
  };

  return (
    <>
      {!isReordering && (
        <ReorderButton disabled={orders.length < 2 || reorderDisabled} onClick={startReordering}/>
      )}
      {isReordering && (
        <>
          <CancelButton
            style={{marginRight: '10px'}}
            onClick={abortReordering}
          />
          <SaveButton
            type={'primary'}
            onClick={saveReordering}
          />
        </>
      )}
    </>
  );
};

export default ReorderingButtons;
