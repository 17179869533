import { observable, makeObservable, computed } from 'mobx';
import {
  faBagsShopping,
  faComment, faFileExport, faFileUser,
  faGears,
  faHistory,
  faPrint,
  faSparkles, faSquarePollVertical,
  faTasks,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { Observer } from 'mobx-react-lite';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import i18n from '../i18n/i18n';
import { displayableProperty } from './displayableProperty';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import { datetimeFormat } from '../config/dayjs';
import { sortAlphabetically, sortChronologically } from '../components/shared/tables/sorters';
import MarkdownItem from '../components/shared/MarkdownItem';
import { Material as MaterialModel } from './material';
import { Order as OrderModel } from './order';
import { InspectionLot } from './inspectionLot';
import { ExternalResource as ExternalResourceModel } from './externalResource';
import { EnDash } from '../components/shared/unicodeWrapper/EnDash';
import UserFullName from '../components/shared/UserFullName';
import { RootStore } from '../stores/rootStore';

export const MAX_LOGS = 1000;

/**
 * event context translations:
 * t('eventLog.contexts.CUSTOM_ACTION')
 * t('eventLog.contexts.MATERIAL')
 * t('eventLog.contexts.CONSUMPTION')
 * t('eventLog.contexts.ORDER')
 * t('eventLog.contexts.PERSONNEL')
 * t('eventLog.contexts.SECURITY')
 * t('eventLog.contexts.STATE_CHANGE')
 * t('eventLog.contexts.SYSTEM')
 * t('eventLog.contexts.TASK')
 * t('eventLog.contexts.USER')
 * t('eventLog.contexts.WORKFLOW_CORRECTION')
 * t('eventLog.contexts.INSPECTION_IMPORT')
 * t('eventLog.contexts.EXTERNAL_RESOURCE')
 * t('eventLog.contexts.INTERRUPTION')
 * t('eventLog.contexts.DATA_EXPORT')
 * t('eventLog.contexts.MESSAGE')
 * t('eventLog.contexts.PRINT_MANAGEMENT')
 * t('eventLog.contexts.REPORT_LOG')
 */
export enum EventLogContext {
  CustomAction = 'CUSTOM_ACTION',
  Material = 'MATERIAL',
  Consumption = 'CONSUMPTION',
  Order = 'ORDER',
  Personnel = 'PERSONNEL',
  Security = 'SECURITY',
  StateChange = 'STATE_CHANGE',
  System = 'SYSTEM',
  Task = 'TASK',
  User = 'USER',
  WorkflowCorrection = 'WORKFLOW_CORRECTION',
  ExternalResource = 'EXTERNAL_RESOURCE',
  Interruption = 'INTERRUPTION',
  DataExport = 'DATA_EXPORT',
  Message = 'MESSAGE',
  PrintManagement = 'PRINT_MANAGEMENT',
  ReportLog = 'REPORT_LOG'
}

export type ContextIconMap = {
  [key in EventLogContext]: IconDefinition;
};

export const contextIconMap: ContextIconMap = {
  [EventLogContext.CustomAction]: faSparkles,
  [EventLogContext.Material]: faGears,
  [EventLogContext.Consumption]: faHistory,
  [EventLogContext.Order]: faBagsShopping,
  [EventLogContext.Personnel]: faFileUser,
  [EventLogContext.Security]: faLock,
  [EventLogContext.StateChange]: faHistory,
  [EventLogContext.System]: faHistory,
  [EventLogContext.Task]: faTasks,
  [EventLogContext.User]: faUser,
  [EventLogContext.WorkflowCorrection]: faHistory,
  [EventLogContext.ExternalResource]: faHistory,
  [EventLogContext.Interruption]: faHistory,
  [EventLogContext.DataExport]: faFileExport,
  [EventLogContext.Message]: faComment,
  [EventLogContext.PrintManagement]: faPrint,
  [EventLogContext.ReportLog]: faSquarePollVertical,
};

/**
 * event log-source translations:
 * t('eventLog.sources.EXTERNAL')
 * t('eventLog.sources.SYSTEM')
 */
export enum EventLogSource {
  External = 'EXTERNAL',
  System = 'SYSTEM'
}

/**
 * event log-level translations:
 * t('eventLog.logLevels.1')
 * t('eventLog.logLevels.2')
 * t('eventLog.logLevels.3')
 * t('eventLog.logLevels.4')
 */
export enum EventLogLevel {
  Info = 1,
  Warning,
  Error,
  Fatal
}

/**
 * event events translations:
 * t('eventLog.events.RELOAD_LABELS')
 * t('eventLog.events.HANDLE_SUCCESS')
 * t('eventLog.events.HANDLE_FAILURE')
 * t('eventLog.events.RUN_QUEUED_TRANSITION')
 * t('eventLog.events.CREATE')
 * t('eventLog.events.UPDATE')
 * t('eventLog.events.PERFORM_CHANGE_TRANSITION')
 * t('eventLog.events.PERFORM_TRANSITION')
 * t('eventLog.events.ABORT')
 * t('eventLog.events.PERFORM_INITIAL_TRANSITION')
 * t('eventLog.events.PERFORM_FINAL_TRANSITION')
 * t('eventLog.events.HANDLE_VALIDATION_ERROR')
 * t('eventLog.events.HANDLE_TRANSITION_ERROR')
 * t('eventLog.events.PUBLISH_NEWLY_VALID')
 * t('eventLog.events.CHANGE_EVENT_LOGGER')
 * t('eventLog.events.PERFORM_QUICKSTOP')
 * t('eventLog.events.CREATE_FROM_INGREDIENT')
 * t('eventLog.events.CREATE_LOGS')
 * t('eventLog.events.REACTIVATE')
 * t('eventLog.events.ACTIVATE')
 * t('eventLog.events.HANDLE_REPORT_SENDING')
 * t('eventLog.events.LOG_ATTEMPT')
 * t('eventLog.events.LOG_LOGIN')
 * t('eventLog.events.LOG_LOGOUT')
 * t('eventLog.events.LOG_LOGOUT_ATTEMPT')
 * t('eventLog.events.LOG_TERMINAL_ATTEMPT')
 * t('eventLog.events.LOG_TERMINAL_LOGIN')
 * t('eventLog.events.PERFORM_TRANSITION_WITH_WORKPLACE_INTERRUPTION')
 * t('eventLog.events.PERFORM_TRANSITION_WITH_ACTIVE_INTERRUPTION')
 * t('eventLog.events.DEACTIVATE_LOT')
 * t('eventLog.events.DEACTIVATE_TASK')
 */
export enum EventLogEvent {
  ReloadLabels = 'RELOAD_LABELS',
  HandleSuccess = 'HANDLE_SUCCESS',
  HandleFailure = 'HANDLE_FAILURE',
  RunQueuedTransition = 'RUN_QUEUED_TRANSITION',
  Create = 'CREATE',
  Update = 'UPDATE',
  PerformChangeTransition = 'PERFORM_CHANGE_TRANSITION',
  PerformTransition = 'PERFORM_TRANSITION',
  Abort = 'ABORT',
  PerformInitialTransition = 'PERFORM_INITIAL_TRANSITION',
  PerformFinalTransition = 'PERFORM_FINAL_TRANSITION',
  HandleValidationError = 'HANDLE_VALIDATION_ERROR',
  HandleTransitionError = 'HANDLE_TRANSITION_ERROR',
  PublishNewlyValid = 'PUBLISH_NEWLY_VALID',
  ChangeEventLogger = 'CHANGE_EVENT_LOGGER',
  PerformQuickstop = 'PERFORM_QUICKSTOP',
  CreateFromIngredient = 'CREATE_FROM_INGREDIENT',
  CreateLogs = 'CREATE_LOGS',
  Reactivate = 'REACTIVATE',
  Activate = 'ACTIVATE',
  LogAttempt = 'LOG_ATTEMPT',
  LogLogin = 'LOG_LOGIN',
  LogLogout = 'LOG_LOGOUT',
  LogLogoutAttempt = 'LOG_LOGOUT_ATTEMPT',
  LogTerminalAttempt = 'LOG_TERMINAL_ATTEMPT',
  LogTerminalLogin = 'LOG_TERMINAL_LOGIN',
  PerformTransitionWithWorkplaceInterruption = 'PERFORM_TRANSITION_WITH_WORKPLACE_INTERRUPTION',
  PerformTransitionWithActiveInterruption = 'PERFORM_TRANSITION_WITH_ACTIVE_INTERRUPTION',
  DeactivateLot = 'DEACTIVATE_LOT',
  DeactivateTask = 'DEACTIVATE_TASK'
}

/**
 * event subjects translations:
 * t('eventLog.subjects.INVALID_TRANSITION')
 * t('eventLog.subjects.UNSUCCESSFUL_TRANSITION_PRE_CUSTOM_ACTIONS')
 * t('eventLog.subjects.UNSUCCESSFUL_TRANSITION_ERROR')
 * t('eventLog.subjects.WITH_CLASSIFICATION')
 * t('eventLog.subjects.WITHOUT_CLASSIFICATION')
 * t('eventLog.subjects.NO_STATE_ID')
 * t('eventLog.subjects.ACTIVE_OPERATION_STATE_ID')
 * t('eventLog.subjects.STARTING_OPERATION_STATE_ID')
 * t('eventLog.subjects.WITH_TIMESTAMP')
 * t('eventLog.subjects.WITHOUT_TIMESTAMP')
 * t('eventLog.subjects.QUALITATIVE')
 * t('eventLog.subjects.QUANTITATIVE')
 * t('eventLog.subjects.NO_QUICKSTOP_ERROR')
 * t('eventLog.subjects.WORKPLACE_TO_STATE_ERROR')
 * t('eventLog.subjects.ACTIVE_OPERATION_TO_STATE_ERROR')
 * t('eventLog.subjects.ACTIVE_OPERATION_STATE_LOG_ERROR')
 * t('eventLog.subjects.FIND_OPTIONS_ERROR')
 * t('eventLog.subjects.QUICKSTOP_TRANSITION_ERROR')
 * t('eventLog.subjects.START_DATE_ERROR')
 * t('eventLog.subjects.SUCCESS')
 * t('eventLog.subjects.FAILURE')
 * t('eventLog.subjects.USER_ID')
 * t('eventLog.subjects.OVERRULED_USER_ID')
 * t('eventLog.subjects.STARTED')
 * t('eventLog.subjects.FINISHED')
 * t('eventLog.subjects.ERROR')
 * t('eventLog.subjects.WORKPLACE_SKIPPED')
 * t('eventLog.subjects.OPERATION')
 * t('eventLog.subjects.WORKPLACE')
 * t('eventLog.subjects.SUCCESS_TRANSITION_ALLOWED')
 * t('eventLog.subjects.SUCCESS_TRANSITION_NOT_ALLOWED')
 * t('eventLog.subjects.CONSUMPTION')
 * t('eventLog.subjects.FULL_ORDER')
 * t('eventLog.subjects.INSPECTION_RESULT')
 * t('eventLog.subjects.OPERATION_PERFORMANCE')
 * t('eventLog.subjects.QUALITY_REPORT')
 * t('eventLog.subjects.PHASE_PERFORMANCE')
 * t('eventLog.subjects.PRINT_LABEL_CODE')
 * t('eventLog.subjects.WORKPLACE_PERFORMANCE')
 * t('eventLog.subjects.YIELD')
 */
export enum EventLogSubject {
  InvalidTransition = 'INVALID_TRANSITION',
  UnsuccessfulTransitionPreCustomActions = 'UNSUCCESSFUL_TRANSITION_PRE_CUSTOM_ACTIONS',
  UnsuccessfulTransitionError = 'UNSUCCESSFUL_TRANSITION_ERROR',
  WithClassification = 'WITH_CLASSIFICATION',
  WithoutClassification = 'WITHOUT_CLASSIFICATION',
  NoStateId = 'NO_STATE_ID',
  ActiveOperationStateId = 'ACTIVE_OPERATION_STATE_ID',
  StartingOperationStateId = 'STARTING_OPERATION_STATE_ID',
  WithTimestamp = 'WITH_TIMESTAMP',
  WithoutTimestamp = 'WITHOUT_TIMESTAMP',
  Qualitative = 'QUALITATIVE',
  Quantitative = 'QUANTITATIVE',
  NoQuickstopError = 'NO_QUICKSTOP_ERROR',
  WorkplaceToStateError = 'WORKPLACE_TO_STATE_ERROR',
  ActiveOperationToStateError = 'ACTIVE_OPERATION_TO_STATE_ERROR',
  ActiveOperationStateLogError = 'ACTIVE_OPERATION_STATE_LOG_ERROR',
  FindOptionsError = 'FIND_OPTIONS_ERROR',
  QuickstopTransitionError = 'QUICKSTOP_TRANSITION_ERROR',
  StartDateError = 'START_DATE_ERROR',
  Success = 'SUCCESS',
  Failure = 'FAILURE',
  UserId = 'USER_ID',
  OverruledUserId = 'OVERRULED_USER_ID',
  Started = 'STARTED',
  Finished = 'FINISHED',
  Error = 'ERROR',
  WorkplaceSkipped = 'WORKPLACE_SKIPPED',
  Operation = 'OPERATION',
  Workplace = 'WORKPLACE',
  SuccessTransitionAllowed = 'SUCCESS_TRANSITION_ALLOWED',
  SuccessTransitionNotAllowed = 'SUCCESS_TRANSITION_NOT_ALLOWED',
  Consumption = 'CONSUMPTION',
  FullOrder = 'FULL_ORDER',
  InspectionResult = 'INSPECTION_RESULT',
  OperationPerformance = 'OPERATION_PERFORMANCE',
  QualityReport = 'QUALITY_REPORT',
  PhasePerformance = 'PHASE_PERFORMANCE',
  PrintLabelCode = 'PRINT_LABEL_CODE',
  WorkplacePerformance = 'WORKPLACE_PERFORMANCE',
  Yield = 'YIELD'
}
export interface TreeNode {
  subject: EventLogSubject;
}

export interface EventNode {
  event: EventLogEvent;
  children?: TreeNode[];
}

export interface ContextNode {
  context: EventLogContext;
  children: EventNode[];
}

const withWithoutTimestamp: TreeNode[] = [
  { subject: EventLogSubject.WithTimestamp },
  { subject: EventLogSubject.WithoutTimestamp },
];

const withWithoutClassification: TreeNode[] = [
  { subject: EventLogSubject.WithClassification },
  { subject: EventLogSubject.WithoutClassification },
];

const successFailure: TreeNode[] = [
  { subject: EventLogSubject.Success },
  { subject: EventLogSubject.Failure },
];

const reportLogTypes: TreeNode[] = [
  { subject: EventLogSubject.Consumption },
  { subject: EventLogSubject.FullOrder },
  { subject: EventLogSubject.InspectionResult },
  { subject: EventLogSubject.OperationPerformance },
  { subject: EventLogSubject.QualityReport },
  { subject: EventLogSubject.PhasePerformance },
  { subject: EventLogSubject.PrintLabelCode },
  { subject: EventLogSubject.WorkplacePerformance },
  { subject: EventLogSubject.Yield },
];

export const ContextEventSubjectTree: ContextNode[] = [
  {
    context: EventLogContext.Message,
    children: [
      {
        event: EventLogEvent.PublishNewlyValid,
      },
    ],
  },
  {
    context: EventLogContext.Interruption,
    children: [
      {
        event: EventLogEvent.Update,
      },
      {
        event: EventLogEvent.PerformChangeTransition,
        children: withWithoutClassification,
      },
      {
        event: EventLogEvent.PerformTransition,
        children: withWithoutClassification,
      },
      {
        event: EventLogEvent.PerformTransitionWithWorkplaceInterruption,
        children: withWithoutClassification,
      },
      {
        event: EventLogEvent.PerformTransitionWithActiveInterruption,
        children: withWithoutClassification,
      },
    ],
  },
  {
    context: EventLogContext.StateChange,
    children: [
      {
        event: EventLogEvent.PerformTransition,
        children: [
          {
            subject: EventLogSubject.NoStateId,
          },
          {
            subject: EventLogSubject.ActiveOperationStateId,
          },
          {
            subject: EventLogSubject.StartingOperationStateId,
          },
          {
            subject: EventLogSubject.WorkplaceToStateError,
          },
          {
            subject: EventLogSubject.WorkplaceSkipped,
          },
          ...withWithoutTimestamp,
        ],
      },
      {
        event: EventLogEvent.Abort,
      },
      {
        event: EventLogEvent.PerformInitialTransition,
        children: withWithoutTimestamp,
      },
      {
        event: EventLogEvent.PerformChangeTransition,
        children: withWithoutTimestamp,
      },
      {
        event: EventLogEvent.PerformFinalTransition,
        children: withWithoutTimestamp,
      },
      {
        event: EventLogEvent.HandleValidationError,
        children: [
          {
            subject: EventLogSubject.Operation,
          },
          {
            subject: EventLogSubject.Workplace,
          },
        ],
      },
      {
        event: EventLogEvent.HandleTransitionError,
      },
      {
        event: EventLogEvent.RunQueuedTransition,
        children: [
          {
            subject: EventLogSubject.InvalidTransition,
          },
          {
            subject: EventLogSubject.UnsuccessfulTransitionPreCustomActions,
          },
          {
            subject: EventLogSubject.UnsuccessfulTransitionError,
          },
        ],
      },
      {
        event: EventLogEvent.PerformQuickstop,
        children: [
          {
            subject: EventLogSubject.NoQuickstopError,
          },
          {
            subject: EventLogSubject.WorkplaceToStateError,
          },
          {
            subject: EventLogSubject.ActiveOperationToStateError,
          },
          {
            subject: EventLogSubject.ActiveOperationStateLogError,
          },
          {
            subject: EventLogSubject.FindOptionsError,
          },
          {
            subject: EventLogSubject.QuickstopTransitionError,
          },
          {
            subject: EventLogSubject.StartDateError,
          },
        ],
      },
    ],
  },
  {
    context: EventLogContext.Order,
    children: [
      {
        event: EventLogEvent.Create,
      },
      {
        event: EventLogEvent.Update,
      },
    ],
  },
  {
    context: EventLogContext.WorkflowCorrection,
    children: [
      {
        event: EventLogEvent.ChangeEventLogger,
      },
    ],
  },
  {
    context: EventLogContext.DataExport,
    children: [
      {
        event: EventLogEvent.HandleSuccess,
      },
      {
        event: EventLogEvent.HandleFailure,
      },
    ],
  },
  {
    context: EventLogContext.Task,
    children: [
      {
        event: EventLogEvent.Update,
        children: [
          {
            subject: EventLogSubject.Qualitative,
          },
          {
            subject: EventLogSubject.Quantitative,
          },
        ],
      },
      {
        event: EventLogEvent.DeactivateLot,
        children: successFailure,
      },
      {
        event: EventLogEvent.DeactivateTask,
      },
      {
        event: EventLogEvent.Reactivate,
        children: successFailure,
      },
      {
        event: EventLogEvent.Create,
        children: [
          {
            subject: EventLogSubject.UserId,
          },
          {
            subject: EventLogSubject.OverruledUserId,
          },
        ],
      },
      {
        event: EventLogEvent.Activate,
      },
    ],
  },
  {
    context: EventLogContext.Consumption,
    children: [
      {
        event: EventLogEvent.Create,
      },
      {
        event: EventLogEvent.CreateFromIngredient,
      },
    ],
  },
  {
    context: EventLogContext.CustomAction,
    children: [
      {
        event: EventLogEvent.CreateLogs,
        children: [
          {
            subject: EventLogSubject.SuccessTransitionAllowed,
          },
          {
            subject: EventLogSubject.SuccessTransitionNotAllowed,
          },
          {
            subject: EventLogSubject.Failure,
          },
        ],
      },
    ],
  },
  {
    context: EventLogContext.Personnel,
    children: [
      {
        event: EventLogEvent.Create,
      },
    ],
  },
  {
    context: EventLogContext.PrintManagement,
    children: [
      {
        event: EventLogEvent.ReloadLabels,
        children: [
          {
            subject: EventLogSubject.Started,
          },
          {
            subject: EventLogSubject.Finished,
          },
          {
            subject: EventLogSubject.Error,
          },
        ],
      },
    ],
  },
  {
    context: EventLogContext.Security,
    children: [
      {
        event: EventLogEvent.LogAttempt,
      },
      {
        event: EventLogEvent.LogLogin,
      },
      {
        event: EventLogEvent.LogLogout,
      },
      {
        event: EventLogEvent.LogLogoutAttempt,
      },
      {
        event: EventLogEvent.LogTerminalAttempt,
      },
      {
        event: EventLogEvent.LogTerminalLogin,
      },
    ],
  },
  {
    context: EventLogContext.User,
    children: [
      {
        event: EventLogEvent.Create,
      },
      {
        event: EventLogEvent.Update,
      },
    ],
  },
  {
    context: EventLogContext.ExternalResource,
    children: [
      {
        event: EventLogEvent.Create,
      },
      {
        event: EventLogEvent.Update,
      },
    ],
  },
  {
    context: EventLogContext.ReportLog,
    children: [
      {
        event: EventLogEvent.HandleFailure,
        children: reportLogTypes,
      },
      {
        event: EventLogEvent.HandleSuccess,
        children: reportLogTypes,
      },
    ],
  },
];

export class EventLog extends BaseModel {
  id: number = 0;
  message: string = '';
  timestamp: string = '';
  logLevel: EventLogLevel = EventLogLevel.Info;
  context: EventLogContext = EventLogContext.System;
  source: EventLogSource = EventLogSource.System;
  userId: number | null = null;
  materialId: number | null = null;
  orderId: number | null = null;
  hierarchyId: number | null = null;
  inspectionLotId: number | null = null;
  referenceLabel: string | null = null;
  referenceKey: string | null = null;
  externalResourceId: number | null = null;
  data: any | null = null;
  event: string | null = null;
  subject: string | null = null;
  notified: boolean = false;
  params: any | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      message: observable,
      timestamp: observable,
      logLevel: observable,
      context: observable,
      source: observable,
      userId: observable,
      user: computed,
      materialId: observable,
      material: computed,
      orderId: observable,
      order: computed,
      hierarchyId: observable,
      hierarchy: computed,
      inspectionLotId: observable,
      inspectionLot: computed,
      referenceLabel: observable,
      referenceKey: observable,
      externalResourceId: observable,
      externalResource: computed,
      data: observable,
      event: observable,
      subject: observable,
      notified: observable,
      params: observable,
    });
  }

  static faIcon = faHistory;

  searchableProperties = ['user.fullName', 'material.no', 'order.no', 'inspectionLot.lotNo', 'hierarchy',
    'externalResource.name', 'message'];

  displayableProperties = [
    displayableProperty({
      key: 'timestamp',
      title: i18n.t('eventLog.model.attributes.timestamp'),
      params: [
        displayablePropertyParam({
          path: 'timestamp', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortChronologically(a.timestamp, b.timestamp),
    }),
    displayableProperty({
      key: 'context',
      title: i18n.t('eventLog.model.attributes.context'),
      params: [
        displayablePropertyParam({
          path: 'context',
          transform: (context) => i18n.t(`eventLog.contexts.${context}`),
        }),
      ],
      raw: true,
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.context, b.context),
    }),
    displayableProperty({
      key: 'event',
      title: i18n.t('eventLog.model.attributes.event'),
      params: [
        displayablePropertyParam({
          path: 'event',
          transform: (event) => i18n.t(`eventLog.events.${event}`),
        }),
      ],
      raw: true,
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.event, b.event),
    }),
    displayableProperty({
      key: 'subject',
      title: i18n.t('eventLog.model.attributes.subject'),
      params: [
        displayablePropertyParam({
          path: 'subject',
          transform: (subject) => i18n.t(`eventLog.subjects.${subject}`),
        }),
      ],
      raw: true,
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.subject, b.subject),
    }),
    displayableProperty({
      key: 'source',
      title: i18n.t('eventLog.model.attributes.source'),
      params: [
        displayablePropertyParam({
          path: 'source',
          transform: (source) => i18n.t(`eventLog.sources.${source}`),
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.source, b.source),
    }),
    displayableProperty({
      key: 'user',
      title: i18n.t('eventLog.model.attributes.user'),
      params: [
        displayablePropertyParam({
          path: 'user',
          transform: (user) => (
            <Observer>
              {() => <UserFullName user={user}/>}
            </Observer>
          ),
        }),
      ],
      raw: true,
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.user?.fullName, b.user?.fullName),
    }),
    displayableProperty({
      key: 'hierarchy',
      title: i18n.t('eventLog.model.attributes.hierarchy'),
      params: [
        displayablePropertyParam({
          path: 'hierarchy',
          transform: (hierarchy) => transformation.objectProperty('name')(hierarchy),
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.hierarchy?.name, b.hierarchy?.name),
    }),
    displayableProperty({
      key: 'logLevel',
      title: i18n.t('eventLog.model.attributes.logLevel'),
      params: [
        displayablePropertyParam({
          path: 'logLevel',
          transform: (logLevel) => i18n.t(`eventLog.logLevels.${logLevel}`),
        }),
      ],
      raw: true,
      template: '{value}',
      sorter: (a, b) => b.logLevel - a.logLevel,
    }),
    displayableProperty({
      key: 'referenceLabel',
      title: i18n.t('eventLog.model.attributes.reference'),
      params: [
        displayablePropertyParam({
          path: 'referenceLabel',
          as: 'referenceLabel',
        }),
        displayablePropertyParam({
          path: 'referenceKey',
          as: 'referenceKey',
          transform: (referenceKey) => referenceKey || EnDash(),
        }),
      ],
      template: '{referenceLabel} {referenceKey}',
      sorter: (a, b) => sortAlphabetically(a.referenceLabel, b.referenceLabel),
    }),
    displayableProperty({
      key: 'message',
      title: i18n.t('eventLog.model.attributes.message'),
      params: [
        displayablePropertyParam({
          path: 'message',
          transform: (message) => (<MarkdownItem>{message}</MarkdownItem>),
        }),
      ],
      raw: true,
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.message, b.message),
    }),
    displayableProperty({
      key: 'data',
      title: i18n.t('eventLog.model.attributes.data'),
      params: [displayablePropertyParam({ path: 'data', transform: transformation.none })],
      raw: true,
      template: '{value}',
    }),
  ];

  get user() {
    return this.rootStore.userStore.getById(this.userId);
  }

  get material() {
    return this.rootStore.materialStore.getById(this.materialId);
  }

  get order() {
    return this.rootStore.orderStore.getById(this.orderId);
  }

  get hierarchy() {
    return this.rootStore.hierarchyStore.getById(this.hierarchyId);
  }

  get inspectionLot() {
    return this.rootStore.inspectionLotStore.getById(this.inspectionLotId);
  }

  get externalResource() {
    return this.rootStore.externalResourceStore.getById(this.externalResourceId);
  }

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);

    const materialPrefix = `${titlePrefix}${i18n.t('material.model.one')} > `;
    allDisplayableProperties.push(
      ...MaterialModel.allDisplayableProperties(rootStore, `${pathPrefix}material.`, materialPrefix)
    );

    const orderPrefix = `${titlePrefix}${i18n.t('order.model.one')} > `;
    allDisplayableProperties.push(
      ...OrderModel.allDisplayableProperties(rootStore, `${pathPrefix}order.`, orderPrefix)
    );

    const inspectionLotPrefix = `${titlePrefix}${i18n.t('inspectionLot.model.one')} > `;
    allDisplayableProperties.push(
      ...InspectionLot.allDisplayableProperties(rootStore, `${pathPrefix}inspectionLot.`, inspectionLotPrefix)
    );

    const externalResourcePrefix = `${titlePrefix}${i18n.t('externalResource.model.one')} > `;
    allDisplayableProperties.push(
      ...ExternalResourceModel.allDisplayableProperties(
        rootStore,
        `${pathPrefix}externalResource.`,
        externalResourcePrefix
      )
    );

    return allDisplayableProperties;
  }
}
