import dayjs from 'dayjs';
import {datetimeFormat} from '../../../../config/dayjs';
import {getChartEventsForLimitHandling} from '../../../../utils/chart';

export const TimeseriesPlotOptions = ({
  width,
  height,
  seriesLabel,
  seriesData,
  xAxisUnit,
  yAxisUnit,
  xAxisMax,
  xAxisMin,
  yAxisMax,
  yAxisMin,
  showLegend,
  connectNulls = false,
  limitSeries = [],
  seriesId = undefined,
  limit,
}, t) => ({
  title: {
    text: null,
  },
  chart: {
    width,
    height,
    events: getChartEventsForLimitHandling(limit, t),
  },
  series: [{
    id: seriesId,
    name: seriesLabel,
    data: seriesData,
    step: 'left',
  }, ...limitSeries],
  legend: {
    enabled: showLegend,
  },
  plotOptions: {
    series: {
      connectNulls,
    },
  },
  xAxis: {
    tickPixelInterval: 150,
    title: {
      text: xAxisUnit,
    },
    min: xAxisMin || xAxisMin === 0 ? dayjs(xAxisMin, datetimeFormat).valueOf() : null,
    max: xAxisMax || xAxisMax === 0 ? dayjs(xAxisMax, datetimeFormat).valueOf() : null,
  },
  yAxis: {
    title: {
      text: yAxisUnit,
    },
    min: !limit && (yAxisMin || yAxisMin === 0) ? Number(yAxisMin) : null,
    max: !limit && (yAxisMax || yAxisMax === 0) ? Number(yAxisMax) : null,
    endOnTick: false,
    startOnTick: false,
  },
});
