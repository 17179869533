import {useState} from 'react';
import {Divider} from 'antd';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import Modal from '../shared/modal/Modal';
import {useStore} from '../../hooks/useStore';
import ScanTerminal from './shared/ScanTerminal';
import ScanWorkplace from './shared/ScanWorkplace';
import {useMount} from '../../hooks/useMount';
import AddOrReplaceModal from './AddOrReplaceModal';
import IsBrokenModal from './shared/IsBrokenModal';

const RegisterModal = ({
  onSuccess,
  onCancel,
  onRepair,
  ...props
}) => {
  const store = useStore();
  const {t} = useTranslation();
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [addOrReplaceModalVisible, setAddOrReplaceModalVisible] = useState(false);
  const [selectedWorkplaces, setSelectedWorkplaces] = useState([]);
  const [isBrokenModalVisible, setIsBrokenModalVisible] = useState(false);

  useMount(() => {
    if (selectedTerminal) {
      if (!selectedTerminal.workplaceGroupId) {
        store.flashMessageStore.addFlashMessage({
          type: 'warning',
          title: t('terminalManagement.modal.noAssignedWorkplaceGroup', {terminalName: selectedTerminal.name}),
        });
        setSelectedTerminal(null);
      } else if (selectedTerminal.isBroken) {
        setIsBrokenModalVisible(true);
      } else {
        store.workplaceGroupStore.load(selectedTerminal.workplaceGroupId).then((group) => {
          setSelectedGroup(group);
          if (group.workplaces.length) {
            setAddOrReplaceModalVisible(true);
          } else {
            setSelectedWorkplaces(group.workplaces);
          }
        });
      }
    } else {
      setSelectedGroup(null);
      setSelectedWorkplaces([]);
    }
  }, [selectedTerminal]);

  const setWorkplaces = (shouldAdd) => {
    if (shouldAdd) {
      setSelectedWorkplaces(selectedGroup.workplaces);
    } else {
      setSelectedWorkplaces([]);
    }
    setAddOrReplaceModalVisible(false);
  };

  const handleCancel = () => {
    setIsBrokenModalVisible(false);
    setAddOrReplaceModalVisible(false);
    setSelectedTerminal(null);
  };

  const handleSubmit = () => {
    if (selectedWorkplaces) {
      const isDefaultWorkplaceAvailable = selectedWorkplaces
        .find((workplace) => workplace.id === selectedGroup.defaultWorkplaceId);
      store.workplaceGroupStore.update({
        id: selectedGroup.id,
        name: selectedGroup.name,
        workplaceIds: selectedWorkplaces.map((workplace) => workplace.id),
        defaultWorkplaceId: isDefaultWorkplaceAvailable
          ? selectedGroup.defaultWorkplaceId
          : (selectedWorkplaces[0]?.id || null),
      }).then(() => onSuccess())
        .catch((e) => {
          if (e.name === 'E_BAD_REQUEST' && e.message.includes('already have a group')) {
            // t('flashMessages.alreadyInGroup')
            store.workplaceGroupStore.handleApiError({error: {...e, messages: ['alreadyInGroup']}});
          }
        });
    }
  };

  return (
    <Modal
      fullscreen={store.clientStore.isMobile}
      open
      okText={t('links.save')}
      cancelText={t('confirm.cancel')}
      okButtonProps={{disabled: !selectedTerminal}}
      maskClosable={false}
      onCancel={onCancel}
      onOk={handleSubmit}
      {...props}
    >
      <ScanTerminal terminal={selectedTerminal} onChangeTerminal={setSelectedTerminal} onRepair={onRepair}/>
      <Divider/>
      <ScanWorkplace
        disabled={!selectedTerminal}
        workplaces={selectedWorkplaces}
        onChangeWorkplaces={setSelectedWorkplaces}
      />
      {
        addOrReplaceModalVisible
          ? (
            <AddOrReplaceModal
              onCancel={handleCancel}
              onSuccess={setWorkplaces}
              terminal={selectedTerminal}
              group={selectedGroup}
            />
          )
          : null
      }
      {
        isBrokenModalVisible
          ? (
            <IsBrokenModal
              onCancel={handleCancel}
              onSuccess={() => onRepair(selectedTerminal)}
              onRepair={onRepair}
              terminal={selectedTerminal}
            />
          )
          : null
      }
    </Modal>
  );
};

export default observer(RegisterModal);
