import { observable, makeObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { Order } from '../models/order';
import { generateInspectionLotEndpoint, reloadEndpoint } from '../middleware/endpoints/orders';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';
import i18n from '../i18n/i18n';
import { RootStore } from './rootStore';
import { SearchParams } from '../types/autocomplete';

export class OrderStore extends EntityStore<Order> {
  orders: Order[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'orders', Api.orders, Order, true);

    makeObservable(this, {
      orders: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this,
        modelId: 'parentOrderId',
      },
      {
        store: this.rootStore.materialStore,
        modelId: 'materialId',
      },
      {
        store: this.rootStore.batchStore,
        modelId: 'batchId',
      },
    ];
  }

  searchByNo({ params }: SearchParams): Promise<any[]> {
    return this.api.search(params).then((response: AxiosResponse) => response.data);
  }

  reloadOrder(id: number) {
    this.addPendingAction(reloadEndpoint, { apiEndpoint: reloadEndpoint, id });
    return this.api[reloadEndpoint](id).then((response: AxiosResponse) => {
      const entity = this.createModelInstance(response);
      this.add(entity);
      this.removePendingAction(reloadEndpoint, { apiEndpoint: reloadEndpoint, id });
      return entity;
    });
  }

  generateInspectionLot(id: number) {
    this.addPendingAction(generateInspectionLotEndpoint, { apiEndpoint: generateInspectionLotEndpoint, id });
    return this.api[generateInspectionLotEndpoint](id).then((res: AxiosResponse) => {
      if (res.data) {
        this.rootStore.flashMessageStore.addFlashMessage(new FlashMessage(
          FlashMessageType.SUCCESS,
          i18n.t('order.view.generateInspectionLot.success'),
          {}
        ));
      } else {
        this.rootStore.flashMessageStore.addFlashMessage(new FlashMessage(
          FlashMessageType.WARNING,
          i18n.t('order.view.generateInspectionLot.warning'),
          {}
        ));
      }
    }).catch(() => {
      this.rootStore.flashMessageStore.addFlashMessage(new FlashMessage(
        FlashMessageType.ERROR,
        i18n.t('order.view.generateInspectionLot.error'),
        {}
      ));
    }).finally(() => {
      this.removePendingAction(reloadEndpoint, { apiEndpoint: reloadEndpoint, id });
    });
  }
}
