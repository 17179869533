import { computed, observable, makeObservable } from 'mobx';
import { faRepeat } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { getTranslation } from '../utils/translations';
import { Translation } from './translations';
import { RootStore } from '../stores/rootStore';

export interface PhaseTranslation extends Translation {
  description: string | null;
}

export class Phase extends BaseModel {
  id: number = 0;
  name: string = '';
  groupName: string | null = null;
  sortOrder: number | null = null;
  translations: PhaseTranslation[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      groupName: observable,
      sortOrder: observable,
      translations: observable,
      description: computed,
    });
  }

  static faIcon = faRepeat;

  searchableProperties = ['name'];

  translatedProperties = ['description'];

  static prepareApiPayload(model: Partial<Phase>) {
    return {
      id: model.id || undefined,
      name: model.name,
      groupName: model.groupName,
      sortOrder: Number.isFinite(model.sortOrder) ? model.sortOrder : null,
      translations: Phase.convertToSavableTranslations(model),
    };
  }

  get description() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.description;
  }
}
