import { observable, makeObservable, computed } from 'mobx';
import { faWarehouseFull } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { sortAlphabetically } from '../components/shared/tables/sorters';
import { Hierarchy } from './hierarchy';
import { RootStore } from '../stores/rootStore';

export class StorageArea extends BaseModel {
  id: number = 0;
  no: string = '';
  description: string | null = null;
  building: string | null = null;
  floor: string | null = null;
  zone: string | null = null;
  hierarchyId: number | null = null;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      no: observable,
      description: observable,
      building: observable,
      floor: observable,
      zone: observable,
      hierarchyId: observable,
      hierarchy: computed,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faWarehouseFull;

  searchableProperties = ['no', 'description', 'building', 'floor', 'zone'];

  displayableProperties = [
    displayableProperty({
      key: 'no',
      title: i18n.t('storageArea.model.attributes.no'),
      params: [displayablePropertyParam({ path: 'no' })],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.no, b.no),
    }),
    displayableProperty({
      key: 'description',
      title: i18n.t('storageArea.model.attributes.description'),
      params: [displayablePropertyParam({ path: 'description' })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'building',
      title: i18n.t('storageArea.model.attributes.building'),
      params: [displayablePropertyParam({ path: 'building' })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'floor',
      title: i18n.t('storageArea.model.attributes.floor'),
      params: [displayablePropertyParam({ path: 'floor' })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'zone',
      title: i18n.t('storageArea.model.attributes.zone'),
      params: [displayablePropertyParam({ path: 'zone' })],
      template: '{value}',
    }),
  ];

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);

    const hierarchyPrefix = `${titlePrefix}${i18n.t('hierarchy.model.one')} > `;
    allDisplayableProperties.push(
      ...Hierarchy.allDisplayableProperties(rootStore, `${pathPrefix}hierarchy.`, hierarchyPrefix)
    );

    return allDisplayableProperties;
  }

  get hierarchy() {
    return this.rootStore.hierarchyStore.getById(this.hierarchyId);
  }
}
