import { observable, makeObservable, computed } from 'mobx';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

/**
 * key performance indicator limit style translations:
 * t('keyPerformanceIndicatorLimit.styles.LINE')
 * t('keyPerformanceIndicatorLimit.styles.AREA')
 */
export enum KeyPerformanceIndicatorLimitStyle {
  LINE = 'LINE',
  AREA = 'AREA'
}

export class KeyPerformanceIndicatorLimit extends BaseModel {
  id: number = 0;
  keyPerformanceIndicatorId: number = 0;
  hierarchyId: number = 0;
  plausibleLow: number | null = null;
  toleranceLow: number | null = null;
  warningLow: number | null = null;
  plausibleHigh: number | null = null;
  toleranceHigh: number | null = null;
  warningHigh: number | null = null;
  toleranceStyle: KeyPerformanceIndicatorLimitStyle | null = null;
  warningStyle: KeyPerformanceIndicatorLimitStyle | null = null;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      keyPerformanceIndicatorId: observable,
      keyPerformanceIndicator: computed,
      hierarchyId: observable,
      hierarchy: computed,
      plausibleLow: observable,
      toleranceLow: observable,
      warningLow: observable,
      plausibleHigh: observable,
      toleranceHigh: observable,
      warningHigh: observable,
      toleranceStyle: observable,
      warningStyle: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faChartLine;

  searchableProperties = ['keyPerformanceIndicator.name', 'hierarchy.name'];

  static prepareApiPayload(model: KeyPerformanceIndicatorLimit) {
    return {
      id: model.id,
      keyPerformanceIndicatorId: model.keyPerformanceIndicatorId,
      hierarchyId: model.hierarchyId,
      plausibleLow: (model.plausibleLow !== undefined && model.plausibleLow !== null) ? model.plausibleLow : null,
      toleranceLow: (model.toleranceLow !== undefined && model.toleranceLow !== null) ? model.toleranceLow : null,
      warningLow: (model.warningLow !== undefined && model.warningLow !== null) ? model.warningLow : null,
      plausibleHigh: (model.plausibleHigh !== undefined && model.plausibleHigh !== null) ? model.plausibleHigh : null,
      toleranceHigh: (model.toleranceHigh !== undefined && model.toleranceHigh !== null) ? model.toleranceHigh : null,
      warningHigh: (model.warningHigh !== undefined && model.warningHigh !== null) ? model.warningHigh : null,
      toleranceStyle: model.toleranceStyle || null,
      warningStyle: model.warningStyle || null,
    };
  }

  get keyPerformanceIndicator() {
    return this.rootStore.keyPerformanceIndicatorStore.getById(this.keyPerformanceIndicatorId);
  }

  get hierarchy() {
    return this.rootStore.hierarchyStore.getById(this.hierarchyId);
  }
}
