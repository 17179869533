import React from 'react';
import { Collapse as AntdCollapse, CollapseProps as AntdCollapseProps } from 'antd';

export type CollapseProps = AntdCollapseProps;

const Collapse: ((props: CollapseProps) => React.ReactNode) & {
  Panel: typeof AntdCollapse.Panel
} = ({ children, ...props }) => (
  <AntdCollapse
    {...props}
  >
    {children}
  </AntdCollapse>
);

Collapse.Panel = AntdCollapse.Panel;

export default Collapse;
