import { computed, makeObservable, observable } from 'mobx';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

export class MessageRead extends BaseModel {
  id: number = 0;
  userId: number = 0;
  messageId: number = 0;
  timestamp: string = '';

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      userId: observable,
      user: computed,
      messageId: observable,
      timestamp: observable,
    });
  }

  get user() {
    return this.rootStore.userStore.getById(this.userId);
  }

  searchableProperties = [];
}
