/**
 * takes an array of objects containing a key property and maps form values with the same key to an consolidated object
 * containing the keys of the custom properties and the values from the form values
 *
 * @param {[{key: *, value: *}]} customProperties
 * @param {{}} formValues
 * @returns {PropertiesObject} a reduced property request object
 * @constructor
 */

export const customePropertyPrefix = 'properties';
export const PropertiesObject = ({customProperties, formValues}) => {
  const properties = customProperties
    .filter((cp) => formValues[customePropertyPrefix][cp.key])
    .reduce((acc, current) => ({...acc, [current.key]: formValues[customePropertyPrefix][current.key]}), {});

  /**
   * @typedef {Readonly<Object>} PropertiesObject
   */
  return Object.freeze({
    ...properties,
  });
};

/**
 * takes an object and maps its properties to an array of {name: *, value: *} objects, which may be used to
 * set up form fields
 *
 * @param {{}} value
 * @returns {[{name: *, value: *}]}
 * @constructor
 */
export const PropertiesArray = (value = {}) => Object.keys(value).map((key) => (
  {name: [customePropertyPrefix, key], value: value[key]}));
