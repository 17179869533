import React, {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {Space} from 'antd';
import Alert from 'antd/es/alert';
import {groupBy} from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBoxCheck} from '@fortawesome/pro-solid-svg-icons';
import {WidgetLayout} from '../../models/widgetLayout';
import {WidgetLayouts} from '../shared/widgets/WidgetLayouts';
import OperatorWidget from '../operator/shared/OperatorWidget';
import {ScopeContext} from '../../policies/scopeContext';
import {unrestricted} from '../../models/scope';
import {useMount} from '../../hooks/useMount';
import {useStore} from '../../hooks/useStore';
import {ProducedMaterialWidgetConfig} from './producedMaterialWidgetConfig';
import WidgetSettingsModal from '../shared/widgets/WidgetSettingsModal';
import ProducedMaterialBatchCreationForm from './ProducedMaterialBatchCreationForm';
import {OperationsApi, produceEndpoint} from '../../middleware/endpoints/operations';
import ConsumedComponents from './ConsumedComponents';
import {ConsumptionLog} from '../../models/consumptionLog';
import BatchDescriptions from '../batchLog/BatchDescriptions';
import {mqtt, TOPIC_BASE} from '../../middleware/mqtt';
import List from '../shared/lists/List';
import Switch from '../shared/inputs/Switch';
import {SensorType} from '../../models/sensor';
import Collapse from '../shared/collapse/Collapse';
import widgetStyles from '../operator/shared/OperatorWidget.module.scss';
import {LoadStrategies} from '../../stores/entityStore';
import ViewBatchLog from '../batchLog/ViewBatchLog';
import BatchLogTable from '../batchLog/BatchLogTable';
import {sortChronologically} from '../shared/tables/sorters';

const ProducedMaterialWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const {t} = useTranslation();
  const widgetConfig = useMemo(() =>
    new ProducedMaterialWidgetConfig(store, identifier), []);
  const [modalVisible, setModalVisible] = useState(false);
  const [hasActiveOperation, setHasActiveOperation] = useState(false);
  const [lastConsumptionLog, setLastConsumptionLog] = useState([]);
  const [lastCreatedBatch, setLastCreatedBatch] = useState(undefined);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formReset, setFormReset] = useState(0);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewRecord, setViewRecord] = useState(null);
  const [consumptionLogRecord, setConsumptionLogRecord] = useState([]);

  const initialBatchLoad = () => {
    setIsLoading(true);
    store.yieldStore.loadByOperation(store.operationStore.active.id, {strategy: LoadStrategies.replace});
    setIsLoading(false);
  };

  useMount(() => {
    if (store.operationStore.active?.id) {
      setHasActiveOperation(true);
      store.sensorStore.loadAll({
        params: {
          hierarchyId: store.workplaceStore.selectedWorkplace.hierarchyId,
          includeHierarchyChildren: true,
        },
      }).then(() => initialBatchLoad());
    } else {
      setHasActiveOperation(false);
    }
  }, [store.operationStore.active?.id]);

  const updateLastProduceInfo = async (data) => {
    setIsLoading(true);
    if (data.consumptions.length) {
      await store.batchStore.loadMany(data.consumptions.map((cl) => cl.batchId));
      const consumptions = data.consumptions.map((cl) => ConsumptionLog.fromPlainObject(cl, store));
      const groupedConsumptions = groupBy(consumptions, 'componentId');
      setLastConsumptionLog(Object.values(groupedConsumptions));
    } else {
      setLastConsumptionLog([]);
    }

    if (data.yie) {
      await store.yieldStore.loadWithDependencies(data.yie.id);
      setLastCreatedBatch(store.yieldStore.getById(data.yie.id)?.batch);
    }
    setIsLoading(false);
  };

  useMount(() => {
    const componentsHandlerId = mqtt.subscribe(`${TOPIC_BASE}/components/+`, (topic, payload) => {
      const component = store.componentStore.createModelInstance(payload);

      if (store.componentStore.getById(component.id)) {
        store.componentStore.add(component);
      }
    });

    const productionTopic = `${TOPIC_BASE}/workplaces/${store.workplaceStore.selectedWorkplace?.id}/production`;
    const productionHandlerId = mqtt.subscribe(productionTopic, (topic, payload) => {
      updateLastProduceInfo(payload);
    });

    return () => {
      mqtt.unsubscribe(`${TOPIC_BASE}/components/+`, componentsHandlerId);
      mqtt.unsubscribe(productionTopic, productionHandlerId);
    };
  });

  const onSubmitSettings = (settingValues) => {
    store.settingStore.createOrUpdate(
      store.terminalLayoutStore.currentTerminalLayout.id,
      store.workplaceStore.selectedWorkplace.id,
      widgetConfig.settingsIdentifier,
      settingValues
    );
    setModalVisible(false);
  };

  const onSubmitNewBatch = async (formValues) => {
    setFormSubmitting(true);
    const {producedAmount, wasteAmount, usedComponents, remark, recordedAt} = formValues;
    const manuallyChangedComponents = usedComponents?.filter((comp) =>
      typeof comp.amount === 'string' && comp.amount !== '') || [];

    const response = await OperationsApi[produceEndpoint](
      store.operationStore.active.id,
      {
        ...(producedAmount && {producedAmount}),
        ...(wasteAmount && {wasteAmount}),
        usedComponents: manuallyChangedComponents,
        ...(remark && {remark}),
        recordedAt: recordedAt ? recordedAt.toISOString() : undefined,
      }
    );

    if (response.data) {
      await updateLastProduceInfo(response.data);
    }

    setFormSubmitting(false);
    setFormReset(formReset + 1);
  };

  const viewBatch = (record) => {
    store.consumptionLogStore.loadByTargetYieldIds([record.id]).then(() => {
      const groupedConsumptions = groupBy(
        store.consumptionLogStore.consumptions.filter((consumption) => consumption.targetYieldId === record.id),
        'componentId'
      );
      setConsumptionLogRecord(Object.values(groupedConsumptions));
      setViewRecord(record);
    });
  };

  const toggleSensorState = (sensor) => {
    setIsUpdateInProgress(true);
    store.sensorStore.update({
      ...sensor,
      enabled: !sensor.enabled,
    }).then(() => {
      setIsUpdateInProgress(false);
    });
  };

  const Alerts = ({noActiveOperation, disabledYield}) => {
    let message = null;
    if (noActiveOperation) {
      message = t('producedMaterialWidget.alert.noActiveOperation');
    } else if (disabledYield) {
      message = t('producedMaterialWidget.alert.yieldReportingDisabled');
    }
    return (
      <Alert
        style={{marginBottom: '1rem'}}
        message={message}
        type="info"
        showIcon
      />
    );
  };

  const yieldReportingDisabled = store.operationStore.active?.order?.isYieldReportingDisabled;

  const sensors = store.sensorStore.getByHierarchyIdAndSensorTypes(
    store.workplaceStore.selectedWorkplace.hierarchyId,
    [SensorType.PRODUCE, SensorType.CONSUME]
  );

  const lastYield = store.operationStore.active?.id
    ? store.yieldStore.getByOperation(store.operationStore.active.id)
      .slice()
      .sort((a, b) => sortChronologically(b, a))[0]
    : undefined;

  return (
    <ScopeContext.Provider value={[unrestricted]}>
      <OperatorWidget
        title={widgetConfig.getWidgetTitle()}
        icon={<FontAwesomeIcon icon={faBoxCheck}/>}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        widgetClassNames={yieldReportingDisabled ? [widgetStyles.disabled] : []}
      >
        <Space direction={'vertical'} style={{width: '100%'}}>
          {
            hasActiveOperation && !yieldReportingDisabled
              ? (
                <>
                  {widgetConfig.showSensorStateManagementList() && sensors.length > 0 && (
                    <Collapse defaultActiveKey={[]} style={{marginBottom: '1em'}}>
                      <Collapse.Panel key="prepareSensors" header={t('producedMaterialWidget.sensors.title')}>

                        <List
                          style={{marginBottom: '1em'}}
                          dataSource={sensors}
                          renderItem={(sensor) => (
                            <List.Item
                              key={sensor.name}
                              onClick={() => toggleSensorState(sensor)}
                              style={{cursor: 'pointer'}}
                            >
                              <List.Item.Meta
                                title={t(
                                  'producedMaterialWidget.sensors.label',
                                  {sensorTypeName: t(`sensor.types.${sensor.sensorType}`)}
                                )}
                                description={`${sensor.label} (${sensor.name})`}
                              />
                              <Switch
                                loading={isUpdateInProgress}
                                disabled={isUpdateInProgress}
                                checked={sensor.enabled}
                              />
                            </List.Item>
                          )}
                        />
                      </Collapse.Panel>
                    </Collapse>
                  )}
                  <ProducedMaterialBatchCreationForm
                    onOk={onSubmitNewBatch}
                    formSubmitting={formSubmitting}
                    reset={formReset}
                    widgetConfig={widgetConfig}
                    lastYieldCreatedAt={lastYield?.createdAt}
                  />
                </>
              )
              : (
                <Alerts
                  disabledYield={yieldReportingDisabled}
                  noActiveOperation={!hasActiveOperation}
                />
              )
          }
          {widgetConfig.showLastProducedBatch() && !formSubmitting && (
            <BatchDescriptions batch={lastCreatedBatch}/>
          )}
          {
            widgetConfig.showLastProducedBatch() && lastConsumptionLog.length && !formSubmitting
              ? <ConsumedComponents consumptionLog={lastConsumptionLog}/>
              : ''
          }
          {widgetConfig.showProducedBatches() && hasActiveOperation && (
            <BatchLogTable
              yieldLogs={store.yieldStore.getFilteredByExistingBatch()}
              loading={isLoading}
              sortOrder={'descend'}
              onView={viewBatch}
              widgetConfig={widgetConfig}
              disableTrackTrace
            />
          )}
        </Space>
        {viewRecord && (
          <ViewBatchLog
            viewRecord={viewRecord}
            consumptionLog={consumptionLogRecord}
            onCancel={() => setViewRecord(null)}
            displayableColumns={widgetConfig.getBatchViewDisplayableColumns()}
          />
        )}
        {
          modalVisible
            ? (
              <WidgetSettingsModal
                widgetConfig={widgetConfig}
                onCancel={() => setModalVisible(false)}
                onOk={onSubmitSettings}
              />
            )
            : ''
        }
      </OperatorWidget>
    </ScopeContext.Provider>
  );
};

ProducedMaterialWidget.icon = faBoxCheck;

ProducedMaterialWidget.identifier = 'ProducedMaterialWidget';
ProducedMaterialWidget.defaultLayout = new WidgetLayout(
  {
    identifier: ProducedMaterialWidget.identifier,
    x: 0,
    y: 100,
    height: 6,
    minHeight: 4,
    width: WidgetLayouts.halfWidth.w,
    minWidth: WidgetLayouts.halfWidth.minW,
  }
);

export default observer(ProducedMaterialWidget);
