import {useContext, useState} from 'react';
import {observer} from 'mobx-react-lite';
import Form from '../../shared/form/Form';
import InspectionTaskExecutionForm from './InspectionTaskExecutionForm';
import {sortAlphabetically} from '../../shared/tables/sorters';
import {InspectionTaskContext} from './InspectionTaskExecutionModal';
import {InspectionTaskResultValuation} from '../../../models/inspectionTaskResult';
import FeatureColumn from './FeatureColumn';

const Qualitative = ({
  task,
  disabled,
  onSubmit,
  minRemarkLength,
}) => {
  const {
    key,
    inspectionTask,
    inspectionTaskPending,
  } = task;
  let {catalogFeatures} = inspectionTask.qualitative;
  const [form] = Form.useForm();
  const inspectionTaskContext = useContext(InspectionTaskContext).contextValue;

  const results = inspectionTaskContext.rootForm.getFieldValue('results') || {};
  const [overruleActive, setOverruleActive] = useState(false);
  const [overruleType, setOverruleType] = useState();
  const [remarkRequired, setRemarkRequired] = useState(false);
  const [overruledRemarkRequired, setOverruledRemarkRequired] = useState(false);
  const [overruledUserId, setOverruledUserId] = useState(null);
  const [submittable, setSubmittable] = useState(results[key] !== undefined);

  catalogFeatures = catalogFeatures.slice()
    .sort((a, b) => sortAlphabetically(a.code, b.code));

  const transform = (values) => {
    const feature = catalogFeatures.find((f) => f.id === values.selectedFeatureId);
    const overruleFeature = catalogFeatures.find((f) => f.id === values.selectedOverruleFeatureId);
    return ({
      value: feature.code,
      valuation: feature.valuation,
      remark: values.remark || '',
      overruledValue: overruleFeature?.code,
      overruledValuation: overruleFeature?.valuation,
      overruledRemark: values.overruledRemark || values.fourEyesRemark || '',
      overruledUserId,
      inspectionTaskId: inspectionTask.id,
      inspectionTaskPendingId: inspectionTaskPending?.id,
      internal: {
        featureId: feature.id,
        overruleFeatureId: overruleFeature?.id,
      },
    });
  };

  const initialValues = {
    remark: results[key]?.remark || null,
    overruledRemark: results[key]?.overruledRemark || null,
    selectedFeatureId: results[key]?.internal.featureId || null,
    selectedOverruleFeatureId: results[key]?.internal.overruleFeatureId || null,
  };

  return (
    <InspectionTaskExecutionForm
      name={key}
      form={form}
      initialValues={initialValues}
      onValuesChange={({
        selectedFeatureId,
        selectedOverruleFeatureId,
      }) => {
        const selectedFeature = catalogFeatures.find((feature) => feature.id === selectedFeatureId);
        const selectedOverruleFeature = catalogFeatures.find((feature) => feature.id === selectedOverruleFeatureId);
        if (selectedFeature) {
          const isRemarkRequired = selectedFeature.valuation === InspectionTaskResultValuation.rejected;
          setRemarkRequired(isRemarkRequired);
          setSubmittable(!(inspectionTask.mustBeAccepted && inspectionTask.isOverruleAllowed && isRemarkRequired));
        }
        if (selectedOverruleFeature) {
          setRemarkRequired(!(selectedOverruleFeature));
          setOverruledRemarkRequired(inspectionTask.isOverruleAllowed && selectedOverruleFeature);
          setSubmittable(true);
        }
      }}
      onFinish={() => {
        const formResults = {...results};
        formResults[key] = transform(form.getFieldsValue(true));
        inspectionTaskContext.rootForm.setFieldsValue({results: formResults});
        onSubmit(formResults[key]);
      }}
      remarkRequired={remarkRequired}
      minRemarkLength={minRemarkLength}
      overruledRemarkRequired={overruledRemarkRequired}
      overruleActive={[overruleActive, setOverruleActive]}
      overruleType={[overruleType, setOverruleType]}
      setOverruleUserId={(userId) => {
        setOverruledUserId(userId);
      }}
      inspectionTask={inspectionTask}
      submittable={submittable}
    >
      <FeatureColumn
        key={'selectedFeature'}
        name={'selectedFeatureId'}
        disabled={disabled}
        catalogFeatures={catalogFeatures}
        overruleActive={overruleActive}
      />
      {overruleActive && (
        <FeatureColumn
          key={'selectedOverruleFeature'}
          name={'selectedOverruleFeatureId'}
          isOverrule
          disabled={disabled}
          catalogFeatures={catalogFeatures}
          overruleActive={overruleActive}
        />
      )}
    </InspectionTaskExecutionForm>
  );
};

export default observer(Qualitative);
