/* eslint-disable */
import {endsWith, isArray} from 'lodash';
import {toJS} from 'mobx';
import i18n from '../../../i18n/i18n';

const flat = require('flat');

/**
 * convert a js object/model to an array of antd form fields used to preset field values and errors
 * (e.g. when server returned validation errors)
 * @param model
 * @param {{}} errors
 * @param {{
 *  valueTransformers: {[key: string]: () => undefined},
 *  nestedValueTransformers: {[key: string]: (nestedModel: {}, key: string, subKey: string, formFields: []) => undefined},
 * }} options
 * @returns {[]|*}
 */
export const makeFormFields = (
  model,
  errors = {},
  options = {}
) => {
  model = toJS(model);

  const formFields = [];
  model = model || {};
  errors = errors || {};
  errors = flat.unflatten(errors); // convert flat keys like "foo.bar" to nested object foo: {bar: ...}
  if (Object.keys(errors).length === 1 && Object.keys(errors).indexOf('body') >= 0) {
    errors = errors.body;
  }
  const keys = Array.from(new Set([...Object.keys(model), ...Object.keys(errors)]));

  if (isArray(model)) {
    return model.map((value) => makeFormFields(value, errors[model.indexOf(value)], options));
  }

  keys.map((key) => {
    if (typeof model[key] === 'object' && isNestedModelField(model, key)) {
      makeFormFields(model[key], errors[key], options).forEach((field) => {
        if (Array.isArray(field.name)) {
          formFields.push({
            ...field,
            name: [key, ...field.name]
          });
        } else {
          formFields.push({
            ...field,
            name: [key, field.name]
          });
        }
      });
    }

    const {formKey, formField} = makeModelFormField(model, key, errors, options) || {};
    if (formField || formField === 0) {
      formFields.push({...formField, name: formKey});
    }
    if (model[key] && typeof model[key] !== 'string') {
        Object.keys(model[key]).forEach((subKey) => {
          if (options.nestedValueTransformers?.[key]) {
            options.nestedValueTransformers[key](model[key], key, subKey, formFields);
          } else {
            const subField = makeModelFormField(model[key], subKey, errors, options) || {};
            if (subField.formKey || subField.formKey === 0) {
              formFields.push({...subField.formField, name: [key, subField.formKey]});
            }
          }
        });
    }
    return null;
  });

  return formFields;
};

const makeModelFormField = (model, key, errors, options = {}) => {
  const {valueTransformers} = options;

  const transformer = valueTransformers ? valueTransformers[key] : null;

  if (isSimpleField(model, key)) {
    if (Number(key) || Number(key) === 0) {
      key = Number(key);
    }
    return {formKey: key, formField: createFormField(model, errors, key, {transformer})};
  }

  return null;
};

const isNestedModelField = (model, key) => {
  const nestedModels = model ? model.nestedModels : [];
  return nestedModels && nestedModels.indexOf(key) >= 0;
};

const isSimpleField = (model, key) => {
  // attributes ending with _keys are used for dynamic field sets,
  // these arrays need to be a field with value of type array (dont convert each item into a field)
  const valueIsAnObject = model[key] && typeof model[key] === 'object';
  if (valueIsAnObject && model[key].hasOwnProperty('signed_id') && model[key].hasOwnProperty('url')) {
    // object is a file, which should be mapped to a single input field
    return true;
  }

  if (valueIsAnObject && key.startsWith('translated_')) {
    return true;
  }

  if (Array.isArray(model[key])) {
    return true;
  }

  return !valueIsAnObject || endsWith(key, '_keys') || endsWith(key, 'Ids');
};

const createFormField = (model, errors, attribute, {transformer} = {}) => {
  const formFieldAttributes = {};
  const value = model[attribute];

  if (value !== undefined) {
    formFieldAttributes.value = transformer ? transformer(value) : value;
  }
  if (errors[attribute]) formFieldAttributes.errors = makeFieldErrors(errors[attribute], attribute);

  return formFieldAttributes;
};

// t('errors.messages.notUnique')
export const makeFieldErrors = (errorMessages) => {
  if (typeof errorMessages === 'object' && errorMessages.message) {return [i18n.t(errorMessages.message, errorMessages.extras)];}
  if (!errorMessages || typeof errorMessages.map !== 'function') return [];
  return errorMessages;
};

export const joinFieldName = (name) => (Array.isArray(name) ? name.join('.') : name);
