import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';
import React, { useMemo } from 'react';
import {
  faBoxes,
  faBook,
  faCheck,
  faEnvelope,
  faExclamationTriangle,
  faFileAlt,
  faInfoCircle,
  faPuzzlePiece,
  faShoppingCart,
  faUndo,
  faSitemap,
} from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react-lite';
import { faLayerGroup } from '@fortawesome/pro-solid-svg-icons';
import { TabTitle } from '../../shared/layouts/TabTitle';
import OperationComponentTable from './OperationComponentTable';
import OrderMaterialTable from './OrderMaterialTable';
import OrderDetailsEventLog from './OrderDetailsEventLog';
import OrderDetailsGeneral from './OrderDetailsGeneral';
import OrderDetailsDocuments from './OrderDetailsDocuments';
import OrderDetailsShiftBook from './OrderDetailsShiftBook';
import OrderMessageTabPane from './OrderMessageTabPane';
import OrderDetailsInterruptions from './OrderDetailsInterruptions';
import Empty from '../../shared/empty/Empty';
import InspectionTaskOverviewByOperation from '../../inspectionTask/overview/InspectionTaskOverviewByOperation';
import InspectionTaskPendingModal from '../../inspectionTask/execution/InspectionTaskExecutionModal';
import ModelPropertyGroups from '../../shared/descriptions/ModelPropertyGroups';
import { iconElements } from '../../../utils/icon';
import useInspectionTaskExecutionModal from '../../../hooks/useInspectionTaskExecutionModal';
import OrderDetailsYields from './OrderDetailsYields';
import appConfig from '../../../utils/appConfig';
import OrderOperationTable from './OrderOperationTable';
import OrderDetailsPreparations from './OrderDetailsPreparations';
import { useStore } from '../../../hooks/useStore';
import QualityReportTable from '../../qualityReport/QualityReportTable';
import { Operation } from '../../../models/operation';
import { CustomTab } from './operationWidgetConfig';
import DosageTable from '../../dosage/dosage/DosageTable';
import { useMount } from '../../../hooks/useMount';

export type OrderDetailsProps = {
  operations: Operation[];
  widgetConfig: any;
  displayHierarchyInEventLogs: boolean;
};

const OrderDetails: React.FC<OrderDetailsProps> = ({
  operations,
  widgetConfig,
  displayHierarchyInEventLogs,
}) => {
  const { t } = useTranslation();
  const store = useStore();
  const generalTabOrderProperties = widgetConfig.getGeneralTabOrderProperties();
  const generalTabOperationProperties = widgetConfig.getGeneralTabOperationProperties();
  const customTabs: CustomTab[] = useMemo(
    () => widgetConfig.getCustomTabs(),
    [store.settingStore.operatorSettingsReady]
  );

  const { open: selectPendingTask, inspectionTaskExecutionModalProps } = useInspectionTaskExecutionModal();

  if (!operations.length) {
    return <Empty/>;
  }

  useMount(() => {
    store.qualityReportStore.loadAllWithDependencies({
      strategy: 'replace',
      params: {
        orderId: operations[0].orderId,
      },
    });
  }, [operations]);

  const isOverall = operations.length > 1;

  return (
    <Tabs
      items={widgetConfig.getModalTabsSetting('modalTabs')?.map((tab: any) => {
        switch (tab) {
          case 'tabGeneral':
            return ({
              key: 'tab-general',
              label: (
                <TabTitle faIcon={faInfoCircle} title={t('orderWidget.tab.general')}/>
              ),
              children: (
                <OrderDetailsGeneral
                  operations={operations}
                  uomTypes={widgetConfig.getGeneralTabSettings('modalTabGeneral.unitOfMeasurementTypes')}
                  decimalsConversion={widgetConfig.getGeneralTabSettings('modalTabGeneral.decimalsConversion')}
                  showOrderFirst={widgetConfig.getGeneralTabSettings('modalTabGeneral.showOrderFirst')}
                  showPhaseMachineHours={widgetConfig.getGeneralTabSettings('modalTabGeneral.showPhaseMachineHours')}
                  orderProperties={generalTabOrderProperties}
                  operationProperties={generalTabOperationProperties}
                />
              ),
            });
          case 'tabCustom':
            return customTabs.map((customTab, index: number) => (
              {
                key: `tab-custom-${index.toString()}`,
                label: (
                  // @ts-ignore
                  <TabTitle faIcon={iconElements[customTab.icon]} title={customTab.title}/>
                ),
                disabled: isOverall,
                children: (
                  <div>
                    {isOverall ? null : (
                      <ModelPropertyGroups
                        column={customTab.columnCount as number}
                        groups={customTab.groups as any}
                        model={operations[0]}
                        // @ts-ignore
                        hideEmpty={!customTab.showEmpty}
                      />
                    )}
                  </div>
                ),
              }
            ));
          case 'tabComponents':
            return ({
              key: 'tab-components',
              label: (
                <TabTitle faIcon={faPuzzlePiece} title={t('orderWidget.tab.components')}/>
              ),
              disabled: isOverall,
              children: (
                <div>
                  {isOverall ? null : (
                    <OperationComponentTable
                      columns={widgetConfig.getComponentsTableColumns()}
                      groupComponents={widgetConfig.getComponentsTableSettings('modalTabComponents.groupComponents')}
                      operationId={operations[0]?.id}
                    />
                  )}
                </div>
              ),
            });
          case 'tabPreparations':
            return ({
              key: 'tab-preparations',
              label: (
                <TabTitle faIcon={faLayerGroup} title={t('orderWidget.tab.preparations')}/>
              ),
              children: (
                <OrderDetailsPreparations
                  operations={operations}
                  widgetConfig={widgetConfig}
                />
              ),
            });
          case 'tabOrderMaterials':
            return ({
              key: 'tab-order-materials',
              label: (
                <TabTitle faIcon={faShoppingCart} title={t('orderWidget.tab.orderMaterials')}/>
              ),
              children: (
                <OrderMaterialTable
                  columns={widgetConfig.getComponentsTableColumns()}
                  orderId={operations[0]?.order?.id}
                />
              ),
            });
          case 'tabYields':
            if (appConfig.modules.enableYields) {
              return ({
                key: 'tab-yields',
                label: (
                  <TabTitle faIcon={faBoxes} title={t('orderWidget.tab.yields')}/>
                ),
                children: (
                  <OrderDetailsYields operations={operations}/>
                ),
              });
            }
            return null;
          case 'tabDocuments':
            return ({
              key: 'tab-documents',
              label: (
                <TabTitle faIcon={faFileAlt} title={t('document.model.other')}/>
              ),
              children: (
                <OrderDetailsDocuments operations={operations}/>
              ),
            });
          case 'tabMessages':
            return ({
              key: 'tab-messages',
              label: (
                <TabTitle faIcon={faEnvelope} title={t('message.model.other')}/>
              ),
              children: (
                <OrderMessageTabPane operations={operations}/>
              ),
            });
          case 'tabShiftBook':
            return ({
              key: 'tab-shiftBook',
              label: (
                <TabTitle faIcon={faBook} title={t('shiftBook.model.other')}/>
              ),
              children: (
                <OrderDetailsShiftBook operation={operations[0]}/>
              ),
            });
          case 'tabTasks':
            return ({
              key: 'tab-tasks',
              label: (
                <TabTitle faIcon={faCheck} title={t('task.model.other')}/>
              ),
              disabled: isOverall,
              children: (
                <div>
                  {isOverall ? null : (
                    <>
                      <InspectionTaskOverviewByOperation
                        operation={operations[0]}
                        selectPendingTask={selectPendingTask}
                      />
                      <InspectionTaskPendingModal
                        onExecuted={undefined}
                        openFirstDocument={undefined}
                        {...inspectionTaskExecutionModalProps}
                      />
                    </>
                  )}
                </div>
              ),
            });
          case 'tabInterruptions':
            return ({
              key: 'tab-interruptions',
              label: (
                <TabTitle faIcon={faExclamationTriangle} title={t('orderWidget.tab.interruptions')}/>
              ),
              children: (
                <OrderDetailsInterruptions
                  operations={operations}
                  columns={widgetConfig.getInterruptionsTabSetting('modalTabInterruption.columns')}
                  sortAttribute={widgetConfig.getInterruptionsTabSetting('modalTabInterruption.sortAttribute')}
                  sortOrder={widgetConfig.getInterruptionsTabSetting('modalTabInterruption.sortOrder')}
                  tableColumns={widgetConfig.getInterruptionsTableColumns()}
                />
              ),
            });
          case 'tabEventLog':
            return ({
              key: 'tab-eventLog',
              label: (
                <TabTitle faIcon={faUndo} title={t('eventLog.model.other')}/>
              ),
              children: (
                <OrderDetailsEventLog
                  operation={operations[0]}
                  filterWorkplace={operations.length === 1}
                  displayHierarchy={displayHierarchyInEventLogs}
                />
              ),
            });
          case 'tabOperationsOverview':
            return ({
              key: 'tab-operations-overview',
              label: (
                <TabTitle faIcon={faSitemap} title={t('orderWidget.tab.operationsOverview')}/>
              ),
              children: (
                <OrderOperationTable
                  orderId={operations[0].orderId}
                  operationColumns={widgetConfig.getOperationsOverviewOperationsTableColumns()}
                  componentColumns={widgetConfig.getOperationsOverviewComponentsTableColumns()}
                />
              ),
            });
          case 'tabQualityReport':
            return ({
              key: 'tab-qualityReport',
              label: (
                <TabTitle faIcon={faSitemap} title={t('orderWidget.tab.qualityReport')}/>
              ),
              children: (
                <QualityReportTable
                  dataSource={store.qualityReportStore.qualityReports.filter((item) =>
                    item.orderId === operations[0].orderId)}
                  loading={store.qualityReportStore.areCollectionOrDependenciesLoading}
                  columns={widgetConfig.getQualityReportTableColumns()}
                />
              ),
            });
          case 'tabDosages':
            return ({
              key: 'tab-dosages',
              label: (
                <TabTitle faIcon={faPuzzlePiece} title={t('orderWidget.tab.dosages')}/>
              ),
              children: (
                <DosageTable
                  widgetConfig={widgetConfig}
                  operationId={operations[0].id}
                />
              ),
            });
          default:
            return null;
        }
      }).flat(1)}
    />
  );
};

export default observer(OrderDetails);
