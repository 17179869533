/* eslint-disable no-param-reassign */
import { ButtonProps, Modal, Popconfirm, Popover } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';

export type ConfirmButtonProps = ButtonProps & {
  onConfirm?: () => void;
  title?: string;
  icon?: any;
  okType?: any;
  disabled?: boolean;
  disabledText?: string;
  confirmType?: string;
  buttonProps?: any;
  buttonContent?: any;
  style?: any;
  type?: ButtonProps['type'];
  dataCyPrefix?: string;
};

const ConfirmButton: React.FC<ConfirmButtonProps> = forwardRef(({
  onConfirm,
  title,
  icon = <CheckOutlined/>,
  okType = 'primary',
  disabled = false,
  disabledText,
  confirmType = 'popconfirm',
  buttonProps,
  buttonContent,
  style = {},
  type = 'link',
  dataCyPrefix,
  ...props
}, ref) => {
  const { t } = useTranslation();

  buttonProps = buttonProps || {};
  title = title || t<string>('confirm.default.title');
  disabledText = disabledText || t<string>('confirm.default.disabled');

  if (confirmType === 'none') {
    buttonProps = {
      ...buttonProps,
      ...props,
      ref,
      onClick: onConfirm,
    };
  }

  if (confirmType === 'modal') {
    buttonProps = {
      ...buttonProps,
      ...props,
      ref,
      onClick: () => {
        Modal.confirm({
          title,
          okType,
          content: '',
          okText: t('confirm.yes'),
          cancelText: t('confirm.cancel'),
          onOk: onConfirm,
        });
      },
    };
  }

  const Content = (
    <Button
      icon={icon}
      type={type}
      style={style}
      disabled={disabled}
      dataCyPrefix={`${dataCyPrefix ? `${dataCyPrefix}-` : ''}ConfirmButton`}
      {...buttonProps}
    >
      {buttonContent || null}
    </Button>
  );

  if (disabled) {
    style.pointerEvents = 'none';

    // @see https://github.com/react-component/tooltip/issues/18#issuecomment-411476678
    return (
      <Popover content={disabledText} placement="left" className={'model-confirm-popover'} {...props} ref={ref}>
        <span style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}>
          <Button
            icon={icon}
            type={type}
            style={style}
            disabled={disabled}
            {...buttonProps}
          >
            {buttonContent || null}
          </Button>
        </span>
      </Popover>
    );
  }

  if (confirmType === 'popconfirm') {
    return (
      <Popconfirm
        title={title}
        placement="left"
        onConfirm={onConfirm}
        okText={t('confirm.yes')}
        okButtonProps={{
          'data-cy': `${dataCyPrefix ? `${dataCyPrefix}-` : ''}ConfirmOkButton`,
        }}
        cancelText={t('confirm.no')}
        ref={ref}
        {...props}
      >
        {Content}
      </Popconfirm>
    );
  }

  return Content;
});

export default ConfirmButton;
