import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {groupBy} from 'lodash';
import {Divider} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import {detailedDurationFormat} from '../../../config/dayjs';
import Table from '../../shared/tables/Table';
import Descriptions from '../../shared/descriptions/Descriptions';
import Label from '../../shared/dataDisplay/Label';
import {useStore} from '../../../hooks/useStore';
import {useMount} from '../../../hooks/useMount';
import InterruptionRemarkModal from '../../interruption/InterruptionRemarkModal';
import Button from '../../shared/buttons/Button';
import {interruptionClassification} from '../../../models/scope';
import {useModulePolicy} from '../../../hooks/useModulePolicy';
import {sortAlphabeticallyByPath} from '../../shared/tables/sorters';
import {Actions} from '../../../stores/entityStore';

const OrderDetailsInterruptions = ({operations, columns, sortAttribute, sortOrder, tableColumns}) => {
  const {t} = useTranslation();
  const store = useStore();
  const scopes = [interruptionClassification];
  const policy = useModulePolicy(store, scopes);
  const [remarkModalOpen, setRemarkModalOpen] = useState(false);
  const [currentLog, setCurrentLog] = useState();
  const [aggregatedSum, setAggregatedSum] = useState(0);
  const [aggregatedDuration, setAggregatedDuration] = useState(0);

  const operationStateLogs = store.operationStateLogStore.getInterruptionsByOperationIds(
    operations.map((operation) => operation.id)
  );

  const groupedOperationStateLogs = groupBy(
    operationStateLogs,
    (interruption) => `${interruption.interruptionClassId},${interruption.interruptionReasonId}`
  );

  useMount(() => {
    const {duration, count} = Object
      .entries(groupedOperationStateLogs)
      // eslint-disable-next-line no-unused-vars
      .reduce((acc, [_, group]) => {
        acc.duration += group
          .reduce((a, interruption) => a + dayjs(interruption.end)
            .diff(dayjs(interruption.start), 'seconds'), 0);
        acc.count += group.length;
        return acc;
      }, {
        duration: 0,
        count: 0,
      });

    setAggregatedSum(count);
    setAggregatedDuration(duration);
  }, []);

  useMount(() => {
    if (operations?.length) {
      store.interruptionClassStore.loadAll();
      store.interruptionReasonStore.loadAll();
      store.operationStateLogStore.loadByOperation(operations.map((operation) => operation.id)).then((data) => {
        const usersToLoadSet = new Set();
        data.forEach((item) => {
          usersToLoadSet.add(item.createdBy);
          usersToLoadSet.add(item.updatedBy);
        });

        const usersToLoad = Array.from(usersToLoadSet);
        if (usersToLoad.length > 0) {
          store.userStore.loadMany(usersToLoad, {onlyIfEmpty: true});
        }
      });
    }
  }, [operations.reduce((res, op) => `${res}-${op.id}`, '')]);

  const tableColumnsCpy = [...tableColumns];

  if (policy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})) {
    tableColumnsCpy.push({
      key: 'actions',
      dataIndex: 'actions',
      align: 'right',
      render: (text, record) => (
        <Button
          icon={<FontAwesomeIcon icon={faEdit}/>}
          type={'link'}
          onClick={(e) => {
            setCurrentLog(record);
            setRemarkModalOpen(true);
            e.stopPropagation();
          }}
        />
      ),
    });
  }

  const tableColumnsWithSortOrder = tableColumnsCpy.map((col) => {
    if ((col.key === 'start' && sortAttribute === 'time')
      || (col.key === 'durationSeconds' && sortAttribute === 'duration')) {
      return {...col, defaultSortOrder: sortOrder};
    }
    return {...col};
  });

  return (
    <>
      <Divider orientation="left">
        {t('orderWidget.interruptions.summarize')}
        <span style={{marginLeft: '25px', marginRight: '25px'}}>-</span>
        {t('orderWidget.interruptions.total')}
        <span style={{marginRight: '10px'}}>:</span>
        {aggregatedSum}
        {' / '}
        {dayjs.utc(aggregatedDuration * 1000).format(detailedDurationFormat)}
      </Divider>
      <Descriptions column={columns} bordered>
        {Object.entries(groupedOperationStateLogs)
          .sort(([keyA], [keyB]) => {
            // eslint-disable-next-line no-unused-vars
            const [interruptionClassIdA, interruptionReasonIdA] = keyA.split(',');
            // eslint-disable-next-line no-unused-vars
            const [interruptionClassIdB, interruptionReasonIdB] = keyB.split(',');
            const reasonA = store.interruptionReasonStore.getById(interruptionReasonIdA);
            const reasonB = store.interruptionReasonStore.getById(interruptionReasonIdB);
            if (reasonA && reasonB) {
              return reasonA.sortOrder - reasonB.sortOrder;
            }
            return 0;
          })
          .sort(([keyA], [keyB]) => {
            // eslint-disable-next-line no-unused-vars
            const [interruptionClassIdA] = keyA.split(',');
            // eslint-disable-next-line no-unused-vars
            const [interruptionClassIdB] = keyB.split(',');
            const classA = store.interruptionClassStore.getById(interruptionClassIdA);
            const classB = store.interruptionClassStore.getById(interruptionClassIdB);
            if (classA && classB) {
              return sortAlphabeticallyByPath(classA, classB, 'name');
            }
            return 0;
          })
          .map(([key, group]) => {
            const [interruptionClassId, interruptionReasonId] = key.split(',');
            const interruptionClass = store.interruptionClassStore.getById(interruptionClassId);
            const interruptionReason = store.interruptionReasonStore.getById(interruptionReasonId);
            const {count, duration} = group.reduce((acc, curr) => {
              acc.count += 1;
              acc.duration += dayjs(curr.end).diff(dayjs(curr.start), 'seconds');
              return acc;
            }, {count: 0, duration: 0});

            return (
              <Descriptions.Item
                key={key}
                label={(
                  <Label>
                    {interruptionClass?.name}
                    {' > '}
                    {interruptionReason?.label}
                  </Label>
                )}
              >
                {count}
                {' / '}
                {dayjs.utc(duration * 1000).format(detailedDurationFormat)}
              </Descriptions.Item>
            );
          })}
      </Descriptions>
      <Divider orientation="left">{t('orderWidget.interruptions.details')}</Divider>
      <Table
        columns={tableColumnsWithSortOrder}
        dataSource={operationStateLogs || []}
        rowKey={'id'}
        loading={store.userStore.isActionInProgress(Actions.load)}
      />
      {remarkModalOpen
        && (
          <InterruptionRemarkModal
            currentLog={currentLog}
            closeModal={() => {
              setCurrentLog(undefined);
              setRemarkModalOpen(false);
            }}
          />
        )}
    </>
  );
};

export default observer(OrderDetailsInterruptions);
