import { observable, makeObservable } from 'mobx';
import { faWindow } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

export class TerminalLayout extends BaseModel {
  id: number = 0;
  name: string = '';
  isDefault: boolean = false;
  widgetLayouts: any[] = [];
  scale: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      isDefault: observable,
      widgetLayouts: observable,
      scale: observable,
    });
  }

  static faIcon = faWindow;

  searchableProperties = ['name'];

  saveableProperties = ['id', 'isDefault', 'name', 'widgetLayouts', 'scale'];

  static prepareApiPayload(model: TerminalLayout) {
    return {
      ...model,
      widgetLayouts: JSON.stringify(model.widgetLayouts || []),
    };
  }
}
