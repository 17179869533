import React, { useState } from 'react';
import { FormInstance, TreeSelect } from 'antd';
import { set } from 'lodash';
import { BaseModel } from '../../../../models/base';
import Form from '../../form/Form';
import { ValidationRules } from '../../../../utils/validationRules';
import { useMount } from '../../../../hooks/useMount';
import { useStore } from '../../../../hooks/useStore';
import { ConfigField, ListElement } from '../../../../types/widgetConfig';
import { Language } from '../../../../models/translations';
import { DisplayablePropertyParams } from '../../../../models/displayableProperty';

export type PropertiesTreeSelectProps = {
  form: FormInstance;
  field: ConfigField;
  fieldListElement: ListElement;
  fieldValuePath: (string | number)[];
};

export type StateValue = {
  label: { [key in Language]: string };
  property: string;
};

const PropertiesTreeSelect: React.FC<PropertiesTreeSelectProps> = ({
  form,
  field,
  fieldListElement,
  fieldValuePath,
}) => {
  const store = useStore();
  const [treeData, setTreeData] = useState([]);

  const modelOptions = field.modelOptions as DisplayablePropertyParams[] || [];

  useMount(() => {
    const selectedProperty = form?.getFieldValue([...fieldValuePath, fieldListElement.name, 'property']);
    const selectedList = form.getFieldValue(field.id) || [];
    const usedProperties = selectedList.filter((o: StateValue) => !!o).map((o: StateValue) => o.property);
    const optionsList = modelOptions.filter((opt) => {
      if (opt.key === selectedProperty) {
        return true;
      }
      if (opt.disabled) {
        return false;
      }
      return !usedProperties.includes(opt.key);
    }) || [];
    const tree = BaseModel.convertDisplayablePropertiesToTree(optionsList);
    setTreeData(tree);
  }, [form, field, fieldListElement]);

  return (
    <Form.Item
      {...fieldListElement}
      name={[fieldListElement.name, 'property']}
      fieldKey={[fieldListElement.fieldKey, 'property']}
      rules={[ValidationRules.required()]}
      wrapperCol={{ sm: 24 }}
      style={{ marginBottom: 0 }}
    >
      <TreeSelect
        treeData={treeData}
        showSearch
        treeNodeFilterProp={'title'}
        treeNodeLabelProp={'breadcrumb'}
        treeDefaultExpandAll
        onSelect={(selection) => {
          const fieldIdentifier = fieldValuePath[0];
          const [, ...path] = [...fieldValuePath, fieldListElement.name];
          const currentValue = form.getFieldValue(fieldIdentifier);
          const currentSelected = modelOptions.find((x) => x.key === selection);
          const updatedValue = set(
            currentValue,
            [...path, 'label'],
            { [store.languageStore.lang]: currentSelected?.title }
          );
          form.setFieldsValue({
            [fieldIdentifier]: updatedValue,
          });
        }}
      />
    </Form.Item>
  );
};

export default PropertiesTreeSelect;
