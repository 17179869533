import { computed, observable, makeObservable } from 'mobx';
import { max, min, sum } from 'lodash';
import { BaseModel } from './base';
import { Operation, OperationDisplayStates } from './operation';
import { RootStore } from '../stores/rootStore';

export class AggregatedOperation extends BaseModel {
  // TODO: this is a hack to make the aggregated operations work with components that expect an id.
  //    This may need to be fixed
  // the id is a combination of the ids of the aggregated operations separated by '-' and prefixed with 'aggregated-op-'
  id: string = '';
  children: Operation[] = [];
  parentOrderId: number | null = null;
  orderId: number | null = null;
  sortOrder: number | null = null;
  tmpSortOrder: number | null = null;

  /**
   * Creates an aggregates operation for display in the OrderTable component.
   * The parentOrderId and orderId parameters define the type of aggregation.
   * They must not be used simultaneously.
   * @param rootStore
   * @param children
   * @param {number | null} parentOrderId defines aggregation as by parentOrderId
   * @param {number | null} orderId defines aggregation as by operations of same order on same workplace
   */
  constructor(
    rootStore: RootStore,
    children: Operation[],
    parentOrderId: number | null,
    orderId: number | null = null
  ) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      no: computed,
      children: observable,
      order: computed,
      plannedStart: computed,
      plannedEnd: computed,
      actualStart: computed,
      actualEnd: computed,
      plannedDurationSeconds: computed,
      displayStatus: computed,
      isActive: computed,
      isPlannedOrReady: computed,
      isFinished: computed,
      plannedQuantity: computed,
      actualQuantity: computed,
      plannedScrap: computed,
      actualScrap: computed,
      unitOfMeasure: computed,
    });

    if (parentOrderId && orderId) {
      const e = new Error('AggregatedOperation used with both orderId and parentOrderId set. This is not allowed');
      // eslint-disable-next-line no-console
      console.error(e);
    }

    this.id = `aggregated-op-${children.map((op) => op.id).join('-')}`;
    this.children = children;
    this.parentOrderId = parentOrderId;
    this.orderId = orderId;
    this.sortOrder = min(children.map((op) => op.sortOrder)) || null;
    this.tmpSortOrder = min(children.map((op) => op.sortOrder)) || null;
  }

  searchableProperties = ['children'];

  get order() {
    return this.rootStore.orderStore.getById(this.parentOrderId ? this.parentOrderId : this.orderId);
  }

  get plannedStart() {
    return min(this.children?.map((op) => op.plannedStart));
  }

  get plannedEnd() {
    return min(this.children.map((op) => op.plannedStart));
  }

  get actualStart() {
    return min(this.children.map((op) => op.actualStart));
  }

  get actualEnd() {
    return max(this.children.map((op) => op.actualStart));
  }

  get plannedDurationSeconds() {
    return sum(this.children.map((op) => op.plannedDurationSeconds));
  }

  get displayStatus() {
    if (this.children.some((op) => op.displayStatus === OperationDisplayStates.active)) {
      return OperationDisplayStates.active;
    }
    if (this.children.every((op) => op.displayStatus === OperationDisplayStates.disabled)) {
      return OperationDisplayStates.disabled;
    }
    if (this.children.some((op) => op.displayStatus === OperationDisplayStates.paused)) {
      return OperationDisplayStates.paused;
    }
    if (this.children.every((op) => op.displayStatus === OperationDisplayStates.planned)) {
      return OperationDisplayStates.planned;
    }
    if (this.children.some((op) => op.displayStatus === OperationDisplayStates.ready)) {
      return OperationDisplayStates.ready;
    }

    return OperationDisplayStates.finished;
  }

  get isActive() {
    return this.displayStatus === OperationDisplayStates.active;
  }

  get isPaused() {
    return this.displayStatus === OperationDisplayStates.paused;
  }

  get isPlannedOrReady() {
    return this.displayStatus === OperationDisplayStates.planned
      || this.displayStatus === OperationDisplayStates.ready;
  }

  get isFinished() {
    return this.displayStatus === OperationDisplayStates.finished;
  }

  get plannedQuantity() {
    return this.children.reduce((quantity, op) => (op.plannedQuantity || 0) + quantity, 0);
  }

  get actualQuantity() {
    return this.children.reduce((quantity, op) => (op.actualQuantity || 0) + quantity, 0);
  }

  get plannedScrap() {
    return this.children.reduce((quantity, op) => (op.plannedScrap || 0) + quantity, 0);
  }

  get actualScrap() {
    return this.children.reduce((quantity, op) => (op.actualScrap || 0) + quantity, 0);
  }

  get unitOfMeasure() {
    return this.children[0]?.unitOfMeasure;
  }

  get no() {
    return min(this.children.map((op) => op.no));
  }
}
