import { superUser, unrestricted } from '../models/scope';
import appConfig from '../utils/appConfig';
import { User } from '../models/user';
import { Scopes } from '../models/scopes';

interface Options {
  hierarchyId?: number;
}

export class ModulePolicy {
  user: User;
  scopes: Scopes[];

  constructor(user: User, scopes: Scopes[] = []) {
    this.user = user;
    this.scopes = scopes;
  }

  /**
   * @param right e.g. 'read'
   * @param options e.g. '{hierarchyId: 1}
   * @returns has permission
   */
  can(right: string, options: Options) {
    if (this.isSuperUser() || this.scopes.find((s) => s === unrestricted)) {
      return true;
    }

    if (!(this.user && this.user.scopes)) {
      return false;
    }

    const permissionScopes = this.user.scopes.filter(
      (userScope) => userScope.right === right
        && this.scopes.find((s) => userScope.name === s)
        && (!appConfig.modules.enableHierarchyPermissions || userScope.hierarchyIds.length === 0
          || options.hierarchyId === undefined || userScope.hierarchyIds.includes(options.hierarchyId))
    );

    return permissionScopes.length > 0;
  }

  canView(options: Options = {}) {
    return this.can('read', options);
  }

  canViewAll(options: Options = {}) {
    return this.can('read', options) && this.hasNoHierarchyRestrictions('read');
  }

  canDelete(options: Options = {}) {
    return this.can('write', options);
  }

  canDeleteAll(options: Options = {}) {
    return this.can('write', options) && this.hasNoHierarchyRestrictions('write');
  }

  canEdit(options: Options = {}) {
    return this.can('write', options);
  }

  canEditAll(options: Options = {}) {
    return this.can('write', options) && this.hasNoHierarchyRestrictions('write');
  }

  canCreate(options: Options = {}) {
    return this.can('write', options);
  }

  canCreateAll(options: Options = {}) {
    return this.can('write', options) && this.hasNoHierarchyRestrictions('write');
  }

  canClone(options: Options = {}) {
    return this.can('write', options);
  }

  canExecute(options: Options = {}) {
    return this.can('exec', options);
  }

  shouldNotBeSameUser(id: number) {
    return (id !== this.user.id);
  }

  isSuperUser() {
    return !!this.user?.scopes.find((s) => s.name === superUser);
  }

  hasNoHierarchyRestrictions(right: string) {
    return appConfig.modules.enableHierarchyPermissions === false
      || this.user.scopes
        .filter((userScope) => userScope.right === right && this.scopes.find((s) => userScope.name === s))
        .every((userScope) => userScope.hierarchyIds.length === 0);
  }
}
