import React from 'react';
import { observer } from 'mobx-react-lite';
import { get } from 'lodash';
import { AnyObject } from 'antd/es/_util/type';
import { ColumnType } from 'antd/es/table';
import Table, { TableProps } from './Table';
import styles from './TransposableTable.module.scss';

export type TransposableTableProps<RecordType extends AnyObject = AnyObject, > = TableProps<RecordType> & {
  renderHeader?: (x: any) => React.ReactNode;
  headerKey: string;
  vertical?: boolean;
};

// eslint-disable-next-line max-len
const TransposableTable: <RecordType extends AnyObject = AnyObject, >(props: TransposableTableProps<RecordType>) => React.ReactNode = <RecordType extends AnyObject = AnyObject, >({
  columns,
  dataSource,
  headerKey,
  renderHeader = (x) => x,
  vertical = false,
  ...props
}: TransposableTableProps<RecordType>) => {
  const hasHeader = !!headerKey;
  const headerColKey = 'headerCol';
  const classes: string[] = hasHeader ? [styles.header] : [];

  const cols = columns as ColumnType<RecordType>[] || [];
  if (vertical) {
    classes.push(styles.verticalTable);
    // do magic
    const vertCols = dataSource?.map((data, index) => ({
      title: renderHeader(data[headerKey]),
      dataIndex: `el:${index}`,
      key: `el:${index}`,
    })) || [];

    const headerCol = {
      title: '',
      dataIndex: headerColKey,
      key: headerColKey,
    };

    const vertDS = cols?.map(({ dataIndex, ...col }, columnsIndex) => dataSource?.reduce((obj, el, index) => ({
      ...obj,
      [`el:${index}`]: dataIndex ? get(el, dataIndex) : el,
    }), {
      [headerColKey]: col.title,
      key: `${dataIndex}-${columnsIndex}`,
    }));

    return (
      <Table<RecordType>
        className={classes.join(' ')}
        columns={[headerCol, ...vertCols]}
        // @ts-ignore - Needs some complex type conversions, as the generic type of the table changes when pivoted
        dataSource={vertDS}
        {...props}
        rowKey={'key'}
        showHeader={hasHeader}
      />
    );
  }

  classes.push(styles.horizontalTable);
  if (hasHeader) {
    const headerCol = {
      title: '',
      dataIndex: headerKey,
      key: headerColKey,
      render: renderHeader,
    };
    cols.unshift(headerCol);
  }
  return (
    <Table
      className={classes.join(' ')}
      columns={cols}
      dataSource={dataSource}
      {...props}
    />
  );
};

export default observer(TransposableTable);
