import React from 'react';
import { EnDash } from '../unicodeWrapper/EnDash';
import { SpeedDenominatorUnit, SpeedDenominator as SpeedDenominatorEnum } from '../../../models/speed';
import appConfig from '../../../utils/appConfig';
import { UnitOfMeasurement } from '../../../models/unitOfMeasurement';

export type SpeedDenominatorProps = {
  value: number;
  fallback: React.ReactNode;
  unit: UnitOfMeasurement;
};

const SpeedDenominator: React.FC<SpeedDenominatorProps> = ({ value, fallback = EnDash(), unit }) => {
  if (value) {
    let unitStr = unit?.label || unit?.name || '';
    if (unitStr) {
      unitStr = `${unitStr} \u22c5`;
    }

    return (
      <div>
        <span>{value}</span>
        <span>{` ${unitStr}`}</span>
        <span>{` ${SpeedDenominatorUnit[appConfig.speedDenominator as SpeedDenominatorEnum]}`}</span>
        <sup>-1</sup>
      </div>
    );
  }
  return fallback;
};

export default SpeedDenominator;
