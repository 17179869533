import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { RootStore } from './rootStore';
import { UserNotification } from '../models/userNotification';

export class UserNotificationStore extends EntityStore<UserNotification> {
  userNotifications: UserNotification[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'userNotifications', Api.userNotifications, UserNotification, true);

    makeObservable(this, {
      userNotifications: observable,
    });
  }
}
