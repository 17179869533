export const auditLog = 'audit-log';
export const batch = 'batch';
export const cockpit = 'cockpit';
export const collaboration = 'collaboration';
export const customActions = 'custom-actions';
export const dataExportScheduler = 'data-export-scheduler';
export const documentManagement = 'document-management';
export const dosage = 'dosage';
export const eventLog = 'event-log';
export const externalResource = 'external-resource';
export const externalResourceLog = 'external-resource-log';
export const fullWorkplaceSelector = 'full-workplace-selector';
export const gatewayManagement = 'gateway-management';
export const hierarchyConfigurator = 'hierarchy-configurator';
export const inspectionOverrule = 'inspection-overrule';
export const inspectionVerificationConfig = 'inspection-verification-config';
export const inspectionVerification = 'inspection-verification';
export const interruptionClassification = 'interruption-classification';
export const inventoryManagement = 'inventory-management';
export const keyPerformanceIndicatorLimit = 'key-performance-indicator-limit';
export const materialManagement = 'material-management';
export const moduleConfiguration = 'module-configuration';
export const objectLocks = 'object-locks';
export const office = 'office';
export const officeAdvancedOrderManagement = 'office-advanced-order-management';
export const officeBatch = 'office-batch';
export const officeCockpit = 'office-cockpit';
export const officeCollaboration = 'office-collaboration';
export const officeDocumentManagement = 'office-document-management';
export const officeEventLog = 'office-event-log';
export const officeExternalResourceLog = 'office-external-resource-log';
export const officeMasterData = 'office-master-data';
export const officeOrderManagement = 'office-order-management';
export const officePerformanceAnalysis = 'office-performance-analysis';
export const officePlantCockpit = 'office-plant-cockpit';
export const officePrintManagement = 'office-print-management';
export const officeReportLog = 'office-report-log';
export const officeTaskManagement = 'office-task-management';
export const officeWorkflowCorrection = 'office-workflow-corrections';
export const operationAbort = 'operation-abort';
export const orderManagement = 'order-management';
export const performanceAnalysis = 'performance-analysis';
export const personnelManagement = 'personnel-management';
export const platformConfigurator = 'platform-configurator';
export const printManagement = 'print-management';
export const processdataCollection = 'processdata-collection';
export const qualityReport = 'quality-report';
export const reportLog = 'report-log';
export const sensorDataLimit = 'sensor-data-limit';
export const shiftManagement = 'shift-management';
export const specialTaskManagement = 'special-task-management';
export const superUser = 'super-user';
export const taskActivation = 'task-activation';
export const taskManagement = 'task-management';
export const terminal = 'terminal';
export const transition = 'transition';
export const unrestricted = 'unrestricted';
export const userManagement = 'user-management';
export const widgetAction = 'widget-action';
export const workflow = 'workflow';
export const workflowCorrection = 'workflow-corrections';

export class Scope {
  name: string;
  right: string;
  hierarchyIds: number[];

  /**
   * @param scope string containing both right and scope in the form of "right:scope" e.g. "read:operations"
   */
  constructor(scope: string) {
    const [right, name, hierarchies] = scope.split(':');
    this.right = right;
    this.name = name;
    this.hierarchyIds = hierarchies?.length > 0 ? hierarchies.split(',').map((id) => parseInt(id, 10)) : [];
  }
}
