import {observer} from 'mobx-react-lite';
import {useMemo, useState} from 'react';
import {faInfo} from '@fortawesome/pro-solid-svg-icons';
import {WidgetLayout} from '../../models/widgetLayout';
import {WidgetLayouts} from '../shared/widgets/WidgetLayouts';
import ModelProperties from '../shared/descriptions/ModelProperties';
import OperatorWidget from '../operator/shared/OperatorWidget';
import ActiveOperationDetailModal from './ActiveOperationDetailModal';
import {ActiveOperationWidgetConfig} from './activeOperationWidgetConfig';
import {useMount} from '../../hooks/useMount';
import ActiveOperationDetailsPhaseProgress from './ActiveOperationDetailsPhaseProgress';
import AbortOperationButton from './AbortOperationButton';
import {useStore} from '../../hooks/useStore';
import {ScopeContext} from '../../policies/scopeContext';
import {operationAbort, orderManagement, workflow} from '../../models/scope';
import {useModulePolicy} from '../../hooks/useModulePolicy';
import './ActiveOperationDetailsWidget.scss';

const ActiveOperationDetailsWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const [modalVisible, setModalVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const widgetConfig = useMemo(
    () =>
      new ActiveOperationWidgetConfig(store, ActiveOperationDetailsWidget.identifier),
    [store.propertyStore.hasPendingRequests]
  );
  const policy = useModulePolicy(store, [operationAbort]);

  // Sometimes, custom properties are loaded too late. This updates the component once, they are available.
  useMount(() => {
    setRefresh(!refresh);
  }, [store.propertyStore.properties.length]);

  useMount(() => {
    if (store.operationStore.active?.id) {
      store.unitOfMeasurementConversionStore.loadByOrderId(store.operationStore.active?.orderId);
    }
  }, [store.operationStore.active?.orderId]);

  const barcodeProperties = widgetConfig.getBarcodePropertyKeys();
  const qrCodeProperties = widgetConfig.getQrCodePropertyKeys();
  const dataMatrixProperties = widgetConfig.getDataMatrixPropertyKeys();
  const properties = widgetConfig.getWidgetProperties();

  return (
    <ScopeContext.Provider value={[workflow, orderManagement]}>
      <OperatorWidget
        onHeadClick={store.operationStore.active ? () => setModalVisible(true) : undefined}
        title={widgetConfig.getWidgetTitle()}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        manualPath={'/order/active-operation'}
      >
        <ModelProperties
          loading={store.operationStore.isLoadingCollection}
          properties={properties}
          barcodeProperties={barcodeProperties}
          qrCodeProperties={qrCodeProperties}
          dataMatrixProperties={dataMatrixProperties}
          model={store.operationStore.active}
          className={'active-operation-properties'}
        />
        <ActiveOperationDetailsPhaseProgress widgetConfig={widgetConfig}/>
        {policy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})
          && store.operationStore.active
          && <AbortOperationButton operation={store.operationStore.active}/>}
      </OperatorWidget>
      {modalVisible && (
        <ActiveOperationDetailModal widgetConfig={widgetConfig} onCancel={() => setModalVisible(false)}/>
      )}
    </ScopeContext.Provider>
  );
};

ActiveOperationDetailsWidget.icon = faInfo;

ActiveOperationDetailsWidget.identifier = 'ActiveOperationDetailsWidget';
ActiveOperationDetailsWidget.defaultLayout = new WidgetLayout(
  {
    identifier: ActiveOperationDetailsWidget.identifier,
    x: 0,
    y: 0,
    height: 10,
    minHeight: 1,
    width: WidgetLayouts.thirdWidth.w,
    minWidth: WidgetLayouts.thirdWidth.minW,
  }
);

export default observer(ActiveOperationDetailsWidget);
