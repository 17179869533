import { makeObservable, observable } from 'mobx';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { ConfigTransferLog } from '../models/configTransferLog';
import { RootStore } from './rootStore';

export class ConfigTransferLogStore extends EntityStore<ConfigTransferLog> {
  configTransferLogs: ConfigTransferLog[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'configTransferLogs', Api.configTransferLogs, ConfigTransferLog);

    makeObservable(this, {
      configTransferLogs: observable,
    });
  }

  getByFileId(fileId: number): ConfigTransferLog[] {
    return this.configTransferLogs.filter((log) => log.configTransferFileId === fileId);
  }
}
