import Chart from './Chart';

const AreaChart = ({options, isTimeseries, dataLabelsEnabled, isLoading, showIsEmpty}) => (
  <Chart
    options={{
      ...options,
      chart: {
        type: 'area',
        zoomType: 'xy',
        ...options.chart,
      },
      plotOptions: {
        area: {
          fillOpacity: 0.5,
          step: 'left',
        },
        ...options.plotOptions,
      },
    }}
    isTimeseries={isTimeseries}
    isLoading={isLoading}
    showIsEmpty={showIsEmpty}
    dataLabelsEnabled={dataLabelsEnabled}
  />
);

export default AreaChart;
