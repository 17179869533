import { makeObservable, observable } from 'mobx';
import { Api } from '../middleware/api';
import { WorkplaceGroup } from '../models/workplaceGroup';
import { EntityStore } from './entityStore';
import { RootStore } from './rootStore';

export class WorkplaceGroupStore extends EntityStore<WorkplaceGroup> {
  workplaceGroups: WorkplaceGroup[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'workplaceGroups', Api.workplaceGroups, WorkplaceGroup);

    makeObservable(this, {
      workplaceGroups: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.userStore,
        modelId: 'createdBy',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'updatedBy',
      },
    ];
  }
}
