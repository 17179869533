import { computed, observable, makeObservable } from 'mobx';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';
import { EventLogContext, EventLogEvent, EventLogSubject } from './eventLog';

export class UserNotificationConfig extends BaseModel {
  id: number = 0;
  userId: number = 0;
  context: EventLogContext | null = null;
  event: EventLogEvent | null = null;
  subject: EventLogSubject | null = null;
  hierarchyId: number | null = null;
  filterForUser: boolean = false;
  sendEmail: boolean = false;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      userId: observable,
      user: computed,
      context: observable,
      event: observable,
      subject: observable,
      hierarchyId: observable,
      hierarchy: computed,
      filterForUser: observable,
      sendEmail: observable,
    });
  }

  saveableProperties = [
    'id',
    'userId',
    'context',
    'event',
    'subject',
    'hierarchyId',
    'filterForUser',
    'sendEmail',
  ];

  get user() {
    return this.rootStore.userStore.getById(this.userId);
  }

  get hierarchy() {
    return this.rootStore.hierarchyStore.getById(this.hierarchyId);
  }

  static prepareApiPayload(model: UserNotificationConfig) {
    return {
      id: model.id || undefined,
      userId: model.userId,
      context: model.context || null,
      event: model.event || null,
      subject: model.subject || null,
      hierarchyId: model.hierarchyId || null,
      filterForUser: model.filterForUser || false,
      sendEmail: model.sendEmail || false,
    };
  }
}
