import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonProps } from './Button';

export type CloseButtonProps = ButtonProps;

const CloseButton: React.FC<CloseButtonProps> = (props) => {
  const { t } = useTranslation();

  const buttonProps: ButtonProps = {
    children: t('button.close'),
    type: 'default',
    ...props,
  };

  return <Button {...buttonProps}/>;
};

export default CloseButton;
