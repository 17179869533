import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import InspectionTaskDescription from './InspectionTaskDescription';
import MarkdownItem from '../../../shared/MarkdownItem';

const DetailsSection = ({fallback, task}) => {
  const {t} = useTranslation();

  return (
    <InspectionTaskDescription title={t('inspectionTask.drilldownModal.section.details')}>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.model.attributes.details.label')}
      >
        <MarkdownItem>
          {fallback(task.details)}
        </MarkdownItem>
      </InspectionTaskDescription.Item>
      <InspectionTaskDescription.Item
        label={t('inspectionTask.drilldownModal.details.detailsNotAccepted')}
      >
        <MarkdownItem>
          {fallback(task.detailsNotAccepted)}
        </MarkdownItem>
      </InspectionTaskDescription.Item>
    </InspectionTaskDescription>
  );
};

export default observer(DetailsSection);
