import { makeObservable, observable } from 'mobx';
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

export class LdapFieldMapping extends BaseModel {
  id: number = 0;
  mesFieldName: string = '';
  ldapFieldName: string = '';
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      mesFieldName: observable,
      ldapFieldName: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faNetworkWired;

  searchableProperties = ['mesFieldName', 'ldapFieldName'];
}
