import {useState} from 'react';
import dayjs from 'dayjs';
import {useMount} from './useMount';

const useTimedRerender = ({seconds = 1, callback} = {}, deps = []) => {
  const [now, setNow] = useState(dayjs());

  useMount(() => {
    const timer = setInterval(() => {
      if (callback) {
        callback();
      }

      setNow(dayjs());
    }, seconds * 1000);
    return () => {
      clearInterval(timer);
    };
  }, deps);

  return now;
};

export default useTimedRerender;
