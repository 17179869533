import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBagsShopping} from '@fortawesome/pro-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import {useMemo, useState} from 'react';
import dayjs from 'dayjs';
import {Col, Row} from 'antd';
import Modal from '../../shared/modal/Modal';
import OrderDetails from './OrderDetails';
import {useMount} from '../../../hooks/useMount';
import {ActiveOperationWidgetConfig} from '../../operation/activeOperationWidgetConfig';
import ActiveOperationDetailsWidget from '../../operation/ActiveOperationDetailsWidget';
import {useStore} from '../../../hooks/useStore';
import OrderTableStateChange from '../operationList/OrderTableStateChange';
import {FilterFormItem} from '../../shared/form/FilterFormItem';
import DateRangePicker from '../../shared/inputs/DateRangePicker';
import {dateFormat} from '../../../config/dayjs';

const OrderWidgetModal = ({
  identifier,
  settings,
  columnsPlanned,
  columnsFinished,
  operationStateIds,
  activeFilter,
  workplaceId,
  useSelectedOperation = [undefined, () => undefined],
  useIsReordering = [undefined, () => false],
  onCancel,
  visible,
  ...props
}) => {
  const store = useStore();
  const {t} = useTranslation();

  const [selectedOperation, setSelectedOperation] = useSelectedOperation;
  const [isReordering, setIsReordering] = useIsReordering;
  const [showOperationDetails, setShowOperationDetails] = useState(false);
  const [isListModalVisible, setIsListModalVisible] = useState(visible);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [fromDate, setFromDate] = useState(dayjs().subtract(settings.loadDaysPast, 'days').startOf('day'));
  const [toDate, setToDate] = useState(dayjs().add(settings.loadDaysFuture, 'days').startOf('day'));
  // Workaround to let modal subcomponents that are not force rendered know, that visibility has
  // changed since their last update.
  const [listOpenCount, setListOpenCount] = useState(false);
  const widgetConfig = useMemo(
    () => new ActiveOperationWidgetConfig(store, ActiveOperationDetailsWidget.identifier),
    [store.settingStore.operatorSettingsReady]
  );

  useMount(() => {
    setFromDate(dayjs().subtract(settings.loadDaysPast, 'days').startOf('day'));
    setToDate(dayjs().add(settings.loadDaysFuture, 'days').startOf('day'));
  }, [settings.loadDaysPast, settings.loadDaysFuture]);

  useMount(() => {
    setIsListModalVisible(!(showOperationDetails && selectedOperation) && visible);
    setIsDetailsModalVisible(!!(showOperationDetails && selectedOperation) && visible);
  }, [visible, showOperationDetails, selectedOperation]);

  useMount(() => {
    setListOpenCount(listOpenCount + 1);
  }, [isListModalVisible]);

  useMount(() => {
    if (selectedOperation?.id) {
      if (selectedOperation.orderId) {
        store.orderStore.load(selectedOperation.orderId);
        store.operationStore.loadAllWithDependencies({params: {orderId: selectedOperation.orderId}});

        store.operationStateLogStore.getByWorkplaceId(store.workplaceStore.selectedWorkplace?.id);
        store.workplaceStateLogStore.getByWorkplaceId(store.workplaceStore.selectedWorkplace?.id);
      }
    }
  }, [selectedOperation, store.workplaceStore.selectedWorkplace?.id]);

  return (
    <>
      <Modal
        width="90vw"
        title={(
          <>
            <FontAwesomeIcon icon={faBagsShopping}/>
            {' '}
            {t('order.model.other')}
          </>
        )}
        footer={false}
        onCancel={() => {
          setIsReordering(false);
          onCancel();
        }}
        open={isListModalVisible}
        {...props}
      >
        {settings.showDatePicker && (
          <Row>
            <Col flex={'300px'}>
              <FilterFormItem label={t('operation.filter.plannedDateRange')}>
                <DateRangePicker
                  showTime={false}
                  dateTimeFrom={fromDate}
                  dateTimeTo={toDate}
                  value={[fromDate, toDate]}
                  defaultDateFormat={dateFormat}
                  onCalendarChange={(dateChange) => {
                    if (dateChange !== null) {
                      setFromDate(dateChange[0] ? dateChange[0].clone().startOf('day') : null);
                      setToDate(dateChange[1] ? dateChange[1].clone().endOf('day') : null);
                    }
                  }}
                />
              </FilterFormItem>
            </Col>
          </Row>
        )}
        <OrderTableStateChange
          fromDate={fromDate}
          toDate={toDate}
          workplaceId={workplaceId}
          identifier={identifier}
          settings={settings}
          columnsPlanned={columnsPlanned}
          columnsFinished={columnsFinished}
          operationStateIds={operationStateIds}
          isModalTable
          useSelectedOperation={[selectedOperation, setSelectedOperation]}
          useIsReordering={[isReordering, setIsReordering]}
          setShowOperationDetails={setShowOperationDetails}
          displayStateChanged={listOpenCount}
          widgetConfig={widgetConfig}
          activeFilter={activeFilter}
        />
      </Modal>
      <Modal
        width="90vw"
        title={(
          <>
            <FontAwesomeIcon icon={faBagsShopping}/>
            {' '}
            {`${t('order.model.one')} ${selectedOperation?.order?.no}`}
          </>
        )}
        footer={false}
        onCancel={() => setShowOperationDetails(false)}
        open={isDetailsModalVisible}
        {...props}
      >
        {showOperationDetails && selectedOperation && (
          <OrderDetails operations={[selectedOperation]} widgetConfig={widgetConfig}/>
        )}
      </Modal>
    </>
  );
};

export default OrderWidgetModal;
