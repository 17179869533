import i18n from '../../../../../i18n/i18n';
import {ValidationRules} from '../../../../../utils/validationRules';
import {CockpitWidgetConfig} from './cockpitWidgetConfig';
import {FieldType} from '../../../../../types/widgetConfig';

export const stateGroups = {
  operationStates: 'operationStates',
  workplaceStates: 'workplaceStates',
};

export class EventDurationCockpitWidgetConfig extends CockpitWidgetConfig {
  static widgetType = {
    identifier: 'event-duration',
    name: () => i18n.t('cockpitWidget.widgetTypeIdentifier.eventDuration'),
  };

  // eslint-disable-next-line class-methods-use-this
  getWidgetTypeName() {
    return i18n.t('cockpitWidget.widgetTypeIdentifier.eventDuration');
  }

  get tabs() {
    return [
      this.generalSettingsTab,
      this.workplaceTab,
      {
        key: 'eventDuration',
        label: i18n.t('widget.WidgetSettingsModal.eventDurationConfigTabTitle'),
      },
    ];
  }

  eventDuration = [
    {
      id: 'state',
      label: i18n.t('eventDurationCockpitWidgetConfig.settings.states.label'),
      type: FieldType.Select,
      options: this.getStateOptions(),
      validations: [ValidationRules.required()],
    },
  ];

  getStateOptions() {
    const operationStatesOptions = this.rootStore.operationStateStore.operationStates?.map((state) => ({
      value: state.id,
      label: `${state.label}`,
      group: {
        key: stateGroups.operationStates,
        label: i18n.t('eventDurationCockpitWidgetConfig.settings.states.operationStates'),
      },
    }));
    const workplaceStatesOptions = this.rootStore.workplaceStateStore.workplaceStates?.map((state) => ({
      value: state.id,
      label: `${state.label}`,
      group: {
        key: stateGroups.workplaceStates,
        label: i18n.t('eventDurationCockpitWidgetConfig.settings.states.workplaceStates'),
      },
    }));

    return [...operationStatesOptions, ...workplaceStatesOptions];
  }
}
