import { observable, makeObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { StorageArea } from '../models/storageArea';
import { RootStore } from './rootStore';
import { SearchParams } from '../types/autocomplete';

interface StorageAreaSearchResponse {
  id: number,
  no: string,
}

export class StorageAreaStore extends EntityStore<StorageArea> {
  storageAreas: StorageArea[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'storageAreas', Api.storageAreas, StorageArea);

    makeObservable(this, {
      storageAreas: observable,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  prepareApiPayload(model: StorageArea) {
    return {
      id: model.id,
      no: model.no || undefined,
      description: model.description || null,
      hierarchyId: model.hierarchyId || null,
      building: model.building || null,
      floor: model.floor || null,
      zone: model.zone || null,
    };
  }

  async searchByNo({ params }: SearchParams): Promise<StorageAreaSearchResponse[]> {
    return this.api
      .search({ ...params })
      .then((response: AxiosResponse) => response.data);
  }
}
