import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import {useCallback, useState} from 'react';
import styles from './ActiveOperationDetailsPhaseProgress.module.scss';
import {useMount} from '../../hooks/useMount';
import {formatDuration} from '../../utils/formatting';
import {durationFormat} from '../../config/dayjs';
import {useStore} from '../../hooks/useStore';
import {PhaseProgressPlanSource} from './activeOperationWidgetConfig';

const REFRESH_INTERVAL_MS = 15000;

const ActiveOperationDetailsPhaseProgress = ({widgetConfig}) => {
  const store = useStore();
  const {t} = useTranslation();
  const [activeOperationPhase, setActiveOperationPhase] = useState();
  const usePhaseData = widgetConfig
    .getWidgetSetting('phaseProgress.dataSource') === PhaseProgressPlanSource.OperationPhase;

  const recalculateUsedDurationSeconds = useCallback(() => {
    if (!usePhaseData && store.operationStore.active?.id) {
      store.operationStore.active.recalculateUsedDurationSeconds(store);
    }
  }, [usePhaseData, activeOperationPhase?.id]);

  useMount(() => {
    const interval = setInterval(recalculateUsedDurationSeconds, REFRESH_INTERVAL_MS);

    return () => {
      clearInterval(interval);
    };
  }, [recalculateUsedDurationSeconds]);

  useMount(() => {
    recalculateUsedDurationSeconds();
  }, [activeOperationPhase?.id, store.operationStore.active?.id, store.operationStateLogStore.logs.length]);

  useMount(() => {
    if (store.operationStore.active?.id) {
      store.operationPhaseStore.loadByOperation(store.operationStore.active.id);
    }
  }, [store.operationStore.active?.id]);

  useMount(() => {
    if (store.operationStore.active?.state?.phaseId) {
      store.phaseStore.load(store.operationStore.active.state.phaseId);
    }
  }, [store.operationStore.active?.state?.phaseId]);

  useMount(() => {
    if (store.operationStore.active?.id) {
      store.operationStateLogStore.loadAll({params: {operationId: store.operationStore.active.id}});
    }
  }, [store.transitionStore.performedTransition]);

  useMount(() => {
    if (usePhaseData) {
      const operationPhase = store.operationPhaseStore.getActiveByOperationIdAndPhaseId(
        store.operationStore.active?.id,
        store.operationStore.active?.state?.phaseId
      );
      if (operationPhase?.id !== activeOperationPhase?.id) {
        setActiveOperationPhase(operationPhase);
      }
    }
  }, [
    store.operationStore.active?.state?.phaseId,
    store.operationStore.active?.id,
    store.transitionStore.performedTransition,
    store.operationPhaseStore.operationPhases.length,
  ]);

  if (!widgetConfig.getWidgetSetting('phaseProgress.showPhaseProgress')
    || !store.operationStore.active?.state?.phase
    || !store.operationStateLogStore.logs.length
    || (store.operationStore.active?.usedDurationSeconds === undefined && usePhaseData === false)
    || (activeOperationPhase === undefined && usePhaseData)
  ) {
    return null;
  }

  const dataSource = usePhaseData ? activeOperationPhase : store.operationStore.active;

  const {
    overtimeSeconds,
    availableSeconds,
    plannedDurationSeconds,
    usedDurationSeconds,
    usedDurationPercentage,
    overtimePercentage,
    availablePercentage,
  } = dataSource;

  return (
    <div className={styles.phaseProgressWrapper}>
      <div className={styles.phaseProgress}>
        {
          usedDurationPercentage
            ? (
              <div
                className={styles.phase}
                style={{
                  flexGrow: usedDurationPercentage,
                  backgroundColor: widgetConfig.getWidgetSetting('phaseProgress.usedColor'),
                }}
              />
            ) : null
        }
        {
          availablePercentage
            ? (
              <div
                className={styles.phase}
                style={{
                  flexGrow: availablePercentage,
                  backgroundColor: widgetConfig.getWidgetSetting('phaseProgress.availableColor'),
                }}
              />
            ) : null
        }
        {
          overtimePercentage
            ? (
              <div
                className={styles.phase}
                style={{
                  flexGrow: overtimePercentage,
                  backgroundColor: widgetConfig.getWidgetSetting('phaseProgress.overtimeColor'),
                }}
              />
            ) : null
        }
      </div>
      <div className={styles.phaseInfo}>
        <div className={styles.phaseInfoEntry}>
          <span>
            {overtimePercentage
              ? t('activeOperationWidget.phaseProgress.planned')
              : t('activeOperationWidget.phaseProgress.is')}
          </span>
          <span style={{backgroundColor: widgetConfig.getWidgetSetting('phaseProgress.usedColor')}}/>
          <span>
            {
              overtimePercentage
                ? formatDuration(
                  dayjs.duration(plannedDurationSeconds, 'seconds'),
                  durationFormat
                )
                : formatDuration(
                  dayjs.duration(usedDurationSeconds, 'seconds'),
                  durationFormat
                )
            }
          </span>
        </div>
        <div className={styles.phaseInfoEntry}>
          <span>
            {overtimePercentage
              ? t('activeOperationWidget.phaseProgress.overtime')
              : t('activeOperationWidget.phaseProgress.available')}
          </span>
          <span style={{
            backgroundColor: overtimePercentage
              ? widgetConfig.getWidgetSetting('phaseProgress.overtimeColor')
              : widgetConfig.getWidgetSetting('phaseProgress.availableColor'),
          }}
          />
          <span>
            {
              overtimePercentage
                ? formatDuration(
                  dayjs.duration(overtimeSeconds, 'seconds'),
                  durationFormat
                )
                : formatDuration(
                  dayjs.duration(availableSeconds, 'seconds'),
                  durationFormat
                )
            }
          </span>
        </div>
      </div>
    </div>
  );
};

export default observer(ActiveOperationDetailsPhaseProgress);
