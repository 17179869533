import { action, observable, makeObservable } from 'mobx';
import dayjs from 'dayjs';
import { Api } from '../middleware/api';
import { InspectionTaskPending } from '../models/inspectionTaskPending';
import { EntityStore } from './entityStore';
import { RootStore } from './rootStore';

export class InspectionTaskPendingStore extends EntityStore<InspectionTaskPending> {
  pendingInspectionTasks: InspectionTaskPending[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'pendingInspectionTasks', Api.inspectionTasksPending, InspectionTaskPending);

    makeObservable(this, {
      pendingInspectionTasks: observable,
      loadByWorkplace: action,
      deleteByWorkplace: action,
    });
  }

  loadByWorkplace(workplaceId: number) {
    this.deleteByWorkplace(workplaceId);
    return this.loadAllWithDependencies({
      params: {
        workplaceId,
      },
    });
  }

  deleteByWorkplace(workplaceId: number) {
    this.getByWorkplaceId(workplaceId).forEach((pending) => this.remove(pending.id));
  }

  getByWorkplaceId(workplaceId: number) {
    return this.pendingInspectionTasks.filter((pending) => pending.inspectionTask?.workplaceId === workplaceId);
  }

  getGroupedByWorkplaceId(workplaceId: number) {
    return InspectionTaskPendingStore.groupTasks(this.getByWorkplaceId(workplaceId)).sort(InspectionTaskPending.sort);
  }

  getByInspectionTaskGroupId(inspectionTaskGroupId: number) {
    return this.pendingInspectionTasks
      .filter((pending) => pending.inspectionTask?.inspectionTaskGroupId === inspectionTaskGroupId);
  }

  getByInspectionTaskIds(inspectionTaskIds: number[]) {
    return this.pendingInspectionTasks.filter((pending) => inspectionTaskIds.includes(pending.inspectionTaskId));
  }

  /**
   * Warning, this function returns merged 'hybrid' objects that represent the most relevant aspect of all tasks inside
   * the group. Do not use these to display single tasks.
   */
  static groupTasks(pendingTasks: InspectionTaskPending[]) {
    const groupedList: Omit<InspectionTaskPending,
      'getPlainSaveableObject'
      | 'populateAttributesFromStore'
      | 'copyAttributesFrom'
      | 'fitsQuery'
      | 'queryFitsProperty'
      | 'matches'
      | 'matchesAttributeValue'
      | 'toFlatObject'
    >[] = [];

    pendingTasks.forEach((pendingTask) => {
      const groupedIdx = groupedList.findIndex(
        (x) => x.inspectionTask?.inspectionTaskGroupId
          && x.inspectionTask?.inspectionTaskGroupId === pendingTask.inspectionTask?.inspectionTaskGroupId
          && InspectionTaskPending.isSameCalculation(x, pendingTask)
      );
      const copy = {
        ...pendingTask,
        inspectionTask: pendingTask.inspectionTask,
        /**
         * @deprecated use inspectionTask.inspectionLot
         */
        inspectionLot: pendingTask.inspectionTask?.inspectionLot,
      };
      if (pendingTask.inspectionTask && groupedIdx < 0) {
        groupedList.push(copy);
      } else if (pendingTask.inspectionTask && groupedIdx >= 0) {
        if (dayjs(groupedList[groupedIdx].dueDate) > dayjs(pendingTask.dueDate)) {
          groupedList[groupedIdx].dueDate = pendingTask.dueDate;
        }
      }
    });
    return groupedList;
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.inspectionTaskStore,
        modelId: 'inspectionTaskId',
      },
    ];
  }
}
