import dayjs from 'dayjs';
import {InfoCircleOutlined, MinusCircleOutlined, PlusCircleOutlined} from '@ant-design/icons/lib/icons';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {useState} from 'react';
import {useStore} from '../../hooks/useStore';
import Table from '../shared/tables/Table';
import Button from '../shared/buttons/Button';
import styles from './componentTable.module.scss';
import {datetimeFormat} from '../../config/dayjs';
import {PreparationState} from '../../models/preparation';
import {useMount} from '../../hooks/useMount';

const toleranceClassNames = {
  pending: 'pending',
  withinTolerance: 'withinTolerance',
  exceedsTolerance: 'exceedsTolerance',
};

const ComponentTable = ({preparation, position}) => {
  const {t} = useTranslation();
  const store = useStore();
  const [update, setUpdate] = useState(true);

  useMount(() => {
    setUpdate(!update);
  }, [store.consumptionLogStore.consumptions.length]);

  const handleConsumeRetrograde = (component, event) => {
    event.stopPropagation();
    store.consumptionLogStore.consumeRetrograde({
      preparationId: preparation.id,
      componentId: component.id,
    });
  };

  const getToleranceClassName = (record) => {
    let className;
    const actualQuantity = store.consumptionLogStore.actualConsumed(preparation.id, record.id);
    if ((actualQuantity >= (record.toleranceLow ?? record.preparationQuantity))
      && (actualQuantity <= (record.toleranceHigh ?? record.preparationQuantity))
    ) {
      className = toleranceClassNames.withinTolerance;
    } else if (record.toleranceHigh && actualQuantity > record.toleranceHigh) {
      className = toleranceClassNames.exceedsTolerance;
    } else {
      className = toleranceClassNames.pending;
    }
    return className;
  };

  const componentDetails = (record) => {
    const consumptions = store.consumptionLogStore.filterBy({
      preparationId: preparation.id,
      componentId: record.id,
    });

    return (
      <>
        {record.info ? (
          <span className={styles.info}>
            <InfoCircleOutlined style={{marginRight: '.5em'}}/>
            {record.info}
          </span>
        ) : null}
        {consumptions.length ? (
          <Table
            size={'small'}
            rowKey={'id'}
            pagination={false}
            dataSource={consumptions}
            rowClassName={getToleranceClassName(record)}
            columns={[
              {
                title: t('batchHandling.preparation.components.consumption.recordedAt'),
                dataIndex: 'recordedAt',
                render: (date) => dayjs(date).format(datetimeFormat),
              },
              {
                title: t('batchHandling.preparation.components.consumption.amount'),
                render: (c) => `${c.amount?.toFixed(3)}${c.component?.unitOfMeasure?.label}`,
              },
              {
                title: t('batchHandling.preparation.components.consumption.batchNo'),
                dataIndex: ['batch', 'no'],
                render: (no) => (no || '-'),
              },
            ]}
          />
        ) : null}
      </>
    );
  };

  return (
    <Table
      className={styles.componentsTable}
      rowKey={'id'}
      loading={store.componentStore.isLoadingCollection || store.consumptionLogStore.isLoadingCollection}
      pagination={false}
      rowClassName={getToleranceClassName}
      expandable={{
        expandRowByClick: true,
        rowExpandable: (record) => {
          const hasInfo = !!record?.info;
          const hasConsumptions = !!(store.consumptionLogStore.filterBy({
            preparationId: preparation.id,
            componentId: record.id,
          })).length;
          return hasInfo || hasConsumptions;
        },
        expandedRowRender: (record) => componentDetails(record),
        expandIcon: ({expanded, onExpand, record}) => {
          const hasInfo = !!record?.info;
          const hasConsumptions = !!(store.consumptionLogStore.filterBy({
            preparationId: preparation.id,
            componentId: record.id,
          })).length;
          const icons = [];
          if (hasInfo) {
            icons.push(<InfoCircleOutlined key={'info'} onClick={(e) => onExpand(record, e)}/>);
          }
          if (hasConsumptions) {
            icons.push((expanded ? (
              <MinusCircleOutlined key={'minus'} onClick={(e) => onExpand(record, e)}/>
            ) : (
              <PlusCircleOutlined key={'plus'} onClick={(e) => onExpand(record, e)}/>
            )));
          }
          return icons;
        },
      }}
      dataSource={position !== undefined
        ? store.componentStore.filterBy({operationId: preparation.operationId, position})
        : []}
      columns={[
        {
          title: t('batchHandling.preparation.components.materialNo'),
          dataIndex: 'material',
          render: (material) => material?.no,
        },
        {
          title: t('batchHandling.preparation.components.material'),
          dataIndex: 'material',
          render: (material) => material?.name,
        },
        {
          title: t('batchHandling.preparation.components.preparationQuantity'),
          render: (record) => (record.preparationQuantity
            ? record.preparationQuantity?.toFixed(3)
            : record.plannedQuantity?.toFixed(3) || Number(0).toFixed(3)),
        },
        {
          title: t('batchHandling.preparation.components.actualQuantity'),
          render: (record) => {
            const actualQuantity = store.consumptionLogStore.actualConsumed(preparation.id, record.id);
            return (record.isRetrograde && !actualQuantity && preparation.state === PreparationState.RUNNING ? (
              <Button onClick={(e) => handleConsumeRetrograde(record, e)}>...</Button>
            ) : actualQuantity.toFixed(3));
          },
        },
        {
          title: t('batchHandling.preparation.components.unitOfMeasure'),
          dataIndex: 'unitOfMeasure',
          render: (unitOfMeasure) => unitOfMeasure?.label,
        },
      ]}
    />
  );
};

export default observer(ComponentTable);
