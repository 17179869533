import React, {useState} from 'react';
import {Form} from 'antd';
import {useTranslation} from 'react-i18next';
import Modal from '../shared/modal/Modal';
import {useMount} from '../../hooks/useMount';
import {useStore} from '../../hooks/useStore';
import {makeFormFields} from '../shared/form/formFields';
import {ValidationRules} from '../../utils/validationRules';
import Select from '../shared/inputs/Select';
import Input from '../shared/inputs/Input';
import {BatchStatus} from '../../models/batch';
import {sortAlphabetically} from '../shared/tables/sorters';

const BatchQueueSetupModal = ({onOk, setupData, ...props}) => {
  const {t} = useTranslation();
  const store = useStore();
  const [form] = Form.useForm();
  const [batchOptions, setBatchOptions] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [loadingBatchOptions, setLoadingBatchOptions] = useState(false);
  const handleSubmit = () => (form.submit());

  useMount(() => {
    form.setFields(makeFormFields({
      batchNo: undefined,
      quantity: undefined,
    }));

    if (setupData && setupData.materialId) {
      setLoadingBatchOptions(true);
      store.batchStore.loadAll({
        params: {
          materialId: setupData.materialId,
          status: BatchStatus.FREE,
        },
      }).then(() => {
        const options = store.batchStore.batches
          .filter((batch) => batch.materialId === setupData.materialId)
          .filter((batch) => batch.status === BatchStatus.FREE);

        setBatchOptions(options);
      }).finally(() => {
        setLoadingBatchOptions(false);
      });
    }
  }, [setupData]);

  const onValuesChange = (changedValues) => {
    if (changedValues.batchId) {
      const batch = store.batchStore.getById(changedValues.batchId);
      form.setFieldsValue({quantity: batch.quantity});
      setSelectedBatch(batch);
    }
  };

  return (
    <Form.Provider
      onFormFinish={(name, {values}) => onOk && onOk(values)}
    >
      <Modal
        title={t('batchQueueWidget.setupModal.title')}
        onOk={handleSubmit}
        open
        {...props}
      >
        <Form
          labelCol={{span: 10}}
          wrapperCol={{span: 14}}
          labelAlign={'left'}
          form={form}
          onValuesChange={onValuesChange}
        >
          {!loadingBatchOptions && (
            <>
              <Form.Item
                name={'batchId'}
                label={t('batchQueueWidget.setupModal.form.batchNo')}
                rules={[ValidationRules.required()]}
              >
                <Select
                  showSearch
                  allowClear
                  autoFocus
                  options={batchOptions.map((batch) => ({
                    label: batch.no,
                    value: batch.id,
                  }))}
                  optionFilterProp={'label'}
                  filterSort={(a, b) => sortAlphabetically(a.label, b.label)}
                />
              </Form.Item>
              <Form.Item
                name={'quantity'}
                label={t('batchQueueWidget.setupModal.form.quantity')}
                rules={[ValidationRules.greaterThan(0), ValidationRules.lessThanOrEqualTo(selectedBatch?.quantity)]}
              >
                <Input type={'number'} addonAfter={setupData?.component?.unitOfMeasure?.label}/>
              </Form.Item>
            </>
          )}
        </Form>
      </Modal>
    </Form.Provider>
  );
};

export default BatchQueueSetupModal;
