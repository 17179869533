import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import { observable, makeObservable, computed } from 'mobx';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

export class KeyPerformanceIndicatorTarget extends BaseModel {
  id: number = 0;
  keyPerformanceIndicatorId: number = 0;
  workplaceId: number | null = null;
  materialId: number | null = null;
  value: number = 0;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      keyPerformanceIndicatorId: observable,
      keyPerformanceIndicator: computed,
      workplaceId: observable,
      workplace: computed,
      materialId: observable,
      material: computed,
      value: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faBullseye;

  searchableProperties = [
    'value',
    'keyPerformanceIndicator.name',
    'material.name',
    'material.no',
    'workplace.name',
    'workplace.no',
  ];

  get keyPerformanceIndicator() {
    return this.rootStore.keyPerformanceIndicatorStore.getById(this.keyPerformanceIndicatorId);
  }

  get workplace() {
    return this.rootStore.workplaceStore.getById(this.workplaceId);
  }

  get material() {
    return this.rootStore.materialStore.getById(this.materialId);
  }

  static prepareApiPayload(model: Partial<KeyPerformanceIndicatorTarget>): Partial<KeyPerformanceIndicatorTarget> {
    return {
      id: model.id,
      keyPerformanceIndicatorId: model.keyPerformanceIndicatorId,
      workplaceId: model.workplaceId || null,
      materialId: model.materialId || null,
      value: model.value,
    };
  }
}
