import {useState} from 'react';
import {useMount} from './useMount';

export const useStateTransitionChanged = (store) => {
  const [stateTransitionChanged, setStateTransitionChanged] = useState(store.transitionStore.performedTransition);

  useMount(() => {
    if (store.transitionStore.performedTransition > stateTransitionChanged) {
      setStateTransitionChanged(store.transitionStore.performedTransition);
    }
  }, [store.transitionStore.performedTransition]);

  return stateTransitionChanged;
};
