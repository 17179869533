import {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBagsShopping} from '@fortawesome/pro-solid-svg-icons';
import {WidgetLayout} from '../../../models/widgetLayout';
import OrderWidgetModal from '../orderDetails/OrderWidgetModal';
import {WidgetLayouts} from '../../shared/widgets/WidgetLayouts';
import OperatorWidget from '../../operator/shared/OperatorWidget';
import {OrderOperatorWidgetConfig} from './orderOperatorWidgetConfig';
import OrderWidgetDetailsModal from '../orderDetails/OrderWidgetDetailsModal';
import {ActiveOperationWidgetConfig} from '../../operation/activeOperationWidgetConfig';
import ActiveOperationDetailsWidget from '../../operation/ActiveOperationDetailsWidget';
import {useStore} from '../../../hooks/useStore';
import {ScopeContext} from '../../../policies/scopeContext';
import {orderManagement} from '../../../models/scope';
import OrderTableStateChange from '../operationList/OrderTableStateChange';

const OrderOperatorWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const [modalVisible, setModalVisible] = useState(false);
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const workplaceId = store.workplaceStore.selectedWorkplace?.id;
  const orderOperatorWidgetConfig = useMemo(() => (
    new OrderOperatorWidgetConfig(store, OrderOperatorWidget.identifier)
  ), [
    store.operationStateStore.operationStates.length,
    store.propertyStore.hasPendingRequests,
    store.languageStore.lang,
  ]);
  const activeOperationWidgetConfig = useMemo(
    () => new ActiveOperationWidgetConfig(store, ActiveOperationDetailsWidget.identifier),
    [store.propertyStore.hasPendingRequests, store.languageStore.lang]
  );
  const [selectedOperation, setSelectedOperation] = useState(undefined);
  const [isReordering, setIsReordering] = useState(false);

  return (
    <ScopeContext.Provider value={[orderManagement]}>
      <OperatorWidget
        onHeadClick={() => {
          setSelectedOperation(undefined);
          setModalVisible(true);
        }}
        icon={<FontAwesomeIcon icon={faBagsShopping}/>}
        title={orderOperatorWidgetConfig.getWidgetTitle()}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={orderOperatorWidgetConfig}
        manualPath={'/order/operation'}
      >
        <OrderTableStateChange
          showTypeSelect={false}
          workplaceId={workplaceId}
          identifier={identifier}
          settings={orderOperatorWidgetConfig.getValues()}
          columnsPlanned={orderOperatorWidgetConfig.getOrderTableColumnsPlanned()}
          columnsFinished={orderOperatorWidgetConfig.getOrderTableColumnsFinished()}
          activeFilter={orderOperatorWidgetConfig.getActiveOperationFilter()}
          operationStateIds={orderOperatorWidgetConfig.getSelectedOperationStateIds()}
          setDetailsModalVisible={setDetailsModalVisible}
          useSelectedOperation={[selectedOperation, setSelectedOperation]}
          widgetConfig={orderOperatorWidgetConfig}
        />
      </OperatorWidget>
      <OrderWidgetDetailsModal
        useSelectedOperation={[selectedOperation, setSelectedOperation]}
        open={detailsModalVisible}
        onCancel={() => setDetailsModalVisible(false)}
        widgetConfig={activeOperationWidgetConfig}
      />
      <OrderWidgetModal
        workplaceId={workplaceId}
        identifier={identifier}
        settings={orderOperatorWidgetConfig.getValues()}
        columnsPlanned={orderOperatorWidgetConfig.getOrderTableColumnsPlanned()}
        columnsFinished={orderOperatorWidgetConfig.getOrderTableColumnsFinished()}
        operationStateIds={orderOperatorWidgetConfig.getSelectedOperationStateIds()}
        activeFilter={orderOperatorWidgetConfig.getActiveOperationFilter()}
        onCancel={() => setModalVisible(false)}
        visible={modalVisible}
        useSelectedOperation={[selectedOperation, setSelectedOperation]}
        useIsReordering={[isReordering, setIsReordering]}
      />
    </ScopeContext.Provider>
  );
};

OrderOperatorWidget.icon = faBagsShopping;

OrderOperatorWidget.identifier = 'OrderOperatorWidget';
OrderOperatorWidget.defaultLayout = new WidgetLayout(
  {
    identifier: OrderOperatorWidget.identifier,
    x: 6,
    y: 0,
    height: 8,
    minHeight: 1,
    width: WidgetLayouts.halfWidth.w,
    minWidth: WidgetLayouts.halfWidth.minW,
  }
);
export default observer(OrderOperatorWidget);
