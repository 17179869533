import React from 'react';
import { Alert, AlertProps } from 'antd';

export type AlertWarningProps = AlertProps;

const AlertWarning: React.FC<AlertWarningProps> = (props) => (
  <Alert
    type="warning"
    {...props}
  />
);

export default AlertWarning;
