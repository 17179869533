import React, { useRef, useState } from 'react';
import { difference, groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Col, RadioGroupProps, Row, Space, Transfer, TransferProps, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FormListFieldData } from 'antd/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Rule, RuleObject } from 'antd/es/form';
import styles from './WidgetSettingsForm.module.scss';
import SortableTable from '../tables/SortableTable';
import Table from '../tables/Table';
import { arrayMove } from '../../../utils/helpers';
import HelpTooltip from '../tooltips/HelpTooltip';
import Form, { FormItemProps } from '../form/Form';
import Input, { InputProps } from '../inputs/Input';
import Select, { SelectProps } from '../inputs/Select';
import Radio from '../inputs/Radio';
import Switch, { SwitchProps } from '../inputs/Switch';
import ColorPicker, { ColorPickerProps } from '../inputs/ColorPicker';
import HierarchyTree, { HierarchyTreeDataStrategy, HierarchyTreeProps } from '../inputs/hierarchyTree/HierarchyTree';
import DeleteButton from '../buttons/DeleteButton';
import Button from '../buttons/Button';
import { ValidationRules } from '../../../utils/validationRules';
import { sortAlphabetically } from '../tables/sorters';
import { useStore } from '../../../hooks/useStore';
import WidgetSettingFormField from './WidgetSettingFormField';
import { Operation } from '../../../models/operation';
import Tabs from '../tabs/Tabs';
import { iconSelectOptions } from '../../../utils/icon';
import PropertiesTreeSelect from './formFieldComponents/PropertiesTreeSelect';
import { TranslatableInput, TranslatableInputProps } from '../inputs/TranslatableInput';
import StorageUnitSelector, { StorageUnitSelectorProps } from '../inputs/autocomplete/StorageUnitSelector';
import i18n from '../../../i18n/i18n';
import {
  ConfigField,
  FieldOption,
  FieldTranslations,
  FieldType,
  ListElement,
  NestedModelOption,
} from '../../../types/widgetConfig';
import { DisplayablePropertyParams } from '../../../models/displayableProperty';
import { OrderUpdate } from '../../../types/sortable';

const { TabPane } = Tabs;

const getDisabledValue = (field: ConfigField) => {
  const form = Form.useFormInstance();
  return typeof field.disabled === 'function' ? field.disabled(form) : field.disabled;
};

export type ValidatorRule = {
  warningOnly?: boolean;
  message?: string | React.ReactElement;
  validator: (rule: RuleObject, value: any, callback: (error?: string) => void) => Promise<void> | void;
};

export type FieldWrapperProps = FormItemProps & {
  field: ConfigField;
  defaultValue?: any;
};

export const FieldWrapper: React.FC<FieldWrapperProps> = ({ field, children, defaultValue, ...props }) => {
  const FieldLabel = () => (
    <span>
      {field.label}
      <HelpTooltip title={field.helpText}/>
    </span>
  );

  return (
    <Form.Item
      name={field.id}
      label={field.label ? <FieldLabel/> : ''}
      rules={field.validations}
      initialValue={field.defaultValue}
      onBlur={field.onBlur}
      dependencies={field.dependencies}
      {...props}
    >
      {children}
    </Form.Item>
  );
};

type FieldProp = {
  field: ConfigField;
};

export const InputField: React.FC<FieldProp & InputProps> = ({ field, ...props }) => (
  <Input
    disabled={getDisabledValue(field)}
    {...props}
  />
);

export const SelectField: React.FC<FieldProp & SelectProps<any>> = ({
  field,
  optionFilterProp = 'label',
  ...props
}) => {
  const defaultKey = 'default';
  const getSelectOption = (group: string, option: FieldOption) => (
    <Select.Option
      key={`${group}_${option.value}`}
      value={group === defaultKey ? option.value : `${group}:${option.value}`}
      label={option.label}
    >
      <span>{option.label}</span>
      <span style={{ float: 'right' }}>
        <HelpTooltip title={option.helpText}/>
      </span>
    </Select.Option>
  );

  const options = typeof field.options === 'function' ? field.options() : field.options;
  const grouped = groupBy(options, (option) => option.group?.key || 'default');
  const groups = Object.entries(grouped).filter(([key]) => key !== defaultKey);

  return (
    <Select
      optionFilterProp={optionFilterProp}
      className={styles.selectField}
      allowClear={field.allowClear}
      showSearch={field.showSearch || undefined}
      disabled={getDisabledValue(field)}
      {...props}
    >
      {groups?.map(([group, items]) => (
        <Select.OptGroup key={group} label={items[0].group?.label}>
          {items?.map((option) => getSelectOption(group, option))}
        </Select.OptGroup>
      ))}
      {grouped[defaultKey]?.map((option) => getSelectOption(defaultKey, option))}
    </Select>
  );
};

export const StorageUnitSelectorField: React.FC<FieldProp & StorageUnitSelectorProps> = ({ field, ...props }) => {
  const form = Form.useFormInstance();

  return (
    <StorageUnitSelector
      form={form}
      name={field.id as string | undefined}
      disabled={getDisabledValue(field)}
      {...props}
    />
  );
};

export const NumberField: React.FC<FieldProp & InputProps> = ({ field, ...props }) => (
  <Input
    type={'number'}
    addonAfter={field.unit ? field.unit : null}
    disabled={getDisabledValue(field)}
    {...props}
  />
);

export const BooleanField: React.FC<FieldProp & SwitchProps> = ({ field, ...props }) => (
  <Switch disabled={getDisabledValue(field)} {...props}/>
);

type PropertiesFieldProps = TransferProps<DisplayablePropertyParams> & {
  availableProperties?: DisplayablePropertyParams[];
  texts?: FieldTranslations;
  value?: string[];
  hasStoredSettings?: boolean;
  label?: string;
  isLoading?: boolean;
};

export const PropertiesField: React.FC<PropertiesFieldProps> = ({
  availableProperties = [],
  texts = {},
  value,
  onChange,
  hasStoredSettings,
  label,
  isLoading,
  ...props
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: texts.headerPropertyName || t('table.WidgetSettingsFormFields.headerPropertyName'),
      render: ({ title, prefix }: { title: string, prefix: string }) => (prefix ? `${prefix} > ${title}` : title),
    },
  ];

  return (
    <>
      {label && (<div style={{ paddingBottom: 4 }}>{label}</div>)}
      <Transfer
        {...props}
        titles={[
          texts.hiddenProperties || t<string>('table.WidgetSettingsFormFields.hiddenProperties'),
          texts.visibleProperties || t<string>('table.WidgetSettingsFormFields.visibleProperties'),
        ]}
        locale={{
          itemUnit: texts.itemUnit || t<string>('table.WidgetSettingsFormFields.itemUnit'),
          itemsUnit: texts.itemsUnit || t<string>('table.WidgetSettingsFormFields.itemsUnit'),
          notFoundContent: texts.notFoundContent || t<string>('table.WidgetSettingsFormFields.notFoundContent'),
          searchPlaceholder: texts.searchPlaceholder || t<string>('table.WidgetSettingsFormFields.searchPlaceholder'),
          selectInvert: texts.selectInvert || t<string>('table.WidgetSettingsFormFields.selectInvert'),
          selectAll: texts.selectAll || t<string>('table.WidgetSettingsFormFields.selectAll'),
        }}
        showSelectAll
        showSearch
        targetKeys={value}
        onChange={onChange}
        filterOption={(inputValue, item) => item.title.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1}
        dataSource={availableProperties}
      >
        {({
          direction,
          filteredItems,
          onItemSelectAll,
          onItemSelect,
          selectedKeys: listSelectedKeys,
          disabled: listDisabled,
        }) => {
          const rowSelection = {
            getCheckboxProps: (item: DisplayablePropertyParams) => ({
              disabled: listDisabled || item.disabled,
              className: 'drag-visible',
            }),
            onSelectAll(selected: boolean, selectedRows: DisplayablePropertyParams[]) {
              const treeSelectedKeys = selectedRows
                .filter((item) => !item.disabled)
                .map((item) => item.key);
              const diffKeys = selected
                ? difference(treeSelectedKeys, listSelectedKeys)
                : difference(listSelectedKeys, treeSelectedKeys);
              onItemSelectAll(diffKeys, selected);
            },
            onSelect({ key }: DisplayablePropertyParams, selected: boolean) {
              onItemSelect(key, selected);
            },
            selectedRowKeys: listSelectedKeys,
          };

          const onOrderUpdated = ({ oldIndex, newIndex }: OrderUpdate) => {
            const newKeys = arrayMove(filteredItems, oldIndex, newIndex)
              .map(({ key }: DisplayablePropertyParams) => key);
            if (onChange) {
              onChange(newKeys, 'right', newKeys);
            }
          };

          return direction === 'right' ? (
            <SortableTable
              showHeader={false}
              showDragHandle
              dataSource={filteredItems}
              onOrderUpdated={onOrderUpdated}
              columns={columns}
              rowSelection={rowSelection}
              onRow={({ key, disabled: itemDisabled }: DisplayablePropertyParams) => ({
                onClick: () => {
                  if (itemDisabled || listDisabled) {
                    return;
                  }
                  onItemSelect(key, !listSelectedKeys.includes(key));
                },
              })}
              style={{ pointerEvents: listDisabled ? 'none' : undefined, height: 300 }}
              loading={isLoading}
            />
          ) : (
            <Table<DisplayablePropertyParams>
              showHeader={false}
              dataSource={filteredItems}
              columns={columns}
              rowSelection={rowSelection}
              pagination={false}
              onRow={({ key, disabled: itemDisabled }) => ({
                onClick: () => {
                  if (itemDisabled || listDisabled) {
                    return;
                  }
                  onItemSelect(key, !listSelectedKeys.includes(key));
                },
              })}
              style={{ pointerEvents: listDisabled ? 'none' : undefined, height: 300 }}
              loading={isLoading}
            />
          );
        }}
      </Transfer>
    </>
  );
};

export const ColorField: React.FC<FieldProp & ColorPickerProps> = ({ field, ...props }) => (
  <ColorPicker disabled={getDisabledValue(field)} {...props}/>
);

export const HierarchyTreeField: React.FC<FieldProp & HierarchyTreeProps> = ({ field, onChange, ...props }) => {
  const handleOnChange = (change: number | undefined) => {
    if (onChange) {
      onChange(change);
    }
    if (field.onChange) {
      field.onChange(change);
    }
  };

  return (
    <HierarchyTree
      treeDataStrategy={HierarchyTreeDataStrategy.FULL}
      allowClear
      onChange={handleOnChange}
      disabled={getDisabledValue(field)}
      {...props}
      isDisabled={field.isDisabled ? field.isDisabled : () => false}
    />
  );
};

export const ModelPropertySelectField: React.FC<FieldProp> = ({ field, ...props }) => {
  const form = Form.useFormInstance();
  const { t } = useTranslation();
  const columns = (remove: (index: number) => void): ColumnsType<FormListFieldData> => [
    {
      title: t('ModelPropertySelectField.label'),
      width: '30%',
      render: (listField) => (
        <Form.Item
          {...listField}
          name={[listField.name, 'label']}
          fieldKey={[listField.fieldKey, 'label']}
          wrapperCol={{ sm: 24 }}
          style={{ marginBottom: 0 }}
        >
          <Input placeholder={t<string>('ModelPropertySelectField.label')}/>
        </Form.Item>
      ),
    },
    {
      title: t('ModelPropertySelectField.model'),
      width: '30%',
      render: ({ ...listField }) => (
        <Form.Item
          {...listField}
          name={[listField.name, 'model']}
          fieldKey={[listField.fieldKey, 'model']}
          rules={[ValidationRules.required()]}
          wrapperCol={{ sm: 24 }}
          style={{ marginBottom: 0 }}
        >
          <Select
            onChange={() => {
              const value = form.getFieldValue(field.id);
              if (value[listField.key]?.property) {
                value[listField.key].property = undefined;
              }
              form.setFieldValue(field.id, value);
            }}
          >
            {(field.modelOptions as NestedModelOption[])
              ?.sort((a, b) => sortAlphabetically(a.label, b.label)).map((model) => (
                <Select.Option value={model.key} key={model.key}>
                  {model.label}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: t('ModelPropertySelectField.property'),
      width: '30%',
      render: (listField) => {
        const selectedModel = form.getFieldValue([field.id, listField.name, 'model']);
        const modelOptions: NestedModelOption[] = field.modelOptions as NestedModelOption[] || [];
        const modelOption = modelOptions.find((model) => model.key === selectedModel);
        const options = typeof modelOption?.properties === 'function'
          ? modelOption?.properties()
          : modelOption?.properties;

        return (
          <Form.Item
            {...listField}
            name={[listField.name, 'property']}
            fieldKey={[listField.fieldKey, 'property']}
            rules={[ValidationRules.required()]}
            wrapperCol={{ sm: 24 }}
            style={{ marginBottom: 0 }}
          >
            <Select disabled={!selectedModel}>
              {selectedModel && options?.map((property) => (
                <Select.Option value={property.key} key={property.key}>
                  {property.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );
      },
    },
    {
      title: '',
      render: (listField) => (
        <DeleteButton confirmType="none" onConfirm={() => remove(listField.name)}/>
      ),
    },
  ];

  return (
    <Form.List
      {...props}
      rules={field.validations as ValidatorRule[]}
      name={field.id}
    >
      {(listFields, { add, remove, move }) => (
        <Space direction="vertical" style={{ width: '100%' }}>
          <SortableTable<FormListFieldData>
            showHeader={false}
            showDragHandle
            dataSource={listFields}
            onOrderUpdated={
              ({ oldIndex, newIndex }) => {
                move(oldIndex, newIndex);
              }
            }
            columns={columns(remove)}
          />
          <Button onClick={() => add()} style={{ paddingLeft: '15px' }}>
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: '0.5em' }}/>
            {t('ModelPropertySelectField.new')}
          </Button>
        </Space>
      )}
    </Form.List>
  );
};

export const PropertiesSelectDisplayField: React.FC<FieldProp> = ({ field, ...props }) => {
  const form = Form.useFormInstance();
  const fieldValuePath = field.name || field.id;
  let arrFieldValuePath: (string | number)[];
  if (typeof fieldValuePath === 'string' || typeof fieldValuePath === 'number') {
    arrFieldValuePath = [fieldValuePath];
  } else {
    arrFieldValuePath = fieldValuePath;
  }
  const { t } = useTranslation();
  const tableRef = useRef<HTMLDivElement>(null);
  const widthColContentByPercent = 45;
  const withColHeaderBySpan = Math.round(0.24 * widthColContentByPercent); // ant: 1 row with 24 cols

  const columns = (remove: (field: number) => void) => [
    {
      title: t('propertiesSelectDisplayField.model'),
      width: `${widthColContentByPercent}%`,
      render: (fieldListElement: ListElement) => (
        <PropertiesTreeSelect
          form={form}
          field={field}
          fieldListElement={fieldListElement}
          fieldValuePath={arrFieldValuePath}
        />
      ),
    },
    {
      title: t('propertiesSelectDisplayField.label'),
      width: `${widthColContentByPercent}%`,
      render: (listField: ListElement) => {
        const modelOptions = field.modelOptions as DisplayablePropertyParams[] || [];
        const modelOption = modelOptions
          ?.find((opt) => opt.key === form?.getFieldValue([...arrFieldValuePath, listField.name, 'property']));
        return (
          <Form.Item
            {...listField}
            name={[listField.name, 'label']}
            fieldKey={[listField.fieldKey, 'label']}
            rules={[ValidationRules.required()]}
            wrapperCol={{ sm: 24 }}
            style={{ marginBottom: 0 }}
          >
            <TranslatableInput
              translatedLabel={modelOption?.title}
            />
          </Form.Item>
        );
      },
    },
    {
      title: '',
      render: (_0: any, _1: any, index: number) => <DeleteButton confirmType="none" onConfirm={() => remove(index)}/>,
    },
  ];
  const handleAdd = (addFunc: () => void) => {
    addFunc();
    // FIXME: This code is probably supposed to scroll to the newly property, but doesn't work at the moment. Commented
    //  out to remove ts errors.
    // const element = tableRef.current?.children[0]?.children[0]?.children[0];
    // if (element) {
    //   setTimeout(() => {
    //     const height = element.children[0].offsetHeight;
    //     element.scrollTo(0, height);
    //   }, 0);
    // }
  };

  return (
    <div className={styles.propertiesSelectDisplayField}>
      {field?.label ? <h4 className={styles.headerTitle}>{field.label}</h4> : ''}
      <div className={styles.contentWrapper}>
        <Row>
          <Col span={withColHeaderBySpan} style={{ marginLeft: '15px' }}>
            {t('propertiesSelectDisplayField.displayCol')}
          </Col>
          <Col className={styles.headerClass} span={withColHeaderBySpan}>
            {t('propertiesSelectDisplayField.displayName')}
          </Col>
        </Row>
        <Row className={styles.propertiesWrapper}>
          <Form.List {...props} rules={field.validations as ValidatorRule[]} name={field.id}>
            {(listFields, { add, remove, move }) => (
              <Space direction="vertical" style={{ width: '100%' }}>
                <div ref={tableRef}>
                  <SortableTable
                    showHeader={false}
                    showDragHandle
                    dataSource={listFields}
                    onOrderUpdated={
                      ({ oldIndex, newIndex }: OrderUpdate) => {
                        move(oldIndex, newIndex);
                      }
                    }
                    columns={columns(remove)}
                    className={form.getFieldValue(fieldValuePath)?.length ? styles.autoWidth : styles.fullWidth}
                  />
                </div>
                <Button onClick={() => handleAdd(add)} style={{ marginLeft: '15px' }}>
                  <FontAwesomeIcon icon={faPlus} style={{ marginRight: '0.5em' }}/>
                  {t('propertiesSelectDisplayField.new')}
                </Button>
              </Space>
            )}
          </Form.List>
        </Row>
      </div>
    </div>
  );
};

export type HierarchyTreeWorkplaceFieldProps = FieldProp
  & Omit<HierarchyTreeProps, 'allowClear' | 'placeholder' | 'disabled'>;
export const HierarchyTreeWorkplaceField: React.FC<HierarchyTreeWorkplaceFieldProps> = ({
  field,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  const store = useStore();

  const handleOnChange = (change: number | undefined) => {
    if (onChange) {
      onChange(change);
    }
    if (field.onChange) {
      field.onChange(change);
    }
  };

  return (
    <HierarchyTree
      allowClear
      onChange={handleOnChange}
      placeholder={t('workplace.model.one')}
      disabled={getDisabledValue(field)}
      {...props}
      isDisabled={(hierarchy) => !store.workplaceStore.workplaces.find((wp) => wp.hierarchyId === hierarchy.id)}
    />
  );
};

type PropertyGroupEditorProps = {
  parentName: string | number;
};
export const PropertyGroupEditorField: React.FC<FieldProp & PropertyGroupEditorProps> = ({ parentName, field }) => {
  const form = Form.useFormInstance();
  const { t } = useTranslation();
  const store = useStore();
  const [refresh, setRefresh] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('tab-0');
  const defaultTitle = t('activeOperationWidget.settings.modal.custom.defaultGroupTitle');

  const getOtherFullPath = (rule: any, otherName: string): string[] => {
    const split = rule.field.split('.');
    return [`${split[0]}.${split[1]}`, split[2], split[3], split[4], otherName];
  };

  const groupFields: ConfigField[] = [
    {
      id: 'title',
      label: t<string>('activeOperationWidget.settings.modal.custom.groupTitle.label'),
      helpText: t<string>(
        'activeOperationWidget.settings.modal.custom.groupTitle.helpText',
        { showGroupLabelOption: t('activeOperationWidget.settings.modal.custom.groupShowGroupLabel.label') }
      ),
      type: FieldType.String,
      validations: [ValidationRules.required()],
      defaultValue: defaultTitle,
      onBlur: () => setRefresh(!refresh),
    },
    {
      id: 'showGroupLabel',
      label: t<string>('activeOperationWidget.settings.modal.custom.groupShowGroupLabel.label'),
      helpText: t<string>('activeOperationWidget.settings.modal.custom.groupShowGroupLabel.helpText'),
      type: FieldType.Boolean,
      defaultValue: true,
    },
    {
      id: 'columnCount',
      label: t<string>('activeOperationWidget.settings.modal.custom.groupColumnCount.label'),
      helpText: t<string>('activeOperationWidget.settings.modal.custom.groupColumnCount.helpText'),
      type: FieldType.Number,
      defaultValue: 1,
      validations: [ValidationRules.required(), ValidationRules.minNumber(1), ValidationRules.maxNumber(12)],
    },
    {
      id: 'showPropertyLabels',
      label: t<string>('activeOperationWidget.settings.modal.custom.groupShowPropertyLabels.label'),
      helpText: t<string>('activeOperationWidget.settings.modal.custom.groupShowPropertyLabels.helpText'),
      type: FieldType.Boolean,
      defaultValue: true,
    },
    {
      id: 'properties',
      type: FieldType.PropertiesSelectDisplayField,
      modelOptions: Operation.allDisplayableProperties(store),
      defaultValue: [],
    },
    {
      id: 'barcode',
      label: t<string>('activeOperationWidget.settings.barcode.label'),
      helpText: t<string>('activeOperationWidget.settings.barcode.help'),
      type: FieldType.SelectMultiple,
      defaultValue: [],
      validations: [
        ValidationRules.notInField(
          (rule: any) => getOtherFullPath(rule, 'dataMatrix'),
          i18n.t('activeOperationWidget.settings.dataMatrix.label')
        ),
        ValidationRules.notInField(
          (rule: any) => getOtherFullPath(rule, 'qrCode'),
          i18n.t('activeOperationWidget.settings.qrCode.label')
        ),
      ],
      options: Operation.allDisplayableProperties(store).map((item) => ({
        value: item.key,
        label: item.title,
      })),
    },
    {
      id: 'qrCode',
      label: t<string>('activeOperationWidget.settings.qrCode.label'),
      helpText: t<string>('activeOperationWidget.settings.qrCode.help'),
      type: FieldType.SelectMultiple,
      defaultValue: [],
      validations: [
        ValidationRules.notInField(
          (rule: any) => getOtherFullPath(rule, 'barcode'),
          i18n.t('activeOperationWidget.settings.barcode.label')
        ),
        ValidationRules.notInField(
          (rule: any) => getOtherFullPath(rule, 'dataMatrix'),
          i18n.t('activeOperationWidget.settings.dataMatrix.label')
        ),
      ],
      options: Operation.allDisplayableProperties(store).map((item) => ({
        value: item.key,
        label: item.title,
      })),
    },
    {
      id: 'dataMatrix',
      label: t<string>('activeOperationWidget.settings.dataMatrix.label'),
      helpText: t<string>('activeOperationWidget.settings.dataMatrix.help'),
      type: FieldType.SelectMultiple,
      defaultValue: [],
      validations: [
        ValidationRules.notInField(
          (rule: Rule) => getOtherFullPath(rule, 'barcode'),
          i18n.t('activeOperationWidget.settings.barcode.label')
        ),
        ValidationRules.notInField(
          (rule: Rule) => getOtherFullPath(rule, 'qrCode'),
          i18n.t('activeOperationWidget.settings.qrCode.label')
        ),
      ],
      options: Operation.allDisplayableProperties(store).map((item) => ({
        value: item.key,
        label: item.title,
      })),
    },
  ];

  const onEdit = (
    targetKey: string,
    action: 'remove' | 'add',
    add: () => void,
    remove: (index: number) => void,
    currentTabCount: number
  ) => {
    if (action === 'add') {
      add();
      setActiveTabKey(`tab-${currentTabCount}`);
    } else if (action === 'remove') {
      const index = parseInt(targetKey.substring(4), 10);
      const activeIndex = parseInt(activeTabKey.substring(4), 10);
      remove(index);
      if (activeIndex > index) {
        setActiveTabKey(`tab-${activeIndex - 1}`);
      } else if (index === currentTabCount - 1) {
        setActiveTabKey(`tab-${currentTabCount - 2}`);
      }
    }
  };

  return (
    <Form.List name={[parentName, 'groups']}>
      {(fields, { add, remove }) => (
        <Tabs
          tabPosition={'top'}
          type={'editable-card'}
          onEdit={(targetKey, action) => onEdit(targetKey as string, action, add, remove, fields.length)}
          activeKey={activeTabKey}
          onChange={setActiveTabKey}
          hideAdd={[...fields].length >= 10}
        >
          {fields.map(({ key, name, fieldKey, ...restField }) => (
            <TabPane
              tab={(
                <Typography.Text style={{ maxWidth: 120, textAlign: 'left' }} ellipsis>
                  {form.getFieldValue([String(field.id), parentName, 'groups', name, 'title']) || defaultTitle}
                </Typography.Text>
              )}
              key={`tab-${name}`}
              closable={fields.length > 1}
              forceRender
            >
              {groupFields.map((tf) => {
                const groupField: ConfigField = {
                  ...tf,
                  ...restField,
                  id: [name, String(tf.id)],
                  name: [String(field.id), parentName, 'groups', name, String(tf.id)],
                };
                return (
                  <div key={String(groupField.id)}>
                    <WidgetSettingFormField field={groupField}/>
                  </div>
                );
              })}
            </TabPane>
          ))}
        </Tabs>
      )}
    </Form.List>
  );
};

export const CustomTabEditorField: React.FC<FieldProp> = ({ field }) => {
  const form = Form.useFormInstance();
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('tab-0');
  const defaultTitle = t('activeOperationWidget.settings.modal.custom.defaultTabTitle');

  const maxTabCount = field?.maxTabCount || undefined;

  const tabFields: ConfigField[] = field?.tabFields || [
    {
      id: 'title',
      label: t<string>('activeOperationWidget.settings.modal.custom.tabTitle.label'),
      helpText: t<string>('activeOperationWidget.settings.modal.custom.tabTitle.helpText'),
      type: FieldType.String,
      validations: [ValidationRules.required()],
      defaultValue: defaultTitle,
      onBlur: () => setRefresh(!refresh),
    },
    {
      id: 'icon',
      label: t<string>('activeOperationWidget.settings.modal.custom.tabIcon.label'),
      helpText: t<string>('activeOperationWidget.settings.modal.custom.tabIcon.helpText'),
      type: FieldType.Select,
      validations: [ValidationRules.required()],
      defaultValue: 'infoCircle',
      options: iconSelectOptions,
    },
    {
      id: 'columnCount',
      label: t<string>('activeOperationWidget.settings.modal.custom.tabColumnCount.label'),
      helpText: t<string>('activeOperationWidget.settings.modal.custom.tabColumnCount.helpText'),
      type: FieldType.Number,
      defaultValue: 1,
      validations: [ValidationRules.required(), ValidationRules.minNumber(1), ValidationRules.maxNumber(12)],
    },
    {
      id: 'showEmpty',
      label: t<string>('activeOperationWidget.settings.modal.custom.showEmpty.label'),
      helpText: t<string>('activeOperationWidget.settings.modal.custom.showEmpty.helpText'),
      type: FieldType.Boolean,
      defaultValue: false,
    },
  ];

  const onEdit = (
    targetKey: string,
    action: 'remove' | 'add',
    add: () => void,
    remove: (index: number) => void,
    currentTabCount: number
  ) => {
    if (action === 'add') {
      add();
      setActiveTabKey(`tab-${currentTabCount}`);
    } else if (action === 'remove') {
      const index = parseInt(targetKey.substring(4), 10);
      const activeIndex = parseInt(activeTabKey.substring(4), 10);
      remove(index);
      if (activeIndex > index) {
        setActiveTabKey(`tab-${activeIndex - 1}`);
      } else if (index === currentTabCount - 1) {
        setActiveTabKey(`tab-${currentTabCount - 2}`);
      }
    }
  };

  return (
    <Form.List name={field.id}>
      {(fields, { add, remove }) => (
        <Tabs
          tabPosition={'left'}
          type={'editable-card'}
          onEdit={(targetKey, action) => onEdit(targetKey as string, action, add, remove, fields.length)}
          activeKey={activeTabKey}
          onChange={setActiveTabKey}
          hideAdd={maxTabCount ? fields.length >= maxTabCount : false}
        >
          {fields.map(({ key, name, fieldKey, ...restField }) => (
            <TabPane
              tab={(
                <Typography.Text style={{ width: 120, textAlign: 'left' }} ellipsis>
                  {form.getFieldValue([String(field.id), name, 'title']) || defaultTitle}
                </Typography.Text>
              )}
              key={`tab-${name}`}
              closable={fields.length > 1}
              forceRender
            >
              {tabFields.map((tf) => {
                const tabField: ConfigField = {
                  ...tf,
                  ...restField,
                  id: [name, String(tf.id)],
                  name: [String(field.id), name, String(tf.id)],
                };
                return (
                  <div key={`${name}-${tf.id}`}>
                    <WidgetSettingFormField field={tabField}/>
                  </div>
                );
              })}
              {!field.disableGroupEditor && (
                <PropertyGroupEditorField parentName={name} field={field}/>
              )}
            </TabPane>
          ))}
        </Tabs>
      )}
    </Form.List>
  );
};

export const RadioField: React.FC<FieldProp & RadioGroupProps> = ({
  field,
  ...props
}) => {
  const options: FieldOption[] = typeof field?.options === 'function' ? field.options() : field?.options || [];

  return (
    <Radio.Group disabled={getDisabledValue(field)} {...props}>
      <Space direction={'horizontal'}>
        {
          options?.map((option) => (
            <Radio.Button
              key={String(option.value)}
              value={option.value}
            >
              {option.label}
            </Radio.Button>
          )) || null
        }
      </Space>
    </Radio.Group>
  );
};

export type TranslatableInputFieldProps = FieldProp & Omit<TranslatableInputProps, 'translatedLabel'>;
export const TranslatableInputField: React.FC<TranslatableInputFieldProps> = ({ field, ...props }) => (
  <TranslatableInput
    {...props}
    translatedLabel={field.label}
  />
);
