import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import Modal from '../../shared/modal/Modal';
import Button from '../../shared/buttons/Button';

const IsBrokenModal = ({
  onSuccess,
  onCancel,
  terminal,
}) => {
  const {t} = useTranslation();

  return (
    <Modal
      open
      title={t('terminalManagement.modal.isBrokenModal.title')}
      onCancel={onCancel}
      footer={[
        <Button key={'cancel'} onClick={() => onCancel()}>
          {t('links.cancel')}
        </Button>,
        <Button key={'add'} type={'primary'} onClick={() => onSuccess(true)}>
          {t('terminalManagement.modal.isBrokenModal.repair')}
        </Button>,
      ]}
    >
      {t('terminalManagement.modal.isBrokenModal.info', {terminalName: terminal.name})}
    </Modal>
  );
};

export default observer(IsBrokenModal);
