import {createEntity, executeGet, executePost} from '../requests';

const resourcesName = 'v1/consumptions';

export const consumeEndpoint = 'consume';
export const validateConsumptionResource = 'validate';
export const consumeRetrogradeEndpoint = 'consumeRetrograde';
export const consumeRetrogradeEndpointResource = 'retrograde';
export const validateConsumptionEndpoint = 'validateConsumption';
export const consumeIngredientEndpoint = 'consumeIngredient';
export const consumeIngredientResource = 'ingredient';

export const ConsumptionsApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  create: (entity) => createEntity(entity, resourcesName),
  [consumeEndpoint]: (params) => executePost(`${resourcesName}`, params),
  [consumeRetrogradeEndpoint]: (params) => executePost(`${resourcesName}/${consumeRetrogradeEndpointResource}`, params),
  [validateConsumptionEndpoint]: (params) => executePost(`${resourcesName}/${validateConsumptionResource}`, params),
  [consumeIngredientEndpoint]: (params) => executePost(`${resourcesName}/${consumeIngredientResource}`, params),
};
