import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
import { Sensor, SensorStatusType } from '../../../models/sensor';
import styles from '../../plantCockpit/Sensors.module.scss';
import Speedometer from '../charts/Speedometer';
import { EnDash } from '../unicodeWrapper/EnDash';
import { getStateColor } from '../../../utils/stateColor';
import { findNotNil } from '../../../utils/helpers';
import { SensorData } from '../../../models/sensorData';
import { SensorDataLimit } from '../../../models/sensorDataLimit';

export type SensorStatusDisplayProps = {
  sensor: Sensor;
  sensorData: SensorData;
  limit: SensorDataLimit;
};

export const SensorStatusDisplay: React.FC<SensorStatusDisplayProps> = ({ sensor, sensorData, limit }) => {
  const { t } = useTranslation();

  const getDisplay = () => {
    const value = sensor ? sensor.roundedValue(sensorData?.value) : sensorData?.value;

    switch (sensor?.statusType) {
      case SensorStatusType.Boolean:
        return (
          <div className={styles.sensorCardValue}>
            {`[ ${(value === 1) ? t('sensor.status.on') : t('sensor.status.off')} ]`}
          </div>
        );
      case SensorStatusType.Speedometer: {
        return (
          <div className={styles.sensorSpeedometerWrapper}>
            <Speedometer
              width={200}
              height={130}
              value={value}
              minValue={findNotNil(limit?.plausibleLow, limit?.toleranceLow, limit?.warningLow)}
              maxValue={findNotNil(limit?.plausibleHigh, limit?.toleranceHigh, limit?.warningHigh)}
              toleranceLow={limit?.toleranceLow}
              toleranceHigh={limit?.toleranceHigh}
              warningLow={limit?.warningLow}
              warningHigh={limit?.warningHigh}
              unit={sensor.unitOfMeasure?.label || EnDash()}
              labelsEnabled={false}
            />
          </div>
        );
      }
      default: {
        return (
          <div className={styles.sensorCardValue}>
            <span>[ </span>
            {value || EnDash()}
            <span> </span>
            {sensor?.unitOfMeasure?.label || ''}
            <span> ]</span>
          </div>
        );
      }
    }
  };

  return (
    <>
      <div className={styles.sensorLabel}>
        <FontAwesomeIcon
          icon={faProjectDiagram}
          style={{ color: getStateColor(sensorData?.state), marginRight: '10px' }}
        />
        {sensor?.label || ''}
      </div>
      <div className={styles.sensorValue}>
        {getDisplay()}
      </div>
    </>
  );
};
