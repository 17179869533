import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';

type MarkdownItemProps = Omit<ReactMarkdownOptions, 'remarkPlugins' | 'className'>;

const MarkdownItem: React.FC<MarkdownItemProps> = ({ children, ...props }) => (
  <ReactMarkdown
    skipHtml
    linkTarget={'_blank'}
    {...props}
    remarkPlugins={[remarkGfm]}
    className={'markdown-body'}
  >
    {children}
  </ReactMarkdown>
);

export default MarkdownItem;
