import { v4 as uuid } from 'uuid';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { every, get, isObject, omitBy, snakeCase, some } from 'lodash';
import { action, isObservableArray, makeObservable, observable } from 'mobx';
import { faInfo } from '@fortawesome/pro-solid-svg-icons';
import { sortNumerically } from '../components/shared/tables/sorters';
import { transformation } from '../utils/transformations';
import { DisplayablePropertyParams, displayableProperty } from './displayableProperty';
import { displayablePropertyParam } from './displayablePropertyParam';
import { renderBreadcrumb } from '../components/shared/HierarchyTreeBreadcrumb';
import { RootStore } from '../stores/rootStore';
import { CustomPropertyTypes } from './customPropertyDataTypes';
import { Translation } from './translations';

export interface ReplaceOptions {
  preserveReduced?: boolean;
}

export interface DisplayablePropertiesOptions {
  includeConversions?: boolean;
}

export type Blamable = 'createdBy' | 'createdAt' | 'updatedBy' | 'updatedAt' | 'deletedBy' | 'deletedAt';

export class BaseModel {
  id?: number | string;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      populateAttributesFromStore: action,
    });
  }

  searchableProperties: string[] = [];
  readonly saveableProperties: string[] = [];
  reducedProperties: string[] = [];
  translatedProperties: string[] = [];
  nestedModels: string[] = [];

  static faIcon: IconDefinition = faInfo;

  static modelDeletedDecoration = {
    deletedAt: observable,
    deletedBy: observable,
  };

  static modelCreateDecoration = {
    updatedAt: observable,
    updatedBy: observable,
  };

  static modelUpdateDecoration = {
    createdAt: observable,
    createdBy: observable,
  };

  static modelChangeDecorations = {
    ...BaseModel.modelCreateDecoration,
    ...BaseModel.modelUpdateDecoration,
    ...BaseModel.modelDeletedDecoration,
  };

  static prepareApiPayload(model: Partial<Omit<BaseModel, Blamable>>): Partial<Omit<BaseModel, Blamable>> {
    return model;
  }

  /**
   * @description   This properties are used to consolidate properties of a model, which may be
   * extended with additional properties provided by the backend. This properties are used to render tables
   * and detail views in widgets.
   *
   * @type {Array.<DisplayablePropertyParams>}
   */
  displayableProperties: DisplayablePropertyParams[] = [];
  customPropertyType?: CustomPropertyTypes;
  i18nPrefix = '';
  translations?: Translation[];

  /**
   * Add translated_ prefixed properties to a model.
   * @param model the model to add the translated_ props to
   * @param customProps pass a custom set of properties. If omitted, the model.translatedProperties are used.
   */
  static addTranslatedPropsToModel(model: BaseModel, customProps = []) {
    const props = customProps.length ? customProps : model.translatedProperties;

    if (props.length) {
      // Transform translation objects to a single object, keyed by language
      props.forEach((prop) => {
        const propTranslation: any = {};
        model.translations?.forEach((translation) => {
          // @ts-ignore
          propTranslation[translation.language] = translation[prop];
        });
        // @ts-ignore
        model[`translated_${prop}`] = propTranslation;
      });
    }
  }

  /**
   * Creates a model instance from a plain JavaScript object. A model instance is part of the mobx store.
   * @param plainObject
   * @param rootStore
   * @param skipPopulate Set to true if attributes should not be populated from store. This is only necessary in places
   * where the store is not yet properly initialized. Defaults to false.
   * @return {BaseModel}
   */
  static fromPlainObject<T extends BaseModel = BaseModel>(
    plainObject: any,
    rootStore: RootStore,
    skipPopulate: boolean = false
  ): T {
    const model = new this(rootStore);
    Object.assign(model, plainObject);
    if (!skipPopulate) {
      model.populateAttributesFromStore(rootStore);
    }
    this.addTranslatedPropsToModel(model);
    return model as T;
  }

  static search<T extends BaseModel = BaseModel>(query: string, models: T[]): T[] {
    if (!query) {
      return models;
    }
    return models.filter((model) => model.fitsQuery(query.toLowerCase()));
  }

  getPlainSaveableObject(): { [key: string]: any } {
    const obj: { [key: string]: any } = {};
    this.saveableProperties.forEach((key) => {
      // @ts-ignore
      obj[key] = this ? this[key] : model[key];
      if (isObservableArray(obj[key])) {
        obj[key] = obj[key].slice();
      }
    });
    return obj;
  }

  static getSaveableProperties(rootStore: RootStore) {
    const model = new this(rootStore);
    return model.saveableProperties;
  }

  static getTranslatableProperties(rootStore: RootStore) {
    const model = new this(rootStore);
    return model.translatedProperties;
  }

  /**
   * Extracts all translated fields from form values and returns an array of translations.
   * @param {{[string]: any}} values
   * @return {*[]}
   */
  static convertToSavableTranslations(values: object) {
    // @ts-ignore
    const model = new this();
    const savableTranslations: any[] = [];
    model.translatedProperties.forEach((prop) => {
      // @ts-ignore
      const translatedProp = values[`translated_${prop}`] || {};
      Object.keys(translatedProp).forEach((language) => {
        const currentTranslation = savableTranslations.find((t) => t.language === language);
        if (currentTranslation) {
          currentTranslation[prop] = translatedProp[language];
        } else {
          savableTranslations.push({
            language,
            [prop]: translatedProp[language],
          });
        }
      });
    });
    return savableTranslations;
  }

  static ownDisplayableProperties(
    rootStore: RootStore,
    pathPrefix: string = '',
    titlePrefix: string = '',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    options: DisplayablePropertiesOptions = {}
  ): BaseModel['displayableProperties'] {
    return new this(rootStore).displayableProperties.map((property) => {
      const clone = {
        ...property,
        key: `${pathPrefix}${property.key}`,
        title: `${titlePrefix}${property.title}`,
      };

      clone.params?.forEach((param) => {
        param.path = `${pathPrefix}${param.path}`;
      });

      return clone;
    });
  }

  static allDisplayableProperties(
    rootStore: RootStore,
    pathPrefix: string = '',
    titlePrefix: string = '',
    options: DisplayablePropertiesOptions = {}
  ) {
    return this.ownDisplayableProperties(rootStore, pathPrefix, titlePrefix, options);
  }

  static displayableCustomProperties(rootStore: RootStore, pathPrefix: string, titlePrefix: string) {
    const entity = new this(rootStore);
    if (!entity.customPropertyType) {
      return [];
    }
    return rootStore.propertyStore.getByType({ type: entity.customPropertyType }).map((property) => {
      const key = `${pathPrefix}properties.${property.key}`;
      const path = `${pathPrefix}properties[${property.key}]`;
      const propertiesPath = `${pathPrefix}properties`;
      const label = property.label ? property.label : property.key;
      return displayableProperty({
        key,
        title: `${titlePrefix}${label}`,
        params: [
          displayablePropertyParam({ path, transform: transformation.customPropertyValue }),
        ],
        customProperty: property,
        propertiesPath,
        raw: true,
      });
    });
  }

  static getAllDisplayablePropertiesByKeys(store: RootStore, propertyKeys: string[]): DisplayablePropertyParams[] {
    const displayableProperties = this.allDisplayableProperties(store);

    const result: DisplayablePropertyParams[] = [];

    // iterate over property keys to preserve order of the properties
    propertyKeys.forEach((key) => {
      const property = displayableProperties.find((dp) => dp.key === key);
      if (property) {
        result.push(property);
      }
    });

    return result;
  }

  /**
   * Returns all ownDisplayableProperties that are either saveable, translated or a customProperty. This can be useful
   * to filter out properties that do not exist directly in the database.
   * @param store
   * @returns {DisplayablePropertyParams[]|*[]}
   */
  static getAllRealDisplayableProperties(store: RootStore) {
    const saveableProperties = this.getSaveableProperties(store) || [];
    const translatableProperties = this.getTranslatableProperties(store) || [];
    const realProperties = saveableProperties.concat(...translatableProperties);
    if (realProperties.length === 0) {
      return [];
    }
    let ownDisplayableProperties = this.ownDisplayableProperties(store);
    ownDisplayableProperties = ownDisplayableProperties.filter((prop) =>
      realProperties.includes(prop.key as unknown as keyof BaseModel));

    ownDisplayableProperties = ownDisplayableProperties
      .concat(...this.displayableCustomProperties(store, '', ''));
    return ownDisplayableProperties;
  }

  /**
   * @private
   */
  static addToTree(tree: any, path: any, property: any) {
    if (path.length === 1) {
      tree.push({
        ...property,
        value: property.key,
        breadcrumb: property.title,
        title: path[0],
      });
      return tree;
    }
    const [nextLevelName] = path.splice(0, 1);
    let nextLevel = tree.find((treeEle: any) => treeEle.title === nextLevelName && treeEle.children !== undefined);
    if (nextLevel === undefined) {
      const parentKey = `_parent-${uuid()}`;
      nextLevel = {
        title: nextLevelName,
        key: parentKey,
        value: parentKey,
        selectable: false,
        children: [],
      };
      tree.push(nextLevel);
    }
    nextLevel.children = this.addToTree(nextLevel.children, path, property);
    return tree;
  }

  static convertDisplayablePropertiesToTree(properties: DisplayablePropertyParams[]) {
    const tree: any = [];
    properties.forEach((prop: any) => {
      const path = prop.title.split(/\s?>\s?/);
      this.addToTree(tree, path, prop);
    });
    return tree;
  }

  copyAttributesFrom(entity: BaseModel, { preserveReduced = false }: ReplaceOptions = {}): BaseModel {
    Object.keys(entity).forEach((key) => {
      const isReducedProperty = this.reducedProperties.indexOf(key as unknown as keyof BaseModel) >= 0;
      const skipReplacement = isReducedProperty && preserveReduced;
      if (!skipReplacement) {
        // @ts-ignore
        if (isObservableArray(this[key]) && isObservableArray(entity[key])) {
          // @ts-ignore
          this[key].replace(entity[key]);
        } else {
          // @ts-ignore
          this[key] = entity[key];
        }
      }
    });
    return this;
  }

  fitsQuery(query: string): boolean {
    return !!this.searchableProperties.find((property) => this.queryFitsProperty(query, property));
  }

  queryFitsProperty(query: string, property: any): boolean {
    const value = get(this, property);
    if (!value) {
      return false;
    }

    if (Array.isArray(value) || isObservableArray(value)) {
      if (!(value.length && typeof value[0].fitsQuery === 'function')) {
        return false;
      }
      return some(value, (item: any) => item.fitsQuery(query));
    }

    if (typeof value === 'object' && typeof value.fitsQuery === 'function') {
      return value.fitsQuery(query);
    }

    return value.toString().toLowerCase().includes(query);
  }

  /**
   * check if a filter or query matches this object.
   * @returns {boolean}
   * @param filter
   */
  matches(filter = {}) {
    return every(
      // @ts-ignore
      Object.keys(filter).map((key) => this.matchesAttributeValue(key, filter[key])),
      Boolean
    );
  }

  matchesAttributeValue(attribute: any, value: any) {
    let thisValue = null;

    // eslint-disable-next-line no-prototype-builtins
    if (this.hasOwnProperty(attribute)) {
      // @ts-ignore
      thisValue = this[attribute];
      // eslint-disable-next-line no-prototype-builtins
    } else if (this.hasOwnProperty(snakeCase(attribute))) {
      // @ts-ignore
      thisValue = this[snakeCase(attribute)];
    } else {
      return true;
    }

    return thisValue === value;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars,class-methods-use-this
  populateAttributesFromStore(rootStore: RootStore): void {
  }

  get toFlatObject() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return toFlatObject(this);
  }

  static findInTree<T extends BaseModel>(tree: any, id: number): T | null {
    let foundEntity = null;

    if (!(id && tree && tree.length)) {
      return foundEntity;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const entity of tree) {
      if (entity.id === id) {
        foundEntity = entity;
        break;
      }

      if (entity.children && entity.children.length) {
        const foundEntityFromChildren = this.findInTree<T>(entity.children, id);

        if (foundEntityFromChildren) {
          foundEntity = foundEntityFromChildren;
          break;
        }
      }
    }

    return foundEntity;
  }

  /**
   * Can be used to convert data for https://ant.design/components/tree-select/
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static mapToSelectTreeData(tree: any, parent: any = null, isDisabled = (entity?: any) => false) {
    if (!(tree && tree.length)) {
      return [];
    }

    return tree.map((entity: any) => {
      const path = [entity.name];
      let iParent = parent;
      while (iParent) {
        path.unshift(iParent.title);
        iParent = iParent.parent;
      }

      const treeData = {
        title: entity.name,
        value: entity.id,
        parent,
        path,
        breadcrumb: renderBreadcrumb(path),
        children: [],
        disabled: isDisabled(entity),
      };

      if (entity.children) {
        treeData.children = this.mapToSelectTreeData(entity.children, treeData, isDisabled);
      }

      return treeData;
    });
  }

  static buildTree(
    models: BaseModel[],
    parentId: number | null = null,
    parentPropName: string = 'parentId',
    childrenPropName: string = 'children'
  ) {
    if (!(models && models.length)) {
      return [];
    }

    // cloning to prevent changes on model
    return this.findChildrenByParent(parentId, models, parentPropName, childrenPropName);
  }

  static findChildrenByParent(
    parentId: number | null | undefined,
    models: BaseModel[],
    parentPropName: string = 'parentId',
    childrenPropName: string = 'children'
  ) {
    const result: any[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const model of models) {
      // @ts-ignore
      if (model[parentPropName] === parentId) {
        // @ts-ignore
        const children = this.findChildrenByParent(model.id, models, parentPropName, childrenPropName);

        // do not add children prop, if there is no children
        // otherwise a "+" sign will be added to the table
        if (children.length) {
          if (childrenPropName) {
            // @ts-ignore
            model[childrenPropName] = children;
          } else {
            result.push(...children);
          }
        } else {
          // @ts-ignore
          delete model[childrenPropName];
        }

        result.push(model);
      }
    }

    return result;
  }

  static sortTree(tree: any, childrenPropName = 'children', sortByPropName = 'sortOrder', sortOrder = 'asc') {
    tree
      .sort((a: any, b: any) => sortNumerically(a[sortByPropName], b[sortByPropName]))
      .map((object: any) => {
        if (object[childrenPropName] && object[childrenPropName].length) {
          this.sortTree(object[childrenPropName], childrenPropName, sortByPropName, sortOrder);
        }

        return object;
      });

    if (sortOrder === 'desc') {
      tree.reverse();
    }
  }

  static searchTree(query: string, tree: any) {
    return tree.map((parentNode: any) => this
      .searchTreeNode(query, parentNode))
      .filter((node: any) => node !== null);
  }

  static searchTreeNode(query: string, node: any, parentFits = false) {
    const fitsQuery = node.fitsQuery(query);

    const children = (node.children || []).map((child: any) => this
      .searchTreeNode(query, child, fitsQuery || parentFits))
      .filter((child: any) => child !== null);

    const isVisible = fitsQuery || parentFits || children.length > 0;

    if (isVisible) {
      node.children = children.length > 0 ? children : undefined;
      // eslint-disable-next-line no-underscore-dangle
      node.__fitsQuery = fitsQuery;
      return node;
    }
    return null;
  }

  static buildTreeAndSort<T extends BaseModel>(
    models: T[],
    parentId: number | null = null,
    parentProp: string = 'parentId',
    childrenProp: string = 'children',
    sortByProp: string = 'sortOrder',
    sortOrder: string = 'asc'
  ): T[] {
    const tree = this.buildTree(models, parentId, parentProp, childrenProp);
    this.sortTree(tree, childrenProp, sortByProp, sortOrder);

    return tree;
  }

  /**
   *
   * @param {Object} model
   * @param {string} text
   * @returns {Object}
   */
  static discardPropsWhereNameIncludes<M = BaseModel>(model: M, text: string) {
    if (!model || !text) {
      return model;
    }

    const workCopy = { ...model };
    Object.keys(workCopy).forEach((key) => {
      if (key.includes(text)) {
        // @ts-ignore
        delete workCopy[key];
      }
    });

    return workCopy;
  }
}

export const toFlatObject = (model: BaseModel) => omitBy(model, isObject);
