import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {groupBy, sumBy} from 'lodash';
import {Divider} from 'antd';
import dayjs from 'dayjs';
import {datetimeFormat} from '../../../config/dayjs';
import Table from '../../shared/tables/Table';
import {sortAlphabetically, sortChronologically, sortNumerically} from '../../shared/tables/sorters';
import Descriptions from '../../shared/descriptions/Descriptions';
import Label from '../../shared/dataDisplay/Label';
import {EnDash} from '../../shared/unicodeWrapper/EnDash';
import {useStore} from '../../../hooks/useStore';
import {useMount} from '../../../hooks/useMount';

const OrderDetailsYields = ({operations}) => {
  const {t} = useTranslation();
  const store = useStore();

  const yieldTypes = store.yieldTypeStore.yieldTypes.slice().sort((a, b) => a.sortOrder - b.sortOrder);
  const yields = store.yieldStore.getByOperations(operations.map((operation) => operation.id));
  const groupedYields = groupBy(yields, (y) => y.yieldTypeId);
  const yieldsSums = new Map();
  Object.keys(groupedYields).forEach((yieldTypeId) => {
    // a sum of floats does not round up as expected (way too many decimals), therefore we need to round manually
    const decimals = () => {
      let maxDecimals = 0;
      groupedYields[yieldTypeId].forEach((y) => {
        const decimal = () => {
          if (Math.floor(y.quantity) === y.quantity) {
            return 0;
          }
          return y.quantity.toString().split('.')[1].length || 0;
        };
        if (decimal() > maxDecimals) {
          maxDecimals = decimal();
        }
      });
      return maxDecimals;
    };
    yieldsSums.set(
      parseInt(yieldTypeId, 10),
      parseFloat(sumBy(groupedYields[yieldTypeId], (y) => y.quantity).toFixed(decimals()))
    );
  });

  useMount(() => {
    if (operations?.length) {
      store.yieldTypeStore.loadAll();
      store.yieldStore.loadByOperation(operations.map((operation) => operation.id));
    }
  }, [operations.reduce((res, op) => `${res}-${op.id}`, '')]);

  return (
    <>
      <Divider orientation="left">{t('orderWidget.yields.sum')}</Divider>
      <Descriptions column={3} bordered style={{marginTop: '1em'}}>
        {yieldTypes.map((yieldType) => (
          <Descriptions.Item
            key={yieldType.id}
            label={<Label>{yieldType.label}</Label>}
          >
            {yieldsSums.get(yieldType.id) || 0}
            {' '}
            {store.yieldTypeStore.getUnit(yieldType, operations[0]) || EnDash()}
          </Descriptions.Item>
        ))}
      </Descriptions>
      <Divider orientation="left">{t('orderWidget.yields.details')}</Divider>
      <Table
        columns={[
          {
            title: t('yield.model.attributes.recordedAt'),
            dataIndex: 'recordedAt',
            render: (date) => dayjs(date).format(datetimeFormat),
            sorter: (a, b) => sortChronologically(a.recordedAt, b.recordedAt),
          },
          {
            title: t('yield.model.attributes.yieldType'),
            dataIndex: 'yieldType',
            render: (y) => y.label,
            sorter: (a, b) => sortAlphabetically(a.yieldType.label, b.yieldType.label),
          },
          {
            title: t('yield.model.attributes.quantity'),
            dataIndex: 'quantity',
            sorter: (a, b) => sortNumerically(a.quantity, b.quantity),
          },
          {
            title: t('yield.model.attributes.unitOfMeasurement'),
            dataIndex: 'unitOfMeasure',
            render: (u) => u.label,
            sorter: (a, b) => sortAlphabetically(a.unitOfMeasure.label, b.unitOfMeasure.label),
          },
        ]}
        dataSource={yields || []}
        rowKey={'id'}
      />

    </>
  );
};

export default observer(OrderDetailsYields);
