import { computed, observable, makeObservable } from 'mobx';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { Preparation, PreparationState } from '../models/preparation';
import { RootStore } from './rootStore';

export class PreparationStore extends EntityStore<Preparation> {
  preparations: Preparation[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'preparations', Api.preparations, Preparation);

    makeObservable(this, {
      preparations: observable,
      currentPreparations: computed,
      runningPreparations: computed,
      donePreparations: computed,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.storageUnitStore,
        modelId: 'storageUnitId',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'createdBy',
      },
      {
        store: this.rootStore.operationStore,
        modelId: 'operationId',
      },
    ];
  }

  loadRunningByOperation(operationId: number) {
    return this.loadAllWithDependencies({ params: { operationId, states: PreparationState.RUNNING } });
  }

  getRunningByOperations(operationIds: number[]): Preparation[] {
    return this.preparations.filter((p) => operationIds.find((operationId) =>
      p.operationId === operationId && p.state === PreparationState.RUNNING));
  }

  filterUndoneByStorageUnitIdAndOperationId(storageUnitId: number, operationId: number): Preparation[] {
    return this.preparations.filter(
      (preparation) => preparation.storageUnitId === storageUnitId
        && preparation.state !== PreparationState.DONE
        && preparation.operationId === operationId
    );
  }

  get currentPreparations(): Preparation[] {
    const currentOperation = this.rootStore.operationStore.active;
    if (currentOperation) {
      return this.preparations.filter((preparation) => preparation.operationId === currentOperation.id);
    }
    return [];
  }

  get runningPreparations(): Preparation[] {
    return this.currentPreparations
      .filter((preparation) => preparation.state !== PreparationState.DONE);
  }

  get donePreparations(): Preparation[] {
    return this.currentPreparations.filter((preparation) => preparation.state === PreparationState.DONE);
  }

  getByProductId(productId: number | undefined | null): Preparation[] {
    // Allow null, as this is a nullable property
    if (productId === undefined) {
      return [];
    }
    return this.preparations.filter((preparation) => preparation.productId === productId);
  }
}
