import {Button} from 'antd';
import {PauseCircleOutlined} from '@ant-design/icons';

export const QuickstopButton = (props) => {
  const {title} = props;

  return (
    <Button
      type="default"
      danger
      block
      {...props}
    >
      <PauseCircleOutlined/>
      {title}
    </Button>
  );
};
