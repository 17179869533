import { computed, makeObservable, observable } from 'mobx';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { getTranslation } from '../utils/translations';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { Translation } from './translations';
import { RootStore } from '../stores/rootStore';

/*
 * Translations:
 * t('printer.model.state.ERROR')
 * t('printer.model.state.OFFLINE')
 * t('printer.model.state.NOT_AVAILABLE')
 * t('printer.model.state.ONLINE')
 */
export enum PrinterState {
  ERROR = 'ERROR',
  OFFLINE = 'OFFLINE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  ONLINE = 'ONLINE'
}

/*
 * Translations:
 * t('printer.model.type.STANDARD')
 * t('printer.model.type.ONE_OFF')
 */
export enum PrinterType {
  STANDARD = 'STANDARD',
  ONE_OFF = 'ONE_OFF'
}

interface PrinterTranslation extends Translation {
  label: string | null;
}

export class Printer extends BaseModel {
  id: number = 0;
  name: string = '';
  customFunctionId: number | null = null;
  state: PrinterState = PrinterState.NOT_AVAILABLE;
  type: PrinterType = PrinterType.STANDARD;
  hierarchyId: number = 0;
  webUrl: string | null = null;
  defaultAmount: number = 0;
  stateUpdatedAt: string = '';
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;
  translations: PrinterTranslation[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      label: computed,
      customFunctionId: observable,
      customFunction: computed,
      state: observable,
      type: observable,
      hierarchyId: observable,
      hierarchy: computed,
      webUrl: observable,
      defaultAmount: observable,
      stateUpdatedAt: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
      translations: observable,
    });
  }

  static faIcon = faPrint;

  searchableProperties = ['name', 'material.no', 'material.name', 'label'];

  translatedProperties = ['label'];

  saveableProperties = ['customFunctionId', 'name', 'type', 'hierarchyId', 'webUrl', 'defaultAmount', 'translations'];

  displayableProperties = [
    displayableProperty({
      key: 'name',
      title: i18n.t('printer.model.attributes.name.label'),
      params: [displayablePropertyParam({ path: 'name' })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'label',
      title: i18n.t('printer.model.attributes.label.label'),
      params: [displayablePropertyParam({ path: 'label' })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'type',
      title: i18n.t('printer.model.attributes.type.label'),
      params: [displayablePropertyParam({ path: 'type' })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'webUrl',
      title: i18n.t('printer.model.attributes.webUrl.label'),
      params: [displayablePropertyParam({ path: 'webUrl' })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'defaultAmount',
      title: i18n.t('printer.model.attributes.defaultAmount'),
      params: [displayablePropertyParam({ path: 'defaultAmount' })],
      template: '{value}',
    }),
  ];

  get label() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.label;
  }

  static prepareApiPayload(model: Printer) {
    return {
      id: model.id || undefined,
      name: model.name,
      customFunctionId: model.customFunctionId,
      state: model.state,
      type: model.type,
      hierarchyId: model.hierarchyId,
      webUrl: model.webUrl,
      defaultAmount: model.defaultAmount,
      translations: Printer.convertToSavableTranslations(model),
    };
  }

  get hierarchy() {
    return this.rootStore.hierarchyStore.getById(this.hierarchyId);
  }

  get customFunction() {
    return this.rootStore.customFunctionStore.getById(this.customFunctionId);
  }
}
