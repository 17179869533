import { computed, observable, makeObservable } from 'mobx';
import { faCertificate } from '@fortawesome/free-solid-svg-icons';
import { Observer } from 'mobx-react-lite';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import { datetimeFormat } from '../config/dayjs';
import { sortAlphabetically, sortChronologically } from '../components/shared/tables/sorters';
import UserFullName from '../components/shared/UserFullName';
import { CustomPropertyTypes } from './customPropertyDataTypes';
import { InspectionTaskResultValuation } from './inspectionTaskResult';
import { RootStore } from '../stores/rootStore';

export interface InspectionVerificationAppliedFilters {
  fromDate?: string;
  toDate?: string;
  isVerified?: boolean;
  orderIds?: number[];
  valuation?: InspectionTaskResultValuation;
  workplaceIds?: number[];
  taskCustomProperties?: {
    [key: string]: string[];
  }
}

export class InspectionVerification extends BaseModel {
  id: number = 0;
  message: string | null = null;
  inspectionTaskResultIds: number[] = [];
  noAccepted: number = 0;
  noNotValuated: number = 0;
  noRejected: number = 0;
  oldestResult: string = '';
  newestResult: string = '';
  appliedFilters: InspectionVerificationAppliedFilters = {};
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      message: observable,
      inspectionTaskResultIds: observable,
      inspectionTaskResults: computed,
      noAccepted: observable,
      noNotValuated: observable,
      noRejected: observable,
      oldestResult: observable,
      newestResult: observable,
      appliedFilters: observable,
      customProperties: computed,
      orderNos: computed,
      workplaceNos: computed,
      createdAt: observable,
      createdBy: observable,
      createdByUser: computed,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faCertificate;

  searchableProperties = ['id', 'message'];

  displayableProperties = [
    displayableProperty({
      key: 'createdAt',
      title: i18n.t('inspectionVerification.model.attributes.createdAt'),
      params: [
        displayablePropertyParam({
          path: 'createdAt', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortChronologically(a.createdAt, b.createdAt),
    }),
    displayableProperty({
      key: 'createdBy',
      title: i18n.t('inspectionVerification.model.attributes.createdBy'),
      params: [displayablePropertyParam({
        path: 'createdByUser',
        transform: (user) => (
          <Observer>
            {() => <UserFullName user={user}/>}
          </Observer>
        ),
      })],
      raw: true,
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.createdBy.fullName, b.createdBy.fullName),
    }),
    displayableProperty({
      key: 'message',
      title: i18n.t('inspectionVerification.model.attributes.message.label'),
      params: [displayablePropertyParam({ path: 'message', transform: transformation.none })],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.message, b.message),
    }),
    displayableProperty({
      key: 'numberOfResults',
      title: i18n.t('inspectionVerification.model.attributes.numberOfResults'),
      params: [
        displayablePropertyParam({
          path: 'noAccepted',
          transform: transformation.none,
          as: 'noAccepted',
        }),
        displayablePropertyParam({
          path: 'noNotValuated',
          transform: transformation.none,
          as: 'noNotValuated',
        }),
        displayablePropertyParam({
          path: 'noRejected',
          transform: transformation.none,
          as: 'noRejected',
        }),
      ],
      template: '{noAccepted} / {noNotValuated} / {noRejected}',
      sorter: (a, b) => a.noAccepted - b.noAccepted,
    }),
    displayableProperty({
      key: 'oldestResult',
      title: i18n.t('inspectionVerification.model.attributes.oldestResult'),
      params: [
        displayablePropertyParam({
          path: 'oldestResult', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortChronologically(a.oldestResult, b.oldestResult),
    }),
    displayableProperty({
      key: 'newestResult',
      title: i18n.t('inspectionVerification.model.attributes.newestResult'),
      params: [
        displayablePropertyParam({
          path: 'newestResult', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortChronologically(a.newestResult, b.newestResult),
    }),
    displayableProperty({
      key: 'appliedFiltersFromDate',
      title: i18n.t('inspectionVerification.model.attributes.appliedFilters.fromDate'),
      params: [
        displayablePropertyParam({
          path: 'appliedFilters.fromDate', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortChronologically(a.appliedFilters.fromDate, b.appliedFilters.fromDate),
    }),
    displayableProperty({
      key: 'appliedFiltersToDate',
      title: i18n.t('inspectionVerification.model.attributes.appliedFilters.toDate'),
      params: [
        displayablePropertyParam({
          path: 'appliedFilters.toDate', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortChronologically(a.appliedFilters.toDate, b.appliedFilters.toDate),
    }),
    displayableProperty({
      key: 'appliedFiltersIsVerified',
      title: i18n.t('inspectionVerification.model.attributes.appliedFilters.isVerified'),
      params: [
        displayablePropertyParam({
          path: 'appliedFilters.isVerified', transform: transformation.booleanValue,
        }),
      ],
      template: '{value}',
      raw: true,
      sorter: (a, b) => sortAlphabetically(a.appliedFilters.isVerified, b.appliedFilters.isVerified),
    }),
    displayableProperty({
      key: 'appliedFiltersValuation',
      title: i18n.t('inspectionVerification.model.attributes.appliedFilters.valuation'),
      params: [
        displayablePropertyParam({
          path: 'appliedFilters.valuation', transform: transformation.none,
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.appliedFilters.valuation, b.appliedFilters.valuation),
    }),
    displayableProperty({
      key: 'appliedFiltersCustomProperties',
      title: i18n.t('inspectionVerification.model.attributes.appliedFilters.customProperties'),
      params: [
        displayablePropertyParam({
          path: 'customProperties', transform: transformation.none,
        }),
      ],
      raw: true,
      template: '{value}',
    }),
    displayableProperty({
      key: 'appliedFiltersOrderNos',
      title: i18n.t('inspectionVerification.model.attributes.appliedFilters.orderIds'),
      params: [
        displayablePropertyParam({
          path: 'orderNos', transform: transformation.none,
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.orderNos, b.orderNos),
    }),
    displayableProperty({
      key: 'appliedFiltersWorkplaceNos',
      title: i18n.t('inspectionVerification.model.attributes.appliedFilters.workplaceIds'),
      params: [
        displayablePropertyParam({
          path: 'workplaceNos', transform: transformation.none,
        }),
      ],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a.workplaceNos, b.workplaceNos),
    }),
  ];

  get inspectionTaskResults() {
    return this.rootStore.inspectionTaskResultStore.getByIds(this.inspectionTaskResultIds);
  }

  get createdByUser() {
    return this.rootStore.userStore.getById(this.createdBy);
  }

  get customProperties() {
    if (this.appliedFilters.taskCustomProperties && Object.keys(this.appliedFilters.taskCustomProperties)) {
      const properties = this.rootStore.propertyStore
        .getByType({ type: CustomPropertyTypes.InspectionTask });
      const jsx: JSX.Element[] = [];
      Object.keys(this.appliedFilters.taskCustomProperties).forEach((key) => {
        const property = properties.find((prop) => prop.key === key);
        if (property) {
          const values = this.appliedFilters.taskCustomProperties?.[key] || [];
          const options = values.map((value) => property.options?.find((opt) => opt.key === value) || null);
          const translated = options.map((option, i) => (option ? property.translatedOption(option) : values[i]));
          jsx.push(
            <div key={property.key}>
              <strong>
                {(property) ? property.label : key}
                :
                {' '}
              </strong>
              {translated.join(', ')}
            </div>
          );
        }
      });
      return jsx;
    }
    return null;
  }

  get orderNos() {
    if (this.appliedFilters.orderIds && this.appliedFilters.orderIds.length > 0) {
      const orders = this.rootStore.orderStore.getByIds(this.appliedFilters.orderIds);
      return orders.map((order) => order.no).join(', ');
    }
    return null;
  }

  get workplaceNos() {
    if (this.appliedFilters.workplaceIds && this.appliedFilters.workplaceIds.length > 0) {
      const workplaces = this.rootStore.workplaceStore.getByIds(this.appliedFilters.workplaceIds);
      if (workplaces) {
        return workplaces.map((workplace) => workplace.no).join(', ');
      }
    }
    return null;
  }
}
