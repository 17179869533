import { observable, makeObservable, computed } from 'mobx';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faSensorTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import i18n from '../i18n/i18n';
import { DisplayablePropertyParams, displayableProperty } from './displayableProperty';
import { displayablePropertyParam } from './displayablePropertyParam';
import { sortAlphabetically } from '../components/shared/tables/sorters';
import { RootStore } from '../stores/rootStore';
import { FaultCollection } from './faultCollection';
import { Hierarchy } from './hierarchy';
import { InterruptionClass } from './interruptionClass';
import { InterruptionReason } from './interruptionReason';

export class Fault extends BaseModel {
  id: number = 0;
  faultCollectionId: number = 0;
  code: string = '';
  description: string | null = null;
  priority: number = 0;
  interruptionDelaySeconds: number | null = null;
  reentryDelaySeconds: number | null = null;
  interruptionSourceHierarchyId: number | null = null;
  interruptionClassId: number | null = null;
  interruptionReasonId: number | null = null;
  message: string | null = null;
  postClassificationDelaySeconds: number | null = null;
  postInterruptionSourceHierarchyId: number | null = null;
  postInterruptionClassId: number | null = null;
  postInterruptionReasonId: number | null = null;
  postMessage: string | null = null;
  uuid: string = '';
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      faultCollectionId: observable,
      faultCollection: computed,
      code: observable,
      description: observable,
      priority: observable,
      interruptionDelaySeconds: observable,
      reentryDelaySeconds: observable,
      interruptionSourceHierarchyId: observable,
      interruptionSourceHierarchy: computed,
      interruptionClassId: observable,
      interruptionClass: computed,
      interruptionReasonId: observable,
      interruptionReason: computed,
      message: observable,
      postClassificationDelaySeconds: observable,
      postInterruptionSourceHierarchyId: observable,
      postInterruptionSourceHierarchy: computed,
      postInterruptionClassId: observable,
      postInterruptionClass: computed,
      postInterruptionReasonId: observable,
      postInterruptionReason: computed,
      postMessage: observable,
      uuid: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  get faultCollection(): any {
    return this.faultCollectionId ? this.rootStore.faultCollectionStore.getById(this.faultCollectionId) : undefined;
  }

  get interruptionSourceHierarchy(): any {
    return this.interruptionSourceHierarchyId ? this.rootStore.hierarchyStore.getById(
      this.interruptionSourceHierarchyId
    ) : undefined;
  }

  get interruptionClass(): any {
    return this.interruptionClassId ? this.rootStore.interruptionClassStore.getById(
      this.interruptionClassId
    ) : undefined;
  }

  get interruptionReason(): any {
    return this.interruptionReasonId ? this.rootStore.interruptionReasonStore.getById(
      this.interruptionReasonId
    ) : undefined;
  }

  get postInterruptionSourceHierarchy(): any {
    return this.postInterruptionSourceHierarchyId ? this.rootStore.hierarchyStore.getById(
      this.postInterruptionSourceHierarchyId
    ) : undefined;
  }

  get postInterruptionClass(): any {
    return this.interruptionClassId ? this.rootStore.interruptionClassStore.getById(
      this.interruptionClassId
    ) : undefined;
  }

  get postInterruptionReason(): any {
    return this.postInterruptionReasonId ? this.rootStore.interruptionReasonStore.getById(
      this.postInterruptionReasonId
    ) : undefined;
  }

  searchableProperties = ['code', 'faultCollection.name'];

  static faIcon: IconDefinition = faSensorTriangleExclamation;

  displayableProperties: DisplayablePropertyParams[] = [
    displayableProperty({
      key: 'code',
      title: i18n.t('fault.model.attributes.code'),
      params: [displayablePropertyParam({ path: 'code' })],
      sorter: (a, b) => sortAlphabetically(a.code, b.code),
    }),
    displayableProperty({
      key: 'description',
      title: i18n.t('fault.model.attributes.description'),
      params: [displayablePropertyParam({ path: 'description' })],
      sorter: (a, b) => sortAlphabetically(a.name, b.name),
    }),
  ];

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);

    allDisplayableProperties.push(
      ...FaultCollection.allDisplayableProperties(rootStore, `${pathPrefix}faultCollection.`, titlePrefix),
      ...Hierarchy.allDisplayableProperties(rootStore, `${pathPrefix}hierarchy.`, titlePrefix),
      ...InterruptionClass.allDisplayableProperties(rootStore, `${pathPrefix}interruptionClass.`, titlePrefix),
      ...InterruptionReason.allDisplayableProperties(rootStore, `${pathPrefix}interruptionReason.`, titlePrefix),
      ...this.displayableCustomProperties(rootStore, pathPrefix, titlePrefix)
    );

    return allDisplayableProperties;
  }

  static prepareApiPayload(model: Partial<Fault>) {
    return {
      id: model.id,
      faultCollectionId: model.faultCollectionId,
      code: model.code,
      description: model.description || null,
      priority: model.priority,
      interruptionDelaySeconds: model.interruptionDelaySeconds || null,
      reentryDelaySeconds: model.reentryDelaySeconds || null,
      interruptionSourceHierarchyId: model.interruptionSourceHierarchyId || null,
      interruptionClassId: model.interruptionClassId || null,
      interruptionReasonId: model.interruptionReasonId || null,
      message: model.message || null,
      postClassificationDelaySeconds: model.postClassificationDelaySeconds || null,
      postInterruptionSourceHierarchyId: model.postInterruptionSourceHierarchyId || null,
      postInterruptionClassId: model.postInterruptionClassId || null,
      postInterruptionReasonId: model.postInterruptionReasonId || null,
      postMessage: model.postMessage || null,
    };
  }
}
