import { makeObservable, observable } from 'mobx';
import { EntityStore } from './entityStore';
import { PopupMessage } from '../models/popupMessage';
import { RootStore } from './rootStore';

export class PopupMessageStore extends EntityStore<PopupMessage> {
  identityField = 'uuid';
  messages: PopupMessage[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'messages', null, PopupMessage);

    makeObservable(this, {
      messages: observable,
    });
  }
}
