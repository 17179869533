import { action, makeObservable, observable } from 'mobx';
import { Dayjs } from 'dayjs';

export class PerformanceAnalysisStore {
  timeWindow?: Dayjs[] = undefined;
  interruptionGroupBy?: string[] = undefined;
  interruptionGroupByTopN?: number = undefined;
  sensorNames?: string[] = undefined;
  materialId?: number = undefined;
  orderId?: number = undefined;
  resolution?: number = undefined;
  kpiIds?: number[] = undefined;

  constructor() {
    makeObservable(this, {
      timeWindow: observable,
      interruptionGroupBy: observable,
      interruptionGroupByTopN: observable,
      sensorNames: observable,
      materialId: observable,
      orderId: observable,
      resolution: observable,
      kpiIds: observable,
      setTimeWindow: action,
      setInterruptionGroupBy: action,
      setInterruptionGroupByTopN: action,
      setSensorNames: action,
      setMaterialId: action,
      setOrderId: action,
      setResolution: action,
      setKpiIds: action,
    });
  }

  setTimeWindow(value: this['timeWindow']) {
    this.timeWindow = value;
  }

  setInterruptionGroupBy(value: this['interruptionGroupBy']) {
    this.interruptionGroupBy = value;
  }

  setInterruptionGroupByTopN(value: this['interruptionGroupByTopN']) {
    this.interruptionGroupByTopN = value;
  }

  setSensorNames(value: this['sensorNames']) {
    this.sensorNames = value;
  }

  setMaterialId(value: this['materialId']) {
    this.materialId = value;
  }

  setOrderId(value: this['orderId']) {
    this.orderId = value;
  }

  setResolution(value: this['resolution']) {
    this.resolution = value;
  }

  setKpiIds(value: this['kpiIds']) {
    this.kpiIds = value;
  }
}
