import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { CustomFunction, CustomFunctionType } from '../models/customFunction';
import { sortAlphabetically } from '../components/shared/tables/sorters';
import { RootStore } from './rootStore';

export interface ExecuteCustomFunctionParams {
  args: string | null,
  customFunctionId: number,
  workplaceId: number | null,
  materialId: number | null,
  orderId: number | null,
  operationId: number | null,
  transient: boolean | null,
}
export class CustomFunctionStore extends EntityStore<CustomFunction> {
  customFunctions: CustomFunction[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'customFunctions', Api.customFunctions, CustomFunction, true);

    makeObservable(this, {
      customFunctions: observable,
    });
  }

  getCustomFunctionsByType(type: CustomFunctionType | null) {
    if (!type) {
      return [];
    }
    return this.customFunctions
      .filter((cF) => cF.type === type)
      .sort((a, b) => sortAlphabetically(a.label, b.label));
  }

  loadByType(type: CustomFunctionType): Promise<CustomFunction[]> {
    return this.loadAll({ params: { type } });
  }
}
