import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { InterruptionClass } from '../models/interruptionClass';
import i18n from '../i18n/i18n';
import { RootStore } from './rootStore';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';

export class InterruptionClassStore extends EntityStore<InterruptionClass> {
  interruptionClasses: InterruptionClass[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'interruptionClasses', Api.interruptionClasses, InterruptionClass);

    makeObservable(this, {
      interruptionClasses: observable,
    });
  }

  async delete(id: number, options = {}) {
    let result;
    try {
      result = await super.delete(id, options);
    } catch (e: any) {
      if (e.name === 'E_CONFLICT') {
        this.rootStore.flashMessageStore.addFlashMessage(
          new FlashMessage(FlashMessageType.ERROR, i18n.t('interruptionClass.errors.conflict'))
        );
      } else {
        this.rootStore.flashMessageStore.addFlashMessage(
          new FlashMessage(FlashMessageType.ERROR, i18n.t('interruptionClass.errors.error'))
        );
      }
    }
    return result;
  }
}
