import {useTranslation} from 'react-i18next';
import Table from '../tables/Table';

/**
 * @param dataSource Data to display
 * @param columns Column definitions
 * @param rowKey Antd Table rowKey
 * @param value Array of selected keys
 * @param onChange Function called when selected rows change.
 * @param onSelectAll Function called when all elements on the current page are selected/deselected
 * @param onSelectFull Function called when all elements are selected
 * @param onSelect Function called when a single element is selected/deselected
 * @param onSelectNone Function called when all elements are deselected
 * @param props Remaining props passed to Table component
 * @return {JSX.Element}
 */
const SelectTable = ({
  dataSource,
  columns,
  rowKey,
  value,
  onChange,
  onSelectAll,
  onSelectFull,
  onSelect,
  onSelectNone,
  ...props
}) => {
  const {t} = useTranslation();
  const selections = [];
  if (onSelectFull) {
    selections.push({
      key: 'SELECT_FULL',
      text: t('table.selections.selectFull'),
      onSelect: (keys) => onSelectFull && onSelectFull(keys, dataSource),
    });
  }
  if (onSelectNone) {
    selections.push({
      key: 'SELECT_NONE',
      text: t('table.selections.selectNone'),
      onSelect: () => onSelectNone && onSelectNone(),
    });
  }

  const rowSelection = {
    selectedRowKeys: value,
    onChange: (selectedRowKeys) => onChange && onChange(selectedRowKeys),
    onSelectAll: (selected, selectedRows, changeRows) =>
      onSelectAll && onSelectAll(selected, selectedRows, changeRows),
    onSelect: (record, selected, selectedRows, nativeEvent) =>
      onSelect && onSelect(record, selected, selectedRows, nativeEvent),
    onSelectNone: () => onSelectNone && onSelectNone(),
    selections: selections.length ? selections : false,
    columnWidth: '64px',
  };

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      rowKey={rowKey}
      rowSelection={rowSelection}
      pagination={false}
      {...props}
    />
  );
};

export default SelectTable;
