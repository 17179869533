import React, {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {Alert} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {groupBy, sumBy} from 'lodash';
import {useMount} from '../../hooks/useMount';
import {WidgetLayout} from '../../models/widgetLayout';
import OperatorWidget from '../operator/shared/OperatorWidget';
import {WidgetLayouts} from '../shared/widgets/WidgetLayouts';
import {useStore} from '../../hooks/useStore';
import {ScopeContext} from '../../policies/scopeContext';
import {materialManagement} from '../../models/scope';
import {useModulePolicy} from '../../hooks/useModulePolicy';
import {Yield as Model} from '../../models/yield';
import {YieldWidgetConfig} from './yieldWidgetConfig';
import {YieldWidgetModal} from './yieldWidgetModal';
import {YieldCreateModal} from './yieldCreateModal';
import Button from '../shared/buttons/Button';
import Descriptions from '../shared/descriptions/Descriptions';
import Label from '../shared/dataDisplay/Label';
import {mqtt, TOPIC_BASE} from '../../middleware/mqtt';
import {EnDash} from '../shared/unicodeWrapper/EnDash';
import widgetStyles from '../operator/shared/OperatorWidget.module.scss';

const YieldWidget = ({disabled, minimized = false, identifier}) => {
  const {t} = useTranslation();
  const store = useStore();
  const [newModalVisible, setNewModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [yieldsSum, setYieldsSum] = useState(new Map());
  const widgetConfig = useMemo(() =>
    new YieldWidgetConfig(store, YieldWidget.identifier), []);
  const policy = useModulePolicy(store, [materialManagement]);

  useMount(() => {
    store.yieldTypeStore.loadAllWithDependencies({ params: { isInternal: false }});

    const id = mqtt.subscribe(`${TOPIC_BASE}/yields/+`, (topic, payload) => {
      const yieldModel = store.yieldStore.createModelInstance(payload);

      if (yieldModel.operationId === store.operationStore.active?.id) {
        store.yieldStore.add(yieldModel);
      }
    });

    return () => {
      mqtt.unsubscribe(`${TOPIC_BASE}/yields/+`, id);
    };
  });

  useMount(() => {
    if (store.operationStore.active?.id) {
      store.yieldStore.loadByOperation(store.operationStore.active?.id);
    }
  }, [store.operationStore.active?.id]);

  useMount(() => {
    if (store.operationStore.active) {
      const yields = store.yieldStore.getByOperation(store.operationStore.active?.id);
      const groupedYields = groupBy(yields, (y) => y.yieldTypeId);
      const sums = new Map();
      Object.keys(groupedYields).forEach((yieldTypeId) => {
        // a sum of floats does not round up as expected (way too many decimals), therefore we need to round manually
        const decimals = () => {
          let maxDecimals = 0;
          groupedYields[yieldTypeId].forEach((y) => {
            const decimal = () => {
              if (Math.floor(y.quantity) === y.quantity) {
                return 0;
              }
              return y.quantity.toString().split('.')[1].length || 0;
            };
            if (decimal() > maxDecimals) {
              maxDecimals = decimal();
            }
          });
          return maxDecimals;
        };
        sums.set(
          parseInt(yieldTypeId, 10),
          parseFloat(sumBy(groupedYields[yieldTypeId], (y) => y.quantity).toFixed(decimals()))
        );
      });
      setYieldsSum(sums);
    } else {
      setYieldsSum(new Map());
    }
  }, [store.operationStore.active?.id, store.yieldStore.yields.length]);

  const yieldReportingDisabledWorkplace = store.workplaceStore.selectedWorkplace?.disableYieldReporting;
  const yieldReportingDisabledOrder = store.operationStore.active?.order?.isYieldReportingDisabled;
  const yieldReportingDisabled = yieldReportingDisabledWorkplace || yieldReportingDisabledOrder;

  const YieldSumReport = () => {
    const yieldTypes = store.yieldTypeStore.generalYieldTypes.slice().sort((a, b) => a.sortOrder - b.sortOrder);
    return (
      <Descriptions column={1} bordered style={{marginTop: '1em'}}>
        {yieldTypes.map((yieldType) => (
          <Descriptions.Item
            key={yieldType.id}
            label={<Label>{yieldType.label}</Label>}
          >
            {yieldsSum.get(yieldType.id) || 0}
            {' '}
            {store.yieldTypeStore.getUnit(yieldType, store.operationStore.active) || EnDash()}
          </Descriptions.Item>
        ))}
      </Descriptions>
    );
  };

  const DisabledAlert = ({disabledWorkplace, disabledOrder}) => {
    let message = null;
    if (disabledWorkplace) {
      message = t('yieldWidget.alert.yieldReportingDisabled.workplace');
    } else if (disabledOrder) {
      message = t('yieldWidget.alert.yieldReportingDisabled.order');
    }
    return (<Alert type={'info'} message={message}/>);
  };

  return (
    <ScopeContext.Provider value={[materialManagement]}>
      <OperatorWidget
        onHeadClick={yieldReportingDisabled || !store.operationStore.active ? undefined : () => setModalVisible(true)}
        icon={<FontAwesomeIcon icon={Model.faIcon}/>}
        title={widgetConfig.getWidgetTitle()}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        widgetClassNames={yieldReportingDisabled ? [widgetStyles.disabled] : []}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {store.operationStore.active === undefined ? (
          <Alert type={'info'} message={t('yieldWidget.alert.noOperationActive')}/>
        ) : (
          yieldReportingDisabled ? (
            <DisabledAlert
              disabledWorkplace={yieldReportingDisabledWorkplace}
              disabledOrder={yieldReportingDisabledOrder}
            />
          ) : (
            <>
              <Button
                type={'primary'}
                onClick={() => setNewModalVisible(true)}
                size={'large'}
                block
                disabled={!store.operationStore.active
                  || !policy.canCreate({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})}
              >
                {t('yieldWidget.create')}
              </Button>
              {widgetConfig.getShowSummReport() === true && <YieldSumReport/>}
            </>
          )
        )}
      </OperatorWidget>

      {modalVisible && (
        <YieldWidgetModal
          widgetConfig={widgetConfig}
          onCancel={() => {
            setModalVisible(false);
          }}
          onAdd={() => {
            setModalVisible(false);
            setNewModalVisible(true);
          }}
        />
      )}
      {newModalVisible && (
        <YieldCreateModal
          widgetConfig={widgetConfig}
          onCancel={() => {
            setNewModalVisible(false);
          }}
          onSuccess={() => {
            setNewModalVisible(false);
          }}
        />
      )}
    </ScopeContext.Provider>
  );
};

YieldWidget.icon = Model.faIcon;

YieldWidget.identifier = 'YieldWidget';
YieldWidget.defaultLayout = new WidgetLayout(
  {
    identifier: YieldWidget.identifier,
    x: 2,
    y: 4,
    height: 3,
    minHeight: 3,
    width: WidgetLayouts.sixthWidth.w,
    minWidth: WidgetLayouts.sixthWidth.minW,
  }
);
export default observer(YieldWidget);
