import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Alert from 'antd/es/alert';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../../../hooks/useStore';
import {useMount} from '../../../../hooks/useMount';
import {InterruptionAnalysisWidgetConfig} from './config/interruptionAnalysisWidgetConfig';
import CockpitWidget from './CockpitWidget';
import {getFromToResolutionByOptions} from './config/timeframeAggregationOptions';
import {WidgetLayout} from '../../../../models/widgetLayout';
import {WidgetLayouts} from '../../../shared/widgets/WidgetLayouts';
import InterruptionAnalysisChart from './InterruptionAnalysisChart';
import useWorkplaceRelatedWidgetTitle from '../../../../hooks/useWorkplaceRelatedWidgetTitle';

const InterruptionAnalysisWidget = ({
  widgetId,
  title,
  ...props
}) => {
  const store = useStore();
  const [workplaceId, setWorkplaceId] = useState();
  const {t} = useTranslation();
  const widgetConfig = useMemo(() => new InterruptionAnalysisWidget.Config({
    rootStore: store,
    identifier: InterruptionAnalysisWidget.identifier,
    widgetId,
  }), [
    store.interruptionClassStore.areCollectionOrDependenciesLoading,
    store.languageStore.lang,
  ]);

  const widgetTitle = useWorkplaceRelatedWidgetTitle(title, workplaceId);

  const timeframe = widgetConfig.getSettingValue('interruptionAnalysis', 'timeframe');
  const daysBack = widgetConfig.getSettingValue('interruptionAnalysis', 'daysBack');
  const {from, to} = getFromToResolutionByOptions(timeframe, null, daysBack);
  const interruptionClassIds = widgetConfig.getSettingValue('interruptionAnalysis', 'interruptionClasses');

  useMount(() => {
    setWorkplaceId(
      store.workplaceStore.getByHierarchyId(widgetConfig.getSettingValue('workplace', 'hierarchyId'))?.id
      || store.cockpitStore.currentWorkplaceId
    );
  }, [
    store.cockpitStore.currentWorkplaceId,
    widgetConfig.getSettingValue('workplace', 'hierarchyId'),
  ]);

  return (
    <CockpitWidget
      widgetId={widgetId}
      title={widgetTitle}
      widgetConfig={widgetConfig}
      {...props}
    >
      {
        !workplaceId
          ? <Alert type={'info'} message={t('interruptionAnalysisWidget.alert.noWorkplaceSelected')}/>
          : ''
      }
      {
        workplaceId
          ? (
            <InterruptionAnalysisChart
              interruptionClassIds={interruptionClassIds}
              fromDate={from}
              toDate={to}
              workplaceId={workplaceId}
            />
          )
          : ''
      }
    </CockpitWidget>
  );
};

InterruptionAnalysisWidget.Config = InterruptionAnalysisWidgetConfig;
InterruptionAnalysisWidget.defaultLayout = new WidgetLayout({
  identifier: CockpitWidget.identifier,
  width: 4,
  height: 16,
  minHeight: 4,
  minWidth: WidgetLayouts.sixthWidth.minW,
});
export default observer(InterruptionAnalysisWidget);
