import { computed, makeObservable, observable } from 'mobx';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

/**
 * t('section.batchGenerators')
 * t('section.cockpits')
 * t('section.cockpitDashboards')
 * t('section.cockpitWidgets')
 * t('section.customActions')
 * t('section.customFunctions')
 * t('section.customProperties')
 * t('section.dataExportSchedulers')
 * t('section.dataExportTemplates')
 * t('section.externalResources')
 * t('section.faults')
 * t('section.faultCollections')
 * t('section.hierarchies')
 * t('section.inspectionEquipment')
 * t('section.inspectionPlans')
 * t('section.inspectionTasks')
 * t('section.inspectionTaskGroups')
 * t('section.interruptionClasses')
 * t('section.interruptionReasons')
 * t('section.keyPerformanceIndicators')
 * t('section.keyPerformanceIndicatorLimits')
 * t('section.operationStates')
 * t('section.orderTemplates')
 * t('section.phases')
 * t('section.phaseTimeouts')
 * t('section.phaseTimeoutReasons')
 * t('section.printers')
 * t('section.processDataConnections')
 * t('section.processDataPoints')
 * t('section.processDataSources')
 * t('section.roles')
 * t('section.sensors')
 * t('section.sensorDataLimits')
 * t('section.sensorDataTargets')
 * t('section.storageAreas')
 * t('section.terminalLayouts')
 * t('section.transitions')
 * t('section.unitOfMeasures')
 * t('section.workflows')
 * t('section.workplaces')
 * t('section.workplaceGroups')
 * t('section.workplaceStates')
 * t('section.yieldTypes')
 */
export enum ConfigTransferSection {
  batchGenerators = 'batchGenerators',
  cockpits = 'cockpits',
  cockpitDashboards = 'cockpitDashboards',
  cockpitWidgets = 'cockpitWidgets',
  customActions = 'customActions',
  customFunctions = 'customFunctions',
  customProperties = 'customProperties',
  dataExportSchedulers = 'dataExportSchedulers',
  dataExportTemplates = 'dataExportTemplates',
  externalResources = 'externalResources',
  faults = 'faults',
  faultCollections = 'faultCollections',
  hierarchies = 'hierarchies',
  inspectionEquipment = 'inspectionEquipment',
  inspectionPlans = 'inspectionPlans',
  inspectionTasks = 'inspectionTasks',
  inspectionTaskGroups = 'inspectionTaskGroups',
  interruptionClasses = 'interruptionClasses',
  interruptionReasons = 'interruptionReasons',
  keyPerformanceIndicators = 'keyPerformanceIndicators',
  keyPerformanceIndicatorLimits = 'keyPerformanceIndicatorLimits',
  operationStates = 'operationStates',
  orderTemplates = 'orderTemplates',
  phases = 'phases',
  phaseTimeouts = 'phaseTimeouts',
  phaseTimeoutReasons = 'phaseTimeoutReasons',
  printers = 'printers',
  processDataConnections = 'processDataConnections',
  processDataPoints = 'processDataPoints',
  processDataSources = 'processDataSources',
  roles = 'roles',
  sensors = 'sensors',
  sensorDataLimits = 'sensorDataLimits',
  sensorDataTargets = 'sensorDataTargets',
  storageAreas = 'storageAreas',
  terminalLayouts = 'terminalLayouts',
  transitions = 'transitions',
  unitOfMeasures = 'unitOfMeasures',
  workflows = 'workflows',
  workplaces = 'workplaces',
  workplaceGroups = 'workplaceGroups',
  workplaceStates = 'workplaceStates',
  yieldTypes = 'yieldTypes'
}

export type ConfigTransferSelectionRequest = {
  [key in ConfigTransferSection]?: boolean;
};

export type ConfigTransferRequest = {
  selection: ConfigTransferSelectionRequest;
  content: string;
  delete: boolean;
};

export class ConfigTransferLog extends BaseModel {
  id: number = 0;
  configTransferFileId: number = 0;
  section: string = '';
  createdCount: number = 0;
  deletedCount: number = 0;
  sourceCount: number = 0;
  updatedCount: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      configTransferFileId: observable,
      section: observable,
      createdCount: observable,
      deletedCount: observable,
      sourceCount: observable,
      updatedCount: observable,
      configTransferFile: computed,
    });
  }

  populateAttributesFromStore(rootStore: RootStore) {
    super.populateAttributesFromStore(rootStore);
  }

  get configTransferFile() {
    return this.rootStore.configTransferFileStore.getById(this.configTransferFileId);
  }
}
