import React, {useMemo, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBoxCheck} from '@fortawesome/pro-solid-svg-icons';
import {v4 as uuid} from 'uuid';
import {WidgetLayout} from '../../../models/widgetLayout';
import {WidgetLayouts} from '../../shared/widgets/WidgetLayouts';
import OperatorWidget from '../../operator/shared/OperatorWidget';
import {ScopeContext} from '../../../policies/scopeContext';
import {unrestricted} from '../../../models/scope';
import {useStore, useStoreLoad} from '../../../hooks/useStore';
import {StorageUnitStackWidgetConfig} from './storageUnitStackWidgetConfig';
import WidgetSettingsModal from '../../shared/widgets/WidgetSettingsModal';
import StorageUnitStackTable from './StorageUnitStackTable';
import {useMount} from '../../../hooks/useMount';
import NewStorageUnit from '../storageUnit/NewStorageUnit';
import {CustomPropertyTypes} from '../../../models/customPropertyDataTypes';
import EditStorageUnit from '../storageUnit/EditStorageUnit';

const StorageUnitStackWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const widgetConfig = useMemo(() =>
    new StorageUnitStackWidgetConfig(store, identifier), [store.storageAreaStore.storageAreas.length]);
  const [modalVisible, setModalVisible] = useState(false);
  const [addingStorageUnit, setAddingStorageUnit] = useState(false);
  const [editingStorageUnit, setEditingStorageUnit] = useState(null);
  const [storageUnits, setStorageUnits] = useState([]);
  const [highlightedRecordId, setHighlightedRecordId] = useState(undefined);
  const [rootStorageUnitId, _setRootStorageUnitId] = useState(null);
  const rootStorageUnitIdRef = useRef(rootStorageUnitId);

  const customProperties = store.propertyStore.getByType({
    type: CustomPropertyTypes.StorageUnit,
    sortOrder: {iteratees: ['sortOrder']},
  });
  const setRootStorageUnitId = async (v) => {
    rootStorageUnitIdRef.current = v;
    if (rootStorageUnitIdRef.current) {
      await store.storageUnitStore.loadWithDependencies(rootStorageUnitIdRef.current);
      let parentStorageUnitId = store.storageUnitStore.getById(rootStorageUnitIdRef.current)?.parentId;
      const reservedOrderId = store.storageUnitStore.getById(rootStorageUnitIdRef.current)?.reservedOrderId;
      if (!parentStorageUnitId) {
        const parentStorageUnit = await store.storageUnitStore.create({
          no: uuid(),
          reservedOrderId,
          storageAreaId: widgetConfig.getDefaultStorageArea(),
        }, {skipNotification: true});
        parentStorageUnitId = parentStorageUnit.id;
        await store.storageUnitStore.update({
          ...store.storageUnitStore.getById(rootStorageUnitIdRef.current).getPlainSaveableObject(),
          parentId: parentStorageUnitId,
          storageAreaId: null,
        });
      }
    }
    _setRootStorageUnitId(v);
  };
  const handleNewStorageUnit = (storageUnit) => {
    setRootStorageUnitId(storageUnit.id);
    setHighlightedRecordId(storageUnit.id);
    setAddingStorageUnit(false);
  };

  const handleEdit = async (storageUnit) => {
    await store.storageUnitLevelStore.loadByStorageUnitId(storageUnit.id);
    storageUnit.levels = store.storageUnitLevelStore
      .getByStorageUnitId(storageUnit.id)
      .sort((a, b) => a.stop - b.stop);
    setEditingStorageUnit(storageUnit);
  };

  const handleAppendStorageUnit = async (storageUnitToAddId) => {
    if (!storageUnitToAddId || !rootStorageUnitIdRef.current) {
      return;
    }
    await store.storageUnitStore.load(storageUnitToAddId);
    const storageUnitToAppend = store.storageUnitStore.getById(storageUnitToAddId)?.getPlainSaveableObject();
    if (widgetConfig.editRootIsDescendants()) {
      const parentReservedOrderId = store.storageUnitStore.getById(rootStorageUnitIdRef.current)?.reservedOrderId;
      store.storageUnitStore.update({
        ...storageUnitToAppend,
        parentId: rootStorageUnitIdRef.current,
        reservedOrderId: parentReservedOrderId,
        storageAreaId: null,
      });
    }
    if (widgetConfig.editRootIsSiblings()) {
      const parentStorageUnitId = store.storageUnitStore.getById(rootStorageUnitIdRef.current)?.parentId;
      const reservedOrderId = store.storageUnitStore.getById(rootStorageUnitIdRef.current)?.reservedOrderId;
      await store.storageUnitStore.update({
        ...storageUnitToAppend,
        parentId: parentStorageUnitId,
        reservedOrderId,
        storageAreaId: null,
      });
    }
    await store.storageUnitStore.loadWithDependencies(storageUnitToAddId);
    setHighlightedRecordId(storageUnitToAddId);
  };

  const handleAppendUnknownStorageUnit = async (storageUnitNo) => {
    const storageUnit = await store.storageUnitStore.create(
      {
        no: storageUnitNo,
        storageAreaId: widgetConfig.getDefaultStorageArea(),
      },
      {skipNotification: true}
    );
    await handleAppendStorageUnit(storageUnit.id);
  };

  useStoreLoad(() => {
    store.storageAreaStore.loadAll();
  });

  useMount(() => {
    if (rootStorageUnitId) {
      if (widgetConfig.editRootIsDescendants()) {
        store.storageUnitStore.loadDescendantsById(rootStorageUnitId);
      }
      if (widgetConfig.editRootIsSiblings()) {
        store.storageUnitStore.loadSiblingsById(rootStorageUnitId);
      }
    } else {
      setStorageUnits([]);
    }
  }, [rootStorageUnitId]);

  useMount(() => {
    if (widgetConfig.editRootIsDescendants()) {
      setStorageUnits(store.storageUnitStore.getDescendantsById(rootStorageUnitId));
    }
    if (widgetConfig.editRootIsSiblings()) {
      setStorageUnits(store.storageUnitStore.getSiblingsById(rootStorageUnitId));
    }
  }, [store.storageUnitStore.areCollectionOrDependenciesLoading]);

  return (
    <ScopeContext.Provider value={[unrestricted]}>
      <OperatorWidget
        title={widgetConfig.getWidgetTitle()}
        icon={<FontAwesomeIcon icon={faBoxCheck}/>}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        manualPath={'/inventory-management/storage-unit-stack'}
      >
        <StorageUnitStackTable
          loading={store.storageUnitStore.areCollectionOrDependenciesLoading}
          storageUnits={storageUnits}
          rootStorageUnitId={rootStorageUnitId}
          setRootStorageUnitId={setRootStorageUnitId}
          highlightedRecordId={highlightedRecordId}
          setHighlightedRecordId={setHighlightedRecordId}
          widgetConfig={widgetConfig}
          onAdd={() => setAddingStorageUnit(true)}
          onEdit={handleEdit}
          onAppend={(unitId) => handleAppendStorageUnit(unitId, rootStorageUnitId)}
          onAppendUnknown={(unitNo) => handleAppendUnknownStorageUnit(unitNo)}
          enableAutofocus={!disabled}
        />
        {addingStorageUnit && (
          <NewStorageUnit
            customProperties={customProperties}
            unitOfMeasurements={store.unitOfMeasurementStore.unitsOfMeasurement.slice()}
            storageAreas={store.storageAreaStore.storageAreas.slice()}
            storageUnits={store.storageUnitStore.storageUnits.slice()}
            widgetConfig={widgetConfig}
            onSuccess={(unitId) => handleNewStorageUnit(unitId)}
            onCancel={() => setAddingStorageUnit(false)}
          />
        )}
        {editingStorageUnit && (
          <EditStorageUnit
            storageUnit={editingStorageUnit}
            customProperties={customProperties}
            unitOfMeasurements={store.unitOfMeasurementStore.unitsOfMeasurement.slice()}
            storageAreas={store.storageAreaStore.storageAreas.slice()}
            storageUnits={store.storageUnitStore.storageUnits.slice()}
            onSuccess={() => setEditingStorageUnit(null)}
            onCancel={() => setEditingStorageUnit(null)}
          />
        )}
        {
          modalVisible
            ? (
              <WidgetSettingsModal
                widgetConfig={widgetConfig}
                onCancel={() => setModalVisible(false)}
                onOk={() => setModalVisible(false)}
              />
            )
            : ''
        }
      </OperatorWidget>
    </ScopeContext.Provider>
  );
};

StorageUnitStackWidget.icon = faBoxCheck;

StorageUnitStackWidget.identifier = 'StorageUnitStackWidget';
StorageUnitStackWidget.defaultLayout = new WidgetLayout(
  {
    identifier: StorageUnitStackWidget.identifier,
    x: 0,
    y: 100,
    height: 6,
    minHeight: 4,
    width: WidgetLayouts.halfWidth.w,
    minWidth: WidgetLayouts.halfWidth.minW,
  }
);

export default observer(StorageUnitStackWidget);
