/* eslint-disable no-param-reassign */
import { get } from 'lodash';
import dayjs from 'dayjs';
import { DayjsParam } from '../../../types/dayjs';

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type PropertyPath = (number | string)[] | string;

export type SortOptions = {
  ignoreCase?: boolean;
  order: SortDirection
};

const defaultSortOptions: SortOptions = {
  ignoreCase: true,
  order: SortDirection.Asc,
};

export const sortAlphabetically = (
  a: string | null | undefined,
  b: string | null | undefined,
  options: SortOptions = defaultSortOptions
): number => {
  if (!b && !a) {
    return 0;
  }
  if (a && !b) {
    return 1;
  }
  if (b && !a) {
    return -1;
  }

  // tell tsc that out checks filter out all non string cases.
  a = a as string;
  b = b as string;

  if (options.ignoreCase) {
    a = a.toLowerCase();
  }
  if (options.ignoreCase) {
    b = b.toLowerCase();
  }

  const sortOrder = options.order === 'asc' ? 1 : -1;

  if (a < b) {
    return -1 * sortOrder;
  }
  if (a > b) {
    return sortOrder;
  }
  return 0;
};

export const sortAlphabeticallyByPath = (
  a: any,
  b: any,
  path: PropertyPath,
  options: SortOptions = defaultSortOptions
// eslint-disable-next-line arrow-body-style
): number => {
  return sortAlphabetically(get(a, path), get(b, path), options);
};

export const sortNumerically = (a: string | number, b: string | number) => {
  let parsedA: number;
  let parsedB: number;
  if (typeof a === 'string') {
    parsedA = parseInt(a, 10);
  } else {
    parsedA = a;
  }
  if (typeof b === 'string') {
    parsedB = parseInt(b, 10);
  } else {
    parsedB = b;
  }

  if (!Number.isFinite(parsedA) && !Number.isFinite(parsedB)) {
    parsedA = 0;
    parsedB = 0;
  }

  if (!Number.isFinite(parsedA) && Number.isFinite(parsedB)) {
    parsedA = parsedB + 1;
  }

  if (Number.isFinite(parsedA) && !Number.isFinite(parsedB)) {
    parsedB = parsedA + 1;
  }

  return parsedA - parsedB;
};

export const sortNumericallyByPath = (a: any, b: any, path: PropertyPath): number => (
  sortNumerically(get(a, path), get(b, path))
);

export const sortChronologically = (a: DayjsParam, b: DayjsParam): number => dayjs(a).valueOf() - dayjs(b).valueOf();

// eslint-disable-next-line max-len
export const sortChronologicallyIgnoreMilliseconds = (a: DayjsParam, b: DayjsParam): number => (
  dayjs(a).milliseconds(0).valueOf() - dayjs(b).milliseconds(0).valueOf()
);

export const sortBoolean = (a: boolean, b: boolean): number => Number(b) - Number(a);
