import * as React from 'react';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {Col, Row, Space} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import {uniqBy} from 'lodash';
import Modal from '../../shared/modal/Modal';
import Button from '../../shared/buttons/Button';
import styles from '../InspectionTaskPendingWidget.module.scss';
import InspectionTaskExecutions from './InspectionTaskExecutions';
import DocumentsNavigator from '../../shared/dataDisplay/DocumentsNavigator';
import {useStore} from '../../../hooks/useStore';
import Form from '../../shared/form/Form';
import {getIcons} from '../getIcons';
import {taskManagement} from '../../../models/scope';
import {useModulePolicy} from '../../../hooks/useModulePolicy';
import hideInputStyles from './inspectionTaskExecutionModal.module.scss';
import GroupRemark from './GroupRemark';
import {EnDash} from '../../shared/unicodeWrapper/EnDash';
import {InspectionTaskPendingWidgetConfig} from '../InspectionTaskPendingWidgetConfig';
import InspectionTaskPendingWidget from '../InspectionTaskPendingWidget';
import DateAdjuster from './DateAdjuster';
import {useMount} from '../../../hooks/useMount';
import OrderRelatedBatchSelector from './OrderRelatedBatchSelector';
import {sortAlphabetically} from '../../shared/tables/sorters';
import {CustomFunctionType} from '../../../models/customFunction';

export const InspectionTaskContext = React.createContext({
  contextValue: {
    rootForm: null,
    tasks: [],
    isCreatingInspectionTaskResult: false,
  },
  setContextValue: () => {},
});

const InspectionTaskExecutionModal = ({
  open,
  setVisible,
  inspectionTaskPending = null,
  inspectionTask = null,
  phaseId = null,
  onCancel,
  onExecuted,
  openFirstDocument,
  ...props
}) => {
  const store = useStore();
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenDocument, setIsOpenDocument] = useState(false);
  const scopes = [taskManagement];
  const [contextValue, setContextValue] = useState(
    {rootForm: form, tasks: [], isCreatingInspectionTaskResult: isLoading}
  );
  const [documentTree, setDocumentTree] = useState([]);
  const [openedDocument, setOpenedDocument] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const policy = useModulePolicy(store, scopes);
  const widgetConfig = useMemo(() =>
    new InspectionTaskPendingWidgetConfig(store, InspectionTaskPendingWidget.identifier), []);
  const [resultErrors, setResultErrors] = useState(new Map());

  let task = null;
  if (inspectionTaskPending) {
    task = inspectionTaskPending.inspectionTask;
  } else if (inspectionTask) {
    task = inspectionTask;
  }

  useMount(() => {
    if (contextValue.tasks.length === 0) {
      return;
    }
    const inspectionEquipmentIds = contextValue.tasks.filter((tsk) =>
      tsk.inspectionTask?.quantitative?.inspectionEquipmentId)
      .map((tsk) =>
        tsk.inspectionTask.quantitative.inspectionEquipmentId);

    if (!inspectionEquipmentIds || !inspectionEquipmentIds.length) {
      return;
    }

    store.inspectionEquipmentStore.loadMany(inspectionEquipmentIds);

    store.customFunctionStore.loadByType(CustomFunctionType.InspectionEquipment);
  }, [contextValue]);

  const handleDocumentSelect = (id) => {
    setIsOpenDocument(!!id);
    if (id) {
      setOpenedDocument(documentTree.find((node) => node.document.id === id)?.document);
    } else {
      setOpenedDocument(null);
    }
  };

  const handleBoxOpen = (taskId) => {
    const metaTask = contextValue.tasks.find((tsk) => tsk.inspectionTask.id === taskId);
    const docNode = metaTask?.inspectionTask.documentsTree
      .sort((a, b) => sortAlphabetically(a.title, b.title))[0];
    handleDocumentSelect(docNode?.document.id);
  };

  useMount(() => {
    setContextValue({
      ...contextValue,
      tasks: task ? store.inspectionTaskStore.getSameGroup(task, inspectionTaskPending, phaseId) : [],
      isCreatingInspectionTaskResult: isLoading,
    });
  }, [task, inspectionTaskPending, phaseId, store.inspectionTaskStore.inspectionTasks.length, isLoading]);

  useMount(() => {
    let newDocumentTree = contextValue.tasks
      .map((tsk) => tsk.inspectionTask.documentsTree
        .sort((a, b) => sortAlphabetically(a.title, b.title)))
      .flat();
    newDocumentTree = uniqBy(newDocumentTree, (doc) => doc.key);
    setDocumentTree(newDocumentTree);
  }, [contextValue?.tasks?.length]);

  useMount(() => {
    if (!initialized && openFirstDocument && !openedDocument && documentTree.length) {
      setOpenedDocument(documentTree[0].document);
      setIsOpenDocument(true);
      setInitialized(true);
    }
  }, [openFirstDocument, openedDocument, documentTree, initialized]);

  const onSubmit = async (values) => {
    setIsLoading(true);

    const groupRemark = form.getFieldValue('groupRemark');
    const batchId = form.getFieldValue('batchId');
    const toSave = [];
    Object.values(values.results).forEach((result) => {
      const {internal, ...data} = result;

      // add group remark to results remark
      if (groupRemark) {
        if (data.remark) {
          data.remark = `${groupRemark} ${EnDash()} ${data.remark}`;
        } else {
          data.remark = groupRemark;
        }
      }

      // add batchId to results
      data.batchId = batchId || null;

      // add recordedAt date if the user has changed it
      if (values.recordedAt) {
        data.recordedAt = values.recordedAt;
      }
      toSave.push(data);
    });

    await store.inspectionTaskResultStore.createMany(toSave, {skipNotification: true});

    store.flashMessageStore.addFlashMessage({
      type: 'success', title: t('inspectionTask.resultSaved'),
    });

    setIsLoading(false);
    setVisible(false);
    if (onExecuted) {
      onExecuted(contextValue.tasks.map((synthTask) => synthTask.inspectionTaskPending));
    }
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    if (onCancel) {
      onCancel();
    }
  };

  if (!open) {
    if (isOpenDocument) {
      setIsOpenDocument(false);
    }
    if (openedDocument) {
      setOpenedDocument(null);
    }
    return null;
  }

  if (task === null) {
    return null;
  }

  const tasks = store.inspectionTaskStore.getSameGroup(task, inspectionTaskPending, phaseId);

  const modalTitle = (
    <Space>
      {inspectionTaskPending
        && getIcons(
          inspectionTaskPending,
          widgetConfig.getWidgetSetting('showInspectionTaskGroupIcon'),
          store.authStore.user?.roleIds,
          true,
          store
        ).map(({icon, style}) => (
          <FontAwesomeIcon key={icon.iconName} icon={icon} size="1x" style={style}/>
        ))}
      {task.inspectionTaskGroup?.name || task.name}
    </Space>
  );

  const validator = {
    validator: (_, results) => {
      if (Object.keys(results).length === tasks.length) {
        const errors = new Map();

        Object.values(results).forEach((result) => {
          const currentInspectionTask = store.inspectionTaskStore.getById(result.inspectionTaskId);
          const pendingTasks = store.inspectionTaskPendingStore.getByInspectionTaskIds([currentInspectionTask.id]);
          const required = pendingTasks.some(
            (pt) => currentInspectionTask.isFourEyes
              && dayjs(pt.dueDate).add(currentInspectionTask.fourEyesDelay, 'minutes').isBefore(dayjs())
          );

          if (required && !result.overruledValue) {
            errors.set(result.inspectionTaskId, t('inspectionTaskPendingModal.fourEyesInspectionRequired'));
          }
        });

        setResultErrors(errors);
        if (errors.size) {
          return Promise.reject(new Error(t('inspectionTaskPendingModal.fourEyesInspectionMissing')));
        }

        return Promise.resolve();
      }
      return Promise.reject(new Error(t('inspectionTaskPendingModal.finishAllTasks')));
    },
  };

  const modalFooter = (
    <Form
      name={'rootForm'}
      form={form}
      onFinish={onSubmit}
      initialValues={{results: {}, groupRemark: '', batchId: null}}
    >
      <Space
        size={store.clientStore.isMobile ? 'small' : 'large'}
        direction={store.clientStore.isMobile ? 'vertical' : 'horizontal'}
      >
        <Form.Item
          className={hideInputStyles.messageOnly}
          style={store.clientStore.isMobile ? {display: 'block', width: '100%'} : {}}
          name={'results'}
          required
          rules={[validator]}
        />
        <Form.Item
          className={hideInputStyles.messageOnly}
          style={store.clientStore.isMobile ? {display: 'block', width: '100%'} : {}}
          name={'recordedAt'}
        />
        <div style={store.clientStore.isMobile ? {} : {padding: '10px 0'}}>
          <Button key={'cancel'} onClick={handleCancel}>
            {t('inspectionTaskPendingModal.cancel')}
          </Button>
        </div>
      </Space>
    </Form>
  );

  return (
    <InspectionTaskContext.Provider
      value={{contextValue, setContextValue}}
    >
      <Form.Provider>
        <Modal
          className={styles.modalFullscreen}
          fullscreen
          title={modalTitle}
          footer={modalFooter}
          open={open}
          onCancel={handleCancel}
          style={{margin: 0, minWidth: '100%'}}
          {...props}
        >
          <Row gutter={[16, {xs: 0, sm: 8, md: 16}]} style={{height: '100%'}}>
            <Col xs={24} lg={isOpenDocument ? 16 : 6} style={{height: '100%'}}>
              {documentTree.length > 0 && (
                <DocumentsNavigator
                  allowDocumentCloseOnClick
                  documentTree={documentTree}
                  openedDocument={openedDocument}
                  selectedKeys={openedDocument ? [openedDocument.id] : []}
                  onDocumentIdSelect={handleDocumentSelect}
                  treeColProps={{xs: 24, lg: isOpenDocument ? 6 : 12}}
                  viewerColProps={{xs: 24, lg: isOpenDocument ? 18 : 24, style: {minHeight: '70vh', height: '100%'}}}
                  style={{height: 'calc(100% - 20px)'}}
                />
              )}
            </Col>
            <Col xs={24} lg={isOpenDocument ? 8 : 12}>
              <Form
                form={form}
              >
                <Space direction={'vertical'} size={'middle'} style={{width: '100%'}}>
                  {widgetConfig.getWidgetSetting('showGroupRemark') && (
                    <GroupRemark
                      form={form}
                      showGroupRemark={widgetConfig.getWidgetSetting('showGroupRemark')}
                      maxLength={255}
                    />
                  )}
                  {widgetConfig.getWidgetSetting('showBatchSelector') && (
                    <OrderRelatedBatchSelector
                      form={form}
                      isSelectorActive={widgetConfig.getWidgetSetting('showBatchSelector')}
                    />
                  )}
                  {widgetConfig.getWidgetSetting('showDateAdjuster') && (
                    <DateAdjuster
                      inspectionTaskPending={inspectionTaskPending}
                    />
                  )}
                </Space>
              </Form>
              <InspectionTaskExecutions
                onSubmit={onSubmit}
                disabled={!policy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})}
                useUniformQualityIndicator={widgetConfig.getWidgetSetting('uniformQualityIndicator')}
                minRemarkLength={widgetConfig.getWidgetSetting('minRemarkLength')}
                resultErrors={resultErrors}
                onBoxOpen={handleBoxOpen}
              />
            </Col>
          </Row>
        </Modal>
      </Form.Provider>
    </InspectionTaskContext.Provider>
  );
};
export default observer(InspectionTaskExecutionModal);
