import { observable, makeObservable, computed } from 'mobx';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

export class WorkplaceGroup extends BaseModel {
  id: number = 0;
  name: string = '';
  defaultWorkplaceId: number | null = null;
  workplaceIds: number[] = [];
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      defaultWorkplace: computed,
      defaultWorkplaceId: observable,
      workplaces: computed,
      workplaceIds: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
      createdByUser: computed,
      updatedByUser: computed,
    });
  }

  searchableProperties = ['name'];

  get workplaces() {
    return this.rootStore.workplaceStore.getByIds(this.workplaceIds);
  }

  get defaultWorkplace() {
    return this.rootStore.workplaceStore.getById(this.defaultWorkplaceId);
  }

  get createdByUser() {
    return this.rootStore.userStore.getById(this.createdBy);
  }

  get updatedByUser() {
    return this.rootStore.userStore.getById(this.updatedBy);
  }
}
