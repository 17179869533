import {BaseWidgetConfig} from '../../shared/widgets/baseWidgetConfig';
import i18n from '../../../i18n/i18n';
import {ValidationRules} from '../../../utils/validationRules';
import {getAllI18nTranslations} from '../../../utils/translations';
import {StorageUnit} from '../../../models/storageUnit';
import {FieldType} from '../../../types/widgetConfig';

/**
 * translations:
 * t('storageUnitStackWidgetConfig.settings.editRoot.options.SIBLINGS.label')
 * t('storageUnitStackWidgetConfig.settings.editRoot.options.SIBLINGS.help')
 * t('storageUnitStackWidgetConfig.settings.editRoot.options.DESCENDANTS.label')
 * t('storageUnitStackWidgetConfig.settings.editRoot.options.DESCENDANTS.help')
 */
export const StorageUnitStackWidgetEditRoot = Object.freeze({
  SIBLINGS: 'SIBLINGS',
  DESCENDANTS: 'DESCENDANTS',
});

export class StorageUnitStackWidgetConfig extends BaseWidgetConfig {
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'settings',
        label: i18n.t('widget.WidgetSettingsModal.settingConfigTabTitle'),
      },
      {
        key: 'data',
        label: i18n.t('widget.WidgetSettingsModal.dataConfigTabTitle'),
      },
    ];
  }

  settings = [
    {
      id: 'title',
      label: i18n.t('widget.settings.title.label'),
      helpText: i18n.t('widget.settings.title.help'),
      type: FieldType.Translatable,
      // translation: t('storageUnitStackWidgetConfig.settings.title.defaultValue')
      defaultValue: getAllI18nTranslations('storageUnitStackWidgetConfig.settings.title.defaultValue'),
      validations: [ValidationRules.required()],
    },
    {
      id: 'editRoot',
      label: i18n.t('storageUnitStackWidgetConfig.settings.editRoot.label'),
      helpText: i18n.t('storageUnitStackWidgetConfig.settings.editRoot.help'),
      type: FieldType.Select,
      options: Object.values(StorageUnitStackWidgetEditRoot).map((value) => ({
        value,
        label: i18n.t(`storageUnitStackWidgetConfig.settings.editRoot.options.${value}.label`),
        helpText: i18n.t(`storageUnitStackWidgetConfig.settings.editRoot.options.${value}.help`),
      })),
      defaultValue: StorageUnitStackWidgetEditRoot.SIBLINGS,
      validations: [ValidationRules.required()],
    },
    {
      id: 'storageArea',
      label: i18n.t('storageUnitStackWidgetConfig.settings.storageArea.label'),
      helpText: i18n.t('storageUnitStackWidgetConfig.settings.storageArea.help'),
      type: FieldType.Select,
      options: this.rootStore.storageAreaStore.storageAreas.map((area) => ({
        value: area.id,
        label: area.no,
      })),
      validations: [ValidationRules.required()],
    },
    {
      id: 'reservedOrderMandatory',
      label: i18n.t('storageUnitStackWidgetConfig.settings.reservedOrderMandatory.label'),
      helpText: i18n.t('storageUnitStackWidgetConfig.settings.reservedOrderMandatory.help'),
      type: FieldType.Boolean,
      defaultValue: false,
    },
  ];

  data = [
    {
      id: 'properties',
      type: FieldType.PropertiesSelectDisplayField,
      modelOptions: StorageUnit.allDisplayableProperties(this.rootStore),
      defaultValue: [
        'no',
        'material.name',
        'amount',
      ],
    },
  ];

  getWidgetSetting(identifier) {
    return this.getSettingValue('settings', identifier);
  }

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'title');
  }

  getTableColumns() {
    return this.getSelectedProperties('data', 'properties');
  }

  getEditRoot() {
    return this.getSettingValue('settings', 'editRoot');
  }

  editRootIsDescendants() {
    return this.getEditRoot() === StorageUnitStackWidgetEditRoot.DESCENDANTS;
  }

  editRootIsSiblings() {
    return this.getEditRoot() === StorageUnitStackWidgetEditRoot.SIBLINGS;
  }

  getReservedOrderMandatory() {
    return this.getSettingValue('settings', 'reservedOrderMandatory');
  }

  getDefaultStorageArea() {
    return this.getSettingValue('settings', 'storageArea');
  }
}
