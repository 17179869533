/* eslint-disable padded-blocks,arrow-body-style */
import { MouseEventHandler, MouseEvent, useCallback } from 'react';

export const useFormCancelCallback = (onCancel: MouseEventHandler) => {

  return useCallback((e: MouseEvent) => {
    e?.preventDefault();
    if (onCancel) {
      onCancel(e);
    }
  }, [onCancel]);

};
