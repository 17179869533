import React from 'react';
import { observer } from 'mobx-react-lite';
import { List } from 'antd';
import { useStore } from '../../../hooks/useStore';
import Button from '../buttons/Button';
import { Document } from '../../../models/document';

export type DocumentListItemProps = {
  documentId: number;
  index: number;
  setShowingDocument: (document: Document | undefined) => void;
};

const DocumentListItem: React.FC<DocumentListItemProps> = ({ documentId, index, setShowingDocument }) => {
  const store = useStore();
  const document = store.documentStore.getById(documentId);

  return (
    <List.Item
      key={documentId}
      style={{
        padding: 8,
        height: '60px',
        backgroundColor: (index % 2 === 0) ? '#FAFAFA' : '',
      }}
    >
      <Button
        onClick={() => setShowingDocument(store.documentStore.getById(documentId))}
        type={'link'}
        style={{
          fontFamily: 'Roboto Mono',
          width: '100%',
          textAlign: 'left',
        }}
      >
        <span style={{ marginRight: '1em' }}>{document?.icon}</span>
        {document?.label}
      </Button>
    </List.Item>
  );
};

export default observer(DocumentListItem);
