/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { useStore } from '../../../../hooks/useStore';
import AutoComplete, { AutoCompleteProps } from './Autocomplete';

export type InspectionLotSelectorProps = Omit<AutoCompleteProps, 'propertyName'> & {
  propertyName?: string;
};

const InspectionLotSelector: React.FC<InspectionLotSelectorProps> = ({
  form,
  name,
  propertyName = 'id',
  ...props
}) => {
  const store = useStore();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <AutoComplete
      autoFocus
      form={form}
      name={name}
      propertyName={propertyName}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      searchFunction={store.inspectionLotStore.searchByNo.bind(store.inspectionLotStore)}
      reverseSearchFunction={store.inspectionLotStore.getById.bind(store.inspectionLotStore)}
      renderOption={(lot) => (
        {
          value: `${lot?.id}`,
          label: `${lot?.lotNo}`,
        }
      )}
      {...props}
    />
  );
};

export default InspectionLotSelector;
