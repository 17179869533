import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Form } from 'antd';
import Modal, { ModalProps } from '../modal/Modal';
import WidgetSettingsForm from './WidgetSettingsForm';
import { useStore } from '../../../hooks/useStore';
import { useMount } from '../../../hooks/useMount';
import WidgetManualButton from '../../manual/WidgetManualButton';
import i18n from '../../../i18n/i18n';
import { BaseWidgetConfig } from './baseWidgetConfig';

const SettingsTitle: React.FC<{ widgetConfig: BaseWidgetConfig }> = ({ widgetConfig }) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <span>
        {t('widget.WidgetSettingsModal.title')}
        {widgetConfig.getWidgetTypeName() && (
          <>
            <Divider type={'vertical'}/>
            <span style={{ fontWeight: 'normal' }}>
              &nbsp;
              {i18n.t('cockpitWidget.AddWidgetWizardModal.AddWidgetWizardForm.widgetTypeIdentifier.label')}
              {': '}
              {widgetConfig.getWidgetTypeName()}
            </span>
          </>
        )}
      </span>
      <div style={{ marginRight: '36px', marginTop: '-4px' }}>
        <WidgetManualButton ignoreStoreState manualPath={widgetConfig.manualPath}/>
      </div>
    </div>
  );
};

export type WidgetSettingsModalProps = ModalProps & {
  widgetConfig: BaseWidgetConfig;
  onOk: (values: any) => void;
};

const WidgetSettingsModal: React.FC<WidgetSettingsModalProps> = ({ widgetConfig, onOk, ...props }) => {
  const store = useStore();
  const [form] = Form.useForm();

  const handleSubmit = () => (form.submit());

  useMount(() => {
    (widgetConfig.constructor as any).onLoadConfig(store);
  });

  return (
    <Form.Provider
      onFormFinish={(name, { values }) => onOk && onOk(values)}
    >
      <Modal
        title={<SettingsTitle widgetConfig={widgetConfig}/>}
        onOk={handleSubmit}
        open
        width="90%"
        {...props}
      >
        <WidgetSettingsForm form={form} widgetConfig={widgetConfig}/>
      </Modal>
    </Form.Provider>
  );
};

export default WidgetSettingsModal;
