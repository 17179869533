import React, { useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { AnyObject } from 'antd/es/_util/type';
import { ColumnProps, ColumnType } from 'antd/es/table';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  SortableContextProps,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { TableRowProps } from 'react-markdown/lib/ast-to-react';
import Table, { TableProps } from './Table';
import { useMount } from '../../../hooks/useMount';
import styles from './SortableTable.module.scss';
import { OrderUpdate } from '../../../types/sortable';

export enum HandlePosition {
  first = 'first',
  last = 'last'
}

type DraggableWrapperProps = Omit<SortableContextProps, 'items' | 'children'> & {
  children: React.ReactNode;
};

const DraggableWrapper: React.FC<DraggableWrapperProps> = ({ children, ...restProps }) => (
  <SortableContext
    strategy={verticalListSortingStrategy}
    // @ts-ignore
    items={!!children && Array.isArray(children[1]) ? children[1].map((child) => child.key) : []}
    {...restProps}
  >
    <tbody {...restProps}>
      {children}
    </tbody>
  </SortableContext>
);

type DraggableRowProps<RecordType> = TableRowProps & {
  children: React.ReactNode;
  showDragHandle?: ((record: RecordType) => boolean) | boolean;
  record: RecordType;
};

const DraggableRow: (<RecordType>(props: DraggableRowProps<RecordType>) => React.ReactNode) = <RecordType, >({
  children,
  showDragHandle,
  record,
  ...restProps
}: DraggableRowProps<RecordType>) => {
  const { attributes, listeners, setNodeRef } = useSortable({
    // @ts-ignore
    id: String(restProps['data-row-key']),
  });

  const showHandle = typeof showDragHandle === 'function' ? showDragHandle(record) : showDragHandle;

  return (
    <tr
      ref={showHandle ? setNodeRef : undefined}
      {...(showHandle ? attributes : [])}
      {...restProps}
    >
      {
        Array.isArray(children) ? (
          children.map((child) => {
            // eslint-disable no-shadow
            // @ts-ignore
            const { key } = child;
            if (key === 'dragHandle' && !showHandle) {
              return null;
            }
            return key === 'dragHandle' ? (
              <td {...listeners} key={key}>
                <span className={styles.dragHandle}>
                  <MenuOutlined style={{ cursor: 'pointer', color: '#999' }}/>
                </span>
              </td>
            ) : (
              child
            );
          })
        ) : (
          children
        )
      }
    </tr>
  );
};

export type SortableTableProps<RecordType extends AnyObject = AnyObject> = TableProps<RecordType> & {
  onOrderUpdated: (change: OrderUpdate) => void;
  showDragHandle?: ((record: RecordType) => boolean) | boolean;
  dragHandlePosition?: HandlePosition;
  rowClassName?: string;
};

// eslint-disable-next-line max-len
const SortableTable: (<RecordType extends AnyObject = AnyObject>(props: SortableTableProps<RecordType>) => React.ReactNode) = <RecordType extends AnyObject = AnyObject>({
  dataSource,
  columns,
  onOrderUpdated,
  showDragHandle,
  dragHandlePosition = HandlePosition.last,
  rowSelection,
  rowKey = 'key',
  loading,
  showHeader = true,
  rowClassName,
  onRow,
  ...props
}: SortableTableProps<RecordType>) => {
  const [visibleColumns, setVisibleColumns] = useState<ColumnType<RecordType>[]>([]);
  const [activeId, setActiveId] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor)
  );

  const getVisibleColumns = (): ColumnType<RecordType>[] => {
    const cols = [];
    columns?.forEach((col) => cols.push({ ...col, className: styles.dragVisible }));
    if (showDragHandle) {
      const sortColumn = {
        dataIndex: 'dragHandle',
        key: 'dragHandle',
        className: styles.dragVisible,
        width: 50,
        align: (dragHandlePosition === HandlePosition.first ? 'left' : 'center') as ColumnProps<RecordType>['align'],
        render: () => (
          <span className={styles.dragHandle}>
            <MenuOutlined style={{ cursor: 'pointer', color: '#999' }}/>
          </span>
        ),
      };

      if (dragHandlePosition === HandlePosition.first) {
        cols.unshift(sortColumn);
      } else {
        cols.push(sortColumn);
      }
    }
    return cols;
  };

  useMount(() => {
    setVisibleColumns(getVisibleColumns());
  });

  const handleDragEnd = (event: DragEndEvent) => {
    if (!dataSource) {
      return;
    }
    const { active, over } = event;
    const oldIndex = dataSource.findIndex((ele) => String(ele[rowKey as string]) === active.id);
    const newIndex = dataSource.findIndex((ele) => String(ele[rowKey as string]) === over?.id);
    if (oldIndex !== -1 && newIndex !== -1 && oldIndex !== newIndex && onOrderUpdated) {
      onOrderUpdated({ oldIndex, newIndex });
    }
    setActiveId(null);
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveId(String(active.id));
  };

  const handleOnRow = (record: RecordType, index?: number) => ({
    showDragHandle,
    record,
    ...(onRow ? onRow(record, index) || {} : {}),
  });

  let overlayDataSource = [];
  if (activeId !== null && dataSource) {
    overlayDataSource = new Array(1)
      .fill(dataSource[dataSource.findIndex((item) => String(item[rowKey as string]) === activeId)]);
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <Table
        showHeader={showHeader}
        pagination={false}
        dataSource={dataSource}
        columns={visibleColumns}
        rowSelection={rowSelection}
        rowKey={rowKey}
        rowClassName={rowClassName}
        onRow={handleOnRow}
        components={{
          body: {
            wrapper: DraggableWrapper,
            row: DraggableRow<RecordType>,
          },
        }}
        loading={loading}
        {...props}
      />
      <DragOverlay>
        <Table
          columns={visibleColumns}
          showHeader={false}
          dataSource={overlayDataSource}
          pagination={false}
        />
      </DragOverlay>
    </DndContext>
  );
};

export default SortableTable;
