let appConfig = {};

try {
  /* eslint-disable global-require */
  // eslint-disable-next-line import/no-unresolved
  appConfig = require('appConfig');
} catch (e) {
  // eslint-disable-next-line no-console
  console.warn('No appConfig.js found. Using default configuration.');
}

const defaultConfig = {
  modules: {
    // t('appConfig.model.attributes.modules.enableOperationManagement.help')
    // t('appConfig.model.attributes.modules.enableOperationManagement.label')
    enableOperationManagement: false,
    // t('appConfig.model.attributes.modules.enableAdvancedOrderManagement.help')
    // t('appConfig.model.attributes.modules.enableAdvancedOrderManagement.label')
    enableAdvancedOrderManagement: false,
    // t('appConfig.model.attributes.modules.enableOrderManagement.help')
    // t('appConfig.model.attributes.modules.enableOrderManagement.label')
    enableOrderManagement: false,
    // t('appConfig.model.attributes.modules.enableBatchHandling.help')
    // t('appConfig.model.attributes.modules.enableBatchHandling.label')
    enableBatchHandling: false,
    // t('appConfig.model.attributes.modules.enableBatchQueue.help')
    // t('appConfig.model.attributes.modules.enableBatchQueue.label')
    enableBatchQueue: false,
    // t('appConfig.model.attributes.modules.enableDosage.help')
    // t('appConfig.model.attributes.modules.enableDosage.label')
    enableDosage: false,
    // t('appConfig.model.attributes.modules.enableGatewayManagement.help')
    // t('appConfig.model.attributes.modules.enableGatewayManagement.label')
    enableGatewayManagement: false,
    // t('appConfig.model.attributes.modules.enableHierarchyPermissions.help')
    // t('appConfig.model.attributes.modules.enableHierarchyPermissions.label')
    enableHierarchyPermissions: false,
    // t('appConfig.model.attributes.modules.enableInspectionLotImport.help')
    // t('appConfig.model.attributes.modules.enableInspectionLotImport.label')
    enableInspectionLotImport: false,
    // t('appConfig.model.attributes.modules.enableInspectionManagement.help')
    // t('appConfig.model.attributes.modules.enableInspectionManagement.label')
    enableInspectionManagement: false,
    // t('appConfig.model.attributes.modules.enableInspectionReport.help')
    // t('appConfig.model.attributes.modules.enableInspectionReport.label')
    enableInspectionReport: false,
    // t('appConfig.model.attributes.modules.enableKpiExport.help')
    // t('appConfig.model.attributes.modules.enableKpiExport.label')
    enableKpiExport: false,
    // t('appConfig.model.attributes.modules.enableProcessDataExport.help')
    // t('appConfig.model.attributes.modules.enableProcessDataExport.label')
    enableProcessDataExport: false,
    // t('appConfig.model.attributes.modules.enableInterruptionExport.help')
    // t('appConfig.model.attributes.modules.enableInterruptionExport.label')
    enableInterruptionExport: false,
    // t('appConfig.model.attributes.modules.enableManualFinalPerformanceReporting.help')
    // t('appConfig.model.attributes.modules.enableManualFinalPerformanceReporting.label')
    enableManualFinalPerformanceReporting: false,
    // t('appConfig.model.attributes.modules.enableFullOrderReporting.help')
    // t('appConfig.model.attributes.modules.enableFullOrderReporting.label')
    enableFullOrderReporting: false,
    // t('appConfig.model.attributes.modules.enableManualSensorDataRecording.help')
    // t('appConfig.model.attributes.modules.enableManualSensorDataRecording.label')
    enableManualSensorDataRecording: false,
    // t('appConfig.model.attributes.modules.enablePlantCockpit.help')
    // t('appConfig.model.attributes.modules.enablePlantCockpit.label')
    enablePlantCockpit: false,
    // t('appConfig.model.attributes.modules.enableUserLanguage.help')
    // t('appConfig.model.attributes.modules.enableUserLanguage.label')
    enableUserLanguage: false,
    // t('appConfig.model.attributes.modules.enableYields.help')
    // t('appConfig.model.attributes.modules.enableYields.label')
    enableYields: false,
    // t('appConfig.model.attributes.modules.enableDataExport.help')
    // t('appConfig.model.attributes.modules.enableDataExport.label')
    enableDataExport: false,
    // t('appConfig.model.attributes.modules.enablePhaseTimeout.help')
    // t('appConfig.model.attributes.modules.enablePhaseTimeout.label')
    enablePhaseTimeout: false,
    // t('appConfig.model.attributes.modules.enableAuditLog.help')
    // t('appConfig.model.attributes.modules.enableAuditLog.label')
    enableAuditLog: false,
    // t('appConfig.model.attributes.modules.enableProcessDataConfiguration.help')
    // t('appConfig.model.attributes.modules.enableProcessDataConfiguration.label')
    enableProcessDataConfiguration: false,
    // t('appConfig.model.attributes.modules.enablePrintManagement.help')
    // t('appConfig.model.attributes.modules.enablePrintManagement.label')
    enablePrintManagement: false,
    // t('appConfig.model.attributes.modules.enableInspectionVerification.help')
    // t('appConfig.model.attributes.modules.enableInspectionVerification.label')
    enableInspectionVerification: false,
    // t('appConfig.model.attributes.modules.enableMaterialPropertyEditor.help')
    // t('appConfig.model.attributes.modules.enableMaterialPropertyEditor.label')
    enableMaterialPropertyEditor: false,
    // t('appConfig.model.attributes.modules.enableTransitionQueue.help')
    // t('appConfig.model.attributes.modules.enableTransitionQueue.label')
    enableTransitionQueue: false,
    // t('appConfig.model.attributes.modules.enableInventoryManagement.help')
    // t('appConfig.model.attributes.modules.enableInventoryManagement.label')
    enableInventoryManagement: false,
    // t('appConfig.model.attributes.modules.enableQualityReport.help')
    // t('appConfig.model.attributes.modules.enableQualityReport.label')
    enableQualityReport: true,
    // t('appConfig.model.attributes.modules.enableShopfloorDashboard.help')
    // t('appConfig.model.attributes.modules.enableShopfloorDashboard.label')
    enableShopfloorDashboard: false,
  },

  /*
   * The language in which the frontend is rendered.
   * Available values: 'de-CH', 'en-CH'
   */
  language: 'en-CH',

  /*
   * The languages that are available for users to choose from.
   * Can be selection from the same list as language.
   */
  languageOptions: ['en-CH'],

  /*
   * token for guest-login. If undefined, guest-session is disabled.
   */
  guestLoginToken: undefined,

  /*
   * time in seconds after which the user gets logged out if they are inactive. 0 to disable.
   */
  logoutAfterInactivity: 0,

  enableExperimental: false,

  speedDenominator: 'MINUTES',

  hierarchy: {},

  corporateIdentity: {
    /*
     * The highlight color of various UI elements.
     * Currently, changes to this will only apply on a frontend rebuild, since it's compiled into CSS via LESS
     */
    aspectColor: '#1890FF',

    /*
     * The background color of the top navigation bar
     */
    navbarColor: '#FFFFFF',

    /*
     * A URL to the logo displayed in the navigation bar.
     */
    image: '/logo.png',

    /*
     * A URL to the video displayed in the start page.
     */
    video1: '/video1.mp4',

    /*
     * A URL to the video displayed in the start page.
     */
    video2: '/video2.mp4',

    /*
     * A URL to the video displayed in the start page.
     */
    video3: '/video3.mp4',

    /*
     * A URL to the video displayed in the start page.
     */
    video4: '/video4.mp4',
    hideWelcomeLogo: false,
  },

  stateColors: {
    good: '#31C110',
    warning: '#FFA500',
    bad: '#FF0000',
  },

  qualitativeStateColors: {
    good: '#31C110',
    warning: '#B3B0B0',
    bad: '#FF0000',
  },

  quantitativeStateColors: {
    good: '#B6F8A5',
    warning: '#FFEDCC',
    bad: '#FFCCCC',
  },

  mqtt: {
    useBackendProxy: false,
    host: null,
    port: 3090,
    username: null,
    password: null,
    disableOfflineIndicator: false,
  },

  locale: {
    startOfWeek: 1,
  },
};

const mergedConfig = {
  ...defaultConfig,
  ...appConfig,
  locale: {
    ...defaultConfig.locale,
    ...appConfig.locale,
  },
  modules: {
    ...defaultConfig.modules,
    ...appConfig.modules,
  },
  corporateIdentity: {
    ...defaultConfig.corporateIdentity,
    ...appConfig.corporateIdentity,
  },
  mqtt: {
    ...defaultConfig.mqtt,
    ...appConfig.mqtt,
  },
};

/**
 * Import this in your components to access the static configuration located in the AppConfig resource of the backend
 */
export default mergedConfig;
