import React, {useState} from 'react';
import Highcharts from 'highcharts';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {useStore} from '../../../../hooks/useStore';
import {useMount} from '../../../../hooks/useMount';
import Chart from '../../../shared/charts/Chart';
import {dateFormat, datetimeFormat, readableDateFormat, timeFormat} from '../../../../config/dayjs';
import {AggregationOptions} from './config/timeframeAggregationOptions';

const KpiChart = ({
  workplaceId,
  kpiIds,
  resolution,
  aggregation,
  fromDate,
  toDate,
  unitOfMeasurement,
  yAxisMax,
  yAxisTitle,
  showPercent,
  legendPosition,
}) => {
  const store = useStore();
  const {t} = useTranslation();
  const [data, setData] = useState();
  const [targetData, setTargetData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isTargetLineEnabled, setIsTargetLineEnabled] = useState(false);

  useMount(() => {
    (async () => {
      setIsLoading(true);
      await store.keyPerformanceIndicatorStore.loadMany(kpiIds);

      if (workplaceId && fromDate && toDate) {
        const kpiResponse = await store.keyPerformanceIndicatorStore.loadKpiByTime({
          workplaceId,
          fromDate,
          toDate,
          resolution,
          kpiId: kpiIds,
          clampToNow: true,
        });

        const hasDisplayableData = kpiResponse.reduce((acc, curr) => acc + curr.data.length, 0);
        let kpiValues;

        if (hasDisplayableData) {
          kpiValues = kpiResponse.map((response) => ({
            data: response.data
              ? response.data.slice(0, response.data.length - 1).map((r) => ({x: r.x, y: r.y || 0}))
              : [],
            kpi: store.keyPerformanceIndicatorStore.getById(response.kpiId),
            kpiId: response.kpiId,
            dates: response.data.map((r) => dayjs(r.x)),
          }));
        }

        kpiValues = kpiValues.map((kpiValue) => ({
          ...kpiValue,
          data: kpiValue.data.map((d) => ({...d, y: kpiValue.kpi.calculateToPercentageValue(d.y)})),
        }));

        if (kpiIds.length === 1 && !showPercent) {
          const targetResponse = await store.keyPerformanceIndicatorTargetStore.calculate({
            params: {
              workplaceId,
              fromDate,
              toDate,
              resolution,
              kpiId: kpiIds,
            },
          });

          const targetResponseData = targetResponse && targetResponse[0]?.series;
          if (targetResponseData) {
            const targetSum = targetResponseData.reduce((acc, curr) => acc + curr.y, 0);

            setIsTargetLineEnabled(!!targetSum);
            setTargetData(
              targetResponseData.map((entry) => ({y: entry.y ? entry.y : 0, x: entry.x}))
            );
          }
        }

        setData(kpiValues);
        setIsLoading(false);
      }
    })();
  }, [workplaceId, kpiIds?.join(''), resolution]);

  const getCategories = () => {
    const categories = [];
    const diff = dayjs(toDate).diff(dayjs(fromDate), 'day');
    const isSameDay = diff === 0;
    if (!data || !data[0]?.dates) {
      return categories;
    }

    data[0].dates.forEach((date, index) => {
      if (aggregation === AggregationOptions.DAY) {
        categories.push(date.format(readableDateFormat));
      } else if (aggregation === AggregationOptions.WEEK) {
        categories.push(
          `${dayjs(fromDate).format(readableDateFormat)}`
            + ` - ${dayjs(toDate).subtract(1, 'day').format(readableDateFormat)}`
        );
      } else if (index === 0 || date.hour() === 0) {
        categories.push(`${date.format(timeFormat)}<br/>${date.format(readableDateFormat)}`);
      } else if (isSameDay) {
        categories.push(date.format(timeFormat));
      } else {
        categories.push(date.format(timeFormat));
      }
    });

    return categories;
  };

  const getSerieData = (serieData) => (
    serieData?.map((d) => {
      const point = {
        y: Number(d.y.toFixed(2)),
      };
      switch (aggregation) {
        case AggregationOptions.HOUR:
          point.datetime = dayjs(d.x).format(datetimeFormat);
          break;
        case AggregationOptions.DAY:
          point.datetime = dayjs(d.x).format(dateFormat);
          break;
        case AggregationOptions.WEEK:
          point.datetime = `${dayjs(fromDate).format(dateFormat)}`
            + ` - ${dayjs(toDate).subtract(1, 'day').format(dateFormat)}`;
          break;
        default:
          point.datetime = dayjs(d.x).format(datetimeFormat);
      }
      return point;
    })
  );

  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: getCategories(),
    },
    yAxis: {
      labels: {
        formatter: (axis) => `${axis.value} ${showPercent ? '%' : unitOfMeasurement?.label || ''}`,
      },
      max: yAxisMax || null,
      title: {
        text: yAxisTitle || null,
      },
    },
    series: data?.map((entry) => ({
      name: entry.kpi.name,
      data: getSerieData(entry.data),
    })),
    plotOptions: {
      column: {
        stacking: showPercent ? 'percent' : 'normal',
        dataLabels: {
          enabled: true,
          pointFormat: showPercent ? '{point.percentage:.1f}%' : '',
        },
      },
    },
    tooltip: {
      formatter() {
        const { y, series: { name }, point } = this;
        /** @type {KeyPerformanceIndicator | null} */
        const kpi = store.keyPerformanceIndicatorStore.getById(kpiIds.length === 1 ? kpiIds[0] : null);
        const newY = kpi?.getDisplayableValue(y.toFixed(2)) || y.toFixed(2);
        return `${point.datetime}<br/>${name}: ${newY}${showPercent ? '%' : ''}`;
      },
    },
  };

  if (isTargetLineEnabled) {
    options.series.push({
      type: 'line',
      name: t('kpiChartCockpitWidget.chart.target', {
        kpiName: store.keyPerformanceIndicatorStore.getById(kpiIds[0])?.name,
      }),
      data: getSerieData(targetData),
    });
  }

  return (
    <Chart
      options={options}
      highcharts={Highcharts}
      isLoading={isLoading}
      showIsEmpty={!data?.length}
      legendPosition={legendPosition}
    />
  );
};

export default observer(KpiChart);
