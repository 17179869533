import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { TreeSelect, TreeSelectProps } from 'antd';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../buttons/Button';
import { BaseModel } from '../../../../models/base';
import { useStore } from '../../../../hooks/useStore';
import HierarchyTreeAdvancedSearch from './HierarchyTreeAdvancedSearch';
import { useMount } from '../../../../hooks/useMount';
import { Hierarchy } from '../../../../models/hierarchy';

export enum HierarchyTreeDataStrategy {
  FULL = 'Full',
  TO_WORKPLACES_ONLY = 'To Workplaces Only',
  NO_WORKPLACES = 'No Workplaces'
}

/* eslint-disable-next-line max-len */
export type HierarchyTreeProps = Omit<TreeSelectProps, 'onChange' | 'treeData' | 'showSearch' | 'dropdownStyle' | 'treeDefaultExpandAll' | 'treeNodeFilterProp' | 'treeNodeLabelProp'> & {
  isDisabled?: (entity: BaseModel) => boolean;
  treeDataStrategy?: HierarchyTreeDataStrategy;
  displayAdvancedSearch?: boolean;
  hideBreadcrumbs?: boolean;
  onChange?: (value: number | undefined) => void;
};

const HierarchyTree: React.FC<HierarchyTreeProps> = ({
  value,
  onChange,
  allowClear = false,
  isDisabled = () => false,
  placeholder = undefined,
  treeDataStrategy = HierarchyTreeDataStrategy.TO_WORKPLACES_ONLY,
  displayAdvancedSearch = true,
  hideBreadcrumbs = false,
  disabled = false,
  ...props
}) => {
  const { t } = useTranslation();
  const store = useStore();
  const [hierarchyTreeData, setHierarchyTreeData] = useState<Hierarchy[]>([]);
  const [hierarchyId, setHierarchyId] = useState(value);
  const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false);

  let placeholderText: React.ReactNode = store.hierarchyStore.hierarchyTree?.length
    ? t('hierarchyTree.selectHierarchy')
    : t('hierarchyTree.noHierarchies');

  if (placeholder) {
    placeholderText = placeholder;
  }

  useMount(() => {
    if (hierarchyId !== value && onChange) {
      onChange(hierarchyId);
    }
  }, [hierarchyId]);

  useMount(() => {
    setHierarchyId(value);
  }, [value]);

  useMount(() => {
    if (store.hierarchyStore.hierarchies.length && store.workplaceStore.workplaces.length) {
      setHierarchyTreeData(() => {
        switch (treeDataStrategy) {
          case HierarchyTreeDataStrategy.NO_WORKPLACES:
            return store.hierarchyStore.hierarchyTreeNoWorkplaces;
          case HierarchyTreeDataStrategy.TO_WORKPLACES_ONLY:
            return store.hierarchyStore.hierarchyTreeToWorkplacesOnly;
          default:
            return store.hierarchyStore.hierarchyTree;
        }
      });
    }
  }, [treeDataStrategy, store.hierarchyStore.hierarchies.length, store.workplaceStore.workplaces.length]);

  const handleAdvancedSearch = (res: number | undefined) => {
    setAdvancedSearchVisible(false);
    setHierarchyId(res);
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
      <TreeSelect
        allowClear={allowClear}
        value={hierarchyId}
        placeholder={placeholderText}
        onChange={(val) => setHierarchyId(val)}
        disabled={!store.hierarchyStore.hierarchyTree?.length || disabled}
        key={'hierarchyId'}
        treeData={BaseModel.mapToSelectTreeData(hierarchyTreeData, null, isDisabled)}
        showSearch
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        treeDefaultExpandAll
        treeNodeFilterProp={'title'}
        treeNodeLabelProp={hideBreadcrumbs ? 'title' : 'breadcrumb'}
        {...props}
      />
      {displayAdvancedSearch && (
        <Button
          type={'default'}
          icon={<FontAwesomeIcon icon={faSearchPlus}/>}
          style={{ width: '2em', marginLeft: '6px' }}
          onClick={() => setAdvancedSearchVisible(true)}
        />
      )}
      <HierarchyTreeAdvancedSearch
        open={advancedSearchVisible}
        onCancel={() => {
          setAdvancedSearchVisible(false);
        }}
        onSubmit={handleAdvancedSearch}
      />
    </div>
  );
};
export default observer(HierarchyTree);
