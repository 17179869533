import React from 'react';
import { observer } from 'mobx-react-lite';
import { useMount } from '../../hooks/useMount';

export interface Mark {
  percentage: number;
  mark: string;
}

export interface Colorizing {
  trackColor: string;
  markColor: string;
  genericMarkColor: string;
  markTextColor: string;
  trackCurrentValueColor: string;
  trackNewValueColor: string;
}

interface SliderOptions {
  min: number;
  max: number;
  marks: Mark[];
  colorizing: Colorizing;
  currentValue: number;
  newValue: number;
  genericSteps?: number;
  unitOfMeasureLabel?: string;
}

export type DosageSliderComponentProps = {
  min: number;
  max: number;
  currentValue: number;
  newValue: number;
  marks: number[];
  colorizing: Colorizing;
  className?: string;
  genericSteps?: number;
  unitOfMeasureLabel?: string;
  isParent?: boolean;
};

const DosageSliderComponent: React.FC<DosageSliderComponentProps> = ({
  min,
  max,
  currentValue,
  newValue,
  marks,
  colorizing,
  className,
  genericSteps,
  unitOfMeasureLabel,
  isParent,
}) => {
  const startingMarks: Mark[] = marks.map((mark) => ({
    percentage: (mark / max) * 100,
    mark: mark.toString(),
  }));

  const [options, setOptions] = React.useState<SliderOptions>({
    min,
    max,
    marks: startingMarks,
    colorizing,
    currentValue,
    newValue,
    genericSteps: genericSteps || 0,
    unitOfMeasureLabel: unitOfMeasureLabel || '',
  });

  useMount(() => {
    let newMarks: Mark[] = [];
    newMarks = marks.map((mark) => ({
      percentage: (mark / max) * 100,
      mark: mark.toString(),
    }));

    setOptions((prevOptions: SliderOptions) => ({
      ...prevOptions,
      min: min != null ? min : prevOptions.min,
      max: max != null ? max : prevOptions.max,
      marks: newMarks || prevOptions.marks,
      colorizing: colorizing || prevOptions.colorizing,
      currentValue: currentValue != null ? currentValue : prevOptions.currentValue,
      newValue: newValue != null ? newValue : prevOptions.newValue,
      unitOfMeasureLabel: unitOfMeasureLabel || prevOptions.unitOfMeasureLabel,
    }));
  }, [min, max, marks, colorizing, currentValue, newValue]);

  const createGenericMarks = () => {
    const newMarks: Mark[] = [];
    let stepsQty = options?.genericSteps || 2;
    stepsQty -= 1;

    if (stepsQty > 0) {
      for (let i = 0; i <= stepsQty; i += 1) {
        const percentage = (i / stepsQty) * 100;

        newMarks.push({
          percentage,
          mark: (Math.round(((options.max / stepsQty) * 100_000) * i) / 100_000).toString(),
        });
      }
    }

    return newMarks;
  };

  const trackMark = (percentage: number, mark: string, isGeneric: boolean = false) => {
    const newValPercentage = (options.newValue / options.max) * 100;
    const curValPercentage = (options.currentValue / options.max) * 100;
    const calculatedPercentage = curValPercentage + newValPercentage;
    const {
      markColor,
      markTextColor,
      genericMarkColor,
    } = options.colorizing;
    const isColumnReverse = percentage !== 0 && (percentage !== 100 || calculatedPercentage >= 100) && !isGeneric;
    const uom = options?.unitOfMeasureLabel || '';

    let finalMark = mark;
    let finalPercentage = percentage;
    if (percentage < 0) {
      finalPercentage = 0;
      finalMark = '0';
    } else if (percentage > 100) {
      finalPercentage = 100;
      finalMark = options.max.toString();
    }

    let coloredMarker = false;
    if (isGeneric && percentage <= calculatedPercentage) {
      coloredMarker = true;
    }

    if (
      !isGeneric
      && finalPercentage === calculatedPercentage
      && isParent
      && calculatedPercentage !== curValPercentage
    ) {
      finalMark = `∅ ${finalMark} ${uom}`;
    } else if (isColumnReverse) {
      finalMark = `${finalMark} ${uom}`;
    }

    return (
      <div
        key={`${percentage}-${isColumnReverse ? '1' : '0'}`}
        className="track-mark-wrapper"
        style={{
          position: 'absolute',
          width: isGeneric ? '12px' : '16px',
          left: `calc(${finalPercentage}% - ${isGeneric ? '6px' : '8px'})`,
          display: 'flex',
          flexDirection: isColumnReverse ? 'column-reverse' : 'column',
          alignItems: 'center',
          // eslint-disable-next-line no-nested-ternary
          top: isGeneric ? '4px' : isColumnReverse ? '-23px' : '2px',
        }}
      >
        <div
          className="track-mark"
          style={{
            height: isGeneric ? '12px' : '16px',
            width: isGeneric ? '12px' : '16px',
            borderRadius: '100%',
            backgroundColor: (isGeneric && !coloredMarker) ? genericMarkColor : markColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            className="track-mark-inner"
            style={{
              height: '8px',
              width: '8px',
              borderRadius: '100%',
              backgroundColor: isGeneric ? 'transparent' : '#ffffff',
            }}
          />
        </div>
        <div
          className="mark"
          style={{
            fontSize: isGeneric ? '14px' : '16px',
            fontWeight: 'bold',
            color: markTextColor,
            whiteSpace: 'nowrap',
          }}
        >
          {finalMark}
        </div>
      </div>
    );
  };

  let newValuePercentage = 0;
  let currentValuePercentage = 0;
  if (options.newValue && options.max) {
    newValuePercentage = (options.newValue / options.max) * 100;
    if (newValuePercentage > 100) {
      newValuePercentage = 100;
    }
  }
  if (options.currentValue && options.max) {
    currentValuePercentage = (options.currentValue / options.max) * 100;
    if (currentValuePercentage > 100) {
      currentValuePercentage = 100;
    }
  }

  if (currentValuePercentage + newValuePercentage > 100) {
    if (100 - currentValuePercentage > 0) {
      newValuePercentage = 100 - currentValuePercentage;
    } else {
      newValuePercentage = 0;
    }
  }

  const {
    trackColor,
    trackCurrentValueColor,
    trackNewValueColor,
  } = options.colorizing;

  const genericMarks = createGenericMarks();

  return (
    <div
      className={`custom-slider ${className || ''}`}
      style={{
        width: '100%',
        minHeight: '60px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        transform: 'translateY(20px)',
      }}
    >
      <div
        className="track"
        style={{
          width: 'calc(100% - 16px)',
          height: '20px',
          display: 'flex',
          alignItems: 'center',
          position: 'relative',
          transform: 'translateX(8px)',
        }}
      >
        <div
          className="track-full-line"
          style={{
            height: '8px',
            width: '100%',
            backgroundColor: trackColor,
          }}
        />
        <div
          className="track-current-line"
          style={{
            height: '8px',
            width: `${currentValuePercentage}%`,
            backgroundColor: trackCurrentValueColor,
            position: 'absolute',
          }}
        />
        <div
          className="track-fill-line"
          style={{
            height: '8px',
            left: `${currentValuePercentage}%`,
            width: `${newValuePercentage}%`,
            backgroundColor: trackNewValueColor,
            position: 'absolute',
          }}
        />
        {
          genericMarks.map((mark: Mark) => (
            trackMark(mark.percentage, mark.mark, true)
          ))
        }
        {
          options.marks
            .filter((mark: Mark) => mark.percentage >= 0)
            .map((mark: Mark) => (
              trackMark(mark.percentage, mark.mark)
            ))
        }
      </div>
    </div>
  );
};

export default observer(DosageSliderComponent);
