import {createEntity, executeGet, executePost, updateEntity} from '../requests';

const resourcesName = 'v1/interruptions/reasons';

export const InterruptionReasonsApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...entity}) => updateEntity(entity, `${resourcesName}/${id}`),
  archive: (id) => executePost(`${resourcesName}/${id}/archive`),
  restore: (id) => executePost(`${resourcesName}/${id}/restore`),
};
