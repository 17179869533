import { isUndefined } from 'lodash';
import appConfig from '../../../utils/appConfig';
import {
  ConfigSetting,
  FieldType,
  NestedModelOption, SettingValue,
  TabDefinition,
  WidgetConfigExtension,
} from '../../../types/widgetConfig';
import { RootStore } from '../../../stores/rootStore';
import { RecordOfString } from '../../../types/util';

export class BaseWidgetConfig {
  rootStore: RootStore;
  identifier: string;
  settingsIdentifier: string;
  storeData: (() => any | undefined);
  manualPath: string | undefined;
  protected tabConfigs: Map<string, ConfigSetting[]> = new Map();
  private extensions: WidgetConfigExtension[];

  /**
   * BaseWidgetConfig constructor
   * @param rootStore type {RootStore}
   * @param widgetIdentifier type {string}
   * @param storeData type {function}
   * @param extensions type {WidgetConfigExtension[]}
   */
  constructor(
    rootStore: RootStore,
    widgetIdentifier: string,
    storeData = undefined,
    extensions: WidgetConfigExtension[] = []
  ) {
    this.rootStore = rootStore;
    this.identifier = widgetIdentifier;
    this.settingsIdentifier = `${widgetIdentifier}.settings`;
    this.storeData = storeData || (() => this.rootStore.settingStore.getByIdentifier(this.settingsIdentifier));
    this.extensions = extensions;
  }

  static onLoadConfig = () => {
    // load dependencies for BaseWidgetConfig
  };

  // eslint-disable-next-line class-methods-use-this
  get tabs(): TabDefinition[] {
    return this.extensions.reduce(
      (tabs: TabDefinition[], extension) => tabs.concat(extension.getTabs()),
      []
    );
  }

  getSettings(tab: string): ConfigSetting[] {
    if (this.tabConfigs?.size) {
      const settings = this.tabConfigs.get(tab) || [];
      const extensionSettings = this.extensions.reduce(
        (extSettings: ConfigSetting[], extension) => extSettings.concat(extension.getSettings(tab)),
        []
      );
      return settings.concat(extensionSettings);
    }
    // @ts-ignore FIXME: Have to be fixed after all config files are TS
    return this[tab] || [];
  }

  // eslint-disable-next-line class-methods-use-this
  getWidgetTypeName(): string {
    return '';
  }

  // eslint-disable-next-line class-methods-use-this
  mapSettingPropertyKeyToProperty(localSetting: ConfigSetting, key: { property?: any, label?: any }): any | undefined {
    let value = key;
    let settings: { key: string }[] | undefined = localSetting.properties;
    if (localSetting.type === FieldType.PropertiesSelectDisplayField) {
      value = key?.property;
      settings = localSetting.modelOptions;
    }
    let result: any = settings?.find((property) => property.key === value);
    if (!result) {
      return undefined;
    }
    result = { ...result };
    if (Object.keys(result).length === 0) {
      // eslint-disable-next-line no-console
      console.warn(`Property "${key}" is unknown`);
      return undefined;
    }
    if (!isUndefined(result)
      && localSetting.type === FieldType.PropertiesSelectDisplayField
      && key.label
    ) {
      if (typeof key.label === 'string') {
        // Old value, set to system default
        key.label = { [appConfig.language]: key.label };
      }
      // TODO: As an additional fallback on every level, try to find a translation for only the language, without the
      //  region.
      result.label = key.label[this.rootStore.languageStore.lang]
        || key.label[appConfig.language] || Object.values(key.label)[0] || '';
      result.title = key.label[this.rootStore.languageStore.lang]
        || key.label[appConfig.language] || Object.values(key.label)[0] || '';
    }
    return result;
  }

  getSelectedProperties(tab: string, identifier: string): any[] {
    const localSetting = this.getSettings(tab).find((config) => config.id === identifier);
    if (localSetting) {
      // This assumes ConfigField.type === PropertiesSelectDisplayField
      return this
        .getSettingValue(tab, identifier)
        .map((propertyKey: NestedModelOption) => this.mapSettingPropertyKeyToProperty(localSetting, propertyKey))
        .filter((item: any) => item !== undefined);
    }

    return [];
  }

  getSettingValueForSource(identifier: string): any {
    // This is the case if the Source is the SettingStore
    if (this.storeData()?.value) {
      return this.storeData()?.value[identifier];
    }

    // This is the case if the Source is either the config property of the cockpitWidget or another property
    // on the entity (e.g name of cockpitWidget)
    const configProperty = this.storeData()?.config && JSON.parse(this.storeData()?.config);
    return configProperty?.[identifier] ?? this.storeData()?.[identifier];
  }

  // FIXME: Interpretation and additional handling based on FieldConfig.type should not happen in this central place
  getSettingValue(tab: string, identifier: string): SettingValue {
    const localSetting = this.getSettings(tab).find((config) => config.id === identifier);
    if (!localSetting) {
      return undefined;
    }
    const remoteSettingValue = this.getSettingValueForSource(identifier);

    let value;
    if (remoteSettingValue) {
      value = remoteSettingValue;
    } else if (localSetting?.type === FieldType.Number) {
      value = remoteSettingValue ?? localSetting?.defaultValue;
    } else if (localSetting?.type === FieldType.Boolean) {
      value = isUndefined(remoteSettingValue) ? localSetting?.defaultValue : remoteSettingValue;
    } else if (localSetting?.type === FieldType.PropertiesSelectDisplayField) {
      value = isUndefined(remoteSettingValue) ? localSetting?.defaultValue : remoteSettingValue;
    } else {
      value = localSetting?.defaultValue;
    }
    switch (localSetting.type) {
      case (FieldType.Number):
        if (value && typeof value === 'string') {
          return Number(value);
        }
        if (!value && typeof value !== 'number') {
          return null;
        }
        return value;
      case (FieldType.Boolean):
        return Boolean(value);
      case (FieldType.Properties):
        return value;
      case FieldType.PropertiesSelectDisplayField: {
        const propertiesSelectDisplayFieldList: NestedModelOption[] = [];
        value = Array.isArray(value) ? value : [];
        // Iterate over properties to handle different version and setting defaults where required
        for (let index = 0; index < value.length; index += 1) {
          const element = value[index];
          let tempValue;
          let option;

          if (typeof element === 'string') {
            // Upgrade from old settings
            option = (<{ key: string, title?: string, label?: string }[]>localSetting.modelOptions)
              .find((x) => x.key === element);
            tempValue = option ? {
              property: element,
              // Old value, assume system language and not user language
              label: { [appConfig.language]: (option?.title || option?.label) },
            } : null;
          } else {
            // Handle new format and set default label if necessary
            tempValue = { ...element };
            if (!tempValue.label) {
              option = (<{ key: string, title?: string, label?: string }[]>localSetting.modelOptions)
                .find((x) => x.key === element.property);
              // First time setting value, use users current language
              tempValue.label = { [this.rootStore.languageStore.lang]: option?.title || option?.label };
            }
            if (typeof tempValue.label === 'string') {
              // Old value, assume system language and not user language
              tempValue.label = { [appConfig.language]: tempValue.label };
            }
          }
          // Add new element to result list
          propertiesSelectDisplayFieldList.push(tempValue);
        }
        return propertiesSelectDisplayFieldList;
      }
      case FieldType.Translatable:
        if (typeof value === 'string') {
          // Handle values from old configurations, when they were simple strings
          return { [appConfig.language]: value };
        }
        return value;
      default:
        return value;
    }
  }

  getValues(): RecordOfString {
    const values: RecordOfString = {};
    this.tabs.forEach((tab) => {
      this.getSettings(tab.key).forEach((localSetting) => {
        values[localSetting.id] = this.getSettingValue(tab.key, localSetting.id);
      });
    });

    return values;
  }

  /**
   * @param tab {string}
   * @param identifier {string}
   * @return {string} The translated value of a translatable setting.
   *   Calling getSettingValue for a translatable setting will return the entire object with all translations.
   *   Use this method when displaying a translatable setting.
   *   If used with anything else than a translatable setting, an empty string is returned.
   */
  getTranslatableValue(tab: string, identifier: string): string {
    const localSetting = this.getSettings(tab).find((config) => config.id === identifier);
    if (!localSetting) {
      return '';
    }

    if (localSetting?.type !== FieldType.Translatable) {
      return '';
    }
    const remoteSettingValue = this.getSettingValueForSource(identifier);

    let value = localSetting?.defaultValue;
    if (remoteSettingValue) {
      value = remoteSettingValue;
    }

    if (typeof value === 'string') {
      // Handle values from old configurations, when they were simple strings
      return value;
    }

    // TODO: As an additional fallback on every level, try to find a translation for only the language, without the
    //  region.
    return value[this.rootStore.languageStore.lang] || value[appConfig.language] || Object.values(value)[0] || '';
  }
}
