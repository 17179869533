import {useState} from 'react';
import {Col, Row, Space} from 'antd';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import {sortBy, uniqBy} from 'lodash';
import {observer} from 'mobx-react-lite';
import {faSignalStream} from '@fortawesome/pro-solid-svg-icons';
import Modal from '../shared/modal/Modal';
import {useMount} from '../../hooks/useMount';
import {VisTimeline} from '../shared/dataDisplay/VisTimeline';
import TimespanNavigator from '../shared/inputs/TimespanNavigator';
import colors from '../../assets/stylesheets/colors.module.scss';
import {useStore} from '../../hooks/useStore';
import {visItemStyle} from '../shared/dataDisplay/visItemStyle';
import {visGroupStyle} from '../shared/dataDisplay/visGroupStyle';
import LogDetails from './LogDetails';
import {getReducedByInterruptionsMinDuration} from './stateHistoryUtils';

const toWindows = (arr) => arr.map((item, index) => [item, arr[index + 1]]);

const toItem = (group, selectedLog) => (log) => {
  const color = log?.state?.color || '#ffffff';
  const isSelected = selectedLog?.id === log.id;

  return ({
    id: `${group}:${log.id}`,
    start: log.start,
    end: log.end || new Date(),
    content: '&nbsp;',
    style: visItemStyle({color, isSelected}),
    group: `${group}:${log.state?.id}`,
    height: '40px',
  });
};

const mapToPersonnelItems = (logs, selectedLog) => {
  logs.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  return toWindows(logs).map(([log, next]) => {
    const color = colors.primaryColor;
    const isSelected = selectedLog?.id === log.id;
    return ({
      id: `personnel:${log.id}`,
      start: log.createdAt,
      end: next?.createdAt || new Date(),
      content: String(log.personnel),
      style: visItemStyle({color, isSelected}),
      group: 'personnel',
    });
  });
};

const mapToGroup = (logs, type) => {
  const states = sortBy(uniqBy(logs.map((log) => log.state), 'id'), 'sortOrder')
    .filter((state) => state?.isShownInHistory);
  return states.map((state, index) => ({
    id: `${type}:${state.id}`,
    content: state.label,
    style: visGroupStyle({hasDivider: index === 0}),
  }));
};

const StateHistoryDetailModal = ({initialFromDate, showInterruptionsMinDuration, ...props}) => {
  const {t} = useTranslation();
  const [toDate, setToDate] = useState(dayjs());
  const [fromDate, setFromDate] = useState(toDate.clone().subtract(5, 'days'));
  const [minFromDate, setMinFromDate] = useState(initialFromDate);
  const store = useStore();
  const workplaceId = store.workplaceStore.selectedWorkplace?.id;
  const selectedLog = store.workplaceStateLogStore.selected
    || store.operationStateLogStore.selected
    || store.personnelLogStore.selected;
  const [nextLog, setNextLog] = useState(null);
  const [items, setItems] = useState([]);
  const [groups, setGroups] = useState([]);

  let operationLogs = store.operationStateLogStore.getByWorkplaceId(workplaceId);
  let workplaceLogs = store.workplaceStateLogStore.getByWorkplaceId(workplaceId);

  if (showInterruptionsMinDuration) {
    operationLogs = getReducedByInterruptionsMinDuration(operationLogs, showInterruptionsMinDuration);
    workplaceLogs = getReducedByInterruptionsMinDuration(workplaceLogs, showInterruptionsMinDuration);
  }

  const personnelLogs = store.personnelLogStore.getByWorkplaceId(workplaceId);

  const maxDate = dayjs();

  useMount(() => {
    setItems([
      ...operationLogs.map(toItem('operationLogs', store.operationStateLogStore.selected)),
      ...workplaceLogs.map(toItem('workplaceLogs', store.workplaceStateLogStore.selected)),
      ...(mapToPersonnelItems(personnelLogs, store.personnelLogStore.selected)),
    ]);
    setGroups([
      ...mapToGroup(operationLogs, 'operationLogs'),
      ...mapToGroup(workplaceLogs, 'workplaceLogs'),
      {
        id: 'personnel',
        content: t('state.historyModal.personnel'),
      },
    ]);
  }, [operationLogs.length, workplaceLogs.length, personnelLogs.length, selectedLog]);

  useMount(() => {
    if (store.workplaceStore.selectedWorkplace) {
      if (fromDate < minFromDate) {
        store.operationStateLogStore.loadManyByWorkplace(workplaceId, {fromDate, toDate})
          .then((logs) => store.operationStore.loadMany(logs.map((log) => log.operationId)));
        store.workplaceStateLogStore.loadManyByWorkplace(workplaceId, {fromDate, toDate});

        store.personnelLogStore.loadAll({
          params: {
            workplaceId,
            fromDate: fromDate.toDate(),
            toDate: toDate.toDate(),
          },
        });
        setMinFromDate(fromDate);
      }
    }
  }, [workplaceId, fromDate, toDate]);

  useMount(() => {
    if (selectedLog) {
      store.userStore.load(selectedLog.createdBy);
      if (selectedLog.operationId) {
        store.operationStore.load(selectedLog.operationId)
          .then((op) => {
            store.orderStore.load(op.orderId)
              .then((order) => {
                store.materialStore.load(order.materialId);
              });
          });
      }
    }
  }, [selectedLog]);

  const handleClick = (event) => {
    const item = event.items[0];
    if (item) {
      const [type, id] = item.split(':');
      if (type === 'operationLogs') {
        const log = store.operationStateLogStore.getById(Number(id));
        setNextLog(null);
        store.workplaceStateLogStore.selected = null;
        store.personnelLogStore.selected = null;
        store.operationStateLogStore.selected = log;
      } else if (type === 'workplaceLogs') {
        const log = store.workplaceStateLogStore.getById(Number(id));
        setNextLog(null);
        store.operationStateLogStore.selected = null;
        store.personnelLogStore.selected = null;
        store.workplaceStateLogStore.selected = log;
      } else if (type === 'personnel') {
        const log = store.personnelLogStore.getById(id);
        setNextLog(store.personnelLogStore.getNextByTimestamp(log.createdAt));
        store.operationStateLogStore.selected = null;
        store.workplaceStateLogStore.selected = null;
        store.personnelLogStore.selected = log;
      }
    }
  };

  const handleRangeChanged = (event) => {
    if (event.byUser) {
      setFromDate(dayjs(event.start));
      setToDate(dayjs(event.end));
    }
  };

  return (
    <Modal
      fullscreen={store.clientStore.isMobile}
      width="90%"
      bodyStyle={{minHeight: '420px'}}
      title={(
        <>
          <FontAwesomeIcon icon={faSignalStream}/>
          {' '}
          {t('state.historyModal.title')}
        </>
      )}
      open
      footer={false}
      {...props}
    >
      <Row gutter={16}>
        <Col span={18}>
          <Space direction={'vertical'} style={{width: '100%'}}>
            <TimespanNavigator
              fromDate={fromDate}
              toDate={toDate}
              setFromDate={setFromDate}
              setToDate={setToDate}
              maxDate={maxDate}
            />
            <VisTimeline
              items={items}
              groups={groups}
              selectHandler={handleClick}
              rangechangedHandler={handleRangeChanged}
              options={{
                start: fromDate.toDate(),
                end: toDate.toDate(),
                max: maxDate.toDate(),
                moveable: true,
              }}
            />
          </Space>
        </Col>
        <Col span={6}>
          <h1>{t('state.historyModal.details.title')}</h1>
          {
            !selectedLog
              ? (<span>{t('state.historyModal.details.placeholder')}</span>)
              : (<LogDetails log={selectedLog} nextLog={nextLog}/>)
          }
        </Col>

      </Row>
    </Modal>
  );
};

export default observer(StateHistoryDetailModal);
