import {Button} from 'antd';
import {CheckSquareOutlined, UploadOutlined} from '@ant-design/icons';
import {observer} from 'mobx-react-lite';
import {OperationDefaultTabs} from '../../../models/operation';
import {ReportState} from '../../../models/reportLog';

const ManualFinalPerformanceReportingButton = ({
  record,
  reportType,
  selectedTab,
  store,
  setCurrentReportLog = () => undefined,
  setReportLogModalVisible = () => undefined,
}) => {
  const createReport = async () => {
    const oldReportLogs = store.reportLogStore.getByReportTypeIdAndOperationId(reportType.id, record.id);

    if (oldReportLogs && oldReportLogs.length > 0 && oldReportLogs[0].state === ReportState.Draft) {
      await store.reportLogStore.delete(oldReportLogs[0].id, {skipNotification: true});
    }

    let newReportLog;
    if (!oldReportLogs || oldReportLogs.length === 0 || oldReportLogs[0].state === ReportState.Draft) {
      newReportLog = await store.reportLogStore.create({
        operationId: record.id,
        typeName: reportType.type,
      }, {skipNotification: true});
    }

    if (newReportLog) {
      setCurrentReportLog(newReportLog);
      setReportLogModalVisible(true);
    } else if (oldReportLogs && oldReportLogs.length === 1) {
      setCurrentReportLog(oldReportLogs[0]);
      setReportLogModalVisible(true);
    }
  };

  if (selectedTab?.id === OperationDefaultTabs.finished && !record.children) {
    const hasReport = store.reportLogStore.getByReportTypeIdAndOperationId(reportType.id, record.id)
      .filter((reportLog) => reportLog.state !== ReportState.Draft).length > 0;
    return (
      <Button
        type={'link'}
        icon={(hasReport) ? <CheckSquareOutlined/> : <UploadOutlined/>}
        onClick={() => createReport(record)}
        loading={store.reportLogStore.isLoadingCollection}
        disabled={record.order.state < 3}
      />
    );
  }
  return null;
};

export default observer(ManualFinalPerformanceReportingButton);
