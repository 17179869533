import React from 'react';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

const AccessDeniedAlert: React.FC<{ missingPermissions: string[] }> = ({ missingPermissions = [] }) => {
  const { t } = useTranslation();
  return (
    <Alert
      message={t('error.one')}
      description={(
        <>
          {t('error.missingPermission')}
          {missingPermissions.length && (
            <ul>
              {missingPermissions.map((permission) => <li key={permission}>{permission}</li>)}
            </ul>
          )}
        </>
      )}
      type={'error'}
      showIcon
      data-cy={'Access-Denied-Alert'}
    />
  );
};

export default AccessDeniedAlert;
