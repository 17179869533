import i18n from '../../i18n/i18n';
import {BaseWidgetConfig} from '../shared/widgets/baseWidgetConfig';
import {ValidationRules} from '../../utils/validationRules';
import {Yield} from '../../models/yield';
import {getAllI18nTranslations} from '../../utils/translations';
import {FieldType} from '../../types/widgetConfig';

export class YieldWidgetConfig extends BaseWidgetConfig {
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'settings',
        label: i18n.t('widget.WidgetSettingsModal.settingConfigTabTitle'),
      },
      {
        key: 'data',
        label: i18n.t('widget.WidgetSettingsModal.dataConfigTabTitle'),
      },
    ];
  }

  settings = [
    {
      id: 'title',
      label: i18n.t('widget.settings.title.label'),
      helpText: i18n.t('widget.settings.title.help'),
      type: FieldType.Translatable,
      defaultValue: getAllI18nTranslations('yieldWidget.title'),
      validations: [ValidationRules.required()],
    },
    {
      id: 'showSumReport',
      label: i18n.t('widget.settings.showSumReport.label'),
      helpText: i18n.t('widget.settings.showSumReport.help'),
      type: FieldType.Boolean,
      defaultValue: false,
    },
  ];

  data = [
    {
      id: 'properties',
      type: FieldType.PropertiesSelectDisplayField,
      modelOptions: Yield.allDisplayableProperties(this.rootStore),
      defaultValue: [
        'yieldType.label',
        'createdAt',
        'quantity',
        'material.no',
        'material.name',
        'unitOfMeasure.label',
      ],
    },
  ];

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'title');
  }

  getShowSummReport() {
    return this.getSettingValue('settings', 'showSumReport');
  }

  getTableColumns() {
    return this.getSelectedProperties('data', 'properties');
  }
}
