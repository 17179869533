import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { DataExportTemplate } from '../models/dataExportTemplate';
import { RootStore } from './rootStore';
import { DataExportType } from '../models/dataExportScheduler';

export class DataExportTemplateStore extends EntityStore<DataExportTemplate> {
  dataExportTemplates: DataExportTemplate[] = [];

  constructor(rootStore: RootStore) {
    super(
      rootStore,
      'dataExportTemplates',
      Api.DataExportTemplate,
      DataExportTemplate
    );

    makeObservable(this, {
      dataExportTemplates: observable,
    });
  }

  loadByType(templateType: DataExportType) {
    return this.loadAll({ params: { type: templateType } });
  }

  getByType(templateType: DataExportType) {
    return this.dataExportTemplates
      .filter((template) => template.type === templateType);
  }
}
