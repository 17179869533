import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import FormModal from '../../shared/modal/FormModal';
import { useStore } from '../../../hooks/useStore';
import Tabs from '../../shared/tabs/Tabs';
import PrintJobLogTab from './PrintJobLogTab';
import { reprintPrintJobLogEndpoint } from '../../../middleware/endpoints/printJobLogs';
import { PrintJob } from '../../../models/printJob';
import PrintJobPrinterTab from './PrintJobPrinterTab';
import PrintJobPreviewTab from './PrintJobPreviewTab';

export type ViewPrintJobProps = {
  job: PrintJob;
  onCancel: () => void;
};

const ViewPrintJob: React.FC<ViewPrintJobProps> = ({
  job,
  onCancel,
}) => {
  const { t } = useTranslation();
  const store = useStore();
  const [currentTab, setCurrentTab] = useState('logs');

  const onReprint = (printJobLogId: number, printerId: number) => {
    store.printJobLogStore.reprintPrintJobLogById(printJobLogId, printerId);
  };

  const onManualPrint = (printerId: number, printData: any) => {
    store.printerStore.print(printerId, printData);
  };

  return (
    <FormModal title={t('printJob.view.title')} open onCancel={onCancel} width="90%">
      <div style={{ height: '70vh', overflowY: 'auto' }}>
        <Tabs
          activeKey={currentTab}
          onChange={setCurrentTab}
          items={[
            {
              key: 'logs',
              label: t('printJob.view.tabs.logs'),
              children: (
                <PrintJobLogTab
                  loading={store.printJobStore.isActionInProgress(reprintPrintJobLogEndpoint)}
                  onReprint={onReprint}
                  onManualPrint={onManualPrint}
                  job={job}
                  currentTab={currentTab}
                />
              ),
            },
            {
              key: 'preview',
              label: t('printJob.view.tabs.preview'),
              children: (
                <PrintJobPreviewTab
                  job={job}
                  currentTab={currentTab}
                />
              ),
            },
            {
              key: 'printer',
              label: t('printJob.view.tabs.printer'),
              children: (
                <PrintJobPrinterTab
                  job={job}
                />
              ),
            },
          ]}
        />
      </div>
    </FormModal>
  );
};

export default observer(ViewPrintJob);
