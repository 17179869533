import i18n from '../../../../../i18n/i18n';
import {CockpitWidgetConfig} from './cockpitWidgetConfig';
import {TimeframeOptions} from './timeframeAggregationOptions';
import {ValidationRules} from '../../../../../utils/validationRules';
import {FieldType} from '../../../../../types/widgetConfig';

export class InterruptionAnalysisWidgetConfig extends CockpitWidgetConfig {
  static onLoadConfig = (rootStore) => rootStore.interruptionClassStore.loadAll();

  static widgetType = {
    identifier: 'interruption-analysis',
    name: () => i18n.t('cockpitWidget.widgetTypeIdentifier.interruptionAnalysis'),
  };

  // eslint-disable-next-line class-methods-use-this
  getWidgetTypeName() {
    return i18n.t('cockpitWidget.widgetTypeIdentifier.interruptionAnalysis');
  }

  get tabs() {
    return [
      this.generalSettingsTab,
      this.workplaceTab,
      {
        key: 'interruptionAnalysis',
        label: i18n.t('widget.WidgetSettingsModal.interruptionAnalysisConfigTabTitle'),
      },
    ];
  }

  workplace = [
    {
      id: 'hierarchyId',
      label: i18n.t('cockpitWidget.settings.workplace.label'),
      helpText: i18n.t('cockpitWidget.settings.workplace.help'),
      type: FieldType.HierarchyTreeWorkplace,
    },
  ];

  interruptionAnalysis = [
    {
      id: 'timeframe',
      label: i18n.t('interruptionAnalysisWidgetConfig.settings.interruptionAnalysis.timeframe.label'),
      helpText: i18n.t('interruptionAnalysisWidgetConfig.settings.interruptionAnalysis.timeframe.helpText'),
      type: FieldType.Select,
      options: Object.values(TimeframeOptions).map((o) => ({
        value: o,
        label: i18n.t(`cockpitWidget.settings.timeframe.${o}`),
      })),
      validations: [ValidationRules.required()],
    },
    {
      id: 'daysBack',
      label: i18n.t('interruptionAnalysisWidgetConfig.settings.interruptionAnalysis.daysBack.label'),
      helpText: i18n.t('interruptionAnalysisWidgetConfig.settings.interruptionAnalysis.daysBack.helpText'),
      type: FieldType.Number,
      unit: i18n.t('interruptionAnalysisWidgetConfig.settings.interruptionAnalysis.daysBack.unit'),
      defaultValue: 3,
      validations: [
        ({getFieldValue}) => ValidationRules.requiredIf(getFieldValue('timeframe') === TimeframeOptions.DAYS_BACK),
        ValidationRules.minNumber(1),
        ValidationRules.maxNumber(7),
      ],
      disabled: ({getFieldValue}) => getFieldValue('timeframe') !== TimeframeOptions.DAYS_BACK,
      dependencies: ['timeframe'],
    },
    {
      id: 'interruptionClasses',
      label: i18n.t('interruptionAnalysisWidgetConfig.settings.interruptionAnalysis.interruptionClasses.label'),
      type: FieldType.SelectMultiple,
      defaultValue: [],
      options: this.rootStore.interruptionClassStore.interruptionClasses.map((irs) => ({
        value: irs.id,
        label: irs.name,
      })),
      validations: [ValidationRules.required()],
    },
  ];
}
