import React, {useState} from 'react';
import {Badge, Space} from 'antd';
import {uniqBy} from 'lodash';
import {ClearOutlined, PlusOutlined} from '@ant-design/icons';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {useStore} from '../../hooks/useStore';
import Table from '../shared/tables/Table';
import Tag from '../shared/tags/Tag';
import Button from '../shared/buttons/Button';
import {BatchQueueItemState} from '../../models/batchQueueItem';
import {EnDash} from '../shared/unicodeWrapper/EnDash';
import Select from '../shared/inputs/Select';
import {useMount} from '../../hooks/useMount';
import {SensorType} from '../../models/sensor';

const BatchQueueItemTag = observer(({batchQueueItem}) => {
  const amount = batchQueueItem.quantity - batchQueueItem.quantityUsed;
  let ribbonColor = 'gray';
  if (batchQueueItem.state === BatchQueueItemState.ACTIVE) {
    ribbonColor = amount >= 0 ? 'green' : 'red';
  }
  return (
    <Badge.Ribbon
      color={ribbonColor}
      text={parseFloat(amount.toFixed(5))}
      style={{right: '0px', top: '-8px'}}
    >
      <Tag
        style={{display: 'block', marginTop: '0.75em', position: 'relative'}}
        color={batchQueueItem.state === BatchQueueItemState.ACTIVE ? 'green' : ''}
      >
        {batchQueueItem.batch?.no || <EnDash/>}
      </Tag>
    </Badge.Ribbon>
  );
});

const BatchQueueItemActions = ({onAdd, onClear, hideAddButton, hideClearButton}) => {
  const {t} = useTranslation();

  return (
    <div style={{marginTop: '1em', marginBottom: '1em'}}>
      {
        !hideAddButton ? (
          <Button
            icon={<PlusOutlined/>}
            type={'primary'}
            onClick={onAdd}
            block
          >
            {t('batchQueueWidget.table.action.add')}
          </Button>
        ) : ''
      }
      {
        !hideClearButton ? (
          <Button
            icon={<ClearOutlined/>}
            onClick={onClear}
            style={{marginTop: '0.75em'}}
            block
            danger
          >
            {t('batchQueueWidget.table.action.reset')}
          </Button>
        ) : ''
      }
    </div>
  );
};

const BatchQueueTable = ({
  widgetConfig,
  onSetMaterialSensor,
  onClearMaterialSensor,
  setSetupModalVisible,
  setSetupData,
  setResetModalVisible,
  setResetData,
  ...props
}) => {
  const store = useStore();
  const {t} = useTranslation();

  const [componentSensors, setComponentSensors] = useState(new Map());

  const getAvailableSensors = () => {
    const sensors = store.sensorStore.getByHierarchyIdAndSensorType(
      store.workplaceStore.selectedWorkplace.hierarchyId,
      SensorType.COMPONENT_PREPARE
    );
    const components = store.operationStore.active?.components || [];
    // Filter for sensors that are not materialSensors for a component of the active operation.
    return sensors.filter((s) => !store.materialSensorStore.materialSensors.find(
      (ms) => ms.sensorName === s.name && components.find((c) => c.materialId === ms.materialId)
    ));
  };

  const getCurrentValue = (component) => {
    const materialSensors = store.materialSensorStore.getRelevantByHierarchyId(
      store.workplaceStore.selectedWorkplace.hierarchyId
    );
    return materialSensors.filter((ms) => ms.materialId === component.materialId)?.map((sensor) => sensor.sensorName);
  };

  useMount(() => {
    const components = store.operationStore.active?.components || [];
    const componentSensorData = new Map();
    components.forEach((c) => {
      const value = getCurrentValue(c);
      componentSensorData.set(c.id, {
        availableSensors: getAvailableSensors(c),
        value,
      });
    });
    setComponentSensors(componentSensorData);
  }, [
    store.componentStore.components.length,
    store.sensorStore.sensors.length,
    store.materialSensorStore.hasPendingRequests,
  ]);

  const clearMaterialSensor = (sensor) => () => {
    onClearMaterialSensor(sensor);
  };

  const clearQueue = (batchQueueItems, component) => () => {
    setResetData({batchQueueItems, component});
    setResetModalVisible(true);
  };

  const addToQueue = (component) => () => {
    setSetupModalVisible(true);
    setSetupData({...component});
  };

  const getBatchQueueItems = (materialId) => store.batchQueueItemStore
    .getByWorkplaceIdAndMaterialId(store.workplaceStore.selectedWorkplace.id, materialId)
    .filter((sqi) => sqi.state !== BatchQueueItemState.USED && sqi.state !== BatchQueueItemState.CANCELLED);

  const dataSource = uniqBy(store.operationStore.active?.components, (c) => c.materialId);

  const configColumns = widgetConfig.getSelectedProperties('settings', 'properties');

  const fixedColumns = [
    {
      title: t('batchQueueWidget.table.title.sensor'),
      key: 'sensor',
      dataIndex: 'id',
      render: (_, component) => (
        <Space direction="vertical" style={{width: '80%'}}>
          {componentSensors.get(component.id)?.value?.map((sensor) =>
            (
              <Select
                allowClear
                style={{width: '110%'}}
                onClear={clearMaterialSensor(sensor)}
                value={sensor}
                open={false}
                showArrow={false}
              />
            ))}
          <Select
            style={{width: '110%'}}
            onChange={onSetMaterialSensor(component)}
          >
            {componentSensors.get(component.id)?.availableSensors.map((s) => (
              <Select.Option key={s.name} value={s.name}>{s.label}</Select.Option>
            ))}
          </Select>
        </Space>
      ),
    },
    {
      title: t('batchQueueWidget.table.title.queue'),
      key: 'queue',
      dataIndex: 'id',
      render: (_, component) => {
        const batchQueueItems = getBatchQueueItems(component.materialId);
        return (
          <>
            {
              batchQueueItems.map((batchQueueItem) => (
                <BatchQueueItemTag key={batchQueueItem.id} batchQueueItem={batchQueueItem}/>
              ))
            }
            <BatchQueueItemActions
              onClear={clearQueue(batchQueueItems, component)}
              onAdd={addToQueue(component)}
              hideClearButton={batchQueueItems.length === 0}
            />
          </>
        );
      },
    },
  ];

  return (
    <Table
      rowKey="id"
      columns={[...configColumns, ...fixedColumns]}
      pagination={false}
      dataSource={dataSource}
      {...props}
    />
  );
};

export default observer(BatchQueueTable);
