import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import DocumentModal from './DocumentModal';
import DocumentByType from './DocumentByType';

const ShowDocument = ({
  document,
  onCancel,
}) => {
  const {t} = useTranslation();

  return (
    <DocumentModal
      title={t('document.show.title', {docLabel: document.label})}
      visible
      onCancel={onCancel}
      width="80%"
    >
      <div style={{height: '80vh'}}>
        <DocumentByType document={document}/>
      </div>
    </DocumentModal>
  );
};
export default observer(ShowDocument);
