import { computed, makeObservable, observable } from 'mobx';
import { BaseModel } from './base';
import { CustomPropertyTypes } from './customPropertyDataTypes';
import i18n from '../i18n/i18n';
import { transformation } from '../utils/transformations';
import { displayableProperty } from './displayableProperty';
import { displayablePropertyParam } from './displayablePropertyParam';
import { Material } from './material';
import { getTranslation } from '../utils/translations';
import { Translation } from './translations';
import { RootStore } from '../stores/rootStore';

const MAX_DECIMAL_PLACES = 3;

interface ComponentTranslation extends Translation {
  info: string | null
}

export class Component extends BaseModel {
  id: number = 0;
  no: string = '';
  position: number | null = null;
  operationId: number = 0;
  materialId: number = 0;
  unitOfMeasureId: number | null = null;
  plannedQuantity: number | null = null;
  actualQuantity: number | null = null;
  isRetrograde: boolean = false;
  isStockRequired: boolean = false;
  toleranceLow: number | null = null;
  toleranceHigh: number | null = null;
  preparationQuantity: number | null = null;
  preparedQuantity: number | null = null;
  translations: ComponentTranslation[] = [];
  productId: number | null = null;
  batchIds: number[] = [];
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      no: observable,
      info: computed,
      position: observable,
      operationId: observable,
      operation: computed,
      material: computed,
      materialId: observable,
      unitOfMeasure: computed,
      unitOfMeasureId: observable,
      plannedQuantity: observable,
      actualQuantity: observable,
      isRetrograde: observable,
      isStockRequired: observable,
      toleranceLow: observable,
      toleranceHigh: observable,
      preparationQuantity: observable,
      preparedQuantity: observable,
      translations: observable,
      productId: observable,
      product: computed,
      batchIds: observable,
      batches: computed,
      batchNos: computed,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  customPropertyType = CustomPropertyTypes.Component;

  translatedProperties = ['info'];

  displayableProperties = [
    displayableProperty({
      title: i18n.t('component.model.attributes.no'),
      params: [displayablePropertyParam({ path: 'no', transform: transformation.none })],
      template: '{value}',
      key: 'no',
    }),
    displayableProperty({
      title: i18n.t('component.model.attributes.quantity'),
      params: [
        displayablePropertyParam({
          path: 'plannedQuantity',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'plannedQuantity',
        }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{plannedQuantity} {unitOfMeasure}',
      key: 'plannedQuantity',
    }),
    displayableProperty({
      title: i18n.t('component.model.attributes.info'),
      params: [displayablePropertyParam({ path: 'info', transform: transformation.none })],
      template: '{value}',
      key: 'info',
    }),
    displayableProperty({
      title: i18n.t('component.model.attributes.actualQuantity'),
      params: [
        displayablePropertyParam({
          path: 'actualQuantity',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'actualQuantity',
        }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{actualQuantity} {unitOfMeasure}',
      key: 'actualQuantity',
    }),
    displayableProperty({
      title: i18n.t('component.model.attributes.unitOfMeasure'),
      params: [displayablePropertyParam({ path: 'unitOfMeasure.label', transform: transformation.none })],
      template: '{value}',
      key: 'unitOfMeasure',
    }),
    displayableProperty({
      key: 'quantityIsShould',
      title: i18n.t('component.model.attributes.quantityIsShould'),
      params: [
        displayablePropertyParam({
          path: 'actualQuantity',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'actualQuantity',
        }),
        displayablePropertyParam({
          path: 'plannedQuantity',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'plannedQuantity',
        }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{actualQuantity} / {plannedQuantity} {unitOfMeasure}',
    }),
    displayableProperty({
      title: i18n.t('component.model.attributes.isRetrograde'),
      params: [displayablePropertyParam({ path: 'isRetrograde', transform: transformation.booleanValue })],
      template: '{value}',
      key: 'isRetrograde',
      raw: true,
    }),
    displayableProperty({
      title: i18n.t('component.model.attributes.isStockRequired'),
      params: [displayablePropertyParam({ path: 'isStockRequired', transform: transformation.booleanValue })],
      template: '{value}',
      key: 'isStockRequired',
      raw: true,
    }),
    displayableProperty({
      key: 'toleranceLow',
      title: i18n.t('component.model.attributes.toleranceLow'),
      params: [
        displayablePropertyParam({
          path: '.',
          transform: (c) => {
            const minValue = c.toleranceLow;
            return `${minValue} ${c.unitOfMeasure?.label}` || '-';
          },
          as: 'toleranceLow',
        }),
      ],
      raw: true,
    }),
    displayableProperty({
      key: 'toleranceHigh',
      title: i18n.t('component.model.attributes.toleranceHigh'),
      params: [
        displayablePropertyParam({
          path: '.',
          transform: (c) => {
            const maxValue = c.toleranceHigh;
            return `${maxValue} ${c.unitOfMeasure?.label}` || '-';
          },
          as: 'toleranceHigh',
        }),
      ],
      raw: true,
    }),
    displayableProperty({
      title: i18n.t('component.model.attributes.preparationQuantity'),
      params: [
        displayablePropertyParam({
          path: 'preparationQuantity',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'preparationQuantity',
        }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{preparationQuantity} {unitOfMeasure}',
      key: 'preparationQuantity',
    }),
    displayableProperty({
      title: i18n.t('component.model.attributes.batches'),
      params: [displayablePropertyParam({ path: 'batchNos' })],
      template: '{value}',
      key: 'batchNos',
    }),
  ];

  get operation() {
    return this.rootStore.operationStore.getById(this.operationId);
  }

  get material() {
    return this.rootStore.materialStore.getById(this.materialId);
  }

  get unitOfMeasure() {
    return this.rootStore.unitOfMeasurementStore.getById(this.unitOfMeasureId);
  }

  get product() {
    return this.rootStore.productStore.getById(this.productId);
  }

  get batches() {
    return this.rootStore.batchStore.getByIds(this.batchIds);
  }

  get batchNos() {
    return this.batches.map((batch) => batch.no).filter((batch) => !!batch).join(', ');
  }

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);
    allDisplayableProperties.push(
      ...this.displayableCustomProperties(rootStore, pathPrefix, titlePrefix)
    );

    allDisplayableProperties.push(
      ...Material.allDisplayableProperties(rootStore, `${pathPrefix}material.`, titlePrefix)
    );

    return allDisplayableProperties;
  }

  get info() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.info;
  }
}
