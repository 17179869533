import { computed, observable, makeObservable } from 'mobx';
import { faBallotCheck } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import { getTranslation } from '../utils/translations';
import { CustomPropertyTypes } from './customPropertyDataTypes';
import { CustomPropertiesObject } from './customProperty';
import { Translation } from './translations';
import { RootStore } from '../stores/rootStore';

interface WorkplaceStateTranslation extends Translation {
  label: string | null;
  description: string | null;
}

export class WorkplaceState extends BaseModel {
  id: number = 0;
  name: string = '';
  isDefault: boolean = false;
  isAutomatic: boolean = false;
  isDelayedReporting: boolean = false;
  isInterruption: boolean = false;
  isOneWay: boolean = false;
  isShownInHistory: boolean = false;
  color: string | null = null;
  inspectionTaskPhaseId: number | null = null;
  sortOrder: number | null = null;
  properties?: CustomPropertiesObject = undefined;
  translations: WorkplaceStateTranslation[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      label: computed,
      description: computed,
      isDefault: observable,
      isAutomatic: observable,
      isDelayedReporting: observable,
      isInterruption: observable,
      isOneWay: observable,
      isShownInHistory: observable,
      color: observable,
      inspectionTaskPhaseId: observable,
      inspectionTaskPhase: computed,
      sortOrder: observable,
      properties: observable,
      translations: observable,
    });
  }

  static faIcon = faBallotCheck;

  searchableProperties = ['name'];

  translatedProperties = ['description', 'label'];

  customPropertyType = CustomPropertyTypes.WorkplaceState;

  displayableProperties = [
    displayableProperty({
      key: 'name',
      title: i18n.t('operationState.model.attributes.name.label'),
      params: [displayablePropertyParam({ path: 'name' })],
    }),
    displayableProperty({
      key: 'label',
      title: i18n.t('operationState.model.attributes.label.label'),
      params: [displayablePropertyParam({ path: 'label' })],
    }),
    displayableProperty({
      key: 'description',
      title: i18n.t('operationState.model.attributes.description'),
      params: [displayablePropertyParam({ path: 'description' })],
    }),
    displayableProperty({
      key: 'color',
      title: i18n.t('operationState.model.attributes.color'),
      raw: true,
      params: [displayablePropertyParam({ path: 'color', transform: transformation.color })],
    }),
  ];

  get inspectionTaskPhase() {
    return this.rootStore.phaseStore.getById(this.inspectionTaskPhaseId);
  }

  static prepareApiPayload(model: WorkplaceState) {
    return {
      id: model.id,
      name: model.name,
      isDefault: Boolean(model.isDefault),
      isAutomatic: Boolean(model.isAutomatic),
      isDelayedReporting: Boolean(model.isDelayedReporting),
      isInterruption: Boolean(model.isInterruption),
      isOneWay: Boolean(model.isOneWay),
      isShownInHistory: Boolean(model.isShownInHistory),
      sortOrder: model.sortOrder || null,
      color: model.color,
      inspectionTaskPhaseId: model.inspectionTaskPhaseId || null,
      properties: model.properties,
      translations: WorkplaceState.convertToSavableTranslations(model),
    };
  }

  get description() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.description;
  }

  get label() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.label;
  }

  getActiveTimeWorkplaceState(workplaceId: number) {
    const workplaceStateLog = this.rootStore.workplaceStateLogStore.getLatestOfWorkplace(workplaceId);
    return workplaceStateLog?.start ? dayjs().diff(dayjs(workplaceStateLog.start)) : 0;
  }
}
