import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import minMax from 'dayjs/plugin/minMax';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import pluralGetSet from 'dayjs/plugin/pluralGetSet';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/pl';

dayjs.extend(minMax);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(pluralGetSet);
dayjs.extend(utc);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(relativeTime);

export const monthFormat = 'MMM YYYY';
export const dateFormat = 'DD.MM.YYYY';
export const readableDateFormat = 'ddd DD. MMM';
export const isoDateFormat = 'YYYY-MM-DD';
export const datetimeFormat = 'DD.MM.YYYY, HH:mm';
export const detailedDatetimeFormat = 'DD.MM.YYYY, HH:mm:ss';
export const timeFormat = 'HH:mm';
export const detailedTimeFormat = 'HH:mm:ss';
export const daysDurationFormat = 'DD[d] HH[h]';
export const durationFormat = 'HH[h] mm[m]';
export const minutesDurationFormat = 'mm[m] ss[s]';
export const secondsDurationFormat = 'ss[s] SSS[ms]';
export const durationFormatWithDays = 'D[d] HH[h] mm[m]';
export const detailedDurationFormat = 'HH[h] mm[m] ss[s]';
export const messageValidTimeFormat = 'D MMMM YYYY, HH:mm';

dayjs.locale('de');
dayjs.defaultFormat = datetimeFormat;
