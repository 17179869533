import { action, makeObservable, observable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { PrintLabel } from '../models/printLabel';
import { reloadLabelsEndpoint } from '../middleware/endpoints/printLabels';
import { RootStore } from './rootStore';

export class PrintLabelStore extends EntityStore<PrintLabel> {
  printLabels: PrintLabel[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'printLabels', Api.printLabels, PrintLabel, true);

    makeObservable(this, {
      printLabels: observable,
      reloadLabels: action,
    });
  }

  reloadLabels() {
    this.addPendingAction(reloadLabelsEndpoint);
    return this.api.reload().finally(() => {
      this.removePendingAction(reloadLabelsEndpoint);
      return this.loadAll();
    });
  }
}
