import { makeObservable, observable } from 'mobx';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { ConfigTransferFile } from '../models/configTransferFile';
import { RootStore } from './rootStore';

export const DIRECTION = {
  IMPORT: 'IMPORT',
  EXPORT: 'EXPORT',
};

export class ConfigTransferFileStore extends EntityStore<ConfigTransferFile> {
  configTransferFiles: ConfigTransferFile[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'configTransferFiles', Api.configTransferFiles, ConfigTransferFile);

    makeObservable(this, {
      configTransferFiles: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.userStore,
        modelId: 'createdBy',
      },
    ];
  }
}
