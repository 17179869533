import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs, { Dayjs } from 'dayjs';
import { useMount } from '../../hooks/useMount';
import { WidgetLayout } from '../../models/widgetLayout';
import OperatorWidget from '../operator/shared/OperatorWidget';
import { WidgetLayouts } from '../shared/widgets/WidgetLayouts';
import EditButton from '../shared/buttons/EditButton';
import ReadButton from '../shared/buttons/ReadButton';
import { QualityReportWidgetConfig } from './qualityReportWidgetConfig';
import { useStore } from '../../hooks/useStore';
import { ScopeContext } from '../../policies/scopeContext';
import { collaboration } from '../../models/scope';
import { useModulePolicy } from '../../hooks/useModulePolicy';
import QualityReportTableModal from './QualityReportTableModal';
import NewQualityReportModal from './NewQualityReportModal';
import { Scopes } from '../../models/scopes';
import appConfig from '../../utils/appConfig';
import { QualityReport } from '../../models/qualityReport';
import { MODULE_NAME_PREFIX, ModuleNames } from '../moduleConfiguration/forms/ModuleConfigurationFormHandler';
import { WidgetImplProps } from '../shared/widgets/Widget';
import { WidgetTypes } from '../../types/widget';

export type QualityReportWidgetProps = {
  disabled?: boolean;
  minimized?: boolean;
  identifier: string;
};

const QualityReportWidget: React.FC<QualityReportWidgetProps> & WidgetImplProps = ({
  disabled,
  minimized = false,
  identifier,
}) => {
  const { t } = useTranslation();
  const store = useStore();
  const [newModalVisible, setNewModalVisible] = useState(false);
  const [tableModalVisible, setTableModalVisible] = useState(false);
  const [tableModalOpen, setTableModalOpen] = useState(false);
  const widgetConfig = useMemo(() =>
    new QualityReportWidgetConfig(store, identifier), [store.languageStore.lang, store.roleStore.roles.length]);
  const policy = useModulePolicy(store, [collaboration]);
  const [coordinatorRole, setCoordinatorRole] = useState<number | undefined>(undefined);

  const initialFromDate = () => dayjs().subtract(widgetConfig.getQueryTimeSpan(), 'hours');

  const queryData = (from: Dayjs | null, to: Dayjs | null) => {
    if (store.workplaceStore.selectedWorkplace && from !== null && to !== null) {
      const activeOrderOnly = widgetConfig.getActiveOrderOnly();
      store.qualityReportStore.loadAllWithDependencies({
        params: {
          workplaceId: store.workplaceStore.selectedWorkplace.id,
          orderId: activeOrderOnly && store.operationStore.active ? store.operationStore.active.orderId : undefined,
          fromDate: from.toISOString(),
          toDate: to.toISOString(),
        },
      });
    }
  };

  useMount(() => {
    const fromDate = initialFromDate();
    if (!fromDate || !store.workplaceStore.selectedWorkplace?.id
      || !store.settingStore.operatorSettingsReady) {
      return;
    }
    queryData(fromDate, dayjs());
  }, [
    store.workplaceStore.selectedWorkplace?.id,
    store.operationStore.active?.id,
    store.settingStore.operatorSettingsReady,
  ]);

  useMount(() => {
    const setting = store.settingStore.getByIdentifier(`${MODULE_NAME_PREFIX}${ModuleNames.QUALITY_REPORT}`);

    setCoordinatorRole((setting?.value as { coordinatorRole: number, supplier: string })?.coordinatorRole);
  }, [store.settingStore.operatorSettingsReady]);

  useMount(() => {
    if (!coordinatorRole) {
      return;
    }

    store.userRoleStore.loadAllWithDependencies({
      dependencies: [{
        store: store.userStore,
        modelId: 'userId',
      }],
      params: {
        roleId: coordinatorRole,
      },
    });
  }, [coordinatorRole]);

  useMount(() => {
    const newCount = store.qualityReportStore
      .bySelectedWorkplace
      .filter((qr) =>
        dayjs(qr.createdAt) >= initialFromDate() && dayjs(qr.createdAt) <= dayjs()).length;
    store.terminalLayoutStore.setBadgeCounter(QualityReportWidget.identifier, newCount);
  }, [store.qualityReportStore.bySelectedWorkplace.length]);

  return appConfig.modules.enableQualityReport && (
    <ScopeContext.Provider value={[Scopes.qualityReport]}>
      <OperatorWidget
        onHeadClick={() => setTableModalVisible(true)}
        icon={<FontAwesomeIcon icon={QualityReport.faIcon}/>}
        title={widgetConfig.getWidgetTitle()}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        manualPath={'/quality-management/quality-report'}
      >
        <ReadButton
          type={'primary'}
          size={'large'}
          message={t<string>('qualityReport.widget.readMessagesButton.label', {
            reports: store.terminalLayoutStore.badgeCounters.get(QualityReportWidget.identifier),
          })}
          style={{ width: '100%', marginBottom: '12px' }}
          onClick={() => setTableModalVisible(true)}
        />
        <br/>
        <EditButton
          type={'default'}
          size={'large'}
          message={t<string>('qualityReport.widget.newMessageButton.label')}
          style={{ width: '100%' }}
          onClick={() => setNewModalVisible(true)}
          // TODO: fix this
          // @ts-ignore
          disabled={!policy.canEdit({ hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId })}
        />
      </OperatorWidget>

      {tableModalVisible
        && (
          <QualityReportTableModal
            models={store.qualityReportStore.bySelectedWorkplace}
            widgetConfig={widgetConfig}
            queryData={queryData}
            settings={widgetConfig.getValues()}
            onCancel={() => {
              setTableModalVisible(false);
              setTableModalOpen(false);
            }}
            onAdd={() => {
              setTableModalOpen(true);
              setTableModalVisible(false);
              setNewModalVisible(true);
            }}
          />
        )}

      {newModalVisible
        && (
          <NewQualityReportModal
            open={newModalVisible}
            widgetConfig={widgetConfig}
            coordinatorRole={coordinatorRole}
            onCancel={() => {
              setNewModalVisible(false);
              if (tableModalOpen) {
                setTableModalVisible(true);
              }
            }}
            onSuccess={() => {
              setNewModalVisible(false);
              if (tableModalOpen) {
                setTableModalVisible(true);
              }
            }}
          />
        )}
    </ScopeContext.Provider>
  );
};

QualityReportWidget.icon = QualityReport.faIcon;
QualityReportWidget.identifier = WidgetTypes.QualityReport;
QualityReportWidget.defaultLayout = new WidgetLayout({
  identifier: QualityReportWidget.identifier,
  x: 2,
  y: 4,
  height: 3,
  minHeight: 3,
  width: WidgetLayouts.sixthWidth.w,
  minWidth: WidgetLayouts.sixthWidth.minW,
});
export default observer(QualityReportWidget);
