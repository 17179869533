import { ConfigSetting, FieldType, TabDefinition, WidgetConfigExtension } from '../../../types/widgetConfig';
import i18n from '../../../i18n/i18n';
import { RootStore } from '../../../stores/rootStore';

const TAB_KEY = 'extWidgetActionConfig';

export class WidgetActionExtension implements WidgetConfigExtension {
  constructor(private rootStore: RootStore) {
  }

  // eslint-disable-next-line class-methods-use-this
  getTabs(): TabDefinition[] {
    return [{
      key: TAB_KEY,
      label: i18n.t('widgetAction.config.tabTitle'),
    }];
  }

  getSettings(tab: string): ConfigSetting[] {
    switch (tab) {
      case TAB_KEY:
        return [{
          id: 'widgetActions',
          type: FieldType.SelectMultiple,
          label: i18n.t<string>('widgetAction.model.other'),
          options: this.rootStore.widgetActionStore.widgetActions
            .map((action) => ({
              value: action.id,
              label: action.label,
            })),
        }];
      default:
        return [];
    }
  }
}
