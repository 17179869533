/**
 * speed denominator translations
 * t('speed.denominator.SECONDS')
 * t('speed.denominator.MINUTES')
 * t('speed.denominator.HOURS')
 * t('speed.denominator.DAYS')
 */
export enum SpeedDenominator {
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS'
}

export const SpeedDenominatorFactor = {
  [SpeedDenominator.SECONDS]: 1000,
  [SpeedDenominator.MINUTES]: 60000,
  [SpeedDenominator.HOURS]: 3600000,
  [SpeedDenominator.DAYS]: 144000000,
};

export const SpeedDenominatorUnit = {
  [SpeedDenominator.SECONDS]: 's',
  [SpeedDenominator.MINUTES]: 'min',
  [SpeedDenominator.HOURS]: 'h',
  [SpeedDenominator.DAYS]: 'd',
};
