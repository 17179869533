import {createEntity, executeDelete, executeGet, executePut, updateEntity} from '../requests';

const resourcesName = 'v1/external-resource-logs';

export const bulkUpdate = 'bulkUpdate';

export const ExternalResourceLogsApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...entity}) => updateEntity(entity, `${resourcesName}/${id}`),
  [bulkUpdate]: (externalResourceLogs) => executePut(resourcesName, externalResourceLogs),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
};
