import { observable, makeObservable, computed } from 'mobx';
import { BaseModel } from './base';
import i18n from '../i18n/i18n';
import { displayableProperty } from './displayableProperty';
import { displayablePropertyParam } from './displayablePropertyParam';
import { RootStore } from '../stores/rootStore';

export enum CockpitWidgetType {
  DATA_TABLE = 'data-table',
  EVENT_DURATION = 'event-duration',
  IMAGE = 'image',
  INTERRUPTION_ANALYSIS = 'interruption-analysis',
  KPI_CHART = 'kpi-chart',
  KPI_SPEEDOMETER = 'kpi-speedometer',
  KPI_LOSS = 'kpi-loss',
  KPI_PER_ORDER = 'kpi-per-order',
  SENSOR_STATUS = 'sensor-status-display',
  TAG_INTERRUPTION_ANALYSIS = 'tag-interruption-analysis',
  TIMESERIES_CHART = 'timeseries-chart',
  MULTIPLE_TIMESERIES_CHART = 'multiple-timeseries-chart',
  ORDER_PROGRESS = 'order-progress'
}

export class CockpitWidget extends BaseModel {
  id: number = 0;
  name: string = '';
  dashboardId: number = 0;
  config: string = '';
  layout: string = '';
  widgetTypeIdentifier: CockpitWidgetType = CockpitWidgetType.IMAGE;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      dashboardId: observable,
      dashboard: computed,
      config: observable,
      layout: observable,
      widgetTypeIdentifier: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  searchableProperties = ['name', 'config', 'layout', 'widgetTypeIdentifier'];

  displayableProperties = [
    displayableProperty({
      key: 'name',
      title: i18n.t('cockpit.model.attributes.name'),
      params: [displayablePropertyParam({ path: 'name' })],
    }),
    displayableProperty({
      key: 'config',
      title: i18n.t('cockpit.model.attributes.config'),
      params: [displayablePropertyParam({
        path: 'config',
      })],
    }),
    displayableProperty({
      key: 'layout',
      title: i18n.t('cockpit.model.attributes.layout'),
      params: [displayablePropertyParam({
        path: 'layout',
      })],
    }),
    displayableProperty({
      key: 'widgetTypeIdentifier',
      title: i18n.t('cockpit.model.attributes.widgetTypeIdentifier'),
      params: [displayablePropertyParam({
        path: 'widgetTypeIdentifier',
      })],
    }),
  ];

  static prepareApiPayload(model: Partial<CockpitWidget>): Partial<CockpitWidget> {
    return {
      id: model.id,
      name: model.name,
      dashboardId: model.dashboardId,
      config: model.config,
      layout: model.layout,
      widgetTypeIdentifier: model.widgetTypeIdentifier,
    };
  }

  get dashboard() {
    return this.rootStore.cockpitDashboardStore.getById(this.dashboardId);
  }
}
