import {createEntity, executeDelete, executeGet, executePost, updateEntity} from '../requests';

const resourcesName = 'v1/print-jobs';

export const calculateEndpoint = 'calculate';
export const startEndpoint = 'start';
export const abortEndpoint = 'abort';
export const printDataEndpoint = 'print-data';

export const PrintJobsApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...values}) => updateEntity(values, `${resourcesName}/${id}`),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
  [calculateEndpoint]: (operationId) => executePost(`${resourcesName}/${calculateEndpoint}?operationId=${operationId}`),
  [startEndpoint]: (printJobIds) => executePost(`${resourcesName}/${startEndpoint}`, printJobIds),
  [abortEndpoint]: (printJobIds) => executePost(`${resourcesName}/${abortEndpoint}`, printJobIds),
  [printDataEndpoint]: (id) => executeGet(`${resourcesName}/${id}/${printDataEndpoint}`),
};
