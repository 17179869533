import { observable, makeObservable, computed } from 'mobx';
import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

// TODO: rename to UnitOfMeasureConversion (use same name in backend)
export class UnitOfMeasurementConversion extends BaseModel {
  id: number = 0;
  orderId: number = 0;
  sourceUnitOfMeasureId: number = 0;
  destinationUnitOfMeasureId: number = 0;
  numerator: number = 0;
  denominator: number = 0;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      orderId: observable,
      sourceUnitOfMeasureId: observable,
      sourceUnitOfMeasure: computed,
      destinationUnitOfMeasureId: observable,
      destinationUnitOfMeasure: computed,
      numerator: observable,
      denominator: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faCalculator;

  get sourceUnitOfMeasure() {
    return this.rootStore.unitOfMeasurementStore.getById(this.sourceUnitOfMeasureId);
  }

  get destinationUnitOfMeasure() {
    return this.rootStore.unitOfMeasurementStore.getById(this.destinationUnitOfMeasureId);
  }
}
