import { action, observable, makeObservable } from 'mobx';
import { EntityStore } from './entityStore';
import { Hierarchy } from '../models/hierarchy';
import { RootStore } from './rootStore';

export class HierarchyNavigationStore extends EntityStore {
  dummy = [];
  selectedHierarchy?: number = undefined;
  openKeys: string[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'dummy', null, Hierarchy);

    makeObservable(this, {
      dummy: observable,
      selectedHierarchy: observable,
      openKeys: observable,
      setOpenKeys: action,
    });
  }

  setOpenKeys(newOpenKeys: string[]) {
    this.openKeys = newOpenKeys;
  }
}
