import { makeObservable, observable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { WorkplaceStorageRelation } from '../models/workplaceStorageRelation';
import { RootStore } from './rootStore';

export class WorkplaceStorageRelationStore extends EntityStore<WorkplaceStorageRelation> {
  workplaceStorageRelations: WorkplaceStorageRelation[] = [];

  constructor(rootStore: RootStore) {
    super(
      rootStore,
      'workplaceStorageRelations',
      Api.workplaceStorageRelations,
      WorkplaceStorageRelation,
      false
    );

    makeObservable(this, {
      workplaceStorageRelations: observable,
    });
  }

  loadByWorkplaceId(workplaceId: number) {
    return this.loadAll({ params: { workplaceId } });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.workplaceStore,
        modelId: 'workplaceId',
      },
      {
        store: this.rootStore.storageUnitStore,
        modelId: 'sourceStorageUnitIds',
      },
      {
        store: this.rootStore.storageUnitStore,
        modelId: 'internalStorageUnitIds',
      },
      {
        store: this.rootStore.storageUnitStore,
        modelId: 'destinationStorageUnitIds',
      },
      {
        store: this.rootStore.storageAreaStore,
        modelId: 'sourceStorageAreaIds',
      },
      {
        store: this.rootStore.storageAreaStore,
        modelId: 'internalStorageAreaIds',
      },
      {
        store: this.rootStore.storageAreaStore,
        modelId: 'destinationStorageAreaIds',
      },
    ];
  }

  getFiltered(
    workplaceIds: number[],
    sourceStorageUnitIds: number[],
    internalStorageUnitIds: number[],
    destinationStorageUnitIds: number[],
    sourceStorageAreaIds: number[],
    internalStorageAreaIds: number[],
    destinationStorageAreaIds: number[]
  ) {
    return this.workplaceStorageRelations.filter(
      (workplaceStorageRelation) => (
        (!workplaceIds.length || workplaceIds.includes(workplaceStorageRelation.workplaceId))
        && (!sourceStorageUnitIds.length
          || sourceStorageUnitIds.some((item) => workplaceStorageRelation.sourceStorageUnitIds.includes(item)))
        && (!internalStorageUnitIds.length
          || internalStorageUnitIds.some((item) => workplaceStorageRelation.internalStorageUnitIds.includes(item)))
        && (!destinationStorageUnitIds.length
          || destinationStorageUnitIds.some((item) =>
            workplaceStorageRelation.destinationStorageUnitIds.includes(item)))
        && (!sourceStorageAreaIds.length
          || sourceStorageAreaIds.some((item) => workplaceStorageRelation.sourceStorageAreaIds.includes(item)))
        && (!internalStorageAreaIds.length
          || internalStorageAreaIds.some((item) => workplaceStorageRelation.internalStorageAreaIds.includes(item)))
        && (!destinationStorageAreaIds.length
          || destinationStorageAreaIds.some((item) =>
            workplaceStorageRelation.destinationStorageAreaIds.includes(item)))
      )
    );
  }

  getByWorkplaceIds(workplaceIds: number[]) {
    return this.workplaceStorageRelations.filter(
      (workplaceStorageRelation) => (
        (!workplaceIds.length || workplaceIds.includes(workplaceStorageRelation.workplaceId))
      )
    );
  }

  getByWorkplaceId(workplaceId?: number) {
    return this.workplaceStorageRelations.find(
      (workplaceStorageRelation) => (
        workplaceId === workplaceStorageRelation.workplaceId
      )
    );
  }
}
