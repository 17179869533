import React, { ReactNode } from 'react';
import HelpTooltip, { HelpTooltipProps } from './HelpTooltip';

export type HelpTooltipFormLabelProps = Omit<HelpTooltipProps, 'title'> & {
  // Keep this as string, as it is possible that this has to be rendered as markdown and therefore must be a string
  help: string;
  label: ReactNode;
  labelClassName?: string;
};

const HelpTooltipFormLabel: React.FC<HelpTooltipFormLabelProps> = ({
  label,
  help,
  markdown,
  labelClassName,
  ...props
}) => (
  <span>
    <span className={labelClassName}>{label}</span>
    <HelpTooltip title={help} markdown={markdown} {...props}/>
  </span>
);

export default HelpTooltipFormLabel;
