import { observable, makeObservable } from 'mobx';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { RootStore } from '../stores/rootStore';

export class PrintLabel extends BaseModel {
  id: number = 0;
  name: string = '';
  path: string = '';

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      path: observable,
    });
  }

  static faIcon = faTag;

  searchableProperties = [
    'name',
    'path',
  ];

  saveableProperties = ['name', 'path'];

  displayableProperties = [
    displayableProperty({
      key: 'name',
      title: i18n.t('printLabel.model.attributes.name'),
      params: [displayablePropertyParam({ path: 'name' })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'path',
      title: i18n.t('printLabel.model.attributes.path'),
      params: [displayablePropertyParam({ path: 'path' })],
      template: '{value}',
    }),
  ];
}
