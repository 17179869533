import React from 'react';
import { observer } from 'mobx-react-lite';
import { Key, TableRowSelection } from 'antd/es/table/interface';
import Table from '../../shared/tables/Table';
import { useStore } from '../../../hooks/useStore';
import { StorageUnit } from '../../../models/storageUnit';
import { DosageWidgetConfig } from './dosageWidgetConfig';
import { sortAlphabetically } from '../../shared/tables/sorters';

export type DosageStorageUnitChildrenTableProps = {
  parentStorageUnitIds: number[];
  getSelection: (selectedRowKeys: Key[], selectedRows: StorageUnit[]) => void;
  widgetConfig: DosageWidgetConfig;
};

const DosageStorageUnitChildrenTable: React.FC<DosageStorageUnitChildrenTableProps> = ({
  parentStorageUnitIds,
  getSelection,
  widgetConfig,
}) => {
  const store = useStore();

  const columns = widgetConfig.getSelectedProperties('settings', 'property_childStorageUnitColumns');

  const handleSelectionChange = (selectedRowKeys: React.Key[], selectedRows: StorageUnit[]) => {
    getSelection(selectedRowKeys, selectedRows);
  };

  const rowSelection: TableRowSelection<StorageUnit> = {
    type: 'checkbox',
    onChange: handleSelectionChange,
    getCheckboxProps: () => ({
      disabled: store.dosageStore.isInPreparation,
    }),
    hideSelectAll: true,
  };

  const dataSource = store.storageUnitStore.getDescendantsByIds(parentStorageUnitIds)
    .filter((storageUnit) => storageUnit.contents.length > 0)
    .sort((a, b) => sortAlphabetically(a.no, b.no));

  return (
    <Table<StorageUnit>
      data-cy={'DosageStorageUnitChildrenTable-Table'}
      rowSelection={rowSelection}
      rowKey={'id'}
      columns={columns}
      dataSource={dataSource}
      loading={store.storageUnitStore.isLoadingCollection || store.storageUnitStore.isMutationInProgress}
    />
  );
};

export default observer(DosageStorageUnitChildrenTable);
