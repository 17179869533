import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import InspectionTaskDescription from './InspectionTaskDescription';
import Button from '../../../shared/buttons/Button';

const QualitativeSettingDescription = ({canEdit, task, setQualitativeTargetValueModalVisible}) => {
  const {t} = useTranslation();

  return (
    <>
      <InspectionTaskDescription>
        <InspectionTaskDescription.Item
          label={t('inspectionTaskQualitative.model.attributes.targetValue.label')}
        >
          {task.qualitative.targetValue}
        </InspectionTaskDescription.Item>
      </InspectionTaskDescription>
      <Button
        disabled={!canEdit}
        onClick={() => setQualitativeTargetValueModalVisible(true)}
        style={{marginBottom: 16}}
      >
        {t('inspectionTask.actions.editTargetValues')}
      </Button>
    </>
  );
};

export default observer(QualitativeSettingDescription);
