import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';
import { Form, Input, Radio, Space } from 'antd';
import FormModal from '../../modal/FormModal';
import OrderSelector from '../autocomplete/OrderSelector';
import InspectionLotSelector from '../autocomplete/InspectionLotSelector';
import SearchButton from '../../buttons/SearchButton';
import CancelButton from '../../buttons/CancelButton';
import Button from '../../buttons/Button';
import { useStore } from '../../../../hooks/useStore';
import { WorkplaceSearchResponse } from '../../../../models/workplace';

export type HierarchyTreeAdvancedSearchProps = {
  open: boolean;
  onSubmit: (hierarchyId?: number | undefined) => void;
  onCancel: (open?: boolean) => void;
};

export type SearchValues = {
  q: string | undefined;
  orderId: number | undefined;
  inspectionLotId: number | undefined;
};

const HierarchyTreeAdvancedSearch: React.FC<HierarchyTreeAdvancedSearchProps> = ({ open, onSubmit, onCancel }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const store = useStore();
  const [searchActive, setSearchActive] = useState(false);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [data, setData] = useState<WorkplaceSearchResponse[]>([]);
  const [selectedData, setSelectedData] = useState<number | undefined>(undefined);
  const [applyActive, setApplyActive] = useState(false);
  const [advancedSearchIsSearching, setAdvancedSearchIsSearching] = useState(false);

  const onChanged = (_: any, allValues: SearchValues) => {
    setSearchActive(Object.values(allValues).some((v) => v));
    setResultsVisible(false);
  };

  const handleCancel = () => {
    setSelectedData(undefined);
    setData([]);
    setApplyActive(false);
    setResultsVisible(false);
    setAdvancedSearchIsSearching(false);
    setSearchActive(false);
    onCancel(false);
  };

  const onSearch = (values: SearchValues) => {
    setAdvancedSearchIsSearching(true);
    store.workplaceStore.advancedSearch({ params: {
      q: values.q,
      orderId: values.orderId,
      inspectionLotId: values.inspectionLotId,
    } }).then((response: WorkplaceSearchResponse[]) => {
      setData(response);
      if (response.length === 1) {
        setSelectedData(response[0].id);
      }
      setApplyActive(response.length === 1);
    }).finally(() => {
      setResultsVisible(true);
      setAdvancedSearchIsSearching(false);
    });
  };

  const changeAdvancedSearchSelection = (val: number | undefined) => {
    setSelectedData(val);
    setApplyActive(true);
  };

  const submit = () => {
    const selectedHierarchy = store.workplaceStore.getById(selectedData || null);
    onSubmit(selectedHierarchy?.hierarchy?.id);
  };

  return (
    <FormModal
      title={(
        <>
          <FontAwesomeIcon icon={faSearchPlus}/>
          {' '}
          {t('hierarchyTree.advancedSearch.title')}
        </>
      )}
      open={open}
      width={'40rem'}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout={'vertical'}
        labelAlign={'left'}
        onValuesChange={onChanged}
        onFinish={onSearch}
      >
        <Form.Item name={'q'} label={t('hierarchyTree.advancedSearch.parameters.q')} style={{ width: '25rem' }}>
          <Input autoFocus/>
        </Form.Item>
        <Form.Item
          name={'orderId'}
          label={t('hierarchyTree.advancedSearch.parameters.orderId')}
          style={{ width: '25rem' }}
        >
          <OrderSelector
            form={form}
            name={'orderId'}
          />
        </Form.Item>
        <Form.Item
          name={'inspectionLotId'}
          label={t('hierarchyTree.advancedSearch.parameters.inspectionLotId')}
          style={{ width: '25rem' }}
        >
          <InspectionLotSelector
            placeholder={t<string>('inspectionLot.model.one')}
            autoFocus={false}
          />
        </Form.Item>
        <div style={{ textAlign: 'right' }}>
          <SearchButton
            disabled={!searchActive}
            style={{ textAlign: 'right' }}
            loading={advancedSearchIsSearching}
          />
        </div>
      </Form>
      {resultsVisible
        && (
          <Form>
            <br/>
            <hr style={{ color: '#E5E4E2' }}/>
            <br/>
            {data.length === 0
              && (
                <div>{t('hierarchyTree.advancedSearch.noDataFound')}</div>
              )}
            {data.length > 0
              && (
                <Radio.Group
                  onChange={(e) => {
                    changeAdvancedSearchSelection(e.target.value);
                  }}
                  defaultValue={(data.length === 1) ? data[0].id : null}
                >
                  <Space direction="vertical">
                    {
                      data.map((wp) =>
                        (
                          <Radio key={wp.id} value={wp.id}>
                            {`${wp.name} (${wp.no})`}
                          </Radio>
                        ))
                    }
                  </Space>
                </Radio.Group>
              )}
            <br/>
            <div style={{ textAlign: 'right' }}>
              <CancelButton
                style={{ marginRight: '10px' }}
                onClick={() => {
                  handleCancel();
                }}
              />
              <Button
                disabled={!applyActive}
                type={'primary'}
                onClick={() => {
                  submit();
                }}
              >
                {t('button.apply')}
              </Button>
            </div>
          </Form>
        )}
    </FormModal>
  );
};

export default HierarchyTreeAdvancedSearch;
