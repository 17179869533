import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Input, { InputProps } from './Input';
import Modal from '../modal/Modal';
import { useMount } from '../../../hooks/useMount';
import appConfig from '../../../utils/appConfig';
import { useStore } from '../../../hooks/useStore';
import { FormItemLayouts } from '../form/formItemLayouts';
import { Language } from '../../../models/translations';
import Form from '../form/Form';

type MultiValue = {
  [key in Language]?: string;
};

export type TranslatableInputProps = Omit<InputProps, 'onChange' | 'value'> & {
  useTextArea?: boolean;
  translatedLabel: React.ReactNode;
  value?: MultiValue;
  dataCyPrefix?: string;
  onChange?: (value: MultiValue) => void;
};

const MultiTranslatableInput: React.FC<TranslatableInputProps> = ({
  value = {},
  useTextArea = false,
  onChange,
  translatedLabel,
  dataCyPrefix,
  ...props
}) => {
  const { t } = useTranslation();
  const store = useStore();
  const [currentValue, setCurrentValue] = useState(value);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const userLanguage = store.languageStore.lang;

  useMount(() => {
    setCurrentValue(value);
  }, [value]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    language: Language
  ) => {
    const valueClone: MultiValue = { ...currentValue };
    valueClone[language] = event.target.value;
    setCurrentValue(valueClone);
  };

  const handleOpen = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    if (onChange) {
      onChange(currentValue);
    }
  };

  const handleCancel = () => {
    setCurrentValue(value);
    setIsModalVisible(false);
  };

  const InputComponent = useTextArea ? Input.TextArea : Input;

  const addon = {
    addonAfter: (
      <div
        onClick={handleOpen}
        role={'button'}
        tabIndex={0}
        data-cy={`${dataCyPrefix ? `${dataCyPrefix}-` : ''}MultiTranslatableInput-OpenTranslationButton`}
      >
        <FontAwesomeIcon icon={faLanguage}/>
      </div>
    ),
  };

  if (useTextArea) {
    // @ts-ignore
    delete addon.addonAfter;
  }

  return (
    <>
      {/* FIXME: Rest-Props need to be clean up, so that they can be used for inputs and text areas. */}
      {/* @ts-ignore */}
      <InputComponent
        {...props}
        {...addon}
        onClick={handleOpen}
        value={
          currentValue[userLanguage]
          || currentValue[appConfig.language as Language]
          || Object.values(currentValue)[0]
          || ''
        }
        readOnly
      />
      <Modal
        open={isModalVisible}
        title={t('input.translatable.title', { label: translatedLabel })}
        onOk={handleOk}
        onCancel={handleCancel}
        data-cy={`${dataCyPrefix ? `${dataCyPrefix}-` : ''}MultiTranslatableInput-Modal`}
        okButtonProps={{
          'data-cy': `${dataCyPrefix ? `${dataCyPrefix}-` : ''}MultiTranslatableInput-Modal-OkButton`,
        }}
        cancelButtonProps={{
          'data-cy': `${dataCyPrefix ? `${dataCyPrefix}-` : ''}MultiTranslatableInput-Modal-CancelButton`,
        }}
      >
        { /* This form is only used for styling */}
        <Form
          {...FormItemLayouts.modal}
          labelAlign={'left'}
        >
          {(appConfig.languageOptions as Language[]).map((language: Language) => (
            <Form.Item
              label={t(`lang.${language}`)}
              key={language}
              data-cy={`${dataCyPrefix ? `${dataCyPrefix}-` : ''}MultiTranslatableInput-FormItem-${language}`}
            >
              <InputComponent
                value={currentValue[language] || ''}
                onChange={(newValue) => handleChange(newValue, language)}
              />
            </Form.Item>
          ))}
        </Form>
      </Modal>
    </>
  );
};

/**
 * @param value Is a map with the language as the key and the translation as the value.
 * @param onChange
 * @param entityTranslation
 * @param props
 * @param {boolean} useTextArea
 * @constructor
 */
export const TranslatableInput: React.FC<TranslatableInputProps> = ({
  value = {},
  onChange,
  translatedLabel = '',
  useTextArea = false,
  dataCyPrefix,
  ...props
}) => {
  if (appConfig.languageOptions.length > 1) {
    return (
      <MultiTranslatableInput
        translatedLabel={translatedLabel}
        value={value}
        onChange={onChange}
        useTextArea={useTextArea}
        dataCyPrefix={dataCyPrefix}
        {...props}
      />
    );
  }
  const InputComponent = useTextArea ? Input.TextArea : Input;
  return (
    // FIXME: Rest-Props need to be clean up, so that they can be used for inputs and text areas.
    // @ts-ignore
    <InputComponent
      value={value[appConfig.language as Language]}
      onChange={(event) => {
        if (onChange) {
          onChange({ ...value, [appConfig.language]: event.target.value });
        }
      }}
      {...props}
    />
  );
};
