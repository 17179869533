import {createEntity, executeDelete, executeGet, updateEntity} from '../requests';

const resourcesName = 'v1/key-performance-indicators';

export const byCalculateEndpoint = 'byCalculate';
export const byCalculateResource = 'calculate';

export const byMaterialEndpoint = 'byMaterial';
export const byMaterialResource = 'by-material';
export const byOrderEndpoint = 'byOrder';
export const byOrderResource = 'by-order';
export const byTimeEndpoint = 'byTime';
export const byTimeResource = 'by-time';

export const oeeByTimeEndpoint = 'oeeByTime';
export const oeeByTimeResource = 'oee/by-time';
export const oeeByMaterialEndpoint = 'oeeByMaterial';
export const oeeByMaterialResource = 'oee/by-material';
export const oeeByOrderEndpoint = 'oeeByOrder';
export const oeeByOrderResource = 'oee/by-order';

export const KeyPerformanceIndicatorApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...entity}) => updateEntity(entity, `${resourcesName}/${id}`),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
  [byCalculateEndpoint]: ({id, ...params}) => executeGet(`${resourcesName}/${id}/${byCalculateResource}`, params),
  [byMaterialEndpoint]: (params) => executeGet(`${resourcesName}/${byMaterialResource}`, params),
  [byOrderEndpoint]: (params) => executeGet(`${resourcesName}/${byOrderResource}`, params),
  [byTimeEndpoint]: (params) => executeGet(`${resourcesName}/${byTimeResource}`, params),
  [oeeByTimeEndpoint]: (params) => executeGet(`${resourcesName}/${oeeByTimeResource}`, params),
  [oeeByMaterialEndpoint]: (params) => executeGet(`${resourcesName}/${oeeByMaterialResource}`, params),
  [oeeByOrderEndpoint]: (params) => executeGet(`${resourcesName}/${oeeByOrderResource}`, params),
};
