import { computed, makeObservable, observable } from 'mobx';
import { maxBy } from 'lodash';
import { faHdd } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import { CustomPropertyTypes } from './customPropertyDataTypes';
import { RootStore } from '../stores/rootStore';
import { CustomPropertiesObject } from './customProperty';
import { Hierarchy } from './hierarchy';

export class Workplace extends BaseModel {
  id: number = 0;
  no: string = '';
  name: string = '';
  info: string | null = null;
  hierarchyId: number | null = null;
  groupId: number | null = null;
  isVirtualRoot: boolean = false;
  relevantDocuments = null;
  terminalLayoutId: number | null = null;
  disableYieldReporting: boolean = false;
  properties?: CustomPropertiesObject = undefined;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      no: observable,
      name: observable,
      info: observable,
      label: computed,
      hierarchyId: observable,
      hierarchy: computed,
      groupId: observable,
      group: computed,
      isVirtualRoot: observable,
      actualPersonnelLogs: computed,
      actualPersonnel: computed,
      relevantDocuments: observable,
      terminalLayoutId: observable,
      terminalLayout: computed,
      disableYieldReporting: observable,
      properties: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
      createdByUser: computed,
      updatedByUser: computed,
    });
  }

  static faIcon = faHdd;

  searchableProperties = ['no', 'name'];

  saveableProperties = [
    'no',
    'name',
    'info',
    'hierarchyId',
    'groupId',
    'isVirtualRoot',
    'properties',
    'disableYieldReporting',
    'terminalLayoutId',
  ];

  customPropertyType = CustomPropertyTypes.Workplace;

  displayableProperties = [
    displayableProperty({
      key: 'no',
      title: i18n.t('workplace.model.attributes.no'),
      params: [displayablePropertyParam({ path: 'no', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'name',
      title: i18n.t('workplace.model.attributes.name'),
      params: [displayablePropertyParam({ path: 'name', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'label',
      title: i18n.t('workplace.model.attributes.label'),
      params: [displayablePropertyParam({ path: 'label', transform: transformation.none })],
      template: '{value}',
    }),
  ];

  get group() {
    return this.rootStore.workplaceGroupStore.getById(this.groupId);
  }

  get hierarchy(): Hierarchy | null {
    return this.hierarchyId
      ? Hierarchy.findInTree<Hierarchy>(this.rootStore.hierarchyStore.hierarchies, this.hierarchyId)
      : null;
  }

  get terminalLayout() {
    return this.rootStore.terminalLayoutStore.getById(this.terminalLayoutId);
  }

  get actualPersonnelLogs() {
    return this.rootStore.personnelLogStore.personnelLogs.filter((log) => log.workplaceId === this.id);
  }

  get createdByUser() {
    return this.rootStore.userStore.getById(this.createdBy);
  }

  get updatedByUser() {
    return this.rootStore.userStore.getById(this.updatedBy);
  }

  static prepareApiPayload(model: Workplace) {
    return {
      id: model.id || undefined,
      no: model.no || null,
      name: model.name || null,
      info: model.info || null,
      isVirtualRoot: model.isVirtualRoot || null,
      properties: model.properties || null,
      groupId: model.groupId || null,
      hierarchyId: model.hierarchyId || null,
      terminalLayoutId: model.terminalLayoutId || null,
      disableYieldReporting: model.disableYieldReporting === null ? undefined : model.disableYieldReporting,
    };
  }

  get actualPersonnel() {
    return maxBy(this.actualPersonnelLogs, 'createdAt')?.personnel;
  }

  get label() {
    return `${this.no} (${this.name})`;
  }
}

export interface WorkplaceSearchResponse extends Pick<Workplace, 'id' | 'no' | 'name'> {
}

export type AdvancedSearchParams = {
  params: {
    q?: string;
    size?: number;
    orderId?: number;
    inspectionLotId?: number;
  };
};
