import { computed, observable, makeObservable, action } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { Cockpit } from '../models/cockpit';
import { arrayMove } from '../utils/helpers';
import i18n from '../i18n/i18n';
import { RootStore } from './rootStore';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';

export class CockpitStore extends EntityStore<Cockpit> {
  cockpits: Cockpit[] = [];
  currentWorkplaceId: number | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore, 'cockpits', Api.cockpits, Cockpit, true);

    makeObservable(this, {
      cockpits: observable,
      customCockpits: computed,
      currentWorkplaceId: observable,
      workplaceCockpits: computed,
      setCurrentWorkplaceId: action,
    });
  }

  setCurrentWorkplaceId(workplaceId: number | null) {
    this.currentWorkplaceId = workplaceId;
  }

  get sortedCockpits() {
    return this.cockpits.slice().sort((a, b) => a.sortOrder - b.sortOrder);
  }

  get customCockpits() {
    return this.sortedCockpits.filter((cockpit) => !cockpit.isWorkplaceCockpit);
  }

  get workplaceCockpits() {
    return this.sortedCockpits.filter((cockpit) => cockpit.isWorkplaceCockpit);
  }

  moveCockpit(fromIdx: number, toIdx: number) {
    if (fromIdx !== toIdx) {
      let cockpits = [...this.customCockpits];
      const from = this.cockpits.findIndex((cockpit) => cockpit.id === cockpits[fromIdx].id);
      const to = this.cockpits.findIndex((cockpit) => cockpit.id === cockpits[toIdx].id);
      cockpits = arrayMove(cockpits, fromIdx, toIdx);
      this.cockpits = arrayMove(this.cockpits, from, to);
      for (let i = Math.min(fromIdx, toIdx); i <= Math.max(fromIdx, toIdx); i += 1) {
        this.update({
          ...cockpits[i],
          sortOrder: i,
        }, { skipNotification: true });
      }
    }
  }

  async clone(cockpitId: number) {
    const response = await this.api.clone(cockpitId);
    this.cockpits.push(Cockpit.fromPlainObject(response.data, this.rootStore));
    this.rootStore.flashMessageStore.addFlashMessage(new FlashMessage(
      FlashMessageType.SUCCESS,
      i18n.t('flashMessages.createSuccess')
    ));
  }
}
