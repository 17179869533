import React from 'react';
import { observer } from 'mobx-react-lite';
import { User } from '../../models/user';

export type UserFullNameProps = {
  user?: User;
  strikeout?: boolean;
};

const UserFullName: React.FC<UserFullNameProps> = ({ user, strikeout = false }) => {
  if (user?.deletedAt || strikeout) {
    return <s>{user?.fullName}</s> || '';
  }

  return user?.fullName || '';
};

export default observer(UserFullName);
