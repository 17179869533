import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import Table from '../../shared/tables/Table';
import {sortAlphabetically, sortChronologically, sortNumerically} from '../../shared/tables/sorters';
import {datetimeFormat} from '../../../config/dayjs';
import {useStore} from '../../../hooks/useStore';
import {useMount} from '../../../hooks/useMount';
import {LoadStrategies} from '../../../stores/entityStore';
import MarkdownItem from '../../shared/MarkdownItem';
import UserFullName from '../../shared/UserFullName';

const OrderDetailsEventLog = ({
  operation,
  filterWorkplace = true,
  displayHierarchy = true,
}) => {
  const {t} = useTranslation();
  const store = useStore();

  useMount(() => {
    store.eventLogStore.loadAllWithDependencies({
      strategy: LoadStrategies.replace,
      params: {
        orderId: operation.orderId,
        hierarchyId: filterWorkplace ? store.workplaceStore.getById(operation.workplaceId)?.hierarchyId : undefined,
        timeFrom: dayjs(operation.order.createdAt).toISOString(),
        timeTo: dayjs().add(1, 'day').toISOString(),
      },
    });
  }, [operation.id, filterWorkplace]);

  const columns = () => {
    const cols = [];
    cols.push(
      {
        title: t('eventLog.model.attributes.timestamp'),
        dataIndex: 'timestamp',
        defaultSortOrder: 'descend',
        render: (date) => dayjs(date).format(datetimeFormat),
        sorter: (a, b) => sortChronologically(a.timestamp, b.timestamp),
      }
    );
    cols.push(
      {
        title: t('eventLog.model.attributes.context'),
        dataIndex: 'context',
        render: (context) => t(`eventLog.contexts.${context}`),
        sorter: (a, b) => sortAlphabetically(a.context, b.context),
      }
    );
    cols.push(
      {
        title: t('eventLog.model.attributes.user'),
        dataIndex: 'user',
        render: (user) => <UserFullName user={user}/>,
        sorter: (a, b) => sortAlphabetically(a.user?.fullName, b.user?.fullName),
      }
    );
    if (displayHierarchy) {
      cols.push(
        {
          title: t('eventLog.model.attributes.hierarchy'),
          dataIndex: 'hierarchy',
          render: (hierarchy) => hierarchy?.name || '-',
          sorter: (a, b) => sortAlphabetically(a.hierarchy?.name, b.hierarchy?.name),
        }
      );
    }
    cols.push(
      {
        title: t('eventLog.model.attributes.logLevel'),
        dataIndex: 'logLevel',
        render: (logLevel) => `${t(`eventLog.logLevels.${logLevel}`)}`,
        sorter: (a, b) => sortNumerically(a.logLevel, b.logLevel),
      }
    );
    cols.push(
      {
        title: t('eventLog.model.attributes.message'),
        dataIndex: 'message',
        sorter: (a, b) => sortAlphabetically(a.message, b.message),
        render: (message) => (<MarkdownItem>{message}</MarkdownItem>),
      }
    );
    return cols;
  };

  return (
    <Table
      columns={columns()}
      loading={store.eventLogStore.isLoadingCollection}
      dataSource={store.eventLogStore.getByOrderId(operation.orderId) || []}
      rowKey={'id'}
    />
  );
};

export default observer(OrderDetailsEventLog);
