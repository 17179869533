import {useContext, useRef, useState} from 'react';
import {Alert, Col, Row} from 'antd';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import Form from '../../shared/form/Form';
import RemarkFormItem from './RemarkFormItem';
import Button from '../../shared/buttons/Button';
import LocationCodeChecker from './LocationCodeChecker';
import SecondUserLoginModal from './SecondUserLoginModal';
import MarkdownItem from '../../shared/MarkdownItem';
import {InspectionTaskContext} from './InspectionTaskExecutionModal';
import {useMount} from '../../../hooks/useMount';
import useTimedRerender from '../../../hooks/useTimedRerender';
import {useStore} from '../../../hooks/useStore';
import {EnDash} from '../../shared/unicodeWrapper/EnDash';

/**
 * translations:
 * t('inspectionTask.overruleType.overrule')
 * t('inspectionTask.overruleType.fourEyes')
 */
export const OverruleType = Object.freeze({
  OVERRULE: 'overrule',
  FOUR_EYES: 'fourEyes',
});

const InspectionTaskExecutionForm = ({
  remarkRequired,
  minRemarkLength,
  overruledRemarkRequired,
  inspectionTask,
  form,
  initialValues = {},
  onValuesChange,
  overruleActive: [overruleActive, setOverruleActive],
  overruleType: [overruleType, setOverruleType],
  setOverruleUserId,
  children,
  submittable,
  onFinish,
  focusSubmit,
  inspectionEquipmentDescription,
  ...props
}) => {
  const {t} = useTranslation();
  const store = useStore();
  const [locationScanRequired, setLocationScanRequired] = useState(Boolean(inspectionTask.locationCode));
  const [isValueValidForOverrule, setIsValueValidForOverrule] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const inspectionTaskContext = useContext(InspectionTaskContext).contextValue;
  const [showModal, setShowModal] = useState(false);
  const fourEyesRequired = inspectionTask.isFourEyes && inspectionTask.fourEyesDelay === null
    && !(initialValues?.overruledValue || initialValues?.selectedOverruleFeatureId);
  const [fourEyesDelayRequired, setFourEyesDelayRequired] = useState(false);
  const [fourEyesDisabled, setFourEyesDisabled] = useState(false);

  const submitRef = useRef();
  const fourEyesRef = useRef();
  const overruleRef = useRef();

  useMount(() => {
    if (!focusSubmit || overruleActive) {
      return;
    }
    if (remarkRequired && inspectionTask.isOverruleAllowed && isValueValidForOverrule && overruleRef.current) {
      overruleRef.current.focus();
      return;
    }
    if (!isFormValid || !submittable) {
      return;
    }
    if (!overruleActive && (fourEyesRequired || fourEyesDelayRequired) && !fourEyesDisabled && fourEyesRef.current) {
      fourEyesRef.current.focus();
    } else if (submitRef.current) {
      submitRef.current.focus();
    }
  }, [
    isFormValid, remarkRequired, inspectionTask, isValueValidForOverrule, focusSubmit, submittable, overruleActive,
    fourEyesRequired, fourEyesDelayRequired, fourEyesDisabled, overruleActive, overruleRef, submitRef, fourEyesRef,
  ]);

  const checkIfFourEyesDelayIsRequired = () => {
    if (!fourEyesDelayRequired && inspectionTask.isFourEyes && inspectionTask.fourEyesDelay) {
      const pendingTasks = store.inspectionTaskPendingStore.getByInspectionTaskIds([inspectionTask.id]);
      const required = pendingTasks.some(
        (pt) => inspectionTask.isFourEyes
          && dayjs(pt.dueDate).add(inspectionTask.fourEyesDelay, 'minutes').isBefore(dayjs())
      );
      setFourEyesDelayRequired(
        required && !(initialValues?.overruledValue || initialValues?.selectedOverruleFeatureId)
      );
    }
  };

  useMount(() => {
    checkIfFourEyesDelayIsRequired();
  });

  useMount(() => {
    if (submittable && fourEyesRequired) {
      setFourEyesDisabled(false);
    }
  }, [submittable]);

  useTimedRerender({
    callback: checkIfFourEyesDelayIsRequired,
  }, [fourEyesRequired, submittable, fourEyesDelayRequired]);

  // eslint-disable-next-line no-unsafe-optional-chaining
  const remarkMaxLength = 255 - (inspectionTaskContext.rootForm.getFieldValue('groupRemark')?.length + 3 || 0);

  useMount(() => {
    if (form.isFieldTouched('remark')) {
      form.validateFields(['remark']);
    }
  }, [form, remarkRequired]);

  useMount(() => {
    if (form) {
      form.validateFields(['overruledRemark']);
    }
  }, [form, overruledRemarkRequired, fourEyesDelayRequired]);

  const onUserAllowed = (userId) => {
    setOverruleUserId(userId);
    setOverruleActive(true);
    setShowModal(false);
    form.validateFields(['value']);
  };

  const overruleClick = () => {
    form.validateFields(['remark']).then(() => {
      setOverruleType(OverruleType.OVERRULE);
      setShowModal(true);
    });
  };

  const validateForm = () => {
    if (inspectionTask.quantitative) {
      const valueErrors = form.getFieldError('value');
      const decimalPlacesValidationError = t('errors.messages.maxDecimalPlaces', {
        decimalPlaces: inspectionTask.quantitative.decimalPlaces,
      });
      // ignore decimalPlacesValidationError for overrule
      setIsValueValidForOverrule(
        valueErrors.length === 0
        || (valueErrors.length === 1 && valueErrors[0] === decimalPlacesValidationError)
      );
    }
    setIsFormValid(!form.getFieldsError().some((field) => field.errors.length > 0));
  };

  // This is required in order to be able to have some reactivity to form changes when having called setFieldValue
  // ourselves. The only negative aspect is, that the rerender every second also triggers this. The dependency is
  // probably nonsense, but it looks better than just calling the function every render.
  useMount(() => {
    validateForm();
  }, [form.getFieldsError()]);

  const fourEyesClick = () => {
    setOverruleType(OverruleType.FOUR_EYES);
    setShowModal(true);
  };

  return (
    <>
      <Form
        form={form}
        initialValues={initialValues}
        onValuesChange={(values) => {
          if (values.locationCode) {
            setLocationScanRequired(values.locationCode !== inspectionTask.locationCode);
          }
          onValuesChange(values);
        }}
        onFieldsChange={() => {
          validateForm();
        }}
        onFinish={(values) => {
          if (!overruleActive && (fourEyesRequired || fourEyesDelayRequired)) {
            fourEyesClick();
          } else if (onFinish) {
            onFinish(values);
          }
        }}
        {...props}
      >
        <MarkdownItem>
          {inspectionTask.details}
        </MarkdownItem>
        <div>
          <Row gutter={[16, 16]}>
            {locationScanRequired ? (
              <Col span={24}>
                <LocationCodeChecker
                  locationCode={inspectionTask.locationCode}
                  onMatch={() => setLocationScanRequired(false)}
                />
              </Col>
            ) : (
              <>
                {children}
                <Col span={24} style={{marginTop: '1rem'}}>
                  <RemarkFormItem
                    remarkRequired={inspectionTask.isRemarkRequired || remarkRequired}
                    form={form}
                    remarkType={'remark'}
                    hidden={overruleActive}
                    maxLength={remarkMaxLength}
                    minLength={minRemarkLength}
                  />
                  {remarkRequired && inspectionTask.detailsNotAccepted && (
                    <Alert
                      message={t('inspectionTaskPendingModal.taskBox.action')}
                      description={<MarkdownItem>{inspectionTask.detailsNotAccepted}</MarkdownItem>}
                      type={'error'}
                    />
                  )}
                </Col>
                {overruleActive && (
                  <Col span={24}>
                    <RemarkFormItem
                      remarkRequired={overruledRemarkRequired || fourEyesDelayRequired}
                      form={form}
                      remarkType={overruleType === OverruleType.OVERRULE ? 'overruledRemark' : 'fourEyesRemark'}
                      maxLength={255}
                      minLength={minRemarkLength}
                    />
                  </Col>
                )}
                <Col flex="auto"/>
                <Col style={{textAlign: 'right'}}>
                  {!overruleActive && remarkRequired && inspectionTask.isOverruleAllowed && (
                    <Button
                      htmlType="button"
                      onClick={overruleClick}
                      disabled={!isValueValidForOverrule}
                      ref={overruleRef}
                    >
                      {t('inspectionTaskPendingModal.taskBox.overrule')}
                    </Button>
                  )}
                </Col>
                <Col style={{textAlign: 'right'}}>
                  {!overruleActive && (fourEyesRequired || fourEyesDelayRequired) ? (
                    <>
                      {inspectionTask.fourEyesRoleId && (
                        <span style={{marginRight: '12px'}}>
                          {t('inspectionTaskPendingModal.taskBox.fourEyesRole')}
                          <span style={{marginRight: '6px'}}>:</span>
                          &quot;
                          {inspectionTask.fourEyesRole?.name || <EnDash/>}
                          &quot;
                        </span>
                      )}
                      <Button
                        htmlType="button"
                        onClick={fourEyesClick}
                        disabled={fourEyesDisabled || !isFormValid || !submittable}
                        ref={fourEyesRef}
                      >
                        {t('inspectionTaskPendingModal.taskBox.fourEyes')}
                      </Button>
                    </>
                  ) : (
                    <Button
                      htmlType="submit"
                      disabled={!submittable || !isFormValid}
                      onClick={form.submit}
                      ref={submitRef}
                    >
                      {t('inspectionTaskPendingModal.taskBox.acknowledge')}
                    </Button>
                  )}
                </Col>
              </>
            )}
          </Row>
        </div>
      </Form>
      {showModal && (
        <SecondUserLoginModal
          title={t(`inspectionTask.overruleType.${overruleType}`)}
          open={showModal}
          onCancel={() => setShowModal(false)}
          onUserAllowed={(userId) => onUserAllowed(userId)}
          requiredRoleId={overruleType === OverruleType.OVERRULE
            ? inspectionTask.requiredRoleId : inspectionTask.fourEyesRoleId}
          modalType={overruleType}
        />
      )}
    </>
  );
};

export default observer(InspectionTaskExecutionForm);
