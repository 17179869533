import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonProps } from './Button';

export type ReorderButtonProps = ButtonProps;

const ReorderButton: React.FC<ReorderButtonProps> = (props) => {
  const { t } = useTranslation();

  const buttonProps: ButtonProps = {
    children: t('button.reorder'),
    ...props,
  };

  return <Button {...buttonProps}/>;
};

export default ReorderButton;
