import { action, makeObservable, observable } from 'mobx';
import { EntityStore, ModelDependency } from './entityStore';
import { WidgetAction, WidgetActionExecRequest } from '../models/widgetAction';
import { RootStore } from './rootStore';
import { execResourceName, WidgetActionsApi } from '../middleware/endpoints/widgetActions';

export class WidgetActionStore extends EntityStore<WidgetAction> {
  widgetActions: WidgetAction[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'widgetActions', WidgetActionsApi, WidgetAction);

    makeObservable(this, {
      widgetActions: observable,
      exec: action,
    });
  }

  getDependencies(): ModelDependency[] {
    return [{
      store: this.rootStore.gatewayStore,
      modelId: 'gatewayId',
    }];
  }

  exec(data: WidgetActionExecRequest) {
    const actionOptions = {
      apiEndpoint: execResourceName,
      params: data,
      action: execResourceName,
    };
    this.addPendingAction(execResourceName, actionOptions);
    return this.api.execute(data).finally(() => {
      this.removePendingAction(execResourceName, actionOptions);
    });
  }
}
