import {observer} from 'mobx-react-lite';
import styles from './WelcomePage.module.scss';
import {ScopeContext} from '../../policies/scopeContext';
import {unrestricted} from '../../models/scope';

const VideoPage = ({videoSrc}) => (
  <div>
    <ScopeContext.Provider value={[unrestricted]}>
      <section className={styles.videoContainer}>
        <video className={styles.video} src={videoSrc} autoPlay loop muted>
          <track default kind="captions"/>
        </video>
      </section>
    </ScopeContext.Provider>
  </div>
);

export default observer(VideoPage);
