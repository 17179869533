import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { get, groupBy } from 'lodash';
import { AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';
import { Api } from '../middleware/api';
import { Operation, OperationDisplayStates } from '../models/operation';
import { AddMessageOptions, EntityStore, LoadOptions } from './entityStore';
import {
  activeOperationEndpoint, finishedOperationsEndpoint, plannedOperationsEndpoint, WorkplacesApi,
} from '../middleware/endpoints/workplaces';
import {
  OperationsApi, personnelDurationsEndpoint, relevantDocumentsEndpoint,
} from '../middleware/endpoints/operations';
import { RelevantDocuments } from '../models/relevantDocuments';
import { AggregatedOperation } from '../models/aggregatedOperation';
import { Document } from '../models/document';
import i18n from '../i18n/i18n';
import { RootStore } from './rootStore';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';

interface PersonnelDurationPerOperationPhase {
  operationPhaseId: number;
  personnelDurationSeconds: number;
}

export const OperationsStoreActions = {
  loadActiveOperation: 'activeOperation',
  abortOperation: 'abortOperation',
  reorderOperation: 'reorderOperation',
  loadRelevantDocuments: 'relevantDocuments',
};

export class OperationsStore extends EntityStore<Operation> {
  operations: Operation[] = [];
  personnelDurations: PersonnelDurationPerOperationPhase[] = [];
  relevantDocuments: RelevantDocuments = {
    hierarchyDocuments: [],
    materialDocuments: [],
    changeDocuments: [],
  };
  orderOperationsUpdated = false;
  operationTableLoading = false;

  constructor(rootStore: RootStore) {
    super(rootStore, 'operations', Api.operations, Operation, true);

    makeObservable(this, {
      operations: observable,
      active: computed,
      isLoadingActiveOperation: computed,
      filterFinishedOnly: observable,
      personnelDurations: observable,
      filterPlannedOnly: observable,
      loadActiveOperationByWorkplace: action,
      loadPlannedOperationsByWorkplace: action,
      loadRelevantDocumentsForOperations: action,
      abortOperation: action,
      isAbortingOperation: computed,
      aggregatedOperations: computed,
      relevantDocuments: observable,
      relevantDocumentsTree: computed,
      orderOperationsUpdated: observable,
      aggregatedPlannedOperations: computed,
      aggregatedFinishedOperations: computed,
      operationTableLoading: observable,
      setOperationTableLoading: action,
      loadAllByWorkplaceFromEndpoint: action,
      loadFinishedOperationsByWorkplace: action,
      loadByOrderId: action,
      loadByWorkplace: action,
      loadByWorkplaceAndState: action,
      loadPersonnelDurations: action,
    });
  }

  get active() {
    if (this.rootStore.workplaceStore.selectedWorkplace) {
      const latestLog = this.rootStore.operationStateLogStore
        .getLatestOfWorkplace(this.rootStore.workplaceStore.selectedWorkplace.id);
      if (latestLog?.end === null) {
        return this.getById(latestLog.operationId);
      }
    }
    return undefined;
  }

  abortOperation(id: number) {
    this.addPendingAction(OperationsStoreActions.abortOperation);

    return this.api
      .abort(id)
      .then(({ data }: AxiosResponse) => {
        runInAction(() => this.removePendingAction(OperationsStoreActions.abortOperation));

        return data;
      })
      .catch((e: Error) => this.handleApiError(e, OperationsStoreActions.abortOperation));
  }

  get isAbortingOperation() {
    return !!this.isActionInProgress(OperationsStoreActions.abortOperation);
  }

  loadRelevantDocumentsForOperations(operations: Operation[]) {
    operations.forEach((operation) => {
      this.relevantDocuments.materialDocuments = [];
      this.load(operation.id, {
        apiEndpoint: relevantDocumentsEndpoint,
        action: OperationsStoreActions.loadRelevantDocuments,
        raw: true,
      }).then((response: AxiosResponse) => {
        const mapped = response.data.materialDocuments?.map(
          (document: any) => Document.fromPlainObject(document, this.rootStore)
        );
        mapped.forEach((document: Document) => {
          if (!this.relevantDocuments.materialDocuments.find((storeDoc) => storeDoc.id === document.id)) {
            this.relevantDocuments.materialDocuments.push(document);
          }
        });
      });
    });
  }

  get isLoadingRelevantDocuments() {
    return !!this.isActionInProgress(OperationsStoreActions.loadRelevantDocuments);
  }

  loadActiveOperationByWorkplace(workplaceId: number) {
    return this.loadWithDependencies(
      workplaceId,
      {
        api: WorkplacesApi,
        apiEndpoint: activeOperationEndpoint,
        action: OperationsStoreActions.loadActiveOperation,
      }
    ).then((operation) => {
      if (operation) {
        operation.setIsPlanned(false);
        operation.setIsActive(true);
        operation.setIsFinished(false);
      }
      return operation;
    });
  }

  loadAllByWorkplaceFromEndpoint(apiEndpoint: string, workplaceId: number, options: LoadOptions) {
    return this.loadAllWithDependencies({
      api: WorkplacesApi,
      apiEndpoint,
      params: {
        id: workplaceId,
        params: options.params,
      },
    });
  }

  loadPlannedOperationsByWorkplace(workplaceId: number, options: LoadOptions = {}) {
    this.operations.forEach((operation) => {
      if (operation.workplaceId === workplaceId) {
        operation.setIsPlanned(false);
      }
    });
    return this.loadAllByWorkplaceFromEndpoint(plannedOperationsEndpoint, workplaceId, options).then(
      (operations) => {
        operations.forEach((operation) => {
          const op = this.getById(operation.id);
          if (op) {
            op.setIsPlanned(true);
          }
        });
        return operations;
      }
    );
  }

  loadFinishedOperationsByWorkplace(workplaceId: number, options: LoadOptions = {}) {
    this.operations.forEach((operation) => {
      if (operation.workplaceId === workplaceId) {
        operation.setIsFinished(false);
      }
    });
    return this.loadAllByWorkplaceFromEndpoint(finishedOperationsEndpoint, workplaceId, options).then(
      (operations) => {
        operations.forEach((operation) => {
          const op = this.getById(operation.id);
          if (op) {
            op.setIsFinished(true);
          }
        });
        return operations;
      }
    );
  }

  loadByOrderId(orderId: number) {
    return this.loadAllWithDependencies({
      params: {
        orderId,
      },
    });
  }

  loadByWorkplace(workplaceId: number, fromDate: Dayjs, toDate: Dayjs) {
    return this.loadAllWithDependencies({
      params: {
        workplaceId,
        plannedStartFrom: fromDate.toISOString(),
        plannedStartTo: toDate.toISOString(),
      },
    });
  }

  loadByWorkplaceAndState(workplaceId: number, stateId: number, fromDate: Dayjs, toDate: Dayjs) {
    return this.loadAllWithDependencies({
      params: {
        workplaceId,
        stateId,
        plannedStartFrom: fromDate.toISOString(),
        plannedStartTo: toDate.toISOString(),
      },
    });
  }

  loadPersonnelDurations(operationIds: number[], options: LoadOptions = {}) {
    this.personnelDurations = [];
    return Promise.all(operationIds.map((operationId) => this.loadAllRaw({
      api: OperationsApi,
      apiEndpoint: personnelDurationsEndpoint,
      params: {
        id: operationId,
      },
      raw: true,
      ...options,
    }).then((result: AxiosResponse) => {
      this.personnelDurations = this.personnelDurations.concat(result.data);
    })));
  }

  getPersonnelDurationByOperationPhase(operationPhaseId: number) {
    return this.personnelDurations.find((duration) => duration.operationPhaseId === operationPhaseId);
  }

  get isLoadingActiveOperation() {
    return !!this.isActionInProgress(OperationsStoreActions.loadActiveOperation);
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.materialStore,
        modelId: 'materialId',
      },
      {
        store: this.rootStore.orderStore,
        modelId: 'orderId',
      },
      {
        store: this.rootStore.operationStateStore,
        modelId: 'stateId',
      },
    ];
  }

  filterFinishedOnly(workplaceId: number, minEndDate: Date, maxEndDate: Date) {
    return this.operations.filter(
      (entry) => entry.workplaceId === workplaceId
        && entry.isFinished
        && entry.actualEnd
        && new Date(entry.actualEnd) >= minEndDate
        && new Date(entry.actualEnd) <= maxEndDate
    );
  }

  filterPlannedOnly(workplaceId: number, minStartDate: Date, maxStartDate: Date) {
    return this.operations.filter(
      (entry) => entry.workplaceId === workplaceId
        && entry.isPlanned
        && entry.plannedStart
        && new Date(entry.plannedStart) >= minStartDate
        && new Date(entry.plannedStart) <= maxStartDate
    );
  }

  getAggregatedOperations(operations: Operation[]) {
    if (!operations?.length) {
      return [];
    }
    const sortBySortOrder = (a: Operation | AggregatedOperation, b: Operation | AggregatedOperation) =>
      (a.sortOrder || 0) - (b.sortOrder || 0);
    const getStatusSortOrder = (displayStatus: string) => {
      switch (displayStatus) {
        case OperationDisplayStates.finished:
          return 3;
        case OperationDisplayStates.active:
          return 2;
        case OperationDisplayStates.paused:
          return 1;
        default:
          return 0;
      }
    };
    const sort = (a: Operation | AggregatedOperation, b: Operation | AggregatedOperation) => {
      const sortOrder = getStatusSortOrder(b.displayStatus) - getStatusSortOrder(a.displayStatus);

      if (sortOrder === 0) {
        return sortBySortOrder(a, b);
      }
      return sortOrder;
    };

    const {
      null: tempOrphans,
      undefined: ignore0,
      ...groupedParent
    } = groupBy(operations, 'order.parentOrderId');
    const { undefined: ignore1, ...groupedOrder } = groupBy(tempOrphans, 'orderId');

    return [
      ...Object.values(groupedParent).map((children) => {
        const parentOrderId = get(children, '0.order.parentOrderId');
        return new AggregatedOperation(this.rootStore, children, parentOrderId || null);
      }),
      ...Object.values(groupedOrder).map((children) => {
        const sameOrderOnWp = this.getByOrderIdAndWorkplaceId(children[0].orderId, children[0].workplaceId);
        if (sameOrderOnWp.length > 1) {
          return new AggregatedOperation(this.rootStore, sameOrderOnWp.sort(sort), null, children[0].orderId);
        }
        return children[0];
      }),
    ]
      .sort(sort);
  }

  getAggregatedOperationsByOperationState(stateId: number) {
    const selectedWorkplaceId = this.rootStore.workplaceStore.selectedWorkplace?.id;
    if (!selectedWorkplaceId) {
      return [];
    }
    const loadedOperations = this.operations.filter((op) => (
      op.workplaceId === selectedWorkplaceId && op.stateId === stateId
    ));
    return this.getAggregatedOperations(loadedOperations);
  }

  getAggregatedAndActiveOperationsByOperationState(stateId: number) {
    const selectedWorkplaceId = this.rootStore.workplaceStore.selectedWorkplace?.id;
    if (!selectedWorkplaceId) {
      return [];
    }
    const loadedOperations = this.operations.filter((op) => (
      op.workplaceId === selectedWorkplaceId && (op.stateId === stateId
        || (op.id === this.active?.id))
    ));
    return this.getAggregatedOperations(loadedOperations);
  }

  /**
   * aggregates the operations by parentOrderId into an array of AggregatedOperation models
   *
   * @returns {AggregatedOperation[]}   operations aggregated by parentOrderId.
   *                                    sorted by sortOrder and startDate.
   *                                    filtered by workplaceId.
   */
  get aggregatedOperations() {
    const selectedWorkplaceId = this.rootStore.workplaceStore.selectedWorkplace?.id;
    if (!selectedWorkplaceId) {
      return [];
    }

    const loadedOperations = this.operations.filter((op) => op.workplaceId === selectedWorkplaceId);
    return this.getAggregatedOperations(loadedOperations);
  }

  get aggregatedPlannedOperations() {
    const selectedWorkplaceId = this.rootStore.workplaceStore.selectedWorkplace?.id;
    if (!selectedWorkplaceId) {
      return [];
    }

    const loadedOperations = this.operations.filter((op) => (
      op.workplaceId === selectedWorkplaceId
      && (op.id === this.active?.id || op.isPlanned || op.isActive)
    ));

    return this.getAggregatedOperations(loadedOperations);
  }

  get aggregatedFinishedOperations() {
    const selectedWorkplaceId = this.rootStore.workplaceStore.selectedWorkplace?.id;
    if (!selectedWorkplaceId) {
      return [];
    }

    const loadedOperations = this.operations.filter((op) => (
      op.workplaceId === selectedWorkplaceId
      && op.isFinished
    ));

    return this.getAggregatedOperations(loadedOperations);
  }

  get relevantDocumentsTree() {
    return RelevantDocuments
      .getRelevantDocumentsTree(this.relevantDocuments)
      .filter((node) => node.children.length > 0);
  }

  getByWorkplaceId(workplaceId: number) {
    return this.operations.find((operation) => operation.workplaceId === workplaceId);
  }

  getByOrderId(orderId: number) {
    return this.operations.filter((operation) => operation.orderId === orderId);
  }

  getByOrderIdAndWorkplaceId(orderId: number, workplaceId: number) {
    return this.operations.filter((operation) => operation.workplaceId === workplaceId
      && operation.orderId === orderId);
  }

  getByParentOrderId(parentOrderId: number, workplaceId: number) {
    return this.operations.filter((operation) => operation.workplaceId === workplaceId
      && operation.order?.parentOrderId === parentOrderId);
  }

  getActiveByWorkplaceId(workplaceId: number) {
    const latestLog = this.rootStore.operationStateLogStore.getLatestOfWorkplace(workplaceId);

    if (latestLog?.end === null) {
      return this.getById(latestLog.operationId);
    }

    return undefined;
  }

  getUnfinishedByWorkplace(workplaceId: number) {
    return this.operations.filter((op) => op.actualEnd === null && op.workplaceId === workplaceId);
  }

  reorder(entity: Operation, options: AddMessageOptions) {
    this.addPendingAction(OperationsStoreActions.reorderOperation);
    return this.api.reorder(entity).then(({ data }: AxiosResponse) => {
      runInAction(() => this.removePendingAction(OperationsStoreActions.reorderOperation));
      this.addMessage(new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.updateSuccess')), options);
      this.add(data);
      return data;
    }).catch((e: Error) => this.handleApiError(e, OperationsStoreActions.reorderOperation));
  }

  setOperationTableLoading(value: boolean) {
    this.operationTableLoading = value;
  }
}
