import {useState} from 'react';
import {Observer, observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faListAlt} from '@fortawesome/free-solid-svg-icons';
import Modal from '../../shared/modal/Modal';
import {useStore} from '../../../hooks/useStore';
import ModelProperties from '../../shared/descriptions/ModelProperties';
import {displayableProperty} from '../../../models/displayableProperty';
import i18n from '../../../i18n/i18n';
import {displayablePropertyParam} from '../../../models/displayablePropertyParam';
import Table from '../../shared/tables/Table';
import {datetimeFormat} from '../../../config/dayjs';
import {mediumPaginationPreset} from '../../shared/tables/paginationPresets';
import {sortAlphabetically, sortChronologically, sortNumerically} from '../../shared/tables/sorters';
import Select from '../../shared/inputs/Select';
import {useMount} from '../../../hooks/useMount';
import {EnDash} from '../../shared/unicodeWrapper/EnDash';
import UserFullName from '../../shared/UserFullName';

const ConsumptionLogModal = ({
  title,
  components = [],
  onClose,
  ...props
}) => {
  const store = useStore();
  const {t} = useTranslation();

  const [selectedComponent, setSelectedComponent] = useState(components.length > 0 ? components[0] : null);
  const [dataSource, setDataSource] = useState([]);

  useMount(() => {
    if (selectedComponent) {
      store.consumptionLogStore.loadAllWithDependencies({
        dependencies: [
          {
            store: store.batchStore,
            modelId: 'batchId',
          },
        ],
        params: {
          componentId: selectedComponent.id,
        },
      }).then(() => {
        setDataSource(store.consumptionLogStore.getByComponentId(selectedComponent.id));
      });
    }
  }, [selectedComponent]);

  useMount(() => {
    if (components.length > 0) {
      setSelectedComponent(components[0]);
    }
  }, [components]);

  return (
    <Modal
      title={(
        <>
          <FontAwesomeIcon icon={faListAlt}/>
          {' '}
          {t('consumption.modal.title')}
        </>
      )}
      fullscreen={store.clientStore.isMobile}
      open={components.length > 0}
      maskClosable
      onCancel={onClose}
      footer={false}
      width={800}
      {...props}
    >
      <>
        {components.length > 1 && (
          <Select
            allowClear={false}
            onChange={(componentId) => setSelectedComponent(components.find((c) => c.id === componentId))}
            value={selectedComponent?.id}
            style={{width: '100%', marginBottom: 16}}
            size={'large'}
          >
            {components.map((component) => (
              <Select.Option
                key={component.id}
                value={component.id}
              >
                {component.no}
                {' - '}
                {component.material.name}
              </Select.Option>
            ))}
          </Select>
        )}
        {selectedComponent && (
          <>
            <ModelProperties
              loading={store.operationStore.isLoadingCollection}
              properties={[
                displayableProperty({
                  key: 'name',
                  title: i18n.t('material.model.attributes.name'),
                  params: [displayablePropertyParam({path: 'material.name'})],
                  template: '{value}',
                }),
                displayableProperty({
                  key: 'no',
                  title: i18n.t('material.model.attributes.no'),
                  params: [displayablePropertyParam({path: 'material.no'})],
                  template: '{value}',
                }),
              ]}
              model={selectedComponent}
            />
            <br/>
            <Table
              loading={store.consumptionLogStore.isLoadingCollection}
              size={'middle'}
              columns={[
                {
                  title: t('consumption.model.attributes.batchNo'),
                  sorter: (a, b) => sortAlphabetically(a.batch?.no, b.batch?.no),
                  render: (consumption) => (
                    <Observer>
                      {
                        () => (consumption.batch?.no ? consumption.batch.no : <EnDash/>)
                      }
                    </Observer>
                  ),
                },
                {
                  title: t('consumption.model.attributes.amount'),
                  dataIndex: 'amount',
                  align: 'right',
                  render: (amount) => `${amount} ${selectedComponent.unitOfMeasure?.label}`,
                  sorter: (a, b) => sortNumerically(a.amount, b.amount),
                },
                {
                  title: t('consumption.model.attributes.recordedAt'),
                  dataIndex: 'recordedAt',
                  render: (date) => dayjs(date).format(datetimeFormat),
                  sorter: (a, b) => sortChronologically(a.recordedAt, b.recordedAt),
                  defaultSortOrder: 'descend',
                },
                {
                  title: t('consumption.model.attributes.user'),
                  dataIndex: 'createdByUser',
                  render: (createdByUser) => (
                    <Observer>
                      {() => <UserFullName user={createdByUser}/>}
                    </Observer>
                  ),
                  sorter: (a, b) => sortAlphabetically(a.createdByUser?.fullName, b.createdByUser?.fullName),
                },
              ]}
              dataSource={dataSource}
              rowKey={'id'}
              pagination={mediumPaginationPreset}
            />
          </>
        )}
      </>
    </Modal>
  );
};

export default observer(ConsumptionLogModal);
