import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

export type LoginFormProps = {
  onSubmit: (values: any) => void;
};

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Form onFinish={onSubmit} className="login-form">
      <Form.Item
        name="username"
        rules={[{ required: true, message: t<string>('form.usernameRequired') }]}
      >
        <Input prefix={<UserOutlined/>} placeholder={t<string>('form.username')} autoFocus/>
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: t<string>('form.passwordRequired') }]}>
        <Input
          prefix={<LockOutlined/>}
          type="password"
          placeholder={t<string>('form.password')}
          autoComplete="current-password"
        />
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <Button size="large" type="primary" htmlType="submit" style={{ float: 'right' }}>
          {t('form.login')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
