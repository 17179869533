import React, { forwardRef } from 'react';
import { Input as AntdInput, InputProps as AntdInputProps } from 'antd';

export type InputProps = AntdInputProps & Partial<React.ForwardedRef<any>> & {
  ref?: React.ForwardedRef<any>;
};

type CompoundType = React.FC<InputProps> & {
  TextArea: typeof AntdInput.TextArea,
  Group: typeof AntdInput.Group,
  Search: typeof AntdInput.Search,
  Password: typeof AntdInput.Password,
};

const Input: any = forwardRef((props, ref?: React.ForwardedRef<any>) => (
  <AntdInput
    ref={ref}
    {...props}
  />
));

Input.TextArea = AntdInput.TextArea;
Input.Group = AntdInput.Group;
Input.Search = AntdInput.Search;
Input.Password = AntdInput.Password;

const CompoundInput: CompoundType = Input;

export default CompoundInput;
