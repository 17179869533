import dayjs from 'dayjs';

/*
 * Translations:
 * t('cockpitWidget.settings.timeframe.TODAY')
 * t('cockpitWidget.settings.timeframe.YESTERDAY')
 * t('cockpitWidget.settings.timeframe.THIS_WEEK')
 * t('cockpitWidget.settings.timeframe.LAST_WEEK')
 * t('cockpitWidget.settings.timeframe.DAYS_BACK')
 */
export const TimeframeOptions = Object.freeze({
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  THIS_WEEK: 'THIS_WEEK',
  LAST_WEEK: 'LAST_WEEK',
  DAYS_BACK: 'DAYS_BACK',
});

/*
 * Translations:
 * t('cockpitWidget.settings.aggregation.HOUR')
 * t('cockpitWidget.settings.aggregation.DAY')
 * t('cockpitWidget.settings.aggregation.WEEK')
 * t('cockpitWidget.settings.aggregation.TIMEFRAME')
 */
export const AggregationOptions = Object.freeze({
  HOUR: 'HOUR',
  DAY: 'DAY',
  WEEK: 'WEEK',
  TIMEFRAME: 'TIMEFRAME',
});

export const getFromToResolutionByOptions = (timeframe, aggregation, daysBack) => {
  let to;
  let from;
  switch (timeframe) {
    case TimeframeOptions.TODAY:
      to = dayjs().add(1, 'hour').startOf('hour');
      from = dayjs().startOf('day');
      break;
    case TimeframeOptions.YESTERDAY:
      to = dayjs().startOf('day');
      from = to.subtract(1, 'day');
      break;
    case TimeframeOptions.THIS_WEEK:
      to = dayjs().add(1, 'day').startOf('day');
      from = dayjs().startOf('week');
      break;
    case TimeframeOptions.LAST_WEEK:
      to = dayjs().startOf('week');
      from = to.subtract(1, 'week');
      break;
    case TimeframeOptions.DAYS_BACK:
      to = dayjs().startOf('day');
      from = to.subtract(daysBack, 'day');
      break;
    default:
      to = dayjs();
      from = dayjs().subtract(1, 'day');
  }
  let resolution;
  switch (aggregation) {
    case AggregationOptions.HOUR:
      resolution = to.diff(from, 'hour') || 1;
      break;
    case AggregationOptions.DAY:
      resolution = to.diff(from, 'day') || 1;
      break;
    case AggregationOptions.WEEK:
      resolution = to.diff(from, 'week') || 1;
      break;
    case AggregationOptions.TIMEFRAME:
      resolution = 1;
      break;
    default:
      resolution = 1;
  }
  return {
    from: from.toISOString(),
    to: to.toISOString(),
    resolution,
  };
};
