import {createEntity, executeDelete, executeGet, executePost, updateEntity} from '../requests';

const resourcesName = 'v1/terminal-layouts';

export const TerminalLayoutsApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...entity}) => updateEntity(entity, `${resourcesName}/${id}`),
  clone: (params) => executePost(`${resourcesName}/clone`, params),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
};
