import {observer} from 'mobx-react-lite';
import Table from '../../shared/tables/Table';

const ComponentTable = ({loading, components, columns = [], ...props}) => (
  <Table
    rowKey="id"
    columns={columns}
    pagination={false}
    dataSource={components}
    loading={loading}
    {...props}
  />
);

export default observer(ComponentTable);
