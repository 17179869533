import {createEntity, executeGet} from '../requests';

const resourcesName = 'v1/sensor-data';

export const sensorDataAnalysisEndpoint = 'sensorDataAnalysis';
export const sensorDataAnalysisResource = 'analysis';

export const SensorDataApi = {
  all: (params) => executeGet(resourcesName, {params}),
  [sensorDataAnalysisEndpoint]: ({...params}) => executeGet(`${resourcesName}/${sensorDataAnalysisResource}`, {params}),
  create: (entity) => createEntity(entity, resourcesName),
};
