import i18n from '../../i18n/i18n';
import {BaseWidgetConfig} from '../shared/widgets/baseWidgetConfig';
import {ValidationRules} from '../../utils/validationRules';
import {getAllI18nTranslations} from '../../utils/translations';
import {FieldType} from '../../types/widgetConfig';

export class TerminalManagementWidgetConfig extends BaseWidgetConfig {
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'settings',
        label: i18n.t('widget.WidgetSettingsModal.settingConfigTabTitle'),
      },
      {
        key: 'broken',
        label: i18n.t('terminalManagement.configTabs.brokenConfigTabTitle'),
      },
    ];
  }

  settings = [
    {
      id: 'title',
      label: i18n.t('widget.settings.title.label'),
      helpText: i18n.t('widget.settings.title.help'),
      type: FieldType.Translatable,
      defaultValue: getAllI18nTranslations('terminalManagement.title'),
      validations: [ValidationRules.required()],
    },
  ];

  broken = [
    {
      id: 'reason1',
      label: `${i18n.t('terminalManagement.settings.reason.label')} 1`,
      helpText: i18n.t('terminalManagement.settings.reason.help'),
      type: FieldType.String,
    },
    {
      id: 'reason2',
      label: `${i18n.t('terminalManagement.settings.reason.label')} 2`,
      helpText: i18n.t('terminalManagement.settings.reason.help'),
      type: FieldType.String,
    },
    {
      id: 'reason3',
      label: `${i18n.t('terminalManagement.settings.reason.label')} 3`,
      helpText: i18n.t('terminalManagement.settings.reason.help'),
      type: FieldType.String,
    },
    {
      id: 'reason4',
      label: `${i18n.t('terminalManagement.settings.reason.label')} 4`,
      helpText: i18n.t('terminalManagement.settings.reason.help'),
      type: FieldType.String,
    },
    {
      id: 'reason5',
      label: `${i18n.t('terminalManagement.settings.reason.label')} 5`,
      helpText: i18n.t('terminalManagement.settings.reason.help'),
      type: FieldType.String,
    },
    {
      id: 'reason6',
      label: `${i18n.t('terminalManagement.settings.reason.label')} 6`,
      helpText: i18n.t('terminalManagement.settings.reason.help'),
      type: FieldType.String,
    },
    {
      id: 'reason7',
      label: `${i18n.t('terminalManagement.settings.reason.label')} 7`,
      helpText: i18n.t('terminalManagement.settings.reason.help'),
      type: FieldType.String,
    },
    {
      id: 'reason8',
      label: `${i18n.t('terminalManagement.settings.reason.label')} 8`,
      helpText: i18n.t('terminalManagement.settings.reason.help'),
      type: FieldType.String,
    },
  ];

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'title');
  }
}
