import { computed, observable, makeObservable } from 'mobx';
import { BaseModel } from './base';
import { getTranslation } from '../utils/translations';
import { RootStore } from '../stores/rootStore';
import { Translation } from './translations';

interface CatalogFeatureTranslation extends Translation {
  text: string;
}

export class CatalogFeature extends BaseModel {
  id: number = 0;
  code: string = '';
  group: string = '';
  valuation: string = '';
  isOverrule: boolean = false;
  translations: CatalogFeatureTranslation[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      translations: observable,
      text: computed,
    });
  }

  translatedProperties = ['text'];

  get text() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.text;
  }

  static prepareApiPayload(model: CatalogFeature) {
    const preprocessed = this.discardPropsWhereNameIncludes(model, 'translated_');

    return {
      id: preprocessed.id,
      code: preprocessed.code,
      group: preprocessed.group,
      valuation: preprocessed.valuation,
      isOverrule: preprocessed.isOverrule,
      translations: this.convertToSavableTranslations(model),
    };
  }
}
