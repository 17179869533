import {Space} from 'antd';
import {observer} from 'mobx-react-lite';
import {useMount} from '../../../hooks/useMount';
import {useStore} from '../../../hooks/useStore';
import InspectionTaskOverview from './InspectionTaskOverview';

const InspectionTaskOverviewByOperation = ({operation, selectPendingTask}) => {
  const store = useStore();

  const operationId = operation?.id;

  useMount(() => {
    if (operationId) {
      store.phaseStore.loadAll();
      store.operationStateStore.loadAll();
      store.inspectionTaskStore.loadAllWithDependencies({params: {orderId: operation.orderId}});
      store.operationPhaseStore.loadByOperation(operationId);
    }
  }, [operationId]);

  const inspectionTasks = store.inspectionTaskStore
    .getValidByWorkplaceIdAndOrderId(operation.workplaceId, operation.orderId);

  return (
    <Space direction="vertical" style={{width: '100%'}}>
      <InspectionTaskOverview
        selectPendingTask={operationId === store.operationStore.active?.id ? selectPendingTask : null}
        inspectionTasks={inspectionTasks}
      />
    </Space>
  );
};

export default observer(InspectionTaskOverviewByOperation);
