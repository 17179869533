import { observable, makeObservable, computed } from 'mobx';
import { faPuzzlePiece } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';
import { Batch } from './batch';

export enum IngredientType {
  CODE_128_INGREDIENT = 'CODE_128_INGREDIENT',
  CODE_128_PURCHASE_NO = 'CODE_128_PURCHASE_NO',
  INSOFT_SINGLE_USE = 'INSOFT_SINGLE_USE',
  INSOFT_MULTI_USE = 'INSOFT_MULTI_USE',
  SSCC = 'SSCC'
}

export interface StockInformation {
  storageLocation?: string;
  storagePlace?: string;
  amount: number;
  batchNo: string;
  batchId?: number;
  batch?: Batch;
  unitOfMeasureId: number;
}

export class Ingredient extends BaseModel {
  no: string = '';
  type: IngredientType = IngredientType.CODE_128_INGREDIENT;
  materialId: number = 0;
  batchId: number = 0;
  expiryDate: string = '';
  locked: boolean = false;
  amount: number = 0;
  unitOfMeasureId: number = 0;
  isSingleUse: boolean = false;
  stock: StockInformation[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      no: observable,
      type: observable,
      materialId: observable,
      material: computed,
      batchId: observable,
      batch: computed,
      expiryDate: observable,
      locked: observable,
      amount: observable,
      unitOfMeasureId: observable,
      unitOfMeasure: computed,
      isSingleUse: observable,
      stock: observable,
    });
  }

  static faIcon = faPuzzlePiece;

  get unitOfMeasure() {
    return this.rootStore.unitOfMeasurementStore.getById(this.unitOfMeasureId);
  }

  get material() {
    return this.rootStore.materialStore.getById(this.materialId);
  }

  get batch() {
    return this.rootStore.batchStore.getById(this.batchId);
  }

  populateAttributesFromStore(rootStore: RootStore) {
    super.populateAttributesFromStore(rootStore);
    if (this.stock && this.stock.length) {
      this.stock.forEach((stockEntry) => {
        stockEntry.batch = this.batch;
      });
    }
  }
}
