import {createEntity, executeDelete, executeGet, executePostMultipart, updateEntity} from '../requests';

const resourcesName = 'v1/process-data-points';

export const ProcessDataPointApi = {

  bulk: (data) => executePostMultipart(`${resourcesName}/bulk`, data),
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...entity}) => updateEntity(entity, `${resourcesName}/${id}`),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
};
