import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {useStore} from '../../hooks/useStore';
import {ValidationRules} from '../../utils/validationRules';
import Select from '../shared/inputs/Select';
import Form from '../shared/form/Form';
import Input from '../shared/inputs/Input';
import FormModal from '../shared/modal/FormModal';
import HelpTooltipFormLabel from '../shared/tooltips/HelpTooltipFormLabel';
import DefaultFormActions from '../shared/form/DefaultFormActions';
import useProfile from '../../hooks/useProfile';

const EditSpeedChangeModal = ({
  title,
  sensor,
  reasons = [],
  onCancel,
  onSuccess,
}) => {
  const [form] = Form.useForm();
  const store = useStore();
  const {t} = useTranslation();
  const {profile} = useProfile();

  const handleSubmit = (values) => {
    const activeOperation = store.operationStore.active;
    if (sensor && activeOperation) {
      const remarkObject = {
        username: profile?.fullName,
        reason: values.reason,
      };
      store.sensorDataStore.create({
        sensorName: sensor,
        value: values.speed,
        workplaceId: activeOperation.workplaceId,
        orderId: activeOperation.orderId,
        remark: JSON.stringify(remarkObject),
      }).then(() => {
        store.operationStore.loadActiveOperationByWorkplace(store.workplaceStore.selectedWorkplace.id)
          .then(() => {
            onSuccess();
          });
      });
    }
  };

  return (
    <FormModal title={title} open onCancel={onCancel}>
      <Form
        form={form}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="speed"
          label={(
            <HelpTooltipFormLabel
              label={t('speedChangeWidget.editModal.form.speed.label')}
              help={t('speedChangeWidget.editModal.form.speed.help')}
            />
          )}
          rules={[ValidationRules.required()]}
        >
          <Input type={'number'}/>
        </Form.Item>
        <Form.Item
          name="reason"
          label={(
            <HelpTooltipFormLabel
              label={t('speedChangeWidget.editModal.form.reason.label')}
              help={t('speedChangeWidget.editModal.form.reason.help')}
            />
          )}
          rules={[ValidationRules.required()]}
        >
          <Select>
            {
              reasons.map((reason) => <Select.Option key={reason} value={reason}>{reason}</Select.Option>)
            }
          </Select>
        </Form.Item>

        <DefaultFormActions saving={store.sensorDataStore.isMutationInProgress} onCancel={onCancel}/>
      </Form>
    </FormModal>
  );
};

export default observer(EditSpeedChangeModal);
