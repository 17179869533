import { Api } from '../middleware/api';
import { RootStore } from './rootStore';
import { ConfigTransferRequest, ConfigTransferSelectionRequest } from '../models/configTransferLog';

export class ConfigTransferStore {
  rootStore: RootStore;
  api = Api.configTransfers;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  async export(selection: ConfigTransferSelectionRequest): Promise<void> {
    if (!Object.keys(selection).length) {
      return Promise.reject(new Error('No selection provided'));
    }

    const response = await this.api.export(selection);
    if (response.status !== 200) {
      return Promise.reject(new Error('Error while exporting'));
    }

    // create a file and download it
    const blob = new Blob([JSON.stringify(response.data)], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', response.data.meta.filename);
    link.click();

    return Promise.resolve();
  }

  async importPreview(
    { selection, content, delete: deleteFlag }: ConfigTransferRequest
  ) {
    if (!content) {
      return Promise.reject(new Error('No content provided'));
    }
    if (!Object.keys(selection).length) {
      return Promise.reject(new Error('No selection provided'));
    }

    const response = await this.api.importPreview(
      { selection, content, delete: deleteFlag }
    );
    if (response.status !== 200) {
      return Promise.reject(new Error('Error while getting import preview'));
    }

    return Promise.resolve(response.data);
  }

  async dependencyCheck(
    { selection }: { selection: ConfigTransferSelectionRequest }
  ) {
    if (!Object.keys(selection).length) {
      return Promise.reject(new Error('No selection provided'));
    }

    const response = await this.api.dependencyCheck({ selection });
    if (response.status !== 200) {
      return Promise.reject(new Error('Error while getting import check'));
    }

    return Promise.resolve(response.data);
  }

  async import(
    { selection, content, delete: deleteFlag }: ConfigTransferRequest
  ) {
    if (!content) {
      return Promise.reject(new Error('No content provided'));
    }
    if (!Object.keys(selection).length) {
      return Promise.reject(new Error('No selection provided'));
    }

    const response = await this.api.import({ selection, content, delete: deleteFlag });
    if (response.status !== 200) {
      return Promise.reject(new Error('Error while importing'));
    }

    return Promise.resolve(response.data);
  }
}
