import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonProps } from './Button';

export type SaveButtonProps = ButtonProps;

const SaveButton: React.FC<SaveButtonProps> = ({ children, ...props }) => {
  const { t } = useTranslation();

  return <Button {...props} htmlType={'submit'}>{t('button.save')}</Button>;
};

export default SaveButton;
