import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { RootStore } from './rootStore';
import { Fault } from '../models/fault';

export class FaultStore extends EntityStore<Fault> {
  faults: Fault[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'faults', Api.faults, Fault, true);

    makeObservable(this, {
      faults: observable,
    });
  }
}
