import { observable, makeObservable, computed } from 'mobx';
import { faRulerVertical } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

export class StorageUnitLevel extends BaseModel {
  id: number = 0;
  storageUnitId: number = 0;
  stop: number = 0;
  value: number = 0;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      storageUnitId: observable,
      storageUnit: computed,
      stop: observable,
      value: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faRulerVertical;

  get storageUnit() {
    return this.rootStore.storageUnitStore.getById(this.storageUnitId);
  }

  static prepareApiPayload(model: Partial<StorageUnitLevel>): Partial<StorageUnitLevel> {
    return {
      id: model.id,
      storageUnitId: model.storageUnitId,
      stop: model.stop,
      value: model.value,
    };
  }
}
