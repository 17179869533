import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';

const ConfirmationValue = ({bool}) => {
  const {t} = useTranslation();

  return (bool ? t('confirm.yes') : t('confirm.no'));
};

export default observer(ConfirmationValue);
