import React from 'react';
import { List as AntdList, ListProps as AntdListProps } from 'antd';

export type ListProps<T> = AntdListProps<T>;

const List: (<T, >(props: ListProps<T>) => React.ReactNode) & {
  Item: typeof AntdList.Item;
} = <T, >({ children, ...props }: ListProps<T>) => <AntdList<T> {...props}/>;

List.Item = AntdList.Item;

export default List;
