import {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {TimeseriesCockpitWidgetConfig} from './config/sensorStatusDisplayWidgetConfig';
import Spinner from '../../../shared/spinners/Spinner';
import CockpitWidget from './CockpitWidget';
import {useStore} from '../../../../hooks/useStore';
import {WidgetLayout} from '../../../../models/widgetLayout';
import {WidgetLayouts} from '../../../shared/widgets/WidgetLayouts';
import useWorkplaceRelatedWidgetTitle from '../../../../hooks/useWorkplaceRelatedWidgetTitle';
import {useMount} from '../../../../hooks/useMount';
import {SensorStatusDisplay} from '../../../shared/dataDisplay/SensorStatusDisplay';

const SensorStatusDisplayWidget = ({
  widgetId,
  title,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const store = useStore();
  const [selectedWorkplaceId, setSelectedWorkplaceId] = useState(null);
  const [limit, setLimit] = useState(null);
  const [sensorData, setSensorData] = useState(null);
  const widgetTitle = useWorkplaceRelatedWidgetTitle(title, selectedWorkplaceId);

  const widgetConfig = useMemo(() => new SensorStatusDisplayWidget.Config({
    rootStore: store,
    identifier: SensorStatusDisplayWidget.Config.widgetType.identifier,
    widgetId,
  }), []);

  useMount(() => {
    if (store.contextStore.isOperatorContext) {
      store.sensorStore.loadAll();
    }
  });

  useMount(() => {
    setSelectedWorkplaceId(
      store.workplaceStore.getByHierarchyId(widgetConfig.getSettingValue('sensorStatus', 'hierarchyId'))?.id
      || store.cockpitStore.currentWorkplaceId || null
    );
  }, [
    store.cockpitStore.currentWorkplaceId,
    widgetConfig.getSettingValue('sensorStatus', 'hierarchyId'),
  ]);

  const loadLimitData = (sensorName, hierarchyId) => {
    if (!sensorName || !hierarchyId) {
      return Promise.resolve();
    }
    return store.sensorDataLimitStore.loadBySensorsAndHierarchy(
      [sensorName],
      store.workplaceStore.getById(selectedWorkplaceId)?.hierarchyId
    );
  };

  const loadData = () => {
    const prom = [];
    const sensorName = widgetConfig.getSettingValue('sensorStatus', 'sensor');
    if (!sensorName) {
      return;
    }
    setIsLoading(true);
    prom.push(store.sensorDataStore.loadLatestByWorkplace(sensorName, selectedWorkplaceId));
    const hierarchyId = store.workplaceStore.getById(selectedWorkplaceId)?.hierarchyId;
    prom.push(loadLimitData(sensorName, hierarchyId));
    Promise.all(prom).then(() => {
      const newSensorData = store.sensorDataStore.getLatestByWorkplace(sensorName, selectedWorkplaceId);
      if (newSensorData) {
        setSensorData(newSensorData);
      } else {
        setSensorData(null);
      }
      const newLimit = store.sensorDataLimitStore.getBySensorAndHierarchy(
        sensorName,
        store.workplaceStore.getById(selectedWorkplaceId)?.hierarchyId
      );
      if (newLimit) {
        setLimit(newLimit);
      } else {
        setLimit(null);
      }
      setIsLoading(false);
    });
  };

  useMount(() => {
    loadData();
  }, [selectedWorkplaceId, widgetConfig.getSettingValue('sensorStatus', 'sensor')]);

  return (
    <CockpitWidget
      widgetId={widgetId}
      title={widgetTitle}
      widgetConfig={widgetConfig}
      onSettingsChange={loadData}
      {...props}
    >
      {
        isLoading
          ? <Spinner fullWidth fullHeight/>
          : (
            <SensorStatusDisplay
              limit={limit}
              sensor={store.sensorStore.getById(widgetConfig.getSettingValue('sensorStatus', 'sensor'))}
              sensorData={sensorData}
            />
          )
      }
    </CockpitWidget>
  );
};

SensorStatusDisplayWidget.Config = TimeseriesCockpitWidgetConfig;
SensorStatusDisplayWidget.defaultLayout = new WidgetLayout({
  identifier: CockpitWidget.identifier,
  width: 1,
  height: 5,
  minHeight: 5,
  minWidth: WidgetLayouts.sixthWidth.minW,
});
export default observer(SensorStatusDisplayWidget);
