import i18n from '../../i18n/i18n';
import {BaseWidgetConfig} from '../shared/widgets/baseWidgetConfig';
import {ValidationRules} from '../../utils/validationRules';
import {getAllI18nTranslations} from '../../utils/translations';
import {FieldType} from '../../types/widgetConfig';

export class ManualSenensorDataWidgetConfig extends BaseWidgetConfig {
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'settings',
        label: i18n.t('widget.WidgetSettingsModal.settingConfigTabTitle'),
      },
    ];
  }

  settings = [
    {
      id: 'title',
      label: i18n.t('widget.settings.title.label'),
      helpText: i18n.t('widget.settings.title.help'),
      type: FieldType.Translatable,
      defaultValue: getAllI18nTranslations('manualSensorDataWidget.title'),
      validations: [ValidationRules.required()],
    },
    {
      id: 'sensors',
      label: i18n.t('manualSensorDataWidget.setting.sensor.label'),
      helpText: i18n.t('manualSensorDataWidget.setting.sensor.help'),
      type: FieldType.SelectMultiple,
      options: this.rootStore.sensorStore.manualSensors
        .map((sensor) => ({value: sensor.name, label: sensor.label || sensor.name})),
      allowClear: true,
    },
    {
      id: 'showLatest',
      label: i18n.t('manualSensorDataWidget.setting.showLatest.label'),
      helpText: i18n.t('manualSensorDataWidget.setting.showLatest.help'),
      type: FieldType.Boolean,
      defaultValue: false,
    },
    {
      id: 'includeOrderId',
      label: i18n.t('manualSensorDataWidget.setting.includeOrderId.label'),
      helpText: i18n.t('manualSensorDataWidget.setting.includeOrderId.help'),
      type: FieldType.Boolean,
      defaultValue: false,
    },
  ];

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'title');
  }

  getSensorNames() {
    return this.getSettingValue('settings', 'sensors');
  }

  getShowLatest() {
    return this.getSettingValue('settings', 'showLatest');
  }

  getIncludeOrderId() {
    return this.getSettingValue('settings', 'includeOrderId');
  }
}
