import {orderBy} from 'lodash';
import {Space} from 'antd';
import {observer} from 'mobx-react-lite';
import QuantitativeDetails from './QuantitativeDetails';
import QualitativeDetails from './QualitativeDetails';
import {useStore} from '../../../hooks/useStore';
import {useMount} from '../../../hooks/useMount';
import Spinner from '../../shared/spinners/Spinner';

const InspectionTaskDetails = ({task}) => {
  const {inspectionTaskResultStore} = useStore();

  useMount(() => {
    inspectionTaskResultStore.loadByInspectionTaskId(task.id);
  });

  let details;
  const results = orderBy(inspectionTaskResultStore.getByInspectionTaskId(task.id), 'recordedAt');
  switch (task.inspectionType) {
    case 'Quantitative': {
      details = <QuantitativeDetails task={task} results={results}/>;
      break;
    }
    default:
      details = <QualitativeDetails task={task} results={results}/>;
  }

  if (inspectionTaskResultStore.isLoadingCollection) {
    return <Spinner fullWidth/>;
  }

  return (
    <Space direction={'vertical'} style={{width: '100%'}}>
      {details}
    </Space>
  );
};
export default observer(InspectionTaskDetails);
