import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperclip} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import {useMount} from '../../hooks/useMount';
import DocumentList from '../shared/documents/DocumentList';
import Message from './partials/Message';
import Validity from './partials/Validity';
import Sender from './partials/Sender';
import Subject from './partials/Subject';
import {useStore} from '../../hooks/useStore';
import SubTitleWithIcon from '../shared/typography/SubTitleWithIcon';

const MessageItemContent = ({message, sender}) => {
  const {t} = useTranslation();
  const store = useStore();

  useMount(() => {
    if (message.documentIds?.length) {
      store.documentStore.loadMany(message.documentIds);
    }
  }, [message]);

  return (
    <>
      <div
        style={{padding: 16, backgroundColor: '#FAFAFA', overflow: 'auto'}}
        className={'message-header'}
      >
        <Subject message={message}/>
        <Sender sender={sender}/>
        <Validity message={message}/>
      </div>
      <div style={{display: 'flex'}}>
        <Message message={message}/>
        {
          message.hasDocuments
            ? (
              <DocumentList
                style={{minWidth: '30%', maxWidth: '30%'}}
                dataSource={message.documentIds}
                title={
                  (
                    <SubTitleWithIcon
                      icon={<FontAwesomeIcon icon={faPaperclip} style={{marginRight: 8}}/>}
                      text={t('message.model.attributes.attachment')}
                    />
                  )
                }
              />
            )
            : ''
        }
      </div>
    </>
  );
};

export default observer(MessageItemContent);
