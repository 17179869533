import { createBreakpoint, useMeasure } from 'react-use';
import React from 'react';
import cssBreakpoints from '../../assets/stylesheets/breakpoints.module.scss';
import { useStore } from '../../hooks/useStore';
import { useMount } from '../../hooks/useMount';
import appConfig from '../../utils/appConfig';

export type BreakPoints = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
};

// Need to convert breakpoints values into integer because scss provides only strings
export const breakpoints: BreakPoints = Object.keys(cssBreakpoints).reduce((acc, key) => ({
  ...acc,
  [key]: parseInt(cssBreakpoints[key], 10),
}), {}) as BreakPoints;

export const useBreakpoint = createBreakpoint(breakpoints);

export const Viewport: React.FC = () => {
  const store = useStore();
  const breakpoint = useBreakpoint();

  const [ref, bounds] = useMeasure<HTMLDivElement>();

  useMount(() => {
    const body = document.getElementsByTagName('body')[0];
    if (store.contextStore.isOperatorContext && body && appConfig.enableExperimental) {
      const scale = store.terminalLayoutStore.currentTerminalLayout?.scale || 1;
      // @ts-ignore - unofficial css tag
      body.style.zoom = `${scale}`;
    } else {
      // @ts-ignore - unofficial css tag
      body.style.zoom = '';
    }
  }, [
    store.terminalLayoutStore.currentTerminalLayout?.id,
    store.contextStore.current,
  ]);

  useMount(() => {
    store.clientStore.setBreakpoint(breakpoint);
  }, [bounds]);

  useMount(() => {
    const oldStyle = document.getElementById('custom-css');
    if (oldStyle) {
      oldStyle.remove();
    }
    if (!appConfig.corporateIdentity.customCss) {
      return;
    }
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('link');
    style.href = appConfig.corporateIdentity.customCss;
    style.type = 'text/css';
    style.rel = 'stylesheet';
    style.id = 'custom-css';
    head.append(style);
  }, [appConfig.corporateIdentity.customCss]);

  return <div ref={ref}/>;
};
