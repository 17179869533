import {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {uniq} from 'lodash/array';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWarehouseFull} from '@fortawesome/pro-solid-svg-icons';
import {WidgetLayout} from '../../../models/widgetLayout';
import OperatorWidget from '../../operator/shared/OperatorWidget';
import {WidgetLayouts} from '../../shared/widgets/WidgetLayouts';
import {InventoryWidgetConfig} from './InventoryWidgetConfig';
import {useStore} from '../../../hooks/useStore';
import {ScopeContext} from '../../../policies/scopeContext';
import {inventoryManagement} from '../../../models/scope';
import InventoryWidgetLayout from './InventoryWidgetLayout';
import {useMount} from '../../../hooks/useMount';
import {mqtt, TOPIC_BASE} from '../../../middleware/mqtt';
import {LoadStrategies} from '../../../stores/entityStore';

const InventoryWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const widgetConfig = useMemo(() => (
    new InventoryWidgetConfig(store, identifier)
  ), [store.storageAreaStore.storageAreas.length, store.settingStore.areCollectionOrDependenciesLoading]);
  const [slots, setSlots] = useState([]);

  useMount(() => {
    store.storageAreaStore.loadAll();
  }, []);

  useMount(() => {
    setSlots(widgetConfig.getInventorySlots());
  }, [widgetConfig]);

  useMount(() => {
    const storageUnitIds = uniq(slots.map((slot) => slot.storageUnit).filter((id) => id));
    let storageUnitHandlerIds = [];
    if (storageUnitIds.length > 0) {
      storageUnitHandlerIds = storageUnitIds.map((storageUnitId) => {
        store.storageUnitStore.loadWithDependencies(storageUnitId, {});
        return mqtt.subscribe(`${TOPIC_BASE}/storage-units/${storageUnitId}`, (topic, payload) => {
          const publishedStorageUnit = store.storageUnitStore.createModelInstance(payload);
          store.storageUnitStore.add(publishedStorageUnit);
        });
      });
    }
    const storageAreaIds = uniq(slots.map((slot) => slot.storageArea).filter((id) => id));
    let storageAreaHandlerIds = [];
    if (storageAreaIds.length > 0) {
      storageAreaHandlerIds = storageAreaIds.map((storageAreaId) => {
        store.storageUnitStore.loadAllWithDependencies({params: {storageAreaId}});
        return mqtt.subscribe(`${TOPIC_BASE}/storage-areas/${storageAreaId}`, (topic, payload) => {
          store.storageUnitStore.loadAllWithDependencies({
            params: {storageAreaId: payload.id},
            strategy: LoadStrategies.replace,
          });
        });
      });
    }
    return () => {
      storageUnitIds.forEach((storageUnitId, idx) => {
        mqtt.unsubscribe(`${TOPIC_BASE}/storage-units/${storageUnitId}`, storageUnitHandlerIds[idx]);
      });
      storageAreaIds.forEach((storageAreaId, idx) => {
        mqtt.unsubscribe(`${TOPIC_BASE}/storage-areas/${storageAreaId}`, storageAreaHandlerIds[idx]);
      });
    };
  }, [slots]);

  return (
    <ScopeContext.Provider value={[inventoryManagement]}>
      <OperatorWidget
        icon={<FontAwesomeIcon icon={faWarehouseFull}/>}
        title={widgetConfig.getWidgetTitle()}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        manualPath={'/inventory-management/inventory-widget'}
      >
        <InventoryWidgetLayout
          loading={store.storageUnitStore.isLoadingCollection || store.storageAreaStore.isLoadingCollection}
          inventorySlots={slots}
          layout={widgetConfig.getWidgetLayout()}
        />
      </OperatorWidget>
    </ScopeContext.Provider>
  );
};

InventoryWidget.icon = faWarehouseFull;

InventoryWidget.identifier = 'InventoryWidget';
InventoryWidget.defaultLayout = new WidgetLayout(
  {
    identifier: InventoryWidget.identifier,
    x: 6,
    y: 0,
    height: 8,
    minHeight: 1,
    width: WidgetLayouts.halfWidth.w,
    minWidth: WidgetLayouts.halfWidth.minW,
  }
);
export default observer(InventoryWidget);
