import { computed, makeObservable, observable } from 'mobx';
import { faListAlt } from '@fortawesome/free-solid-svg-icons';
import { BaseModel, Blamable } from './base';
import i18n from '../i18n/i18n';
import { getTranslation } from '../utils/translations';
import { RootStore } from '../stores/rootStore';
import { CustomPropertyDataTypes, CustomPropertyTypes } from './customPropertyDataTypes';

export interface CustomPropertiesOptions {
  [key: string]: any;
}

export interface CustomPropertiesObject {
  [key: string]: any;
}

export class CustomProperty extends BaseModel {
  id?: number = 0;
  type: CustomPropertyTypes = CustomPropertyTypes.Component;
  key: string = '';
  required: boolean = false;
  sortOrder: number = 0;
  dataType?: CustomPropertyDataTypes | null = undefined;
  options?: CustomPropertiesOptions[] | null = undefined;
  translations?: any[] = [];
  createdAt?: string = undefined;
  createdBy?: number = undefined;
  updatedAt?: string = undefined;
  updatedBy?: number = undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      type: observable,
      key: observable,
      label: computed,
      required: observable,
      typeFormatted: computed,
      dataType: observable,
      options: observable,
      translations: observable,
      sortOrder: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faListAlt;

  searchableProperties = ['typeFormatted', 'key', 'label'];

  translatedProperties = ['label', 'options.label'];

  static prepareApiPayload(model: Partial<Omit<CustomProperty, Blamable>>): Partial<Omit<CustomProperty, Blamable>> {
    return {
      id: model.id || undefined,
      type: model.type,
      key: model.key,
      required: model.required,
      sortOrder: model.sortOrder,
      dataType: model.dataType || null,
      options: model.options,
      translations: CustomProperty.convertToSavableTranslations(model),
    };
  }

  get typeFormatted() {
    return i18n.t(`customProperty.types.${this.type}`);
  }

  get label() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.label;
  }

  translatedOption(option: any) {
    const translated = option.label[this.rootStore.languageStore.lang];
    if (!translated) {
      if (Object.keys(option.label).length > 0) {
        return Object.values(option.label)[0];
      }
      return null;
    }
    return translated;
  }

  getOptionsLabel(key: any) {
    // structure of options: [{"key": "START", "label": {"de-CH": "Translated label"}}]
    if (!this.options) {
      return key;
    }

    const label = this.options.find((o) => o.key === key)?.label;
    const language = this.rootStore.languageStore.lang;

    if (!label || !label[language]) {
      return key;
    }

    return label[language];
  }
}
