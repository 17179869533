import { faList } from '@fortawesome/free-solid-svg-icons';
import { computed, makeObservable } from 'mobx';
import { uniq } from 'lodash';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import { datetimeFormat } from '../config/dayjs';
import { sortAlphabetically, sortChronologically } from '../components/shared/tables/sorters';
import { RootStore } from '../stores/rootStore';

export enum AuditLogOperation {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

export const AuditLogTableName = Object.freeze({
  Batch: 'Batch',
  BatchProperty: 'BatchProperty',
  CustomAction: 'CustomAction',
  CustomFunction: 'CustomFunction',
  CustomProperty: 'CustomProperty',
  CustomPropertyTranslation: 'CustomPropertyTranslation',
  Document: 'Document',
  Document_Hierarchy: 'Document_Hierarchy',
  DocumentType: 'DocumentType',
  ExternalResource: 'ExternalResource',
  ExternalResourceProperty: 'ExternalResourceProperty',
  ExternalResource_Workplace: 'ExternalResource_Workplace',
  Fault: 'Fault',
  FaultCollection: 'FaultCollection',
  Gateway: 'Gateway',
  GatewayProperty: 'GatewayProperty',
  Hierarchy: 'Hierarchy',
  HierarchyTranslation: 'HierarchyTranslation',
  InspectionEquipment: 'InspectionEquipment',
  InspectionEquipment_CustomFunctions: 'InspectionEquipment_CustomFunctions',
  InspectionPlan: 'InspectionPlan',
  InspectionPlan_Hierarchy: 'InspectionPlan_Hierarchy',
  InspectionPlan_Material: 'InspectionPlan_Material',
  InspectionPlan_Task: 'InspectionPlan_Task',
  InspectionPlanHierarchy: 'InspectionPlanHierarchy',
  InspectionTask: 'InspectionTask',
  InspectionTask_Document: 'InspectionTask_Document',
  InspectionTask_ExcepSrcPhase: 'InspectionTask_ExcepSrcPhase',
  InspectionTask_Phase: 'InspectionTask_Phase',
  InspectionTaskGroup: 'InspectionTaskGroup',
  InspectionTaskHierarchyTemplate: 'InspectionTaskHierarchyTemplate',
  InspectionTaskProperty: 'InspectionTaskProperty',
  InspectionTaskQualitative: 'InspectionTaskQualitative',
  InspectionTaskQualitativeCatalogFeature: 'InspectionTaskQualitativeCatalogFeature',
  InspectionTaskQualitativeCatalogFeatureTranslation: 'InspectionTaskQualitativeCatalogFeatureTranslation',
  InspectionTaskQuantitative: 'InspectionTaskQuantitative',
  InspectionTaskTranslation: 'InspectionTaskTranslation',
  InterruptionReason: 'InterruptionReason',
  InterruptionReason_Hierarchy: 'InterruptionReason_Hierarchy',
  InterruptionClass: 'InterruptionClass',
  InterruptionClassTranslation: 'InterruptionClassTranslation',
  InterruptionReasonTranslation: 'InterruptionReasonTranslation',
  LdapFieldMapping: 'LdapFieldMapping',
  LdapGroup: 'LdapGroup',
  LdapGroup_Role: 'LdapGroup_Role',
  MaterialDocument: 'MaterialDocument',
  MaterialSensor: 'MaterialSensor',
  Message: 'Message',
  Message_Document: 'Message_Document',
  Message_Hierarchy: 'Message_Hierarchy',
  OperationState: 'OperationState',
  OperationStateLog: 'OperationStateLog',
  OperationStateProperty: 'OperationStateProperty',
  OperationStateTranslation: 'OperationStateTranslation',
  OrderTemplate: 'OrderTemplate',
  PersonnelLog: 'PersonnelLog',
  Phase: 'Phase',
  PhaseTimeout: 'PhaseTimeout',
  PhaseTimeoutReason: 'PhaseTimeoutReason',
  PhaseTimeoutReason_Hierarchy: 'PhaseTimeoutReason_Hierarchy',
  PhaseTimeoutReasonTranslation: 'PhaseTimeoutReasonTranslation',
  PhaseTranslation: 'PhaseTranslation',
  PrintAllocation: 'PrintAllocation',
  PrintAllocation_Printer: 'PrintAllocation_Printer',
  Printer: 'Printer',
  PrinterTranslation: 'PrinterTranslation',
  PrintLabel: 'PrintLabel',
  ProcessDataSource: 'ProcessDataSource',
  ProcessDataConnection: 'ProcessDataConnection',
  ProcessDataPoint: 'ProcessDataPoint',
  ReportType: 'ReportType',
  ReportType_Workplace: 'ReportType_Workplace',
  Role: 'Role',
  Role_Hierarchy: 'Role_Hierarchy',
  Role_Permission: 'Role_Permission',
  Sensor: 'Sensor',
  SensorTranslation: 'SensorTranslation',
  SensorDataLimit: 'SensorDataLimit',
  SensorDataTarget: 'SensorDataTarget',
  Setting: 'Setting',
  StorageArea: 'StorageArea',
  StorageUnit: 'StorageUnit',
  Shift: 'Shift',
  Terminal: 'Terminal',
  TerminalLayout: 'TerminalLayout',
  Transition: 'Transition',
  Transition_active_from_OperationState: 'Transition_active_from_OperationState',
  Transition_CustomAction: 'Transition_CustomAction',
  Transition_from_WorkplaceState: 'Transition_from_WorkplaceState',
  Transition_starting_from_OperationState: 'Transition_starting_from_OperationState',
  TransitionTranslation: 'TransitionTranslation',
  UnitOfMeasure: 'UnitOfMeasure',
  User: 'User',
  UserNotificationConfig: 'UserNotificationConfig',
  UserProperty: 'UserProperty',
  UserRole: 'UserRole',
  WidgetAction: 'WidgetAction',
  WidgetActionTranslation: 'WidgetActionTranslation',
  Workflow: 'Workflow',
  WorkflowProperty: 'WorkflowProperty',
  WorkflowTranslation: 'WorkflowTranslation',
  Workplace: 'Workplace',
  WorkplaceGroup: 'WorkplaceGroup',
  WorkplaceProperty: 'WorkplaceProperty',
  WorkplaceState: 'WorkplaceState',
  WorkplaceStateLog: 'WorkplaceStateLog',
  WorkplaceStateProperty: 'WorkplaceStateProperty',
  WorkplaceStateTranslation: 'WorkplaceStateTranslation',
  WorkplaceStorageRelation: 'WorkplaceStorageRelation',
  YieldType: 'YieldType',
});

export const AuditLogTableRelations = Object.freeze({
  [AuditLogTableName.Batch]: [
    AuditLogTableName.Batch,
    AuditLogTableName.BatchProperty,
  ],
  [AuditLogTableName.CustomAction]: [AuditLogTableName.CustomAction],
  [AuditLogTableName.CustomFunction]: [AuditLogTableName.CustomFunction],
  [AuditLogTableName.CustomProperty]: [
    AuditLogTableName.CustomProperty,
    AuditLogTableName.CustomPropertyTranslation,
  ],
  [AuditLogTableName.Document]: [
    AuditLogTableName.Document,
    AuditLogTableName.Document_Hierarchy,
  ],
  [AuditLogTableName.DocumentType]: [AuditLogTableName.DocumentType],
  [AuditLogTableName.ExternalResource]: [
    AuditLogTableName.ExternalResource,
    AuditLogTableName.ExternalResourceProperty,
    AuditLogTableName.ExternalResource_Workplace,
  ],
  [AuditLogTableName.Fault]: [AuditLogTableName.Fault],
  [AuditLogTableName.FaultCollection]: [AuditLogTableName.FaultCollection],
  [AuditLogTableName.Gateway]: [
    AuditLogTableName.Gateway,
    AuditLogTableName.GatewayProperty,
  ],
  [AuditLogTableName.Hierarchy]: [
    AuditLogTableName.Hierarchy,
    AuditLogTableName.HierarchyTranslation,
  ],
  [AuditLogTableName.InspectionEquipment]: [
    AuditLogTableName.InspectionEquipment,
    AuditLogTableName.InspectionEquipment_CustomFunctions,
  ],
  [AuditLogTableName.InspectionPlan]: [
    AuditLogTableName.InspectionPlan,
    AuditLogTableName.InspectionPlan_Hierarchy,
    AuditLogTableName.InspectionPlan_Material,
    AuditLogTableName.InspectionPlan_Task,
    AuditLogTableName.InspectionPlanHierarchy,
  ],
  [AuditLogTableName.InspectionTask]: [
    AuditLogTableName.InspectionTask,
    AuditLogTableName.InspectionTask_Document,
    AuditLogTableName.InspectionTask_ExcepSrcPhase,
    AuditLogTableName.InspectionTask_Phase,
    AuditLogTableName.InspectionTaskHierarchyTemplate,
    AuditLogTableName.InspectionTaskProperty,
    AuditLogTableName.InspectionTaskQualitative,
    AuditLogTableName.InspectionTaskQualitativeCatalogFeature,
    AuditLogTableName.InspectionTaskQualitativeCatalogFeatureTranslation,
    AuditLogTableName.InspectionTaskTranslation,
  ],
  [AuditLogTableName.InspectionTaskGroup]: [AuditLogTableName.InspectionTaskGroup],
  [AuditLogTableName.InterruptionReason]: [
    AuditLogTableName.InterruptionReason,
    AuditLogTableName.InterruptionReason_Hierarchy,
    AuditLogTableName.InterruptionReasonTranslation,
  ],
  [AuditLogTableName.InterruptionClass]: [
    AuditLogTableName.InterruptionClass,
    AuditLogTableName.InterruptionClassTranslation,
  ],
  [AuditLogTableName.LdapFieldMapping]: [AuditLogTableName.LdapFieldMapping],
  [AuditLogTableName.LdapGroup]: [
    AuditLogTableName.LdapGroup,
    AuditLogTableName.LdapGroup_Role,
  ],
  [AuditLogTableName.MaterialDocument]: [AuditLogTableName.MaterialDocument],
  [AuditLogTableName.MaterialSensor]: [AuditLogTableName.MaterialSensor],
  [AuditLogTableName.Message]: [
    AuditLogTableName.Message,
    AuditLogTableName.Message_Document,
    AuditLogTableName.Message_Hierarchy,
  ],
  [AuditLogTableName.OperationState]: [
    AuditLogTableName.OperationState,
    AuditLogTableName.OperationStateProperty,
    AuditLogTableName.OperationStateTranslation,
  ],
  [AuditLogTableName.OrderTemplate]: [AuditLogTableName.OrderTemplate],
  [AuditLogTableName.OperationStateLog]: [AuditLogTableName.OperationStateLog],
  [AuditLogTableName.PersonnelLog]: [AuditLogTableName.PersonnelLog],
  [AuditLogTableName.Phase]: [
    AuditLogTableName.Phase,
    AuditLogTableName.PhaseTranslation,
  ],
  [AuditLogTableName.PhaseTimeout]: [AuditLogTableName.PhaseTimeout],
  [AuditLogTableName.PhaseTimeoutReason]: [
    AuditLogTableName.PhaseTimeoutReason,
    AuditLogTableName.PhaseTimeoutReason_Hierarchy,
    AuditLogTableName.PhaseTimeoutReasonTranslation,
  ],
  [AuditLogTableName.PrintAllocation]: [
    AuditLogTableName.PrintAllocation,
    AuditLogTableName.PrintAllocation_Printer,
  ],
  [AuditLogTableName.Printer]: [AuditLogTableName.Printer, AuditLogTableName.PrinterTranslation],
  [AuditLogTableName.PrintLabel]: [AuditLogTableName.PrintLabel],
  [AuditLogTableName.ProcessDataConnection]: [AuditLogTableName.ProcessDataConnection],
  [AuditLogTableName.ProcessDataPoint]: [AuditLogTableName.ProcessDataPoint],
  [AuditLogTableName.ProcessDataSource]: [AuditLogTableName.ProcessDataSource],
  [AuditLogTableName.ReportType]: [AuditLogTableName.ReportType, AuditLogTableName.ReportType_Workplace],
  [AuditLogTableName.Role]: [
    AuditLogTableName.Role,
    AuditLogTableName.Role_Hierarchy,
    AuditLogTableName.Role_Permission,
  ],
  [AuditLogTableName.Sensor]: [
    AuditLogTableName.Sensor,
    AuditLogTableName.SensorTranslation,
  ],
  [AuditLogTableName.SensorDataLimit]: [AuditLogTableName.SensorDataLimit],
  [AuditLogTableName.SensorDataTarget]: [AuditLogTableName.SensorDataTarget],
  [AuditLogTableName.Setting]: [AuditLogTableName.Setting],
  [AuditLogTableName.StorageArea]: [AuditLogTableName.StorageArea],
  [AuditLogTableName.StorageUnit]: [AuditLogTableName.StorageUnit],
  [AuditLogTableName.Shift]: [AuditLogTableName.Shift],
  [AuditLogTableName.Terminal]: [AuditLogTableName.Terminal],
  [AuditLogTableName.TerminalLayout]: [AuditLogTableName.TerminalLayout],
  [AuditLogTableName.Transition]: [
    AuditLogTableName.Transition,
    AuditLogTableName.Transition_CustomAction,
    AuditLogTableName.Transition_from_WorkplaceState,
    AuditLogTableName.Transition_active_from_OperationState,
    AuditLogTableName.Transition_starting_from_OperationState,
    AuditLogTableName.TransitionTranslation,
  ],
  [AuditLogTableName.UnitOfMeasure]: [AuditLogTableName.UnitOfMeasure],
  [AuditLogTableName.User]: [
    AuditLogTableName.User,
    AuditLogTableName.UserProperty,
  ],

  [AuditLogTableName.UserNotificationConfig]: [AuditLogTableName.UserNotificationConfig],

  [AuditLogTableName.UserRole]: [AuditLogTableName.UserRole],
  [AuditLogTableName.WidgetAction]: [AuditLogTableName.WidgetAction, AuditLogTableName.WidgetActionTranslation],
  [AuditLogTableName.Workflow]: [
    AuditLogTableName.Workflow,
    AuditLogTableName.WorkflowProperty,
    AuditLogTableName.WorkflowTranslation,
  ],
  [AuditLogTableName.Workplace]: [
    AuditLogTableName.Workplace,
    AuditLogTableName.WorkplaceProperty,
  ],
  [AuditLogTableName.WorkplaceGroup]: [AuditLogTableName.WorkplaceGroup],
  [AuditLogTableName.WorkplaceState]: [
    AuditLogTableName.WorkplaceState,
    AuditLogTableName.WorkplaceStateProperty,
    AuditLogTableName.WorkplaceStateTranslation,
  ],
  [AuditLogTableName.WorkplaceStateLog]: [AuditLogTableName.WorkplaceStateLog],
  [AuditLogTableName.WorkplaceStorageRelation]: [AuditLogTableName.WorkplaceStorageRelation],
  [AuditLogTableName.YieldType]: [AuditLogTableName.YieldType],
});

export type AuditLogData = Array<{
  field: string,
  previousValue?: any,
  nextValue?: any
}>;

export interface AuditLogAdditionalKeys {
  [key: string]: string;
}

export class AuditLog extends BaseModel {
  id: number = 0;
  tableName: string = '';
  key: string = '';
  operation: AuditLogOperation = AuditLogOperation.CREATE;
  data: AuditLogData | null = null;
  username: string = '';
  additionalKeys: AuditLogAdditionalKeys | null = null;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      createdByUser: computed,
      searchableData: computed,
      changedColumns: computed,
    });
  }

  static faIcon = faList;

  searchableProperties = ['username', 'tableName', 'key', 'operation', 'changedColumns', 'searchableData'];

  displayableProperties = [
    displayableProperty({
      key: 'key',
      title: i18n.t('auditLog.model.attributes.key'),
      params: [
        displayablePropertyParam({
          path: 'key',
        }),
      ],
      sorter: (a, b) => sortAlphabetically(a.key, b.key),
    }),
    displayableProperty({
      key: 'createdAt',
      title: i18n.t('auditLog.model.attributes.createdAt'),
      params: [
        displayablePropertyParam({
          path: 'createdAt', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      sorter: (a, b) => sortChronologically(a.createdAt, b.createdAt),
    }),
    displayableProperty({
      key: 'tableName',
      title: i18n.t('auditLog.model.attributes.tableName'),
      params: [
        displayablePropertyParam({
          path: 'tableName',
        }),
      ],
      sorter: (a, b) => sortAlphabetically(a.tableName, b.tableName),
    }),
    displayableProperty({
      key: 'operation',
      title: i18n.t('auditLog.model.attributes.operation'),
      params: [
        displayablePropertyParam({
          path: 'operation',
        }),
      ],
      sorter: (a, b) => sortAlphabetically(a.operation, b.operation),
    }),
    displayableProperty({
      key: 'username',
      title: i18n.t('auditLog.model.attributes.username'),
      params: [
        displayablePropertyParam({
          path: 'username',
        }),
      ],
      sorter: (a, b) => sortAlphabetically(a.username, b.username),
    }),
    displayableProperty({
      key: 'changedColumns',
      title: i18n.t('auditLog.model.attributes.changedColumns'),
      params: [
        displayablePropertyParam({
          path: 'changedColumns',
        }),
      ],
    }),
  ];

  get createdByUser() {
    return this.rootStore.userStore.getById(this.createdBy);
  }

  get searchableData() {
    return this.data?.reduce((acc, curr) => `${acc} ${curr.field} ${curr.previousValue} ${curr.nextValue}`, '');
  }

  get changedColumns() {
    return uniq(this.data?.map((entry) => entry.field)).sort(sortAlphabetically).join(', ');
  }
}
