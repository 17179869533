import { observable, makeObservable, computed } from 'mobx';
import { faBagsShopping } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { BaseModel } from './base';
import i18n from '../i18n/i18n';
import { DisplayablePropertyParams, displayableProperty } from './displayableProperty';
import { displayablePropertyParam } from './displayablePropertyParam';
import { Material } from './material';
import { sortAlphabetically, sortNumerically } from '../components/shared/tables/sorters';
import { RootStore } from '../stores/rootStore';
import { UnitOfMeasurement } from './unitOfMeasurement';
import { Workflow } from './workflow';
import { OperationState } from './operationState';
import { Workplace } from './workplace';
import BooleanValue from '../components/shared/dataDisplay/BooleanValue';
import { transformation } from '../utils/transformations';
import { datetimeFormat } from '../config/dayjs';

export class OrderTemplate extends BaseModel {
  id: number = 0;
  uuid: string = '';
  name: string = '';
  isDefault: boolean = false;
  no: string | null = null;
  materialId?: number | null = undefined;
  unitOfMeasureId?: number | null = undefined;
  plannedQuantity: number | null = null;
  plannedScrap: number | null = null;
  workflowId?: number | null = undefined;
  operationNo: string | null = null;
  operationStateId?: number | null = undefined;
  workplaceId?: number | null = undefined;
  plannedStart: Date | null = null;
  plannedEnd: Date | null = null;
  plannedDurationSeconds: number | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      uuid: observable,
      name: observable,
      isDefault: observable,
      no: observable,
      materialId: observable,
      material: computed,
      unitOfMeasureId: observable,
      unitOfMeasure: computed,
      plannedQuantity: observable,
      plannedScrap: observable,
      workflowId: observable,
      workflow: computed,
      operationNo: observable,
      operationStateId: observable,
      operationState: computed,
      workplaceId: observable,
      workplace: computed,
      plannedStart: observable,
      plannedEnd: observable,
      plannedDurationSeconds: observable,
    });
  }

  get material(): any {
    return this.materialId ? this.rootStore.materialStore.getById(this.materialId) : undefined;
  }

  get unitOfMeasure(): any {
    return this.unitOfMeasureId ? this.rootStore.unitOfMeasurementStore.getById(this.unitOfMeasureId) : undefined;
  }

  get workflow(): any {
    return this.workflowId ? this.rootStore.workflowStore.getById(this.workflowId) : undefined;
  }

  get operationState(): any {
    return this.operationStateId ? this.rootStore.operationStateStore.getById(this.operationStateId) : undefined;
  }

  get workplace(): any {
    return this.workplaceId ? this.rootStore.workplaceStore.getById(this.workplaceId) : undefined;
  }

  static faIcon = faBagsShopping;

  searchableProperties = [];

  readonly saveableProperties = [
    'id',
    'uuid',
    'name',
    'isDefault',
    'no',
    'materialId',
    'unitOfMeasureId',
    'plannedQuantity',
    'plannedScrap',
    'workflowId',
    'operationNo',
    'operationStateId',
    'workplaceId',
    'plannedStart',
    'plannedEnd',
    'plannedDurationSeconds',
  ];

  displayableProperties: DisplayablePropertyParams[] = [
    displayableProperty({
      key: 'name',
      title: i18n.t('orderTemplate.model.attributes.name'),
      params: [displayablePropertyParam({ path: 'name' })],
      sorter: (a, b) => sortAlphabetically(a.name, b.name),
    }),
    displayableProperty({
      key: 'no',
      title: i18n.t('orderTemplate.model.attributes.no'),
      params: [displayablePropertyParam({ path: 'no' })],
      sorter: (a, b) => sortAlphabetically(a.no, b.no),
    }),
    displayableProperty({
      key: 'material',
      title: i18n.t('orderTemplate.model.attributes.material'),
      params: [displayablePropertyParam({ path: 'material.label' })],
    }),
    displayableProperty({
      key: 'unitOfMeasure',
      title: i18n.t('orderTemplate.model.attributes.unitOfMeasure'),
      params: [displayablePropertyParam({ path: 'unitOfMeasure.label' })],
    }),
    displayableProperty({
      key: 'workflow',
      title: i18n.t('orderTemplate.model.attributes.workflow'),
      params: [displayablePropertyParam({ path: 'workflow.label' })],
    }),
    displayableProperty({
      key: 'workplace',
      title: i18n.t('orderTemplate.model.attributes.workplace'),
      params: [displayablePropertyParam({ path: 'workplace.label' })],
    }),
    displayableProperty({
      key: 'operationNo',
      title: i18n.t('orderTemplate.model.attributes.operationNo'),
      params: [displayablePropertyParam({ path: 'operationNo' })],
      sorter: (a, b) => sortAlphabetically(a.operationNo, b.operationNo),
    }),
    displayableProperty({
      key: 'isDefault',
      title: i18n.t('orderTemplate.model.attributes.isDefault.title'),
      params: [
        displayablePropertyParam({
          path: 'isDefault',
          transform: (boolVal) => (<BooleanValue value={boolVal}/>),
        }),
      ],
      raw: true,
      template: '{value}',
    }),
    displayableProperty({
      key: 'plannedQuantity',
      title: i18n.t('orderTemplate.model.attributes.plannedQuantity'),
      params: [displayablePropertyParam({ path: 'plannedQuantity' })],
      sorter: (a, b) => sortNumerically(a.plannedQuantity, b.plannedQuantity),
    }),
    displayableProperty({
      key: 'plannedScrap',
      title: i18n.t('orderTemplate.model.attributes.plannedScrap'),
      params: [displayablePropertyParam({ path: 'plannedScrap' })],
      sorter: (a, b) => sortNumerically(a.plannedScrap, b.plannedScrap),
    }),
    displayableProperty({
      key: 'plannedStart',
      title: i18n.t('orderTemplate.model.attributes.plannedStart'),
      params: [
        displayablePropertyParam({
          path: 'plannedStart',
          transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
    }),
    displayableProperty({
      key: 'plannedEnd',
      title: i18n.t('orderTemplate.model.attributes.plannedEnd'),
      params: [
        displayablePropertyParam({
          path: 'plannedEnd',
          transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
    }),
    displayableProperty({
      key: 'plannedDurationSeconds',
      title: i18n.t('orderTemplate.model.attributes.plannedDurationSeconds'),
      params: [displayablePropertyParam({ path: 'plannedDurationSeconds' })],
      sorter: (a, b) => sortNumerically(a.plannedDurationSeconds, b.plannedDurationSeconds),
    }),
  ];

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);

    const materialPrefix = `${titlePrefix}${i18n.t('material.model.one')} > `;
    allDisplayableProperties.push(
      ...Material.allDisplayableProperties(rootStore, `${pathPrefix}material.`, materialPrefix)
    );

    const unitOfMeasurePrefix = `${titlePrefix}${i18n.t('unitOfMeasurement.model.one')} > `;
    allDisplayableProperties.push(
      ...UnitOfMeasurement.allDisplayableProperties(rootStore, `${pathPrefix}unitOfMeasure.`, unitOfMeasurePrefix)
    );

    const workflowPrefix = `${titlePrefix}${i18n.t('workflow.model.one')} > `;
    allDisplayableProperties.push(
      ...Workflow.allDisplayableProperties(rootStore, `${pathPrefix}workflow.`, workflowPrefix)
    );

    const operationStatePrefix = `${titlePrefix}${i18n.t('operationState.model.one')} > `;
    allDisplayableProperties.push(
      ...OperationState.allDisplayableProperties(rootStore, `${pathPrefix}operationState.`, operationStatePrefix)
    );

    const workplacePrefix = `${titlePrefix}${i18n.t('workplace.model.one')} > `;
    allDisplayableProperties.push(
      ...Workplace.allDisplayableProperties(rootStore, `${pathPrefix}workplace.`, workplacePrefix)
    );

    return allDisplayableProperties;
  }

  static prepareApiPayload(model: Partial<OrderTemplate>): Partial<OrderTemplate> {
    return {
      id: model.id,
      uuid: model.uuid,
      name: model.name,
      isDefault: model.isDefault,
      no: model.no,
      materialId: model.materialId,
      unitOfMeasureId: model.unitOfMeasureId,
      plannedQuantity: model.plannedQuantity,
      plannedScrap: model.plannedScrap,
      workflowId: model.workflowId,
      operationNo: model.operationNo,
      operationStateId: model.operationStateId,
      workplaceId: model.workplaceId,
      plannedStart: model.plannedStart,
      plannedEnd: model.plannedEnd,
      plannedDurationSeconds: model.plannedDurationSeconds,
    };
  }
}
