import { computed, observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore, LoadStrategies } from './entityStore';
import { Message } from '../models/message';
import { RootStore } from './rootStore';

export class OperationMessageStore extends EntityStore<Message> {
  static loadRelevantMessages = 'loadRelevantMessages';

  operationMessages: Message[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'operationMessages', Api.workplaces, Message);

    makeObservable(this, {
      operationMessages: observable,
      isLoadingRelevantMessages: computed,
    });
  }

  get isLoadingRelevantMessages() {
    return this.isActionInProgress(OperationMessageStore.loadRelevantMessages);
  }

  loadRelevantMessages = (workplaceId: number, { operationId }: { operationId: number }, addStrategy = false) => {
    this.operationMessages = [];
    this.loadAll({
      apiEndpoint: OperationMessageStore.loadRelevantMessages,
      strategy: addStrategy ? LoadStrategies.add : LoadStrategies.replace,
      params: {
        id: workplaceId,
        operationId,
      },
      mapper: (e: any) => (super.createModelInstance(e)),
    });
  };
}
