import { computed, observable, makeObservable } from 'mobx';
import { sortBy } from 'lodash';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { InterruptionReason } from '../models/interruptionReason';
import { RootStore } from './rootStore';

export class InterruptionReasonStore extends EntityStore<InterruptionReason> {
  interruptionReasons: InterruptionReason[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'interruptionReasons', Api.interruptionReasons, InterruptionReason);

    makeObservable(this, {
      interruptionReasons: observable,
      nextAvailableSortOrder: computed,
      quickstopInterruptionReasons: computed,
      reasonsForSelectedWorkplace: computed,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.interruptionClassStore,
        modelId: 'interruptionClassIds',
      },
    ];
  }

  get nextAvailableSortOrder() {
    /**
     * searches the first entry, for which the successor is not current.sortOrder + 1
     * precondition: sorted interruption reason array
     *
     * @return boolean
     */
    const findEntryWithFreeSuccessor = (current: InterruptionReason) => this.interruptionReasons
      .findIndex((i) => i.sortOrder === (current.sortOrder ?? 0) + 1) < 0;

    const next = this.interruptionReasons
      .filter((interruptionReason) => InterruptionReason.hasSortOrder(interruptionReason))
      .sort((a, b) => (a.sortOrder ?? 0) - (b.sortOrder ?? 0))
      .find(findEntryWithFreeSuccessor);

    if (!next) {
      return 0;
    }

    return (next.sortOrder ?? 0) + 1;
  }

  get reasonsForSelectedWorkplace() {
    if (this.rootStore.workplaceStore.selectedWorkplace?.hierarchyId) {
      return this.getReasonsByHierarchyId(this.rootStore.workplaceStore.selectedWorkplace?.hierarchyId);
    }
    return sortBy(this.interruptionReasons, ['sortOrder']);
  }

  get quickstopInterruptionReasons() {
    return this.reasonsForSelectedWorkplace.filter((entry) => entry.isQuickstop);
  }

  getReasonsByHierarchyId(hierarchyId: number) {
    let reasons: InterruptionReason[] = [];
    if (hierarchyId) {
      reasons = this.interruptionReasons;
      const pathIds = this.rootStore.hierarchyStore.getPathIds(hierarchyId);

      reasons = reasons.filter(
        (reason) => reason.hierarchyIds.length === 0 || reason.hierarchyIds.some((id) => pathIds.includes(id))
      );
    }
    reasons = reasons.filter((reason) => reason.archivedAt === null);
    return sortBy(reasons, ['sortOrder']);
  }

  getReasonsByHierarchyIdAndClassId(
    hierarchyId: number | undefined,
    classId: number | undefined,
    includeQuickstopReasons: boolean = false
  ): InterruptionReason[] {
    let reasons: InterruptionReason[] = this.interruptionReasons;

    if (classId) {
      reasons = reasons.filter(
        (reason) => reason.interruptionClassIds.length === 0 || reason.interruptionClassIds.includes(classId)
      );
    }

    if (hierarchyId) {
      const pathIds = this.rootStore.hierarchyStore.getPathIds(hierarchyId);

      reasons = reasons.filter(
        (reason) => reason.hierarchyIds.length === 0 || reason.hierarchyIds.some((id) => pathIds.includes(id))
      );
    }

    reasons = reasons.filter(
      (reason) => reason.archivedAt === null && (includeQuickstopReasons || !reason.isQuickstop)
    );
    return sortBy(reasons, ['sortOrder']);
  }
}
