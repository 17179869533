import {isDarkBackground} from '../../../utils/color';

export const visItemStyle = ({
  color: stateColor,
  isSelected = false,
  isError = false,
  zIndex = 'auto',
  display = 'inherit',
}) => {
  let borderColor = stateColor;
  let borderSize = 1;
  if (isError) {
    borderColor = 'red';
    borderSize = 3;
  }

  const textColor = isDarkBackground(stateColor) ? '#ffffff' : '#000000';
  return `background-color: ${stateColor}${isSelected ? '' : 'b3'}; `
    + `display: ${display};`
    + `color: ${textColor}; `
    + 'border-radius: 5px; '
    + `border: ${borderSize}px solid ${borderColor};`
    + 'cursor: pointer;'
    + `z-index: ${zIndex};`;
};
