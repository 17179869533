import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useMount} from '../../../hooks/useMount';
import {useStore} from '../../../hooks/useStore';
import Table from '../../shared/tables/Table';

const YieldConsumptionTable = ({
  operations,
  yieldColumns,
  yieldSortAttribute,
  yieldSortOrder,
  consumptionColumns,
  consumptionSortAttribute,
  consumptionSortOrder,
}) => {
  const store = useStore();
  const [expandedKeys, setExpandedKeys] = useState([]);

  useMount(() => {
    store.yieldStore.loadByOperation(operations.map((operation) => operation.id)).then(() => {
      store.consumptionLogStore.loadByTargetYieldIds(
        store.yieldStore.getByOperations(operations.map((operation) => operation.id))
          .map((yie) => yie.id)
      );
    });
    store.preparationStore.loadRunningByOperation(operations.map((operation) => operation.id)).then(() => {
      store.consumptionLogStore.loadByPreparationIds(
        store.preparationStore.getRunningByOperations(operations.map((operation) => operation.id))
          .map((preparation) => preparation.id)
      );
    });
  }, []);

  const yields = store.yieldStore.getByOperations(operations.map((operation) => operation.id));
  const preparations = store.preparationStore.getRunningByOperations(operations.map((operation) => operation.id));
  const consumptions = [
    ...store.consumptionLogStore.getByTargetYieldIds(yields.map((yie) => yie.id)),
    ...store.consumptionLogStore.getByPreparationIds(preparations.map((preparation) => preparation.id)),
  ];
  // Add all preparations as a partial yield to the yields array, so that they can be displayed in the table
  // Some fields may be empty, but that has to be expected since those have not been fully processed yet
  preparations.forEach((preparation) => {
    yields.push({preparation});
  });

  const isLoading = store.preparationStore.areCollectionOrDependenciesLoading
    || store.consumptionLogStore.areCollectionOrDependenciesLoading
    || store.yieldStore.areCollectionOrDependenciesLoading;

  const expandedRowRender = (yie) => (
    <Table
      rowKey={'id'}
      columns={consumptionColumns.map((column) => ({
        ...column,
        defaultSortOrder: column.key === consumptionSortAttribute ? consumptionSortOrder : undefined,
      }))}
      dataSource={consumptions.filter((con) =>
        (con.targetYieldId ? con.targetYieldId === yie.id : con.preparationId === yie.preparation?.id))}
      showActions={false}
      pagination={false}
    />
  );

  return (
    <Table
      rowKey={(yie) => (yie.preparation?.id && `prep-${yie.preparation.id}`) || `yield-${yie.id}`}
      columns={yieldColumns.map((column) => ({
        ...column,
        defaultSortOrder: column.key === yieldSortAttribute ? yieldSortOrder : undefined,
      }))}
      pagination={false}
      dataSource={yields}
      expandable={consumptionColumns && {
        expandedRowRender,
        expandedRowKeys: expandedKeys,
        onExpandedRowsChange: (keys) => setExpandedKeys(keys),
      }}
      loading={isLoading}
      tableLayout={'fixed'}
      childrenColumnName={'not-children'}
    />
  );
};

export default observer(YieldConsumptionTable);
