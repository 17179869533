import { get, identity } from 'lodash';
import { DisplayablePropertyParam } from './displayablePropertyParam';

const format = require('string-template');

/**
 * @param {string}                           title     - The title of the property
 * @param {Array.<DisplayablePropertyParam>} params    - An array of DisplayablePropertyParam
 * @param {string}                           template  - A string template in which displayable
 *                                                       param names can be rendered
 * @param {string}                           key       - The key of the property
 * @param {boolean}                          raw       - Interpret value as raw (no string format)
 * @param {function(a,b):number}             sorter    - sort-function for table columns
 * @param {boolean}                          disabled  - to disable a property
 * @param {{text: string, value: string}[]}  filters   - Filter menu config
 * @param {function(value,record):boolean}   onFilter  - Function that determines if the row is displayed when filtered
 * @param {{}}                         customProperty  - The custom property of the object to display
 * @param {string}                     propertiesPath  - The path to the properties
 * @param {((text: string, record: any) => string)|undefined} renderText
 *   A function that guarantees a string, used in exports
 * @return {Readonly<{DisplayableProperty}>}
 */

export interface DisplayablePropertyParams {
  title: string;
  key: string;
  params?: DisplayablePropertyParam[];
  template?: string;
  raw?: boolean;
  sorter?: (a: any, b: any) => number;
  disabled?: boolean;
  filters?: { text: string, value: string }[];
  onFilter?: (value: any, record: any) => boolean;
  customProperty?: any;
  propertiesPath?: string;
  renderText?: (text: any, record: any) => string;
  align?: string;
  defaultSortOrder?: string;
}

export interface DisplayableProperty extends DisplayablePropertyParams {
  render: (text: any, record: any, index: any) => { children: any };
}

export const displayableProperty = ({
  title,
  key,
  params,
  template,
  raw,
  sorter,
  disabled,
  filters,
  onFilter,
  customProperty,
  propertiesPath,
  renderText,
}: DisplayablePropertyParams): DisplayableProperty =>
  /**
   * @typedef {Object}                            DisplayableProperty
   * @property {string}                           title
   * @property {Array.<DisplayablePropertyParam>} params
   * @property {string}                           template
   * @property {string}                           key
   * @property {boolean}                          raw
   * @property {function(a,b):number}             sorter
   * @property {boolean}                          disabled
   * @property {{text: string, value: string}[]}  filters
   * @property {function(value,record):boolean}   onFilter
   * @property {{}}                               customProperty
   * @property {string}                           propertiesPath
   */
  Object.freeze({
    title,
    params,
    template,
    key,
    raw,
    sorter,
    disabled,
    filters,
    onFilter,
    customProperty,
    propertiesPath,
    render(text: any, record: any) {
      const renderRaw = this.raw || false;
      const renderTemplate = this.template || '{value}';
      const resolvedParams = this.params?.map((param: DisplayablePropertyParam) => {
        if (!param.as || !renderTemplate.includes(param.as)) {
          param.as = 'value';
        }

        const transform = param.transform && (typeof param.transform) === 'function' ? param.transform : identity;

        // Special case: interpret "."
        if (param.path === '.') {
          return { [param.as]: transform(record) };
        }

        // Special case: interpret "customProperty"
        if (this.customProperty) {
          return {
            [param.as]: transform({
              properties: get(record, this.propertiesPath?.replace(/\.+$/, '') || '', {}),
              availableProperty: this.customProperty,
            }),
          };
        }
        // Remove any trailing "." characters. This allows to pass only the nested component, if path is defined as ''.
        return { [param.as]: param.path ? transform(get(record, param.path.replace(/\.+$/, ''), '')) : '' };
      });

      // @ts-ignore TODO: fix this
      const mergedParams = Object.assign(...resolvedParams);

      return {
        children: renderRaw ? mergedParams.value : format(renderTemplate, mergedParams),
      };
    },
    renderText(text: any, record: any) {
      const splitKey = this.key?.split('.') || [];
      const path = splitKey.slice(0, splitKey.length - 1);
      const resolvedField = path.length ? get(record, path) : record;
      if (renderText) {
        return renderText(text, resolvedField);
      }
      const renderResult = this.render(text, record);
      if (typeof renderResult.children === 'string') {
        return renderResult.children;
      }
      // Should handle all default cases where no params are available and the key corresponds with an observable
      // or computed value, including custom properties.
      return get(record, this.key, '');
    },
  });
