import { faCog, faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import styles from './Widget.module.scss';
import WidgetSettingsModal from './WidgetSettingsModal';
import AccessDeniedAlert from '../alert/AccessDeniedAlert';
import PinButton from '../buttons/PinButton';
import DeleteButton from '../buttons/DeleteButton';
import { useModulePolicy } from '../../../hooks/useModulePolicy';
import { useScopes } from '../../../hooks/useScopes';
import { useStore } from '../../../hooks/useStore';
import { platformConfigurator } from '../../../models/scope';
import WidgetManualButton from '../../manual/WidgetManualButton';
import { WidgetLayout } from '../../../models/widgetLayout';
import WidgetActionContainer from './widgetActions/WidgetActionContainer';

export type WidgetImplProps = {
  identifier: string;
  icon: IconDefinition;
  defaultLayout: WidgetLayout;
};

export type WidgetProps = {
  title: string;
  actionsLeft?: React.ReactNode;
  children?: React.ReactNode;
  onHeadClick?: () => void;
  widgetClassNames?: string[];
  headerClassNames?: string[];
  widgetConfig: any;
  additionalWidgetConfigPermission?: string;
  createOrUpdateSettings: (settingValues: any) => void;
  isEditMode?: boolean;
  configureEnabled?: boolean;
  showPin?: boolean;
  isPinable?: boolean;
  onPinWidget?: (isPinned: boolean) => void;
  minimized?: boolean;
  isDeletable?: boolean;
  onDeleteWidget?: () => void;
  manualPath?: string;
};

const Widget: React.FC<WidgetProps> = ({
  title,
  actionsLeft,
  children,
  onHeadClick,
  widgetClassNames = [],
  headerClassNames = [],
  widgetConfig,
  additionalWidgetConfigPermission,
  createOrUpdateSettings,
  isEditMode = true,
  configureEnabled = true,
  showPin = false,
  isPinable = false,
  onPinWidget = () => {},
  minimized = false,
  isDeletable = false,
  onDeleteWidget,
  manualPath,
  ...props
}) => {
  const store = useStore();
  const scopes = useScopes();
  const policy = useModulePolicy(store, scopes);
  const platformConfiguratorPolicy = useModulePolicy(store, [platformConfigurator]);

  const [isEditingWidget, setIsEditingWidget] = useState(false);
  const { t } = useTranslation();
  const configureButtonVisibility = isEditMode
    && platformConfiguratorPolicy.canEdit()
    && (additionalWidgetConfigPermission === undefined || additionalWidgetConfigPermission)
    && (widgetConfig !== undefined);

  if (!policy.canView()) {
    return <AccessDeniedAlert missingPermissions={scopes}/>;
  }

  const defaultHeaderClassNames = [styles.widgetHead];
  if (onHeadClick) {
    defaultHeaderClassNames.push(styles.withClickAction);
  }

  const onSubmitSettings = (settingValues: any) => {
    createOrUpdateSettings(settingValues);
    setIsEditingWidget(false);
  };

  return (
    <>
      <div className={[styles.widget, ...widgetClassNames].join(' ')} {...props}>
        <div
          className={[...defaultHeaderClassNames, ...headerClassNames].join(' ')}
          onClick={onHeadClick}
          role="button"
          tabIndex={0}
        >
          <div className={styles.titleWrapper}>
            <div className={styles.widgetHeadLeftActions}>
              {actionsLeft || <FontAwesomeIcon size="xs" icon={faInfo}/>}
            </div>
            <div className={styles.widgetHeadTitle}>{title}</div>
          </div>
          <div className={styles.widgetHeadRightActions}>
            {configureButtonVisibility && (
              <div>
                <Button
                  disabled={!configureEnabled}
                  type="link"
                  style={{ padding: 0 }}
                  onClick={() => setIsEditingWidget(true)}
                >
                  <FontAwesomeIcon icon={faCog}/>
                  <span style={{
                    fontWeight: '500',
                    marginLeft: '0.5em',
                  }}
                  >
                    {t('widget.configure')}
                  </span>
                </Button>
              </div>
            )}
            {isEditMode && showPin && (
              <PinButton
                disabled={!isPinable}
                pinned={!minimized}
                onPinned={onPinWidget}
                style={{ width: '2em', textAlign: 'right' }}
              />
            )}
            {isEditMode && isDeletable && (
              <DeleteButton onConfirm={onDeleteWidget}/>
            )}
            {manualPath && (
              <div>
                <WidgetManualButton manualPath={manualPath}/>
              </div>
            )}
          </div>
        </div>
        <div className={styles.widgetBody}>
          {widgetConfig ? (
            <WidgetActionContainer
              widgetConfig={widgetConfig}
            />
          ) : null}
          <div className={styles.innerWidgetBody}>
            {children}
          </div>
        </div>
      </div>
      {isEditingWidget && (
        <WidgetSettingsModal
          widgetConfig={widgetConfig}
          onCancel={() => setIsEditingWidget(false)}
          onOk={onSubmitSettings}
        />
      )}
    </>

  );
};

export default observer(Widget);
