import {observer} from 'mobx-react-lite';
import {groupBy, sumBy} from 'lodash';
import {useMount} from '../../../hooks/useMount';
import ComponentTable from './ComponentTable';
import {useStore} from '../../../hooks/useStore';

const OrderMaterialTable = ({orderId, columns}) => {
  const store = useStore();

  useMount(() => {
    store.componentStore.loadByOrderId(orderId);
  }, [orderId]);

  const components = store.componentStore.filterByOrderId(orderId);

  const grouped = groupBy(components, (c) => `${c.materialId}-${c.unitOfMeasureId}`);
  const aggregated = Object.entries(grouped).map(([materialId, groupedComponents]) => ({
    ...groupedComponents[0],
    id: `${orderId}_${materialId}`,
    orderId,
    plannedQuantity: sumBy(groupedComponents, 'plannedQuantity'),
    actualQuantity: sumBy(groupedComponents, 'actualQuantity'),
    preparationQuantity: sumBy(groupedComponents, 'preparationQuantity'),
    material: groupedComponents[0].material,
    operation: groupedComponents[0].operation,
    unitOfMeasure: groupedComponents[0].unitOfMeasure,
    info: groupedComponents[0].info,
  }));

  return (
    <ComponentTable
      columns={columns}
      components={aggregated}
      loading={store.componentStore.isLoadingCollection}
    />
  );
};

export default observer(OrderMaterialTable);
