import {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faProjectDiagram} from '@fortawesome/free-solid-svg-icons';
import {useMount} from '../../hooks/useMount';
import {WidgetLayouts} from '../shared/widgets/WidgetLayouts';
import {StateTransitionWidgetDetailModal} from './StateTransitionWidgetDetailModal';
import {WidgetLayout} from '../../models/widgetLayout';
import {StateTransitionWidgetConfig} from './StateTransitionWidgetConfig';
import OperatorWidget from '../operator/shared/OperatorWidget';
import WidgetButtons from './StateTransitionButtons';
import {useStore} from '../../hooks/useStore';
import {ScopeContext} from '../../policies/scopeContext';
import {transition} from '../../models/scope';
import {useModulePolicy} from '../../hooks/useModulePolicy';
import {useStateTransitionChanged} from '../../hooks/useStateTransitionChanged';
import appConfig from '../../utils/appConfig';

const StateTransitionWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const scopes = [transition];
  const policy = useModulePolicy(store, scopes);

  const [modalVisible, setModalVisible] = useState(false);
  const [transitionId, setTransitionId] = useState(null);
  const [lastStateChange, setLastStateChange] = useState(null);
  const [lastOperationStateChange, setLastOperationStateChange] = useState(null);
  const [validTransition, setValidTransitions] = useState([]);
  const stateTransitionChanged = useStateTransitionChanged(store);

  const widgetConfig = useMemo(() =>
    new StateTransitionWidgetConfig(store, StateTransitionWidget.identifier), []);

  const loadValidTransitions = () => {
    if (!store.workplaceStore.selectedWorkplace) {
      return;
    }
    store.validTransitionsStore.loadValidTransitionsByWorkplace(
      store.workplaceStore.selectedWorkplace.id
    ).catch(() => {
      // clear the valid transitions if an error occurs when loading the valid transitions
      store.validTransitionsStore.transitions.clear();
    });
  };

  const openModal = (model) => {
    setTransitionId(model.id);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  useMount(() => {
    if (!store.workplaceStore.selectedWorkplace) {
      return;
    }
    const latestWorkplaceLog = store.workplaceStateLogStore
      .getLatestOfWorkplace(store.workplaceStore.selectedWorkplace.id);
    let transitions = [];
    if (latestWorkplaceLog) {
      const latestOperationLog = store.operationStateLogStore
        .getLatestOfWorkplace(store.workplaceStore.selectedWorkplace.id);

      const currentWorkplaceState = latestWorkplaceLog
        ? store.workplaceStateStore.getById(latestWorkplaceLog.stateId)
        : undefined;
      const activeOperationState = latestOperationLog
        ? store.operationStateStore.getById(latestOperationLog.stateId)
        : undefined;

      if (currentWorkplaceState?.isInterruption || activeOperationState?.isInterruption) {
        transitions = [];
      } else {
        transitions = store.validTransitionsStore.transitions.filter((trans) => (
          !store.operationStateStore.getById(trans.activeOperationToStateId)?.isInterruption
          && !store.workplaceStateStore.getById(trans.workplaceToStateId)?.isInterruption
        ));
      }
      setValidTransitions(transitions);
    }
    setValidTransitions(transitions);
  }, [
    store.workplaceStore.selectedWorkplace?.id,
    JSON.stringify(store.validTransitionsStore.transitions.map((trans) => trans.id)),
    store.operationStateLogStore.logs.length,
    store.workplaceStateLogStore.logs.length,
    store.operationStateStore.operationStates.length,
    store.workplaceStateStore.workplaceStates.length,
  ]);

  useMount(() => {
    const workplaceId = store.workplaceStore.selectedWorkplace ? store.workplaceStore.selectedWorkplace.id : null;
    if (workplaceId) {
      let latestStateChange = store.workplaceStateLogStore.getLatestOfWorkplace(workplaceId);
      const latestOperationStateChange = store.operationStateLogStore.getLatestOfWorkplace(workplaceId);

      if (
        latestOperationStateChange
        && latestStateChange
        && latestStateChange.start < latestOperationStateChange.start
      ) {
        latestStateChange = latestOperationStateChange;
      }
      setLastStateChange(latestStateChange);
      setLastOperationStateChange(latestOperationStateChange);
    }
  }, [
    store.workplaceStore.selectedWorkplace?.id,
    store.operationStore.operations.length,
    store.operationStateLogStore.logs.length,
    store.workplaceStateLogStore.logs.length,
  ]);

  useMount(() => {
    loadValidTransitions();
  }, [store.operationStore.active?.id, store.operationStore.active?.stateId, stateTransitionChanged]);

  useMount(() => {
    if (!appConfig.modules.enablePhaseTimeout || lastOperationStateChange?.end
      || !lastOperationStateChange?.state?.phaseId || !lastOperationStateChange.operation?.order?.id) {
      return;
    }
    store.phaseTimeoutStore.loadAll({
      params: {
        workflowId: lastOperationStateChange.operation.workflowId
          || lastOperationStateChange.operation.order.workflowId,
        phaseId: lastOperationStateChange.state.phaseId,
      },
    });
    store.phaseTimeoutReasonStore.loadAll({
      params: {
        hierarchyId: store.workplaceStore.selectedWorkplace.hierarchyId,
      },
    });
  }, [lastOperationStateChange?.operation?.order?.id, lastOperationStateChange?.state?.id]);

  return (
    <ScopeContext.Provider value={scopes}>
      <OperatorWidget
        icon={<FontAwesomeIcon icon={faProjectDiagram}/>}
        title={widgetConfig.getWidgetTitle()}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        manualPath={'/workflow/state-transition'}
      >
        <WidgetButtons
          size={'large'}
          models={validTransition}
          loading={
            store.validTransitionsStore.isLoadingAllValidTransitions
            || store.operationStore.isLoadingActiveOperation
            || store.operationStateStore.isLoading()
            || store.workplaceStateStore.isLoading()
          }
          onClick={openModal}
          disabled={!policy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})}
        />
      </OperatorWidget>
      {modalVisible && (
        <StateTransitionWidgetDetailModal
          onCancel={closeModal}
          defaultTransitionId={transitionId}
          lastStateChange={lastStateChange}
        />
      )}
    </ScopeContext.Provider>
  );
};

StateTransitionWidget.icon = faProjectDiagram;

StateTransitionWidget.identifier = 'StateTransitionWidget';
StateTransitionWidget.defaultLayout = new WidgetLayout({
  identifier: StateTransitionWidget.identifier,
  x: 2,
  y: 0,
  height: 4,
  minHeight: 1,
  width: WidgetLayouts.sixthWidth.w,
  minWidth: WidgetLayouts.sixthWidth.minW,
});

export default observer(StateTransitionWidget);
