import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';
import { useMount } from '../../../../hooks/useMount';
import styles from './WebcamModal.module.scss';
import Spinner from '../../spinners/Spinner';
import Button from '../../buttons/Button';
import { useStore } from '../../../../hooks/useStore';
import Modal from '../../modal/Modal';

export type WebcamModalProps = {
  open?: boolean;
  onCancel: () => void;
  onSave: (data: string) => void;
};

const VIDEO_ID = 'multi-image-video';

const CANVAS_ID = 'multi-image-canvas';

const WebcamModal: React.FC<WebcamModalProps> = ({ open = false, onCancel, onSave }) => {
  const streamRef = useRef<MediaStream | undefined>();
  const [playing, setPlaying] = useState(false);
  const store = useStore();
  const { t } = useTranslation();

  useMount(() => {
    if (!open) {
      return () => {};
    }

    let stream;
    (async () => {
      const video = document.getElementById(VIDEO_ID) as HTMLVideoElement | null;
      const canvas = document.getElementById(CANVAS_ID) as HTMLCanvasElement | null;
      if (!video || !canvas) {
        return;
      }
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          // Full HD with 3:2 aspect ratio, prefer rear facing camera
          video: { width: { ideal: 1620 }, height: { ideal: 1080 }, facingMode: { ideal: 'environment' } },
          audio: false,
        });
        video.srcObject = stream;
        streamRef.current = stream;
        await video.play();

        video.setAttribute('width', String(video.videoWidth));
        video.setAttribute('height', String(video.videoHeight));
        canvas.setAttribute('width', String(video.videoWidth));
        canvas.setAttribute('height', String(video.videoHeight));
        setPlaying(true);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('An error occurred when trying to load webcam feed');
      }
    })();

    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
        setPlaying(false);
      }
    };
  }, [open]);

  const handleCapture = () => {
    const video = document.getElementById(VIDEO_ID) as HTMLVideoElement | null;
    const canvas = document.getElementById(CANVAS_ID) as HTMLCanvasElement | null;
    if (!canvas || !video || !playing) {
      return;
    }
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, video.width, video.height);
    const data = canvas.toDataURL('image/jpeg');

    if (onSave) {
      onSave(data);
    }
  };

  useMount(() => {
    if (!playing) {
      return () => {};
    }
    const handler = debounce((e: Event) => {
      const event = e as unknown as React.KeyboardEvent;
      if (event.code === 'Space') {
        handleCapture();
      }
    }, 250, { leading: true });
    window.addEventListener('keypress', handler);
    return () => window.removeEventListener('keypress', handler);
  }, [playing]);

  return (
    <Modal
      fullscreen={store.clientStore.isMobile}
      open={open}
      onCancel={onCancel}
      footer={null}
      title={(
        <>
          <FontAwesomeIcon icon={faCamera}/>
          {' '}
          {t('input.multiImageUpload.modal.title')}
        </>
      )}
      forceRender
    >
      <div className={styles.videoContainer}>
        {!playing && (
          <Spinner fullWidth fullHeight/>
        )}
        <canvas className={styles.canvas} id={'multi-image-canvas'}/>
        <video playsInline id={'multi-image-video'} className={`${styles.video} ${!playing && styles.videoHidden}`}>
          Your browser does not support video.
        </video>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          type={'primary'}
          icon={<FontAwesomeIcon icon={faCamera}/>}
          onClick={handleCapture}
          autoFocus
        >
          {t('input.multiImageUpload.modal.capture')}
        </Button>
      </div>
    </Modal>
  );
};

export default WebcamModal;
