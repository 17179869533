import {useState} from 'react';
import {Radio, Space} from 'antd';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faIndustry, faServer} from '@fortawesome/free-solid-svg-icons';
import {useMount} from '../../../hooks/useMount';
import {useStore} from '../../../hooks/useStore';
import InspectionTaskOverview from './InspectionTaskOverview';
import Spinner from '../../shared/spinners/Spinner';

const MODE_WORKPLACE = 'workplace';
const MODE_ORDER = 'order';

const InspectionTaskOverviewByWorkplace = ({workplaceId, selectPendingTask}) => {
  const {t} = useTranslation();
  const [mode, setMode] = useState(MODE_ORDER);
  const store = useStore();

  useMount(() => {
    store.phaseStore.loadAll();
  });

  useMount(() => {
    if (store.operationStore.active?.id) {
      store.inspectionTaskStore.loadAllWithDependencies({
        params: {
          workplaceId,
          orderId: store.operationStore.active.orderId,
          validAt: dayjs().toISOString(),
          includeTasksFromSiblingVirtualWorkplaces: true,
        },
      });
      store.inspectionTaskStore.loadAllWithDependencies({
        params: {
          workplaceId,
          orderId: 'null',
          validAt: dayjs().toISOString(),
          includeTasksFromSiblingVirtualWorkplaces: true,
        },
      });
      store.operationPhaseStore.loadByOperation(store.operationStore.active.id);
    }
  }, [store.operationStore.active?.id]);

  const expectedOrderId = mode === MODE_ORDER ? store.operationStore.active?.orderId : null;
  const inspectionTasks = store.inspectionTaskStore.getValidByWorkplaceIdAndOrderId(workplaceId, expectedOrderId);

  const modeOptions = [
    {
      label: (
        <Space>
          <FontAwesomeIcon icon={faServer}/>
          {t('order.model.one')}
        </Space>
      ),
      value: MODE_ORDER,
    },
    {
      label: (
        <Space>
          <FontAwesomeIcon icon={faIndustry}/>
          {t('workplace.model.one')}
        </Space>
      ),
      value: MODE_WORKPLACE,
    },
  ];

  const isLoading = store.inspectionTaskStore.isLoadingCollection || store.inspectionLotStore.isLoadingCollection;

  return (
    <Space direction="vertical" style={{width: '100%'}}>
      <Radio.Group
        onChange={(e) => setMode(e.target.value)}
        value={mode}
        optionType={'button'}
        options={modeOptions}
        disabled={isLoading}
      />
      {isLoading && <Spinner fullWidth/>}
      {!isLoading && <InspectionTaskOverview selectPendingTask={selectPendingTask} inspectionTasks={inspectionTasks}/>}
    </Space>
  );
};

export default observer(InspectionTaskOverviewByWorkplace);
