import {useContext, useRef, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Col} from 'antd';
import {useTranslation} from 'react-i18next';
import Form from '../../shared/form/Form';
import Input from '../../shared/inputs/Input';
import {ValidationRules} from '../../../utils/validationRules';
import InspectionTaskExecutionForm from './InspectionTaskExecutionForm';
import Select from '../../shared/inputs/Select';
import Radio from '../../shared/inputs/Radio';
import {InspectionTaskContext} from './InspectionTaskExecutionModal';
import {InspectionTaskResultValuation} from '../../../models/inspectionTaskResult';
import {useMount} from '../../../hooks/useMount';

const TargetValue = ({task, disabled, onSubmit, minRemarkLength}) => {
  const {t} = useTranslation();
  const {key, inspectionTask, inspectionTaskPending} = task;
  const {catalogFeatures, targetValue} = inspectionTask.qualitative;
  const [form] = Form.useForm();
  const inspectionTaskContext = useContext(InspectionTaskContext).contextValue;
  const [validateStatus, setValidateStatus] = useState({});
  const [remarkRequired, setRemarkRequired] = useState(false);
  const [overruleActive, setOverruleActive] = useState(false);
  const [overruleType, setOverruleType] = useState();
  const [overruledUserId, setOverruledUserId] = useState(null);
  const [overruledRemarkRequired, setOverruledRemarkRequired] = useState(false);

  const results = inspectionTaskContext.rootForm.getFieldValue('results') || {};
  const [submittable, setSubmittable] = useState(results[key] !== undefined);
  const inputRef = useRef();

  useMount(() => {
    inputRef.current.focus();
  });

  const transform = (values) => {
    const valuation = values.enteredValue === targetValue
      ? InspectionTaskResultValuation.accepted
      : InspectionTaskResultValuation.rejected;
    const feature = inspectionTask.qualitative.catalogFeatures.find((f) => f.valuation === valuation);
    const overruleFeature = inspectionTask.qualitative.catalogFeatures.find(
      (f) => f.valuation === InspectionTaskResultValuation.accepted && f.isOverrule
    );
    return {
      valuation,
      value: feature.code,
      remark: `Input: ${values.enteredValue || ''} | ${values.remark || ''}`,
      overruledValue: overruleFeature?.code,
      overruledValuation: overruleFeature?.valuation,
      overruledRemark: values.overruledRemark || values.fourEyesRemark || '',
      overruledUserId,
      inspectionTaskId: inspectionTask.id,
      inspectionTaskPendingId: inspectionTaskPending?.id,
      internal: {
        featureId: feature.id,
        overruleFeatureId: overruleFeature?.id,
        enteredValue: values.enteredValue,
      },
    };
  };

  const getValidateStatus = (value) => {
    if (value === targetValue) {
      return {
        validateStatus: 'success',
      };
    }

    return {
      validateStatus: 'warning',
      help: t('inspectionTask.targetValue.deviating'),
    };
  };

  const FeatureColumn = ({name, isOverrule = false}) => {
    const relevantCatalogFeatures = catalogFeatures.filter(
      (feature) => (feature.isOverrule === isOverrule)
    );

    return (
      <Col span={24}>
        <Form.Item
          wrapperCol={{span: 24}}
          name={name}
          style={{marginBottom: 0}}
          rules={[ValidationRules.required()]}
        >
          {relevantCatalogFeatures.length > 5 ? (
            <Select
              style={{width: '100%'}}
              disabled={disabled || (!isOverrule && overruleActive)}
            >
              {relevantCatalogFeatures.map((feature) => (
                <Select.Option key={feature.id} value={feature.id}>
                  {feature.text}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Radio.Group disabled={disabled || (!isOverrule && overruleActive)}>
              {relevantCatalogFeatures.map((feature) => (
                <Radio.Button
                  key={feature.id}
                  value={feature.id}
                  disabled={disabled}
                >
                  {feature.text}
                </Radio.Button>
              ))}
            </Radio.Group>
          )}
        </Form.Item>
      </Col>
    );
  };

  const initialValues = {
    remark: results[key]?.remark || null,
    overruledRemark: results[key]?.overruledRemark || null,
    selectedOverruleFeatureId: results[key]?.internal.overruleFeatureId || null,
    enteredValue: results[key]?.internal.enteredValue || '',
  };

  return (
    <InspectionTaskExecutionForm
      name={key}
      form={form}
      initialValues={initialValues}
      onValuesChange={({enteredValue, selectedOverruleFeatureId}) => {
        if (enteredValue) {
          if (enteredValue.length) {
            const state = getValidateStatus(enteredValue);
            setValidateStatus(state);
            setRemarkRequired(state.validateStatus === 'warning');
            setSubmittable(!(inspectionTask.mustBeAccepted && enteredValue !== targetValue));
          }
        }

        const selectedOverruleFeature = catalogFeatures.find((feature) => feature.id === selectedOverruleFeatureId);
        if (selectedOverruleFeature) {
          setRemarkRequired(!(selectedOverruleFeature));
          setOverruledRemarkRequired(inspectionTask.isOverruleAllowed && selectedOverruleFeature);
          setSubmittable(true);
        }
      }}
      onFinish={() => {
        const formResults = {...results};
        formResults[key] = transform(form.getFieldsValue(true));
        inspectionTaskContext.rootForm.setFieldsValue({results: formResults});
        onSubmit(formResults[key]);
      }}
      remarkRequired={remarkRequired && !overruleActive}
      minRemarkLength={minRemarkLength}
      overruledRemarkRequired={overruledRemarkRequired}
      overruleActive={[overruleActive, setOverruleActive]}
      overruleType={[overruleType, setOverruleType]}
      setOverruleUserId={(userId) => {
        setOverruledUserId(userId);
      }}
      inspectionTask={inspectionTask}
      submittable={submittable}
    >
      <Col span={24}>
        <Form.Item
          name={'enteredValue'}
          style={{marginBottom: 0, minWidth: 300}}
          hasFeedback
          rules={[ValidationRules.required()]}
          {...validateStatus}
        >
          <Input
            autoComplete="off"
            disabled={disabled || overruleActive}
            ref={inputRef}
          />
        </Form.Item>
      </Col>
      {overruleActive && (
        <FeatureColumn key={'selectedOverruleFeature'} name={'selectedOverruleFeatureId'} isOverrule/>
      )}
    </InspectionTaskExecutionForm>
  );
};

export default observer(TargetValue);
