import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faForklift} from '@fortawesome/pro-solid-svg-icons';
import {useMemo} from 'react';
import {ScopeContext} from '../../../policies/scopeContext';
import OperatorWidget from '../../operator/shared/OperatorWidget';
import {MaterialFlowWidgetConfig} from './MaterialFlowWidgetConfig';
import {useStore} from '../../../hooks/useStore';
import {inventoryManagement} from '../../../models/scope';
import {WidgetLayout} from '../../../models/widgetLayout';
import MaterialFlowView from './MaterialFlowView';
import {useMount} from '../../../hooks/useMount';
import {mqtt, TOPIC_BASE} from '../../../middleware/mqtt';
import {LoadStrategies} from '../../../stores/entityStore';

const MaterialFlowWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const widgetConfig = useMemo(() =>
    new MaterialFlowWidgetConfig(store, MaterialFlowWidget.identifier), [store.settingStore.operatorSettingsReady]);

  useMount(() => {
    const workplaceId = store.workplaceStore.selectedWorkplace?.id;
    if (workplaceId) {
      store.workplaceStorageRelationStore.loadByWorkplaceId(workplaceId);
    }
  }, [store.workplaceStore.selectedWorkplace?.id]);

  useMount(() => {
    const workplaceRelation = store.workplaceStorageRelationStore.getByWorkplaceId(
      store.workplaceStore.selectedWorkplace.id
    );

    if (!workplaceRelation) {
      return () => {};
    }

    const storageUnitIds = new Set();
    if (workplaceRelation.sourceStorageUnitIds?.length) {
      workplaceRelation.sourceStorageUnitIds.forEach((id) => storageUnitIds.add(id));
    }
    if (workplaceRelation.destinationStorageUnitIds?.length) {
      workplaceRelation.destinationStorageUnitIds.forEach((id) => storageUnitIds.add(id));
    }
    if (workplaceRelation.internalStorageUnitIds?.length) {
      workplaceRelation.internalStorageUnitIds.forEach((id) => storageUnitIds.add(id));
    }

    const storageAreaIds = new Set();
    if (workplaceRelation.sourceStorageAreaIds?.length) {
      workplaceRelation.sourceStorageAreaIds.forEach((id) => storageAreaIds.add(id));
    }
    if (workplaceRelation.destinationStorageAreaIds?.length) {
      workplaceRelation.destinationStorageAreaIds.forEach((id) => storageAreaIds.add(id));
    }
    if (workplaceRelation.internalStorageAreaIds?.length) {
      workplaceRelation.internalStorageAreaIds.forEach((id) => storageAreaIds.add(id));
    }
    const unitIdArray = Array.from(storageUnitIds);
    const areaIdArray = Array.from(storageAreaIds);
    let unitHandlerIds = [];
    let areaHandlerIds = [];
    if (storageUnitIds.size > 0) {
      unitHandlerIds = unitIdArray.map((storageUnitId) => {
        store.storageUnitStore.loadWithDependencies(storageUnitId, {});
        return mqtt.subscribe(`${TOPIC_BASE}/storage-units/${storageUnitId}`, (topic, payload) => {
          const publishedStorageUnit = store.storageUnitStore.createModelInstance(payload);
          store.storageUnitStore.add(publishedStorageUnit);
        });
      });
    }
    if (storageAreaIds.size > 0) {
      areaHandlerIds = areaIdArray.map((storageAreaId) => {
        store.storageUnitStore.loadAllWithDependencies({params: {storageAreaId}});
        return mqtt.subscribe(`${TOPIC_BASE}/storage-areas/${storageAreaId}`, (topic, payload) => {
          store.storageUnitStore.loadAllWithDependencies({
            params: {storageAreaId: payload.id},
            strategy: LoadStrategies.add,
          });
        });
      });
    }

    return () => {
      unitIdArray.forEach((storageUnitId, idx) => {
        mqtt.unsubscribe(`${TOPIC_BASE}/storage-units/${storageUnitId}`, unitHandlerIds[idx]);
      });
      areaIdArray.forEach((storageAreaId, idx) => {
        mqtt.unsubscribe(`${TOPIC_BASE}/storage-areas/${storageAreaId}`, areaHandlerIds[idx]);
      });
    };
  }, [store.workplaceStorageRelationStore.workplaceStorageRelations.length]);

  return (
    <ScopeContext.Provider value={[inventoryManagement]}>
      <OperatorWidget
        icon={<FontAwesomeIcon icon={faForklift}/>}
        title={widgetConfig.getWidgetTitle()}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        manualPath={'/inventory-management/material-flow'}
      >
        <MaterialFlowView
          showSourceStorageUnit={widgetConfig.getSettingValue('settings', 'showSourceStorageUnit')}
          showTargetStorageUnit={widgetConfig.getSettingValue('settings', 'showTargetStorageUnit')}
          showInternalStorageUnit={widgetConfig.getSettingValue('settings', 'showInternalStorageUnit')}
          showGraph={widgetConfig.getSettingValue('settings', 'showGraph')}
          showTable={widgetConfig.getSettingValue('settings', 'showTable')}
          graphLocation={widgetConfig.getSettingValue('settings', 'graphLocation')}
          amountOfColumns={widgetConfig.getSettingValue('settings', 'amountOfColumns')}
          sortBy={widgetConfig.getSettingValue('data', 'sortBy')}
          sortOrder={widgetConfig.getSettingValue('data', 'sortOrder')}
          filterByOrderMaterial={widgetConfig.getSettingValue('data', 'filterByOrderMaterial')}
          columns={widgetConfig.getTableColumns()}
          wpStorageRelation={
            store.workplaceStorageRelationStore?.getByWorkplaceId(store.workplaceStore.selectedWorkplace?.id)
          }
        />
      </OperatorWidget>
    </ScopeContext.Provider>
  );
};
MaterialFlowWidget.identifier = 'MaterialFlow';
MaterialFlowWidget.icon = faForklift;
MaterialFlowWidget.defaultLayout = new WidgetLayout({
  identifier: MaterialFlowWidget.identifier,
  x: 1,
  y: 10,
  height: 10,
  minHeight: 4,
  width: 4,
  minWidth: 1,
});

export default observer(MaterialFlowWidget);
