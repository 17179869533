import { FC, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Space, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsToEye } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react-lite';
import { Yield } from '../../models/yield';
import Modal from '../shared/modal/Modal';
import { useStore } from '../../hooks/useStore';
import Descriptions from '../shared/descriptions/Descriptions';
import { BatchLogWidgetConfig } from './batchLogWidgetConfig';
import ModelProperties from '../shared/descriptions/ModelProperties';
import styles from './TrackTraceVisualiserModal.module.scss';
import Button from '../shared/buttons/Button';
import Empty from '../shared/empty/Empty';

const signum = (x: number): number => ((x < 0) ? -1 : 1);

const drawPath = (svg: SVGSVGElement, startX: number, startY: number, endX: number, endY: number) => {
  const path = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path'
  );
  path.setAttribute('stroke', '#ccc');
  path.setAttribute('fill', 'none');
  svg.appendChild(path);

  const svgRect = svg.getBoundingClientRect();
  const stroke = 2;

  if (svgRect.height < endY) {
    svg.setAttribute('height', String(endY));
  }

  const maxX = Math.max((startX + stroke), (endX + stroke));
  if (svgRect.width < maxX) {
    svg.setAttribute('width', String(maxX));
  }

  const deltaX = (endX - startX) * 0.15;
  const deltaY = (endY - startY) * 0.15;
  const delta = deltaY < Math.abs(deltaX) ? deltaY : Math.abs(deltaX);

  let arc1 = 0;
  let arc2 = 1;
  if (startX > endX) {
    arc1 = 1;
    arc2 = 0;
  }
  path.setAttribute(
    'd',
    `M${startX} ${startY} `
    + `V${startY + delta} `
    + `A${delta} ${delta} 0 0 ${arc1} ${startX + delta * signum(deltaX)} ${startY + 2 * delta} `
    + `H${endX - delta * signum(deltaX)} `
    + `A${delta} ${delta} 0 0 ${arc2} ${endX} ${startY + 3 * delta} V${endY}`
  );
};

interface YieldCardProps {
  id?: string;
  className?: string;
  model: Yield;
  onClick: (event: MouseEvent) => void;
  isSelected?: boolean;
}

const YieldCard: FC<YieldCardProps> = ({ id, className, model, isSelected = false, onClick }) => {
  const { t } = useTranslation();

  return (
    <Descriptions
      id={id}
      className={[className, styles.yieldCard, isSelected ? styles.selected : undefined].filter((s) => !!s).join(' ')}
      bordered
      column={1}
      size={'small'}
      onClick={onClick}
      labelStyle={{ width: 150 }}
    >
      <Descriptions.Item label={`${t('batch.model.one')} ${t('batch.model.attributes.no')}`}>
        {model.batch?.no || ''}
      </Descriptions.Item>
      <Descriptions.Item label={t('material.model.attributes.no')}>
        {model.batch?.material ? model.batch.material.no : ''}
      </Descriptions.Item>
      <Descriptions.Item label={t('material.model.one')}>
        {model.batch?.material ? model.batch.material.label : ''}
      </Descriptions.Item>
      <Descriptions.Item label={t('batchLog.model.attributes.quantity')}>
        {model.quantity}
        {' '}
        {model.unitOfMeasure ? model.unitOfMeasure.label : ''}
      </Descriptions.Item>
    </Descriptions>
  );
};

interface TrackTraceVisualiserModalProps {
  widgetConfig: BatchLogWidgetConfig;
  yieldModel: Yield;
  onClose: () => void;
}

const TrackTraceVisualiserModal: FC<TrackTraceVisualiserModalProps> = ({ widgetConfig, yieldModel, onClose }) => {
  const store = useStore();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [centerYield, setCenterYield] = useState<Yield>(yieldModel);
  const [selectedYield, setSelectedYield] = useState<Yield>(yieldModel);
  const [sourceYields, setSourceYields] = useState<Yield[]>([]);
  const [targetYields, setTargetYields] = useState<Yield[]>([]);

  const svgContainer = document.getElementById('svg');

  useEffect(() => {
    setIsLoading(true);
    if (svgContainer) {
      svgContainer.style.display = 'none';
    }
    const promises = [];
    promises.push(store.yieldStore.loadAllWithDependencies({ params: { targetBatchId: centerYield.batchId } })
      .then((yields: Yield[]) => setSourceYields(yields)));
    promises.push(store.yieldStore.loadAllWithDependencies({ params: { sourceBatchId: centerYield.batchId } })
      .then((yields: Yield[]) => setTargetYields(yields)));

    Promise.all(promises).then(() => setIsLoading(false));
  }, [centerYield]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const traceIllustrator = document.getElementById('trace-visualiser');
    if (traceIllustrator) {
      const x = (traceIllustrator.scrollWidth - traceIllustrator.clientWidth) / 2;
      traceIllustrator.scrollTo(x, 0);
      setTimeout(() => {
        const svg = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'svg'
        );

        const center = document.getElementById('trace-visualiser-center');
        if (svgContainer && svg && center) {
          svg.replaceChildren();
          const traceIllustratorRect = traceIllustrator.getBoundingClientRect();
          const sources = Array.from(document.getElementsByClassName('trace-visualiser-source'));
          const targets = Array.from(document.getElementsByClassName('trace-visualiser-target'));

          const centerRect = center.getBoundingClientRect();
          const getX = (left: number) => (left + x - traceIllustratorRect.x);

          sources.forEach((source) => {
            const rect = source.getBoundingClientRect();
            drawPath(
              svg,
              getX(rect.x) + (rect.width / 2),
              rect.y - traceIllustratorRect.y + rect.height,
              getX(centerRect.x) + (centerRect.width / 2),
              centerRect.y - traceIllustratorRect.y
            );
          });

          targets.forEach((target) => {
            const rect = target.getBoundingClientRect();
            const left = (rect.x + x - traceIllustratorRect.x);
            drawPath(
              svg,
              getX(centerRect.x) + (centerRect.width / 2),
              centerRect.y - traceIllustratorRect.y + centerRect.height,
              left + (rect.width / 2),
              rect.y - traceIllustratorRect.y
            );
          });

          svgContainer.replaceChildren(svg);
          svgContainer.style.display = 'block';
        }
      }, 200);
    }
  }, [isLoading]);

  const onYieldCardClick = (e: MouseEvent, model: Yield) => {
    if (e.detail === 2) {
      setCenterYield(model);
    } else {
      setSelectedYield(model);
    }
  };

  const yieldProperties = widgetConfig.getTraceIllustratorYieldDetailProperties();

  const maxItemsCount = Math.max(sourceYields.length, targetYields.length, 1);
  const rowStyle = { width: maxItemsCount * 416, minWidth: '100%' };

  return (
    <Modal
      title={(
        <>
          <FontAwesomeIcon icon={faArrowsToEye}/>
          {' '}
          {t('batchLog.traceIllustrator.title')}
        </>
      )}
      fullscreen={store.clientStore.isMobile}
      open
      maskClosable
      footer={false}
      width={'90vw'}
      className={undefined}
      onCancel={() => onClose()}
    >
      <Row gutter={16}>
        <Col xs={24} lg={20} xxl={18}>
          <Spin spinning={isLoading}>
            <div id={'trace-visualiser'} className={styles.wrapper}>
              <div id={'svg'} className={styles.svg}/>
              <div className={styles.cards}>
                <div className={styles.row} style={rowStyle}>
                  {sourceYields.map((sourceYield, index) => (
                    <YieldCard
                      id={`trace-visualiser-source-${index}`}
                      className={'trace-visualiser-source'}
                      model={sourceYield}
                      onClick={(e) => onYieldCardClick(e, sourceYield)}
                      isSelected={sourceYield.id === selectedYield.id}
                    />
                  ))}
                  {sourceYields.length === 0 && <Empty/>}
                </div>
                <div className={styles.row} style={rowStyle}>
                  <YieldCard
                    id={'trace-visualiser-center'}
                    model={centerYield}
                    onClick={() => setSelectedYield(centerYield)}
                    isSelected={centerYield.id === selectedYield.id}
                  />
                </div>
                <div className={styles.row} style={rowStyle}>
                  {targetYields.map((targetYield, index) => (
                    <YieldCard
                      id={`trace-visualiser-target-${index}`}
                      className={'trace-visualiser-target'}
                      model={targetYield}
                      onClick={(e) => onYieldCardClick(e, targetYield)}
                      isSelected={targetYield.id === selectedYield.id}
                    />
                  ))}
                  {targetYields.length === 0 && <Empty/>}
                </div>
              </div>
            </div>
          </Spin>
        </Col>
        <Col xs={24} lg={4} xxl={6} className={styles.infoColumn}>
          <Space direction={'vertical'} size={16} style={{ width: '100%' }}>
            <ModelProperties
              properties={yieldProperties}
              model={selectedYield}
              size={'small'}
              labelStyle={{ width: 150 }}
            />
            <Button
              block
              type={'primary'}
              onClick={() => setCenterYield(selectedYield)}
              disabled={centerYield.id === selectedYield.id}
            >
              {t('batchLog.traceIllustrator.followUp')}
            </Button>
          </Space>
        </Col>
      </Row>
    </Modal>
  );
};

export default observer(TrackTraceVisualiserModal);
