import { cloneDeep } from 'lodash';
import i18n from '../../../i18n/i18n';
import { Operation } from '../../../models/operation';
import { BaseWidgetConfig } from '../../shared/widgets/baseWidgetConfig';
import { Component } from '../../../models/component';
import { OperationStateLog } from '../../../models/operationStateLog';
import { UnitOfMeasurementType } from '../../../models/unitOfMeasurement';
import { Order } from '../../../models/order';
import appConfig from '../../../utils/appConfig';
import { ValidationRules } from '../../../utils/validationRules';
import { ConsumptionLog } from '../../../models/consumptionLog';
import { Yield } from '../../../models/yield';
import { QualityReport } from '../../../models/qualityReport';
import { FieldType } from '../../../types/widgetConfig';
import { Product } from '../../../models/product';
import { RootStore } from '../../../stores/rootStore';

export interface Group {
  title: string;
  showGroupLabel: boolean;
  columnCount: number | string;
  showPropertyLabels: boolean;
  properties: any[];
  barcode: any[];
  dataMatrix: any[];
  qrCode: any[];
}

export interface CustomTab {
  title: string;
  icon: string;
  columnCount: number | string;
  groups: Group[];
}

export interface CustomItem {
  id: string;
  type: FieldType;
  defaultValue: CustomTab[];
}

export class OperationWidgetConfig extends BaseWidgetConfig {
  constructor(rootStore: RootStore, identifier: string) {
    super(rootStore, identifier);
    this.tabConfigs.set('modalTabs', [
      {
        id: 'modalTabs',
        label: i18n.t<string>('activeOperationWidget.settings.tabs.description'),
        type: FieldType.Properties,
        texts: {
          headerPropertyName: i18n.t('activeOperationWidget.settings.tabs.headerPropertyName'),
          hiddenProperties: i18n.t('activeOperationWidget.settings.tabs.hiddenTabs'),
          visibleProperties: i18n.t('activeOperationWidget.settings.tabs.visibleTabs'),
          itemUnit: i18n.t('activeOperationWidget.settings.tabs.itemUnit'),
          itemsUnit: i18n.t('activeOperationWidget.settings.tabs.itemsUnit'),
          selectAll: i18n.t('activeOperationWidget.settings.tabs.selectAll'),
        },
        properties: [
          {
            key: 'tabGeneral',
            title: i18n.t('activeOperationWidget.settings.modal.tabs.general'),
            enabled: true,
          },
          {
            key: 'tabCustom',
            title: i18n.t('activeOperationWidget.settings.modal.tabs.custom'),
            enabled: true,
          },
          {
            key: 'tabComponents',
            title: i18n.t('activeOperationWidget.settings.modal.tabs.components'),
            enabled: true,
          },
          {
            key: 'tabOrderMaterials',
            title: i18n.t('activeOperationWidget.settings.modal.tabs.orderMaterials'),
            enabled: true,
          },
          {
            key: 'tabYields',
            title: i18n.t('activeOperationWidget.settings.modal.tabs.yields'),
            enabled: appConfig.modules.enableYields,
          },
          {
            key: 'tabPreparations',
            title: i18n.t('activeOperationWidget.settings.modal.tabs.preparations'),
            enabled: true,
          },
          {
            key: 'tabDocuments',
            title: i18n.t('activeOperationWidget.settings.modal.tabs.documents'),
            enabled: true,
          },
          {
            key: 'tabMessages',
            title: i18n.t('activeOperationWidget.settings.modal.tabs.messages'),
            enabled: true,
          },
          {
            key: 'tabShiftBook',
            title: i18n.t('activeOperationWidget.settings.modal.tabs.shiftBook'),
            enabled: true,
          },
          {
            key: 'tabTasks',
            title: i18n.t('activeOperationWidget.settings.modal.tabs.tasks'),
            enabled: true,
          },
          {
            key: 'tabInterruptions',
            title: i18n.t('activeOperationWidget.settings.modal.tabs.interruptions'),
            enabled: true,
          },
          {
            key: 'tabEventLog',
            title: i18n.t('activeOperationWidget.settings.modal.tabs.eventLog'),
            enabled: true,
          },
          {
            key: 'tabOperationsOverview',
            title: i18n.t('activeOperationWidget.settings.modal.tabs.operationsOverview'),
            enabled: true,
          },
          {
            key: 'tabQualityReport',
            title: i18n.t('activeOperationWidget.settings.modal.tabs.qualityReport'),
            enabled: true,
          },
          {
            key: 'tabDosages',
            title: i18n.t('activeOperationWidget.settings.modal.tabs.dosage'),
            enabled: true,
          },
        ].filter((tab) => tab.enabled),
        defaultValue: ['tabGeneral'],
      },
    ]);

    this.tabConfigs.set('modalTabGeneral', [
      {
        id: 'modalTabGeneral.unitOfMeasurementTypes',
        label: i18n.t<string>('activeOperationWidget.settings.modal.general.unitOfMeasurementTypes'),
        type: FieldType.SelectMultiple,
        options: Object.values(UnitOfMeasurementType).map((type) => ({ label: type, value: type })),
        defaultValue: [UnitOfMeasurementType.TU, UnitOfMeasurementType.LU],
      },
      {
        id: 'modalTabGeneral.decimalsConversion',
        label: `${i18n.t('activeOperationWidget.settings.modal.general.decimalsConversion')}`,
        type: FieldType.Number,
        validations: [ValidationRules.minNumber(0), ValidationRules.maxNumber(15)],
      },
      {
        id: 'modalTabGeneral.showOrderFirst',
        label: i18n.t<string>('activeOperationWidget.settings.modal.general.showOrderFirst'),
        type: FieldType.Boolean,
        defaultValue: true,
      },
      {
        id: 'modalTabGeneral.showPhaseMachineHours',
        label: i18n.t<string>('activeOperationWidget.settings.modal.general.showPhaseMachineHours.label'),
        type: FieldType.Boolean,
        defaultValue: true,
      },
      {
        id: 'modalTabGeneral.operationProperties',
        label: i18n.t<string>('activeOperationWidget.settings.modal.general.operationProperties.label'),
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: Operation.allDisplayableProperties(this.rootStore),
        defaultValue: [],
      },
      {
        id: 'modalTabGeneral.orderProperties',
        label: i18n.t<string>('activeOperationWidget.settings.modal.general.orderProperties.label'),
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: Order.allDisplayableProperties(this.rootStore),
        defaultValue: [],
      },
    ]);

    this.tabConfigs.set('modalTabCustom', [
      {
        id: 'modalTabCustom.tabs',
        type: FieldType.CustomTabEditor,
        defaultValue: [{
          title: i18n.t('activeOperationWidget.settings.modal.custom.defaultTabTitle'),
          icon: 'infoCircle',
          columnCount: 1,
          groups: [{
            title: i18n.t('activeOperationWidget.settings.modal.custom.defaultGroupTitle'),
            showGroupLabel: false,
            columnCount: 1,
            showPropertyLabels: true,
            properties: [],
            barcode: [],
            dataMatrix: [],
            qrCode: [],
          }],
        }],
      },
    ]);

    this.tabConfigs.set('modalTabComponents', [
      {
        id: 'modalTabComponents.groupComponents',
        label: i18n.t<string>('activeOperationWidget.settings.modal.components.groupComponents.label'),
        helpText: i18n.t<string>('activeOperationWidget.settings.modal.components.groupComponents.helpText'),
        type: FieldType.Boolean,
        defaultValue: true,
      },
      {
        id: 'modalTabComponents.properties',
        label: i18n.t<string>('activeOperationWidget.settings.modal.components.tabTitle'),
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: Component.allDisplayableProperties(this.rootStore),
        defaultValue: [
          'no',
          'info',
          'material.no',
          'material.name',
          'plannedQuantity',
          'actualQuantity',
          'unitOfMeasure',
        ],
      },
    ]);

    this.tabConfigs.set('modalTabPreparations', [
      {
        id: 'modalTabPreparations.yieldProperties',
        label: i18n.t<string>('activeOperationWidget.settings.modal.preparations.yieldProperties.label'),
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: Yield.allDisplayableProperties(this.rootStore),
        defaultValue: ['preparation.createdAt', 'preparation.state'],
      },
      {
        id: 'modalTabPreparations.yieldSortAttribute',
        label: i18n.t<string>('activeOperationWidget.settings.modal.preparations.yieldSortAttribute.label'),
        helpText: i18n.t<string>('activeOperationWidget.settings.modal.preparations.yieldSortAttribute.help'),
        type: FieldType.Select,
        options: Object.values(Yield.allDisplayableProperties(this.rootStore)).map(({ title, key }) => ({
          value: key,
          label: title,
        })),
        defaultValue: 'preparation.createdAt',
        validations: [ValidationRules.required()],
      },
      /**
       * yield sort order translations:
       * t('activeOperationWidget.settings.modal.preparations.yieldSortOrder.options.ascend')
       * t('activeOperationWidget.settings.modal.preparations.yieldSortOrder.options.descend')
       */
      {
        id: 'modalTabPreparations.yieldSortOrder',
        label: i18n.t<string>('activeOperationWidget.settings.modal.preparations.yieldSortOrder.label'),
        helpText: i18n.t<string>('activeOperationWidget.settings.modal.preparations.yieldSortOrder.help'),
        type: FieldType.Select,
        options: ['ascend', 'descend'].map((value) => ({
          value,
          label: i18n.t(`activeOperationWidget.settings.modal.preparations.yieldSortOrder.options.${value}`),
        })),
        defaultValue: 'ascend',
        validations: [ValidationRules.required()],
      },
      {
        id: 'modalTabPreparations.consumptionProperties',
        label: i18n.t<string>('activeOperationWidget.settings.modal.preparations.consumptionProperties.label'),
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: ConsumptionLog.allDisplayableProperties(this.rootStore),
        defaultValue: ['createdAt', 'amount', 'component.material.label'],
      },
      {
        id: 'modalTabPreparations.consumptionSortAttribute',
        label: i18n.t<string>('activeOperationWidget.settings.modal.preparations.consumptionSortAttribute.label'),
        helpText: i18n.t<string>('activeOperationWidget.settings.modal.preparations.consumptionSortAttribute.help'),
        type: FieldType.Select,
        options: Object.values(ConsumptionLog.allDisplayableProperties(this.rootStore)).map(({ title, key }) => ({
          value: key,
          label: title,
        })),
        defaultValue: 'createdAt',
        validations: [ValidationRules.required()],
      },
      /**
       * consumption sort order translations:
       * t('activeOperationWidget.settings.modal.preparations.consumptionSortOrder.options.ascend')
       * t('activeOperationWidget.settings.modal.preparations.consumptionSortOrder.options.descend')
       */
      {
        id: 'modalTabPreparations.consumptionSortOrder',
        label: i18n.t<string>('activeOperationWidget.settings.modal.preparations.consumptionSortOrder.label'),
        helpText: i18n.t<string>('activeOperationWidget.settings.modal.preparations.consumptionSortOrder.help'),
        type: FieldType.Select,
        options: ['ascend', 'descend'].map((value) => ({
          value,
          label: i18n.t(`activeOperationWidget.settings.modal.preparations.consumptionSortOrder.options.${value}`),
        })),
        defaultValue: 'ascend',
        validations: [ValidationRules.required()],
      },
    ]);

    this.tabConfigs.set('modalTabInterruptions', [
      {
        id: 'modalTabInterruption.columns',
        label: i18n.t<string>('activeOperationWidget.settings.modal.interruptions.columns.label'),
        helpText: i18n.t<string>('activeOperationWidget.settings.modal.interruptions.columns.help'),
        type: FieldType.Number,
        defaultValue: 3,
        validations: [
          ValidationRules.required(),
          ValidationRules.integer,
          ValidationRules.greaterThanOrEqualTo(1),
          ValidationRules.lessThanOrEqualTo(6),
        ],
      },
      {
        id: 'modalTabInterruption.sortAttribute',
        label: i18n.t<string>('activeOperationWidget.settings.modal.interruptions.sortAttribute.label'),
        helpText: i18n.t<string>('activeOperationWidget.settings.modal.interruptions.sortAttribute.help'),
        type: FieldType.Select,
        options: ['time', 'duration'].map((value) => ({
          value,
          label: i18n.t<string>(
            `activeOperationWidget.settings.modal.interruptions.sortAttribute.options.${value}.label`
          ),
          helpText: i18n.t<string>(
            `activeOperationWidget.settings.modal.interruptions.sortAttribute.options.${value}.help`
          ),
        })),
        defaultValue: 'time',
        validations: [ValidationRules.required()],
      },
      {
        id: 'modalTabInterruption.sortOrder',
        label: i18n.t<string>('activeOperationWidget.settings.modal.interruptions.sortOrder.label'),
        helpText: i18n.t<string>('activeOperationWidget.settings.modal.interruptions.sortOrder.help'),
        type: FieldType.Select,
        options: ['ascend', 'descend'].map((value) => ({
          value,
          label: i18n.t<string>(
            `activeOperationWidget.settings.modal.interruptions.sortOrder.options.${value}.label`
          ),
          helpText: i18n.t<string>(
            `activeOperationWidget.settings.modal.interruptions.sortOrder.options.${value}.help`
          ),
        })),
        defaultValue: 'ascend',
        validations: [ValidationRules.required()],
      },
      {
        id: 'modalTabInterruptions.properties',
        label: i18n.t<string>('activeOperationWidget.settings.modal.interruptions.properties.label'),
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: OperationStateLog.allDisplayableProperties(this.rootStore),
        defaultValue: [
          'start',
          'durationSeconds',
          'interruptionClass',
          'interruptionReason',
          'message',
        ],
      },
    ]);

    this.tabConfigs.set('modalTabOperationsOverview', [
      {
        id: 'modalTabOperationsOverview.operationColumns',
        label: i18n.t<string>('activeOperationWidget.settings.modal.operationsOverview.operationColumns.label'),
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: Operation.allDisplayableProperties(this.rootStore),
        defaultValue: [
          'no',
          'name',
          'actualStartDatetime',
          'quantityIsShould',
          'material.no',
          'material.name',
        ],
      },
      {
        id: 'modalTabOperationsOverview.componentColumns',
        label: i18n.t<string>('activeOperationWidget.settings.modal.operationsOverview.componentColumns.label'),
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: Component.allDisplayableProperties(this.rootStore),
        defaultValue: [
          'no',
          'info',
          'material.no',
          'material.name',
          'quantityIsShould',
        ],
      },
    ]);

    this.tabConfigs.set('modalTabQualityReport', [
      {
        id: 'modalTabQualityReport.columns',
        label: i18n.t<string>('activeOperationWidget.settings.modal.qualityReport.columns.label'),
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: QualityReport.allDisplayableProperties(this.rootStore),
        defaultValue: [
          'title',
          'type',
          'priority',
          'state',
        ],
      },
    ]);

    this.tabConfigs.set('modalTabDosage', [
      {
        id: 'modalTabDosage.productColumns',
        label: i18n.t<string>('activeOperationWidget.settings.modal.dosage.productColumns.label'),
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: Product.allDisplayableProperties(this.rootStore),
        defaultValue: [
          'material.name',
          'plannedQuantity',
          'actualQuantity',
        ],
      },
      {
        id: 'modalTabDosage.componentColumns',
        label: i18n.t<string>('activeOperationWidget.settings.modal.dosage.componentColumns.label'),
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: Component.allDisplayableProperties(this.rootStore),
        defaultValue: [
          'material.name',
          'plannedQuantity',
          'actualQuantity',
          'toleranceLow',
          'toleranceHigh',
        ],
      },
    ]);
  }
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'modalTabs',
        label: i18n.t('activeOperationWidget.settings.modalTabs'),
      },
      {
        key: 'modalTabGeneral',
        label: i18n.t('activeOperationWidget.settings.modalTabGeneral'),
      },
      {
        key: 'modalTabCustom',
        label: i18n.t('activeOperationWidget.settings.modalTabCustom'),
      },
      {
        key: 'modalTabComponents',
        label: i18n.t('activeOperationWidget.settings.modalTabComponents'),
      },
      {
        key: 'modalTabPreparations',
        label: i18n.t('activeOperationWidget.settings.modalTabPreparations'),
      },
      {
        key: 'modalTabInterruptions',
        label: i18n.t('activeOperationWidget.settings.modalTabInterruptions'),
      },
      {
        key: 'modalTabOperationsOverview',
        label: i18n.t('activeOperationWidget.settings.modalTabOperationsOverview'),
      },
      {
        key: 'modalTabQualityReport',
        label: i18n.t('activeOperationWidget.settings.modalTabQualityReport'),
      },
      {
        key: 'modalTabDosage',
        label: i18n.t('activeOperationWidget.settings.modalTabDosage'),
      },
    ];
  }

  getGeneralTabSettings(identifier: string) {
    return this.getSettingValue('modalTabGeneral', identifier);
  }

  getCustomTabSetting(identifier: string) {
    return this.getSettingValue('modalTabCustom', identifier);
  }

  getCustomTabs() {
    const tabsConfig: CustomTab[] = cloneDeep(this.getSettingValue('modalTabCustom', 'modalTabCustom.tabs'));
    const availableProperties = Operation.allDisplayableProperties(this.rootStore);

    tabsConfig.forEach((tabConfig) => {
      // eslint-disable-next-line max-len
      tabConfig.columnCount = typeof tabConfig.columnCount === 'string' ? parseInt(tabConfig.columnCount, 10) : tabConfig.columnCount;
      tabConfig.groups?.forEach((group) => {
        group.columnCount = typeof group.columnCount === 'string' ? parseInt(group.columnCount, 10) : group.columnCount;
        group.properties = group.properties?.map(
          (item) => this.mapSettingPropertyKeyToProperty({
            id: item.key,
            modelOptions: availableProperties,
            type: FieldType.PropertiesSelectDisplayField,
          }, item)
        );
      });
    });

    return tabsConfig;
  }

  getInterruptionsTabSetting(identifier: string) {
    return this.getSettingValue('modalTabInterruptions', identifier);
  }

  getModalTabsSetting(identifier: string) {
    return this.getSettingValue('modalTabs', identifier);
  }

  getGeneralTabOperationProperties() {
    return this.getSelectedProperties('modalTabGeneral', 'modalTabGeneral.operationProperties');
  }

  getGeneralTabOrderProperties() {
    return this.getSelectedProperties('modalTabGeneral', 'modalTabGeneral.orderProperties');
  }

  getCustomTabProperties() {
    return this.getSelectedProperties('modalTabCustom', 'modalTabCustom.properties');
  }

  getComponentsTableColumns() {
    return this.getSelectedProperties('modalTabComponents', 'modalTabComponents.properties');
  }

  getComponentsTableSettings(identifier: string) {
    return this.getSettingValue('modalTabComponents', identifier);
  }

  getInterruptionsTableColumns() {
    return this.getSelectedProperties('modalTabInterruptions', 'modalTabInterruptions.properties');
  }

  getInterruptionsTableSettings(identifier: string) {
    return this.getSettingValue('modalTabInterruptions', identifier);
  }

  getOperationsOverviewOperationsTableColumns() {
    return this.getSelectedProperties('modalTabOperationsOverview', 'modalTabOperationsOverview.operationColumns');
  }

  getOperationsOverviewComponentsTableColumns() {
    return this.getSelectedProperties('modalTabOperationsOverview', 'modalTabOperationsOverview.componentColumns');
  }

  getQualityReportTableColumns() {
    return this.getSelectedProperties('modalTabQualityReport', 'modalTabQualityReport.columns');
  }

  getPreparationsTabYieldProperties() {
    return this.getSelectedProperties('modalTabPreparations', 'modalTabPreparations.yieldProperties');
  }

  getPreparationsTabYieldSortAttribute() {
    return this.getSettingValue('modalTabPreparations', 'modalTabPreparations.yieldSortAttribute');
  }

  getPreparationsTabYieldSortOrder() {
    return this.getSettingValue('modalTabPreparations', 'modalTabPreparations.yieldSortOrder');
  }

  getPreparationsTabConsumptionsProperties() {
    return this.getSelectedProperties('modalTabPreparations', 'modalTabPreparations.consumptionProperties');
  }

  getPreparationsTabConsumptionsSortAttribute() {
    return this.getSettingValue('modalTabPreparations', 'modalTabPreparations.consumptionSortAttribute');
  }

  getPreparationsTabConsumptionsSortOrder() {
    return this.getSettingValue('modalTabPreparations', 'modalTabPreparations.consumptionSortOrder');
  }

  getDosageProductTableColumns() {
    return this.getSelectedProperties('modalTabDosage', 'modalTabDosage.productColumns');
  }

  getDosageComponentTableColumns() {
    return this.getSelectedProperties('modalTabDosage', 'modalTabDosage.componentColumns');
  }
}
