import { observable, makeObservable } from 'mobx';
import { faCodeFork } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import i18n from '../i18n/i18n';
import { DisplayablePropertyParams, displayableProperty } from './displayableProperty';
import { displayablePropertyParam } from './displayablePropertyParam';
import { sortAlphabetically } from '../components/shared/tables/sorters';
import { CustomPropertiesObject } from './customProperty';
import { RootStore } from '../stores/rootStore';
import { CustomPropertyTypes } from './customPropertyDataTypes';

export class Gateway extends BaseModel {
  id: number = 0;
  name: string = '';
  description: string = '';
  url: string = '';
  properties?: CustomPropertiesObject = undefined;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;
  deletedAt?: string = undefined;
  deletedBy?: number = undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      description: observable,
      url: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
      deletedAt: observable,
      deletedBy: observable,
      properties: observable,
    });
  }

  static faIcon = faCodeFork;

  readonly saveableProperties = [
    'name',
    'description',
    'url',
    'properties',
  ];

  customPropertyType? = CustomPropertyTypes.Gateway;

  displayableProperties: DisplayablePropertyParams[] = [
    displayableProperty({
      key: 'name',
      title: i18n.t('gateway.model.attributes.name'),
      params: [displayablePropertyParam({ path: 'name' })],
      sorter: (a, b) => sortAlphabetically(a.name, b.name),
    }),
    displayableProperty({
      key: 'description',
      title: i18n.t('gateway.model.attributes.description'),
      params: [displayablePropertyParam({ path: 'description' })],
      sorter: (a, b) => sortAlphabetically(a.description, b.description),
    }),
    displayableProperty({
      key: 'url',
      title: i18n.t('gateway.model.attributes.url'),
      params: [displayablePropertyParam({ path: 'url' })],
      sorter: (a, b) => sortAlphabetically(a.url, b.url),
    }),
  ];

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);

    allDisplayableProperties.push(
      ...this.displayableCustomProperties(rootStore, pathPrefix, titlePrefix)
    );

    return allDisplayableProperties;
  }

  static prepareApiPayload(model: Partial<Gateway>): Partial<Gateway> {
    return {
      id: model.id,
      name: model.name,
      description: model.description,
      url: model.url,
      properties: model.properties,
    };
  }
}
