import { observable, makeObservable, computed } from 'mobx';
import dayjs from 'dayjs';
import i18n from '../i18n/i18n';
import { BaseModel } from './base';
import { transformation } from '../utils/transformations';
import { InspectionTask } from './inspectionTask';
import { displayableProperty } from './displayableProperty';
import { sortChronologicallyIgnoreMilliseconds } from '../components/shared/tables/sorters';
import { InspectionTaskGroup } from './inspectionTaskGroup';
import { RootStore } from '../stores/rootStore';

export class InspectionTaskPending extends BaseModel {
  id: number = 0;
  inspectionTaskId: number = 0;
  dueDate: string | null = null;
  pausedSince: string | null = null;
  pausedByUserId: number | null = null;
  isSilent: boolean = false;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      inspectionTaskId: observable,
      inspectionTask: computed,
      inspectionLot: computed,
      dueDate: observable,
      pausedSince: observable,
      pausedByUserId: observable,
      isSilent: observable,
    });
  }

  displayableProperties = [
    displayableProperty({
      key: 'dueDate',
      params: [{ path: 'dueDate', transform: transformation.none }],
      template: '{value}',
      title: i18n.t('inspectionTaskPending.model.attributes.dueDate'),
      raw: false,
    }),
  ];

  get inspectionTask() {
    return this.rootStore.inspectionTaskStore.getById(this.inspectionTaskId);
  }

  /**
   * @deprecated use inspectionTaks.inspectionLot
   */
  get inspectionLot() {
    return this.inspectionTask?.inspectionLot;
  }

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);
    allDisplayableProperties.push(
      ...InspectionTask.allDisplayableProperties(rootStore, `${pathPrefix}inspectionTask.`, titlePrefix)
    );
    const inspectionTaskGroupPrefix = `${titlePrefix}${i18n.t('inspectionTaskGroup.model.one')} > `;
    allDisplayableProperties.push(
      ...InspectionTaskGroup
        .allDisplayableProperties(rootStore, `${pathPrefix}inspectionTaskGroup.`, inspectionTaskGroupPrefix)
    );

    return allDisplayableProperties;
  }

  static sort(a: Partial<InspectionTaskPending>, b: Partial<InspectionTaskPending>) {
    if (a.pausedSince && !b.pausedSince) {
      return 1;
    }
    if (!a.pausedSince && b.pausedSince) {
      return -1;
    }
    if (!a.dueDate && b.dueDate) {
      return 1;
    }
    if (a.dueDate && !b.dueDate) {
      return -1;
    }
    return sortChronologicallyIgnoreMilliseconds(a.dueDate, b.dueDate);
  }

  /**
   * Filters out calculations of absolute interval tasks that where not calculated together.
   * Returns always true if one of the tasks does not have the isAbsoluteInterval set to true.
   * This preserves functionality for tasks from before the absolute interval was introduced.
   */
  static isSameCalculation(
    a: Pick<InspectionTaskPending, 'inspectionTask' | 'dueDate'>,
    b: Pick<InspectionTaskPending, 'inspectionTask' | 'dueDate'>
  ) {
    return !a.inspectionTask?.isAbsoluteInterval || !a.inspectionTask?.isAbsoluteInterval
    || dayjs(a.dueDate).isSame(dayjs(b.dueDate), 'minute');
  }
}
