import i18n from '../../i18n/i18n';
import {ValidationRules} from '../../utils/validationRules';
import {Operation} from '../../models/operation';
import {BaseWidgetConfig} from '../shared/widgets/baseWidgetConfig';
import {getAllI18nTranslations} from '../../utils/translations';
import {FieldType} from '../../types/widgetConfig';

export const AttributeLabelLayout = {
  TOP: 'TOP',
  LEFT: 'LEFT',
  NOT_VISIBLE: 'NOT_VISIBLE',
};

export class BarcodeWidgetConfig extends BaseWidgetConfig {
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'settings',
        label: i18n.t('barcodeWidget.settings.label'),
      },
    ];
  }

  settings = [
    {
      id: 'title',
      label: i18n.t('widget.settings.title.label'),
      helpText: i18n.t('widget.settings.title.help'),
      type: FieldType.Translatable,
      defaultValue: getAllI18nTranslations('barcodeWidget.title'),
      validations: [ValidationRules.required()],
    },
    {
      id: 'properties',
      type: FieldType.PropertiesSelectDisplayField,
      modelOptions: Operation.allDisplayableProperties(this.rootStore),
      defaultValue: [
        'order.no',
        'order.material.no',
        'material.no',
      ],
    },
    {
      id: 'spaceSize',
      label: i18n.t('barcodeWidget.settings.spaceSize.label'),
      helpText: i18n.t('barcodeWidget.settings.spaceSize.help'),
      type: FieldType.Select,
      validations: [ValidationRules.required()],
      defaultValue: 'default',
      options: [
        {
          value: 'default',
          label: i18n.t('barcodeWidget.settings.spaceSize.default'),
        },
        {
          value: 'middle',
          label: i18n.t('barcodeWidget.settings.spaceSize.middle'),
        },
        {
          value: 'small',
          label: i18n.t('barcodeWidget.settings.spaceSize.small'),
        },
      ],
    },
    {
      id: 'attributeLabelLayout',
      label: i18n.t('barcodeWidget.settings.attributeLabelLayout.label'),
      type: FieldType.Select,
      validations: [ValidationRules.required()],
      defaultValue: AttributeLabelLayout.TOP,
      options: [
        {
          value: AttributeLabelLayout.TOP,
          label: i18n.t('barcodeWidget.settings.attributeLabelLayout.top'),
        },
        {
          value: AttributeLabelLayout.LEFT,
          label: i18n.t('barcodeWidget.settings.attributeLabelLayout.left'),
        },
        {
          value: AttributeLabelLayout.NOT_VISIBLE,
          label: i18n.t('barcodeWidget.settings.attributeLabelLayout.notVisible'),
        },
      ],
    },
    {
      id: 'barcodeWidth',
      label: i18n.t('barcodeWidget.settings.barcodeWidth.label'),
      helpText: i18n.t('barcodeWidget.settings.barcodeWidth.help'),
      type: FieldType.Number,
      validations: [ValidationRules.minNumber(2), ValidationRules.maxNumber(10)],
      defaultValue: 2,
    },
    {
      id: 'barcodeHeight',
      label: i18n.t('barcodeWidget.settings.barcodeHeight.label'),
      helpText: i18n.t('barcodeWidget.settings.barcodeHeight.help'),
      type: FieldType.Number,
      validations: [ValidationRules.minNumber(10), ValidationRules.maxNumber(200)],
      defaultValue: 100,
    },
    {
      id: 'showBarcodeValueAsString',
      label: i18n.t('barcodeWidget.settings.showBarcodeValueAsString.label'),
      type: FieldType.Boolean,
      defaultValue: true,
    },
    {
      id: 'qrCode',
      label: i18n.t('barcodeWidget.settings.qrCode.label'),
      helpText: i18n.t('barcodeWidget.settings.qrCode.help'),
      type: FieldType.SelectMultiple,
      defaultValue: [],
      validations: [ValidationRules.notInField('dataMatrix', i18n.t('barcodeWidget.settings.dataMatrix.label'))],
      options: Operation.allDisplayableProperties(this.rootStore).map((item) => ({
        value: item.key,
        label: item.title,
      })),
    },
    {
      id: 'qrCodeSize',
      label: i18n.t('barcodeWidget.settings.qrCodeSize.label'),
      helpText: i18n.t('barcodeWidget.settings.qrCodeSize.help'),
      type: FieldType.Number,
      validations: [],
      defaultValue: 7,
    },
    {
      id: 'dataMatrix',
      label: i18n.t('barcodeWidget.settings.dataMatrix.label'),
      helpText: i18n.t('barcodeWidget.settings.dataMatrix.help'),
      type: FieldType.SelectMultiple,
      defaultValue: [],
      validations: [ValidationRules.notInField('qrCode', i18n.t('barcodeWidget.settings.qrCode.label'))],
      options: Operation.allDisplayableProperties(this.rootStore).map((item) => ({
        value: item.key,
        label: item.title,
      })),
    },
    {
      id: 'dataMatrixSize',
      label: i18n.t('barcodeWidget.settings.dataMatrixSize.label'),
      helpText: i18n.t('barcodeWidget.settings.dataMatrixSize.help'),
      type: FieldType.Number,
      validations: [],
      defaultValue: 8,
    },
  ];

  getWidgetSetting(identifier) {
    return this.getSettingValue('settings', identifier);
  }

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'title');
  }

  getWidgetProperties() {
    return this.getSelectedProperties('settings', 'properties');
  }

  getSpaceSize() {
    return this.getSettingValue('settings', 'spaceSize');
  }

  getAttributeLabelLayout() {
    return this.getSettingValue('settings', 'attributeLabelLayout');
  }

  getBarcodeWidth() {
    return this.getSettingValue('settings', 'barcodeWidth');
  }

  getBarcodeHeight() {
    return this.getSettingValue('settings', 'barcodeHeight');
  }

  getQrCodeProperties() {
    return this.getSettingValue('settings', 'qrCode');
  }

  getQrCodeSize() {
    return this.getSettingValue('settings', 'qrCodeSize');
  }
  getDataMatrixProperties() {
    return this.getSettingValue('settings', 'dataMatrix');
  }
  getDataMatrixSize() {
    return this.getSettingValue('settings', 'dataMatrixSize');
  }
  showBarcodeValueAsString() {
    return this.getSettingValue('settings', 'showBarcodeValueAsString');
  }
}
