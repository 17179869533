import React from 'react';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Button from '../../shared/buttons/Button';
import { useStore } from '../../../hooks/useStore';
import { PrintJobState } from '../../../models/printJob';

export type PrintJobActionsProps = {
  selectedJobs?: number[];
  onRecalculate: () => void;
  onStart: () => void;
  onAbort: () => void;
  loading: boolean;
};

const PrintJobActions: React.FC<PrintJobActionsProps> = ({
  selectedJobs = [],
  onRecalculate,
  onStart,
  onAbort,
  loading,
}) => {
  const { t } = useTranslation();
  const store = useStore();

  const hasUniquePrintersAndNotRunning = store.printJobStore.jobsHaveUniquePrintersAndAreNotPrinting(selectedJobs);
  const hasInitState = store.printJobStore.jobsHaveRequestedState(selectedJobs, PrintJobState.INIT);
  const hasPrintingState = store.printJobStore.jobsHaveRequestedState(selectedJobs, PrintJobState.PRINTING);
  return (
    <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Space>
        <Button
          type={'primary'}
          onClick={onStart}
          disabled={!selectedJobs.length || !hasUniquePrintersAndNotRunning || !hasInitState || loading}
        >
          {t('printJob.start')}
        </Button>
        <Button
          danger
          onClick={onAbort}
          disabled={!selectedJobs.length || !hasPrintingState || loading}
        >
          {t('printJob.stop')}
        </Button>
      </Space>
      <Button onClick={onRecalculate} disabled={!selectedJobs.length || !hasInitState || loading}>
        {t('printJob.reCalc')}
      </Button>
    </Space>
  );
};

export default observer(PrintJobActions);
