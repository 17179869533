import { observable, makeObservable, computed } from 'mobx';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import i18n from '../i18n/i18n';
import { DisplayablePropertyParams, displayableProperty } from './displayableProperty';
import { displayablePropertyParam } from './displayablePropertyParam';
import { sortAlphabetically } from '../components/shared/tables/sorters';
import { RootStore } from '../stores/rootStore';
import { CustomFunction } from './customFunction';
import { EnDash } from '../components/shared/unicodeWrapper/EnDash';

export class InspectionEquipment extends BaseModel {
  id: number = 0;
  name: string = '';
  description: string = '';
  uuid: string = '';
  defaultCustomFunctionId: number | null = null;
  customFunctionIds: number[] = [];
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;
  deletedAt?: string = undefined;
  deletedBy?: number = undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      description: observable,
      uuid: observable,
      defaultCustomFunctionId: observable,
      defaultCustomFunction: computed,
      customFunctionIds: observable,
      customFunctions: computed,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
      deletedAt: observable,
      deletedBy: observable,
    });
  }

  static faIcon = faServer;

  readonly saveableProperties = [
    'name',
    'description',
    'defaultCustomFunctionId',
    'customFunctionIds',
  ];

  displayableProperties: DisplayablePropertyParams[] = [
    displayableProperty({
      key: 'name',
      title: i18n.t('inspectionEquipment.model.attributes.name'),
      params: [displayablePropertyParam({ path: 'name' })],
      sorter: (a, b) => sortAlphabetically(a.name, b.name),
    }),
    displayableProperty({
      key: 'description',
      title: i18n.t('inspectionEquipment.model.attributes.description'),
      params: [displayablePropertyParam({ path: 'description' })],
      sorter: (a, b) => sortAlphabetically(a.description, b.description),
    }),
    displayableProperty({
      key: 'customFunctions',
      title: i18n.t('inspectionEquipment.model.attributes.customFunctions'),
      params: [
        displayablePropertyParam({
          path: '.',
          transform: (ie) => {
            // eslint-disable-next-line max-len
            const customFunctionsCommaSeparated = ie?.customFunctions?.map((cf: CustomFunction) => cf?.label).join(', ');
            return customFunctionsCommaSeparated || <EnDash/>;
          },
          as: 'customFunctions',
        }),
      ],
      raw: true,
      sorter: (a, b) => sortAlphabetically(a.customFunctions.label, b.customFunctions.label),
    }),
    displayableProperty({
      key: 'defaultCustomFunction',
      title: i18n.t('inspectionEquipment.model.attributes.defaultCustomFunction'),
      params: [displayablePropertyParam({ path: 'defaultCustomFunction.label' })],
      sorter: (a, b) => sortAlphabetically(a.defaultCustomFunction.label, b.defaultCustomFunction.label),
    }),
  ];

  get defaultCustomFunction() {
    return this.defaultCustomFunctionId
      ? this.rootStore.customFunctionStore.getById(this.defaultCustomFunctionId)
      : undefined;
  }

  get customFunctions() {
    return this.customFunctionIds.map((id) => this.rootStore.customFunctionStore.getById(id));
  }

  static prepareApiPayload(model: InspectionEquipment) {
    return {
      id: model.id,
      name: model.name,
      description: model.description || null,
      customFunctionIds: model.customFunctionIds,
      defaultCustomFunctionId: model.defaultCustomFunctionId,
    };
  }
}
