import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClock} from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import TextParagraph from '../../shared/typography/TextParagraph';
import {messageValidTimeFormat} from '../../../config/dayjs';
import SubTitleWithIcon from '../../shared/typography/SubTitleWithIcon';
import styles from './Validity.module.scss';

const Validity = ({message}) => {
  const {t} = useTranslation();

  let validityText = t('message.model.attributes.unlimited');
  if (message.validFrom && message.validTo) {
    const fromFormatted = dayjs(message.validFrom).format(messageValidTimeFormat);
    const toFormatted = dayjs(message.validTo).format(messageValidTimeFormat);
    validityText = `${fromFormatted} - ${toFormatted}`;
  }

  return (
    <div className={styles.validity}>
      <SubTitleWithIcon
        icon={<FontAwesomeIcon icon={faClock} style={{marginRight: 8}}/>}
        text={t('message.model.attributes.validity')}
      />
      <TextParagraph
        text={validityText}
        fontWeight={500}
      />
    </div>
  );
};

export default observer(Validity);
