import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Table from '../../shared/tables/Table';
import { useStore } from '../../../hooks/useStore';
import { Product } from '../../../models/product';
import { Component } from '../../../models/component';
import { useMount } from '../../../hooks/useMount';
import { DosageWidgetConfig } from './dosageWidgetConfig';
import { getActualPreparation, getBatchesNo } from '../dosageUtil';

export type DosageTableProps = {
  widgetConfig: DosageWidgetConfig;
  operationId?: number;
  showAverages?: boolean;
  productId?: number;
  showPagination?: boolean;
};

const DosageTable: React.FC<DosageTableProps> = ({
  widgetConfig,
  operationId,
  showAverages,
  productId,
  showPagination = true,
}) => {
  const store = useStore();
  const { t } = useTranslation();
  const isSingleProduct = productId !== undefined;

  useMount(() => {
    if (!showAverages || !operationId) {
      return;
    }

    store.preparationStore.loadRunningByOperation(operationId);
  }, [showAverages, operationId]);

  const singleProductColumns = isSingleProduct ? widgetConfig.getDosageSingleProductTableColumns() : [];
  const productColumns = widgetConfig.getDosageProductTableColumns();
  let componentColumns = widgetConfig.getDosageComponentTableColumns();

  if (!isSingleProduct && showAverages) {
    componentColumns = [
      ...componentColumns,
      {
        title: t('dosageWidget.config.settings.propertyModalComponents.title.averageDosage'),
        key: 'averageDosage',
        render: (_: any, record: Component) => {
          const productPreparations = store.preparationStore.preparations.filter(
            (preparation) => preparation.productId === record.productId && preparation.operationId === operationId
          );

          let amount = 0;
          productPreparations.forEach((preparation) => {
            const prepAmount = getActualPreparation(preparation.id, record, store);
            amount += prepAmount;
          });

          const averageDosage = (record && record.unitOfMeasure && amount > 0 && productPreparations.length > 0)
            ? `${amount / productPreparations.length}${record.unitOfMeasure.label}`
            : '-';

          return (
            <span>
              {averageDosage}
            </span>
          );
        },
      },
      {
        title: t('dosageWidget.config.settings.propertyModalComponents.title.dosedBatches'),
        key: 'dosageBatches',
        render: (_: any, record: Component) => {
          const productPreparations = store.preparationStore.preparations.filter(
            (preparation) => preparation.productId === record.productId
          );

          const batches: string[] = [];
          productPreparations.forEach((preparation) => {
            const batchNos = getBatchesNo(preparation.id, record, store);
            batches.push(...batchNos);
          });

          const batchesCommaSeperated = batches.length > 0 ? batches.join(', ') : '-';

          return (
            <span>
              {batchesCommaSeperated}
            </span>
          );
        },
      },
    ];
  }

  useMount(() => {
    if (!operationId || !isSingleProduct) {
      return;
    }

    store.productStore.loadWithDependencies(productId, {});
  }, [productId, isSingleProduct]);

  useMount(() => {
    if (!operationId || isSingleProduct) {
      return;
    }

    store.productStore.loadAllWithDependencies({
      params: {
        operationId,
      },
    }).then(() => {
      if (!operationId) {
        return;
      }
      store.componentStore.loadAllWithDependencies({
        params: {
          operationId,
        },
      });
    });
  }, [operationId]);

  const hasComponents = (product: Product) => {
    if (isSingleProduct) {
      return false;
    }

    return store.componentStore.components.some(
      (component) => component.productId === product.id
    );
  };

  const expandedRowRender = (product: Product) => {
    const data = store.componentStore.components.filter((component) =>
      component.productId === product.id);

    return (
      <Table<Component>
        rowKey={'id'}
        data-cy={'DosageComponentTable-Table'}
        columns={componentColumns}
        dataSource={data}
        pagination={false}
      />
    );
  };

  const dataSource = !isSingleProduct
    ? store.productStore.getByOperationId(operationId)
    : store.productStore.products.filter((product) => product.id === productId);

  return (
    <Table<Product>
      data-cy={'DosageProductTable-Table'}
      rowKey={'id'}
      columns={!isSingleProduct ? productColumns : singleProductColumns}
      dataSource={dataSource}
      pagination={showPagination ? undefined : false}
      expandable={{
        expandedRowRender: (record: Product) => expandedRowRender(record),
        rowExpandable: (record: Product) => hasComponents(record),
        defaultExpandedRowKeys: !isSingleProduct ? dataSource.map((product) => product.id) : undefined,
      }}
      loading={store.productStore.isLoadingCollection || store.productStore.isLoading()}
      style={{ marginBottom: '20px' }}
    />
  );
};

export default observer(DosageTable);
