import { computed, makeObservable, observable } from 'mobx';
import { uniq } from 'lodash';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { Hierarchy } from '../models/hierarchy';
import { RootStore } from './rootStore';
import { Sensor } from '../models/sensor';

interface HierarchySubTreeReturn {
  hierarchy: Hierarchy;
  children: HierarchySubTreeReturn[];
  sensors: Sensor[];
}

export class HierarchyStore extends EntityStore<Hierarchy> {
  hierarchies: Hierarchy[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'hierarchies', Api.hierarchy, Hierarchy);

    makeObservable(this, {
      hierarchies: observable,
      hierarchyTree: computed,
      hierarchyTreeNoWorkplaces: computed,
      hierarchyTreeToWorkplacesOnly: computed,
      hierarchyRoot: computed,
    });
  }

  get hierarchyTree() {
    return Hierarchy.buildTreeAndSort(this.hierarchies.slice());
  }

  get hierarchyTreeNoWorkplaces() {
    const { workplaceStore } = this.rootStore;
    const removeWorkplaces = (source: Hierarchy[]): Hierarchy[] => {
      if (!source) {
        return [];
      }
      return source.filter((hierarchy) => !workplaceStore.getByHierarchyId(hierarchy.id));
    };
    return Hierarchy.buildTreeAndSort(removeWorkplaces(this.hierarchies));
  }

  get hierarchyTreeToWorkplacesOnly(): Hierarchy[] {
    const { workplaceStore } = this.rootStore;
    const isOrHasWorkplace = (source: Hierarchy) => {
      if (workplaceStore.getByHierarchyId(source.id)) {
        return true;
      }
      const children = this.hierarchies.filter((h) => h.parentId === source.id);
      if (children.length > 0) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < children.length; i++) {
          if (workplaceStore.getByHierarchyId(children[i].id)) {
            return true;
          }
          if (isOrHasWorkplace(children[i])) {
            return true;
          }
        }
      }
      return false;
    };
    return Hierarchy.buildTreeAndSort(this.hierarchies.filter((h) => isOrHasWorkplace(h))
      .map((h) => Hierarchy.fromPlainObject({ ...h, children: [] }, this.rootStore)));
  }

  get hierarchyRoot() {
    return this.hierarchies.find((hierarchy) => hierarchy.parentId === null);
  }

  getPathIds(hierarchyId: number): number[] {
    const hierarchy = this.getById(hierarchyId);
    if (hierarchy?.parentId) {
      return [
        hierarchyId,
        ...this.getPathIds(hierarchy.parentId),
      ];
    }
    return [hierarchyId];
  }

  getChildPathIds(hierarchyId: number): number[] {
    const children = this.getChildren(hierarchyId);
    if (children.length) {
      return [
        hierarchyId,
        ...children.map((c) => this.getChildPathIds(c.id)).flat(1),
      ];
    }
    return [hierarchyId];
  }

  getFullPathIds(hierarchyId: number) {
    return uniq([
      ...this.getPathIds(hierarchyId),
      ...this.getChildPathIds(hierarchyId),
    ]);
  }

  getChildren(hierarchyId?: number) {
    if (!hierarchyId) {
      return [];
    }
    return this.hierarchies.filter((hierarchy) => hierarchy.parentId === hierarchyId);
  }

  getHierarchySubTree(hierarchyId: number): HierarchySubTreeReturn[] {
    const children = this.getChildren(hierarchyId);
    return children.map((child) => ({
      hierarchy: child,
      children: this.getHierarchySubTree(child.id),
      sensors: this.rootStore.sensorStore.getByHierarchyId(child.id, true),
    })).sort((a, b) => a.hierarchy.sortOrder - b.hierarchy.sortOrder);
  }

  getSubTree(hierarchyId: number) {
    const parent = this.getById(hierarchyId) as Hierarchy;
    const children = this.getChildren(hierarchyId);
    return Hierarchy.buildTreeAndSort<Hierarchy>([parent, ...children], parent.parentId);
  }

  recursiveChildSearch(hierarchyId: number) {
    const allChildren: Hierarchy[] = [];
    const children = this.getChildren(hierarchyId);
    children.forEach((child) => {
      allChildren.push(child);
      allChildren.push(...this.recursiveChildSearch(child.id));
    });
    return allChildren;
  }

  getFullSubTree(hierarchyId: number) {
    const parent = this.getById(hierarchyId) as Hierarchy;
    const children = this.recursiveChildSearch(hierarchyId);
    return Hierarchy.buildTreeAndSort<Hierarchy>([parent, ...children], parent.parentId);
  }

  getParentHierarchyByLevel = (element: Hierarchy, targetLevel = 0): Hierarchy => {
    if (!element?.level || element.level < targetLevel) {
      return element;
    }

    if (element.level === targetLevel) {
      return element;
    }
    return this.getParentHierarchyByLevel(this.getById(element.parentId) as Hierarchy, targetLevel);
  };
}
