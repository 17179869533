import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Space} from 'antd';
import {faArrowsToEye, faListAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import Button from '../shared/buttons/Button';
import {smallPaginationPreset} from '../shared/tables/paginationPresets';
import {TableColumns} from '../shared/tables/columns';
import BatchLogTableAction from './BatchLogTableAction';
import WidgetTable from '../shared/widgets/WidgetTable';
import {useMount} from '../../hooks/useMount';
import {useStore} from '../../hooks/useStore';
import {ShiftBook} from '../../models/shiftBook';
import {ShiftBookRoutes} from '../shiftBook/routes';
import ButtonLink from '../shared/buttons/ButtonLink';
import TrackTraceVisualiserModal from './TrackTraceVisualiserModal';

const BatchLogTable = ({
  yieldLogs = [],
  widgetConfig,
  loading,
  onView,
  sortOrder,
  onExport,
  disableTrackTrace = false,
}) => {
  const store = useStore();
  const [update, setUpdate] = useState(false);
  const [selectedYield, setSelectedYield] = useState();

  useMount(() => {
    setUpdate(!update);
  }, [
    store.materialStore.materials.length,
    store.operationStore.operations.length,
    store.orderStore.orders.length,
    store.batchStore.batches.length,
    store.yieldTypeStore.yieldTypes.length,
  ]);

  const getShiftBookUrl = (record) => {
    const workplace = store.workplaceStore.getById(record.operation?.workplaceId);
    const fromDate = dayjs(record.createdAt)
      .subtract(1, 'day')
      .toISOString();
    const toDate = dayjs(record.createdAt)
      .toISOString();
    const shiftBookUrl = ShiftBookRoutes.all();
    const queryParams = `hierarchyId=${encodeURIComponent(workplace?.hierarchyId || 0)}`
      + `&fromDate=${encodeURIComponent(fromDate)}`
      + `&toDate=${encodeURIComponent(toDate)}`;
    return `${shiftBookUrl}?${queryParams}`;
  };

  return (
    <Space size={'middle'} direction={'vertical'} style={{display: 'flex'}}>
      {onExport && (
        <BatchLogTableAction
          onExport={onExport}
        />
      )}
      <WidgetTable
        loading={loading}
        size={'middle'}
        columns={[
          TableColumns.id({defaultSortOrder: sortOrder}),
          ...(widgetConfig.getBatchLogTableColumns()),
          {
            dataIndex: '',
            key: 'actions',
            align: 'right',
            width: 50,
            render: (_, record) => (
              <Space>
                <Button icon={<FontAwesomeIcon icon={faListAlt}/>} onClick={() => onView(record)}/>
                <ButtonLink to={getShiftBookUrl(record)} icon={<FontAwesomeIcon icon={ShiftBook.faIcon}/>}/>
                {!disableTrackTrace
                  && <Button icon={<FontAwesomeIcon icon={faArrowsToEye}/>} onClick={() => setSelectedYield(record)}/>}
              </Space>
            ),
          },
        ]}
        dataSource={yieldLogs}
        rowKey={'id'}
        pagination={smallPaginationPreset}
      />
      {!disableTrackTrace && selectedYield && (
        <TrackTraceVisualiserModal
          widgetConfig={widgetConfig}
          yieldModel={selectedYield}
          onClose={() => setSelectedYield(undefined)}
        />
      )}
    </Space>
  );
};

export default observer(BatchLogTable);
