import {observer} from 'mobx-react-lite';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import ScanIngredient from './shared/ScanIngredient';
import IngredientDetails from './shared/IngredientDetails';
import Modal from '../shared/modal/Modal';
import {useStore} from '../../hooks/useStore';
import Button from '../shared/buttons/Button';

const BarcodeInfoModal = ({ingredient, onClose, title}) => {
  const [detailIngredient, setDetailIngredient] = useState(ingredient);
  const store = useStore();
  const {t} = useTranslation();

  return (
    <Modal
      title={title}
      fullscreen={store.clientStore.isMobile}
      open
      footer={(
        <Button onClick={onClose}>{t('button.close')}</Button>
      )}
      maskClosable={false}
      onCancel={onClose}
    >
      <ScanIngredient
        ingredient={detailIngredient}
        onChangeIngredient={setDetailIngredient}
        disabled={!store.operationStore.active}
      />
      {detailIngredient ? <IngredientDetails ingredient={detailIngredient}/> : null}
    </Modal>
  );
};

export default observer(BarcodeInfoModal);
