import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ColProps, Empty, Input, Row, RowProps, Tree } from 'antd';
import DocumentByType from '../../document/DocumentByType';
import styles from './DocumentsNavigator.module.scss';
import { useMount } from '../../../hooks/useMount';
import { Document, DocumentTreeNode } from '../../../models/document';

const { Search } = Input;
const { DirectoryTree } = Tree;

type DocumentsNavigatorProps = Omit<RowProps, 'gutter'> & {
  documentTree: any[];
  selectedKeys?: number[];
  openedDocument: Document;
  onDocumentIdSelect: (value: number) => {};
  allowDocumentCloseOnClick: boolean;
  treeColProps: Omit<ColProps, 'style'>;
  viewerColProps: ColProps;
};

const DocumentsNavigator: React.FC<DocumentsNavigatorProps> = ({
  documentTree,
  selectedKeys,
  openedDocument = undefined,
  treeColProps = { xl: 6, md: 6, sm: 24, xs: 24 },
  viewerColProps = { xl: 18, md: 18, sm: 24, xs: 24 },
  allowDocumentCloseOnClick = false,
  onDocumentIdSelect = () => undefined,
  ...props
}) => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState(selectedKeys ? selectedKeys[0] : undefined);
  const [selectedDocument, setSelectedDocument] = useState(openedDocument);
  const [searchValue, setSearchValue] = useState('');
  const [expandedKeys, setExpandedKeys] = useState<(number | string)[]>(
    selectedKeys !== undefined && selectedKeys?.length > 0 ? selectedKeys[0].toString().split('-') : []);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState(selectedKeys);

  const getParentKey = (key: number, tree: DocumentTreeNode[]): number | null => {
    let parentKey: number | null = null;
    for (let i = 0; i < tree.length; i += 1) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey;
  };

  const flatDataTree = (tree: DocumentTreeNode[]): DocumentTreeNode[] => {
    let flatTree = tree;
    tree.forEach((entry) => {
      if (entry.children) {
        flatTree = flatTree.concat(flatDataTree(entry.children));
      }
    });
    return flatTree;
  };

  const onSelect = (keys: (number | string)[], event: any) => {
    let newSelectedItem;
    if (event.node.isLeaf) {
      if (allowDocumentCloseOnClick && selectedItem === event.node.key) {
        setDefaultSelectedKeys([]);
        newSelectedItem = undefined;
        setSelectedDocument(undefined);
      } else {
        newSelectedItem = event.node.key;
        setSelectedDocument(event.node.document);
      }
    } else {
      setSelectedDocument(undefined);
    }
    setSelectedItem(newSelectedItem);
    onDocumentIdSelect(newSelectedItem);
  };

  useMount(() => {
    setSelectedDocument(openedDocument);
  }, [openedDocument]);

  useMount(() => {
    setSelectedItem(selectedKeys ? selectedKeys[0] : undefined);
    setExpandedKeys(selectedKeys !== undefined && selectedKeys?.length > 0
      ? selectedKeys[0].toString().split('-') : []);
  }, [selectedKeys]);

  const onExpand = (keys: (number | string)[]) => setExpandedKeys(keys);

  const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    if (value.length > 0) {
      setExpandedKeys(flatDataTree(documentTree)
        .map((item) => {
          if (typeof item.title === 'string' && item.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
            return getParentKey(item.key, documentTree);
          }
          return null;
        })
        .filter((item, i, self): item is number => item !== null && item >= 0 && self.indexOf(item) === i));
    }
  };

  const highlightSearched = (data: DocumentTreeNode[]) => data.map<DocumentTreeNode>((item): DocumentTreeNode => {
    if (typeof item.title !== 'string') {
      return item;
    }
    const index = item.title.toLowerCase().indexOf(searchValue.toLowerCase());
    const beforeStr = item.title.substr(0, index);
    const searchStr = item.title.substr(index, searchValue.length);
    const afterStr = item.title.substr(index + searchValue.length);
    const style = { color: selectedItem === item.key ? '#000000' : '#1890FF', fontWeight: 'bold' };
    let title = null;
    if (index > -1) {
      title = (
        <span>
          {beforeStr}
          <span style={style}>{searchStr}</span>
          {afterStr}
        </span>
      );
    } else if (item.children?.length > 0) {
      title = (
        <span>{item.title}</span>
      );
    }

    if (item.children) {
      return { ...item, title, children: highlightSearched(item.children) };
    }

    return {
      ...item,
      title,
    };
  }).filter((item) => item.children?.length > 0 || item.title !== null);

  if (documentTree.length === 0) {
    return <Empty description={t('document.noDocuments')} style={{ marginTop: 40 }}/>;
  }

  return (
    <Row gutter={[16, { xs: 16, sm: 16, md: 16, lg: 16, xl: 0 }]} {...props}>
      <Col {...treeColProps} style={{ height: '100%' }}>
        <Search
          style={{ marginBottom: 8 }}
          placeholder={t<string>('document.documentDetailModal.searchPlaceholder')}
          onChange={onSearchChange}
          allowClear
        />
        {!allowDocumentCloseOnClick && (
          <DirectoryTree
            showIcon
            onSelect={onSelect}
            expandedKeys={expandedKeys}
            defaultSelectedKeys={defaultSelectedKeys}
            selectedKeys={selectedItem ? [selectedItem] : []}
            onExpand={onExpand}
            treeData={highlightSearched(documentTree)}
            className={styles.documentsDirectoryTree}
          />
        )}
        {allowDocumentCloseOnClick && (
          <Tree
            showIcon
            blockNode
            onSelect={onSelect}
            expandedKeys={expandedKeys}
            defaultSelectedKeys={defaultSelectedKeys}
            selectedKeys={selectedItem ? [selectedItem] : []}
            onExpand={onExpand}
            treeData={highlightSearched(documentTree)}
            className={styles.documentsTree}
          />
        )}
      </Col>
      {selectedDocument && (
        <Col {...viewerColProps}>
          <DocumentByType document={selectedDocument}/>
        </Col>
      )}
    </Row>
  );
};

export default DocumentsNavigator;
