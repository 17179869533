import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Divider} from 'antd';
import {useTranslation} from 'react-i18next';
import Modal from '../shared/modal/Modal';
import {useStore} from '../../hooks/useStore';
import ScanTerminal from './shared/ScanTerminal';
import {useMount} from '../../hooks/useMount';
import AlertWarning from '../shared/alert/AlertWarning';
import Table from '../shared/tables/Table';

const BrokenModal = ({
  onSuccess,
  onCancel,
  brokenReasons,
  ...props
}) => {
  const store = useStore();
  const {t} = useTranslation();
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const [selectedReason, setSelectedReason] = useState(0);
  const [dataSource, setDataSource] = useState([]);

  const columns = [
    {
      title: t('terminalManagement.modal.broken.brokenReason'),
      dataIndex: 'name',
      key: 'name',
    },
  ];

  useMount(() => {
    const reasons = [...brokenReasons];
    if (selectedTerminal?.isBroken) {
      const existingReason = reasons.findIndex((reason) => reason === selectedTerminal.brokenReason);
      if (existingReason >= 0) {
        setSelectedReason(existingReason);
      } else {
        reasons.push(selectedTerminal.brokenReason);
        setSelectedReason(reasons.length - 1);
      }
    }
    setDataSource(reasons.map((value, index) => ({
      key: index,
      name: value,
    })));
  }, [selectedTerminal]);

  const handleSubmit = () => {
    if (selectedTerminal && brokenReasons[selectedReason]) {
      store.terminalStore.update({
        id: selectedTerminal.id,
        name: selectedTerminal.name,
        isBroken: true,
        brokenReason: brokenReasons[selectedReason],
      }).then(() => onSuccess());
    }
  };

  return (
    <Modal
      fullscreen={store.clientStore.isMobile}
      open
      okText={t('terminalManagement.modal.broken.reportBroken')}
      cancelText={t('confirm.cancel')}
      okButtonProps={{disabled: !brokenReasons.length || !brokenReasons[selectedReason] || !selectedTerminal}}
      maskClosable={false}
      onCancel={onCancel}
      onOk={handleSubmit}
      {...props}
    >
      <ScanTerminal terminal={selectedTerminal} onChangeTerminal={setSelectedTerminal}/>
      <Divider/>
      {selectedTerminal?.isBroken
        ? (
          <AlertWarning
            message={t('terminalManagement.modal.broken.alreadyMarked')}
            style={{marginBottom: '1em'}}
          />
        ) : null}
      <Table
        style={!selectedTerminal ? {opacity: 0.6, cursor: 'not-allowed'} : {}}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        onRow={(record) => ({
          onClick: () => selectedTerminal && setSelectedReason(record.key),
        })}
        rowSelection={{
          type: 'radio',
          onChange: (values) => selectedTerminal && setSelectedReason(values[0]),
          selectedRowKeys: [selectedReason],
        }}
      />

    </Modal>
  );
};

export default observer(BrokenModal);
