import { action, computed, makeObservable, observable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore, LoadStrategies } from './entityStore';
import { Transition } from '../models/transition';
import { validTransitionsEndpoint, WorkplacesApi } from '../middleware/endpoints/workplaces';
import { RootStore } from './rootStore';

export const ValidTransitionsStoreActions = {
  loadAllValidTransitions: 'validTransitions',
};

export class ValidTransitionsStore extends EntityStore<Transition> {
  transitions: Transition[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'transitions', Api.transitions, Transition);

    makeObservable(this, {
      transitions: observable,
      isLoadingAllValidTransitions: computed,
      loadValidTransitionsByWorkplace: action,
      interruptionTransitions: computed,
      hasValidInterruptionTransitions: computed,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.operationStateStore,
        modelId: 'activeOperationToStateId',
      },
      {
        store: this.rootStore.operationStateStore,
        modelId: 'startingOperationToStateId',
      },
      {
        store: this.rootStore.workplaceStateStore,
        modelId: 'workplaceToStateId',
      },
    ];
  }

  loadValidTransitionsByWorkplace(
    workplaceId: number,
    { params, ...options }: { params?: { timestamp?: string; includeInternal?: boolean } } = {}
  ) {
    return this.loadAllWithDependencies(
      {
        api: WorkplacesApi,
        apiEndpoint: validTransitionsEndpoint,
        action: ValidTransitionsStoreActions.loadAllValidTransitions,
        strategy: LoadStrategies.replace,
        params: {
          id: workplaceId,
          ...params,
        },
        ...options,
      }
    );
  }

  get isLoadingAllValidTransitions() {
    return this.isActionInProgress(ValidTransitionsStoreActions.loadAllValidTransitions);
  }

  get interruptionTransitions() {
    return this.transitions.filter(
      (transition) => (
        transition.activeOperationToState?.isInterruption
        || transition.workplaceToState?.isInterruption
      )
    );
  }

  get hasValidInterruptionTransitions() {
    return Boolean(this.interruptionTransitions.length);
  }
}
