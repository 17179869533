/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { useStore } from '../../../../hooks/useStore';
import AutoComplete, { AutoCompleteProps } from './Autocomplete';
import { Material } from '../../../../models/material';

export type MaterialSelectorProps = Omit<AutoCompleteProps, 'propertyName'> & {
  propertyName?: string;
};

const MaterialSelector: React.FC<MaterialSelectorProps> = ({
  form,
  name,
  propertyName = 'id',
  value,
  multiple,
  ...props
}) => {
  const store = useStore();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <AutoComplete
      {...props}
      value={value}
      form={form}
      name={name}
      propertyName={propertyName}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      multiple={multiple}
      // TODO: remove
      // @ts-ignore
      searchFunction={store.materialStore.searchByNoAndName.bind(store.materialStore)}
      reverseSearchFunction={
        multiple
          ? store.materialStore.getByIds.bind(store.materialStore)
          : store.materialStore.getById.bind(store.materialStore)
      }
      populateStore={store.materialStore.load.bind(store.materialStore)}
      renderOption={(material: Material) => ({
        value: material.id,
        label: `${material.no} (${material.name})`,
      })}
    />
  );
};

export default MaterialSelector;
