import { action, observable, makeObservable } from 'mobx';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';
import i18n from '../i18n/i18n';
import { RootStore } from './rootStore';

export class FlashMessageStore {
  /**
   * @type {FlashMessage[]}
   */
  flashMessages: FlashMessage[] = [];
  rootStore: RootStore;
  comparator: (id: string) => (e: FlashMessage) => boolean;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      flashMessages: observable,
      addFlashMessage: action,
      removeFlashMessage: action,
    });

    this.rootStore = rootStore;
    this.comparator = (id?) => (e) => e.id?.toString() === id?.toString();
  }

  /**
   * @param {FlashMessage} message
   */
  addFlashMessage(message: FlashMessage) {
    this.flashMessages.push(message);
  }

  removeFlashMessage(message: FlashMessage) {
    const index = this.flashMessages.findIndex(this.comparator(message.id));
    if (index >= 0) {
      this.flashMessages.splice(index, 1);
    }
  }

  clear() {
    this.flashMessages.splice(0);
  }

  addUnexpectedErrorFlashMessage() {
    const message = new FlashMessage(FlashMessageType.ERROR, i18n.t('errors.messages.unexpected'));
    this.addFlashMessage(message);
  }
}
