import { makeObservable, observable } from 'mobx';
import { AxiosResponse } from 'axios';
import { Api } from '../middleware/api';
import { EntityStore, LoadStrategies } from './entityStore';
import { PrintJobLog } from '../models/printJobLog';
import { reprintPrintJobLogEndpoint } from '../middleware/endpoints/printJobLogs';
import i18n from '../i18n/i18n';
import { RootStore } from './rootStore';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';

export class PrintJobLogStore extends EntityStore<PrintJobLog> {
  printJobLogs: PrintJobLog[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'printJobLogs', Api.printJobLogs, PrintJobLog, true);

    makeObservable(this, {
      printJobLogs: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.printJobStore,
        modelId: 'printJobId',
      },
    ];
  }

  loadByPrintJobId(printJobId: number) {
    return this.loadAll({
      strategy: LoadStrategies.replace,
      params: {
        printJobId,
      },
    });
  }

  reprintPrintJobLogById(printJobLogId: number, printerId: number) {
    this.addPendingAction(reprintPrintJobLogEndpoint);
    return this.api.reprint(printJobLogId, { printerId }).then(({ data }: AxiosResponse) => {
      this.removePendingAction(reprintPrintJobLogEndpoint);
      if (data?.error?.length > 0) {
        this.addMessage(new FlashMessage(FlashMessageType.ERROR, i18n.t('printJobLog.error')), {});
        return undefined;
      }
      if (data.state === 'FAILURE') {
        this.addMessage(new FlashMessage(FlashMessageType.WARNING, i18n.t('printJobLog.warning')), {});
      } else {
        this.addMessage(new FlashMessage(FlashMessageType.SUCCESS, i18n.t('printJobLog.success')), {});
      }
      return this.add(this.createModelInstance(data));
    });
  }
}
