import {useTranslation} from 'react-i18next';
import {faFile} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Modal from '../shared/modal/Modal';
import DocumentsNavigator from '../shared/dataDisplay/DocumentsNavigator';
import {useStore} from '../../hooks/useStore';

const DocumentsDetailModal = ({
  selectedKeys = undefined,
  openedDocument = undefined,
  documentTree,
  ...props
}) => {
  const {t} = useTranslation();
  const store = useStore();

  return (
    <Modal
      width="90%"
      title={(
        <>
          <FontAwesomeIcon icon={faFile}/>
          {' '}
          {t('document.documentDetailModal.title')}
        </>
      )}
      open
      footer={false}
      {...props}
    >
      <DocumentsNavigator
        selectedKeys={selectedKeys}
        openedDocument={openedDocument}
        documentTree={documentTree}
        isMobile={store.clientStore.isMobile}
        style={{height: '70vh'}}
      />
    </Modal>
  );
};

export default DocumentsDetailModal;
