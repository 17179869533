import { computed, makeObservable, observable } from 'mobx';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

export enum BatchQueueItemState {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  PLANNED = 'PLANNED',
  USED = 'USED'
}

export class BatchQueueItem extends BaseModel {
  id: number = 0;
  workplaceId: number = 0;
  materialId: number = 0;
  quantity: number = 0;
  quantityUsed: number = 0;
  plannedAt: string = '';
  batchId: number = 0;
  state: BatchQueueItemState = BatchQueueItemState.PLANNED;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      workplaceId: observable,
      workplace: computed,
      materialId: observable,
      material: computed,
      quantity: observable,
      quantityUsed: observable,
      plannedAt: observable,
      batchId: observable,
      batch: computed,
      state: observable,
      updatedAt: observable,
      updatedBy: observable,
      createdAt: observable,
      createdBy: observable,
    });
  }

  get workplace() {
    return this.rootStore.workplaceStore.getById(this.workplaceId);
  }

  get material() {
    return this.rootStore.materialStore.getById(this.materialId);
  }

  get batch() {
    return this.rootStore.batchStore.getById(this.batchId);
  }
}
