import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { PhaseTimeout } from '../models/phaseTimeout';
import { RootStore } from './rootStore';

export class PhaseTimeoutStore extends EntityStore<PhaseTimeout> {
  phaseTimeouts: PhaseTimeout[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'phaseTimeouts', Api.phaseTimeouts, PhaseTimeout);

    makeObservable(this, {
      phaseTimeouts: observable,
    });
  }
  getDependencies() {
    return [
      {
        store: this.rootStore.workflowStore,
        modelId: 'workflowId',
      },
      {
        store: this.rootStore.phaseStore,
        modelId: 'phaseId',
      },
    ];
  }

  getByPhaseIdAndWorkflowId(phaseId: number, workflowId: number) {
    return this.phaseTimeouts.find((timeout) => (
      timeout.phaseId === phaseId && timeout.workflowId === workflowId
    ));
  }
}
