import { computed, makeObservable, observable } from 'mobx';
import dayjs from 'dayjs';
import { Observer } from 'mobx-react-lite';
import i18n from '../i18n/i18n';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import { datetimeFormat } from '../config/dayjs';
import { sortAlphabetically, sortBoolean, sortChronologically } from '../components/shared/tables/sorters';
import UserFullName from '../components/shared/UserFullName';
import { RootStore } from '../stores/rootStore';
import { CustomPropertiesObject } from './customProperty';

export abstract class StateLog extends BaseModel {
  id: number = 0;
  stateId: number = 0;
  interruptionSourceHierarchyId: number | null = null;
  interruptionClassId: number | null = null;
  interruptionReasonId: number | null = null;
  start: string = '';
  end: string | null = null;
  message: string | null = null;
  properties?: CustomPropertiesObject = undefined;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  protected constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      stateId: observable,
      interruptionSourceHierarchyId: observable,
      interruptionSourceHierarchy: computed,
      interruptionClassId: observable,
      interruptionClass: computed,
      interruptionReasonId: observable,
      interruptionReason: computed,
      interruptionCode: computed,
      start: observable,
      end: observable,
      message: observable,
      properties: observable,
      createdBy: observable,
      createdByUser: computed,
      updatedBy: observable,
      updatedByUser: computed,
      durationSeconds: computed,
      active: computed,
    });
  }

  get interruptionSourceHierarchy() {
    return this.rootStore.hierarchyStore.getById(this.interruptionSourceHierarchyId);
  }

  get interruptionClass() {
    return this.rootStore.interruptionClassStore.getById(this.interruptionClassId);
  }

  get interruptionReason() {
    return this.rootStore.interruptionReasonStore.getById(this.interruptionReasonId);
  }

  get interruptionCode() {
    return this.properties?.FAULT_CODE || '';
  }

  get createdByUser() {
    return this.rootStore.userStore.getById(this.createdBy);
  }

  get updatedByUser() {
    return this.rootStore.userStore.getById(this.updatedBy);
  }

  get active() {
    return this.end === null;
  }

  /**
   * Calculates the duration of this stateLog and returns it as a dayjs.Duration object
   */
  get durationSeconds() {
    if (this.start) {
      return dayjs.duration(dayjs((this.end) ? this.end : dayjs())
        .diff(dayjs(this.start), 'seconds'), 'seconds');
    }
    return null;
  }

  displayableProperties = [
    displayableProperty({
      title: i18n.t('stateLog.model.attributes.start'),
      params: [
        displayablePropertyParam({
          path: 'start', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      key: 'start',
      sorter: (a, b) => sortChronologically(a.start, b.start),
      raw: false,
    }),
    displayableProperty({
      title: i18n.t('stateLog.model.attributes.end'),
      params: [
        displayablePropertyParam({
          path: 'end', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      key: 'end',
      sorter: (a, b) => sortChronologically(a.end, b.end),
      raw: false,
    }),
    displayableProperty({
      title: i18n.t('stateLog.model.attributes.durationSeconds'),
      params: [
        displayablePropertyParam({
          path: 'durationSeconds', transform: transformation.detailedDuration,
        }),
      ],
      template: '{value}',
      key: 'durationSeconds',
      sorter: (a, b) => sortChronologically(a.durationSeconds, b.durationSeconds),
      raw: false,
    }),
    displayableProperty({
      title: i18n.t('stateLog.model.attributes.active'),
      params: [
        displayablePropertyParam({
          path: 'active', transform: transformation.none,
        }),
      ],
      template: '{value}',
      key: 'active',
      sorter: (a, b) => sortBoolean(a.active, b.active),
      raw: false,
    }),
    displayableProperty({
      title: i18n.t('stateLog.model.attributes.interruptionSourceHierarchy'),
      params: [
        displayablePropertyParam({
          path: 'interruptionSourceHierarchy.title', transform: transformation.none,
        }),
      ],
      template: '{value}',
      key: 'interruptionSourceHierarchy',
      sorter: (a, b) => sortAlphabetically(
        a.interruptionSourceHierarchy?.title,
        b.interruptionSourceHierarchy?.title
      ),
      raw: false,
    }),
    displayableProperty({
      title: i18n.t('interruptionClass.model.one'),
      params: [
        displayablePropertyParam({
          path: 'interruptionClass.label', transform: transformation.none,
        }),
      ],
      template: '{value}',
      key: 'interruptionClass',
      sorter: (a, b) => sortAlphabetically(
        a.interruptionClass?.label,
        b.interruptionClass?.label
      ),
      raw: false,
    }),
    displayableProperty({
      title: i18n.t('interruptionReason.model.one'),
      params: [
        displayablePropertyParam({
          path: 'interruptionReason.label', transform: transformation.none,
        }),
      ],
      template: '{value}',
      key: 'interruptionReason',
      sorter: (a, b) => sortAlphabetically(a.interruptionReason?.label, b.interruptionReason?.label),
      raw: false,
    }),
    displayableProperty({
      title: i18n.t('stateLog.model.attributes.message'),
      params: [
        displayablePropertyParam({
          path: 'message', transform: transformation.none,
        }),
      ],
      template: '{value}',
      key: 'message',
      sorter: (a, b) => sortAlphabetically(a.message, b.message),
      raw: false,
    }),
    displayableProperty({
      title: i18n.t('stateLog.model.attributes.createdBy'),
      params: [
        displayablePropertyParam({
          path: 'createdByUser',
          transform: (user) => (
            <Observer>
              {() => <UserFullName user={user}/>}
            </Observer>
          ),
        }),
      ],
      template: '{value}',
      key: 'createdByUser',
      sorter: (a, b) => sortAlphabetically(a.createdByUser.fullName, b.createdByUser.fullName),
      raw: true,
    }),
    displayableProperty({
      title: i18n.t('stateLog.model.attributes.updatedBy'),
      params: [
        displayablePropertyParam({
          path: 'updatedByUser',
          transform: (user) => (
            <Observer>
              {() => <UserFullName user={user}/>}
            </Observer>
          ),
        }),
      ],
      template: '{value}',
      key: 'updatedByUser',
      sorter: (a, b) => sortAlphabetically(a.updatedByUser.fullName, b.updatedByUser.fullName),
      raw: true,
    }),
  ];
}
