import {observable, makeObservable} from 'mobx';
import {User} from '../models/user';
import {EntityStore} from './entityStore';
import {Api} from '../middleware/api';
import i18n from '../i18n/i18n';

export class ObjectLockStore extends EntityStore {
  // this array is not filled by loadAll(), therefore it is filled manually
  // it is intended to use only internally, otherwise use hook useObjectLock
  objectLocks = [];
  // only set this in development, when the locks get in your way
  ignoreLocks = false;
  // required for entity-store but unused because entities are loaded in raw format
  dummyList = [];

  constructor(rootStore) {
    super(rootStore, 'dummyList', Api.objectLocks, User);

    makeObservable(this, {
      objectLocks: observable,
      ignoreLocks: observable,
    });

    setInterval(async () => {
      this.objectLocks.forEach((objectLock) => {
        const res = this.acquire(objectLock.object, objectLock.objectId);
        if (res) {
          this.setObjectLocks({
            object: objectLock.object,
            objectId: objectLock.objectId,
            key: res,
          });
        }
      });
    }, 60 * 2000); // execute all 2 minutes
  }

  async acquire(model, id) {
    const objectLock = {
      object: model.name || String(model),
      objectId: id,
    };

    try {
      const {data} = await this.loadAll({
        apiEndpoint: 'acquire',
        params: objectLock,
        raw: true,
      });
      if (data.key) {
        // add lock to store, loadAll() doesn't do it
        objectLock.key = data.key;
        this.setObjectLocks(objectLock);
        // end adding lock
        return data.key;
      } if (this.ignoreLocks) {
        this.warn('flashMessages.lockIgnored');
        return 'ignored';
      }
      this.rootStore.flashMessageStore.addFlashMessage({type: 'error', title: i18n.t('flashMessages.objectLocked')});
      return null;
    } catch (e) {
      this.warn('flashMessages.errorAcquiringLock');
      return null;
    }
  }

  async release(key) {
    try {
      await this.loadAll({
        apiEndpoint: 'release',
        params: {
          key,
        },
        raw: true,
      });
      // remove lock from store, loadAll() doesn't do it
      const index = this.objectLocks.findIndex((obj) =>
        obj.key === key);
      if (index >= 0) {
        this.objectLocks.splice(index, 1);
      }
      // end removing lock
    } catch (e) {
      this.warn('flashMessages.errorReleasingLock');
    }
  }

  warn(title) {
    // t('flashMessages.lockIgnored')
    // t('flashMessages.errorReleasingLock')
    // t('flashMessages.errorAcquiringLock')
    this.rootStore.flashMessageStore.addFlashMessage({type: 'warning', title: i18n.t(title)});
  }

  setObjectLocks(objectLock) {
    const index = this.objectLocks.findIndex((obj) =>
      obj.model === objectLock.model && obj.objectId === objectLock.objectId);
    if (index < 0) {
      this.objectLocks.push(objectLock);
    } else {
      this.objectLocks[index] = objectLock;
    }
  }
}
