import {useState} from 'react';
import {Form} from 'antd';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {useStore} from '../../../hooks/useStore';
import ScanInput from '../../shared/inputs/ScanInput';
import TouchTag from '../../shared/tags/TouchTag';
import i18n from '../../../i18n/i18n';

const ScanTerminal = ({
  terminal,
  onChangeTerminal,
}) => {
  const [form] = Form.useForm();
  const store = useStore();
  const {t} = useTranslation();
  const [isValid, setIsValid] = useState(false);

  const handleValuesChange = (changedValues) => {
    if (changedValues?.terminal !== undefined) {
      setIsValid(changedValues?.terminal && store.terminalStore.getByName(changedValues.terminal));
    }
  };
  const addTerminal = (terminalName) => {
    const terminalToAdd = store.terminalStore.getByName(terminalName);
    setIsValid(false);
    if (terminalToAdd) {
      onChangeTerminal(terminalToAdd);
      form.setFieldsValue({terminal: null});
    } else {
      store.flashMessageStore.addFlashMessage({
        type: 'warning',
        title: i18n.t('terminalManagement.modal.form.invalidTerminal'),
      });
    }
  };
  const TerminalTag = () => {
    if (terminal) {
      return (
        <TouchTag style={{width: '100%'}} closable onClose={() => onChangeTerminal(null)}>
          {terminal.name}
        </TouchTag>
      );
    }
    return <i style={{opacity: 0.6}}>{t('terminalManagement.modal.form.noTerminal')}</i>;
  };

  return (
    <>
      <Form form={form} onValuesChange={handleValuesChange}>
        <Form.Item
          name={'terminal'}
          label={t('terminalManagement.modal.form.terminal')}
        >
          <ScanInput disabled={terminal} isValid={isValid} onAccept={addTerminal}/>
        </Form.Item>
      </Form>
      <TerminalTag/>
    </>
  );
};

export default observer(ScanTerminal);
