import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormModal from '../../shared/modal/FormModal';
import Button from '../../shared/buttons/Button';
import StorageUnitSelector from '../../shared/inputs/autocomplete/StorageUnitSelector';

export type DosageSelectTargetModalProps = {
  onCancel: () => void;
  onSuccess: (targetUnitId: number) => void;
  open?: boolean;
};

const DosageSelectTargetModal: React.FC<DosageSelectTargetModalProps> = ({
  onCancel,
  onSuccess,
  open,
}) => {
  const { t } = useTranslation();
  const [selectedTargetStorageUnit, setSelectedTargetStorageUnit] = useState<number | null>(null);

  const handleSuccess = async () => {
    try {
      if (selectedTargetStorageUnit) {
        if (onSuccess) {
          onSuccess(selectedTargetStorageUnit);
          setSelectedTargetStorageUnit(null);
        }
      } else {
        throw new Error('dosageWidget.selectTargetStorageUnit.error.noUnit');
      }
    } catch (error) {
      return Promise.reject(error);
    }
    return Promise.resolve();
  };

  const handleCancel = async () => {
    try {
      if (onCancel) {
        onCancel();
        setSelectedTargetStorageUnit(null);
      }
    } catch (error) {
      return Promise.reject(error);
    }
    return Promise.resolve();
  };

  return (
    <FormModal
      data-cy={'DosageSelectTargetModal-ModalTitle'}
      title={t('dosageWidget.selectTargetStorageUnit.title')}
      open={open}
      onCancel={handleCancel}
    >
      <StorageUnitSelector
        data-cy={'DosageSelectTargetModal-StorageUnitSelect'}
        style={{ width: 300, display: 'flex' }}
        onChange={setSelectedTargetStorageUnit}
        disableExactMatch
        placeholder={t<string>('dosageWidget.selectTargetStorageUnit.placeholder')}
      />

      <div style={{ textAlign: 'right', marginTop: '20px' }}>
        <Button
          data-cy={'DosageSelectTargetModal-SaveButton'}
          type={'primary'}
          onClick={handleSuccess}
          style={{
            width: '150px',
            alignItems: 'flex-end',
          }}
        >
          {t('dosageWidget.selectTargetStorageUnit.button.save')}
        </Button>
      </div>
    </FormModal>
  );
};

export default DosageSelectTargetModal;
