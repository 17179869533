import {useTranslation} from 'react-i18next';
import styles from './WelcomePage.module.scss';

export const WelcomePageFooter = () => {
  const {t} = useTranslation();

  return (
    <div className={styles.footer}>
      <a
        href={'/license-disclaimer.txt'}
        title={t('links.licenseDisclaimer')}
        download
      >
        {t('links.licenseDisclaimer')}
      </a>
    </div>
  );
};
