import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import InspectionTaskDescription from './InspectionTaskDescription';
import CustomPropertyValue from '../../../shared/dataDisplay/CustomPropertyValue';

const TaskPropertiesSection = ({availableProperties, task}) => {
  const {t} = useTranslation();

  return (
    <InspectionTaskDescription title={t('inspectionTask.drilldownModal.section.properties')}>
      {availableProperties.map((prop) => (
        <InspectionTaskDescription.Item
          key={prop.key}
          label={prop.label || prop.key}
        >
          <CustomPropertyValue properties={task.properties} availableProperty={prop}/>
        </InspectionTaskDescription.Item>
      ))}
    </InspectionTaskDescription>
  );
};

export default observer(TaskPropertiesSection);
