import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import InspectionTaskDescription from './InspectionTaskDescription';
import DocumentList from '../../../shared/documents/DocumentList';
import {useStore} from '../../../../hooks/useStore';
import ConfirmationValue from './ConfirmationValue';

const DFSection = ({fallback, task}) => {
  const {t} = useTranslation();
  const store = useStore();
  const formatRole = (roleId) => store.roleStore.getById(roleId)?.name;
  const formatPhases = (phaseIds) => store.phaseStore.getByIds(phaseIds)
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map((phase) => phase?.name)
    .join(', ');

  return (
    <>
      <InspectionTaskDescription title={t('inspectionTask.drilldownModal.section.mes')}>
        <InspectionTaskDescription.Item
          label={t('inspectionTask.model.attributes.id')}
        >
          {fallback(task.id)}
        </InspectionTaskDescription.Item>
        <InspectionTaskDescription.Item
          label={t('inspectionTask.model.attributes.requiredRoleId.label')}
        >
          {fallback(formatRole(task.requiredRoleId))}
        </InspectionTaskDescription.Item>
        <InspectionTaskDescription.Item
          label={t('inspectionTask.model.attributes.locationCode')}
        >
          {fallback(task.locationCode)}
        </InspectionTaskDescription.Item>
        <InspectionTaskDescription.Item label={t('inspectionTask.drilldownModal.mes.phase')}>
          {fallback(formatPhases(task.phaseIds))}
        </InspectionTaskDescription.Item>
        <InspectionTaskDescription.Item label={t('inspectionTask.drilldownModal.mes.inspectionType')}>
          {fallback(task.inspectionType)}
        </InspectionTaskDescription.Item>
        <InspectionTaskDescription.Item
          label={t('inspectionTask.model.attributes.active')}
        >
          <ConfirmationValue bool={!task.deactivatedAt}/>
        </InspectionTaskDescription.Item>
      </InspectionTaskDescription>
      <DocumentList
        style={{width: '100%'}}
        dataSource={task.documentIds?.slice()}
        title={
          (
            <div className={'ant-descriptions-header'}>
              <div className={'ant-descriptions-title'}>
                {t('inspectionTask.drilldownModal.section.documents')}
              </div>
            </div>
          )
        }
      />
    </>
  );
};

export default observer(DFSection);
