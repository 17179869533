import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faInfo} from '@fortawesome/pro-solid-svg-icons';
import Modal from '../shared/modal/Modal';
import OrderDetails from '../order/orderDetails/OrderDetails';

import {useStore} from '../../hooks/useStore';

const ActiveOperationDetailModal = ({widgetConfig, ...props}) => {
  const {t} = useTranslation();
  const store = useStore();

  return (
    <Modal
      fullscreen={store.clientStore.isMobile}
      title={(
        <>
          <FontAwesomeIcon icon={faInfo}/>
          {' '}
          {t('operation.activeOperationDetailModal.title')}
        </>
      )}
      width="90%"
      bodyStyle={{minHeight: '80vh'}}
      open
      footer={false}
      {...props}
    >
      <OrderDetails
        operations={store.operationStore.active ? [store.operationStore.active] : []}
        widgetConfig={widgetConfig}
      />
    </Modal>
  );
};

export default ActiveOperationDetailModal;
