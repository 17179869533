import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/pro-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../hooks/useStore';
import {useMount} from '../../hooks/useMount';
import Button from '../shared/buttons/Button';

const WidgetManualButton = ({manualPath = '', ignoreStoreState = false}) => {
  const store = useStore();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [path, setPath] = useState(undefined);

  useMount(() => {
    if (!manualPath) {
      return;
    }
    let newPath = manualPath;
    if (!newPath.endsWith('/')) {
      newPath = `${newPath}/`;
    }
    const lang = store.languageStore.lang.split('-')[0];
    newPath = `${newPath}${lang}.md`;
    setPath(newPath);
  }, [store.languageStore.lang, manualPath]);

  if (!path || (!store.manualStore.showProductionWidgetManual && !ignoreStoreState)) {
    return null;
  }

  const handleClick = (e) => {
    e.stopPropagation();
    navigate(`#help=${path}`);
  };

  return (
    <Button
      onClick={handleClick}
      type="link"
      style={{padding: 0, marginLeft: '12px'}}
    >
      <FontAwesomeIcon icon={faQuestionCircle}/>
      <span style={{fontWeight: 500, marginLeft: '0.5em'}}>
        {t('manual.display.title')}
      </span>
    </Button>
  );
};

export default observer(WidgetManualButton);
