import {Form, Input} from 'antd';
import {useTranslation} from 'react-i18next';
import {ValidationRules} from '../../utils/validationRules';
import {FormItemLayouts} from '../shared/form/formItemLayouts';
import CancelSaveButtonGroup from '../shared/buttons/CancelSaveButtonGroup';
import Select from '../shared/inputs/Select';
import {sortChronologically} from '../shared/tables/sorters';

const ShiftBooksForm = ({
  fields,
  onChange,
  onSubmit,
  onCancel,
  rows,
  activeOperation,
  finishedOperations,
  plannedOperations,
  ordersDisplayedInSelect,
  orderIsMandatory,
}) => {
  const [form] = Form.useForm();
  const {t} = useTranslation();

  const finished = finishedOperations.sort((a, b) => sortChronologically(b.actualEnd, a.actualEnd));
  const planned = plannedOperations.sort((a, b) => sortChronologically(a.plannedStart, b.plannedStart));

  return (
    <Form
      form={form}
      {...FormItemLayouts.default}
      labelAlign={'left'}
      onFinish={onSubmit}
      fields={fields}
      onFieldsChange={(changedFields, allFields) => onChange && onChange(allFields)}
    >
      <Form.Item
        name={'orderId'}
        label={t('shiftBook.model.attributes.order')}
        rules={[ValidationRules.requiredIf(orderIsMandatory)]}
      >
        <Select placeholder={t('shiftBook.shiftBooksForm.order.selectPlaceholder')} allowClear virtual={false}>
          {activeOperation
          && (
            <Select.OptGroup label={t('shiftBook.shiftBooksForm.order.currentOrder')}>
              <Select.Option key={activeOperation.order.id} value={activeOperation.order.id}>
                {activeOperation.order.no}
                {' - '}
                {activeOperation.order.material.name}
              </Select.Option>
            </Select.OptGroup>
          )}
          {finished.length > 0
          && (
            <Select.OptGroup label={t('shiftBook.shiftBooksForm.order.finishedOrders')}>
              {finished.map((operation, index) => {
                if (index < ordersDisplayedInSelect) {
                  return (
                    <Select.Option key={operation.order.id} value={operation.order.id}>
                      {operation.order.no}
                      {' - '}
                      {operation.order.material.name}
                    </Select.Option>
                  );
                }
                return null;
              })}
            </Select.OptGroup>
          )}
          {planned.length > 0
          && (
            <Select.OptGroup label={t('shiftBook.shiftBooksForm.order.plannedOrders')}>
              {planned.map((operation, index) => {
                if (index < ordersDisplayedInSelect) {
                  return (
                    <Select.Option key={operation.order.id} value={operation.order.id}>
                      {operation.order.no}
                      {' - '}
                      {operation.order.material.name}
                    </Select.Option>
                  );
                }
                return null;
              })}
            </Select.OptGroup>
          )}
        </Select>
      </Form.Item>
      <Form.Item name={'message'} label={t('shiftBook.model.attributes.message')} rules={[ValidationRules.required()]}>
        <Input.TextArea rows={rows}/>
      </Form.Item>
      <Form.Item name={'workplaceId'} hidden>
        <Input/>
      </Form.Item>
      <Form.Item {...FormItemLayouts.fullWidth} style={{textAlign: 'right'}}>
        <CancelSaveButtonGroup onCancel={onCancel}/>
      </Form.Item>
    </Form>
  );
};

export default ShiftBooksForm;
