import {executePost} from '../requests';

const resourcesName = 'v1/auth';
export const refreshUrl = `${resourcesName}/refresh-token`;

export const AuthApi = {
  login: (params) => executePost(`${resourcesName}/login`, params),
  guestLogin: (params) => executePost(`${resourcesName}/guest-login`, params),
  logout: (params) => executePost(`${resourcesName}/logout`, params),
  refreshToken: () => executePost(refreshUrl, {}),
};
