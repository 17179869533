import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Material } from '../../../models/material';
import MaterialSelector from '../../shared/inputs/autocomplete/MaterialSelector';
import Form from '../../shared/form/Form';
import Select, { SelectProps } from '../../shared/inputs/Select';
import { sortAlphabetically } from '../../shared/tables/sorters';

export type MaterialSelectProps = SelectProps<number> & {
  restrictToOrder: boolean;
  materials: Material[];
};

export const MaterialSelect: React.FC<MaterialSelectProps> = ({
  restrictToOrder,
  materials,
}) => {
  const { t } = useTranslation();
  let select: ReactNode;
  if (restrictToOrder) {
    const materialOptions = materials.map((material: Material) => ({
      value: material.id,
      label: material.label,
    })).sort((a, b) => sortAlphabetically(a.label, b.label));
    select = (
      <Select<number, { label: string, value: number }>
        allowClear
        placeholder={t<string>('qualityReport.form.material.selectPlaceholder')}
        options={materialOptions}
        showSearch
        filterOption={(input: string, option?: { label: string, value: number }) =>
          (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
      />
    );
  } else {
    select = (
      <MaterialSelector
        allowClear
        multiple={false}
        placeholder={t<string>('qualityReport.form.material.selectPlaceholder')}
      />
    );
  }
  return (
    <Form.Item
      name={'materialId'}
      label={t('qualityReport.model.material.label')}
    >
      {select}
    </Form.Item>
  );
};
