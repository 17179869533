import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { faLayerPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { WidgetLayout } from '../../../models/widgetLayout';
import { WidgetLayouts } from '../../shared/widgets/WidgetLayouts';
import OperatorWidget from '../../operator/shared/OperatorWidget';
import { useStore } from '../../../hooks/useStore';
import { ScopeContext } from '../../../policies/scopeContext';
import { DosageSetupWidgetConfig } from './dosageSetupWidgetConfig';
import DosageSetupTable from './DosageSetupTable';
import { Scopes } from '../../../models/scopes';
import { WidgetImplProps } from '../../shared/widgets/Widget';
import { WidgetTypes } from '../../../types/widget';
import AlertWarning from '../../shared/alert/AlertWarning';
import { useMount } from '../../../hooks/useMount';

export type DosageSetupWidgetProps = {
  disabled?: boolean;
  minimized?: boolean;
  identifier: string;
};

const DosageSetupWidget: React.FC<DosageSetupWidgetProps> & WidgetImplProps = ({
  disabled,
  minimized = false,
  identifier,
}) => {
  const { t } = useTranslation();
  const store = useStore();
  const widgetConfig = useMemo<DosageSetupWidgetConfig>(() =>
    new DosageSetupWidgetConfig(store, identifier), [store.settingStore.operatorSettingsReady]);
  const [isStorageAreasOkay, setIsStorageAreasOkay] = React.useState<boolean>(false);

  useMount(() => {
    const { selectedWorkplace } = store.workplaceStore;
    if (!selectedWorkplace) {
      setIsStorageAreasOkay(false);
      return;
    }

    store.workplaceStorageRelationStore
      .loadByWorkplaceId(selectedWorkplace.id).then((res) => {
        if (res.length !== 1) {
          setIsStorageAreasOkay(false);
          return;
        }

        const workplaceStorageRelation = res[0];
        const {
          sourceStorageAreaIds,
          internalStorageAreaIds,
          destinationStorageAreaIds,
        } = workplaceStorageRelation;

        if (sourceStorageAreaIds.length !== 1
          || internalStorageAreaIds.length !== 1
          || destinationStorageAreaIds.length !== 1
        ) {
          setIsStorageAreasOkay(false);
          return;
        }

        setIsStorageAreasOkay(true);
      }).catch(() => {
        setIsStorageAreasOkay(false);
      });
  }, [store.workplaceStore.selectedWorkplace]);

  return (
    <ScopeContext.Provider value={[Scopes.dosage]}>
      <OperatorWidget
        title={widgetConfig.getWidgetTitle()}
        icon={<FontAwesomeIcon icon={faLayerPlus}/>}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        onHeadClick={undefined}
        manualPath={'/dosage/setup'}
      >
        {
          isStorageAreasOkay ? (
            <DosageSetupTable
              widgetConfig={widgetConfig}
            />
          ) : (
            <AlertWarning
              message={t('dosageSetupWidget.message.misconfiguredStorageAreas')}
              type="error"
              showIcon
            />
          )
        }
      </OperatorWidget>
    </ScopeContext.Provider>
  );
};

DosageSetupWidget.icon = faLayerPlus;
DosageSetupWidget.identifier = WidgetTypes.DosageSetupWidget;
DosageSetupWidget.defaultLayout = new WidgetLayout(
  {
    identifier: DosageSetupWidget.identifier,
    x: 0,
    y: 10,
    height: 6,
    minHeight: 4,
    width: WidgetLayouts.fullWidth.w,
    minWidth: WidgetLayouts.halfWidth.minW,
  }
);

export default observer(DosageSetupWidget);
