import {useCallback, useMemo, useState} from 'react';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {Col, Row} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUsers} from '@fortawesome/free-solid-svg-icons';
import {debounce} from 'lodash';
import OperatorWidget from '../operator/shared/OperatorWidget';
import CurrentPersonnelDetailModal from './CurrentPersonnelDetailModal';
import {CurrentPersonnelWidgetConfig} from './currentPersonnelWidgetConfig';
import IncrementButton from '../shared/buttons/IncrementButton';
import DecrementButton from '../shared/buttons/DecrementButton';
import Spinner from '../shared/spinners/Spinner';
import {WidgetLayouts} from '../shared/widgets/WidgetLayouts';
import {WidgetLayout} from '../../models/widgetLayout';
import Descriptions from '../shared/descriptions/Descriptions';
import {useMount} from '../../hooks/useMount';
import {Actions} from '../../stores/entityStore';
import {useStore} from '../../hooks/useStore';
import {ScopeContext} from '../../policies/scopeContext';
import {personnelManagement} from '../../models/scope';
import {useModulePolicy} from '../../hooks/useModulePolicy';
import {EnDash} from '../shared/unicodeWrapper/EnDash';

const CurrentPersonnelDetailsWidget = ({disabled, minimized = false, identifier}) => {
  const {t} = useTranslation();
  const store = useStore();
  const [modalVisible, setModalVisible] = useState(false);
  const [actualPersonnel, setActualPersonnel] = useState(null);
  const widgetConfig = useMemo(() =>
    new CurrentPersonnelWidgetConfig(store, CurrentPersonnelDetailsWidget.identifier), []);
  const stepSize = 0.5;
  const minPersonnel = 0;
  const policy = useModulePolicy(store, [personnelManagement]);

  useMount(() => {
    if (store.workplaceStore.selectedWorkplace) {
      store.personnelLogStore.loadAll({
        params: {
          workplaceId: store.workplaceStore.selectedWorkplace.id,
          fromDate: dayjs().toISOString(),
        },
      });
    }
  }, [store.workplaceStore.selectedWorkplace, store.operationStore.active?.stateId]);

  useMount(() => {
    if (store.workplaceStore.selectedWorkplace) {
      setActualPersonnel(store.workplaceStore.selectedWorkplace.actualPersonnel);
    }
  }, [store.workplaceStore.selectedWorkplace, store.workplaceStore.selectedWorkplace?.actualPersonnel]);

  const debouncedPersonnelSave = useCallback(debounce((value) => {
    if (
      store.workplaceStore.selectedWorkplace
        && value !== store.workplaceStore.selectedWorkplace.actualPersonnel
    ) {
      store.personnelLogStore.create({
        workplaceId: store.workplaceStore.selectedWorkplace.id,
        personnel: value,
      });
    }
  }, 1000), []);

  const increment = () => {
    const newValue = (actualPersonnel || 0) + stepSize;
    setActualPersonnel(newValue);
    debouncedPersonnelSave(newValue);
  };
  const decrement = () => {
    if (actualPersonnel > minPersonnel) {
      const newValue = (actualPersonnel || 0) - stepSize;
      setActualPersonnel(newValue);
      debouncedPersonnelSave(newValue);
    }
  };

  const getActualPersonnelValue = () => (actualPersonnel || actualPersonnel === 0 ? actualPersonnel : EnDash());
  const getPlannedPersonnelValue = () => {
    const actualPhaseId = store.operationStateLogStore.activeBySelectedWorkplace?.state?.phaseId;
    const current = store.operationStore.active?.operationPhases?.find((op) => op.phaseId === actualPhaseId);

    if (current) {
      return current.plannedPersonnel;
    }

    return EnDash();
  };

  return (
    <ScopeContext.Provider value={[personnelManagement]}>
      <OperatorWidget
        onHeadClick={() => setModalVisible(true)}
        icon={<FontAwesomeIcon icon={faUsers}/>}
        title={widgetConfig.getWidgetTitle()}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        manualPath={'/personnel/personnel'}
      >
        <Row align="middle">
          <Col span={18}>
            <Descriptions size={'small'} column={1} bordered>
              <Descriptions.Item label={t('personnel.actual')} style={{width: '50%'}}>
                {
                  (store.personnelLogStore.isActionInProgress(Actions.loadAll)
                    || store.personnelLogStore.isActionInProgress(Actions.create))
                    ? (<span><Spinner size={'small'}/></span>)
                    : getActualPersonnelValue()
                }
              </Descriptions.Item>
              <Descriptions.Item label={t('personnel.planned')}>
                {
                  store.personnelLogStore.isActionInProgress(Actions.loadAll)
                    ? (<span><Spinner size={'small'}/></span>)
                    : getPlannedPersonnelValue()
                }
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col offset={2} span={1}>
            <IncrementButton
              size={'large'}
              disabled={
                !policy.canEdit({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})
                || store.operationStore.isActionInProgress('updatePersonnel')
              }
              onClick={increment}
            />
            <DecrementButton
              size={'large'}
              disabled={
                !policy.canEdit({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})
                || store.operationStore.isActionInProgress('updatePersonnel')
              }
              onClick={decrement}
            />
          </Col>
        </Row>
      </OperatorWidget>
      {modalVisible && <CurrentPersonnelDetailModal onCancel={() => setModalVisible(false)}/>}
    </ScopeContext.Provider>
  );
};

CurrentPersonnelDetailsWidget.icon = faUsers;

CurrentPersonnelDetailsWidget.identifier = 'CurrentPersonnelDetailsWidget';
CurrentPersonnelDetailsWidget.defaultLayout = new WidgetLayout(
  {
    identifier: CurrentPersonnelDetailsWidget.identifier,
    x: 2,
    y: 7,
    height: 3,
    minHeight: 3,
    width: WidgetLayouts.sixthWidth.w,
    minWidth: WidgetLayouts.sixthWidth.minW,
  }
);

export default observer(CurrentPersonnelDetailsWidget);
