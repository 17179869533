import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { chunk } from 'lodash';
import Empty from '../empty/Empty';
import ModelProperties from './ModelProperties';
import { BaseModel } from '../../../models/base';
import { DisplayableProperty } from '../../../models/displayableProperty';

export type PropertyGroup = {
  label: string;
  columnCount: number;
  showGroupLabel: boolean;
  showGroupLabels: boolean;
  showPropertyLabels: boolean;
  properties: DisplayableProperty[];
  barcode: string[],
  qrCode: string[],
  dataMatrix: string[],
  title: string;
};

export type ModelPropertyGroupsProps = {
  column?: number;
  groups: PropertyGroup[];
  model: BaseModel;
  hideEmpty?: boolean;
};

const ModelPropertyGroups: React.FC<ModelPropertyGroupsProps> = ({ column = 1, groups, model, hideEmpty = true }) => {
  if (!groups || !groups.length || !model) {
    return <Empty/>;
  }

  const rows = chunk(groups, column);

  return (
    <>
      {rows.map((rowGroups, rowIndex) => (
        <Row key={`row-${rowIndex.toString()}`} gutter={[16, 16]} wrap={false} style={{ marginBottom: 16 }}>
          {rowGroups.map((group, index) => (
            <Col key={`group-${index.toString()}`} flex={1} style={{ flexBasis: 0 }}>
              <ModelProperties
                title={group.showGroupLabel ? group.title : undefined}
                column={group.columnCount}
                showPropertyLabels={group.showPropertyLabels}
                properties={group.properties}
                barcodeProperties={group.barcode}
                dataMatrixProperties={group.dataMatrix}
                qrCodeProperties={group.qrCode}
                model={model}
                hideEmpty={hideEmpty}
              />
            </Col>
          ))}
        </Row>
      ))}
    </>
  );
};

export default observer(ModelPropertyGroups);
