import {useState} from 'react';
import {Form, TreeSelect} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import Alert from 'antd/es/alert';
import {flatten, uniq} from 'lodash';
import dayjs from 'dayjs';
import styles from './InterruptionModal.module.css';
import Modal from '../shared/modal/Modal';
import {useStore} from '../../hooks/useStore';
import {DatePicker} from '../shared/DatePicker';
import Input from '../shared/inputs/Input';
import {ValidationRules} from '../../utils/validationRules';
import Button from '../shared/buttons/Button';
import {datetimeFormat} from '../../config/dayjs';
import {sortAlphabetically} from '../shared/tables/sorters';
import InterruptionClassSelect from './InterruptionClassSelect';
import InterruptionReasonSelect from './InterruptionReasonSelect';
import {Hierarchy} from '../../models/hierarchy';
import {useMount} from '../../hooks/useMount';

const {TextArea} = Input;

const InterruptionModal = ({
  form,
  onSubmit,
  onCancel,
  currentLog = null,
  validTransitions = null,
  isSubmitting = false,
  setIsSubmitting,
  scopeSources,
  selectSourceLeavesOnly,
  ...props
}) => {
  const store = useStore();
  const {t} = useTranslation();
  const [selectedInterruptionSourceHierarchyId, setSelectedInterruptionSourceHierarchyId] = useState();
  const [selectedInterruptionClassId, setSelectedInterruptionClassId] = useState(undefined);
  const [selectedInterruptionReasonId, setSelectedInterruptionReasonId] = useState(undefined);
  const selectedInterruptionReason = store.interruptionReasonStore.getById(selectedInterruptionReasonId);
  const [availableInterruptionReasons, setAvailableInterruptionReasons] = useState([]);
  const [availableInterruptionClasses, setAvailableInterruptionClasses] = useState([]);

  const [isCommentRequired, setIsCommentRequired] = useState(selectedInterruptionReason?.isMessageRequired);
  const [recalc, setRecalc] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [treeData, setTreeData] = useState();
  const [treeDefaultExpandedKeys, setTreeDefaultExpandedKeys] = useState([]);

  const transition = validTransitions.length > 0 ? validTransitions[0] : undefined;

  const getLatestStart = () => {
    const latestOperationLog = store.operationStateLogStore
      .getLatestOfWorkplace(store.workplaceStore.selectedWorkplace?.id);
    const latestWorkplaceLog = store.workplaceStateLogStore
      .getLatestOfWorkplace(store.workplaceStore.selectedWorkplace?.id);
    return latestOperationLog === undefined || latestWorkplaceLog.start > latestOperationLog.start
      ? latestWorkplaceLog.start
      : latestOperationLog.start;
  };

  useMount(() => {
    if (currentLog?.interruptionSourceHierarchyId) {
      form.setFieldValue('interruptionSourceHierarchyId', currentLog.interruptionSourceHierarchyId);
      setSelectedInterruptionSourceHierarchyId(currentLog.interruptionSourceHierarchyId);
    }
    if (currentLog?.interruptionClassId) {
      form.setFieldValue('interruptionClassId', currentLog.interruptionClassId);
      setSelectedInterruptionClassId(currentLog.interruptionClassId);
    }
    if (currentLog?.interruptionReasonId) {
      form.setFieldValue('interruptionReasonId', currentLog.interruptionReasonId);
      setSelectedInterruptionReasonId(currentLog.interruptionReasonId);
    }
    if (currentLog?.message) {
      form.setFieldValue('comment', currentLog.message);
    } else {
      form.setFieldValue('comment', '');
    }
  });

  useMount(() => {
    if (!selectedInterruptionSourceHierarchyId) {
      return;
    }
    const interruptionReasons = store.interruptionReasonStore
      .getReasonsByHierarchyId(selectedInterruptionSourceHierarchyId)
      .filter((r) => !r.isQuickstop);

    const interruptionClasses = store.interruptionClassStore.getByIds(
      uniq(flatten(interruptionReasons.map((r) => r.interruptionClasses.map((ic) => ic.id))))
    ).sort((a, b) => sortAlphabetically(a.label, b.label));

    if (interruptionClasses.length && selectedInterruptionClassId) {
      const filteredInterruptionReasonsByClass = interruptionReasons.filter(
        (reason) => reason.interruptionClassIds.includes(selectedInterruptionClassId)
      );

      setAvailableInterruptionReasons(filteredInterruptionReasonsByClass);
      if (filteredInterruptionReasonsByClass.length === 0) {
        form.setFieldValue('interruptionReasonId', undefined);
        setSelectedInterruptionReasonId(undefined);
      }
    }

    setAvailableInterruptionClasses(interruptionClasses);
  }, [selectedInterruptionSourceHierarchyId]);

  useMount(() => {
    if (!selectedInterruptionClassId) {
      return;
    }
    const interruptionReasons = store.interruptionReasonStore
      .getReasonsByHierarchyId(selectedInterruptionSourceHierarchyId)
      .filter((r) => !r.isQuickstop);

    const filteredInterruptionReasonsByClass = interruptionReasons.filter(
      (reason) => reason.interruptionClassIds.includes(selectedInterruptionClassId)
    );

    setAvailableInterruptionReasons(filteredInterruptionReasonsByClass);
    if (filteredInterruptionReasonsByClass.length === 0) {
      form.setFieldValue('interruptionReasonId', undefined);
      setSelectedInterruptionReasonId(undefined);
    }
  }, [selectedInterruptionClassId]);

  const handleValuesChange = (changedValues) => {
    if (changedValues.interruptionSourceHierarchyId) {
      setSelectedInterruptionSourceHierarchyId(changedValues.interruptionSourceHierarchyId);
      setSelectedInterruptionClassId(undefined);
      setSelectedInterruptionReasonId(undefined);
      form.setFieldValue('interruptionClassId', undefined);
      form.setFieldValue('interruptionReasonId', undefined);
    }
    if (changedValues.interruptionClassId) {
      setSelectedInterruptionClassId(changedValues.interruptionClassId);
      setSelectedInterruptionReasonId(undefined);
      form.setFieldValue('interruptionReasonId', undefined);
    }
    if (changedValues.interruptionReasonId) {
      setSelectedInterruptionReasonId(changedValues.interruptionReasonId);
    }
  };

  useMount(() => {
    if (store.workplaceStore.selectedWorkplace?.id) {
      setSelectedInterruptionSourceHierarchyId(store.workplaceStore.selectedWorkplace.hierarchyId);

      store.validTransitionsStore.loadValidTransitionsByWorkplace(
        store.workplaceStore.selectedWorkplace.id
      ).then((validTrans) => {
        if (validTrans) {
          if (validTrans.find((vt) => vt.activeOperationToStateId)) {
            store.operationStateStore.loadMany(validTrans.map((vt) => vt.activeOperationToStateId));
          }
          if (validTrans.find((vt) => vt.workplaceToStateId)) {
            store.workplaceStateStore.loadMany(validTrans.map((vt) => vt.workplaceToStateId));
          }
        }
      });
    }
  }, [store.workplaceStore.selectedWorkplace?.id]);

  useMount(() => {
    if (
      !selectedInterruptionReason?.isMessageRequired
      || (!currentLog && selectedInterruptionReason.messageRequiredAfterMinutes)
    ) {
      setIsCommentRequired(false);
      return;
    }
    if (selectedInterruptionReason.messageRequiredAfterMinutes === 0) {
      setIsCommentRequired(true);
      return;
    }
    const latestStart = getLatestStart();
    const commentRequiredAt = dayjs(latestStart)
      .add(selectedInterruptionReason.messageRequiredAfterMinutes, 'minutes');
    if (commentRequiredAt.isBefore(dayjs())) {
      setIsCommentRequired(true);
      return;
    }
    setTimeout(() => setRecalc(!recalc), commentRequiredAt.valueOf() - dayjs().valueOf());
    setIsCommentRequired(false);
  }, [selectedInterruptionReason, recalc]);

  useMount(() => {
    let hierarchyTree;
    if (store.workplaceStore.selectedWorkplace?.hierarchy) {
      const rootId = store.workplaceStore.getRootIdFromWorkplace(store.workplaceStore.selectedWorkplace, scopeSources);
      hierarchyTree = store.hierarchyStore.getFullSubTree(rootId);
    } else {
      hierarchyTree = store.hierarchyStore.hierarchyTree;
    }
    setTreeData(Hierarchy.mapToSelectTreeData(
      hierarchyTree,
      null,
      (entity) => selectSourceLeavesOnly && entity.children
    ));
    if (!selectSourceLeavesOnly && store.workplaceStore.selectedWorkplace?.hierarchy?.id) {
      setSelectedInterruptionSourceHierarchyId(store.workplaceStore.selectedWorkplace?.hierarchy?.id);
      form.setFieldValue('interruptionSourceHierarchyId', store.workplaceStore.selectedWorkplace?.hierarchy?.id);
    }
  }, [store.workplaceStore.selectedWorkplace?.hierarchy?.id, selectSourceLeavesOnly]);

  useMount(() => {
    if (store.workplaceStore.selectedWorkplace?.hierarchy) {
      const rootId = store.workplaceStore.getRootIdFromWorkplace(store.workplaceStore.selectedWorkplace, scopeSources);
      setTreeDefaultExpandedKeys([rootId]);
    } else {
      setTreeDefaultExpandedKeys([store.hierarchyStore.hierarchyTree?.id]);
    }
  });

  const handleCancel = () => {
    onCancel();
  };

  const disabledDate = (current) => {
    const latestStart = getLatestStart();
    if (!latestStart) {
      return false;
    }
    const maxStart = dayjs(latestStart).add(1, 'minute');
    const maxEnd = dayjs();
    return !(maxStart.isSameOrBefore(current) && maxEnd.isSameOrAfter(current));
  };

  return (
    <Modal visible footer={false} maskClosable={false} onCancel={handleCancel} {...props}>
      {validTransitions.length > 1 && (
        <Alert
          className={styles.alertMessage}
          message={t('interruption.modal.alert.hasMultipleTransitions')}
          type="warning"
          closable
          showIcon
        />
      )}
      <Form
        form={form}
        onFinish={(values) => {
          setIsSubmitting(true);
          const data = {transitionId: transition?.id, ...values};

          if (values.datetime) {
            data.datetime = dayjs(data.datetime).seconds(0);
          } else {
            data.datetime = undefined;
          }

          onSubmit({transitionId: transition?.id, ...data});
        }}
        initialValues={{
          comment: currentLog?.message,
        }}
        layout="vertical"
        onValuesChange={handleValuesChange}
      >
        {showDatePicker && (
          <>
            <p>{t('interruption.modal.form.datetime.label')}</p>
            <Form.Item name="datetime">
              <DatePicker
                showToday
                showTime
                format={datetimeFormat}
                defaultValue={dayjs()}
                allowClear={false}
                disabledDate={disabledDate}
                disabled={isSubmitting}
              />
            </Form.Item>
          </>
        )}
        {!showDatePicker && (
          <Form.Item name="addTime">
            <Button
              key="interruptionModalAddTime"
              htmlType="button"
              size="large"
              icon={<PlusOutlined/>}
              onClick={() => {
                setShowDatePicker(true);
                form.setFieldValue('datetime', dayjs());
              }}
            >
              {t('interruption.modal.form.button.setTime')}
            </Button>
          </Form.Item>
        )}
        <Form.Item
          name="interruptionSourceHierarchyId"
          rules={[ValidationRules.requiredIf(currentLog !== null)]}
          label={t('interruption.modal.form.interruptionSourceHierarchy.label')}
        >
          <TreeSelect
            showSearch
            style={{width: '100%'}}
            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
            allowClear
            treeDefaultExpandAll={false}
            treeDefaultExpandedKeys={treeDefaultExpandedKeys}
            treeData={treeData}
            treeNodeFilterProp="title"
          />
        </Form.Item>
        <Form.Item
          name="interruptionClassId"
          rules={[ValidationRules.requiredIf(currentLog !== null)]}
          label={t('interruption.modal.form.interruptionClass.label')}
        >
          <InterruptionClassSelect
            availableInterruptionClasses={availableInterruptionClasses}
          />
        </Form.Item>
        <Form.Item
          name="interruptionReasonId"
          rules={[ValidationRules.requiredIf(currentLog !== null)]}
          label={t('interruption.modal.form.interruptionReason.label')}
        >
          <InterruptionReasonSelect
            disabled={!availableInterruptionReasons.length}
            availableInterruptionReasons={availableInterruptionReasons}
          />
        </Form.Item>
        <Form.Item
          name="comment"
          label={t('interruption.modal.form.comment.label')}
          rules={[ValidationRules.requiredIf(isCommentRequired)]}
        >
          <TextArea
            placeholder={t('interruption.modal.form.comment.placeholder')}
          />
        </Form.Item>
        <Form.Item className={styles.buttonRow}>
          <Button
            className={styles.buttonDiscard}
            key="interruptionModalCancelButton"
            htmlType="button"
            size="large"
            onClick={() => handleCancel()}
            disabled={isSubmitting}
            danger
          >
            {t('interruption.modal.form.button.discard')}
          </Button>
          <Button
            key="interruptionModalSubmitButton"
            type="primary"
            htmlType="submit"
            size="large"
            loading={isSubmitting}
          >
            {t('interruption.modal.form.button.submit')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(InterruptionModal);
