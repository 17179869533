import {observer} from 'mobx-react-lite';
import Table from '../../shared/tables/Table';
import {useStore} from '../../../hooks/useStore';

const IngredientStockTable = ({ingredientStock, columns, ...props}) => {
  const store = useStore();

  const dataSource = ingredientStock.map((stockEntry, index) => {
    const unitOfMeasure = store.unitOfMeasurementStore
      .getById(stockEntry.unitOfMeasureId);
    return {
      key: index,
      unitOfMeasure: unitOfMeasure?.label,
      ...stockEntry,
    };
  });

  return (
    <Table
      rowKey="key"
      columns={columns}
      pagination={false}
      dataSource={dataSource}
      {...props}
    />
  );
};

export default observer(IngredientStockTable);
