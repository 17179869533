import i18n from '../../i18n/i18n';
import {BaseWidgetConfig} from '../shared/widgets/baseWidgetConfig';
import {ValidationRules} from '../../utils/validationRules';
import {SensorType} from '../../models/sensor';
import {getAllI18nTranslations} from '../../utils/translations';
import {FieldType} from '../../types/widgetConfig';

export class SpeedChangeWidgetConfig extends BaseWidgetConfig {
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'settings',
        label: i18n.t('widget.WidgetSettingsModal.settingConfigTabTitle'),
      },
    ];
  }

  settings = [
    {
      id: 'title',
      label: i18n.t('widget.settings.title.label'),
      helpText: i18n.t('widget.settings.title.help'),
      type: FieldType.Translatable,
      defaultValue: getAllI18nTranslations('speedChangeWidget.title'),
      validations: [ValidationRules.required()],
    },
    {
      id: 'sensor',
      label: `${i18n.t('speedChangeWidget.settings.sensor.label')}`,
      helpText: i18n.t('speedChangeWidget.settings.sensor.help', {sensorType: SensorType.SPEED}),
      options: this.rootStore.sensorStore.sensors
        .filter((sensor) => sensor.sensorType === SensorType.SPEED && !sensor.hierarchyId)
        .map((sensor) => ({value: sensor.name, label: sensor.label || sensor.name})),
      type: FieldType.Select,
      allowClear: true,
    },
    {
      id: 'reasons',
      label: `${i18n.t('speedChangeWidget.settings.reasons.label')}`,
      helpText: i18n.t('speedChangeWidget.settings.reasons.help'),
      type: FieldType.SelectTags,
    },
    {
      id: 'decimals',
      label: `${i18n.t('speedChangeWidget.settings.rounding.label')}`,
      helpText: i18n.t('speedChangeWidget.settings.rounding.help'),
      type: FieldType.Number,
      validations: [ValidationRules.minNumber(0), ValidationRules.maxNumber(15)],
    },
  ];

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'title');
  }
}
