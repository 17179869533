import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Button, { ButtonProps } from './Button';

export type EditButtonProps = ButtonProps & {
  message?: string;
  icon?: IconDefinition;
  dataCyPrefix?: string;
};

const EditButton: React.FC<EditButtonProps> = ({ message, icon, dataCyPrefix, ...props }) => {
  const buttonProps = {
    type: 'link',
    icon: undefined,
    ...props,
  } as ButtonProps;

  /* eslint-disable react/destructuring-assignment */
  if (message) {
    buttonProps.icon = icon || <EditOutlined/>;
    return (
      <Button
        data-cy={`${dataCyPrefix ? `${dataCyPrefix}-` : ''}EditButton`}
        {...buttonProps}
      >
        {message}
      </Button>
    );
  }
  return (
    <Button
      data-cy={`${dataCyPrefix ? `${dataCyPrefix}-` : ''}EditButton`}
      {...buttonProps}
    >
      {icon || <EditOutlined/>}
    </Button>
  );
};

export default EditButton;
