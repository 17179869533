import { ColumnProps } from 'antd/es/table';
import { SortOrder } from 'antd/es/table/interface';
import { sortAlphabetically, sortNumerically } from './sorters';
import { BaseModel } from '../../../models/base';

export const TableColumns = {
  id: <T extends BaseModel = BaseModel>(props: ColumnProps<T> = {}) => (
    {
      title: 'Id',
      dataIndex: 'id',
      sorter: (a: T, b: T) => {
        if (typeof a.id === 'number' && typeof b.id === 'number') {
          return sortNumerically(a.id, b.id);
        }
        // Assume all instances of the same entity have the same type of id
        return sortAlphabetically(a.id as string | undefined, b.id as string | undefined);
      },
      defaultSortOrder: 'ascend' as SortOrder,
      ...props,
    }
  ),
};
