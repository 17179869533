import React from 'react';
import {
  BooleanField,
  ColorField,
  CustomTabEditorField,
  FieldWrapper,
  HierarchyTreeField,
  HierarchyTreeWorkplaceField,
  InputField,
  ModelPropertySelectField,
  NumberField,
  PropertiesField,
  PropertiesSelectDisplayField,
  RadioField,
  SelectField,
  StorageUnitSelectorField,
  TranslatableInputField,
} from './WidgetSettingsFormFields';
import Form from '../form/Form';
import { HierarchyTreeDataStrategy } from '../inputs/hierarchyTree/HierarchyTree';
import { ConfigField, FieldType } from '../../../types/widgetConfig';

export type WidgetSettingFormFieldProps = {
  field: ConfigField;
};

const WidgetSettingFormField: React.FC<WidgetSettingFormFieldProps> = ({ field }) => {
  switch (field.type) {
    case FieldType.String:
      return (
        <FieldWrapper field={field}>
          <InputField field={field} autoComplete={'off'}/>
        </FieldWrapper>
      );
    case FieldType.Select:
      return (
        <FieldWrapper field={field}>
          <SelectField field={field}/>
        </FieldWrapper>
      );
    case FieldType.SelectMultiple:
      return (
        <FieldWrapper field={field}>
          <SelectField mode="multiple" field={field}/>
        </FieldWrapper>
      );
    case FieldType.SelectTags:
      return (
        <FieldWrapper field={field}>
          <SelectField mode="tags" field={field}/>
        </FieldWrapper>
      );
    case FieldType.StorageUnitSelectorField:
      return (
        <FieldWrapper field={field}>
          <StorageUnitSelectorField field={field}/>
        </FieldWrapper>
      );
    case FieldType.Number:
      return (
        <FieldWrapper field={field}>
          <NumberField field={field} autoComplete={'off'}/>
        </FieldWrapper>
      );
    case FieldType.Boolean:
      return (
        <FieldWrapper field={field} valuePropName={'checked'}>
          <BooleanField field={field}/>
        </FieldWrapper>
      );
    case FieldType.Properties:
      return (
        <div style={{ marginBottom: '24px' }}>
          <Form.Item name={field.id} noStyle>
            <PropertiesField label={field.label} availableProperties={field.properties} texts={field.texts}/>
          </Form.Item>
        </div>
      );
    case FieldType.Color:
      return (
        <FieldWrapper field={field}>
          <ColorField field={field}/>
        </FieldWrapper>
      );
    case FieldType.HierarchyTree:
      return (
        <FieldWrapper field={field}>
          <HierarchyTreeField field={field}/>
        </FieldWrapper>
      );
    case FieldType.HierarchyTreeMultiple:
      return (
        <FieldWrapper field={field}>
          <HierarchyTreeField field={field} treeCheckable/>
        </FieldWrapper>
      );
    case FieldType.HierarchyTreeMultipleWorkplacesOnly:
      return (
        <FieldWrapper field={field}>
          <HierarchyTreeField
            field={field}
            treeCheckable
            treeDataStrategy={HierarchyTreeDataStrategy.TO_WORKPLACES_ONLY}
          />
        </FieldWrapper>
      );
    case FieldType.ModelPropertySelectField:
      return <ModelPropertySelectField field={field}/>;
    case FieldType.PropertiesSelectDisplayField:
      return <PropertiesSelectDisplayField field={field}/>;
    case FieldType.HierarchyTreeWorkplace:
      return (
        <FieldWrapper field={field}>
          <HierarchyTreeWorkplaceField field={field}/>
        </FieldWrapper>
      );
    case FieldType.CustomTabEditor:
      return <CustomTabEditorField field={field}/>;
    case FieldType.Translatable:
      return (
        <FieldWrapper field={field}>
          <TranslatableInputField field={field}/>
        </FieldWrapper>
      );
    case FieldType.RadioField:
      return (
        <FieldWrapper field={field}>
          <RadioField field={field}/>
        </FieldWrapper>
      );
    default:
      return null;
  }
};

export default WidgetSettingFormField;
