import { action, observable, makeObservable } from 'mobx';
import { AxiosResponse } from 'axios';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { KeyPerformanceIndicator } from '../models/keyPerformanceIndicator';
import {
  byCalculateEndpoint,
  byMaterialEndpoint,
  byOrderEndpoint,
  byTimeEndpoint,
  oeeByMaterialEndpoint,
  oeeByOrderEndpoint,
  oeeByTimeEndpoint,
} from '../middleware/endpoints/keyPerformanceIndicator';
import { RootStore } from './rootStore';
import { ApiError } from '../types/api';

interface CalculateParams {
  id: number;
  fromDate: Date;
  toDate: Date;
  workplaceId: number;
}

export interface KpiResult {
  value: number;
  kpiComponents: Record<string, KpiResult>;
  unitOfMeasureId?: number;
}

interface KpiFilter {
  workplaceId: number;
  fromDate: string;
  toDate: string;
  orderId: number;
  materialId: number;
  kpiId: number;
  resolution: number;
  clampToNow: boolean;
}

export interface SeriesDataPoint {
  x: string;
  y: number;
}

export interface KpiSeries {
  kpiId: number;
  data: SeriesDataPoint[];
}

export interface KeyPerformanceIndicatorFilterResponse {
  series: KpiSeries[];
}

export class KeyPerformanceIndicatorStore extends EntityStore<KeyPerformanceIndicator> {
  kpis: KeyPerformanceIndicator[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'kpis', Api.keyPerformanceIndicator, KeyPerformanceIndicator);

    makeObservable(this, {
      kpis: observable,
      loadKpiByFilter: action,
      loadKpiByTime: action,
      loadKpiByMaterial: action,
      loadKpiByOrder: action,
      loadOeeByFilter: action,
      loadOeeByTime: action,
      loadOeeByMaterial: action,
      loadOeeByOrder: action,
    });
  }

  calculate({ id, workplaceId, fromDate, toDate }: CalculateParams) {
    return this.api[byCalculateEndpoint]({
      id,
      params: { workplaceId, fromDate, toDate },
    })
      .then((response: AxiosResponse) => response)
      .catch((e: ApiError) => this.handleApiError(e, byCalculateEndpoint));
  }

  loadKpiByFilter(filter: KpiFilter) {
    if (filter.materialId) {
      return this.loadKpiByMaterial(filter);
    }
    if (filter.orderId) {
      return this.loadKpiByOrder(filter);
    }
    return this.loadKpiByTime(filter);
  }

  loadKpiByTime({ workplaceId, fromDate, toDate, resolution, kpiId, clampToNow }: KpiFilter) {
    return this.api[byTimeEndpoint]({
      params: { workplaceId, fromDate, toDate, resolution, kpiId, clampToNow },
    })
      .then((response: AxiosResponse) => response.data.series || [])
      .catch((e: ApiError) => this.handleApiError(e, byTimeEndpoint));
  }

  loadKpiByMaterial({ workplaceId, fromDate, toDate, materialId, resolution, kpiId }: KpiFilter) {
    return this.api[byMaterialEndpoint]({
      params: { workplaceId, fromDate, toDate, materialId, resolution, kpiId },
    })
      .then((response: AxiosResponse) => response.data.series || [])
      .catch((e: ApiError) => this.handleApiError(e, byMaterialEndpoint));
  }

  loadKpiByOrder({ workplaceId, orderId, resolution, kpiId }: KpiFilter) {
    return this.api[byOrderEndpoint]({
      params: { workplaceId, orderId, resolution, kpiId },
    })
      .then((response: AxiosResponse) => response.data.series || [])
      .catch((e: ApiError) => this.handleApiError(e, byOrderEndpoint));
  }

  loadOeeByFilter(filter: KpiFilter) {
    if (filter.materialId) {
      return this.loadOeeByMaterial(filter);
    }
    if (filter.orderId) {
      return this.loadOeeByOrder(filter);
    }
    return this.loadOeeByTime(filter);
  }

  loadOeeByTime({ workplaceId, fromDate, toDate }: KpiFilter) {
    return this.api[oeeByTimeEndpoint]({
      params: { workplaceId, fromDate, toDate },
    })
      .then((response: AxiosResponse) => response.data)
      .catch((e: ApiError) => this.handleApiError(e, oeeByTimeEndpoint));
  }

  loadOeeByMaterial({ workplaceId, fromDate, toDate, materialId }: KpiFilter) {
    return this.api[oeeByMaterialEndpoint]({
      params: { workplaceId, fromDate, toDate, materialId },
    })
      .then((response: AxiosResponse) => response.data)
      .catch((e: ApiError) => this.handleApiError(e, oeeByMaterialEndpoint));
  }

  loadOeeByOrder({ workplaceId, orderId }: KpiFilter) {
    return this.api[oeeByOrderEndpoint]({
      params: { workplaceId, orderId },
    })
      .then((response: AxiosResponse) => response.data)
      .catch((e: ApiError) => this.handleApiError(e, oeeByOrderEndpoint));
  }
}
