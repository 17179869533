import React from 'react';
import { faBan, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type BooleanValueProps = {
  value: boolean;
};

const BooleanValue: React.FC<BooleanValueProps> = ({ value }) => (value
  ? <FontAwesomeIcon color={'#389e0d'} icon={faCheckCircle}/>
  : <FontAwesomeIcon color={'#595959'} icon={faBan}/>);

export default BooleanValue;
