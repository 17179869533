import { computed, observable, makeObservable } from 'mobx';
import { faRuler } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { getTranslation } from '../utils/translations';
import { Translation } from './translations';
import { RootStore } from '../stores/rootStore';

/* Translation
 * t('unitOfMeasurement.types.PCT')
 * t('unitOfMeasurement.types.S')
 * t('unitOfMeasurement.types.KG')
 * t('unitOfMeasurement.types.G')
 * t('unitOfMeasurement.types.PCE')
 * t('unitOfMeasurement.types.CU')
 * t('unitOfMeasurement.types.TU')
 * t('unitOfMeasurement.types.LU')
 */
export enum UnitOfMeasurementType {
  PCT = 'PCT',
  S = 'S',
  KG = 'KG',
  G = 'G',
  PCE = 'PCE',
  CU = 'CU',
  TU = 'TU',
  LU = 'LU'
}

interface UnitOfMeasurementTranslation extends Translation {
  label: string | null;
}

export class UnitOfMeasurement extends BaseModel {
  id : number = 0;
  name: string = '';
  type: UnitOfMeasurementType | null = null;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;
  translations: UnitOfMeasurementTranslation[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      label: computed,
      type: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
      isPercent: computed,
      translations: observable,
    });
  }

  static faIcon = faRuler;

  searchableProperties = ['name', 'label'];

  translatedProperties = ['label'];

  displayableProperties = [
    displayableProperty({
      key: 'name',
      title: i18n.t('unitOfMeasurement.model.attributes.name.label'),
      params: [displayablePropertyParam({ path: 'name' })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'label',
      title: i18n.t('unitOfMeasurement.model.attributes.label.label'),
      params: [displayablePropertyParam({ path: 'label' })],
      template: '{value}',
    }),
  ];

  getDisplayableValue(value: number): string {
    const { label } = this;
    return `${value}${label}`;
  }

  calculateToPercentageValue(value: number): number {
    const { type } = this;
    if (type && type === UnitOfMeasurementType.PCT && value) {
      return value * 100;
    }
    return value;
  }

  get label() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.label;
  }

  get isPercent() {
    return this.type === UnitOfMeasurementType.PCT;
  }

  static prepareApiPayload(model: Partial<UnitOfMeasurement>) {
    return {
      id: model.id || undefined,
      name: model.name,
      type: model.type,
      translations: UnitOfMeasurement.convertToSavableTranslations(model),
    };
  }
}
