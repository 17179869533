import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Order } from '../../../models/order';
import Form from '../../shared/form/Form';
import OrderSelector from '../../shared/inputs/autocomplete/OrderSelector';
import Select, { SelectProps } from '../../shared/inputs/Select';
import { sortAlphabetically } from '../../shared/tables/sorters';

export type OrderSelectProps = SelectProps<number> & {
  restrictToOrder: boolean;
  orders: Order[];
};

export const OrderSelect: React.FC<OrderSelectProps> = ({
  restrictToOrder,
  orders,
}) => {
  const { t } = useTranslation();
  let selector: ReactNode;
  if (restrictToOrder) {
    const orderOptions = orders.map((order: Order) => ({
      value: order.id,
      label: order.no,
    })).sort((a, b) => sortAlphabetically(a.label, b.label));
    selector = (
      <Select<number, { label: string, value: number }>
        allowClear
        placeholder={t<string>('qualityReport.form.order.selectPlaceholder')}
        options={orderOptions}
        showSearch
        filterOption={(input: string, option?: { label: string, value: number }) =>
          (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
      />
    );
  } else {
    selector = (
      <OrderSelector
        allowClear
        multiple={false}
        placeholder={t<string>('qualityReport.form.order.selectPlaceholder')}
      />
    );
  }
  return (
    <Form.Item
      name={'orderId'}
      label={t('qualityReport.model.order.label')}
    >
      {selector}
    </Form.Item>
  );
};
