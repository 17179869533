import {createEntity, executeDelete, executeGet, executePost, updateEntity} from '../requests';

const resourcesName = 'v1/custom-functions';

export const executeCustomFunctionEndpoint = 'execute';
export const executeCustomFunctionResource = 'execute';

export const CustomFunctionsApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...entity}) => updateEntity(entity, `${resourcesName}/${id}`),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
  [executeCustomFunctionEndpoint]: ({customFunctionId, ...params}) => executePost(
    `${resourcesName}/${customFunctionId}/${executeCustomFunctionResource}`,
    params
  ),
};
