import {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import {useMount} from '../../hooks/useMount';
import {WidgetLayout} from '../../models/widgetLayout';
import OperatorWidget from '../operator/shared/OperatorWidget';
import {WidgetLayouts} from '../shared/widgets/WidgetLayouts';
import {makeFormFields} from '../shared/form/formFields';
import EditButton from '../shared/buttons/EditButton';
import ReadButton from '../shared/buttons/ReadButton';
import {ShiftBookWidgetConfig} from './shiftBookWidgetConfig';
import ShiftBooksTableModal from './ShiftBooksTableModal';
import NewShiftBooksModal from './NewShiftBooksModal';
import {useStore} from '../../hooks/useStore';
import {ScopeContext} from '../../policies/scopeContext';
import {collaboration} from '../../models/scope';
import {useModulePolicy} from '../../hooks/useModulePolicy';
import {ShiftBook} from '../../models/shiftBook';

const ShiftBooksWidget = ({disabled, minimized = false, identifier}) => {
  const {t} = useTranslation();
  const store = useStore();
  const [newModalVisible, setNewModalVisible] = useState(false);
  const [tableModalVisible, setTableModalVisible] = useState(false);
  const [tableModalOpen, setTableModalOpen] = useState(false);
  const widgetConfig = useMemo(() =>
    new ShiftBookWidgetConfig(store, identifier), []);
  const policy = useModulePolicy(store, [collaboration]);

  const initialFromDate = dayjs().subtract(widgetConfig.getValues()?.queryTimeSpan, 'hours');
  const initialToDate = dayjs();

  const queryData = (from, to) => {
    if (store.workplaceStore.selectedWorkplace) {
      store.shiftBookStore.loadAllWithDependencies({
        params: {
          workplaceId: store.workplaceStore.selectedWorkplace.id,
          fromDate: from.toISOString(),
          toDate: to.toISOString(),
        },
      });
    }
  };

  useMount(() => {
    queryData(initialFromDate, initialToDate);
  }, [store.workplaceStore.selectedWorkplace?.id, initialFromDate, initialToDate]);

  useMount(() => {
    const newCount = store.shiftBookStore.bySelectedWorkplace.filter((sb) =>
      dayjs(sb.createdAt) >= initialFromDate && dayjs(sb.createdAt) <= initialToDate).length;
    store.terminalLayoutStore.setBadgeCounter(ShiftBooksWidget.identifier, newCount);
  }, [store.shiftBookStore.bySelectedWorkplace.length, initialFromDate, initialToDate]);

  return (
    <ScopeContext.Provider value={[collaboration]}>
      <OperatorWidget
        onHeadClick={() => setTableModalVisible(true)}
        icon={<FontAwesomeIcon icon={ShiftBook.faIcon}/>}
        title={widgetConfig.getWidgetTitle()}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        manualPath={'/information/shift-book'}
      >
        <ReadButton
          type={'primary'}
          size={'large'}
          message={
            `${store.terminalLayoutStore.badgeCounters.get(ShiftBooksWidget.identifier)}`
            + ` ${t('shiftBookWidget.readMessagesButton.label')}`
          }
          style={{width: '100%', marginBottom: '12px'}}
          onClick={() => setTableModalVisible(true)}
        />
        <br/>
        <EditButton
          type={'default'}
          size={'large'}
          message={t('shiftBookWidget.newMessageButton.label')}
          style={{width: '100%'}}
          onClick={() => setNewModalVisible(true)}
          disabled={!policy.canEdit({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})}
        />
      </OperatorWidget>

      {tableModalVisible
      && (
        <ShiftBooksTableModal
          models={store.shiftBookStore.bySelectedWorkplace}
          widgetConfig={widgetConfig}
          queryData={queryData}
          settings={widgetConfig.getValues()}
          onCancel={() => {
            setTableModalVisible(false);
            setTableModalOpen(false);
          }}
          onAdd={() => {
            setTableModalOpen(true);
            setTableModalVisible(false);
            setNewModalVisible(true);
          }}
          identifier={identifier}
        />
      )}

      {newModalVisible
      && (
        <NewShiftBooksModal
          messageFieldRows={widgetConfig.getValues().messageFieldRows}
          timeSpanFinished={widgetConfig.getValues().timeSpanFinished}
          ordersDisplayedInSelect={widgetConfig.getValues().ordersDisplayedInSelect}
          orderIsMandatory={widgetConfig.getValues().orderIsMandatory}
          onCancel={() => {
            setNewModalVisible(false);
            if (tableModalOpen) {
              setTableModalVisible(true);
            }
          }}
          onSubmit={(values, setFields) => {
            values.workplaceId = store.workplaceStore.selectedWorkplace.id;
            store.shiftBookStore.create(values)
              .then(() => {
                setNewModalVisible(false);
                if (tableModalOpen) {
                  setTableModalVisible(true);
                }
              })
              .catch((errors) => setFields && setFields(makeFormFields(values, errors.fields)));
          }}
        />
      )}
    </ScopeContext.Provider>
  );
};

ShiftBooksWidget.icon = ShiftBook.faIcon;

ShiftBooksWidget.identifier = 'ShiftBooksWidget';
ShiftBooksWidget.defaultLayout = new WidgetLayout(
  {
    identifier: ShiftBooksWidget.identifier,
    x: 2,
    y: 4,
    height: 3,
    minHeight: 3,
    width: WidgetLayouts.sixthWidth.w,
    minWidth: WidgetLayouts.sixthWidth.minW,
  }
);
export default observer(ShiftBooksWidget);
