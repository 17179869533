import React from 'react';
import {observer} from 'mobx-react-lite';
import YieldConsumptionTable from './YieldConsumptionTable';

const OrderDetailsPreparations = ({
  operations,
  widgetConfig,
}) => (
  <YieldConsumptionTable
    operations={operations}
    yieldColumns={widgetConfig.getPreparationsTabYieldProperties()}
    yieldSortAttribute={widgetConfig.getPreparationsTabYieldSortAttribute()}
    yieldSortOrder={widgetConfig.getPreparationsTabYieldSortOrder()}
    consumptionColumns={widgetConfig.getPreparationsTabConsumptionsProperties()}
    consumptionSortAttribute={widgetConfig.getPreparationsTabConsumptionsSortAttribute()}
    consumptionSortOrder={widgetConfig.getPreparationsTabConsumptionsSortOrder()}
  />
);

export default observer(OrderDetailsPreparations);
