import { action, makeObservable, observable } from 'mobx';
import { AxiosResponse } from 'axios';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { LdapConnection } from '../models/ldapConnection';
import { ldapConnectionEndpoint } from '../middleware/endpoints/ldapConnection';
import { RootStore } from './rootStore';

export class LdapConnectionStore extends EntityStore<LdapConnection> {
  ldapConnections: LdapConnection[] = []; // not used
  ldapConnection: LdapConnection | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore, 'ldapConnections', Api.ldapConnection, LdapConnection);

    makeObservable(this, {
      ldapConnection: observable,
      setLdapConnection: action,
    });
  }

  getOnly() {
    Api.ldapConnection[ldapConnectionEndpoint]({}).then((res: AxiosResponse) => {
      this.setLdapConnection(LdapConnection.fromPlainObject<LdapConnection>(res.data, this.rootStore));
    });
  }

  setLdapConnection(connection: LdapConnection) {
    this.ldapConnection = connection;
  }
}
