import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import classnames from 'classnames';
import styles from './Label.module.scss';

const Label: React.FC<DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>> = ({
  children,
  ...props
}) => (
  <span
    className={classnames({
      [styles.label]: true,
    })}
    {...props}
  >
    {children}
  </span>
);

export default Label;
