import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { ProcessDataSource } from '../models/processDataSource';
import { RootStore } from './rootStore';

export class ProcessDataSourceStore extends EntityStore<ProcessDataSource> {
  processDataSources: ProcessDataSource[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'processDataSources', Api.processDataSources, ProcessDataSource);

    makeObservable(this, {
      processDataSources: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.processDataConnectionStore,
        modelId: 'processDataConnectionId',
      },
      {
        store: this.rootStore.workplaceStore,
        modelId: 'workplaceId',
      },
    ];
  }
}
