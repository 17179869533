import React from 'react';
import {Button} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileCsv, faTimes, faSave} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import Form from '../shared/form/Form';
import {FormItemLayouts} from '../shared/form/formItemLayouts';

const BatchLogFormActions = ({
  onExport,
  onCancel,
  onOk,
  onOkDisabled,
}) => {
  const {t} = useTranslation();

  return (
    <Form.Item {...FormItemLayouts.fullWidth} style={{textAlign: 'right'}}>
      <Button style={{marginRight: '10px'}} onClick={onCancel}>
        <FontAwesomeIcon style={{marginRight: '1em'}} icon={faTimes}/>
        {t('confirm.cancel')}
      </Button>
      {onExport && (
      <Button style={{marginRight: '10px'}} onClick={() => onExport()}>
        <FontAwesomeIcon style={{marginRight: '1em'}} icon={faFileCsv}/>
        {t('batchLog.actions.exportToCsv')}
      </Button>
      )}
      {onOk && (
      <Button type="primary" htmlType="submit" style={{marginRight: '10px'}} onClick={onOk} disabled={onOkDisabled}>
        <FontAwesomeIcon style={{marginRight: '1em'}} icon={faSave}/>
        {t('confirm.save')}
      </Button>
      )}
    </Form.Item>
  );
};

export default BatchLogFormActions;
