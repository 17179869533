import React from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { faRepeat } from '@fortawesome/pro-solid-svg-icons';
import { useStore } from '../../../hooks/useStore';
import Form from '../../shared/form/Form';
import FormModal from '../../shared/modal/FormModal';
import { FormItemLayouts as formItemLayouts } from '../../shared/form/formItemLayouts';
import Select from '../../shared/inputs/Select';
import DefaultFormActions from '../../shared/form/DefaultFormActions';
import { PrintJob } from '../../../models/printJob';
import Input from '../../shared/inputs/Input';
import Switch from '../../shared/inputs/Switch';
import InputNumber from '../../shared/inputs/InputNumber';

export type ManualPrintConfirmModalProps = {
  printJob: PrintJob;
  settings: any;
  onCancel: () => void;
  onConfirm: (formData: any) => void;
};

const ManualPrintConfirmModal: React.FC<ManualPrintConfirmModalProps> = ({
  printJob,
  settings,
  onCancel,
  onConfirm,
}) => {
  const store = useStore();
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const settingsFormItems = settings?.map((setting: any) => (
    <Form.Item
      name={setting.label}
      label={setting.label}
      initialValue={setting.property === 'boolean' ? false : undefined}
      valuePropName={setting.property === 'boolean' ? 'checked' : undefined}
    >
      {setting.property === 'number' && (<InputNumber/>)}
      {setting.property === 'string' && (<Input/>)}
      {setting.property === 'boolean' && (
        <Switch
          checkedChildren={<CheckOutlined/>}
          unCheckedChildren={<CloseOutlined/>}
        />
      )}
    </Form.Item>
  ));

  return (
    <FormModal
      title={t('printJobLog.manualPrint.button')}
      open
      onCancel={onCancel}
    >
      <Form
        form={form}
        onFinish={onConfirm}
        {...formItemLayouts.fullWidth}
      >
        <Form.Item
          name={'printerId'}
          label={t('printJobLog.reprint.selection')}
          initialValue={printJob?.printerId}
        >
          <Select
            options={
              store.printerStore.printers.map((printer) => ({
                label: printer.name,
                value: printer.id,
              }))
            }
          />
        </Form.Item>
        {settingsFormItems.length ? settingsFormItems : null}
        <DefaultFormActions
          onCancel={onCancel}
          submitText={t<string>('printJobLog.manualPrint.print')}
          submitIcon={faRepeat}
        />
      </Form>
    </FormModal>
  );
};

export default ManualPrintConfirmModal;
