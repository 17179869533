import { computed, observable, makeObservable } from 'mobx';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import i18n from '../i18n/i18n';
import { transformation } from '../utils/transformations';
import { displayablePropertyParam } from './displayablePropertyParam';
import { CustomPropertyTypes } from './customPropertyDataTypes';
import { displayableProperty } from './displayableProperty';
import { InspectionLot } from './inspectionLot';
import { InspectionTaskGroup } from './inspectionTaskGroup';
import { Workplace } from './workplace';
import { getTranslation } from '../utils/translations';
import { CatalogFeature } from './catalogFeature';
import { Translation } from './translations';
import { RootStore } from '../stores/rootStore';
import { CustomPropertiesObject } from './customProperty';
import { CustomFunction } from './customFunction';
import { InspectionEquipment } from './inspectionEquipment';

/**
 * translations:
 * t('inspectionTask.model.attributes.priorityType.NORMAL')
 * t('inspectionTask.model.attributes.priorityType.CRITICAL')
 */
export enum InspectionTaskPriority {
  NORMAL = 'NORMAL',
  CRITICAL = 'CRITICAL'
}

/**
 * translations:
 * t('inspectionTask.model.attributes.graphType.none')
 * t('inspectionTask.model.attributes.graphType.LineChart')
 * t('inspectionTask.model.attributes.graphType.QualityIndicatorChart')
 */
export enum InspectionGraph {
  LineChart = 'LineChart',
  QualityIndicatorChart = 'QualityIndicatorChart',
  none = 'none'
}

/**
 * translations:
 * t('inspectionTask.model.attributes.inspectionTypes.Qualitative')
 * t('inspectionTask.model.attributes.inspectionTypes.Quantitative')
 * t('inspectionTask.model.attributes.inspectionTypes.FactoryCode')
 * t('inspectionTask.model.attributes.inspectionTypes.BestBeforeDate')
 */
export enum InspectionType {
  Qualitative = 'Qualitative',
  Quantitative = 'Quantitative',
  BestBeforeDate = 'BestBeforeDate',
  FactoryCode = 'FactoryCode'
}

interface InspectionTaskTranslation extends Translation {
  name: string;
  description: string | null;
  details: string | null;
  detailsNotAccepted: string | null;
}

export interface InspectionTaskQualitative {
  catalogFeatures: CatalogFeature[];
  type: number;
  plant: number;
  name: string;
  targetValue: string | null;
  targetValueOld: string | null;
}

export interface InspectionTaskQuantitativeOld {
  decimalPlacesOld: number | null;
  desiredValueOld: number | null;
  toleranceHighOld: number | null;
  toleranceLowOld: number | null;
  warningHighOld: number | null;
  warningLowOld: number | null;
  plausibleHighOld: number | null;
  plausibleLowOld: number | null;
}

export interface InspectionTaskQuantitativeBase {
  decimalPlaces: number;
  desiredValue: number | null;
  toleranceHigh: number | null;
  toleranceLow: number | null;
  warningHigh: number | null;
  warningLow: number | null;
  plausibleHigh: number | null;
  plausibleLow: number | null;
  unit: string;
  unitText: string;
  calculatedCustomFunctionId: number | null;
  calculatedCustomFunction: CustomFunction | undefined;
  inspectionEquipmentId: number | null;
  inspectionEquipment: InspectionEquipment | undefined;
}

export interface InspectionTaskQuantitative extends InspectionTaskQuantitativeBase, InspectionTaskQuantitativeOld {
}

export class InspectionTask extends BaseModel {
  id: number = 0;
  inspectionLotId: number | null = null;
  inspectionType: InspectionType = InspectionType.Qualitative;
  isSilent: boolean = false;
  isSilentNotAccepted: boolean = false;
  workplaceId: number | null = null;
  phaseIds: number[] = [];
  characteristicNo: string = '';
  inspectionTaskGroupId: number | null = null;
  requiredRoleId: number | null = null;
  interval: number | null = null;
  isAbsoluteInterval: boolean = false;
  intervalNotAccepted: number | null = null;
  checkAtStart: boolean = false;
  checkAtStartOffset: number | null = null;
  checkAtEnd: boolean = false;
  intervalEnd: number | null = null;
  dueDateFromInspectionLot: number | null = null;
  isObligatory: boolean = false;
  mustBeAccepted: boolean = false;
  isOverruleAllowed: boolean = false;
  showUntilAccepted: boolean = false;
  showUntilConducted: boolean = false;
  onceForAllVirtualWorkplaces: boolean = false;
  showOnSiblingVirtualWorkplaces: boolean = false;
  locationCode: string | null = null;
  isAutoValuate: boolean = false;
  graph: InspectionGraph = InspectionGraph.none;
  hideQualityIndicator: boolean = false;
  isUserExit: boolean = false;
  deactivatedAt: string | null = null;
  deactivatedBy: number | null = null;
  deactivatedRemark: string | null = null;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;
  operationNo: string | null = null;
  qualitative: InspectionTaskQualitative | null = null;
  quantitative: InspectionTaskQuantitative | null = null;
  documentIds: number[] = [];
  priority: InspectionTaskPriority = InspectionTaskPriority.NORMAL;
  isTemplateTask: boolean = false;
  templateHierarchyIds: number[] = [];
  isFourEyes: boolean = false;
  fourEyesDelay: number | null = null;
  fourEyesRoleId: number | null = null;
  maxOverdueAllowed: number | null = null;
  translations: InspectionTaskTranslation[] = [];
  properties?: CustomPropertiesObject = undefined;
  isRemarkRequired: boolean = false;
  checkAtException: boolean = false;
  checkAtExceptionOffset: number | null = null;
  checkAtExceptionLimit: number | null = null;
  exceptionSourcePhaseIds: number[] = [];
  cronPattern: string | null = null;
  reportingNo: string | null = null;
  editedRemark: string | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      inspectionLotId: observable,
      inspectionLot: computed,
      inspectionType: observable,
      isSilent: observable,
      isSilentNotAccepted: observable,
      name: computed,
      description: computed,
      details: computed,
      workplace: computed,
      workplaceId: observable,
      phaseIds: observable,
      characteristicNo: observable,
      inspectionTaskGroupId: observable,
      inspectionTaskGroup: computed,
      requiredRoleId: observable,
      interval: observable,
      isAbsoluteInterval: observable,
      intervalNotAccepted: observable,
      checkAtStart: observable,
      checkAtStartOffset: observable,
      checkAtEnd: observable,
      intervalEnd: observable,
      dueDateFromInspectionLot: observable,
      isObligatory: observable,
      mustBeAccepted: observable,
      isOverruleAllowed: observable,
      showUntilAccepted: observable,
      showUntilConducted: observable,
      onceForAllVirtualWorkplaces: observable,
      showOnSiblingVirtualWorkplaces: observable,
      locationCode: observable,
      isAutoValuate: observable,
      graph: observable,
      hideQualityIndicator: observable,
      isUserExit: observable,
      deactivatedAt: observable,
      deactivatedBy: observable,
      deactivatedByUser: computed,
      deactivatedRemark: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
      operationNo: observable,
      qualitative: observable,
      quantitative: observable,
      documentIds: observable,
      documents: computed,
      priority: observable,
      isTemplateTask: observable,
      templateHierarchyIds: observable,
      detailsNotAccepted: computed,
      isFourEyes: observable,
      fourEyesDelay: observable,
      fourEyesRoleId: observable,
      fourEyesRole: computed,
      maxOverdueAllowed: observable,
      overruleType: computed,
      translations: observable,
      isRemarkRequired: observable,
      checkAtException: observable,
      checkAtExceptionOffset: observable,
      checkAtExceptionLimit: observable,
      exceptionSourcePhaseIds: observable,
    });
  }

  static faIcon = faTag;

  searchableProperties = ['id', 'name', 'description'];

  translatedProperties = ['name', 'description', 'details', 'detailsNotAccepted'];

  displayableProperties = [
    displayableProperty({
      key: 'inspectionType',
      title: i18n.t('inspectionTask.model.attributes.inspectionType.label'),
      params: [displayablePropertyParam({ path: 'inspectionType', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'name',
      title: i18n.t('inspectionTask.model.attributes.name.label'),
      params: [displayablePropertyParam({ path: 'name', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'description',
      title: i18n.t('inspectionTask.model.attributes.description.label'),
      params: [displayablePropertyParam({ path: 'description', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'characteristicNo',
      title: i18n.t('inspectionTask.model.attributes.characteristicNo.label'),
      params: [displayablePropertyParam({ path: 'characteristicNo', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'operationNo',
      title: i18n.t('inspectionTask.model.attributes.operationNo.label'),
      params: [displayablePropertyParam({ path: 'operationNo', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'priority',
      title: i18n.t('inspectionTask.model.attributes.priority.label'),
      params: [displayablePropertyParam({ path: 'priority', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'interval',
      // eslint-disable-next-line max-len
      title: `${i18n.t('inspectionTask.model.attributes.interval.label')}`,
      params: [displayablePropertyParam({ path: 'interval', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'isAbsoluteInterval',
      // eslint-disable-next-line max-len
      title: `${i18n.t('inspectionTask.model.attributes.isAbsoluteInterval.label')}`,
      params: [displayablePropertyParam({ path: 'isAbsoluteInterval', transform: transformation.booleanValue })],
      template: '{value}',
      raw: true,
    }),
    displayableProperty({
      key: 'intervalNotAccepted',
      // eslint-disable-next-line max-len
      title: `${i18n.t('inspectionTask.model.attributes.intervalNotAccepted.label')}`,
      params: [displayablePropertyParam({ path: 'intervalNotAccepted', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'quantitative.desiredValue',
      // eslint-disable-next-line max-len
      title: `${i18n.t('inspectionManagement.inspectionTask.widget.quantitativeValues')} > ${i18n.t('inspectionTaskQuantitative.model.attributes.desiredValue.label')}`,
      params: [displayablePropertyParam({ path: 'quantitative.desiredValue', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'quantitative.decimalPlaces',
      // eslint-disable-next-line max-len
      title: `${i18n.t('inspectionManagement.inspectionTask.widget.quantitativeValues')} > ${i18n.t('inspectionTaskQuantitative.model.attributes.decimalPlaces.label')}`,
      params: [displayablePropertyParam({ path: 'quantitative.decimalPlaces', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'quantitative.unit',
      // eslint-disable-next-line max-len
      title: `${i18n.t('inspectionManagement.inspectionTask.widget.quantitativeValues')} > ${i18n.t('inspectionTaskQuantitative.model.attributes.unit.label')}`,
      params: [displayablePropertyParam({ path: 'quantitative.unit', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'quantitative.unitText',
      // eslint-disable-next-line max-len
      title: `${i18n.t('inspectionManagement.inspectionTask.widget.quantitativeValues')} > ${i18n.t('inspectionTaskQuantitative.model.attributes.unitText.label')}`,
      params: [displayablePropertyParam({ path: 'quantitative.unitText', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'quantitative.warningLow',
      // eslint-disable-next-line max-len
      title: `${i18n.t('inspectionManagement.inspectionTask.widget.quantitativeValues')} > ${i18n.t('inspectionTaskQuantitative.model.attributes.warningLow.label')}`,
      params: [displayablePropertyParam({ path: 'quantitative.warningLow', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'quantitative.warningHigh',
      // eslint-disable-next-line max-len
      title: `${i18n.t('inspectionManagement.inspectionTask.widget.quantitativeValues')} > ${i18n.t('inspectionTaskQuantitative.model.attributes.warningHigh.label')}`,
      params: [displayablePropertyParam({ path: 'quantitative.warningHigh', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'quantitative.toleranceLow',
      // eslint-disable-next-line max-len
      title: `${i18n.t('inspectionManagement.inspectionTask.widget.quantitativeValues')} > ${i18n.t('inspectionTaskQuantitative.model.attributes.toleranceLow.label')}`,
      params: [displayablePropertyParam({ path: 'quantitative.toleranceLow', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'quantitative.toleranceHigh',
      // eslint-disable-next-line max-len
      title: `${i18n.t('inspectionManagement.inspectionTask.widget.quantitativeValues')} > ${i18n.t('inspectionTaskQuantitative.model.attributes.toleranceHigh.label')}`,
      params: [displayablePropertyParam({ path: 'quantitative.toleranceHigh', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'quantitative.plausibleLow',
      // eslint-disable-next-line max-len
      title: `${i18n.t('inspectionManagement.inspectionTask.widget.quantitativeValues')} > ${i18n.t('inspectionTaskQuantitative.model.attributes.plausibleLow.label')}`,
      params: [displayablePropertyParam({ path: 'quantitative.plausibleLow', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'quantitative.plausibleHigh',
      // eslint-disable-next-line max-len
      title: `${i18n.t('inspectionManagement.inspectionTask.widget.quantitativeValues')} > ${i18n.t('inspectionTaskQuantitative.model.attributes.plausibleHigh.label')}`,
      params: [displayablePropertyParam({ path: 'quantitative.plausibleHigh', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'details',
      // eslint-disable-next-line max-len
      title: `${i18n.t('inspectionTask.model.attributes.details.label')}`,
      params: [displayablePropertyParam({ path: 'details', transform: transformation.none })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'isRemarkRequired',
      title: `${i18n.t('inspectionTask.model.attributes.isRemarkRequired.label')}`,
      params: [displayablePropertyParam({ path: 'isRemarkRequired', transform: transformation.booleanValue })],
      template: '{value}',
      raw: true,
    }),
  ];

  nestedModels = ['qualitative', 'quantitative'];

  customPropertyType = CustomPropertyTypes.InspectionTask;

  populateAttributesFromStore(rootStore: RootStore) {
    super.populateAttributesFromStore(rootStore);
    if (this.qualitative?.catalogFeatures?.length) {
      this.qualitative.catalogFeatures = this.qualitative.catalogFeatures.map(
        (catalogFeature) => CatalogFeature.fromPlainObject(catalogFeature, rootStore)
      );
    }
    if (this.quantitative?.calculatedCustomFunctionId) {
      this.quantitative.calculatedCustomFunction = rootStore.customFunctionStore
        .getById(this.quantitative.calculatedCustomFunctionId);
    }
    if (this.quantitative?.inspectionEquipmentId) {
      this.quantitative.inspectionEquipment = rootStore.inspectionEquipmentStore
        .getById(this.quantitative.inspectionEquipmentId);
    }
  }

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);
    allDisplayableProperties.push(
      ...this.displayableCustomProperties(rootStore, pathPrefix, titlePrefix)
    );
    const inspectionLotPrefix = `${titlePrefix}${i18n.t('inspectionLot.model.one')} > `;
    allDisplayableProperties.push(
      ...InspectionLot.allDisplayableProperties(rootStore, `${pathPrefix}inspectionLot.`, inspectionLotPrefix)
    );
    const inspectionTaskGroupPrefix = `${titlePrefix}${i18n.t('inspectionTaskGroup.model.one')} > `;
    allDisplayableProperties.push(
      ...InspectionTaskGroup.allDisplayableProperties(
        rootStore,
        `${pathPrefix}inspectionTaskGroup.`,
        inspectionTaskGroupPrefix
      )
    );
    const workplacePrefix = `${titlePrefix}${i18n.t('workplace.model.one')} > `;
    allDisplayableProperties.push(
      ...Workplace.allDisplayableProperties(rootStore, `${pathPrefix}workplace.`, workplacePrefix)
    );

    const inspectionEquipmentPrefix = `${titlePrefix}${i18n.t('inspectionEquipment.model.one')} > `;
    allDisplayableProperties.push(
      ...InspectionEquipment.allDisplayableProperties(
        rootStore,
        `${pathPrefix}inspectionEquipment.`,
        inspectionEquipmentPrefix
      )
    );

    return allDisplayableProperties;
  }

  static prepareApiPayload(model: InspectionTask) {
    if (model.inspectionType === InspectionType.Qualitative
      || model.inspectionType === InspectionType.FactoryCode
      || model.inspectionType === InspectionType.BestBeforeDate) {
      if (model.qualitative?.catalogFeatures?.length) {
        model.qualitative.catalogFeatures = model.qualitative.catalogFeatures.map(
          (feature) => CatalogFeature.prepareApiPayload(feature) as CatalogFeature
        );
      }
    }

    return {
      ...this.discardPropsWhereNameIncludes(model, 'translated_'),
      checkAtStartOffset: model.checkAtStartOffset || null,
      phaseIds: model.phaseIds || null,
      exceptionSourcePhaseIds: model.exceptionSourcePhaseIds?.length ? model.exceptionSourcePhaseIds : [],
      checkAtException: model.checkAtException || false,
      isSilent: model.isSilent || false,
      isSilentNotAccepted: model.isSilentNotAccepted || false,
      checkAtExceptionLimit: model.checkAtExceptionLimit !== null
        ? model.checkAtExceptionLimit : null,
      checkAtExceptionOffset: model.checkAtExceptionOffset || model.checkAtExceptionOffset !== null
        ? model.checkAtExceptionOffset : null,
      inspectionTaskGroupId: model.inspectionTaskGroupId || null,
      requiredRoleId: model.requiredRoleId || null,
      interval: model.interval || null,
      isAbsoluteInterval: model.isAbsoluteInterval || false,
      intervalNotAccepted: model.intervalNotAccepted || null,
      intervalEnd: model.intervalEnd || null,
      dueDateFromInspectionLot: model.dueDateFromInspectionLot || null,
      locationCode: model.locationCode || null,
      operationNo: model.operationNo || null,
      documentIds: model.documentIds?.length ? model.documentIds : [],
      templateHierarchyIds: model.templateHierarchyIds.length ? model.templateHierarchyIds : [],
      isTemplateTask: true,
      fourEyesDelay: model.fourEyesDelay !== null ? model.fourEyesDelay : null,
      fourEyesRoleId: model.fourEyesRoleId || null,
      maxOverdueAllowed: model.maxOverdueAllowed !== null ? model.maxOverdueAllowed : null,
      translations: this.convertToSavableTranslations(model),
    };
  }

  get documentsTree() {
    return this.rootStore.documentStore.getByIds(this.documentIds).map((document) => ({
      title: document.label,
      key: document.id,
      isLeaf: true,
      icon: document.icon,
      document,
    }));
  }

  get overruleType() {
    if (this.isOverruleAllowed) {
      return i18n.t('inspectionTask.model.attributes.overruleType.overrule');
    }
    if (this.isFourEyes) {
      if (this.fourEyesDelay) {
        return i18n.t('inspectionTask.model.attributes.overruleType.fourEyesDelay');
      }
      return i18n.t('inspectionTask.model.attributes.overruleType.fourEyes');
    }
    return i18n.t('inspectionTask.model.attributes.overruleType.none');
  }

  get name() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.name;
  }

  get description() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.description;
  }

  get details() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.details;
  }

  get detailsNotAccepted() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.detailsNotAccepted;
  }

  get inspectionLot() {
    return this.rootStore.inspectionLotStore.getById(this.inspectionLotId);
  }

  get documents() {
    return this.rootStore.documentStore.getByIds(this.documentIds);
  }

  get workplace() {
    return this.rootStore.workplaceStore.getById(this.workplaceId);
  }

  get deactivatedByUser() {
    return this.rootStore.userStore.getById(this.deactivatedBy);
  }

  get inspectionTaskGroup() {
    return this.rootStore.inspectionTaskGroupStore.getById(this.inspectionTaskGroupId);
  }

  get fourEyesRole() {
    return this.rootStore.roleStore.getById(this.fourEyesRoleId);
  }
}
