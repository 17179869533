import { action, observable, makeObservable } from 'mobx';
import i18n from '../i18n/i18n';
import { Api } from '../middleware/api';
import { Component } from '../models/component';
import { EntityStore } from './entityStore';
import { RootStore } from './rootStore';
import { Preparation } from '../models/preparation';

export class ComponentStore extends EntityStore<Component> {
  components: Component[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'components', Api.components, Component);

    makeObservable(this, {
      components: observable,
      loadByOperation: action,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.materialStore,
        modelId: 'materialId',
      },
      {
        store: this.rootStore.operationStore,
        modelId: 'operationId',
      },
      {
        store: this.rootStore.productStore,
        modelId: 'productId',
      },
      {
        store: this.rootStore.batchStore,
        modelId: 'batchIds',
      },
    ];
  }

  filterBy({ operationId, position }: Pick<Component, 'operationId' | 'position'>) {
    let result = this.components;
    if (operationId) {
      result = result.filter((component) => component.operationId === operationId);
    }
    if (position !== undefined) {
      result = result.filter((component) => component.position === position);
    }

    return result;
  }

  positionFinished(preparation: Preparation, position: Component['position']) {
    const positionComponents = this.filterBy({ operationId: preparation.operationId, position });
    return positionComponents.every(
      (component) => component.toleranceLow
        && this.rootStore.consumptionLogStore.actualConsumed(preparation.id, component.id) >= component.toleranceLow
    );
  }

  // eslint-disable-next-line class-methods-use-this
  positionName(position: Component['position']) {
    return position === null
      ? i18n.t('batchHandling.preparation.otherPosition')
      : `${i18n.t('batchHandling.preparation.position')} ${position}`;
  }

  loadByOperation(operationId: Component['operationId']) {
    return this.loadAllWithDependencies({ params: { operationId } });
  }

  getByOperationId(operationId: Component['operationId']) {
    return this.components.filter((c) => c.operationId === operationId);
  }

  getByOperationIds(operationIds: Component['operationId'][]) {
    return this.components.filter((c) => operationIds.includes(c.operationId));
  }

  loadByOrderId(orderId: number) {
    return this.loadAllWithDependencies({ params: { orderId } });
  }

  filterByOrderId(orderId: number) {
    return this.components.filter((c) => c.operation?.orderId === orderId);
  }
}
