import {BaseWidgetConfig} from '../shared/widgets/baseWidgetConfig';
import i18n from '../../i18n/i18n';
import {ValidationRules} from '../../utils/validationRules';
import {Yield} from '../../models/yield';
import {getAllI18nTranslations} from '../../utils/translations';
import {InspectionTaskResult} from '../../models/inspectionTaskResult';
import {FieldType} from '../../types/widgetConfig';

export class ProducedMaterialWidgetConfig extends BaseWidgetConfig {
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'settings',
        label: i18n.t('widget.WidgetSettingsModal.settingConfigTabTitle'),
      },
      {
        key: 'input',
        label: i18n.t('widget.WidgetSettingsModal.inputConfigTabTitle'),
      },
      {
        key: 'data',
        label: i18n.t('widget.WidgetSettingsModal.dataConfigTabTitle'),
      },
      {
        key: 'batchView',
        label: i18n.t('widget.WidgetSettingsModal.modalViewBatchConfigTabTitle'),
      },
    ];
  }

  settings = [
    {
      id: 'title',
      label: i18n.t('widget.settings.title.label'),
      helpText: i18n.t('widget.settings.title.help'),
      type: FieldType.Translatable,
      defaultValue: getAllI18nTranslations('producedMaterialWidgetConfig.config.settings.title.defaultValue'),
      validations: [ValidationRules.required()],
    },
    {
      id: 'produce',
      label: i18n.t('producedMaterialWidgetConfig.config.settings.produce.label'),
      helpText: i18n.t('producedMaterialWidgetConfig.config.settings.produce.help'),
      type: FieldType.String,
      defaultValue: i18n.t('producedMaterialWidgetConfig.config.settings.produce.defaultValue'),
      validations: [ValidationRules.required()],
    },
    {
      id: 'waste',
      label: i18n.t('producedMaterialWidgetConfig.config.settings.waste.label'),
      helpText: i18n.t('producedMaterialWidgetConfig.config.settings.waste.help'),
      type: FieldType.String,
      defaultValue: i18n.t('producedMaterialWidgetConfig.config.settings.waste.defaultValue'),
      validations: [ValidationRules.required()],
    },
    {
      id: 'consume',
      label: i18n.t('producedMaterialWidgetConfig.config.settings.consume.label'),
      helpText: i18n.t('producedMaterialWidgetConfig.config.settings.consume.help'),
      type: FieldType.String,
      defaultValue: i18n.t('producedMaterialWidgetConfig.config.settings.consume.defaultValue'),
      validations: [ValidationRules.required()],
    },
    {
      id: 'showSensorStateManagementList',
      label: i18n.t('producedMaterialWidget.settings.showSensorStateManagementList.label'),
      helpText: i18n.t('producedMaterialWidget.settings.showSensorStateManagementList.help'),
      type: FieldType.Boolean,
      defaultValue: true,
    },
    {
      id: 'showLastProducedBatch',
      label: i18n.t('producedMaterialWidget.settings.showLastProducedBatch.label'),
      helpText: i18n.t('producedMaterialWidget.settings.showLastProducedBatch.help'),
      type: FieldType.Boolean,
      defaultValue: true,
    },
    {
      id: 'showProducedBatches',
      label: i18n.t('producedMaterialWidget.settings.showProducedBatches.label'),
      helpText: i18n.t('producedMaterialWidget.settings.showProducedBatches.help'),
      type: FieldType.Boolean,
      defaultValue: false,
    },
    {
      id: 'showDateAdjuster',
      label: i18n.t('producedMaterialWidget.settings.showDateAdjuster.label'),
      helpText: i18n.t('producedMaterialWidget.settings.showDateAdjuster.help'),
      type: FieldType.Boolean,
      defaultValue: false,
    },
  ];

  input = [
    {
      id: 'plausibleLow',
      label: i18n.t('producedMaterialWidgetConfig.config.input.plausibleLow.label'),
      helpText: i18n.t('producedMaterialWidgetConfig.config.input.plausibleLow.help'),
      type: FieldType.Number,
      validations: [
        ValidationRules.greaterThan(0),
        ValidationRules.compareToField('plausibleHigh', ValidationRules.lessThan),
      ],
      dependencies: ['plausibleHigh'],
    },
    {
      id: 'plausibleHigh',
      label: i18n.t('producedMaterialWidgetConfig.config.input.plausibleHigh.label'),
      helpText: i18n.t('producedMaterialWidgetConfig.config.input.plausibleHigh.help'),
      type: FieldType.Number,
      validations: [
        ValidationRules.greaterThan(0),
        ValidationRules.compareToField('plausibleLow', ValidationRules.greaterThan),
      ],
      dependencies: ['plausibleLow'],
    },
  ];

  data = [
    {
      id: 'properties',
      type: FieldType.PropertiesSelectDisplayField,
      modelOptions: Yield.allDisplayableProperties(this.rootStore),
      defaultValue: [
        'batch.no', 'quantity', 'material.label', 'yieldType.label',
      ],
    },
  ];

  batchView = [
    {
      id: 'columns',
      label: i18n.t('inspectionReportWidgetConfig.settings.columns'),
      type: FieldType.PropertiesSelectDisplayField,
      modelOptions: InspectionTaskResult.allDisplayableProperties(this.rootStore),
      defaultValue: [
        'createdAt',
        'createdBy',
        'value',
        'valuation',
      ],
    },
  ];

  getWidgetSetting(identifier) {
    return this.getSettingValue('settings', identifier);
  }

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'title');
  }

  showSensorStateManagementList() {
    return this.getSettingValue('settings', 'showSensorStateManagementList');
  }

  showProducedBatches() {
    return this.getSettingValue('settings', 'showProducedBatches');
  }

  showLastProducedBatch() {
    return this.getSettingValue('settings', 'showLastProducedBatch');
  }

  getBatchLogTableColumns() {
    return this.getSelectedProperties('data', 'properties');
  }

  getBatchViewDisplayableColumns() {
    return this.getSelectedProperties('batchView', 'columns');
  }
}
