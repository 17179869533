import { createContext } from 'react';
import { AppConfigStore } from './appConfigStore';
import { AuditLogStore } from './auditLogStore';
import { AuthStore } from './authStore';
import { BatchGeneratorFunctionStore } from './batchGeneratorFunctionStore';
import { BatchQueueItemStore } from './batchQueueItemStore';
import { BatchStore } from './batchStore';
import { ClientStore } from './clientStore';
import { CockpitDashboardStore } from './cockpitDashboardStore';
import { CockpitStore } from './cockpitStore';
import { CockpitWidgetStore } from './cockpitWidgetStore';
import { ComponentStore } from './componentStore';
import { ConfigTransferStore } from './configTransferStore';
import { ConfigTransferFileStore } from './configTransferFileStore';
import { ConfigTransferLogStore } from './configTransferLogStore';
import { ConsumptionLogStore } from './consumptionLogStore';
import { ContextStore } from './contextStore';
import { CustomActionStore } from './customActionStore';
import { CustomFunctionStore } from './customFunctionStore';
import { CustomPropertyStore } from './customPropertyStore';
import { DataExportSchedulerStore } from './dataExportSchedulerStore';
import { DocumentStore } from './documentStore';
import { DocumentTypeStore } from './documentTypeStore';
import { EventLogStore } from './eventLogStore';
import { ExternalResourceStore } from './externalResourceStore';
import { ExternalResourceLogStore } from './externalResourceLogStore';
import { FaultStore } from './faultStore';
import { FaultCollectionStore } from './faultCollectionStore';
import { FlashMessageStore } from './flashMessageStore';
import { GatewayStore } from './gatewayStore';
import { HierarchyNavigationStore } from './hierarchyNavigationStore';
import { HierarchyStore } from './hierarchyStore';
import { IngredientStore } from './ingredientStore';
import { InspectionLotStore } from './inspectionLotStore';
import { InspectionPlanStore } from './inspectionPlanStore';
import { InspectionTaskGroupStore } from './inspectionTaskGroupStore';
import { InspectionTaskPendingStore } from './inspectionTaskPendingStore';
import { InspectionTaskResultStore } from './inspectionTaskResultStore';
import { InspectionTaskStore } from './inspectionTaskStore';
import { InspectionVerificationStore } from './inspectionVerificationStore';
import { InterruptionClassStore } from './interruptionClassStore';
import { InterruptionReasonStore } from './interruptionReasonStore';
import { DataExportTemplateStore } from './dataExportTemplateStore';
import { KeyPerformanceIndicatorLimitStore } from './keyPerformanceIndicatorLimitStore';
import { KeyPerformanceIndicatorStore } from './keyPerformanceIndicatorStore';
import { KeyPerformanceIndicatorTargetStore } from './keyPerformanceIndicatorTargetStore';
import { LanguageStore } from './languageStore';
import { LdapConnectionStore } from './ldapConnectionStore';
import { LdapFieldMappingStore } from './ldapFieldMappingStore';
import { LdapGroupStore } from './ldapGroupStore';
import { ManualStore } from './manualStore';
import { MaterialSensorStore } from './materialSensorStore';
import { MaterialStore } from './materialStore';
import { MessageReadStore } from './messageReadStore';
import { MessageStore } from './messageStore';
import { MqttStore } from './mqttStore';
import { ObjectLockStore } from './objectLockStore';
import { OfficeStore } from './officeStore';
import { OperationMessageStore } from './operationMessageStore';
import { OperationPhaseStore } from './operationPhaseStore';
import { OperationStateLogStore } from './operationStateLogStore';
import { OperationStateStore } from './operationStateStore';
import { OperationsStore } from './operationStore';
import { OrderStore } from './orderStore';
import { PerformanceAnalysisStore } from './performanceAnalysisStore';
import { PermissionStore } from './permissionStore';
import { PersonnelLogStore } from './personnelLogStore';
import { PhaseStore } from './phaseStore';
import { PhaseTimeoutReasonStore } from './phaseTimeoutReasonStore';
import { PhaseTimeoutStore } from './phaseTimeoutStore';
import { PopupMessageStore } from './popupMessageStore';
import { PreparationStore } from './preparationStore';
import { PrintAllocationStore } from './printAllocationStore';
import { PrintJobLogStore } from './printJobLogStore';
import { PrintJobStore } from './printJobStore';
import { PrintLabelStore } from './printLabelStore';
import { PrinterStore } from './printerStore';
import { ProcessDataConnectionStore } from './processDataConnectionStore';
import { ProcessDataPointStore } from './processDataPointStore';
import { ProcessDataSourceStore } from './processDataSourceStore';
import { ProfileStore } from './profileStore';
import { RelevantMessagesStore } from './relevantMessagesStore';
import { ReportLogStore } from './reportLogStore';
import { ReportTypeStore } from './reportTypeStore';
import { RoleStore } from './roleStore';
import { SensorDataLimitStore } from './sensorDataLimitStore';
import { SensorDataStore } from './sensorDataStore';
import { SensorDataTargetStore } from './sensorDataTargetStore';
import { SensorStore } from './sensorStore';
import { SettingStore } from './settingStore';
import { ShiftBookStore } from './shiftBookStore';
import { ShiftStore } from './shiftStore';
import { StateHistoryStore } from './stateHistoryStore';
import { StorageAreaStore } from './storageAreaStore';
import { StorageUnitStore } from './storageUnitStore';
import { StorageUnitLevelStore } from './storageUnitLevelStore';
import { StorageUnitLogStore } from './storageUnitLogStore';
import { TerminalLayoutStore } from './terminalLayoutStore';
import { TerminalStore } from './terminalStore';
import { TransitionStore } from './transitionStore';
import { TransitionQueueStore } from './transitionQueueStore';
import { UnitOfMeasurementConversionStore } from './unitOfMeasurementConversionStore';
import { UnitOfMeasurementStore } from './unitOfMeasurementStore';
import { UserRoleStore } from './userRoleStore';
import { UserStore } from './userStore';
import { ValidStartTransitionsStore } from './validStartTransitionsStore';
import { ValidTransitionsStore } from './validTransitionsStore';
import { WorkflowCorrectionStore } from './workflowCorrectionStore';
import { WorkflowStore } from './workflowStore';
import { WorkplaceGroupStore } from './workplaceGroupStore';
import { WorkplaceStateLogStore } from './workplaceStateLogStore';
import { WorkplaceStateStore } from './workplaceStateStore';
import { WorkplaceStore } from './workplaceStore';
import { YieldStore } from './yieldStore';
import { YieldTypeStore } from './yieldTypeStore';
import { WorkplaceStorageRelationStore } from './workplaceStorageRelationStore';
import { QualityReportStore } from './qualityReportStore';
import { OrderTemplateStore } from './orderTemplateStore';
import { InspectionEquipmentStore } from './inspectionEquipmentStore';
import { UserNotificationConfigStore } from './userNotificationConfigStore';
import { UserNotificationStore } from './userNotificationStore';
import { ProductStore } from './productStore';
import { DosageStore } from './dosageStore';
import { WidgetActionStore } from './widgetActionStore';

export class RootStore {
  appConfigStore: AppConfigStore;
  auditLogStore: AuditLogStore;
  authStore: AuthStore;
  batchGeneratorFunctionStore: BatchGeneratorFunctionStore;
  batchQueueItemStore: BatchQueueItemStore;
  batchStore: BatchStore;
  clientStore: ClientStore;
  cockpitDashboardStore: CockpitDashboardStore;
  cockpitStore: CockpitStore;
  cockpitWidgetStore: CockpitWidgetStore;
  componentStore: ComponentStore;
  configTransferStore: ConfigTransferStore;
  configTransferFileStore: ConfigTransferFileStore;
  configTransferLogStore: ConfigTransferLogStore;
  consumptionLogStore: ConsumptionLogStore;
  contextStore: ContextStore;
  customActionStore: CustomActionStore;
  customFunctionStore: CustomFunctionStore;
  dataExportSchedulerStore: DataExportSchedulerStore;
  dataExportTemplateStore: DataExportTemplateStore;
  dosageStore: DosageStore;
  documentStore: DocumentStore;
  documentTypeStore: DocumentTypeStore;
  eventLogStore: EventLogStore;
  externalResourceStore: ExternalResourceStore;
  externalResourceLogStore: ExternalResourceLogStore;
  faultStore: FaultStore;
  faultCollectionStore: FaultCollectionStore;
  flashMessageStore: FlashMessageStore;
  gatewayStore: GatewayStore;
  hierarchyNavigationStore: HierarchyNavigationStore;
  hierarchyStore: HierarchyStore;
  ingredientStore: IngredientStore;
  inspectionEquipmentStore: InspectionEquipmentStore;
  inspectionLotStore: InspectionLotStore;
  inspectionPlanStore: InspectionPlanStore;
  inspectionTaskGroupStore: InspectionTaskGroupStore;
  inspectionTaskPendingStore: InspectionTaskPendingStore;
  inspectionTaskResultStore: InspectionTaskResultStore;
  inspectionTaskStore: InspectionTaskStore;
  inspectionVerificationStore: InspectionVerificationStore;
  interruptionClassStore: InterruptionClassStore;
  interruptionReasonStore: InterruptionReasonStore;
  keyPerformanceIndicatorLimitStore: KeyPerformanceIndicatorLimitStore;
  keyPerformanceIndicatorStore: KeyPerformanceIndicatorStore;
  keyPerformanceIndicatorTargetStore: KeyPerformanceIndicatorTargetStore;
  languageStore: LanguageStore;
  ldapConnectionStore: LdapConnectionStore;
  ldapFieldMappingStore: LdapFieldMappingStore;
  ldapGroupStore: LdapGroupStore;
  manualStore: ManualStore;
  materialSensorStore: MaterialSensorStore;
  materialStore: MaterialStore;
  messageReadStore: MessageReadStore;
  messageStore: MessageStore;
  mqttStore: MqttStore;
  objectLockStore: ObjectLockStore;
  officeStore: OfficeStore;
  operationMessageStore: OperationMessageStore;
  operationPhaseStore: OperationPhaseStore;
  operationStateLogStore: OperationStateLogStore;
  operationStateStore: OperationStateStore;
  operationStore: OperationsStore;
  orderStore: OrderStore;
  performanceAnalysisStore: PerformanceAnalysisStore;
  permissionStore: PermissionStore;
  personnelLogStore: PersonnelLogStore;
  phaseStore: PhaseStore;
  phaseTimeoutReasonStore: PhaseTimeoutReasonStore;
  phaseTimeoutStore: PhaseTimeoutStore;
  popupMessageStore: PopupMessageStore;
  preparationStore: PreparationStore;
  printAllocationStore: PrintAllocationStore;
  printJobLogStore: PrintJobLogStore;
  printJobStore: PrintJobStore;
  printLabelStore: PrintLabelStore;
  printerStore: PrinterStore;
  processDataConnectionStore: ProcessDataConnectionStore;
  processDataPointStore: ProcessDataPointStore;
  processDataSourceStore: ProcessDataSourceStore;
  productStore: ProductStore;
  profileStore: ProfileStore;
  propertyStore: CustomPropertyStore;
  qualityReportStore: QualityReportStore;
  relevantMessagesStore: RelevantMessagesStore;
  reportLogStore: ReportLogStore;
  reportTypeStore: ReportTypeStore;
  roleStore: RoleStore;
  sensorDataLimitStore: SensorDataLimitStore;
  sensorDataStore: SensorDataStore;
  sensorDataTargetStore: SensorDataTargetStore;
  sensorStore: SensorStore;
  settingStore: SettingStore;
  shiftBookStore: ShiftBookStore;
  shiftStore: ShiftStore;
  stateHistoryStore: StateHistoryStore;
  storageAreaStore: StorageAreaStore;
  storageUnitStore: StorageUnitStore;
  storageUnitLevelStore: StorageUnitLevelStore;
  storageUnitLogStore: StorageUnitLogStore;
  terminalLayoutStore: TerminalLayoutStore;
  terminalStore: TerminalStore;
  transitionStore: TransitionStore;
  transitionQueueStore: TransitionQueueStore;
  unitOfMeasurementConversionStore: UnitOfMeasurementConversionStore;
  unitOfMeasurementStore: UnitOfMeasurementStore;
  userRoleStore: UserRoleStore;
  userStore: UserStore;
  userNotificationConfigStore: UserNotificationConfigStore;
  userNotificationStore: UserNotificationStore;
  validStartTransitionsStore: ValidStartTransitionsStore;
  validTransitionsStore: ValidTransitionsStore;
  widgetActionStore: WidgetActionStore;
  workflowCorrectionStore: WorkflowCorrectionStore;
  workflowStore: WorkflowStore;
  workplaceGroupStore: WorkplaceGroupStore;
  workplaceStateLogStore: WorkplaceStateLogStore;
  workplaceStateStore: WorkplaceStateStore;
  workplaceStorageRelationStore: WorkplaceStorageRelationStore;
  workplaceStore: WorkplaceStore;
  yieldStore: YieldStore;
  yieldTypeStore: YieldTypeStore;
  orderTemplateStore: OrderTemplateStore;

  constructor() {
    this.appConfigStore = new AppConfigStore(this);
    this.auditLogStore = new AuditLogStore(this);
    this.authStore = new AuthStore(this);
    this.batchGeneratorFunctionStore = new BatchGeneratorFunctionStore(this);
    this.batchQueueItemStore = new BatchQueueItemStore(this);
    this.batchStore = new BatchStore(this);
    this.clientStore = new ClientStore(this);
    this.cockpitDashboardStore = new CockpitDashboardStore(this);
    this.cockpitStore = new CockpitStore(this);
    this.cockpitWidgetStore = new CockpitWidgetStore(this);
    this.componentStore = new ComponentStore(this);
    this.configTransferStore = new ConfigTransferStore(this);
    this.configTransferFileStore = new ConfigTransferFileStore(this);
    this.configTransferLogStore = new ConfigTransferLogStore(this);
    this.consumptionLogStore = new ConsumptionLogStore(this);
    this.contextStore = new ContextStore(this);
    this.customActionStore = new CustomActionStore(this);
    this.customFunctionStore = new CustomFunctionStore(this);
    this.dataExportSchedulerStore = new DataExportSchedulerStore(this);
    this.dataExportTemplateStore = new DataExportTemplateStore(this);
    this.documentStore = new DocumentStore(this);
    this.documentTypeStore = new DocumentTypeStore(this);
    this.dosageStore = new DosageStore(this);
    this.eventLogStore = new EventLogStore(this);
    this.externalResourceStore = new ExternalResourceStore(this);
    this.externalResourceLogStore = new ExternalResourceLogStore(this);
    this.faultStore = new FaultStore(this);
    this.faultCollectionStore = new FaultCollectionStore(this);
    this.flashMessageStore = new FlashMessageStore(this);
    this.gatewayStore = new GatewayStore(this);
    this.hierarchyNavigationStore = new HierarchyNavigationStore(this);
    this.hierarchyStore = new HierarchyStore(this);
    this.ingredientStore = new IngredientStore(this);
    this.inspectionEquipmentStore = new InspectionEquipmentStore(this);
    this.inspectionLotStore = new InspectionLotStore(this);
    this.inspectionPlanStore = new InspectionPlanStore(this);
    this.inspectionTaskGroupStore = new InspectionTaskGroupStore(this);
    this.inspectionTaskPendingStore = new InspectionTaskPendingStore(this);
    this.inspectionTaskResultStore = new InspectionTaskResultStore(this);
    this.inspectionTaskStore = new InspectionTaskStore(this);
    this.inspectionVerificationStore = new InspectionVerificationStore(this);
    this.interruptionClassStore = new InterruptionClassStore(this);
    this.interruptionReasonStore = new InterruptionReasonStore(this);
    this.keyPerformanceIndicatorLimitStore = new KeyPerformanceIndicatorLimitStore(this);
    this.keyPerformanceIndicatorStore = new KeyPerformanceIndicatorStore(this);
    this.keyPerformanceIndicatorTargetStore = new KeyPerformanceIndicatorTargetStore(this);
    this.languageStore = new LanguageStore(this);
    this.ldapConnectionStore = new LdapConnectionStore(this);
    this.ldapFieldMappingStore = new LdapFieldMappingStore(this);
    this.ldapGroupStore = new LdapGroupStore(this);
    this.manualStore = new ManualStore(this);
    this.materialSensorStore = new MaterialSensorStore(this);
    this.materialStore = new MaterialStore(this);
    this.messageReadStore = new MessageReadStore(this);
    this.messageStore = new MessageStore(this);
    this.mqttStore = new MqttStore();
    this.objectLockStore = new ObjectLockStore(this);
    this.officeStore = new OfficeStore(this);
    this.operationMessageStore = new OperationMessageStore(this);
    this.operationPhaseStore = new OperationPhaseStore(this);
    this.operationStateLogStore = new OperationStateLogStore(this);
    this.operationStateStore = new OperationStateStore(this);
    this.operationStore = new OperationsStore(this);
    this.orderStore = new OrderStore(this);
    this.orderTemplateStore = new OrderTemplateStore(this);
    this.performanceAnalysisStore = new PerformanceAnalysisStore();
    this.permissionStore = new PermissionStore(this);
    this.personnelLogStore = new PersonnelLogStore(this);
    this.phaseStore = new PhaseStore(this);
    this.phaseTimeoutReasonStore = new PhaseTimeoutReasonStore(this);
    this.phaseTimeoutStore = new PhaseTimeoutStore(this);
    this.popupMessageStore = new PopupMessageStore(this);
    this.preparationStore = new PreparationStore(this);
    this.printAllocationStore = new PrintAllocationStore(this);
    this.printJobLogStore = new PrintJobLogStore(this);
    this.printJobStore = new PrintJobStore(this);
    this.printLabelStore = new PrintLabelStore(this);
    this.printerStore = new PrinterStore(this);
    this.processDataConnectionStore = new ProcessDataConnectionStore(this);
    this.processDataPointStore = new ProcessDataPointStore(this);
    this.processDataSourceStore = new ProcessDataSourceStore(this);
    this.productStore = new ProductStore(this);
    this.profileStore = new ProfileStore(this);
    this.propertyStore = new CustomPropertyStore(this);
    this.qualityReportStore = new QualityReportStore(this);
    this.relevantMessagesStore = new RelevantMessagesStore(this);
    this.reportLogStore = new ReportLogStore(this);
    this.reportTypeStore = new ReportTypeStore(this);
    this.roleStore = new RoleStore(this);
    this.sensorDataLimitStore = new SensorDataLimitStore(this);
    this.sensorDataStore = new SensorDataStore(this);
    this.sensorDataTargetStore = new SensorDataTargetStore(this);
    this.sensorStore = new SensorStore(this);
    this.settingStore = new SettingStore(this);
    this.shiftBookStore = new ShiftBookStore(this);
    this.shiftStore = new ShiftStore(this);
    this.stateHistoryStore = new StateHistoryStore(this);
    this.storageAreaStore = new StorageAreaStore(this);
    this.storageUnitStore = new StorageUnitStore(this);
    this.storageUnitLevelStore = new StorageUnitLevelStore(this);
    this.storageUnitLogStore = new StorageUnitLogStore(this);
    this.terminalLayoutStore = new TerminalLayoutStore(this);
    this.terminalStore = new TerminalStore(this);
    this.transitionStore = new TransitionStore(this);
    this.transitionQueueStore = new TransitionQueueStore(this);
    this.unitOfMeasurementConversionStore = new UnitOfMeasurementConversionStore(this);
    this.unitOfMeasurementStore = new UnitOfMeasurementStore(this);
    this.userRoleStore = new UserRoleStore(this);
    this.userStore = new UserStore(this);
    this.userNotificationConfigStore = new UserNotificationConfigStore(this);
    this.userNotificationStore = new UserNotificationStore(this);
    this.validStartTransitionsStore = new ValidStartTransitionsStore(this);
    this.validTransitionsStore = new ValidTransitionsStore(this);
    this.widgetActionStore = new WidgetActionStore(this);
    this.workflowCorrectionStore = new WorkflowCorrectionStore(this);
    this.workflowStore = new WorkflowStore(this);
    this.workplaceGroupStore = new WorkplaceGroupStore(this);
    this.workplaceStateLogStore = new WorkplaceStateLogStore(this);
    this.workplaceStateStore = new WorkplaceStateStore(this);
    this.workplaceStorageRelationStore = new WorkplaceStorageRelationStore(this);
    this.workplaceStore = new WorkplaceStore(this);
    this.yieldStore = new YieldStore(this);
    this.yieldTypeStore = new YieldTypeStore(this);
  }

  clear(): void {
    // call clear on all properties (stores) that have a clear method
    // eslint-disable-next-line no-restricted-syntax
    for (const property in this) {
      if (
        property
        // eslint-disable-next-line no-prototype-builtins
        && Object.hasOwn(this, property)
        && typeof this[property] === 'object'
        // @ts-ignore TODO: fix this
        && typeof this[property].clear === 'function'
      ) {
        // @ts-ignore TODO: fix this
        this[property].clear();
      }
    }
  }
}

export const rootStoreInstance = new RootStore();
export const StoreContext = createContext(rootStoreInstance);

// Expose the store for testing purposes
if (window.Cypress) {
  // @ts-ignore
  window.rootStore = rootStoreInstance;
}
