import {Carousel, Menu} from 'antd';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {ProductionTerminalRoutes} from '../operator/routes';
import styles from './WelcomePage.module.scss';
import VideoPage from './VideoPage';
import {OfficeRoutes} from '../office/routes';
import appConfig from '../../utils/appConfig';
import logo from '../../assets/images/autexislogo.png';
import {WelcomePageFooter} from './WelcomePageFooter';

const WelcomePage = () => {
  const {t} = useTranslation();
  const { video1, video2, video3, video4} = appConfig.corporateIdentity;
  return (
    <div className={styles.background}>
      <div className={styles.overlay}>
        <Menu mode="horizontal" className={styles.header}>
          {appConfig.modules.enableProductionTerminal && (
            <Menu.Item
              key={'production-terminal'}
              data-cy={'WelcomePage-ProductionMenuItem'}
            >
              <Link to={ProductionTerminalRoutes.all()}>
                <span>{t('context.productionTerminal')}</span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item
            key={'office'}
            data-cy={'WelcomePage-OfficeMenuItem'}
          >
            <Link to={OfficeRoutes.all()}>
              <span>{t('context.office')}</span>
            </Link>
          </Menu.Item>
        </Menu>
        <div className={styles.title}>Digital Factory Suite</div>
        {!appConfig.corporateIdentity.hideWelcomeLogo && (
          <div className={styles.middleRow}>
            <img className={styles.logo} src={logo} alt="Company Logo"/>
          </div>
        )}
        <Carousel
          effect="fade"
          autoplay
          pauseOnHover={false}
          autoplaySpeed={15000}
          dots={{className: `slick-dots ${styles.slickDots}`}}
        >
          {[video1, video2, video3, video4].map((src) => (src ? <VideoPage videoSrc={src}/> : ''))}
        </Carousel>
        <WelcomePageFooter/>
      </div>
    </div>
  );
};

export default observer(WelcomePage);
