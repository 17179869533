import { makeObservable, observable } from 'mobx';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

/**
 * popup types translations:
 * t('popup.model.types.success')
 * t('popup.model.types.info')
 * t('popup.model.types.warning')
 * t('popup.model.types.error')
 */
export enum PopupType {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error'
}

export class PopupMessage extends BaseModel {
  id: number = 0;
  uuid: string = '';
  type: PopupType = PopupType.info;
  title: string = '';
  message: string = '';

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      uuid: observable,
      type: observable,
      title: observable,
      message: observable,
    });
  }
}
