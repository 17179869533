import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { KeyPerformanceIndicatorLimit } from '../models/keyPerformanceIndicatorLimit';
import { RootStore } from './rootStore';

export class KeyPerformanceIndicatorLimitStore extends EntityStore<KeyPerformanceIndicatorLimit> {
  keyPerformanceIndicatorLimits: KeyPerformanceIndicatorLimit[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'keyPerformanceIndicatorLimits', Api.keyPerformanceIndicatorLimit, KeyPerformanceIndicatorLimit);

    makeObservable(this, {
      keyPerformanceIndicatorLimits: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.keyPerformanceIndicatorStore,
        modelId: 'keyPerformanceIndicatorId',
      },
      {
        store: this.rootStore.hierarchyStore,
        modelId: 'hierarchyId',
      },
    ];
  }

  loadByKpisAndHierarchy(keyPerformanceIndicatorIds: number[], hierarchyId: number) {
    return this.loadAll({
      params: {
        keyPerformanceIndicatorId: keyPerformanceIndicatorIds,
        hierarchyId,
        includeParents: true,
      },
    });
  }

  getByKpiAndHierarchy(keyPerformanceIndicatorId: number, hierarchyId: number) {
    const hierarchyPath = this.rootStore.hierarchyStore.getPathIds(hierarchyId);
    // eslint-disable-next-line no-restricted-syntax
    for (const pathId of hierarchyPath) {
      const kpiLimit = this.keyPerformanceIndicatorLimits
        .find((limit) => limit.keyPerformanceIndicatorId === keyPerformanceIndicatorId && limit.hierarchyId === pathId);
      if (kpiLimit) {
        return kpiLimit;
      }
    }
    return undefined;
  }
}
