import { executeGet, updateEntity } from '../requests';

const resourcesName = 'v1/user-notifications';

export const readEndpoint = 'read';

export const UserNotificationsApi = {
  all: ({ ...params }) => executeGet(resourcesName, { params }),
  update: ({ id, ...entity }: any) => updateEntity(entity, `${resourcesName}/${id}/${readEndpoint}`),
};
