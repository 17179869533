import {executeGet, executePost} from '../requests';

const resourcesName = 'v1/inspection-lots';

const deactivate = 'deactivate';
const reactivate = 'reactivate';
const reload = 'reload';
export const searchEndpoint = 'v1/search-inspection-lots';

export const InspectionLotsApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  [deactivate]: ({id, reason}) => executePost(`${resourcesName}/${id}/${deactivate}?reason=${reason}`),
  [reactivate]: ({id}) => executePost(`${resourcesName}/${id}/${reactivate}`),
  // eslint-disable-next-line max-len
  reload: ({lotNo, isWorkplaceInspectionLot}) => executePost(`${resourcesName}/${reload}?lotNo=${lotNo}&isWorkplaceInspectionLot=${isWorkplaceInspectionLot}`),
  search: (params) => executeGet(`${searchEndpoint}`, {params}),
};
