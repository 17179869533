import { observable, makeObservable, computed } from 'mobx';
import { faSensorOn } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { ProcessDataSettings } from './processDataSettings';
import { RootStore } from '../stores/rootStore';

/**
 * process-data-source connection translations:
 * t('processDataSource.connection.type.READ')
 * t('processDataSource.connection.type.SUBSCRIBE')
 */
export const ProcessDataSourceConnectionTypes = Object.freeze({
  READ: 'READ',
  SUBSCRIBE: 'SUBSCRIBE',
});

export class ProcessDataSource extends BaseModel {
  id: number = 0;
  name: string = '';
  processDataConnectionId: number = 0;
  workplaceId: number = 0;
  transformer: string | null = null;
  settings: ProcessDataSettings | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      processDataConnectionId: observable,
      processDataConnection: computed,
      workplaceId: observable,
      workplace: computed,
      transformer: observable,
      settings: observable,
    });
  }

  static faIcon = faSensorOn;

  searchableProperties = ['name'];

  nestedModels = ['settings'];

  static prepareApiPayload(model: ProcessDataSource) {
    return {
      id: model.id || undefined,
      name: model.name,
      processDataConnectionId: model.processDataConnectionId,
      workplaceId: model.workplaceId,
      transformer: model.transformer,
      settings: model.settings || null,
    };
  }

  get processDataConnection() {
    return this.rootStore.processDataConnectionStore.getById(this.processDataConnectionId);
  }

  get workplace() {
    return this.rootStore.workplaceStore.getById(this.workplaceId);
  }
}
