import {Progress} from 'antd';
import {observer} from 'mobx-react-lite';
import appConfig from '../../../utils/appConfig';
import styles from './MaterialFlowWidget.module.scss';

const MaterialFlowSlot = ({ loading, layout, storageUnit }) => {
  const level = storageUnit?.capacity ? (storageUnit?.totalAmount || 0) / storageUnit.capacity
    : (Number(storageUnit?.totalAmount > 0));
  let stateColor = appConfig.quantitativeStateColors.good;
  if (level >= 0.9) {
    stateColor = appConfig.quantitativeStateColors.bad;
  } else if (level >= 0.8) {
    stateColor = appConfig.quantitativeStateColors.warning;
  }

  const height = layout === 'vertical' ? '50%' : '100%';

  return (
    <div style={{ height }}>
      <div className={styles.materialGauge}>
        <Progress
          gapDegree={90}
          strokeColor={stateColor}
          type="dashboard"
          percent={level * 100}
          format={() => `${storageUnit?.totalAmount || 0} ${storageUnit?.unitOfMeasure?.label || ''}`}
          status={loading ? 'active' : 'normal'}
        />
      </div>
    </div>
  );
};

export default observer(MaterialFlowSlot);
