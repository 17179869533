import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import FormModal from '../../shared/modal/FormModal';
import PrintJobForm from './PrintJobForm';
import { makeFormFields } from '../../shared/form/formFields';
import { useStore } from '../../../hooks/useStore';
import { PrintJob } from '../../../models/printJob';

export type EditPrintJobProps = {
  job: PrintJob;
  onCancel: () => void;
  onSuccess: (record: any) => void;
};

const EditPrintJob: React.FC<EditPrintJobProps> = ({
  job,
  onCancel,
  onSuccess,
}) => {
  const { t } = useTranslation();
  const store = useStore();
  const [fields, setFields] = useState(makeFormFields(job));

  const handleSubmit = (values: any) => store.printJobStore.update({
    id: job.id,
    documentId: job.documentId,
    operationId: job.operationId,
    printLabelId: job.printLabelId,
    state: job.state,
    ...values,
  }).then((record: any) => onSuccess && onSuccess(record))
    .catch((errors: any) => setFields(makeFormFields(job, errors.fields)));

  return (
    <FormModal title={t('printJob.edit.title')} open onCancel={onCancel}>
      <PrintJobForm
        fields={fields}
        onChange={setFields}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        saving={store.printJobStore.isMutationInProgress}
      />
    </FormModal>
  );
};

export default observer(EditPrintJob);
