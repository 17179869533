import { computed, observable, makeObservable } from 'mobx';
import { faBacon } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { getTranslation } from '../utils/translations';
import { CustomPropertyTypes } from './customPropertyDataTypes';
import { Translation } from './translations';
import { CustomPropertiesObject } from './customProperty';
import { RootStore } from '../stores/rootStore';

interface WorkflowTranslation extends Translation {
  label: string | null;
  info: string | null;
}

export class Workflow extends BaseModel {
  id: number = 0;
  name = null;
  translations: WorkflowTranslation[] = [];
  properties?: CustomPropertiesObject = undefined;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      info: computed,
      label: computed,
      translations: observable,
      properties: observable,
      updatedAt: observable,
      updatedBy: observable,
      createdAt: observable,
      createdBy: observable,
    });
  }

  static faIcon = faBacon;

  searchableProperties = ['name'];

  saveableProperties = ['name', 'properties'];

  translatedProperties = ['info', 'label'];

  customPropertyType = CustomPropertyTypes.Workflow;

  static prepareApiPayload(model: Partial<Workflow>) {
    return {
      id: model.id || undefined,
      name: model.name || null,
      properties: model.properties || null,
      translations: Workflow.convertToSavableTranslations(model),
    };
  }

  get info() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.info;
  }

  get label() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.label;
  }
}
