import { action, makeObservable, observable } from 'mobx';
import { EntityStore, LoadStrategies } from './entityStore';
import { Api } from '../middleware/api';
import { Message } from '../models/message';
import { RootStore } from './rootStore';

export class RelevantMessagesStore extends EntityStore<Message> {
  relevantMessages: Message[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'relevantMessages', Api.relevantMessages, Message);

    makeObservable(this, {
      relevantMessages: observable,
      loadRelevantMessages: action,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.orderStore,
        modelId: 'orderId',
      },
      {
        store: this.rootStore.messageReadStore,
        modelId: 'messageId',
      },
    ];
  }

  loadRelevantMessages() {
    if (!this.rootStore.workplaceStore.selectedWorkplace?.id) {
      return [];
    }
    return this.loadAllWithDependencies({
      strategy: LoadStrategies.replace,
      params: { id: this.rootStore.workplaceStore.selectedWorkplace.id },
    });
  }
}
