import { computed, observable, makeObservable } from 'mobx';
import { faList } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import i18n from '../i18n/i18n';
import { CustomPropertyTypes } from './customPropertyDataTypes';
import { displayableProperty } from './displayableProperty';
import { displayablePropertyParam } from './displayablePropertyParam';
import { sortAlphabetically } from '../components/shared/tables/sorters';
import { getTranslation } from '../utils/translations';
import { Translation } from './translations';
import { RootStore } from '../stores/rootStore';
import { CustomPropertiesObject } from './customProperty';

interface MaterialTranslation extends Translation {
  name: string | null;
}

export class Material extends BaseModel {
  id: number = 0;
  no: string = '';
  translations: MaterialTranslation[] = [];
  properties?: CustomPropertiesObject = undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: computed,
      no: observable,
      label: computed,
      translations: observable,
      properties: observable,
    });
  }

  static faIcon = faList;

  searchableProperties = ['name', 'no'];

  translatedProperties = ['name'];

  saveableProperties = ['no', 'efficiency', 'properties', 'translations'];
  customPropertyType = CustomPropertyTypes.Material;

  i18nPrefix = 'material';

  displayableProperties = [
    displayableProperty({
      key: 'name',
      title: i18n.t('material.model.attributes.name'),
      params: [displayablePropertyParam({ path: 'name' })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'no',
      title: i18n.t('material.model.attributes.no'),
      params: [displayablePropertyParam({ path: 'no' })],
      template: '{value}',
    }),
    displayableProperty({
      key: 'label',
      title: i18n.t('material.model.attributes.label'),
      params: [displayablePropertyParam({ path: 'label' })],
      template: '{value}',
      sorter: (a, b) => sortAlphabetically(a?.label, b?.label),
    }),
  ];

  get label() {
    return `${this.no} (${this.name})`;
  }

  get name() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.name;
  }

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);
    allDisplayableProperties.push(
      ...this.displayableCustomProperties(rootStore, pathPrefix, titlePrefix)
    );

    return allDisplayableProperties;
  }

  static prepareApiPayload(model: Material): Partial<Material> {
    return {
      id: model.id,
      properties: model.properties,
    };
  }
}
