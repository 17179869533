import React from 'react';
import {observer} from 'mobx-react-lite';
import Button from '../shared/buttons/Button';
import Spinner from '../shared/spinners/Spinner';

const StateTransitionButtons = ({models, loading, onClick, ...props}) => {
  if (loading) {
    return <Spinner fullWidth fullHeight/>;
  }

  return (
    <>
      {
        models.map((model) => (
          <Button
            style={{marginBottom: '12px'}}
            block
            key={model.id}
            type="primary"
            onClick={() => onClick && onClick(model)}
            hideOverflow
            {...props}
          >
            {model.label}
          </Button>
        ))
      }
    </>
  );
};

export default observer(StateTransitionButtons);
