import { observable, makeObservable } from 'mobx';
import { BaseModel } from './base';
import { superUser } from './scope';
import { RootStore } from '../stores/rootStore';

export const superUserPermission = `exec:${superUser}`;

export class Permission extends BaseModel {
  /*
   * t('permission.description.exec:custom-function')
   * t('permission.description.exec:full-workplace-selector')
   * t('permission.description.exec:inspection-overrule')
   * t('permission.description.exec:inspection-verification')
   * t('permission.description.exec:interruption-classification')
   * t('permission.description.exec:message-read')
   * t('permission.description.exec:operation-abort')
   * t('permission.description.exec:reorder-operations')
   * t('permission.description.exec:session-control')
   * t('permission.description.exec:special-task-management')
   * t('permission.description.exec:start-any-operation')
   * t('permission.description.exec:super-user')
   * t('permission.description.exec:task-activation')
   * t('permission.description.exec:task-management')
   * t('permission.description.exec:transition')
   * t('permission.description.exec:widget-action')
   * t('permission.description.read:audit-log')
   * t('permission.description.read:batch')
   * t('permission.description.read:cockpit')
   * t('permission.description.read:collaboration')
   * t('permission.description.read:config-transfer')
   * t('permission.description.read:custom-actions')
   * t('permission.description.read:custom-function')
   * t('permission.description.read:data-export-scheduler')
   * t('permission.description.read:document-management')
   * t('permission.description.read:dosage')
   * t('permission.description.read:event-log')
   * t('permission.description.read:external-resource')
   * t('permission.description.read:external-resource-log')
   * t('permission.description.read:gateway-management')
   * t('permission.description.read:hierarchy-configurator')
   * t('permission.description.read:inventory-management')
   * t('permission.description.read:key-performance-indicator-limit')
   * t('permission.description.read:material-management')
   * t('permission.description.read:module-configuration')
   * t('permission.description.read:office')
   * t('permission.description.read:office-advanced-order-management')
   * t('permission.description.read:office-batch')
   * t('permission.description.read:office-cockpit')
   * t('permission.description.read:office-collaboration')
   * t('permission.description.read:office-document-management')
   * t('permission.description.read:office-event-log')
   * t('permission.description.read:office-external-resource-log')
   * t('permission.description.read:office-master-data')
   * t('permission.description.read:office-order-management')
   * t('permission.description.read:office-performance-analysis')
   * t('permission.description.read:office-plant-cockpit')
   * t('permission.description.read:office-print-management')
   * t('permission.description.read:office-report-log')
   * t('permission.description.read:office-task-management')
   * t('permission.description.read:office-workflow-corrections')
   * t('permission.description.read:order-management')
   * t('permission.description.read:performance-analysis')
   * t('permission.description.read:personnel-management')
   * t('permission.description.read:platform-configurator')
   * t('permission.description.read:print-management')
   * t('permission.description.read:processdata-collection')
   * t('permission.description.read:quality-report')
   * t('permission.description.read:report-log')
   * t('permission.description.read:sensor-data-limit')
   * t('permission.description.read:sensor-queue-item')
   * t('permission.description.read:shift-management')
   * t('permission.description.read:task-management')
   * t('permission.description.read:terminal')
   * t('permission.description.read:transition')
   * t('permission.description.read:user-management')
   * t('permission.description.read:widget-action')
   * t('permission.description.read:workflow')
   * t('permission.description.read:workflow-corrections')
   * t('permission.description.write:audit-log')
   * t('permission.description.write:batch')
   * t('permission.description.write:cockpit')
   * t('permission.description.write:collaboration')
   * t('permission.description.write:config-transfer')
   * t('permission.description.write:custom-actions')
   * t('permission.description.write:custom-function')
   * t('permission.description.write:data-export-scheduler')
   * t('permission.description.write:document-management')
   * t('permission.description.write:dosage')
   * t('permission.description.write:event-log')
   * t('permission.description.write:external-resource')
   * t('permission.description.write:external-resource-log')
   * t('permission.description.write:gateway-management')
   * t('permission.description.write:hierarchy-configurator')
   * t('permission.description.write:inspection-verification-config')
   * t('permission.description.write:inventory-management')
   * t('permission.description.write:key-performance-indicator-limit')
   * t('permission.description.write:material-management')
   * t('permission.description.write:module-configuration')
   * t('permission.description.write:object-locks')
   * t('permission.description.write:order-management')
   * t('permission.description.write:performance-analysis')
   * t('permission.description.write:personnel-management')
   * t('permission.description.write:platform-configurator')
   * t('permission.description.write:print-management')
   * t('permission.description.write:processdata-collection')
   * t('permission.description.write:quality-report')
   * t('permission.description.write:report-log')
   * t('permission.description.write:sensor-data-limit')
   * t('permission.description.write:sensor-queue-item')
   * t('permission.description.write:shift-management')
   * t('permission.description.write:task-management')
   * t('permission.description.write:terminal')
   * t('permission.description.write:transition')
   * t('permission.description.write:user-management')
   * t('permission.description.write:widget-action')
   * t('permission.description.write:workflow')
   * t('permission.description.write:workflow-corrections')
   */
  identifier: string = '';
  description: string = '';

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      identifier: observable,
      description: observable,
    });
  }

  searchableProperties = ['identifier'];
}
