import {createEntity, executeDelete, executeGet, executePost, updateEntity} from '../requests';

const resourcesName = 'v1/cockpit-widgets';

export const clone = 'clone';

export const CockpitWidgetsApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...values}) => updateEntity(values, `${resourcesName}/${id}`),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
  [clone]: (id, params) => executePost(`${resourcesName}/${id}/${clone}`, params),
};
