import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './locales/en.json';
import de from './locales/de.json';
import fr from './locales/fr.json';
import it from './locales/it.json';
import pl from './locales/pl.json';
import appConfig from '../utils/appConfig';

const english = 'en';
const german = 'de';
const french = 'fr';
const italian = 'it';
const polish = 'pl';
export const AvailableLanguages = [english, german, french, italian, polish];

i18n
  .use(initReactI18next)// passes i18n down to react-i18next
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
      fr: {
        translation: fr,
      },
      it: {
        translation: it,
      },
      pl: {
        translation: pl,
      },
    },
    load: 'all',
    fallbackLng: english,
    debug: process.env.NODE_ENV !== 'production',
    whitelist: AvailableLanguages,
    nonExplicitWhitelist: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    nsSeparator: '-::-',
    returnObjects: true,
    lng: appConfig.language,
  });

export default i18n;
