import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import InspectionTaskDescription from './InspectionTaskDescription';
import Button from '../../../shared/buttons/Button';

const QuantitativeSettingSection = ({canEdit, fallback, task, setQuantitativeTargetValueModalVisible}) => {
  const {t} = useTranslation();

  return (
    <>
      <InspectionTaskDescription title={t('inspectionTask.drilldownModal.section.quantitative')}>
        <InspectionTaskDescription.Item
          label={t('inspectionTaskQuantitative.model.attributes.desiredValue.label')}
        >
          {fallback(task.quantitative.desiredValue)}
        </InspectionTaskDescription.Item>
        <InspectionTaskDescription.Item
          label={t('inspectionTaskQuantitative.model.attributes.unit.label')}
        >
          {fallback(task.quantitative.unit)}
          {task.quantitative.unitText ? ` (${task.quantitative.unitText})` : ''}
        </InspectionTaskDescription.Item>
        <InspectionTaskDescription.Item
          label={t('inspectionTaskQuantitative.model.attributes.decimalPlaces.label')}
        >
          {fallback(task.quantitative.decimalPlaces)}
        </InspectionTaskDescription.Item>
        <InspectionTaskDescription.Item
          label={t('inspectionTaskQuantitative.model.attributes.plausibleLow.label')}
        >
          {fallback(task.quantitative.plausibleLow)}
        </InspectionTaskDescription.Item>
        <InspectionTaskDescription.Item
          label={t('inspectionTaskQuantitative.model.attributes.warningLow.label')}
        >
          {fallback(task.quantitative.warningLow)}
        </InspectionTaskDescription.Item>
        <InspectionTaskDescription.Item
          label={t('inspectionTaskQuantitative.model.attributes.toleranceLow.label')}
        >
          {fallback(task.quantitative.toleranceLow)}
        </InspectionTaskDescription.Item>
        <InspectionTaskDescription.Item
          label={t('inspectionTaskQuantitative.model.attributes.toleranceHigh.label')}
        >
          {fallback(task.quantitative.toleranceHigh)}
        </InspectionTaskDescription.Item>
        <InspectionTaskDescription.Item
          label={t('inspectionTaskQuantitative.model.attributes.warningHigh.label')}
        >
          {fallback(task.quantitative.warningHigh)}
        </InspectionTaskDescription.Item>
        <InspectionTaskDescription.Item
          label={t('inspectionTaskQuantitative.model.attributes.plausibleHigh.label')}
        >
          {fallback(task.quantitative.plausibleHigh)}
        </InspectionTaskDescription.Item>
        <InspectionTaskDescription.Item
          label={t('inspectionTaskQuantitative.model.attributes.calculatedCustomFunctionId.label')}
        >
          {fallback(task.quantitative.calculatedCustomFunction?.label)}
        </InspectionTaskDescription.Item>
      </InspectionTaskDescription>
      <Button disabled={!canEdit} onClick={() => setQuantitativeTargetValueModalVisible(true)}>
        {t('inspectionTask.actions.editTargetValues')}
      </Button>
    </>
  );
};

export default observer(QuantitativeSettingSection);
