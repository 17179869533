import { action, makeObservable, observable, runInAction } from 'mobx';
import jwtDecode, { JwtPayload as BaseJwtPayload } from 'jwt-decode';
import dayjs from 'dayjs';
import i18n from '../i18n/i18n';
import { Api } from '../middleware/api';
import { User } from '../models/user';
import appConfig from '../utils/appConfig';
import { Scope } from '../models/scope';
import { ModulePolicy } from '../policies/modulePolicy';
import { RootStore } from './rootStore';
import { Scopes } from '../models/scopes';

export const TOKEN_STORAGE_KEY = 'auth-token';

interface JwtPayload extends BaseJwtPayload {
  id: number;
  username: string;
  roleIds: number[];
  scopes: string[];
}

interface CheckCompleted {
  success: boolean;
  message?: string;
  userId: null | number;
}

interface LoginResponse {
  user: Partial<User>;
  token: string;
}

export class AuthStore {
  rootStore: RootStore;
  user: User | null = null;
  timeout: ReturnType<typeof setTimeout> | null = null;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      user: observable,
      timeout: observable,
      setCurrentUser: action,
      clearUser: action,
      login: action,
      terminalLogin: action,
      logout: action,
      updateAuthToken: action,
      validateToken: action,
      setRefreshTimeout: action,
      clearRefreshTimeout: action,
    });

    this.rootStore = rootStore;
    this.updateAuthToken(true);

    window.addEventListener('storage', (event) => {
      if (event.key === TOKEN_STORAGE_KEY && event.oldValue !== event.newValue) {
        if (event.newValue) {
          const decodedToken = jwtDecode<JwtPayload>(event.newValue);
          if (this.validateToken(decodedToken)) {
            if (this.user?.id !== Number(decodedToken.id)) {
              this.clearRefreshTimeout();
              this.rootStore.userStore.load(decodedToken.id).then((user: User) => {
                this.setCurrentUser({ user, token: event.newValue as string }, false);
              }).catch(() => {
                this.clearUser();
              });
            } else {
              this.setRefreshTimeout(decodedToken, 2000);
            }
          }
        } else if (this.user) {
          this.clearUser();
        }
      }
    });
  }

  setCurrentUser({ user, token }: LoginResponse, updateStorage = true) {
    this.user = User.fromPlainObject<User>(user, this.rootStore, true);
    if (updateStorage) {
      localStorage.setItem(TOKEN_STORAGE_KEY, token);
    }
    const decodedToken = jwtDecode<JwtPayload>(token);

    if (this.validateToken(decodedToken)) {
      this.user.setRoleIds(decodedToken.roleIds);
      this.user.setScopes(decodedToken.scopes);

      this.setRefreshTimeout(decodedToken);
      this.rootStore.profileStore.loadProfile(this.user.id);
    }
  }

  guestLogin() {
    if (!appConfig.guestLoginToken) {
      return {};
    }
    return Api.auth.guestLogin({ token: appConfig.guestLoginToken }).then(({ data }) => {
      runInAction(() => {
        this.setCurrentUser(data);
      });
    });
  }

  login(user: Pick<User, 'username' | 'password'>) {
    return Api.auth.login(user).then(({ data }) => {
      runInAction(() => {
        this.setCurrentUser(data);
      });
    });
  }

  terminalLogin(data: LoginResponse) {
    this.setCurrentUser(data);
  }

  async checkRequiredRolePermissions(
    {
      requiredRoleId,
      authenticate,
      onCheckCompleted,
      isOverrule,
    }: {
      requiredRoleId: number;
      authenticate: () => Promise<LoginResponse>;
      onCheckCompleted: (result: CheckCompleted) => void;
      isOverrule: boolean;
    }
  ) {
    const { user, token } = await authenticate();
    const decodedJwt = jwtDecode<JwtPayload>(token);
    const { roleIds } = decodedJwt;
    const scopes = decodedJwt.scopes?.map((scope) => new Scope(scope)) || [];
    const userModel = User.fromPlainObject<User>({ ...user, scopes }, this.rootStore, true);
    const policy = new ModulePolicy(userModel, [Scopes.inspectionOverrule]);

    if (this.user?.id === user.id) {
      onCheckCompleted({
        success: false,
        message: i18n.t<string>('inspectionTaskPendingModal.taskBox.differentUserRequired'),
        userId: null,
      });
      return Promise.resolve();
    }

    // @ts-ignore
    // eslint-disable-next-line max-len
    if (isOverrule && !policy.canExecute({ hierarchyId: this.rootStore.workplaceStore.selectedWorkplace ? this.rootStore.workplaceStore.selectedWorkplace.hierarchyId : undefined })) {
      onCheckCompleted({
        success: false,
        message: i18n.t<string>('inspectionTaskPendingModal.taskBox.overrulePermissionMissing'),
        userId: null,
      });
      return Promise.resolve();
    }

    if (requiredRoleId) {
      if (!roleIds.includes(requiredRoleId) && !policy.isSuperUser()) {
        onCheckCompleted({
          success: false,
          message: i18n.t<string>('inspectionTaskPendingModal.taskBox.requiredRoleMissing', { requiredRoleId }),
          userId: null,
        });
        return Promise.resolve();
      }
    }
    onCheckCompleted({
      success: true,
      userId: userModel.id,
    });
    return Promise.resolve();
  }

  logout(manualLogout = false) {
    if (this.user) {
      Api.auth.logout({ username: this.user.username }).finally(() => {
        this.clearUser(manualLogout);
        window.location.reload();
      });
    }
  }

  clearUser(manualLogout = false) {
    this.clearRefreshTimeout();
    runInAction(() => {
      localStorage.removeItem(TOKEN_STORAGE_KEY);
      this.user = null;
      if (manualLogout) {
        this.guestLogin();
      }
    });
  }

  updateAuthToken(init = false) {
    Api.auth.refreshToken().then(({ data }) => {
      runInAction(() => {
        this.setCurrentUser(data);
      });
    }).catch(() => {
      this.clearUser(init);
    });
  }

  refreshAuthToken() {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);
    if (token) {
      Api.auth.refreshToken().then(({ data }) => {
        runInAction(() => {
          this.setCurrentUser(data);
        });
      }).catch(() => {
        this.clearUser();
      });
    } else {
      this.clearUser();
    }
  }

  validateStorageToken() {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);
    if (!token) {
      return false;
    }
    const decodedToken = jwtDecode<JwtPayload>(token);
    return this.validateToken(decodedToken);
  }

  validateToken(decodedToken: JwtPayload) {
    let isValid = !!decodedToken;
    if (decodedToken && decodedToken.exp) {
      isValid = dayjs(decodedToken.exp * 1000).isAfter(new Date());
    }
    if (!isValid) {
      this.clearUser();
    }
    return isValid;
  }

  setRefreshTimeout(decodedToken: JwtPayload, additionalTimeout = 0) {
    if (!decodedToken.exp) {
      return;
    }
    this.clearRefreshTimeout();
    let timeoutMs = dayjs(dayjs(decodedToken.exp * 1000)).diff(new Date());
    timeoutMs -= 10000 - additionalTimeout; // Remove 10 seconds to ensure refresh happens in time.
    const notThis = this;
    this.timeout = setTimeout(() => notThis.refreshAuthToken(), Math.max(timeoutMs, 0));
  }

  clearRefreshTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getRequestHeaders() {
    const token = localStorage.getItem(TOKEN_STORAGE_KEY);
    if (!token) {
      return {};
    }
    return {
      Authorization: `Bearer ${token}`,
    };
  }
}
