import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import {round} from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalculator} from '@fortawesome/free-solid-svg-icons';
import {useStore} from '../../../../hooks/useStore';
import {useMount} from '../../../../hooks/useMount';
import Speedometer from '../../../shared/charts/Speedometer';
import Spinner from '../../../shared/spinners/Spinner';
import styles from './KpiSpeedometer.module.scss';
import {findNotNil} from '../../../../utils/helpers';

const KpiSpeedometer = ({workplaceId, kpiId, minutesBack}) => {
  const store = useStore();
  const [actualData, setActualData] = useState();
  const [kpi, setKpi] = useState();
  const [unitOfMeasurement, setUnitOfMeasurement] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [oeeComponents, setOeeComponents] = useState();

  const getLimitByKpiId = (id) => {
    const workplace = store.workplaceStore.getById(workplaceId);
    return store.keyPerformanceIndicatorLimitStore.getByKpiAndHierarchy(id, workplace?.hierarchyId);
  };

  useMount(() => {
    store.keyPerformanceIndicatorStore.loadAll();
  });

  useMount(() => {
    /** @type {KeyPerformanceIndicator | undefined} */
    const newKpi = store.keyPerformanceIndicatorStore.getById(kpiId);
    setKpi(newKpi);
  }, [kpiId, store.keyPerformanceIndicatorStore.kpis.length]);

  useMount(() => {
    const workplace = store.workplaceStore.getById(workplaceId);
    if (workplace?.hierarchyId && kpiId) {
      store.keyPerformanceIndicatorLimitStore
        .loadByKpisAndHierarchy([kpiId], workplace.hierarchyId);
    }
  }, [workplaceId, kpiId]);

  useMount(() => {
    (async () => {
      if (!kpi) {
        return;
      }

      setIsLoading(true);
      let uom;
      if (kpi.unitOfMeasureId) {
        uom = await store.unitOfMeasurementStore.getById(kpi.unitOfMeasureId);
        setUnitOfMeasurement(uom);
      } else {
        setUnitOfMeasurement(undefined);
      }

      const toDate = dayjs().toISOString();
      const fromDate = dayjs().subtract(minutesBack, 'minutes').toISOString();

      if (workplaceId && minutesBack) {
        const actualResponse = await store.keyPerformanceIndicatorStore.calculate({
          id: kpiId,
          workplaceId,
          fromDate,
          toDate,
        });
        let actualResponseData = actualResponse?.data?.value;

        if (actualResponseData !== undefined) {
          actualResponseData = kpi?.calculateToPercentageValue(actualResponseData) || actualResponseData;
          setActualData(actualResponseData);
        }

        if (kpi.name === 'OEE' && actualResponse.data?.kpiComponents) {
          const oeeComp = [];

          const workplace = store.workplaceStore.getById(workplaceId);

          // eslint-disable-next-line no-restricted-syntax
          for (const [kpiName, data] of Object.entries(actualResponse.data.kpiComponents)) {
            const kpiComp = store.keyPerformanceIndicatorStore.kpis.find((k) => k.name === kpiName);
            const unit = store.unitOfMeasurementStore.getById(data.unitOfMeasureId);
            let {value} = data;
            value = unit?.calculateToPercentageValue(value) || value;
            // eslint-disable-next-line no-await-in-loop
            await store.keyPerformanceIndicatorLimitStore.loadByKpisAndHierarchy(kpiComp.id, workplace.hierarchyId);
            oeeComp.push({
              ...data,
              kpi: kpiComp,
              lim: getLimitByKpiId(kpiComp.id),
              unit,
              value,
            });
            if (oeeComp.length > 2) {
              break;
            }
          }
          setOeeComponents(oeeComp);
        } else {
          setOeeComponents(null);
        }
      }
      setIsLoading(false);
    })();
  }, [kpi, workplaceId, minutesBack]);

  const SpeedometerWrapper = ({val, lim, label, unit}) => (
    <>
      <div className={styles.kpiLabel}>
        <FontAwesomeIcon
          icon={faCalculator}
          style={{marginRight: '10px'}}
        />
        {label || ''}
      </div>
      <div className={styles.kpiValue}>
        <div className={styles.kpiMiniSpeedometerWrapper}>
          <Speedometer
            width={200}
            height={130}
            value={val !== undefined ? round(val, 2) : val}
            minValue={findNotNil(lim?.plausibleLow, lim?.toleranceLow, lim?.warningLow)}
            maxValue={findNotNil(lim?.plausibleHigh, lim?.toleranceHigh, lim?.warningHigh)}
            toleranceLow={lim?.toleranceLow}
            toleranceHigh={lim?.toleranceHigh}
            warningLow={lim?.warningLow}
            warningHigh={lim?.warningHigh}
            unit={unit}
            labelsEnabled={false}
          />
        </div>
      </div>
    </>
  );

  const OEESpeedometerWrapper = ({val, lim, unit, label, comps}) => (
    <div className={styles.kpiOEESpeedometerWrapperRow}>
      <div className={styles.kpiOEESpeedometerWrapperColumn} style={{marginTop: '80px'}}>
        <Speedometer
          width={300}
          height={195}
          value={val}
          minValue={findNotNil(lim?.plausibleLow, lim?.toleranceLow, lim?.warningLow)}
          maxValue={findNotNil(lim?.plausibleHigh, lim?.toleranceHigh, lim?.warningHigh)}
          toleranceLow={lim?.toleranceLow}
          toleranceHigh={lim?.toleranceHigh}
          warningLow={lim?.warningLow}
          warningHigh={lim?.warningHigh}
          unit={unit}
          labelsEnabled={false}
          dataLabelEnabled={false}
          bandThickness={50}
        />
        <div className={styles.kpiOEESpeedometerWrapperColumn} style={{textAlign: 'center'}}>
          {val !== undefined ? round(val, 2) : val}
          {unit}
          <br/>
          {label}
        </div>
      </div>
      <div className={styles.kpiOEESpeedometerWrapperColumn} style={{marginTop: '20px', alignItems: 'start'}}>
        {comps?.map((comp) => (
          <div className={styles.kpiMiniSpeedometerWrapper}>
            <div className={styles.kpiOEESpeedometerWrapperRow}>
              <div className={styles.kpiOEESpeedometerWrapperColumn}>
                <Speedometer
                  width={180}
                  height={117}
                  value={comp.value}
                  minValue={findNotNil(comp.lim?.plausibleLow, comp.lim?.toleranceLow, comp.lim?.warningLow)}
                  maxValue={findNotNil(comp.lim?.plausibleHigh, comp.lim?.toleranceHigh, comp.lim?.warningHigh)}
                  toleranceLow={comp.lim?.toleranceLow}
                  toleranceHigh={comp.lim?.toleranceHigh}
                  warningLow={comp.lim?.warningLow}
                  warningHigh={comp.lim?.warningHigh}
                  unit={comp.unit}
                  labelsEnabled={false}
                  dataLabelEnabled={false}
                />
              </div>
              <div className={styles.kpiOEESpeedometerWrapperColumn} style={{marginTop: '20px', marginLeft: '20px'}}>
                {comp.value !== undefined ? round(comp.value, 2) : comp.value}
                {comp.unit?.label}
                <br/>
                {comp.kpi.name}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return isLoading ? (<Spinner fullWidth fullHeight/>) : (
    <>
      {!oeeComponents && (
        <SpeedometerWrapper
          val={actualData}
          lim={getLimitByKpiId(kpiId)}
          unit={unitOfMeasurement?.label}
          label={store.keyPerformanceIndicatorStore.getById(kpiId)?.name}
        />
      )}
      {oeeComponents && (
        <OEESpeedometerWrapper
          val={actualData}
          lim={getLimitByKpiId(kpiId)}
          unit={unitOfMeasurement?.label}
          label={store.keyPerformanceIndicatorStore.getById(kpiId)?.name}
          comps={oeeComponents}
        />
      )}
    </>
  );
};

export default observer(KpiSpeedometer);
