/**
 * @param name name of the shape
 * @param color
 * @returns {string} returns the symbol by shape name as html text
 */
export const getSymbolByNameAsHtml = (name, color) => {
  switch (name) {
    case 'diamond':
      return `<span style="color: ${color}">♦</span>`;
    case 'square':
      return `<span style="color: ${color}">■</span>`;
    case 'triangle':
      return `<span style="color: ${color}">▲</span>`;
    case 'triangle-down':
      return `<span style="color: ${color}">▼</span>`;
    default:
      return `<span style="color: ${color}">●</span>`;
  }
};
