import { computed, makeObservable, observable } from 'mobx';
import { faSensor } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { BaseModel } from './base';
import { SensorType } from './sensor';
import { RootStore } from '../stores/rootStore';
import { ProcessDataSettings } from './processDataSettings';
import { AddMessageOptions } from '../stores/entityStore';
import { TransitionFilters } from '../stores/processDataPointStore';

/**
 * process-data-point side-effect types translations:
 * t('processDataPoint.sideEffects.DEFAULT')
 * t('processDataPoint.sideEffects.RAW')
 * t('processDataPoint.sideEffects.DIFF')
 * t('processDataPoint.sideEffects.THRESHOLD_RELATIVE')
 * t('processDataPoint.sideEffects.THRESHOLD_ABSOLUTE')
 * t('processDataPoint.sideEffects.GREATER_THAN')
 * t('processDataPoint.sideEffects.GREATER_THAN_EQUAL')
 * t('processDataPoint.sideEffects.EQUAL_TO')
 * t('processDataPoint.sideEffects.LESS_THAN_EQUAL')
 * t('processDataPoint.sideEffects.LESS_THAN')
 * t('processDataPoint.sideEffects.NOT_EQUAL')
 * t('processDataPoint.sideEffects.BETWEEN')
 */
export enum ProcessDataPointSideEffect {
  DEFAULT = 'DEFAULT', // default()
  RAW = 'RAW', // raw()
  DIFF = 'DIFF', // diff()
  THRESHOLD_RELATIVE = 'THRESHOLD_RELATIVE', // thresholdRelativePercent(x)
  THRESHOLD_ABSOLUTE = 'THRESHOLD_ABSOLUTE', // thresholdAbsoluteValue(x)
  GREATER_THAN = 'GREATER_THAN', // greaterThan(x)
  GREATER_THAN_EQUAL = 'GREATER_THAN_EQUAL', // greaterThanOrEqualTo(x)
  EQUAL_TO = 'EQUAL_TO', // equalTo(x)
  LESS_THAN_EQUAL = 'LESS_THAN_EQUAL', // lessThanOrEqual(x)
  LESS_THAN = 'LESS_THAN', // lessThan(x)
  NOT_EQUAL = 'NOT_EQUAL', // NotEqualTo(x)
  BETWEEN = 'BETWEEN' // between(x,y)
}

/**
 * process-data-point matrix translations:
 * t('processDataPoint.matrix.MACHINE_STATE')
 */
export enum ProcessDataSourceMatrix {
  MACHINE_STATE = 'MACHINE_STATE'
}

export const ProcessDataPointTypes = {
  ...SensorType,
  ...ProcessDataSourceMatrix,
};

export function useTransitionFilter() {
  const { t } = useTranslation();

  return [
    {
      label: t('processDataPoint.model.attributes.settings.transitionFilter.without'),
      value: TransitionFilters.WITHOUT,
    },
    { label: t('processDataPoint.model.attributes.settings.transitionFilter.user'), value: TransitionFilters.USER },
    { label: t('processDataPoint.model.attributes.settings.transitionFilter.system'), value: TransitionFilters.SYSTEM },
  ];
}

export type ProcessDataSourceType = ProcessDataSourceMatrix | SensorType;

export const getProcessDataPointTypeTranslationPath = (value: string) => {
  if (Object.values(ProcessDataSourceMatrix).includes(value as ProcessDataSourceMatrix)) {
    return `processDataPoint.matrix.${value}`;
  }
  return `sensor.types.${value}`;
};

export const SideEffectParameters = Object.freeze({
  [ProcessDataPointSideEffect.DEFAULT]: 0,
  [ProcessDataPointSideEffect.RAW]: 0,
  [ProcessDataPointSideEffect.DIFF]: 0,
  [ProcessDataPointSideEffect.THRESHOLD_RELATIVE]: 1,
  [ProcessDataPointSideEffect.THRESHOLD_ABSOLUTE]: 1,
  [ProcessDataPointSideEffect.GREATER_THAN]: 1,
  [ProcessDataPointSideEffect.GREATER_THAN_EQUAL]: 1,
  [ProcessDataPointSideEffect.EQUAL_TO]: 1,
  [ProcessDataPointSideEffect.LESS_THAN_EQUAL]: 1,
  [ProcessDataPointSideEffect.LESS_THAN]: 1,
  [ProcessDataPointSideEffect.NOT_EQUAL]: 1,
  [ProcessDataPointSideEffect.BETWEEN]: 2,
});

export type SideEffectParameterType = string[];

export interface ProcessDataBulkImportRequestData {
  file: File;
  deleteAllDatapoints: boolean;
  processDataSourceId: number;
}

// TODO: add typings
export interface ProcessDataBulkImportRequestOptions extends AddMessageOptions {
  params?: any;
  raw?: any;
}

export class ProcessDataPoint extends BaseModel {
  id: number = 0;
  name: string = '';
  description: string | null = null;
  type: ProcessDataSourceType = SensorType.DEFAULT;
  processDataSourceId: number = 0;
  isDefault: boolean = false;
  isActive: boolean = false;
  decimalPlaces: number | null = null;
  isSensorNamePathString: boolean = false;
  sensorNamePath: string | null = null;
  sensorNamePathType: string | null = null;
  valuePath: string | null = null;
  valuePathType: string | null = null;
  orderNoPath: string | null = null;
  orderNoPathType: string | null = null;
  timestampPath: string | null = null;
  timestampPathType: string | null = null;
  sensorUnitOfMeasureId: number | null = null;
  sideEffect: ProcessDataPointSideEffect | null = null;
  sideEffectParameters: SideEffectParameterType | null = null;
  minimalWriteRate: number | null = null;
  settings: ProcessDataSettings | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      description: observable,
      type: observable,
      processDataSourceId: observable,
      processDataSource: computed,
      isDefault: observable,
      isActive: observable,
      decimalPlaces: observable,
      sensorNamePath: observable,
      isSensorNamePathString: observable,
      sensorNamePathType: observable,
      valuePath: observable,
      valuePathType: observable,
      orderNoPath: observable,
      orderNoPathType: observable,
      timestampPath: observable,
      timestampPathType: observable,
      sensorUnitOfMeasureId: observable,
      sensorUnitOfMeasurement: computed,
      sideEffect: observable,
      sideEffectParameters: observable,
      minimalWriteRate: observable,
      settings: observable,
    });
  }

  static faIcon = faSensor;

  searchableProperties = ['name'];

  nestedModels = ['sideEffectParameters', 'settings'];

  get processDataSource() {
    return this.rootStore.processDataSourceStore.getById(this.processDataSourceId);
  }

  get sensorUnitOfMeasurement() {
    return this.rootStore.unitOfMeasurementStore.getById(this.sensorUnitOfMeasureId);
  }

  static prepareApiPayload(model: ProcessDataPoint) {
    return {
      id: model.id || undefined,
      name: model.name,
      description: model.description || null,
      type: model.type,
      processDataSourceId: model.processDataSourceId,
      isDefault: model.isDefault || false,
      isActive: model.isActive || false,
      decimalPlaces: model.decimalPlaces || null,
      sensorNamePath: model.sensorNamePath || null,
      isSensorNamePathString: model.isSensorNamePathString || false,
      sensorNamePathType: model.sensorNamePathType || null,
      valuePath: model.valuePath || null,
      valuePathType: model.valuePathType || null,
      orderNoPath: model.orderNoPath || null,
      orderNoPathType: model.orderNoPathType || null,
      timestampPath: model.timestampPath || null,
      timestampPathType: model.timestampPathType || null,
      sensorUnitOfMeasureId: model.sensorUnitOfMeasureId || null,
      sideEffect: model.sideEffect || null,
      sideEffectParameters: model.sideEffectParameters || null,
      minimalWriteRate: model.minimalWriteRate || null,
      settings: model.settings || null,
    };
  }
}
