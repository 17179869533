import {BaseWidgetConfig} from '../../../../shared/widgets/baseWidgetConfig';
import i18n from '../../../../../i18n/i18n';
import {ValidationRules} from '../../../../../utils/validationRules';
import {iconSelectOptions} from './cockpitWidgetIconConfig';
import {FieldType} from '../../../../../types/widgetConfig';

export class CockpitWidgetConfig extends BaseWidgetConfig {
  constructor({rootStore, widgetTypeIdentifier, widgetId}) {
    super(rootStore, widgetTypeIdentifier, () => rootStore.cockpitWidgetStore.getById(widgetId));
  }

  generalSettingsTab = {
    key: 'generalSettings',
    label: i18n.t('cockpitWidget.AddWidgetWizardModal.GeneralSettings'),
  };

  workplaceTab = {
    key: 'workplace',
    label: i18n.t('widget.WidgetSettingsModal.workplaceConfigTabTitle'),
  };

  generalSettings = [
    {
      id: 'name',
      label: i18n.t('widget.settings.title.label'),
      helpText: i18n.t('widget.settings.title.help'),
      type: FieldType.String,
      validations: [ValidationRules.required()],
    },
    {
      id: 'icon',
      label: i18n.t('cockpitWidget.settings.icon.label'),
      helpText: i18n.t('cockpitWidget.settings.icon.help'),
      type: FieldType.Select,
      options: iconSelectOptions,
      allowClear: true,
    },
  ];

  workplace = [
    {
      id: 'hierarchyId',
      label: i18n.t('cockpitWidget.settings.workplace.label'),
      helpText: i18n.t('cockpitWidget.settings.workplace.help'),
      type: FieldType.HierarchyTreeWorkplace,
    },
  ];

  getRelevantWorkplaceId() {
    const configuredWorkplace = this.rootStore.workplaceStore.workplaces.find((workplace) =>
      workplace.hierarchyId === this.getSettingValue('workplace', 'hierarchyId'));
    return configuredWorkplace?.id || this.rootStore.cockpitStore.currentWorkplaceId;
  }
}
