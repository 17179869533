import {CockpitWidgetConfig} from './cockpitWidgetConfig';
import i18n from '../../../../../i18n/i18n';
import {ValidationRules} from '../../../../../utils/validationRules';
import {AggregationOptions, TimeframeOptions} from './timeframeAggregationOptions';
import {LegendPositionOptionTypes} from '../../../../../types/charts';
import {FieldType} from '../../../../../types/widgetConfig';

export class KpiChartCockpitWidgetConfig extends CockpitWidgetConfig {
  static onLoadConfig = async (rootStore) => rootStore.keyPerformanceIndicatorStore.loadAll();

  static widgetType = {
    identifier: 'kpi-chart',
    name: () => i18n.t('cockpitWidget.widgetTypeIdentifier.kpiChart'),
  };

  // eslint-disable-next-line class-methods-use-this
  getWidgetTypeName() {
    return i18n.t('cockpitWidget.widgetTypeIdentifier.kpiChart');
  }

  get tabs() {
    return [
      this.generalSettingsTab,
      this.workplaceTab,
      {
        key: 'kpi',
        label: i18n.t('widget.WidgetSettingsModal.kpiChartConfigTabTitle'),
      },
    ];
  }

  workplace = [
    {
      id: 'hierarchyId',
      label: i18n.t('cockpitWidget.settings.workplace.label'),
      helpText: i18n.t('cockpitWidget.settings.workplace.help'),
      type: FieldType.HierarchyTreeWorkplace,
    },
  ];

  kpi = [
    {
      id: 'kpis',
      label: i18n.t('kpiChartCockpitWidgetConfig.settings.kpi.kpis.label'),
      type: FieldType.SelectMultiple,
      options: this.rootStore.keyPerformanceIndicatorStore.kpis.map((kpi) => ({
        value: kpi.id,
        label: kpi.name,
        group: {
          key: kpi.unitOfMeasureId || -1,
          label: kpi.unitOfMeasure?.label
            || i18n.t('kpiChartCockpitWidgetConfig.settings.kpiLoss.kpis.noUnitOfMeasure'),
        },
      })),
      validations: [ValidationRules.required()],
    },
    {
      id: 'timeframe',
      label: i18n.t('kpiChartCockpitWidgetConfig.settings.kpi.timeframe.label'),
      helpText: i18n.t('kpiChartCockpitWidgetConfig.settings.kpi.timeframe.helpText'),
      type: FieldType.Select,
      options: Object.values(TimeframeOptions).map((o) => ({
        value: o,
        label: i18n.t(`cockpitWidget.settings.timeframe.${o}`),
      })),
      validations: [ValidationRules.required()],
    },
    {
      id: 'aggregation',
      label: i18n.t('kpiChartCockpitWidgetConfig.settings.kpi.aggregation.label'),
      helpText: i18n.t('kpiChartCockpitWidgetConfig.settings.kpi.aggregation.helpText'),
      type: FieldType.Select,
      options: Object.values(AggregationOptions).map((o) => ({
        value: o,
        label: i18n.t(`cockpitWidget.settings.aggregation.${o}`),
      })),
      validations: [ValidationRules.required()],
    },
    {
      id: 'daysBack',
      label: i18n.t('kpiChartCockpitWidgetConfig.settings.kpi.daysBack.label'),
      helpText: i18n.t('kpiChartCockpitWidgetConfig.settings.kpi.daysBack.helpText'),
      type: FieldType.Number,
      unit: i18n.t('kpiChartCockpitWidgetConfig.settings.kpi.daysBack.unit'),
      defaultValue: 3,
      // validations: [ValidationRules.minNumber(1), ValidationRules.maxNumber(7)],
      validations: [
        ({getFieldValue}) => ValidationRules.requiredIf(getFieldValue('timeframe') === TimeframeOptions.DAYS_BACK),
        ValidationRules.minNumber(1),
        ValidationRules.maxNumber(7),
      ],
      disabled: ({getFieldValue}) => getFieldValue('timeframe') !== TimeframeOptions.DAYS_BACK,
      dependencies: ['timeframe'],
    },
    {
      id: 'yAxisMax',
      label: i18n.t('kpiChartCockpitWidgetConfig.settings.kpi.yAxisMax.label'),
      type: FieldType.Number,
    },
    {
      id: 'yAxisTitle',
      label: i18n.t('kpiChartCockpitWidgetConfig.settings.kpi.yAxisTitle.label'),
      type: FieldType.String,
    },
    {
      id: 'showPercent',
      label: i18n.t('kpiChartCockpitWidgetConfig.settings.kpi.showPercent.label'),
      type: FieldType.Boolean,
      defaultValue: false,
    },
    {
      id: 'legendPosition',
      label: i18n.t('kpiChartCockpitWidgetConfig.settings.kpi.legendPosition.label'),
      type: FieldType.Select,
      options: Object.keys(LegendPositionOptionTypes).map((o) => ({
        value: o,
        label: i18n.t(`kpiChartCockpitWidgetConfig.settings.kpi.legendPosition.${o}`),
      })),
      defaultValue: LegendPositionOptionTypes.bottom,
    },
  ];
}
