import {useState} from 'react';
import {clamp, omitBy, isNil, sortBy, uniq, findLastIndex} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useMount} from '../../../hooks/useMount';
import styles from './QualityIndicator.module.scss';
import appConfig from '../../../utils/appConfig';

const getIndicatorPosition = (value, borders, widths, uniform) => {
  if (uniform) {
    const bordersAscending = uniq(sortBy(Object.values(omitBy(borders, isNil))), (a, b) => a > b);
    const highIndex = bordersAscending.findIndex((v) => v >= value);
    const high = bordersAscending[highIndex];
    const lowIndex = findLastIndex(bordersAscending, (v) => v <= value);
    const low = bordersAscending[lowIndex];
    if (high === undefined) {
      return 100;
    }
    if (low === undefined) {
      return 0;
    }
    if (high === low) {
      return lowIndex * widths.uniformWidth;
    }
    return lowIndex * widths.uniformWidth + widths.uniformWidth * ((value - low) / (high - low));
  }
  return (100 * (clamp(value, borders.plausibleLow, borders.plausibleHigh) - borders.plausibleLow))
    / (borders.plausibleHigh - borders.plausibleLow);
};

const QualityIndicator = ({
  values,
  desiredValue,
  toleranceHigh,
  toleranceLow,
  warningHigh,
  warningLow,
  plausibleHigh,
  plausibleLow,
  useUniformQualityIndicator,
}) => {
  const {t} = useTranslation();
  const [widths, setWidths] = useState({
    badLow: undefined,
    warningLow: undefined,
    goodLow: undefined,
    goodHigh: undefined,
    warningHigh: undefined,
    badHigh: undefined,
  });
  const borders = {
    desiredValue,
    toleranceHigh,
    toleranceLow,
    warningHigh,
    warningLow,
    plausibleHigh,
    plausibleLow,
  };

  useMount(() => {
    const onePercent = (plausibleHigh - plausibleLow);
    const w = {
      badLow: undefined,
      warningLow: undefined,
      goodLow: undefined,
      goodHigh: undefined,
      warningHigh: undefined,
      badHigh: undefined,
    };

    let low = plausibleLow;

    if (toleranceLow !== null) {
      w.badLow = ((toleranceLow - low) / onePercent) * 100;
      low = toleranceLow;
    }

    if (warningLow !== null) {
      w.warningLow = ((warningLow - low) / onePercent) * 100;
      low = warningLow;
    }

    if (toleranceLow === null && warningLow === null) {
      w.badLow = ((desiredValue - low) / onePercent) * 100;
    } else {
      w.goodLow = ((desiredValue - low) / onePercent) * 100;
    }

    let high = plausibleHigh;

    if (toleranceHigh !== null) {
      w.badHigh = ((high - toleranceHigh) / onePercent) * 100;
      high = toleranceHigh;
    }

    if (warningHigh !== null) {
      w.warningHigh = ((high - warningHigh) / onePercent) * 100;
      high = warningHigh;
    }

    if (toleranceHigh === null && warningHigh === null) {
      w.badHigh = ((high - desiredValue) / onePercent) * 100;
    } else {
      w.goodHigh = ((high - desiredValue) / onePercent) * 100;
    }

    if (useUniformQualityIndicator) {
      const numberOfSegments = Object.values(w).filter((v) => v).length;
      Object.entries(w).forEach((range) => {
        const [key, value] = range;
        if (value > 0) {
          w[key] = (100 / numberOfSegments);
        }
      });
      w.uniformWidth = 100 / numberOfSegments;
    }

    setWidths(w);
  }, [desiredValue, toleranceHigh, toleranceLow, warningHigh, warningLow, plausibleHigh, plausibleLow]);

  if (plausibleLow === null && plausibleHigh === null) {
    return (
      <div className={styles.rangeDisplay}>
        {`${t('inspectionTaskQuantitative.model.attributes.desiredValue.label')}: ${desiredValue}`}
      </div>
    );
  }

  return (
    <div className={styles.rangeDisplay}>
      <div className={'strip'}>
        {widths.badLow > 0 && (
          <div
            className={'range'}
            style={{
              width: `${widths.badLow}%`,
              backgroundColor: appConfig.quantitativeStateColors.bad,
            }}
          >
            <div
              className={'label left'}
              style={{
                marginLeft: `-${String(plausibleLow).length * 0.3}em`,
                paddingRight: `${String(plausibleLow).length * 0.3}em`,
              }}

            >
              {plausibleLow}
            </div>
          </div>
        )}
        {widths.warningLow > 0 && (
          <div
            className={'range'}
            style={{
              width: `${widths.warningLow}%`,
              backgroundColor: appConfig.quantitativeStateColors.warning,
            }}
          >
            <div
              className={'label left'}
              style={{
                marginLeft: `-${String(toleranceLow || plausibleLow).length * 0.3}em`,
                paddingRight: `${String(toleranceLow || plausibleLow).length * 0.3}em`,
              }}
            >
              {toleranceLow || plausibleLow}
            </div>
          </div>
        )}
        {widths.goodLow > 0 && (
          <div
            className={'range'}
            style={{
              width: `${widths.goodLow}%`,
              backgroundColor: appConfig.quantitativeStateColors.good,
            }}
          >
            <div
              className={'label left'}
              style={{
                marginLeft: `-${String(warningLow || toleranceLow || plausibleLow).length * 0.3}em`,
                paddingRight: `${String(warningLow || toleranceLow || plausibleLow).length * 0.3}em`,
              }}
            >
              {warningLow || toleranceLow || plausibleLow}
            </div>
          </div>
        )}
        <div
          className={'range desired'}
          style={{
            width: 0,
            borderColor: appConfig.quantitativeStateColors.good,
            backgroundColor: appConfig.quantitativeStateColors.good,
          }}
        >
          <div
            className={'label center'}
          >
            {desiredValue}
          </div>
        </div>
        {widths.goodHigh > 0 && (
          <div
            className={'range'}
            style={{
              width: `${widths.goodHigh}%`,
              backgroundColor: appConfig.quantitativeStateColors.good,
            }}
          >
            <div
              className={'label right'}
              style={{
                marginRight: `-${String(warningHigh || toleranceHigh || plausibleHigh).length * 0.3}em`,
                paddingLeft: `${String(warningHigh || toleranceHigh || plausibleHigh).length * 0.3}em`,
              }}

            >
              {warningHigh || toleranceHigh || plausibleHigh}
            </div>
          </div>
        )}
        {widths.warningHigh > 0 && (
          <div
            className={'range'}
            style={{
              width: `${widths.warningHigh}%`,
              backgroundColor: appConfig.quantitativeStateColors.warning,
            }}
          >
            <div
              className={'label right'}
              style={{
                marginRight: `-${String(toleranceHigh || plausibleHigh).length * 0.3}em`,
                paddingLeft: `${String(toleranceHigh || plausibleHigh).length * 0.3}em`,
              }}
            >
              {toleranceHigh || plausibleHigh}
            </div>
          </div>
        )}
        {widths.badHigh > 0 && (
          <div
            className={'range'}
            style={{
              width: `${widths.badHigh}%`,
              backgroundColor: appConfig.quantitativeStateColors.bad,
            }}
          >
            <div
              className={'label right'}
              style={{
                marginRight: `-${String(plausibleHigh).length * 0.3}em`,
                paddingLeft: `${String(plausibleHigh).length * 0.3}em`,
              }}

            >
              {plausibleHigh}
            </div>
          </div>
        )}
      </div>
      {values.map((value, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={'indicator'}
          style={{left: `calc(${getIndicatorPosition(value, borders, widths, useUniformQualityIndicator)}% - 8px)`}}
        />
      ))}
    </div>
  );
};
export default QualityIndicator;
