import {
  faCalendarAlt, faChartBar, faChartLine, faClock, faExclamationTriangle, faFile, faFileAlt, faHistory, faImage,
  faIndustry, faInfoCircle, faRocket, faTable
} from '@fortawesome/free-solid-svg-icons';
import {faAnalytics, faChartPie, faUserChart} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import i18n from '../../../../../i18n/i18n';

export const selectableIcons = {
  infoCircle: faInfoCircle,
  exclamationTriangle: faExclamationTriangle,
  chartLine: faChartLine,
  rocket: faRocket,
  image: faImage,
  file: faFile,
  fileAlt: faFileAlt,
  table: faTable,
  history: faHistory,
  clock: faClock,
  calendarAlt: faCalendarAlt,
  industry: faIndustry,
  analytics: faAnalytics,
  chartBar: faChartBar,
  userChart: faUserChart,
  chartPie: faChartPie,
};

const cockpitWidgetIconNames = Object.keys(selectableIcons);

export const getCockpitWidgetIcon = (name, withLabel = true) => (
  name ? (
    <>
      <FontAwesomeIcon icon={selectableIcons[name]}/>
      {withLabel && ` - ${i18n.t(`cockpitWidget.settings.icon.${name}`)}`}
    </>
  ) : null
);
export const iconSelectOptions = cockpitWidgetIconNames.map((value) => ({
  value,
  label: getCockpitWidgetIcon(value),
}));
