import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../../hooks/useStore';
import Modal from '../../shared/modal/Modal';
import ModelProperties from '../../shared/descriptions/ModelProperties';
import Button from '../../shared/buttons/Button';
import InspectionLotDeactivationModal from './InspectionLotDeactivationModal';
import {taskActivation} from '../../../models/scope';
import {useMount} from '../../../hooks/useMount';
import {useModulePolicy} from '../../../hooks/useModulePolicy';
import {CustomPropertyTypes} from '../../../models/customPropertyDataTypes';
import CustomPropertyValue from '../../shared/dataDisplay/CustomPropertyValue';

const InspectionLotDetailsModal = ({
  inspectionLotId,
  open,
  onCancel,
  afterDeactivate = () => undefined,
  afterActivate = () => undefined,
}) => {
  const store = useStore();
  const {t} = useTranslation();
  const [deactivating, setDeactivating] = useState(false);
  const [displayableProperties, setDisplayableProperties] = useState([]);
  const inspectionLot = store.inspectionLotStore.getById(inspectionLotId);
  const policy = useModulePolicy(store, [taskActivation]);

  useMount(() => {
    if (inspectionLot) {
      setDisplayableProperties(inspectionLot.displayableProperties);
    }
  }, [inspectionLot]);

  const onDeactivate = () => {
    setDeactivating(true);
  };

  const onReactivate = () => {
    store.inspectionLotStore.reactivate(inspectionLotId).finally(() => afterActivate(inspectionLotId));
  };

  const submitButton = (record) => {
    if (record.deactivatedBy) {
      return (
        <Button
          disabled={!policy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})}
          onClick={onReactivate}
        >
          {t('inspectionLot.reactivate')}
        </Button>
      );
    }
    return (
      <Button
        disabled={!policy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})}
        onClick={onDeactivate}
      >
        {t('inspectionLot.deactivate')}
      </Button>
    );
  };

  const availableCustomProperties = store.propertyStore.getByType({type: CustomPropertyTypes.InspectionLot});
  const customProperties = availableCustomProperties.map((prop) => (
    {
      key: prop.key,
      title: prop.label || prop.key,
      render: (record) => ({
        props: {},
        children: <CustomPropertyValue properties={record.properties} availableProperty={prop}/>,
      }),
    }
  ));

  const properties = [
    ...displayableProperties,
    ...customProperties,
    {
      key: 'deactivate',
      title: '',
      render: (record) => ({
        props: {},
        children: submitButton(record),
      }),
    },
  ];

  let title = t('inspectionLot.model.one');
  if (inspectionLot?.deactivatedBy) {
    title = `${title} (${t('inspectionLot.deactivated')})`;
  }

  return (
    <Modal
      title={title}
      open={open}
      onCancel={onCancel}
      footer={false}
      style={{minWidth: 600}}
      fullscreen={store.clientStore.isMobile}
    >
      <ModelProperties
        properties={properties}
        model={inspectionLot}
      />
      <InspectionLotDeactivationModal
        inspectionLotId={inspectionLotId}
        open={deactivating}
        onCancel={() => { setDeactivating(false); }}
        afterDeactivate={afterDeactivate}
      />
    </Modal>
  );
};

export default observer(InspectionLotDetailsModal);
