import { EffectCallback, useContext, useEffect } from 'react';
import { StoreContext } from '../stores/rootStore';

export const useStore = () => useContext(StoreContext);

export const useStoreLoad = (effect: EffectCallback) => {
  const store = useStore();

  // @ts-ignore
  useEffect(effect, [store.authStore.user?.id]);
};
export default class React {
}
