import dayjs from 'dayjs';
import {useMount} from './useMount';
import {mqtt, TOPIC_BASE} from '../middleware/mqtt';
import {PopupType} from '../models/popupMessage';

export const useMQTTLogSubscription = (store) => {
  useMount(() => {
    const opStateLogsId = mqtt.subscribe(`${TOPIC_BASE}/operation-state-logs/+`, (topic, payload) => {
      // TODO: Improve this, so that only workplace relevant logs are put into the store.
      store.operationStateLogStore.add(store.operationStateLogStore.createModelInstance(payload));
    });

    const wpStateLogsId = mqtt.subscribe(`${TOPIC_BASE}/workplace-state-logs/+`, (topic, payload) => {
      if (store.workplaceStore.selectedWorkplace?.id !== payload.workplaceId) {
        return;
      }
      store.workplaceStateLogStore.add(store.workplaceStateLogStore.createModelInstance(payload));
    });

    const persLogId = mqtt.subscribe(`${TOPIC_BASE}/personnel-logs/+`, (topic, payload) => {
      store.personnelLogStore.add(store.personnelLogStore.createModelInstance(payload));
    });

    const opsId = mqtt.subscribe(`${TOPIC_BASE}/operations/+`, (topic, payload) => {
      store.operationStore.add(store.operationStore.createModelInstance(payload));
    });

    const ordersId = mqtt.subscribe(`${TOPIC_BASE}/orders/+`, (topic, payload) => {
      store.orderStore.add(store.orderStore.createModelInstance(payload));
    });

    const batchesId = mqtt.subscribe(`${TOPIC_BASE}/batches/+`, (topic, payload) => {
      store.batchStore.add(store.batchStore.createModelInstance(payload));
    });

    const messagesId = mqtt.subscribe(`${TOPIC_BASE}/messages/+`, (topic, payload) => {
      if (store.workplaceStore.selectedWorkplace?.id) {
        const message = store.messageStore.createModelInstance(payload);
        let isRelevant = false;
        let materialMatches = false;

        if (store.operationStore.active) {
          // check if order matches
          if (store.operationStore.active.orderId === message.orderId) {
            isRelevant = true;
          } else if (message.materialId) {
            const materialIds = [
              store.operationStore.active.materialId,
              store.operationStore.active.order.materialId,
              ...store.operationStore.active.components.map((c) => c.materialId),
            ];

            // check if material matches
            if (materialIds.includes(message.materialId)) {
              materialMatches = true;
              if (message.hierarchyIds.length === 0) {
                // only add message, when it is not hierarchy-related.
                // the hierarchy relation is checked later
                isRelevant = true;
              }
            }
          }
        }

        if (store.workplaceStore.selectedWorkplace.hierarchyId) {
          const pathIds = store.hierarchyStore.getPathIds(store.workplaceStore.selectedWorkplace.hierarchyId);
          // check if hierarchy matches
          if (message.hierarchyIds.some((hierarchyId) => pathIds.includes(hierarchyId))) {
            if (message.materialId === null || materialMatches) {
              // only add if the message is not material related or if the materiel matches
              isRelevant = true;
            }
          }
        }

        if (message.validFrom) {
          const now = dayjs();
          if (dayjs(message.validFrom).isAfter(now) || dayjs(message.validTo).isBefore(now)) {
            // set as irrelevant when date does not match
            isRelevant = false;
          }
        }

        if (isRelevant || store.relevantMessagesStore.getById(message.id)) {
          store.relevantMessagesStore.add(message);
          store.messageReadStore.loadByMessageId(message.id);
        }
      }
    });

    const qualityReportsId = mqtt.subscribe(`${TOPIC_BASE}/quality-reports/+`, (topic, payload) => {
      const workplaceId = store.workplaceStore.selectedWorkplace?.id;
      if (workplaceId && workplaceId === payload.workplaceId) {
        const model = store.qualityReportStore.createModelInstance(payload);
        store.qualityReportStore.add(model);
        store.qualityReportStore.loadDependencies(
          store.qualityReportStore.getDependencies(),
          Promise.resolve(model)
        );
      }
    });

    return () => {
      mqtt.unsubscribe(`${TOPIC_BASE}/operation-state-logs/+`, opStateLogsId);
      mqtt.unsubscribe(`${TOPIC_BASE}/workplace-state-logs/+`, wpStateLogsId);
      mqtt.unsubscribe(`${TOPIC_BASE}/personnel-logs/+`, persLogId);
      mqtt.unsubscribe(`${TOPIC_BASE}/operations/+`, opsId);
      mqtt.unsubscribe(`${TOPIC_BASE}/orders/+`, ordersId);
      mqtt.unsubscribe(`${TOPIC_BASE}/batches/+`, batchesId);
      mqtt.unsubscribe(`${TOPIC_BASE}/messages/+`, messagesId);
      mqtt.unsubscribe(`${TOPIC_BASE}/quality-reports/+`, qualityReportsId);
    };
  }, [store.mqttStore.clientReady]);
};

export const useMQTTInspectionTasksPendingSubscription = (store) => {
  useMount(() => {
    const workplaces = store.workplaceStore.monitoredWorkplaces.slice();
    const ids = workplaces.map((workplace) => {
      const basePath = `${TOPIC_BASE}/workplaces/${workplace.id}`;

      return mqtt.subscribe(`${basePath}/inspection-tasks-pending`, (topic, payload) => {
        const toDelete = [];
        store.inspectionTaskPendingStore.pendingInspectionTasks.forEach((storePending) => {
          const stillPending = payload.some((pending) => pending.id === storePending.id);
          if (!stillPending) {
            toDelete.push(storePending.id);
          }
        });
        toDelete.forEach((id) => store.inspectionTaskPendingStore.remove(id));
        payload.forEach(
          (pending) => store.inspectionTaskPendingStore
            .add(store.inspectionTaskPendingStore.createModelInstance(pending))
        );
      });
    });

    return () => {
      workplaces.forEach((workplace, idx) => {
        const basePath = `${TOPIC_BASE}/workplaces/${workplace.id}`;
        mqtt.unsubscribe(`${basePath}/inspection-tasks-pending`, ids[idx]);
      });
    };
  }, [store.workplaceStore.monitoredWorkplaces.length, store.mqttStore.clientReady]);
};

export const useMQTTAuthSubscription = ({store, visible, onAuthentication}) => {
  useMount(
    () => {
      const terminal = store.terminalStore.activeTerminal;

      let revertEffect = () => undefined;

      if (visible && terminal) {
        const id = mqtt.subscribe(
          `${TOPIC_BASE}/terminals/${terminal.id}/auth`,
          (topic, payload) => onAuthentication && onAuthentication(payload)
        );
        revertEffect = () => {
          mqtt.unsubscribe(`${TOPIC_BASE}/terminals/${terminal.id}/auth`, id);
        };
      }

      return revertEffect;
    },
    [store.terminalStore.terminals.length, visible, store.mqttStore.clientReady]
  );
};

export const useMQTTPopupMessage = (store) => {
  useMount(() => {
    const workplaceId = store.workplaceStore.selectedWorkplace?.id;
    let revertEffect = () => undefined;
    if (workplaceId) {
      const id = mqtt.subscribe(
        `${TOPIC_BASE}/workplaces/${workplaceId}/popup-messages`,
        (topic, payload) => {
          if (Object.values(PopupType).includes(payload.type)) {
            store.popupMessageStore.add(store.popupMessageStore.createModelInstance(payload));
          }
        }
      );
      revertEffect = () => { mqtt.unsubscribe(`${TOPIC_BASE}/workplaces/${workplaceId}/popup-messages`, id); };
    }
    return revertEffect;
  }, [store.workplaceStore.selectedWorkplace?.id]);
};

export const useMQTTBatchQueueItemSubscription = (store, disabled) => {
  useMount(() => {
    if (!disabled) {
      const id = mqtt.subscribe(`${TOPIC_BASE}/batch-queue-items/+`, (topic, payload) => {
        if (payload.workplaceId === store.workplaceStore.selectedWorkplace?.id) {
          store.batchStore.load(payload.batchId, {onlyIfEmpty: true}).then(() => {
            store.batchQueueItemStore.add(store.batchQueueItemStore.createModelInstance(payload));
          });
        }
      });

      return () => {
        mqtt.unsubscribe(`${TOPIC_BASE}/batch-queue-items/+`, id);
      };
    }
    return () => {};
  }, [store.mqttStore.clientReady, disabled]);
};

export const useMQTTManualSensorDataSubscription = (store, disabled) => {
  useMount(() => {
    if (disabled) {
      return () => {};
    }
    const topic = `${TOPIC_BASE}/workplaces/${store.workplaceStore.selectedWorkplace.id}/manual-sensor-data`;
    const id = mqtt.subscribe(topic, (_, payload) => {
      store.sensorDataStore.add(store.sensorDataStore.createModelInstance(payload));
    });
    return () => {
      mqtt.unsubscribe(topic, id);
    };
  }, [store.mqttStore.clientReady, store.workplaceStore.selectedWorkplace.id, disabled]);
};

export const useMQTTTransitionQueueSubscription = (store) => {
  useMount(() => {
    if (!store.workplaceStore.selectedWorkplace?.id) {
      return () => {};
    }
    const topic = `${TOPIC_BASE}/workplaces/${store.workplaceStore.selectedWorkplace.id}/transition-queues`;
    const id = mqtt.subscribe(topic, (_, payload) => {
      if (payload?.deleted === true) {
        store.transitionQueueStore.remove(payload.id);
      } else {
        store.transitionQueueStore.add(store.transitionQueueStore.createModelInstance(payload));
      }
    });
    return () => {
      mqtt.unsubscribe(topic, id);
    };
  }, [store.mqttStore.clientReady, store.workplaceStore.selectedWorkplace?.id]);
};
