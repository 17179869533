import {createEntity, executeDelete, executeGet, updateEntity} from '../requests';

const resourcesName = 'v1/key-performance-indicator-targets';
export const calculateEndpoint = 'calculate';
export const calculateResource = 'calculate';

export const KeyPerformanceIndicatorTargetApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...entity}) => updateEntity(entity, `${resourcesName}/${id}`),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
  [calculateEndpoint]: (params) => executeGet(`${resourcesName}/${calculateResource}`, params),
};
