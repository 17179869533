import { computed, makeObservable, observable } from 'mobx';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import { sortAlphabetically, sortChronologically } from '../components/shared/tables/sorters';
import { datetimeFormat } from '../config/dayjs';
import { ExternalResource } from './externalResource';
import { RootStore } from '../stores/rootStore';

/**
 * reference translations:
 * t('externalResourceLog.references.ORDER')
 * t('externalResourceLog.references.MATERIAL')
 * t('externalResourceLog.references.INSPECTIONLOT')
 */
export enum ExternalResourceLogReference {
  Order = 'ORDER',
  Material = 'MATERIAL',
  InspectionLot = 'INSPECTIONLOT'
}

/**
 * state translations:
 * t('externalResourceLog.states.SENT')
 * t('externalResourceLog.states.PROCESSING')
 * t('externalResourceLog.states.SUCCESS')
 * t('externalResourceLog.states.ERROR')
 * t('externalResourceLog.states.ARCHIVED')
 */
export enum ExternalResourceLogState {
  Sent = 'SENT',
  Processing = 'PROCESSING',
  Success = 'SUCCESS',
  Error = 'ERROR',
  Archived = 'ARCHIVED'
}

export class ExternalResourceLog extends BaseModel {
  id: number = 0;
  externalResourceId: number = 0;
  reference: ExternalResourceLogReference = ExternalResourceLogReference.Order;
  referenceId: number = 0;
  state: ExternalResourceLogState = ExternalResourceLogState.Success;
  message: string | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      externalResourceId: observable,
      externalResource: computed,
      reference: observable,
      referenceId: observable,
      order: computed,
      material: computed,
      inspectionLot: computed,
      state: observable,
      message: observable,
      referenceTranslated: computed,
      stateTranslated: computed,
      referenceNo: computed,
      shortMessage: computed,
    });
  }

  static faIcon = faArrowUpRightFromSquare;

  searchableProperties = ['referenceNo', 'message'];

  displayableProperties = [
    displayableProperty({
      key: 'createdAt',
      title: i18n.t('externalResourceLog.model.attributes.createdAt'),
      params: [
        displayablePropertyParam({
          path: 'createdAt', transform: transformation.datetime({ format: datetimeFormat }),
        })],
      sorter: (a, b) => sortChronologically(a.createdAt, b.createdAt),
      defaultSortOrder: 'descend',
    }),
    displayableProperty({
      key: 'reference',
      title: i18n.t('externalResourceLog.model.attributes.reference'),
      sorter: (a, b) => sortAlphabetically(a.referenceTranslated, b.referenceTranslated),
      params: [displayablePropertyParam({ path: 'referenceTranslated', transform: transformation.none })],
    }),
    displayableProperty({
      key: 'referenceNo',
      title: i18n.t('externalResourceLog.model.attributes.referenceNo'),
      sorter: (a, b) => sortAlphabetically(a.referenceNo, b.referenceNo),
      params: [displayablePropertyParam({ path: 'referenceNo', transform: transformation.none })],
    }),
    displayableProperty({
      key: 'state',
      title: i18n.t('externalResourceLog.model.attributes.state'),
      sorter: (a, b) => sortAlphabetically(a.stateTranslated, b.stateTranslated),
      params: [displayablePropertyParam({ path: 'stateTranslated', transform: transformation.none })],
    }),
    displayableProperty({
      key: 'shortMessage',
      title: i18n.t('externalResourceLog.model.attributes.shortMessage'),
      sorter: (a, b) => sortAlphabetically(a.shortMessage, b.shortMessage),
      params: [displayablePropertyParam({ path: 'shortMessage', transform: transformation.none })],
    }),
  ];

  get externalResource() {
    return this.rootStore.externalResourceStore.getById(this.externalResourceId);
  }

  get order() {
    return this.reference === ExternalResourceLogReference.Order
      ? this.rootStore.orderStore.getById(this.referenceId)
      : null;
  }

  get material() {
    return this.reference === ExternalResourceLogReference.Material
      ? this.rootStore.materialStore.getById(this.referenceId)
      : null;
  }

  get inspectionLot() {
    return this.reference === ExternalResourceLogReference.InspectionLot
      ? this.rootStore.inspectionLotStore.getById(this.referenceId)
      : null;
  }

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);

    const externalResourcePrefix = `${titlePrefix}${i18n.t('externalResource.model.one')} > `;
    allDisplayableProperties.push(
      ...ExternalResource.allDisplayableProperties(
        rootStore,
        `${pathPrefix}externalResource.`,
        externalResourcePrefix
      )
    );

    return allDisplayableProperties;
  }

  get referenceTranslated() {
    return i18n.t(`externalResourceLog.references.${this.reference}`);
  }

  get stateTranslated() {
    return i18n.t(`externalResourceLog.states.${this.state}`);
  }

  get referenceNo() {
    switch (this.reference) {
      case ExternalResourceLogReference.Order:
        return this.order?.no;
      case ExternalResourceLogReference.Material:
        return this.material?.no;
      case ExternalResourceLogReference.InspectionLot:
        return this.inspectionLot?.lotNo;
      default:
        return null;
    }
  }

  get shortMessage() {
    if (!this.message) {
      return '';
    }
    const firstLine = this.message.split(/\r?\n|\r|\n/g)[0];
    if (firstLine.length > 50) {
      return `${firstLine.substring(0, 46)}...`;
    }
    return firstLine;
  }

  static prepareApiPayload(model: Partial<ExternalResourceLog>): Partial<ExternalResourceLog> {
    return {
      id: model.id,
      reference: model.reference,
      referenceId: model.referenceId,
      state: model.state,
      message: model.message,
    };
  }
}
