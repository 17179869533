import { computed, makeObservable, observable } from 'mobx';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';
import { User } from './user';
import { ConfigTransferSection } from './configTransferLog';

export enum ConfigTransferDirection {
  EXPORT = 'EXPORT',
  IMPORT = 'IMPORT'
}

export interface ConfigImportSectionReportError {
  uuid: string;
  message: string;
  error?: Error;
}

export type ConfigImportSectionReport = {
  sourceCount: number;
  ignored: string[];
  created: string[];
  updated: string[];
  deleted: string[];
  errors: ConfigImportSectionReportError[];
};

export type ConfigImportReport = {
  [key in ConfigTransferSection]?: ConfigImportSectionReport;
};

export class ConfigTransferFile extends BaseModel {
  id: number = 0;
  uuid: string = '';
  direction: ConfigTransferDirection = ConfigTransferDirection.EXPORT;
  deleteOnImport: boolean = false;
  filename: string = '';
  createdAt: string = '';
  createdBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      uuid: observable,
      direction: observable,
      deleteOnImport: observable,
      filename: observable,
      createdAt: observable,
      createdBy: observable,
      configTransferLogs: computed,
      createdByUser: computed,
    });
  }

  get configTransferLogs() {
    return this.rootStore.configTransferLogStore.getByFileId(this.id);
  }

  get createdByUser(): User | undefined {
    return this.rootStore.userStore.getById(this.createdBy) as User | undefined;
  }

  populateAttributesFromStore(rootStore: RootStore) {
    super.populateAttributesFromStore(rootStore);
  }
}
