import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Space } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { difference, uniq } from 'lodash';
import { useStore } from '../../../hooks/useStore';
import Table from '../../shared/tables/Table';
import { useMount } from '../../../hooks/useMount';
import MultiSelect from '../../shared/inputs/MultiSelect';
import { BatchAmount, StorageUnit } from '../../../models/storageUnit';
import { Component } from '../../../models/component';
import Tag from '../../shared/tags/Tag';
import Empty from '../../shared/empty/Empty';
import { mqtt, TOPIC_BASE } from '../../../middleware/mqtt';
import { STORAGE_UNIT_SETUP_PREFIX } from '../../../stores/storageUnitStore';
import { DosageSetupWidgetConfig } from './dosageSetupWidgetConfig';
import AlertWarning from '../../shared/alert/AlertWarning';
import { sortAlphabetically } from '../../shared/tables/sorters';
import { DosageStoreActions } from '../../../stores/dosageStore';
import { getParentInformationByComponent } from '../dosageUtil';

export type DosageSetupTableProps = {
  widgetConfig: DosageSetupWidgetConfig;
  isSecondary?: boolean;
};

export interface MultiselectValues {
  storageUnitIds: number[];
  materialId: number;
}

const DosageSetupTable: React.FC<DosageSetupTableProps> = ({
  widgetConfig,
  isSecondary = false,
  ...props
}) => {
  const store = useStore();
  const { t } = useTranslation();
  const [multiselectValues, setMultiselectValues] = useState<MultiselectValues[]>([]);
  const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);
  const [sourceStorageAreaId, setSourceStorageAreaId] = useState<number | null>(null);
  const [storageUnitMqttTopics, setStorageUnitMqttTopics] = useState<string[]>([]);
  const isMultiselect: boolean = widgetConfig.getIsMultiselect();

  const reset = () => {
    setMultiselectValues([]);
    store.dosageStore.clearSelectedComponents();
    store.dosageStore.clearSelectedComponentUnitIds();
    setSelectedRows([]);
  };

  const getMaterialId = (storageUnits: StorageUnit[]): number | null => {
    let materialId: number | null;
    const children = store.storageUnitStore.getDescendantsById(storageUnits[0].id)
      .filter((child) => child.contents.length > 0);
    if (children.length) {
      materialId = children[0].materialId || null;
    } else {
      materialId = storageUnits[0].materialId || null;
    }
    return materialId;
  };

  const getBatchAmount = (unit: StorageUnit): BatchAmount[] => {
    const { batchAmount } = unit;
    if (batchAmount && batchAmount.length > 0) {
      return batchAmount;
    }

    let batchAmounts: BatchAmount[] = [];
    const children = store.storageUnitStore.getDescendantsById(unit.id);
    if (children.length) {
      batchAmounts = children.flatMap((child) => child.batchAmount);
    }

    return batchAmounts;
  };

  const getMultiselectValueBySetupUnit = (setupUnit: StorageUnit | null): MultiselectValues | null => {
    if (!setupUnit) {
      return null;
    }

    const children = store.storageUnitStore.getDescendantsById(setupUnit.id);

    if (!children.length) {
      return null;
    }

    const materialId = getMaterialId(children);

    if (!materialId) {
      return null;
    }

    const sortedChildren = children.sort((a, b) => {
      const aOrder = a.setupOrder === null ? Number.POSITIVE_INFINITY : a.setupOrder;
      const bOrder = b.setupOrder === null ? Number.POSITIVE_INFINITY : b.setupOrder;
      return aOrder - bOrder;
    });

    return {
      storageUnitIds: sortedChildren.map((child) => child.id),
      materialId,
    };
  };

  const initialData = () => {
    const setupStorageUnits = store.storageUnitStore.getAllSetupStorageUnits();
    if (!setupStorageUnits) {
      return;
    }

    setSelectedRows(store.dosageStore.selectedComponents.map((sc) => sc.id) || []);

    const values: MultiselectValues[] = [];
    setupStorageUnits.forEach((unit) => {
      const valuesBySetupUnit = getMultiselectValueBySetupUnit(unit);

      if (!valuesBySetupUnit) {
        return;
      }

      values.push(valuesBySetupUnit);
    });
    setMultiselectValues(values);
  };

  useMount(() => {
    const activeOperation = store.operationStore.active;
    if (!activeOperation) {
      reset();
      setSourceStorageAreaId(null);
      return;
    }

    store.workplaceStorageRelationStore.loadAllWithDependencies({
      params: {
        workplaceId: activeOperation.workplaceId,
      },
    }).then((r) => {
      if (!r || !r.length || !r[0].sourceStorageAreaIds.length) {
        return;
      }
      setSourceStorageAreaId(r[0].sourceStorageAreaIds[0]);
    });
  }, [store.operationStore.active?.id]);

  useMount(() => {
    const activeOperation = store.operationStore.active;
    if (!activeOperation?.id || !sourceStorageAreaId) {
      return;
    }

    store.storageUnitStore.loadAllWithDependencies({
      params: {
        storageAreaIds: [sourceStorageAreaId],
      },
    }).then((units) => {
      const allUnits: StorageUnit[] = [];
      allUnits.push(...units);
      const unitIds = units.map((unit) => unit.id);

      const promises = unitIds.map((unitId) => store.storageUnitStore.loadDescendantsById(unitId));

      Promise.all(promises).then((data) => {
        allUnits.push(...data.flatMap((unit) => unit));

        const allUnitsIds = uniq(allUnits).map((unit) => unit.id);
        setStorageUnitMqttTopics(
          uniq(allUnitsIds).map((id) => `${TOPIC_BASE}/storage-units/${id}`)
        );

        const batchIds = uniq<StorageUnit>(allUnits).flatMap((unit) => unit.contents.map((content) => content.batchId));
        const uniqueBatchIds = uniq(batchIds);
        if (!uniqueBatchIds.length) {
          initialData();
          return;
        }
        store.batchStore.loadMany(uniqueBatchIds as number[]).then(() => {
          initialData();
        });
      });
    });

    store.productStore.loadAllWithDependencies({
      params: {
        operationId: activeOperation.id,
      },
    }).then(() => store.componentStore.loadAllWithDependencies({
      params: {
        operationId: activeOperation.id,
      },
    }));
  }, [store.operationStore.active?.id, sourceStorageAreaId]);

  // reevaluates the multiselect value upon dosage store changes
  useMount(() => {
    if (store.dosageStore.areCollectionOrDependenciesLoading) {
      return;
    }
    initialData();
  }, [store.dosageStore.areCollectionOrDependenciesLoading]);

  useMount(() => {
    const units = store.storageUnitStore.storageUnits;
    const unitIds = uniq(units.map((unit) => unit.id));
    const allUnitIdsTopics = unitIds.map((id) => `${TOPIC_BASE}/storage-units/${id}`);
    const newTopics = difference(allUnitIdsTopics, storageUnitMqttTopics);

    if (newTopics.length > 0) {
      setStorageUnitMqttTopics([...storageUnitMqttTopics, ...newTopics]);
    }
  }, [store.dosageStore.isActionInProgress(DosageStoreActions.setup)]);

  useMount(() => {
    if (storageUnitMqttTopics.length <= 0) {
      return () => {};
    }

    const handlerIds = storageUnitMqttTopics.map((topic) => (
      mqtt.subscribe(topic, (_, payload: StorageUnit) => {
        if (!payload) {
          return;
        }

        const model = store.storageUnitStore.createModelInstance(payload);
        store.storageUnitStore.add(model);

        initialData();
      })
    ));

    return () => {
      storageUnitMqttTopics.forEach((topic, idx) => mqtt.unsubscribe(topic, handlerIds[idx]));
    };
  }, [storageUnitMqttTopics]);

  useMount(() => {
    if (isSecondary || !sourceStorageAreaId) {
      return () => {};
    }

    const storageAreaTopic = `${TOPIC_BASE}/storage-areas/${sourceStorageAreaId}`;
    const id = mqtt.subscribe(storageAreaTopic, () => {
      store.storageUnitStore.loadAllByStorageAreaIds([sourceStorageAreaId]);
    });

    return () => {
      mqtt.unsubscribe(storageAreaTopic, id);
    };
  }, [
    store.workplaceStorageRelationStore.workplaceStorageRelations.length,
    isSecondary,
    sourceStorageAreaId,
  ]);

  const setStorageUnitIdsSelection = (storageUnitIds: number[], componentId: number) => {
    const { selectedComponents } = store.dosageStore;
    if (!selectedComponents || !selectedComponents.length) {
      store.dosageStore.clearSelectedComponentUnitIds();
      return;
    }

    store.dosageStore.addSelectedComponentUnitIds(componentId, storageUnitIds);
  };

  const processComponentSelection = (component: Component | undefined) => {
    if (!component) {
      return;
    }
    const selectedValues = multiselectValues
      .filter((value) => value.materialId === component.materialId)
      .flatMap((value) => value.storageUnitIds);

    store.dosageStore.addSelectedComponent(component);
    store.dosageStore.addSelectedComponentUnitIds(component.id, selectedValues);
  };

  const handleSelectionChange = (selectedRowKeys: React.Key[]) => {
    const isAddAnyway = !selectedRows.length;

    if (!selectedRowKeys) {
      setSelectedRows([]);
      store.dosageStore.clearSelectedComponents();
      store.dosageStore.clearSelectedComponentUnitIds();
      return;
    }

    const newSelection: number[] = difference(selectedRowKeys, selectedRows) as number[];
    if (!newSelection.length) {
      const removedSelection = difference(selectedRows, selectedRowKeys) as number[];
      const removedSelectionValue = removedSelection[0];
      setSelectedRows(selectedRowKeys);
      store.dosageStore.removeSelectedComponent(removedSelectionValue);
      store.dosageStore.removeSelectedComponentUnitIds(removedSelectionValue);
      return;
    }

    const newSelectionValue = newSelection[0];

    const oldComponent = store.componentStore.getById((selectedRows[0]) as number);
    const newComponent = store.componentStore.getById(newSelectionValue);

    let oldComponentParentInformation = null;
    let newComponentParentInformation = null;
    if (oldComponent && newComponent) {
      oldComponentParentInformation = getParentInformationByComponent(oldComponent, store);
      newComponentParentInformation = getParentInformationByComponent(newComponent, store);
    }

    if (!isMultiselect) {
      store.dosageStore.clearSelectedComponents();
      store.dosageStore.clearSelectedComponentUnitIds();
    }

    if (
      isAddAnyway
      || (
        newComponent
        && newComponent?.productId === oldComponent?.productId
        && newComponent?.unitOfMeasureId === oldComponent?.unitOfMeasureId
        && newComponentParentInformation?.isParent === oldComponentParentInformation?.isParent
      )
    ) {
      setSelectedRows(selectedRowKeys);
      processComponentSelection(newComponent);
    } else {
      store.dosageStore.clearSelectedComponents();
      store.dosageStore.clearSelectedComponentUnitIds();
      setSelectedRows([newSelectionValue]);
      processComponentSelection(newComponent);
    }
  };

  const handleMultiselectChange = async (storageUnitIds: number[], componentId: number) => {
    const component: Component | null = store.componentStore.getById(componentId) || null;
    const activeOperation = store.operationStore.active;

    if (!component || !activeOperation) {
      return;
    }

    let setupStorageUnit: StorageUnit | null = null;

    try {
      setupStorageUnit = await store.dosageStore.setup(
        activeOperation.workplaceId,
        componentId,
        storageUnitIds
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error setting up dosage', e);
    }

    setStorageUnitIdsSelection(storageUnitIds, componentId);

    if (selectedRows.includes(componentId) && selectedRows.length > 1) {
      const selectedRowId = selectedRows.find((sr) => sr !== componentId);
      if (selectedRowId) {
        const selectedRowComponent = store.componentStore.getById(selectedRowId as number);
        if (selectedRowComponent) {
          const parentInformation = getParentInformationByComponent(component, store);
          const selectedParentInformation = getParentInformationByComponent(selectedRowComponent, store);
          if (selectedParentInformation.isParent !== parentInformation.isParent) {
            reset();
          }
        }
      }
    }

    const prevMultiselectValues = multiselectValues
      .filter((value) => value.materialId !== component.materialId);

    prevMultiselectValues.forEach((value) => {
      value.storageUnitIds = value.storageUnitIds.sort((a, b) => {
        const aUnit = store.storageUnitStore.getById(a);
        const bUnit = store.storageUnitStore.getById(b);
        return (aUnit?.setupOrder ?? Number.POSITIVE_INFINITY) - (bUnit?.setupOrder ?? Number.POSITIVE_INFINITY);
      });
    });

    const valuesBySetupUnit = getMultiselectValueBySetupUnit(setupStorageUnit);

    if (!valuesBySetupUnit) {
      setMultiselectValues(
        [
          ...prevMultiselectValues,
        ]
      );
    } else {
      setMultiselectValues(
        [
          ...prevMultiselectValues,
          valuesBySetupUnit,
        ]
      );
    }
  };

  const configColumns = widgetConfig.getWidgetProperties();

  const fixedColumns = [
    {
      title: t('dosageSetupWidget.table.title.storageUnits'),
      key: 'storageUnits',
      dataIndex: 'id',
      render: (_: any, component: Component) => {
        const componentOperationId = component.operationId;
        const multiselectValueMaterial = multiselectValues.filter((value) =>
          value.materialId === component.materialId);

        let selectedValues: number[] = [];
        if (multiselectValueMaterial && multiselectValueMaterial.length > 0) {
          selectedValues = multiselectValueMaterial[0].storageUnitIds;
        }

        const setupUnit = store.storageUnitStore.getSetupStorageUnitByOperationAndMaterialId(
          component.operationId,
          component.materialId
        );

        let storageUnits: StorageUnit[] = [];
        if (setupUnit) {
          const setup = store.storageUnitStore.getDescendantsById(setupUnit.id);

          if (setup) {
            storageUnits.push(...setup);
          }
        }

        if (sourceStorageAreaId) {
          storageUnits = storageUnits.concat(store.storageUnitStore.getByStorageAreaId(sourceStorageAreaId)
            .filter((unit) => {
              const children = store.storageUnitStore.getDescendantsById(unit.id);

              return unit.materialId === component.materialId
                || (children.length > 0 && children.map((child) => child.materialId).includes(component.materialId)
                  && (!unit.no || !unit.no.includes(`${STORAGE_UNIT_SETUP_PREFIX}${componentOperationId}_`)));
            }));
        }

        return (
          <MultiSelect
            data-cy={'DosageSetupTable-MultiSelect'}
            onSelectionChange={(ids: number[]) => handleMultiselectChange(ids, component.id)}
            allowClear
            disabled={store.dosageStore.isInPreparation}
            filterOption
            optionFilterProp={'label'}
            /* @ts-ignore its possible */
            value={selectedValues}
            options={storageUnits.map((unit: StorageUnit) => ({
              label: unit.no,
              value: unit.id,
            }))}
          />
        );
      },
    },
    {
      title: t('dosageSetupWidget.table.title.queue'),
      key: 'queue',
      render: (_: any, component: Component) => {
        const multiselectValueMaterial = multiselectValues.filter((value) =>
          value?.materialId === component.materialId);

        let selectedValues: number[] = [];
        if (multiselectValueMaterial && multiselectValueMaterial.length > 0) {
          selectedValues = multiselectValueMaterial[0].storageUnitIds;
        }

        if (isSecondary && component.isStockRequired && selectedValues.length === 0) {
          return <AlertWarning message={t('dosageSetupWidget.table.requiredStockMissing')} showIcon/>;
        }

        const allContentData: BatchAmount[] = [];
        selectedValues.forEach((id) => {
          const unit = store.storageUnitStore.getById(id);
          if (!unit) {
            return;
          }

          const batchAmountData = getBatchAmount(unit);
          allContentData.push(...batchAmountData.flat());
        });

        return (
          <div
            style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '5px' }}
          >
            {
              allContentData?.map((content) => (
                <Tag
                  color="default"
                  key={content.createdAt}
                >
                  {
                    content.label
                  }
                </Tag>
              ))
            }
          </div>
        );
      },
    },
  ];

  const rowSelection: TableRowSelection<Component> | undefined = isSecondary ? undefined : {
    type: isMultiselect ? 'checkbox' : 'radio',
    hideSelectAll: isMultiselect,
    selectedRowKeys: selectedRows,
    getCheckboxProps: useCallback(() => ({
      disabled: store.dosageStore.isInPreparation,
    }), [store.dosageStore.isInPreparation]),
    onChange: handleSelectionChange,
  };

  const products = store.productStore
    .getByOperationId(store.operationStore.active?.id)
    .sort((a, b) => sortAlphabetically(a.no, b.no));

  return (
    <Space
      direction={'vertical'}
      style={{ width: '100%' }}
      size={'large'}
    >
      {
        !products.length ? <Empty/> : (
          products.map((product) => (
            <div key={product.id}>
              <div
                data-cy={'DosageSetupTable-ProductNo'}
                style={{
                  fontSize: '1.1em',
                  color: 'black',
                  fontWeight: 'bold',
                }}
              >
                {product.no}
              </div>
              <Table
                data-cy={'DosageSetupTable-Table'}
                rowSelection={rowSelection}
                rowKey="id"
                columns={[...configColumns, ...fixedColumns]}
                pagination={false}
                dataSource={store.componentStore.components.filter((component) => component.productId === product.id)}
                loading={store.storageUnitStore.isResourceBusy('all')}
                {...props}
              />
            </div>
          ))
        )
      }
    </Space>
  );
};

export default observer(DosageSetupTable);
