import { makeObservable, observable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { PrintAllocation } from '../models/printAllocation';
import { RootStore } from './rootStore';

export class PrintAllocationStore extends EntityStore<PrintAllocation> {
  printAllocations: PrintAllocation[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'printAllocations', Api.printAllocations, PrintAllocation, true);

    makeObservable(this, {
      printAllocations: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.materialStore,
        modelId: 'materialId',
      },
      {
        store: this.rootStore.printLabelStore,
        modelId: 'printLabelId',
      },
      {
        store: this.rootStore.printerStore,
        modelId: 'printerIds',
      },
    ];
  }
}
