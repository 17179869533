/* eslint-disable no-param-reassign */
import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import ConfirmButton from './ConfirmButton';

export type DeleteButtonProps = {
  onConfirm?: () => void;
  title?: string;
  icon?: any;
  disabledText?: string;
  buttonProps?: any;
  confirmType?: string;
  dataCyPrefix?: string;
};

const DeleteButton: React.FC<DeleteButtonProps> = ({
  onConfirm,
  title,
  icon = <DeleteOutlined/>,
  disabledText,
  buttonProps = { danger: true },
  confirmType,
  dataCyPrefix,
  ...props
}) => {
  const { t } = useTranslation();
  title = title || t<string>('confirm.delete.title');
  disabledText = disabledText || t<string>('confirm.delete.disabled');

  return (
    <ConfirmButton
      onConfirm={onConfirm}
      title={title}
      icon={icon}
      okType={'danger'}
      confirmType={confirmType}
      disabledText={disabledText}
      buttonProps={buttonProps}
      dataCyPrefix={`${dataCyPrefix ? `${dataCyPrefix}-` : ''}DeleteButton`}
      {...props}
    />
  );
};

export default DeleteButton;
