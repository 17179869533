import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import Modal from '../shared/modal/Modal';
import {useStore} from '../../hooks/useStore';
import ScanTerminal from './shared/ScanTerminal';
import AlertWarning from '../shared/alert/AlertWarning';

const RepairedModal = ({
  onSuccess,
  onCancel,
  terminal,
  ...props
}) => {
  const store = useStore();
  const {t} = useTranslation();
  const [selectedTerminal, setSelectedTerminal] = useState(terminal?.id ? terminal : null);

  const handleSubmit = () => {
    if (selectedTerminal?.isBroken) {
      store.terminalStore.update({
        id: selectedTerminal.id,
        name: selectedTerminal.name,
        isBroken: false,
        brokenReason: '',
      }).then(() => onSuccess());
    }
  };

  return (
    <Modal
      fullscreen={store.clientStore.isMobile}
      open
      okText={t('terminalManagement.modal.repaired.repair')}
      cancelText={t('confirm.cancel')}
      okButtonProps={{disabled: !selectedTerminal?.isBroken}}
      maskClosable={false}
      onCancel={onCancel}
      onOk={handleSubmit}
      {...props}
    >
      <ScanTerminal terminal={selectedTerminal} onChangeTerminal={setSelectedTerminal}/>
      {selectedTerminal && !selectedTerminal?.isBroken
        ? (
          <AlertWarning
            message={t('terminalManagement.modal.repaired.notMarkedAsBroken')}
            style={{marginBottom: '1em'}}
          />
        ) : null}
    </Modal>
  );
};

export default observer(RepairedModal);
