import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { RootStore } from './rootStore';
import { QualityReport } from '../models/qualityReport';

export class QualityReportStore extends EntityStore<QualityReport> {
  qualityReports: QualityReport[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'qualityReports', Api.qualityReport, QualityReport, true);

    makeObservable(this, {
      qualityReports: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.workplaceStore,
        modelId: 'workplaceId',
      },
      {
        store: this.rootStore.orderStore,
        modelId: 'orderId',
      },
      {
        store: this.rootStore.materialStore,
        modelId: 'materialId',
      },
      {
        store: this.rootStore.batchStore,
        modelId: 'batchId',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'coordinatorUserId',
      },
      {
        store: this.rootStore.documentStore,
        modelId: 'documentIds',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'createdBy',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'updatedBy',
      },
    ];
  }

  get bySelectedWorkplace() {
    if (this.rootStore.workplaceStore.selectedWorkplace) {
      // @ts-ignore
      // FIXME: once types are available
      // eslint-disable-next-line max-len
      return this.qualityReports.filter((item) => item.workplaceId === this.rootStore.workplaceStore.selectedWorkplace?.id);
    }
    return [];
  }
}
