import {useStore} from '../../../hooks/useStore';
import styles from './QuickStopButtons.module.scss';
import {QuickstopButton} from './QuickstopButton';

const QuickStopButtons = ({quickstops, disabled = false}) => {
  const store = useStore();

  const handleQuickstop = (interruptionReasonId) => {
    const transitionId = store.validTransitionsStore.interruptionTransitions[0].id;

    store.workplaceStore.performQuickstop({
      workplaceId: store.workplaceStore.selectedWorkplace?.id,
      body: {
        transitionId,
        interruptionReasonId,
      },
    });
  };

  return (
    <div className={styles.quickStopButtons}>
      {
        quickstops.map((quickstop) => (
          <QuickstopButton
            className={`${quickstops.length > 1 ? styles.quickStopButton : ''}`}
            key={quickstop.id}
            title={quickstop.label}
            onClick={() => handleQuickstop(quickstop.id)}
            size="middle"
            disabled={disabled}
          />
        ))
      }
    </div>
  );
};

export default QuickStopButtons;
