import {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {Col, Row} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTachometerAlt} from '@fortawesome/free-solid-svg-icons';
import OperatorWidget from '../operator/shared/OperatorWidget';
import {useStore} from '../../hooks/useStore';
import {useMount} from '../../hooks/useMount';
import {ScopeContext} from '../../policies/scopeContext';
import {processdataCollection} from '../../models/scope';
import {SpeedChangeWidgetConfig} from './SpeedChangeWidgetConfig';
import EditButton from '../shared/buttons/EditButton';
import Descriptions from '../shared/descriptions/Descriptions';
import SpeedDenominator from '../shared/dataDisplay/SpeedDenominator';
import EditSpeedChangeModal from './EditSpeedChangeModal';
import {WidgetLayouts} from '../shared/widgets/WidgetLayouts';
import {WidgetLayout} from '../../models/widgetLayout';
import SpeedChangeDetailModal from './SpeedChangeDetailModal';
import {SensorType} from '../../models/sensor';
import AlertWarning from '../shared/alert/AlertWarning';
import {useModulePolicy} from '../../hooks/useModulePolicy';

const SpeedChangeWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const {t} = useTranslation();
  const widgetConfig = useMemo(() => (
    new SpeedChangeWidgetConfig(store, identifier)
  ), [store.sensorStore.sensors.length]);
  const settings = widgetConfig.getValues();
  const scopes = [processdataCollection];
  const policy = useModulePolicy(store, scopes);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [detailModalVisible, setDetailModalVisible] = useState(false);
  const [workplaceSensor, setWorkplaceSensor] = useState(null);
  const [showTooManySensorsWarning, setShowTooManySensorsWarning] = useState(false);

  const loadGlobalSensors = () => {
    store.sensorStore.loadAll({
      params: {
        hierarchyId: 'null',
        type: [SensorType.SPEED],
      },
    });
  };

  const loadWorkplaceSensors = async () => {
    if (!store.workplaceStore.selectedWorkplace) {
      return;
    }
    await store.sensorStore.loadAll({
      params: {
        hierarchyId: store.workplaceStore.selectedWorkplace.hierarchyId,
        includeHierarchyChildren: true,
        type: [SensorType.SPEED],
      },
    });
    const workplaceSensors = store.sensorStore
      .getByHierarchyIdAndSensorType(store.workplaceStore.selectedWorkplace.hierarchyId, SensorType.SPEED);
    setWorkplaceSensor(workplaceSensors.length ? workplaceSensors[0] : null);
    setShowTooManySensorsWarning(workplaceSensors.length > 1);
  };

  useMount(() => {
    loadGlobalSensors();
  });

  useMount(() => {
    loadWorkplaceSensors();
  }, [store.workplaceStore.selectedWorkplace?.id]);

  const getEditDisabledReason = () => {
    let reason = null;
    if (!store.operationStore.active) {
      reason = t('speedChangeWidget.noActiveOperation');
    }
    if (!settings?.sensor) {
      reason = t('speedChangeWidget.noSensorConfigured');
    }
    return reason;
  };

  const getDetailSensor = () => workplaceSensor?.name || settings?.sensor || null;

  const actualValue = settings.decimals !== null
    ? store.operationStore.active?.actualSpeed?.toFixed(settings.decimals) : store.operationStore.active?.actualSpeed;
  const plannedValue = settings.decimals !== null
    ? store.operationStore.active?.plannedSpeed?.toFixed(settings.decimals) : store.operationStore.active?.plannedSpeed;

  return (
    <ScopeContext.Provider value={scopes}>
      <OperatorWidget
        icon={<FontAwesomeIcon icon={faTachometerAlt}/>}
        title={widgetConfig.getWidgetTitle()}
        onHeadClick={() => setDetailModalVisible(true)}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        manualPath={'/process-data/speed-change'}
      >
        {showTooManySensorsWarning && (
          <Row style={{marginBottom: '0.5em'}}>
            <Col>
              <AlertWarning
                size={'small'}
                message={t('tooManySensors')}
                closable
              />
            </Col>
          </Row>
        )}
        <Row align="middle">
          <Col span={18}>
            <Descriptions size={'small'} column={1} bordered>
              <Descriptions.Item label={t('speedChangeWidget.actual')} style={{width: '50%'}}>
                <SpeedDenominator value={actualValue} unit={store.operationStore.active?.speedUnitOfMeasure}/>
              </Descriptions.Item>
              <Descriptions.Item label={t('speedChangeWidget.planned')}>
                <SpeedDenominator value={plannedValue} unit={store.operationStore.active?.speedUnitOfMeasure}/>
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col offset={2} span={1}>
            {!workplaceSensor && (
              <EditButton
                type={undefined}
                size={'large'}
                disabled={
                  !policy.canEdit({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})
                  || getEditDisabledReason()
                }
                title={getEditDisabledReason()}
                onClick={setEditModalVisible}
              />
            )}
          </Col>
        </Row>
      </OperatorWidget>

      {editModalVisible && (
        <EditSpeedChangeModal
          title={t('speedChangeWidget.editModal.title')}
          onCancel={() => setEditModalVisible(false)}
          onSuccess={() => setEditModalVisible(false)}
          sensor={settings?.sensor}
          reasons={settings?.reasons}
        />
      )}
      {detailModalVisible && (
        <SpeedChangeDetailModal
          onCancel={() => setDetailModalVisible(false)}
          sensor={getDetailSensor()}
          decimals={settings.decimals}
        />
      )}
    </ScopeContext.Provider>
  );
};

SpeedChangeWidget.icon = faTachometerAlt;

SpeedChangeWidget.identifier = 'speedChangeWidget';
SpeedChangeWidget.defaultLayout = new WidgetLayout(
  {
    identifier: SpeedChangeWidget.identifier,
    x: 2,
    y: 8,
    height: 3,
    minHeight: 3,
    width: WidgetLayouts.sixthWidth.w,
    minWidth: WidgetLayouts.sixthWidth.minW,
  }
);

export default observer(SpeedChangeWidget);
