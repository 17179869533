import {first, last} from 'lodash';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import LineChart from '../../shared/charts/LineChart';
import appConfig from '../../../utils/appConfig';

const QualitativeChart = ({
  results = [],
  task,
}) => {
  const {t} = useTranslation();
  const {
    plausibleLow,
    toleranceLow,
    warningLow,
    warningHigh,
    toleranceHigh,
    plausibleHigh,
    desiredValue,
  } = task.quantitative;
  const getValueBasedOnTaskType = (result) => {
    if (task.isFourEyes) {
      return Number(result.value);
    }
    return result.overruledUserId ? Number(result.overruledValue) : Number(result.value);
  };
  const values = results.map((r) => ({
    x: dayjs(r.recordedAt).valueOf(),
    y: getValueBasedOnTaskType(r),
  }));
  const overruledValues = results.map((r) => ({
    x: dayjs(r.recordedAt).valueOf(),
    y: r.overruledUserId ? Number(r.overruledValue) : null,
  }));
  const minDate = dayjs(first(results)?.recordedAt).valueOf();
  const maxDate = dayjs(last(results)?.recordedAt).valueOf();

  const plotBands = [];

  let low = plausibleLow;

  if (toleranceLow !== null) {
    plotBands.push({
      from: low,
      to: toleranceLow,
      color: appConfig.quantitativeStateColors.bad,
    });
    low = toleranceLow;
  }

  if (warningLow !== null) {
    plotBands.push({
      from: low,
      to: warningLow,
      color: appConfig.quantitativeStateColors.warning,
    });
    low = warningLow;
  }

  if (toleranceLow === null && warningLow === null) {
    plotBands.push({
      from: low,
      to: desiredValue,
      color: appConfig.quantitativeStateColors.bad,
    });
  } else {
    plotBands.push({
      from: low,
      to: desiredValue,
      color: appConfig.quantitativeStateColors.good,
    });
  }

  let high = plausibleHigh;

  if (toleranceHigh !== null) {
    plotBands.push({
      from: toleranceHigh,
      to: high,
      color: appConfig.quantitativeStateColors.bad,
    });
    high = toleranceHigh;
  }

  if (warningHigh !== null) {
    plotBands.push({
      from: warningHigh,
      to: high,
      color: appConfig.quantitativeStateColors.warning,
    });
    high = warningHigh;
  }

  if (toleranceHigh === null && warningHigh === null) {
    plotBands.push({
      from: desiredValue,
      to: high,
      color: appConfig.quantitativeStateColors.bad,
    });
  } else {
    plotBands.push({
      from: desiredValue,
      to: high,
      color: appConfig.quantitativeStateColors.good,
    });
  }

  const series = [{
    name: t('inspectionTaskResult.model.attributes.value'),
    data: values,
    color: 'black',
  }];

  if (task.isFourEyes) {
    series.push({
      name: t('inspectionTaskResult.model.attributes.fourEyesValue'),
      data: overruledValues,
      color: '#1890FF',
    });
  }

  return (
    <LineChart
      options={{
        series,
        legend: {enabled: task.isFourEyes},
        title: {text: ''},
        xAxis: {
          min: minDate,
          max: maxDate,
        },
        yAxis: {
          title: false,
          min: plausibleLow,
          max: plausibleHigh,
          plotBands,
        },
      }}
      isTimeseries
    />
  );
};
export default QualitativeChart;
