import { observable, makeObservable, computed } from 'mobx';
import { faGlassHalf } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import { datetimeFormat, detailedDurationFormat } from '../config/dayjs';
import { sortChronologically, sortNumerically } from '../components/shared/tables/sorters';
import { Batch } from './batch';
import { Component } from './component';
import { Preparation } from './preparation';
import { StorageUnit } from './storageUnit';
import { Yield } from './yield';
import { UnitOfMeasurement } from './unitOfMeasurement';
import { User } from './user';
import { RootStore } from '../stores/rootStore';

const MAX_DECIMAL_PLACES = 3;

export class ConsumptionLog extends BaseModel {
  id: number = 0;
  amount: number = 0;
  dosedStart: string | null = null;
  dosedEnd: string | null = null;
  dosedDurationSeconds: number | null = null;
  batchId: number | null = null;
  componentId: number = 0;
  preparationId: number | null = null;
  targetYieldId: number | null = null;
  unitOfMeasureId: number | null = null;
  originStorageUnitId: number | null = null;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;
  recordedAt: string = '';

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      amount: observable,
      dosedStart: observable,
      dosedEnd: observable,
      dosedDurationSeconds: observable,
      batchId: observable,
      batch: computed,
      componentId: observable,
      component: computed,
      preparationId: observable,
      preparation: computed,
      targetYieldId: observable,
      targetYield: computed,
      unitOfMeasureId: observable,
      unitOfMeasure: computed,
      originStorageUnitId: observable,
      originStorageUnit: computed,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
      createdByUser: computed,
      updatedByUser: computed,
      recordedAt: observable,
    });
  }

  static faIcon = faGlassHalf;

  searchableProperties = ['no', 'amount', 'component.no', 'component.info'];

  displayableProperties = [
    displayableProperty({
      title: i18n.t('consumption.model.attributes.recordedAt'),
      params: [
        displayablePropertyParam({
          path: 'recordedAt', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      key: 'recordedAt',
      sorter: (a, b) => sortChronologically(a.recordedAt, b.recordedAt),
      renderText: (text, record) => record?.recordedAt || '',
    }),
    displayableProperty({
      title: i18n.t('consumption.model.attributes.createdAt'),
      params: [
        displayablePropertyParam({
          path: 'createdAt', transform: transformation.datetime({ format: datetimeFormat }),
        }),
      ],
      template: '{value}',
      key: 'createdAt',
      sorter: (a, b) => sortChronologically(a.createdAt, b.createdAt),
    }),
    displayableProperty({
      key: 'amount',
      title: i18n.t('consumption.model.attributes.amount'),
      sorter: (a, b) => a.amount - b.amount,
      params: [
        displayablePropertyParam({
          path: 'amount',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'amount',
        }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{amount} {unitOfMeasure}',
    }),
    displayableProperty({
      key: 'dosedStart',
      title: i18n.t('consumption.model.attributes.dosedStart'),
      sorter: (a, b) => sortChronologically(a.dosedStart, b.dosedStart),
      params: [
        displayablePropertyParam({
          path: 'dosedStart',
          transform: transformation.datetime({ format: datetimeFormat }),
        })],
    }),
    displayableProperty({
      key: 'dosedEnd',
      title: i18n.t('consumption.model.attributes.dosedEnd'),
      sorter: (a, b) => sortChronologically(a.dosedEnd, b.dosedEnd),
      params: [
        displayablePropertyParam({
          path: 'dosedEnd',
          transform: transformation.datetime({ format: datetimeFormat }),
        })],
    }),
    displayableProperty({
      key: 'dosedDurationSeconds',
      title: i18n.t('consumption.model.attributes.dosedDurationSeconds'),
      sorter: (a, b) => sortNumerically(a.dosedDurationSeconds, b.dosedDurationSeconds),
      params: [
        displayablePropertyParam({
          path: 'dosedDurationSeconds',
          transform: transformation.durationOrDash({ format: detailedDurationFormat }),
        })],
    }),
    displayableProperty({
      key: 'targetAmount',
      title: i18n.t('consumption.model.attributes.targetAmount'),
      sorter: (a, b) => a.targetAmount - b.targetAmount,
      params: [
        displayablePropertyParam({
          path: 'targetAmount',
          as: 'targetAmount',
        }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{targetAmount} {unitOfMeasure}',
    }),
    displayableProperty({
      key: 'amountIsShould',
      title: i18n.t('consumption.model.attributes.amountIsShould'),
      params: [
        displayablePropertyParam({
          path: 'amount',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'amount',
        }),
        displayablePropertyParam({
          path: 'targetAmount',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'targetAmount',
        }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{amount} / {targetAmount} {unitOfMeasure}',
    }),
  ];

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);

    const batchPrefix = `${titlePrefix}${i18n.t('batch.model.one')} > `;
    allDisplayableProperties.push(
      ...Batch.allDisplayableProperties(rootStore, `${pathPrefix}batch.`, batchPrefix)
    );

    const componentPrefix = `${titlePrefix}${i18n.t('component.model.one')} > `;
    allDisplayableProperties.push(
      ...Component.allDisplayableProperties(rootStore, `${pathPrefix}component.`, componentPrefix)
    );

    const preparationPrefix = `${titlePrefix}${i18n.t('preparation.model.one')} > `;
    allDisplayableProperties.push(
      ...Preparation.allDisplayableProperties(rootStore, `${pathPrefix}preparation.`, preparationPrefix)
    );

    const originStorageUnitPrefix = `${titlePrefix}${i18n.t('storageUnit.model.one')} > `;
    allDisplayableProperties.push(
      ...StorageUnit.allDisplayableProperties(rootStore, `${pathPrefix}originStorageUnit.`, originStorageUnitPrefix)
    );

    const targetYieldPrefix = `${titlePrefix}${i18n.t('yield.model.one')} > `;
    allDisplayableProperties.push(
      ...Yield.allDisplayableProperties(rootStore, `${pathPrefix}targetYield.`, targetYieldPrefix)
    );

    const unitOfMeasurePrefix = `${titlePrefix}${i18n.t('unitOfMeasurement.model.one')} > `;
    allDisplayableProperties.push(
      ...UnitOfMeasurement.allDisplayableProperties(rootStore, `${pathPrefix}unitOfMeasure.`, unitOfMeasurePrefix)
    );

    const createdByUserPrefix = `${titlePrefix}${i18n.t('consumption.model.attributes.createdBy')} > `;
    allDisplayableProperties.push(
      ...User.allDisplayableProperties(rootStore, `${pathPrefix}createdByUser.`, createdByUserPrefix)
    );

    const updatedByUserPrefix = `${titlePrefix}${i18n.t('consumption.model.attributes.updatedBy')} > `;
    allDisplayableProperties.push(
      ...User.allDisplayableProperties(rootStore, `${pathPrefix}updatedByUser.`, updatedByUserPrefix)
    );

    return allDisplayableProperties;
  }

  get createdByUser() {
    return this.rootStore.userStore.getById(this.createdBy);
  }

  get updatedByUser() {
    return this.rootStore.userStore.getById(this.updatedBy);
  }

  get component() {
    return this.rootStore.componentStore.getById(this.componentId);
  }

  get preparation() {
    return this.rootStore.preparationStore.getById(this.preparationId);
  }

  get batch() {
    return this.rootStore.batchStore.getById(this.batchId);
  }

  get originStorageUnit() {
    return this.rootStore.storageUnitStore.getById(this.originStorageUnitId);
  }

  get targetYield() {
    return this.rootStore.yieldStore.getById(this.targetYieldId);
  }

  get unitOfMeasure() {
    return this.rootStore.unitOfMeasurementStore.getById(this.unitOfMeasureId);
  }
}
