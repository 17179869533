import { computed, observable, makeObservable } from 'mobx';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import i18n from '../i18n/i18n';
import { RootStore } from '../stores/rootStore';

/**
 * custom property translations:
 * t('reportType.types.CONSUMPTION')
 * t('reportType.types.INSPECTION_RESULT')
 * t('reportType.types.INVENTORY')
 * t('reportType.types.OPERATION_PERFORMANCE')
 * t('reportType.types.PHASE_PERFORMANCE')
 * t('reportType.types.PRINT_LABEL_CODE')
 * t('reportType.types.WORKPLACE_PERFORMANCE')
 * t('reportType.types.YIELD')
 * t('reportType.types.FULL_ORDER')
 * t('reportType.types.QUALITY_REPORT')
 */
export enum ReportTypeEnum {
  Consumption = 'CONSUMPTION',
  FullOrder = 'FULL_ORDER',
  InspectionResult = 'INSPECTION_RESULT',
  Inventory = 'INVENTORY',
  OperationPerformance = 'OPERATION_PERFORMANCE',
  PhasePerformance = 'PHASE_PERFORMANCE',
  PrintLabelCode = 'PRINT_LABEL_CODE',
  QualityReport = 'QUALITY_REPORT',
  WorkplacePerformance = 'WORKPLACE_PERFORMANCE',
  Yield = 'YIELD'
}

export interface ReportTypeAdditionalHeaders {
  [key: string]: string;
}

export class ReportType extends BaseModel {
  // TODO: remove id
  id: number = 0;
  type: ReportTypeEnum = ReportTypeEnum.Consumption;
  url: string | null = null;
  additionalHeaders: ReportTypeAdditionalHeaders = {};
  transformer: string | null = null;
  heldWorkplaceIds?: number[] = [];
  suppressedWorkplaceIds?: number[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      type: observable,
      url: observable,
      additionalHeaders: observable,
      typeFormatted: computed,
    });
  }

  static faIcon = faPaperPlane;

  searchableProperties = ['type', 'url'];

  saveableProperties = ['url', 'additionalHeaders'];

  get typeFormatted() {
    return i18n.t(`reportType.types.${this.type}`);
  }
}
