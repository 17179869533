import { computed, makeObservable, observable } from 'mobx';
import { RootStore } from '../stores/rootStore';
import { StateLog } from './stateLog';
import { CustomPropertyTypes } from './customPropertyDataTypes';

export class WorkplaceStateLog extends StateLog {
  workplaceId: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      state: computed,
      workplaceId: observable,
      workplace: computed,
    });
  }

  customPropertyType? = CustomPropertyTypes.OperationStateLog;

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);
    allDisplayableProperties.push(
      ...this.displayableCustomProperties(rootStore, pathPrefix, titlePrefix)
    );

    return allDisplayableProperties;
  }

  get state() {
    return this.rootStore.workplaceStateStore.getById(this.stateId);
  }

  get workplace() {
    return this.rootStore.workplaceStore.getById(this.workplaceId);
  }
}
