import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope, faEnvelopeOpen, faPaperclip} from '@fortawesome/free-solid-svg-icons';
import {Typography} from 'antd';

const {Paragraph} = Typography;

const MessageListItem = ({
  message,
  isSelected,
  onSelect,
}) => {
  const primaryColor = '#108ee9';
  const lightGrey = '#8E8E8E';
  const darkGrey = '#404040';
  const selectedColor = '#E7F4FF';

  return (
    <div
      key={message.id}
      className={'message-item'}
      style={{
        minHeight: 100,
        padding: '24px 24px 24px 56px',
        borderBottom: '1px solid #f0f0f0',
        position: 'relative',
        backgroundColor: isSelected ? selectedColor : '',
        fontFamily: 'Roboto Mono',
        cursor: 'pointer',
      }}
      role={'button'}
      tabIndex={0}
      onClick={() => onSelect({id: message.id})}
    >
      <FontAwesomeIcon
        icon={
          message.isUnread ? faEnvelope : faEnvelopeOpen
        }
        style={{
          position: 'absolute',
          top: 28,
          left: 24,
          color: message.isUnread ? primaryColor : lightGrey,
        }}
      />
      <p style={{
        color: message.isUnread ? darkGrey : lightGrey,
        fontWeight: message.isUnread ? '700' : '500',
        padding: 0,
        marginBottom: 4,
        fontSize: '14px',
        lineHeight: '24px',
      }}
      >
        {message.subject}
        {message.hasDocuments
          ? (
            <FontAwesomeIcon
              icon={faPaperclip}
              style={{
                marginLeft: 8,
                color: message.isUnread ? darkGrey : lightGrey,
              }}
            />
          )
          : null}
      </p>
      <Paragraph
        ellipsis={{rows: 3}}
        style={{
          color: message.isUnread ? darkGrey : lightGrey,
          fontWeight: message.isUnread ? '500' : '300',
        }}
      >
        {message.message}
      </Paragraph>
    </div>
  );
};

export default observer(MessageListItem);
