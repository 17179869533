import { observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { RootStore } from './rootStore';
import { FaultCollection } from '../models/faultCollection';

export class FaultCollectionStore extends EntityStore<FaultCollection> {
  faultCollections: FaultCollection[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'faultCollections', Api.faultCollections, FaultCollection, true);

    makeObservable(this, {
      faultCollections: observable,
    });
  }
}
