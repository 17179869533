/* eslint-disable no-plusplus */
import {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import {round} from 'lodash';
import CockpitWidget from './CockpitWidget';
import {WidgetLayout} from '../../../../models/widgetLayout';
import {WidgetLayouts} from '../../../shared/widgets/WidgetLayouts';
import {DataTableWidgetConfig} from './config/dataTableWidgetConfig';
import {useMount} from '../../../../hooks/useMount';
import TransposableTable from '../../../shared/tables/TransposableTable';
import {WorkplaceCockpitRoutes} from '../../workplaceCockpit/routes';
import {EnDash} from '../../../shared/unicodeWrapper/EnDash';
import {useStore, useStoreLoad} from '../../../../hooks/useStore';
import {useMQTTInspectionTasksPendingSubscription} from '../../../../hooks/useMQTT';
import OverlaySpinner from '../../../shared/spinners/OverlaySpinner';
import ColorValue from '../../../shared/dataDisplay/ColorValue';
import {getStateColor} from '../../../../utils/stateColor';
import {TimeframeOptions} from './config/timeframeAggregationOptions';

const DataTableWidget = ({
  widgetId,
  title,
  ...props
}) => {
  const store = useStore();
  const widgetConfig = useMemo(() => new DataTableWidgetConfig({
    rootStore: store,
    identifier: DataTableWidget.Config.widgetType.identifier,
    widgetId,
  }), []);
  widgetConfig.currentWorkplaceId = store.cockpitStore.currentWorkplaceId;

  const workplacesSource = widgetConfig.getWorkplacesSource();
  const showWorkplace = widgetConfig.getShowWorkplace();
  const linkWorkplaceTo = widgetConfig.getSettingValue('settings', 'linkWorkplaceTo');
  const linkWorkplaceInNewTab = widgetConfig.getSettingValue('settings', 'linkWorkplaceInNewTab');
  const [workplaces, setWorkplaces] = useState([]);
  const [rerender, setRerender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const orientation = widgetConfig.getSettingValue('settings', 'orientation');
  const fieldSettings = widgetConfig.getSettingValue('dataSource', 'fields') || [];
  const selectedHierarchies = widgetConfig.setSelectedWorkplaces();

  const [workplaceKpis, setWorkplaceKpis] = useState([]);

  if (store.contextStore.isOperatorContext) {
    useMQTTInspectionTasksPendingSubscription(store);
  }

  useStoreLoad(() => {
    store.keyPerformanceIndicatorStore.loadAll();
  });

  useMount(() => {
    store.propertyStore.loadAll();
  }, []);

  // Occasionally force a render to update time based values such as overdue pending tasks
  useMount(() => {
    const timeout = setTimeout(() => {
      // stop rendering in edit mode to prevent uncontrolled behavior of components in edit modal
      if (!props.isEditMode) {
        setRerender(!rerender);
      }
    }, 15000);

    return () => {
      clearTimeout(timeout);
    };
  }, [rerender, props.isEditMode]);

  // Load data in a staggered manner to prevent overloading the backend
  const loadData = async (workplacesToDisplay, loadActions, sensors, kpis) => {
    const timeframe = widgetConfig.getSettingValue('settings', 'timeframe');
    const amountOfDays = widgetConfig.getSettingValue('settings', 'amountOfDays');

    let fromDate;
    let toDate;
    const kpiDataByWorkplace = [];

    switch (timeframe) {
      case TimeframeOptions.TODAY:
        fromDate = dayjs().startOf('day').toISOString();
        toDate = dayjs().endOf('day').toISOString();
        break;
      case TimeframeOptions.YESTERDAY:
        fromDate = dayjs().subtract(1, 'day').startOf('day').toISOString();
        toDate = dayjs().subtract(1, 'day').endOf('day').toISOString();
        break;
      case TimeframeOptions.THIS_WEEK:
        fromDate = dayjs().startOf('week').toISOString();
        toDate = dayjs().endOf('week').toISOString();
        break;
      case TimeframeOptions.LAST_WEEK:
        fromDate = dayjs().subtract(1, 'week').startOf('week').toISOString();
        toDate = dayjs().subtract(1, 'week').endOf('week').toISOString();
        break;
      case TimeframeOptions.DAYS_BACK:
        fromDate = dayjs().subtract(amountOfDays, 'day').startOf('day').toISOString();
        toDate = dayjs().endOf('day').toISOString();
        break;
      default:
        fromDate = dayjs().subtract(amountOfDays, 'day').startOf('day').toISOString();
        toDate = dayjs().endOf('day').toISOString();
    }

    for (let wpIdx = 0; wpIdx < workplacesToDisplay.length; wpIdx++) {
      const promises = [];
      const workplace = workplacesToDisplay[wpIdx];

      for (let laIdx = 0; laIdx < loadActions.length; laIdx++) {
        promises.push(widgetConfig[loadActions[laIdx]](workplace.id));
      }

      for (let snIdx = 0; snIdx < sensors.length; snIdx++) {
        store.sensorStore.load(sensors[snIdx], {onlyIfEmpty: true});
        promises.push(store.sensorDataStore.loadLatestByWorkplace(sensors[snIdx], workplace.id));
      }

      if (kpis?.length) {
        store.keyPerformanceIndicatorStore.loadMany(kpis, {onlyIfEmpty: true});
        // eslint-disable-next-line no-await-in-loop
        const kpiData = await store.keyPerformanceIndicatorStore.loadKpiByTime({
          workplaceId: workplace.id,
          fromDate,
          toDate,
          resolution: 1,
          kpiId: kpis,
          clampToNow: true,
        });
        kpiDataByWorkplace.push({
          workplaceId: workplace.id,
          kpiData,
        });
      }
      // eslint-disable-next-line no-await-in-loop
      await Promise.all(promises);
    }
    setWorkplaceKpis(kpiDataByWorkplace);
    setIsLoading(false);
  };

  useMount(() => {
    const workplacesToDisplay = [];
    const selectedWorkplaces = selectedHierarchies.map(
      (hierarchyId) => store.workplaceStore.getByHierarchyId(hierarchyId)
    ).filter((wp) => !!wp);

    setIsLoading(true);
    if (store.cockpitStore.currentWorkplaceId) {
      const currentWorkplace = store.workplaceStore.getById(store.cockpitStore.currentWorkplaceId);
      const source = widgetConfig.getWorkplacesSource();

      switch (source) {
        case DataTableWidgetConfig.SOURCE_HIERARCHY:
          workplacesToDisplay.push(currentWorkplace);
          break;
        case DataTableWidgetConfig.SOURCE_GROUP:
          if (currentWorkplace && currentWorkplace.groupId) {
            const workplaceGroup = store.workplaceGroupStore.getById(currentWorkplace.groupId);
            if (workplaceGroup) {
              workplacesToDisplay.push(...workplaceGroup.workplaces);
            }
          } else {
            workplacesToDisplay.push(currentWorkplace);
          }
          break;
        default:
          workplacesToDisplay.push(...selectedWorkplaces);
          break;
      }
    } else {
      workplacesToDisplay.push(...selectedWorkplaces);
    }

    const modelOptions = new Set();
    const sensors = new Set();
    const kpis = new Set();
    // get the set of required model options
    fieldSettings.forEach((field) => {
      const modelOption = widgetConfig.modelOptions.find((model) => model.key === field.model);
      if (modelOption?.loadAction) {
        modelOptions.add(modelOption);
      }
      if (field.model.startsWith('sensor')) {
        sensors.add(field.property);
      }
      if (field.model.startsWith('keyPerformanceIndicator')) {
        kpis.add(field.property);
      }
    });
    const operationLoadAction = widgetConfig.modelOptions.find((model) => model.key === 'operation');
    if (!modelOptions.has(operationLoadAction)) {
      modelOptions.add(operationLoadAction);
    }
    // load all dependencies per workplace and model option
    const loadActions = new Set();
    modelOptions.forEach((modelOption) => {
      loadActions.add(modelOption.loadAction);
    });
    loadData(workplacesToDisplay, Array.from(loadActions), Array.from(sensors), Array.from(kpis));
    setWorkplaces(workplacesToDisplay);
  }, [
    store.cockpitStore.currentWorkplaceId,
    store.workplaceGroupStore.workplaceGroups.length,
    widgetConfig,
    workplacesSource,
    selectedHierarchies.length,
  ]);

  useMount(() => {
    store.workplaceStore.addMonitoredWorkplaces(workplaces);
  }, [workplaces]);

  const columns = fieldSettings.map((field) => ({
    title: field.label,
    key: `${field.model}-${field.property}`,
    dataIndex: `${field.model}-${field.property}`,
  }));

  const formatKPIValue = (field, workplace) => {
    const workplaceKpiData = workplaceKpis.find(({workplaceId}) => workplaceId === workplace.id);
    /** @type {KeyPerformanceIndicator} */
    const kpi = store.keyPerformanceIndicatorStore.getById(field.property);
    const kpiData = workplaceKpiData?.kpiData?.find((e) => e.kpiId === field.property);
    let value = kpiData?.data?.[0]?.y;
    value = kpi?.calculateToPercentageValue(value) || value;

    if (value !== undefined) {
      value = round(value, 2);
    }
    return `${value !== undefined ? value : EnDash()} ${kpi?.unitOfMeasure?.label || ''}`;
  };

  const dataSource = workplaces.map((workplace, wpIndex) => {
    const data = {};
    if (showWorkplace) {
      data.workplaceId = workplace.id;
      data.key = workplace?.id;
    }

    fieldSettings.forEach((field, fieldIndex) => {
      const modelOption = widgetConfig.modelOptions.find((model) => model.key === field.model);
      const record = modelOption?.getRecord({workplaceId: workplace.id});

      if (field.model.startsWith('sensor')) {
        const sensor = store.sensorStore.getById(field.property);
        const sensorData = store.sensorDataStore.getLatestByWorkplace(field.property, workplace.id);
        if (field.model === 'sensor' && sensorData?.value) {
          const value = sensor?.roundedValue(sensorData?.value);
          const uom = sensor?.unitOfMeasure?.label || '';
          data[`${field.model}-${field.property}`] = `${value} ${uom}`;
        } else if (field.model === 'sensorState' && sensorData?.state) {
          data[`${field.model}-${field.property}`] = (<ColorValue value={getStateColor(sensorData.state)}/>);
        } else {
          data[`${field.model}-${field.property}`] = EnDash();
        }
      } else if (field.model.startsWith('keyPerformanceIndicator')) {
        data[`${field.model}-${field.property}`] = formatKPIValue(field, workplace);
      } else if (!record) {
        data[`${field.model}-${field.property}`] = EnDash();
      } else {
        const value = modelOption?.properties.find((prop) => prop.key === field.property)?.render(null, record);

        if (value) {
          data[`${field.model}-${field.property}`] = value.children;
        }
      }

      data.rowId = `${wpIndex}-${fieldIndex}`;
    });
    return data;
  });

  const renderWorkplaceLink = (workplace) => {
    const label = workplace?.label;
    let link;
    if (linkWorkplaceTo === DataTableWidgetConfig.LINK_WORKPLACE_TO_COCKPIT) {
      link = `${window.location.origin}${WorkplaceCockpitRoutes.sharedCockpit(workplace?.no)}`;
    } else if (linkWorkplaceTo === DataTableWidgetConfig.LINK_WORKPLACE_TO_PRODUCTION) {
      link = `${window.location.origin}/production/${workplace?.no}`;
    }
    if (linkWorkplaceTo === DataTableWidgetConfig.LINK_WORKPLACE_NOWHERE) {
      return label;
    }
    return (
      <a href={link} target={linkWorkplaceInNewTab ? '_blank' : '_self'} rel="noopener noreferrer">
        {label}
      </a>
    );
  };

  return (
    <CockpitWidget
      widgetId={widgetId}
      title={title}
      widgetConfig={widgetConfig}
      {...props}
    >
      {isLoading && (
        <OverlaySpinner/>
      )}
      <TransposableTable
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        vertical={orientation === 'vertical'}
        rowKey={'rowId'}
        headerKey={showWorkplace ? 'workplaceId' : null}
        renderHeader={(id) => {
          const workplace = store.workplaceStore.getById(id);
          return (
            <span>
              {renderWorkplaceLink(workplace)}
            </span>
          );
        }}
      />
    </CockpitWidget>
  );
};

DataTableWidget.Config = DataTableWidgetConfig;
DataTableWidget.defaultLayout = new WidgetLayout({
  identifier: CockpitWidget.identifier,
  width: 4,
  height: 5,
  minHeight: 3,
  minWidth: WidgetLayouts.sixthWidth.minW,
});
export default observer(DataTableWidget);
