import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { observable, makeObservable } from 'mobx';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';
import { DataExportType } from './dataExportScheduler';

export class DataExportTemplate extends BaseModel {
  id: number = 0;
  name: string = '';
  type: DataExportType = DataExportType.KPI;
  columns: string = '';
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      type: observable,
      columns: observable,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faFileExport;

  searchableProperties = [
    'name',
  ];
}

/*
 * Translations:
 * t('dataExport.manualExport.calculationType.months')
 * t('dataExport.manualExport.calculationType.weeks')
 * t('dataExport.manualExport.calculationType.days')
 * t('dataExport.manualExport.calculationType.hours')
 * t('dataExport.manualExport.calculationType.minutes')
 */
export const RangeType = Object.freeze({
  MONTHS: 'months',
  WEEKS: 'weeks',
  DAYS: 'days',
  HOURS: 'hours',
  MINUTES: 'minutes',
});
