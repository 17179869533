import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Alert from 'antd/es/alert';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import {useStore} from '../../../../hooks/useStore';
import {useMount} from '../../../../hooks/useMount';
import {TagInterruptionAnalysisWidgetConfig} from './config/tagInterruptionAnalysisWidgetConfig';
import CockpitWidget from './CockpitWidget';
import {getFromToResolutionByOptions} from './config/timeframeAggregationOptions';
import {WidgetLayout} from '../../../../models/widgetLayout';
import {WidgetLayouts} from '../../../shared/widgets/WidgetLayouts';
import TagInterruptionAnalysisChart from './TagInterruptionAnalysisChart';
import useWorkplaceRelatedWidgetTitle from '../../../../hooks/useWorkplaceRelatedWidgetTitle';

const TagInterruptionAnalysisWidget = ({
  widgetId,
  title,
  ...props
}) => {
  const store = useStore();
  const [selectedWorkplaceId, setSelectedWorkplaceId] = useState();
  const [dates, setDates] = useState({
    fromDate: dayjs().toISOString(),
    toDate: dayjs().toISOString(),
  });
  const {t} = useTranslation();
  const widgetConfig = useMemo(() => new TagInterruptionAnalysisWidget.Config({
    rootStore: store,
    identifier: TagInterruptionAnalysisWidget.identifier,
    widgetId,
  }), []);
  const widgetTitle = useWorkplaceRelatedWidgetTitle(title, selectedWorkplaceId);

  const tagKeys = widgetConfig.getSettingValue('tagInterruptionAnalysis', 'tags');

  useMount(() => {
    const configHierarchyId = widgetConfig.getSettingValue('workplace', 'hierarchyId');
    if (configHierarchyId) {
      setSelectedWorkplaceId(store.workplaceStore.getByHierarchyId(configHierarchyId)?.id);
    } else if (store.cockpitStore.currentWorkplaceId) {
      setSelectedWorkplaceId(store.cockpitStore.currentWorkplaceId);
    } else {
      setSelectedWorkplaceId(undefined);
    }
  }, [
    store.cockpitStore.currentWorkplaceId,
    widgetConfig.getSettingValue('workplace', 'hierarchyId'),
  ]);

  useMount(() => {
    const timeframe = widgetConfig.getSettingValue('tagInterruptionAnalysis', 'timeframe');
    const daysBack = widgetConfig.getSettingValue('tagInterruptionAnalysis', 'daysBack');
    const {from, to} = getFromToResolutionByOptions(timeframe, null, daysBack);
    setDates({
      fromDate: from,
      toDate: to,
    });
  }, [
    widgetConfig.getSettingValue('tagInterruptionAnalysis', 'timeframe'),
    widgetConfig.getSettingValue('tagInterruptionAnalysis', 'daysBack'),
  ]);

  return (
    <CockpitWidget
      widgetId={widgetId}
      title={widgetTitle}
      widgetConfig={widgetConfig}
      {...props}
    >
      {
        !selectedWorkplaceId
          ? <Alert type={'info'} message={t('TagInterruptionAnalysisWidget.alert.noWorkplaceSelected')}/>
          : ''
      }
      {
        selectedWorkplaceId
          ? (
            <TagInterruptionAnalysisChart
              tagKeys={tagKeys}
              fromDate={dates.fromDate}
              toDate={dates.toDate}
              workplaceId={selectedWorkplaceId}
            />
          )
          : ''
      }
    </CockpitWidget>
  );
};

TagInterruptionAnalysisWidget.Config = TagInterruptionAnalysisWidgetConfig;
TagInterruptionAnalysisWidget.defaultLayout = new WidgetLayout({
  identifier: CockpitWidget.identifier,
  width: 4,
  height: 16,
  minHeight: 4,
  minWidth: WidgetLayouts.sixthWidth.minW,
});
export default observer(TagInterruptionAnalysisWidget);
