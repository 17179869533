import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FormModal from '../shared/modal/FormModal';
import {useStore} from '../../hooks/useStore';
import {SensorData as Model} from '../../models/sensorData';
import {useMount} from '../../hooks/useMount';
import ManualSensorDataWidgetForm from './ManualSensorDataWidgetForm';
import {Actions} from '../../stores/entityStore';

export const ManualSensorDataWidgetModal = ({widgetConfig, onCancel, sensors}) => {
  const store = useStore();

  useMount(() => {
    store.yieldStore.loadByOperation(store.operationStore.active?.id);
  });

  const handleSubmit = (values) => {
    const workplaceId = store.workplaceStore.selectedWorkplace.id;
    const orderId = widgetConfig.getIncludeOrderId() ? store.operationStore.active?.orderId : undefined;

    store.sensorDataStore.create({
      sensorName: values.sensorName,
      value: values.value,
      workplaceId,
      orderId,
    });
    onCancel();
  };

  return (
    <FormModal
      fullscreen={store.clientStore.isMobile}
      title={(
        <>
          <FontAwesomeIcon icon={Model.faIcon}/>
          {' '}
          {widgetConfig.getWidgetTitle()}
        </>
        )}
      open
      width={'600px'}
      onCancel={onCancel}
    >
      <ManualSensorDataWidgetForm
        sensors={sensors}
        onCancel={onCancel}
        onSubmit={handleSubmit}
        saving={store.sensorDataStore.isActionInProgress(Actions.create)}
      />
    </FormModal>
  );
};

export default observer(ManualSensorDataWidgetModal);
