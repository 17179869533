import {isEmpty, sortBy} from 'lodash';
import dayjs from 'dayjs';
import {action, computed, observable, makeObservable} from 'mobx';
import i18n from '../i18n/i18n';
import {Api} from '../middleware/api';
import {EntityStore} from './entityStore';
import {WorkflowCorrection} from '../models/workflowCorrection';
import {logTypes} from '../components/workflowCorrection/shared/StateHistory';

export const editTypes = {
  create: 'CREATE',
  update: 'UPDATE',
  delete: 'DELETE',
};

const applyChanges = (initialLogs, changes) => sortBy(changes.reduce((logs, change) => {
  switch (change.editType) {
    case editTypes.update:
      return logs.map((log) => {
        if (log.id === change.action.id) {
          if (log.personnel === undefined) {
            return {
              ...log,
              start: change.action.start,
              end: change.action.end,
              stateId: change.action.stateId,
              operationId: change.action.operationId,
              interruptionReasonId: change.action.interruptionReasonId,
              interruptionClassId: change.action.interruptionClassId,
              interruptionSourceHierarchyId: change.action.interruptionSourceHierarchyId,
              message: change.action.message,
              isEdited: true,
            };
          }
          return {
            ...log,
            createdAt: change.action.start,
            start: change.action.start,
            end: change.action.end,
            personnel: change.action.personnel,
            isEdited: true,
          };
        }
        return log;
      });
    case editTypes.create:
      return [...logs, {...change.action, isEdited: true}];
    case editTypes.delete:
      return logs.filter((log) => log.id !== change.action.id);
    default:
      return logs;
  }
}, initialLogs), (log) => (log.start ? log.start : log.createdAt));

export class WorkflowCorrectionStore extends EntityStore {
  workflowCorrections = []; // not used
  editStart = null;
  editEnd = null;
  operationStateLogChanges = [];
  workplaceStateLogChanges = [];
  personnelLogChanges = [];
  validationError = {};
  workplaceLock = null;

  constructor(rootStore) {
    super(rootStore, 'workflowCorrections', Api.workflowCorrections, WorkflowCorrection);

    makeObservable(this, {
      operationStateLogChanges: observable,
      workplaceStateLogChanges: observable,
      personnelLogChanges: observable,
      getLocalChanges: action,
      workplace: computed,
      operationStateLogs: computed,
      workplaceStateLogs: computed,
      personnelLogs: computed,
      validationError: observable,
      validationErrorLogs: computed,
      workplaceLock: observable,
      workplaceId: computed,
    });

    window.store = this;
  }

  async selectWorkplace(workplaceId) {
    const key = await this.rootStore.objectLockStore.acquire(WorkflowCorrection, workplaceId);
    if (key) {
      this.workplaceLock = {
        key,
        workplaceId,
      };
    }
  }

  async releaseWorkplace() {
    if (this.workplaceLock) {
      try {
        await this.rootStore.objectLockStore.release(this.workplaceLock.key);
      } finally {
        this.workplaceLock = null;
      }
    }
  }

  get workplaceId() {
    return this.workplaceLock?.workplaceId;
  }

  get workplace() {
    return this.rootStore.workplaceStore.getById(this.workplaceId);
  }

  request({changed, apiEndpoint, skipNotification = false, ...changes}) {
    if (!changed || isEmpty(changes)) {
      return null;
    }
    return this.create(changes, {
      raw: true,
      apiEndpoint,
      skipNotification,
    }).then((response) => {
      this.validationError = {};
      return response?.data;
    }).catch((error) => {
      if (error.name) {
        // this is an API error, meaning we sent an invalid request. This should not happen during normal usage.
        this.rootStore.flashMessageStore
          .addFlashMessage({type: 'error', title: i18n.t('workflowCorrection.errors.validationFailed')});
        this.validationError = {error: error.name};
      } else {
        this.validationError = error;
      }
      return error;
    });
  }

  verify(args) {
    return this.request({
      apiEndpoint: 'verify',
      skipNotification: true,
      ...args,
    });
  }

  save(args) {
    return this.request({
      apiEndpoint: 'save',
      ...args,
    });
  }

  resetChanges() {
    this.editStart = null;
    this.editEnd = null;
    this.operationStateLogChanges = [];
    this.workplaceStateLogChanges = [];
    this.personnelLogChanges = [];
    this.validationError = {};
  }

  getLocalChanges(user, {reportErp = false, dates = {}, warn = true}) {
    if (!(this.operationStateLogChanges.length
      || this.workplaceStateLogChanges.length
      || this.personnelLogChanges.length)
    ) {
      if (warn) {
        this.rootStore.flashMessageStore.addFlashMessage({
          type: 'warning', title: i18n.t('workflowCorrection.errors.noChangesFound'),
        });
      }
      return {
        changed: false,
      };
    }
    return {
      userId: user.id,
      from: dates.dateTimeFrom || this.editStart,
      to: dates.dateTimeTo || this.editEnd,
      workplaceId: this.workplaceId,
      reportErp,
      operationLogChanges: sortBy(this.operationStateLogChanges, 'start'),
      workplaceLogChanges: sortBy(this.workplaceStateLogChanges, 'start'),
      personnelLogChanges: sortBy(this.personnelLogChanges, 'createdAt'),
      changed: true,
    };
  }

  addChange(logType, editType, change) {
    if (logType === logTypes.operationStateLog) {
      this.updateEditTimeSpan(change.start, change.end);
      this.operationStateLogChanges.push({
        editType,
        action: {
          id: change.id,
          start: change.start,
          // Ensures that a change to an outdated, local active log entry does not create an inconsistent state log.
          // https://autexis.myjetbrains.com/youtrack/issue/DF40-388
          end: change.end === null ? undefined : change.end,
          stateId: change.stateId,
          operationId: change.operationId,
          interruptionReasonId: change.interruptionReasonId,
          interruptionClassId: change.interruptionClassId,
          interruptionSourceHierarchyId: change.interruptionSourceHierarchyId,
          message: change.message,
        },
      });
    } else if (logType === logTypes.workplaceStateLog) {
      this.updateEditTimeSpan(change.start, change.end);
      this.workplaceStateLogChanges.push({
        editType,
        action: {
          id: change.id,
          start: change.start,
          // Ensures that a change to an outdated, local active-log-entry does not create an inconsistent state log.
          // https://autexis.myjetbrains.com/youtrack/issue/DF40-388
          end: change.end === null ? undefined : change.end,
          stateId: change.stateId,
          workplaceId: change.workplaceId,
          interruptionReasonId: change.interruptionReasonId,
          interruptionClassId: change.interruptionClassId,
          interruptionSourceHierarchyId: change.interruptionSourceHierarchyId,
          message: change.message,
        },
      });
    } else if (logType === logTypes.personnelLog) {
      this.updateEditTimeSpan(change.start, change.end);
      this.personnelLogChanges.push({
        editType,
        action: {
          id: change.id,
          start: change.start || change.createdAt,
          createdAt: change.start || change.createdAt,
          personnel: change.personnel,
          workplaceId: change.workplaceId,
        },
      });
    }
  }

  updateEditTimeSpan(start, end) {
    this.editStart = !this.editStart || start < this.editStart ? start : this.editStart;
    this.editEnd = !this.editEnd || end > this.editStart ? end : this.editEnd;
  }

  get operationStateLogs() {
    const initialOperationStateLogs = this.rootStore.stateHistoryStore.stateHistoryOfWorkplace.operationStateLogs;
    if (initialOperationStateLogs && this.operationStateLogChanges) {
      const logs = applyChanges(initialOperationStateLogs, this.operationStateLogChanges);
      return this.markValidationError('operation', logs);
    }
    return [];
  }

  get workplaceStateLogs() {
    const initialWorkplaceStateLogs = this.rootStore.stateHistoryStore.stateHistoryOfWorkplace.workplaceStateLogs;
    if (initialWorkplaceStateLogs && this.workplaceStateLogChanges) {
      const logs = applyChanges(initialWorkplaceStateLogs, this.workplaceStateLogChanges);
      return this.markValidationError('workplace', logs);
    }
    return [];
  }

  get personnelLogs() {
    const initialPersonnelLogs = this.rootStore.stateHistoryStore.stateHistoryOfWorkplace.personnelLogs;
    if (initialPersonnelLogs && this.personnelLogChanges) {
      return applyChanges(initialPersonnelLogs, this.personnelLogChanges);
    }
    return [];
  }

  get validationErrorLogs() {
    return this.validationError.stateLogIds || {
      operation: [],
      workplace: [],
    };
  }

  markValidationError(logType, logs) {
    const errorLogIds = this.validationErrorLogs[logType];
    if (errorLogIds.length > 0) {
      return logs.map((log) => {
        if (errorLogIds.includes(log.id)) {
          return {
            ...log,
            error: true,
          };
        }
        return log;
      });
    }
    return logs;
  }

  // eslint-disable-next-line class-methods-use-this
  combineStateLogs(stateHistory) {
    const allLogs = [];
    allLogs.push(...stateHistory.operationStateLogs);
    allLogs.push(...stateHistory.workplaceStateLogs);
    return sortBy(allLogs, 'start');
  }

  // eslint-disable-next-line class-methods-use-this
  getLogAtTimeByType = (logType, time, prevItems, currentItems, nextItems) => {
    let log = null;
    if (time?.isBetween(
      dayjs(currentItems[logType]?.start),
      dayjs(currentItems[logType]?.end || undefined),
      null,
      '()'
    )) {
      log = currentItems[logType]?.log;
    } else if (time?.isBetween(
      dayjs(prevItems[logType]?.start),
      dayjs(prevItems[logType]?.end || undefined).add(1, 'ms'),
      null,
      '[]'
    )) {
      log = prevItems[logType]?.log;
    } else if (time?.isBetween(
      dayjs(nextItems[logType]?.start).subtract(1, 'ms'),
      dayjs(nextItems[logType]?.end || undefined),
      null,
      '[]'
    )) {
      log = nextItems[logType]?.log;
    }
    return log;
  };
}
