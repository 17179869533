import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import InspectionTaskDescription from './InspectionTaskDescription';
import {transformation} from '../../../../utils/transformations';
import Button from '../../../shared/buttons/Button';
import {datetimeFormat} from '../../../../config/dayjs';
import {useStore} from '../../../../hooks/useStore';
import {useModulePolicy} from '../../../../hooks/useModulePolicy';
import {taskActivation} from '../../../../models/scope';
import UserFullName from '../../../shared/UserFullName';

const BlameSection = ({fallback, task, setDeactivateModalVisible}) => {
  const {t} = useTranslation();
  const store = useStore();
  const policy = useModulePolicy(store, [taskActivation]);
  const getUserName = (userId) => {
    const user = store.userStore.getById(userId);
    return <UserFullName user={user}/>;
  };

  return (
    <>
      <InspectionTaskDescription title={t('inspectionTask.drilldownModal.section.blame')}>
        <InspectionTaskDescription.Item
          label={t('inspectionTask.model.attributes.updatedAt')}
        >
          {fallback(
            task.updatedAt,
            transformation.datetime({format: datetimeFormat})
          )}
        </InspectionTaskDescription.Item>
        <InspectionTaskDescription.Item
          label={t('inspectionTask.model.attributes.updatedBy')}
        >
          {fallback(getUserName(task.updatedBy))}
        </InspectionTaskDescription.Item>
        <InspectionTaskDescription.Item
          label={t('inspectionTask.model.attributes.deactivatedAt')}
        >
          {fallback(
            task.deactivatedAt,
            transformation.datetime({format: datetimeFormat})
          )}
        </InspectionTaskDescription.Item>
        <InspectionTaskDescription.Item
          label={t('inspectionTask.model.attributes.deactivatedBy')}
        >
          {fallback(getUserName(task.deactivatedBy))}
        </InspectionTaskDescription.Item>
        <InspectionTaskDescription.Item
          label={t('inspectionTask.model.attributes.deactivatedRemark')}
        >
          {fallback(task.deactivatedRemark)}
        </InspectionTaskDescription.Item>
      </InspectionTaskDescription>
      {task.deactivatedAt
        ? (
          <Button
            disabled={!policy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})}
            onClick={() => store.inspectionTaskStore.activate(task.id)}
          >
            {t('inspectionTask.actions.reactivate')}
          </Button>
        )
        : (
          <Button
            disabled={!policy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})}
            onClick={() => setDeactivateModalVisible(true)}
          >
            {t('inspectionTask.actions.deactivate')}
          </Button>
        )}
    </>
  );
};

export default observer(BlameSection);
