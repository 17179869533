import {useTranslation} from 'react-i18next';
import {TransitionStoreActions} from '../../../stores/transitionStore';
import {useStore} from '../../../hooks/useStore';
import Popconfirm from '../../shared/messaging/Popconfirm';

const OrderWidgetPopconfirm = ({
  children,
  showTransitionConfirm,
  onConfirmForce,
  onCancel,
}) => {
  const store = useStore();
  const {t} = useTranslation();

  const handleConfirmForce = () => {
    onConfirmForce();
  };

  const SingleTransitionPopover = () => (
    <Popconfirm
      title={t('orderWidget.transitionConfirm')}
      open={showTransitionConfirm}
      placement={'right'}
      onConfirm={(e) => {
        handleConfirmForce();
        e.stopPropagation();
      }}
      okButtonProps={{loading: store.transitionStore.isLoading(TransitionStoreActions.performTransition)}}
      onCancel={(e) => {
        onCancel();
        e.stopPropagation();
      }}
    />
  );

  return (
    <>
      {children}
      {showTransitionConfirm && (
        <SingleTransitionPopover/>
      )}
    </>
  );
};

export default OrderWidgetPopconfirm;
