import { observable, makeObservable } from 'mobx';
import { UserRole } from '../models/userRole';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { RootStore } from './rootStore';

export class UserRoleStore extends EntityStore<UserRole> {
  userRoles: UserRole[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'userRoles', Api.userRoles, UserRole);

    makeObservable(this, {
      userRoles: observable,
    });
  }
}
