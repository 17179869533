import { computed, observable, makeObservable } from 'mobx';
import { faFunction } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import i18n from '../i18n/i18n';
import { RootStore } from '../stores/rootStore';

/**
 * custom function translations:
 * t('customFunction.types.Helper')
 * t('customFunction.types.BatchGenerator')
 * t('customFunction.types.OneOffPrinter')
 * t('customFunction.types.CalculatedInspectionTask')
 * t('customFunction.types.InspectionEquipment')
 */
export enum CustomFunctionType {
  Helper = 'Helper',
  BatchGenerator = 'BatchGenerator',
  OneOffPrinter = 'OneOffPrinter',
  CalculatedInspectionTask = 'CalculatedInspectionTask',
  InspectionEquipment = 'InspectionEquipment'
}

export const MathjsTypes = [CustomFunctionType.CalculatedInspectionTask];

export class CustomFunction extends BaseModel {
  id: number = 0;
  name: string = '';
  label: string = '';
  params: string | null = null;
  body: string = '';
  type: CustomFunctionType = CustomFunctionType.Helper;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      label: observable,
      params: observable,
      body: observable,
      typeFormatted: computed,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faFunction;

  searchableProperties = ['name', 'typeFormatted', 'label'];

  saveableProperties = ['name', 'label', 'params', 'body', 'typeFormatted'];

  get typeFormatted() {
    return i18n.t(`customFunction.types.${this.type}`);
  }

  get paramsFormatted() {
    return this.params ? JSON.stringify(this.params) : null;
  }
}
