import React, { forwardRef } from 'react';
import { InputNumber as AntdInputNumber, InputNumberProps as AntdInputNumberProps } from 'antd';

export type InputNumberProps = AntdInputNumberProps & {
  ref?: React.ForwardedRef<any>;
};

const InputNumber: React.FC<InputNumberProps> = forwardRef((props, ref) => (
  <AntdInputNumber
    ref={ref}
    style={{
      width: '100%',
    }}
    {...props}
  />
));

export default InputNumber;
