import { observable, makeObservable } from 'mobx';
import { forEach, groupBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { ReactNode } from 'react';
import i18n from '../i18n/i18n';
import { Document } from './document';

interface DocumentTreeEntry {
  title: string;
  key: string;
  isLeaf: boolean;
  icon: ReactNode | (({ expanded }: { expanded: boolean }) => ReactNode);
  document?: Document;
  isDocumentType?: boolean;
  children?: DocumentTreeEntry[];
}

export class RelevantDocuments {
  hierarchyDocuments: Document[] = [];
  materialDocuments: Document[] = [];
  changeDocuments: Document[] = [];

  constructor() {
    makeObservable(this, {
      hierarchyDocuments: observable,
      materialDocuments: observable,
      changeDocuments: observable,
    });
  }

  static getRelevantDocumentsTree(relevantDocuments: RelevantDocuments, categoryEntries?: number) {
    const rootHierarchyDocuments = relevantDocuments.hierarchyDocuments?.filter(
      (d) => (d.hierarchies ? d.hierarchies.some((h) => h.parentId === null) : false)
    );
    const hierarchyDocuments = relevantDocuments.hierarchyDocuments?.filter(
      (d) => (d.hierarchies ? !d.hierarchies.some((h) => h.parentId === null) : false)
    );
    return [
      {
        title: i18n.t('document.types.generalDocuments'),
        key: '0',
        icon: ({ expanded }: { expanded: boolean }) => (
          expanded ? <FontAwesomeIcon icon={faFolderOpen}/> : <FontAwesomeIcon icon={faFolder}/>
        ),
        children: this.getDocumentCategories('0', rootHierarchyDocuments, categoryEntries),
      },
      {
        title: i18n.t('document.types.hierarchyDocuments'),
        key: '1',
        icon: ({ expanded }: { expanded: boolean }) => (
          expanded ? <FontAwesomeIcon icon={faFolderOpen}/> : <FontAwesomeIcon icon={faFolder}/>
        ),
        children: this.getDocumentCategories('1', hierarchyDocuments, categoryEntries),
      },
      {
        title: i18n.t('document.types.materialDocuments'),
        key: '2',
        icon: ({ expanded }: { expanded: boolean }) => (
          expanded ? <FontAwesomeIcon icon={faFolderOpen}/> : <FontAwesomeIcon icon={faFolder}/>
        ),
        children: this.getDocumentCategories('2', relevantDocuments.materialDocuments, categoryEntries),
      },
      {
        title: i18n.t('document.types.changeDocuments'),
        key: '3',
        icon: ({ expanded }: { expanded: boolean }) => (
          expanded ? <FontAwesomeIcon icon={faFolderOpen}/> : <FontAwesomeIcon icon={faFolder}/>
        ),
        children: this.getDocumentCategories('3', relevantDocuments.changeDocuments, categoryEntries),
      },
    ];
  }

  static getDocumentCategories(keyPrefix: string, documents: Document[], categoryEntries?: number) {
    let list: DocumentTreeEntry[] = [];
    let count = 1;

    const groupedByDocumentType = groupBy(documents, 'documentTypeId');
    forEach(groupedByDocumentType, (value, key) => {
      if ((categoryEntries && count <= categoryEntries) || categoryEntries === undefined) {
        const treeKey = `${keyPrefix}-${key !== 'null' ? key : '0'}`;
        if (value[0].documentType) {
          list.push({
            // ...value[0].documentType,
            title: value[0].documentType.label,
            isDocumentType: true,
            icon: ({ expanded }: { expanded: boolean }) => (
              expanded ? <FontAwesomeIcon icon={faFolderOpen}/> : <FontAwesomeIcon icon={faFolder}/>
            ),
            key: treeKey,
            isLeaf: categoryEntries !== undefined,
            children: categoryEntries === undefined ? this.getDocumentTreeEntries(treeKey, value) : undefined,
          });
        } else {
          list = Array.of(...list, ...this.getDocumentTreeEntries(treeKey, value));
        }
        count += 1;
      }
    });
    return list.sort((a, b) => a.title?.localeCompare(b.title));
  }

  static getDocumentTreeEntries(keyPrefix: string, documents: Document[]): DocumentTreeEntry[] {
    return documents.map((document) => ({
      title: document.label,
      key: `${keyPrefix}-${document.id}`,
      isLeaf: true,
      icon: document.icon,
      document,
    }));
  }
}
