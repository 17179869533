const customFormItemLayout = (labelSpan: number, wrapperSpan: number) => ({
  labelCol: {
    xs: { span: 24 },
    sm: { span: labelSpan },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: wrapperSpan },
  },
});

export const FormItemLayouts = {
  default: customFormItemLayout(8, 16),

  fullWidth: {
    wrapperCol: {
      span: 24,
      offset: 0,
    },
    labelCol: {
      span: 24,
      offset: 0,
    },
  },

  modal: customFormItemLayout(8, 16),

  customFormItemLayout,
};
