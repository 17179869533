import i18n from '../../../../../i18n/i18n';
import {CockpitWidgetConfig} from './cockpitWidgetConfig';
import {FieldType} from '../../../../../types/widgetConfig';

export class OrderProgressWidgetConfig extends CockpitWidgetConfig {
  static onLoadConfig = (rootStore) => rootStore.interruptionClassStore.loadAll();

  static widgetType = {
    identifier: 'order-progress',
    name: () => i18n.t('cockpitWidget.widgetTypeIdentifier.orderProgress'),
  };

  // eslint-disable-next-line class-methods-use-this
  getWidgetTypeName() {
    return i18n.t('cockpitWidget.widgetTypeIdentifier.orderProgress');
  }

  settings = [
    {
      id: 'speedForCalculation',
      label: i18n.t('cockpitWidget.settings.speedForCalculation.label'),
      helpText: i18n.t('cockpitWidget.settings.speedForCalculation.help'),
      type: FieldType.Select,
      defaultValue: 'actualSpeed',
      options: [
        {
          value: 'actualSpeed',
          label: i18n.t('cockpitWidget.settings.actualSpeed'),
        },
        {
          value: 'plannedSpeed',
          label: i18n.t('cockpitWidget.settings.plannedSpeed'),
        },
      ],
    },
  ];

  get tabs() {
    return [
      this.generalSettingsTab,
      {
        key: 'settings',
        label: i18n.t('cockpitWidget.AddWidgetWizardModal.Settings'),
      },
      this.workplaceTab,
    ];
  }
}
