import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

export class WidgetLayout extends BaseModel {
  saveableProperties = ['identifier', 'x', 'y', 'height', 'width', 'disabled', 'minimized', 'minimizedOrder'];
  disabled?: boolean;
  identifier?: string;
  x?: number;
  y?: number;
  height?: number;
  minHeight?: number;
  width?: number;
  minWidth?: number;
  minimized?: boolean;
  minimizedOrder?: number;

  /**
   * @param identifier      how the layout is identified and mapped to a widget
   * @param x               the x-coordinate position on the grid layout
   * @param y               the y-coordinate position on the grid layout
   * @param height          the height of the widget
   * @param minHeight       the minimum height of the widget
   * @param width           the width of the widget
   * @param minWidth        the minimum width of the widget
   * @param disabled        if the widget is disabled
   * @param minimized       if the widget is minimized
   * @param minimizedOrder  order of minimized widgets in sidebar
   */
  constructor({
    identifier,
    x,
    y,
    height,
    minHeight,
    width,
    minWidth,
    disabled,
    minimized,
    minimizedOrder,
  }: {
    identifier?: string;
    x?: number;
    y?: number;
    height?: number;
    minHeight?: number;
    width?: number;
    minWidth?: number;
    disabled?: boolean;
    minimized?: boolean;
    minimizedOrder?: number;
  } = {}) {
    super({} as RootStore);
    this.disabled = Boolean(disabled);
    this.identifier = identifier;
    this.x = Number(x);
    this.y = Number(y);
    this.height = Number(height);
    this.minHeight = Number(minHeight);
    this.width = Number(width);
    this.minWidth = Number(minWidth);
    this.minimized = Boolean(minimized);
    this.minimizedOrder = Number(minimizedOrder);
  }
}
