import Timeline from 'react-visjs-timeline';
import {Space} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {renderToStaticMarkup} from 'react-dom/server';
import {useState} from 'react';
import styles from './visTimeline.module.scss';
import {useMount} from '../../../hooks/useMount';

const defaultOptions = {
  groupTemplate: (group) => {
    if (!group || !group.content) { return null; }
    return renderToStaticMarkup(<span>{group.content}</span>);
  },
  margin: {
    item: 10,
    axis: 5,
  },
  stack: false,
  orientation: 'top',
  moveable: false,
  zoomMin: 1000 * 60 * 5, // = 5 min
  zoomMax: 1000 * 60 * 60 * 24 * 7, // = 7 days
};

export const VisTimeline = ({layout = 'default', options, ...props}) => {
  const [repaint, setRepaint] = useState(0);

  useMount(() => {
    setTimeout(() => {
      setRepaint(repaint + 1);
    }, 500);
  });

  return (
    <div className={styles[layout]}>
      <Timeline
        options={{
          ...defaultOptions,
          ...options,
        }}
        {...props}
      />
    </div>
  );
};

const GroupContent = ({icon, children, style}) => (
  <Space style={{margin: '0 0.4em', ...style}}>
    {icon && <FontAwesomeIcon icon={icon}/>}
    <span>{children}</span>
  </Space>
);

VisTimeline.GroupContent = GroupContent;
