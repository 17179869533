/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { useStore } from '../../../../hooks/useStore';
import AutoComplete, { AutoCompleteProps } from './Autocomplete';

export type StorageUnitSelectorProps = Omit<AutoCompleteProps, 'propertyName'> & {
  propertyName?: string;
};

const StorageUnitSelector: React.FC<StorageUnitSelectorProps> = ({
  name,
  propertyName = 'id',
  value,
  multiple,
  disableExactMatch,
  ...props
}) => {
  const store = useStore();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <AutoComplete
      value={value}
      name={name}
      propertyName={propertyName}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      multiple={multiple}
      searchFunction={store.storageUnitStore.searchByNo.bind(store.storageUnitStore)}
      reverseSearchFunction={
        multiple
          ? store.storageUnitStore.getByIds.bind(store.storageUnitStore)
          : store.storageUnitStore.getById.bind(store.storageUnitStore)
      }
      populateStore={store.storageUnitStore.load.bind(store.storageUnitStore)}
      renderOption={(storageUnit) => (
        {
          value: storageUnit.id,
          label: storageUnit.no,
        }
      )}
      disableExactMatch={disableExactMatch}
      {...props}
    />
  );
};

export default StorageUnitSelector;
