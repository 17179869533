import React from 'react';
import { observer } from 'mobx-react-lite';
import Table from './Table';

export type KeyValue = [string, React.ReactNode];

export type KeyValueTableProps = {
  rows: KeyValue[];
};

const KeyValueTable: React.FC<KeyValueTableProps> = ({ rows }) => (
  <Table
    striped
    size="small"
    components={{
      header: {
        wrapper: () => null,
      },
    }}
    rowKey={(row: KeyValue) => row[0]}
    pagination={false}
    columns={[
      {
        key: 'key',
        dataIndex: 0,
        render: (x: string) => (
          <span style={{ color: '#949494' }}>
            {
              (typeof x === 'string')
                ? x.toUpperCase()
                : x
            }
          </span>
        ),
      },
      {
        key: 'value',
        dataIndex: 1,
      },
    ]}
    dataSource={rows}
  />
);

export default observer(KeyValueTable);
