import React from 'react';
import { Modal as AntdModal, ModalProps as AntdModalprops } from 'antd';
import styles from './Modal.module.scss';

export type ModalProps = AntdModalprops & {
  fullscreen?: boolean;
  className?: string;
  width?: number | string;
};

const Modal: React.FC<ModalProps> = ({
  fullscreen,
  className = '',
  width,
  ...props
}) => (
  // eslint-disable-next-line jsx-a11y/no-static-element-interactions
  <div onMouseDown={(e) => e.stopPropagation()}>
    <AntdModal
      width={fullscreen ? '100%' : width}
      {...props}
      className={`${fullscreen ? styles.modalFullscreen : ''} ${className}`}
    />
  </div>
);

export default Modal;
