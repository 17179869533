import React, {useEffect, useState} from 'react';
import {Alert} from 'antd';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import FormModal from '../shared/modal/FormModal';
import {smallPaginationPreset} from '../shared/tables/paginationPresets';
import WidgetTable from '../shared/widgets/WidgetTable';
import UnclassifiedInterruptionsForm from './UnclassifiedInterruptionsForm';
import {useStore} from '../../hooks/useStore';

const BatchInterruptionModal = ({
  logs,
  columns,
  updateStateLog,
  scopeSources,
  selectSourceLeavesOnly,
  open,
  ...props
}) => {
  const [selectedLogIds, setSelectedLogIds] = useState([]);
  const [fields, setFields] = useState([]);
  const [updateFailed, setUpdateFailed] = useState(false);
  const [longestInterruptionDuration, setLongestInterruptionDuration] = useState(0);
  const store = useStore();
  const {t} = useTranslation();

  const handleSelectionChange = (newValues) => {
    setSelectedLogIds(newValues);
  };

  useEffect(() => {
    if (!selectedLogIds.length) {
      setLongestInterruptionDuration(0);
      return;
    }
    let longestDuration = 0;
    logs.filter((l) => selectedLogIds.includes(l.id)).forEach((log) => {
      const duration = dayjs(log.end).valueOf() - dayjs(log.start).valueOf();
      if (duration > longestDuration) {
        longestDuration = duration;
      }
    });
    setLongestInterruptionDuration(longestDuration);
  }, [selectedLogIds]);

  return (
    <FormModal
      title={t('unclassifiedInterruptions.batchInterruptionModal.title')}
      open={open}
      {...props}
    >
      {
        updateFailed
          ? (
            <Alert
              style={{marginBottom: '1rem'}}
              message={t('unclassifiedInterruptions.batchInterruptionModal.error.message')}
              description={t('unclassifiedInterruptions.batchInterruptionModal.error.description')}
              type="error"
              closable
              showIcon
              onClose={() => setUpdateFailed(false)}
            />
          )
          : ''
      }
      <WidgetTable
        size={'middle'}
        columns={columns}
        dataSource={logs}
        rowKey={'id'}
        pagination={smallPaginationPreset}
        rowSelection={{
          selectedRowKeys: selectedLogIds,
          onChange: handleSelectionChange,
        }}
      />
      <UnclassifiedInterruptionsForm
        fields={fields}
        fieldsDisabled={!logs.length}
        onChange={setFields}
        saving={store.operationStateLogStore.isMutationInProgress}
        saveButtonDisabled={!selectedLogIds.length}
        longestInterruptionDuration={longestInterruptionDuration}
        onCancel={() => props.onCancel()}
        onSubmit={async (values) => {
          const selectedLogs = logs.filter((log) => selectedLogIds.includes(log.id));
          // eslint-disable-next-line no-restricted-syntax
          for (const selectedLog of selectedLogs) {
            const promise = updateStateLog(selectedLog, values, {skipNotification: true});
            promise.catch(() => {
              setUpdateFailed(true);
            });

            // eslint-disable-next-line no-await-in-loop
            await promise;
          }

          if (!updateFailed) {
            props.onCancel();
            store.messageStore.addMessage({type: 'success', title: t('flashMessages.updateSuccess')}, {});
          }
        }}
        scopeSources={scopeSources}
        selectSourceLeavesOnly={selectSourceLeavesOnly}
      />
    </FormModal>
  );
};

export default observer(BatchInterruptionModal);
