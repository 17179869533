import { action, makeObservable, observable } from 'mobx';
import { get } from 'lodash';
import dayjs from 'dayjs';
import { AxiosResponse } from 'axios';
import i18n from '../i18n/i18n';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { ConsumptionLog } from '../models/consumptionLog';
import {
  consumeIngredientEndpoint,
  consumeRetrogradeEndpoint,
  validateConsumptionEndpoint,
} from '../middleware/endpoints/consumptions';
import { RootStore } from './rootStore';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';

interface ConsumptionLogFilterParams {
  preparationId?: number;
  componentId?: number;
  materialIds?: number[];
  createdAtFrom?: string;
  createdAtTo?: string;
}

interface ConsumeIngredientParams {
  ingredientNo: string;
  amount: number;
  position: number | null;
  preparationId: number;
}

interface ConsumptionRetrogradeParams {
  componentId: number;
  preparationId: number;
}

export class ConsumptionLogStore extends EntityStore<ConsumptionLog> {
  consumptions: ConsumptionLog[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'consumptions', Api.consumptions, ConsumptionLog);

    makeObservable(this, {
      consumptions: observable,
      validate: action,
      consumeIngredient: action,
      consumeRetrograde: action,
      reloadConsumptions: action,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.batchStore,
        modelId: 'batchId',
      },
      {
        store: this.rootStore.componentStore,
        modelId: 'componentId',
      },
      {
        store: this.rootStore.storageUnitStore,
        modelId: 'originStorageUnitId',
      },
      {
        store: this.rootStore.yieldStore,
        modelId: 'targetYieldId',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'createdBy',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'updatedBy',
      },
    ];
  }

  filterBy({ preparationId, componentId, materialIds, createdAtFrom, createdAtTo }: ConsumptionLogFilterParams) {
    let result = this.consumptions;
    if (preparationId) {
      result = result.filter((consumption) => consumption.preparationId === preparationId);
    }
    if (componentId) {
      result = result.filter((consumption) => get(consumption, 'componentId') === componentId);
    }
    if (materialIds && materialIds.length > 0) {
      result = result.filter(
        (consumption) => consumption.component && materialIds.includes(consumption.component?.materialId)
      );
    }
    if (createdAtFrom) {
      result = result.filter((consumption) => dayjs(consumption.createdAt).isAfter(createdAtFrom));
    }
    if (createdAtTo) {
      result = result.filter((consumption) => dayjs(consumption.createdAt).isBefore(createdAtTo));
    }
    return result;
  }

  loadByTargetYieldIds(targetYieldIds: number[]) {
    if (!targetYieldIds?.length) {
      return Promise.resolve([]);
    }
    return this.loadAllWithDependencies({
      params: {
        targetYieldIds,
      },
    });
  }

  loadByPreparationIds(preparationIds: number[]) {
    if (!preparationIds?.length) {
      return Promise.resolve([]);
    }
    return this.loadAllWithDependencies({
      params: {
        preparationId: preparationIds,
      },
    });
  }

  actualConsumed(preparationId: number, componentId: number) {
    const consumptions = this.filterBy({ preparationId, componentId });
    return consumptions.reduce((acc, curr) => acc + curr.amount, 0);
  }

  async consumeIngredient({ preparationId, ingredientNo, amount, position }: ConsumeIngredientParams) {
    const query = this.api[consumeIngredientEndpoint]({ preparationId, ingredientNo, amount, position });
    query.then((response: AxiosResponse) => {
      const { data } = response;
      this.rootStore.consumptionLogStore.loadAllWithDependencies(
        { params: { preparationId, componentId: data.componentId } }
      );
      this.materialConsumedSuccess(data.componentId, data.amount);
    });
    return query.catch((err: any) => {
      if (err.name === 'E_BAD_REQUEST') {
        this.rootStore.flashMessageStore.addFlashMessage(new FlashMessage(
          FlashMessageType.ERROR,
          i18n.t(`batchHandling.consumption.errors.${err.error?.messages[0]}`)
        ));
      }
    });
  }

  consumeRetrograde({ preparationId, componentId }: ConsumptionRetrogradeParams) {
    return this.api[consumeRetrogradeEndpoint]({ preparationId, componentId })
      .then((response: AxiosResponse) => {
        const { data } = response;
        this.rootStore.consumptionLogStore.loadAll(
          { params: { preparationId, componentId: data.componentId } }
        );
        this.materialConsumedSuccess(data.componentId, data.amount);
      }).catch((err: Error) => {
        this.handleApiError(err);
      });
  }

  materialConsumedSuccess(componentId: number, amount: number) {
    const component = this.rootStore.componentStore.getById(componentId);
    if (component) {
      this.rootStore.flashMessageStore.addFlashMessage(new FlashMessage(
        FlashMessageType.SUCCESS,
        i18n.t(
          'batchHandling.preparation.materialConsumed',
          {
            materialName: component.material?.name,
            amount,
            unitOfMeasureLabel: component.unitOfMeasure?.label,
          }
        )
      ));
    }
  }

  /**
   * validates whether a ingredient can be consumed
   *
   * Validation errors:
   * t('batchHandling.consumption.errors.E_INTERNAL_SERVER_ERROR');
   * t('batchHandling.consumption.errors.E_INGREDIENT_NOT_FOUND');
   * t('batchHandling.consumption.errors.E_ALREADY_CONSUMED');
   * t('batchHandling.consumption.errors.E_BATCH_LOCKED');
   * t('batchHandling.consumption.errors.E_BATCH_EXPIRED');
   * t('batchHandling.consumption.errors.E_RESERVED');
   * t('batchHandling.consumption.errors.E_MATERIAL_NOT_RELEVANT');
   * t('batchHandling.consumption.errors.E_IS_RETROGRADE');
   * t('batchHandling.consumption.errors.E_AMOUNT_INVALID');
   * t('batchHandling.consumption.errors.E_STOCK_TOO_LOW');
   * t('batchHandling.consumption.errors.E_INVALID_BARCODE');
   * t('batchHandling.consumption.errors.E_COULD_NOT_VALIDATE');
   * t('batchHandling.consumption.errors.W_POSITION_INCORRECT');
   *
   * Validation warnings:
   * t('batchHandling.consumption.warnings.W_TOLERANCE_HIGH_EXCEEDED');
   *
   * @param preparationId
   * @param ingredientNo
   * @param amount
   * @param position
   */
  async validate({ preparationId, ingredientNo, amount, position }: ConsumeIngredientParams) {
    return this.api[validateConsumptionEndpoint]({ preparationId, ingredientNo, amount, position });
  }

  reloadConsumptions(params: any) {
    this.loadAllWithDependencies({
      params,
      dependencies: [
        {
          store: this.rootStore.batchStore,
          modelId: 'batchId',
        },
      ],
    });
  }

  getByComponentId(componentId: number) {
    return this.consumptions.filter((c) => c.componentId === componentId);
  }

  getByTargetYieldIds(targetYieldIds: number[]) {
    return this.consumptions.filter((c) => targetYieldIds.find((ty) => ty === c.targetYieldId));
  }

  getByPreparationIds(preparationIds: number[]) {
    return this.consumptions.filter((c) => preparationIds.find((p) => p === c.preparationId));
  }
}
