import { observable, makeObservable } from 'mobx';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { Message } from '../models/message';
import { RootStore } from './rootStore';

export class MessageStore extends EntityStore<Message> {
  messages: Message[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'messages', Api.messages, Message);

    makeObservable(this, {
      messages: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.orderStore,
        modelId: 'orderId',
      },
      {
        store: this.rootStore.materialStore,
        modelId: 'materialId',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'createdBy',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'updatedBy',
      },
    ];
  }
}
