import React from 'react';
import CancelButton from './CancelButton';
import SaveButton, { SaveButtonProps } from './SaveButton';
import { ButtonProps } from './Button';

export type CancelSaveButtonGroupProps = Omit<ButtonProps, 'onClick'> & {
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  onSave?: React.MouseEventHandler<HTMLButtonElement>;
  saving?: boolean,
  cancelButtonDisabled?: boolean;
  saveButtonDisabled?: boolean;
};

const CancelSaveButtonGroup: React.FC<CancelSaveButtonGroupProps> = ({
  onCancel,
  onSave,
  saving = false,
  cancelButtonDisabled = false,
  saveButtonDisabled = false,
  dataCyPrefix = '',
  ...props
}) => {
  const cancelButtonProps = {
    style: { marginRight: '10px' },
    onClick: onCancel,
    ...props,
  };

  const saveButtonProps: SaveButtonProps = {
    onClick: onSave,
    type: 'primary',
    ...props,
  };

  return (
    <div>
      <CancelButton
        {...cancelButtonProps}
        disabled={cancelButtonDisabled}
        data-cy={`${dataCyPrefix ? `${dataCyPrefix}-` : ''}CancelButton`}
      />
      <SaveButton
        {...saveButtonProps}
        disabled={saveButtonDisabled}
        loading={saving}
        data-cy={`${dataCyPrefix ? `${dataCyPrefix}-` : ''}SaveButton`}
      />
    </div>
  );
};

export default CancelSaveButtonGroup;
