import React from 'react';
import { Col, Divider, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { trimEnd } from 'lodash';
import Modal, { ModalProps } from '../modal/Modal';
import Input from './Input';
import Button from '../buttons/Button';
import Form from '../form/Form';

const FNC1_ENDING = '\x1D';
const numbersToReceiveFNC1Ending = ['10', '30', '240', '241', '400'];

type Value = {
  fragments: {
    parenthesesInput: string;
    valueInput: string;
  }[];
};

export type ManualBarcodeInputModalProps = ModalProps & {
  onSubmit: (value: string) => void;
};

const ManualBarcodeInputModal: React.FC<ManualBarcodeInputModalProps> = ({
  onSubmit,
  onCancel,
  open,
  ...props
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const formLayout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 },
  };

  const resetModal = () => {
    form.resetFields();
  };

  const onFinish = (values: Value) => {
    const barCodeFragmentsWithEndings = values.fragments.map((barcodeFragmentObject) => {
      const barcodeFragment = `${barcodeFragmentObject.parenthesesInput || ''}${barcodeFragmentObject.valueInput}`;
      if (numbersToReceiveFNC1Ending.includes(barcodeFragmentObject.parenthesesInput)) {
        return `${barcodeFragment}${FNC1_ENDING}`;
      }
      return barcodeFragment;
    });
    onSubmit(trimEnd(barCodeFragmentsWithEndings.join(''), FNC1_ENDING));
    resetModal();
  };

  const initialValues = {
    fragments: [
      {
        parenthesesInput: '',
        valueInput: '',
      },
    ],
  };

  return (
    <Modal
      title={t('batchHandling.manualBarcodeInputModal.title')}
      footer={false}
      onCancel={onCancel}
      open={open}
      {...props}
    >
      <Form
        form={form}
        {...formLayout}
        name="barcodeFragments"
        initialValues={initialValues}
        onFinish={onFinish}
      >
        <Form.List name="fragments">
          {(fields, { add }) => (
            <>
              {fields.map((field) => (
                <Row key={field.fieldKey} gutter={8}>
                  <Col span={10}>
                    <Form.Item
                      {...field}
                      key={[field.fieldKey, 'parenthesesInput'].join('-')}
                      name={[field.name, 'parenthesesInput']}
                    >
                      <Input addonBefore="(" addonAfter=")" type="number"/>
                    </Form.Item>
                  </Col>
                  <Col span={14}>
                    <Form.Item
                      {...field}
                      key={[field.fieldKey, 'valueInput'].join('-')}
                      name={[field.name, 'valueInput']}
                      rules={[{
                        required: true,
                        message: t<string>('batchHandling.manualBarcodeInputModal.validation.pleaseEnter'),
                      }]}
                    >
                      <Input/>
                    </Form.Item>
                  </Col>
                </Row>
              ))}
              <Form.Item>
                <Button type="link" onClick={() => add()}>
                  <b>{t('batchHandling.manualBarcodeInputModal.button.addElement')}</b>
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Divider/>
        <Row gutter={8}>
          <Col span={12}>
            <Button
              type="primary"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                if (onCancel) { onCancel(e); }
              }}
              size="large"
              block
            >
              {t('batchHandling.manualBarcodeInputModal.button.cancel')}
            </Button>
          </Col>
          <Col span={12}>
            <Button type="primary" htmlType="submit" size="large" block>
              {t('batchHandling.manualBarcodeInputModal.button.submit')}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ManualBarcodeInputModal;
