import React, {useContext, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Col} from 'antd';
import {evaluate} from 'mathjs';
import {round} from 'lodash';
import dayjs from 'dayjs';
import {observer} from 'mobx-react-lite';
import Form from '../../shared/form/Form';
import Input from '../../shared/inputs/Input';
import QualityIndicator from '../qualityIndicator/QualityIndicator';
import {ValidationRules} from '../../../utils/validationRules';
import styles from '../InspectionTaskPendingWidget.module.scss';
import InspectionTaskExecutionForm, {OverruleType} from './InspectionTaskExecutionForm';
import {InspectionTaskContext} from './InspectionTaskExecutionModal';
import {InspectionTaskResultValuation} from '../../../models/inspectionTaskResult';
import {useMount} from '../../../hooks/useMount';
import {FlashMessage, FlashMessageType} from '../../../models/flashMessage';
import {useStore} from '../../../hooks/useStore';
import {countDecimals, localeNumber} from '../../../utils/number';
import Select from '../../shared/inputs/Select';
import {sortAlphabetically} from '../../shared/tables/sorters';
import Button from '../../shared/buttons/Button';
import {datetimeFormat} from '../../../config/dayjs';
import {executeCustomFunctionEndpoint} from '../../../middleware/endpoints/customFunctions';

const makeValueAccepted = (desired, low, high) => (value) => {
  if (Number(value) === Number(desired)) {
    return true;
  }
  if (low && high) {
    return value <= high && value >= low;
  }
  if (high || high === 0) {
    return value <= high;
  }
  if (low || low === 0) {
    return value >= low;
  }
  return false;
};

const Quantitative = ({task, useUniformQualityIndicator, disabled, onSubmit, minRemarkLength}) => {
  const {t} = useTranslation();
  const store = useStore();
  const {key, inspectionTask, inspectionTaskPending} = task;
  const {quantitative} = inspectionTask;
  const [form] = Form.useForm();
  const {contextValue: inspectionTaskContext, setContextValue} = useContext(InspectionTaskContext);

  const results = inspectionTaskContext.rootForm.getFieldValue('results') || {};
  const [remarkRequired, setRemarkRequired] = useState(
    results[key]?.valuation === InspectionTaskResultValuation.rejected
  );
  const [overruledRemarkRequired, setOverruledRemarkRequired] = useState(
    results[key]?.overruledValuation === InspectionTaskResultValuation.rejected
  );
  const [value, setValue] = useState(results[key]?.value || null);
  const [overruleActive, setOverruleActive] = useState(results[key]?.overruledUserId || false);
  const [overruleType, setOverruleType] = useState();
  const [overruledUserId, setOverruleUserId] = useState(null);
  const [submittable, setSubmittable] = useState(results[key] !== undefined);
  const [inspectionEquipmentObject, setInspectionEquipmentObject] = useState();
  const [isInspectionEquipment, setIsInspectionEquipment] = useState(false);
  const [lastTimeCalculated, setLastTimeCalculated] = useState(new Date());
  const inputRef = useRef();

  useMount(() => {
    inputRef.current.focus();
  });

  const valueAccepted = makeValueAccepted(
    quantitative.desiredValue,
    quantitative.toleranceLow,
    quantitative.toleranceHigh
  );

  const transform = (values) => {
    const cleanValue = localeNumber(navigator.language, values.value);
    return {
      valuation: valueAccepted(cleanValue)
        ? InspectionTaskResultValuation.accepted
        : InspectionTaskResultValuation.rejected,
      value: cleanValue,
      remark: values.remark || '',
      overruledValue: overruleActive ? values.overruledValue : undefined,
      // eslint-disable-next-line no-nested-ternary
      overruledValuation: overruleActive
        ? (valueAccepted(values.overruledValue)
          ? InspectionTaskResultValuation.accepted
          : InspectionTaskResultValuation.rejected)
        : undefined,
      overruledRemark: values.overruledRemark || values.fourEyesRemark || '',
      overruledUserId,
      inspectionTaskId: inspectionTask.id,
      inspectionTaskPendingId: inspectionTaskPending?.id,
    };
  };

  const decimalValidator = {
    validator: (rule, v) => {
      if (countDecimals(Number(localeNumber(navigator.language, v))) > quantitative.decimalPlaces) {
        return Promise.reject(t('errors.messages.maxDecimalPlaces', {decimalPlaces: quantitative.decimalPlaces}));
      }
      return Promise.resolve();
    },
  };

  const initialValues = {
    value: results[key]?.value || null,
    remark: results[key]?.remark || null,
    overruledValue: results[key]?.overruledValue || null,
    overruledRemark: results[key]?.overruledRemark || null,
  };

  const validationRules = [ValidationRules.number, ValidationRules.required(), decimalValidator];
  if (quantitative.plausibleLow !== undefined && quantitative.plausibleLow !== null) {
    validationRules.push(ValidationRules.minNumber(quantitative.plausibleLow));
  }

  if (quantitative.plausibleHigh !== undefined && quantitative.plausibleHigh !== null) {
    validationRules.push(ValidationRules.maxNumber(quantitative.plausibleHigh));
  }

  const handleValueChange = (changedValues) => {
    const pointedNumber = localeNumber(navigator.language, changedValues.value || '');
    if (changedValues.value) {
      setValue(Number(pointedNumber));
      const accepted = valueAccepted(pointedNumber);
      setRemarkRequired(!accepted);
      setSubmittable(!(inspectionTask.mustBeAccepted && inspectionTask.isOverruleAllowed && !accepted));
    }
    if (changedValues.overruledValue) {
      const accepted = valueAccepted(pointedNumber);
      setOverruledRemarkRequired(!accepted);
      setSubmittable(true);
    }
  };

  const handleFinish = () => {
    const formResults = {...results};
    formResults[key] = transform(form.getFieldsValue(true));
    inspectionTaskContext.rootForm.setFieldsValue({results: formResults});
    setContextValue({...inspectionTaskContext});
    onSubmit(formResults[key]);
  };

  const getScope = () => {
    const scope = {scope: {}};
    inspectionTaskContext.tasks.forEach((scopeTask) => {
      const result = results[scopeTask.key];
      scope.scope[scopeTask.inspectionTask.characteristicNo] = {
        value: Number(result?.value || 0),
        valuation: result?.valuation || '',
      };
    });
    return scope;
  };

  useMount(() => {
    if (!inspectionTask.quantitative.calculatedCustomFunction) {
      return;
    }
    let result = '0';
    try {
      result = evaluate(inspectionTask.quantitative.calculatedCustomFunction.body, getScope());
      if (result && inspectionTask.quantitative.decimalPlaces !== null) {
        result = round(result, inspectionTask.quantitative.decimalPlaces);
      }
      result = String(result);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Error while processing mathjs expression: ', e);
      store.flashMessageStore.addFlashMessage(new FlashMessage(
        FlashMessageType.WARNING,
        t('inspectionTaskQuantitative.execution.warning.expressionError'),
        {}
      ));
    }
    form.setFieldValue('value', result);
    handleValueChange({value: result});
  }, [inspectionTaskContext]);

  const calculateInspectionEquipmentCustomFunction = async (customFunctionId) => {
    const inspectionEquipment = store.inspectionEquipmentStore.getById(
      quantitative?.inspectionEquipmentId
    );

    const params = {
      customFunctionId: customFunctionId || inspectionEquipment.defaultCustomFunctionId,
      args: '',
      workplaceId: null,
      materialId: null,
      orderId: null,
      operationId: null,
      transient: null,
    };

    const query = store.customFunctionStore.api[executeCustomFunctionEndpoint](params);
    query.then((response) => {
      const { data } = response;
      form.setFieldValue('value', data);
      handleValueChange({value: data.toString()});
      form.validateFields(['value']);
    });
    return query.catch((err) => {
      store.customFunctionStore.rootStore.flashMessageStore.addFlashMessage(new FlashMessage(
        FlashMessageType.ERROR,
        t(`quantitative.inspectionEquipment.executeCustomFunction.errors.${err.error?.messages[0]}`)
      ));
    });
  };

  const handleRecalculate = () => {
    calculateInspectionEquipmentCustomFunction(form.getFieldValue('function'));
    setLastTimeCalculated(new Date());
  };

  useMount(() => {
    if (!quantitative?.inspectionEquipmentId) {
      return;
    }
    const inspectionEquipment = store.inspectionEquipmentStore.getById(
      quantitative?.inspectionEquipmentId
    );
    const inspectionEquipmentCustomFunction = store.customFunctionStore.getByIds(
      inspectionEquipment?.customFunctionIds
    );
    const inspectionEquipmentDefaultCustomFunction = store.customFunctionStore.getById(
      inspectionEquipment?.defaultCustomFunctionId
    );

    if (!inspectionEquipment || !inspectionEquipmentCustomFunction || !inspectionEquipmentDefaultCustomFunction) {
      return;
    }

    calculateInspectionEquipmentCustomFunction(inspectionEquipment.defaultCustomFunctionId);
    setIsInspectionEquipment(true);
    setInspectionEquipmentObject({
      inspectionEquipment,
      inspectionEquipmentCustomFunction,
      inspectionEquipmentDefaultCustomFunction,
    });
  }, [
    quantitative?.inspectionEquipment?.id,
    store.inspectionEquipmentStore.inspectionEquipment.length,
    store.customFunctionStore.customFunctions.length,
  ]);

  const {
    inspectionEquipmentCustomFunction,
    inspectionEquipmentDefaultCustomFunction,
  } = inspectionEquipmentObject || {};

  const defaultCustomFunctionId = inspectionEquipmentDefaultCustomFunction?.id;

  return (
    <InspectionTaskExecutionForm
      name={key}
      form={form}
      onValuesChange={handleValueChange}
      initialValues={initialValues}
      onFinish={handleFinish}
      remarkRequired={remarkRequired && !overruleActive}
      minRemarkLength={minRemarkLength}
      overruledRemarkRequired={overruledRemarkRequired && overruleActive}
      overruleActive={[overruleActive, setOverruleActive]}
      overruleType={[overruleType, setOverruleType]}
      setOverruleUserId={(userId) => setOverruleUserId(userId)}
      inspectionTask={inspectionTask}
      submittable={submittable}
      focusSubmit={inspectionTask?.quantitative?.calculatedCustomFunction}
      inspectionEquipmentDescription={inspectionEquipmentObject?.inspectionEquipment?.description}
    >
      <Col xl={8} xs={24}>
        <Form.Item
          wrapperCol={{span: 24}}
          name={'value'}
          style={{marginBottom: 0}}
          rules={!overruleActive ? validationRules : [ValidationRules.number]}
        >
          <Input
            className={styles.quantitativeValueInput}
            quantitative={quantitative}
            addonAfter={quantitative.unit}
            disabled={disabled || overruleActive || inspectionTask.quantitative.calculatedCustomFunction}
            autoComplete={'off'}
            ref={inputRef}
            lang={navigator.language}
          />
        </Form.Item>
      </Col>
      {!inspectionTask.hideQualityIndicator && (
        <Col xl={16} xs={24}>
          <div>{t('inspectionTaskPendingModal.taskBox.quality')}</div>
          <QualityIndicator
            values={[value]}
            useUniformQualityIndicator={useUniformQualityIndicator}
            {...quantitative}
          />
        </Col>
      )}

      {
        (inspectionEquipmentCustomFunction && inspectionEquipmentCustomFunction.length && defaultCustomFunctionId) && (
          <>
            <Col xl={8} xs={24}>
              <Form.Item
                wrapperCol={{span: 24}}
                name={'function'}
                style={{marginBottom: 0, marginTop: '16px'}}
              >
                <Select
                  options={inspectionEquipmentCustomFunction.map((func) => ({
                    label: func.label,
                    value: func.id,
                  }))}
                  defaultValue={defaultCustomFunctionId}
                  allowClear={false}
                  optionFilterProp={'label'}
                  filterSort={(a, b) => sortAlphabetically(a.label, b.label)}
                />
              </Form.Item>
            </Col>
            <Col xl={16} xs={24}/>
          </>
        )
      }

      {
        isInspectionEquipment && (
          <>
            <Col xl={8} xs={24}>
              <Form.Item
                wrapperCol={{span: 24}}
                name={'calculateButton'}
                style={{marginBottom: 0}}
              >
                <Button
                  type={'primary'}
                  onClick={handleRecalculate}
                  style={{width: '100%'}}
                >
                  {t('inspectionTaskPendingModal.taskBox.calculateButton')}
                </Button>
              </Form.Item>
            </Col>
            <Col xl={16} xs={24}/>
          </>
        )
      }

      {
        isInspectionEquipment && (
          <>
            <Col xl={12} xs={24}>
              <div
                style={{width: '100%', fontSize: '12px'}}
              >
                {/* eslint-disable-next-line max-len */}
                {`${t('inspectionTaskPendingModal.taskBox.lastCalculatedValue')} ${dayjs(lastTimeCalculated)?.format(datetimeFormat)}`}
              </div>
            </Col>
            <Col xl={12} xs={24}/>
          </>
        )
      }

      {overruleActive && (
        <>
          <Col span={24}>
            {
              overruleType === OverruleType.OVERRULE
                ? t('inspectionTaskPendingModal.taskBox.overruledValue')
                : t('inspectionTaskPendingModal.taskBox.fourEyesValue')
            }
          </Col>
          <Col md={8} xs={24}>
            <Form.Item
              wrapperCol={{span: 24}}
              name={'overruledValue'}
              style={{marginBottom: 0}}
              rules={overruleActive ? validationRules : []}
            >
              <Input
                className={styles.quantitativeValueInput}
                quantitative={quantitative}
                addonAfter={quantitative.unit}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
        </>
      )}
    </InspectionTaskExecutionForm>
  );
};
export default observer(Quantitative);
