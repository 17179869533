import {executeGet, executePatch} from '../requests';

const resourcesName = 'v1/profile';
const updateOwnPwResourcesName = 'password';

export const changePasswordEndpoint = 'changePassword';
export const getEndpoint = 'get';

export const ProfileApi = {
  [getEndpoint]: () => executeGet(resourcesName),
  update: (values) => executePatch(resourcesName, values),
  [changePasswordEndpoint]: (entity) => executePatch(`${resourcesName}/${updateOwnPwResourcesName}`, entity),
};
