import React, {useContext, useState} from 'react';
import {Button, Col, Form, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import {ClockCircleOutlined, PlusOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import {DatePicker} from '../../shared/DatePicker';
import {datetimeFormat} from '../../../config/dayjs';
import {InspectionTaskContext} from './InspectionTaskExecutionModal';

const DateAdjuster = ({
  disabled,
  inspectionTaskPending,
}) => {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const inspectionTaskContext = useContext(InspectionTaskContext).contextValue;
  const [showDatePicker, setShowDatePicker] = useState(false);

  const setDateTime = () => {
    if (!inspectionTaskPending) {
      return;
    }
    const time = dayjs(inspectionTaskPending.recordedAt).add(1, 'second');
    form.setFieldsValue({recordedAt: time});
    inspectionTaskContext.rootForm.setFieldsValue({recordedAt: time});
  };

  const onChange = (values) => {
    inspectionTaskContext.rootForm.setFieldsValue({recordedAt: values.recordedAt});
  };

  const disabledDate = (current) => {
    if (!inspectionTaskPending) {
      return false;
    }
    const maxStart = dayjs(inspectionTaskPending.createdAt).add(1, 'second');
    const maxEnd = dayjs();
    return !(maxStart.isSameOrBefore(current) && maxEnd.isSameOrAfter(current));
  };

  return (
    <Form name={'dateForm'} form={form} initialValues={{recordedAt: dayjs()}} onValuesChange={onChange}>
      {showDatePicker && (
        <Row gutter={16}>
          <Col>
            <Form.Item name={'recordedAt'}>
              <DatePicker
                showToday
                showTime
                format={datetimeFormat}
                disabledDate={disabledDate}
                disabled={disabled}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button onClick={setDateTime} disabled={disabled}>
                <ClockCircleOutlined/>
                {t('inspectionTaskPendingModal.taskBox.setPendingCreationTime')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      )}
      {!showDatePicker && (
        <Row>
          <Col xs={26}>
            <Form.Item name={'addTime'}>
              <Button
                key={'interruptionModalAddTime'}
                htmlType={'button'}
                size={'large'}
                icon={<PlusOutlined/>}
                onClick={() => {
                  const value = dayjs();
                  setShowDatePicker(true);
                  form.setFieldValue('recordedAt', value);
                  onChange({recordedAt: value});
                }}
              >
                {t('inspectionTaskPendingModal.taskBox.setTime')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default DateAdjuster;
