import React from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { ValidationRules } from '../../../utils/validationRules';
import Form from '../../shared/form/Form';
import DefaultFormActions from '../../shared/form/DefaultFormActions';
import { useStore } from '../../../hooks/useStore';
import HelpTooltipFormLabel from '../../shared/tooltips/HelpTooltipFormLabel';
import Select from '../../shared/inputs/Select';

export type PrintJobFormProps = {
  fields: any[];
  onChange: (fields: any[]) => void;
  saving?: boolean;
  onSubmit: (values: any) => void;
  onCancel: () => void;
};

const PrintJobForm: React.FC<PrintJobFormProps> = ({
  fields,
  onChange,
  saving = false,
  onSubmit,
  onCancel,
}) => {
  const store = useStore();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      fields={fields}
      onFieldsChange={(changedFields: any, allFields: any) => onChange && onChange(allFields)}
    >
      <Form.Item
        name={'printerId'}
        label={(
          <HelpTooltipFormLabel
            label={t('printJob.model.attributes.printer.label')}
            help={t('printJob.model.attributes.printer.help')}
          />
        )}
        rules={[ValidationRules.required()]}
      >
        <Select>
          {store.printerStore.printers.map((printer) => (
            <Select.Option key={printer.id} value={printer.id}>
              {printer.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={'actualAmount'}
        label={(
          <HelpTooltipFormLabel
            label={t('printJob.model.attributes.actualAmount.label')}
            help={t('printJob.model.attributes.actualAmount.help')}
          />
        )}
        rules={[ValidationRules.required()]}
      >
        <Input/>
      </Form.Item>
      <DefaultFormActions saving={saving} onCancel={onCancel}/>
    </Form>
  );
};

export default PrintJobForm;
