import {
  faAsterisk,
  faBarcode,
  faBookOpen,
  faBox,
  faBoxOpen,
  faCalendar,
  faCheck,
  faInfo,
  faInfoCircle,
  faPrint,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import React, { ReactElement } from 'react';
import i18n from '../i18n/i18n';

/**
 * context translations:
 * t('icon.asterisk')
 * t('icon.barcode')
 * t('icon.bookOpen')
 * t('icon.box')
 * t('icon.boxOpen')
 * t('icon.calendar')
 * t('icon.check')
 * t('icon.info')
 * t('icon.infoCircle')
 * t('icon.print')
 * t('icon.timesCircle')
 */
export const iconElements: { [key: string]: IconDefinition } = {
  asterisk: faAsterisk,
  barcode: faBarcode,
  bookOpen: faBookOpen,
  box: faBox,
  boxOpen: faBoxOpen,
  calendar: faCalendar,
  check: faCheck,
  info: faInfo,
  infoCircle: faInfoCircle,
  print: faPrint,
  timesCircle: faTimesCircle,
};

type IconKey = keyof typeof iconElements;

export const getIcon = (name: IconKey, withLabel: boolean = false): ReactElement => (
  <>
    <FontAwesomeIcon icon={iconElements[name]}/>
    { withLabel && ` - ${i18n.t(`icon.${name}`)}`}
  </>
);

// Definition von iconSelectOptions in TypeScript
export interface IconSelectOption {
  value: IconKey;
  label: ReactElement;
}

export const iconSelectOptions: IconSelectOption[] = Object.keys(iconElements).map((value) => ({
  value: value as IconKey,
  label: getIcon(value as IconKey, true),
}));
