import * as React from 'react';
import {useContext, useState} from 'react';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as classnames from 'classnames';
import {observer} from 'mobx-react-lite';
import {Space} from 'antd';
import {faTimesCircle} from '@fortawesome/pro-solid-svg-icons';
import {useMount} from '../../../hooks/useMount';
import Qualitative from './Qualitative';
import Quantitative from './Quantitative';
import TargetValue from './TargetValue';
import MarkdownItem from '../../shared/MarkdownItem';
import {InspectionTaskContext} from './InspectionTaskExecutionModal';
import Spinner from '../../shared/spinners/Spinner';
import {InspectionTaskResultValuation} from '../../../models/inspectionTaskResult';
import AlertWarning from '../../shared/alert/AlertWarning';

const InspectionTaskBox = ({
  openNext,
  open: defaultOpen,
  task,
  disabled,
  useUniformQualityIndicator,
  minRemarkLength,
  resultError,
  onOpen,
}) => {
  const [valuationResult, setValuationResult] = useState();
  const [overruledValuationResult, setOverruledValuationResult] = useState();
  const [open, setOpen] = useState(defaultOpen);
  const {rootForm, tasks, isCreatingInspectionTaskResult} = useContext(InspectionTaskContext).contextValue;
  const [boxState, setBoxState] = useState(InspectionTaskResultValuation.notValuated);
  const {inspectionTask} = task;

  const handleSubmit = (fieldResult) => {
    const results = rootForm.getFieldValue('results');
    const areAllInspectionTasksProcessed = Object.keys(results).length === tasks.length;

    if (areAllInspectionTasksProcessed) {
      rootForm.submit();
    }

    setValuationResult(fieldResult.valuation);
    setOverruledValuationResult(fieldResult.overruledValuation);
    setOpen(false);
    openNext();
  };

  const handleOpen = () => {
    setOpen(!open);
    if (!open) {
      onOpen();
    }
  };

  useMount(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  useMount(() => {
    let newState = InspectionTaskResultValuation.notValuated;
    if (!resultError && ((valuationResult === InspectionTaskResultValuation.accepted
        && (!inspectionTask.isFourEyes || overruledValuationResult === InspectionTaskResultValuation.accepted))
      || (inspectionTask.isOverruleAllowed && overruledValuationResult === InspectionTaskResultValuation.accepted))) {
      newState = InspectionTaskResultValuation.accepted;
    }
    if ((valuationResult === InspectionTaskResultValuation.rejected && !overruledValuationResult)
      || overruledValuationResult === InspectionTaskResultValuation.rejected) {
      newState = InspectionTaskResultValuation.rejected;
    }
    setBoxState(newState);
  }, [resultError, valuationResult, overruledValuationResult, inspectionTask]);

  const HeaderItem = ({icon, text}) => (
    <Space size={24} align={'end'}>
      {icon}
      <MarkdownItem>
        {text}
      </MarkdownItem>
    </Space>
  );

  const Header = () => (
    <div
      role={'button'}
      tabIndex={inspectionTask.id}
      className={classnames({
        title: true,
        done: boxState === InspectionTaskResultValuation.accepted,
        danger: boxState === InspectionTaskResultValuation.rejected,
      })}
      onClick={handleOpen}
    >
      {!valuationResult && <HeaderItem text={inspectionTask.description?.replace(/\\n/, '\n')}/>}
      {boxState === InspectionTaskResultValuation.accepted && (
        <HeaderItem
          icon={<FontAwesomeIcon icon={faCheckCircle}/>}
          text={inspectionTask.description?.replace(/\\n/, '\n')}
        />
      )}
      {boxState === InspectionTaskResultValuation.rejected && (
        <HeaderItem
          icon={<FontAwesomeIcon icon={faTimesCircle}/>}
          text={inspectionTask.description?.replace(/\\n/, '\n')}
        />
      )}
    </div>
  );

  const BodyContent = () => {
    if (inspectionTask.inspectionType === 'Qualitative') {
      return (
        <Qualitative
          task={task}
          disabled={disabled}
          onSubmit={handleSubmit}
          minRemarkLength={minRemarkLength}
        />
      );
    }
    if (inspectionTask.inspectionType === 'Quantitative') {
      return (
        <Quantitative
          task={task}
          useUniformQualityIndicator={useUniformQualityIndicator}
          disabled={disabled}
          onSubmit={handleSubmit}
          minRemarkLength={minRemarkLength}
        />
      );
    }
    if (['BestBeforeDate', 'FactoryCode'].includes(inspectionTask.inspectionType)) {
      return (
        <TargetValue
          task={task}
          disabled={disabled}
          onSubmit={handleSubmit}
          minRemarkLength={minRemarkLength}
        />
      );
    }
    return `Unknown inspectionType ${inspectionTask.inspectionType}`;
  };

  const Body = () => {
    if (open || resultError) {
      return (
        <div className={'body'}>
          {resultError !== undefined && (
            <AlertWarning
              size={'small'}
              message={resultError}
              type={'error'}
              style={{marginBottom: 16}}
            />
          )}
          <BodyContent/>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={'card'} style={{position: 'relative'}}>
      {isCreatingInspectionTaskResult && (
        <Spinner fullWidth fullHeight backdrop style={{position: 'absolute'}}/>
      )}
      <Header/>
      <Body/>
    </div>
  );
};
export default observer(InspectionTaskBox);
