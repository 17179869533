import {observer} from 'mobx-react-lite';
import {useMemo, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBarcode} from '@fortawesome/free-solid-svg-icons';
import {WidgetLayout} from '../../models/widgetLayout';
import {WidgetLayouts} from '../shared/widgets/WidgetLayouts';
import ModelProperties from '../shared/descriptions/ModelProperties';
import OperatorWidget from '../operator/shared/OperatorWidget';
import {useMount} from '../../hooks/useMount';
import {useStore} from '../../hooks/useStore';
import {ScopeContext} from '../../policies/scopeContext';
import {orderManagement, workflow} from '../../models/scope';
import './ActiveOperationDetailsWidget.scss';
import {AttributeLabelLayout, BarcodeWidgetConfig} from './barcodeWidgetConfig';

const BarcodeWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const [refresh, setRefresh] = useState(false);
  const widgetConfig = useMemo(() =>
    new BarcodeWidgetConfig(store, BarcodeWidget.identifier), []);

  // Sometimes, custom properties are loaded too late. This updates the component once, they are available.
  useMount(() => {
    setRefresh(!refresh);
  }, [store.propertyStore.properties.length]);

  const getPropertyKeys = (props) => props.map((property) => property.key);

  const attributeLabelLayout = widgetConfig.getAttributeLabelLayout();
  const showBarcodeValueAsString = widgetConfig.showBarcodeValueAsString();
  const barcodeWidth = widgetConfig.getBarcodeWidth();
  const barcodeHeight = widgetConfig.getBarcodeHeight();
  const properties = widgetConfig.getWidgetProperties();
  const barcodeProperties = getPropertyKeys(properties);
  const qrCodeProperties = widgetConfig.getQrCodeProperties();
  const qrCodeSize = widgetConfig.getQrCodeSize();
  const dataMatrixProperties = widgetConfig.getDataMatrixProperties();
  const dataMatrixSize = widgetConfig.getDataMatrixSize();

  const barcodeOptions = {
    barcode: {
      displayValue: showBarcodeValueAsString,
      width: barcodeWidth,
      height: barcodeHeight,
    },
    qrCode: {
      displayValue: true,
      width: qrCodeSize,
      height: qrCodeSize,
    },
    dataMatrix: {
      displayValue: true,
      width: dataMatrixSize,
      height: dataMatrixSize,
    },
  };
  const itemLabelStyle = attributeLabelLayout === AttributeLabelLayout.TOP
    ? {textAlign: 'center', width: '100%', display: 'block'}
    : undefined;

  function getFilteredBarcodeProperties(props) {
    return props.filter((property) => (!qrCodeProperties.find((qrCodeProperty) => qrCodeProperty === property)
      && !dataMatrixProperties.find((dataMatrixProperty) => dataMatrixProperty === property)));
  }

  return (
    <ScopeContext.Provider value={[workflow, orderManagement]}>
      <OperatorWidget
        title={widgetConfig.getWidgetTitle()}
        icon={<FontAwesomeIcon icon={faBarcode}/>}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
      >
        <ModelProperties
          size={widgetConfig.getSpaceSize()}
          showPropertyLabels={attributeLabelLayout !== AttributeLabelLayout.NOT_VISIBLE}
          loading={store.operationStore.isLoadingCollection}
          properties={properties}
          barcodeProperties={getFilteredBarcodeProperties(barcodeProperties)}
          barcodeOptions={barcodeOptions.barcode}
          qrCodeProperties={qrCodeProperties}
          qrCodeOptions={barcodeOptions.qrCode}
          dataMatrixProperties={dataMatrixProperties}
          dataMatrixOptions={barcodeOptions.dataMatrix}
          model={store.operationStore.active}
          layout={attributeLabelLayout === AttributeLabelLayout.TOP ? 'vertical' : undefined}
          itemLabelStyle={itemLabelStyle}
        />
      </OperatorWidget>
    </ScopeContext.Provider>
  );
};

BarcodeWidget.icon = faBarcode;

BarcodeWidget.identifier = 'BarcodeWidget';
BarcodeWidget.defaultLayout = new WidgetLayout(
  {
    identifier: BarcodeWidget.identifier,
    x: 0,
    y: 1,
    height: 10,
    minHeight: 1,
    width: WidgetLayouts.thirdWidth.w,
    minWidth: WidgetLayouts.thirdWidth.minW,
  }
);

export default observer(BarcodeWidget);
