/* eslint-disable import/no-cycle */
import {createEntity, executeGet} from '../requests';

const resourcesName = 'v1/inspection-verifications';

export const InspectionVerificationsApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
};
