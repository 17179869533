import { observable, makeObservable, computed } from 'mobx';
import { BaseModel } from './base';
import i18n from '../i18n/i18n';
import { DisplayablePropertyParams, displayableProperty } from './displayableProperty';
import { displayablePropertyParam } from './displayablePropertyParam';
import { Material } from './material';
import { sortAlphabetically, sortNumerically } from '../components/shared/tables/sorters';
import { RootStore } from '../stores/rootStore';
import { Operation } from './operation';
import { UnitOfMeasurement } from './unitOfMeasurement';
import { transformation } from '../utils/transformations';

const MAX_DECIMAL_PLACES = 3;

export class Product extends BaseModel {
  id: number = 0;
  no: string = '';
  materialId: number = 0;
  operationId: number = 0;
  unitOfMeasureId: number = 0;
  plannedQuantity: number | null = null;
  actualQuantity: number | null = null;
  plannedPreparations: number | null = null;
  preparationQuantity: number | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      no: observable,
      materialId: observable,
      material: computed,
      operationId: observable,
      operation: computed,
      unitOfMeasureId: observable,
      unitOfMeasure: computed,
      plannedQuantity: observable,
      actualQuantity: observable,
      plannedPreparations: observable,
      preparationQuantity: observable,
      actualPreparations: computed,
    });
  }

  get material(): Material | undefined {
    return this.materialId ? this.rootStore.materialStore.getById(this.materialId) : undefined;
  }

  get operation(): Operation | undefined {
    return this.operationId ? this.rootStore.operationStore.getById(this.operationId) : undefined;
  }

  get unitOfMeasure(): UnitOfMeasurement | undefined {
    return this.unitOfMeasureId ? this.rootStore.unitOfMeasurementStore.getById(this.unitOfMeasureId) : undefined;
  }

  get actualPreparations(): number {
    return this.rootStore.preparationStore.getByProductId(this.id).length;
  }

  readonly saveableProperties = [
    'no',
    'materialId',
    'operationId',
    'unitOfMeasureId',
    'plannedQuantity',
    'actualQuantity',
    'plannedPreparations',
    'preparationQuantity',
  ];

  displayableProperties: DisplayablePropertyParams[] = [
    displayableProperty({
      key: 'no',
      title: i18n.t('product.model.attributes.no'),
      params: [displayablePropertyParam({ path: 'no' })],
      sorter: (a, b) => sortAlphabetically(a.no, b.no),
    }),
    displayableProperty({
      key: 'plannedQuantity',
      title: i18n.t('product.model.attributes.plannedQuantity'),
      params: [
        displayablePropertyParam({
          path: 'plannedQuantity',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'plannedQuantity',
        }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{plannedQuantity} {unitOfMeasure}',
      sorter: (a, b) => sortNumerically(a.plannedQuantity, b.plannedQuantity),
    }),
    displayableProperty({
      key: 'actualQuantity',
      title: i18n.t('product.model.attributes.actualQuantity'),
      params: [
        displayablePropertyParam({
          path: 'actualQuantity',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'actualQuantity',
        }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{actualQuantity} {unitOfMeasure}',
      sorter: (a, b) => sortNumerically(a.actualQuantity, b.actualQuantity),
    }),
    displayableProperty({
      key: 'plannedPreparations',
      title: i18n.t('product.model.attributes.plannedPreparations'),
      params: [
        displayablePropertyParam({
          path: 'plannedPreparations',
          as: 'plannedPreparations',
        }),
      ],
      template: '{plannedPreparations}',
      sorter: (a, b) => sortNumerically(a.plannedPreparations, b.plannedPreparations),
    }),
    displayableProperty({
      key: 'preparationQuantity',
      title: i18n.t('product.model.attributes.preparationQuantity'),
      params: [
        displayablePropertyParam({
          path: 'preparationQuantity',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'preparationQuantity',
        }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),

      ],
      template: '{preparationQuantity} {unitOfMeasure}',
      sorter: (a, b) => sortNumerically(a.preparationQuantity, b.preparationQuantity),
    }),
    displayableProperty({
      key: 'actualPreparations',
      title: i18n.t('product.model.attributes.actualPreparations'),
      params: [displayablePropertyParam({ path: 'actualPreparations' })],
      sorter: (a: Product, b: Product) => sortNumerically(a.actualPreparations, b.actualPreparations),
    }),
  ];

  static allDisplayableProperties(rootStore: RootStore, pathPrefix = '', titlePrefix = '') {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix);

    const materialPrefix = `${titlePrefix}${i18n.t('material.model.one')} > `;
    const operationPrefix = `${titlePrefix}${i18n.t('operation.model.one')} > `;

    allDisplayableProperties.push(
      ...Material.allDisplayableProperties(rootStore, `${pathPrefix}material.`, materialPrefix),
      ...Operation.allDisplayableProperties(rootStore, `${pathPrefix}operation.`, operationPrefix),
      ...this.displayableCustomProperties(rootStore, pathPrefix, titlePrefix)
    );

    return allDisplayableProperties;
  }

  static prepareApiPayload(model: Partial<Product>): Partial<Product> {
    return {
      id: model.id,
      no: model.no,
      materialId: model.materialId,
      operationId: model.operationId,
      unitOfMeasureId: model.unitOfMeasureId,
      plannedQuantity: model.plannedQuantity,
      actualQuantity: model.actualQuantity,
      plannedPreparations: model.plannedPreparations,
      preparationQuantity: model.preparationQuantity,
    };
  }
}
