import { computed, observable, makeObservable, action } from 'mobx';
import { isEmpty } from 'lodash';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { Scope } from './scope';
import appConfig from '../utils/appConfig';
import { CustomPropertyTypes } from './customPropertyDataTypes';
import { displayableProperty } from './displayableProperty';
import i18n from '../i18n/i18n';
import { displayablePropertyParam } from './displayablePropertyParam';
import { sortAlphabetically, sortChronologically } from '../components/shared/tables/sorters';
import { CustomPropertiesObject } from './customProperty';
import { RootStore } from '../stores/rootStore';
import { EnDash } from '../components/shared/unicodeWrapper/EnDash';

export const GUEST_USER_ID = 0;
export const SYSTEM_USER_ID = 1;
export const ADMINISTRATOR_USER_ID = 2;

export class User extends BaseModel {
  id: number = 0;
  username: string = '';
  password?: string = undefined;
  firstName: string = '';
  lastName: string = '';
  email: string | null = null;
  isInternal: boolean = false;
  properties?: CustomPropertiesObject = undefined;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;
  deletedAt: string | null = null;
  deletedBy: number | null = null;
  isLdapUser: boolean | null = null;
  language: string | null = null;
  roleIds: number[] = [];
  officeInitialPath: string | null = null;
  officeStatusBarHierarchyIds: number[] = [];
  officeSideBarCollapsed: boolean = false;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      username: observable,
      firstName: observable,
      lastName: observable,
      email: observable,
      isInternal: observable,
      properties: observable,
      userRoles: computed,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
      deletedAt: observable,
      deletedBy: observable,
      scopes: observable,
      sortedRoles: computed,
      fullName: computed,
      completeName: computed,
      isLdapUser: observable,
      language: observable,
      roleIds: observable,
      setScopes: action,
      setRoleIds: action,
      officeInitialPath: observable,
      officeStatusBarHierarchyIds: observable,
      officeSideBarCollapsed: observable,
      officeStatusBarHierarchies: computed,
    });
  }

  static faIcon = faUser;

  scopes: Scope[] = [];

  saveableProperties = [
    'password', 'username', 'firstName', 'lastName', 'email', 'isInternal', 'properties', 'language',
  ];

  searchableProperties = ['username', 'firstName', 'lastName'];

  customPropertyType = CustomPropertyTypes.User;

  displayableProperties = [
    displayableProperty({
      title: i18n.t('user.model.attributes.fullName'),
      params: [
        displayablePropertyParam({
          path: 'fullName',
        }),
      ],
      template: '{value}',
      key: 'fullName',
      sorter: (a, b) => sortChronologically(a.fullName, b.fullName),
    }),
    displayableProperty({
      title: i18n.t('user.model.attributes.username'),
      params: [
        displayablePropertyParam({
          path: 'username',
        }),
      ],
      template: '{value}',
      key: 'username',
      sorter: (a, b) => sortChronologically(a.username, b.username),
    }),
  ];

  static prepareApiPayload(model: Partial<User>): Partial<User> {
    return {
      id: model.id || undefined,
      password: model.password || undefined,
      username: model.username || undefined,
      firstName: model.firstName,
      lastName: model.lastName,
      email: model.email || null,
      isInternal: model.isInternal || false,
      properties: model.properties || {},
      language: appConfig.modules.enableUserLanguage ? model.language || null : undefined,
    };
  }

  setScopes(scopes: string[]) {
    this.scopes = scopes.map((scope) => new Scope(scope));
  }

  setRoleIds(roleIds: number[]) {
    this.roleIds = roleIds;
  }

  hasRole(roleId: number) {
    return this.userRoles.some((role) => role.roleId === roleId);
  }

  get userRoles() {
    return this.rootStore.userRoleStore.userRoles.filter((userRole) => userRole.userId === this.id);
  }

  get sortedRoles() {
    return this.userRoles
      .slice()
      .sort((a, b) => sortAlphabetically(a.role?.name.toUpperCase() || '', b.role?.name.toUpperCase() || ''));
  }

  get fullName() {
    const firstName = this.firstName.trim();
    const lastName = this.lastName.trim();

    // return username as fallback if name is empty, which should not happen in production because names are required
    return (isEmpty(firstName) && isEmpty(lastName)) ? this.username : `${firstName} ${lastName}`;
  }

  get completeName() {
    const firstName = this.firstName.trim();
    const lastName = this.lastName.trim();

    if (isEmpty(firstName) && isEmpty(lastName)) {
      return `${EnDash()} (${this.username})`;
    }

    return `${firstName} ${lastName} (${this.username})`;
  }

  /**
   * Indicates if the user has a role with superUser permissions. Requires UserRole and Role dependencies and
   * is slower than isSuperUser, but does work with every user. Might return false at first if data is still loading.
   */
  get hasSuperUserRole(): boolean {
    return !!this.userRoles.find((ur) => ur.role?.isSuperUserRole);
  }

  get officeStatusBarHierarchies() {
    return this.officeStatusBarHierarchyIds.map(
      (id) => this.rootStore.hierarchyStore.getById(id)
    );
  }
}
