import {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTasks} from '@fortawesome/free-solid-svg-icons';
import {useMount} from '../../hooks/useMount';
import {WidgetLayout} from '../../models/widgetLayout';
import OperatorWidget from '../operator/shared/OperatorWidget';
import {WidgetLayouts} from '../shared/widgets/WidgetLayouts';
import {InspectionTaskPendingWidgetConfig} from './InspectionTaskPendingWidgetConfig';
import InspectionTaskPendingTable from './InspectionTaskPendingTable';
import InspectionTaskExecutionModal from './execution/InspectionTaskExecutionModal';
import {ScopeContext} from '../../policies/scopeContext';
import {taskManagement} from '../../models/scope';
import {useStore} from '../../hooks/useStore';
import InspectionTaskPendingWidgetModal from './InspectionTaskPendingWidgetModal';
import useInspectionTaskExecutionModal from '../../hooks/useInspectionTaskExecutionModal';
import {LoadStrategies} from '../../stores/entityStore';

const InspectionTaskPendingWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const [detailsModalVisible, setDetailsModalVisible] = useState(false);
  const widgetConfig = useMemo(
    () => new InspectionTaskPendingWidgetConfig(store, InspectionTaskPendingWidget.identifier),
    [store.settingStore.operatorSettingsReady]
  );
  const {open: selectPendingTask, inspectionTaskExecutionModalProps} = useInspectionTaskExecutionModal();

  useMount(() => {
    if (store.workplaceStore.selectedWorkplace?.id) {
      store.inspectionTaskPendingStore.loadByWorkplace(store.workplaceStore.selectedWorkplace.id);
    }
  }, [store.workplaceStore.selectedWorkplace?.id, store.transitionStore.performedTransition]);

  useMount(() => {
    store.inspectionLotStore.loadMany(store.inspectionTaskStore.inspectionTasks.map((task) => task.inspectionLotId));
  }, [store.inspectionTaskStore.inspectionTasks?.length]);

  useMount(() => {
    if (store.authStore.user.id) {
      store.userRoleStore.loadAll({
        strategy: LoadStrategies.replace,
        params: {
          userId: store.authStore.user.id,
        },
      });
    }
  }, [store.authStore.user.id]);

  const isLoading = store.inspectionTaskStore.isLoadingCollection
    || store.inspectionTaskPendingStore.isLoadingCollection;

  return (
    <ScopeContext.Provider value={[taskManagement]}>
      <OperatorWidget
        icon={<FontAwesomeIcon icon={faTasks}/>}
        title={widgetConfig.getWidgetTitle()}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        onHeadClick={() => setDetailsModalVisible(true)}
        manualPath={'/task/pending'}
      >
        <InspectionTaskPendingTable
          onRowClick={selectPendingTask}
          loading={isLoading}
          widgetConfig={widgetConfig}
          filterSilent
          showInspectionTaskGroupIcon={widgetConfig.getWidgetSetting('showInspectionTaskGroupIcon')}
        />
      </OperatorWidget>
      <InspectionTaskExecutionModal
        openFirstDocument={widgetConfig.getOpenFirstDocument()}
        widgetConfig={widgetConfig}
        {...inspectionTaskExecutionModalProps}
      />
      {detailsModalVisible && (
        <InspectionTaskPendingWidgetModal
          workplaceId={store.workplaceStore.selectedWorkplace?.id}
          selectPendingTask={selectPendingTask}
          onCancel={() => setDetailsModalVisible(false)}
          widgetConfig={widgetConfig}
        />
      )}
    </ScopeContext.Provider>
  );
};

InspectionTaskPendingWidget.icon = faTasks;

InspectionTaskPendingWidget.identifier = 'InspectionTaskPendingWidget';
InspectionTaskPendingWidget.defaultLayout = new WidgetLayout({
  identifier: InspectionTaskPendingWidget.identifier,
  x: 1,
  y: 10,
  height: 8,
  minHeight: 1,
  width: WidgetLayouts.sixthWidth.w,
  minWidth: WidgetLayouts.sixthWidth.minW,
});

export default observer(InspectionTaskPendingWidget);
