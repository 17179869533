/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { useStore } from '../../../../hooks/useStore';
import AutoComplete, { AutoCompleteProps } from './Autocomplete';

export type OrderSelectorProps = Omit<AutoCompleteProps, 'propertyName'> & {
  propertyName?: string;
};

const OrderSelector: React.FC<OrderSelectorProps> = ({
  form = undefined,
  name = undefined,
  propertyName = 'id',
  value = undefined,
  multiple,
  ...props
}) => {
  const store = useStore();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <AutoComplete
      value={value}
      form={form}
      name={name}
      propertyName={propertyName}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      multiple={multiple}
      searchFunction={store.orderStore.searchByNo.bind(store.orderStore)}
      reverseSearchFunction={
        multiple
          ? store.orderStore.getByIds.bind(store.orderStore)
          : store.orderStore.getById.bind(store.orderStore)
      }
      populateStore={store.orderStore.load.bind(store.orderStore)}
      renderOption={(order) => (
        {
          value: order?.id,
          label: `${order?.no}`,
        }
      )}
      {...props}
    />
  );
};

export default OrderSelector;
