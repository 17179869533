/* eslint-disable no-param-reassign */
import React from 'react';
import { PushpinOutlined, PushpinFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Button, { ButtonProps } from './Button';

export type PinButtonProps = ButtonProps & {
  pinned: boolean;
  onPinned: (isPinned: boolean, e: any) => void;
  title?: string;
};

const PinButton: React.FC<PinButtonProps> = ({
  pinned = false,
  onPinned,
  title,
  ...props
}) => {
  const { t } = useTranslation();
  title = (pinned) ? title || t<string>('confirm.pin.title') : t<string>('confirm.unpin.title');

  const onClickHandle = (e: any) => {
    onPinned(!pinned, e);
  };

  return (
    <Button
      onClick={onClickHandle}
      title={title}
      icon={pinned ? <PushpinFilled/> : <PushpinOutlined/>}
      type={'link'}
      {...props}
    />
  );
};

export default PinButton;
