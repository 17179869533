import React, { ReactElement } from 'react';
import { Form as AntdForm, FormProps as AntdFormProps, FormInstance, FormItemProps as AntdFormItemProps } from 'antd';
import { FormItemLayouts } from './formItemLayouts';

export type FieldData = Parameters<FormInstance['setFields']>[0][0];

export type FormProps = AntdFormProps;

export type FormItemProps = AntdFormItemProps & {
  // Workaround, as these should be set on the inner input element
  onBlur?: (e: FocusEvent) => void;
};

const FormItem: React.FC<FormItemProps> = (props) => <AntdForm.Item {...props} colon={false}/>;

const Form: React.FC<FormProps> & {
  Item: typeof FormItem;
  useForm: typeof AntdForm.useForm;
  List: typeof AntdForm.List;
  ErrorList: typeof AntdForm.ErrorList;
  Provider: typeof AntdForm.Provider;
  useFormInstance: typeof AntdForm.useFormInstance;
} = ({ children, ...props }) => (
  <AntdForm
    {...FormItemLayouts.default}
    labelAlign={'left'}
    {...props}
  >
    {children as ReactElement}
  </AntdForm>
);

Form.Item = FormItem;
Form.useForm = AntdForm.useForm;
Form.List = AntdForm.List;
Form.ErrorList = AntdForm.ErrorList;
Form.Provider = AntdForm.Provider;
Form.useFormInstance = AntdForm.useFormInstance;

export default Form;
