import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBagsShopping } from '@fortawesome/pro-solid-svg-icons';
import { observer } from 'mobx-react-lite';
import Modal, { ModalProps } from '../../shared/modal/Modal';
import OrderDetails from './OrderDetails';
import { useMount } from '../../../hooks/useMount';
import { useStore } from '../../../hooks/useStore';
import Select from '../../shared/inputs/Select';
import { EnDash } from '../../shared/unicodeWrapper/EnDash';
import { OperationWidgetConfig } from './operationWidgetConfig';
import { Operation } from '../../../models/operation';

export type OrderWidgetDetailsModalProps = ModalProps & {
  useSelectedOperation: [Operation | undefined, (param: Operation | undefined) => void],
  showOperationSelection?: boolean,
  widgetConfig: OperationWidgetConfig,
  displayHierarchyInEventLogs?: boolean,
};

const OrderWidgetDetailsModal: React.FC<OrderWidgetDetailsModalProps> = ({
  useSelectedOperation = [undefined, () => undefined],
  showOperationSelection = false,
  widgetConfig,
  displayHierarchyInEventLogs = true,
  open,
  ...props
}) => {
  const store = useStore();
  const { t } = useTranslation();
  const [initialOperation] = useSelectedOperation;
  const [selectedOperation, setSelectedOperation] = useState<Operation | undefined>(undefined);

  const overallOperation = useRef<Operation | undefined>(undefined);

  useMount(() => {
    if (initialOperation?.id && open) {
      store.validStartTransitionsStore.loadValidStartTransitions(initialOperation.id);
      store.operationStore.loadAll({ params: { orderId: initialOperation.orderId } });
      setSelectedOperation(initialOperation);
      overallOperation.current = Operation.fromPlainObject<Operation>({
        id: 0,
        no: t('activeOperationWidget.overall'),
        orderId: initialOperation.orderId,
      }, store);
    } else {
      setSelectedOperation(undefined);
    }
  }, [initialOperation, open]);

  const OperationSelect = ({ operations }: { operations: Operation[] }) => (
    <>
      {`${EnDash()} ${t('operation.model.one')}: `}
      <Select
        style={{ minWidth: '180px' }}
        value={selectedOperation?.id}
        onSelect={(id) => {
          if (id) {
            setSelectedOperation(store.operationStore.getById(id));
          } else {
            setSelectedOperation(overallOperation.current);
          }
        }}
      >
        {operations.map((operation) => (
          <Select.Option value={operation.id} key={operation.id}>
            {operation.no}
            {' '}
            {operation.name}
          </Select.Option>
        ))}
      </Select>
    </>
  );

  const orderOperations = store.operationStore.getByOrderId(selectedOperation?.orderId || 0);

  if (selectedOperation) {
    let operations;
    if (selectedOperation?.id === 0) {
      operations = [...orderOperations];
    } else {
      operations = [selectedOperation];
    }

    if (overallOperation.current && orderOperations.length > 1) {
      orderOperations.push(overallOperation.current);
    }

    return (
      <Modal
        width="90%"
        bodyStyle={{ minHeight: '80vh' }}
        title={(
          <>
            <FontAwesomeIcon icon={faBagsShopping}/>
            {' '}
            {`${t('order.model.one')}: ${initialOperation?.order?.no}`}
            {' '}
            {showOperationSelection && (<OperationSelect operations={orderOperations}/>)}
          </>
        )}
        footer={false}
        open={open}
        {...props}
      >
        <OrderDetails
          operations={operations}
          widgetConfig={widgetConfig}
          displayHierarchyInEventLogs={displayHierarchyInEventLogs}
        />
      </Modal>
    );
  }

  return '';
};

export default observer(OrderWidgetDetailsModal);
