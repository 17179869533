import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBook} from '@fortawesome/free-solid-svg-icons';
import {Col, Row} from 'antd';
import dayjs from 'dayjs';
import DateRangePicker from '../shared/inputs/DateRangePicker';
import FormModal from '../shared/modal/FormModal';
import AddButton from '../shared/buttons/AddButton';
import CloseButton from '../shared/buttons/CloseButton';
import WidgetTable from '../shared/widgets/WidgetTable';
import {getPagination} from '../shared/widgets/WidgetSettingsHelper';
import {useStore} from '../../hooks/useStore';
import {useModulePolicy} from '../../hooks/useModulePolicy';
import {collaboration} from '../../models/scope';
import {TableColumns} from '../shared/tables/columns';

const ShiftBooksTableModal = ({models, widgetConfig, queryData, identifier, settings, ...props}) => {
  const {t} = useTranslation();
  const store = useStore();
  const policy = useModulePolicy(store, [collaboration]);
  const [fromDate, setFromDate] = useState(dayjs().subtract(settings?.queryTimeSpan, 'hours'));
  const [toDate, setToDate] = useState(dayjs());

  const onDateRangeChange = (dates) => {
    /* no index check needed, as long that the DateRangePicker is configured to not
     allow null dates and may not be cleared */
    setFromDate(dates[0]);
    setToDate(dates[1]);
    queryData(dates[0], dates[1]);
  };

  return (
    <FormModal
      fullscreen={store.clientStore.isMobile}
      title={(
        <>
          <FontAwesomeIcon icon={faBook}/>
          {' '}
          {t('shiftBook.shiftBooksTableModal.title')}
        </>
        )}
      open
      width={'80%'}
      onCancel={{...props}.onCancel}
    >
      <Row type={'flex'} justify={'space-between'} style={{marginBottom: '12px'}}>
        <Col xl={8} md={10} sm={16} xs={24}>
          <DateRangePicker
            dateTimeFrom={fromDate}
            dateTimeTo={toDate}
            onChange={onDateRangeChange}
            placeholder={[t('dateRangePicker.placeholder.from'), t('dateRangePicker.placeholder.to')]}
            allowEmpty={[false, false]}
            allowClear={false}
            showDefaultTimeRanges
          />
        </Col>
        <Col>
          <AddButton
            onClick={{...props}.onAdd}
            type={'primary'}
            disabled={!policy.canEdit({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})}
          />
        </Col>
      </Row>
      <WidgetTable
        models={models.filter((sb) => dayjs(sb.createdAt) >= fromDate && dayjs(sb.createdAt) <= toDate)}
        loading={store.shiftBookStore.isLoadingCollection}
        columns={[TableColumns.id({defaultSortOrder: 'descend'}), ...widgetConfig.getTableColumns()]}
        pagination={getPagination(settings?.['pagination.entries'], settings?.['pagination.showTotal'])}
      />
      <Row type={'flex'} justify={'space-between'} style={{marginTop: '12px'}}>
        <Col xl={8} md={10} sm={16} xs={24}>
          <span/>
        </Col>
        <Col>
          <CloseButton onClick={{...props}.onCancel}/>
        </Col>
      </Row>
    </FormModal>
  );
};

export default observer(ShiftBooksTableModal);
