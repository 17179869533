import React from 'react';
import { FormInstance } from 'antd';
import { observer } from 'mobx-react-lite';
import { useMount } from '../../../hooks/useMount';
import Form from '../form/Form';
import WidgetSettingFormField from './WidgetSettingFormField';
import Tabs, { TabItems } from '../tabs/Tabs';
import { BaseWidgetConfig } from './baseWidgetConfig';
import { ConfigField } from '../../../types/widgetConfig';

export type WidgetSettingsFormProps = {
  widgetConfig: BaseWidgetConfig & {
    [key: string]: any;
  };
  form: FormInstance;
};

const WidgetSettingsForm: React.FC<WidgetSettingsFormProps> = ({ widgetConfig, form }) => {
  useMount(() => {
    form.setFieldsValue(widgetConfig.getValues());
  });

  const items: TabItems = widgetConfig.tabs.map((tab) => {
    const children = widgetConfig.getSettings(tab.key).map((field: ConfigField) => (
      <div key={field.id as string | number}>
        <WidgetSettingFormField field={field}/>
      </div>
    ));
    return {
      children,
      key: tab.key,
      label: tab.label,
      forceRender: true,
    };
  });

  return (
    <Form form={form}>
      <Tabs
        items={items}
      />
    </Form>
  );
};

export default observer(WidgetSettingsForm);
