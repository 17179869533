import {SensorState} from '../models/sensor';
import appConfig from './appConfig';

export const getStateColor = (state) => {
  switch (state) {
    case SensorState.GOOD:
      return appConfig.stateColors.good;
    case SensorState.WARNING:
      return appConfig.stateColors.warning;
    case SensorState.BAD:
      return appConfig.stateColors.bad;
    default:
      return '#000000';
  }
};
