import {useTranslation} from 'react-i18next';
import styles from './RemarkFormItem.module.scss';
import Input from '../shared/inputs/Input';
import Form from '../shared/form/Form';
import {ValidationRules} from '../../utils/validationRules';
import Button from '../shared/buttons/Button';

const RemarkFormItem = ({
  maxLength = 40,
  form,
  name,
  remarkRequired,
  hidden = false,
  manualShowRemark,
  setManualShowRemark,
  showLabel = true,
}) => {
  const {t} = useTranslation();
  const showRemark = hidden || remarkRequired || manualShowRemark;
  const buttonsEnabled = !hidden;
  return (
    <>
      {buttonsEnabled && (
        <Button
          type={'link'}
          className={styles.primaryText}
          onClick={() => setManualShowRemark(true)}
          style={{display: showRemark ? 'none' : 'inline', padding: 0}}
          disabled={showRemark}
        >
          {t('producesMaterialWidget.form.addRemark')}
        </Button>
      )}
      <div style={{display: showRemark ? 'inline' : 'none'}}>
        <Form.Item
          name={name}
          labelCol={{span: 24}}
          wrapperCol={{span: 24}}
          label={showLabel && t('producesMaterialWidget.form.label.remark')}
          rules={[ValidationRules.requiredIf(remarkRequired)]}
          hidden={hidden}
          style={{marginBottom: 0}}
        >
          <Input.TextArea
            maxLength={maxLength}
          />
        </Form.Item>
        {!remarkRequired && buttonsEnabled && (
          <Button
            type={'link'}
            className={styles.redText}
            onClick={() => {
              setManualShowRemark(false);
              form.setFieldsValue({remark: null});
            }}
            style={{display: 'inline', padding: 0}}
          >
            {t('producesMaterialWidget.form.deleteRemark')}
          </Button>
        )}
      </div>
    </>
  );
};

export default RemarkFormItem;
