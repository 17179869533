import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Col, Row} from 'antd';
import {observer} from 'mobx-react-lite';
import TextArea from 'antd/lib/input/TextArea';
import Modal from '../../shared/modal/Modal';
import Form from '../../shared/form/Form';
import {ValidationRules} from '../../../utils/validationRules';
import DefaultFormActions from '../../shared/form/DefaultFormActions';
import {useStore} from '../../../hooks/useStore';

const InspectionTaskDeactivationModal = ({task, onClose, ...props}) => {
  const {t} = useTranslation();
  const {inspectionTaskStore} = useStore();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async ({remark}) => {
    setSubmitting(true);
    try {
      await inspectionTaskStore.deactivate(task.id, remark);
      setSubmitting(false);
      onClose();
    } catch {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      title={t('inspectionTask.deactivationModal.title')}
      onCancel={onClose}
      open
      footer={false}
      width={'35%'}
      {...props}
    >
      <Form onFinish={handleSubmit}>
        <Row style={{marginBottom: '2em'}}>
          <Col span={8}>
            {t('inspectionTask.model.one')}
            :
          </Col>
          <Col span={16}>
            {task?.name || '-'}
          </Col>
        </Row>
        <Form.Item
          name="remark"
          rules={[ValidationRules.required()]}
          label={t('inspectionTask.model.attributes.deactivatedRemark')}
        >
          <TextArea/>
        </Form.Item>
        <DefaultFormActions
          submitText={t('inspectionTask.actions.deactivate')}
          onCancel={onClose}
          saving={submitting}
        />
      </Form>
    </Modal>
  );
};

export default observer(InspectionTaskDeactivationModal);
