import { observable, makeObservable, runInAction } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import {
  ProcessDataBulkImportRequestData,
  ProcessDataBulkImportRequestOptions,
  ProcessDataPoint,
} from '../models/processDataPoint';
import { RootStore } from './rootStore';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';
import i18n from '../i18n/i18n';

export enum TransitionFilters {
  SYSTEM = 'SYSTEM',
  USER = 'USER',
  WITHOUT = 'WITHOUT'
}

export class ProcessDataPointStore extends EntityStore<ProcessDataPoint> {
  processDataPoints: ProcessDataPoint[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'processDataPoints', Api.processDataPoints, ProcessDataPoint);

    makeObservable(this, {
      processDataPoints: observable,
    });
  }

  async import(
    reqData: ProcessDataBulkImportRequestData,
    options: ProcessDataBulkImportRequestOptions = {}
  ) {
    const pendingAction = 'bulk';
    this.addPendingAction(pendingAction);
    return this.api[pendingAction](reqData, options?.params).then(({ data, ...rest }: { data: string[] }) => {
      // t('flashMessages.createSuccess')
      this.addMessage(
        new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.createSuccess')),
        options
      );
      runInAction(() => this.removePendingAction(pendingAction));
      if (options?.raw) {
        return { data, ...rest };
      }

      const { deleteAllDatapoints } = reqData;

      if (deleteAllDatapoints) {
        this.clear();
      }

      return this.addAll(data.map((d) => this.createModelInstance(d)));
    }).catch((e: any) => this.handleApiError(e, pendingAction));
  }
}
