import React from 'react';
import { PrintJob } from '../../../models/printJob';

export type PrintJobPrinterTabProps = {
  job: PrintJob;
};
const PrintJobPrinterTab: React.FC<PrintJobPrinterTabProps> = ({ job }) => {
  const { printer } = job;

  return (
    <div style={{ height: '80vh' }}>
      <iframe
        // @ts-ignore
        title={printer.name}
        // @ts-ignore
        src={printer.webUrl}
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default PrintJobPrinterTab;
