import React from 'react';
import { Form as AntdForm, FormItemProps } from 'antd';
import { ValidationRules } from '../../../utils/validationRules';

export type NumericFormItemProps = FormItemProps;

const NumericFormItem: React.FC<NumericFormItemProps> = ({ rules, ...props }) => (
  <AntdForm.Item
    {...props}
    rules={rules && rules.length ? [...rules, ValidationRules.safeNumber] : [ValidationRules.safeNumber]}
  />
);

export default NumericFormItem;
