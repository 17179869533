import React from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {Space} from 'antd';
import Descriptions from '../shared/descriptions/Descriptions';
import Label from '../shared/dataDisplay/Label';
import Barcode, {BarcodeType} from '../shared/barcode/Barcode';
import {transformation} from '../../utils/transformations';
import {datetimeFormat} from '../../config/dayjs';
import EditButton from '../shared/buttons/EditButton';
import RemarkFormItem from '../producedMaterial/RemarkFormItem';
import UserFullName from '../shared/UserFullName';

const BatchDescriptions = ({batch, amount, unit, form, manualShowRemark, setManualShowRemark}) => {
  const {t} = useTranslation();
  if (!batch) {
    return '';
  }

  const batchRemark = form?.getFieldValue('remark') === undefined ? batch.remark : form.getFieldValue('remark');

  return (
    <Descriptions column={1} bordered>
      <Descriptions.Item
        key={'batch.no'}
        label={<Label>{t('batch.model.attributes.no')}</Label>}
      >
        <Barcode type={BarcodeType.Barcode} value={batch.no} displayValue height={30}/>
      </Descriptions.Item>
      <Descriptions.Item
        key={'amount'}
        label={<Label>{t('batch.model.attributes.quantity')}</Label>}
      >
        {`${amount || batch.quantity} ${unit || batch.unitOfMeasure?.label}`}
      </Descriptions.Item>
      <Descriptions.Item
        key={'batch.material'}
        label={<Label>{t('batch.model.attributes.material')}</Label>}
      >
        {batch.material?.label}
      </Descriptions.Item>
      <Descriptions.Item
        key={'batch.createdAt'}
        label={<Label>{t('batch.model.attributes.createdAt')}</Label>}
      >
        {transformation.datetime({format: datetimeFormat})(new Date(batch.createdAt))}
      </Descriptions.Item>
      <Descriptions.Item
        key={'batch.createdBy'}
        label={<Label>{t('batch.model.attributes.createdBy')}</Label>}
      >
        <UserFullName user={batch?.user}/>
      </Descriptions.Item>
      <Descriptions.Item
        key={'batch.remark'}
        label={<Label>{t('batch.model.attributes.remark')}</Label>}
      >
        {(!batchRemark || manualShowRemark) && setManualShowRemark && (
          <RemarkFormItem
            form={form}
            name={'remark'}
            manualShowRemark={manualShowRemark}
            setManualShowRemark={setManualShowRemark}
            showLabel={false}
          />
        )}
        {batchRemark && !manualShowRemark && setManualShowRemark && (
          <Space>
            {batchRemark}
            <EditButton onClick={() => setManualShowRemark(true)}/>
          </Space>
        )}
        {!setManualShowRemark && batchRemark}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default observer(BatchDescriptions);
