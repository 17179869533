import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import FormModal from '../shared/modal/FormModal';
import {useStore} from '../../hooks/useStore';
import UnclassifiedInterruptionsForm from './UnclassifiedInterruptionsForm';

const UnclassifiedInterruptionsModal = ({
  record,
  onCancel,
  onSubmit,
  scopeSources,
  selectSourceLeavesOnly,
  ...props
}) => {
  const {t} = useTranslation();
  const store = useStore();
  const [fields, setFields] = useState([]);

  return (
    <FormModal
      title={(
        <>
          <FontAwesomeIcon icon={faExclamationCircle}/>
          {' '}
          {t('unclassifiedInterruption.modal.title')}
        </>
      )}
      open
      onCancel={onCancel}
      {...props}
    >
      <UnclassifiedInterruptionsForm
        record={record}
        fields={fields}
        onChange={setFields}
        saving={store.operationStateLogStore.isMutationInProgress}
        onSubmit={onSubmit}
        onCancel={onCancel}
        scopeSources={scopeSources}
        selectSourceLeavesOnly={selectSourceLeavesOnly}
      />
    </FormModal>
  );
};

export default UnclassifiedInterruptionsModal;
