import {Col} from 'antd';
import Form from '../../shared/form/Form';
import {ValidationRules} from '../../../utils/validationRules';
import Select from '../../shared/inputs/Select';
import Radio from '../../shared/inputs/Radio';

const FeatureColumn = ({
  catalogFeatures,
  disabled,
  name,
  isOverrule = false,
  overruleActive,
}) => {
  const relevantCatalogFeatures = catalogFeatures.filter(
    (feature) => (feature.isOverrule === isOverrule)
  );

  return (
    <Col span={24}>
      <Form.Item
        wrapperCol={{span: 24}}
        name={name}
        style={{marginBottom: 0}}
        rules={[ValidationRules.required()]}
      >
        {relevantCatalogFeatures.length > 5 ? (
          <Select style={{width: '100%'}} disabled={disabled || (!isOverrule && overruleActive)} autoFocus>
            {relevantCatalogFeatures.map((feature) => (
              <Select.Option key={feature.id} value={feature.id}>
                {feature.text}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <Radio.Group disabled={disabled || (!isOverrule && overruleActive)}>
            {relevantCatalogFeatures.map((feature, index) => (
              <Radio.Button key={feature.id} value={feature.id} autoFocus={index === 0}>
                {feature.text}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      </Form.Item>
    </Col>
  );
};

export default FeatureColumn;
