import { cloneDeep } from 'lodash';
import { Rule } from 'antd/es/form';
import i18n from '../../../i18n/i18n';
import { BaseWidgetConfig } from '../../shared/widgets/baseWidgetConfig';
import { ValidationRules } from '../../../utils/validationRules';
import { getAllI18nTranslations } from '../../../utils/translations';
import { StorageUnit } from '../../../models/storageUnit';
import { FieldType } from '../../../types/widgetConfig';
import { RootStore } from '../../../stores/rootStore';
import { WidgetActionExtension } from '../../shared/widgets/WidgetActionExtension';

const getOtherFullPath = (rule: Rule, otherName: string): string[] => {
  const split = (rule as any).field.split('.');
  return [`${split[0]}.${split[1]}`, split[2], otherName];
};

export class InventoryWidgetConfig extends BaseWidgetConfig {
  constructor(rootStore: RootStore, widgetIdentifier: string) {
    super(rootStore, widgetIdentifier, undefined, [
      new WidgetActionExtension(rootStore),
    ]);

    this.tabConfigs.set('settings', [
      {
        id: 'title',
        label: i18n.t<string>('widget.settings.title.label'),
        helpText: i18n.t<string>('widget.settings.title.help'),
        type: FieldType.Translatable,
        // t('inventoryWidget.title')
        defaultValue: getAllI18nTranslations('inventoryWidget.title'),
        validations: [ValidationRules.required()],
      },
      {
        id: 'layout',
        label: i18n.t<string>('inventoryWidget.settings.layout.label'),
        helpText: i18n.t<string>('inventoryWidget.settings.layout.help'),
        type: FieldType.RadioField,
        validations: [ValidationRules.minNumber(0), ValidationRules.maxNumber(100)],
        defaultValue: 'square',
        options: [
          {
            value: 'horizontal',
            label: i18n.t('inventoryWidget.settings.layout.horizontal.label'),
          },
          {
            value: 'vertical',
            label: i18n.t('inventoryWidget.settings.layout.vertical.label'),
          },
          {
            value: 'square',
            label: i18n.t('inventoryWidget.settings.layout.square.label'),
          },
        ],
      },
    ]);

    this.tabConfigs.set('inventorySlots', [
      {
        id: 'inventorySlots.tabs',
        type: FieldType.CustomTabEditor,
        defaultValue: [{
          title: i18n.t('storageUnit.model.one'),
          storageArea: null,
          storageUnit: null,
          displayGauge: true,
          displayTable: true,
          property: [],
          barcode: [],
          barcodeWidth: 2,
          barcodeHeight: 100,
          qrCode: [],
          qrCodeSize: 7,
          dataMatrix: [],
          dataMatrixSize: 8,
        }],
        maxTabCount: 4,
        disableGroupEditor: true,
        tabFields: [
          {
            id: 'title',
            label: i18n.t<string>('inventoryWidget.settings.slot.label'),
            helpText: i18n.t<string>('inventoryWidget.settings.slot.help'),
            type: FieldType.String,
            defaultValue: i18n.t('storageUnit.model.one'),
            validations: [ValidationRules.required()],
          },
          {
            id: 'storageArea',
            label: i18n.t<string>('inventoryWidget.settings.storageArea.label'),
            helpText: i18n.t<string>('inventoryWidget.settings.storageArea.help'),
            type: FieldType.Select,
            defaultValue: null,
            allowClear: true,
            options: this.rootStore.storageAreaStore.storageAreas.map((storageArea) => ({
              value: storageArea.id,
              label: storageArea.no,
            })),
            validations: [],
          },
          {
            id: 'storageUnit',
            label: i18n.t<string>('inventoryWidget.settings.storageUnit.label'),
            helpText: i18n.t<string>('inventoryWidget.settings.storageUnit.help'),
            type: FieldType.StorageUnitSelectorField,
          },
          {
            id: 'displayGauge',
            label: i18n.t<string>('inventoryWidget.settings.displayGauge.label'),
            helpText: i18n.t<string>('inventoryWidget.settings.displayGauge.help'),
            type: FieldType.Boolean,
            defaultValue: true,
            validations: [],
          },
          {
            id: 'displayTable',
            label: i18n.t<string>('inventoryWidget.settings.displayTable.label'),
            helpText: i18n.t<string>('inventoryWidget.settings.displayTable.help'),
            type: FieldType.Boolean,
            defaultValue: true,
            validations: [],
          },
          {
            id: 'property',
            label: i18n.t<string>('inventoryWidget.settings.tableData.label'),
            type: FieldType.PropertiesSelectDisplayField,
            modelOptions: StorageUnit.allDisplayableProperties(this.rootStore),
            validations: [],
          },
          {
            id: 'barcode',
            label: i18n.t<string>('inventoryWidget.settings.barcode.label'),
            helpText: i18n.t<string>('inventoryWidget.settings.barcode.help'),
            type: FieldType.SelectMultiple,
            defaultValue: [],
            validations: [
              ValidationRules.notInField(
                (rule: Rule) => getOtherFullPath(rule, 'qrCode'),
                i18n.t<string>('inventoryWidget.settings.qrCode.label')
              ),
              ValidationRules.notInField(
                (rule: Rule) => getOtherFullPath(rule, 'dataMatrix'),
                i18n.t('inventoryWidget.settings.dataMatrix.label')
              ),
            ],
            options: StorageUnit.allDisplayableProperties(this.rootStore).map((item) => ({
              value: item.key,
              label: item.title,
            })),
          },
          {
            id: 'barcodeWidth',
            label: i18n.t<string>('inventoryWidget.settings.barcodeWidth.label'),
            helpText: i18n.t<string>('inventoryWidget.settings.barcodeWidth.help'),
            type: FieldType.Number,
            validations: [],
            defaultValue: 2,
          },
          {
            id: 'barcodeHeight',
            label: i18n.t<string>('inventoryWidget.settings.barcodeHeight.label'),
            helpText: i18n.t<string>('inventoryWidget.settings.barcodeHeight.help'),
            type: FieldType.Number,
            validations: [],
            defaultValue: 100,
          },
          {
            id: 'qrCode',
            label: i18n.t<string>('inventoryWidget.settings.qrCode.label'),
            helpText: i18n.t<string>('inventoryWidget.settings.qrCode.help'),
            type: FieldType.SelectMultiple,
            defaultValue: [],
            validations: [
              ValidationRules.notInField(
                (rule) => getOtherFullPath(rule, 'barcode'),
                i18n.t('inventoryWidget.settings.barcode.label')
              ),
              ValidationRules.notInField(
                (rule) => getOtherFullPath(rule, 'dataMatrix'),
                i18n.t('inventoryWidget.settings.dataMatrix.label')
              ),
            ],
            options: StorageUnit.allDisplayableProperties(this.rootStore).map((item) => ({
              value: item.key,
              label: item.title,
            })),
          },
          {
            id: 'qrCodeSize',
            label: i18n.t<string>('inventoryWidget.settings.qrCodeSize.label'),
            helpText: i18n.t<string>('inventoryWidget.settings.qrCodeSize.help'),
            type: FieldType.Number,
            validations: [],
            defaultValue: 7,
          },
          {
            id: 'dataMatrix',
            label: i18n.t<string>('inventoryWidget.settings.dataMatrix.label'),
            helpText: i18n.t<string>('inventoryWidget.settings.dataMatrix.help'),
            type: FieldType.SelectMultiple,
            defaultValue: [],
            validations: [
              ValidationRules.notInField(
                (rule) => getOtherFullPath(rule, 'barcode'),
                i18n.t('inventoryWidget.settings.barcode.label')
              ),
              ValidationRules.notInField(
                (rule) => getOtherFullPath(rule, 'qrCode'),
                i18n.t('inventoryWidget.settings.qrCode.label')
              ),
            ],
            options: StorageUnit.allDisplayableProperties(this.rootStore).map((item) => ({
              value: item.key,
              label: item.title,
            })),
          },
          {
            id: 'dataMatrixSize',
            label: i18n.t<string>('inventoryWidget.settings.dataMatrixSize.label'),
            helpText: i18n.t<string>('inventoryWidget.settings.dataMatrixSize.help'),
            type: FieldType.Number,
            validations: [],
            defaultValue: 8,
          },
        ],
      },
    ]);
  }

  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'settings',
        label: i18n.t('widget.WidgetSettingsModal.settingConfigTabTitle'),
        closable: false,
      },
      {
        key: 'inventorySlots',
        label: i18n.t('inventoryWidget.settings.inventorySlots.title'),
      },
      ...super.tabs,
    ];
  }

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'title');
  }
  getWidgetLayout() {
    return this.getSettingValue('settings', 'layout');
  }
  getWidgetProperties() {
    return this.getSelectedProperties('widget', 'properties');
  }

  getInventorySlots() {
    const tabsConfig: any[] = cloneDeep(this.getSettingValue('inventorySlots', 'inventorySlots.tabs'));

    tabsConfig.forEach((tabConfig) => {
      tabConfig.columnCount = parseInt(tabConfig.columnCount, 10) || 1;
      tabConfig.property = tabConfig.property?.map(
        (item: any) => this.mapSettingPropertyKeyToProperty({
          id: '',
          modelOptions: StorageUnit.allDisplayableProperties(this.rootStore),
          type: FieldType.PropertiesSelectDisplayField,
        }, item)
      );
    });

    return tabsConfig;
  }
}
