import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styles from '../InspectionTaskPendingWidget.module.scss';
import Input from '../../shared/inputs/Input';
import {ValidationRules} from '../../../utils/validationRules';
import Button from '../../shared/buttons/Button';
import Form from '../../shared/form/Form';

const RemarkFormItem = ({minLength = 0, maxLength = 40, form, remarkRequired, remarkType, hidden = false}) => {
  const {t} = useTranslation();
  const [manualShowRemark, setManualShowRemark] = useState(false);
  const showRemark = hidden || remarkRequired || manualShowRemark || form.getFieldValue(remarkType);
  const buttonsEnabled = !hidden && (remarkType === 'remark' || remarkType === 'fourEyesRemark');

  return (
    <>
      {buttonsEnabled && (
        <Button
          type={'link'}
          className={styles.primaryText}
          onClick={() => setManualShowRemark(true)}
          style={{display: showRemark ? 'none' : 'inline', padding: 0}}
          disabled={showRemark}
        >
          {t('inspectionTaskPendingModal.taskBox.addRemark')}
        </Button>
      )}
      <div style={{display: showRemark ? 'inline' : 'none'}}>
        <Form.Item
          name={remarkType}
          labelCol={{span: 24}}
          wrapperCol={{span: 24}}
          // t('inspectionTaskPendingModal.taskBox.remark')
          // t('inspectionTaskPendingModal.taskBox.overruledRemark')
          // t('inspectionTaskPendingModal.taskBox.fourEyesRemark')
          label={t(`inspectionTaskPendingModal.taskBox.${remarkType}`)}
          rules={[
            ValidationRules.requiredIf(remarkRequired),
            ValidationRules.stringMinLengthAlphanumeric(remarkRequired ? minLength : 0),
            ValidationRules.stringMaxLength(maxLength),
          ]}
          hidden={hidden}
          style={{marginBottom: 0}}
          dependencies={['value']}
        >
          <Input.TextArea
            maxLength={maxLength}
          />
        </Form.Item>
        {!remarkRequired && buttonsEnabled && (
          <Button
            type={'link'}
            className={styles.redText}
            onClick={() => {
              setManualShowRemark(false);
              form.setFieldsValue({remark: null});
            }}
            style={{display: 'inline', padding: 0}}
          >
            {t('inspectionTaskPendingModal.taskBox.deleteRemark')}
          </Button>
        )}
      </div>
    </>
  );
};

export default RemarkFormItem;
