import ActiveOperationDetailsWidget from '../components/operation/ActiveOperationDetailsWidget';
import BarcodeInfoWidget from '../components/batchHandling/BarcodeInfoWidget';
import BatchHandlingWidget from '../components/batchHandling/BatchHandlingWidget';
import BatchQueueWidget from '../components/batchQueue/BatchQueueWidget';
import CurrentPersonnelDetailsWidget from '../components/personnel/CurrentPersonnelDetailsWidget';
import DocumentsWidget from '../components/document/DocumentsWidget';
import InspectionTaskPendingWidget from '../components/inspectionTask/InspectionTaskPendingWidget';
import InterruptionWidget from '../components/interruption/InterruptionWidget';
import InventoryWidget from '../components/inventoryManagement/production/InventoryWidget';
import OrderOperatorWidget from '../components/order/production/OrderOperatorWidget';
import ProducedMaterialWidget from '../components/producedMaterial/ProducedMaterialWidget';
import PrintJobWidget from '../components/printJob/production/PrintJobWidget';
import ShiftBooksWidget from '../components/shiftBook/ShiftBooksWidget';
import SpeedChangeWidget from '../components/speedChange/SpeedChangeWidget';
import StateHistoryWidget from '../components/stateHistory/StateHistoryWidget';
import StateTransitionWidget from '../components/stateTransition/StateTransitionWidget';
import TerminalManagementWidget from '../components/terminalManagement/TerminalManagementWidget';
import UnclassifiedInterruptionsWidget from '../components/unclassifiedInterruption/UnclassifiedInterruptionsWidget';
import YieldWidget from '../components/yield/yieldWidget';
import CockpitViewerWidget from '../components/cockpitViewer/CockpitViewerWidget';
import appConfig from '../utils/appConfig';
import BarcodeWidget from '../components/operation/BarcodeWidget';
import ManualSensorDataWidget from '../components/sensorData/ManualSensorDataWidget';
import MaterialFlowWidget from '../components/inventoryManagement/materialFlow/MaterialFlowWidget';
import StorageUnitStackWidget from '../components/inventoryManagement/storageUnitStacks/StorageUnitStackWidget';
import QualityReportWidget from '../components/qualityReport/QualityReportWidget';
import DosageSetupWidget from '../components/dosage/setup/DosageSetupWidget';
import DosageWidget from '../components/dosage/dosage/DosageWidget';

// register all available dashboard widgets here so they can be found by the widget configuration.
// NOTE: widget components need to respond to 'key' and 'defaultLayout'
const widgetRegistry = [
  ActiveOperationDetailsWidget,
  CurrentPersonnelDetailsWidget,
  DocumentsWidget,
  InspectionTaskPendingWidget,
  InterruptionWidget,
  OrderOperatorWidget,
  ShiftBooksWidget,
  SpeedChangeWidget,
  StateHistoryWidget,
  StateTransitionWidget,
  TerminalManagementWidget,
  UnclassifiedInterruptionsWidget,
  CockpitViewerWidget,
  BarcodeWidget,
];

if (appConfig.modules.enableBatchHandling) {
  widgetRegistry.push(BatchHandlingWidget);
  widgetRegistry.push(BarcodeInfoWidget);
}

if (appConfig.modules.enableBatchQueue) {
  widgetRegistry.push(BatchQueueWidget);
  widgetRegistry.push(ProducedMaterialWidget);
}

if (appConfig.modules.enableDosage) {
  widgetRegistry.push(DosageSetupWidget);
  widgetRegistry.push(DosageWidget);
}

if (appConfig.modules.enableYields) {
  widgetRegistry.push(YieldWidget);
}

if (appConfig.modules.enableManualSensorDataRecording) {
  widgetRegistry.push(ManualSensorDataWidget);
}

if (appConfig.modules.enableInventoryManagement) {
  widgetRegistry.push(InventoryWidget);
  widgetRegistry.push(StorageUnitStackWidget);
  widgetRegistry.push(MaterialFlowWidget);
}

if (appConfig.modules.enablePrintManagement) {
  widgetRegistry.push(PrintJobWidget);
}

if (appConfig.modules.enableQualityReport && appConfig.enableExperimental) {
  widgetRegistry.push(QualityReportWidget);
}

export const WidgetRegistry = widgetRegistry;

export const BadgedWidgets = [ShiftBooksWidget];
