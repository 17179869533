/* eslint-disable no-console */
import { connect } from 'async-mqtt';
import { MqttClient } from 'mqtt';
import { v4 as uuid } from 'uuid';
import appConfig from '../utils/appConfig';
import { rootStoreInstance } from '../stores/rootStore';

export type MQTTHandler = (topic?: string, payload?: any) => void;

class MQTTConnection {
  client: MqttClient | null;

  handlers: Map<string, Map<string, MQTTHandler>>;

  brokerUrl: string;

  constructor() {
    this.client = null;
    this.handlers = new Map<string, Map<string, MQTTHandler>>();

    let brokerUrl = `${MQTTConnection.getRelativeProto()}//${appConfig.mqtt.host}:${appConfig.mqtt.port}`;
    if (appConfig.mqtt.useBackendProxy) {
      brokerUrl = `${MQTTConnection.getRelativeProto()}//${window.location.host}/ws`;
    }
    if (process.env.NODE_ENV === 'development') {
      brokerUrl = `${MQTTConnection.getRelativeProto()}//localhost:${appConfig.mqtt.port}`;
    }
    this.brokerUrl = brokerUrl;

    this.handleMessage = this.handleMessage.bind(this);
  }

  /**
   * connect to the MQTT Broker; handle the 'message' event listener
   *
   * @returns {void}
   */
  connect(): void {
    if (!appConfig.mqtt?.host && !appConfig.mqtt?.useBackendProxy) {
      console.warn('No MQTT server configured. Not attempting to connect.');
      return;
    }
    rootStoreInstance.mqttStore.setIsReady(false);
    try {
      console.log(`Init MQTT server connection on "${this.brokerUrl}"...`);
      this.client = connect(this.brokerUrl, {
        username: appConfig.mqtt.username,
        password: appConfig.mqtt.password,
      });

      this.client.on('reconnect', () => {
        console.log('Reconnecting to MQTT server...');
        rootStoreInstance.mqttStore.setIsOnline(false);
      });

      this.client.on('connect', () => {
        console.log('Successfully connected to MQTT server.');
        rootStoreInstance.mqttStore.setIsOnline(true);
        rootStoreInstance.mqttStore.setIsReady(true);
      });

      this.client.on('offline', () => {
        console.warn('MQTT server went offline');
        rootStoreInstance.mqttStore.setIsOnline(false);
      });

      this.client.on('error', (e) => {
        console.warn('Connection to MQTT broker failed', e);
      });

      this.client.on('message', this.handleMessage);
    } catch (err) {
      console.error('MQTT connect failed', err);
    }
  }

  /**
   * Subscribes to a topic with the provided handler. Returns an id needed for unsubscribing. Returns an empty string
   * if mqtt client is not initialized.
   * @param topic
   * @param handler
   */
  subscribe(topic: string, handler: MQTTHandler): string {
    let id = '';
    if (!this.client) {
      return id;
    }
    this.client.subscribe(topic);
    let topicMap = this.handlers.get(topic);
    if (!topicMap) {
      topicMap = new Map<string, MQTTHandler>();
      this.handlers.set(topic, topicMap);
    }
    id = uuid();
    topicMap.set(id, handler);
    return id;
  }

  /**
   * Removes the handler identified by the provided topic and id.
   * @param topic
   * @param id
   */
  unsubscribe(topic: string, id: string): void {
    if (!this.client) {
      return;
    }
    const topicMap = this.handlers.get(topic);
    if (!topicMap) {
      this.client.unsubscribe(topic);
      return;
    }
    topicMap.delete(id);
    if (topicMap.size === 0) {
      this.client.unsubscribe(topic);
    }
  }

  handleMessage(topic: string, message: Buffer) {
    let payload: string;
    // try json parsing
    try {
      payload = JSON.parse(message.toString() || '');
    } catch (err) {
      payload = message.toString();
    }

    // call topic specific handler
    try {
      this.handlers.forEach((topicMap, key) => {
        const pattern = key.replaceAll('/', '\\/');
        const singlePattern = pattern.replaceAll('+', '[^/]*');
        const multiPattern = pattern.replaceAll('#', '.*');
        const singleChecker = new RegExp(singlePattern);
        const multiChecker = new RegExp(multiPattern);

        if (singleChecker.test(topic) || multiChecker.test(topic)) {
          topicMap.forEach((handler) => {
            handler(topic, payload);
          });
        }
      });
    } catch (err) {
      // NOOP
    }
  }

  static getRelativeProto() {
    const siteProto = window.location.protocol;
    if (siteProto === 'http:') {
      return 'ws:';
    }
    if (siteProto === 'https:') {
      return 'wss:';
    }
    throw new Error(`Accessed DF with unknown protocol: ${siteProto}`);
  }
}

const mqtt = new MQTTConnection();
const TOPIC_BASE = 'mes';
export { mqtt, TOPIC_BASE };
