import {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Tree} from 'antd';
import {faFile} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useMount} from '../../hooks/useMount';
import {WidgetLayouts} from '../shared/widgets/WidgetLayouts';
import {WidgetLayout} from '../../models/widgetLayout';
import OperatorWidget from '../operator/shared/OperatorWidget';
import {DocumentWidgetConfig} from './documentWidgetConfig';
import DocumentsDetailModal from './DocumentsDetailModal';
import {useStore} from '../../hooks/useStore';
import {ScopeContext} from '../../policies/scopeContext';
import {documentManagement} from '../../models/scope';
import styles from './DocumentsWidget.module.scss';

const {DirectoryTree} = Tree;

const DocumentsWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedDocumentKeys, setSelectedDocumentKeys] = useState();
  const [selectedDocument, setSelectedDocument] = useState();
  const widgetConfig = useMemo(() =>
    new DocumentWidgetConfig(store, DocumentsWidget.identifier), []);

  useMount(() => {
    if (store.workplaceStore.selectedWorkplace) {
      store.workplaceStore.loadRelevantDocumentsToSelectedWorkplace(store.documentTypeStore.documentTypes);
    }
  }, [store.workplaceStore.selectedWorkplace?.id, store.operationStore.active?.id]);

  useMount(() => {
    if (store.documentTypeStore.documentTypes?.length <= 0) {
      store.documentTypeStore.loadAll();
    }
  }, [store.documentTypeStore.documentTypes?.length]);

  const onSelect = (keys, event) => {
    if (event.node.isLeaf) {
      setSelectedDocumentKeys(keys);
      setSelectedDocument(event.node.document);
      setModalVisible(true);
    }
  };

  const onHeadClick = () => {
    setSelectedDocumentKeys([]);
    setSelectedDocument(undefined);
    setModalVisible(true);
  };

  return (
    <ScopeContext.Provider value={[documentManagement]}>
      <OperatorWidget
        onHeadClick={onHeadClick}
        icon={<FontAwesomeIcon icon={faFile}/>}
        title={widgetConfig.getWidgetTitle()}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        manualPath={'/information/document'}
      >
        <DirectoryTree
          showIcon
          onSelect={onSelect}
          treeData={store.workplaceStore
            .getRelevantDocumentsWidgetTree(widgetConfig.getWidgetSetting('document.category.entries'))}
          className={styles.documentsTree}
        />
      </OperatorWidget>
      {modalVisible && (
        <DocumentsDetailModal
          fullscreen={store.clientStore.isMobile}
          selectedKeys={selectedDocumentKeys}
          openedDocument={selectedDocument}
          documentTree={store.workplaceStore.relevantDocumentsTree}
          onCancel={() => setModalVisible(false)}
        />
      )}
    </ScopeContext.Provider>
  );
};

DocumentsWidget.icon = faFile;

DocumentsWidget.identifier = 'DocumentsWidget';
DocumentsWidget.defaultLayout = new WidgetLayout(
  {
    identifier: DocumentsWidget.identifier,
    x: 0,
    y: 10,
    height: 8,
    minHeight: 1,
    width: WidgetLayouts.sixthWidth.w,
    minWidth: WidgetLayouts.sixthWidth.minW,
  }
);
export default observer(DocumentsWidget);
