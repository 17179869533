import { action, computed, observable, makeObservable } from 'mobx';
import { Dayjs } from 'dayjs';
import { RootStore } from './rootStore';
import { Hierarchy } from '../models/hierarchy';
import { Workplace } from '../models/workplace';

export class OfficeStore {
  fromDate: Dayjs | null = null;
  toDate: Dayjs | null = null;
  hierarchyId: number | null = null;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      fromDate: observable,
      toDate: observable,
      hierarchyId: observable,
      hierarchy: computed,
      workplace: computed,
      dates: computed,
      setFromDate: action,
      setToDate: action,
      setHierarchyId: action,
      setDates: action,
    });

    this.rootStore = rootStore;
  }

  setFromDate(fromDate: Dayjs | null) {
    this.fromDate = fromDate;
  }

  setToDate(toDate: Dayjs | null) {
    this.toDate = toDate;
  }

  setHierarchyId(hierarchyId: number | null) {
    this.hierarchyId = hierarchyId;
  }

  setDates(dates: Dayjs[]) {
    if (dates?.length === 2) {
      [this.fromDate, this.toDate] = dates;
    } else {
      this.fromDate = null;
      this.toDate = null;
    }
  }

  get dates(): [Dayjs | null, Dayjs | null] {
    return [this.fromDate, this.toDate];
  }

  get hierarchy(): Hierarchy | undefined {
    return this.hierarchyId
      ? this.rootStore.hierarchyStore.getById(this.hierarchyId) as Hierarchy | undefined
      : undefined;
  }

  get workplace(): Workplace | undefined {
    return this.hierarchyId ? this.rootStore.workplaceStore.getByHierarchyId(this.hierarchyId) : undefined;
  }
}
