import i18n from '../../../../../i18n/i18n';
import {CockpitWidgetConfig} from './cockpitWidgetConfig';
import {ValidationRules} from '../../../../../utils/validationRules';
import {FieldType} from '../../../../../types/widgetConfig';

export class KpiPerOrderCockpitWidgetConfig extends CockpitWidgetConfig {
  static onLoadConfig = async (rootStore) => rootStore.keyPerformanceIndicatorStore.loadAll();

  static widgetType = {
    identifier: 'kpi-per-order',
    name: () => i18n.t('cockpitWidget.widgetTypeIdentifier.kpiPerOrder'),
  };

  // eslint-disable-next-line class-methods-use-this
  getWidgetTypeName() {
    return i18n.t('cockpitWidget.widgetTypeIdentifier.kpiPerOrder');
  }

  get tabs() {
    return [
      this.generalSettingsTab,
      this.workplaceTab,
      {
        key: 'kpiPerOrder',
        label: i18n.t('widget.WidgetSettingsModal.kpiPerOrderConfigTabTitle'),
      },
    ];
  }

  workplace = [
    {
      id: 'hierarchyId',
      label: i18n.t('cockpitWidget.settings.workplace.label'),
      helpText: i18n.t('cockpitWidget.settings.workplace.help'),
      type: FieldType.HierarchyTreeWorkplace,
    },
  ];

  kpiPerOrder = [
    {
      id: 'daysBack',
      label: i18n.t('kpiPerOrderCockpitWidgetConfig.settings.kpiPerOrder.daysBack.label'),
      helpText: i18n.t('kpiPerOrderCockpitWidgetConfig.settings.kpiPerOrder.daysBack.helpText'),
      type: FieldType.Number,
      unit: i18n.t('kpiPerOrderCockpitWidgetConfig.settings.kpiPerOrder.daysBack.unit'),
      defaultValue: 3,
      validations: [ValidationRules.minNumber(1), ValidationRules.maxNumber(7), ValidationRules.required()],
    },
    {
      id: 'barKpiId',
      label: i18n.t('kpiPerOrderCockpitWidgetConfig.settings.kpiPerOrder.barKpiId.label'),
      type: FieldType.Select,
      options: this.rootStore.keyPerformanceIndicatorStore.kpis.map((kpi) => ({
        value: kpi.id,
        label: kpi.name,
      })),
      validations: [ValidationRules.required()],
    },
    {
      id: 'lineKpiIds',
      label: i18n.t('kpiPerOrderCockpitWidgetConfig.settings.kpiPerOrder.lineKpiIds.label'),
      type: FieldType.SelectMultiple,
      options: this.rootStore.keyPerformanceIndicatorStore.kpis.map((kpi) => ({
        value: kpi.id,
        label: kpi.name,
      })),
      validations: [ValidationRules.required(), ValidationRules.notInField(
        'barKpiId',
        i18n.t('kpiPerOrderCockpitWidgetConfig.settings.kpiPerOrder.barKpiId.label')
      )],
      dependencies: ['barKpiId'],
    },
    {
      id: 'yAxisTitle',
      label: i18n.t('kpiPerOrderCockpitWidgetConfig.settings.kpiPerOrder.yAxisTitle.label'),
      type: FieldType.String,
    },
  ];
}
