import React, {useState} from 'react';
import {Col, Row} from 'antd';
import {observer} from 'mobx-react-lite';
import {uniqBy} from 'lodash';
import {useStore} from '../../hooks/useStore';
import Input from '../shared/inputs/Input';
import {useMount} from '../../hooks/useMount';

const ComponentsFormInput = ({onChange}) => {
  const store = useStore();

  const mapComponentsToValues = () => store.operationStore.active?.components?.map((c) => ({
    componentId: c.id,
    amount: c.preparedQuantity || 0,
  }));

  const [values, setValues] = useState(mapComponentsToValues());

  useMount(() => {
    const newValues = mapComponentsToValues();
    setValues(newValues);
    onChange(newValues);
  }, [store.operationStore.active?.components?.map((component) => component.preparedQuantity || 0).join(',')]);

  const onInputChange = (componentId) => (e) => {
    const newValues = [
      ...values,
    ];
    const index = newValues.findIndex((entryValue) => entryValue.componentId === componentId);

    newValues.splice(index, 1);
    newValues.push({
      componentId,
      amount: e.target.value,
    });

    setValues(newValues);
    onChange(newValues);
  };

  return uniqBy(store.operationStore.active?.components, (c) => c.materialId)?.map((component) => (
    <Row gutter={16} key={component.id}>
      <>
        <Col span={12}>
          <div style={{border: 'none'}} className={'roboto-mono ant-input'}>
            <div className={'font-weight-bold'}>{component.material?.no}</div>
            <div className={'small'}>{component.material ? component.material.name : ''}</div>
          </div>
        </Col>
        <Col span={12}>
          <Input
            type={'number'}
            addonAfter={component.unitOfMeasure?.label}
            onChange={onInputChange(component.id)}
            value={values.find((v) => v.componentId === component.id)?.amount || ''}
          />
        </Col>
      </>
    </Row>
  ));
};

export default observer(ComponentsFormInput);
