import { action, observable, makeObservable } from 'mobx';
import { orderBy } from 'lodash';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { CustomProperty } from '../models/customProperty';
import { RootStore } from './rootStore';
import { CustomPropertyDataTypes, CustomPropertyTypes } from '../models/customPropertyDataTypes';

export class CustomPropertyStore extends EntityStore {
  properties: CustomProperty[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'properties', Api.properties, CustomProperty);

    makeObservable(this, {
      properties: observable,
      loadByType: action,
    });
  }

  loadByType(type: CustomPropertyTypes) {
    return this.loadAll({ params: { type } });
  }

  /**
   * If orders is not specified in sortOrder, then the ordering defaults to ascending
   *
   * @param {string} type
   * @param {string | undefined} dataType
   * @param {{orders: string[], iteratees: string[]} | undefined} sortOrder
   * @returns {CustomProperty[]}
   */
  getByType({
    type,
    dataType,
    sortOrder = { iteratees: [], orders: [] },
  }: {
    type: CustomPropertyTypes;
    dataType?: CustomPropertyDataTypes;
    sortOrder?: { iteratees?: any, orders?: any };
  }) {
    let properties = this.properties.filter((prop) => prop.type === type);
    if (dataType) {
      properties = properties.filter((prop) => prop.dataType === dataType);
    }
    if (sortOrder.iteratees.length) {
      return orderBy(properties, sortOrder.iteratees, sortOrder.orders);
    }
    return properties;
  }
}
