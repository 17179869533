import {useState} from 'react';
import {useMount} from './useMount';
import {useStore} from './useStore';

const useWorkplaceRelatedWidgetTitle = (title, workplaceId) => {
  const [widgetTitle, setWidgetTitle] = useState(title);
  const store = useStore();

  useMount(() => {
    let titleSuffix = '';

    if (workplaceId && store.cockpitStore.currentWorkplaceId && workplaceId !== store.cockpitStore.currentWorkplaceId) {
      const {label} = store.workplaceStore.getById(workplaceId);
      titleSuffix = ` - ${label}`;
    }

    setWidgetTitle(`${title}${titleSuffix}`);
  }, [title, workplaceId, store.cockpitStore.currentWorkplaceId]);

  return widgetTitle;
};

export default useWorkplaceRelatedWidgetTitle;
