import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChartLine} from '@fortawesome/free-solid-svg-icons';
import {useStore} from '../../hooks/useStore';
import OperatorWidget from '../operator/shared/OperatorWidget';
import {CockpitViewerWidgetConfig} from './cockpitViewerWidgetConfig';
import {ScopeContext} from '../../policies/scopeContext';
import {processdataCollection} from '../../models/scope';
import {WidgetLayouts} from '../shared/widgets/WidgetLayouts';
import {WidgetLayout} from '../../models/widgetLayout';
import {CockpitWidgetRegistry} from '../../config/cockpitWidgetRegistry';
import {useMount} from '../../hooks/useMount';
import AlertWarning from '../shared/alert/AlertWarning';
import Spinner from '../shared/spinners/Spinner';

const CockpitViewerWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const {t} = useTranslation();
  const workplace = store.workplaceStore.selectedWorkplace;

  useMount(() => {
    store.cockpitStore.loadAll({params: {isWorkplaceCockpit: true}})
      .then((cockpits) => {
        const [workplaceCockpit] = cockpits;
        return store.cockpitDashboardStore.loadByCockpitId(workplaceCockpit.id);
      })
      .then(
        (dashboards) => store.cockpitWidgetStore
          .loadAllWithDependencies({params: {dashboardId: dashboards.map((d) => d.id)}})
      );
  });

  useMount(() => {
    store.cockpitStore.currentWorkplaceId = workplace.id;
  }, [workplace.id]);

  const widgetConfig = useMemo(() => (
    new CockpitViewerWidgetConfig(store, identifier)
  ), [store.cockpitWidgetStore.cockpitWidgets.length]);
  const settings = widgetConfig.getValues();

  const scopes = [processdataCollection];
  const cockpitWidget = store.cockpitWidgetStore.getById(settings.cockpitWidget);
  const check = store.cockpitWidgetStore
    .getWorkplaceCockpitWidgetsWithoutHierarchyLink().find((w) => w.id === settings.cockpitWidget);
  const WidgetFromRegistry = check ? CockpitWidgetRegistry.find((widget) => cockpitWidget?.widgetTypeIdentifier
    && widget.Config.widgetType.identifier === cockpitWidget?.widgetTypeIdentifier) : undefined;

  const isLoading = store.cockpitStore.isLoadingCollection
    || store.cockpitDashboardStore.isLoadingCollection
    || store.cockpitWidgetStore.isLoadingCollection;

  return (
    <ScopeContext.Provider value={scopes}>
      {WidgetFromRegistry ? (
        <WidgetFromRegistry
          isOperatorWidget
          operatorWidgetConfig={widgetConfig}
          title={cockpitWidget.name}
          widgetId={cockpitWidget.id}
          config={cockpitWidget.config}
          identifier={identifier}
          disabled={disabled}
          isEditMode={false}
          allowDelete={false}
        />
      ) : (
        <OperatorWidget
          icon={<FontAwesomeIcon icon={faChartLine}/>}
          title={t('cockpitViewerWidget.title')}
          disabled={disabled}
          minimized={minimized}
          identifier={identifier}
          widgetConfig={widgetConfig}
        >
          {isLoading ? (
            <Spinner fullWidth fullHeight/>
          ) : (
            check === undefined && (
              <AlertWarning
                size={'small'}
                message={t('cockpitViewerWidget.invalidConfiguration')}
              />
            )
          )}
        </OperatorWidget>
      )}
    </ScopeContext.Provider>
  );
};

CockpitViewerWidget.icon = faChartLine;

CockpitViewerWidget.identifier = 'CockpitViewerWidget';
CockpitViewerWidget.defaultLayout = new WidgetLayout({
  identifier: CockpitViewerWidget.identifier,
  x: 2,
  y: 8,
  height: 3,
  minHeight: 3,
  width: WidgetLayouts.sixthWidth.w,
  minWidth: WidgetLayouts.sixthWidth.minW,
});

export default observer(CockpitViewerWidget);
