import { action, observable, makeObservable } from 'mobx';

export class MqttStore {
  /**
   * Only indicates if the client is successfully set up. Required because initial client setup is not guaranteed. But
   * after initial setup client should run forever. Refer to isOnline to see if a connection to the broker exists.
   * true = setup successful
   * false = setup ongoing
   * null = not connecting (e.g. missing configuration)
   */
  clientReady: boolean | null = null;
  /**
   * Indicates if there is a connection between the broker and the client. For normal subscriptions, it is not
   * necessary to check this, as the client will automatically reconnect to the broker.
   */
  isOnline: boolean = false;

  constructor() {
    makeObservable(this, {
      clientReady: observable,
      isOnline: observable,
      setIsOnline: action,
      setIsReady: action,
    });
  }

  setIsOnline(isOnline: boolean) {
    this.isOnline = isOnline;
  }

  setIsReady(clientReady: boolean | null) {
    this.clientReady = clientReady;
  }
}
