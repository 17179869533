import { computed, observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { Sensor, SensorType } from '../models/sensor';
import { sortAlphabetically } from '../components/shared/tables/sorters';
import { RootStore } from './rootStore';

export class SensorStore extends EntityStore<Sensor, string> {
  sensors: Sensor[] = [];
  identityField = 'name';

  constructor(rootStore: RootStore) {
    super(rootStore, 'sensors', Api.sensors, Sensor);

    makeObservable(this, {
      sensors: observable,
      manualSensors: computed,
    });
  }

  async loadLocalAsync(hierarchyId: number): Promise<void> {
    await this.loadAll({
      params: {
        hierarchyId,
        enabled: true,
        includeHierarchyChildren: true,
        publishAsync: true,
        type: SensorType.DEFAULT,
      },
    });
  }

  getByHierarchyIdAndSensorType(hierarchyId: number, sensorType: SensorType, ignoreHierarchyChildren = false) {
    return this.getByHierarchyIdAndSensorTypes(hierarchyId, [sensorType], ignoreHierarchyChildren);
  }

  getByHierarchyIdAndSensorTypes(hierarchyId: number, sensorTypes: SensorType[], ignoreHierarchyChildren = false) {
    let hierarchyIds = [hierarchyId];
    if (!ignoreHierarchyChildren) {
      hierarchyIds = this.rootStore.hierarchyStore.getChildPathIds(hierarchyId);
    }
    return this.sensors
      .filter((s) => s.hierarchyId && hierarchyIds.includes(s.hierarchyId) && sensorTypes.includes(s.sensorType))
      .sort((a, b) => sortAlphabetically(a.label, b.label));
  }

  getByHierarchyId(hierarchyId: number, ignoreHierarchyChildren = false) {
    let hierarchyIds = [hierarchyId];
    if (!ignoreHierarchyChildren) {
      hierarchyIds = this.rootStore.hierarchyStore.getChildPathIds(hierarchyId);
    }
    return this.sensors
      .filter((s) => s.hierarchyId && hierarchyIds.includes(s.hierarchyId))
      .sort((a, b) => sortAlphabetically(a.label, b.label));
  }

  getEnabledByHierarchyId(hierarchyId: number, ignoreHierarchyChildren = false) {
    let hierarchyIds = [hierarchyId];
    if (!ignoreHierarchyChildren) {
      hierarchyIds = this.rootStore.hierarchyStore.getChildPathIds(hierarchyId);
    }
    return this.sensors
      .filter((s) => s.hierarchyId && hierarchyIds.includes(s.hierarchyId) && s.enabled)
      .sort((a, b) => sortAlphabetically(a.label, b.label));
  }

  getLocalAsync(hierarchyId: number | undefined | null, ignoreHierarchyChildren = false) {
    if (!hierarchyId) {
      return [];
    }
    let hierarchyIds = [hierarchyId];
    if (!ignoreHierarchyChildren) {
      hierarchyIds = this.rootStore.hierarchyStore.getChildPathIds(hierarchyId);
    }
    return this.sensors
      .filter((s) => s.hierarchyId && hierarchyIds.includes(s.hierarchyId) && s.enabled && s.publishAsync)
      .sort((a, b) => sortAlphabetically(a.label, b.label));
  }

  get manualSensors() {
    return this.sensors.filter((s) => s.isManual);
  }
}
