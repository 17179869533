import { computed, observable, makeObservable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { Phase } from '../models/phase';
import { sortAlphabetically } from '../components/shared/tables/sorters';
import { RootStore } from './rootStore';

export class PhaseStore extends EntityStore<Phase> {
  phases: Phase[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'phases', Api.phases, Phase);

    makeObservable(this, {
      phases: observable,
      orderedAlphabeticallyByName: computed,
    });
  }

  get orderedAlphabeticallyByName() {
    return this.phases.slice().sort((a, b) => sortAlphabetically(a.name, b.name));
  }
}
