import {
  faExclamationTriangle, faFlag, faIndustry, faLayerGroup, faLock, faServer
} from '@fortawesome/free-solid-svg-icons';
import {uniqBy} from 'lodash';
import {InspectionTaskPriority} from '../../models/inspectionTask';

export const getIcons = (
  pendingTask,
  showInspectionTaskGroupIcon,
  roleIds = [],
  showGroupIcons = false,
  store = undefined
) => {
  const icons = [];
  let pendingTasks = [pendingTask];
  if (showGroupIcons && pendingTask.inspectionTask?.inspectionTaskGroup?.id) {
    pendingTasks = store.inspectionTaskPendingStore
      .getByInspectionTaskGroupId(pendingTask.inspectionTask.inspectionTaskGroup.id);
  }

  pendingTasks.forEach((pending) => {
    if (pending.inspectionTask.priority === InspectionTaskPriority.CRITICAL) {
      icons.push({icon: faExclamationTriangle, style: {color: '#FFA500'}});
    }
    if (pending.inspectionLot?.orderId) {
      icons.push({icon: faServer});
    } else {
      icons.push({icon: faIndustry});
    }

    if (pending.inspectionTask?.checkAtEnd) {
      icons.push({icon: faFlag});
    }

    if (showInspectionTaskGroupIcon && pendingTask.inspectionTask.inspectionTaskGroup) {
      icons.push({icon: faLayerGroup});
    }

    const locked = pending.inspectionTask?.requiredRoleId
      && !roleIds.includes(pending.inspectionTask.requiredRoleId);
    if (locked) {
      icons.push({icon: faLock});
    }
  });

  return uniqBy(icons, (icon) => icon.icon.iconName);
};
