import { computed, observable, makeObservable } from 'mobx';
import { BaseModel } from './base';
import { getTranslation } from '../utils/translations';
import { TransitionTranslation } from './transition';
import { RootStore } from '../stores/rootStore';

export class ValidStartTransition extends BaseModel {
  id: number = 0;
  name = null;
  customActionIds = null;
  translations: TransitionTranslation[] = [];
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      label: computed,
      description: computed,
      customActionIds: observable,
      translations: observable,
      ...ValidStartTransition.modelCreateDecoration,
      ...ValidStartTransition.modelUpdateDecoration,
    });
  }

  get description() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.description;
  }

  get label() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.label;
  }
}
