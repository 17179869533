import {observer} from 'mobx-react-lite';
import Modal from '../shared/modal/Modal';

const DocumentModal = (props) => (
  <Modal
    destroyOnClose
    maskClosable
    footer={null}
    {...props}
  />
);

export default observer(DocumentModal);
