import {Button} from 'antd';
import i18n from '../../../i18n/i18n';

const storageLocationColumn = {
  title: i18n.t('ingredientStock.attribute.storageLocation.label'),
  dataIndex: 'storageLocation',
  key: 'storageLocation',
};

const storagePlaceColumn = {
  title: i18n.t('ingredientStock.attribute.storagePlace.label'),
  dataIndex: 'storagePlace',
  key: 'storagePlace',
};

const typeColumn = {
  title: i18n.t('ingredientStock.attribute.type.label'),
  dataIndex: 'type',
  key: 'type',
};

const batchNoColumn = {
  title: i18n.t('ingredientStock.attribute.batchNo.label'),
  dataIndex: ['batch', 'no'],
  key: 'batchNo',
};

const amountColumn = {
  title: i18n.t('ingredientStock.attribute.amount.label'),
  dataIndex: 'amount',
  key: 'amount',
};

const unitOfMeasureColumn = {
  title: i18n.t('ingredientStock.attribute.unitOfMeasure.label'),
  dataIndex: 'unitOfMeasure',
  key: 'unitOfMeasure',
};

const showDetailsButtonColumn = (onClick) => ({
  key: 'actions',
  dataIndex: 'actions',
  render: (text, record) => ({
    props: {},
    children: (
      <Button type={'link'} onClick={() => onClick(record)}>
        {i18n.t('links.show')}
      </Button>
    ),
  }),
});

export const aggregatedStorageLocationsTableColumns = (onClick) => [
  storageLocationColumn,
  batchNoColumn,
  amountColumn,
  unitOfMeasureColumn,
  showDetailsButtonColumn(onClick),
];

export const storageLocationDetailsColumns = [
  storageLocationColumn,
  typeColumn,
  storagePlaceColumn,
  batchNoColumn,
  amountColumn,
  unitOfMeasureColumn,
];
