import {createEntity, executeDelete, executeGet, executePost, updateEntity} from '../requests';

const resourcesName = 'v1/workplaces';

export const searchEndpoint = 'v1/search-workplaces';
export const plannedOperationsEndpoint = 'plannedOperations';
export const plannedOperationsResource = 'planned-operations';
export const finishedOperationsEndpoint = 'finishedOperations';
export const finishedOperationsResource = 'finished-operations';
export const activeOperationEndpoint = 'activeOperation';
export const activeOperationResource = 'active-operation';
export const loadRelevantMessagesEndpoint = 'loadRelevantMessages';
export const loadRelevantMessagesResource = 'relevant-messages';
export const loadRelevantDocumentsEndpoint = 'loadRelevantDocuments';
export const loadRelevantDocumentsResource = 'relevant-documents';
export const validTransitionsEndpoint = 'valid-transitions';
export const siblingWorkplacesEndpoint = 'siblingWorkplaces';
export const siblingWorkplacesResource = 'sibling-workplaces';
export const loadPendingInspectionTasks = 'pending-inspection-tasks';
export const loadLatestLogEndpoint = 'loadLatestLog';
export const loadLatestLog = 'latest-logs';
export const loadInspectionLotsEndpoint = 'loadInspectionLots';
export const inspectionLotsResource = 'inspection-lots';
export const validPhaseTimeoutEndpoint = 'validPhaseTimeout';
export const validPhaseTimeoutResource = 'valid-phase-timeout';

export const WorkplacesApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, resourcesName),
  update: ({id, ...entity}) => updateEntity(entity, `${resourcesName}/${id}`),
  updatePersonnel: ({id, ...entity}) => executePost(`${resourcesName}/${id}/personnel`, entity),
  performQuickstop: ({id, ...body}) => executePost(`${resourcesName}/${id}/perform-quickstop`, body),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
  search: (params) => executeGet(`${searchEndpoint}`, {params}),
  [loadRelevantMessagesEndpoint]: ({id, ...params}) => executeGet(
    `${resourcesName}/${id}/${loadRelevantMessagesResource}`,
    {params}
  ),
  [activeOperationEndpoint]: (id) => executeGet(`${resourcesName}/${id}/${activeOperationResource}`),
  [plannedOperationsEndpoint]: ({id, ...params}) => executeGet(
    `${resourcesName}/${id}/${plannedOperationsResource}`,
    params
  ),
  [finishedOperationsEndpoint]: ({id, ...params}) => executeGet(
    `${resourcesName}/${id}/${finishedOperationsResource}`,
    params
  ),
  [loadRelevantDocumentsEndpoint]: (id) => executeGet(
    `${resourcesName}/${id}/${loadRelevantDocumentsResource}`
  ),
  [validTransitionsEndpoint]: ({id, ...params}) => executeGet(
    `${resourcesName}/${id}/${validTransitionsEndpoint}`,
    {params}
  ),
  [loadLatestLogEndpoint]: ({workplaceId, ...params}) => executeGet(
    `${resourcesName}/${workplaceId}/${loadLatestLog}`,
    {params}
  ),
  [siblingWorkplacesEndpoint]: (id) => executeGet(`${resourcesName}/${id}/${siblingWorkplacesResource}`),
  [loadInspectionLotsEndpoint]: ({id, ...params}) => executeGet(
    `${resourcesName}/${id}/${inspectionLotsResource}`,
    {params}
  ),
  [validPhaseTimeoutEndpoint]: (id, params) => executeGet(
    `${resourcesName}/${id}/${validPhaseTimeoutResource}`,
    {params}
  ),
};
