import dayjs from 'dayjs';
import {get, isUndefined} from 'lodash';
import {detailedDurationFormat, durationFormat} from '../config/dayjs';
import {formatDuration} from './formatting';
import {round as numberRound} from './number';
import {EnDash} from '../components/shared/unicodeWrapper/EnDash';
import ColorValue from '../components/shared/dataDisplay/ColorValue';
import BooleanValue from '../components/shared/dataDisplay/BooleanValue';
import CustomPropertyValue from '../components/shared/dataDisplay/CustomPropertyValue';

const id = (x) => x;

/**
 * @param x duration in seconds
 * @param format the format to use for the duration
 * @param unit the unit of the duration
 * @returns {*}
 */
const duration = ({format, unit = 'seconds'} = {}) =>
  (x) => formatDuration(dayjs.duration(x, unit), format || durationFormat);

/**
 * @param x duration in seconds
 * @param format the format to use for the duration
 * @param unit the unit of the duration
 * @returns {*}
 */
const durationOrDash = ({format, unit = 'seconds'} = {}) => (x) => {
  if (x || x === 0) {
    return formatDuration(dayjs.duration(x, unit), format || durationFormat);
  }
  return EnDash();
};

/**
 * Formats a duration in a detailed format
 * @param {plugin.Duration} x
 * @returns {string}
 */
const detailedDuration = (x) => formatDuration(x, detailedDurationFormat);

const datetime = ({format}) => (x) => (dayjs(x).isValid() ? dayjs(x).format(format) : EnDash());

const objectProperty = (name) => (x) => get(x, name, EnDash());

const milliseconds = (x) => (x ? dayjs(x).valueOf() : dayjs().valueOf());

const round = (decimalPlaces) => (x) => (
  x ? numberRound(x, isUndefined(decimalPlaces) ? 2 : decimalPlaces) : (x || 0)
);

const percent = (x) => `${x} %`;

const fallback = (defaultFallback) => (x, transformation) => {
  if (!defaultFallback) {
    defaultFallback = EnDash();
  }
  if (!x) {
    return defaultFallback;
  }
  if (transformation) {
    return transformation(x);
  }

  return x;
};
const color = (x) => <ColorValue value={x}/>;
const booleanValue = (x) => <BooleanValue value={x}/>;
const customPropertyValue = ({properties, availableProperty}) => (
  <CustomPropertyValue availableProperty={availableProperty} properties={properties}/>
);

const sum = (propertyName = 'amount') => (x) => x.reduce((acc, curr) => acc + curr[propertyName], 0);

const youngest = (format, propertyName = 'createdAt') => (x) => {
  if (x.length < 1) {
    return EnDash();
  }
  const y = x.reduce((acc, curr) => {
    if (!acc || dayjs(curr[propertyName]).isAfter(dayjs(acc))) {
      acc = dayjs(curr[propertyName]);
    }
    return acc;
  }, null);
  return dayjs(y).isValid() ? dayjs(y).format(format) : EnDash();
};

const timeDifference = (startProperty, endProperty, format) => (x) => {
  if (x.length < 1) {
    return EnDash();
  }
  const end = x.reduce((acc, curr) => {
    if (!acc || dayjs(curr[endProperty]).isAfter(dayjs(acc))) {
      acc = dayjs(curr[endProperty]);
    }
    return acc;
  }, null);
  const start = x.reduce((acc, curr) => {
    if (!acc || dayjs(curr[startProperty]).isBefore(dayjs(acc))) {
      acc = dayjs(curr[startProperty]);
    }
    return acc;
  }, null);
  return formatDuration(dayjs.duration(end - start, 'milliseconds'), format || detailedDurationFormat);
};

export const transformation = {
  none: id,
  fallback,
  datetime,
  duration,
  durationOrDash,
  detailedDuration,
  objectProperty,
  milliseconds,
  round,
  color,
  percent,
  booleanValue,
  customPropertyValue,
  sum,
  youngest,
  timeDifference,
};

/**
 * Applies multiple transformation on one value
 *
 * @param {[]} transformations
 * @returns {function(*=): (*|string)}
 */
export const batchTransform = (transformations = []) => (x) => {
  if (transformations.length > 1) {
    return transformations.reduce((acc, curr) => curr(acc(x)));
  }
  if (transformations.length === 1) {
    return transformations[0](x);
  }

  return '';
};
