import { action, makeObservable, observable } from 'mobx';
import { AxiosResponse } from 'axios';
import { Dayjs } from 'dayjs';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { InspectionLot } from '../models/inspectionLot';
import { loadInspectionLotsEndpoint, WorkplacesApi } from '../middleware/endpoints/workplaces';
import i18n from '../i18n/i18n';
import { RootStore } from './rootStore';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';

import { SearchParams } from '../types/autocomplete';

export class InspectionLotStore extends EntityStore<InspectionLot> {
  inspectionLots: InspectionLot[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'inspectionLots', Api.inspectionLot, InspectionLot, true);

    makeObservable(this, {
      inspectionLots: observable,
      deactivate: action,
      reactivate: action,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.orderStore,
        modelId: 'orderId',
      },
      {
        store: this.rootStore.userStore,
        modelId: 'deactivatedBy',
      },
    ];
  }

  searchByNo({ params }: SearchParams): Promise<any[]> {
    return this.api.search(params).then((response: AxiosResponse) => response.data);
  }

  deactivate(id: number, reason: string) {
    return this.api.deactivate({ id, reason })
      .then(() => {
        this.load(id);
        if (this.rootStore.workplaceStore.selectedWorkplace?.id) {
          this.rootStore.inspectionTaskPendingStore.loadByWorkplace(this.rootStore.workplaceStore.selectedWorkplace.id);
        }
        this.addMessage(
          new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.deactivateSuccess')),
          { skipNotification: false }
        );
      }).catch((e: Error) => this.handleApiError(e, 'deactivate'));
  }

  reactivate(id: number) {
    return this.api.reactivate({ id })
      .then(() => {
        this.load(id);
        this.addMessage(
          new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.reactivateSuccess')),
          { skipNotification: false }
        );
      }).catch((e: Error) => this.handleApiError(e, 'reactivate'));
  }

  loadValidByWorkplace(workplaceId: number, validFrom: Dayjs, validTo: Dayjs) {
    return this.loadAllWithDependencies({
      api: WorkplacesApi,
      apiEndpoint: loadInspectionLotsEndpoint,
      action: 'load-by-workplace',
      params: {
        id: workplaceId,
        validFrom: validFrom.toISOString(),
        validTo: validTo.toISOString(),
      },
    });
  }

  reload(fields: { lotNo: string; isWorkplaceInspectionLot: boolean }) {
    return this.api.reload(fields);
  }
}
