import { observable, makeObservable } from 'mobx';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { ExternalResource, ExternalResourceType } from '../models/externalResource';
import { RootStore } from './rootStore';

export class ExternalResourceStore extends EntityStore<ExternalResource> {
  externalResources: ExternalResource[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'externalResources', Api.externalResources, ExternalResource);

    makeObservable(this, {
      externalResources: observable,
    });
  }

  filterByType(type: ExternalResourceType) {
    return this.externalResources.filter((r) => r.type === type);
  }
}
