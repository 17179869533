import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Col, Form, Radio, Row, Space} from 'antd';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBoxCheck, faDollyEmpty, faDumpster} from '@fortawesome/pro-solid-svg-icons';
import {PlusOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import {useStore} from '../../hooks/useStore';
import Input from '../shared/inputs/Input';
import Title from '../shared/typography/Title';
import {ValidationRules} from '../../utils/validationRules';
import ComponentsFormInput from './ComponentsFormInput';
import {useMount} from '../../hooks/useMount';
import RemarkFormItem from './RemarkFormItem';
import {datetimeFormat} from '../../config/dayjs';
import {DatePicker} from '../shared/DatePicker';

const MODE_PRODUCE = 'produce';
const MODE_WASTE = 'waste';
const MODE_CONSUME = 'consume';

const ProducedMaterialWidget = ({
  formSubmitting,
  widgetConfig,
  onOk,
  reset,
  lastYieldCreatedAt,
}) => {
  const store = useStore();
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [mode, setMode] = useState(MODE_PRODUCE);
  const [manualShowRemark, setManualShowRemark] = useState(false);
  const [showRecordedAt, setShowRecordedAt] = useState(false);
  const validationRules = [ValidationRules.required()];

  if (widgetConfig.getSettingValue('input', 'plausibleLow')) {
    validationRules.push(
      ValidationRules.greaterThanOrEqualTo(widgetConfig.getSettingValue('input', 'plausibleLow'))
    );
  } else {
    validationRules.push(ValidationRules.greaterThan(0));
  }

  if (widgetConfig.getSettingValue('input', 'plausibleHigh')) {
    validationRules.push(
      ValidationRules.lessThanOrEqualTo(widgetConfig.getSettingValue('input', 'plausibleHigh'))
    );
  }

  const modeOptions = [
    {
      label: (
        <Space>
          <FontAwesomeIcon icon={faBoxCheck}/>
          {widgetConfig.getSettingValue('settings', 'produce')}
        </Space>
      ),
      value: MODE_PRODUCE,
    },
    {
      label: (
        <Space>
          <FontAwesomeIcon icon={faDumpster}/>
          {widgetConfig.getSettingValue('settings', 'waste')}
        </Space>
      ),
      value: MODE_WASTE,
    },
    {
      label: (
        <Space>
          <FontAwesomeIcon icon={faDollyEmpty}/>
          {widgetConfig.getSettingValue('settings', 'consume')}
        </Space>
      ),
      value: MODE_CONSUME,
    },
  ];

  useMount(() => {
    form.resetFields();
    setManualShowRemark(false);
    setShowRecordedAt(false);
  }, [reset]);

  const disabledDate = (current) => {
    if (lastYieldCreatedAt && current < dayjs(lastYieldCreatedAt)) {
      return true;
    }
    return current > dayjs();
  };

  return (
    <Form.Provider
      onFormFinish={(name, {values}) => onOk && onOk(values)}
    >
      <Space direction={'vertical'} style={{width: '100%'}}>
        <Row gutter={16}>
          <Col span={24}>
            <Title level={5}>{t('producesMaterialWidget.form.label.productionMode')}</Title>
          </Col>
          <Col span={24}>
            <Radio.Group
              onChange={(e) => setMode(e.target.value)}
              value={mode}
              optionType={'button'}
              options={modeOptions}
            />
          </Col>
        </Row>
        <Form form={form} layout="vertical" name="userForm">
          {mode !== MODE_CONSUME && (
            <>
              <Row gutter={16}>
                <Col span={24}>
                  <Title level={5}>{t('producesMaterialWidget.form.label.producedMaterial')}</Title>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <div style={{border: 'none'}} className={'roboto-mono ant-input'}>
                    <div className={'font-weight-bold'}>{store.operationStore.active?.material?.no}</div>
                    <div className={'small'}>
                      {
                        store.operationStore.active?.material
                          ? store.operationStore.active.material.name
                          : ''
                      }
                    </div>
                  </div>
                </Col>
                {mode === MODE_PRODUCE && (
                  <Col span={12}>
                    <Form.Item
                      name="producedAmount"
                      label={`${t('producesMaterialWidget.form.label.amount')}: `
                        + `${widgetConfig.getSettingValue('settings', 'produce')}`}
                      rules={validationRules}
                    >
                      <Input
                        type={'number'}
                        addonAfter={store.operationStore.active?.unitOfMeasure?.label}
                      />
                    </Form.Item>
                  </Col>
                )}
                {mode === MODE_WASTE && (
                  <Col span={12}>
                    <Form.Item
                      name="wasteAmount"
                      label={`${t('producesMaterialWidget.form.label.amount')}: `
                        + `${widgetConfig.getSettingValue('settings', 'waste')}`}
                      rules={validationRules}
                    >
                      <Input
                        type={'number'}
                        addonAfter={store.operationStore.active?.unitOfMeasure?.label}
                      />
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </>
          )}
          <Row gutter={16}>
            <Col span={24}>
              <Title level={5}>{t('producesMaterialWidget.form.label.usedComponents')}</Title>
            </Col>
          </Row>
          <Form.Item name="usedComponents">
            <ComponentsFormInput/>
          </Form.Item>
          {mode !== MODE_CONSUME && (
            <RemarkFormItem
              form={form}
              name={'remark'}
              manualShowRemark={manualShowRemark}
              setManualShowRemark={setManualShowRemark}
            />
          )}
          {showRecordedAt ? (
            <Form.Item name={'recordedAt'}>
              <DatePicker
                showToday
                showTime
                format={datetimeFormat}
                disabledDate={disabledDate}
              />
            </Form.Item>
          ) : widgetConfig.getWidgetSetting('showDateAdjuster') && (
            <Row gutter={16} style={{margin: '1rem 0'}}>
              <Col>
                <Button
                  htmlType={'button'}
                  icon={<PlusOutlined/>}
                  onClick={() => {
                    const value = dayjs();
                    setShowRecordedAt(true);
                    form.setFieldValue('recordedAt', value);
                  }}
                >
                  {t('inspectionTaskPendingModal.taskBox.setTime')}
                </Button>
              </Col>
            </Row>
          )}
          <Row gutter={16} style={{margin: '1rem 0'}}>
            <Col>
              <Button type={'primary'} htmlType={'submit'} loading={formSubmitting}>
                {`${t('producesMaterialWidget.form.submitButton.book')} `
                  + `(${widgetConfig.getSettingValue('settings', mode)})`}
              </Button>
            </Col>
          </Row>
        </Form>
      </Space>
    </Form.Provider>
  );
};

export default observer(ProducedMaterialWidget);
