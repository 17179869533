import React from 'react';
import { MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Button, { ButtonProps } from './Button';

export type ReadButtonProps = ButtonProps & {
  message?: string;
};

const ReadButton: React.FC<ReadButtonProps> = ({ message, ...props }) => {
  const { t } = useTranslation();

  const buttonProps = {
    icon: <MailOutlined/>,
    children: t('links.read'),
    type: 'link',
    ...props,
  } as ButtonProps;

  /* eslint-disable react/destructuring-assignment */
  if (message) {
    return <Button {...buttonProps}>{message}</Button>;
  }
  return <Button {...buttonProps}/>;
};

export default ReadButton;
