import { makeObservable, observable } from 'mobx';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';
import { Language } from './translations';
import { Workplace } from './workplace';
import { DataExportTemplate, RangeType } from './dataExportTemplate';
import { ExternalResource } from './externalResource';

/*
 * Translations:
 * t('dataExport.model.type.KPI.one')
 * t('dataExport.model.type.SENSOR_DATA.one')
 * t('dataExport.model.type.INTERRUPTIONS.one')
 * t('dataExport.model.type.KPI.other')
 * t('dataExport.model.type.SENSOR_DATA.other')
 * t('dataExport.model.type.INTERRUPTIONS.other')
 * t('dataExportScheduler.model.type.KPI')
 * t('dataExportScheduler.model.type.INTERRUPTIONS')
 * t('dataExportScheduler.model.type.SENSOR_DATA')
 */
export enum DataExportType {
  KPI = 'KPI',
  SENSOR_DATA = 'SENSOR_DATA',
  INTERRUPTIONS = 'INTERRUPTIONS'
}

/*
 * Translations:
 * t('dataExportScheduler.model.format.CSV')
 * t('dataExportScheduler.model.format.JSON')
 */
export enum DataExportSchedulerFormat {
  CSV = 'CSV',
  JSON = 'JSON'
}

/*
 * Translations:
 * t('dataExportScheduler.model.unit.months')
 * t('dataExportScheduler.model.unit.weeks')
 * t('dataExportScheduler.model.unit.days')
 * t('dataExportScheduler.model.unit.hours')
 * t('dataExportScheduler.model.unit.minutes')
 */
export enum DataExportSchedulerUnit {
  MONTHS = 'months',
  WEEKS = 'weeks',
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes'
}

export class DataExportScheduler extends BaseModel {
  id?: number = 0;
  name: string = '';
  description: string = '';
  type: DataExportType = DataExportType.KPI;
  format: DataExportSchedulerFormat = DataExportSchedulerFormat.CSV;
  language: Language = Language.EN_CH;
  cronPattern: string = '';
  offset: number = 0;
  offsetUnit: DataExportSchedulerUnit = DataExportSchedulerUnit.DAYS;
  duration: number = 0;
  enabled: boolean = false;
  workplaceIds: number[] = [];
  workplaces: Workplace[] = [];
  dataExportTemplateId: number | null = null;
  dataExportTemplate?: DataExportTemplate | null = undefined;
  rangeType: typeof RangeType | null = null;
  externalResourceId: number = 0;
  externalResource?: ExternalResource = undefined;
  lastExecution: Date | null = null;
  lastExecutionSuccess: boolean = false;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      description: observable,
      type: observable,
      format: observable,
      language: observable,
      cronPattern: observable,
      offset: observable,
      offsetUnit: observable,
      duration: observable,
      enabled: observable,
      workplaceIds: observable,
      workplaces: observable,
      dataExportTemplateId: observable,
      dataExportTemplate: observable,
      rangeType: observable,
      externalResourceId: observable,
      externalResource: observable,
      lastExecution: observable,
      lastExecutionSuccess: observable,
    });
  }

  static faIcon = faCalendarCheck;

  searchableProperties = ['name', 'description'];

  populateAttributesFromStore(rootStore: RootStore) {
    super.populateAttributesFromStore(rootStore);
    this.dataExportTemplate = rootStore.dataExportTemplateStore
      .getById(this.dataExportTemplateId) as DataExportTemplate;
    this.externalResource = rootStore.externalResourceStore
      .getById(this.externalResourceId) as ExternalResource;
    this.workplaces = rootStore.workplaceStore.getByIds(this.workplaceIds) as Workplace[];
  }

  static prepareApiPayload(model: DataExportScheduler) {
    return {
      id: model?.id,
      name: model.name,
      description: model.description || null,
      type: model.type,
      format: model.format,
      cronPattern: model.cronPattern,
      offset: model.offset,
      offsetUnit: model.offsetUnit,
      duration: model.duration,
      enabled: model.enabled || false,
      workplaceIds: model.workplaceIds,
      dataExportTemplateId: model.dataExportTemplateId || null,
      rangeType: model.rangeType || null,
      externalResourceId: model.externalResourceId,
      language: model.language,
    };
  }
}
