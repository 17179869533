import {executeDelete, executeGet, executePost, updateEntity} from '../requests';

const resourcesName = 'v1/print-job-logs';
export const reprintPrintJobLogEndpoint = 'reprint';

export const PrintJobLogsApi = {
  all: ({...params}) => executeGet(resourcesName, {params}),
  byId: (id) => executeGet(`${resourcesName}/${id}`),
  update: ({id, ...values}) => updateEntity(values, `${resourcesName}/${id}`),
  destroy: (id) => executeDelete(`${resourcesName}/${id}`),
  reprint: (id, payload) => executePost(`${resourcesName}/${id}/${reprintPrintJobLogEndpoint}`, payload),
};
