import { action, makeObservable, observable } from 'mobx';
import { AxiosResponse } from 'axios';
import { Actions, EntityStore, LoadOptions } from './entityStore';
import { Api } from '../middleware/api';
import { changePasswordEndpoint, getEndpoint } from '../middleware/endpoints/profile';
import { GUEST_USER_ID, User } from '../models/user';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';
import { RootStore } from './rootStore';
import { Profile } from '../models/profile';
import i18n from '../i18n/i18n';

export interface ChangePasswordParams {
  oldPassword: string;
  newPassword: string;
}

export class ProfileStore extends EntityStore<Profile> {
  currentProfile: Profile | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore, 'profiles', Api.profiles, Profile);

    makeObservable(this, {
      currentProfile: observable,
      setCurrentProfile: action,
      changePassword: action,
      updateProfile: action,
      loadProfile: action,
    });
  }

  loadProfile(id: number) {
    if (id === GUEST_USER_ID) {
      this.currentProfile = null;
      return Promise.resolve(this.currentProfile);
    }

    return this.api[getEndpoint]().then(({ data, ...rest }: AxiosResponse) => {
      if (rest.status === 204) {
        return undefined;
      }
      this.setCurrentProfile(this.createModelInstance(data));
      return this.currentProfile;
    }).catch((e: Error) => this.handleApiError(e));
  }

  updateProfile(entity: Partial<Profile>, options: LoadOptions = {}) {
    const pendingAction = Actions.update;
    return this.api[pendingAction](this.prepareApiPayload(entity), options.params)
      .then(({ data, ...rest }: AxiosResponse) => {
        if (rest.status === 204) {
          return undefined;
        }
        this.addMessage(
          new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.updateSuccess')),
          options
        );
        this.setCurrentProfile(this.createModelInstance(data));
        return this.currentProfile;
      }).catch((e: Error) => this.handleApiError(e, pendingAction));
  }

  changePassword(params: ChangePasswordParams, options: LoadOptions = {}) {
    return this.api[changePasswordEndpoint](params).then(({ data, ...rest }: AxiosResponse) => {
      if (rest.status === 204) {
        return undefined;
      }
      this.addMessage(
        new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.updateSuccess')),
        options
      );
      this.currentProfile = this.createModelInstance(data);
      return this.currentProfile;
    }).catch((e: Error) => this.handleApiError(e));
  }

  setCurrentProfile(profile: User | null) {
    this.currentProfile = profile;
  }
}
