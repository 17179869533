import React from 'react';
import { observer } from 'mobx-react-lite';

export type TextParagraphProps = {
  text: React.ReactNode;
  fontWeight: string | number;
  lineHeight: string | number;
};

const TextParagraph: React.FC<TextParagraphProps> = ({ text, fontWeight, lineHeight }) => (
  <p style={{
    fontFamily: 'Roboto Mono',
    fontWeight,
    lineHeight,
    color: '#404040',
    marginBottom: 0,
  }}
  >
    {text}
  </p>
);

export default observer(TextParagraph);
