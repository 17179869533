/**
 * Round to defined decimal places (only if necessary).
 * Round half away from zero (is the default behaviour of rounding).
 *
 * @param {number} number
 * @param {number} decimalPlaces
 * @returns {number}
 * @link https://stackoverflow.com/a/11832950/5394518
 * @link https://en.wikipedia.org/wiki/Rounding#Round_half_away_from_zero
 */
export const round = (number, decimalPlaces = 2) => {
  const precision = 10 ** decimalPlaces;
  const x = (number * precision) * (1 + Number.EPSILON);

  return Math.round(x) / precision;
};

export const localeNumber = (locale, floatStr = '') => {
  if (typeof floatStr !== 'string') {
    floatStr = String(floatStr);
  }
  const localSeparator = new Intl.NumberFormat(locale, {useGrouping: false})
    .formatToParts(12.34).find((part) => part.type === 'decimal')?.value;
  return floatStr.replace(localSeparator, '.');
};

export const countDecimals = (value) => {
  if (Math.floor(value) !== value) {
    const parts = value.toString().split('.');
    if (parts.length > 1) {
      return parts[1].length || 0;
    }
    return 0;
  }
  return 0;
};
