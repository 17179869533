import {useState} from 'react';
import {Form} from 'antd';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {useStore} from '../../../hooks/useStore';
import ScanInput from '../../shared/inputs/ScanInput';
import TouchTag from '../../shared/tags/TouchTag';
import i18n from '../../../i18n/i18n';

const ScanWorkplace = ({
  workplaces,
  onChangeWorkplaces,
  maxWorkplaces = 5,
  ...props
}) => {
  const [form] = Form.useForm();
  const store = useStore();
  const {t} = useTranslation();
  const [isValid, setIsValid] = useState(false);

  const handleValuesChange = (changedValues) => {
    if (changedValues?.workplace !== undefined) {
      setIsValid(changedValues?.workplace && store.workplaceStore.getByNo(changedValues.workplace));
    }
  };
  const addWorkplace = (workplaceNo) => {
    const workplaceToAdd = store.workplaceStore.getByNo(workplaceNo);
    setIsValid(false);
    if (workplaceToAdd) {
      if (!workplaces.find((workplace) => workplace.no === workplaceNo)) {
        onChangeWorkplaces([...workplaces, workplaceToAdd]);
      }
      form.setFieldsValue({workplace: null});
    } else {
      store.flashMessageStore.addFlashMessage({
        type: 'warning',
        title: i18n.t('terminalManagement.modal.form.invalidWorkplace'),
      });
    }
  };
  const WorkplaceTags = () => {
    if (workplaces && workplaces.length) {
      return workplaces.map((workplace, index) => (
        <TouchTag
          style={{width: '100%'}}
          key={workplace.no}
          size={'large'}
          closable
          onClose={() => {
            const newWorkplaces = [...workplaces];
            newWorkplaces.splice(index, 1);
            onChangeWorkplaces(newWorkplaces);
          }}
        >
          {`${workplace.no} (${workplace.name})`}
        </TouchTag>
      ));
    }
    return <i style={{opacity: 0.6}}>{t('terminalManagement.modal.form.noWorkplace')}</i>;
  };

  return (
    <>
      <Form form={form} onValuesChange={handleValuesChange}>
        <Form.Item
          name={'workplace'}
          label={t('terminalManagement.modal.form.workplace')}
        >
          <ScanInput
            disabled={workplaces.length >= maxWorkplaces}
            isValid={isValid}
            onAccept={addWorkplace}
            {...props}
          />
        </Form.Item>
      </Form>
      <WorkplaceTags/>
    </>
  );
};

export default observer(ScanWorkplace);
