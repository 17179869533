import { observable, makeObservable, computed } from 'mobx';
import { faConveyorBeltBoxes } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';
import { StorageUnit } from './storageUnit';
import { StorageArea } from './storageArea';

export class WorkplaceStorageRelation extends BaseModel {
  id: number = 0;
  workplaceId: number = 0;
  sourceStorageUnitIds: number[] = [];
  internalStorageUnitIds: number[] = [];
  destinationStorageUnitIds: number[] = [];
  sourceStorageAreaIds: number[] = [];
  internalStorageAreaIds: number[] = [];
  destinationStorageAreaIds: number[] = [];
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      workplaceId: observable,
      workplace: computed,
      sourceStorageUnitIds: observable,
      sourceStorageUnits: computed,
      internalStorageUnitIds: observable,
      internalStorageUnits: computed,
      destinationStorageUnitIds: observable,
      destinationStorageUnits: computed,
      sourceStorageAreaIds: observable,
      sourceStorageAreas: computed,
      internalStorageAreaIds: observable,
      internalStorageAreas: computed,
      destinationStorageAreaIds: observable,
      destinationStorageAreas: computed,
      renderedFiveStorageUnits: computed,
      renderedFiveStorageAreas: computed,
      createdAt: observable,
      createdBy: observable,
      updatedAt: observable,
      updatedBy: observable,
    });
  }

  static faIcon = faConveyorBeltBoxes;

  searchableProperties = [
    'workplace.label',
    'sourceStorageUnits',
    'internalStorageUnits',
    'destinationStorageUnits',
    'sourceStorageAreas',
    'internalStorageAreas',
    'destinationStorageAreas',
    'renderedFiveStorageUnits',
    'renderedFiveStorageAreas',
  ];

  get workplace() {
    return this.rootStore.workplaceStore.getById(this.workplaceId);
  }

  get sourceStorageUnits() {
    return this.rootStore.storageUnitStore.getByIds(this.sourceStorageUnitIds);
  }

  get internalStorageUnits() {
    return this.rootStore.storageUnitStore.getByIds(this.internalStorageUnitIds);
  }

  get destinationStorageUnits() {
    return this.rootStore.storageUnitStore.getByIds(this.destinationStorageUnitIds);
  }

  get sourceStorageAreas() {
    return this.rootStore.storageAreaStore.getByIds(this.sourceStorageAreaIds);
  }

  get internalStorageAreas() {
    return this.rootStore.storageAreaStore.getByIds(this.internalStorageAreaIds);
  }

  get destinationStorageAreas() {
    return this.rootStore.storageAreaStore.getByIds(this.destinationStorageAreaIds);
  }

  get renderedFiveStorageUnits() {
    const { sourceStorageUnits, internalStorageUnits, destinationStorageUnits } = this;
    const uniqueStorageUnits :Set<number> = new Set();
    const addToUniqueStorageUnits = (storageUnits :StorageUnit[]) => {
      storageUnits?.forEach((storageUnit) => {
        uniqueStorageUnits.add(storageUnit.id);
      });
    };
    addToUniqueStorageUnits(sourceStorageUnits);
    addToUniqueStorageUnits(internalStorageUnits);
    addToUniqueStorageUnits(destinationStorageUnits);
    const limitedStorageUnits = Array.from(uniqueStorageUnits).slice(0, 5);
    const renderStorageUnits = limitedStorageUnits.map((storageUnitId) => {
      const storageUnit = this.rootStore.storageUnitStore.getById(storageUnitId);
      return <div key={storageUnitId}>{storageUnit?.no}</div>;
    });
    if (uniqueStorageUnits.size > 5) {
      renderStorageUnits.push(<div key="ellipsis">...</div>);
    }
    return renderStorageUnits;
  }

  get renderedFiveStorageAreas() {
    const { sourceStorageAreas, internalStorageAreas, destinationStorageAreas } = this;
    const uniqueStorageAreas :Set<number> = new Set();
    const addToUniqueStorageAreas = (storageAreas :StorageArea[]) => {
      storageAreas?.forEach((storageArea) => {
        uniqueStorageAreas.add(storageArea.id);
      });
    };
    addToUniqueStorageAreas(sourceStorageAreas);
    addToUniqueStorageAreas(internalStorageAreas);
    addToUniqueStorageAreas(destinationStorageAreas);
    const limitedStorageAreas = Array.from(uniqueStorageAreas).slice(0, 5);
    const renderStorageAreas = limitedStorageAreas.map((storageAreaId) => {
      const storageArea = this.rootStore.storageAreaStore.getById(storageAreaId);
      return <div key={storageAreaId}>{storageArea?.no}</div>;
    });
    if (uniqueStorageAreas.size > 5) {
      renderStorageAreas.push(<div key="ellipsis">...</div>);
    }
    return renderStorageAreas;
  }
}
