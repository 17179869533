import { createEntity, executeDelete, executeGet, updateEntity } from '../requests';
import { UserNotificationConfig } from '../../models/userNotificationConfig';

const resourcesName = 'v1/user-notification-configs';

export const UserNotificationConfigApi = {
  all: ({ ...params }) => executeGet(resourcesName, { params }),
  create: (entity: Partial<UserNotificationConfig>) => createEntity(entity, resourcesName),
  update: ({ id, ...entity }: any) => updateEntity(entity, `${resourcesName}/${id}`),
  destroy: (id: number) => executeDelete(`${resourcesName}/${id}`),
};
