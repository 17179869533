import { makeObservable, observable } from 'mobx';
import { User } from '../models/user';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { updatePasswordEndpoint, UsersApi } from '../middleware/endpoints/users';
import { RootStore } from './rootStore';

export const UserStoreActions = {
  changePassword: 'changePassword',
  updateProfile: 'update',
  getProfile: 'get',
};

export class UserStore extends EntityStore<User> {
  users: User[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'users', Api.users, User);

    makeObservable(this, {
      users: observable,
    });
  }

  updatePassword(entity: Partial<User>) {
    return this.update(
      entity,
      {
        api: UsersApi,
        apiEndpoint: updatePasswordEndpoint,
      }
    );
  }

  changePassword({ oldPassword, newPassword }: { oldPassword: string, newPassword: string }) {
    const options = {
      action: UserStoreActions.changePassword,
    };

    this.addPendingAction(options.action);

    return this.rootStore.profileStore.changePassword({ oldPassword, newPassword });
  }

  getByRoleId(roleId: number) {
    return this.users.filter((user) => user.hasRole(roleId));
  }
}
