import {observer} from 'mobx-react-lite';
import {Alert, Col, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import Modal from '../shared/modal/Modal';
import {useStore} from '../../hooks/useStore';
import Button from '../shared/buttons/Button';
import styles from './componentTable.module.scss';
import Table from '../shared/tables/Table';

const PreparationWarningModal = ({
  title,
  preparation,
  onClose,
  onSuccess,
  outOfToleranceComponents = [],
}) => {
  const store = useStore();
  const {t} = useTranslation();

  return (
    <Modal
      title={title}
      fullscreen={store.clientStore.isMobile}
      open
      footer={(
        <Row gutter={[16, 8]} justify={'end'}>
          <Col>
            <Button onClick={() => onClose()}>{t('confirm.no')}</Button>
          </Col>
          <Col>
            <Button type={'primary'} onClick={() => onSuccess()}>{t('confirm.yes')}</Button>
          </Col>
        </Row>
      )}
      maskClosable={false}
      onCancel={onClose}
    >
      <>
        <Alert
          message={t('batchHandling.preparation.outOfTolerance')}
          type="warning"
        />
        <br/>
        <Table
          className={styles.componentsTable}
          rowKey={'id'}
          loading={store.componentStore.isLoadingCollection || store.consumptionLogStore.isLoadingCollection}
          pagination={false}
          dataSource={outOfToleranceComponents}
          columns={[
            {
              title: t('batchHandling.preparation.components.materialNo'),
              dataIndex: 'material',
              render: (material) => material?.no,
            },
            {
              title: t('batchHandling.preparation.components.material'),
              dataIndex: 'material',
              render: (material) => material?.name,
            },
            {
              title: t('batchHandling.preparation.components.preparationQuantity'),
              render: (record) => (record.preparationQuantity
                ? record.preparationQuantity?.toFixed(3)
                : record.plannedQuantity?.toFixed(3) || Number(0).toFixed(3)),
            },
            {
              title: t('batchHandling.preparation.components.actualQuantity'),
              render: (record) => {
                const actualQuantity = store.consumptionLogStore.actualConsumed(preparation.id, record.id);
                return actualQuantity.toFixed(3);
              },
            },
            {
              title: t('batchHandling.preparation.components.unitOfMeasure'),
              dataIndex: 'unitOfMeasure',
              render: (unitOfMeasure) => unitOfMeasure?.label,
            },
          ]}
        />
      </>
    </Modal>
  );
};

export default observer(PreparationWarningModal);
