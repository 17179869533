import {Select} from 'antd';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';

const InterruptionClassSelect = ({
  disabled = false,
  onChange,
  value,
  availableInterruptionClasses = [],
  ...props
}) => {
  const {t} = useTranslation();

  const options = availableInterruptionClasses.map((interruptionClass) => ({
    label: interruptionClass.label,
    value: interruptionClass.id,
  }));

  return (
    <Select
      disabled={disabled}
      placeholder={t('interruption.modal.form.interruptionClass.placeholder')}
      options={options}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

export default observer(InterruptionClassSelect);
