import { observable, makeObservable } from 'mobx';
import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

export class LdapConnection extends BaseModel {
  enabled: boolean = false;
  protocol: string = '';
  host: string = '';
  port: number = 0;
  ou: string = '';
  cn: string = '';
  dc: string = '';
  password: string = '';

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      enabled: observable,
      protocol: observable,
      host: observable,
      port: observable,
      ou: observable,
      cn: observable,
      dc: observable,
      password: observable,
    });
  }

  static faIcon = faCog;

  static prepareApiPayload(model: Partial<LdapConnection>): Partial<LdapConnection> {
    return {
      ...model,
      enabled: Boolean(model.enabled),
      protocol: model.protocol || '',
      host: model.host || '',
      port: model.port || 0,
      ou: model.ou || '',
      cn: model.cn || '',
      dc: model.dc || '',
    };
  }
}
