import { BaseWidgetConfig } from '../../shared/widgets/baseWidgetConfig';
import i18n from '../../../i18n/i18n';
import { ValidationRules } from '../../../utils/validationRules';
import { Component } from '../../../models/component';
import { getAllI18nTranslations } from '../../../utils/translations';
import { FieldType, TabDefinition } from '../../../types/widgetConfig';
import { RootStore } from '../../../stores/rootStore';
import { WidgetActionExtension } from '../../shared/widgets/WidgetActionExtension';

export class DosageSetupWidgetConfig extends BaseWidgetConfig {
  constructor(rootStore: RootStore, identifier: string) {
    super(rootStore, identifier, undefined, [new WidgetActionExtension(rootStore)]);
    this.tabConfigs.set('settings', [
      {
        id: 'title',
        label: i18n.t<string>('widget.settings.title.label'),
        helpText: i18n.t<string>('widget.settings.title.help'),
        type: FieldType.Translatable,
        // t('dosageSetupWidget.config.settings.title.defaultValue')
        defaultValue: getAllI18nTranslations('dosageSetupWidget.config.settings.title.defaultValue'),
        validations: [ValidationRules.required()],
      },
      {
        id: 'isMultiselect',
        label: i18n.t<string>('dosageWidget.config.settings.isMultiselect.label'),
        helpText: i18n.t<string>('dosageWidget.config.settings.isMultiselect.help'),
        type: FieldType.Boolean,
        defaultValue: false,
      },
      {
        id: 'properties',
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: [
          ...Component.allDisplayableProperties(this.rootStore),
        ],
        defaultValue: ['material.name', 'unitOfMeasure'],
      },
    ]);
  }
  // eslint-disable-next-line class-methods-use-this
  get tabs(): TabDefinition[] {
    return [
      {
        key: 'settings',
        label: i18n.t('widget.WidgetSettingsModal.settingConfigTabTitle'),
      },
      ...super.tabs,
    ];
  }

  getWidgetSetting(identifier: string) {
    return this.getSettingValue('settings', identifier);
  }

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'title');
  }

  getWidgetProperties() {
    return this.getSelectedProperties('settings', 'properties');
  }

  getIsMultiselect() {
    return this.getSettingValue('settings', 'isMultiselect');
  }
}
