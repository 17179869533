import React from 'react';
import { Radio as AntdRadio, RadioProps as AntdRadioProps } from 'antd';

export type RadioProps = AntdRadioProps & {
  invertValue?: boolean;
  checked?: boolean;
};

const Radio: (React.FC<RadioProps>) & {
  Button: typeof AntdRadio.Button,
  Group: typeof AntdRadio.Group,
} = ({
  invertValue,
  checked,
  ...props
}) => <AntdRadio checked={invertValue ? !checked : checked} {...props}/>;

export default Radio;

Radio.Button = AntdRadio.Button;
Radio.Group = AntdRadio.Group;
