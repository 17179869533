import { computed, observable, makeObservable } from 'mobx';
import { faBagsShopping } from '@fortawesome/pro-solid-svg-icons';
import dayjs from 'dayjs';
import i18n from '../i18n/i18n';
import { CustomPropertyTypes } from './customPropertyDataTypes';
import { DisplayableProperty, displayableProperty } from './displayableProperty';
import { displayablePropertyParam } from './displayablePropertyParam';
import { transformation } from '../utils/transformations';
import { Batch } from './batch';
import { Material } from './material';
import { round } from '../utils/number';
import { QuantityConversionModel } from './quantityConversion';
import { getTranslation } from '../utils/translations';
import { dateFormat } from '../config/dayjs';
import { CustomPropertiesObject } from './customProperty';
import { Translation } from './translations';
import { RootStore } from '../stores/rootStore';
import { DisplayablePropertiesOptions } from './base';
import { Operation } from './operation';

/**
 * order states translations:
 * t('order.model.attributes.state.PLANNED')
 * t('order.model.attributes.state.READY')
 * t('order.model.attributes.state.RUNNING')
 * t('order.model.attributes.state.FINISHED')
 * t('order.model.attributes.state.LOCKED')
 * t('order.model.attributes.state.TERMINATED')
 * t('order.model.attributes.state.REPORTED')
 */
export const OrderStateNames = Object.freeze({
  0: 'PLANNED',
  1: 'READY',
  2: 'RUNNING',
  3: 'FINISHED',
  4: 'LOCKED',
  5: 'TERMINATED',
  6: 'REPORTED',
});

export enum OrderState {
  PLANNED,
  READY,
  RUNNING,
  FINISHED,
  LOCKED,
  TERMINATED,
  REPORTED
}

const MAX_DECIMAL_PLACES = 3;

// id: number;
// no: string;
// state: OrderState;
// workflowId: number;
// materialId: number;
// unitOfMeasureId: number | null;
// plannedQuantity?: number | null;
// actualQuantity?: number | null;
// plannedScrap?: number | null;
// actualScrap?: number | null;
// batchId?: number | null;
// type?: string | null;
// sortOrder?: number | null;
// operations?: Operation[];
// properties?: OrderProperty[];
// translations?: OrderTranslation[];
// parentOrderId?: number | null;
// isYieldReportingDisabled?: boolean;

interface OrderTranslation extends Translation {
  name: string | null;
  info: string | null;
}

export class Order extends QuantityConversionModel {
  id: number = 0;
  no: string = '';
  materialId: number = 0;
  unitOfMeasureId: number = 0;
  parentOrderId: number | null = null;
  properties?: CustomPropertiesObject = undefined;
  translations: OrderTranslation[] = [];
  state: OrderState = OrderState.PLANNED;
  workflowId: number = 0;
  plannedQuantity: number | null = null;
  actualQuantity: number | null = null;
  plannedScrap: number | null = null;
  actualScrap: number | null = null;
  batchId: number | null = null;
  type: string | null = null;
  isYieldReportingDisabled: boolean = false;
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;
  deletedAt: string | null = null;
  deletedBy: number | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      no: observable,
      name: computed,
      materialId: observable,
      material: computed,
      unitOfMeasureId: observable,
      unitOfMeasure: computed,
      parentOrderId: observable,
      parentOrder: computed,
      operations: computed,
      properties: observable,
      translations: observable,
      state: observable,
      workflowId: observable,
      plannedQuantity: observable,
      actualQuantity: observable,
      plannedScrap: observable,
      actualScrap: observable,
      batchId: observable,
      batch: computed,
      type: observable,
      info: computed,
      isYieldReportingDisabled: observable,
      unitOfMeasureConversions: computed,
      ...Order.modelChangeDecorations,
      stateText: computed,
      remainingQuantity: computed,
      plannedStartDate: computed,
    });
  }

  static faIcon = faBagsShopping;

  searchableProperties = ['no'];

  saveableProperties = [
    'no', 'materialId', 'state', 'batchId', 'unitOfMeasureId', 'sortOrder', 'plannedQuantity', 'actualQuantity', 'type',
    'workflowId', 'plannedScrap', 'actualScrap', 'parentOrderId', 'isYieldReportingDisabled',
    'unitOfMeasureConversions', 'operations', 'translations', 'properties',
  ];

  translatedProperties = ['name', 'info'];

  customPropertyType = CustomPropertyTypes.Order;

  i18nPrefix = 'order';

  displayableProperties = [
    displayableProperty({
      key: 'no',
      title: i18n.t('order.model.attributes.no'),
      params: [displayablePropertyParam({ path: 'no' })],
    }),
    displayableProperty({
      key: 'parentOrderNo',
      title: i18n.t('order.model.attributes.parentOrderNo'),
      params: [displayablePropertyParam({ path: 'parentOrder.no' })],
    }),
    displayableProperty({
      key: 'unitOfMeasure',
      title: i18n.t('order.model.attributes.unitOfMeasure'),
      params: [displayablePropertyParam({ path: 'unitOfMeasure.label' })],
    }),
    displayableProperty({
      key: 'state',
      title: i18n.t('order.model.attributes.stateLabel'),
      params: [displayablePropertyParam({ path: 'stateText' })],
    }),
    displayableProperty({
      key: 'quantityIsShould',
      title: i18n.t('order.model.attributes.quantityIsShould'),
      params: [
        displayablePropertyParam({
          path: 'actualQuantity',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'actualQuantity',
        }),
        displayablePropertyParam({
          path: 'plannedQuantity',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'plannedQuantity',
        }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{actualQuantity} / {plannedQuantity} {unitOfMeasure}',
    }),
    displayableProperty({
      key: 'scrapIsShould',
      title: i18n.t('order.model.attributes.scrapIsShould'),
      params: [
        displayablePropertyParam({
          path: 'actualScrap',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'actualScrap',
        }),
        displayablePropertyParam({
          path: 'plannedScrap',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'plannedScrap',
        }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{actualScrap} / {plannedScrap} {unitOfMeasure}',
    }),
    displayableProperty({
      key: 'type',
      title: i18n.t('order.model.attributes.type'),
      params: [displayablePropertyParam({ path: 'type' })],
    }),
    displayableProperty({
      key: 'info',
      title: i18n.t('order.model.attributes.info'),
      params: [displayablePropertyParam({ path: 'info' })],
    }),
    displayableProperty({
      key: 'plannedQuantity',
      title: i18n.t('order.model.attributes.plannedQuantity'),
      params: [
        displayablePropertyParam({
          path: 'plannedQuantity',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'plannedQuantity',
        }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{plannedQuantity} {unitOfMeasure}',
    }),
    displayableProperty({
      key: 'actualQuantity',
      title: i18n.t('order.model.attributes.actualQuantity'),
      params: [
        displayablePropertyParam({
          path: 'actualQuantity',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'actualQuantity',
        }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{actualQuantity} {unitOfMeasure}',
    }),
    displayableProperty({
      key: 'plannedScrap',
      title: i18n.t('order.model.attributes.plannedScrap'),
      params: [
        displayablePropertyParam({
          path: 'plannedScrap',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'plannedScrap',
        }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{plannedScrap} {unitOfMeasure}',
    }),
    displayableProperty({
      key: 'actualScrap',
      title: i18n.t('order.model.attributes.actualScrap'),
      params: [
        displayablePropertyParam({
          path: 'actualScrap',
          transform: transformation.round(MAX_DECIMAL_PLACES),
          as: 'actualScrap',
        }),
        displayablePropertyParam({
          path: 'unitOfMeasure',
          transform: transformation.objectProperty('label'),
          as: 'unitOfMeasure',
        }),
      ],
      template: '{actualScrap} {unitOfMeasure}',
    }),
    displayableProperty({
      key: 'plannedStartDate',
      title: i18n.t('order.model.attributes.plannedStartDate'),
      params: [
        displayablePropertyParam({
          path: 'plannedStartDate',
          transform: transformation.datetime({ format: dateFormat }),
        }),
      ],
      renderText: (text, record) => record?.plannedStartDate || '',
    }),
  ];

  static allDisplayableProperties(
    rootStore: RootStore,
    pathPrefix = '',
    titlePrefix = '',
    options: DisplayablePropertiesOptions = { includeConversions: false }
  ) {
    const allDisplayableProperties = super.allDisplayableProperties(rootStore, pathPrefix, titlePrefix, options);
    const addAdditionalFields = (insertAfterKey: string, fields: DisplayableProperty[]) => {
      allDisplayableProperties.splice(
        allDisplayableProperties.findIndex((p) => p.key === insertAfterKey) + 1,
        0,
        ...fields
      );
    };

    allDisplayableProperties.push(
      ...this.displayableCustomProperties(rootStore, pathPrefix, titlePrefix)
    );
    const batchPrefix = `${titlePrefix}${i18n.t('batch.model.one')} > `;
    allDisplayableProperties.push(
      ...Batch.allDisplayableProperties(rootStore, `${pathPrefix}batch.`, batchPrefix)
    );
    const materialPrefix = `${titlePrefix}`;
    allDisplayableProperties.push(
      ...Material.allDisplayableProperties(rootStore, `${pathPrefix}material.`, materialPrefix)
    );
    if (options.includeConversions) {
      addAdditionalFields(
        `${pathPrefix}actualQuantity`,
        this.getSimpleQuantityFieldProperties('actualQuantity', 'id', rootStore, pathPrefix, titlePrefix)
      );
      addAdditionalFields(
        `${pathPrefix}plannedQuantity`,
        this.getSimpleQuantityFieldProperties('plannedQuantity', 'id', rootStore, pathPrefix, titlePrefix)
      );
      addAdditionalFields(
        `${pathPrefix}quantityIsShould`,
        this.getIsShouldQuantityFieldProperties('quantity', 'id', rootStore, pathPrefix, titlePrefix)
      );
      addAdditionalFields(
        `${pathPrefix}actualScrap`,
        this.getSimpleQuantityFieldProperties('actualScrap', 'id', rootStore, pathPrefix, titlePrefix)
      );
      addAdditionalFields(
        `${pathPrefix}plannedScrap`,
        this.getSimpleQuantityFieldProperties('plannedScrap', 'id', rootStore, pathPrefix, titlePrefix)
      );
      addAdditionalFields(
        `${pathPrefix}scrapIsShould`,
        this.getIsShouldQuantityFieldProperties('scrap', 'id', rootStore, pathPrefix, titlePrefix)
      );
    }

    return allDisplayableProperties;
  }

  get remainingQuantity() {
    return round((this.plannedQuantity || 0) - (this.actualQuantity || 0), MAX_DECIMAL_PLACES);
  }

  get name() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.name;
  }

  get info() {
    return getTranslation(this.rootStore.languageStore.lang, this.translations)?.info;
  }

  get stateText() {
    const stateName = OrderStateNames[this.state];
    return i18n.t(`order.model.attributes.state.${stateName}`);
  }

  get material() {
    return this.rootStore.materialStore.getById(this.materialId);
  }

  get unitOfMeasure() {
    return this.rootStore.unitOfMeasurementStore.getById(this.unitOfMeasureId);
  }

  get operations() {
    return this.rootStore.operationStore.operations.filter((op) => op.orderId === this.id);
  }

  get parentOrder() {
    return this.rootStore.orderStore.getById(this.parentOrderId);
  }

  get batch() {
    return this.rootStore.batchStore.getById(this.batchId);
  }

  get eventLogs() { // TODO: remove if not required
    return this.rootStore.eventLogStore.eventLogs.filter((eventLog) => eventLog.orderId === this.id);
  }

  get shiftBooks() { // TODO: remove if not required
    return this.rootStore.shiftBookStore.shiftBooks.filter((shiftBook) => shiftBook.orderId === this.id);
  }

  get unitOfMeasureConversions() {
    return this.rootStore.unitOfMeasurementConversionStore.getByOrderId(this.id);
  }

  get plannedStartDate() {
    const result = this.operations.reduce((newest: Operation | null, next: Operation) => {
      if (!newest || dayjs(newest.plannedStart) < dayjs(next.plannedStart)) {
        return next;
      }
      return newest;
    }, null);

    return result ? result.plannedStart : null;
  }
}
