import React, {useMemo, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle, faIndustry, faServer} from '@fortawesome/free-solid-svg-icons';
import {Space} from 'antd';
import {observer} from 'mobx-react-lite';
import dayjs from 'dayjs';
import {useMount} from '../../hooks/useMount';
import {WidgetLayout} from '../../models/widgetLayout';
import {UnclassifiedInterruptionsWidgetConfig} from './UnclassifiedInterruptionsWidgetConfig';
import UnclassifiedInterruptionsModal from './UnclassifiedInterruptionsModal';
import OperatorWidget from '../operator/shared/OperatorWidget';
import {WidgetLayouts} from '../shared/widgets/WidgetLayouts';
import WidgetTable from '../shared/widgets/WidgetTable';
import EditButton from '../shared/buttons/EditButton';
import BooleanValue from '../shared/dataDisplay/BooleanValue';
import {makeFormFields} from '../shared/form/formFields';
import {useStore} from '../../hooks/useStore';
import {ScopeContext} from '../../policies/scopeContext';
import {interruptionClassification, workflow} from '../../models/scope';
import {useModulePolicy} from '../../hooks/useModulePolicy';
import BatchInterruptionModal from './BatchInterruptionModal';

const UnclassifiedInterruptionsWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const scopes = [interruptionClassification];
  const policy = useModulePolicy(store, scopes);

  const [modalVisible, setModalVisible] = useState(false);
  const [batchInterruptionModalVisible, setBatchInterruptionModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const widgetConfig = useMemo(
    () => new UnclassifiedInterruptionsWidgetConfig(store, UnclassifiedInterruptionsWidget.identifier),
    [store.settingStore.operatorSettingsReady, store.languageStore.lang]
  );

  const fromDate = dayjs().subtract(widgetConfig.getSettingValue('settings', 'loadMaxDays'), 'days');
  const toDate = dayjs().endOf('day');

  useMount(() => {
    if (store.workplaceStore.selectedWorkplace && store.settingStore.operatorSettingsReady) {
      store.operationStateLogStore.loadManyByWorkplace(
        store.workplaceStore.selectedWorkplace.id,
        {fromDate, toDate, isInterruption: true, interruptionReasonId: 'null'}
      );
      store.workplaceStateLogStore.loadManyByWorkplace(
        store.workplaceStore.selectedWorkplace.id,
        {fromDate, toDate, isInterruption: true, interruptionReasonId: 'null'}
      );
    }
  }, [store.workplaceStore.selectedWorkplace?.id, store.settingStore.operatorSettingsReady]);

  const baseColumns = [
    {
      dataIndex: '',
      key: 'icon',
      align: 'left',
      render: (text, record) => (
        <Space>
          <FontAwesomeIcon icon={(record.operationId) ? faServer : faIndustry}/>
        </Space>
      ),
    },
    ...widgetConfig.getTableColumns().map((column) => {
      column.title = column.label;
      if (column.key === 'active') {
        column.render = (active) => <BooleanValue value={active}/>;
      }
      return column;
    }),
  ];

  const columns = [
    ...baseColumns,
    {
      dataIndex: '',
      key: 'actions',
      align: 'right',
      render: (text, record) => (
        <Space>
          {policy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId}) && (
            <EditButton
              onClick={() => {
                setCurrentRecord(record);
                setModalVisible(true);
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  const logs = useMemo(() => store.operationStateLogStore
    .getUnclassifiedByWorkplaceId(store.workplaceStore.selectedWorkplace?.id)
    .concat(store.workplaceStateLogStore.getUnclassifiedByWorkplaceId(store.workplaceStore.selectedWorkplace?.id))
    .filter((log) => {
      if (log.end) {
        return dayjs(log.end) >= dayjs()
          .subtract(widgetConfig.getSettingValue('settings', 'loadMaxDays'), 'days');
      }
      return true;
    }).filter((log) =>
      log.durationSeconds?.asSeconds() >= widgetConfig.getSettingValue('settings', 'loadMinDuration'))
    // for some reason, the default order attribute in the column of the table doesn't work
    .sort((a, b) => {
      if (widgetConfig.getSettingValue('settings', 'sortOrder') === 'asc') {
        if (a.start < b.start) {
          return -1;
        }
        if (a.start > b.start) {
          return 1;
        }
        return 0;
      }
      if (a.start < b.start) {
        return 1;
      }
      if (a.start > b.start) {
        return -1;
      }
      return 0;
    }), [
    store.workplaceStore.selectedWorkplace?.id,
    store.workplaceStateLogStore.areCollectionOrDependenciesLoading,
    store.operationStateLogStore.areCollectionOrDependenciesLoading,
    widgetConfig,
  ]);

  const updateStateLog = async (updateRecord, values, options = {}) => {
    const currentStore = (updateRecord.operationId)
      ? store.operationStateLogStore
      : store.workplaceStateLogStore;

    return currentStore.update({
      id: updateRecord.id,
      interruptionReasonId: values.interruptionReasonId,
      interruptionClassId: values.interruptionClassId,
      interruptionSourceHierarchyId: values.interruptionSourceHierarchyId,
      message: values.message,
    }, options);
  };

  const modalHeadClick = policy.canExecute({hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId})
    ? () => setBatchInterruptionModalVisible(true)
    : undefined;

  return (
    <ScopeContext.Provider value={[workflow]}>
      <OperatorWidget
        icon={<FontAwesomeIcon icon={faExclamationCircle}/>}
        title={widgetConfig.getWidgetTitle()}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        onHeadClick={widgetConfig.getSettingValue('settings', 'allowMultipleMutation') ? modalHeadClick : undefined}
        manualPath={'/workflow/unclassified-interruptions'}
      >
        <WidgetTable
          models={logs}
          loading={store.operationStateLogStore.isLoadingCollection || store.workplaceStore.isLoadingCollection}
          columns={columns}
          size={'small'}
        />
      </OperatorWidget>

      {modalVisible
        && (
          <UnclassifiedInterruptionsModal
            record={currentRecord}
            onCancel={() => setModalVisible(false)}
            onSubmit={(values, setFields) => {
              updateStateLog(currentRecord, values)
                .then(() => {
                  setModalVisible(false);
                })
                .catch((errors) => setFields(makeFormFields(values, errors.fields)));
            }}
            scopeSources={parseInt(widgetConfig.getValues()?.scopeSources || 0, 10)}
            selectSourceLeavesOnly={widgetConfig.getValues()?.selectSourceLeavesOnly}
          />
        )}
      {batchInterruptionModalVisible
        && (
          <BatchInterruptionModal
            onCancel={() => setBatchInterruptionModalVisible(false)}
            open={batchInterruptionModalVisible}
            logs={logs}
            columns={baseColumns}
            updateStateLog={updateStateLog}
            scopeSources={parseInt(widgetConfig.getValues()?.scopeSources || 0, 10)}
            selectSourceLeavesOnly={widgetConfig.getValues()?.selectSourceLeavesOnly}
          />
        )}
    </ScopeContext.Provider>
  );
};

UnclassifiedInterruptionsWidget.icon = faExclamationCircle;

UnclassifiedInterruptionsWidget.identifier = 'UnclassifiedInterruptionsWidget';
UnclassifiedInterruptionsWidget.defaultLayout = new WidgetLayout(
  {
    identifier: UnclassifiedInterruptionsWidget.identifier,
    x: 6,
    y: 9,
    height: 4,
    minHeight: 1,
    width: WidgetLayouts.halfWidth.w,
    minWidth: WidgetLayouts.halfWidth.minW,
  }
);
export default observer(UnclassifiedInterruptionsWidget);
