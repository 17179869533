import { makeObservable, observable } from 'mobx';
import { Api } from '../middleware/api';
import { EntityStore } from './entityStore';
import { PhaseTimeoutReason } from '../models/phaseTimeoutReason';
import { sortAlphabetically } from '../components/shared/tables/sorters';
import { RootStore } from './rootStore';

export class PhaseTimeoutReasonStore extends EntityStore<PhaseTimeoutReason> {
  phaseTimeoutReasons: PhaseTimeoutReason[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'phaseTimeoutReasons', Api.phaseTimeoutReasons, PhaseTimeoutReason);

    makeObservable(this, {
      phaseTimeoutReasons: observable,
    });
  }

  getByHierarchyId(hierarchyId: number) {
    const hierarchyPath = this.rootStore.hierarchyStore.getPathIds(hierarchyId);
    return this.phaseTimeoutReasons.filter(
      (reason) => hierarchyPath.find(
        (hierarchy) => reason.hierarchyIds.includes(hierarchy)
      )
    ).sort((a, b) => sortAlphabetically(a.label, b.label));
  }
}
