import { observable, makeObservable, computed } from 'mobx';
import { faSensorCloud } from '@fortawesome/pro-solid-svg-icons';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';

/**
 * process-data-connection types translations:
 * t('processDataConnection.types.HTTP_SERVER')
 * t('processDataConnection.types.MQTT_CLIENT')
 * t('processDataConnection.types.OPC_UA_CLIENT')
 * t('processDataConnection.types.S7_CLIENT')
 */
export enum ProcessDataConnectionType {
  HTTP_SERVER = 'HTTP_SERVER',
  MQTT_CLIENT = 'MQTT_CLIENT',
  OPC_UA_CLIENT = 'OPC_UA_CLIENT',
  S7_CLIENT = 'S7_CLIENT'
}

/**
 * process-data-connection securityModes translations:
 * t('processDataConnection.securityModes.NONE')
 * t('processDataConnection.securityModes.SIGN')
 * t('processDataConnection.securityModes.SIGN_ENCRYPTED')
 */
export const ProcessDataConnectionSecurityModes = Object.freeze({
  NONE: 'none',
  SIGN: 'sign',
  SIGN_ENCRYPTED: 'sign_encrypted',
});

/**
 * process-data-connection mqttVersions translations:
 * t('processDataConnection.mqttVersions.VERSION_3_1')
 * t('processDataConnection.mqttVersions.VERSION_3_1_1')
 * t('processDataConnection.mqttVersions.VERSION_5')
 */
export const ProcessDataConnectionMqttVersions = Object.freeze({
  VERSION_3_1: '3.1',
  VERSION_3_1_1: '3.1.1',
  VERSION_5: '5',
});

export interface ProcessDataSettings {
  [key: string]: any;
}

export class ProcessDataConnection extends BaseModel {
  id: number = 0;
  name: string = '';
  description: string | null = null;
  type: ProcessDataConnectionType = ProcessDataConnectionType.HTTP_SERVER;
  settings: ProcessDataSettings | null = {};
  gatewayId: number | null = null;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      name: observable,
      description: observable,
      type: observable,
      settings: observable,
      gatewayId: observable,
      gateway: computed,
    });
  }

  static faIcon = faSensorCloud;

  searchableProperties = ['name'];

  nestedModels = ['settings'];

  static prepareApiPayload(model: Partial<ProcessDataConnection>): Partial<ProcessDataConnection> {
    return {
      id: model.id || undefined,
      name: model.name,
      description: model.description || null,
      type: model.type,
      settings: model.settings || null,
      gatewayId: model.gatewayId || null,
    };
  }

  get gateway() {
    return this.rootStore.gatewayStore.getById(this.gatewayId);
  }
}
