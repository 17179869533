import React from 'react';
import { Tag as AntdTag, TagProps as AntdTagProps } from 'antd';
import styles from './touchTag.module.scss';

export type TouchTagProps = Omit<AntdTagProps, 'style'>;

const TouchTag: React.FC<TouchTagProps> = ({ closable, children, ...props }) => (
  <div
    className={styles.touchTag}
  >
    <AntdTag
      style={{ padding: closable ? 0 : '10px' }}
      closable={closable}
      {...props}
    >
      <span>{children}</span>
    </AntdTag>
  </div>
);
export default TouchTag;
