import React from 'react';
import { Tabs as AntdTabs, TabsProps as AntdTabsProps } from 'antd';
import './Tabs.module.scss';

export type TabsProps = AntdTabsProps;

export type TabItems = AntdTabsProps['items'];

const Tabs: React.FC<TabsProps> & {
  TabPane: typeof AntdTabs.TabPane;
} = ({ ...props }) => (
  <AntdTabs
    {...props}
  />
);

Tabs.TabPane = AntdTabs.TabPane;

export default Tabs;
