import React, { useState } from 'react';
import { Col, Row, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRepeat } from '@fortawesome/pro-solid-svg-icons';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { useMount } from '../../../hooks/useMount';
import { useStore } from '../../../hooks/useStore';
import Table from '../../shared/tables/Table';
import { smallPaginationPreset } from '../../shared/tables/paginationPresets';
import { TableColumns } from '../../shared/tables/columns';
import { transformation } from '../../../utils/transformations';
import { datetimeFormat } from '../../../config/dayjs';
import Button from '../../shared/buttons/Button';
import { useScopes } from '../../../hooks/useScopes';
import { useModulePolicy } from '../../../hooks/useModulePolicy';
import ReprintConfirmModal from './ReprintConfirmModal';
import ManualPrintConfirmModal from './ManualPrintConfirmModal';
import { PrintJobState } from '../../../models/printJob';
import { PrinterType } from '../../../models/printer';

export type PrintJobLogTabProps = {
  job: any;
  currentTab: string;
  loading: boolean;
  onReprint: (printJobLogId: number, printerId: number) => void;
  onManualPrint: (printerId: number, printData: any) => void;
};

const PrintJobLogTab: React.FC<PrintJobLogTabProps> = ({
  job,
  currentTab,
  loading,
  onReprint,
  onManualPrint,
}) => {
  const store = useStore();
  const scopes = useScopes();
  const policy = useModulePolicy(store, scopes);
  const { t } = useTranslation();
  const [reprintModalVisible, setReprintModalVisible] = useState<any>(null);
  const [manualPrintModalVisible, setManualPrintModalVisible] = useState<any>(null);

  useMount(() => {
    store.printJobLogStore.loadByPrintJobId(job.id);
  }, [currentTab]);

  const manualPrint = () => {
    const settings = store.settingStore.getByIdentifier('Office.PrintAllocationWidget.settings')
      // @ts-ignore
      ?.value?.printData.filter((el: any) => el.model === 'additionalData');
    setManualPrintModalVisible(settings);
  };

  return (
    <Space size={'middle'} direction={'vertical'} style={{ display: 'flex' }}>
      <Row justify={'end'}>
        <Col>
          {policy.canCreate() && job.state === PrintJobState.PRINTING && job.printer.type === PrinterType.ONE_OFF
            && (
              <Button
                onClick={manualPrint}
                type={'primary'}
                icon={<FontAwesomeIcon icon={faPrint} style={{ marginRight: '1em' }}/>}
              >
                {t('printJobLog.manualPrint.button')}
              </Button>
            )}
        </Col>
      </Row>
      <Table
        style={{ whiteSpace: 'pre' }}
        loading={store.printJobLogStore.areCollectionOrDependenciesLoading || loading}
        columns={[
          TableColumns.id(),
          {
            title: t('printJobLog.model.attributes.updatedAt'),
            dataIndex: 'updatedAt',
            render: transformation.datetime({ format: datetimeFormat }),
          },
          {
            title: t('printJobLog.model.attributes.printJobData'),
            dataIndex: 'printJobData',
            render: (data: any) => JSON.stringify(data, null, 2),
          },
          {
            title: t('printJobLog.model.attributes.state'),
            dataIndex: 'state',
          },
          {
            title: t('printer.model.one'),
            // @ts-ignore
            render: (record: any) => store.printerStore.getById(record?.printerId)?.name,
          },
          {
            dataIndex: '',
            key: 'actions',
            align: 'right',
            render: (record: any) => (
              <Space>
                <Button
                  onClick={() => setReprintModalVisible(record)}
                  type={'link'}
                  disabled={loading}
                  icon={<FontAwesomeIcon icon={faRepeat} style={{ marginRight: '1em' }}/>}
                >
                  {t('printJobLog.reprint.button')}
                </Button>
              </Space>
            ),
          },
        ]}
        dataSource={store.printJobLogStore.printJobLogs.slice()}
        rowKey={'id'}
        pagination={smallPaginationPreset}
        wrapperStyle={undefined}
      />
      {reprintModalVisible && (
        <ReprintConfirmModal
          printJobLog={reprintModalVisible}
          onCancel={() => setReprintModalVisible(null)}
          onConfirm={(formData) => {
            onReprint(reprintModalVisible.id, formData.printerId);
            setReprintModalVisible(null);
          }}
        />
      )}
      {manualPrintModalVisible && (
        <ManualPrintConfirmModal
          printJob={job}
          settings={manualPrintModalVisible}
          onCancel={() => setManualPrintModalVisible(null)}
          onConfirm={(formData) => {
            const { printerId, ...additionalData } = formData;
            const printData = {
              printJobId: job.id,
              ...additionalData,
            };
            onManualPrint(printerId, printData);
            setManualPrintModalVisible(null);
          }}
        />
      )}
    </Space>
  );
};
export default observer(PrintJobLogTab);
