import { observable, makeObservable, computed } from 'mobx';
import { BaseModel } from './base';
import { RootStore } from '../stores/rootStore';
import { Workplace } from './workplace';
import { TerminalLayout } from './terminalLayout';

export class Setting extends BaseModel {
  id: number = 0;
  workplaceId: number | null = 0;
  terminalLayoutId: number | null = 0;
  identifier: string = '';
  value: object = {};
  createdAt: string = '';
  createdBy: number = 0;
  updatedAt: string = '';
  updatedBy: number = 0;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      id: observable,
      workplaceId: observable,
      workplace: computed,
      terminalLayoutId: observable,
      terminalLayout: computed,
      identifier: observable,
      value: observable,
      ...Setting.modelCreateDecoration,
      ...Setting.modelUpdateDecoration,
    });
  }

  get workplace(): Workplace | null {
    return this.workplaceId ? this.rootStore.workplaceStore.getById(this.workplaceId) as unknown as Workplace : null;
  }

  get terminalLayout(): TerminalLayout | null {
    return this.terminalLayoutId
      ? this.rootStore.terminalLayoutStore.getById(this.terminalLayoutId) as unknown as TerminalLayout
      : null;
  }
}
