export class ApiError {
  static fromPlainObject(plainObject) {
    const errors = new ApiError();
    if (typeof plainObject !== 'object') {
      this.error = plainObject;
      return plainObject;
    }
    return Object.assign(errors, plainObject);
  }

  joinMessages(options = {}) {
    const joinStr = options.joinStr || ', ';
    const errorMessages = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const property in this) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.hasOwnProperty(property)) {
        const value = this[property];
        if (value && ((value.length && typeof value[0] === 'string') || typeof value === 'string')) {
          errorMessages.push(value);
        }
      }
    }

    return errorMessages.join(joinStr);
  }
}
