import {isEmpty} from 'lodash';

export const handleError = (e) => {
  // variations of error responses that are expected:

  // model validation errors (these are returned as-is): { [model-property]: string[] }

  // other, rather unusual errors: { error: string | string[]} or [ { message: string } ]
  // these different error response types are normalized into a single type:
  // { error: { status: 500, messages: string[] } }

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.warn(e, {response: e && e.response});
  }

  if (!e.response || !e.response.data || isEmpty(e.response.data) || typeof e.response.data === 'string') {
    const status = (e.response && e.response.status) ? e.response.status : 500;
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({error: {status, messages: ['unknown_error']}});
  }

  const {data} = e.response;

  if (data.error || data.errors || Array.isArray(data)) {
    // eslint-disable-next-line no-use-before-define
    data.error = normalizeResponseError(e.response);
    return Promise.reject(data);
  }

  return Promise.reject(data);
};

const normalizeResponseError = (response) => {
  const {data, status} = response;
  const error = {messages: []};
  if (!error.status) {
    error.status = status || 500;
  }

  let serverError = null;
  if (Array.isArray(data)) {
    serverError = data;
  }
  serverError = serverError || data.error || data.errors;

  if (typeof serverError === 'string') {
    error.messages.push(serverError);
  }
  if (Array.isArray(serverError)) {
    if (typeof serverError[0] === 'string') {
      error.messages.push(...serverError);
    } else if (typeof serverError[0] === 'object' && typeof serverError[0].message === 'string') {
      error.messages.push(...serverError.map((e) => e.message));
    }
  }
  return error;
};

export const pipe = (data) => ({
  next: (func) => pipe(func(data)),
});
