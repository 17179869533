import { computed, observable, makeObservable, action } from 'mobx';
import { ReactNode } from 'react';
import { RootStore } from './rootStore';

export const officeContext = 'office';
export const operatorContext = 'operator';

export interface Context {
  name: string;
  navBarContentLeft?: string;
  navBarContent?: ReactNode,
  navBarContentRight?: ReactNode,
  isLoginRequired: boolean,
}

export const defaultContext: Context = {
  name: 'default',
  isLoginRequired: false,
};

export class ContextStore {
  rootStore: RootStore;
  current: Context = defaultContext;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      current: observable,
      isOfficeContext: computed,
      isOperatorContext: computed,
      setCurrent: action,
    });

    this.rootStore = rootStore;
  }

  get isOfficeContext() {
    return this.current.name === officeContext;
  }

  get isOperatorContext() {
    return this.current.name === operatorContext;
  }

  setCurrent(context: Context) {
    this.current = context;
  }
}
