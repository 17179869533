import React from 'react';
import { Space } from 'antd';
import Modal from '../shared/modal/Modal';
import ShowDocumentImage from '../document/ShowDocumentImage';
import { useStore } from '../../hooks/useStore';

export type QualityReportAttachmentModalProps = {
  open: boolean;
  onCancel: () => void;
  onOk?: () => void;
  qualityReportId: number | null;
};

const QualityReportAttachmentModal: React.FC<QualityReportAttachmentModalProps> = ({
  open,
  onCancel,
  qualityReportId,
  onOk,
}) => {
  const store = useStore();
  const qualityReport = store.qualityReportStore.getById(qualityReportId);
  const documents = qualityReport?.documents || [];

  return (
    <Modal open={open} onCancel={onCancel} onOk={onOk ?? onOk} width={'80vw'} title={qualityReport?.title}>
      <Space
        align={'center'}
        direction={'vertical'}
        style={{ width: 'calc(100% - 24px)', maxHeight: 'calc(100vh - 250px)', overflowY: 'auto' }}
      >
        {documents.map((document) => (
          <div style={{ marginBottom: '10px' }} key={document.id}>
            <ShowDocumentImage
              document={document}
            />
            <br/>
            <a href={document.src} target={'_blank'} rel="noreferrer noopener">{document.filename}</a>
          </div>
        ))}
      </Space>
    </Modal>
  );
};

export default QualityReportAttachmentModal;
