import {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {Space} from 'antd';
import dayjs from 'dayjs';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPauseCircle} from '@fortawesome/free-solid-svg-icons';
import {useStore} from '../../../hooks/useStore';
import {InspectionTaskPendingWidgetConfig} from '../../inspectionTask/InspectionTaskPendingWidgetConfig';
import InspectionTaskPendingWidget from '../../inspectionTask/InspectionTaskPendingWidget';
import styles from '../../stateTransition/StateTransitionWidgetDetailModal.module.css';
import {formatDuration} from '../../../utils/formatting';
import {detailedDurationFormat} from '../../../config/dayjs';
import Spinner from '../../shared/spinners/Spinner';
import {EnDash} from '../../shared/unicodeWrapper/EnDash';
import {getIcons} from '../../inspectionTask/getIcons';
import {InspectionTaskPendingStore} from '../../../stores/inspectionTaskPendingStore';
import InspectionTaskPendingTable from '../../inspectionTask/InspectionTaskPendingTable';

const PendingTasksList = ({relevantPendingIds, selectPendingTask}) => {
  const {t} = useTranslation();
  const store = useStore();
  const widgetConfig = useMemo(() =>
    new InspectionTaskPendingWidgetConfig(store, InspectionTaskPendingWidget.identifier), []);
  const roleIds = store.authStore.user?.roleIds;

  const configColumns = widgetConfig.getWidgetTableColumns().map((column) => {
    if (column.key === 'dueDate') {
      return {
        ...column,
        render: (value, pendingTask) => {
          if (pendingTask.pausedSince) {
            return (
              <Space>
                <FontAwesomeIcon icon={faPauseCircle}/>
                {t('inspectionTaskPendingWidget.paused')}
              </Space>
            );
          }
          if (!pendingTask.dueDate) {
            return EnDash();
          }
          const duration = dayjs.duration(dayjs(pendingTask.dueDate).diff(dayjs()));
          const dueDateIsBeforeNow = dayjs(pendingTask.dueDate).isBefore(dayjs());
          return (
            <span className={dueDateIsBeforeNow ? styles.redText : null}>
              {formatDuration(duration, detailedDurationFormat)}
            </span>
          );
        },
      };
    }
    return column;
  });

  const columns = [
    {
      title: '',
      key: 'icons',
      render: (value, pendingTask) => (
        <Space>
          {
            pendingTask.inspectionLot === undefined
              ? <Spinner size={'small'}/>
              : getIcons(pendingTask, true, roleIds)
                .map(({icon, style}) => <FontAwesomeIcon key={icon.iconName} icon={icon} size="1x" style={style}/>)
          }
        </Space>
      ),
    },
    ...configColumns,
  ];

  const datasource = InspectionTaskPendingStore.groupTasks(
    store.inspectionTaskPendingStore.getByIds(relevantPendingIds)
  );

  return (
    <>
      {t('transition.customActionError.checksBlockingStateTransition')}
      <InspectionTaskPendingTable
        style={{marginTop: '1em'}}
        columns={columns}
        dataSource={datasource}
        onRowClick={selectPendingTask}
      />
    </>
  );
};

export default observer(PendingTasksList);
