import {useRef, useState} from 'react';
import {SpecialZoomLevel, Viewer, Worker} from '@react-pdf-viewer/core';
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {useTranslation} from 'react-i18next';
import {useMount} from '../../hooks/useMount';
import de from './translations/de.json';
import AlertWarning from '../shared/alert/AlertWarning';
import {useStore} from '../../hooks/useStore';

const ShowDocumentPdf = ({document, isMobile = false}) => {
  const {t} = useTranslation();
  const [height, setHeight] = useState(0);
  const store = useStore();
  const ref = useRef(null);

  useMount(() => {
    setHeight(ref.current.clientHeight);
  });

  const renderToolbar = (Toolbar) => (
    <Toolbar>
      {
        (slots) => {
          const {
            ShowSearchPopover, GoToPreviousPage, GoToNextPage, CurrentPageInput, NumberOfPages,
            Zoom, ZoomIn, ZoomOut, EnterFullScreen,
          } = slots;
          return (
            <div
              style={{
                alignItems: 'center',
                justifyContent: 'space-between',
                display: 'flex',
                width: '100%',
                margin: '2px 0 2px 2px',
              }}
            >
              {!isMobile && (
                <div style={{margin: '0 2px'}}>
                  <ShowSearchPopover/>
                </div>
              )}
              <div style={{margin: '0 2px'}}>
                <GoToPreviousPage/>
              </div>
              {!isMobile && (
                <div style={{margin: '0 2px', display: 'flex', alignItems: 'center'}}>
                  <div style={{maxWidth: '40px', margin: '0 2px 0 -4px'}}>
                    <CurrentPageInput/>
                  </div>
                  <span>
                    &nbsp;/&nbsp;
                    <NumberOfPages/>
                  </span>
                </div>
              )}
              <div style={{margin: '0 2px'}}>
                <GoToNextPage/>
              </div>
              <div style={{margin: '0 2px 0 auto'}}>
                <ZoomOut/>
              </div>
              <div style={{margin: '0 2px'}}>
                <Zoom/>
              </div>
              <div style={{margin: '0 2px'}}>
                <ZoomIn/>
              </div>
              <div style={{margin: '0 2px 0 auto'}}>
                <EnterFullScreen/>
              </div>
            </div>
          );
        }
      }
    </Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [
      defaultTabs[0], // thumbnails
    ],
    toolbarPlugin: {
      fullScreenPlugin: {
        onEnterFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageFit);
        },
        onExitFullScreen: (zoom) => {
          zoom(SpecialZoomLevel.PageFit);
        },
      },
    },
    renderToolbar,
  });

  const layout = (
    isSidebarOpened,
    container,
    main,
    toolbar,
    sidebar
  ) => (
    <div
      {...container.attrs}
      style={({
        border: '1px solid rgba(0, 0, 0, .3)',
        display: 'grid',
        gridTemplateAreas: isSidebarOpened
          ? '\'toolbar toolbar\' \'sidebar main\''
          : '\'toolbar\' \'main\'',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
        ...container.attrs.style,
      })}
    >
      {container.children}
      <div
        style={{
          borderRight: '1px solid rgba(0, 0, 0, 0.2)',
          display: isSidebarOpened ? 'flex' : 'none',
          gridArea: 'sidebar',
          justifyContent: 'center',
        }}
      >
        {sidebar.children}
      </div>
      <div
        {...main.attrs}
        style={({
          gridArea: 'main',
          overflow: 'scroll',
          backgroundColor: '#ededf0',
          ...main.attrs.style,
        })}
      >
        {main.children}
      </div>
    </div>
  );

  const localization = (store.languageStore.lang.split('-')[0] === 'de') ? de : undefined;

  const renderError = (error) => {
    // eslint-disable-next-line no-console
    console.error('PDF-Viewer error:', error);

    let message = '';
    switch (error.name) {
      case 'InvalidPDFException':
        message = t('document.show.pdfError.invalidPdf');
        break;
      case 'MissingPDFException':
        message = t('document.show.pdfError.missingPdf');
        break;
      case 'UnexpectedResponseException':
        message = t('document.show.pdfError.unexpectedResponse');
        break;
      default:
        message = t('document.show.pdfError.cantLoadDocument');
        break;
    }

    return (
      <AlertWarning
        message={message}
        type={'error'}
      />
    );
  };

  return (
    <div style={{height: '100%', minHeight: 400}} ref={ref}>
      <Worker workerUrl={'/pdf.worker.js'}>
        <div style={{height}}>
          <Viewer
            fileUrl={document.src}
            defaultScale={SpecialZoomLevel.PageFit}
            plugins={[
              defaultLayoutPluginInstance,
            ]}
            layout={layout}
            localization={localization}
            renderError={renderError}
          />
        </div>
      </Worker>
    </div>
  );
};
export default ShowDocumentPdf;
