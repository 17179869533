export const hexToRGBArray = (hex) => hex.substring(0, 7).match(/[A-Za-z0-9]{2}/g).map((v) => parseInt(v, 16));
export const RGBToHex = (r, g, b) => {
  const [rr, gg, bb] = [r, g, b].map((val) => {
    const v = Math.max(Math.min(255, val), 0).toString(16);
    return (v.length < 2 ? '0' : '') + v;
  });
  return `#${rr}${gg}${bb}`;
};

// adapted from https://stackoverflow.com/a/3943023/1049699
export const isDarkBackground = (backgroundColor) => {
  const [r, g, b] = hexToRGBArray(backgroundColor);
  return (r * 0.299 + g * 0.587 + b * 0.114) <= 186;
};

/**
 * @param {string} col The color (in #hex) to shade
 * @param {number} amt How much to lighten/darken the color. lighten: amt > 0; darken amt < 0
 * @return {string}
 */
export const shadeColor = (col, amt) => {
  let [r, g, b] = hexToRGBArray(col);
  [r, g, b] = [r + amt, g + amt, b + amt];
  return RGBToHex(r, g, b);
};
