import i18n from '../../i18n/i18n';
import {ValidationRules} from '../../utils/validationRules';
import {BaseWidgetConfig} from '../shared/widgets/baseWidgetConfig';
import {getAllI18nTranslations} from '../../utils/translations';
import {Operation} from '../../models/operation';
import {FieldType} from '../../types/widgetConfig';

export class StateTransitionWidgetConfig extends BaseWidgetConfig {
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'settings',
        label: i18n.t('widget.WidgetSettingsModal.settingConfigTabTitle'),
      },
      {
        key: 'data',
        label: i18n.t('widget.WidgetSettingsModal.dataConfigTabTitle'),
      },
    ];
  }

  settings = [
    {
      id: 'title',
      label: i18n.t('widget.settings.title.label'),
      helpText: i18n.t('widget.settings.title.help'),
      type: FieldType.Translatable,
      defaultValue: getAllI18nTranslations('stateTransitionWidget.title'),
      validations: [ValidationRules.required()],
    },
  ];

  data = [
    {
      id: 'operationProperties',
      label: i18n.t('stateTransitionWidget.settings.operationProperties'),
      type: FieldType.PropertiesSelectDisplayField,
      modelOptions: Operation.allDisplayableProperties(this.rootStore),
      defaultValue: [],
    },
  ];

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'title');
  }

  getOperationProperties() {
    return this.getSelectedProperties('data', 'operationProperties');
  }
}
