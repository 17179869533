import { RecordOfString } from '../types/util';

export type KeyValueObject = {
  key: string;
  value: string;
};

/**
 * takes an array of {key: *, value: *} objects and reduces it to an object containing key / value properties
 *
 * @param {KeyValueObject} values
 * @returns {RecordOfString} a reduced property request object
 * @constructor
 */
export const getKeyValueObject = (values: KeyValueObject[] = []): RecordOfString => {
  const properties = values
    .reduce((acc, current) => ({ ...acc, [current.key]: current.value }), {});

  return Object.freeze({
    ...properties,
  });
};

/**
 * takes an object and returns its properties as an array of {key: *, value: *} objects
 *
 * @param {RecordOfString} value
 * @returns {[{value: *, key: *}]}
 * @constructor
 */
export const getKeyValueArray = (value: RecordOfString | null = {}): KeyValueObject[] => {
  if (!value) {
    return [];
  }
  return Object.keys(value).map((v) => ({
    key: v,
    value: value[v],
  }));
};
