/**
 * @typedef  {Object} DisplayablePropertyParam
 * @property {string} path - Path in the object structure to display or transform.
 * If set to "." will use the entire object.
 * @property {function} transform - Transformation function to transform the value to another representation.
 * @property {string} as - Alias for the value, to be used in the template of a DisplayableProperty.
 * If none is provided, then {value} may be used as default reference to the value.
 */
/**
 * @param {DisplayablePropertyParam} param
 *
 * @return {DisplayablePropertyParam}
 */

export interface DisplayablePropertyParam {
  path: string;
  transform?: (value: any) => any;
  as?: string;
}

export const displayablePropertyParam = ({
  path = '',
  transform = undefined,
  as = undefined,
}: DisplayablePropertyParam): DisplayablePropertyParam =>
  ({
    path,
    transform,
    as,
  });
