import {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Col, Row, Space} from 'antd';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBrowser} from '@fortawesome/pro-solid-svg-icons';
import {WidgetLayouts} from '../shared/widgets/WidgetLayouts';
import {WidgetLayout} from '../../models/widgetLayout';
import OperatorWidget from '../operator/shared/OperatorWidget';
import {useStore} from '../../hooks/useStore';
import {useMount} from '../../hooks/useMount';
import {ScopeContext} from '../../policies/scopeContext';
import {terminal} from '../../models/scope';
import Button from '../shared/buttons/Button';
import {TerminalManagementWidgetConfig} from './TerminalManagementWidgetConfig';
import RegisterModal from './RegisterModal';
import LogoffModal from './LogoffModal';
import BrokenModal from './BrokenModal';
import RepairedModal from './RepairedModal';

const TerminalManagementWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const {t} = useTranslation();
  const widgetConfig = useMemo(() =>
    new TerminalManagementWidgetConfig(store, identifier), []);
  const settings = widgetConfig.getValues();

  const [registerModalVisible, setRegisterModalVisible] = useState(false);
  const [logoffModalVisible, setLogoffModalVisible] = useState(false);
  const [brokenModalVisible, setBrokenModalVisible] = useState(false);
  const [repairedModalVisible, setRepairedModalVisible] = useState(null);

  useMount(() => {
    if (store.authStore.user) {
      store.terminalStore.loadAll();
    }
  }, [store.authStore.user?.id]);

  const handleRepair = (terminalToRepair) => {
    setRegisterModalVisible(false);
    setLogoffModalVisible(false);
    setBrokenModalVisible(false);
    setRepairedModalVisible(terminalToRepair);
  };

  return (
    <ScopeContext.Provider value={[terminal]}>
      <OperatorWidget
        icon={<FontAwesomeIcon icon={faBrowser}/>}
        title={widgetConfig.getWidgetTitle()}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
      >
        <Space size={'small'} direction={'vertical'} style={{width: '100%'}}>
          <Row gutter={16}>
            <Col span={12}>
              <Button onClick={() => setRegisterModalVisible(true)} size={'large'} block>
                {t('terminalManagement.buttons.register')}
              </Button>
            </Col>
            <Col span={12}>
              <Button onClick={() => setLogoffModalVisible(true)} size={'large'} block>
                {t('terminalManagement.buttons.logoff')}
              </Button>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Button onClick={() => setBrokenModalVisible(true)} size={'large'} block>
                {t('terminalManagement.buttons.broken')}
              </Button>
            </Col>
            <Col span={12}>
              <Button onClick={() => setRepairedModalVisible({})} size={'large'} block>
                {t('terminalManagement.buttons.repaired')}
              </Button>
            </Col>
          </Row>
        </Space>
      </OperatorWidget>
      {registerModalVisible && (
        <RegisterModal
          title={(
            <>
              <FontAwesomeIcon icon={faBrowser}/>
              {' '}
              {t('terminalManagement.modal.titles.register')}
            </>
          )}
          onCancel={() => setRegisterModalVisible(false)}
          onSuccess={() => setRegisterModalVisible(false)}
          onRepair={handleRepair}
        />
      )}
      {logoffModalVisible && (
        <LogoffModal
          title={(
            <>
              <FontAwesomeIcon icon={faBrowser}/>
              {' '}
              {t('terminalManagement.modal.titles.logoff')}
            </>
          )}
          onCancel={() => setLogoffModalVisible(false)}
          onSuccess={() => setLogoffModalVisible(false)}
          onRepair={handleRepair}
        />
      )}
      {brokenModalVisible && (
        <BrokenModal
          title={(
            <>
              <FontAwesomeIcon icon={faBrowser}/>
              {' '}
              {t('terminalManagement.modal.titles.broken')}
            </>
          )}
          onCancel={() => setBrokenModalVisible(false)}
          onSuccess={() => setBrokenModalVisible(false)}
          brokenReasons={[
            settings.reason1,
            settings.reason2,
            settings.reason3,
            settings.reason4,
            settings.reason5,
            settings.reason6,
            settings.reason7,
            settings.reason8,
          ].filter((reason) => !!reason)}
        />
      )}
      {repairedModalVisible && (
        <RepairedModal
          title={(
            <>
              <FontAwesomeIcon icon={faBrowser}/>
              {' '}
              {t('terminalManagement.modal.titles.repaired')}
            </>
          )}
          terminal={repairedModalVisible}
          onCancel={() => setRepairedModalVisible(null)}
          onSuccess={() => setRepairedModalVisible(null)}
        />
      )}
    </ScopeContext.Provider>
  );
};

TerminalManagementWidget.icon = faBrowser;

TerminalManagementWidget.identifier = 'terminalManagementWidget';
TerminalManagementWidget.defaultLayout = new WidgetLayout(
  {
    identifier: TerminalManagementWidget.identifier,
    x: 2,
    y: 8,
    height: 4,
    minHeight: 3,
    width: WidgetLayouts.sixthWidth.w,
    minWidth: WidgetLayouts.sixthWidth.minW,
  }
);

export default observer(TerminalManagementWidget);
