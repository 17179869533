import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import styles from '../InspectionTaskPendingWidget.module.scss';
import Input from '../../shared/inputs/Input';
import Form from '../../shared/form/Form';
import Button from '../../shared/buttons/Button';

const GroupRemark = ({maxLength = 40, form, showGroupRemark, hidden = false}) => {
  const {t} = useTranslation();
  const [manualShowRemark, setManualShowRemark] = useState(false);
  const showRemark = hidden || manualShowRemark;
  const buttonsEnabled = !hidden && showGroupRemark;

  const onChange = (e) => {
    e.persist();
    form.setFieldsValue({groupRemark: e.target.value});
  };

  return (
    <>
      {buttonsEnabled && (
        <Button
          type={'link'}
          className={styles.primaryText}
          onClick={() => setManualShowRemark(true)}
          style={{display: showRemark ? 'none' : 'inline', padding: 0}}
          disabled={showRemark}
        >
          {t('inspectionTaskPendingModal.taskBox.addGroupRemark')}
        </Button>
      )}
      <div style={{display: showRemark ? 'inline' : 'none'}}>
        <Form.Item
          name={'groupRemark'}
          labelCol={{span: 24}}
          wrapperCol={{span: 24}}
          label={t('inspectionTaskPendingModal.taskBox.groupRemark')}
          hidden={hidden}
          style={{marginBottom: 0}}
        >
          <Input.TextArea
            maxLength={maxLength}
            onChange={onChange}
          />
        </Form.Item>
        {buttonsEnabled && (
          <Button
            type={'link'}
            className={styles.redText}
            onClick={() => {
              setManualShowRemark(false);
              form.setFieldsValue({groupRemark: ''});
            }}
            style={{display: 'inline', padding: 0}}
          >
            {t('inspectionTaskPendingModal.taskBox.deleteGroupRemark')}
          </Button>
        )}
      </div>
    </>
  );
};

export default GroupRemark;
