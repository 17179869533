import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStore } from '../../hooks/useStore';
import DateRangePicker from '../shared/inputs/DateRangePicker';
import AddButton from '../shared/buttons/AddButton';
import { useModulePolicy } from '../../hooks/useModulePolicy';
import { Scopes } from '../../models/scopes';
import { QualityReportWidgetConfig } from './qualityReportWidgetConfig';
import QualityReportTable from './QualityReportTable';
import FormModal from '../shared/modal/FormModal';
import { QualityReport } from '../../models/qualityReport';
import { datetimeFormat } from '../../config/dayjs';

export type QualityReportTableModalProps = {
  models: any[];
  widgetConfig: QualityReportWidgetConfig;
  queryData: (from: dayjs.Dayjs | null, to: dayjs.Dayjs | null) => void;
  onAdd: () => void;
  settings: any;
  onCancel: () => void;
};

const getDateString = (date: Dayjs | null) => (date ? date.format(datetimeFormat) : undefined);

const QualityReportTableModal: React.FC<QualityReportTableModalProps> = ({
  models,
  widgetConfig,
  queryData,
  onAdd,
  settings,
  onCancel,
}) => {
  const { t } = useTranslation();
  const store = useStore();
  const policy = useModulePolicy(store, [Scopes.qualityReport]);
  const [fromDate, setFromDate] = useState<dayjs.Dayjs | null>(dayjs().subtract(settings?.queryTimeSpan, 'hours'));
  const [toDate, setToDate] = useState<dayjs.Dayjs | null>(dayjs());

  const onDateRangeChange = (
    dates: RangePickerProps['value']
  ) => {
    if (dates?.length !== 2) {
      return;
    }
    setFromDate(dates[0]);
    setToDate(dates[1]);
    queryData(dates[0], dates[1]);
  };

  return (
    <FormModal
      fullscreen={store.clientStore.isMobile}
      title={(
        <>
          <FontAwesomeIcon icon={QualityReport.faIcon}/>
          {' '}
          {t('qualityReport.widget.modal.title')}
        </>
      )}
      open
      width={'80%'}
      onCancel={onCancel}
    >
      <Row gutter={16} justify={'space-between'} style={{ marginBottom: '12px' }}>
        <Col xl={8} md={10} sm={16} xs={24}>
          <DateRangePicker
            dateTimeFrom={getDateString(fromDate)}
            dateTimeTo={getDateString(toDate)}
            onChange={onDateRangeChange}
            placeholder={[t('dateRangePicker.placeholder.from'), t('dateRangePicker.placeholder.to')]}
            allowEmpty={[false, false]}
            allowClear={false}
            showDefaultTimeRanges
          />
        </Col>
        <Col>
          <AddButton
            onClick={onAdd}
            type={'primary'}
            // TODO: fix this
            // @ts-ignore
            disabled={!policy.canEdit({ hierarchyId: store.workplaceStore.selectedWorkplace?.hierarchyId })}
          />
        </Col>
      </Row>
      <QualityReportTable
        dataSource={models.filter((qr) => dayjs(qr.createdAt).valueOf() >= dayjs(fromDate).valueOf()
          && dayjs(qr.createdAt).valueOf() <= dayjs(toDate).valueOf())}
        loading={store.qualityReportStore.areCollectionOrDependenciesLoading}
        columns={widgetConfig.getTableColumns()}
      />
    </FormModal>
  );
};

export default observer(QualityReportTableModal);
