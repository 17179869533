import {Select} from 'antd';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';

const InterruptionReasonSelect = ({
  disabled = false,
  onChange,
  value,
  availableInterruptionReasons,
  ...props
}) => {
  const {t} = useTranslation();

  const options = availableInterruptionReasons.map((interruptionReason) => ({
    label: interruptionReason.label,
    value: interruptionReason.id,
  }));

  return (
    <Select
      disabled={disabled}
      placeholder={t('interruption.modal.form.interruptionReason.placeholder')}
      options={options}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

export default observer(InterruptionReasonSelect);
