import {Divider} from 'antd';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import Modal from '../shared/modal/Modal';
import Button from '../shared/buttons/Button';

const AddOrReplaceModal = ({
  onSuccess,
  onCancel,
  terminal,
  group,
}) => {
  const {t} = useTranslation();

  return (
    <Modal
      open
      title={t('terminalManagement.modal.register.addOrReplace')}
      onCancel={onCancel}
      footer={[
        <Button key={'cancel'} style={{float: 'left'}} onClick={() => onCancel()}>
          {t('links.cancel')}
        </Button>,
        <Button key={'add'} type={'primary'} onClick={() => onSuccess(true)}>
          {t('terminalManagement.modal.register.add')}
        </Button>,
        <Button key={'replace'} type={'primary'} onClick={() => onSuccess(false)}>
          {t('terminalManagement.modal.register.replace')}
        </Button>,
      ]}
    >
      {t('terminalManagement.modal.register.addReplaceInfo', {terminalName: terminal.name})}
      <Divider/>
      {group.workplaces.map((workplace) => (<div key={workplace.id}>{`${workplace.no} (${workplace.name})`}</div>))}
    </Modal>
  );
};

export default observer(AddOrReplaceModal);
