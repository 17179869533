import React from 'react';
import { Col, Row, TreeSelect } from 'antd';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import DateRangePicker from '../../shared/inputs/DateRangePicker';
import { FilterFormItem } from '../../shared/form/FilterFormItem';
import { useStore } from '../../../hooks/useStore';
import SearchField from '../../shared/inputs/SearchField';

export type PrintJobFilterProps = {
  setSearchQuery: (query: string) => void;
  dateAndHierarchyFilterEnabled?: boolean;
};

const PrintJobFilter: React.FC<PrintJobFilterProps> = ({
  setSearchQuery,
  dateAndHierarchyFilterEnabled,
}) => {
  const { t } = useTranslation();
  const store = useStore();
  return (
    <Row justify={'space-between'}>
      {dateAndHierarchyFilterEnabled && (
        <Col xs={24} md={6}>
          <FilterFormItem
            label={t('printJob.dateRange.label')}
            required
          >
            <DateRangePicker
              value={store.officeStore.dates}
              onOk={(dateChange) => (dateChange ? store.officeStore.setDates(dateChange as [Dayjs, Dayjs]) : null)}
              showDefaultTimeRanges
              allowClear={false}
            />
          </FilterFormItem>
        </Col>
      )}
      {dateAndHierarchyFilterEnabled && (
        <Col xs={24} md={6}>
          <FilterFormItem
            label={t('printJob.hierarchy.label')}
            required
          >
            <TreeSelect
              allowClear={false}
              value={store.officeStore.hierarchyId}
              placeholder={t('printJob.hierarchy.selectHierarchy')}
              onChange={(hierarchyId) => store.officeStore.setHierarchyId(hierarchyId)}
              disabled={!store.hierarchyStore.hierarchyTreeToWorkplacesOnly?.length}
              key={'hierarchyId'}
              treeData={store.hierarchyStore.hierarchyTreeToWorkplacesOnly}
              showSearch
              style={{ width: '100%' }}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeDefaultExpandAll
              treeNodeFilterProp={'title'}
            />
          </FilterFormItem>
        </Col>
      )}
      <Col xs={24} md={dateAndHierarchyFilterEnabled ? 6 : 24}>
        <FilterFormItem label={dateAndHierarchyFilterEnabled ? String.fromCharCode(160) : null}>
          <SearchField placeholder={t<string>('printJob.filter.placeholder')} onSearch={setSearchQuery}/>
        </FilterFormItem>
      </Col>
    </Row>
  );
};

export default observer(PrintJobFilter);
