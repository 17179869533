import { BaseWidgetConfig } from '../../shared/widgets/baseWidgetConfig';
import i18n from '../../../i18n/i18n';
import { getAllI18nTranslations } from '../../../utils/translations';
import { ValidationRules } from '../../../utils/validationRules';
import { StorageUnit } from '../../../models/storageUnit';
import { FieldType } from '../../../types/widgetConfig';
import { RootStore } from '../../../stores/rootStore';
import { WidgetActionExtension } from '../../shared/widgets/WidgetActionExtension';

export class MaterialFlowWidgetConfig extends BaseWidgetConfig {
  constructor(rootStore: RootStore, identifier: string) {
    super(rootStore, identifier, undefined, [new WidgetActionExtension(rootStore)]);

    this.tabConfigs.set('settings', [
      {
        id: 'title',
        label: i18n.t<string>('widget.settings.title.label'),
        helpText: i18n.t<string>('widget.settings.title.help'),
        type: FieldType.Translatable,
        // t('materialFlowWidget.title')
        defaultValue: getAllI18nTranslations('materialFlowWidget.title'),
        validations: [ValidationRules.required()],
      },
      {
        id: 'showSourceStorageUnit',
        label: i18n.t<string>('materialFlowWidget.settings.showSourceStorageUnit.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.showSourceStorageUnit.help'),
        type: FieldType.Boolean,
        defaultValue: true,
      },
      {
        id: 'showTargetStorageUnit',
        label: i18n.t<string>('materialFlowWidget.settings.showTargetStorageUnit.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.showTargetStorageUnit.help'),
        type: FieldType.Boolean,
        defaultValue: true,
      },
      {
        id: 'showInternalStorageUnit',
        label: i18n.t<string>('materialFlowWidget.settings.showInternalStorageUnit.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.showInternalStorageUnit.help'),
        type: FieldType.Boolean,
        defaultValue: true,
      },
      {
        id: 'showGraph',
        label: i18n.t<string>('materialFlowWidget.settings.showGraph.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.showGraph.help'),
        type: FieldType.Boolean,
        defaultValue: true,
      },
      {
        id: 'showTable',
        label: i18n.t<string>('materialFlowWidget.settings.showTable.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.showTable.help'),
        type: FieldType.Boolean,
        defaultValue: true,
      },
      {
        id: 'graphLocation',
        label: i18n.t<string>('materialFlowWidget.settings.graphLocation.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.graphLocation.help'),
        type: FieldType.Select,
        options: [{
          value: 'top',
          label: i18n.t('materialFlowWidget.settings.graphLocation.options.top'),
        }, {
          value: 'left',
          label: i18n.t('materialFlowWidget.settings.graphLocation.options.left'),
        }],
        defaultValue: 'top',
        disabled: ({ getFieldValue }) => !getFieldValue('showGraph'),
        dependencies: ['showGraph'],
      },
      {
        id: 'amountOfColumns',
        label: i18n.t<string>('materialFlowWidget.settings.amountOfColumns.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.amountOfColumns.help'),
        type: FieldType.Number,
        validations: [
          ValidationRules.minNumber(1),
          ValidationRules.maxNumber(4),
        ],
        defaultValue: 1,
      },
    ]);

    this.tabConfigs.set('data', [
      {
        id: 'properties',
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: StorageUnit.allDisplayableProperties(this.rootStore),
        defaultValue: [
          'no',
          'capacity',
        ],
      },
      {
        id: 'sortBy',
        label: i18n.t<string>('materialFlowWidget.settings.sortBy.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.sortBy.help'),
        type: FieldType.Select,
        options: StorageUnit.allDisplayableProperties(this.rootStore)
          .filter((property) => property.sorter)
          .map((value) => ({
            value: value.key,
            label: value.title,
          })),
        defaultValue: '',
        allowClear: true,
      },
      /**
       * sort order translations:
       * t('materialFlowWidget.settings.sortOrder.options.ascend')
       * t('materialFlowWidget.settings.sortOrder.options.descend')
       */
      {
        id: 'sortOrder',
        label: i18n.t<string>('materialFlowWidget.settings.sortOrder.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.sortOrder.help'),
        type: FieldType.Select,
        options: ['ascend', 'descend'].map((value) => ({
          value,
          label: i18n.t(`activeOperationWidget.settings.modal.preparations.yieldSortOrder.options.${value}`),
        })),
        defaultValue: 'descend',
        validations: [ValidationRules.required()],
      },
      {
        id: 'filterByOrderMaterial',
        label: i18n.t<string>('materialFlowWidget.settings.filterByOrderMaterial.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.filterByOrderMaterial.help'),
        type: FieldType.Boolean,
        defaultValue: false,
      },
    ]);

    this.tabConfigs.set('modalSettings', [
      {
        id: 'modalTitle',
        label: i18n.t<string>('widget.settings.title.label'),
        helpText: i18n.t<string>('widget.settings.title.help'),
        type: FieldType.Translatable,
        // t('materialFlowWidget.title')
        defaultValue: getAllI18nTranslations('materialFlowWidget.title'),
        validations: [ValidationRules.required()],
      },
      {
        id: 'modalShowSourceStorageUnit',
        label: i18n.t<string>('materialFlowWidget.settings.showSourceStorageUnit.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.showSourceStorageUnit.help'),
        type: FieldType.Boolean,
        defaultValue: true,
      },
      {
        id: 'modalShowTargetStorageUnit',
        label: i18n.t<string>('materialFlowWidget.settings.showTargetStorageUnit.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.showTargetStorageUnit.help'),
        type: FieldType.Boolean,
        defaultValue: true,
      },
      {
        id: 'modalShowInternalStorageUnit',
        label: i18n.t<string>('materialFlowWidget.settings.showInternalStorageUnit.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.showInternalStorageUnit.help'),
        type: FieldType.Boolean,
        defaultValue: true,
      },
      {
        id: 'modalShowGraph',
        label: i18n.t<string>('materialFlowWidget.settings.showGraph.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.showGraph.help'),
        type: FieldType.Boolean,
        defaultValue: true,
      },
      {
        id: 'modalShowTable',
        label: i18n.t<string>('materialFlowWidget.settings.showTable.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.showTable.help'),
        type: FieldType.Boolean,
        defaultValue: true,
      },
      {
        id: 'modalGraphLocation',
        label: i18n.t<string>('materialFlowWidget.settings.graphLocation.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.graphLocation.help'),
        type: FieldType.Select,
        options: [{
          value: 'top',
          label: i18n.t('materialFlowWidget.settings.graphLocation.options.top'),
        }, {
          value: 'left',
          label: i18n.t('materialFlowWidget.settings.graphLocation.options.left'),
        }],
        defaultValue: 'top',
        disabled: ({ getFieldValue }) => !getFieldValue('showGraph'),
        dependencies: ['showGraph'],
      },
      {
        id: 'modalAmountOfColumns',
        label: i18n.t<string>('materialFlowWidget.settings.amountOfColumns.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.amountOfColumns.help'),
        type: FieldType.Number,
        validations: [
          ValidationRules.minNumber(1),
          ValidationRules.maxNumber(4),
        ],
        defaultValue: 1,
      },
    ]);

    this.tabConfigs.set('modalData', [
      {
        id: 'modalProperties',
        type: FieldType.PropertiesSelectDisplayField,
        modelOptions: StorageUnit.allDisplayableProperties(this.rootStore),
        defaultValue: [
          'no',
          'capacity',
        ],
      },
      {
        id: 'sortBy',
        label: i18n.t<string>('materialFlowWidget.settings.sortBy.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.sortBy.help'),
        type: FieldType.Select,
        options: StorageUnit.allDisplayableProperties(this.rootStore)
          .filter((property) => property.sorter)
          .map((value) => ({
            value: value.key,
            label: value.title,
          })),
        defaultValue: '',
        allowClear: true,
      },
      /**
       * sort order translations:
       * t('materialFlowWidget.settings.sortOrder.options.ascend')
       * t('materialFlowWidget.settings.sortOrder.options.descend')
       */
      {
        id: 'sortOrder',
        label: i18n.t<string>('materialFlowWidget.settings.sortOrder.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.sortOrder.help'),
        type: FieldType.Select,
        options: ['ascend', 'descend'].map((value) => ({
          value,
          label: i18n.t(`activeOperationWidget.settings.modal.preparations.yieldSortOrder.options.${value}`),
        })),
        defaultValue: 'descend',
        validations: [ValidationRules.required()],
      },
      {
        id: 'filterByOrderMaterial',
        label: i18n.t<string>('materialFlowWidget.settings.filterByOrderMaterial.label'),
        helpText: i18n.t<string>('materialFlowWidget.settings.filterByOrderMaterial.help'),
        type: FieldType.Boolean,
        defaultValue: false,
      },
    ]);
  }
  // eslint-disable-next-line class-methods-use-this
  get tabs() {
    return [
      {
        key: 'settings',
        label: i18n.t('materialFlowWidget.settings.tabTitle'),
      },
      {
        key: 'data',
        label: i18n.t('materialFlowWidget.data.tabTitle'),
      },
      {
        key: 'modalSettings',
        label: i18n.t('materialFlowWidget.modalSettings.tabTitle'),
      },
      {
        key: 'modalData',
        label: i18n.t('materialFlowWidget.modalData.tabTitle'),
      },
      ...super.tabs,
    ];
  }

  getWidgetTitle() {
    return this.getTranslatableValue('settings', 'title');
  }

  getTableColumns() {
    return this.getSelectedProperties('data', 'properties');
  }

  getModalTitle() {
    return this.getTranslatableValue('modalSettings', 'modalTitle');
  }

  getModalTableColumns() {
    return this.getSelectedProperties('modalData', 'modalProperties');
  }
}
