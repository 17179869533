import {observer} from 'mobx-react-lite';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useMemo, useState} from 'react';
import dayjs from 'dayjs';
import {faPrint} from '@fortawesome/pro-solid-svg-icons';
import {printManagement} from '../../../models/scope';
import {PrintJobWidgetConfig} from './PrintJobWidgetConfig';
import {ScopeContext} from '../../../policies/scopeContext';
import {useStore} from '../../../hooks/useStore';
import PrintJobTable from '../shared/PrintJobTable';
import EditPrintJob from '../shared/EditPrintJob';
import ViewPrintJob from '../shared/ViewPrintJob';
import PrintJobWidgetModal from './PrintJobWidgetModal';
import OperatorWidget from '../../operator/shared/OperatorWidget';
import {WidgetLayout} from '../../../models/widgetLayout';
import {WidgetLayouts} from '../../shared/widgets/WidgetLayouts';
import {useMount} from '../../../hooks/useMount';
import {PrintAllocationSettingsIdentifier} from '../../../models/printJob';

const PrintJobWidget = ({disabled, minimized = false, identifier}) => {
  const store = useStore();
  const widgetConfig = useMemo(() => (
    new PrintJobWidgetConfig(store, identifier)
  ), [store.settingStore.operatorSettingsReady]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [viewingRecord, setViewingRecord] = useState(null);
  const fromDate = dayjs().subtract(widgetConfig.getLoadDaysPast(), 'days').startOf('day');
  const toDate = dayjs().add(widgetConfig.getLoadDaysFuture(), 'days').endOf('day');

  useMount(() => {
    store.settingStore.loadAll(
      {
        params: {
          identifier: PrintAllocationSettingsIdentifier,
        },
      }
    );
  });

  return (
    <ScopeContext.Provider value={[printManagement]}>
      <OperatorWidget
        onHeadClick={() => {
          setModalVisible(true);
        }}
        icon={<FontAwesomeIcon icon={faPrint}/>}
        title={widgetConfig.getWidgetTitle()}
        disabled={disabled}
        minimized={minimized}
        identifier={identifier}
        widgetConfig={widgetConfig}
        manualPath={'/print-management/print-job'}
      >
        <PrintJobTable
          fromDate={fromDate}
          toDate={toDate}
          hierarchyId={store.workplaceStore.selectedWorkplace?.hierarchyId}
          onEdit={setEditingRecord}
          onView={setViewingRecord}
        />
      </OperatorWidget>
      {editingRecord && (
        <EditPrintJob
          job={editingRecord}
          onSuccess={() => setEditingRecord(null)}
          onCancel={() => setEditingRecord(null)}
        />
      )}
      {viewingRecord && (
        <ViewPrintJob
          job={viewingRecord}
          onSuccess={() => setViewingRecord(null)}
          onCancel={() => setViewingRecord(null)}
        />
      )}
      {modalVisible && (
        <PrintJobWidgetModal
          fromDate={fromDate}
          toDate={toDate}
          hierarchyId={store.workplaceStore.selectedWorkplace?.hierarchyId}
          open={modalVisible}
          onCancel={() => setModalVisible(false)}
        />
      )}
    </ScopeContext.Provider>
  );
};

PrintJobWidget.icon = faPrint;

PrintJobWidget.identifier = 'PrintJobWidget';
PrintJobWidget.defaultLayout = new WidgetLayout(
  {
    identifier: PrintJobWidget.identifier,
    x: 6,
    y: 0,
    height: 8,
    minHeight: 1,
    width: WidgetLayouts.halfWidth.w,
    minWidth: WidgetLayouts.halfWidth.minW,
  }
);
export default observer(PrintJobWidget);
