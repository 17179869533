import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import BatchSelector from '../../shared/inputs/autocomplete/BatchSelector';
import { Batch } from '../../../models/batch';
import Form from '../../shared/form/Form';
import Select, { SelectProps } from '../../shared/inputs/Select';
import { sortAlphabetically } from '../../shared/tables/sorters';

export type BatchSelectProps = SelectProps<number> & {
  restrictToOrder: boolean;
  batches: Batch[];
};

export const BatchSelect: React.FC<BatchSelectProps> = ({
  restrictToOrder,
  batches,
}) => {
  const { t } = useTranslation();
  let selector: ReactNode;
  if (restrictToOrder) {
    const batchOptions = batches.map((batch: Batch) => ({
      value: batch.id,
      label: batch.no,
    })).sort((a, b) => sortAlphabetically(a.label, b.label));
    selector = (
      <Select<number, { label: string, value: number }>
        allowClear
        placeholder={t<string>('qualityReport.form.batch.selectPlaceholder')}
        options={batchOptions}
        showSearch
        filterOption={(input: string, option?: { label: string, value: number }) =>
          (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
      />
    );
  } else {
    selector = (
      <BatchSelector
        allowClear
        multiple={false}
        placeholder={t<string>('qualityReport.form.batch.selectPlaceholder')}
      />
    );
  }
  return (

    <Form.Item
      name={'batchId'}
      label={t('qualityReport.model.batch.label')}
    >
      {selector}
    </Form.Item>
  );
};
