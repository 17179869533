import { action, observable, makeObservable } from 'mobx';
import { RootStore } from './rootStore';

export class ManualStore {
  showProductionWidgetManual = false;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      showProductionWidgetManual: observable,
      setShowProductionWidgetManual: action,
    });

    this.rootStore = rootStore;
  }

  setShowProductionWidgetManual(value: boolean) {
    this.showProductionWidgetManual = value;
  }
}
