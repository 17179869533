import { action, observable, makeObservable } from 'mobx';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { AppConfig } from '../models/appConfig';
import {
  updateImage, deleteImage,
  updateVideo1, deleteVideo1,
  updateVideo2, deleteVideo2,
  updateVideo3, deleteVideo3,
  updateVideo4, deleteVideo4,
  updateCustomCss, deleteCustomCss
} from '../middleware/endpoints/appConfig';
import { RootStore } from './rootStore';

interface UploadRequest {
  setting?: string;
  content: string;
  filename: string;
}

export interface DeleteRequest {
  setting?: string;
  filename: string;
}

export class AppConfigStore extends EntityStore<AppConfig> {
  appConfig: AppConfig[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'appConfig', Api.appConfig, AppConfig);

    makeObservable(this, {
      appConfig: observable,
      updateFile: action,
      deleteFile: action,
    });
  }

  async updateFile(request: UploadRequest) {
    let result;
    const { setting } = request;
    delete request.setting;
    switch (setting) {
      case 'image':
        result = await this.api[updateImage](request);
        break;
      case 'video1':
        result = await this.api[updateVideo1](request);
        break;
      case 'video2':
        result = await this.api[updateVideo2](request);
        break;
      case 'video3':
        result = await this.api[updateVideo3](request);
        break;
      case 'video4':
        result = await this.api[updateVideo4](request);
        break;
      case 'custom-css':
        result = await this.api[updateCustomCss](request);
        break;
      default:
        break;
    }
    this.appConfig = [this.createModelInstance(result.data)];
  }

  async deleteFile(request: DeleteRequest) {
    let result;
    const { setting } = request;
    delete request.setting;
    switch (setting) {
      case 'image':
        result = await this.api[deleteImage](request);
        break;
      case 'video1':
        result = await this.api[deleteVideo1](request);
        break;
      case 'video2':
        result = await this.api[deleteVideo2](request);
        break;
      case 'video3':
        result = await this.api[deleteVideo3](request);
        break;
      case 'video4':
        result = await this.api[deleteVideo4](request);
        break;
      case 'custom-css':
        result = await this.api[deleteCustomCss](request);
        break;
      default:
        break;
    }
    this.appConfig = [this.createModelInstance(result.data)];
  }
}
