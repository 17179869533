import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBook} from '@fortawesome/free-solid-svg-icons';
import FormModal from '../shared/modal/FormModal';
import ShiftBooksForm from './ShiftBooksForm';
import {useStore} from '../../hooks/useStore';

const NewShiftBooksModal = ({onCancel, onSubmit, ...props}) => {
  const {t} = useTranslation();
  const store = useStore();
  const [fields, setFields] = useState([]);

  const currentWorkplaceId = store.workplaceStore.selectedWorkplace.id;
  const minEndDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    new Date().getHours() - {...props}.timeSpanFinished,
    new Date().getMinutes()
  );
  const maxEndDate = new Date();
  const minStartDate = new Date(
    new Date().getFullYear() - 1,
    new Date().getMonth(),
    new Date().getDate(),
    new Date().getHours(),
    new Date().getMinutes()
  );
  const maxStartDate = new Date(
    new Date().getFullYear() + 1,
    new Date().getMonth(),
    new Date().getDate(),
    new Date().getHours(),
    new Date().getMinutes()
  );

  const activeOperation = store.operationStore.active;
  const finishedOperations = store.operationStore.filterFinishedOnly(currentWorkplaceId, minEndDate, maxEndDate);
  const plannedOperations = store.operationStore.filterPlannedOnly(currentWorkplaceId, minStartDate, maxStartDate);

  return (
    <FormModal
      title={(
        <>
          <FontAwesomeIcon icon={faBook}/>
          {' '}
          {t('shiftBook.newShiftBooksModal.title')}
        </>
      )}
      open
      width={'80%'}
      onCancel={onCancel}
      {...props}
    >
      <ShiftBooksForm
        fields={fields}
        onChange={setFields}
        onCancel={onCancel}
        onSubmit={onSubmit}
        rows={{...props}.messageFieldRows}
        activeOperation={activeOperation}
        finishedOperations={finishedOperations}
        plannedOperations={plannedOperations}
        ordersDisplayedInSelect={{...props}.ordersDisplayedInSelect}
        orderIsMandatory={{...props}.orderIsMandatory}
      />
    </FormModal>
  );
};

export default NewShiftBooksModal;
