import dayjs from 'dayjs';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useStore} from './useStore';
import {useMount} from './useMount';

const useInspectionTaskExecutionModal = () => {
  const store = useStore();
  const {t} = useTranslation();
  const [pendingModalVisible, setPendingModalVisible] = useState(false);
  const [selectedPendingTask, setSelectedPendingTask] = useState(null);
  const [selectedInspectionTask, setSelectedInspectionTask] = useState(null);
  const [selectedPhaseId, setSelectedPhaseId] = useState(null);
  const onModalClose = () => {
    setSelectedPendingTask(null);
    setSelectedInspectionTask(null);
    setSelectedPhaseId(null);
    setPendingModalVisible(false);
  };

  useMount(() => {
    store.roleStore.loadAll();
  });

  const handleOpen = (pendingTask, inspectionTask = null, phaseId = null) => {
    let pendingGroupTasks = [pendingTask];
    if (pendingTask?.inspectionTask?.inspectionTaskGroup?.id) {
      pendingGroupTasks = store.inspectionTaskPendingStore
        .getByInspectionTaskGroupId(pendingTask.inspectionTask.inspectionTaskGroup.id);
    }
    let missingRole;
    pendingGroupTasks.forEach((pendingGroupTask) => {
      const requiredRoleId = (pendingTask) ? pendingGroupTask.inspectionTask?.requiredRoleId
        : inspectionTask?.requiredRoleId;
      if (requiredRoleId && !store.authStore.user?.hasRole(requiredRoleId)) {
        missingRole = requiredRoleId;
      }
    });
    if (pendingTask?.pausedByUserId) {
      store.flashMessageStore.addFlashMessage({
        type: 'info', title: t('inspectionTask.paused'),
      });
    } else if (pendingTask?.inspectionTask.isAbsoluteInterval && (dayjs().valueOf()
        < dayjs(pendingTask?.dueDate).subtract(pendingTask?.inspectionTask.interval, 'minutes').valueOf())) {
      store.flashMessageStore.addFlashMessage({
        type: 'info', title: t('inspectionTask.tooEarly'),
      });
    } else if (missingRole) {
      const role = store.roleStore.getById(missingRole);
      store.flashMessageStore.addFlashMessage({
        type: 'error', title: t('inspectionTask.missingRole', {role: role?.name || missingRole}),
      });
    } else {
      let taskIds;
      if (inspectionTask) {
        taskIds = store.inspectionTaskStore.getSameGroup(inspectionTask, null, phaseId)
          .map((group) => group.inspectionTask.id);
      } else {
        taskIds = pendingGroupTasks.map((pending) => pending.inspectionTaskId);
      }
      store.inspectionTaskStore.loadMany(taskIds)
        .then((detailedTasks) => {
          const docIds = detailedTasks.map((task) => task.documentIds).flat();
          store.documentStore.loadMany(docIds).then(() => {
            setSelectedPendingTask(pendingTask);
            setSelectedInspectionTask(inspectionTask);
            setSelectedPhaseId(phaseId);
            setPendingModalVisible(true);
          });
        });
    }
  };

  return {
    open: handleOpen,
    inspectionTaskExecutionModalProps: {
      open: pendingModalVisible,
      setVisible: setPendingModalVisible,
      inspectionTask: selectedInspectionTask,
      inspectionTaskPending: selectedPendingTask,
      phaseId: selectedPhaseId,
      onCancel: onModalClose,
    },
  };
};

export default useInspectionTaskExecutionModal;
