import React, { CSSProperties, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { List } from 'antd';
import DocumentListItem from './DocumentListItem';
import styles from './DocumentList.module.scss';
import ShowDocument from '../../document/ShowDocument';
import { Document } from '../../../models/document';

export type DocumentListProps = {
  dataSource: number[];
  title: React.ReactNode;
  style?: CSSProperties;
};

const DocumentList: React.FC<DocumentListProps> = ({ dataSource, title, style }) => {
  const [showingDocument, setShowingDocument] = useState<Document | undefined>(undefined);

  return (
    <div style={style} className={styles.documents}>
      {title}
      <List
        className={styles.documentList}
        dataSource={dataSource}
        renderItem={(documentId, index) => (
          <DocumentListItem
            setShowingDocument={setShowingDocument}
            documentId={documentId}
            index={index}
          />
        )}
      />
      {showingDocument && (
        <ShowDocument
          document={showingDocument}
          onCancel={() => setShowingDocument(undefined)}
        />
      )}
    </div>
  );
};

export default observer(DocumentList);
