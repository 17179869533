import { observable, makeObservable } from 'mobx';
import { EntityStore } from './entityStore';
import { Api } from '../middleware/api';
import { ReportLog } from '../models/reportLog';
import i18n from '../i18n/i18n';
import { RootStore } from './rootStore';
import { FlashMessage, FlashMessageType } from '../models/flashMessage';
import { ReportTypeEnum } from '../models/reportType';

export class ReportLogStore extends EntityStore<ReportLog> {
  reportLogs: ReportLog[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore, 'reportLogs', Api.reportLogs, ReportLog);

    makeObservable(this, {
      reportLogs: observable,
    });
  }

  getDependencies() {
    return [
      {
        store: this.rootStore.operationStore,
        modelId: 'operationId',
      },
      {
        store: this.rootStore.orderStore,
        modelId: 'orderId',
      },
      {
        store: this.rootStore.inspectionLotStore,
        modelId: 'inspectionLotId',
      },
    ];
  }

  // TODO: replace typeId with typeName
  getByReportTypeIdAndOperationId(typeId: number, operationId: number) {
    return this.reportLogs.filter((reportLog) => reportLog.typeId === typeId && reportLog.operationId === operationId);
  }

  // TODO: replace typeId with typeName
  getOneByReportTypeIdAndOrderId(typeId: number, orderId: number) {
    return this.reportLogs.find((reportLog) => reportLog.typeId === typeId && reportLog.orderId === orderId);
  }

  getByReportTypeAndOrderId(type: ReportTypeEnum, orderId: number) {
    return this.reportLogs.filter((reportLog) => reportLog.typeName === type && reportLog.orderId === orderId);
  }

  loadByReportTypeAndOrderId(type: ReportTypeEnum, orderId: number) {
    return this.loadAllWithDependencies({
      params: {
        typeName: type,
        orderId,
      },
    });
  }

  async bulkUpdate(reportLogs: ReportLog[]) {
    await this.api.bulkUpdate(reportLogs);
    this.addMessage(
      new FlashMessage(FlashMessageType.SUCCESS, i18n.t('flashMessages.updateSuccess')),
      { skipNotification: false }
    );
    this.clear();
  }
}
