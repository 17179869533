import {CockpitWidgetConfig} from './cockpitWidgetConfig';
import i18n from '../../../../../i18n/i18n';
import {ValidationRules} from '../../../../../utils/validationRules';
import {FieldType} from '../../../../../types/widgetConfig';

export class KpiSpeedometerCockpitWidgetConfig extends CockpitWidgetConfig {
  static onLoadConfig = async (rootStore) => rootStore.keyPerformanceIndicatorStore.loadAll();

  static widgetType = {
    identifier: 'kpi-speedometer',
    name: () => i18n.t('cockpitWidget.widgetTypeIdentifier.kpiSpeedometer'),
  };

  // eslint-disable-next-line class-methods-use-this
  getWidgetTypeName() {
    return i18n.t('cockpitWidget.widgetTypeIdentifier.kpiSpeedometer');
  }

  get tabs() {
    return [
      this.generalSettingsTab,
      this.workplaceTab,
      {
        key: 'kpi',
        label: i18n.t('widget.WidgetSettingsModal.kpiSpeedometerConfigTabTitle'),
      },
    ];
  }

  workplace = [
    {
      id: 'hierarchyId',
      label: i18n.t('cockpitWidget.settings.workplace.label'),
      helpText: i18n.t('cockpitWidget.settings.workplace.help'),
      type: FieldType.HierarchyTreeWorkplace,
    },
  ];

  kpi = [
    {
      id: 'kpi',
      label: i18n.t('kpiSpeedometerCockpitWidgetConfig.settings.kpi.kpi.label'),
      type: FieldType.Select,
      options: this.rootStore.keyPerformanceIndicatorStore.kpis.map((kpi) => ({
        value: kpi.id,
        label: kpi.name,
      })),
      validations: [ValidationRules.required()],
      showSearch: true,
    },
    {
      id: 'minutesBack',
      label: i18n.t('kpiSpeedometerCockpitWidgetConfig.settings.kpi.minutesBack.label'),
      helpText: i18n.t('kpiSpeedometerCockpitWidgetConfig.settings.kpi.minutesBack.helpText'),
      type: FieldType.Number,
      unit: i18n.t('kpiSpeedometerCockpitWidgetConfig.settings.kpi.minutesBack.unit'),
      defaultValue: 120,
      validations: [ValidationRules.minNumber(1), ValidationRules.maxNumber(10080)],
    },
  ];
}
