import React, {useState} from 'react';
import Highcharts from 'highcharts';
import {observer} from 'mobx-react-lite';
import {groupBy} from 'lodash';
import dayjs from 'dayjs';
import {useMount} from '../../../../hooks/useMount';
import {useStore} from '../../../../hooks/useStore';
import Chart from '../../../shared/charts/Chart';
import {formatDuration} from '../../../../utils/formatting';
import {durationFormat, minutesDurationFormat} from '../../../../config/dayjs';
import {TagInterruptionAnalysisWidgetConfig} from './config/tagInterruptionAnalysisWidgetConfig';
import Spinner from '../../../shared/spinners/Spinner';

const TagInterruptionAnalysisChart = ({tagKeys, workplaceId, fromDate, toDate}) => {
  const store = useStore();
  const [chartData, setChartData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [dependenciesLoaded, setDependenciesLoaded] = useState({
    [TagInterruptionAnalysisWidgetConfig.interruptionClass]: false,
    [TagInterruptionAnalysisWidgetConfig.interruptionReason]: false,
  });

  const groupInterruptionStateHistoryByGroupLabel = (interruptionStateHistory) =>
    groupBy(interruptionStateHistory, (ish) => ish.groupLabel);

  const createGroupStrings = (interruptionStateHistory) =>
    interruptionStateHistory.map((ish) => {
      ish.groupLabel = [];

      TagInterruptionAnalysisWidgetConfig.interruptionSourceLevels.forEach((level, index) => {
        if (
          tagKeys.includes(level)
          && ish.interruptionSourceHierarchy
        ) {
          ish.groupLabel
            .push(store.hierarchyStore.getParentHierarchyByLevel(ish.interruptionSourceHierarchy, index)?.name);
        }
      });

      if (
        tagKeys.includes(TagInterruptionAnalysisWidgetConfig.interruptionClass)
        && ish.interruptionClass?.name
      ) {
        ish.groupLabel.push(ish.interruptionClass?.name);
      }

      if (
        tagKeys.includes(TagInterruptionAnalysisWidgetConfig.interruptionReason)
        && ish.interruptionReason?.label
      ) {
        ish.groupLabel.push(ish.interruptionReason.label);
      }

      return ish;
    });

  const aggregateInterruptionStateHistoryByGroupLabel = (groupedInterruptionStateHistory) =>
    Object.values(groupedInterruptionStateHistory).map((stateHistoryEntries) => {
      const aggregatedDuration = stateHistoryEntries
        .reduce((acc, curr) => acc + (curr.durationSeconds?.asMilliseconds() || 0), 0);
      return {
        aggregatedDuration: dayjs.duration(aggregatedDuration, 'milliseconds'),
        interruptionReasons: stateHistoryEntries,
        groupLabel: stateHistoryEntries[0].groupLabel,
      };
    });

  const sortDesc = (a, b) => b.aggregatedDuration.asSeconds() - a.aggregatedDuration.asSeconds();

  const sortInterruptionStateHistoryByDuration = (aggregatedInterruptionStateHistory) =>
    aggregatedInterruptionStateHistory.sort(sortDesc);

  useMount(() => {
    const extended = createGroupStrings(store.stateHistoryStore.getInterruptionStateHistoryByWorkplaceId(workplaceId));
    const grouped = groupInterruptionStateHistoryByGroupLabel(extended);
    const aggregated = aggregateInterruptionStateHistoryByGroupLabel(grouped);
    const sorted = sortInterruptionStateHistoryByDuration(aggregated);
    const sliced = sorted.length > 10 ? sorted.slice(0, 10) : sorted;
    setChartData(sliced);
  }, [store.stateHistoryStore.getInterruptionStateHistoryByWorkplaceId(workplaceId).length, workplaceId]);

  useMount(() => {
    setIsLoading(true);
    const resolve = [];

    if (
      tagKeys.includes(TagInterruptionAnalysisWidgetConfig.interruptionClass)
      && !dependenciesLoaded[TagInterruptionAnalysisWidgetConfig.interruptionClass]
    ) {
      const interruptionReasonPromise = store.interruptionReasonStore.loadAll();
      const interruptionClassPromise = store.interruptionClassStore.loadAll();
      resolve.push(interruptionReasonPromise);
      resolve.push(interruptionClassPromise);

      setDependenciesLoaded({
        ...dependenciesLoaded,
        [TagInterruptionAnalysisWidgetConfig.interruptionClass]: true,
      });
    } else if (
      tagKeys.includes(TagInterruptionAnalysisWidgetConfig.interruptionReason)
      && !dependenciesLoaded[TagInterruptionAnalysisWidgetConfig.interruptionReason]
    ) {
      const interruptionReasonPromise = store.interruptionReasonStore.loadAll();
      resolve.push(interruptionReasonPromise);

      setDependenciesLoaded({
        ...dependenciesLoaded,
        [TagInterruptionAnalysisWidgetConfig.interruptionReason]: true,
      });
    }

    Promise.all(resolve).then(() => store.stateHistoryStore.loadInterruptionHistory({
      workplaceId,
      fromDate,
      toDate,
    }, false))
      .finally(() => setIsLoading(false));
  }, [
    tagKeys?.join(''),
    workplaceId,
    fromDate,
    toDate,
  ]);

  /* eslint-disable object-shorthand */
  /* eslint-disable react/no-this-in-sfc */
  /* eslint-disable func-names */
  const options = {
    chart: {
      type: 'column',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
    },
    title: {
      text: '',
    },
    series: [{
      data: chartData
        ? chartData.map((interruptionReason) => interruptionReason.aggregatedDuration.asSeconds())
        : [],
    }],
    xAxis: {
      categories: chartData
        ? chartData.map((interruptionReason) => interruptionReason.groupLabel)
        : [],
      labels: {
        rotation: -45,
      },
    },
    yAxis: {
      min: 0,
      title: '',
      labels: {
        formatter: function () {
          const format = chartData?.reduce((acc, curr) => (
            acc === durationFormat
            || curr.aggregatedDuration.asMinutes() >= 60 ? durationFormat : minutesDurationFormat
          ), minutesDurationFormat) || durationFormat;
          return formatDuration(dayjs.duration(this.value, 'seconds'), format);
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          formatter: function () {
            const duration = dayjs.duration(this.point.y, 'seconds');
            return formatDuration(duration, duration.asMinutes() < 60 ? minutesDurationFormat : durationFormat);
          },
        },
      },
    },
    legend: {
      enabled: false,
    },
  };

  return (
    <div style={{height: '100%'}}>
      {
        isLoading
          ? <Spinner fullWidth fullHeight/>
          : <Chart options={options} highcharts={Highcharts} showIsEmpty={!chartData?.length}/>
      }
    </div>
  );
};

export default observer(TagInterruptionAnalysisChart);
